import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LabelIcon from '@material-ui/icons/Label';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { useConfirm } from '@passthrough/uikit';
import { Dropdown } from './dropdown';
import { QuestionDisplay } from './question_display';

const useStyles = makeStyles(() => ({
  cardContent: {
    display: 'flex',
  },
  leftContent: {
    flex: 1,
  },
  rightContent: {
    flex: '0 0 40%',
  },
}));

export const QuestionCard = ({ item, tagOptions, onAddTag, onRemoveTag }) => {
  const classes = useStyles();
  const confirm = useConfirm();
  const [tags, setTags] = React.useState(item.tags);
  const [loading, setLoading] = React.useState(false);

  const handleDeleteTag = (value) => {
    confirm({
      description: (
        <div>
          <QuestionDisplay item={item} />
          <Typography>
            You are <strong>REMOVING</strong> the question above from the tag{' '}
            <strong>{value}</strong>.
          </Typography>
        </div>
      ),
      destructive: true,
    }).then(() => {
      setLoading(true);

      onRemoveTag(value)
        .then(() => {
          setTags(tags.filter((tag) => tag !== value));
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const handleAddTag = (newValue) => {
    confirm({
      description: (
        <div>
          <QuestionDisplay item={item} />
          <Typography>
            You are <strong>ADDING</strong> the question above to the tag{' '}
            <strong>{newValue}</strong>.
          </Typography>
        </div>
      ),
    }).then(() => {
      setLoading(true);
      onAddTag(newValue)
        .then(() => {
          setTags([...tags, newValue]);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <Card elevation={0} variant="outlined">
      <CardContent className={classes.cardContent}>
        <List className={classes.leftContent}>
          <ListItem>
            <ListItemText
              secondary={item.questionText}
              primary={<strong>Question</strong>}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              secondary={
                <Box
                  mt={2}
                  display="flex"
                  flexWrap="wrap"
                  flexDirection="row"
                  gridGap={10}
                >
                  {tags.map((value) => (
                    <Chip
                      key={value}
                      variant="outlined"
                      color="default"
                      icon={<LabelIcon />}
                      disabled={loading}
                      onDelete={() => handleDeleteTag(value)}
                      label={<Typography variant="body2">{value}</Typography>}
                    />
                  ))}

                  <Dropdown
                    options={tagOptions.filter(
                      (option) => !tags.includes(option),
                    )}
                    getOptionLabel={(option) => option}
                    onSelect={handleAddTag}
                    placeholder="Add a new tag"
                    startIcon={<AddCircleIcon />}
                  >
                    Add tag
                  </Dropdown>
                </Box>
              }
              primary={<strong>Tags</strong>}
            />
          </ListItem>
        </List>
        <div className={classes.rightContent}>
          <List>
            <ListItem>
              <ListItemText
                secondary={item.questionType}
                primary={<strong>Question Type</strong>}
              />
            </ListItem>
            {item.questionFieldOrChoice ? (
              <ListItem>
                <ListItemText
                  secondary={item.questionFieldOrChoice}
                  primary={<strong>Question Field</strong>}
                />
              </ListItem>
            ) : null}
          </List>
          <Accordion variant="outlined" dense>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <strong>Questionnaires ({item.questionnaireIds.length})</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <span>
                  {item.questionnaireIds.map((value, idx) => (
                    <>
                      {value}
                      {idx !== item.questionnaireIds.length - 1 ? <br /> : null}
                    </>
                  ))}
                </span>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </CardContent>
    </Card>
  );
};
