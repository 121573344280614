import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Typography, Button } from '@passthrough/uikit';
import PencilIcon from 'mdi-material-ui/PencilOutline';
import { List } from '@material-ui/core';

import { DocumentCardEntry } from './document_card_entry';
import { MultiDocCardEntry } from './multiple_documents_card_entry';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  docCards: {
    padding: theme.spacing(0, 2),
  },
}));

export function DocumentsDisplay({
  allFileConfigData,
  answerData,
  lastSubmittedAnswer,
  handleOpenDocument,
  hideEditButtons,
  handleOpenDocsEditModal,
  multiDocs = false,
}) {
  const classes = useStyles();

  const hasMultipleDocuments = allFileConfigData.length > 1 || multiDocs;

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <Typography variant="card-heading">Documents</Typography>

        {hideEditButtons ? null : (
          <Button
            size="small"
            aria-label="Manage"
            variant="icon"
            onClick={handleOpenDocsEditModal}
          >
            <PencilIcon />
          </Button>
        )}
      </div>

      <Typography variant="label" color="text.secondary">
        Compare information in the following document
        {hasMultipleDocuments ? 's' : ''} with the provided details.
      </Typography>

      <Paper variant="outlined">
        <List>
          {allFileConfigData.map((configData) =>
            multiDocs ? (
              <MultiDocCardEntry
                key={configData.key}
                type={configData.label}
                fileData={answerData?.[configData.key] || []}
                lastSubmittedAnswer={lastSubmittedAnswer?.[configData.key]}
                handleOpenDocument={handleOpenDocument}
              />
            ) : (
              <DocumentCardEntry
                key={configData.key}
                type={configData.label}
                fileData={answerData?.[configData.key] || {}}
                lastSubmittedAnswer={lastSubmittedAnswer?.[configData.key]}
                handleOpenDocument={handleOpenDocument}
              />
            ),
          )}
        </List>
      </Paper>
    </div>
  );
}
