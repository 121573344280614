import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Icons, Link, Typography } from '@passthrough/uikit';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    color: `${theme.palette.primary.main} !important`,
  },
  buttonContainer: {
    marginTop: theme.spacing(1),
  },
  fileList: {
    paddingLeft: theme.spacing(2),
    borderLeft: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));

export function HistoryFileDownload({ files }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const tooltipText = open ? 'Hide documents' : 'View documents';

  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <div className={classes.fileList}>
          {files.map((file) => (
            <Link key={file.name} href={file.url} variant="external">
              {file.name}
            </Link>
          ))}
        </div>
      </Collapse>
      <div className={classes.buttonContainer}>
        <Tooltip title={<Typography variant="label">{tooltipText}</Typography>}>
          <Button
            variant="secondary"
            size="small"
            className={classes.button}
            onClick={() => setOpen(!open)}
            aria-label={tooltipText}
          >
            <Icons.DescriptionOutlined fontSize="small" />
            {open ? (
              <Icons.KeyboardArrowUp fontSize="small" />
            ) : (
              <Icons.KeyboardArrowDown fontSize="small" />
            )}
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}
