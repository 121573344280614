import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { US_STATES } from 'services/us_states';
import { VisuallyHidden } from './utils';

const STATE_BY_LABEL = new Map(US_STATES.map((c) => [c.label, c]));
const STATE_BY_CODE = new Map(US_STATES.map((c) => [c.code, c]));

export function getState(codeOrLabel) {
  return STATE_BY_CODE.get(codeOrLabel) || STATE_BY_LABEL.get(codeOrLabel);
}

export function StateInput({
  frontendLabel,
  value,
  handleChange,
  errorMsg,
  autoFocus = false,
  fullWidth = false,
  selectOnFocus = true,
  enableAutofill = false,
}) {
  const foundState = getState(value);

  // hack to support state values that are not in the list
  const [enableFreeSolo, setEnableFreeSolo] = React.useState(
    Boolean(value && !foundState),
  );

  function getOptionLabel(option) {
    let state;

    if (typeof option === 'string') {
      state = US_STATES.find((c) => c.label === option);
      if (!state) {
        state = { label: option, code: option };
      }
    } else {
      state = option;
    }

    if (state) {
      return state.label;
    }

    return '';
  }

  return (
    <>
      {enableAutofill && (
        <VisuallyHidden
          component="input"
          value={foundState?.label || value || ''}
          onChange={(e) => {
            const state = getState(e.target.value);
            if (state) {
              setEnableFreeSolo(false);
            }
            handleChange(e, state);
          }}
          autoComplete="address-level1"
        />
      )}
      <Autocomplete
        options={US_STATES}
        autoHighlight
        selectOnFocus={selectOnFocus}
        fullWidth={fullWidth}
        value={foundState || value || ''}
        onChange={handleChange}
        getOptionLabel={getOptionLabel}
        onInputChange={() => {
          if (enableFreeSolo) {
            setEnableFreeSolo(false);
          }
        }}
        renderOption={(option) => <>{option.label}</>}
        renderInput={(params) => (
          <TextField
            {...params}
            label={frontendLabel || 'State'}
            variant="outlined"
            error={!!errorMsg}
            autoFocus={autoFocus}
            helperText={errorMsg}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'chrome-off',
              'data-test': 'state',
            }}
          />
        )}
      />
    </>
  );
}
