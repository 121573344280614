import React from 'react';

import { Chip, Icons } from '@passthrough/uikit';

export function IssueCell({ issueType }) {
  switch (issueType) {
    case 'review':
      return <Chip variant="success" size="small" label="Ready for review" />;
    case 'waived':
      return (
        <Chip variant="success" size="small" label="Investor stuck (waived)" />
      );
    case 'comment':
      return (
        <Chip
          variant="neutral"
          size="small"
          label="Unresolved"
          icon={<Icons.CommentOutlined />}
        />
      );
    case 'potentialMatch':
      return (
        <Chip variant="neutral" size="small" label="New potential match" />
      );
    case 'newAlert-TRUE_MATCH':
      return (
        <Chip variant="error" size="small" label="New alert (Positive match)" />
      );
    case 'newAlert-NOT_SURE':
      return (
        <Chip variant="warning" size="small" label="New alert (Unsure match)" />
      );

    default:
  }
}
