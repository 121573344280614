import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { Button } from 'components/button';
import * as constants from './constants';

const useStyles = makeStyles((theme) => ({
  noButton: {
    marginRight: theme.spacing(1),
  },
  questionConfirm: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 2),
    marginBottom: theme.spacing(2),
  },
}));

function onClickNo(setConfirmed, setAlertText) {
  setConfirmed(constants.CONFIRMED_NO);
  const alertText = <Typography>No tags will be reused</Typography>;
  setAlertText(alertText);
}

function onClickYes(setConfirmed, setAlertText, questionTags) {
  setConfirmed(constants.CONFIRMED_YES);

  const tags = questionTags.map((tag, i) => (i > 0 ? ', ' : '') + tag.tag);
  const alertText = (
    <Typography>
      The following tags will be reused: <strong>{tags}</strong>
    </Typography>
  );
  setAlertText(alertText);
}

export const ConfirmationBanner = ({
  setConfirmed,
  setAlertText,
  questionTags,
}) => {
  const classes = useStyles();

  return (
    <Paper
      elevation={0}
      key="ack"
      variant="outlined"
      className={classes.questionConfirm}
    >
      <Typography>
        <strong>Are they the same question?</strong>
      </Typography>

      <div key="buttons">
        <Button
          variant="outlined"
          onClick={() => onClickNo(setConfirmed, setAlertText)}
          className={classes.noButton}
        >
          No
        </Button>
        <Button
          variant="outlined"
          onClick={() => onClickYes(setConfirmed, setAlertText, questionTags)}
        >
          Yes, they are
        </Button>
      </div>
    </Paper>
  );
};
