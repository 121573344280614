import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';

import { useQuestionnaire } from 'services/providers/questionnaire';
import { Button } from 'components/button';

const useStyles = makeStyles((theme) => ({
  startFromScratchTitle: {
    fontWeight: 400,
    marginBottom: theme.spacing(4),
    fontSize: theme.typography.pxToRem(20),
  },
  displayName: {
    fontWeight: 500,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(8, 0),
  },
  svgContainer: {
    width: '100%',
    maxWidth: '450px',
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
  },
  fundName: {
    margin: theme.spacing(3, 0),
  },
  cancelButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.dark,
      backgroundColor: theme.palette.error.background,
    },
  },
}));

const LOADING_TIME = 6000;

export function ArtificialLoadingStep({
  fundName,
  lpName,
  profileName,
  onCancel,
  onSubmit,
  documentDisplayType,
  isSubmitting,
}) {
  const [waiting, setWaiting] = useState(true);
  const [timeoutId, setTimeoutId] = useState(null);
  const classes = useStyles();
  const { artificialLoadingSvgUrl } = useQuestionnaire();

  const clearTimer = () => clearTimeout(timeoutId);

  const timeoutEffect = () => {
    const timeout = setTimeout(() => {
      setWaiting(false);
      onSubmit();
    }, LOADING_TIME);

    setTimeoutId(timeout);
    return clearTimer;
  };

  useEffect(timeoutEffect, []);

  const handleCancel = () => {
    onCancel();
    clearTimer();
  };

  return (
    <Container component="main" maxWidth="md">
      <Fade in={waiting || isSubmitting}>
        <div className={classes.container}>
          <div className={classes.svgContainer}>
            <img
              src={artificialLoadingSvgUrl}
              alt="Prefilling questionnaire..."
            />
          </div>
          <Typography className={classes.fundName} align="center">
            {fundName}
          </Typography>
          <Typography className={classes.startFromScratchTitle} align="center">
            Preparing <span className={classes.displayName}>{lpName}</span>'s
            {` ${documentDisplayType}`} using the{' '}
            <span className={classes.displayName}>{profileName}</span>{' '}
            profile...
          </Typography>
          {!isSubmitting ? (
            <Button className={classes.cancelButton} onClick={handleCancel}>
              Cancel
            </Button>
          ) : null}
        </div>
      </Fade>
    </Container>
  );
}
