/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useContext, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MatTable from '@material-ui/core/Table';
import MatTableContainer from '@material-ui/core/TableContainer';
import MatTableBody from '@material-ui/core/TableBody';
import MatTableFooter from '@material-ui/core/TableFooter';
import MatTableCell from '@material-ui/core/TableCell';
import MatTableHead from '@material-ui/core/TableHead';
import MatTableRow from '@material-ui/core/TableRow';
import MatTableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const TableTypeContext = createContext(null);
const TABLE_TYPE_NORMAL = 'TABLE_TYPE_NORMAL';
const TABLE_TYPE_STICKY = 'TABLE_TYPE_STICKY';

const useContainerStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    width: '100%',
    border: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    overflowY: 'hidden',
    borderRadius: 4,
  },
  nonPaperRoot: {
    border: `1px solid ${theme.palette.divider}`,
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  title: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  actions: {
    alignSelf: 'flex-end',
  },
  noTopMargin: {
    marginTop: 0,
  },
}));

export function TableContainer({ children }) {
  const classes = useContainerStyles();
  return <div className={classes.container}>{children}</div>;
}

export function TableActions({ children, fullWidth }) {
  const classes = useContainerStyles();
  const style = { width: fullWidth ? '100%' : '' };
  return (
    <div className={classes.actions} style={style}>
      {children}
    </div>
  );
}

export function Table({
  title,
  noTopMargin,
  elevation = 2,
  usePaper = true,
  belowRowsElement = null,
  stickyHeader = false,
  stickyHeaderMaxHeight = '80vh',
  stickyHeaderMaxWidth = 'calc(100vw - 48px)',
  ...props
}) {
  // (stickyHeader = true, usePaper = false) together is not supported
  const classes = useContainerStyles();

  const tableClassName = usePaper ? undefined : classes.nonPaperRoot;
  const table = stickyHeader ? (
    <TableTypeContext.Provider value={TABLE_TYPE_STICKY}>
      <MatTableContainer
        style={{
          maxHeight: stickyHeaderMaxHeight,
          maxWidth: stickyHeaderMaxWidth,
          overflowX: 'visible',
          overflowY: 'auto',
        }}
      >
        <MatTable
          className={tableClassName}
          component="div"
          stickyHeader
          {...props}
        />
      </MatTableContainer>
    </TableTypeContext.Provider>
  ) : (
    <TableTypeContext.Provider value={TABLE_TYPE_NORMAL}>
      <MatTable className={tableClassName} component="div" {...props} />
    </TableTypeContext.Provider>
  );

  if (!usePaper) return table;
  return (
    <Paper
      className={clsx(classes.paper, {
        [classes.noTopMargin]: noTopMargin,
      })}
      elevation={elevation}
    >
      {title ? (
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
      ) : null}
      {table}
      {belowRowsElement}
    </Paper>
  );
}

export function TableHead(props) {
  return <MatTableHead component="div" {...props} />;
}

export function TableBody(props) {
  return <MatTableBody component="div" {...props} />;
}

export function TableFooter(props) {
  return <MatTableFooter component="div" {...props} />;
}

const useRowStyles = makeStyles((theme) => ({
  root: {
    textDecoration: 'none',
    borderBottom: `1px solid ${theme.palette.table.divider}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  extraPadding: {
    '& > :first-child': {
      paddingLeft: theme.spacing(2),
    },
    '&:last-child': {
      borderBottom: 'none',
    },
    '& > :last-child': {
      paddingRight: theme.spacing(2),
    },
  },
  customHover: {
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  customSelected: {
    backgroundColor: theme.palette.primary.light,
  },
}));

const useArrowStyles = makeStyles((theme) => ({
  arrowContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
  },

  defaultArrow: {
    transition: '0.5s',
    opacity: '0',
    color: theme.palette.primary.grey,
  },
  hoverArrow: {
    color: theme.palette.primary.main,
    opacity: '1',
    transform: 'translate(5px)',
  },
}));

export function TableRow({
  role,
  selected,
  hover,
  disableSidePadding = false,
  children,
  className,
  ...props
}) {
  const [onHover, setOnHover] = useState(false);
  const classes = useRowStyles();
  const arrowClasses = useArrowStyles();

  const showArrowOnHover = role === 'checkbox';

  return (
    <MatTableRow
      component="div"
      onMouseEnter={() => {
        setOnHover(true);
      }}
      onMouseLeave={() => {
        setOnHover(false);
      }}
      onFocus={() => {
        setOnHover(true);
      }}
      onBlur={() => {
        setOnHover(false);
      }}
      classes={{
        root: clsx(classes.root, {
          [classes.extraPadding]: !disableSidePadding,
        }),
      }}
      {...props}
      className={clsx(className, {
        [classes.customSelected]: selected,
        [classes.customHover]: hover && !selected,
      })}
      hover={false}
      selected={false}
    >
      {children}

      {showArrowOnHover ? (
        <TableCell scope="row" align="right">
          <div className={arrowClasses.arrowContainer}>
            <ArrowForwardIcon
              className={clsx(arrowClasses.defaultArrow, {
                [arrowClasses.hoverArrow]: onHover,
              })}
            />
          </div>
        </TableCell>
      ) : null}
    </MatTableRow>
  );
}

const useCellStyles = makeStyles((theme) => ({
  head: {
    fontWeight: '400',
    backgroundColor: theme.palette.table.header,
    borderBottom: `1px solid ${theme.palette.divider} !important`,
    fontSize: '0.875rem',
  },
  body: {
    fontSize: '1rem',
  },
  footer: {
    padding: '0px !important',
  },
  root: {
    padding: '4px 8px',
    border: 'none',
    height: '40px',
  },
  tableTypeStickyRoot: {
    padding: '4px 8px',
    height: '40px',
  },
  string: {
    minWidth: '120px',
  },
  actions: { display: 'flex', justifyContent: 'flex-end' },
}));

export function TableCell({
  children,
  component = 'div',
  actions = false,
  className,
  ...props
}) {
  const isString = typeof children === 'string' || children instanceof String;
  const classes = useCellStyles();
  let rootClass = classes.root;
  const tableType = useContext(TableTypeContext);
  switch (tableType) {
    case TABLE_TYPE_STICKY:
      rootClass = classes.tableTypeStickyRoot;
      break;
    case TABLE_TYPE_NORMAL:
      rootClass = classes.root;
      break;
    default:
      rootClass = classes.root;
      break;
  }

  return (
    <MatTableCell
      {...props}
      component={component}
      classes={{
        head: classes.head,
        body: classes.body,
        footer: classes.footer,
        root: clsx(rootClass, {
          [classes.string]: isString,
        }),
      }}
      className={className}
      align={actions ? 'right' : props.align}
    >
      {actions ? <div className={classes.actions}>{children}</div> : children}
    </MatTableCell>
  );
}

export function TableSortLabel({ ...props }) {
  return <MatTableSortLabel {...props} />;
}
