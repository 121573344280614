import React from 'react';

export function NoMatchesIcon({ className }) {
  return (
    <svg
      data-test="diligence_no_matches"
      className={className}
      width="156"
      height="145"
      viewBox="0 0 156 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="44"
        y="112"
        width="96"
        height="33"
        rx="16.5"
        fill="url(#paint0_linear_1060_16039)"
        fillOpacity="0.1"
      />
      <rect
        opacity="0.1"
        y="53"
        width="156"
        height="92"
        rx="46"
        fill="url(#paint1_linear_1060_16039)"
      />
      <rect
        opacity="0.1"
        x="126.086"
        y="65"
        width="121"
        height="65"
        rx="32.5"
        transform="rotate(-180 126.086 65)"
        fill="url(#paint2_linear_1060_16039)"
      />
      <g filter="url(#filter0_d_1060_16039)">
        <path
          d="M39.25 24H87.784L105.984 42.2002V108.934H39.25V24Z"
          fill="#21BF60"
        />
        <path
          d="M72.8336 66.3219C77.4084 66.3219 81.1137 62.6166 81.1137 58.0418C81.1137 53.4671 77.4084 49.7617 72.8336 49.7617C68.2589 49.7617 64.5535 53.4671 64.5535 58.0418C64.5535 62.6166 68.2589 66.3219 72.8336 66.3219ZM72.8336 70.4619C67.3067 70.4619 56.2734 73.2358 56.2734 78.742V80.8121C56.2734 81.9506 57.2049 82.8821 58.3435 82.8821H87.3238C88.4623 82.8821 89.3938 81.9506 89.3938 80.8121V78.742C89.3938 73.2358 78.3606 70.4619 72.8336 70.4619Z"
          fill="#161616"
        />
        <path d="M87.7812 42.2002V24L105.981 42.2002H87.7812Z" fill="#161616" />
        <circle cx="105.986" cy="102.869" r="24.267" fill="#161616" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M120.195 95.9806L101.744 113.278L91.5859 101.85L96.1203 97.8191L102.14 104.591L116.045 91.5547L120.195 95.9806Z"
          fill="#EFFFFD"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1060_16039"
          x="31.25"
          y="17"
          width="107"
          height="119.137"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1060_16039"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1060_16039"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1060_16039"
          x1="110.891"
          y1="147.5"
          x2="55.6962"
          y2="96"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8060FF" />
          <stop offset="1" stopColor="#8060FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1060_16039"
          x1="16.5001"
          y1="85.6729"
          x2="45.0348"
          y2="125.039"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#21BF60" />
          <stop offset="0.898629" stopColor="#21BF60" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1060_16039"
          x1="138.586"
          y1="69.5614"
          x2="196.064"
          y2="105.517"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#21BF60" />
          <stop offset="1" stopColor="#21BF60" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
