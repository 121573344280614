import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Link, UIKitSettingsProvider } from '@passthrough/uikit';
import Skeleton from '@material-ui/lab/Skeleton';

import * as urls from 'services/urls';
import { useRegionalUrlBuilder } from 'services/regional';

const useStyles = makeStyles(() => ({
  orgName: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export function NameCell({ fund, skeleton, alwaysLinkToFund }) {
  const classes = useStyles();

  if (skeleton) {
    return (
      <>
        <Skeleton width="100%" height={20} />
        <Skeleton width="70%" height={16} />
      </>
    );
  }

  const urlBuilder = useRegionalUrlBuilder();

  const { url, isLocal } = urlBuilder(
    fund.baseUrl,
    urls.closingsUrl({ fundId: fund.id }),
  );

  return (
    <UIKitSettingsProvider
      linkComponent={isLocal ? RouterLink : 'a'}
      redirectAttribute={isLocal ? 'to' : 'href'}
    >
      <div className={classes.orgName}>
        {alwaysLinkToFund || fund.userHasAccess ? (
          <Link href={url} htmlProps={{ 'data-test': `fund-link-${fund.id}` }}>
            {fund.name}
          </Link>
        ) : (
          <Typography>{fund.name}</Typography>
        )}
        <Typography
          variant="label"
          size="small"
          color="text.secondary"
          data-test={`fund-${fund.id}`}
        >
          {fund.orgName}
        </Typography>
      </div>
    </UIKitSettingsProvider>
  );
}
