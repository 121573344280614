import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { getSingleErrorFromResponse } from 'services/utils';

import * as api from 'services/api';
import { EmptyState } from 'components/empty_v2';
import { Button } from 'components/button';
import { Dialog } from 'components/dialog/index';
import { Spinner } from 'components/spinner';

import * as utils from '../publish_modal/utils';
import { ReviewChanges } from './review_changes';
import { CompareBar } from './compare_bar';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 4, 0, 4),
  },
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.appbar.background,
  },
  toolBar: {
    justifyContent: 'space-between',
  },
  toolBarLeftSide: {
    flexDirection: 'column',
  },
  title: {
    marginLeft: theme.spacing(1),
  },
}));

const useDialogStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.default,
  },
}));

export const CompareTemplateModal = ({
  open,
  onClose,
  onRefresh,
  fundId,
  questionnaireId,
  questionnaireName,
  templateFundId,
  templateQuestionnaireId,
  templateQuestionnaireName,
}) => {
  const classes = useStyles();
  const dialogClasses = useDialogStyles();
  const [isLoading, setLoading] = React.useState(false);
  const [diff, setDiff] = React.useState(null);
  const [editError, setEditError] = React.useState(null);

  const fetch = async () => {
    setLoading(true);

    const { data: current } = await api.getQuestionnaireDraft({
      fundId,
      questionnaireId,
    });

    const { data: template } = await api.getQuestionnaireDraft({
      fundId: templateFundId,
      questionnaireId: templateQuestionnaireId,
    });

    const dataDiff = utils.getDiff({
      data: template.data || [],
      draftData: current.draftData || [],
    });
    setDiff(dataDiff);
    setLoading(false);
  };

  const handleEditTemplate = (template) => {
    setLoading(true);
    setEditError('');

    api
      .editQuestionnaireTemplate({
        fundId,
        questionnaireId,
        template,
      })
      .then(() => {
        onRefresh();
      })
      .catch((e) => {
        setEditError(getSingleErrorFromResponse(e.response));
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (open) {
      fetch();
    }
  }, [open, templateQuestionnaireId]);

  const hasDiff =
    diff?.updated?.length || diff?.created?.length || diff?.deleted?.length;

  return (
    <Dialog open={open} fullScreen onClose={onClose} classes={dialogClasses}>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <div className={classes.toolBarLeftSide}>
            <Typography className={classes.title}>
              Comparing <strong>{questionnaireName}</strong> to template{' '}
              <strong>{templateQuestionnaireName}</strong>
            </Typography>
          </div>
          <div>
            <Button
              variant="text"
              size="large"
              color="default"
              onClick={onClose}
              loading={isLoading}
              className={classes.closeButton}
            >
              Close
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <CompareBar
          questionnaireName={questionnaireName}
          templateQuestionnaireName={templateQuestionnaireName}
          onChange={handleEditTemplate}
          loading={isLoading}
          error={editError}
        />

        {isLoading ? <Spinner fullScreen /> : null}

        {!isLoading && hasDiff ? <ReviewChanges diff={diff} /> : null}

        {!isLoading && !hasDiff ? (
          <EmptyState title="There are no changes." />
        ) : null}
      </div>
    </Dialog>
  );
};
