import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import { PoweredBy } from 'components/powered_by';
import { useWhiteLabelConfig } from 'services/providers/theme';

const useStyles = makeStyles((theme) => ({
  appLogo: {
    maxHeight: '50px',
    maxWidth: '15vw',
    minWidth: '120px',
    objectFit: 'contain',
  },
  externalLogo: {
    maxHeight: '50px',
    maxWidth: '8vw',
    minWidth: '140px',
    objectFit: 'contain',
  },
  logoContainer: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));

export function Logo({ src }) {
  const classes = useStyles();
  return <img src={src} alt="logo" className={classes.appLogo} />;
}

export function ExternalLogo() {
  const classes = useStyles();
  const whiteLabelConfig = useWhiteLabelConfig();
  const whiteLabelLogo =
    whiteLabelConfig?.logos?.app || window.static?.dark_logo_png;
  const showPoweredBy = !!whiteLabelConfig?.logos?.app;
  return (
    <div className={classes.logoContainer}>
      <img src={whiteLabelLogo} alt="logo" className={classes.externalLogo} />
      {showPoweredBy ? (
        <>
          <Divider orientation="vertical" flexItem />
          <PoweredBy />
        </>
      ) : null}
    </div>
  );
}
