import React from 'react';
import { Chip } from '@passthrough/uikit';

export const DiligenceChipVariants = {
  CHANGES_REQUESTED: 0,
  POTENTIAL_MATCHES: 1,
  APPROVED: 2,
};

function generalizeVariant(diligenceVariant) {
  if (diligenceVariant === DiligenceChipVariants.CHANGES_REQUESTED) {
    return 'error';
  }

  if (diligenceVariant === DiligenceChipVariants.POTENTIAL_MATCHES) {
    return 'warning';
  }

  if (diligenceVariant === DiligenceChipVariants.APPROVED) {
    return 'success';
  }

  return '';
}

export function DiligenceChip({ label, variant }) {
  const generalVariant = generalizeVariant(variant);

  return <Chip size="small" variant={generalVariant} label={label} />;
}
