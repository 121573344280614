import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Button } from 'components/button';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(4, 6),
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: theme.spacing(2, 1),
    },
  },
  paper: {
    textAlign: 'center',
  },
  backButton: {
    fontWeight: 500,
    fontSize: '16px',
  },
  hiddenSpacingButton: {
    visibility: 'hidden',
  },
}));

export const ProfilePage = ({ maxWidth, children }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <div className={classes.backButton}>
        <Button
          name="back"
          variant="text"
          color="primary"
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={() => history.push('/')}
        >
          Tasks
        </Button>
      </div>
      <Container component="main" maxWidth={maxWidth || 'md'}>
        <Paper className={classes.paper} variant="outlined">
          {children}
        </Paper>
      </Container>
      <div className={classes.hiddenSpacingButton}>
        <Button
          variant="text"
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={() => {}}
        >
          Tasks
        </Button>
      </div>
    </div>
  );
};
