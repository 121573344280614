import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 4),
  },
  container: {
    display: 'flex',
    width: 'inherit',
    flexWrap: 'wrap',
  },
  answerTypeLabel: {
    flex: '1 1 60%',
  },
  answer: {
    flex: '1 1 40%',
    textAlign: 'left',
  },
}));

export function DataItem({ answerType, answer }) {
  const classes = useStyles();

  return (
    <>
      <ListItem className={classes.listItem}>
        <div className={classes.container}>
          <div className={classes.answerTypeLabel}>
            <Typography>{answerType}</Typography>
          </div>
          <div className={classes.answer}>
            <Typography>{answer}</Typography>
          </div>
        </div>
      </ListItem>
    </>
  );
}
