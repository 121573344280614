import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useDiscardConfirmation } from 'services/discard';
import { objectEquals } from 'services/utils';

import {
  Modal,
  Link,
  Chip,
  Typography,
  UIKitSettingsProvider,
} from '@passthrough/uikit';
import { GPSelectionInput } from 'components/gp_selection_input/index';
import * as constants from 'components/gp_selection_input/constants';

const useStyles = makeStyles((theme) => ({
  recommendedLabel: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
}));

export function QuestionnaireSettingsModal({
  open,
  onClose,
  subscriptionDocumentFileUrl,
  subscriptionDocumentName,
  numberOfCountersigners,
  countersignersAreOptional,
  members,
  fundId,
  initialState,
  errors,
  setErrors,
  updateSettings,
  loading,
}) {
  const [form, setForm] = useState(initialState);
  const closeWithConfirmation = useDiscardConfirmation({
    onConfirm: onClose,
    needConfirmation: open && !objectEquals(form, initialState),
  });
  const classes = useStyles();

  const getLabel = (baseLabel) =>
    countersignersAreOptional ? `${baseLabel} (optional)` : baseLabel;

  return (
    <Modal
      open={open}
      onClose={closeWithConfirmation}
      headerLabel="Edit questionnaire settings"
      showCancelButton
      primaryButtonText="Save"
      primaryButtonLoading={loading}
      onEntering={() => {
        setForm(initialState);
        setErrors({});
      }}
      onSubmit={() => updateSettings(form)}
    >
      <div>
        <Typography variant="body" size="small">
          Subscription document
        </Typography>
        <UIKitSettingsProvider redirectAttribute="href" linkComponent="a">
          <Typography>
            <Link href={subscriptionDocumentFileUrl} variant="external">
              {subscriptionDocumentName}
            </Link>
          </Typography>
        </UIKitSettingsProvider>
      </div>

      {numberOfCountersigners >= 1 ? (
        <GPSelectionInput
          label={getLabel('Default countersigner')}
          errorMsg={errors.defaultCountersignerId}
          value={form.defaultCountersignerId || ''}
          setValue={(value) =>
            setForm({ ...form, defaultCountersignerId: value })
          }
          roleLabelNum={constants.DEFAULT_COUNTERSIGNER_1_LABEL_NUM}
          members={members}
          fundId={fundId}
          includeBlankOption={countersignersAreOptional}
        />
      ) : null}
      {numberOfCountersigners >= 2 ? (
        <GPSelectionInput
          label={getLabel('Default second countersigner')}
          errorMsg={errors.defaultCountersigner2Id}
          value={form.defaultCountersigner2Id || ''}
          setValue={(value) =>
            setForm({ ...form, defaultCountersigner2Id: value })
          }
          roleLabelNum={constants.DEFAULT_COUNTERSIGNER_2_LABEL_NUM}
          members={members}
          fundId={fundId}
          includeBlankOption={countersignersAreOptional}
        />
      ) : null}
      {numberOfCountersigners >= 3 ? (
        <GPSelectionInput
          label={getLabel('Default third countersigner')}
          errorMsg={errors.defaultCountersigner3Id}
          value={form.defaultCountersigner3Id || ''}
          setValue={(value) =>
            setForm({ ...form, defaultCountersigner3Id: value })
          }
          roleLabelNum={constants.DEFAULT_COUNTERSIGNER_3_LABEL_NUM}
          members={members}
          fundId={fundId}
          includeBlankOption={countersignersAreOptional}
        />
      ) : null}

      <FormControl
        id="downloadable"
        error={!!errors.downloadable}
        component="fieldset"
        fullWidth
      >
        <FormLabel component="legend">Document download</FormLabel>
        <FormHelperText>
          Allow investors to download their documents for offline reading.
        </FormHelperText>
        <RadioGroup
          aria-label="Completion message enabled"
          name="downloadable"
          value={form.downloadable}
          onChange={(e) =>
            setForm({
              ...form,
              downloadable: e.target.value === 'true',
            })
          }
        >
          <FormControlLabel
            value
            control={<Radio />}
            label={
              <div className={classes.recommendedLabel}>
                <Typography>Enabled</Typography>
                <Chip label="Recommended" size="small" variant="info" />
              </div>
            }
          />
          <FormControlLabel
            value={false}
            control={<Radio />}
            label={<Typography>Disabled</Typography>}
          />
        </RadioGroup>
        {errors.downloadable ? (
          <FormHelperText>{errors.downloadable}</FormHelperText>
        ) : null}
      </FormControl>

      <FormControl
        id="offline_signing"
        error={errors.disableOfflineSigning}
        component="fieldset"
        fullWidth
      >
        <FormLabel component="legend">Offline signing</FormLabel>
        <FormHelperText>
          Allow investors to print and sign offline at the end of the
          questionnaire.
        </FormHelperText>
        <RadioGroup
          aria-label="Offline signing enabled"
          name="disable_offline_signing"
          value={!form.disableOfflineSigning}
          onChange={(e) =>
            setForm({
              ...form,
              disableOfflineSigning: e.target.value === 'false',
            })
          }
        >
          <FormControlLabel
            value
            control={<Radio />}
            label={
              <div className={classes.recommendedLabel}>
                <Typography>Enabled</Typography>
                <Chip label="Recommended" size="small" variant="info" />
              </div>
            }
          />
          <FormControlLabel
            value={false}
            control={<Radio />}
            label={<Typography>Disabled</Typography>}
          />
        </RadioGroup>
        {errors.disableOfflineSigning ? (
          <FormHelperText>{errors.disableOfflineSigning}</FormHelperText>
        ) : null}
      </FormControl>
    </Modal>
  );
}
