/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import { Answers } from '../answers';
import { UNSAVED } from '../saved';

const useStyles = makeStyles((theme) => ({
  input: { margin: theme.spacing(1, 0) },
}));

export const NameRequiredTitleQuestion = ({
  updateLpDoc,
  setSaving,
  answer,
  label,
  QuestionStepper,
  QuestionPromptComponent,
}) => {
  const {
    firstName: initialFirstName,
    lastName: initialLastName,
    title: initialTitle,
  } = answer || { firstName: '', lastName: '', title: '' };

  const [firstName, setFirstName] = useState(initialFirstName);
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState(initialLastName);
  const [lastNameError, setLastNameError] = useState('');
  const [title, setTitle] = useState(initialTitle);
  const [titleError, setTitleError] = useState('');
  const classes = useStyles();
  const hasInput = Boolean(firstName) || Boolean(lastName) || Boolean(title);

  function handleSubmit(e) {
    e.preventDefault();

    setFirstNameError('');
    setLastNameError('');
    setTitleError('');

    return updateLpDoc({
      label,
      answer: { firstName, lastName, title },
      skipQuestion: !hasInput,
    }).catch((error) => {
      setSaving(UNSAVED);
      if (error.response?.status === 400) {
        setFirstNameError(error.response.data.answer.firstName);
        setLastNameError(error.response.data.answer.lastName);
        setTitleError(error.response.data.answer.title);
      }
      return error;
    });
  }

  function handleFirstNameChange(e) {
    setSaving(UNSAVED);
    setFirstName(e.target.value);
  }

  function handleLastNameChange(e) {
    setSaving(UNSAVED);
    setLastName(e.target.value);
  }

  function handleTitleChange(e) {
    setSaving(UNSAVED);
    setTitle(e.target.value);
  }

  // Reset the name when changing questions
  useEffect(() => {
    setFirstName(initialFirstName);
    setLastName(initialLastName);
    setTitle(initialTitle);
    setFirstNameError('');
    setLastNameError('');
    setTitleError('');
  }, [label]);

  return (
    <form onSubmit={handleSubmit} autoComplete="chrome-off">
      <QuestionPromptComponent short />
      <Answers tall>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <TextField
              id="firstName"
              autoFocus
              className={classes.input}
              label="First name"
              variant="outlined"
              value={firstName}
              error={!!firstNameError}
              helperText={firstNameError}
              fullWidth
              onChange={handleFirstNameChange}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              id="lastName"
              className={classes.input}
              label="Last name"
              variant="outlined"
              value={lastName}
              error={!!lastNameError}
              helperText={lastNameError}
              fullWidth
              onChange={handleLastNameChange}
            />
          </Grid>
        </Grid>

        <TextField
          id="title"
          className={classes.input}
          label="Title"
          variant="outlined"
          value={title}
          error={!!titleError}
          helperText={titleError}
          fullWidth
          onChange={handleTitleChange}
        />

        <QuestionStepper
          handleSubmit={handleSubmit}
          hasAnswerChanged={
            firstName !== initialFirstName ||
            lastName !== initialLastName ||
            title !== initialTitle
          }
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
};
