import React, { useState } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Menu, MenuItem, Button } from '@passthrough/uikit';
import TextRotationNoneIcon from '@material-ui/icons/TextRotationNone';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

export function AddButton({
  onAddQuestion,
  onAddSignature,
  onAddText,
  onAddSection,
  className,
}) {
  const options = [
    {
      text: 'Question',
      onClick: onAddQuestion,
      icon: <QuestionAnswerIcon fontSize="small" />,
    },
    {
      text: 'Signature',
      onClick: onAddSignature,
      icon: <VerticalAlignBottomIcon fontSize="small" />,
    },
    {
      text: 'Text',
      onClick: onAddText,
      icon: <TextRotationNoneIcon fontSize="small" />,
    },
    {
      text: 'Section',
      onClick: onAddSection,
      icon: <VerticalSplitIcon fontSize="small" />,
    },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl((el) => (el ? null : event.currentTarget));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <span className={className}>
      <Button
        endIcon={<ArrowDropDownIcon />}
        onClick={handleMenu}
        variant="primary"
      >
        Add
      </Button>

      <Menu
        id="menu-actions"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem
            onClick={option.onClick}
            key={option.text}
            icon={option.icon}
            text={option.text}
          />
        ))}
      </Menu>
    </span>
  );
}
