import React from 'react';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import IconButton from '@material-ui/core/IconButton';
import { Link, UIKitSettingsProvider } from '@passthrough/uikit';

export function MultiFileUploadDisplay({ files, onDelete }) {
  return files.map((file) => (
    <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
      <div key={file.fileId}>
        <Link variant="external" href={file.fileUrl}>
          {file.fileName}
        </Link>
        <IconButton
          aria-label="delete"
          onClick={() => {
            onDelete(file.fileId);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </UIKitSettingsProvider>
  ));
}
