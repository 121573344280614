import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Button } from 'components/button';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Divider from '@material-ui/core/Divider';

import { ProfilePage } from './profile_page';
import { Progress } from './progress';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(4, 10),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4, 2),
    },
  },
  newInvestmentText: {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export const ProfileCard = ({
  lpName,
  maxWidth,
  onBackClick,
  onContinueClick,
  onContinueDisabled,
  children,
  step,
  setStep,
  hideRoleStep = false,
  hideNewInvestmentText = false,
}) => {
  const classes = useStyles();

  return (
    <ProfilePage maxWidth={maxWidth}>
      <Progress step={step} setStep={setStep} hideRoleStep={hideRoleStep} />
      <Divider />
      <div className={classes.content}>
        {hideNewInvestmentText ? null : (
          <Typography className={classes.newInvestmentText}>
            New investment for {lpName}
          </Typography>
        )}
        <div>{children}</div>
        <div className={classes.footer}>
          <Button
            color="default"
            variant="text"
            startIcon={<KeyboardArrowLeftIcon />}
            onClick={onBackClick}
            style={{ visibility: onBackClick ? 'visible' : 'hidden' }}
          >
            Back
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={onContinueClick}
            disabled={onContinueDisabled}
            data-test={`continue-${step}`}
          >
            Continue
          </Button>
        </div>
      </div>
    </ProfilePage>
  );
};
