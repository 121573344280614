import React from 'react';
import { DocuSignDialog } from 'components/docusign_dialog';

const SignContext = React.createContext();

export const SignProvider = ({ markPendingDoc, onIdCheckFailed, children }) => {
  const [docuSign, setDocuSign] = React.useState({
    isOpen: false,
    docId: null,
    url: null,
    onSign: null,
    onClose: null,
  });

  const openDocuSign = ({ docId, url, onSign, onClose, ...extra }) => {
    setDocuSign({
      docId,
      isOpen: true,
      url,
      onSign,
      onClose: () => {
        onClose();
        setDocuSign({});
      },
      ...extra,
    });
  };

  function onSignFunctionWrapper(docId, onSign) {
    if (markPendingDoc && docId) {
      markPendingDoc(docId);
      onSign();
    } else {
      onSign();
    }
  }

  const openSigningDialog = ({ docId, url, onSign, onClose, ...extra }) => {
    if (url.includes('docusign')) {
      openDocuSign({
        docId,
        url,
        onSign: () => onSignFunctionWrapper(docId, onSign),
        onClose,
        ...extra,
      });
    }
  };

  return (
    <SignContext.Provider value={{ openSigningDialog }}>
      {docuSign.isOpen ? (
        <DocuSignDialog {...docuSign} onIdCheckFailed={onIdCheckFailed} />
      ) : null}
      {children}
    </SignContext.Provider>
  );
};

export const useSignContext = () => React.useContext(SignContext);
