import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export const CustomLogicCheckbox = ({ hasCustomLogic, setHasCustomLogic }) => (
  <FormControl>
    <FormControlLabel
      control={
        <Checkbox
          checked={hasCustomLogic || false}
          onChange={(e) => {
            setHasCustomLogic(e.target.checked);
          }}
          name="has-custom-logic"
        />
      }
      label="Needs custom logic"
    />
  </FormControl>
);
