import React from 'react';
import { Button } from 'components/button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import SelectionDragIcon from 'mdi-material-ui/SelectionDrag';

import { InputDiv } from './input_div';
import { BoxInput } from '../inputs/box_input';
import { ANSWER_TYPES_WITHOUT_PDF_LOCATIONS, boxTypes } from '../constants';
import { HellosignBoxUsageWarnings } from '../warnings/usage_warnings';

const useStyles = makeStyles((theme) => ({
  leftInput: {
    marginRight: theme.spacing(1),
  },
  cardContent: {
    padding: theme.spacing(1),
    paddingTop: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cardRoot: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
}));

export const OutputBoxes = ({
  highlightBox,
  setHighlightBoxId,
  replaceHellosignBox,
  deleteHellosignBox,
  setDragSelected,
  setPage,
  questions,
  handleClickItem,
  answerType,
  hellosignBoxes,
  handleAddHellosignBox,
  isCustomText,
  selectDocument,
}) => {
  const classes = useStyles();
  const outputBoxes = hellosignBoxes.filter(
    (box) => !box.choice && !box.field && !box.linkId,
  );
  const textboxType = !isCustomText ? boxTypes.TEXTBOX : boxTypes.CUSTOMTEXTBOX;

  return (
    <>
      {outputBoxes.map((box) => (
        <Card variant="outlined" className={classes.cardRoot} key={box.id}>
          <CardContent className={classes.cardContent}>
            <BoxInput
              key={box.id}
              box={box}
              onChange={replaceHellosignBox}
              onDelete={deleteHellosignBox}
              onSelectDrag={setDragSelected}
              setPage={setPage}
              highlight={
                highlightBox.id === box.id ? highlightBox.timestamp : null
              }
              setHighlightBoxId={setHighlightBoxId}
              questions={questions}
              handleClickItem={handleClickItem}
              isCustomText={isCustomText}
              isDateBoxInput={answerType === 'date'}
              selectDocument={selectDocument}
            />
          </CardContent>
        </Card>
      ))}
      {!ANSWER_TYPES_WITHOUT_PDF_LOCATIONS.includes(answerType) ? (
        <>
          <HellosignBoxUsageWarnings
            classes={classes}
            answerType={answerType}
            boxes={hellosignBoxes}
          />

          <InputDiv>
            <div>
              <Button
                className={classes.leftInput}
                startIcon={<SelectionDragIcon />}
                onClick={() => handleAddHellosignBox({ type: textboxType })}
              >
                Text
              </Button>
              {!isCustomText ? (
                <Button
                  startIcon={<SelectionDragIcon />}
                  onClick={() =>
                    handleAddHellosignBox({ type: boxTypes.CHECKBOX })
                  }
                >
                  Checkbox
                </Button>
              ) : null}
            </div>
          </InputDiv>
        </>
      ) : null}
    </>
  );
};
