import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Badge from '@material-ui/core/Badge';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';

const useStyles = makeStyles((theme) => ({
  threadIcon: {
    marginRight: theme.spacing(0.25),
  },
  actionableThreadIcon: {
    color: theme.palette.primary.main,
  },
  unconfirmedSectionThreadIcon: {
    color: theme.palette.primary.grey,
  },
  confirmedSectionThreadIcon: {
    color: theme.palette.primary.black,
  },
  selectedIcon: {
    color: theme.palette.primary.white,
  },
  selectedBadge: {
    '& .MuiBadge-dot': {
      backgroundColor: theme.palette.primary.white,
    },
  },
}));

export function StepperThreadIcon({
  isActiveThread,
  isSectionConfirmed,
  isSelected,
  IsDiligenceQuestion,
}) {
  const classes = useStyles();

  return (
    <Badge
      variant="dot"
      invisible={!isActiveThread}
      className={clsx({ [classes.selectedBadge]: isSelected })}
      color="primary"
    >
      <ChatOutlinedIcon
        className={clsx(classes.threadIcon, {
          [classes.selectedIcon]: isSelected,
          [classes.actionableThreadIcon]: isActiveThread,
          [classes.confirmedSectionThreadIcon]:
            !isActiveThread && (IsDiligenceQuestion || isSectionConfirmed),
          [classes.unconfirmedSectionThreadIcon]:
            !isActiveThread && !isSectionConfirmed,
        })}
        fontSize="small"
      />
    </Badge>
  );
}
