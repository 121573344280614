/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import clsx from 'clsx';
import { alpha, makeStyles } from '@material-ui/core/styles';
import MatButton from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import OpenInNewIcon from 'mdi-material-ui/OpenInNew';

const useStyles = makeStyles((theme) => ({
  xl: {
    fontSize: '1.3rem',
  },
  success: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  text: {
    fontWeight: '500',
  },
  square: {
    borderRadius: '8px',
  },
  thinOutline: {
    border: `thin solid ${theme.palette.primary.lightGrey}`,
    '&:hover': {
      border: `thin solid ${theme.palette.primary.lightGrey}`,
    },
  },
  skeleton: {
    backgroundColor: `${alpha(
      theme.palette.text.primary,
      theme.palette.type === 'light' ? 0.11 : 0.13,
    )} !important`,
    color: `${alpha(
      theme.palette.text.primary,
      theme.palette.type === 'light' ? 0.11 : 0.13,
    )} !important`,
    animation: '$skeleton 1.5s ease-in-out 0.5s infinite',
  },
  skeletonLabel: {
    visibility: 'hidden',
  },
  '@keyframes skeleton': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.4,
    },
    '100%': {
      opacity: 1,
    },
  },
}));

export function Button({
  size,
  square,
  skeleton,
  disabled,
  selected,
  loading,
  startIcon,
  color,
  className,
  variant,
  classes,
  innerRef,
  ...props
}) {
  const buttonClasses = useStyles();

  const matClassName = clsx(className, {
    [buttonClasses.success]: selected,
    [buttonClasses.xl]: size === 'xl',
    [buttonClasses.text]: variant === 'text',
    [buttonClasses.square]: square,
    [buttonClasses.skeleton]: skeleton,
  });

  const matSize = size === 'xl' ? 'large' : size;

  function getMatClasses() {
    if (skeleton) {
      return { ...classes, label: buttonClasses.skeletonLabel };
    }
    if (variant === 'outlined_thin') {
      return { ...classes, outlined: buttonClasses.thinOutline };
    }
    return classes;
  }

  function getMatColor() {
    if (variant === 'outlined_thin') {
      return 'default';
    }
    if (color) {
      return color;
    }
    return 'primary';
  }

  function getStartIcon() {
    if (loading) {
      return <CircularProgress size={20} />;
    }
    if (selected) {
      return <CheckIcon />;
    }
    return startIcon;
  }

  function getVariant() {
    if (disabled) {
      return 'contained';
    }
    if (selected) {
      return 'contained';
    }
    if (variant) {
      if (variant === 'outlined_thin') {
        return 'outlined';
      }
      return variant;
    }
    return 'outlined';
  }

  const matColor = getMatColor();
  const matStartIcon = getStartIcon();
  const matVariant = getVariant();
  const matClasses = getMatClasses();
  const matDisabled = disabled || loading || skeleton;

  return (
    <MatButton
      disableElevation
      {...props}
      disabled={matDisabled}
      startIcon={matStartIcon}
      size={matSize}
      className={matClassName}
      classes={matClasses}
      variant={matVariant}
      color={matColor}
      ref={innerRef}
    />
  );
}

export function LinkButton({ children, url, ...props }) {
  return (
    <Button
      color="default"
      onClick={() => {
        // https://stackoverflow.com/a/63627759
        // noopener and noreferrer are used to prevent giving the external URL
        // phishing power on our pages
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) {
          newWindow.opener = null;
        }
      }}
      endIcon={<OpenInNewIcon />}
      {...props}
    >
      {children}
    </Button>
  );
}
