import React from 'react';
import { Typography } from '@passthrough/uikit';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import { KeyValuePair } from 'components/key_value';
import MessageIcon from '@material-ui/icons/MessageOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
}));

export const EditStep = ({ message, pluralGrammar, setMessage }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography>
        The investor{pluralGrammar} will receive all executed documents in this
        email.
      </Typography>
      <KeyValuePair input icon={<MessageIcon />} disableGutters dense>
        <TextField
          id="message-to-investor"
          label="Include a message (Optional)"
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          variant="outlined"
          multiline
          fullWidth
          helperText={
            <Typography size="small">
              This message will appear in the email to investors.
            </Typography>
          }
          minRows={4}
        />
      </KeyValuePair>
    </div>
  );
};
