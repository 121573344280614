export const ANSWER_STATES = {
  UNCHANGED: 0,
  CHANGED_PRIOR_ANSWER: 1,
  CHANGED_CURRENT_ANSWER: 2,
};

export const DEFAULT_CURRENCY = {
  code: 'USD',
  symbol: '$',
};
