import React from 'react';
import { Button } from 'components/button';

export const RequestReviewButton = ({
  className,
  onRequestReview,
  isLoading,
}) => (
  <Button
    variant="contained"
    color="primary"
    className={className}
    size="xl"
    loading={isLoading}
    onClick={onRequestReview}
  >
    Request a review
  </Button>
);
