import React from 'react';
import { Typography, SpotIcon } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';
import ManageSearchIcon from 'mdi-material-ui/TextSearchVariant';
import { Button } from 'components/button';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: theme.spacing(2),
    padding: theme.spacing(6),
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: theme.spacing(1),
    textAlign: 'center',
  },
}));

export function EmptyState({
  Icon,
  iconVariant,
  title,
  text,
  ctaText,
  ctaIcon,
  ctaOnClick,
  ctaPermission,
  children,
}) {
  const classes = useStyles();
  const MatIcon = Icon || ManageSearchIcon;

  return (
    <div className={classes.root}>
      <SpotIcon size="medium" variant={iconVariant}>
        <MatIcon />
      </SpotIcon>

      <div className={classes.textContainer}>
        {title ? (
          <Typography variant="body" size="large" fontWeight={500}>
            {title}
          </Typography>
        ) : null}

        {text ? <Typography variant="body">{text}</Typography> : null}
      </div>

      {children}

      {ctaPermission ? (
        <Button
          data-test="cta"
          startIcon={ctaIcon}
          variant="contained"
          color="primary"
          onClick={ctaOnClick}
        >
          {ctaText}
        </Button>
      ) : null}
    </div>
  );
}
