import React from 'react';

import { Typography } from '@passthrough/uikit';
import { useWhiteLabelConfig } from 'services/providers/theme';
import { SettingsGroup, Setting } from 'components/settings_group';

import { BccRecipientTable } from './bcc_recipient_table';

export function ComplianceSettings({ bccRecipients, onEdit }) {
  const { productName } = useWhiteLabelConfig();
  return (
    <SettingsGroup header="Compliance" onEdit={onEdit}>
      <Setting
        label="BCC email recipients"
        description={
          <Typography variant="body" size="small" color="text.secondary">
            Add blind carbon copy (BCC) recipients to all outgoing emails sent
            from {productName}.
          </Typography>
        }
      >
        <BccRecipientTable recipientList={bccRecipients} />
      </Setting>
    </SettingsGroup>
  );
}
