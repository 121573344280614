import React from 'react';
import { Chip, ClickableChip } from '@passthrough/uikit';
import Tooltip from '@material-ui/core/Tooltip';

const INVESTOR_TAG_NAME_MAX_LENGTH = 20;

function getTruncatedChipName(name) {
  return `${name.slice(0, INVESTOR_TAG_NAME_MAX_LENGTH / 2)}...${name.slice(
    -INVESTOR_TAG_NAME_MAX_LENGTH / 2 + 2,
  )}`;
}

export function InvestorTagChip({
  labelStr,
  size = 'small',
  startIcon,
  onClick,
  // TODO - need to formally support deleteIcon and onDelete in uikit
  ...props
}) {
  const willTruncateLabel = labelStr?.length > INVESTOR_TAG_NAME_MAX_LENGTH;
  // empty string will not display a tooltip
  const tooltipLabel = willTruncateLabel ? labelStr : '';
  const displayLabel = willTruncateLabel
    ? getTruncatedChipName(labelStr)
    : labelStr;

  const InnerChipComponent = onClick ? (
    <ClickableChip size={size} label={displayLabel} filter onClick={onClick} />
  ) : (
    <Chip icon={startIcon} size={size} label={displayLabel} {...props} />
  );

  return (
    <Tooltip key={`${labelStr}-${Boolean(onClick)}`} arrow title={tooltipLabel}>
      <span>{InnerChipComponent}</span>
    </Tooltip>
  );
}
