import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { useFundReview } from 'services/providers/fund';
import { DataAndDocsDisplay } from './data_and_docs_display';
import { DisplayHeader } from './header/index';
import { AddNotesDisplay } from './add_notes_display';
import { ChildrenDisplay } from './children_display/index';
import { CAN_REVIEW_STATUSES, CAN_ADD_DRAFT_NOTE_STATUSES } from '../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2, 3, 2),
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(3),
  },
}));

export function OwnerDataApproval({
  fundName,
  jurisdiction,
  questionId,
  dataIsOutOfSync,
  hasSearch,
  hasInProgressAnswerChange,
  answerData,
  isApproved,
  numCommentEvents,
  hasUnresolvedInternalNote,
  commentThreadsResolved,
  isDiligenceApprover,
  currOwnerIndex,
  note,
  supplementaryFiles,
  allPotentialMatchesEvaluated,
  atLeastOneTrueMatch,
  atLeastOneUnsureMatch,
  numOwners,
  closingStatus,
  className,
  onLeftNavClick,
  onRightNavClick,
  handleOpenDocument,
  nodeIsReused,
  hideEditButtons,
  handleOpenDocsEditModal,
  handleOpenExtraDocsEditModal,
  handleOpenDetailsEditModal,
  handleOpenOwnersEditModal,
  setLocalNumApproved,
  dispatch,
  lastSubmittedAnswer,
  updated,
  nodeDataSettled,
  loadingUnresolvedThreads,
  docCollectionMode,
  adverseMediaEnabled,
  handleEnableAdverseMedia,
}) {
  const classes = useStyles();
  const [fundReview] = useFundReview();

  const cannotEnableAdverseMedia =
    dataIsOutOfSync ||
    hasInProgressAnswerChange ||
    !CAN_REVIEW_STATUSES.includes(closingStatus);

  const shouldHideApproval =
    !hasSearch || cannotEnableAdverseMedia || !isDiligenceApprover;

  const isRootNode = questionId === 'QD1';

  const shouldDisableApproval =
    Boolean(note) || !allPotentialMatchesEvaluated || !commentThreadsResolved;

  const canAddNotesToInvestor =
    fundReview && CAN_ADD_DRAFT_NOTE_STATUSES.includes(closingStatus);

  // When the investor type changes, the only difference we want to show is the
  // type change. We don't want to show the data that was previously submitted.
  const changedType =
    lastSubmittedAnswer && lastSubmittedAnswer.type !== answerData.type;

  return (
    <div className={clsx(classes.root, className)}>
      <DisplayHeader
        questionId={questionId}
        name={answerData?.name || ''}
        hasUnsentNote={Boolean(note)}
        numCommentEvents={numCommentEvents}
        isApproved={isApproved}
        commentThreadsResolved={commentThreadsResolved}
        hasUnresolvedInternalNote={hasUnresolvedInternalNote}
        currOwnerIndex={currOwnerIndex}
        atLeastOneTrueMatch={atLeastOneTrueMatch}
        atLeastOneUnsureMatch={atLeastOneUnsureMatch}
        numOwners={numOwners}
        dispatch={dispatch}
        shouldDisableApproval={shouldDisableApproval}
        shouldHideApproval={shouldHideApproval}
        onLeftNavClick={onLeftNavClick}
        onRightNavClick={onRightNavClick}
        setLocalNumApproved={setLocalNumApproved}
        updated={updated}
        nodeDataSettled={nodeDataSettled}
        loadingUnresolvedThreads={loadingUnresolvedThreads}
        docCollectionMode={docCollectionMode}
        adverseMediaEnabled={adverseMediaEnabled ?? false}
        isIndividual={answerData?.type === 'Individual'}
        handleEnableAdverseMedia={handleEnableAdverseMedia}
        cannotEnableAdverseMedia={cannotEnableAdverseMedia}
      />

      <DataAndDocsDisplay
        isRootNode={isRootNode}
        jurisdiction={jurisdiction}
        supplementaryFiles={supplementaryFiles}
        answerData={answerData}
        handleOpenDocument={handleOpenDocument}
        nodeIsReused={nodeIsReused}
        hideEditButtons={hideEditButtons}
        handleOpenDocsEditModal={handleOpenDocsEditModal}
        handleOpenExtraDocsEditModal={handleOpenExtraDocsEditModal}
        handleOpenDetailsEditModal={handleOpenDetailsEditModal}
        lastSubmittedAnswer={lastSubmittedAnswer}
        changedType={changedType}
      />

      <ChildrenDisplay
        jurisdiction={jurisdiction}
        answerData={answerData}
        lastSubmittedAnswer={lastSubmittedAnswer}
        hideEditButtons={hideEditButtons}
        changedType={changedType}
        handleOpenOwnersEditModal={handleOpenOwnersEditModal}
      />

      {dataIsOutOfSync ? null : (
        <AddNotesDisplay
          fundName={fundName}
          questionId={questionId}
          isApproved={isApproved}
          isReviewMode={canAddNotesToInvestor}
          note={note}
          dispatch={dispatch}
        />
      )}
    </div>
  );
}
