import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Button } from 'components/button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'components/dialog/index';

const useStyles = makeStyles((theme) => ({
  pauseButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

export function PauseDialog({
  open,
  isLoading,
  handleClose,
  handleClick,
  externalAccessPaused,
}) {
  const classes = useStyles();

  const title = externalAccessPaused
    ? 'Resume external access'
    : 'Pause external access';
  const description = externalAccessPaused
    ? 'All external users (including LPs and GPs) with permissions to this fund will have access.'
    : 'All external users (including LPs and GPs) with permissions to this fund will lose access.';
  const buttonText = externalAccessPaused ? 'Resume access' : 'Pause access';
  const buttonClassName = externalAccessPaused
    ? undefined
    : classes.pauseButton;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="pause-dialog-title"
      aria-describedby="pause-dialog-description"
    >
      <DialogTitle id="pause-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Typography id="pause-dialog-description">{description}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          fullWidth
          onClick={handleClick}
          loading={isLoading}
          className={buttonClassName}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
