import React from 'react';

import * as api from 'services/api';
import { GenericDataExportModal } from './generic_data_export_modal';

export function DiligenceDataExportModal({
  open,
  handleClose,
  investors,
  onExport,
}) {
  return (
    <GenericDataExportModal
      open={open}
      headerLabel="Export diligence data"
      handleClose={handleClose}
      investors={investors}
      onExport={onExport}
      generateExportEndpoint={api.exportDiligenceData}
    />
  );
}
