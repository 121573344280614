import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  chipRoot: {
    borderRadius: theme.spacing(0.5),
    height: theme.spacing(3),
    backgroundColor: theme.palette.primary.white,
    border: `1px solid ${theme.palette.primary.grey}`,
    '&:hover': {
      backgroundColor: theme.palette.greyHover,
    },
    '&:focus': {
      backgroundColor: theme.palette.greyHover,
    },
  },
  chipLabel: {
    padding: theme.spacing(0, 1),
  },
}));

export function MembersCell({ fund, skeleton, openMemberAccessDialog }) {
  const classes = useStyles();

  if (skeleton) {
    return <Skeleton width={100} height={40} />;
  }

  return (
    <Chip
      classes={{
        root: classes.chipRoot,
        label: classes.chipLabel,
      }}
      clickable
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        openMemberAccessDialog(fund);
      }}
      label={`${fund.teamMembers.length} member${
        fund.teamMembers.length === 1 ? '' : 's'
      }`}
      data-test={`team-members-${fund.id}`}
    />
  );
}
