import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { Chip as OldChip } from 'components/chip';
import { Chip, Icons } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(0.5),
    '& .MuiChip-label': {
      padding: theme.spacing(0, 1),
    },
  },
  isErrorColoring: {
    backgroundColor: theme.palette.changeRequest.background,
    color: theme.palette.changeRequest.text,
  },
  isWarningColoring: {
    backgroundColor: theme.palette.warning.background,
    color: theme.palette.warning.text,
  },
  isApprovedColoring: {
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.background,
  },
  isEmptyColoring: {
    color: theme.palette.primary.grey,
    backgroundColor: theme.palette.background.default,
  },
  isInfoColoring: {
    backgroundColor: theme.palette.info.background,
    color: theme.palette.info.main,
  },
}));

export const TextChipVariants = {
  APPROVED: 'approved',
  WARNING: 'warning',
  ERROR: 'error',
  EMPTY: 'empty',
  INFO: 'info',
};

export function TextChip({ label, icon, variant, className }) {
  const classes = useStyles();

  return (
    <OldChip
      label={label}
      icon={icon}
      size="small"
      className={clsx(classes.root, className, {
        [classes.isErrorColoring]: variant === TextChipVariants.ERROR,
        [classes.isWarningColoring]: variant === TextChipVariants.WARNING,
        [classes.isApprovedColoring]: variant === TextChipVariants.APPROVED,
        [classes.isEmptyColoring]: variant === TextChipVariants.EMPTY,
        [classes.isInfoColoring]: variant === TextChipVariants.INFO,
      })}
    />
  );
}

export function NotProvidedChip() {
  return <Chip label="Not provided" variant="neutral" />;
}

export function UpdatedChip() {
  return (
    <Chip
      label="Updated"
      size="small"
      icon={<Icons.FlagOutlined />}
      variant="info"
    />
  );
}

export function UnsentChip() {
  return (
    <Chip
      label="Unsent"
      size="small"
      icon={<Icons.ChatOutlined />}
      variant="info"
    />
  );
}

export function ResolvedThreadChip() {
  return (
    <Chip
      label="Resolved"
      size="small"
      icon={<Icons.ChatOutlined />}
      variant="neutral"
    />
  );
}
