/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { objectEquals } from 'services/utils';

import { Answers } from '../answers';
import { UNSAVED } from '../saved';

const useStyles = makeStyles((theme) => ({
  input: { margin: theme.spacing(1, 0) },
}));

const ContactInput = ({ id, label, data, errors, onChange, ...props }) => {
  const classes = useStyles();
  return (
    <TextField
      {...props}
      className={classes.input}
      id={id}
      label={label}
      variant="outlined"
      value={data[id]}
      error={!!errors[id]}
      helperText={errors[id]}
      fullWidth
      onChange={(e) => {
        onChange(e.target.value, id);
      }}
    />
  );
};

export const ContactNameAndEmail = ({
  updateLpDoc,
  setSaving,
  answer,
  label,
  QuestionStepper,
  QuestionPromptComponent,
}) => {
  const initialData = {
    firstName: answer?.firstName || '',
    lastName: answer?.lastName || '',
    email: answer?.email || '',
  };
  const initialErrors = {
    firstName: '',
    lastName: '',
    email: '',
  };
  const [data, setData] = React.useState(initialData);
  const [errors, setErrors] = React.useState(initialErrors);
  const resetErrors = () => setErrors(initialErrors);
  const resetData = () => setData(initialData);
  const hasInput =
    Boolean(data.firstName) || Boolean(data.lastName) || Boolean(data.email);

  const handleSubmit = (e) => {
    e.preventDefault();
    resetErrors();

    return updateLpDoc({ label, answer: data, skipQuestion: !hasInput }).catch(
      (error) => {
        setSaving(UNSAVED);
        if (error.response?.status === 400) {
          setErrors(error.response.data.answer);
        }
        return error;
      },
    );
  };

  const handleChange = (value, field) => {
    setSaving(UNSAVED);

    if (field === 'firstName') {
      setData({ ...data, firstName: value });
    } else if (field === 'lastName') {
      setData({ ...data, lastName: value });
    } else if (field === 'email') {
      setData({ ...data, email: value });
    }
  };

  // Reset the input when we change questions.
  React.useEffect(() => {
    resetData();
    resetErrors();
  }, [label]);

  return (
    <form onSubmit={handleSubmit} autoComplete="chrome-off">
      <QuestionPromptComponent short />
      <Answers tall>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <ContactInput
              id="firstName"
              label="First name"
              autoFocus
              data={data}
              errors={errors}
              onChange={handleChange}
            />
          </Grid>
          <Grid item sm={6}>
            <ContactInput
              id="lastName"
              label="Last name"
              data={data}
              errors={errors}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <ContactInput
          type="email"
          id="email"
          label="Email"
          data={data}
          errors={errors}
          onChange={handleChange}
        />

        <QuestionStepper
          handleSubmit={handleSubmit}
          hasAnswerChanged={!objectEquals(data, initialData)}
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
};
