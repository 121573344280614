export const INITIAL_STATE = {
  open: false,
  selectedDocId: null,
  currentMenu: '',
  pdf: null,
  page: 1,
  numPages: 0,
  zoom: 1,
  loading: true,
  linkId: null,
  currentQuestions: [],
};

export const DRAWER_CLOSE = 'close';
export const DRAWER_SELECT = 'select';
export const DRAWER_OPEN_LINK = 'openLink';
export const DRAWER_OPEN_MENU = 'openMenu';
export const DRAWER_CLOSE_MENU = 'closeMenu';
export const DRAWER_SET_PDF = 'setPdf';
export const DRAWER_SET_PAGE = 'setPage';
export const DRAWER_SET_ZOOM = 'setZoom';
export const DRAWER_TOGGLE = 'toggle';
export const CURRENT_QUESTIONS = 'currentQuestions';

function baseReducer(state, { type, payload }) {
  switch (type) {
    case DRAWER_CLOSE:
      return { ...state, open: false, linkId: null };
    case DRAWER_SELECT: {
      const { docId } = payload;
      return {
        ...state,
        selectedDocId: docId,
        page: 1,
        pdf: null,
      };
    }
    case DRAWER_OPEN_LINK: {
      const {
        docId = state.selectedDocId,
        page = state.page,
        linkId,
      } = payload;

      return {
        ...state,
        open: true,
        selectedDocId: docId,
        loading: true,
        page,
        linkId,
      };
    }
    case DRAWER_OPEN_MENU: {
      const { menu, anchor } = payload;
      return {
        ...state,
        currentMenu: menu,
        menuAnchor: anchor,
      };
    }
    case DRAWER_CLOSE_MENU:
      return { ...state, currentMenu: '' };
    case DRAWER_SET_PDF: {
      const { pdf } = payload;
      return {
        ...state,
        pdf,
        loading: false,
        numPages: pdf.numPages,
      };
    }
    case DRAWER_SET_PAGE: {
      const { page, docId = state.selectedDocId } = payload;
      return { ...state, page, selectedDocId: docId };
    }
    case DRAWER_SET_ZOOM: {
      const { zoom } = payload;
      return { ...state, zoom };
    }
    case DRAWER_TOGGLE: {
      const open = !state.open;
      return {
        ...state,
        open,
        loading: open,
        linkId: open ? state.linkId : null,
      };
    }
    case CURRENT_QUESTIONS: {
      const { currentQuestions } = payload;
      return { ...state, currentQuestions };
    }
    default:
      throw new Error('Invalid action type');
  }
}

export function drawerReducer(state, action) {
  const newState = baseReducer(state, action);

  if (state.selectedDocId && newState.selectedDocId !== state.selectedDocId) {
    return {
      ...newState,
      loading: true,
      linkId: null,
    };
  }
  return newState;
}
