import React from 'react';
import { Modal, Button } from '@passthrough/uikit';
import { FileList } from './file_list';

export const ModalView = ({
  open,
  files,
  onClose,
  onEdit,
  onDelete,
  onUpdateOrder,
  onAdd,
}) => (
  <Modal
    key="modal"
    open={open}
    onClose={onClose}
    size="sm"
    headerLabel="Manage files"
    footer={null}
  >
    <FileList
      files={files}
      onEdit={onEdit}
      onDelete={onDelete}
      onUpdateOrder={onUpdateOrder}
    />
    <div style={{ textAlign: 'center' }}>
      <Button variant="primary" onClick={onAdd}>
        Add file
      </Button>
    </div>
  </Modal>
);
