import React from 'react';
import { Typography, Link, UIKitSettingsProvider } from '@passthrough/uikit';

export function NotificationLearnMoreLink({ text }) {
  return (
    <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
      <Typography variant="label">
        Learn more about{' '}
        <Link
          inline
          variant="external"
          href="https://support.passthrough.com/en_us/fund-settings-page-notifications-rJveQ8xPA"
        >
          {text}
        </Link>
      </Typography>
    </UIKitSettingsProvider>
  );
}
