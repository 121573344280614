import React from 'react';
import { useHistory } from 'react-router-dom';

export function useRegionalAction() {
  const history = useHistory();
  const urlBuilder = useRegionalUrlBuilder();

  return React.useCallback((baseUrl, url) => {
    const { url: newUrl, isLocal } = urlBuilder(baseUrl, url);
    if (isLocal) {
      history.push(newUrl);
    } else {
      window.location.assign(newUrl);
    }
  }, []);
}

export function useRegionalUrlBuilder() {
  return React.useCallback((baseUrl, url) => {
    const currentUrl = new URL(window.location);
    const fullUrl = new URL(url, baseUrl || window.location.origin);
    if (currentUrl.host === fullUrl.host) {
      return { url, isLocal: true };
    }
    return { url: fullUrl.href, isLocal: false };
  }, []);
}
