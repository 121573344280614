import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';

import {
  DataValueDisplay,
  DataValueDisplayVariants,
} from 'components/data_value_display';
import { AssociatesDisplay } from './associates_display';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(1),
  },
  remainingDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(1),
  },
}));

export function MatchDataDisplay({
  associates,
  potentialMatchDataFields,
  showAllData,
}) {
  const classes = useStyles();

  const fieldKeys = Object.keys(potentialMatchDataFields);
  const firstFourDataKeys = fieldKeys.slice(0, 4);
  const remainingDataKeys = fieldKeys.slice(4);

  return (
    <div className={classes.root}>
      {associates.length > 0 ? (
        <DataValueDisplay
          label="Associates"
          value={<AssociatesDisplay associates={associates} />}
          variant={DataValueDisplayVariants.HORIZONTAL}
        />
      ) : null}

      {firstFourDataKeys.map((fieldKey) => (
        <DataValueDisplay
          key={fieldKey}
          label={fieldKey}
          value={potentialMatchDataFields[fieldKey].join(', ')}
          variant={DataValueDisplayVariants.HORIZONTAL}
        />
      ))}

      <Collapse in={showAllData} mountOnEnter unmountOnExit>
        <div className={classes.remainingDataContainer}>
          {remainingDataKeys.map((fieldKey) => (
            <DataValueDisplay
              key={fieldKey}
              label={fieldKey}
              value={potentialMatchDataFields[fieldKey].join(', ')}
              variant={DataValueDisplayVariants.HORIZONTAL}
            />
          ))}
        </div>
      </Collapse>
    </div>
  );
}
