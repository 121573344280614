import { objectEquals } from 'services/utils';
import {
  getDocumentFields,
  getOwnerFields,
  isDetailsSectionComplete,
  isDetailsSectionInProgress,
  isDocumentsSectionComplete,
  isDocumentsSectionInProgress,
  isOwnersSectionComplete,
  isOwnersSectionInProgress,
  hasOwnerDataForEachCategory,
} from '../utils';

export const TREE_ERROR = 'TREE_ERROR';
export const TREE_COMPLETE = 'TREE_COMPLETE';
export const TREE_REUSING = 'TREE_REUSING';
export const TREE_IN_PROGRESS = 'TREE_IN_PROGRESS';
export const TREE_OPT_OUT = 'TREE_OPT_OUT';

export const getOwners = (answer, jurisdiction) => {
  // Owners for a specific node returned in a format used for tree viewing
  const ownerFields = getOwnerFields(answer.type, jurisdiction);

  return ownerFields.reduce((prevState, ownerConfig) => {
    // Attach the label so we can use it to indicate what kind of children
    // the owners are (Trustee, Beneficiary, etc.)
    const ownersWithLabel = (answer[ownerConfig.key] || []).map((owner) => ({
      ...owner,
      singularLabel: ownerConfig.singularLabel,
    }));
    return prevState.concat(ownersWithLabel);
  }, []);
};

export function hasDataForEachKey(form, jurisdiction, isRootNode) {
  if (form?.idToReuse) {
    return true;
  }

  if (!form?.type) {
    return false;
  }

  const docFields = getDocumentFields(form.type, jurisdiction, isRootNode);
  const ownerFields = getOwnerFields(form.type, jurisdiction);

  const detailsComplete = isDetailsSectionComplete(
    form,
    jurisdiction,
    isRootNode,
  );
  const documentsComplete = isDocumentsSectionComplete(form, docFields);

  const hasOwnersForEachCategory = hasOwnerDataForEachCategory(
    form,
    ownerFields,
  );

  return detailsComplete && documentsComplete && hasOwnersForEachCategory;
}

// NOTE: we defined owners subsection completion as only requiring 1 child
// across all owner categories as some categories may never apply, ie
// owners with 25% or more stake
function aggregateNodeCompletionStates(
  form,
  jurisdiction,
  isRootNode,
  docOptOutMeansCompletion = false,
) {
  if (form?.idToReuse) {
    return true;
  }

  if (!form?.type) {
    return false;
  }

  const docFields = getDocumentFields(form.type, jurisdiction, isRootNode);
  const ownerFields = getOwnerFields(form.type, jurisdiction);

  const detailsComplete = isDetailsSectionComplete(
    form,
    jurisdiction,
    isRootNode,
  );
  const documentsComplete = isDocumentsSectionComplete(form, docFields);
  const documentsImplicitlyCompleteDueToOptOut =
    docOptOutMeansCompletion && form?.optOut;
  const ownersComplete = isOwnersSectionComplete(form, ownerFields);

  return (
    detailsComplete &&
    (documentsComplete || documentsImplicitlyCompleteDueToOptOut) &&
    ownersComplete
  );
}

export function isNodeComplete(form, jurisdiction, isRootNode) {
  return aggregateNodeCompletionStates(form, jurisdiction, isRootNode);
}

function isNodeCompleteWithDocsOptedOut(form, jurisdiction, isRootNode) {
  return aggregateNodeCompletionStates(form, jurisdiction, isRootNode, true);
}

export function isNodeInProgress(form, jurisdiction, isRootNode) {
  if (!form?.type) {
    return false;
  }
  const docFields = getDocumentFields(form.type, jurisdiction, isRootNode);
  const ownerFields = getOwnerFields(form.type, jurisdiction);

  const detailsComplete = isDetailsSectionComplete(
    form,
    jurisdiction,
    isRootNode,
  );
  const documentsComplete = isDocumentsSectionComplete(form, docFields);
  const ownersComplete = isOwnersSectionComplete(form, ownerFields);

  const detailsInProgress = isDetailsSectionInProgress(form, detailsComplete);
  const documentsInProgress = isDocumentsSectionInProgress(
    form,
    docFields,
    documentsComplete,
  );
  const ownersInProgress = isOwnersSectionInProgress(
    form,
    ownerFields,
    ownersComplete,
  );

  const selfNotCompleted = !(
    detailsComplete &&
    documentsComplete &&
    ownersComplete
  );
  const atLeastOneSectionInProgress =
    detailsInProgress || documentsInProgress || ownersInProgress;
  const atLeastOneSectionComplete =
    detailsComplete || documentsComplete || ownersComplete;

  return (
    selfNotCompleted &&
    (atLeastOneSectionInProgress || atLeastOneSectionComplete)
  );
}

const getLPSideTreeState = (question, jurisdiction, isRootNode, formErrors) => {
  const { answer } = question;

  const isReusingAnotherNode = Boolean(answer?.idToReuse);
  const isSelfComplete = isNodeComplete(answer, jurisdiction, isRootNode);
  const isSelfCompleteWithDocsOptedOut = isNodeCompleteWithDocsOptedOut(
    answer,
    jurisdiction,
    isRootNode,
  );
  const isSelfInProgress = isNodeInProgress(answer, jurisdiction, isRootNode);

  const hasErrors = Boolean(formErrors) && !objectEquals(formErrors, {});
  if (hasErrors) {
    return TREE_ERROR;
  }

  if (isReusingAnotherNode) {
    return TREE_REUSING;
  }
  if (isSelfComplete) {
    return TREE_COMPLETE;
  }
  if (isSelfCompleteWithDocsOptedOut) {
    return TREE_OPT_OUT;
  }
  if (isSelfInProgress) {
    return TREE_IN_PROGRESS;
  }
  return '';
};

const getGPSideTreeState = (question) => {
  if (question?.isApproved) {
    return TREE_COMPLETE;
  }

  return '';
};

export const getTreeState = (
  question,
  isGPSide = false,
  jurisdiction,
  isRootNode,
  formErrors,
) => {
  const canRender = question && question.answer;
  if (!canRender) {
    return '';
  }

  if (isGPSide) {
    return getGPSideTreeState(question);
  }
  return getLPSideTreeState(question, jurisdiction, isRootNode, formErrors);
};
