/* eslint-disable react/no-array-index-key */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Answers } from '../../answers';
import { SingleChoiceList, SingleChoiceFileItem, Actions } from '../common';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '500px',
  },
  description: {
    textAlign: 'center',
    fontSize: '0.8rem',
    marginBottom: theme.spacing(2),
  },
}));

export const FileQuestionReconciliation = ({
  lpDoc,
  activeProfile,
  QuestionPromptComponent,
  answersToReconcile,
  navRef,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const answer =
    value === null || value < 0 ? null : answersToReconcile[value]?.answer;
  return (
    <div className={classes.root}>
      <QuestionPromptComponent short />
      <FormHelperText className={classes.description}>
        Select an option to prefill your answer. You may make edits in the next
        step.
      </FormHelperText>
      <Answers>
        <SingleChoiceList
          value={value}
          onChange={(e) => {
            setValue(Number(e.target.value));
          }}
        >
          {answersToReconcile.map((answerToReconcile, idx) => (
            <SingleChoiceFileItem
              key={idx}
              value={idx}
              source={answerToReconcile.source}
              lpDoc={lpDoc}
              activeProfile={activeProfile}
              fileUrl={answerToReconcile?.answer?.fileUrl}
            >
              {answerToReconcile?.answer?.fileName}
            </SingleChoiceFileItem>
          ))}
        </SingleChoiceList>
        <Actions navRef={navRef} answer={answer} showSkip />
      </Answers>
    </div>
  );
};
