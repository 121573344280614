import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';

import { EXPOSED_SIDE_LETTER_TYPES } from 'components/document_upload/constants';
import { Modal } from '@passthrough/uikit';
import { useDiscardConfirmation } from 'services/discard';
import { objectEquals } from 'services/utils';

export function InvestorNotificationSettingsModal({
  open,
  onClose,
  initialState,
  errors,
  setErrors,
  updateSettings,
  loading,
}) {
  const [form, setForm] = useState(initialState);
  const closeWithConfirmation = useDiscardConfirmation({
    onConfirm: onClose,
    needConfirmation: open && !objectEquals(form, initialState),
  });

  return (
    <Modal
      open={open}
      onClose={closeWithConfirmation}
      headerLabel="Edit investor notifications"
      primaryButtonText="Save"
      primaryButtonLoading={loading}
      showCancelButton
      onEntering={() => {
        setForm(initialState);
        setErrors({});
      }}
      onSubmit={() => updateSettings(form)}
    >
      <FormControl
        error={!!errors.subdocCompleteEmail}
        component="fieldset"
        fullWidth
      >
        <FormLabel component="legend">Completion email</FormLabel>
        <RadioGroup
          aria-label="Completion email"
          name="completion-email"
          value={form.subdocCompleteEmail}
          onChange={() =>
            setForm({
              ...form,
              subdocCompleteEmail: !form.subdocCompleteEmail,
            })
          }
        >
          <FormControlLabel value control={<Radio />} label="Enabled" />
          <FormControlLabel
            value={false}
            control={<Radio />}
            label="Disabled"
          />
        </RadioGroup>
        {errors.subdocCompleteEmail ? (
          <FormHelperText>{errors.subdocCompleteEmail}</FormHelperText>
        ) : null}
      </FormControl>
      <FormControl
        error={!!errors.fullyExecutedEmail}
        component="fieldset"
        fullWidth
      >
        <FormLabel component="legend">Fully executed email</FormLabel>
        <RadioGroup
          aria-label="Fully executed email"
          name="fully-executed-email"
          value={form.fullyExecutedEmail}
          onChange={() =>
            setForm({
              ...form,
              fullyExecutedEmail: !form.fullyExecutedEmail,
            })
          }
        >
          <FormControlLabel value control={<Radio />} label="Enabled" />
          <FormControlLabel
            value={false}
            control={<Radio />}
            label="Disabled"
          />
        </RadioGroup>
        {errors.fullyExecutedEmail ? (
          <FormHelperText>{errors.fullyExecutedEmail}</FormHelperText>
        ) : null}
      </FormControl>
      <FormControl
        error={!!errors.documentTypesToInclude}
        component="fieldset"
        fullWidth
      >
        <FormLabel component="legend">
          Documents included for investors
        </FormLabel>
        {EXPOSED_SIDE_LETTER_TYPES.map(({ key, display }) => {
          const onChange = () => {
            const { documentTypesToInclude } = form;
            if (documentTypesToInclude.includes(key)) {
              setForm({
                ...form,
                documentTypesToInclude: documentTypesToInclude.filter(
                  (type) => type !== key,
                ),
              });
            } else {
              setForm({
                ...form,
                documentTypesToInclude: [...documentTypesToInclude, key],
              });
            }
          };
          return (
            <FormControlLabel
              key={key}
              control={
                <Checkbox
                  checked={form.documentTypesToInclude.includes(key)}
                  onChange={onChange}
                />
              }
              label={display}
            />
          );
        })}
        {errors.documentTypesToInclude ? (
          <FormHelperText>{errors.documentTypesToInclude}</FormHelperText>
        ) : null}
      </FormControl>
    </Modal>
  );
}
