import React, { useState } from 'react';
import { Button, Typography } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Popover } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';

import { objectEquals } from 'services/utils';
import { Chip } from 'components/chip';
import { FilterChips } from './filter_chips';
import { LpCustomTagGroup } from './lp_custom_tag_group';
import { LpClosingStatusGroup } from './lp_closing_status_group';
import { RiskRatingsGroup } from './risk_ratings_group';
import { Footer } from './footer';

const useStyles = makeStyles((theme) => ({
  btnLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing(1),
  },
  filterBtnAndChipContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(1),
  },
  modifyFiltersButtons: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.spacing(1),
    alignItems: 'center',
  },
  popoverRoot: {
    marginTop: theme.spacing(2),
  },
  paddingRoot: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
  allTags: {
    padding: theme.spacing(2, 2, 0, 2),
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.spacing(4),
    overflowY: 'auto',
    maxHeight: '450px',
  },
  statusTags: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
    width: '300px',
  },
  customTagsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
    width: '300px',
  },
}));

function countNumTags(tagGroups) {
  return tagGroups.reduce((acc, curr) => acc + curr.tags.length, 0);
}

export function LpFiltersPopOver({
  investorTagGroups,
  statusTags,
  riskRatingTags,
  filters,
  onChange,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [locallySelectedFilters, setLocallySelectedFilters] = useState(filters);

  const disableApplyFiltersBtn = objectEquals(locallySelectedFilters, filters);
  const showRiskRatingDerivedFilters = riskRatingTags?.length > 0;

  function removeAppliedFilter(filterVal) {
    onChange(filters.filter((f) => f.id !== filterVal.id));
  }

  function setLocallySelected(e, selectedVal, isChecked) {
    e.preventDefault();

    if (isChecked) {
      setLocallySelectedFilters(
        locallySelectedFilters.filter((f) => f.id !== selectedVal.id),
      );
    } else {
      setLocallySelectedFilters((f) => [...f, selectedVal]);
    }
  }

  return (
    <>
      <div className={classes.filterBtnAndChipContainer}>
        <div className={classes.modifyFiltersButtons}>
          <Button
            variant="secondary"
            startIcon={<FilterListIcon />}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          >
            <div className={classes.btnLabel}>
              Filter
              {filters?.length > 0 ? (
                <Chip color="primary" size="small" label={filters.length} />
              ) : null}
            </div>
          </Button>

          <Fade in={filters?.length > 0}>
            <Button
              variant="text"
              onClick={() => {
                onChange([]);
              }}
            >
              Clear filters
            </Button>
          </Fade>
        </div>

        <FilterChips filters={filters} handleDelete={removeAppliedFilter} />
      </div>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        TransitionProps={{
          onEntering: () => {
            // as filters can be altered through different parts
            // of the ui, need to reset as we open this component
            setLocallySelectedFilters(filters);
          },
        }}
        onClose={() => {
          setAnchorEl(null);
        }}
        className={classes.popoverRoot}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={classes.paddingRoot}>
          <div className={classes.allTags}>
            {investorTagGroups?.length > 0 ? (
              <div className={classes.customTagsContainer}>
                <div>
                  <Typography fontWeight={500}>Tags</Typography>

                  <Typography
                    variant="label"
                    size="medium"
                    color="text.secondary"
                  >
                    {countNumTags(investorTagGroups)} total
                  </Typography>
                </div>

                {investorTagGroups.map((tagGroup) => (
                  <LpCustomTagGroup
                    key={tagGroup.id}
                    tagGroupName={tagGroup.name}
                    tags={tagGroup.tags}
                    locallySelectedFilters={locallySelectedFilters}
                    setLocallySelected={setLocallySelected}
                  />
                ))}
              </div>
            ) : null}

            <div className={classes.statusTags}>
              {showRiskRatingDerivedFilters ? (
                <RiskRatingsGroup
                  riskRatingTags={riskRatingTags}
                  locallySelectedFilters={locallySelectedFilters}
                  setLocallySelected={setLocallySelected}
                />
              ) : null}

              <LpClosingStatusGroup
                statuses={statusTags}
                locallySelectedFilters={locallySelectedFilters}
                setLocallySelected={setLocallySelected}
              />
            </div>
          </div>

          <Footer
            numSelectedFilters={locallySelectedFilters?.length}
            disableApplyFiltersBtn={disableApplyFiltersBtn}
            handleCancel={() => {
              setLocallySelectedFilters(filters);
              setAnchorEl(null);
            }}
            handleApply={() => {
              onChange(locallySelectedFilters);
              setAnchorEl(null);
            }}
          />
        </div>
      </Popover>
    </>
  );
}
