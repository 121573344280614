import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

import { boxTypeList } from './box_type';

const useStyles = makeStyles((theme) => ({
  boxTypeIcon: {
    paddingRight: theme.spacing(2),
  },
  boxTypeItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  select: {
    minWidth: '200px',
    minHeight: '28px',
    padding: '14.5px',
  },
}));

export function BoxTypeSelect({ boxType, setBoxType }) {
  const classes = useStyles();

  const boxTypeItems = boxTypeList.map((b) => (
    <MenuItem key={b.type} value={b.type}>
      <div className={classes.boxTypeItem}>
        <div className={classes.boxTypeIcon}>{b.icon}</div>
        {b.text}
      </div>
    </MenuItem>
  ));

  return (
    <FormControl variant="outlined">
      <InputLabel id="box-type-select-label">Type</InputLabel>
      <Select
        label="Type"
        id="box-type-select"
        labelId="box-type-select"
        value={boxType}
        onChange={(e) => setBoxType(e.target.value)}
        variant="outlined"
        classes={{ root: classes.select }}
      >
        {boxTypeItems}
      </Select>
    </FormControl>
  );
}
