import React from 'react';
import { Modal, Typography } from '@passthrough/uikit';
import ArrowForward from '@material-ui/icons/ArrowForward';
import * as api from 'services/api';
import { useToast } from 'services/toast';
import { useMe } from 'services/providers/me';

export function SetUpProfileModal({ open, profile, onClose, refresh }) {
  const [me] = useMe();
  const [step, setStep] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const { toast, errorToast } = useToast();
  const step1Text = (
    <>
      <Typography>
        Are you the investor or their authorized representative?
      </Typography>
      <Typography>
        If you are neither, do not continue and ask them to complete the setup
        to use this profile.
      </Typography>
    </>
  );

  const step2Text = (
    <>
      <Typography>
        To start using this profile, you will be added as the first
        collaborator.
      </Typography>
      <Typography>
        Until you add another collaborator, you will be the only one with access
        to this profile.
      </Typography>
    </>
  );

  const handleClose = () => {
    if (!loading) {
      onClose();
    }
  };

  const onBack = () => {
    if (!loading) {
      setStep(1);
    }
  };

  const resetState = () => {
    setStep(1);
    setLoading(false);
  };

  const onSubmit = () => {
    setLoading(true);
    api
      .claimProfile({ id: profile.id })
      .then(() => {
        refresh();
        toast(`Added ${me.email} as a collaborator.`);
        onClose();
      })
      .catch(() => {
        errorToast('An unexpected error occurred.');
        setLoading(false);
      });
  };

  return (
    <Modal
      open={open}
      headerLabel="Set up profile"
      onClose={handleClose}
      showCancelButton={!loading}
      primaryButtonText={step === 1 ? 'Yes, continue' : 'Confirm'}
      primaryButtonEndIcon={step === 1 ? <ArrowForward /> : null}
      primaryButtonLoading={loading}
      onBack={step === 1 ? null : onBack}
      onEntering={resetState}
      onSubmit={() => {
        if (step === 1) {
          setStep(2);
        } else {
          onSubmit();
        }
      }}
    >
      {step === 1 ? step1Text : step2Text}
    </Modal>
  );
}
