import React, { useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { getSelectedStaffOrg, redirectIfDifferentDomain } from 'services/utils';
import { useMe } from 'services/providers/me';

import { TableRows } from './table_rows';
import { filterMyFunds, filterClosingStatus, filterByQuery } from './utils';
import { SearchInput } from './search_input';
import { CLOSING_FILTERS, FILTER_ALL, DEFAULT_COLUMNS } from './constants';

const useStyles = makeStyles((theme) => ({
  filtersContainer: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(2),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  fundCount: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: '.875rem',
  },
  helpText: {
    color: theme.palette.text.secondary,
  },
  closingFilter: {
    minWidth: '200px',
  },
}));

export function FundTable({
  funds,
  commitments,
  openClientMatterDialog = () => null,
  openMemberAccessDialog = () => null,
  columns = DEFAULT_COLUMNS,
  searchLabel = 'Fund or organization',
  enableAssignedToMeFilter = false,
}) {
  const classes = useStyles();
  const [me] = useMe();
  const [showMyFunds, setShowMyFunds] = useState(false);
  const [closingFilter, setClosingFilter] = useState(FILTER_ALL);
  const [throttledSearchQuery, setThrottledSearchQuery] = useState('');
  const [clearSearch, setClearSearch] = useState(0);
  const selectedStaffOrg = getSelectedStaffOrg(me);

  const filteredFunds = useMemo(() => {
    if (funds) {
      let updatedFilteredFunds = [...funds];
      if (enableAssignedToMeFilter) {
        updatedFilteredFunds = filterMyFunds(showMyFunds, funds);
      }
      updatedFilteredFunds = filterClosingStatus(
        closingFilter,
        updatedFilteredFunds,
      );
      if (throttledSearchQuery !== '') {
        updatedFilteredFunds = filterByQuery(
          throttledSearchQuery,
          updatedFilteredFunds,
        );
      }
      return updatedFilteredFunds;
    }
    return null;
  }, [
    closingFilter,
    showMyFunds,
    throttledSearchQuery,
    funds,
    enableAssignedToMeFilter,
  ]);

  const clearFilters = () => {
    setShowMyFunds(false);
    setClosingFilter(FILTER_ALL);
    setClearSearch((prev) => prev + 1);
  };

  const skeleton = filteredFunds === null;

  React.useEffect(() => {
    if (selectedStaffOrg && me?.isStaff && funds?.length) {
      const orgFunds = funds.filter(
        (f) => f.organizationId === selectedStaffOrg,
      );

      if (orgFunds.length > 0) {
        redirectIfDifferentDomain(orgFunds[0].baseUrl);
      }
    }
  }, [me, selectedStaffOrg]);

  return (
    <>
      <div className={classes.filtersContainer}>
        <form className={classes.filters} role="search">
          <Typography className={classes.helpText}>Filter by</Typography>
          <div>
            <SearchInput
              label={searchLabel}
              setThrottledSearchQuery={setThrottledSearchQuery}
              clearSearch={clearSearch}
            />
          </div>
          <FormControl
            variant="outlined"
            className={classes.closingFilter}
            size="small"
          >
            <Select
              value={closingFilter}
              onChange={(e) => {
                setClosingFilter(e.target.value);
              }}
            >
              {CLOSING_FILTERS.map(({ key, display }) => (
                <MenuItem key={key} value={key}>
                  {display}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {enableAssignedToMeFilter ? (
            <FormControlLabel
              control={
                <Switch
                  data-test="my-funds-switch"
                  checked={showMyFunds}
                  onClick={() => {
                    setShowMyFunds((prev) => !prev);
                  }}
                />
              }
              label="Funds assigned to me"
            />
          ) : null}
        </form>
        {skeleton ? null : (
          <Typography className={classes.fundCount}>
            {`${filteredFunds.length} total`}
          </Typography>
        )}
      </div>
      <TableRows
        unsortedFunds={filteredFunds}
        commitments={commitments}
        clearFilters={clearFilters}
        openClientMatterDialog={openClientMatterDialog}
        openMemberAccessDialog={openMemberAccessDialog}
        columnIds={columns}
        skeleton={skeleton}
        alwaysLinkToFund={!enableAssignedToMeFilter}
      />
    </>
  );
}
