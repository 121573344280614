import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { Button } from 'components/button';
import { ABAInput } from 'components/aba';
import {
  getDefaultEmptyAddress,
  Address,
  objectEqualsEmptyAddress,
} from 'components/address_v2';
import { DisplayLink } from 'components/link';
import { SubsectionHeading } from 'components/subsection_heading';
import { SubsectionBody } from 'components/subsection_body';
import { objectEquals } from 'services/utils';
import { Answers } from '../answers';
import { UNSAVED } from '../saved';

const useStyles = makeStyles((theme) => ({
  input: {
    margin: theme.spacing(1, 0),
  },
  helperText: {
    marginTop: '-4px',
  },
  radio: {
    display: 'flex',
    flexDirection: 'row',
  },
  radioForm: {
    display: 'flex',
    paddingLeft: theme.spacing(1),
    margin: theme.spacing(1, 0),
  },
  addSectionLink: {
    fontWeight: '500',
    margin: theme.spacing(2, 0),
  },
  closeSectionButton: {
    padding: theme.spacing(0, 1),
  },
}));

function parseInitialAddress(answer, defaultEmptyAddress) {
  const initialAddress = {
    ...defaultEmptyAddress,
    ...((answer && answer.googleAddress) || {}),
    ...((answer && answer.manualAddress) || {}),
  };

  return initialAddress;
}

export function BankV2Question({
  updateLpDoc,
  setSaving,
  answer,
  label,
  QuestionStepper,
  QuestionPromptComponent,
}) {
  const classes = useStyles();

  const initialBankName = (answer && answer.bank.bankName) || '';
  const initialAba = (answer && answer.bank.aba) || '';
  const initialSwift = (answer && answer.bank.swift) || '';
  const initialIban = (answer && answer.bank.iban) || '';
  const initialAccountName = (answer && answer.bank.accountName) || '';
  const initialAccountNumber = (answer && answer.bank.accountNumber) || '';
  const initialFfctAccountName = (answer && answer.bank.ffctAccountName) || '';
  const initialFfctAccountNumber =
    (answer && answer.bank.ffctAccountNumber) || '';
  const initialTransmissionNotes =
    (answer && answer.bank.transmissionNotes) || '';
  const { emptyAddress } = getDefaultEmptyAddress();
  const initialAddress = parseInitialAddress(answer, emptyAddress);

  const [bankName, setBankName] = useState(initialBankName);
  const [aba, setAba] = useState(initialAba);
  const [swift, setSwift] = useState(initialSwift);
  const [iban, setIban] = useState(initialIban);
  const [accountName, setAccountName] = useState(initialAccountName);
  const [accountNumber, setAccountNumber] = useState(initialAccountNumber);
  const [ffctAccountNumber, setFfctAccountNumber] = useState(
    initialFfctAccountNumber,
  );
  const [ffctAccountName, setFfctAccountName] = useState(
    initialFfctAccountName,
  );
  const [transmissionNotes, setTransmissionNotes] = useState(
    initialTransmissionNotes,
  );
  const [address, setAddress] = useState(initialAddress);

  const [bankNameError, setBankNameError] = useState('');
  const [abaError, setAbaError] = useState('');
  const [swiftError, setSwiftError] = useState('');
  const [ibanError, setIbanError] = useState('');
  const [accountNameError, setAccountNameError] = useState('');
  const [accountNumberError, setAccountNumberError] = useState('');
  const [ffctAccountNumberError, setFfctAccountNumberError] = useState('');
  const [ffctAccountNameError, setFfctAccountNameError] = useState('');
  const [transmissionNotesError, setTransmissionNotesError] = useState('');
  const [addressErrors, setAddressErrors] = useState({});

  const [showSwift, setShowSwift] = useState(initialSwift ? 'yes' : 'no');
  const [showTransmissionNotes, setShowTransmissionNotes] = useState(
    initialTransmissionNotes,
  );
  const [showFfctSection, setShowFfctSection] = useState(
    !!ffctAccountNumber || !!ffctAccountName,
  );

  const hasInput =
    Boolean(bankName) ||
    Boolean(aba) ||
    Boolean(swift) ||
    Boolean(iban) ||
    Boolean(accountName) ||
    Boolean(accountNumber) ||
    Boolean(ffctAccountNumber) ||
    Boolean(ffctAccountName) ||
    Boolean(transmissionNotes) ||
    !objectEqualsEmptyAddress(address);

  function handleSubmit(e) {
    e.preventDefault();

    setBankNameError('');
    setAbaError('');
    setSwiftError('');
    setIbanError('');
    setAccountNameError('');
    setAccountNumberError('');
    setFfctAccountNumberError('');
    setFfctAccountNameError('');
    setTransmissionNotesError('');
    setAddressErrors({});

    const props = {
      bank: {
        bankName,
        accountName,
        accountNumber,
      },
    };

    if (transmissionNotes && showTransmissionNotes) {
      props.bank.transmissionNotes = transmissionNotes;
    }

    if (showSwift === 'yes') {
      props.bank.swift = swift;
      if (iban) {
        props.bank.iban = iban;
      }
    } else {
      props.bank.aba = aba;
    }

    if (showFfctSection) {
      props.bank.ffctAccountNumber = ffctAccountNumber;
      props.bank.ffctAccountName = ffctAccountName;
    }

    const { street, city, state, country, postalCode } = address.line1;

    props.manualAddress = {
      line1: {
        street,
        city,
        state,
        country,
        postalCode,
      },
      line2: address.line2,
    };

    return updateLpDoc({ label, answer: props, skipQuestion: !hasInput }).catch(
      (error) => {
        setSaving(UNSAVED);
        if (error.response?.status === 400) {
          const bankErrors = error.response.data.answer.bank;
          setBankNameError(bankErrors?.bankName);
          setAbaError(bankErrors?.aba);
          setIbanError(bankErrors?.iban);
          setSwiftError(bankErrors?.swift);
          setAccountNameError(bankErrors?.accountName);
          setAccountNumberError(bankErrors?.accountNumber);
          setTransmissionNotesError(bankErrors?.transmissionNotes);
          setFfctAccountNameError(bankErrors?.ffctAccountName);
          setFfctAccountNumberError(bankErrors?.ffctAccountNumber);
          if (error.response.data.answer.googleAddress) {
            setAddressErrors(error.response.data.answer.googleAddress);
          } else if (error.response.data.answer.manualAddress) {
            setAddressErrors(error.response.data.answer.manualAddress);
          }
        }
        return error;
      },
    );
  }

  function handleChange(e, field) {
    setSaving(UNSAVED);

    if (field === 'bankName') {
      setBankName(e.target.value);
    } else if (field === 'aba') {
      setAba(e.target.value);
    } else if (field === 'swift') {
      setSwift(e.target.value);
    } else if (field === 'iban') {
      setIban(e.target.value);
    } else if (field === 'accountName') {
      setAccountName(e.target.value);
    } else if (field === 'accountNumber') {
      setAccountNumber(e.target.value);
    } else if (field === 'ffctAccountNumber') {
      setFfctAccountNumber(e.target.value);
    } else if (field === 'ffctAccountName') {
      setFfctAccountName(e.target.value);
    } else if (field === 'transmissionNotes') {
      setTransmissionNotes(e.target.value);
    } else if (field === 'address') {
      setAddress(e);
    }
  }

  // Reset the input when we change questions.
  useEffect(() => {
    setBankName(initialBankName);
    setAba(initialAba);
    setSwift(initialSwift);
    setIban(initialIban);
    setAccountName(initialAccountName);
    setAccountNumber(initialAccountNumber);
    setFfctAccountNumber(initialFfctAccountNumber);
    setFfctAccountName(initialFfctAccountName);
    setTransmissionNotes(initialTransmissionNotes);
    setAddress(initialAddress);

    setBankNameError('');
    setAbaError('');
    setSwiftError('');
    setIbanError('');
    setAccountNameError('');
    setAccountNumberError('');
    setFfctAccountNameError('');
    setFfctAccountNumberError('');
    setTransmissionNotesError('');
    setAddressErrors({});

    setShowSwift(swift ? 'yes' : 'no');
    setShowTransmissionNotes(!!initialTransmissionNotes);
    setShowFfctSection(!!initialFfctAccountNumber || !!initialFfctAccountName);
  }, [label]);

  return (
    <form onSubmit={handleSubmit}>
      <QuestionPromptComponent short />
      <Answers tall>
        <TextField
          className={classes.input}
          id="bankName"
          autoFocus
          label="Bank name"
          variant="outlined"
          value={bankName}
          error={!!bankNameError}
          helperText={bankNameError}
          onChange={(e) => {
            handleChange(e, 'bankName');
          }}
          fullWidth
        />

        <FormControl component="fieldset" className={classes.radioForm}>
          <RadioGroup
            aria-label="aba or swift"
            className={classes.radio}
            value={showSwift}
            onChange={(e) => {
              setShowSwift(e.target.value);
            }}
          >
            <FormControlLabel
              value="no"
              control={<Radio />}
              label="ABA (Routing number)"
            />
            <FormControlLabel
              value="yes"
              control={<Radio />}
              label="SWIFT code and IBAN"
            />
          </RadioGroup>
        </FormControl>

        {showSwift === 'yes' ? (
          <>
            <TextField
              className={classes.input}
              id="swift"
              label="SWIFT code"
              variant="outlined"
              value={swift}
              error={!!swiftError}
              helperText={swiftError}
              onChange={(e) => {
                handleChange(e, 'swift');
              }}
              fullWidth
            />
            <TextField
              className={classes.input}
              id="iban"
              label="IBAN (Optional)"
              variant="outlined"
              value={iban}
              error={!!ibanError}
              helperText={ibanError}
              onChange={(e) => {
                handleChange(e, 'iban');
              }}
              fullWidth
              inputProps={{ maxLength: 34 }}
            />
          </>
        ) : (
          <>
            <TextField
              className={classes.input}
              id="aba"
              label="ABA (Routing number)"
              variant="outlined"
              value={aba}
              error={!!abaError}
              helperText={abaError}
              onChange={(e) => {
                handleChange(e, 'aba');
              }}
              InputProps={{
                inputComponent: ABAInput,
              }}
              fullWidth
            />
          </>
        )}

        <SubsectionHeading headerText="Account" />
        <SubsectionBody>
          <TextField
            className={classes.input}
            id="accountNumber"
            label="Account number"
            variant="outlined"
            value={accountNumber}
            error={!!accountNumberError}
            helperText={accountNumberError}
            onChange={(e) => {
              handleChange(e, 'accountNumber');
            }}
            fullWidth
          />
          <TextField
            className={classes.input}
            id="accountName"
            label="Account name"
            variant="outlined"
            value={accountName}
            error={!!accountNameError}
            helperText={accountNameError}
            onChange={(e) => {
              handleChange(e, 'accountName');
            }}
            fullWidth
          />
          <FormHelperText className={classes.helperText}>
            The name under which the account is held. This typically matches the
            legal name of the investor.
          </FormHelperText>
        </SubsectionBody>

        <SubsectionHeading headerText="Account address" />
        <SubsectionBody
          helperText={
            <>
              The account holder’s address on file with the bank to verify the
              ownership of this account. Not the address of the bank.
            </>
          }
        >
          <Address
            address={address}
            onChange={(value) => {
              handleChange(value, 'address');
            }}
            errors={addressErrors}
            label="Account address"
            hideSmartToggle
          />
        </SubsectionBody>

        {showTransmissionNotes ? (
          <>
            <SubsectionHeading
              headerText="Transmission notes"
              headerAction={
                <Button
                  onClick={() => {
                    setShowTransmissionNotes(false);
                  }}
                  variant="text"
                  className={classes.closeSectionButton}
                  startIcon={<CloseIcon />}
                >
                  Remove
                </Button>
              }
            />
            <SubsectionBody>
              <TextField
                className={classes.input}
                id="transmissionNotes"
                label="Transmission notes (Optional)"
                variant="outlined"
                value={transmissionNotes}
                error={!!transmissionNotesError}
                helperText={transmissionNotesError}
                onChange={(e) => {
                  handleChange(e, 'transmissionNotes');
                }}
                fullWidth
                multiline
                minRows={2}
              />
            </SubsectionBody>
          </>
        ) : (
          <DisplayLink
            onClick={() => {
              setShowTransmissionNotes(true);
            }}
          >
            <Typography align="center" className={classes.addSectionLink}>
              Add transmission notes
            </Typography>
          </DisplayLink>
        )}

        {showFfctSection ? (
          <>
            <SubsectionHeading
              headerText="For further credit to"
              headerAction={
                <Button
                  onClick={() => {
                    setShowFfctSection(false);
                  }}
                  variant="text"
                  className={classes.closeSectionButton}
                  startIcon={<CloseIcon />}
                >
                  Remove
                </Button>
              }
            />
            <SubsectionBody helperText="Not required for most bank accounts. It may be required if you are using a brokerage or transferring through an intermediary bank account.">
              <TextField
                className={classes.input}
                id="ffctAccountNumber"
                label="Account number"
                variant="outlined"
                value={ffctAccountNumber}
                error={!!ffctAccountNumberError}
                helperText={ffctAccountNumberError}
                onChange={(e) => {
                  handleChange(e, 'ffctAccountNumber');
                }}
                fullWidth
              />
              <TextField
                className={classes.input}
                id="ffctAccountName"
                label="Account name"
                variant="outlined"
                value={ffctAccountName}
                error={!!ffctAccountNameError}
                helperText={ffctAccountNameError}
                onChange={(e) => {
                  handleChange(e, 'ffctAccountName');
                }}
                fullWidth
              />
            </SubsectionBody>
          </>
        ) : (
          <DisplayLink
            onClick={() => {
              setShowFfctSection(true);
            }}
          >
            <Typography align="center" className={classes.addSectionLink}>
              Add for further credit to
            </Typography>
          </DisplayLink>
        )}

        <QuestionStepper
          hasAnswerChanged={
            bankName !== initialBankName ||
            aba !== initialAba ||
            swift !== initialSwift ||
            iban !== initialIban ||
            accountName !== initialAccountName ||
            accountNumber !== initialAccountNumber ||
            ffctAccountName !== initialFfctAccountName ||
            ffctAccountNumber !== initialFfctAccountNumber ||
            transmissionNotes !== initialTransmissionNotes ||
            !objectEquals(address, initialAddress)
          }
          handleSubmit={handleSubmit}
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
}
