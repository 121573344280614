/* eslint-disable no-restricted-syntax */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import { Alert, Typography, Button } from '@passthrough/uikit';

import { useFundEdit, useFundReview } from 'services/providers/fund';
import { DocumentSigningItem } from 'components/document_signing_item_v2/index';
import { PageContainer } from 'components/page_container';
import { QuestionPanel } from './question_panel';
import { UploadDocumentDialog } from './upload_document_dialog';
import { commentModeLpClosingStatuses } from './constants';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: theme.spacing(3),
  },
  list: {
    padding: 0,
  },
  documentsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
  fileUploadsHeader: {
    display: 'flex',
    justifyContent: 'start',
  },
}));

const DOCUMENT_QUESTIONS = [
  'AnswerType.file_upload',
  'AnswerType.multi_file_upload',
];

export function DocumentsTab({
  fundName,
  closing,
  lpClosing,
  onChange,
  changeEventsPerQuestion,
  priorAnswersPerQuestion,
  enableAnswerFormatting,
  openDocumentUploadPage,
}) {
  const { lpClosingId } = useParams();

  const [loadingId, setLoadingId] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  // It takes a bit before the hellosign callback can tell the backend
  // that we have signed. So we remember separately here.
  const [alreadySigned, setAlreadySigned] = useState([]);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [uploadDialogDoc, setUploadDialogDoc] = useState(null);
  const [fundEdit] = useFundEdit();
  const [fundReview] = useFundReview();
  const classes = useStyles();

  const isSignedStatus = ['SIGNED', 'PARTIALLY_SIGNED'].includes(
    lpClosing.status,
  );

  const isReviewMode = fundReview && isSignedStatus;
  const inCommentMode =
    fundReview && commentModeLpClosingStatuses.includes(lpClosing.status);

  const isDraft = lpClosing.status === 'DRAFT';

  const sections = lpClosing.sections
    .map((s) => ({ ...s, questions: s.questions.filter((q) => q.isAsked) }))
    .filter((s) => s.questions.length > 0);

  function lpDocHasAtLeastOneSignature(doc) {
    let isDocSigned = false;

    for (const signer of doc.signers) {
      isDocSigned = isDocSigned || signer.signed;
    }
    return isDocSigned;
  }

  const documentQuestions = [];
  for (const section of sections) {
    for (const question of section.questions) {
      if (DOCUMENT_QUESTIONS.includes(question.answerType)) {
        documentQuestions.push(question);
      }
    }
  }

  function handleSign(doc) {
    setAlreadySigned((a) => [...a, doc.id]);
    onChange();
  }

  function canRemoveSignaturesFromDoc(doc) {
    return !doc.isOffline && fundEdit && lpDocHasAtLeastOneSignature(doc);
  }

  return (
    <PageContainer maxWidth="lg">
      <div className={classes.section}>
        <div className={classes.documentsHeader}>
          <Typography variant="card-heading" size="large" color="text.primary">
            Documents
          </Typography>
          {fundEdit ? (
            <Button
              variant="secondary"
              onClick={openDocumentUploadPage}
              htmlProps={{ 'data-test': 'add-document-btn' }}
            >
              Add document
            </Button>
          ) : null}
        </div>
        {errorMsg ? <Alert severity="error">{errorMsg}</Alert> : null}
        <Paper className={classes.paper} variant="outlined">
          <List className={classes.list}>
            {lpClosing.docs.map((doc, index) => (
              <React.Fragment key={doc.id}>
                {index !== 0 ? <Divider /> : null}

                <DocumentSigningItem
                  name={doc.name}
                  type={doc.doctype}
                  documentTypesToExclude={lpClosing.documentTypesToExclude}
                  mustCountersign={doc.mustCountersign}
                  isPdfReady={doc.isPdfReady && !alreadySigned.includes(doc.id)}
                  isOffline={doc.isOffline}
                  signers={doc.signers}
                  lpDocId={doc.id}
                  lpClosingId={lpClosingId}
                  isLoading={doc.id === loadingId}
                  setLoading={(l) => {
                    setLoadingId(l ? doc.id : null);
                  }}
                  handleSign={() => handleSign(doc)}
                  canUpload={(doc.canUploadOffline || doc.isSubdoc) && !isDraft}
                  handleUpload={() => {
                    setUploadDialogDoc(doc);
                    setUploadDialogOpen(true);
                  }}
                  canRemoveDoc={fundEdit}
                  disabled={!!loadingId}
                  alreadySigned={alreadySigned.includes(doc.id)}
                  setErrorMsg={setErrorMsg}
                  canRemoveSignatures={canRemoveSignaturesFromDoc(doc)}
                  isReviewMode={isReviewMode}
                  sideletterId={doc.sideletterId}
                  offeringDocumentId={doc.offeringDocumentId}
                  onChange={onChange}
                  removeDocFromAlreadySigned={() =>
                    setAlreadySigned((a) => a.filter((id) => id !== doc.id))
                  }
                  hasOnlineCountersigners={
                    doc.hasOnlineCountersigners ||
                    alreadySigned.includes(doc.id)
                  }
                />
              </React.Fragment>
            ))}
          </List>
        </Paper>
      </div>
      <div className={classes.section}>
        <div className={classes.fileUploadsHeader}>
          {documentQuestions.length > 0 ? (
            <Typography
              variant="card-heading"
              size="large"
              color="text.primary"
            >
              Questions with file uploads
            </Typography>
          ) : null}
        </div>
        <div>
          {documentQuestions.map((question) => (
            <QuestionPanel
              fundName={fundName}
              key={question.label}
              priorAnswer={priorAnswersPerQuestion[question.label]}
              enableAnswerFormatting={enableAnswerFormatting}
              changeEvents={changeEventsPerQuestion[question.label]}
              question={question}
              onChange={onChange}
              isReviewMode={isReviewMode || inCommentMode}
              showQuestionAndAnswer
            />
          ))}
        </div>
        <UploadDocumentDialog
          open={uploadDialogOpen}
          handleClose={() => {
            setUploadDialogOpen(false);
          }}
          lpDoc={uploadDialogDoc}
          lpClosing={lpClosing}
          closing={closing}
          onChange={onChange}
          onExited={() => {
            setUploadDialogDoc(null);
          }}
        />
      </div>
    </PageContainer>
  );
}
