import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { Button } from 'components/button';
import { Chip } from 'components/chip';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  titleAndNumContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  titleBox: {
    fontSize: '1.25rem',
    marginRight: theme.spacing(1),
  },
  greyText: {
    color: theme.palette.primary.grey,
  },
}));

export function HeaderWithSeeMoreButton({
  title,
  numHits,
  shouldExposeButton,
  showAll,
  onClick,
  className,
}) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.titleAndNumContainer}>
        <Typography className={classes.titleBox}>{title}</Typography>

        <Chip label={numHits} size="small" />
      </div>

      {shouldExposeButton ? (
        <Button
          variant="text"
          onClick={() => {
            onClick();
          }}
        >
          {showAll ? 'View less' : 'View all'}
        </Button>
      ) : null}
    </div>
  );
}
