import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useQuestionnaire } from 'services/providers/questionnaire';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    margin: theme.spacing(10),
  },
  icon: {
    width: '80px',
    height: '97px',
    margin: theme.spacing(2),
  },
}));

export function Signed({ text }) {
  const classes = useStyles();
  const { pLogoUrl } = useQuestionnaire();

  return (
    <div className={classes.root}>
      <img src={pLogoUrl} className={classes.icon} alt="" />
      <Typography variant="h5" align="center" gutterBottom>
        All done!
      </Typography>

      {text ? (
        <Typography align="center" gutterBottom>
          {text}
        </Typography>
      ) : null}
    </div>
  );
}
