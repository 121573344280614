import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Radio from '@material-ui/core/Radio';

import { Typography } from '@passthrough/uikit';
import { ProgressBar } from './progress_bar';
import { TypographyHighlight } from '../../../typography_highlight';

export const Item = ({
  question,
  potentialMatch,
  setPotentialMatch,
  active,
}) => (
  <ListItem
    key={potentialMatch.id}
    button
    onClick={() => setPotentialMatch(potentialMatch)}
  >
    <ListItemIcon>
      <Radio checked={active} />
    </ListItemIcon>
    <ListItemText>
      <Typography variant="card-heading" size="small">
        {potentialMatch.id}{' '}
        <Typography variant="label" color="text.secondary">
          {potentialMatch.question.answerType}
        </Typography>
      </Typography>
      <TypographyHighlight
        highlight={question.question}
        text={potentialMatch.question.question}
      />
      <ProgressBar value={potentialMatch.score} />
      {!potentialMatch.compatible ? (
        <Typography variant="body2" color="error">
          Not compatible
        </Typography>
      ) : null}
    </ListItemText>
  </ListItem>
);
