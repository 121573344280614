import React from 'react';
import TextField from '@material-ui/core/TextField';

import { useInvestorTaggingModalState } from './providers/state_provider';
import { useInvestorTaggingMutations } from './providers/mutations_provider';
import { INVESTOR_TAGGING_REDUCER_ACTIONS } from './constants';

export function EditTagGroupModalContents() {
  const { newTagGroupName, dispatch } = useInvestorTaggingModalState();
  const { errorMsg } = useInvestorTaggingMutations();

  return (
    <>
      <TextField
        variant="outlined"
        type="text"
        label="Group"
        value={newTagGroupName}
        error={errorMsg}
        helperText={errorMsg}
        onChange={(e) => {
          dispatch({
            type: INVESTOR_TAGGING_REDUCER_ACTIONS.EDIT_TAG_GROUP_NAME,
            newTagGroupName: e.target.value,
          });
        }}
      />
    </>
  );
}
