import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Icons } from '@passthrough/uikit';
import * as api from 'services/api';
import { isUuid } from './utils';
import { PROVIDERS } from './constants';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  input: {
    backgroundColor: 'white',
  },
  submit: {
    marginTop: theme.spacing(3),
  },
}));

export function SignInEnterEmailStep({
  email,
  errorComponent,
  onChange,
  onConfirm,
  onPasswordRequired,
  onTOTPRequired,
  onSMSRequired,
  onSamlRequired,
  onSocialAuthEnabled,
}) {
  const classes = useStyles();
  const [emailError, setEmailError] = useState('');
  const [loading, setLoading] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    api
      .canUseSocialAuth({ email })
      .then((response) => {
        const { provider } = response.data;
        const hasSocialProvider = PROVIDERS[provider] !== undefined;

        if (hasSocialProvider) {
          // They have the option to use social authentication
          onSocialAuthEnabled(response.data);
        } else {
          // They can't use social authentication, so we continue with the regular flow
          api
            .loginWithEmail({ email })
            .then((emailResponse) => {
              if (emailResponse.status === 204) {
                setLoading(false);
                onConfirm();
              } else if (emailResponse.status === 200) {
                if (emailResponse.data === 'password required') {
                  setLoading(false);
                  onPasswordRequired();
                } else if (emailResponse.data === 'totp required') {
                  setLoading(false);
                  onTOTPRequired();
                } else if (emailResponse.data === 'sms required') {
                  setLoading(false);
                  onSMSRequired();
                } else if (isUuid(emailResponse.data)) {
                  onSamlRequired(emailResponse.data);
                }
              }
            })
            .catch((error) => {
              setLoading(false);
              if (error.response?.status === 400) {
                setEmailError(error.response.data.email);
              } else if (error.response?.status === 429) {
                setEmailError(
                  'Too many sign in attempts. Please wait a few minutes before trying again.',
                );
              }
            });
        }
      })
      .catch(() => {});
  }

  return (
    <>
      {errorComponent}
      <form
        className={classes.form}
        onSubmit={(e) => handleSubmit(e)}
        noValidate
      >
        <TextField
          InputProps={{
            className: classes.input,
          }}
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          autoFocus
          error={!!emailError}
          helperText={emailError}
          value={email}
          onChange={(e) => onChange(e.target.value)}
          disabled={loading}
        />
        <div className={classes.submit}>
          <Button
            type="submit"
            fullWidth
            variant="primary"
            endIcon={<Icons.ArrowForward />}
            loading={loading}
            disabled={loading}
            htmlProps={{ style: { maxWidth: '100%' } }}
          >
            Next
          </Button>
        </div>
      </form>
    </>
  );
}
