import React from 'react';
import { Chip } from '@passthrough/uikit';
import { NOT_REUSABLE } from './constants';

export const QuestionChips = ({ tags }) => (
  <span>
    {tags.map((tag) => (
      <Chip
        key={tag}
        label={tag}
        size="small"
        variant={NOT_REUSABLE.includes(tag) ? 'error' : 'success'}
      />
    ))}
  </span>
);
