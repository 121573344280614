import React from 'react';
import { Modal, Button } from '@passthrough/uikit';
import { ShowCaseBox, ComponentShowCase, ShowCaseBoxRow } from '../show_case';

export const ModalShowCase = () => {
  const [open, setOpen] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  return (
    <ComponentShowCase title="Modal">
      <ShowCaseBox>
        <ShowCaseBoxRow>
          <Button variant="primary" onClick={() => setOpen(true)}>
            Open
          </Button>
          <Modal open={open} onClose={() => setOpen(false)} />
          <Modal
            open={open}
            headerLabel="Header"
            subheaderLabel="This is a subheader"
            onClose={() => setOpen(false)}
            showCancelButton
            primaryButtonText="Approve"
            primaryButtonLoading={loading}
            onSubmit={() => {
              setLoading(true);
              setTimeout(() => {
                setLoading(false);
              }, 1000);
            }}
          >
            This is the body.
          </Modal>
        </ShowCaseBoxRow>
      </ShowCaseBox>
    </ComponentShowCase>
  );
};
