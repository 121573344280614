import React from 'react';
import { Alert, Modal, Typography } from '@passthrough/uikit';
import { useToast } from 'services/toast';
import * as api from 'services/api';
import { CopyValueButton } from '../copy_value_button';

const getHeaderLabel = (variant, apiKey, card) => {
  if (variant === 'regenerate' && !apiKey) {
    return 'Regenerate API Key?';
  }
  return `Connect to ${card.name}`;
};

const getPrimaryButtonText = (variant) => {
  if (variant === 'regenerate') {
    return 'Regenerate API key';
  }
  return 'Generate API key';
};

export const IntegrationCardDialog = ({
  open,
  onClose,
  onConfirm,
  organizationId,
  card,
  variant,
}) => {
  const [apiKey, setApiKey] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const { toast, errorToast } = useToast();

  const generateApiKey = () => {
    setLoading(true);
    api
      .enableIntegrationCard({ organizationId, cardId: card.id })
      .then((res) => {
        setApiKey(res.data.key);
        toast('API key generated.');
        onConfirm();
        setLoading(false);
      })
      .catch(() => {
        errorToast('Failed to create API key.');
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (open) {
      setLoading(false);
      setApiKey(null);
    }
  }, [open]);

  return (
    <Modal
      size="sm"
      open={open}
      onClose={onClose}
      headerLabel={getHeaderLabel(variant, apiKey, card)}
      onSubmit={generateApiKey}
      primaryButtonText={getPrimaryButtonText(variant)}
      primaryButtonLoading={loading}
      showCancelButton
      cancel
      footer={apiKey ? null : undefined}
    >
      {apiKey ? (
        <>
          <Typography>{card.copyKeyInstruction}</Typography>
          <Alert severity="warning">
            You will only see this API key once. If you lose this key, you can
            generate a new one.
          </Alert>
          <div>
            <CopyValueButton value={apiKey} />
          </div>
          <Typography variant="label" color="text.secondary">
            By placing your API key on third-party systems that you choose to
            integrate with Passthrough, you authorize Passthrough to share your
            customer data with the relevant integration partner.
          </Typography>
        </>
      ) : null}

      {!apiKey && variant === 'connect' ? (
        <>
          <Typography>{card.generateInstruction}</Typography>
        </>
      ) : null}

      {!apiKey && variant === 'regenerate' ? (
        <>
          <Typography>
            The existing API key will be deleted and you will need to generate a
            new API key for the integration to work properly.
          </Typography>
          <Typography>This action cannot be undone.</Typography>
        </>
      ) : null}
    </Modal>
  );
};
