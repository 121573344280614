import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useMe } from 'services/providers/me';
import { QuestionTags } from './tags';
import { StaffCompareSubdocPage } from './compare_subdoc';
import { GIDs } from './gids';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  nav: {
    margin: `-${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3, 3, 0, 3),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const TABS = {
  tags: QuestionTags,
  subdocs: StaffCompareSubdocPage,
  gids: GIDs,
};

export function QuestionTagsPage() {
  const classes = useStyles();
  const [tab, setTab] = useState('gids');
  const [me] = useMe();
  const TabComponent = TABS[tab];
  return (
    <div>
      <div className={classes.title}>
        <Typography variant="h5">Tagging center</Typography>
      </div>

      {me.canAccessQuestionTags ? (
        <>
          <div className={classes.nav}>
            <Tabs
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, value) => setTab(value)}
              variant="fullWidth"
            >
              <Tab label="Manage GIDs" value="gids" />
              <Tab label="Manage tags" value="tags" />
              <Tab label="Compare Subscription documents" value="subdocs" />
            </Tabs>
          </div>
          <Container component="main" maxWidth="lg">
            <TabComponent />
          </Container>
        </>
      ) : (
        <StaffCompareSubdocPage />
      )}
    </div>
  );
}
