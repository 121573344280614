import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import { useSupport } from 'components/support';
import { SendLPComment } from './send_lp_comment';
import { InitialDisplay } from './initial_display';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    zIndex: 2,
  },
  helpDisplay: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    minWidth: '350px',
  },
  closeButton: {
    alignSelf: 'end',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
}));

const INITIAL_HELP_DISPLAY = 0;
const SEND_LP_COMMENT_DISPLAY = 1;

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export function HelpDisplay({
  showMenu,
  questionId,
  fundName,
  isDiligenceQuestion,
  anchor,
  sendLPComment,
  handleClose,
  openExistingThread,
}) {
  const [draftNote, setDraftNote] = useState('');
  const [step, setStep] = useState(INITIAL_HELP_DISPLAY);
  const classes = useStyles();
  const { setHasDraftComment } = useSupport();

  const headerText =
    step === INITIAL_HELP_DISPLAY
      ? 'I need help with...'
      : 'Start a comment thread';

  useEffect(() => {
    setHasDraftComment(Boolean(draftNote));

    if (draftNote) {
      window.onbeforeunload = () => true;
    }

    return () => {
      window.onbeforeunload = null;
      setHasDraftComment(false);
    };
  }, [Boolean(draftNote)]);

  const handleClickComment = () => {
    if (openExistingThread) {
      openExistingThread();
      scrollToTop();
      handleClose();
    } else {
      setStep(SEND_LP_COMMENT_DISPLAY);
    }
  };

  useEffect(() => {
    // moving between questions should reset the help display
    handleClose();
    setStep(INITIAL_HELP_DISPLAY);
    setDraftNote('');
  }, [questionId]);

  return (
    <Popper
      open={showMenu}
      anchorEl={anchor?.current}
      disablePortal
      onClose={handleClose}
      placement="top-end"
      className={classes.root}
    >
      <Paper className={classes.helpDisplay} elevation={3}>
        <div className={classes.header}>
          <Typography>{headerText}</Typography>

          <IconButton
            onClick={handleClose}
            size="small"
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </div>

        {step === INITIAL_HELP_DISPLAY ? (
          <InitialDisplay
            onSigningQuestion={!questionId}
            handleClickComment={() => {
              handleClickComment();
            }}
            handleClose={handleClose}
          />
        ) : null}

        {step === SEND_LP_COMMENT_DISPLAY ? (
          <SendLPComment
            draftNote={draftNote}
            setDraftNote={setDraftNote}
            questionId={questionId}
            isDiligenceQuestion={isDiligenceQuestion}
            fundName={fundName}
            sendLPComment={sendLPComment}
            handleClose={() => {
              handleClose();
              setStep(INITIAL_HELP_DISPLAY);
            }}
            handleGoBack={() => {
              setStep(INITIAL_HELP_DISPLAY);
            }}
          />
        ) : null}
      </Paper>
    </Popper>
  );
}
