import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  groupQuestionnaireEventsByTzAwareDate,
  hasLPAnswerChanged,
} from 'services/utils';
import { QUESTIONNAIRE_EVENT_TYPES } from 'services/thread_utils/constants';
import { doesThreadExist, isThreadResolved } from 'services/thread_utils';
import { EventsPerDateDisplay } from './events_per_date_display';
import { ThreadResolutionButtons } from './thread_resolution_buttons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(3),
  },
  errorText: {
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
  },
  eventGroupContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(3),
  },
}));

function markUnchangedAnswerEvents(changeEvents) {
  let priorAnswer = null;

  for (let i = 0; i < changeEvents.length; i += 1) {
    const event = changeEvents[i];

    if (
      event.type === QUESTIONNAIRE_EVENT_TYPES.ANSWER_SUBMITTED ||
      event.type === QUESTIONNAIRE_EVENT_TYPES.ANSWER_PREFILLED
    ) {
      if (priorAnswer) {
        const questionAnswerData = { answer: event.answer };
        event.isAnswerUnchanged = !hasLPAnswerChanged(
          priorAnswer,
          questionAnswerData,
        );
      }

      priorAnswer = event.answer;
    }
  }

  return changeEvents;
}

export function QuestionnaireActivityTimeline({
  fundName,
  question,
  handleResolveThread,
  handleReopenThread,
  isLoading,
  canInteract,
  events = [],
}) {
  const classes = useStyles();

  const annotatedChangeEvents = markUnchangedAnswerEvents(events);

  const { orderedDates, eventsByDate } = groupQuestionnaireEventsByTzAwareDate(
    annotatedChangeEvents,
  );
  const threadExists = doesThreadExist(events);
  const isResolved = isThreadResolved(events);

  return (
    <div className={classes.root}>
      {threadExists && canInteract ? (
        <ThreadResolutionButtons
          isResolved={isResolved}
          loading={isLoading}
          handleResolveThread={handleResolveThread}
          handleReopenThread={handleReopenThread}
        />
      ) : null}

      <div className={classes.eventGroupContainer}>
        {orderedDates.map((date) => (
          <EventsPerDateDisplay
            key={date}
            fundName={fundName}
            question={question}
            dateStr={date}
            events={eventsByDate[date]}
          />
        ))}
      </div>
    </div>
  );
}
