import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@passthrough/uikit';

import { Button } from 'components/button';
import { PaperHeader } from 'components/paper_header';
import { DraftNoteDisplay } from 'pages/review_v2/draft_note_display';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
  changeContent: {
    padding: theme.spacing(3),
  },
  panelActions: {
    justifyContent: 'flex-end',
    display: 'flex',
    columnGap: theme.spacing(2),
  },
  savedNoteSpacing: {
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(3),
  },
}));

function ReadOnlyNoteDisplay({ note }) {
  const classes = useStyles();

  return (
    <PaperHeader headerText="Note to investor">
      <div className={classes.changeContent}>
        <Typography variant="body" size="small">
          {note}
        </Typography>
      </div>
    </PaperHeader>
  );
}

function ActionButtons({
  onSave,
  onCancel,
  isLoading,
  disableSave,
  showCancel,
}) {
  const classes = useStyles();

  return (
    <div className={classes.panelActions}>
      {showCancel ? (
        <Button variant="text" color="primary" onClick={onCancel}>
          Cancel
        </Button>
      ) : null}

      <Button
        aria-label="save_note"
        variant="contained"
        loading={isLoading}
        onClick={onSave}
        disabled={disableSave}
        data-test="save_note"
      >
        Save
      </Button>
    </div>
  );
}

export function NoteBox({
  fundName,
  isReviewMode,
  note,
  canAddNote,
  isLoading = false,
  onDelete,
  onSave,
  hideSavedNoteIcon,
  showSavedNoteLabel,
  showDivider,
}) {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(canAddNote && !note);
  const [draftNote, setDraftNote] = useState(note || '');

  useEffect(() => {
    setIsEditing(canAddNote && !note);
    setDraftNote(note || '');
  }, [note, canAddNote]);

  useEffect(() => {
    if (draftNote && isEditing) {
      window.onbeforeunload = () => true;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [Boolean(draftNote), isEditing]);

  return (
    <div className={classes.root}>
      {!isReviewMode && note ? <ReadOnlyNoteDisplay note={note} /> : null}

      {isReviewMode && note && !isEditing ? (
        <DraftNoteDisplay
          fundName={fundName}
          note={note}
          handleEdit={() => {
            setIsEditing(true);
          }}
          handleDelete={() => {
            setDraftNote('');
            onDelete();
          }}
          hideIcon={hideSavedNoteIcon}
          showLabel={showSavedNoteLabel}
          className={clsx({ [classes.savedNoteSpacing]: showDivider })}
          isLoading={isLoading}
        />
      ) : null}

      {isEditing ? (
        <>
          <TextField
            data-test="note_to_investor"
            inputProps={{
              'aria-label': 'note_to_investor',
            }}
            style={{ backgroundColor: 'white' }}
            value={draftNote}
            onChange={(e) => {
              setDraftNote(e.target.value);
              e.stopPropagation();
            }}
            variant="outlined"
            multiline
            minRows={4}
            fullWidth
            label="Comment to investor"
          />

          <ActionButtons
            onSave={() => {
              setIsEditing(false);
              onSave(draftNote);
            }}
            onCancel={() => {
              setIsEditing(false);
            }}
            isLoading={isLoading}
            disableSave={draftNote === note || draftNote === ''}
            showCancel={Boolean(note)}
          />
        </>
      ) : null}
    </div>
  );
}
