import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';

import * as api from 'services/api';

import { Alert, Button, Typography } from '@passthrough/uikit';
import { RadioCardChoice, RadioCardContainer } from 'components/radio_card';
import { getSingleErrorFromResponse } from 'services/utils';
import { NewQuestionnaireDialog } from './profile/profile_select_flow/new_questionnaire_dialog';
import { ACCESS_LEVEL_MAPPING } from './profile/constants';
import { ProfilePage } from './profile/profile_select_flow/profile_page';

const USE_PREFILL = 'USE_PREFILL';
const START_WITH_BLANK = 'START_WITH_BLANK';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(4, 10),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4, 2),
    },
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  headerSection: {
    marginBottom: theme.spacing(2),
  },
  lowFontWeightHeading: {
    fontWeight: 400,
  },
  footer: {
    display: 'flex',
    justifyContent: 'right',
  },
}));

export function FundPrefillFlow({
  lpClosingId,
  lpName,
  legalName,
  organizationName,
  refresh,
}) {
  const classes = useStyles();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [usePrefill, setUsePrefill] = useState('');
  const [newQuestionnaireDialogOpen, setNewQuestionnaireDialogOpen] =
    useState(false);

  const clearErrorAndSetUsePrefill = (value) => {
    setError('');
    setUsePrefill(value);
  };

  function saveSetupProfile(collaborators) {
    const remappedCollaborators = collaborators.map((c) => ({
      ...c,
      accessLevel: ACCESS_LEVEL_MAPPING[c.accessLevel],
    }));

    setIsSubmitting(true);
    api
      .setupLpClosingProfile({
        lpClosingId,
        profileId: null,
        collaborators: remappedCollaborators,
        restarting: usePrefill === START_WITH_BLANK,
        shouldBecomeOwner: false,
      })
      .then(() => {
        refresh();
      })
      .catch((e) => {
        if (e.response?.status === 400) {
          setError(getSingleErrorFromResponse(e.response));
        }
        setIsSubmitting(false);
        setNewQuestionnaireDialogOpen(false);
      });
  }

  const onSubmit = () => {
    setIsSubmitting(true);
    api
      .getLpClosingProfileSetupUsers({
        lpClosingId,
        setupProfileId: null,
        shouldBecomeOwner: false,
      })
      .then((response) => {
        saveSetupProfile(response.data.collaborators);
      })
      .catch(() => {
        setIsSubmitting(false);
      });
  };

  const onContinue = () => {
    if ([USE_PREFILL, START_WITH_BLANK].includes(usePrefill)) {
      setNewQuestionnaireDialogOpen(true);
    } else {
      setError('You must select an option to continue.');
    }
  };

  return (
    <>
      <ProfilePage maxWidth="md">
        <div className={classes.content}>
          <div>
            <Typography variant="label" color="text.secondary">
              New investment for {lpName}
            </Typography>
          </div>
          <div className={classes.headerSection}>
            <Typography variant="section-heading">
              {lpName}{' '}
              <span className={classes.lowFontWeightHeading}>
                has been invited to invest.
              </span>
            </Typography>
          </div>
          <Alert severity="info">
            {organizationName} has prefilled your questionnaire to save you time
          </Alert>
          {error ? <Alert severity="error">{error}</Alert> : null}
          <RadioCardContainer
            fullWidth
            selectedChoice={usePrefill}
            setSelectedChoice={clearErrorAndSetUsePrefill}
          >
            <RadioCardChoice
              choiceKey={USE_PREFILL}
              choiceValue={USE_PREFILL}
              choiceBackground="white"
              choiceTitle={`Prefill for ${legalName || lpName}`}
            />
            <RadioCardChoice
              choiceKey={START_WITH_BLANK}
              choiceValue={START_WITH_BLANK}
              choiceBackground="white"
              choiceTitle="Start with a blank questionnaire"
            />
          </RadioCardContainer>
          <div className={classes.footer}>
            <Button
              variant="primary"
              size="large"
              onClick={onContinue}
              loading={isSubmitting}
            >
              Continue
            </Button>
          </div>
        </div>
      </ProfilePage>
      <NewQuestionnaireDialog
        open={newQuestionnaireDialogOpen}
        onClose={() => {
          setNewQuestionnaireDialogOpen(false);
        }}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        organizationName={organizationName}
        usingPrefilledAnswers={usePrefill === USE_PREFILL}
      />
    </>
  );
}
