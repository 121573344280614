import React from 'react';

import { Typography, Link, UIKitSettingsProvider } from '@passthrough/uikit';
import { BooleanValue } from 'components/boolean_value';
import { SettingsGroup, Setting } from 'components/settings_group';

import { REQUEST_REVIEW_VALUES, REQUEST_REVIEW_DISABLED } from './constants';

export function QuestionnaireSettings({ lpRequestReview, onEdit }) {
  const { label } = REQUEST_REVIEW_VALUES.find(
    (value) => value.value === lpRequestReview,
  );

  const requestReviewDescription = (
    <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
      <Typography variant="body" size="small" color="text.secondary">
        Require or allow investors to{' '}
        <Link
          inline
          variant="external"
          href="https://support.passthrough.com/en_us/requiring-lps-to-request-a-review-H1JRZi0fp"
        >
          request a review
        </Link>{' '}
        before signing.
      </Typography>
    </UIKitSettingsProvider>
  );

  return (
    <SettingsGroup internal header="Questionnaire" onEdit={onEdit}>
      <Setting label="Request a review" description={requestReviewDescription}>
        <BooleanValue
          label={label}
          bool={lpRequestReview !== REQUEST_REVIEW_DISABLED}
        />
      </Setting>
    </SettingsGroup>
  );
}
