import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { HeaderWithCount } from 'components/header_with_count_v2';
import { ReviewCard } from '../review_card/index';

const useStyles = makeStyles((theme) => ({
  bottomSpacing: {
    marginBottom: theme.spacing(2.5),
  },
}));

export function ApprovedCardsReview({
  approvedEntries,
  onShowReviewClick,
  className,
  areSearchesReady,
  isInProgress,
}) {
  const classes = useStyles();

  return (
    <div className={className}>
      <HeaderWithCount
        title="Reviewed"
        count={approvedEntries.length}
        className={classes.bottomSpacing}
      />

      <List disablePadding>
        {approvedEntries.map((question) => (
          <ListItem disableGutters key={question.label}>
            <ReviewCard
              question={question}
              onShowReviewClick={onShowReviewClick}
              areSearchesReady={areSearchesReady}
              isInProgress={isInProgress}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
}
