import React from 'react';

import { UIKitSettingsProvider, Typography, Link } from '@passthrough/uikit';
import { SettingsGroup, Setting } from 'components/settings_group';
import { BooleanValue } from 'components/boolean_value';

import { Member } from './member';

function SubscriptionDocument({
  subscriptionDocumentFileUrl,
  subscriptionDocumentName,
}) {
  return (
    <Setting label="Subscription document">
      <UIKitSettingsProvider redirectAttribute="href" linkComponent="a">
        <Typography>
          <Link href={subscriptionDocumentFileUrl} variant="external">
            {subscriptionDocumentName}
          </Link>
        </Typography>
      </UIKitSettingsProvider>
    </Setting>
  );
}

function DocumentDownload({ downloadable }) {
  return (
    <Setting
      label="Document download"
      description={
        <Typography variant="body" size="small" color="text.secondary">
          Allow investors to download their documents for offline reading.
        </Typography>
      }
    >
      <BooleanValue
        bool={downloadable}
        label={downloadable ? 'Enabled' : 'Disabled'}
      />
    </Setting>
  );
}

function OfflineSigning({ disableOfflineSigning }) {
  return (
    <Setting
      label="Offline signing"
      description={
        <Typography variant="body" size="small" color="text.secondary">
          Allow investors to print and sign offline at the end of the
          questionnaire.
        </Typography>
      }
    >
      <BooleanValue
        bool={!disableOfflineSigning}
        label={disableOfflineSigning ? 'Disabled' : 'Enabled'}
      />
    </Setting>
  );
}

export function QuestionnaireSettings({
  members,
  countersignersAreOptional,
  hasSubdocQuestionnaire,
  subscriptionDocumentFileUrl,
  subscriptionDocumentName,
  numberOfCountersigners,
  defaultCountersignerId,
  defaultCountersigner2Id,
  defaultCountersigner3Id,
  downloadable,
  disableOfflineSigning,
  onEdit,
  readOnly,
}) {
  if (!hasSubdocQuestionnaire) {
    return null;
  }

  return (
    <SettingsGroup header="Questionnaire" onEdit={onEdit} readOnly={readOnly}>
      <SubscriptionDocument
        subscriptionDocumentFileUrl={subscriptionDocumentFileUrl}
        subscriptionDocumentName={subscriptionDocumentName}
      />
      {numberOfCountersigners >= 1 ? (
        <Member
          optional={countersignersAreOptional}
          members={members}
          memberId={defaultCountersignerId}
          display="countersigner"
        />
      ) : null}
      {numberOfCountersigners >= 2 ? (
        <Member
          optional={countersignersAreOptional}
          members={members}
          memberId={defaultCountersigner2Id}
          display="second countersigner"
        />
      ) : null}
      {numberOfCountersigners >= 3 ? (
        <Member
          optional={countersignersAreOptional}
          members={members}
          memberId={defaultCountersigner3Id}
          display="third countersigner"
        />
      ) : null}
      <DocumentDownload downloadable={downloadable} />
      <OfflineSigning disableOfflineSigning={disableOfflineSigning} />
    </SettingsGroup>
  );
}
