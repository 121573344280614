import React from 'react';
import { Icons, Menu, MenuItem } from '@passthrough/uikit';
import DatabaseExportIcon from 'mdi-material-ui/DatabaseExportOutline';
import FileDocumentPlus from 'mdi-material-ui/FileDocumentPlusOutline';
import FileDocumentMinus from 'mdi-material-ui/FileDocumentMinusOutline';
import FileDocumentEdit from 'mdi-material-ui/FileDocumentEditOutline';
import LabelIcon from '@material-ui/icons/LabelOutlined';

import { canUnapproveStatuses } from 'components/status/constants';
import { canRemoveDocsFromInvestor, canPrepareSignaturePages } from './utils';

export function IndividualActionsMenu({
  menuAnchor,
  investor,
  menuOpen,
  hasSubdoc,
  canEdit,
  diligenceEnabled,
  handleMenuClose,
  handleExited,
  hideWorkflowAction,
  workflowStepText,
  WorkflowStepIcon,
  handleWorkflowOperation,
  handleExport,
  handleDiligenceExport,
  handleZip,
  handleEditNote,
  handleTagInvestor,
  handleMove,
  handleDelete,
  handleAddDoc,
  handleRemoveDoc,
  handlePrepareSignaturePages,
  handleUndoApproval,
  handleChangeDiligenceApprover,
  keepOpen,
}) {
  return (
    <Menu
      id="menu-individual-actions"
      anchorEl={menuAnchor}
      open={menuOpen}
      onClose={handleMenuClose}
      disableCloseOnClick
      // prevents workflow step from momentarily
      // rerendering as menu is closing
      onExited={() => {
        handleExited();
      }}
      keepOpen={keepOpen}
    >
      {hideWorkflowAction || !canEdit ? null : (
        <MenuItem
          onClick={() => {
            handleWorkflowOperation();
          }}
          divider
          icon={WorkflowStepIcon}
          text={workflowStepText}
        />
      )}

      {hasSubdoc ? (
        <MenuItem
          onClick={handleExport}
          icon={<DatabaseExportIcon fontSize="small" color="primary" />}
          text="Export data"
        />
      ) : null}

      {diligenceEnabled ? (
        <MenuItem
          onClick={handleDiligenceExport}
          icon={<DatabaseExportIcon fontSize="small" color="primary" />}
          text="Export diligence data"
        />
      ) : null}

      <MenuItem
        onClick={handleZip}
        divider={canEdit}
        icon={<Icons.FileDownloadOutlined fontSize="small" color="primary" />}
        text="Download documents (.zip)"
      />

      {canEdit ? (
        <MenuItem
          onClick={handleEditNote}
          icon={<Icons.StickyNote2Outlined fontSize="small" color="primary" />}
          text="Add or edit note"
        />
      ) : null}

      {canEdit ? (
        <MenuItem
          onClick={handleTagInvestor}
          icon={<LabelIcon fontSize="small" color="primary" />}
          text="Tag investor"
        />
      ) : null}

      {canEdit ? (
        <MenuItem
          onClick={handleAddDoc}
          icon={<FileDocumentPlus fontSize="small" color="primary" />}
          text="Add document"
        />
      ) : null}

      {canEdit ? (
        <MenuItem
          onClick={handleRemoveDoc}
          disabled={!canRemoveDocsFromInvestor(investor)}
          icon={<FileDocumentMinus fontSize="small" color="primary" />}
          text="Remove document"
        />
      ) : null}

      {canEdit ? (
        <MenuItem
          onClick={handlePrepareSignaturePages}
          disabled={!canPrepareSignaturePages(investor)}
          icon={<FileDocumentEdit fontSize="small" color="primary" />}
          text="Prepare signature pages"
        />
      ) : null}

      {canEdit ? (
        <MenuItem
          onClick={handleUndoApproval}
          disabled={!canUnapproveStatuses.includes(investor?.status)}
          icon={<Icons.UndoOutlined fontSize="small" color="primary" />}
          text="Undo approval"
        />
      ) : null}

      {canEdit ? (
        <MenuItem
          onClick={handleMove}
          icon={
            <Icons.DriveFileMoveOutlined fontSize="small" color="primary" />
          }
          text="Move to new closing"
        />
      ) : null}

      {canEdit && diligenceEnabled ? (
        <MenuItem
          onClick={handleChangeDiligenceApprover}
          icon={<Icons.EditOutlined fontSize="small" color="primary" />}
          text="Change diligence reviewer"
        />
      ) : null}

      {canEdit ? (
        <MenuItem
          onClick={handleDelete}
          icon={<Icons.DeleteOutline fontSize="small" />}
          destructive
          text="Delete"
        />
      ) : null}
    </Menu>
  );
}
