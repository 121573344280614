import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  Button,
  Typography,
  Chip,
  Icons,
  useConfirm,
} from '@passthrough/uikit';

import { useFundReview } from 'services/providers/fund';
import { PageContainer } from 'components/page_container';
import { Callout } from 'components/Callout';
import { getErrorListFromResponse } from 'services/utils';
import * as api from 'services/api';
import { useToast } from 'services/toast';

import {
  calculateNumUnsent,
  calculateNumUnresolved,
} from 'services/thread_utils';
import {
  inProgressStatuses,
  PARTIALLY_SIGNED_STATUS,
} from 'components/status/constants';
import { QuestionPanel } from './question_panel';
import { PDFReviewDropdownButton } from '../pdf_review/pdf_review_dropdown';
import { PDFReviewDialog } from '../pdf_review/pdf_review_dialog';
import { commentModeLpClosingStatuses } from './constants';

const useStyles = makeStyles((theme) => ({
  topBar: {
    display: 'flex',
    height: 'min-content',
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  showFullQuestionnaire: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderBottom: 'none',
    padding: theme.spacing(2, 6, 2, 3),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 3),
      borderLeft: 'none',
    },
  },
  chipsAndButtons: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
    padding: theme.spacing(2, 3, 2, 6),
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 3),
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  chipsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
  topBarItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  topButtons: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  questionnaireApprovedIcon: {
    color: theme.palette.text.secondary,
  },
  pdfReview: {
    marginBottom: theme.spacing(3),
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(3),
    gap: theme.spacing(2),
  },
  noMarginRight: {
    marginRight: 0,
  },
}));

export function ReviewTab({
  fund,
  lpClosing,
  onChange,
  changeEventsPerQuestion,
  priorAnswersPerQuestion,
  enableAnswerFormatting,
  numChangedAnswers,
  usesQuestionnaireDiligenceSubapprovals,
  hasCustomApprovals,
}) {
  const [fundReview] = useFundReview();
  const { fundId, lpClosingId } = useParams();
  const { successToast } = useToast();
  const classes = useStyles();
  const confirm = useConfirm();
  const [pdfReviewModalDocument, setPdfReviewModalDocument] = useState(null);
  const [numUnsent, setNumUnsent] = useState(0);
  const [numUnresolved, setNumUnresolved] = useState(0);
  const [showFullQuestionnaire, setShowFullQuestionnaire] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);

  const isReviewMode =
    fundReview &&
    !lpClosing?.questionnaireApproved &&
    ['SIGNED', 'PARTIALLY_SIGNED'].includes(lpClosing.status);
  const hasFundReviewPermission = fundReview;
  const isInProgress = inProgressStatuses.includes(lpClosing.status);
  const isNotFullySigned = [
    ...inProgressStatuses,
    PARTIALLY_SIGNED_STATUS,
  ].includes(lpClosing.status);

  const inCommentMode =
    fundReview &&
    !lpClosing?.questionnaireApproved &&
    commentModeLpClosingStatuses.includes(lpClosing.status);

  const sections = lpClosing.sections
    .filter((s) => s.questions.length > 0)
    .filter((s) => s.type !== 'DILIGENCE');

  const sectionsWithAskedQuestions = sections.filter(
    (s) => s.questions.filter((q) => q.isAsked).length > 0,
  );
  const sectionsToShow = showFullQuestionnaire
    ? sections
    : sectionsWithAskedQuestions;

  const pdfCanReviewDocs = lpClosing.docs.filter((doc) => doc.canReviewAsPdf);
  const pdfReviewableDocs = pdfCanReviewDocs.filter((doc) => doc.isPdfReady);

  const allQuestions = sections.map((s) => s.questions).flat();
  const hasAtLeastOneAnswer = allQuestions.some((q) => q.answer);
  const showPDFReviewButton =
    ((isInProgress && hasAtLeastOneAnswer) || isReviewMode) &&
    pdfCanReviewDocs.length > 0;

  const showApproveQuestionnaireButton =
    usesQuestionnaireDiligenceSubapprovals &&
    !isNotFullySigned &&
    !numUnresolved &&
    !numUnsent &&
    !lpClosing.hasLpRequestedReview &&
    !lpClosing.questionnaireApproved &&
    !hasCustomApprovals;

  useEffect(() => {
    setNumUnsent(calculateNumUnsent(sections));
  }, [sections]);

  useEffect(() => {
    setNumUnresolved(calculateNumUnresolved(sections, changeEventsPerQuestion));
  }, [sections, changeEventsPerQuestion]);

  function approveQuestionnaire() {
    setIsLoading(true);
    setErrorMessages(null);

    api
      .lpClosingApproveQuestionnaire({ fundId, lpClosingId })
      .then(() => {
        onChange();
        setIsLoading(false);
        successToast('Approved questionnaire.');
      })
      .catch((e) => {
        setIsLoading(false);
        setErrorMessages(getErrorListFromResponse(e.response));
      });
  }

  function handleApproveQuestionnaire() {
    confirm({
      title: 'Approve this questionnaire?',
      description: 'The investor will not be notified of this action.',
      confirmationText: 'Approve',
      primaryButtonProps: {
        htmlProps: {
          'data-test': 'confirm_questionnaire_approval',
        },
      },
    })
      .then(approveQuestionnaire)
      .catch(() => {});
  }

  function unapproveQuestionnaire() {
    setIsLoading(true);
    setErrorMessages(null);

    api
      .lpClosingUnapproveQuestionnaire({ fundId, lpClosingId })
      .then(() => {
        onChange();
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        setErrorMessages(getErrorListFromResponse(e.response));
      });
  }

  function handleUnapproveQuestionnaire() {
    confirm({
      title: 'Undo the approval?',
      description: 'The investor will not be notified of this action.',
      confirmationText: 'Undo approval',
    })
      .then(unapproveQuestionnaire)
      .catch(() => {});
  }

  return (
    <>
      <div className={classes.topBar}>
        <div className={classes.chipsAndButtons}>
          <div className={classes.chipsContainer}>
            {isInProgress ? (
              <Tooltip
                title={
                  <Typography variant="label">
                    This investor has not yet completed their questionnaire and
                    their answers may still change.
                  </Typography>
                }
              >
                <Chip
                  label="Data not final"
                  variant="warning"
                  icon={<Icons.WarningAmber />}
                />
              </Tooltip>
            ) : null}
            {!lpClosing.isPostApproval && numUnresolved ? (
              <Tooltip
                title={
                  <Typography variant="label">
                    Resolve all comment threads to approve investors.
                  </Typography>
                }
              >
                <Chip
                  label={`Unresolved (${numUnresolved})`}
                  variant="warning"
                  icon={<Icons.ChatOutlined />}
                />
              </Tooltip>
            ) : null}
            {numUnsent ? (
              <Tooltip
                title={
                  <Typography variant="label">
                    The investor is not notified of comment drafts until they
                    are sent.
                  </Typography>
                }
              >
                <Chip
                  label={`Unsent (${numUnsent})`}
                  variant="info"
                  icon={<Icons.ChatOutlined />}
                />
              </Tooltip>
            ) : null}
            {numChangedAnswers ? (
              <Tooltip
                title={
                  <Typography variant="label">
                    The investor updated their submission since last review.
                  </Typography>
                }
              >
                <Chip
                  label={`Updated (${numChangedAnswers})`}
                  variant="info"
                  icon={<Icons.FlagOutlined />}
                />
              </Tooltip>
            ) : null}
            {lpClosing.hasDiligence &&
            !isNotFullySigned &&
            !numUnresolved &&
            !numUnsent &&
            !lpClosing.hasLpRequestedReview &&
            !lpClosing.questionnaireApproved &&
            !hasCustomApprovals ? (
              <div className={classes.topBarItem}>
                <Typography variant="label" color="text.primary">
                  Ready for approval
                </Typography>
              </div>
            ) : null}
            {lpClosing.hasDiligence &&
            lpClosing.questionnaireApproved &&
            !numUnresolved &&
            lpClosing.status === 'SIGNED' ? (
              <div className={classes.topBarItem}>
                <Typography variant="label" color="text.primary">
                  <div>Questionnaire approved</div>
                </Typography>
                <Tooltip
                  title={
                    <Typography variant="label">
                      To approve this investor, review all owners listed in the
                      diligence section.
                    </Typography>
                  }
                >
                  <Icons.InfoOutlined
                    fontSize="small"
                    className={classes.questionnaireApprovedIcon}
                  />
                </Tooltip>
              </div>
            ) : null}
          </div>
          <div className={classes.topButtons}>
            {errorMessages
              ? errorMessages.map((errorMessage) => (
                  <Typography key={errorMessage} variant="label" color="error">
                    {errorMessage}
                  </Typography>
                ))
              : null}
            {showApproveQuestionnaireButton ? (
              <Button
                variant="primary"
                loading={isLoading}
                onClick={handleApproveQuestionnaire}
                htmlProps={{
                  'data-test': 'questionnaire_approval',
                }}
              >
                Approve questionnaire
              </Button>
            ) : null}
            {lpClosing.hasDiligence &&
            lpClosing.questionnaireApproved &&
            lpClosing.status === 'SIGNED' ? (
              <Button
                variant="secondary"
                loading={isLoading}
                onClick={handleUnapproveQuestionnaire}
              >
                Undo approval
              </Button>
            ) : null}
          </div>
        </div>
        <div className={classes.showFullQuestionnaire}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={showFullQuestionnaire}
                onChange={() => {
                  setShowFullQuestionnaire(!showFullQuestionnaire);
                }}
              />
            }
            label={
              <Typography size="small">Show full questionnaire</Typography>
            }
            className={classes.noMarginRight}
          />
        </div>
      </div>
      <PageContainer maxWidth="lg">
        {showPDFReviewButton ? (
          <div className={classes.pdfReview}>
            <Callout
              message="View investor's submission in the original document"
              action={
                <PDFReviewDropdownButton
                  pdfCanReviewDocs={pdfCanReviewDocs}
                  setPdfReviewModalDocument={setPdfReviewModalDocument}
                />
              }
            />
          </div>
        ) : null}

        {sectionsToShow.map((section) => (
          <div
            key={`${section.label}${lpClosing.id}`}
            className={classes.section}
          >
            <Typography
              variant="card-heading"
              size="large"
              color="text.primary"
            >
              {section.label}
            </Typography>
            <div>
              {section.questions.map((question) => (
                <QuestionPanel
                  key={`${question.label}${lpClosing.id}`}
                  fundName={fund.name}
                  priorAnswer={priorAnswersPerQuestion[question.label]}
                  enableAnswerFormatting={enableAnswerFormatting}
                  question={question}
                  onChange={onChange}
                  isReviewMode={isReviewMode || inCommentMode}
                  hasFundReviewPermission={hasFundReviewPermission}
                  changeEvents={changeEventsPerQuestion[question.label]}
                  showQuestionAndAnswer
                  showFullQuestionnaire={showFullQuestionnaire}
                  lpClosing={lpClosing}
                />
              ))}
            </div>
          </div>
        ))}
      </PageContainer>
      {showPDFReviewButton && pdfReviewableDocs.length > 0 && (
        <PDFReviewDialog
          open={!!pdfReviewModalDocument}
          fundName={fund.name}
          handleClose={() => {
            setPdfReviewModalDocument(null);
          }}
          document={pdfReviewModalDocument}
          lpName={lpClosing.lpName}
          pdfReviewableDocs={pdfReviewableDocs}
          questions={allQuestions}
          setPdfReviewModalDocument={setPdfReviewModalDocument}
          enableAnswerFormatting={enableAnswerFormatting}
          priorAnswers={priorAnswersPerQuestion}
          changeEventsPerQuestion={changeEventsPerQuestion}
          onChange={onChange}
          isInProgress={isInProgress}
        />
      )}
    </>
  );
}
