import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { ANSWER_STATES } from 'services/constants';
import { DiffStyle } from 'components/Diff';
import { SensitiveData } from './sensitive_data';
import { MAX_NUM_CHARS } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(1),
  },
}));

const useLineStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '36px',
  },
  questionText: {
    flex: '0 0 50%',
    alignSelf: 'start',
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontWeight: 300,
  },
  w9Answer: {
    fontWeight: 600,
    textAlign: 'left',
    flex: '1 0 50%',
  },
}));

function Line({ question, questionState, rawAnswer, AnswerComp }) {
  const classes = useLineStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.questionText}>{question}</Typography>

      {rawAnswer && typeof rawAnswer === 'string' ? (
        <Typography>
          <DiffStyle
            isNew={questionState === ANSWER_STATES.CHANGED_CURRENT_ANSWER}
            isDeleted={questionState === ANSWER_STATES.CHANGED_PRIOR_ANSWER}
          >
            {rawAnswer}
          </DiffStyle>
        </Typography>
      ) : null}

      {AnswerComp || null}
    </div>
  );
}
const allKeys = [
  'name',
  'businessName',
  'taxClassification',
  'llcTaxClassification',
  'otherTaxClassification',
  'address1',
  'address2',
  'ssn',
  'ein',
  'accountNumbers',
  'exemptCode',
  'fatcaCode',
];

function getQuestionText(key) {
  switch (key) {
    case 'name':
      return 'Name';
    case 'businessName':
      return 'Business name';
    case 'taxClassification':
      return 'Tax classification';
    case 'llcTaxClassification':
      return 'LLC tax classification';
    case 'otherTaxClassification':
      return 'Other tax classification';
    case 'address1':
      return 'Address (number, street, apt no.)';
    case 'address2':
      return 'Address (city, state, zip code)';
    case 'ssn':
      return 'SSN';
    case 'ein':
      return 'EIN';
    case 'accountNumbers':
      return 'Account number(s)';
    case 'exemptCode':
      return 'Exempt payee code';
    case 'fatcaCode':
      return 'FATCA reporting code';
    default:
      return '';
  }
}

function getSubAnswersToDisplay(answers) {
  const processedAnswers = allKeys.map((key) => {
    const rawAnswer = answers[key];

    if (key === 'ssn' && rawAnswer) {
      // always hide ssn when flattening w9
      return '***-**-****';
    }

    return rawAnswer;
  });
  return processedAnswers.filter((a) => a);
}

export function W9v3Display({ answer, questionState, flatten }) {
  const classes = useStyles();

  if (!answer) {
    return null;
  }

  if (flatten) {
    const answersToShow = getSubAnswersToDisplay(answer);
    const numChars = answersToShow.reduce(
      (prevCount, subAnswer) => prevCount + `${subAnswer}`.length,
      0,
    );

    const joinedAnswers = answersToShow.join(', ');
    const truncatedAnswer = joinedAnswers.substring(0, MAX_NUM_CHARS);

    return numChars >= MAX_NUM_CHARS ? `${truncatedAnswer}...` : joinedAnswers;
  }

  return (
    <div className={classes.root}>
      {allKeys.map((key) => {
        if (key === 'ssn') {
          return (
            <Line
              key={key}
              question={getQuestionText(key)}
              questionState={questionState}
              AnswerComp={
                answer.ssn ? (
                  <SensitiveData
                    answer={answer.ssn}
                    questionState={questionState}
                  />
                ) : null
              }
            />
          );
        }

        return (
          <Line
            key={key}
            question={getQuestionText(key)}
            rawAnswer={answer[key]}
            questionState={questionState}
          />
        );
      })}
    </div>
  );
}
