import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

export const Colors = {
  PENDING: 'pending',
  FULLY_EXECUTED: 'fullyExecuted',
  FILLER: 'filler',
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    height: '5px',
    width: '100%',
  },
  tooltipTextContainer: {
    padding: theme.spacing(1),
  },
  tooltipText: {
    fontSize: '1.1rem',
  },
  bar: {
    height: '100%',
    borderRadius: theme.spacing(0.5),
  },
  pending: {
    backgroundColor: theme.palette.progressBar.pending,
  },
  fullyExecuted: {
    backgroundColor: theme.palette.progressBar.fullyExecuted,
  },
  filler: {
    backgroundColor: theme.palette.progressBar.filler,
  },
}));

export function ProgressBar({ colors, percentages, tooltips = [] }) {
  const classes = useStyles();

  function bar(percentage, color, tooltipText) {
    const barElement = (
      <div
        key={color}
        className={clsx(classes.bar, {
          [classes.pending]: color === Colors.PENDING,
          [classes.fullyExecuted]: color === Colors.FULLY_EXECUTED,
          [classes.filler]: color === Colors.FILLER,
        })}
        style={{ width: `${percentage}%` }}
      />
    );

    if (!tooltipText) {
      return barElement;
    }

    const tooltipTextLines = tooltipText.split('\n');
    return (
      <Tooltip
        key={color}
        placement="top"
        arrow
        title={
          <div className={classes.tooltipTextContainer}>
            {tooltipTextLines.map((line) => (
              <Typography key={line} className={classes.tooltipText}>
                {line}
              </Typography>
            ))}
          </div>
        }
      >
        {barElement}
      </Tooltip>
    );
  }

  return (
    <div className={classes.container}>
      {percentages.map((percentage, index) =>
        bar(percentage, colors[index], tooltips[index]),
      )}
    </div>
  );
}
