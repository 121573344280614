import React from 'react';
import { Menu, MenuItem, Icons, Button } from '@passthrough/uikit';
import { ShowCaseBox, ComponentShowCase, ShowCaseBoxRow } from '../show_case';

export const MenuShowCase = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  return (
    <ComponentShowCase title="Menu">
      <ShowCaseBox>
        <ShowCaseBoxRow>
          <Button
            variant="primary"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            Menu
          </Button>
          <Menu
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem text="Item 1" />
            <MenuItem text="Item 4" subtext="Subtext" />
            <MenuItem text="Item 2" icon={<Icons.Delete />} />
            <MenuItem text="Item 3" destructive icon={<Icons.Delete />} />
          </Menu>
        </ShowCaseBoxRow>
      </ShowCaseBox>
    </ComponentShowCase>
  );
};
