// @ts-check
import React, { useState, useEffect } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';
import { Modal, Button, useConfirm } from '@passthrough/uikit';
import * as api from 'services/api';
import { useToast } from 'services/toast';
import { CurrencyTextField } from 'components/currency_text_field/index';
import { getSingleErrorFromResponse } from 'services/utils';

export function TargetDialog({
  open,
  fundId,
  onClose,
  initialTargetCommitment,
  refreshData,
  currency,
}) {
  const confirm = useConfirm();
  const { toast } = useToast();
  const [targetCommitment, setTargetCommitment] = useState(
    initialTargetCommitment || '',
  );
  const [targetError, setTargetError] = useState(null);

  const changed = initialTargetCommitment
    ? targetCommitment !== initialTargetCommitment
    : !!targetCommitment;

  const { code, symbol } = currency;
  const currencyDisplay = `${code} ${symbol}`;

  function handleClose() {
    if (changed) {
      confirm({
        title: 'Discard unsaved changes?',
        description: 'Your changes have not been saved.',
        confirmationText: 'Discard',
        destructive: true,
        size: 'xs',
      })
        .then(() => {
          onClose();
        })
        .catch(() => {});
    } else {
      onClose();
    }
  }

  function handleChangeCommitment(e, v) {
    const targetValue = parseFloat(e.target.value.replace(/,/g, ''));
    const value = v || targetValue;
    setTargetCommitment(value);
  }

  const handleSubmit = () => {
    api
      .targetCommitmentUpdate({ fundId, targetCommitment })
      .then(() => {
        toast('Updated fundraising target');
        refreshData();
        onClose();
      })
      .catch((error) => {
        setTargetError(getSingleErrorFromResponse(error.response));
      });
  };

  useEffect(() => {
    setTargetError(null);
    if (!open) {
      return;
    }
    setTargetCommitment(initialTargetCommitment || '');
  }, [open]);

  return (
    <Modal
      headerLabel="Enter fundraising target"
      open={open}
      onClose={handleClose}
      size="sm"
      primaryButtonText="Save"
      onSubmit={handleSubmit}
      showCancelButton
    >
      <CurrencyTextField
        id="dollars"
        currencySymbol={currencyDisplay}
        label="Fundraising target"
        variant="outlined"
        value={targetCommitment}
        error={!!targetError}
        helperText={targetError}
        onChange={handleChangeCommitment}
        fullWidth
        endAdornment={
          targetCommitment ? (
            <InputAdornment position="end">
              <Button
                size="small"
                variant="icon"
                aria-label="Clear fundraising target"
                onClick={() => {
                  setTargetCommitment('');
                  setTargetError(null);
                }}
              >
                <CloseIcon />
              </Button>
            </InputAdornment>
          ) : null
        }
      />
    </Modal>
  );
}
