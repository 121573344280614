import React from 'react';

import { DiligenceTIN } from '../tin';

export function SpousalTrustDetails({ tin, tinError, handleChange }) {
  return (
    <DiligenceTIN
      label="TIN"
      tin={tin}
      tinError={tinError}
      onChange={(e) => {
        handleChange('tin', e.target.value);
      }}
    />
  );
}
