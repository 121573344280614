import React from 'react';
import { useConfirm, Button } from '@passthrough/uikit';
import { ShowCaseBox, ComponentShowCase, ShowCaseBoxRow } from '../show_case';

export const ConfirmShowCase = () => {
  const confirm = useConfirm();
  return (
    <ComponentShowCase title="useConfirm">
      <ShowCaseBox>
        <ShowCaseBoxRow>
          <Button
            variant="primary"
            onClick={() =>
              confirm({
                title: 'Read and confirm agreement',
                description:
                  'Do you confirm the world has more wheels than doors?',
                confirmationText: 'Confirm',
              })
            }
          >
            Confirm
          </Button>
          <Button
            variant="primary"
            destructive
            onClick={() =>
              confirm({
                title: 'Destroy the world',
                description:
                  'Are you sure you would like to destroy the world? This action cannot be undone.',
                destructive: true,
                confirmationText: 'Destroy',
              })
            }
          >
            Destroy
          </Button>
        </ShowCaseBoxRow>
      </ShowCaseBox>
    </ComponentShowCase>
  );
};
