import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormHelperText } from '@material-ui/core';

import {
  Typography,
  Button,
  Icons,
  useConfirm,
  Modal,
} from '@passthrough/uikit';
import { useToast } from 'services/toast';
import { objectEquals } from 'services/utils';

import { EMPTY_GRAPH } from './constants';

const useStyles = makeStyles((theme) => ({
  jsonHeader: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  json: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    whiteSpace: 'pre-wrap',
  },
}));

const REPLACE_JSON = 'replace';
const MERGE_JSON = 'merge';

function canMerge(jsonToPaste, data) {
  return ['states', 'actions', 'groups'].every((key) => {
    const oldIds = data[key].map((item) => item.id);
    const newIds = jsonToPaste[key].map((item) => item.id);
    const combinedSize = new Set([...oldIds, ...newIds]).size;

    return combinedSize === oldIds.length + newIds.length;
  });
}

function replaceJson(jsonToPaste, setData) {
  setData(jsonToPaste);
}

function mergeJson(jsonToPaste, data, setData) {
  const {
    states: currentStates,
    actions: currentActions,
    groups: currentGroups,
  } = data;
  const {
    states: statesToPaste,
    actions: actionsToPaste,
    groups: groupsToPaste,
  } = jsonToPaste;

  setData({
    states: [...currentStates, ...statesToPaste],
    actions: [...currentActions, ...actionsToPaste],
    groups: [...currentGroups, ...groupsToPaste],
  });
}

function PasteModal({
  open,
  onClose,
  disableMerge,
  setDisableMerge,
  jsonToPaste,
  setJsonToPaste,
  data,
  setData,
  pasteOption,
  setPasteOption,
  setError,
}) {
  const { toast } = useToast();
  const onSubmit = () => {
    setError(null);
    if (pasteOption === REPLACE_JSON) {
      replaceJson(jsonToPaste, setData);
    } else if (pasteOption === MERGE_JSON) {
      mergeJson(jsonToPaste, data, setData);
    } else {
      throw new Error('Invalid paste option');
    }
    toast('JSON pasted');
    onClose();
  };
  const onExited = () => {
    setJsonToPaste('');
    setPasteOption(REPLACE_JSON);
    setDisableMerge(false);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      headerLabel="Paste JSON"
      showCancelButton
      primaryButtonText="Paste"
      onSubmit={onSubmit}
      onExited={onExited}
    >
      <Typography variant="body">
        Should the existing JSON be replaced, or should the new JSON be combined
        with the existing JSON?
      </Typography>
      <RadioGroup
        value={pasteOption}
        onChange={(event) => setPasteOption(event.target.value)}
      >
        <FormControlLabel
          value={REPLACE_JSON}
          control={<Radio />}
          label="Replace existing JSON"
        />
        <FormControlLabel
          value={MERGE_JSON}
          control={<Radio />}
          label="Merge with existing JSON"
          disabled={disableMerge}
        />
        {disableMerge ? (
          <FormHelperText>
            Can't merge because the result would have duplicate IDs
          </FormHelperText>
        ) : null}
      </RadioGroup>
    </Modal>
  );
}

export function JSONSection({
  data,
  setData,
  setError,
  haveUnpublishedChanges,
}) {
  const [pasteModalOpen, setPasteModalOpen] = useState(false);
  const [pasteOption, setPasteOption] = useState(REPLACE_JSON);
  const [jsonToPaste, setJsonToPaste] = useState('');
  const [disableMerge, setDisableMerge] = useState(false);
  const classes = useStyles();
  const confirm = useConfirm();
  const { toast } = useToast();

  const pasteJSON = () => {
    const handleRead = (text) => {
      let json;

      try {
        json = JSON.parse(text);
      } catch (error) {
        setError('Invalid JSON to paste');
        return;
      }

      // Empty array is truthy in JS
      if (!json.states || !json.actions || !json.groups) {
        setError('Invalid custom approval data to paste');
        return;
      }

      if (!canMerge(json, data)) {
        setDisableMerge(true);
      }

      const needConfirmation = !objectEquals(data, EMPTY_GRAPH);
      if (!needConfirmation) {
        replaceJson(json, setData);
        toast('Pasted JSON');
        return;
      }

      setJsonToPaste(json);
      setPasteModalOpen(true);
    };

    navigator.clipboard
      .readText()
      .then(handleRead)
      .catch(() => {});
  };

  const copyJSON = () => {
    navigator.clipboard
      .writeText(JSON.stringify(data, null, 2))
      .then(() => toast('Copied JSON to clipboard'))
      .catch(() => {});
  };

  const deleteJSON = () => {
    setData(EMPTY_GRAPH);
    toast('Deleted JSON');
  };

  const confirmDeleteJSON = () => {
    if (haveUnpublishedChanges) {
      confirm({
        title: 'Delete JSON',
        description:
          'Are you sure you want to delete the JSON? This will discard any unsaved changes.',
        confirmationText: 'Delete',
        destructive: true,
      })
        .then(deleteJSON)
        .catch(() => {});
    } else {
      deleteJSON();
    }
  };

  return (
    <>
      <div className={classes.jsonHeader}>
        <Typography variant="section-heading">JSON</Typography>
        <div>
          <Button
            onClick={confirmDeleteJSON}
            variant="icon"
            aria-label="Delete JSON"
            size="large"
          >
            <Icons.DeleteOutlined />
          </Button>
          <Button
            onClick={copyJSON}
            variant="icon"
            aria-label="Copy JSON"
            size="large"
          >
            <Icons.FileCopyOutlined />
          </Button>
          <Button
            onClick={pasteJSON}
            variant="icon"
            aria-label="Paste JSON"
            size="large"
          >
            <Icons.ContentPaste />
          </Button>
        </div>
      </div>
      <div className={classes.json}>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </div>
      <PasteModal
        open={pasteModalOpen}
        onClose={() => setPasteModalOpen(false)}
        disableMerge={disableMerge}
        setDisableMerge={setDisableMerge}
        jsonToPaste={jsonToPaste}
        setJsonToPaste={setJsonToPaste}
        data={data}
        setData={setData}
        pasteOption={pasteOption}
        setPasteOption={setPasteOption}
        setError={setError}
      />
    </>
  );
}
