import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useConfirm, Alert } from '@passthrough/uikit';
import { useParams } from 'react-router-dom';
import { PageContainer } from 'components/page_container';
import { Spinner } from 'components/spinner';
import { EmptyState } from 'components/empty_v2';
import { useFundEdit, useFund, useMembers } from 'services/providers/fund';
import { useMe } from 'services/providers/me';
import { useToast } from 'services/toast';
import * as api from 'services/api';
import { NewMemberDialog } from './modals/new_member_dialog';
import { UpdateMemberDialog } from './modals/update_member_dialog';
import { MemberList } from './member_list';
import { ExpireFundPermissionModal } from './modals/expire_fund_permission_modal';

const useStyles = makeStyles(() => ({
  groups: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

export function AccessPage() {
  const { fundId } = useParams();
  const confirm = useConfirm();
  const [errorMsg, setErrorMsg] = useState('');
  const [selectedMember, setSelectedMember] = useState(null);

  const [newDialogOpen, setNewDialogOpen] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [expireFundPermissionDialogOpen, setExpireFundPermissionDialogOpen] =
    useState(false);
  const [change, setChange] = useState(0);
  const [groupChoices, setGroupChoices] = useState([]);
  const [fundEdit] = useFundEdit();
  const [members, isLoadingMembers, refreshMembers] = useMembers();
  const [me] = useMe();

  const [fund, , refreshFund] = useFund();
  const { toast } = useToast();
  const classes = useStyles();

  function getCustomGroups() {
    api.customGroups({ fundId }).then((response) => {
      setGroupChoices(response.data);
    });
  }

  useEffect(refreshMembers, [change]);
  useEffect(getCustomGroups, [fundId]);

  function onChange() {
    setChange((c) => c + 1);
    refreshFund((f) => f + 1);
  }

  if (isLoadingMembers) {
    return <Spinner fullScreen />;
  }

  function onDelete(id) {
    const member = members.find((m) => m.id === id);

    function performDelete() {
      api
        .deleteMember({ fundId, memberId: id })
        .then(() => {
          setErrorMsg('');
          onChange();
          toast(`Revoked access from ${member.name}.`);
        })
        .catch((error) => {
          if (error.response?.status === 400) {
            setErrorMsg(error.response.data[0]);
          }
        });
    }

    confirm({
      description: `You are about to revoke access to ${member.name}.`,
    })
      .then(performDelete)
      .catch(() => {});
  }

  return (
    <PageContainer maxWidth="lg">
      <div className={classes.groups}>
        {errorMsg ? <Alert severity="error">{errorMsg}</Alert> : null}
        {members.length === 0 ? (
          <EmptyState
            title="No collaborators"
            ctaText="New collaborator"
            ctaOnClick={() => {
              setNewDialogOpen(true);
            }}
            ctaPermission={fundEdit}
          />
        ) : (
          <MemberList
            members={members}
            openNewDialog={() => {
              setNewDialogOpen(true);
            }}
            openExpireFundPermissionDialog={() => {
              setExpireFundPermissionDialogOpen(true);
            }}
            onDelete={onDelete}
            onUpdate={(id) => {
              const member = members.find((m) => m.id === id);
              setSelectedMember(member);
              setUpdateDialogOpen(true);
            }}
            groups={groupChoices}
          />
        )}
      </div>

      <NewMemberDialog
        fundId={fundId}
        diligenceEnabled={fund?.diligenceEnabled}
        open={newDialogOpen}
        onChange={onChange}
        onClose={() => setNewDialogOpen(false)}
        groupChoices={groupChoices}
      />

      <UpdateMemberDialog
        fundId={fundId}
        memberId={selectedMember?.id}
        name={selectedMember?.name}
        level={selectedMember?.level}
        diligenceEnabled={fund?.diligenceEnabled}
        open={updateDialogOpen}
        onChange={onChange}
        onClose={() => setUpdateDialogOpen(false)}
        groupId={selectedMember?.customGroupId}
        groupChoices={groupChoices}
        currEnableTransactionEmailsSetting={
          selectedMember?.transactionEmailsEnabled
        }
        currEnableDigestEmailsSetting={selectedMember?.digestEmailsEnabled}
      />

      <ExpireFundPermissionModal
        open={!!(me.isStaff && fundEdit && expireFundPermissionDialogOpen)}
        onChange={onChange}
        onClose={() => setExpireFundPermissionDialogOpen(false)}
        fundId={fundId}
        members={members}
      />
    </PageContainer>
  );
}
