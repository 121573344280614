import React from 'react';
import { SessionTimeoutDialog } from 'components/session_timeout_dialog';

const SessionContext = React.createContext();

export const SessionProvider = ({ userId, children }) => {
  const [open, setOpen] = React.useState(false);
  const showTimeoutDialog = () => setOpen(true);
  const hideTimeoutDialog = () => setOpen(false);
  return (
    <SessionContext.Provider value={{ showTimeoutDialog }}>
      {open ? (
        <SessionTimeoutDialog
          open={open}
          handleClose={hideTimeoutDialog}
          userId={userId}
        />
      ) : null}
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => React.useContext(SessionContext);
