import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  greyIcon: {
    color: theme.palette.neutral.icon,
  },
  sliderRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  track: {
    height: '6px',
  },
  rail: {
    height: '6px',
  },
  thumb: {
    // Adjusted so that it centers correctly with the updated heights
    marginTop: '0px',
  },
}));

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

export function MobileZoom({ zoom, setZoom, disabled }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ZoomOutIcon fontSize="small" className={classes.greyIcon} />
      <Slider
        aria-label="Zoom slider"
        value={zoom}
        onChange={(event, newValue) => {
          setZoom(newValue);
        }}
        valueLabelDisplay="auto"
        step={0.1}
        marks
        min={0.4}
        max={2}
        disabled={disabled}
        ValueLabelComponent={ValueLabelComponent}
        valueLabelFormat={(value) => `${Math.round(value * 100)}%`}
        classes={{
          root: classes.sliderRoot,
          track: classes.track,
          rail: classes.rail,
          thumb: classes.thumb,
        }}
      />
      <ZoomInIcon fontSize="small" className={classes.greyIcon} />
    </div>
  );
}
