import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Icons } from '@passthrough/uikit';
import CircleSlice3Icon from 'mdi-material-ui/CircleSlice3';
import MinusCircleIcon from 'mdi-material-ui/MinusCircle';
import AlertCircleIcon from 'mdi-material-ui/AlertCircle';
import RepeatIcon from '@material-ui/icons/Repeat';
import clsx from 'clsx';
import {
  TREE_COMPLETE,
  TREE_REUSING,
  TREE_ERROR,
  TREE_IN_PROGRESS,
  TREE_OPT_OUT,
} from './tree_utils';

const useStyles = makeStyles((theme) => ({
  labelIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  optOutIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.warning.main,
  },
  errorIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.error.main,
  },
  completedIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.success.main,
  },
  repeatIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.neutral.icon,
  },
  selected: { color: 'inherit' },
}));

export function TreeIcon({ treeState, isSelected }) {
  const classes = useStyles({ isSelected });

  switch (treeState) {
    case TREE_COMPLETE:
      return (
        <Icons.CheckCircle
          className={clsx(classes.completedIcon, {
            [classes.selected]: isSelected,
          })}
        />
      );
    case TREE_REUSING:
      return (
        <RepeatIcon
          className={clsx(classes.repeatIcon, {
            [classes.selected]: isSelected,
          })}
        />
      );
    case TREE_IN_PROGRESS:
      return (
        <CircleSlice3Icon
          className={clsx(classes.labelIcon, {
            [classes.selected]: isSelected,
          })}
        />
      );
    case TREE_OPT_OUT:
      return (
        <MinusCircleIcon
          className={clsx(classes.optOutIcon, {
            [classes.selected]: isSelected,
          })}
        />
      );
    case TREE_ERROR:
      return (
        <AlertCircleIcon
          className={clsx(classes.errorIcon, {
            [classes.selected]: isSelected,
          })}
        />
      );

    default:
      return (
        <Icons.RadioButtonUnchecked
          className={clsx(classes.labelIcon, {
            [classes.selected]: isSelected,
          })}
        />
      );
  }
}
