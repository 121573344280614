import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Alert, Typography } from '@passthrough/uikit';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ListItemText from '@material-ui/core/ListItemText';
import { Divider } from '@material-ui/core';

import * as api from 'services/api';
import {
  getAutocompleteOptionLabelByName,
  capitalizeFirstLetter,
} from 'services/utils';
import { useMe } from 'services/providers/me';

import { EditTimeForm, useEditTimeState } from '../review_timer/edit_time';
import {
  sendHeader,
  sendText,
  senderRole,
  noteFlags,
  REVIEW_STATE,
  REVISION_STATE,
  COMPLETED_STATE,
} from '../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

function buildErrorMessage(message, questionIds) {
  questionIds.sort();
  return `${message}: ${questionIds.join(', ')}`;
}

function getMissingLogicErrorMessage(missingLogicQuestionIds) {
  return buildErrorMessage(
    "Questions require custom logic but don't have any defined",
    missingLogicQuestionIds,
  );
}

function getWarnings(hasUnpublishedChanges, newState, missingLogicQuestionIds) {
  const newWarnings = [];
  // don't have to worry about "discuss with client" flags
  // because onboarding managers said those only really get
  // resolved when the onboarding is completed

  if (hasUnpublishedChanges) {
    // this is only a warning because some onboarding managers
    // mentioned that they might send a document for review
    // even if the document is not publishable
    newWarnings.push({
      key: 'unpublishedChangesWarning',
      message: 'Questionnaire is not published',
    });
  }

  if (newState !== COMPLETED_STATE && missingLogicQuestionIds.length > 0) {
    // this is a warning if newState isn't COMPLETED_STATE because OMs
    // should be able to change between REVIEW_STATE and REVISION_STATE
    // before custom logic is implemented.
    newWarnings.push({
      key: 'missingLogicWarning',
      message: getMissingLogicErrorMessage(missingLogicQuestionIds),
    });
  }

  return newWarnings;
}

function getUnresolvedFlagQuestionIds(questions, notes, flag) {
  return questions
    .filter(
      (q) =>
        !q.isSection &&
        notes[q.id]?.some((note) => note.flag === flag && !note.resolved),
    )
    .map((question) => question.id);
}

function getUnreviewedQuestionsIds(questions, reviewedQuestionIds) {
  return questions
    .filter((q) => !q.isSection && !reviewedQuestionIds?.includes(q.id))
    .map((question) => question.id);
}

function getErrors(
  currentState,
  notes,
  questions,
  reviewedQuestionIds,
  newState,
  missingLogicQuestionIds,
) {
  const newErrors = [];
  const unreviewedQuestionIds = getUnreviewedQuestionsIds(
    questions,
    reviewedQuestionIds,
  );
  const unresolvedQuestionIds = getUnresolvedFlagQuestionIds(
    questions,
    notes,
    noteFlags.NEEDS_REVIEW,
  );
  if (currentState === REVIEW_STATE) {
    if (newState === COMPLETED_STATE && unreviewedQuestionIds.length > 0) {
      newErrors.push({
        key: 'unreviewedQuestionsError',
        message: buildErrorMessage(
          'Questions still need review',
          unreviewedQuestionIds,
        ),
      });
    }
  } else if (currentState === REVISION_STATE) {
    if (unresolvedQuestionIds.length > 0) {
      newErrors.push({
        key: 'unresolvedQuestionsError',
        message: buildErrorMessage(
          'Questions still need fixes',
          unresolvedQuestionIds,
        ),
      });
    }
  }

  if (newState === COMPLETED_STATE && missingLogicQuestionIds.length > 0) {
    // don't allow OMs to complete questionnaire review if custom logic
    // hasn't been implemented yet
    newErrors.push({
      key: 'missingLogicError',
      message: getMissingLogicErrorMessage(missingLogicQuestionIds),
    });
  }

  return newErrors;
}

function ReviewerSelect({ reviewerOptions, reviewerId, setReviewerId, label }) {
  return (
    <Autocomplete
      selectOnFocus
      fullWidth
      options={reviewerOptions}
      value={reviewerId}
      onChange={(e, v) => setReviewerId(v?.id)}
      renderOption={(option) => (
        <ListItemText primary={option.name} secondary={option.email} />
      )}
      getOptionLabel={(option) =>
        getAutocompleteOptionLabelByName(option, reviewerOptions)
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth
          size="small"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'chrome-off',
          }}
        />
      )}
    />
  );
}

const INITIAL_STEP = 'initial';
const CONFIRMATION_STEP = 'confirm';

export function SendModal({
  open,
  onClose,
  fundId,
  questionnaireId,
  saveState,
  questions,
  reviewedQuestionIds,
  currentState,
  nextState,
  notes,
  hasUnpublishedChanges,
  totalTimeInSeconds,
  saving,
}) {
  const classes = useStyles();
  const [step, setStep] = useState(INITIAL_STEP);
  // Keep track of new state separate from the nextState prop, because
  // nextState is set to null as soon as the modal is closed, while we want
  // to keep the new state until the modal is done exiting.
  const [newState, setNewState] = useState(null);
  const [reviewerId, setReviewerId] = useState('');
  const [reviewerOptions, setReviewerOptions] = useState([]);
  const [reviewOptionsLoading, setReviewOptionsLoading] = useState(false);
  const [missingLogicQuestionIds, setMissingLogicQuestionIds] = useState([]);
  const [missingLogicQuestionIdsLoading, setMissingLogicQuestionIdsLoading] =
    useState(false);
  const [me] = useMe();

  const initialStateLoading =
    reviewOptionsLoading || missingLogicQuestionIdsLoading;

  const {
    seconds,
    minutes,
    setSeconds,
    setMinutes,
    newTotalSeconds,
    secondsOutOfRange,
    minutesOutOfRange,
    isInvalid: timeIsInvalid,
    resetState: resetEditTimeState,
  } = useEditTimeState(totalTimeInSeconds);

  const resetState = () => {
    resetEditTimeState();
    setNewState(nextState);
    setMissingLogicQuestionIds([]);
    setReviewerOptions([]);
    setReviewerId(me.id);
  };

  const resetReviewerOptions = () => {
    setReviewOptionsLoading(true);
    api
      .staffUsersList()
      .then((response) => setReviewerOptions(response.data))
      .finally(() => setReviewOptionsLoading(false));
  };

  const checkMissingLogic = () => {
    setMissingLogicQuestionIdsLoading(true);
    api
      .missingLogicQuestions({ fundId, questionnaireId })
      .then((response) => setMissingLogicQuestionIds(response.data))
      .finally(() => setMissingLogicQuestionIdsLoading(false));
  };

  const warnings = getWarnings(
    hasUnpublishedChanges,
    newState,
    missingLogicQuestionIds,
  );
  const errors = getErrors(
    currentState,
    notes,
    questions,
    reviewedQuestionIds,
    newState,
    missingLogicQuestionIds,
  );

  // If the "Continue" button is pressed while the initial state is
  // still being fetched, we show a spinner on the primary button
  // and delay moving to CONFIRMATION_STEP until the state is loaded.
  const stepToDisplay =
    step === CONFIRMATION_STEP && !initialStateLoading
      ? CONFIRMATION_STEP
      : INITIAL_STEP;

  const canSubmit =
    errors.length === 0 &&
    !(stepToDisplay === CONFIRMATION_STEP && (timeIsInvalid || !reviewerId));

  const shouldSend =
    stepToDisplay === CONFIRMATION_STEP || currentState === COMPLETED_STATE;
  const send = () => saveState(newState, newTotalSeconds, reviewerId);

  const onSubmit = (e) => {
    e.preventDefault();
    if (shouldSend) {
      send();
    } else {
      setStep(CONFIRMATION_STEP);
    }
  };

  const headerLabel = sendHeader[newState];
  const primaryButtonText = shouldSend ? 'Send' : 'Continue';
  const onBack =
    stepToDisplay === CONFIRMATION_STEP
      ? () => setStep(INITIAL_STEP)
      : undefined;
  const primaryButtonDisabled = !canSubmit;
  const primaryButtonLoading =
    saving || (initialStateLoading && step === CONFIRMATION_STEP);

  const onEntering = () => {
    if (currentState !== COMPLETED_STATE) {
      resetReviewerOptions();
      checkMissingLogic();
    }
    resetState();
  };

  const onExited = () => setStep(INITIAL_STEP);

  const initialStepMessage = canSubmit ? (
    <Typography>{sendText[newState]}</Typography>
  ) : null;
  const modalContent =
    stepToDisplay === CONFIRMATION_STEP ? (
      <>
        <Alert severity="info">
          Confirm {senderRole[currentState]} and time to move to the {newState}{' '}
          state. The {senderRole[currentState]} and time will be saved on the
          current onboarding log (current state: {currentState}).
        </Alert>
        <ReviewerSelect
          reviewerOptions={reviewerOptions}
          reviewerId={reviewerId}
          setReviewerId={setReviewerId}
          label={capitalizeFirstLetter(senderRole[currentState])}
        />
        <Divider />
        <EditTimeForm
          seconds={seconds}
          minutes={minutes}
          setSeconds={setSeconds}
          setMinutes={setMinutes}
          secondsOutOfRange={secondsOutOfRange}
          minutesOutOfRange={minutesOutOfRange}
        />
      </>
    ) : (
      initialStepMessage
    );

  return (
    <Modal
      open={open}
      onClose={onClose}
      onBack={onBack}
      headerLabel={headerLabel}
      primaryButtonText={primaryButtonText}
      primaryButtonDisabled={primaryButtonDisabled}
      primaryButtonLoading={primaryButtonLoading}
      onEntering={onEntering}
      onExited={onExited}
      onSubmit={onSubmit}
      showCancelButton
    >
      <div className={classes.root}>
        {errors.map((error) => (
          <Alert key={error.key} severity="error">
            {error.message}
          </Alert>
        ))}
        {warnings.map((warning) => (
          <Alert key={warning.key} severity="warning">
            {warning.message}
          </Alert>
        ))}
        {modalContent}
      </div>
    </Modal>
  );
}
