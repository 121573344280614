import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { ConfirmationBanner } from './confirmation_banner';
import * as constants from './constants';
import { TagDiffPanel } from './diff_panel';
import { ChoicePanelContent } from './choice_panel_content';
import { SimplePanelContent } from './simple_panel_content';
import { UndoBanner } from './undo_banner';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
}));

export const KeepTagPanel = ({
  confirmed,
  setConfirmed,
  undoConfirmation,
  questionType,
  questionTags,
  origQuestionText,
  origQuestionChoice,
  newQuestionText,
  newQuestionChoice,
}) => {
  const classes = useStyles();
  const [alertText, setAlertText] = useState(false);
  const leftContent =
    questionType === constants.CHOICE ? (
      <ChoicePanelContent
        questionText={origQuestionText}
        questionTags={questionTags}
        choice={origQuestionChoice}
        left
      />
    ) : (
      <SimplePanelContent
        questionText={origQuestionText}
        questionTags={questionTags}
        left
      />
    );
  const rightContent =
    questionType === constants.CHOICE ? (
      <ChoicePanelContent
        questionText={newQuestionText}
        questionTags={questionTags}
        choice={newQuestionChoice}
      />
    ) : (
      <SimplePanelContent
        questionText={newQuestionText}
        questionTags={questionTags}
      />
    );

  return confirmed === constants.NOT_CONFIRMED ? (
    <div key="questionKeepTagPanel" className={classes.root}>
      <ConfirmationBanner
        key="banner"
        setConfirmed={setConfirmed}
        setAlertText={setAlertText}
        questionTags={questionTags}
      />
      <TagDiffPanel
        key="diff"
        leftContent={leftContent}
        rightContent={rightContent}
      />
    </div>
  ) : (
    <UndoBanner undoConfirmation={undoConfirmation} alertText={alertText} />
  );
};
