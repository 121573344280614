import React from 'react';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Icons } from '@passthrough/uikit';

import { TypeDisplay } from 'components/type_display';
import { calculateAge } from 'services/utils';
import { DiligenceChipsDisplay } from './diligence_chips_display';

const useStyles = makeStyles((theme) => ({
  typeContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(1.5),
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
}));

export function IdAndStatusDisplay({
  name,
  answer,
  hasDraftComment,
  hasNewAlerts,
  numUnevaluatedMatches,
  numCommentEvents,
  isThreadResolved,
  isApproved,
  updated,
}) {
  const classes = useStyles();
  const type = answer?.type;
  const country =
    answer?.residentialAddress?.line1?.country ||
    answer?.hqAddress?.line1?.country;
  const date = answer?.dob || answer?.incorporationDate;
  let formattedDate = null;
  if (date) {
    const fullDate = parse(date, 'MM/dd/yyyy', new Date());
    formattedDate = format(fullDate, 'd MMM yyyy');

    if (answer?.dob) {
      formattedDate = `${formattedDate} (age ${calculateAge(fullDate)})`;
    }
  }

  return (
    <div>
      <DiligenceChipsDisplay
        hasDraftComment={hasDraftComment}
        hasNewAlerts={hasNewAlerts}
        numCommentEvents={numCommentEvents}
        isThreadResolved={isThreadResolved}
        numUnevaluatedMatches={numUnevaluatedMatches}
        isApproved={isApproved}
        updated={updated}
      />
      <Typography variant="body" color="text.primary">
        {name}
      </Typography>

      <div className={classes.typeContainer}>
        <Typography variant="label">
          <TypeDisplay type={type} />
        </Typography>
        {country ? (
          <Typography variant="label">
            <TypeDisplay
              type={country}
              icon={<Icons.Language fontSize="inherit" />}
            />
          </Typography>
        ) : null}
        {formattedDate ? (
          <Typography variant="label">
            <TypeDisplay
              type={formattedDate}
              icon={<Icons.DateRangeOutlined fontSize="inherit" />}
            />
          </Typography>
        ) : null}
      </div>
    </div>
  );
}
