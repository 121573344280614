import React from 'react';

import { Chip, Icons } from '@passthrough/uikit';

export function RiskRatingCell({ rating }) {
  switch (rating) {
    case 'CRITICAL_RISK':
      return (
        <Chip
          variant="error"
          size="small"
          label="Critical"
          icon={<Icons.ErrorOutline />}
        />
      );
    case 'HIGH_RISK':
      return <Chip variant="error" size="small" label="High" />;
    case 'MODERATE_RISK':
      return <Chip variant="warning" size="small" label="Moderate" />;
    case 'LOW_RISK':
      return <Chip variant="success" size="small" label="Low" />;
    case 'NO_RISK_RATING':
      return <Chip variant="neutral" size="small" label="Unable to assess" />;
    default:
  }
}
