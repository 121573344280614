import React, { useState } from 'react';
import { useFund, useFundEdit } from 'services/providers/fund';
import { useMe } from 'services/providers/me';
import { ExternalAccess } from 'components/fund_external_access';
import * as urls from 'services/urls';
import { useFeatureFlags } from 'services/providers/feature_flag';
import {
  PageHeader,
  Tabs,
  Tab,
  Breadcrumbs,
  HeaderSkeleton,
  InternalChip,
  Typography,
  Button,
} from '@passthrough/uikit';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/EditOutlined';
import { makeStyles } from '@material-ui/core';
import { useCurrency } from 'services/providers/currency';
import { formatCurrency } from 'services/utils';
import { TargetDialog } from './target_dialog';

const useStyles = makeStyles((theme) => ({
  fundraisingTargetFlex: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    flexWrap: 'wrap',
    alignItems: 'center',
  },
}));

const TABS = [
  {
    id: 'closings',
    name: 'Closings',
    path: urls.CLOSINGS_URL,
    toUrl: (props) => urls.closingsUrl(props),
  },
  {
    id: 'diligence',
    name: 'Diligence',
    path: urls.DILIGENCES_URL,
    toUrl: (props) => urls.diligenceUrl(props),
  },
  {
    id: 'offering',
    name: 'Fund documents',
    path: urls.OFFERING_URL,
    toUrl: (props) => urls.offeringUrl(props),
  },
  {
    id: 'access',
    name: 'Fund access',
    path: urls.ACCESS_URL,
    toUrl: (props) => urls.accessUrl(props),
  },
  {
    id: 'settings',
    name: 'Settings',
    path: urls.FUND_SETTINGS_URL,
    toUrl: (props) => urls.fundSettingsUrl(props),
  },
  {
    id: 'staff_tools',
    name: (
      <>
        Onboarding <InternalChip />
      </>
    ),
    path: urls.FUND_STAFF_TOOLS_URL,
    toUrl: (props) => urls.fundStaffToolsUrl(props),
  },
  {
    id: 'custom_approvals',
    name: (
      <>
        Custom approvals <InternalChip />
      </>
    ),
    path: urls.FUND_CUSTOM_APPROVALS_URL,
    toUrl: (props) => urls.fundCustomApprovalsUrl(props),
  },
];

function getMatchTabId(filteredTabs, history, fundId) {
  const matchTab = filteredTabs.find(
    (t) => t.toUrl({ fundId }) === history.location.pathname,
  );
  return matchTab ? matchTab.id : TABS[0].id;
}

export function FundNav() {
  const classes = useStyles();
  const [fund, isLoading, refreshFund] = useFund();
  const [fundEdit] = useFundEdit();
  const currency = useCurrency();
  const fundId = urls.useFundId();
  const { DILIGENCE_ON_SEPARATE_CLOSING } = useFeatureFlags();

  const [me] = useMe();
  const history = useHistory();
  const [targetDialogOpen, setTargetDialogOpen] = useState(false);

  const rightSideElement =
    me.isStaff && fund?.level === 'EDIT' ? <ExternalAccess /> : null;

  const filteredTabs = TABS.filter((tab) => {
    if (tab.id === 'diligence') {
      return DILIGENCE_ON_SEPARATE_CLOSING ? fund?.diligenceEnabled : false;
    }
    if (!me.isStaff && ['staff_tools', 'custom_approvals'].includes(tab.id)) {
      return false;
    }
    return true;
  });

  const [currentTab, setCurrentTab] = React.useState(
    getMatchTabId(filteredTabs, history, fundId),
  );

  React.useEffect(() => {
    const matchTabId = getMatchTabId(filteredTabs, history, fundId);
    setCurrentTab(matchTabId);
  }, [history.location.pathname, fundId, filteredTabs]);

  const crumbs = [];
  if (fund) {
    if (fund.isOrganizationAdmin) {
      crumbs.push({
        name: fund.organizationName,
        to: urls.organizationUrl({ organizationId: fund.organizationId }),
      });
    } else {
      crumbs.push({ name: fund.organizationName });
    }
    crumbs.push({ name: fund.name });
  }

  const description = isLoading ? null : (
    <div className={classes.fundraisingTargetFlex}>
      <Typography color="text.secondary">
        {fund.targetCommitment
          ? `Fundraising target ${formatCurrency(
              fund.targetCommitment,
              currency,
            )}`
          : 'No fundraising target'}
      </Typography>
      {fundEdit ? (
        <Button
          variant="icon"
          onClick={() => {
            setTargetDialogOpen(true);
          }}
          aria-label="Edit fundraising target"
        >
          <EditIcon />
        </Button>
      ) : null}
    </div>
  );

  return (
    <>
      <PageHeader
        heading={fund?.name}
        tabs={
          <Tabs
            value={currentTab}
            onChange={(ev, nextTab) => {
              setCurrentTab(nextTab);
            }}
          >
            {filteredTabs.map((tab) => (
              <Tab
                key={tab.id}
                label={tab.name}
                value={tab.id}
                href={tab.toUrl({ fundId })}
              />
            ))}
          </Tabs>
        }
        crumbs={crumbs.length ? <Breadcrumbs crumbs={crumbs || []} /> : null}
        description={description}
        actionButtons={rightSideElement}
        skeletons={[
          HeaderSkeleton.crumbs,
          HeaderSkeleton.heading,
          HeaderSkeleton.description,
        ]}
      />
      <TargetDialog
        fundId={fundId}
        initialTargetCommitment={fund?.targetCommitment}
        refreshData={() => {
          refreshFund((prevNum) => prevNum + 1);
        }}
        open={targetDialogOpen}
        onClose={() => setTargetDialogOpen(false)}
        currency={currency}
      />
    </>
  );
}
