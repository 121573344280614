import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';

import { Typography, Modal } from '@passthrough/uikit';
import { PDF_WIDTH, PDF_HEIGHT } from 'services/pdf_dimensions';
import { useToast } from 'services/toast';

export const BulkOutputShiftModal = ({
  open,
  isSaving,
  selectedQuestions,
  onClose,
  onSave,
}) => {
  const [xShift, setXShift] = useState(0);
  const [yShift, setYShift] = useState(0);
  const { toast } = useToast();

  const questionIdsThatCannotShift = selectedQuestions
    .filter((q) =>
      (q.hellosignBoxes || []).some(
        (box) =>
          box.x + xShift <= 0 ||
          box.x + box.w + xShift >= PDF_WIDTH ||
          box.y + yShift <= 0 ||
          box.y + box.h + yShift >= PDF_HEIGHT,
      ),
    )
    .map((q) => q.id);

  return (
    <Modal
      open={open}
      onClose={onClose}
      primaryButtonText="Save"
      primaryButtonLoading={isSaving}
      primaryButtonDisabled={
        (!xShift && !yShift) || questionIdsThatCannotShift.length > 0
      }
      headerLabel="Bulk output shift"
      onExited={() => {
        setXShift(0);
        setYShift(0);
      }}
      onSubmit={() => {
        onSave(xShift, yShift);
        onClose();
        toast(
          `${selectedQuestions.reduce(
            (total, question) => total + (question.hellosignBoxes || []).length,
            0,
          )} output boxes moved.`,
        );
      }}
      showCancelButton
    >
      <Typography>
        Each output box of the selected questions will shift its location by the
        specified x and y values.
      </Typography>

      <TextField
        value={xShift}
        onChange={(e) => {
          setXShift(Number(e.target.value));
        }}
        variant="outlined"
        required
        type="number"
        label="X shift"
      />

      <TextField
        value={yShift}
        onChange={(e) => {
          setYShift(Number(e.target.value));
        }}
        variant="outlined"
        required
        type="number"
        label="Y shift"
      />

      {questionIdsThatCannotShift.length > 0 ? (
        <Typography>
          The following questions contain output boxes that cannot be shifted by
          the specified amount: {questionIdsThatCannotShift.join(', ')}
        </Typography>
      ) : null}
    </Modal>
  );
};
