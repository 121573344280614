import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
} from '@material-ui/core';
import { Alert, InternalChip, Modal } from '@passthrough/uikit';
import * as api from 'services/api';
import { getSingleErrorFromResponse } from 'services/utils';
import { useToast } from 'services/toast';

const useStyles = makeStyles((theme) => ({
  internalHeader: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(1),
  },
}));

export function ExpireFundPermissionModal({
  open,
  onChange,
  onClose,
  fundId,
  members,
}) {
  const classes = useStyles();
  const { toast } = useToast();
  const [memberId, setMemberId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [memberToExpireData, setMemberToExpireData] = useState({});
  const [error, setError] = useState('');

  useEffect(() => {
    setMemberToExpireData({});
    if (!memberId) return;
    setIsLoading(true);
    api
      .getMemberToExpire({ fundId, memberId })
      .then((response) => {
        setMemberToExpireData(response.data);
      })
      .catch((e) => {
        setError(getSingleErrorFromResponse(e.response));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [memberId]);

  const handleExpire = () => {
    setIsLoading(true);
    const { name } = members.find((member) => member.id === memberId);
    api
      .expireMember({ fundId, memberId })
      .then(() => {
        onChange();
        onClose();
        toast(`Expired ${name}'s permission.`);
      })
      .catch((e) => {
        setError(getSingleErrorFromResponse(e.response));
        setIsLoading(false);
      });
  };

  const resetState = () => {
    setMemberId('');
    setIsLoading(false);
    setMemberToExpireData({});
    setError('');
  };

  return (
    <Modal
      headerLabel={
        <div className={classes.internalHeader}>
          <span>Expire fund access</span>
          <InternalChip />
        </div>
      }
      open={open}
      onClose={onClose}
      onExited={resetState}
      size="xs"
      primaryButtonText="Expire"
      destructive
      primaryButtonLoading={isLoading}
      primaryButtonDisabled={!memberId}
      onSubmit={handleExpire}
      showCancelButton
    >
      <Alert severity="warning">
        Prefer to delete a collaborator rather than expiring them. Use
        expiration only for quick access removal or when deletion isn't possible
        (like for a countersigner).
      </Alert>
      {error ? <Alert severity="error">{error}</Alert> : null}
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="member-to-expire">Member to expire</InputLabel>
        <Select
          label="Member to expire"
          labelId="member-to-expire"
          value={memberId}
          onChange={(e) => setMemberId(e.target.value)}
        >
          {members.map((member) => (
            <MenuItem key={member.id} value={member.id}>
              {member.name} ({member.email})
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {memberToExpireData?.warnings?.length ? (
        <Alert
          title="Try to fix these issues before expiring"
          severity="warning"
          skipTypography
        >
          <ul>
            {memberToExpireData.warnings.map((warning) => (
              <li key={warning}>{warning}</li>
            ))}
          </ul>
        </Alert>
      ) : null}
      {memberToExpireData?.info?.length ? (
        <Alert severity="info" skipTypography>
          <ul>
            {memberToExpireData.info.map((info) => (
              <li key={info}>{info}</li>
            ))}
          </ul>
        </Alert>
      ) : null}
    </Modal>
  );
}
