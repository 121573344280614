/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useQuestionnaire } from 'services/providers/questionnaire';
import { PdfViewer } from 'components/pdf';

const PdfContext = React.createContext();

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export function usePdf() {
  return useContext(PdfContext);
}

export function SDKPDFProvider({ downloadable, ...props }) {
  const [pdf, setPdf] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getPdf } = useQuestionnaire();
  const classes = useStyles();

  function onOpenPdf(doc, page) {
    setLoading(true);
    getPdf(doc).then((res) => {
      const { url, filledUrl } = res.data;

      setPdf({
        fileUrl: url,
        filledFileUrl: filledUrl,
        fileName: `${doc.name}.pdf`,
        docDisplayName: doc.name,
        page,
        fillable: doc.fillable,
      });
      setOpen(true);
      setLoading(false);
    });
  }

  return (
    <>
      <PdfContext.Provider value={onOpenPdf} {...props} />
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <PdfViewer
        downloadable={downloadable}
        fillable={pdf.fillable}
        fileUrl={pdf.fileUrl}
        filledFileUrl={pdf.filledFileUrl}
        fileName={pdf.fileName}
        docDisplayName={pdf.docDisplayName}
        initialPage={pdf.page}
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
}

export function PdfProvider({ downloadable, ...props }) {
  const [pdf, setPdf] = useState({});
  const [open, setOpen] = useState(false);
  const { getPdf } = useQuestionnaire();

  function onOpenPdf(doc, page) {
    getPdf(doc).then((res) => {
      const { url, filledUrl } = res.data;

      setPdf({
        fileUrl: url,
        filledFileUrl: filledUrl,
        fileName: `${doc.name}.pdf`,
        docDisplayName: doc.name,
        page,
        fillable: doc.fillable,
      });
      setOpen(true);
    });
  }

  return (
    <>
      <PdfContext.Provider value={onOpenPdf} {...props} />
      <PdfViewer
        downloadable={downloadable}
        fillable={pdf.fillable}
        fileUrl={pdf.fileUrl}
        filledFileUrl={pdf.filledFileUrl}
        fileName={pdf.fileName}
        docDisplayName={pdf.docDisplayName}
        initialPage={pdf.page}
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
}
