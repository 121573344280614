import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { Button } from 'components/button';
import { OfflineDisplay } from 'components/help_display/offline_display';
import { useWhiteLabelConfig } from 'services/providers/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  bottomSpacing: {
    marginBottom: theme.spacing(2),
  },
  helpText: {
    marginTop: '4px',
    fontSize: '14px',
    color: theme.palette.primary.grey,
    textAlign: 'center',
  },
}));

export function InitialDisplay({
  onSigningQuestion,
  handleClickComment,
  handleClose,
}) {
  const [showOfflineHelp, setShowOfflineHelp] = useState(false);
  const kustomerEnabled = Boolean(window.Kustomer);
  const classes = useStyles();
  const { productName } = useWhiteLabelConfig();

  const handleClickPassthrough = () => {
    if (kustomerEnabled) {
      window.Kustomer.open();
      handleClose();
    } else {
      setShowOfflineHelp(true);
    }
  };

  return (
    <>
      {showOfflineHelp ? (
        <OfflineDisplay
          handleClose={() => {
            setShowOfflineHelp(false);
          }}
        />
      ) : null}

      <div className={classes.root}>
        <div className={classes.bottomSpacing}>
          <Button
            variant="contained"
            fullWidth
            onClick={handleClickPassthrough}
          >
            {productName}
          </Button>

          <Typography className={classes.helpText}>
            Our team typically responds in an hour
          </Typography>
        </div>

        <div className={classes.bottomSpacing}>
          <Button
            fullWidth
            disabled={onSigningQuestion}
            onClick={handleClickComment}
          >
            This question
          </Button>

          {onSigningQuestion ? (
            <div>
              <Typography className={classes.helpText}>
                The Passthrough team can help you with this question.
              </Typography>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
