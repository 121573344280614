import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export function Spinner({ fullScreen, size, height, width }) {
  const inner = {
    position: 'absolute',
    margin: 'auto',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  };
  const matSize = size ? `${size}px` : '400px';
  const matHeight = height || matSize;
  const matWidth = width || matSize;
  const outer = {
    position: fullScreen || size ? 'static' : 'relative',
    height: fullScreen || size || height ? matHeight : '',
    width: fullScreen || size || width ? matWidth : '',
  };

  function theActualSpinner() {
    return (
      <div style={outer}>
        <CircularProgress style={inner} />
      </div>
    );
  }

  if (fullScreen) {
    return theActualSpinner();
  }

  return <div style={{ position: 'relative' }}>{theActualSpinner()}</div>;
}
