import React from 'react';
import { GenericAnswerDisplay } from './generic_display';

function formatName(answer) {
  return `${answer.firstName} ${answer.lastName}`;
}

export function NameDisplay({ answer, questionState, flatten }) {
  return (
    <GenericAnswerDisplay
      answer={answer}
      questionState={questionState}
      flatten={flatten}
      transformAnswer={(a) => formatName(a)}
    />
  );
}
