import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

import { Typography, Chip } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  footerRoot: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1.5, 2),
    display: 'flex',
    gap: theme.spacing(2),
  },
  navigationTip: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
}));

export function SearchFooter() {
  const classes = useStyles();

  return (
    <>
      <Divider />
      <div className={classes.footerRoot}>
        <div className={classes.navigationTip}>
          <Chip variant="neutral" label="↑" size="small" />
          <Chip variant="neutral" label="↓" size="small" />
          <Typography variant="label">To navigate</Typography>
        </div>

        <div className={classes.navigationTip}>
          <Chip variant="neutral" label="Return" size="small" />
          <Typography variant="label">To select</Typography>
        </div>

        <div className={classes.navigationTip}>
          <Chip variant="neutral" label="Esc" size="small" />
          <Typography variant="label">To close</Typography>
        </div>
      </div>
    </>
  );
}
