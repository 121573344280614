import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Radio from '@material-ui/core/Radio';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
}));

export const ExperienceSelectorItem = ({
  value,
  title,
  subtitle,
  selected,
  onClick,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <Paper variant="outlined" elevation={0} className={classes.root}>
      <ListItem button onClick={() => onClick(value)} disabled={disabled}>
        <ListItemIcon>
          <Radio checked={selected === value} />
        </ListItemIcon>
        <ListItemText primary={<strong>{title}</strong>} secondary={subtitle} />
      </ListItem>
    </Paper>
  );
};
