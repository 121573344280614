import React, { useEffect, useState, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useConfirm } from '@passthrough/uikit';

import { Dialog } from 'components/dialog/index';
import { ModalContent } from './content';
import {
  DATA_ENTRY_DISCARD_CHANGES_CONFIRMATION,
  STEP_DELIVERY_OPTION,
} from './constants';

const useDialogStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.default,
  },
}));

export function SendToCountersignersModal({
  open,
  handleClose,
  investors,
  onChange,
  disableFullyExecutedEmail,
  subdoc,
  members,
}) {
  const [dataEntryUpdated, setDataEntryUpdated] = useState(false);
  const [dataEntryDirty, setDataEntryDirty] = useState(false);
  const [wasSentToCountersigners, setWasSentToCountersigners] = useState(false);
  const [step, setStep] = useState(STEP_DELIVERY_OPTION);
  const dialogPaperRef = useRef(null);

  useEffect(() => {
    if (!open || !dialogPaperRef?.current) return;
    // Scroll to the top when changing steps
    dialogPaperRef.current.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, [open, step]);

  useEffect(() => {
    const confirmForBrowser = (e) => {
      if (dataEntryDirty) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.onbeforeunload = confirmForBrowser;
    return () => {
      window.onbeforeunload = null;
    };
  }, [dataEntryDirty]);

  const dialogClasses = useDialogStyles();
  const confirm = useConfirm();

  const closeWithConfirmation = () => {
    if (dataEntryDirty) {
      // TODO: Console errors
      // Uncaught RangeError: Maximum call stack size exceeded.
      // This dialog and the confirmation modal are both competing for focus?
      // disablePortal on the confirmation modal doesn't work because it's outside
      // of this component. disableEnforceFocus on the confirmation doesn't work
      // because keyboard events still go to the dialog.
      confirm(DATA_ENTRY_DISCARD_CHANGES_CONFIRMATION)
        .then(() => {
          setDataEntryDirty(false);
          handleClose();
        })
        .catch(() => {});
    } else {
      handleClose();
    }
  };

  const onExited = () => {
    setStep(STEP_DELIVERY_OPTION);
    if (dataEntryUpdated) {
      setDataEntryUpdated(false);
      onChange();
    } else if (wasSentToCountersigners) {
      setWasSentToCountersigners(false);
      onChange();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={closeWithConfirmation}
      fullScreen
      classes={dialogClasses}
      PaperProps={{
        ref: dialogPaperRef,
      }}
      TransitionProps={{
        onExited,
      }}
    >
      <ModalContent
        onCloseWithoutConfirmation={handleClose}
        onClose={closeWithConfirmation}
        investors={investors}
        setDataEntryUpdated={setDataEntryUpdated}
        setDataEntryDirty={setDataEntryDirty}
        setWasSentToCountersigners={setWasSentToCountersigners}
        step={step}
        setStep={setStep}
        disableFullyExecutedEmail={disableFullyExecutedEmail}
        closingSubDoc={subdoc}
        members={members}
      />
    </Dialog>
  );
}
