import { SUBSCRIPTION_DOCUMENT } from './constants';

// For the first iteration of bulk countersigning, we are only supporting
// bulk countersigning of subdocs, but we're passing lp document ids instead
// of lp closing ids to leave the door open to bulk countersign other document
// types. There's nothing stopping us from having different documents rolled
// into the same bulk countersign envelope.
export function collectLpDocumentsFromSubdocs(investors) {
  const lpDocuments = [];
  investors.forEach((investor) => {
    investor.docs.forEach((doc) => {
      if (doc.type === SUBSCRIPTION_DOCUMENT) {
        lpDocuments.push(doc);
      }
    });
  });
  return lpDocuments;
}

export function collectLpDocumentIdsFromSubdocs(investors) {
  return collectLpDocumentsFromSubdocs(investors).map((doc) => doc.id);
}

function getGroupKey(countersignerList) {
  return countersignerList.map((countersigner) => countersigner.id).join('-');
}

function sortedMembers(members) {
  const sorted = [...members];
  sorted.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
  );
  return sorted;
}

export function getCountersignerDataFromInvestors(investors) {
  const seenGroups = new Set();

  const signers = [new Map(), new Map(), new Map()];
  const [firstCountersigner, secondCountersigner, thirdCountersigner] = signers;

  investors.forEach((investor) => {
    const { countersigners } = investor;
    const groupKey = getGroupKey(countersigners);
    const shouldIncrementBulkCount = !seenGroups.has(groupKey);

    for (let i = 0; i < countersigners.length; i += 1) {
      const { id } = countersigners[i];
      const signerMap = signers[i];

      let signer;
      if (!signerMap.has(id)) {
        signer = {
          bulkPacketCount: 0,
          individualPacketCount: 0,
          ...countersigners[i],
        };
        signerMap.set(id, signer);
      } else {
        signer = signerMap.get(id);
      }

      if (shouldIncrementBulkCount || signer.bulkPacketCount === 0) {
        signer.bulkPacketCount += 1;
      }
      signer.individualPacketCount += 1;
    }

    seenGroups.add(groupKey);
  });

  return {
    signers: {
      first: sortedMembers(Array.from(firstCountersigner.values())),
      second: sortedMembers(Array.from(secondCountersigner.values())),
      third: sortedMembers(Array.from(thirdCountersigner.values())),
    },
    numSigners:
      (firstCountersigner.size > 0 ? 1 : 0) +
      (secondCountersigner.size > 0 ? 1 : 0) +
      (thirdCountersigner.size > 0 ? 1 : 0),
  };
}

export function countersignerIsMember(countersigner, members) {
  return members.some((member) => member.id === countersigner);
}
