import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@passthrough/uikit';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
}));

export function DateDisplay({ dateStr }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="body" size="small">
        {dateStr}
      </Typography>
    </div>
  );
}
