import { BoxType } from './box_type';
import * as constants from './constants';

export function boxCanToggleRequiredStatus(boxType) {
  return !(boxType === BoxType.CHECKBOX || boxType === BoxType.DATE);
}

export function getSignerColor(signer) {
  return constants.signers.find((s) => s.pdfBoxSigner === signer).color;
}

export function determineIfBoxesAreInvalid(boxes) {
  const boxesContainPrimarySigner = boxes.some(
    (box) => box.signer === constants.FIRST_SIGNER,
  );
  const boxesContainJointSigners = boxes.some(
    (box) =>
      box.signer === constants.SECOND_SIGNER ||
      box.signer === constants.THIRD_SIGNER ||
      box.signer === constants.FOURTH_SIGNER ||
      box.signer === constants.FIFTH_SIGNER ||
      box.signer === constants.SIXTH_SIGNER,
  );

  const areBoxesInvalid =
    boxesContainJointSigners && !boxesContainPrimarySigner;
  return areBoxesInvalid;
}

export function throttle(f) {
  let token = null;
  let lastArgs = null;
  const invoke = () => {
    f(...lastArgs);
    token = null;
  };
  const result = (...args) => {
    lastArgs = args;
    if (!token) {
      token = requestAnimationFrame(invoke);
    }
  };
  result.cancel = () => {
    if (token) {
      cancelAnimationFrame(token);
      token = null;
    }
  };
  return result;
}
