import React, { useState } from 'react';

import { Modal, Typography } from '@passthrough/uikit';
import { GPSelectionInput } from 'components/gp_selection_input/index';
import * as constants from 'components/gp_selection_input/constants';
import { useDiscardConfirmation } from 'services/discard';
import { objectEquals } from 'services/utils';
import { JURISDICTIONS } from 'components/jurisdiction_select';

export function DiligenceSettingsModal({
  open,
  onClose,
  diligenceJurisdiction,
  members,
  fundId,
  initialState,
  errors,
  setErrors,
  updateSettings,
  loading,
}) {
  const [form, setForm] = useState(initialState);
  const closeWithConfirmation = useDiscardConfirmation({
    onConfirm: onClose,
    needConfirmation: open && !objectEquals(form, initialState),
  });

  return (
    <Modal
      open={open}
      onClose={closeWithConfirmation}
      headerLabel="Edit diligence settings"
      showCancelButton
      primaryButtonText="Save"
      primaryButtonLoading={loading}
      onEntering={() => {
        setForm(initialState);
        setErrors({});
      }}
      onSubmit={() => updateSettings(form)}
    >
      <div>
        <Typography variant="body" size="small">
          Jurisdiction
        </Typography>
        <Typography>
          {JURISDICTIONS.find((j) => j.id === diligenceJurisdiction)?.name}
        </Typography>
      </div>

      <GPSelectionInput
        label="Default diligence reviewer"
        errorMsg={errors.defaultDiligenceApproverId}
        value={form.defaultDiligenceApproverId || ''}
        setValue={(value) =>
          setForm({ ...form, defaultDiligenceApproverId: value })
        }
        roleLabelNum={constants.DEFAULT_DILIGENCE_APPROVER_LABEL_NUM}
        members={members}
        fundId={fundId}
        fundPermissionLevel={constants.ACCESS_LEVEL_REVIEW}
        includeBlankOption={false}
      />
    </Modal>
  );
}
