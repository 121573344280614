import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { Typography } from '@passthrough/uikit';

import { Spinner } from 'components/spinner';
import { EmptyState } from 'components/empty_v2';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(3),
  },
  errorText: {
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  // hide with css instead of unmounting the component to keep
  // the state in NoteBox from being cleared
  hide: {
    display: 'none',
  },
}));

export function ActivityCard({
  question,
  showDivider,
  showFullQuestionnaire,
  hasUnresolvedThread,
  isLoading,
  TimelineComponent,
  NoteBoxComponent,
  errorMsg,
}) {
  const classes = useStyles();

  if (!question?.isAsked && !showFullQuestionnaire && !hasUnresolvedThread) {
    return null;
  }

  return (
    <>
      {isLoading ? <Spinner size={150} /> : null}

      <div
        className={clsx(classes.root, {
          [classes.hide]: isLoading,
        })}
      >
        {showDivider ? <Divider /> : null}
        <Typography variant="card-heading" size="medium">
          Activity
        </Typography>

        {errorMsg ? <div className={classes.errorText}>{errorMsg}</div> : null}

        {TimelineComponent || <EmptyState title="No activity" />}
      </div>

      <div
        className={clsx({
          [classes.hide]: isLoading,
        })}
      >
        {NoteBoxComponent}
      </div>
    </>
  );
}
