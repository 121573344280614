import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Typography } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

export function MarkReviewModal({ open, onClose, onSubmit }) {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      headerLabel={<div className={classes.header}>Mark match as reviewed</div>}
      primaryButtonText="Mark as reviewed"
      onClose={onClose}
      showCancelButton
      onSubmit={onSubmit}
    >
      <Typography variant="body2">
        You will dismiss all updates for this match. This action cannot be
        undone.
      </Typography>
    </Modal>
  );
}
