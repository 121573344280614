import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import Badge from '@material-ui/core/Badge';

import { Chip } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  shiftedBadge: {
    '& .MuiBadge-badge': {
      transform: 'translate(20%, -30%)',
    },
  },
  chipRoot: {
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
}));

export function ThreadCountChip({
  threadIsActionable,
  threadCount,
  className,
}) {
  const classes = useStyles();

  return (
    <Badge
      invisible={!threadIsActionable}
      color="primary"
      variant="dot"
      className={clsx(className, {
        [classes.shiftedBadge]: threadIsActionable,
      })}
    >
      <Chip
        label={threadCount}
        size="small"
        icon={<ChatOutlinedIcon />}
        variant={threadIsActionable ? 'success' : 'neutral'}
      />
    </Badge>
  );
}
