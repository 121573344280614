import React, { cloneElement } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { Button } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  selectedButton: {
    backgroundColor: `${theme.palette.action.hover} !important`,
  },
  textButton: {
    justifyContent: 'left !important',
  },
}));

export function BoxTypeListItem({
  onClick,
  boxType,
  selectedBoxType,
  icon,
  text,
  signer,
}) {
  const classes = useStyles();
  const isSelectedBoxType = selectedBoxType === boxType;

  return (
    <Button
      className={clsx({ [classes.selectedButton]: isSelectedBoxType })}
      fullWidth
      onClick={onClick}
      startIcon={cloneElement(icon, { style: { color: signer.color } })}
      variant="text"
      classes={{ text: classes.textButton }}
      ellipsis
    >
      {text}
    </Button>
  );
}
