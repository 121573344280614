import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { useToast } from 'services/toast';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Button } from 'components/button';
import Paper from '@material-ui/core/Paper';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
  InternalChip,
  Link,
  Button as PassButton,
  UIKitSettingsProvider,
} from '@passthrough/uikit';

import * as api from 'services/api';
import { useMe } from 'services/providers/me';
import { useFundEdit } from 'services/providers/fund';
import { reducerActionTypes, matchStatuses } from '../constants';
import { NoMatchExplanationModal } from './no_match_explanation_modal';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(1),
  },
  paperRoot: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    width: '100%',
  },
  unevaluatedHelpText: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    columnGap: theme.spacing(1),
    fontSize: '14px',
    color: theme.palette.primary.grey,
  },
  loadingRoot: {
    minHeight: '72px',
  },
  isFalseMatchBackground: {
    backgroundColor: theme.palette.success.background,
    borderColor: theme.palette.success.main,
  },
  isTrueMatchBackground: {
    backgroundColor: theme.palette.error.background,
    borderColor: theme.palette.error.main,
  },
  matchTextContainer: {
    flex: 4,
    display: 'flex',
    gap: theme.spacing(1),
  },
  matchContainer: {
    display: 'flex',
    alignItems: 'start',
    columnGap: theme.spacing(2),
  },
  orangeIcon: {
    color: theme.palette.error.main,
  },
  greyIcon: {
    color: theme.palette.primary.grey,
  },
  greyText: {
    color: theme.palette.primary.grey,
  },
  errorMsg: {
    textAlign: 'center',
  },
  evaluationButtons: {
    display: 'flex',
    columnGap: theme.spacing(1),
  },
  noButton: {
    fontWeight: '500',
    backgroundColor: theme.palette.background.paper,
  },
  trueMatchLabel: {
    color: theme.palette.error.text,
    fontWeight: '500',
  },
  undoButton: {
    alignSelf: 'flex-start',
  },
  notAMatchLabel: {
    color: theme.palette.success.text,
    fontSize: '1rem',
    fontWeight: '500',
  },
  notSureLabel: {
    color: theme.palette.primary.black,
    fontSize: '1rem',
    fontWeight: '500',
  },
  unevaluatedLabel: {
    fontWeight: '500',
    fontSize: '1rem',
    display: 'flex',
    gap: theme.spacing(1),
  },
  explanationsPaperRoot: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function getDisplayText(status) {
  switch (status) {
    case matchStatuses.TRUE_MATCH:
      return 'a true match';
    case matchStatuses.NOT_SURE:
      return 'an unsure match';
    case matchStatuses.NOT_TRUE_MATCH:
      return 'not a true match';
    default:
      return '';
  }
}

function getToastText(status, name) {
  if (status === matchStatuses.NO_CHOICE) {
    return `Unmarked ${name}.`;
  }

  const evaluatedText = getDisplayText(status);
  return `Marked ${name} as ${evaluatedText}.`;
}

export function TrueMatchSelection({
  name,
  selectedId,
  matchStatus,
  questionId,
  explanations,
  managedDiligenceEnabled,
  showMatchStatus,
  dispatch,
}) {
  const classes = useStyles();
  const { fundId, lpClosingId } = useParams();
  const { toast } = useToast();
  const [me] = useMe();
  const [fundEdit] = useFundEdit();

  const [errorText, setErrorText] = useState('');
  const [
    showNotTrueMatchExplanationModal,
    setShowNotTrueMatchExplanationModal,
  ] = useState(false);

  const isUnevaluatedMatch =
    !showMatchStatus || matchStatus === matchStatuses.NO_CHOICE;
  const isTrueMatch =
    showMatchStatus && matchStatus === matchStatuses.TRUE_MATCH;
  const isNotSure = showMatchStatus && matchStatus === matchStatuses.NOT_SURE;
  const isFalseMatch =
    showMatchStatus && matchStatus === matchStatuses.NOT_TRUE_MATCH;

  const isEvaluatedMatch = isTrueMatch || isFalseMatch || isNotSure;
  const showAlertsHelpText = isUnevaluatedMatch || isTrueMatch || isNotSure;

  const enableNoMatchExplanations = managedDiligenceEnabled && me.isStaff;
  // check if evaluated so that we don't display the explanations
  // as the match fades away and the next one appears
  const displayNoMatchExplanations = isFalseMatch && enableNoMatchExplanations;
  const isSelfServiceOrStaff = !managedDiligenceEnabled || me.isStaff;
  // allow choose for category match for self service or staff
  // user managed service with edit access
  const canChooseCategoryMatch = isSelfServiceOrStaff && fundEdit;

  useEffect(() => {
    setErrorText('');
  }, [selectedId]);

  const handleMatchStatusUpdate = (
    matchId,
    newMatchStatus,
    newExplanations = [],
  ) => {
    setErrorText('');

    api
      .updateDiligenceMatchStatus({
        fundId,
        lpClosingId,
        questionId,
        matchId,
        matchStatus: newMatchStatus,
        explanations: newExplanations,
      })
      .then(() => {
        dispatch({
          type: reducerActionTypes.UPDATE_MATCH_STATUS,
          matchId,
          newMatchStatus,
          newExplanations,
        });

        if (newMatchStatus !== matchStatuses.NO_CHOICE) {
          dispatch({
            type: reducerActionTypes.AUTO_SELECT_NEXT_ID,
          });
        }

        toast(getToastText(newMatchStatus, name));
      })
      .catch(() => {
        setErrorText("An error occurred while evaluating this match's status.");
      });
  };

  return (
    <>
      {enableNoMatchExplanations ? (
        <NoMatchExplanationModal
          internal
          open={showNotTrueMatchExplanationModal}
          onClose={() => {
            setShowNotTrueMatchExplanationModal(false);
          }}
          onSubmit={(newExplanations) => {
            handleMatchStatusUpdate(
              selectedId,
              matchStatuses.NOT_TRUE_MATCH,
              newExplanations,
            );
          }}
        />
      ) : null}

      <div className={classes.container}>
        {canChooseCategoryMatch ? (
          <Paper
            variant="outlined"
            className={clsx(classes.paperRoot, {
              [classes.isFalseMatchBackground]: isFalseMatch,
              [classes.isTrueMatchBackground]: isTrueMatch,
            })}
          >
            <div className={classes.matchTextContainer}>
              {isUnevaluatedMatch ? (
                <Typography className={classes.unevaluatedLabel}>
                  Is this a true match?
                </Typography>
              ) : null}

              {isFalseMatch ? (
                <Typography className={classes.notAMatchLabel}>
                  Not a match
                </Typography>
              ) : null}

              {isTrueMatch ? (
                <div className={classes.matchContainer}>
                  <NotificationsIcon className={classes.orangeIcon} />

                  <Typography className={classes.trueMatchLabel}>
                    True match
                  </Typography>
                </div>
              ) : null}

              {isNotSure ? (
                <div className={classes.matchContainer}>
                  <NotificationsIcon className={classes.greyIcon} />

                  <Typography className={classes.notSureLabel}>
                    Unsure match
                  </Typography>
                </div>
              ) : null}
              {managedDiligenceEnabled ? <InternalChip /> : null}
            </div>

            {isUnevaluatedMatch ? (
              <div className={classes.evaluationButtons}>
                <PassButton
                  variant="text"
                  onClick={() =>
                    handleMatchStatusUpdate(selectedId, matchStatuses.NOT_SURE)
                  }
                  startIcon={<NotificationsIcon />}
                >
                  Not sure
                </PassButton>

                <PassButton
                  variant="secondary"
                  destructive
                  onClick={() =>
                    handleMatchStatusUpdate(
                      selectedId,
                      matchStatuses.TRUE_MATCH,
                    )
                  }
                  startIcon={<NotificationsIcon />}
                >
                  Yes
                </PassButton>

                <Button
                  aria-label="not_match"
                  data-test="not_match"
                  onClick={() => {
                    if (enableNoMatchExplanations) {
                      setShowNotTrueMatchExplanationModal(true);
                    } else {
                      handleMatchStatusUpdate(
                        selectedId,
                        matchStatuses.NOT_TRUE_MATCH,
                      );
                    }
                  }}
                  className={classes.noButton}
                >
                  No
                </Button>
              </div>
            ) : null}

            {isEvaluatedMatch ? (
              <Button
                variant="text"
                onClick={() =>
                  handleMatchStatusUpdate(selectedId, matchStatuses.NO_CHOICE)
                }
                className={classes.undoButton}
              >
                Undo
              </Button>
            ) : null}
          </Paper>
        ) : null}

        {showAlertsHelpText ? (
          <div className={classes.unevaluatedHelpText}>
            <NotificationsIcon />

            <Typography>
              You will be notified when new alerts are available.
            </Typography>

            <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
              <Link
                href="https://support.passthrough.com/en_us/monitored-alerts-on-matches-for-investors-ByhWRQjcs"
                inline
                variant="external"
              >
                Learn more
              </Link>
            </UIKitSettingsProvider>
          </div>
        ) : null}

        {displayNoMatchExplanations ? (
          <div className={classes.explanationsPaperRoot}>
            {explanations.length > 0
              ? `Not a true match due to the following discrepancies: ${explanations.join(
                  ', ',
                )}`
              : 'No explanation was provided for why this hit is not a true match.'}
          </div>
        ) : null}

        {errorText ? (
          <FormHelperText error className={classes.errorMsg}>
            {errorText}
          </FormHelperText>
        ) : null}
      </div>
    </>
  );
}
