import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@passthrough/uikit';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';

import { NotProvidedChip } from 'components/text_chip';
import { DiffStyle } from 'components/Diff';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 0),
  },
  flexRoot: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(1),
  },
  childrenNameContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  noChildDataDisplay: {
    padding: theme.spacing(2, 0),
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(1),
    justifyContent: 'space-between',
  },
  typeLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    overflowWrap: 'anywhere',
    columnGap: theme.spacing(1),
  },
  noKidsIcon: {
    color: theme.palette.primary.lightGrey,
  },
  extraPadding: {
    paddingLeft: '4px',
  },
}));

function OwnerName({ variant, children }) {
  const classes = useStyles();
  return (
    <DiffStyle
      isNew={variant === 'new'}
      isDeleted={variant === 'removed'}
      className={clsx({
        [classes.extraPadding]: variant === 'current',
      })}
    >
      {children}
    </DiffStyle>
  );
}

export function SingleChildTypeDisplay({
  typeLabel,
  currChildren,
  lastChildren,
  className,
}) {
  const classes = useStyles();

  const newChildren = lastChildren
    ? currChildren.filter(
        (childData) =>
          !lastChildren?.find((lastChild) => lastChild.id === childData.id),
      )
    : [];

  const removedChildren = lastChildren
    ? lastChildren?.filter(
        (lastChild) =>
          lastChild.name &&
          !currChildren.find((childData) => childData.id === lastChild.id),
      )
    : [];

  const commonChildren = lastChildren
    ? currChildren.filter((childData) =>
        lastChildren?.find(
          (lastChild) => lastChild.name && lastChild.id === childData.id,
        ),
      )
    : currChildren || [];

  const hasChildrenData =
    newChildren.length > 0 ||
    commonChildren.length > 0 ||
    removedChildren.length > 0;

  if (!hasChildrenData) {
    return (
      <div className={clsx(classes.root, classes.noChildDataDisplay)}>
        <div className={classes.typeLabelContainer}>
          <SubdirectoryArrowRightIcon className={classes.noKidsIcon} />

          <Typography variant="label" color="text.secondary">
            {typeLabel}
          </Typography>
        </div>

        <NotProvidedChip />
      </div>
    );
  }

  return (
    <div className={clsx(classes.root, classes.flexRoot, className)}>
      <div className={classes.headerContainer}>
        <div className={classes.typeLabelContainer}>
          <SubdirectoryArrowRightIcon />

          <Typography variant="card-heading" color="text.primary">
            {typeLabel}
          </Typography>
        </div>
      </div>

      <div className={classes.childrenNameContainer}>
        {newChildren.map((childData) => (
          <OwnerName variant="new" key={childData.id}>
            {childData.name}
          </OwnerName>
        ))}

        {commonChildren.map((childData) => (
          <OwnerName variant="current" key={childData.id}>
            {childData.name}
          </OwnerName>
        ))}

        {removedChildren.map((childData) => (
          <OwnerName variant="removed" key={childData.id}>
            {childData.name}
          </OwnerName>
        ))}
      </div>
    </div>
  );
}
