// cspell:words Phundraiser
import { Phundraiser } from './phundraiser';
import { GoodwinEmbark } from './goodwin_embark';

export const CUSTOM_SIGNUP = {
  PHUNDRAISER: Phundraiser,
  GOODWIN_EMBARK: GoodwinEmbark,
};

export const showCustomSignup = ({ me, config }) => {
  const hasCustomSignup = Object.keys(CUSTOM_SIGNUP).includes(config.terms);

  if (hasCustomSignup) {
    return (
      me.requiresPlatformTerms || !me.acceptedTermsAndPrivacyAt || !me.firstName
    );
  }

  return false;
};
