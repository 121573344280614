/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MaterialLink from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
    color: theme.palette.text.link,
  },
}));

export function DisplayLink(props) {
  // A component that looks like a link,
  // but doesn't necessarily go to another page
  const classes = useStyles();
  const { className } = props;

  return (
    <MaterialLink
      component="span"
      {...props}
      className={clsx(classes.link, className)}
    />
  );
}

export function TrueLink(props) {
  return (
    <DisplayLink
      component="a"
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    />
  );
}

export function Link(props) {
  const classes = useStyles();

  const { className } = props;

  return (
    <MaterialLink
      component={RouterLink}
      {...props}
      className={clsx(classes.link, className)}
    />
  );
}

export const AdapterLink = React.forwardRef((props, ref) => (
  <RouterLink innerRef={ref} {...props} />
));
