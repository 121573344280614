import React from 'react';
import { Button, Alert } from '@passthrough/uikit';

export function CountersignAlert({ numCountersignTasks, handleRedirect }) {
  return (
    <Alert
      severity="success"
      title="Ready for countersigning"
      action={
        <Button
          variant="primary"
          size="small"
          onClick={handleRedirect}
          data-test="countersigner-task"
        >
          Go to tasks
        </Button>
      }
    >
      {numCountersignTasks > 1
        ? `There are ${numCountersignTasks} documents awaiting your countersignature.`
        : 'There is 1 document awaiting your countersignature.'}
    </Alert>
  );
}
