import React from 'react';
import { Typography } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2, 3),
    backgroundColor: theme.palette.table.header,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderTop: `1px solid ${theme.palette.divider}`,
    width: '100%',
  },
}));

export const Header = ({ title, description, action }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <Typography variant="card-heading">{title}</Typography>
        <Typography variant="label">{description}</Typography>
      </div>
      {action ? <div>{action}</div> : null}
    </div>
  );
};
