import React, { useState } from 'react';
import { Alert } from 'components/alert';
import TextField from '@material-ui/core/TextField';
import PersonIcon from '@material-ui/icons/PersonOutlineOutlined';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import {
  Link,
  Modal,
  Typography,
  UIKitSettingsProvider,
} from '@passthrough/uikit';

import { KeyValuePair, KeyValuePairs } from 'components/key_value';
import { useToast } from 'services/toast';
import * as api from 'services/api';

export function AddPersonModal({
  open,
  onClose,
  fundId,
  lpId,
  onChange,
  someClosingInActiveStatus,
}) {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [notify, setNotify] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  function onSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    api
      .investorAddUser({
        fundId,
        lpId,
        email,
        name,
        notify: notify && someClosingInActiveStatus,
      })
      .then(() => {
        setNameError('');
        setEmailError('');
        toast(`Added ${name}`);
        onChange();
        onClose();
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setNameError(error.response.data.name);
          setEmailError(error.response.data.email);
          setIsLoading(false);
        }
      });
  }

  const clear = () => {
    setName('');
    setNameError('');
    setEmail('');
    setEmailError('');
    setNotify(true);
  };

  return (
    <Modal
      open={open}
      size="sm"
      headerLabel="Add person"
      onClose={onClose}
      showCancelButton
      primaryButtonText="Add"
      primaryButtonLoading={isLoading}
      onSubmit={onSubmit}
      onExited={clear}
    >
      <Alert
        severity="info"
        action={
          <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
            <Link
              variant="external"
              href="https://support.passthrough.com/en_us/how-to-add-new-collaborators-to-an-investor-account-HkisBcEn9"
            >
              Learn more
            </Link>
          </UIKitSettingsProvider>
        }
      >
        Grant access to complete and sign documents.
      </Alert>
      <form onSubmit={onSubmit} autoComplete="off">
        <KeyValuePairs>
          <KeyValuePair input disableGutters icon={<PersonIcon />}>
            <TextField
              autoFocus
              id="name"
              variant="outlined"
              label="Name"
              type="text"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={!!nameError}
              helperText={nameError}
            />
          </KeyValuePair>
          <KeyValuePair input disableGutters icon={<EmailIcon />}>
            <TextField
              required
              id="email"
              variant="outlined"
              label="Email"
              type="email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!emailError}
              helperText={emailError}
            />
          </KeyValuePair>
        </KeyValuePairs>
        {someClosingInActiveStatus ? (
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={notify}
                  onChange={(e) => setNotify(e.target.checked)}
                  name="notify"
                />
              }
              label="Notify them by email."
            />
          </FormControl>
        ) : (
          <Typography>They will not be notified at this moment.</Typography>
        )}
      </form>
    </Modal>
  );
}
