import {
  CLOSING_COLUMN,
  CREATED_COLUMN,
  FUND_COLUMN,
  INVESTOR_COLUMN,
  ISSUE_COLUMN,
  LEGAL_NAME_COLUMN,
} from './constants';

export function sortTasks(order, tasks) {
  if (tasks === null) {
    return null;
  }

  const sortedTasks = [...tasks];
  const [columnId, direction] = order;

  const issueComparison = {
    'newAlert-TRUE_MATCH': 4,
    'newAlert-NOT_SURE': 3,
    review: 2,
    comment: 1,
    waived: 2,
    potentialMatch: 1,
  };

  function sortByIssue(a, b, dir) {
    if (dir === 'desc') {
      return issueComparison[b.issue] - issueComparison[a.issue];
    }
    return issueComparison[a.issue] - issueComparison[b.issue];
  }

  sortedTasks.sort((a, b) => {
    const issueComp = sortByIssue(a, b, direction);
    const aLegalName = a.legalName ? a.legalName : '';
    const bLegalName = b.legalName ? b.legalName : '';
    switch (columnId) {
      case ISSUE_COLUMN:
        if (issueComp === 0) {
          if (direction === 'desc') {
            return new Date(a.submissionTime) - new Date(b.submissionTime);
          }
          return new Date(b.submissionTime) - new Date(a.submissionTime);
        }
        return issueComp;
      case CREATED_COLUMN:
        if (direction === 'desc') {
          return new Date(a.submissionTime) - new Date(b.submissionTime);
        }
        return new Date(b.submissionTime) - new Date(a.submissionTime);
      case LEGAL_NAME_COLUMN:
        if (direction === 'desc') {
          return aLegalName.localeCompare(bLegalName);
        }
        return bLegalName.localeCompare(aLegalName);
      case INVESTOR_COLUMN:
        if (direction === 'desc') {
          return a.lpName.localeCompare(b.lpName);
        }
        return b.lpName.localeCompare(a.lpName);
      case CLOSING_COLUMN:
        if (direction === 'desc') {
          return a.closingName.localeCompare(b.closingName);
        }
        return b.closingName.localeCompare(a.closingName);
      case FUND_COLUMN:
        if (direction === 'desc') {
          return a.fundName.localeCompare(b.fundName);
        }
        return b.fundName.localeCompare(a.fundName);
      default:
        if (direction === 'desc') {
          return a > b;
        }
        return b > a;
    }
  });

  return sortedTasks;
}

function normalizeString(str) {
  return str.toLowerCase().replace(/\s/g, '');
}

function taskMatchesQuery(task, query) {
  const normalizedQuery = normalizeString(query);
  if (normalizedQuery.length === 0) {
    return true;
  }

  const normalizedFundName = normalizeString(task.fundName);
  if (normalizedFundName.includes(normalizedQuery)) {
    return true;
  }

  const normalizedClosingName = normalizeString(task.closingName);
  if (normalizedClosingName.includes(normalizedQuery)) {
    return true;
  }

  const normalizedInvestorName = normalizeString(task.lpName);
  if (normalizedInvestorName.includes(normalizedQuery)) {
    return true;
  }

  if (task.legalName) {
    const normalizedLegalName = normalizeString(task.legalName);
    if (normalizedLegalName.includes(normalizedQuery)) {
      return true;
    }
  }

  return false;
}

export function filterByQuery(query, tasks) {
  return tasks.filter((task) => taskMatchesQuery(task, query));
}
