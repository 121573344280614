import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Alert } from 'components/alert';
import { KeyValuePair, KeyValuePairs } from 'components/key_value';
import MessageIcon from '@material-ui/icons/MessageOutlined';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';

import { Button } from 'components/button';
import * as api from 'services/api';
import { useToast } from 'services/toast';
import { getSingleErrorFromResponse } from 'services/utils';

import { ACCESS_LEVELS } from '../../constants';
import { DialogStep } from '../../dialog_step';
import { ReviewCollaborator } from './review_collaborator';
import { ReviewInvestments } from './review_investments';

const useStyles = makeStyles((theme) => ({
  error: {
    marginBottom: theme.spacing(1),
  },
  addButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  checkbox: {
    marginTop: theme.spacing(1),
    alignItems: 'flex-start',
  },
  fullAccessTableContainer: {
    marginTop: theme.spacing(3),
  },
  fullAccessTableText: {
    marginBottom: theme.spacing(1),
  },
}));

const emptyForm = () => ({
  name: '',
  email: '',
  message: '',
  fullAccess: false,
});

export const StepUserAdd = ({
  lpClosingId,
  profileId,
  isProfileOwner,
  profileDisplayName,
  onBack,
  onSubmit,
  onClose,
}) => {
  const classes = useStyles();
  const [form, setForm] = React.useState(emptyForm());
  const [showReview, setShowReview] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [errorMsg, setErrorMsg] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [profileInvestments, setProfileInvestments] = React.useState(null);
  const { toast, errorToast } = useToast();

  const handleUpdate = (field) => (e) =>
    setForm({ ...form, [field]: e.target.value });

  const setAccessLevel = (e, checked) =>
    setForm({ ...form, fullAccess: checked });

  function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    api
      .createLpClosingCollaborator({
        lpClosingId,
        name: form.name.trim(),
        email: form.email.toLowerCase().trim(),
        accessLevel: form.fullAccess
          ? ACCESS_LEVELS.OWNER.apiValue
          : ACCESS_LEVELS.ONLY_THIS_INVESTMENT.apiValue,
        message: form.message.trim(),
      })
      .then(() => {
        setForm(emptyForm());
        setErrorMsg('');
        onSubmit();
        onBack();
        setIsLoading(false);
        toast(`Added ${form.name} (${form.email}) as a collaborator.`);
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          if (error.response?.data?.email) {
            setErrors({ email: error.response.data.email });
          } else if (error.response?.data?.name) {
            setErrors({ name: error.response.data.name });
          } else {
            setErrorMsg(getSingleErrorFromResponse(error.response));
          }
        } else {
          errorToast('An unexpected error occurred.');
        }
        setShowReview(false);
        setIsLoading(false);
      });
  }

  React.useEffect(() => {
    if (isProfileOwner) {
      api.getProfileInvestments(profileId, lpClosingId).then((response) => {
        setProfileInvestments(response.data);
      });
    } else {
      setProfileInvestments(null);
    }
  }, [isProfileOwner]);

  if (showReview) {
    return (
      <DialogStep
        title="Invite signatory or collaborator"
        onClose={onClose}
        onBack={() => setShowReview(false)}
      >
        <ReviewCollaborator
          form={form}
          loading={isLoading}
          onConfirm={handleSubmit}
          profileInvestments={profileInvestments}
          isProfileOwner={isProfileOwner}
        />
      </DialogStep>
    );
  }

  return (
    <DialogStep
      title="Invite signatory or collaborator"
      onClose={onClose}
      onBack={onBack}
    >
      <form onSubmit={onSubmit} autoComplete="off">
        <Typography paragraph>
          New collaborator will be notified by email.
        </Typography>
        <Typography paragraph>
          They can add and remove people, view and edit information for only
          this investment.
        </Typography>
        {errorMsg ? (
          <Alert severity="error" className={classes.error}>
            {errorMsg}
          </Alert>
        ) : null}
        <KeyValuePairs>
          <KeyValuePair input icon={<PersonIcon />}>
            <TextField
              id="name"
              variant="outlined"
              label="Name"
              type="text"
              value={form.name}
              onChange={handleUpdate('name')}
              error={!!errors.name}
              helperText={errors.name}
              fullWidth
            />
          </KeyValuePair>
          <KeyValuePair input icon={<EmailIcon />}>
            <TextField
              id="email"
              variant="outlined"
              label="Email"
              type="email"
              value={form.email}
              onChange={handleUpdate('email')}
              error={!!errors.email}
              helperText={errors.email}
              fullWidth
            />
          </KeyValuePair>
          <KeyValuePair input icon={<MessageIcon />}>
            <TextField
              id="message"
              label="Include a message (optional)"
              value={form.message}
              onChange={handleUpdate('message')}
              variant="outlined"
              multiline
              minRows={4}
              fullWidth
              helperText={
                errors.message || 'This message will appear in the email.'
              }
              error={!!errors.message}
            />
          </KeyValuePair>
        </KeyValuePairs>
        {isProfileOwner ? (
          <>
            <Typography variant="subtitle2">Additional access</Typography>
            <FormControl required fullWidth>
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox
                    name="grant-full-access"
                    onChange={setAccessLevel}
                    checked={form.fullAccess}
                  />
                }
                label={
                  <span>
                    <Typography>
                      Give them full access to the investor profile for{' '}
                      {profileDisplayName}.
                    </Typography>
                    <Typography variant="body2">
                      They can add and remove people from the profile, view and
                      edit profile data, manage all of this profile's
                      investments.
                    </Typography>
                  </span>
                }
              />
            </FormControl>
          </>
        ) : null}
        {form.fullAccess && profileInvestments?.length ? (
          <ReviewInvestments investments={profileInvestments} />
        ) : null}
        <div className={classes.addButton}>
          <Button
            type="submit"
            onClick={() => setShowReview(true)}
            variant="contained"
            color="primary"
            disabled={
              !form.name ||
              !form.email ||
              (form.fullAccess && !profileInvestments)
            }
            fullWidth
            loading={isLoading}
          >
            Next: Review
          </Button>
        </div>
      </form>
    </DialogStep>
  );
};
