import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Modal } from '@passthrough/uikit';

import { useToast } from 'services/toast';
import * as api from 'services/api';

function unique(array, propertyName) {
  return array.filter(
    (e, i) => array.findIndex((a) => a[propertyName] === e[propertyName]) === i,
  );
}

export function RemoveDocumentDialog({
  open,
  onChange,
  handleClose,
  investors,
}) {
  const { fundId, closingId } = useParams();
  const { successToast } = useToast();

  const [documentId, setDocumentId] = useState('');
  const [documentError, setDocumentError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const allSideletters = investors.map((inv) => inv.sideletters || []).flat();
  const sideletters = unique(allSideletters, 'id');

  const allOfferings = investors.map((inv) => inv.offerings || []).flat();
  const offerings = unique(allOfferings, 'id');

  function onSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    const lpClosingIds = investors.map((i) => i.id);
    const isSideLetter = sideletters.map((s) => s.id).includes(documentId);
    const params = {
      fundId,
      closingId,
      lpClosingIds,
    };

    if (isSideLetter) {
      params.sideletterId = documentId;
    } else {
      params.offeringDocumentId = documentId;
    }

    api
      .detachDocument(params)
      .then((response) => {
        const trueCount = response.data;
        const plural = trueCount > 1 ? 's' : '';

        successToast(`Removed document from ${trueCount} investor${plural}`);
        onChange();
        handleClose();
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          const message =
            error.response.data.sideletterId ||
            error.response.data.offeringDocumentId ||
            error.response.data.nonFieldErrors;
          setDocumentError(message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function dialogContent() {
    return (
      <>
        <FormControl variant="outlined" fullWidth error={!!documentError}>
          <InputLabel id="sideletter-label">Document</InputLabel>

          <Select
            label="Document"
            labelId="sideletter-label"
            value={documentId}
            onChange={(e) => setDocumentId(e.target.value)}
          >
            {offerings.map((offering) => (
              <MenuItem key={offering.id} value={offering.id}>
                {offering.name}
              </MenuItem>
            ))}

            {sideletters.map((sideletter) => (
              <MenuItem key={sideletter.id} value={sideletter.id}>
                {sideletter.name}
              </MenuItem>
            ))}
          </Select>

          {documentError ? (
            <FormHelperText>{documentError}</FormHelperText>
          ) : null}
        </FormControl>
      </>
    );
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      showCancelButton
      headerLabel="Remove a document"
      subheaderLabel={`${investors.length} investor${
        investors.length > 1 ? 's' : ''
      } selected`}
      onExited={() => {
        setDocumentId('');
      }}
      primaryButtonText="Remove"
      onSubmit={onSubmit}
      primaryButtonLoading={isLoading}
      primaryButtonDisabled={!documentId}
    >
      {dialogContent()}
    </Modal>
  );
}
