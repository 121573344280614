import React from 'react';
import { GenericAnswerDisplay } from './generic_display';

export function YesOrNoDisplay({ answer, flatten, questionState }) {
  return (
    <GenericAnswerDisplay
      answer={answer}
      flatten={flatten}
      questionState={questionState}
      transformAnswer={(a) => (a === true ? 'Yes' : 'No')}
    />
  );
}
