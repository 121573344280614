import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Answers } from '../../answers';
import { MultiChoiceList, MultiChoiceItem, ActionsSubmit } from '../common';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '500px',
  },
}));

export const MultiSelectQuestionReconciliation = ({
  lpDoc,
  activeProfile,
  answersToReconcile,
  question,
  setSaving,
  updateLpDoc,
  navRef,
  QuestionPromptComponent,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState([]);
  const [errorMsg, setErrorMsg] = React.useState('');
  const sourcesByChoice = answersToReconcile
    .flatMap((item) =>
      item.answer.map((choice) => ({ choice, source: item.source })),
    )
    .reduce(
      (prev, curr) => ({
        ...prev,
        [curr.choice]: [...(prev[curr.choice] || []), curr.source],
      }),
      {},
    );

  const toggleChoice = (choice) => {
    if (value.includes(choice)) {
      setValue(value.filter((v) => v !== choice));
    } else {
      setValue([...value, choice]);
    }
  };

  return (
    <div className={classes.root}>
      <QuestionPromptComponent short />
      <Answers>
        <MultiChoiceList error={errorMsg}>
          {question.choices.map((choice) => (
            <MultiChoiceItem
              key={choice.text}
              sources={sourcesByChoice[choice.text] || []}
              lpDoc={lpDoc}
              activeProfile={activeProfile}
              name={choice.text}
              checked={value.includes(choice.text)}
              onChange={() => toggleChoice(choice.text)}
            >
              {choice.text}
            </MultiChoiceItem>
          ))}
        </MultiChoiceList>
        <ActionsSubmit
          navRef={navRef}
          answer={value}
          isContinueDisabled={question.isRequired && !value?.length}
          label={question.label}
          setSaving={setSaving}
          updateLpDoc={updateLpDoc}
          setErrorMsg={setErrorMsg}
        />
      </Answers>
    </div>
  );
};
