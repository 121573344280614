import React from 'react';

import { makeStyles } from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import FingerprintOutlinedIcon from '@material-ui/icons/FingerprintOutlined';
import LinkIcon from '@material-ui/icons/LinkOutlined';

import { SpotIcon } from '@passthrough/uikit';

const useStyles = makeStyles(() => ({
  icon: {
    '& > *:last-child:not(:first-child)': {
      marginTop: '-10px',
    },
    '& > *:first-child': {
      zIndex: 1,
    },
  },
}));

export function ClosingIcon({ isSubdoc, isDiligence, isLinked }) {
  const classes = useStyles();
  return (
    <div className={classes.icon}>
      {isSubdoc ? (
        <SpotIcon size="medium">
          <DescriptionOutlinedIcon />
        </SpotIcon>
      ) : null}
      {isDiligence ? (
        <SpotIcon size="medium" variant="warning">
          <FingerprintOutlinedIcon />
        </SpotIcon>
      ) : null}
      {isLinked ? (
        <SpotIcon size="medium" variant="neutral">
          <LinkIcon />
        </SpotIcon>
      ) : null}
    </div>
  );
}
