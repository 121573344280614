import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';

import {
  Modal,
  Link,
  Typography,
  UIKitSettingsProvider,
} from '@passthrough/uikit';
import { useDiscardConfirmation } from 'services/discard';
import { objectEquals } from 'services/utils';

export function GeneralSettingsModal({
  open,
  onClose,
  initialState,
  errors,
  setErrors,
  updateSettings,
  loading,
}) {
  const [form, setForm] = useState(initialState);
  const closeWithConfirmation = useDiscardConfirmation({
    onConfirm: onClose,
    needConfirmation: open && !objectEquals(form, initialState),
  });

  const currencyCodeHelperText = (
    <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
      <Typography variant="body" size="small" color="text.secondary">
        Use an{' '}
        <Link
          inline
          variant="external"
          href="https://en.wikipedia.org/wiki/ISO_4217"
        >
          ISO 4217
        </Link>{' '}
        currency code
      </Typography>
    </UIKitSettingsProvider>
  );

  return (
    <Modal
      open={open}
      onClose={closeWithConfirmation}
      headerLabel="Edit general settings"
      showCancelButton
      primaryButtonText="Save"
      primaryButtonLoading={loading}
      onEntering={() => {
        setForm(initialState);
        setErrors({});
      }}
      onSubmit={() => updateSettings(form)}
    >
      <TextField
        variant="outlined"
        label="Logo"
        value={form.logo}
        onChange={(e) => setForm({ ...form, logo: e.target.value })}
        fullWidth
        error={!!errors.logo}
        helperText={errors.logo}
      />
      <TextField
        variant="outlined"
        label="Currency code"
        value={form.currencyCode}
        onChange={(e) => setForm({ ...form, currencyCode: e.target.value })}
        fullWidth
        error={!!errors.currencyCode}
        helperText={errors.currencyCode || currencyCodeHelperText}
      />
      <TextField
        variant="outlined"
        label="Currency symbol"
        value={form.currencySymbol}
        onChange={(e) => setForm({ ...form, currencySymbol: e.target.value })}
        fullWidth
        error={!!errors.currencySymbol}
        helperText={errors.currencySymbol}
      />
    </Modal>
  );
}
