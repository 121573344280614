import React, { useState } from 'react';
import { Button } from '@passthrough/uikit';
import { SettingsOutlined } from '@material-ui/icons';
import { ScreeningCategoriesMenu } from './screening_categories_menu';

export const ScreeningCategories = ({
  adverseMediaEnabled,
  isIndividual,
  handleEnableAdverseMedia,
  shouldShowDisabledIcon = false,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setShowMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setShowMenu(false);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button variant="icon" aria-label="Settings" onClick={handleClick}>
        <SettingsOutlined />
      </Button>
      <ScreeningCategoriesMenu
        open={showMenu}
        onClose={handleClose}
        anchorEl={anchorEl}
        adverseMediaEnabled={adverseMediaEnabled}
        isIndividual={isIndividual}
        handleEnableAdverseMedia={handleEnableAdverseMedia}
        shouldShowDisabledIcon={shouldShowDisabledIcon}
      />
    </div>
  );
};
