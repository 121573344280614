import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Spinner } from 'components/spinner';
import { ProfileUsersList } from './profile_users_list';
import { ProfileCard } from './profile_card';
import {
  ROLE_STEP,
  ACCESS_STEP,
  SELECT_PROFILE_STEP,
  REVIEW_STEP,
} from './constants';
import { ProfileCollaboratorModal } from '../profile_collaborator_modal';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 400,
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(24),
  },
  displayName: {
    fontWeight: 500,
  },
  listContainer: {
    margin: theme.spacing(4, 0),
  },
}));

export function AccessStep({
  fundName,
  lpName,
  isLoading,
  errors,
  addNewCollaborator,
  deleteCollaborator,
  setAccessLevel,
  setupCollaborators,
  suggestedCollaborators,
  setNavStep,
  hideRoleStep,
  profileData,
}) {
  const classes = useStyles();
  const [newCollaboratorModal, setNewCollaboratorModal] = useState(false);

  const onBackClick = () => {
    if (hideRoleStep) {
      setNavStep(SELECT_PROFILE_STEP);
    } else {
      setNavStep(ROLE_STEP);
    }
  };

  const goToReview = () => {
    setNavStep(REVIEW_STEP);
  };

  const cardContents = (
    <>
      <div className={classes.listContainer}>
        <ProfileUsersList
          allCollaborators={setupCollaborators}
          setNewCollaboratorModal={setNewCollaboratorModal}
          onDelete={deleteCollaborator}
          setAccessLevel={setAccessLevel}
          errors={errors}
          addNewCollaborator={addNewCollaborator}
          suggestedCollaborators={suggestedCollaborators}
        />
      </div>
      <ProfileCollaboratorModal
        open={newCollaboratorModal}
        addNewCollaborator={addNewCollaborator}
        handleClose={() => setNewCollaboratorModal(false)}
      />
    </>
  );

  return (
    <ProfileCard
      maxWidth="md"
      lpName={lpName}
      fundName={fundName}
      step={ACCESS_STEP}
      setStep={setNavStep}
      onBackClick={onBackClick}
      onContinueDisabled={isLoading}
      onContinueClick={goToReview}
      hideRoleStep={hideRoleStep}
    >
      <Typography className={classes.title} align="center" gutterBottom>
        Who should have access to{' '}
        <span className={classes.displayName}>{profileData.displayName}</span>'s
        profile?
      </Typography>

      {isLoading ? <Spinner fullScreen /> : cardContents}
    </ProfileCard>
  );
}
