import React from 'react';

import { Alert, Link, UIKitSettingsProvider } from '@passthrough/uikit';

export function OptOutAlert({ sticky }) {
  return (
    <UIKitSettingsProvider redirectAttribute="href">
      <Alert severity="warning" variant={sticky ? 'sticky' : 'inpage'}>
        The investor did not provide all requested information for this owner.{' '}
        <Link
          href="https://support.passthrough.com/en_us/understanding-an-investor-s-diligence-submission-rkwfqmoqi"
          inline
          variant="external"
        >
          Learn more
        </Link>
      </Alert>
    </UIKitSettingsProvider>
  );
}
