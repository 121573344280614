import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Alert } from 'components/alert';
import { useToast } from 'services/toast';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'components/dialog/index';
import { Button } from 'components/button';
import * as api from 'services/api';
import { getSingleErrorFromResponse } from 'services/utils';
import { formatApprovedDomainEmailError } from 'services/approved_domains';

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: theme.spacing(2),
  },
  helperText: {
    marginBottom: theme.spacing(3),
  },
  input: {
    margin: theme.spacing(0.5, 0),
  },
}));

export function NewAdminModal({ organizationId, open, handleClose, onChange }) {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [addCollaboratorError, setAddCollaboratorError] = useState(null);
  const { successToast } = useToast();

  function onSubmit(e) {
    e.preventDefault();
    api
      .createOrgAdmin({
        name,
        email,
        organizationId,
      })
      .then(() => {
        onChange();
        handleClose();
        successToast(`Added ${name} as an admin.`);
        setName('');
        setEmail('');
        setNameError('');
        setEmailError(null);
        setAddCollaboratorError('');
      })
      .catch((error) => {
        setNameError('');
        setEmailError(null);
        setAddCollaboratorError('');
        if (error.response?.status === 400) {
          if (error.response.data.name) {
            setNameError(error.response.data.name);
          }
          if (error.response.data.email) {
            setEmailError(error.response.data);
          }
        } else {
          setAddCollaboratorError(getSingleErrorFromResponse(error.response));
        }
      });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      aria-labelledby="form-dialog-title"
    >
      <form onSubmit={onSubmit} autoComplete="off">
        <DialogTitle id="form-dialog-title">New admin</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.helperText}>
            This will notify the new admin by email.
          </DialogContentText>
          {addCollaboratorError ? (
            <Alert severity="error" className={classes.alert}>
              {addCollaboratorError}
            </Alert>
          ) : null}
          <TextField
            autoFocus
            required
            className={classes.input}
            id="name"
            variant="outlined"
            label="Name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={!!nameError}
            helperText={nameError}
            fullWidth
          />
          <TextField
            required
            className={classes.input}
            id="email"
            variant="outlined"
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!emailError}
            helperText={formatApprovedDomainEmailError(emailError)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            onClick={onSubmit}
            variant="contained"
            color="primary"
            fullWidth
          >
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
