import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Button } from 'components/button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/PersonOutlineOutlined';
import PublicIcon from '@material-ui/icons/Public';
import BusinessIcon from '@material-ui/icons/Business';
import UndoIcon from 'mdi-material-ui/Undo';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import CheckIcon from 'mdi-material-ui/Check';
import { DragDropContext } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core/styles';

import { NoteHistoryDisplay } from './note_history';
import { InputDiv } from './common/input_div';
import { OutputBoxes } from './common/output_boxes';
import { Paper } from './common/paper';
import { QueryBuilder } from './query_builder';
import { REVIEW_STATE } from './constants';

const useStyles = makeStyles((theme) => ({
  leftInput: {
    marginRight: theme.spacing(1),
  },
  restrictedThreadDisplay: {
    margin: 'auto',
  },
}));

export const EditText = ({
  id,
  text,
  setText,
  isAsked,
  setIsAsked,
  hellosignBoxes,
  replaceHellosignBox,
  handleAddHellosignBox,
  deleteHellosignBox,
  setDragSelected,
  onDragEnd,
  setPage,
  deps,
  setDeps,
  questions,
  newNotes,
  handleSave,
  hasUnsavedChanges,
  isValid,
  isSaving,
  highlightBox,
  setHighlightBoxId,
  handleClickItem,
  createQuestionNote,
  updateQuestionNote,
  deleteQuestionNote,
  onboardingLogs,
  handleReview,
  currentState,
  reviewedQuestionIds,
  setReviewedQuestionIds,
  selectedIds,
}) => {
  const classes = useStyles();
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Paper>
        <NoteHistoryDisplay
          notes={newNotes[id] || []}
          className={classes.restrictedThreadDisplay}
          createQuestionNote={createQuestionNote}
          updateQuestionNote={updateQuestionNote}
          deleteQuestionNote={deleteQuestionNote}
          questionID={id}
          currentLog={onboardingLogs[0]}
          handleReview={handleReview}
          reviewedQuestionIds={reviewedQuestionIds}
          setReviewedQuestionIds={setReviewedQuestionIds}
          selectedIds={selectedIds}
        />
        <InputDiv>
          <TextField
            value={id}
            className={classes.leftInput}
            disabled
            variant="outlined"
            label="Text ID"
          />

          <TextField
            value={text}
            onChange={(e) => {
              setText(e.target.value);
            }}
            variant="outlined"
            multiline
            rows={1}
            fullWidth
            required
            label="Custom text"
            inputProps={{ style: { resize: 'revert' } }}
          />

          {currentState === REVIEW_STATE ? (
            <Tooltip title={<Typography>Mark as reviewed</Typography>}>
              <IconButton
                aria-label="reviewed"
                onClick={() => {
                  handleReview(!reviewedQuestionIds.includes(id));
                }}
              >
                {reviewedQuestionIds.includes(id) ? (
                  <UndoIcon />
                ) : (
                  <CheckIcon />
                )}
              </IconButton>
            </Tooltip>
          ) : null}
        </InputDiv>

        <InputDiv>
          <ToggleButtonGroup
            value={isAsked}
            exclusive
            onChange={(e, v) => {
              if (v !== null) {
                setIsAsked(v);
              }
            }}
          >
            <ToggleButton value="always" aria-label="always ask">
              <Tooltip title={<Typography>Always ask</Typography>}>
                <PublicIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="natural_person" aria-label="natural person">
              <Tooltip title={<Typography>Only natural people</Typography>}>
                <PersonIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="entity" aria-label="entity">
              <Tooltip title={<Typography>Only entities</Typography>}>
                <BusinessIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </InputDiv>

        <OutputBoxes
          highlightBox={highlightBox}
          setHighlightBoxId={setHighlightBoxId}
          replaceHellosignBox={replaceHellosignBox}
          deleteHellosignBox={deleteHellosignBox}
          setDragSelected={setDragSelected}
          setPage={setPage}
          questions={questions}
          handleClickItem={handleClickItem}
          answerType="custom_text"
          hellosignBoxes={hellosignBoxes}
          handleAddHellosignBox={handleAddHellosignBox}
          isCustomText
        />

        <InputDiv>
          <QueryBuilder
            questionIds={[id]}
            value={deps}
            onChange={(d) => {
              setDeps(d);
            }}
            questions={questions}
            handleClickItem={handleClickItem}
            hideAddCondition={false}
            showThisAndFutureQuestions
          />
        </InputDiv>

        <InputDiv>
          <Button
            variant="contained"
            color="primary"
            disabled={!isValid() || !hasUnsavedChanges}
            onClick={handleSave}
            fullWidth
            loading={isSaving}
          >
            Save
          </Button>
        </InputDiv>
      </Paper>
    </DragDropContext>
  );
};
