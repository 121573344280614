import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Link, Typography, UIKitSettingsProvider } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    margin: theme.spacing(2, 0),
  },
}));

export function OfferingDocSideLetterTypeSelector({
  type,
  setType,
  docTypesToDisplay,
}) {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.input}>
      <InputLabel id="type-label">Type</InputLabel>
      <Select
        label="Type"
        labelId="type-label"
        value={type}
        onChange={(e) => setType(e.target.value)}
        data-test="add_document_type_input"
      >
        {docTypesToDisplay.map(({ key, display }) => (
          <MenuItem value={key}>{display}</MenuItem>
        ))}
      </Select>
      {type === 'Blank form' ? (
        <Typography size="small" variant="label" color="text.secondary">
          By default, blank forms are not included in emails to investors.{' '}
          <UIKitSettingsProvider redirectAttribute="href">
            <Link
              href="https://support.passthrough.com/en_us/how-to-add-documents-BJMMTkkhq"
              inline
              variant="external"
              onClick={(e) => e.stopPropagation()}
            >
              Learn more
            </Link>
          </UIKitSettingsProvider>
        </Typography>
      ) : null}
    </FormControl>
  );
}
