import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import SelectionDragIcon from 'mdi-material-ui/SelectionDrag';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Tooltip from '@material-ui/core/Tooltip';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import { useFeatureFlags } from 'services/providers/feature_flag';
import { InputDiv } from '../common/input_div';
import { Ripple } from '../common/ripple';

import {
  NAMED_SIGNATURE_BOX_TYPES,
  SIGNATURE_CHECKBOX_TYPES,
  MAX_BOX_NAME_LENGTH,
  SUBDOC_DOC_NAME,
} from '../constants';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(1),
    paddingTop: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cardRoot: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  outputBoxTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  outputBoxTitle: {
    fontWeight: 600,
  },
}));

export function SignatureBoxInput({
  box,
  onChange,
  onDelete,
  onSelectDrag,
  setPage,
  highlight,
  setHighlightBoxId,
  signer,
  selectDocument,
}) {
  const { MULTIPLE_DOCUMENTS } = useFeatureFlags();
  const classes = useStyles();
  const boxInputRequiresName = NAMED_SIGNATURE_BOX_TYPES.includes(box.type);
  const canToggleBoxIsRequired = !SIGNATURE_CHECKBOX_TYPES.includes(box.type);

  const isBoxNameTooLong =
    box?.name?.length && box.name.length >= MAX_BOX_NAME_LENGTH;
  const shorterNameText = isBoxNameTooLong
    ? `Name must be less than ${MAX_BOX_NAME_LENGTH} chars`
    : '';

  const onClick = () => {
    if (MULTIPLE_DOCUMENTS) {
      selectDocument(box.documentName);
    }
    setPage(box.page);
    setHighlightBoxId(box.id);
  };
  return (
    <Ripple ripple={highlight}>
      <div style={{ marginTop: 15 }}>
        <Card variant="outlined" className={classes.cardRoot} key={box.id}>
          <CardContent className={classes.cardContent}>
            <div className={classes.outputBoxTitleContainer}>
              <Typography className={classes.outputBoxTitle}>
                Output box
                {MULTIPLE_DOCUMENTS
                  ? ` (${box.documentName || SUBDOC_DOC_NAME})`
                  : ''}
              </Typography>
            </div>
            <InputDiv>
              <IconButton aria-label="find" onClick={onClick}>
                <FindInPageIcon />
              </IconButton>
              <TextField
                value={box.page}
                onChange={(e) => {
                  onChange({ ...box, page: Number(e.target.value) });
                }}
                variant="outlined"
                required
                type="number"
                label="page"
              />
              <TextField
                value={box.x}
                onChange={(e) => {
                  onChange({ ...box, x: Number(e.target.value) });
                }}
                variant="outlined"
                required
                type="number"
                label="x"
              />
              <TextField
                value={box.y}
                onChange={(e) => {
                  onChange({ ...box, y: Number(e.target.value) });
                }}
                variant="outlined"
                required
                type="number"
                label="y"
              />
              <FormControl
                variant="outlined"
                style={{ minWidth: '150px' }}
                required
              >
                <InputLabel id="answer-type">Answer type</InputLabel>
                <Select
                  label="Answer type"
                  labelId="answer-type"
                  value={box.type || ''}
                  onChange={(e) => {
                    onChange({ ...box, type: e.target.value, name: null });
                  }}
                >
                  <MenuItem value={`${signer}_signature`}>Signature</MenuItem>
                  <MenuItem value={`${signer}_date_signed`}>
                    Date signed
                  </MenuItem>
                  <MenuItem value={`${signer}_name`}>Name</MenuItem>
                  <MenuItem value={`${signer}_title`}>Title</MenuItem>
                  <MenuItem value={`${signer}_checkbox`}>Checkbox</MenuItem>
                  <MenuItem value={`${signer}_initials`}>Initials</MenuItem>
                  <MenuItem value={`${signer}_accepted_commitment`}>
                    Accepted commitment
                  </MenuItem>
                  <MenuItem value={`${signer}_text`}>Other</MenuItem>
                </Select>
              </FormControl>
              {boxInputRequiresName ? (
                <TextField
                  value={box.name || ''}
                  onChange={(e) => {
                    onChange({ ...box, name: e.target.value });
                  }}
                  variant="outlined"
                  required
                  label="Name"
                  error={isBoxNameTooLong}
                  helperText={shorterNameText}
                />
              ) : null}
              {canToggleBoxIsRequired ? (
                <ToggleButtonGroup
                  value={box.isRequired ? 'required' : 'optional'}
                  exclusive
                  onChange={(e, v) => {
                    if (v !== null) {
                      onChange({ ...box, isRequired: v === 'required' });
                    }
                  }}
                >
                  <ToggleButton value="required" aria-label="required">
                    <Tooltip title={<Typography>Required</Typography>}>
                      <LockIcon />
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton value="optional" aria-label="optional">
                    <Tooltip title={<Typography>Optional</Typography>}>
                      <LockOpenIcon />
                    </Tooltip>
                  </ToggleButton>
                </ToggleButtonGroup>
              ) : null}
              <IconButton
                aria-label="select"
                onClick={() => onSelectDrag(box.id)}
              >
                <SelectionDragIcon />
              </IconButton>
              <IconButton aria-label="delete" onClick={() => onDelete(box.id)}>
                <DeleteIcon />
              </IconButton>
            </InputDiv>
          </CardContent>
        </Card>
      </div>
    </Ripple>
  );
}
