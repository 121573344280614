import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { Alert, Typography, Button } from '@passthrough/uikit';
import * as api from 'services/api';
import { EmailChip } from './email_chip';
import { SocialButton } from './social_button';
import { PROVIDERS } from './constants';
import { isUuid } from './utils';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  or: {
    margin: theme.spacing(3, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1.25),
    width: '100%',
  },
  flexDivider: {
    flex: 1,
  },
}));

export function SignInConfirmEmailStep({
  email,
  errorComponent,
  onConfirm,
  onPasswordRequired,
  onSamlRequired,
  onSwitch,
  socialProvider,
}) {
  const classes = useStyles();
  const [emailError, setEmailError] = useState('');
  const [loading, setLoading] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    api
      .loginWithEmail({ email })
      .then((response) => {
        if (response.status === 204) {
          setLoading(false);
          onConfirm();
        } else if (response.status === 200) {
          if (response.data === 'password required') {
            setLoading(false);
            onPasswordRequired();
          } else if (isUuid(response.data)) {
            onSamlRequired(response.data);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status === 400) {
          setEmailError(error.response.data.email);
        } else if (error.response?.status === 429) {
          setEmailError(
            'Too many sign in attempts. Please wait a few minutes before trying again.',
          );
        }
      });
  }
  const hasSocialProvider = PROVIDERS[socialProvider] !== undefined;

  return (
    <>
      <form
        className={classes.form}
        onSubmit={(e) => handleSubmit(e)}
        noValidate
      >
        {errorComponent}
        <EmailChip email={email} onClick={onSwitch} />
        {emailError ? <Alert severity="error">{emailError}</Alert> : null}
        <Button
          type="submit"
          fullWidth
          variant="primary"
          loading={loading}
          disabled={loading}
          htmlProps={{
            'data-test': 'next',
            style: { maxWidth: '100%' },
          }}
        >
          {hasSocialProvider ? 'Email me a link' : 'Next'}
        </Button>
        {hasSocialProvider ? (
          <Typography size="small">
            You will receive a link in your email to sign in without a password.
          </Typography>
        ) : null}
      </form>
      {hasSocialProvider ? (
        <>
          <div className={classes.or}>
            <Divider className={classes.flexDivider} />
            <Typography size="small">or</Typography>
            <Divider className={classes.flexDivider} />
          </div>
          <SocialButton provider={socialProvider} email={email} />
        </>
      ) : null}
    </>
  );
}
