import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import startOfToday from 'date-fns/startOfToday';

import { dateCompare, strCompare } from 'services/shared_sort_comparisons';
import {
  FILTER_ALL,
  FILTER_FULLY_EXECUTED,
  FILTER_IN_PROGRESS,
  NAME_COLUMN,
  NEXT_DATE_COLUMN,
} from './constants';

export function dateIsBeforeToday(dateStr) {
  const date = new Date(dateStr);
  const today = startOfToday();
  return isBefore(date, today);
}

// not exactly future proof
const FAR_FUTURE = '9999-12-31';
const LESS_FAR_FUTURE = '8999-12-31';
const FAR_PAST = '0000-01-01';
const LESS_FAR_PAST = '1000-01-01';

function sortValueForDateFactory(direction) {
  return (fund) => {
    const dateStr = fund.nextClosingDate;
    if (!dateStr) {
      return direction === 'desc' ? LESS_FAR_FUTURE : LESS_FAR_PAST;
    }
    if (dateIsBeforeToday(dateStr)) {
      // Funds with no upcoming closing come after funds with no date set
      return direction === 'desc' ? FAR_FUTURE : FAR_PAST;
    }

    return dateStr;
  };
}

const getFundName = (fund) => fund.name;

export function sortFunds(order, funds) {
  if (funds === null) {
    return null;
  }

  const sortedFunds = [...funds];
  const [columnId, direction] = order;

  const sortValueForDate = sortValueForDateFactory(direction);

  sortedFunds.sort((a, b) => {
    switch (columnId) {
      case NEXT_DATE_COLUMN: {
        const byDate = dateCompare(a, b, sortValueForDate, direction);
        return byDate || strCompare(a, b, getFundName, 'desc');
      }

      case NAME_COLUMN:
        return strCompare(a, b, getFundName, direction);

      default:
        throw Error('unknown column');
    }
  });

  return sortedFunds;
}

export function formatDate(dateStr) {
  if (dateStr) {
    const dateOb = new Date(dateStr);
    return format(dateOb, 'dd MMM yyyy');
  }
  return null;
}

export function filterMyFunds(showMyFunds, funds) {
  if (showMyFunds) {
    return funds.filter((fund) => fund.userHasAccess);
  }
  return funds;
}

export function filterClosingStatus(closingFilter, funds) {
  switch (closingFilter) {
    case FILTER_ALL:
      return funds;
    case FILTER_IN_PROGRESS:
      return funds.filter((fund) => fund.closingStatus === 'IN_PROGRESS');
    case FILTER_FULLY_EXECUTED:
      return funds.filter((fund) => fund.closingStatus === 'FULLY_EXECUTED');
    default:
      return funds;
  }
}

function normalizeString(str) {
  return str.toLowerCase().replace(/\s/g, '');
}

function fundMatchesQuery(fund, query) {
  const normalizedQuery = normalizeString(query);
  if (normalizedQuery.length === 0) {
    return true;
  }

  const normalizedFundName = normalizeString(fund.name);
  if (normalizedFundName.includes(normalizedQuery)) {
    return true;
  }

  const normalizedOrgName = normalizeString(fund.orgName);
  if (normalizedOrgName.includes(normalizedQuery)) {
    return true;
  }

  const normalizedClientMatterNumber = normalizeString(
    fund.clientMatterNumber || '',
  );
  if (normalizedClientMatterNumber.includes(normalizedQuery)) {
    return true;
  }

  const teamMembers = fund.teamMembers || [];
  for (let i = 0; i < teamMembers.length; i += 1) {
    const normalizedMemberName = normalizeString(teamMembers[i].name);
    if (normalizedMemberName.includes(normalizedQuery)) {
      return true;
    }
    const normalizedMemberEmail = normalizeString(teamMembers[i].email);
    if (normalizedMemberEmail.includes(normalizedQuery)) {
      return true;
    }
  }

  return false;
}

export function filterByQuery(query, funds) {
  return funds.filter((fund) => fundMatchesQuery(fund, query));
}
