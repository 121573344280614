import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import { Typography, Button, UserDisplay } from '@passthrough/uikit';

import * as urls from 'services/urls';
import { useFundEdit } from 'services/providers/fund';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 6, 0, 3),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(0, 3),
    },
  },
  contactSection: {
    margin: theme.spacing(2, 0),
  },
  contactHeader: {
    margin: theme.spacing(1, 0),
  },
  lpNameHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2.5),
    gap: theme.spacing(1),
  },
  contactWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 3),
    rowGap: theme.spacing(2),
  },
}));

function Contacts({ users }) {
  // Same user can appear twice in the list
  const uniqueUsers = users.filter(
    (user, index, self) =>
      index === self.findIndex((u) => u.email === user.email),
  );
  const classes = useStyles();
  return (
    <div className={classes.contactSection}>
      <Paper variant="outlined" className={classes.contactWrapper}>
        {uniqueUsers.map((user) => (
          <UserDisplay
            key={user.email}
            variant="default"
            name={user.name}
            email={user.email}
          />
        ))}
      </Paper>
    </div>
  );
}

export function PeopleTab({ lpClosing, fundId, closingId }) {
  const classes = useStyles();
  const [fundEdit] = useFundEdit();

  return (
    <div className={classes.root}>
      <div className={classes.contactSection}>
        <div className={classes.lpNameHeader}>
          <Typography variant="card-heading">{lpClosing.lpName}</Typography>
          {fundEdit ? (
            <Button
              variant="secondary"
              size="small"
              aria-label="Go to investor page"
              href={urls.investorUrl({
                fundId,
                closingId,
                investorId: lpClosing.lpId,
              })}
            >
              Edit
            </Button>
          ) : null}
        </div>
        <Contacts users={lpClosing.lpUsers} />
      </div>

      {lpClosing.investorSigners?.length > 0 ? (
        <div>
          <div className={classes.contactHeader}>
            <Typography variant="card-heading">
              Signer
              {lpClosing.investorSigners.length > 1 ? 's' : ''}
            </Typography>
          </div>
          <Contacts users={lpClosing.investorSigners} />
        </div>
      ) : null}
      {lpClosing.diligenceApprovers.length > 0 ? (
        <div>
          <div className={classes.contactHeader}>
            <Typography variant="card-heading">
              Diligence reviewer
              {lpClosing.diligenceApprovers.length > 1 ? 's' : ''}
            </Typography>
          </div>
          <Contacts users={lpClosing.diligenceApprovers} />
        </div>
      ) : null}
      {lpClosing.countersigners.length > 0 ? (
        <div>
          <div className={classes.contactHeader}>
            <Typography variant="card-heading">
              Countersigner
              {lpClosing.countersigners.length > 1 ? 's' : ''}
            </Typography>
          </div>
          <Contacts users={lpClosing.countersigners} />
        </div>
      ) : null}
    </div>
  );
}
