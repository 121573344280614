import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import { boxTypeList } from 'components/document_fields/box_type';
import { BoxTypeListItem } from 'components/document_fields/box_type_list_item';
import { SignerDropdown } from 'components/document_fields/signer_select';
import * as constants from 'components/document_fields/constants';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(3, 2),
    width: `${constants.PANEL_WIDTH}px`,
    minWidth: `${constants.PANEL_WIDTH}px`,
    height: '100%',
    border: 'none',
    borderBottom: 'none',
    borderLeft: 'none',
    borderTop: 'none',
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export function SideBar({
  relevantSigners,
  selectedSigner,
  setSelectedSigner,
  selectedBoxType,
  updateSelectedDocType,
}) {
  const classes = useStyles();
  const signer = relevantSigners.find((s) => s.pdfBoxSigner === selectedSigner);

  return (
    <Paper square variant="outlined" className={classes.container}>
      <SignerDropdown
        relevantSigners={relevantSigners}
        activeSigner={selectedSigner}
        setActiveSigner={setSelectedSigner}
        inputLabelText="Signers"
      />
      {boxTypeList.map((b) => (
        <BoxTypeListItem
          key={b.type}
          onClick={() => updateSelectedDocType(b.type)}
          boxType={b.type}
          selectedBoxType={selectedBoxType}
          icon={b.icon}
          text={b.text}
          signer={signer}
        />
      ))}
    </Paper>
  );
}
