import { formatCurrency } from 'services/utils';
import { Colors } from './progress_bar';

const PROGRESS_ITEM_MIN_WIDTH = 2.5;

export function handleMinWidths(percentages, minWidth) {
  const newPercentages = [...percentages];

  // Get the index of the percentage with largest value. Space added to
  // items smaller than minWidth will be deducted from this item.
  const maxIndex = newPercentages.reduce((maxInd, percentage, index, arr) => {
    if (percentage > arr[maxInd]) {
      return index;
    }
    return maxInd;
  }, 0);

  // Add space to items smaller than minWidth
  for (let i = 0; i < newPercentages.length; i += 1) {
    if (newPercentages[i] < minWidth) {
      newPercentages[maxIndex] -= minWidth - newPercentages[i];
      newPercentages[i] = minWidth;
    }
  }

  return newPercentages;
}

export function useProgressBarInfo({
  pending,
  fullyExecuted,
  target,
  currency,
}) {
  const colors = [];
  const percentages = [];
  const tooltips = [];

  const commitmentSum = Number(pending) + Number(fullyExecuted);
  let total = target ? Number(target) : commitmentSum;
  const diff = total - commitmentSum;

  if (diff < 0) {
    total = commitmentSum;
  }

  let pendingPercentage = (Number(pending) / commitmentSum) * 100;
  let executedPercentage = (Number(fullyExecuted) / commitmentSum) * 100;
  const diffPercentage = (diff / total) * 100;

  if (diff > 0) {
    const scalingFactor = (100 - diffPercentage) * 0.01;
    pendingPercentage *= scalingFactor;
    executedPercentage *= scalingFactor;
  }

  if (pendingPercentage > 0) {
    colors.push(Colors.PENDING);
    percentages.push(pendingPercentage);
    tooltips.push(`Pending\n${formatCurrency(pending, currency)}`);
  }

  if (executedPercentage > 0) {
    colors.push(Colors.FULLY_EXECUTED);
    percentages.push(executedPercentage);
    tooltips.push(`Fully executed\n${formatCurrency(fullyExecuted, currency)}`);
  }

  if (diff > 0) {
    colors.push(Colors.FILLER);
    percentages.push(diffPercentage);
    tooltips.push(
      `${formatCurrency(diff, currency)} to reach Target (${formatCurrency(
        target,
        currency,
      )})`,
    );
  }

  // If no commitments, show grey bar
  if (colors.length === 0) {
    colors.push(Colors.FILLER);
    percentages.push(100);
    // no tooltip on purpose
  }

  return [
    colors,
    handleMinWidths(percentages, PROGRESS_ITEM_MIN_WIDTH),
    tooltips,
  ];
}
