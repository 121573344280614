import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { QuestionTags } from './tag';

const useStyles = makeStyles((theme) => ({
  field: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.grey,
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 1, 1, 0),
    padding: theme.spacing(1, 1, 1, 2),
    flex: '65%',
  },
  fieldsList: {
    flexDirection: 'column',
  },
  fieldWithTag: {
    display: 'flex',
    flexDirection: 'row',
  },
  item: {
    marginBottom: theme.spacing(2),
  },
}));

export const ComplexPanelContent = ({
  questionTags,
  questionText,
  fields,
  left = false,
}) => {
  const classes = useStyles();

  const fieldsDisplay =
    !left && fields && questionTags
      ? fields.map((f) => (
          <div key={f} className={classes.fieldWithTag}>
            <Paper
              key={`${f}-name`}
              elevation={0}
              variant="outlined"
              className={classes.field}
            >
              <strong>{f}</strong>
            </Paper>
          </div>
        ))
      : null;
  const fieldWithTags =
    left && fields && questionTags
      ? fields.map((f) => (
          <div key={f} className={classes.fieldWithTag}>
            <Paper
              key={`${f}-name`}
              elevation={0}
              variant="outlined"
              className={classes.field}
            >
              <strong>{f}</strong>
            </Paper>
            <QuestionTags questionTags={questionTags[f]} />
          </div>
        ))
      : null;

  return (
    <div>
      <Typography className={classes.item}>
        <strong>{questionText}</strong>
      </Typography>

      <div key="tags" className={classes.fieldsList}>
        {left ? fieldWithTags : fieldsDisplay}
      </div>
    </div>
  );
};
