import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { Modal } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  languageFormLabel: {
    overflow: 'inherit',
  },
  radioGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  input: {
    margin: theme.spacing(1, 0),
  },
  clearButton: {
    marginTop: theme.spacing(1),
  },
}));

export const SettingsModal = ({
  open,
  onClose,
  investorWord,
  updateSettings,
}) => {
  const [word, setInvestorWord] = useState(investorWord);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const saveSettings = (e) => {
    e.preventDefault();

    setIsLoading(true);
    updateSettings(word);
    setIsLoading(false);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      onSubmit={saveSettings}
      headerLabel="Settings"
      primaryButtonText="Save"
      primaryButtonLoading={isLoading}
      onExited={() => setInvestorWord(investorWord)}
      onEntering={() => setInvestorWord(investorWord)}
      showCancelButton
    >
      <FormControl
        component="fieldset"
        fullWidth
        className={classes.radioGroup}
      >
        <div className={classes.input}>
          <FormLabel className={classes.languageFormLabel}>
            Select the correct language for profile questions:
          </FormLabel>
          <RadioGroup
            row
            aria-label="organization-exists"
            name="row-radio-buttons-group"
            value={word}
            onChange={(e) => {
              setInvestorWord(e.target.value);
            }}
          >
            <FormControlLabel
              value="investor"
              control={<Radio />}
              label="Investor"
            />
            <FormControlLabel
              value="subscriber"
              control={<Radio />}
              label="Subscriber"
            />
          </RadioGroup>
        </div>
      </FormControl>
    </Modal>
  );
};
