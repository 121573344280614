import React from 'react';
import { Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IssueCell } from './issue_cell';

const useStyles = makeStyles((theme) => ({
  groupValue: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: '4px',
  },
  nameDisplay: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing(1),
  },
}));

export function IssueFilterEntry({ issue, issueFilter, setIssueFilter }) {
  const classes = useStyles();

  const isChecked = issueFilter.some((f) => f === issue.key);

  return (
    <div className={classes.groupValue}>
      <div className={classes.nameDisplay}>
        <IssueCell issueType={issue.key} />
      </div>

      <Checkbox
        checked={isChecked}
        // the checkbox onChange callback does not reliably execute
        // so rely on the onClick handler instead
        onClick={() => {
          if (isChecked) {
            const newIssueFilter = issueFilter.toSpliced(
              issueFilter.indexOf(issue.key),
              1,
            );
            setIssueFilter(newIssueFilter);
          } else {
            setIssueFilter([issue.key, ...issueFilter]);
          }
        }}
      />
    </div>
  );
}
