import { getOwners } from './tree_utils';

class Node {
  constructor({
    question,
    children,
    childLabel,
    treeSlices = [],
    parentName = null,
  }) {
    this.question = question;
    this.children = children;
    this.treeSlices = treeSlices;
    this.childLabel = childLabel;
    this.parentName = parentName;
  }

  formatChildParentRelationship() {
    if (!this.childLabel) {
      return '';
    }

    return `${this.childLabel} of ${this.parentName}`;
  }

  addTreeSlice(slice) {
    this.treeSlices.push(slice);
  }

  getAllChildren() {
    // Return all children of this node- including all the children
    // of children (recursive)
    if (this.children.length === 0) {
      return [];
    }

    let result = [...this.children];
    this.children.forEach((c) => {
      result = [...result, ...c.getAllChildren()];
    });

    return result;
  }

  getInPreOrder() {
    // Doesn't actually sort/do any ordering, assumes children are already sorted
    if (this.children.length === 0) {
      return [this];
    }

    let result = [this];
    this.children.forEach((c) => {
      result = [...result, ...c.getInPreOrder()];
    });
    return result;
  }

  hasDirectChild(childNode) {
    const directChildrenLabels = this.children.map((c) => c.question.label);

    return directChildrenLabels.includes(childNode.question.label);
  }

  upToLastChild() {
    // Excludes last child
    const childrenMinusLast = this.children.slice(0, this.children.length - 1);
    if (childrenMinusLast.length === 0) {
      return [];
    }

    let result = [...childrenMinusLast];
    childrenMinusLast.forEach((c) => {
      result = [...result, ...c.getAllChildren()];
    });
    return result;
  }

  afterLastChild() {
    // Includes last child
    const lastChild = this.children.slice(
      this.children.length - 1,
      this.children.length,
    );
    if (lastChild.length === 0) {
      return [];
    }

    return [lastChild[0], ...lastChild[0].getAllChildren()];
  }

  findNodeByLabel(tgtLabel) {
    if (!tgtLabel) {
      return null;
    }

    if (this.question.label === tgtLabel) {
      return this;
    }

    if (this.children.length === 0) {
      return null;
    }

    let result = null;
    this.children.forEach((c) => {
      result = result || c.findNodeByLabel(tgtLabel);
    });

    return result;
  }
}

export function getQuestionsAsNodes(
  diligenceQuestion,
  allDiligenceQuestions,
  jurisdiction,
  childLabel = null,
  parentName = null,
) {
  if (!diligenceQuestion?.answer) {
    return null;
  }

  const nodeName = diligenceQuestion.answer?.name || null;

  const children = getOwners(diligenceQuestion.answer, jurisdiction).map(
    (owner) => {
      const childQuestion = allDiligenceQuestions.find(
        (subQ) => subQ.label === owner.id,
      );

      return getQuestionsAsNodes(
        childQuestion,
        allDiligenceQuestions,
        jurisdiction,
        owner.singularLabel,
        nodeName,
      );
    },
  );

  return new Node({
    question: diligenceQuestion,
    children,
    childLabel,
    parentName,
  });
}

export function addSlices(node) {
  node.upToLastChild().forEach((child) => {
    if (node.hasDirectChild(child)) {
      child.addTreeSlice('┠');
    } else {
      child.addTreeSlice('|');
    }
  });
  node.afterLastChild().forEach((child) => {
    if (node.hasDirectChild(child)) {
      child.addTreeSlice('╰');
    } else {
      child.addTreeSlice(' ');
    }
  });
  node.children.forEach((child) => {
    addSlices(child);
  });
}
