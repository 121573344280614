import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import * as api from 'services/api';
import { useToast } from 'services/toast';
import { useInvestorTaggingModalState } from './state_provider';
import {
  INVESTOR_TAGGING_REDUCER_ACTIONS,
  TAGGING_MODAL_STAGES,
} from '../constants';

const InvestorTaggingMutationsContext = React.createContext({});

export function InvestorTaggingMutationsProvider({
  refreshTaggingData,
  handleClose,
  children,
}) {
  const { fundId, closingId } = useParams();
  const { toast } = useToast();

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const {
    tagsToApply,
    tagsToRetain,
    selectedTag,
    selectedTagGroup,
    newTagName,
    newTagGroupName,
    dispatch,
  } = useInvestorTaggingModalState();

  function resetLoadingState() {
    setLoading(false);
    setErrorMsg('');
  }

  function handleApplyTags(selectedInvestors) {
    const lpClosingIds = selectedInvestors.map((i) => i.id);
    const tagIdsToApply = tagsToApply.map((it) => it.id);
    const tagIdsToRetain = tagsToRetain.map((it) => it.id);

    const usePluralLanguage = selectedInvestors?.length > 1;
    setLoading(true);

    return api
      .tagInvestors({
        fundId,
        closingId,
        lpClosingIds,
        tagIdsToApply,
        tagIdsToRetain,
      })
      .then(() => {
        refreshTaggingData();
        toast(
          `Tagged ${selectedInvestors.length} investor${
            usePluralLanguage ? 's' : ''
          }`,
        );
        handleClose();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleCreateTag() {
    setLoading(true);
    setErrorMsg(null);

    return api
      .createFundTag({
        fundId,
        tagName: newTagName,
        tagGroupName: newTagGroupName,
      })
      .then(() => {
        toast('Tag created');
        dispatch({
          type: INVESTOR_TAGGING_REDUCER_ACTIONS.MOVE_BACKWARDS,
          modalStage: TAGGING_MODAL_STAGES.MANAGE_TAGS,
        });
        refreshTaggingData();
      })
      .catch((e) => {
        setErrorMsg(e.response.data.tagName);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleEditTagGroup() {
    const cleanedName = newTagGroupName.trim();
    setLoading(true);
    setErrorMsg(null);

    return api
      .editFundTagGroup({
        fundId,
        tagGroupId: selectedTagGroup.id,
        newGroupName: cleanedName,
      })
      .then(() => {
        toast('Group renamed');
        refreshTaggingData();
        dispatch({
          type: INVESTOR_TAGGING_REDUCER_ACTIONS.MOVE_BACKWARDS,
          modalStage: TAGGING_MODAL_STAGES.MANAGE_TAGS,
        });
      })
      .catch((err) => {
        setErrorMsg(err.response.data.newGroupName);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleEditTag() {
    const cleanedTagName = newTagName.trim();
    const cleanedTagGroupName = newTagGroupName.trim();
    setLoading(true);
    setErrorMsg(null);

    return api
      .editFundTag({
        fundId,
        tagId: selectedTag.id,
        tagName: cleanedTagName,
        tagGroupName: cleanedTagGroupName,
      })
      .then(() => {
        toast('Changes saved');
        refreshTaggingData();
        dispatch({
          type: INVESTOR_TAGGING_REDUCER_ACTIONS.MOVE_BACKWARDS,
          modalStage: TAGGING_MODAL_STAGES.MANAGE_TAGS,
        });
      })
      .catch((e) => {
        setErrorMsg(e.response.data.tagName);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleDeleteTag(tag) {
    api.deleteTag({ fundId, tagId: tag.id }).then(() => {
      toast('Tag deleted');

      dispatch({
        type: INVESTOR_TAGGING_REDUCER_ACTIONS.DELETE_TAG,
        tag,
      });

      refreshTaggingData();
    });
  }

  return (
    <InvestorTaggingMutationsContext.Provider
      value={{
        loading,
        errorMsg,
        resetLoadingState,
        handleApplyTags,
        handleCreateTag,
        handleEditTagGroup,
        handleEditTag,
        handleDeleteTag,
      }}
    >
      {children}
    </InvestorTaggingMutationsContext.Provider>
  );
}

export function useInvestorTaggingMutations() {
  return useContext(InvestorTaggingMutationsContext);
}
