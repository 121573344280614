import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { DisplayLink } from 'components/link';
import { SSNInput } from 'components/ssn';
import { Answers } from '../answers';
import { UNSAVED } from '../saved';

export function SSNWithEscapeQuestion({
  updateLpDoc,
  setSaving,
  answer,
  label,
  QuestionStepper,
  frontendLabel,
  QuestionPromptComponent,
}) {
  const initialSsn = answer || '';
  const [ssn, setSsn] = useState(initialSsn);
  const [errorMsg, setErrorMsg] = useState('');

  const isValid = ssn.match(/^\d\d\d-\d\d-\d\d\d\d$/);

  const [showSmartInput, setShowSmartInput] = useState(!ssn || isValid);
  const hasInput = Boolean(ssn);

  function handleSubmit(e) {
    e.preventDefault();

    setErrorMsg('');

    return updateLpDoc({ label, answer: ssn, skipQuestion: !hasInput }).catch(
      (error) => {
        setSaving(UNSAVED);
        if (error.response?.status === 400) {
          setErrorMsg(error.response.data.answer);
        }
        return error;
      },
    );
  }

  function handleChange(e) {
    setSaving(UNSAVED);
    setSsn(e.target.value);
  }

  // Reset the input when we change questions.
  useEffect(() => {
    setSsn(initialSsn);
    setErrorMsg('');
  }, [label]);

  return (
    <form onSubmit={handleSubmit}>
      <QuestionPromptComponent />
      <Answers>
        {showSmartInput ? (
          <>
            <TextField
              id="ssn"
              autoFocus
              label={frontendLabel || 'Social Security Number'}
              variant="outlined"
              value={ssn}
              error={!!errorMsg}
              helperText={errorMsg}
              onChange={handleChange}
              InputProps={{
                inputComponent: SSNInput,
              }}
              fullWidth
            />
            <FormHelperText>
              <DisplayLink
                onClick={() => {
                  setShowSmartInput(false);
                }}
              >
                I don't have an SSN ›
              </DisplayLink>
            </FormHelperText>
          </>
        ) : (
          <>
            <TextField
              id="ssn"
              autoFocus
              label="Other Tax ID"
              variant="outlined"
              value={ssn}
              error={!!errorMsg}
              helperText={errorMsg}
              onChange={handleChange}
              fullWidth
            />
            <FormHelperText>
              <DisplayLink
                onClick={() => {
                  setShowSmartInput(true);
                }}
              >
                I have an SSN ›
              </DisplayLink>
            </FormHelperText>
          </>
        )}
        <QuestionStepper
          disabled={(!isValid && showSmartInput) || (!ssn && !showSmartInput)}
          handleSubmit={handleSubmit}
          hasAnswerChanged={ssn !== initialSsn}
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
}
