import React from 'react';
import { Typography } from '@passthrough/uikit';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { INVESTOR_TAGGING_REDUCER_ACTIONS } from './constants';
import { useInvestorTaggingModalState } from './providers/state_provider';
import { useInvestorTaggingMutations } from './providers/mutations_provider';

export function CreateEditTagModalContents({ investorTagGroups }) {
  const { newTagName, newTagGroupName, dispatch } =
    useInvestorTaggingModalState();
  const { errorMsg } = useInvestorTaggingMutations();

  const existingTagGroupNames =
    investorTagGroups?.map((tagGroup) => tagGroup.name) || [];

  const tagDataIsInvalid =
    !newTagName ||
    newTagName?.trim() === '' ||
    !newTagGroupName ||
    newTagGroupName?.trim() === '';
  const willCreateNewTagName =
    !tagDataIsInvalid && !existingTagGroupNames.includes(newTagGroupName);

  return (
    <>
      <TextField
        variant="outlined"
        type="text"
        label="Tag"
        error={errorMsg}
        helperText={errorMsg}
        value={newTagName}
        onChange={(e) => {
          dispatch({
            type: INVESTOR_TAGGING_REDUCER_ACTIONS.EDIT_TAG_NAME,
            newTagName: e.target.value,
          });
        }}
      />

      <Autocomplete
        value={newTagGroupName}
        freeSolo
        fullWidth
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={existingTagGroupNames}
        onChange={(e, autoGroupName) => {
          dispatch({
            type: INVESTOR_TAGGING_REDUCER_ACTIONS.EDIT_TAG_GROUP_NAME,
            newTagGroupName: autoGroupName,
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Group"
            variant="outlined"
            fullWidth
            onChange={(e) => {
              dispatch({
                type: INVESTOR_TAGGING_REDUCER_ACTIONS.EDIT_TAG_GROUP_NAME,
                newTagGroupName: e.target.value,
              });
            }}
          />
        )}
      />

      {willCreateNewTagName ? (
        <Typography>
          A new tag group "{newTagGroupName}" will be created.
        </Typography>
      ) : null}
    </>
  );
}
