import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import GetAppIcon from '@material-ui/icons/GetApp';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'components/dialog/index';
import { Button, LinkButton } from 'components/button';
import { Spinner } from 'components/spinner';
import * as api from 'services/api';
import { useToast } from 'services/toast';

const useStyles = makeStyles((theme) => ({
  helperText: {
    color: theme.palette.text.secondary,
  },
  dialogContent: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  download: {
    margin: theme.spacing(2, 0),
  },
}));

export function AnswersDialog({ open, handleClose, closings }) {
  const classes = useStyles();
  const { fundId } = useParams();
  const { successToast } = useToast();
  const [exportLoading, setExportLoading] = useState(false);
  const [exportUrl, setExportUrl] = useState(null);
  const [closingId, setClosingId] = useState(null);

  function pollForResults(exportId) {
    api
      .getLpClosingsExport({ fundId, closingId, exportId })
      .then((response) => {
        if (response.data.url) {
          setExportLoading(false);
          setExportUrl(response.data.url);
          successToast('Generated excel export');
        } else {
          setTimeout(() => {
            pollForResults(exportId);
          }, 500);
        }
      });
  }

  function onSubmit(e) {
    e.preventDefault();

    setExportLoading(true);

    api.exportLpAnswers({ fundId, closingId }).then((response) => {
      const exportId = response.data;
      setTimeout(() => {
        pollForResults(exportId);
      }, 500);
    });
  }

  useEffect(() => {
    setExportUrl(null);
    setClosingId(null);
  }, [open]);

  function dialogContent() {
    return (
      <>
        <DialogTitle>Export answers</DialogTitle>
        <DialogContent dividers className={classes.dialogContent}>
          <Typography>
            Select a closing to export its answers. Generating an export may
            take a few moments.
          </Typography>

          <FormControl variant="outlined" fullWidth>
            <InputLabel id="closing-label">Fund closing</InputLabel>
            <Select
              value={closingId}
              id="closing-select"
              onChange={(e) => {
                setClosingId(e.target.value);
                setExportUrl(null);
              }}
              label="Fund closing"
              labelId="closing-label"
              disabled={exportLoading}
            >
              {closings.map((closing) => (
                <MenuItem key={closing.id} value={closing.id}>
                  {closing.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {exportLoading ? (
            <>
              <Typography>Your export is being generated.</Typography>
              <div className={classes.spinner}>
                <Spinner height={300} width={300} />
              </div>
            </>
          ) : null}
          {exportUrl ? (
            <LinkButton
              variant="contained"
              color="primary"
              href={exportUrl}
              onClick={() => {
                handleClose();
              }}
              endIcon={<GetAppIcon />}
              className={classes.download}
            >
              Download report
            </LinkButton>
          ) : null}
        </DialogContent>
        <DialogActions>
          {exportUrl ? (
            <Button
              type="submit"
              variant="contained"
              color="default"
              fullWidth
              onClick={handleClose}
            >
              Close
            </Button>
          ) : (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              onClick={onSubmit}
              disabled={exportLoading || !closingId}
            >
              Generate export
            </Button>
          )}
        </DialogActions>
      </>
    );
  }

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      onClose={(ev) => {
        const keepOpen = exportLoading || exportUrl;
        if (keepOpen) {
          ev.preventDefault();
          ev.stopPropagation();
          return;
        }
        setClosingId(null);
        handleClose();
      }}
    >
      {dialogContent()}
    </Dialog>
  );
}
