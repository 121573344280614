import React from 'react';
import { getAnswerDisplayComponent } from 'components/answer_displays/get_answer_display';
import { FieldContainer, Field } from './fields';

export const BankWithAddressDisplay = ({ answer }) => {
  const AnswerComponent = getAnswerDisplayComponent('AnswerType.address');
  return (
    <FieldContainer>
      <Field label="Bank name" labelMinWidth={180}>
        {answer?.bank?.bankName || '-'}
      </Field>
      <Field label="Account name">{answer?.bank?.accountName || '-'}</Field>
      <Field label="Account number">{answer?.bank?.accountNumber || '-'}</Field>
      <Field label="ABA (routing number)">{answer?.bank?.aba || '-'}</Field>
      <Field label="SWIFT">{answer?.bank?.swift || '-'}</Field>
      <Field label="For further credit">{answer?.bank?.ffct || '-'}</Field>
      <Field label="Address">
        {answer?.googleAddress || answer?.manualAddress ? (
          <AnswerComponent
            answer={answer.googleAddress || answer.manualAddress}
          />
        ) : (
          '-'
        )}
      </Field>
    </FieldContainer>
  );
};
