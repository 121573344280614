import React, { useState, useEffect } from 'react';
import Divider from '@material-ui/core/Divider';

import * as api from 'services/api';
import * as urls from 'services/urls';
import { useToast } from 'services/toast';
import { getSingleErrorFromResponse } from 'services/utils';
import { Dialog } from 'components/dialog';

import { VIEW, EDIT } from './constants';
import { Title } from './title';
import { Content } from './content/index';
import { Actions } from './actions';

export function MemberAccessDialog({
  open,
  onClose,
  fundId,
  fundName,
  fundAccessRestricted,
  teamMembers,
  reloadFunds,
}) {
  const [mode, setMode] = useState(VIEW);
  const [memberToDelete, setMemberToDelete] = useState({});
  const [formDataForCreate, setFormDataForCreate] = useState({});
  const [formErrorsForCreate, setFormErrorsForCreate] = useState({});
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const organizationId = urls.useOrganizationId();
  const { toast } = useToast();

  const disableAddButton =
    !formDataForCreate.name ||
    !formDataForCreate.email ||
    !formDataForCreate.level;

  function resetState() {
    setMode(VIEW);
    setIsLoading(false);
    setMemberToDelete({});
    setFormDataForCreate({});
    setFormErrorsForCreate({});
  }

  useEffect(() => {
    if (open) {
      resetState();
    }
  }, [open]);

  // clear error message when moving to a different state
  useEffect(() => {
    setErrorMsg('');
    setFormErrorsForCreate({});
  }, [mode]);

  function onAddMemberSubmit(event) {
    event.preventDefault();
    setFormErrorsForCreate({});
    setErrorMsg('');
    setIsLoading(true);

    const { name, email, level } = formDataForCreate;
    api
      .orgFundMemberCreate({
        organizationId,
        fundId,
        name,
        email,
        level,
      })
      .then(() => {
        reloadFunds().then(() => {
          setIsLoading(false);
          setFormDataForCreate({});
          setMode(EDIT);
          toast(`Added ${name}`);
        });
      })
      .catch((error) => {
        setErrorMsg(getSingleErrorFromResponse(error.response));
        if (error.response?.status === 400 && !error.response.data?.detail) {
          setFormErrorsForCreate(error.response.data);
        }
        setIsLoading(false);
      });
  }

  function onMemberLevelUpdate(memberId, level) {
    setIsLoading(true);
    setErrorMsg('');
    api
      .orgFundMemberUpdate({
        organizationId,
        fundId,
        memberId,
        level,
      })
      .then(() => {
        reloadFunds().then(() => {
          setIsLoading(false);
          toast('Updated member level');
        });
      })
      .catch((error) => {
        setErrorMsg(getSingleErrorFromResponse(error.response));
        setIsLoading(false);
      });
  }

  function onMemberDelete() {
    setIsLoading(true);
    setErrorMsg('');
    api
      .orgFundMemberDelete({
        organizationId,
        fundId,
        memberId: memberToDelete.id,
      })
      .then(() => {
        reloadFunds().then(() => {
          setIsLoading(false);
          setMode(EDIT);
          toast(`Removed ${memberToDelete.name || memberToDelete.email}`);
        });
      })
      .catch((error) => {
        setErrorMsg(getSingleErrorFromResponse(error.response));
        setIsLoading(false);
      });
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      aria-labelledby="member-access-dialog-title"
    >
      <Title mode={mode} fundName={fundName} memberToDelete={memberToDelete} />
      <Content
        mode={mode}
        setMode={setMode}
        fundName={fundName}
        teamMembers={teamMembers}
        memberToDelete={memberToDelete}
        setMemberToDelete={setMemberToDelete}
        formDataForCreate={formDataForCreate}
        setFormDataForCreate={setFormDataForCreate}
        formErrorsForCreate={formErrorsForCreate}
        onAddMemberSubmit={onAddMemberSubmit}
        onMemberLevelUpdate={onMemberLevelUpdate}
        errorMsg={errorMsg}
        isLoading={isLoading}
      />
      <Divider />
      <Actions
        mode={mode}
        setMode={setMode}
        onClose={onClose}
        disableAddButton={disableAddButton}
        onAddMemberSubmit={onAddMemberSubmit}
        onMemberDelete={onMemberDelete}
        fundAccessRestricted={fundAccessRestricted}
        isLoading={isLoading}
      />
    </Dialog>
  );
}
