import * as React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import { PDF_WIDTH, PDF_HEIGHT } from 'services/pdf_dimensions';
import clsx from 'clsx';
import { useDocumentsDrawerState, hashPdfBoxes } from './utils';

const useStyles = makeStyles(() => ({
  canvas: {
    position: 'absolute',
    top: 0,
    mixBlendMode: 'multiply',
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
      '&$pointer': {
        cursor: 'pointer',
      },
    },
  },
  pointer: {
    cursor: 'pointer',
  },
}));

const PRIMARY_BUTTON = 1;

export function OverlayCanvas({
  nav,
  questions,
  currentQuestion,
  pdfBoxes,
  scale,
  containerRef,
}) {
  const classes = useStyles();
  const drawCanvasRef = React.useRef(null);
  const { pdf, page, linkId } = useDocumentsDrawerState();

  const theme = useTheme();
  const [showPointer, setShowPointer] = React.useState(false);

  const container = containerRef.current;

  function initDrawingCanvas() {
    if (!pdf) {
      // When the PDF hasn't loaded yet, don't render the canvas to avoid drawing
      // floating boxes
      return () => {};
    }

    const drawCanvas = drawCanvasRef.current;
    const ctx = drawCanvas.getContext('2d');

    const onPageBoxes = pdfBoxes.filter((box) => {
      if (box.page !== page) {
        return false;
      }
      if (box.highlightType === 'context') {
        const question = getQuestionFromBox(box);

        return (
          question.label === currentQuestion?.label && box.linkId === linkId
        );
      }
      return true;
    });

    function getMousePos(e) {
      const bound = drawCanvas.getBoundingClientRect();
      return {
        x: Math.round(
          ((e.clientX - bound.left) / (bound.right - bound.left)) *
            scale *
            PDF_WIDTH,
        ),
        y: Math.round(
          ((e.clientY - bound.top) / (bound.bottom - bound.top)) *
            scale *
            PDF_HEIGHT,
        ),
      };
    }

    function findBoxForMousePos(e) {
      const mouse = getMousePos(e);
      const found = onPageBoxes.find((box) => {
        const path = new Path2D();
        path.rect(
          scale * box.x,
          scale * box.y,
          scale * box.width,
          scale * box.height,
        );
        return ctx.isPointInPath(path, mouse.x, mouse.y);
      });
      return found;
    }

    function mousemove(e) {
      const box = findBoxForMousePos(e);

      if (box && box.highlightType !== 'context') {
        setShowPointer('pointer');
      } else {
        setShowPointer('move');
      }
    }

    function getQuestionFromBox(box) {
      return questions.find((q) =>
        (q.pdfBoxes || []).map((h) => h.apiId).includes(box.apiId),
      );
    }

    function click(e) {
      const box = findBoxForMousePos(e);

      if (box && box.highlightType !== 'context') {
        const question = getQuestionFromBox(box);
        if (
          question &&
          question.label !== 'QSignatures' &&
          question.label !== currentQuestion?.label
        ) {
          nav.toQuestionAndSection(question.sectionId, question.label);
        }
      }
    }

    function drawBox(box, fillColor, lineColor, lineWidth) {
      ctx.scale(scale, scale);
      ctx.fillStyle = `${fillColor}`;
      ctx.fillRect(box.x, box.y, box.width, box.height);
      ctx.strokeStyle = lineColor;
      ctx.lineWidth = lineWidth;
      if (lineWidth > 0) {
        ctx.strokeRect(box.x, box.y, box.width, box.height);
      }
      ctx.scale(1 / scale, 1 / scale);
    }

    function getThemeColorForBox(question, box) {
      if (box.highlightType === 'context') {
        return theme.palette.emphasis;
      }

      if (question.needsConfirmation) {
        return theme.palette.warning;
      }

      return theme.palette.info;
    }

    function drawBoxes() {
      onPageBoxes.forEach((b) => {
        const question = getQuestionFromBox(b);
        const color = getThemeColorForBox(question, b);
        drawBox(
          b,
          color.fill,
          color.main,
          currentQuestion?.label === question.label ? 2 : 0,
        );
      });
    }
    if (pdf) {
      drawBoxes();
    }

    drawCanvas.addEventListener('click', click, true);
    drawCanvas.addEventListener('mousemove', mousemove, true);

    function cleanup() {
      ctx.clearRect(0, 0, scale * PDF_WIDTH, scale * PDF_HEIGHT);
      drawCanvas.removeEventListener('click', click, true);
      drawCanvas.removeEventListener('mousemove', mousemove, true);
    }

    return cleanup;
  }

  React.useEffect(initDrawingCanvas, [
    pdf,
    page,
    linkId,
    hashPdfBoxes(pdfBoxes),
    scale,
    currentQuestion?.label,
    nav,
  ]);

  return (
    <canvas
      ref={drawCanvasRef}
      className={clsx(classes.canvas, {
        [classes.pointer]: showPointer === 'pointer',
      })}
      onMouseMove={(e) => {
        if (e.buttons === PRIMARY_BUTTON) {
          container.scrollLeft += -e.movementX;
          container.scrollTop += -e.movementY;
        }
      }}
      width={scale * PDF_WIDTH}
      height={scale * PDF_HEIGHT}
      data-test="pro-mode"
    />
  );
}
