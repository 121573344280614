import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import SelectionDragIcon from 'mdi-material-ui/SelectionDrag';

import { Button } from 'components/button';
import { BoxInput } from './box_input';
import { HellosignBoxUsageWarnings } from '../warnings/usage_warnings';
import { DEPRECATED_FIELDS_BY_ANSWER_TYPE } from '../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  choiceNameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  choiceName: {
    fontWeight: '600',
    color: theme.palette.text.secondary,
  },
  cardContent: {
    padding: theme.spacing(0, 2),
  },
  choiceText: {
    backgroundColor: 'white',
  },
  leftInput: {
    marginRight: theme.spacing(1),
  },
  warning: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
}));

export function QuestionFieldInput({
  field,
  hellosignBoxes,
  setPage,
  changeBox,
  deleteBox,
  dragBox,
  addBox,
  highlightBox,
  setHighlightBoxId,
  questions,
  handleClickItem,
  questionId,
  answerType,
  selectDocument,
}) {
  const classes = useStyles();
  const isDeprecated = (
    DEPRECATED_FIELDS_BY_ANSWER_TYPE[answerType] || []
  ).includes(field);
  if (isDeprecated && hellosignBoxes.length === 0) {
    return null;
  }
  const fieldDisplay = isDeprecated ? `${field} (DEPRECATED)` : field;
  return (
    <Card variant="outlined" className={classes.root}>
      <div className={classes.cardContent}>
        <HellosignBoxUsageWarnings
          classes={classes}
          answerType={answerType}
          field={field}
          boxes={hellosignBoxes}
        />
        <div className={classes.actions}>
          <div className={classes.choiceNameContainer}>
            <Typography className={classes.choiceName}>
              {fieldDisplay}
            </Typography>
          </div>

          <div>
            <Button
              variant="text"
              className={classes.leftInput}
              startIcon={<SelectionDragIcon />}
              onClick={() => addBox({ type: 'output_text', field })}
            >
              Text
            </Button>
            <Button
              variant="text"
              startIcon={<SelectionDragIcon />}
              onClick={() => addBox({ type: 'output_checkbox', field })}
            >
              Checkbox
            </Button>
          </div>
        </div>
        {hellosignBoxes.map((box) => (
          <div key={box.id}>
            <BoxInput
              box={box}
              onChange={changeBox}
              onDelete={deleteBox}
              onSelectDrag={dragBox}
              setPage={setPage}
              highlight={
                highlightBox.id === box.id ? highlightBox.timestamp : null
              }
              setHighlightBoxId={setHighlightBoxId}
              questions={questions}
              handleClickItem={handleClickItem}
              questionId={questionId}
              selectDocument={selectDocument}
            />
          </div>
        ))}
      </div>
    </Card>
  );
}
