import format from 'date-fns/format';
import intervalToDuration from 'date-fns/intervalToDuration';
import { DEFAULT_CURRENCY } from './constants';

export function objectEquals(x, y) {
  // https://stackoverflow.com/a/16788517/2168192
  if (x === null || x === undefined || y === null || y === undefined) {
    return x === y;
  }
  if (x.constructor !== y.constructor) {
    return false;
  }
  if (x instanceof Function) {
    return x === y;
  }
  if (x instanceof RegExp) {
    return x === y;
  }
  if (x === y || x.valueOf() === y.valueOf()) {
    return true;
  }
  if (Array.isArray(x) && x.length !== y.length) {
    return false;
  }
  if (x instanceof Date) {
    return false;
  }
  if (!(x instanceof Object)) {
    return false;
  }
  if (!(y instanceof Object)) {
    return false;
  }

  // recursive object equality check
  const p = Object.keys(x);
  return (
    Object.keys(y).every((i) => p.indexOf(i) !== -1) &&
    p.every((i) => objectEquals(x[i], y[i]))
  );
}

export function formatCurrency(text, currency = DEFAULT_CURRENCY) {
  const { symbol } = currency;

  return Number(text)
    .toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    .replace('.00', '')
    .replace(/\$/g, symbol);
}

function updateReact(input, type, lastValue) {
  const event = new Event(type, { bubbles: true });
  // hack React15
  event.simulated = true;
  // hack React16
  // eslint-disable-next-line no-underscore-dangle
  const tracker = input._valueTracker;
  if (tracker) {
    tracker.setValue(lastValue);
  }
  input.dispatchEvent(event);
}

export function forceInput(selector, value, callback) {
  const elements = document.querySelectorAll(selector);

  elements.forEach((element) => {
    if (element.select) {
      element.select();
    }

    element.click();

    const input = element;
    const lastValue = input.value;
    input.value = value;
    updateReact(input, 'input', lastValue);
    updateReact(input, 'change', lastValue);
    input.blur();
    callback?.(input);
  });

  return elements;
}

export function isLongAnswer({ answer, answerType }) {
  if (answer) {
    if (
      answerType === 'AnswerType.w9_v3' ||
      answerType === 'AnswerType.w9_v4'
    ) {
      return true;
    }
    if (answerType === 'AnswerType.multi_select') {
      return answer.some((a) => a.length > 150);
    }
    if (answer.length > 200) {
      return true;
    }
  }
  return false;
}

export function formatName(answer) {
  return `${answer.firstName} ${answer.lastName}`;
}

export function formatDate(answer) {
  if (typeof answer?.getMonth === 'function') {
    return format(answer, 'MM/dd/yyyy');
  }
  return answer;
}

function areFilesEqual(priorFile, currFile) {
  if (
    priorFile === null ||
    priorFile === undefined ||
    currFile === null ||
    currFile === undefined ||
    !('fileId' in priorFile) ||
    !('fileId' in currFile) ||
    !('fileName' in priorFile) ||
    !('fileName' in currFile)
  ) {
    return priorFile === currFile;
  }

  return (
    currFile.fileId === priorFile.fileId &&
    currFile.fileName === priorFile.fileName
  );
}

function areMultiFileUploadAnswersEqual(priorAnswer, currAnswer) {
  if (
    priorAnswer === null ||
    priorAnswer === undefined ||
    currAnswer === null ||
    currAnswer === undefined ||
    !(Array.isArray(priorAnswer) || Array.isArray(currAnswer))
  ) {
    return priorAnswer === currAnswer;
  }

  const currLength = currAnswer.length;
  const priorLength = priorAnswer.length;

  if (currLength !== priorLength) {
    return false;
  }

  let allFilesEqual = true;

  for (let i = 0; i < currLength; i += 1) {
    const currFile = currAnswer[i];
    const priorFile = priorAnswer[i];

    allFilesEqual = allFilesEqual && areFilesEqual(priorFile, currFile);
  }

  return allFilesEqual;
}

export function hasLPAnswerChanged(priorAnswer, question) {
  const currAnswer = question.answer;
  // do not care if an optional question was not asked previously,
  // but is now asked in current lp submission but not given an answer
  if (priorAnswer === undefined && currAnswer === null) {
    return false;
  }

  // NOTE: the url for a single file changes across signings in remote envs,
  // so only compare against limited set of fields
  if (question.answerType === 'AnswerType.file_upload') {
    return !areFilesEqual(priorAnswer, currAnswer);
  }
  if (question.answerType === 'AnswerType.multi_file_upload') {
    return !areMultiFileUploadAnswersEqual(priorAnswer, currAnswer);
  }

  return !objectEquals(priorAnswer, currAnswer);
}

export function flattenLPClosingChangeEvents(lpDocs) {
  return lpDocs.map((doc) => doc.changeEvents).flat();
}

export function filterChangeEventsByTypes(changeEvents, eventTypes) {
  return changeEvents.filter((changeEvent) =>
    eventTypes.includes(changeEvent.type),
  );
}

export function groupChangeEventsByQuestion(allChangeEvents) {
  const eventsPerQuestion = allChangeEvents.reduce((compiledEvents, event) => {
    if (event) {
      const updatedEvents = compiledEvents;

      const processedEvents = updatedEvents[event.questionId] || [];

      processedEvents.push(event);
      updatedEvents[event.questionId] = processedEvents;

      return updatedEvents;
    }

    return compiledEvents;
  }, {});

  return eventsPerQuestion;
}

export const getSingleErrorFromResponse = (response) => {
  /**
   * Returns a single string representing the error from the following formats:
   *    1. ["This is an error."] -> "This is an error"
   *    2. { nonFieldErrors: ["This is an error"] } -> "This is an error"
   *    3. { data: {detail: "This is an error" } -> "This is an error"
   *
   * Defaults to "An unexpected error occurred." when it does not match
   * any format or is not a validation error.
   */
  const errorMessages = Object.values(response?.data || []).flat();
  if (response?.status === 400 && errorMessages.length) {
    return errorMessages[0];
  }

  if (response?.status === 403 && response?.data?.detail) {
    return response.data.detail;
  }

  return 'An unexpected error occurred.';
};

export const getErrorListFromResponse = (response) => {
  /**
   * Returns a list representing the errors from the following formats:
   *    1. ["This is an error"] -> ["This is an error"]
   *    2. ["One error", "Another error"] -> ["One error", "Another error"]
   *    3. { nonFieldErrors: ["This is an error"] } -> ["This is an error"]
   *    4. { foo: ["Error 1", "Error 2"] } -> ["Error 1", "Error 2"]
   *    5. { data: {detail: "This is an error" } -> ["This is an error"]
   */
  const errorMessages = Object.values(response?.data || []).flat();
  if (response?.status === 400 && errorMessages.length) {
    return errorMessages;
  }
  if (response?.status === 403 && response?.data?.detail) {
    return [response.data.detail];
  }
  return ['An unexpected error occurred.'];
};

export const cleanLPName = (lpName) =>
  lpName.toLowerCase().replace(/[^a-z0-9\s]/gi, '');

export const containsLpName = (fileName, lpName) => {
  if (!lpName) {
    return false;
  }
  const cleanedLpName = cleanLPName(lpName);
  return cleanedLpName
    .split(' ')
    .every((name) => fileName.toLowerCase().includes(name));
};

export function getAutocompleteOptionLabelByName(option, allItems) {
  let item;

  if (typeof option === 'string') {
    item = allItems.find((c) => c.id === option);
  } else {
    item = option;
  }

  if (item) {
    return item.name;
  }

  return '';
}

export const getSelectedStaffOrg = (me) => me?.selectedOrganizationId;

// python iso8601 format includes 6 digits microseconds
const isIsoDate = (dateStr) =>
  /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{6}Z/.test(dateStr);

const getDateComponent = (dateComponents, key) =>
  dateComponents.find(({ type }) => type === key).value;

const formatIsoDate = (dateStr, localTz) => {
  const tzAwareDateComponents = Intl.DateTimeFormat('en-US', {
    timeZone: localTz,
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }).formatToParts(new Date(dateStr));

  const day = getDateComponent(tzAwareDateComponents, 'day');
  const month = getDateComponent(tzAwareDateComponents, 'month');
  const year = getDateComponent(tzAwareDateComponents, 'year');
  const hour = getDateComponent(tzAwareDateComponents, 'hour');
  const minute = getDateComponent(tzAwareDateComponents, 'minute');
  const dayPeriod = getDateComponent(tzAwareDateComponents, 'dayPeriod');

  return `${day} ${month} ${year}, ${hour}:${minute} ${dayPeriod}`;
};

export function timezoneNow() {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateStr = new Date().toISOString();

  return formatIsoDate(dateStr, tz);
}

function updateAllTimes(answerObj, tz) {
  if (!answerObj) {
    return;
  }

  const keyValuePairs = Object.entries(answerObj);

  for (let i = 0; i < keyValuePairs.length; i += 1) {
    const [key, value] = keyValuePairs[i];
    if (isIsoDate(value)) {
      const formattedDate = formatIsoDate(value, tz);

      // eslint-disable-next-line no-param-reassign
      answerObj[key] = formattedDate;
    }

    if (typeof value === 'object') {
      updateAllTimes(value, tz);
    }
  }
}

export function reformatTzAwareTimes(answerObj, testTz = null) {
  const tz = testTz || Intl.DateTimeFormat().resolvedOptions().timeZone;

  updateAllTimes(answerObj, tz);

  return answerObj;
}

export function groupEventsByTzAwareDate(events, getEventTime, testTz = null) {
  // assumes events are asc sorted by time
  return events.reduce(
    (acc, currEvent) => {
      const tzAwareEvent = reformatTzAwareTimes(currEvent, testTz);
      const rawTimeVal = getEventTime(tzAwareEvent);
      const submissionDate = rawTimeVal.split(',')[0];

      if (acc.currDate !== submissionDate) {
        acc.currDate = submissionDate;
        acc.orderedDates.push(submissionDate);
      }

      if (acc.eventsByDate[submissionDate]) {
        acc.eventsByDate[submissionDate].push(currEvent);
      } else {
        acc.eventsByDate[submissionDate] = [currEvent];
      }

      return acc;
    },
    { orderedDates: [], eventsByDate: [], currDate: null },
  );
}

// wrapper methods to improve readability
export function groupQuestionnaireEventsByTzAwareDate(events) {
  const getEventTime = (event) => event.isoSubmissionTime;
  return groupEventsByTzAwareDate(events, getEventTime);
}

export function groupDiligenceEventsByTzAwareDate(events) {
  const getEventTime = (event) => event.isoEventTime;
  return groupEventsByTzAwareDate(events, getEventTime);
}

function structureAllComments(commentsList) {
  return commentsList.reduce((acc, comment) => {
    const { questionId } = comment;
    if (acc[questionId]) {
      acc[questionId].push(comment);
    } else {
      acc[questionId] = [comment];
    }
    return acc;
  }, {});
}

export function structureAnnotatedComments(commentsData) {
  if (!commentsData) {
    return [];
  }
  const tzAwareComments = reformatTzAwareTimes(commentsData);
  const structuredComments = structureAllComments(tzAwareComments);

  return structuredComments;
}

export const formatProfileLastUsed = (lastUsedDatetime, lastUsedFundName) => {
  if (lastUsedDatetime) {
    let formattedDatetime = '';
    try {
      formattedDatetime = format(new Date(lastUsedDatetime), 'dd LLL, yyyy');
    } catch (e) {
      formattedDatetime = '';
    }

    if (lastUsedFundName) {
      return `Last used ${formattedDatetime} for ${lastUsedFundName}`;
    }

    return `Last used ${formattedDatetime}`;
  }

  return '';
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function splitCamelCaseString(string) {
  return string.replace(/([a-z])([A-Z])/g, '$1 $2');
}

export function genDiligenceAnswerLabel(rawKey) {
  const dataKeysWithDifferentNames = {
    name: 'Full legal name',
    dob: 'Date of birth',
    hqAddress: 'Headquarters address',
    amlEntityDetails: 'AML entity details',
    tinEin: 'TIN or EIN',
    taxId: 'Other tax ID',
    ssn: 'SSN',
    tin: 'SSN or TIN',
    tinOrPassport: 'TIN or Passport number',
    tinOnly: 'TIN',
  };

  if (dataKeysWithDifferentNames[rawKey]) {
    return dataKeysWithDifferentNames[rawKey];
  }

  const splitString = splitCamelCaseString(rawKey);
  return capitalizeFirstLetter(splitString.toLowerCase());
}

export function filterDisplayableDiligenceAnswerKeys(answerData) {
  const answerKeys = Object.keys(answerData);
  const relevantAnswerKeys = answerKeys.filter(
    (key) => !['useSsn', 'optOut'].includes(key),
  );

  return relevantAnswerKeys;
}

export const getDefaultCountersignerLabel = (label) =>
  `Default ${(label || '').toLowerCase()} (optional)`;

export function calculateAge(birthDate) {
  return intervalToDuration({ start: birthDate, end: new Date() }).years;
}

export function getIsAppleDevice() {
  const navigatorPlatform = navigator.platform.toUpperCase();
  return (
    navigatorPlatform.indexOf('MAC') >= 0 ||
    navigatorPlatform.indexOf('IPAD') >= 0 ||
    navigatorPlatform.indexOf('IPOD') >= 0 ||
    navigatorPlatform.indexOf('IPHONE') >= 0
  );
}

export function isValidPageRange(pageRange) {
  /** pageRange is a comma separated list of page numbers or ranges. e.g. "1-5,8,9-10" */
  const pageRangePattern = /^(\d+(-\d+)?)(,\s*\d+(-\d+)?)*$/;
  return pageRangePattern.test(pageRange);
}

export function isPageInRange(pageNumber, pageRange) {
  if (!pageRange) {
    return true;
  }

  if (!isValidPageRange(pageRange)) {
    return false;
  }

  const page = Number(pageNumber);

  if (Number.isNaN(page)) {
    return false;
  }

  return pageRange.split(',').some((range) => {
    const cleanRange = range.trim();
    if (cleanRange.includes('-')) {
      const [start, end] = cleanRange.split('-').map(Number);
      return page >= start && page <= end;
    }
    return page === Number(cleanRange);
  });
}

export function isSameOrigin(url1, url2) {
  /** Returns true if the two urls are from the same origin */
  const normalizedUrl1 = new URL('/', url1);
  const normalizedUrl2 = new URL('/', url2);
  return normalizedUrl1.origin === normalizedUrl2.origin;
}

export function redirectIfDifferentDomain(baseUrl) {
  const origin = `${window.location.origin}/`;

  // Redirect the user to the correct domain if they are on a whitelabel domain
  // and somehow navigated to a fund that doesn't belong to that domain.
  if (baseUrl && origin && !isSameOrigin(baseUrl, origin)) {
    window.location.replace(new URL(window.location.pathname, baseUrl));
    return true;
  }

  return false;
}
