import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useSectionHeadingStyles = makeStyles((theme) => ({
  heading: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerText: {
    display: 'flex',
    fontSize: '1.1rem',
    alignSelf: 'center',
  },
}));

export function SubsectionHeading({ headerText, headerAction }) {
  const classes = useSectionHeadingStyles();

  return (
    <div className={classes.heading}>
      <Typography variant="subtitle2" className={classes.headerText}>
        {headerText}
      </Typography>
      {headerAction}
    </div>
  );
}
