import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Player, PlayerEvent } from '@lottiefiles/react-lottie-player';
import { useQuestionnaire } from 'services/providers/questionnaire';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
  },
  animation: {
    height: '200px',
    width: '200px',
  },
  bottomMargin: {
    marginBottom: theme.spacing(3),
  },
}));

export function ConfettiPlayer({ onAnimationComplete }) {
  const classes = useStyles();
  const { confettiUrl } = useQuestionnaire();

  const onComplete = (event) => {
    if (event === PlayerEvent.Complete) {
      onAnimationComplete();
    }
  };

  return (
    <div className={classes.root}>
      <Player
        autoplay
        src={confettiUrl}
        keepLastFrame
        className={classes.animation}
        onEvent={onComplete}
      />
      <Typography
        variant="h5"
        align="center"
        data-test="questionnaire-finished"
        className={classes.bottomMargin}
      >
        All done!
      </Typography>
    </div>
  );
}
