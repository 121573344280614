import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Alert } from '@passthrough/uikit';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { RadioCardChoice, RadioCardContainer } from 'components/radio_card';
import { useSupport } from 'components/support';

import { DisplayLink } from 'components/link';
import { useWhiteLabelConfig } from 'services/providers/theme';
import { DeliveryOptionDisplay } from './delivery_option_display';
import { CountersignerList } from './countersigner_list';
import {
  DELIVERY_OPTION_BULK_PACKET,
  DELIVERY_OPTION_INDIVIDUAL_PACKETS,
} from './constants';
import { SelectCountersigners } from './select_countersigners';
import { InvestorsWithFullyExecutedSubDoc } from './investors_with_fully_executed_subdoc';

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  deliveryOptionSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  listItem: {
    display: 'list-item',
    listStyleType: 'disc',
    marginLeft: '30px',
  },
}));

export function DeliveryOptionStep({
  investorsWithFullyExecutedSubdoc,
  partiallyCountersignedInvestors,
  allowChangeCountersigners,
  deliveryOption,
  setDeliveryOption,
  showDeliveryOption,
  deliveryOptionError,
  onContinue,
  countersignerData,
  disableFullyExecutedEmail,
  atLeastOneDocumentHasVariables,
  closingSubDoc,
  members,
  countersigners,
  setCountersigners,
  countersignerErrors,
  setCountersignerErrors,
  multiplePackets,
}) {
  const classes = useStyles();
  const { productName } = useWhiteLabelConfig();
  const { showSupport } = useSupport();

  const isContinueLoading = !closingSubDoc;

  return (
    <>
      <Alert severity="warning" skipTypography>
        <Typography variant="body">
          Countersigning will initiate the closing.
        </Typography>
        <Typography variant="body" size="small">
          Confirm with your legal counsel that you intend to close.
          {!disableFullyExecutedEmail ? (
            <>
              {' '}
              Once countersigned, investors will automatically receive fully
              executed subscription agreements from {productName}.
            </>
          ) : null}
        </Typography>
      </Alert>

      {partiallyCountersignedInvestors.length ? (
        <Alert severity="info" skipTypography>
          <Typography variant="body">
            At least one investor has partially countersigned documents.
          </Typography>
          <Typography variant="body" size="small">
            To change countersigners or select the combined packet delivery
            option, clear document signature for the following investors:
          </Typography>
          <List>
            {partiallyCountersignedInvestors.map((investor) => (
              <ListItem key={investor.id} dense className={classes.listItem}>
                <Typography variant="body" size="small">
                  {investor.lpName}
                </Typography>
              </ListItem>
            ))}
          </List>
          <Typography variant="body" size="small">
            You may continue to send all selected investors to countersigner.
          </Typography>
        </Alert>
      ) : null}

      {allowChangeCountersigners ? (
        <SelectCountersigners
          closingSubDoc={closingSubDoc}
          members={members}
          countersigners={countersigners}
          setCountersigners={setCountersigners}
          countersignerErrors={countersignerErrors}
          setCountersignerErrors={setCountersignerErrors}
        />
      ) : (
        <CountersignerList
          allowChangeCountersigners={false}
          countersignerData={countersignerData}
        />
      )}

      {showDeliveryOption ? (
        <div className={classes.deliveryOptionSection}>
          <Typography variant="card-heading">
            How should we send the signature packets?
          </Typography>
          {deliveryOptionError ? (
            <Alert severity="error">{deliveryOptionError}</Alert>
          ) : null}
          <RadioCardContainer
            selectedChoice={deliveryOption}
            setSelectedChoice={setDeliveryOption}
          >
            {partiallyCountersignedInvestors.length ? null : (
              <RadioCardChoice
                choiceKey={DELIVERY_OPTION_BULK_PACKET}
                choiceValue={DELIVERY_OPTION_BULK_PACKET}
                choiceBackground="white"
                setSelectedChoice={setDeliveryOption}
                cardContent={
                  <DeliveryOptionDisplay
                    deliveryOption={DELIVERY_OPTION_BULK_PACKET}
                    atLeastOneDocumentHasVariables={
                      atLeastOneDocumentHasVariables
                    }
                    multiplePackets={multiplePackets}
                  />
                }
              />
            )}
            <RadioCardChoice
              choiceKey={DELIVERY_OPTION_INDIVIDUAL_PACKETS}
              choiceValue={DELIVERY_OPTION_INDIVIDUAL_PACKETS}
              choiceBackground="white"
              setSelectedChoice={setDeliveryOption}
              cardContent={
                <DeliveryOptionDisplay
                  deliveryOption={DELIVERY_OPTION_INDIVIDUAL_PACKETS}
                  atLeastOneDocumentHasVariables={
                    atLeastOneDocumentHasVariables
                  }
                  multiplePackets={multiplePackets}
                />
              }
            />
          </RadioCardContainer>
          <Typography variant="body" size="small">
            If you are unsure about selecting a delivery option,{' '}
            <DisplayLink onClick={showSupport}>contact support</DisplayLink>.
          </Typography>
        </div>
      ) : null}

      {investorsWithFullyExecutedSubdoc.length > 0 ? (
        <div className={classes.section}>
          <InvestorsWithFullyExecutedSubDoc
            investors={investorsWithFullyExecutedSubdoc}
            closingSubDocName={closingSubDoc?.name}
          />
        </div>
      ) : null}

      <div className={classes.actions}>
        <Button
          variant="primary"
          onClick={onContinue}
          size="large"
          loading={isContinueLoading}
          data-test="delivery-option-continue"
        >
          Continue
        </Button>
      </div>
    </>
  );
}
