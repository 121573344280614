import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@passthrough/uikit';
import * as api from 'services/api';
import { PROVIDERS } from '../constants';

const useStyles = makeStyles(() => ({
  button: {
    width: '100%',
  },
  icon: {
    marginRight: 'auto',
  },
  label: {
    marginRight: 'auto',
  },
}));

export const SocialButton = ({ provider, email }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const config = PROVIDERS[provider];

  if (!config) {
    return null;
  }

  const Icon = config.icon;
  const handleClick = () => {
    setLoading(true);
    api
      .socialLogin({ email })
      .then((resp) => {
        if (resp.data.authUri) {
          window.location.href = resp.data.authUri;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Button
      variant="secondary"
      fullWidth
      onClick={handleClick}
      loading={loading}
      disabled={loading}
      htmlProps={{
        style: { maxWidth: '100%' },
      }}
    >
      <Icon className={classes.icon} />
      <span className={classes.label}>{config.label}</span>
    </Button>
  );
};
