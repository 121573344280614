import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';

import * as api from 'services/api';

const ClosingContext = createContext();

export function useClosing() {
  return useContext(ClosingContext);
}

export function ClosingProvider({ fundId, closingId, children }) {
  const [closing, setClosing] = useState(null);
  const [countersignTasks, setCountersignTasks] = useState(null);
  const [change, setChange] = useState(0);
  const lastClosingId = useRef(closingId);

  function getClosing() {
    if (closingId !== lastClosingId.current) {
      setClosing(null);
      setCountersignTasks(null);
      lastClosingId.current = closingId;
    }
    api.closing({ fundId, closingId }).then((response) => {
      setClosing(response.data);
    });
    api.countersignTasks({ fundId, closingId }).then((response) => {
      setCountersignTasks(response.data);
    });
  }

  useEffect(getClosing, [change, fundId, closingId]);

  const data = {
    closing,
    countersignTasks,
  };
  const refresh = () => setChange((c) => c + 1);

  return (
    <ClosingContext.Provider value={[data, refresh]}>
      {children}
    </ClosingContext.Provider>
  );
}
