import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Typography, Icons } from '@passthrough/uikit';
import * as constants from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

export function InvestorAccessLabel({ externalSignupEnabled }) {
  const classes = useStyles();

  const icon = externalSignupEnabled ? (
    <Icons.LanguageOutlined color="primary" />
  ) : (
    <Icons.LockOutlined color="primary" />
  );
  const label = externalSignupEnabled
    ? 'Anyone can join with a sign-up link'
    : 'Investors must be invited to join';

  return (
    <div className={classes.root}>
      {icon}
      <Typography>{label}</Typography>
    </div>
  );
}

export const INVESTOR_ACCESS_LABELS = [
  {
    value: constants.INVITE_ONLY,
    label: <InvestorAccessLabel externalSignupEnabled={false} />,
  },
  {
    value: constants.SIGNUP_LINK,
    label: <InvestorAccessLabel externalSignupEnabled />,
  },
];
