import React from 'react';
import { Typography } from '@passthrough/uikit';

import { RiskRatingChip } from 'components/risk_rating_chip';
import { GenericInvestorTagGroup } from './generic_investor_tag_group';

export function RiskRatingsGroup({
  riskRatingTags,
  locallySelectedFilters,
  setLocallySelected,
}) {
  return (
    <>
      <Typography fontWeight={500}>Risk rating</Typography>

      <GenericInvestorTagGroup
        tags={riskRatingTags}
        getIcon={() => null}
        getDisplayLabel={(riskTag) => (
          <RiskRatingChip riskRating={riskTag.name} />
        )}
        locallySelectedFilters={locallySelectedFilters}
        setLocallySelected={setLocallySelected}
      />
    </>
  );
}
