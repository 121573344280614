import React from 'react';

import {
  TYPE_PENSION_SCHEME,
  TYPE_INDIVIDUAL,
  TYPE_LIMITED_PARTNERSHIP,
  TYPE_PRIVATE_COMPANY,
  TYPE_LISTED_COMPANY,
  TYPE_LLC,
  TYPE_CHARITY_OR_FOUNDATION,
  TYPE_SCHOOL,
  TYPE_S_CORP,
  TYPE_PRIVATE_TRUST,
  TYPE_PARTNERSHIP,
} from '../../../constants';
import { PensionScheme } from '../../../components/details/pension_scheme';
import { IndividualDetails } from '../../../components/details/individual';
import { TinHqAddressDetails } from '../../../components/details/tin_and_hq_address_details';
import { PrivateTrustDetails } from '../../../components/details/private_trust';

export function AdditionalDetails({ form, formErrors, handleChange }) {
  const shouldDisplayTinAndHq = [
    TYPE_LIMITED_PARTNERSHIP,
    TYPE_PRIVATE_COMPANY,
    TYPE_LISTED_COMPANY,
    TYPE_LLC,
    TYPE_CHARITY_OR_FOUNDATION,
    TYPE_SCHOOL,
    TYPE_S_CORP,
    TYPE_PARTNERSHIP,
  ].includes(form.type);

  if (shouldDisplayTinAndHq) {
    return (
      <TinHqAddressDetails
        key={form.type}
        tin={form.tin}
        tinError={formErrors.tin}
        hqAddress={form.hqAddress}
        hqAddressError={formErrors.hqAddress}
        handleChange={handleChange}
      />
    );
  }

  if (form.type === TYPE_PRIVATE_TRUST) {
    return (
      <PrivateTrustDetails
        tin={form.tin}
        tinError={formErrors.tin}
        trustType={form.trustType}
        trustTypeError={formErrors.trustType}
        handleChange={handleChange}
      />
    );
  }

  if (form.type === TYPE_INDIVIDUAL) {
    return (
      <IndividualDetails
        dob={form.dob}
        dobError={formErrors.dob}
        ssn={form.ssn}
        ssnError={formErrors.ssn}
        useSsn={form.useSsn}
        handleChange={handleChange}
        residentialAddress={form.residentialAddress}
        residentialAddressError={formErrors.residentialAddress}
      />
    );
  }

  if (form.type === TYPE_PENSION_SCHEME) {
    return (
      <PensionScheme
        schemeAdminDetails={form.schemeAdminDetails}
        schemeAdminDetailsError={formErrors.schemeAdminDetails}
        amlEntityDetails={form.amlEntityDetails}
        amlEntityDetailsError={formErrors.amlEntityDetails}
        handleChange={handleChange}
      />
    );
  }

  return null;
}
