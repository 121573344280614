import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Button, Icons } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';
import * as urls from 'services/urls';
import { QuestionPreview } from '../../../../../question_preview';

const useStyles = makeStyles(() => ({
  actions: {
    textAlign: 'right',
  },
}));

export const DefaultCard = ({ question, questionnaireId, fundId }) => {
  const classes = useStyles();

  return (
    <Grid xs={12}>
      <div className={classes.actions}>
        <Button
          variant="text"
          href={urls.onboardingV2Url({
            fundId,
            questionnaireId,
          })}
          external
          endIcon={<Icons.OpenInNew />}
        >
          Open questionnaire
        </Button>
      </div>
      <QuestionPreview item={question} />
    </Grid>
  );
};
