import * as React from 'react';

export const LOGO = (
  <img
    src={window.static?.dark_logo_png}
    width="135px"
    height="23px"
    alt="logo"
  />
);

export const P_LOGO = (
  <img src={window.static?.p_logo_png} width="25px" height="32px" alt="logo" />
);
