import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  highlight: {
    backgroundColor: theme.palette.primary.fill,
  },
}));

export const TypographyHighlight = ({ highlight, text, ...rest }) => {
  const classes = useStyles();
  const words = text.split(' ');
  return (
    <Typography {...rest}>
      {words.map((word, idx) => (
        <span
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          className={highlight.includes(word) ? classes.highlight : undefined}
        >
          {word}{' '}
        </span>
      ))}
    </Typography>
  );
};
