import * as React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  GlobalNavigation as NavBar,
  Button,
  InternalChip,
  Link,
  Modal,
} from '@passthrough/uikit';
import { Chip as MuiChip } from '@material-ui/core';
import BuildIcon from '@material-ui/icons/BuildOutlined';
import ListIcon from '@material-ui/icons/List';
import * as urls from 'services/urls';
import { P_LOGO, LOGO } from 'services/branding';
import { useTasks } from 'services/providers/tasks';
import { getSelectedStaffOrg } from 'services/utils';
import { useWhiteLabelConfig } from 'services/providers/theme';
import { DemoModeButton } from 'components/demo_mode_button';
import { Logo } from 'components/logo';
import { StaffTools } from 'pages/staff_tools';
import { AccountNavButton } from './AccountNavButton';
import { UniversalSearchBar } from './universal_search/UniversalSearchBar';

const useStyles = makeStyles((theme) => ({
  chipLeftSpacing: {
    marginLeft: theme.spacing(1),
    padding: '0px 2px',
  },
  internalTools: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(1),
  },
  internalToolsHeader: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
  },
  taskContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

function InternalToolsButton({ isStaff, useMobileUI }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  if (!isStaff) {
    return null;
  }

  return (
    <>
      <Button
        variant={useMobileUI ? 'icon' : 'text'}
        onClick={() => {
          setOpen(true);
        }}
      >
        {useMobileUI ? (
          <BuildIcon />
        ) : (
          <div className={classes.internalTools}>
            Tools
            <InternalChip />
          </div>
        )}
      </Button>
      <Modal
        open={open}
        headerLabel={
          <div className={classes.internalToolsHeader}>
            <span>Staff tools</span>
            <InternalChip />
          </div>
        }
        onClose={closeModal}
        footer={null}
      >
        <StaffTools closeModal={closeModal} />
      </Modal>
    </>
  );
}

function TasksButton({ tasks, useMobileUI }) {
  const classes = useStyles();

  if (!tasks) {
    return null;
  }

  if (useMobileUI) {
    return (
      <Button variant="text" href={urls.DASH_URL}>
        <div className={classes.taskContainer}>
          <ListIcon />
          <MuiChip label={tasks} size="small" color="secondary" />
        </div>
      </Button>
    );
  }

  return (
    <Button variant="text" href={urls.DASH_URL}>
      Tasks{' '}
      <MuiChip
        label={tasks}
        size="small"
        color="secondary"
        className={classes.chipLeftSpacing}
      />
    </Button>
  );
}

export function GlobalNavigation({ me, open }) {
  const [tasks, , refresh] = useTasks();
  const selectedStaffOrg = getSelectedStaffOrg(me);
  const classes = useStyles();

  const theme = useTheme();
  const useMobileUI = useMediaQuery(theme.breakpoints.down('md'));
  const whiteLabelConfig = useWhiteLabelConfig();

  function refreshTasks() {
    refresh();
    const id = setInterval(refresh, 15000); // every 15 seconds
    return () => clearInterval(id);
  }

  React.useEffect(refreshTasks, []);

  if (!open) {
    return null;
  }

  let exposedTasks = tasks;
  if (whiteLabelConfig?.organizationIds?.length) {
    exposedTasks = exposedTasks?.filter((taskData) =>
      whiteLabelConfig?.organizationIds.includes(taskData.orgId),
    );
  } else if (me.isStaff) {
    exposedTasks = exposedTasks?.filter(
      (taskData) => taskData.orgId === selectedStaffOrg,
    );
  }

  const organizationId = urls.useOrganizationId();
  let orgLogo = '';
  if (whiteLabelConfig?.logos?.app) {
    // If the white label config has a logo, use that instead of the org logo
    orgLogo = whiteLabelConfig.logos.app;
  } else if (organizationId && me.dashboardOrgs) {
    orgLogo = me.dashboardOrgs.find((org) => org.id === organizationId)?.logo;
  }

  const defaultPassthroughLogo = useMobileUI ? P_LOGO : LOGO;

  const leftSide = [
    <Link key="LeftSideLink" href={urls.DASH_URL}>
      <div className={classes.logo}>
        {orgLogo ? <Logo src={orgLogo} /> : defaultPassthroughLogo}
      </div>
    </Link>,
  ];

  if (!window.EXPLORE_MODE) {
    leftSide.push(<DemoModeButton key="LeftSideDemoModeButton" />);
  }

  const searchComponent = me.canUseSearch ? (
    <UniversalSearchBar
      key="RightSideUniversalSearchBar"
      useMobileUI={useMobileUI}
    />
  ) : null;

  const rightSide = [
    searchComponent,
    <InternalToolsButton
      key="RightSideInternalToolsButton"
      isStaff={me.isStaff}
      useMobileUI={useMobileUI}
    />,
    <TasksButton
      key="RightSideTasksButton"
      tasks={exposedTasks?.length}
      useMobileUI={useMobileUI}
    />,
    <AccountNavButton
      key="RightSideAccountNavButton"
      useMobileUI={useMobileUI}
    />,
  ];
  return <NavBar sides={[leftSide, rightSide]} />;
}
