/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';

const SubdocContext = React.createContext();

export function useSubdoc() {
  return useContext(SubdocContext);
}

export function SubdocProvider({ subdoc, ...props }) {
  return (
    <>
      <SubdocContext.Provider value={subdoc} {...props} />
    </>
  );
}
