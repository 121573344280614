import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import DoneIcon from '@material-ui/icons/Done';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  leftAligned: {
    textAlign: 'left',
    paddingTop: theme.spacing(1),
  },
  leftJustified: {
    flexGrow: 0,
    padding: theme.spacing(0),
    marginLeft: theme.spacing(1),
  },
  successColor: {
    backgroundColor: theme.palette.primary.main,
  },
  icon: {
    width: '15px',
    height: '15px',
  },
  dotSize: {
    width: '25px',
    height: '25px',
  },
  lastTask: {
    minHeight: 'auto',
  },
}));

export function TimelineTask({ children, done, isLast }) {
  const classes = useStyles();

  const dotComponent = done ? (
    <TimelineDot className={classes.successColor}>
      <DoneIcon className={classes.icon} />
    </TimelineDot>
  ) : (
    <TimelineDot className={classes.dotSize} variant="outlined" />
  );

  return (
    <TimelineItem className={clsx({ [classes.lastTask]: isLast })}>
      <TimelineOppositeContent
        color="text.secondary"
        className={classes.leftJustified}
      />
      <TimelineSeparator>
        {dotComponent}
        {!isLast ? (
          <TimelineConnector
            className={clsx({ [classes.successColor]: done })}
          />
        ) : null}
      </TimelineSeparator>

      <TimelineContent className={classes.leftAligned}>
        <Typography>{children}</Typography>
      </TimelineContent>
    </TimelineItem>
  );
}
