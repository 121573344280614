import React, { useState } from 'react';
import { forceInput } from 'services/utils';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { useMe } from 'services/providers/me';
import { Button } from 'components/button';

const useStyles = makeStyles((theme) => ({
  testMode: {
    userSelect: 'none',
    cursor: 'pointer',
    color: theme.palette.primary.white,
    padding: theme.spacing(0.5, 1),
    borderRadius: '6px',
    fontWeight: '400',
    backgroundImage: `linear-gradient(45deg, ${theme.palette.demoMode[0]}, ${theme.palette.demoMode[1]}, ${theme.palette.demoMode[0]})`,
    backgroundSize: '200%',
    transition: 'all 0.16s cubic-bezier(0.68, -0.25, 0.265, 1.25)',
    position: 'relative',
    overflow: 'hidden',
    zIndex: 1,
    border: 'none',
    '&::after': {
      zIndex: -1,
      position: 'absolute',
      top: '-5px',
      left: '-5px',
      right: '-5px',
      bottom: '-5px',
      filter: 'blur(10px)',
      content: '""',
      backgroundImage: `linear-gradient(45deg, ${theme.palette.demoMode[0]}, ${theme.palette.demoMode[1]}, ${theme.palette.demoMode[0]})`,
      backgroundSize: '200%',
      animation: '$demo 3s linear infinite',
    },
    '&:hover': {
      border: 'none',
      '&::after': {
        animationDuration: '0.5s',
      },
    },
    minWidth: 'max-content',
  },
  testTooltip: {
    padding: theme.spacing(1),
  },
  '@keyframes demo': {
    '0%': {
      backgroundPosition: '0%',
    },
    '100%': {
      backgroundPosition: '200%',
    },
  },
  desktopIcon: {
    marginLeft: theme.spacing(1),
  },
  demoModeText: {
    minWidth: 'max-content',
  },
}));

const ButtonWithRef = React.forwardRef((props, ref) => (
  <Button innerRef={ref} {...props} />
));

export function DemoModeButton() {
  const classes = useStyles();
  const [me] = useMe();
  const [forcingInput, setForcingInput] = useState(false);

  const userEmail = me.email;

  async function onTestModeClick() {
    if (forcingInput) return;

    setForcingInput(true);

    const submit = document.querySelector('#continue');
    if (submit) {
      submit.click();
    }

    const nextRequired = document.querySelector('#section-next-required');
    if (nextRequired) {
      nextRequired.click();
    }

    const yes = document.querySelector('#yes');
    if (yes) {
      yes.click();
    }

    const radio = document.querySelector('input[type="radio"]');
    if (radio) {
      radio.click();
    }

    const multiSelectCheckbox = document.querySelector(
      'input[type="checkbox"]',
    );
    if (multiSelectCheckbox) {
      multiSelectCheckbox.click();
    }

    forceInput('input[value="Submit a W-8 or other form"]', '');
    forceInput('#taxId', '111111111');
    forceInput('#ein', '151111111');
    forceInput('#ssn', '111111111');
    forceInput('#select-question', 'Natural Person');
    forceInput('#select-question', 'Submit a W-8 or other form');
    forceInput('[data-test="manual-address-link"]', '');
    forceInput('div[data-test="manual-address-state"] input', 'New York');
    forceInput('div[data-test="manual-address-postal"] input', '10006');
    forceInput('div[data-test="manual-address-city"] input', 'New York');
    forceInput('div[data-test="manual-address-street"] input', '115 Broadway');
    forceInput('input[data-test="state"]', 'New York', (stateEl) => {
      const state = document.querySelector(`#${stateEl.id}-option-0`);
      if (state) state.click();
    });
    forceInput('input[data-test="country"]', 'United States', (countryEl) => {
      const usa = document.querySelector(`#${countryEl.id}-option-0`);
      if (usa) usa.click();
    });
    forceInput('#firstName', 'John');
    forceInput('#lastName', 'Doe');
    forceInput('#legalName', 'John Doe');
    forceInput('#title', 'Software Engineer');
    forceInput('input[data-test="address-line-1"]', '115 Broadway');
    forceInput('div[data-test="city"] input', 'New York');
    forceInput('div[data-test="postal"] input', '10006');
    forceInput('#dollars', '500,000');
    forceInput('input[type="tel"]', '+1 (123) 456-7890');
    forceInput('#email', userEmail);
    forceInput('#text', 'Test');
    forceInput(
      '#text-long',
      // cspell: disable
      'Sed ut perspiciatis unde omnis iste natus error sit ' +
        'voluptatem accusantium doloremque laudantium, totam rem ' +
        'aperiam, eaque ipsa quae ab illo inventore veritatis et ' +
        'quasi architecto beatae vitae dicta sunt explicabo. Nemo ' +
        'enim ipsam voluptatem quia voluptas sit aspernatur aut odit ' +
        'aut fugit, sed quia consequuntur magni dolores eos qui ' +
        'ratione voluptatem sequi nesciunt.',
      // cspell: enable
    );
    forceInput('#bankName', 'Bank of America');
    forceInput('#accountName', 'Demo LP');
    forceInput('#accountNumber', '12345');
    forceInput('#aba', '123456789');
    forceInput('#date-picker-inline', '01/01/2000');
    forceInput('#dateWithoutYear', '12/31');
    forceInput('#dateYear', '2000');
    forceInput('#percent', '100');
    forceInput('input[type="number"][id="text"]', '1');
    forceInput('#giin', '123456.78901.AB.123');
    forceInput('#placeOfBirth', 'New York');

    // for w9 autofill
    forceInput('#name', 'John Doe');
    forceInput('#address1', '115 Broadway');
    forceInput('#address2', 'New York');

    // there are two sets of radio buttons on the w9 page, this code targets the latter one
    const w9SsnRadio = document.querySelector(
      'input[type="radio"][value="SSN"]',
    );
    if (w9SsnRadio) w9SsnRadio.click();

    await new Promise((r) => setTimeout(r, 100));

    setForcingInput(false);
  }

  if (!window.TEST_MODE) {
    return null;
  }

  return (
    <Tooltip
      title={
        <div className={classes.testTooltip}>
          <Typography>In this environment:</Typography>
          <ul>
            <li>
              <Typography>Signatures are not legally binding</Typography>
            </li>
            <li>
              <Typography>Data is reset periodically</Typography>
            </li>
            <li>
              <Typography>
                {window.TEST_MODE_TO_EMAIL
                  ? `All emails go to ${window.TEST_MODE_TO_EMAIL}`
                  : 'Emails are sent to real addresses'}
              </Typography>
            </li>
          </ul>
        </div>
      }
    >
      <ButtonWithRef className={classes.testMode} onClick={onTestModeClick}>
        <Typography variant="h6">Demo mode</Typography>
      </ButtonWithRef>
    </Tooltip>
  );
}
