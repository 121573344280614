import React from 'react';

import { getCountry } from 'services/countries';
import { GenericAnswerDisplay } from './generic_display';

export function CountryDisplay({ answer, flatten, questionState }) {
  return (
    <GenericAnswerDisplay
      answer={answer}
      flatten={flatten}
      questionState={questionState}
      transformAnswer={(a) => getCountry(a)}
    />
  );
}
