import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import { Menu, MenuItem, Icons, Typography } from '@passthrough/uikit';

const useStyles = makeStyles(() => ({
  root: {
    // Otherwise the label on the page number field floats above the menu
    zIndex: 2,
  },
}));

export function TimerMenu({
  timerIsRunning,
  anchorEl,
  open,
  onClose,
  onSave,
  onReset,
  onEditTime,
  onStart,
  onStop,
  keepOpen,
  isLoading,
}) {
  const classes = useStyles();

  const timerAction = timerIsRunning ? onStop : onStart;
  const timerActionText = timerIsRunning ? 'Stop timer' : 'Start timer';
  const TimerIcon = timerIsRunning ? Icons.Stop : Icons.PlayArrow;

  const tooltipText = timerIsRunning ? (
    <Typography variant="body">Stop timer to edit</Typography>
  ) : (
    ''
  );

  return (
    <Menu
      id="timer-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      disableCloseOnClick
      keepOpen={keepOpen}
      className={classes.root}
    >
      <MenuItem
        onClick={timerAction}
        icon={<TimerIcon fontSize="small" />}
        text={timerActionText}
      />
      <MenuItem
        onClick={onReset}
        icon={<Icons.ReplayOutlined fontSize="small" />}
        text="Reset to saved time"
      />
      <Tooltip title={tooltipText} placement="left">
        <span>
          <MenuItem
            onClick={onEditTime}
            icon={<Icons.Edit fontSize="small" />}
            divider
            disabled={timerIsRunning}
            text="Edit time"
          />
        </span>
      </Tooltip>
      <MenuItem
        onClick={onSave}
        icon={<Icons.SaveOutlined fontSize="small" color="primary" />}
        disabled={isLoading}
        text="Save"
      />
    </Menu>
  );
}
