import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Icons, Button } from '@passthrough/uikit';
import { ExperienceSelector } from 'components/explore/experience_selector';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: '600px',
  },
  submit: {
    textAlign: 'right',
  },
}));

export function Step1({ config, loading, onSubmit }) {
  const [experience, setExperience] = React.useState('');
  const classes = useStyles();
  const product = config.productName;
  return (
    <div className={classes.root}>
      <div>
        <Typography variant="section-heading">
          What experience are you interested in?
        </Typography>
        <Typography variant="subtitle">
          Select a user experience to start exploring {product}
        </Typography>
      </div>
      <br />
      <div>
        <ExperienceSelector
          loading={loading}
          experience={experience}
          setExperience={setExperience}
        />
        <div className={classes.submit}>
          <Button
            variant="primary"
            fullWidth
            endIcon={<Icons.ArrowForward />}
            size="large"
            onClick={() => onSubmit(experience)}
            disabled={!experience}
            loading={loading}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
}
