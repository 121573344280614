/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { formatDistanceStrict } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  root: {
    whiteSpace: 'nowrap',
  },
}));

export function LastModified({ when }) {
  const classes = useStyles();
  const [now, setNow] = useState(new Date());

  React.useEffect(() => {
    const interval = setInterval(
      () => {
        setNow(new Date());
      },
      1000, // every 1 second
    );
    return () => {
      clearInterval(interval);
    };
  }, []);

  const then = new Date(when);

  const timeAgo = formatDistanceStrict(then, now, { addSuffix: true });

  return (
    <Typography align="right" className={classes.root}>
      Last modified: {timeAgo}
    </Typography>
  );
}
