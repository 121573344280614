/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useContext } from 'react';
import * as api from 'services/api';
import { redirectIfDifferentDomain } from 'services/utils';

const OrganizationContext = React.createContext();

export function useOrganization() {
  return useContext(OrganizationContext);
}

export function OrganizationProvider({ organizationId, ...props }) {
  const [organization, setOrganization] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [organizationRefreshCount, refreshOrganization] = useState(0);

  function getOrganization() {
    api.organization({ id: organizationId }).then((response) => {
      const { baseUrl } = response.data;

      if (redirectIfDifferentDomain(baseUrl)) {
        return;
      }

      setOrganization(response.data);
      setIsLoading(false);
    });
  }

  useEffect(getOrganization, [organizationId, organizationRefreshCount]);

  return (
    <OrganizationContext.Provider
      value={[organization, isLoading, refreshOrganization]}
      {...props}
    />
  );
}
