import React, { useEffect, useState } from 'react';
import { objectEquals } from 'services/utils';
import cloneDeep from 'lodash/cloneDeep';
import {
  getDefaultEmptyAddress,
  Address,
  objectEqualsEmptyAddress,
} from 'components/address_v2';
import { Answers } from '../answers';
import { UNSAVED } from '../saved';

function parseInitialAddress(answer, defaultAddress) {
  const initialAddress = {
    ...defaultAddress,
    ...(cloneDeep(answer) || {}),
  };

  return initialAddress;
}

export function AddressQuestion({
  answer,
  setSaving,
  updateLpDoc,
  label,
  QuestionStepper,
  QuestionPromptComponent,
}) {
  const { emptyAddress } = getDefaultEmptyAddress();
  const initialAddress = parseInitialAddress(answer, emptyAddress);

  const [address, setAddress] = useState(initialAddress);
  const [errors, setErrors] = useState({});

  const addressIsEmpty = objectEqualsEmptyAddress(address);

  function handleSubmit(e) {
    e.preventDefault();

    setErrors({});

    return updateLpDoc({
      label,
      answer: address,
      skipQuestion: addressIsEmpty,
    }).catch((error) => {
      setSaving(UNSAVED);
      if (error.response?.status === 400) {
        setErrors(error.response.data.answer);
      }
      return error;
    });
  }

  function handleChange(value) {
    setSaving(UNSAVED);
    setAddress(value);
  }

  // Reset the input when we change questions.
  useEffect(() => {
    const cleanup = () => {
      setAddress(initialAddress);
      setErrors({});
    };
    return cleanup;
  }, [label]);

  return (
    <form onSubmit={handleSubmit}>
      <QuestionPromptComponent />
      <Answers>
        <Address address={address} onChange={handleChange} errors={errors} />
        <QuestionStepper
          handleSubmit={handleSubmit}
          hasAnswerChanged={!objectEquals(address, initialAddress)}
          hasInput={!addressIsEmpty}
        />
      </Answers>
    </form>
  );
}
