import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'components/dialog/index';
import { Alert } from 'components/alert';
import { Button } from 'components/button';
import TextField from '@material-ui/core/TextField';
import * as api from 'services/api';
import { getSingleErrorFromResponse } from 'services/utils';

const useStyles = makeStyles((theme) => ({
  input: {
    margin: theme.spacing(0.5, 0),
  },
  error: {
    marginBottom: theme.spacing(2),
  },
}));

export function AddNewCollaboratorModal({
  open,
  handleClose,
  fetchProfileUsers,
  profileId,
}) {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  function onSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    api
      .profileUserCreate({
        profileId,
        name,
        email,
      })
      .then(() => {
        setErrorMsg('');
        fetchProfileUsers();
        handleClose();
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setErrorMsg(getSingleErrorFromResponse(error.response));
        }
        setIsLoading(false);
      });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      aria-labelledby="form-dialog-title"
    >
      <form onSubmit={onSubmit} autoComplete="off">
        <DialogTitle id="form-dialog-title">New collaborator</DialogTitle>
        <DialogContent>
          {errorMsg ? (
            <Alert severity="error" className={classes.error}>
              {errorMsg}
            </Alert>
          ) : null}
          <TextField
            autoFocus
            required
            className={classes.input}
            id="name"
            variant="outlined"
            label="Name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />
          <TextField
            required
            className={classes.input}
            id="email"
            variant="outlined"
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />

          <FormHelperText>
            This collaborator can add and remove people from the profile, view
            and edit profile data, as well as manage all this profile's
            investments.
          </FormHelperText>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            onClick={onSubmit}
            disabled={!name || !email}
            variant="contained"
            color="primary"
            fullWidth
            loading={isLoading}
          >
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
