import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core';

const MenuProps = {
  variant: 'menu',
};

const useStyles = makeStyles(() => ({
  formControl: {
    width: 250,
  },
}));

export const AnswerMeaningsInput = ({
  answerMeanings,
  setAnswerMeanings,
  possibleAnswerMeanings,
}) => {
  const classes = useStyles();
  return (
    <div>
      <FormControl className={classes.formControl} variant="outlined" fullWidth>
        <InputLabel id="answer-meanings">Answer meanings</InputLabel>
        <Select
          label="Answer meanings"
          labelId="answer-meanings"
          multiple
          value={answerMeanings}
          onChange={(e) => setAnswerMeanings(e.target.value)}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {possibleAnswerMeanings.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox checked={answerMeanings.indexOf(option.value) > -1} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
