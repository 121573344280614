import * as React from 'react';

const DiligenceErrorContext = React.createContext({});
const DiligenceErrorDispatchContext = React.createContext(() => {});

export const useErrorContext = () => React.useContext(DiligenceErrorContext);
export const useErrorDispatchContext = () =>
  React.useContext(DiligenceErrorDispatchContext);

function errorReducer(state, action) {
  switch (action.type) {
    case 'set':
      return {
        ...state,
        [action.label]: {
          ...state[action.label],
          ...action.errors,
        },
      };
    case 'clear': {
      const newFormErrors = { ...state };
      delete newFormErrors[action.label];
      return newFormErrors;
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

export function DiligenceErrorProvider({ children }) {
  const [errors, dispatch] = React.useReducer(errorReducer, {});

  return (
    <DiligenceErrorContext.Provider value={errors}>
      <DiligenceErrorDispatchContext.Provider value={dispatch}>
        {children}
      </DiligenceErrorDispatchContext.Provider>
    </DiligenceErrorContext.Provider>
  );
}
