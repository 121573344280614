import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import clsx from 'clsx';

import * as api from 'services/api';
import { useToast } from 'services/toast';
import { reducerActionTypes } from 'pages/review_v2/diligence_v2/review_modal/constants';
import {
  DiligenceChip,
  DiligenceChipVariants,
} from 'pages/review_v2/diligence_v2/components/diligence_chip';
import { ThreadCountChip } from 'components/thread_count_chip';
import { InternalNoteChip } from 'components/internal_note_chip';
import {
  UnsentChip,
  ResolvedThreadChip,
  UpdatedChip,
} from 'components/text_chip';
import { useFeatureFlags } from 'services/providers/feature_flag';
import { ApprovalButtons } from '../approval_buttons';
import { NavKeys } from './nav_keys';
import { ApproveConfirmationModal } from './approve_confirmation_modal';
import { ScreeningCategories } from '../../screening_categories';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
    textAlign: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(3),
  },
  approvalActionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(1),
    textAlign: 'center',
  },
  approvalActionsContainerLeftAligned: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(1),
    textAlign: 'left',
    alignItems: 'flex-start',
  },
  largeName: {
    fontSize: '1.5rem',
  },
  largeNameLeftAligned: {
    fontSize: '1.5rem',
    textAlign: 'left',
  },
  greyText: {
    color: theme.palette.primary.grey,
  },
  errorText: {
    textAlign: 'center',
  },
  noteChipContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  noteChipContainerLeftAligned: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  approvalButtonAndSettingsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
    width: '100%',
  },
  approvalButton: {
    width: '100%',
  },
}));

export function DisplayHeader({
  questionId,
  name,
  hasUnsentNote,
  isApproved,
  currOwnerIndex,
  commentThreadsResolved,
  hasUnresolvedInternalNote,
  numOwners,
  numCommentEvents,
  atLeastOneTrueMatch,
  atLeastOneUnsureMatch,
  dispatch,
  shouldDisableApproval,
  shouldHideApproval,
  onLeftNavClick,
  onRightNavClick,
  setLocalNumApproved,
  updated,
  nodeDataSettled,
  loadingUnresolvedThreads,
  docCollectionMode,
  adverseMediaEnabled,
  isIndividual,
  handleEnableAdverseMedia,
  cannotEnableAdverseMedia,
}) {
  const classes = useStyles();
  const { toast } = useToast();
  const { fundId, lpClosingId } = useParams();
  const [approvalModalNote, setApproveModalNote] = useState('');
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { ADVERSE_MEDIA_ALERTS } = useFeatureFlags();

  const showThreadCountChip =
    !loadingUnresolvedThreads &&
    !hasUnsentNote &&
    !isApproved &&
    numCommentEvents > 0 &&
    !commentThreadsResolved;

  const showResolvedThreadChip =
    !loadingUnresolvedThreads &&
    !hasUnsentNote &&
    !isApproved &&
    numCommentEvents > 0 &&
    commentThreadsResolved;

  const showAChip =
    updated ||
    hasUnsentNote ||
    showThreadCountChip ||
    showResolvedThreadChip ||
    hasUnresolvedInternalNote ||
    isApproved;

  useEffect(() => {
    if (!atLeastOneTrueMatch && !atLeastOneUnsureMatch) {
      setApproveModalNote('');
    }
  }, [atLeastOneTrueMatch, atLeastOneUnsureMatch]);

  const approveDiligenceNode = () => {
    setIsLoading(true);
    setErrorMsg('');

    api
      .approveDiligenceNode({
        fundId,
        lpClosingId,
        questionId,
        note: approvalModalNote,
      })
      .then(() => {
        dispatch({
          type: reducerActionTypes.APPROVE_NODE,
        });

        toast('Owner marked as reviewed');
        setIsLoading(false);
        setShowApproveModal(false);
        setApproveModalNote('');
        setLocalNumApproved((num) => num + 1);
      })
      .catch((e) => {
        setErrorMsg(
          e?.response?.data?.nonFieldErrors ||
            'There was an error reviewing this owner.',
        );
        setIsLoading(false);
      });
  };

  const approveDiligenceNodeWithModal = () => {
    if (atLeastOneTrueMatch || atLeastOneUnsureMatch) {
      setShowApproveModal(true);
    } else {
      approveDiligenceNode();
    }
  };

  const unapproveDiligenceNode = () => {
    setIsLoading(true);
    setErrorMsg('');

    api
      .unapproveDiligenceNode({ fundId, lpClosingId, questionId })
      .then(() => {
        dispatch({
          type: reducerActionTypes.UNAPPROVE_NODE,
        });

        toast('Undid review of owner');
        setIsLoading(false);
        setLocalNumApproved((num) => num - 1);
      })
      .catch(() => {
        // no error message defined on the backend
        setErrorMsg('There was an error undoing the review of this owner.');
        setIsLoading(false);
      });
  };

  const getApprovalButton = () => (
    <ApprovalButtons
      className={classes.approvalButton}
      isApproved={isApproved}
      isLoading={isLoading}
      shouldDisableApproval={
        loadingUnresolvedThreads || !nodeDataSettled || shouldDisableApproval
      }
      approveDiligenceNode={approveDiligenceNodeWithModal}
      unapproveDiligenceNode={unapproveDiligenceNode}
    />
  );

  const getScreeningCategories = (shouldShowDisabledIcon = false) => (
    <ScreeningCategories
      adverseMediaEnabled={adverseMediaEnabled}
      isIndividual={isIndividual}
      handleEnableAdverseMedia={() => handleEnableAdverseMedia(questionId)}
      shouldShowDisabledIcon={shouldShowDisabledIcon}
    />
  );

  return (
    <div className={classes.root}>
      <ApproveConfirmationModal
        name={name}
        showModal={showApproveModal}
        setShowModal={setShowApproveModal}
        internalNote={approvalModalNote}
        setInternalNote={setApproveModalNote}
        approveDiligenceNode={approveDiligenceNode}
      />

      <NavKeys
        onLeftNavClick={onLeftNavClick}
        onRightNavClick={onRightNavClick}
        currOwnerIndex={currOwnerIndex}
        numOwners={numOwners}
      />

      {!loadingUnresolvedThreads && nodeDataSettled && showAChip ? (
        <div
          className={clsx({
            [classes.noteChipContainer]: !ADVERSE_MEDIA_ALERTS,
            [classes.noteChipContainerLeftAligned]: ADVERSE_MEDIA_ALERTS,
          })}
        >
          {updated ? <UpdatedChip /> : null}
          {hasUnsentNote ? <UnsentChip /> : null}
          {hasUnresolvedInternalNote ? <InternalNoteChip /> : null}
          {showThreadCountChip ? (
            <ThreadCountChip threadCount={numCommentEvents} />
          ) : null}
          {showResolvedThreadChip ? <ResolvedThreadChip /> : null}
          {isApproved ? (
            <DiligenceChip
              label="Reviewed"
              variant={DiligenceChipVariants.APPROVED}
            />
          ) : null}
        </div>
      ) : null}
      <Typography
        variant="h6"
        className={clsx({
          [classes.largeName]: !ADVERSE_MEDIA_ALERTS,
          [classes.largeNameLeftAligned]: ADVERSE_MEDIA_ALERTS,
        })}
      >
        {name}
      </Typography>
      {shouldHideApproval ? null : (
        <div
          className={clsx({
            [classes.approvalActionsContainer]: !ADVERSE_MEDIA_ALERTS,
            [classes.approvalActionsContainerLeftAligned]: ADVERSE_MEDIA_ALERTS,
          })}
        >
          {ADVERSE_MEDIA_ALERTS ? (
            <div className={classes.approvalButtonAndSettingsContainer}>
              {getApprovalButton()}
              {getScreeningCategories()}
            </div>
          ) : (
            getApprovalButton()
          )}

          {errorMsg ? (
            <FormHelperText error className={classes.errorText}>
              {errorMsg}
            </FormHelperText>
          ) : null}

          {nodeDataSettled &&
          !loadingUnresolvedThreads &&
          !isApproved &&
          shouldDisableApproval ? (
            <Typography variant="body2" className={classes.greyText}>
              {docCollectionMode ? (
                <>You must resolve all comment threads to review this owner.</>
              ) : (
                <>
                  You must evaluate all potential matches and resolve all
                  comment threads to review this owner.
                </>
              )}
            </Typography>
          ) : null}
        </div>
      )}
      {shouldHideApproval && ADVERSE_MEDIA_ALERTS ? (
        <div className={classes.approvalActionsContainerLeftAligned}>
          {getScreeningCategories(cannotEnableAdverseMedia)}
        </div>
      ) : null}
    </div>
  );
}
