/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';

import { TYPE_INDIVIDUAL } from './constants';
import { RootIndividualQuestion } from './root_individual_question';
import { SubsectionQuestionDisplay } from './subsection_question_display/index';
import { NameAndTypeInputs } from './components/name_and_type_inputs';
import { hasDataForEachKey } from './tree_nav_v2/tree_utils';
import { Notice } from './notice';

const useStyles = makeStyles((theme) => ({
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
}));

export function DiligenceUI({
  fundName,
  form,
  formErrors,
  jurisdiction,
  hideOptOut,
  hideIndividualRootNodeOptOutOverride,
  label,
  fullAnswerData,
  isNested,
  handleChange,
  QuestionPromptComponent,
  diligenceQuestions,
  detailsExpanded,
  documentsExpanded,
  ownersExpanded,
  goToDetails,
  goToDocuments,
  goToOwners,
  clickDetails,
  clickDocuments,
  clickOwners,
  changeType,
  lpClosingId,
  toLegalName,
  preventLegalNameChange,
}) {
  const classes = useStyles();
  const showDefaultRootLandingUI = !isNested && !form.type;
  const showRootIndividualUI = !isNested && form.type === TYPE_INDIVIDUAL;
  const showSubsectionsUI =
    (!isNested && form.type && form.type !== TYPE_INDIVIDUAL) || isNested;
  const hasAllData = hasDataForEachKey(form, jurisdiction, !isNested);

  useEffect(() => {
    if (hasAllData && form.optOut) {
      handleChange('optOut', false);
    }
  }, [hasAllData, form.optOut]);

  function PromptWithNotice({ ...props }) {
    return (
      <QuestionPromptComponent {...props}>
        <Notice />
      </QuestionPromptComponent>
    );
  }

  return (
    <>
      <Collapse in={showDefaultRootLandingUI} mountOnEnter unmountOnExit>
        <PromptWithNotice short />

        <div className={classes.inputsContainer}>
          <NameAndTypeInputs
            jurisdiction={jurisdiction}
            name={form.name}
            nameError={formErrors.name}
            type={form.type}
            typeError={formErrors.type}
            handleChange={handleChange}
            changeType={changeType}
            preventLegalNameChange={preventLegalNameChange}
            toLegalName={toLegalName}
          />
        </div>
      </Collapse>

      <Collapse in={showRootIndividualUI} mountOnEnter unmountOnExit>
        <RootIndividualQuestion
          lpClosingId={lpClosingId}
          fundName={fundName}
          form={form}
          formErrors={formErrors}
          hideOptOut={hideIndividualRootNodeOptOutOverride || hideOptOut}
          jurisdiction={jurisdiction}
          handleChange={handleChange}
          changeType={changeType}
          QuestionPromptComponent={PromptWithNotice}
          preventLegalNameChange={preventLegalNameChange}
          toLegalName={toLegalName}
        />
      </Collapse>

      <Collapse in={showSubsectionsUI} mountOnEnter unmountOnExit>
        <SubsectionQuestionDisplay
          lpClosingId={lpClosingId}
          fundName={fundName}
          form={form}
          formErrors={formErrors}
          jurisdiction={jurisdiction}
          label={label}
          hideOptOut={hideOptOut}
          fullAnswerData={fullAnswerData}
          handleChange={handleChange}
          isNested={isNested}
          diligenceQuestions={diligenceQuestions}
          detailsExpanded={detailsExpanded}
          documentsExpanded={documentsExpanded}
          ownersExpanded={ownersExpanded}
          goToDetails={goToDetails}
          goToDocuments={goToDocuments}
          goToOwners={goToOwners}
          clickDetails={clickDetails}
          clickDocuments={clickDocuments}
          clickOwners={clickOwners}
          changeType={changeType}
          QuestionPromptComponent={PromptWithNotice}
          preventLegalNameChange={preventLegalNameChange}
          toLegalName={toLegalName}
        />
      </Collapse>
    </>
  );
}
