import React from 'react';
import { Button, Icons } from '@passthrough/uikit';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { ShowCaseBox, ComponentShowCase, ShowCaseBoxRow } from '../show_case';

export const ButtonShowCase = () => (
  <ComponentShowCase title="Button">
    <ShowCaseBox>
      <ShowCaseBoxRow>
        <Button variant="text">Text</Button>
        <Button variant="primary">Primary</Button>
        <Button variant="secondary">Secondary</Button>
        <Button variant="primary" destructive>
          Destructive
        </Button>
        <Button variant="primary" disabled>
          Disabled
        </Button>
        <Button variant="primary" loading>
          Loading
        </Button>
      </ShowCaseBoxRow>
      <ShowCaseBoxRow mt={4}>
        <Button variant="primary" size="small">
          Small
        </Button>
        <Button variant="primary" size="medium">
          Medium
        </Button>
        <Button variant="primary" size="large">
          Large
        </Button>
      </ShowCaseBoxRow>
      <ShowCaseBoxRow mt={4}>
        <Button variant="primary" startIcon={<Icons.Delete />}>
          Start Icon
        </Button>
        <Button variant="primary" endIcon={<Icons.Delete />}>
          End Icon
        </Button>
      </ShowCaseBoxRow>
      <ShowCaseBoxRow mt={4}>
        <Button variant="icon" size="small" aria-label="Delete this item">
          <Icons.Delete />
        </Button>
        <Button
          variant="icon"
          size="small"
          aria-label="Visible only to you and anyone who can manage sharing of this calendar"
        >
          <Icons.LockOutlined />
        </Button>
        <Button variant="icon" size="medium" aria-label="Delete this item">
          <Icons.Delete />
        </Button>
        <Button
          variant="icon"
          disabled
          size="medium"
          aria-label="Visible only to you and anyone who can manage sharing of this calendar"
        >
          <Icons.LockOutlined />
        </Button>
        <Button variant="icon" size="large" aria-label="Delete this item">
          <Icons.Delete />
        </Button>
        <Button
          variant="icon"
          size="large"
          aria-label="Visible only to you and anyone who can manage sharing of this calendar"
        >
          <Icons.LockOutlined />
        </Button>
      </ShowCaseBoxRow>
      <ShowCaseBoxRow mt={4}>
        <Button variant="primary" fullWidth>
          Full Width
        </Button>
      </ShowCaseBoxRow>
      <ShowCaseBoxRow mt={4}>
        <Box width={200} display="flex">
          <Button
            variant="secondary"
            ellipsis
            tooltip="Demonstrating a button with a long label that will be truncated"
          >
            Ellipsis: Demonstrating a button with a long label that will be
            truncated
          </Button>
        </Box>
      </ShowCaseBoxRow>
      <ShowCaseBoxRow mt={4}>
        <TextField
          label="Icon button as end adornment"
          variant="outlined"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button variant="icon" aria-label="Clear" size="small">
                  <Icons.Clear />
                </Button>
              </InputAdornment>
            ),
          }}
        />
      </ShowCaseBoxRow>
    </ShowCaseBox>
  </ComponentShowCase>
);
