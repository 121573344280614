import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Skeleton from '@material-ui/lab/Skeleton';
import { Button } from 'components/button';

const useStyles = makeStyles(() => ({
  clientMatterNumber: {
    wordWrap: 'break-word',
  },
  editClientMatterButton: {
    marginLeft: '4px',
    marginBottom: '4px',
  },
  editIcon: {
    width: '80%',
  },
}));

export function ClientMatterCell({ fund, skeleton, openClientMatterDialog }) {
  const classes = useStyles();

  const openEditClientMatterDialog = () => {
    openClientMatterDialog(fund);
  };

  if (skeleton) {
    return <Skeleton width="100%" height={20} />;
  }

  if (fund.clientMatterNumber) {
    return (
      <>
        <span className={classes.clientMatterNumber}>
          {fund.clientMatterNumber}
        </span>
        <IconButton
          className={classes.editClientMatterButton}
          onClick={openEditClientMatterDialog}
          size="small"
        >
          <EditIcon color="primary" className={classes.editIcon} />
        </IconButton>
      </>
    );
  }

  return (
    <>
      <Button
        variant="text"
        color="default"
        data-test={`add-client-number-${fund.id}`}
        onClick={openEditClientMatterDialog}
      >
        Add number
      </Button>
    </>
  );
}
