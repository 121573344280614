/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { DisplayLink } from 'components/link';

const useListStyles = makeStyles((theme) => ({
  helperTextContainer: {
    padding: theme.spacing(0, 2, 2, 2),
  },
  helperText: {
    color: theme.palette.drawer.contrastText,
  },
}));

export function ListBulkAction({
  numSelected,
  numActionable,
  onClick,
  onUnselect,
  icon,
  actionPhrase,
  children,
  unselectLinkProps = {},
  ...props
}) {
  const classes = useListStyles();
  const numUnactionable = numSelected - numActionable;
  const disabled = numSelected === 0 || numUnactionable > 0;

  const formHelperText = `${numActionable} selected investor${
    numActionable > 1 ? 's' : ''
  } ${actionPhrase}. `;
  return (
    <div>
      <ListItem
        button
        key={disabled}
        disabled={disabled}
        onClick={onClick}
        {...props}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText>{children}</ListItemText>
      </ListItem>
      <div>
        <Collapse
          in={!!(numActionable && numUnactionable > 0)}
          appear={false}
          unmountOnExit
        >
          <div className={classes.helperTextContainer}>
            <FormHelperText className={classes.helperText}>
              {formHelperText}
            </FormHelperText>
            <FormHelperText>
              <DisplayLink onClick={onUnselect} {...unselectLinkProps}>
                Unselect others
              </DisplayLink>
            </FormHelperText>
          </div>
        </Collapse>
      </div>
    </div>
  );
}
