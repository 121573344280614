import React from 'react';
import TextField from '@material-ui/core/TextField';
import { InputDiv } from './input_div';
import { frontendLabelRequired } from '../constants';

export const FrontendLabelInput = ({
  frontendLabel,
  setFrontendLabel,
  answerType,
}) => {
  if (!frontendLabelRequired.includes(answerType)) return null;

  return (
    <InputDiv>
      <TextField
        value={frontendLabel}
        onChange={(e) => {
          setFrontendLabel(e.target.value);
        }}
        variant="outlined"
        fullWidth
        required
        label="Frontend label"
      />
    </InputDiv>
  );
};
