import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Alert, Icons } from '@passthrough/uikit';

import { TimerMenu } from './menu';
import { EditTimeModal } from './edit_time';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  label: {
    whiteSpace: 'nowrap',
  },
  timeContainer: {
    minWidth: '3rem',
    textAlign: 'center',
  },
}));

export function ReviewTimer({
  savedTimeInSeconds,
  saveTotalSeconds,
  errorMsg,
  isLoading,
  totalTimeInSeconds,
  setTotalTimeInSeconds,
}) {
  const [timerId, setTimerId] = useState(null);
  const [startTime, setStartTime] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const [modalOpen, setModalOpen] = useState(false);

  const seconds = totalTimeInSeconds % 60;
  const minutes = Math.floor(totalTimeInSeconds / 60);

  const timeString = `${minutes}:${seconds.toString().padStart(2, '0')}`;

  const classes = useStyles();

  const handleMenuOpen = (event) => {
    setAnchorEl((el) => (el ? null : event.currentTarget));
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleStart = () => {
    setStartTime(Date.now());
  };

  const handleStop = () => {
    clearInterval(timerId);
    setTimerId(null);
    setStartTime(null);
  };

  const handleReset = () => {
    handleStop();
    setTotalTimeInSeconds(savedTimeInSeconds);
  };

  const handleTimeUpdate = (newTimeInSeconds) => {
    setTotalTimeInSeconds(newTimeInSeconds);
    saveTotalSeconds(newTimeInSeconds).then(() => {
      setModalOpen(false);
      handleStart();
    });
  };

  useEffect(() => {
    setTotalTimeInSeconds(savedTimeInSeconds);
    // Start timer automatically on page load
    handleStart();
  }, []);

  useEffect(() => {
    if (startTime !== null && timerId === null) {
      setTimerId(
        setInterval(() => {
          const elapsedTimeInSeconds =
            Math.floor((startTime - Date.now()) / 1000) + 1;

          setTotalTimeInSeconds(totalTimeInSeconds - elapsedTimeInSeconds);
        }, 100),
      );
    }

    return () => {
      clearInterval(timerId);
    };
  }, [startTime, timerId]);

  return (
    <>
      {errorMsg !== null ? <Alert severity="error">{errorMsg}</Alert> : null}

      <TimerMenu
        open={menuOpen}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        onSave={() => saveTotalSeconds(totalTimeInSeconds)}
        onReset={handleReset}
        onStart={handleStart}
        onStop={handleStop}
        onEditTime={() => setModalOpen(true)}
        isLoading={isLoading}
        keepOpen={modalOpen}
        timerIsRunning={timerId !== null}
      />
      <EditTimeModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSave={handleTimeUpdate}
        isLoading={isLoading}
        totalTimeInSeconds={totalTimeInSeconds}
      />

      <div className={classes.container}>
        <Typography variant="card-heading" className={classes.label}>
          Review timer
        </Typography>
        <div className={classes.timeContainer}>
          <Typography variant="body" size="medium">
            {timeString}
          </Typography>
        </div>
        <Button
          variant="icon"
          size="medium"
          onClick={handleMenuOpen}
          aria-label="More options"
        >
          <Icons.MoreVertOutlined />
        </Button>
      </div>
    </>
  );
}
