import React, { useState, useEffect } from 'react';
import { useQuestionnaire } from 'services/providers/questionnaire';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { getEmptyFile } from 'components/file_upload';
import { DndSingleFileUpload } from 'components/dnd_file_upload/single_file/single_file_upload';
import { objectEquals } from 'services/utils';
import { Answers } from '../answers';
import { UNSAVED } from '../saved';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'inline-block',
  },
  fileUploadMinWidth: {
    minWidth: '600px',
    [theme.breakpoints.down('md')]: {
      minWidth: '320px',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '250px',
    },
  },
}));

export function FileUploadQuestion({
  updateLpDoc,
  setSaving,
  answer,
  label,
  isRequired,
  QuestionStepper,
  QuestionPromptComponent,
}) {
  const initialFile = answer || getEmptyFile();
  const [file, setFile] = useState(initialFile);
  const [errorMsg, setErrorMsg] = useState('');
  const classes = useStyles();
  const hasInput = Boolean(file.fileId);
  const { lpDocumentId } = useParams();
  const { buildSingleFileUploadUrl } = useQuestionnaire();
  const url = buildSingleFileUploadUrl(lpDocumentId);

  function handleSubmit(e) {
    e.preventDefault();

    setErrorMsg('');

    return updateLpDoc({
      label,
      answer: file.fileId,
      skipQuestion: !hasInput,
    }).catch((error) => {
      setSaving(UNSAVED);
      if (error.response?.status === 400) {
        setErrorMsg(error.response.data.answer[0]);
      }
      return error;
    });
  }

  function handleChange(newFile) {
    setSaving(UNSAVED);
    setFile(newFile);
  }

  // Reset the input when we change questions.
  useEffect(() => {
    setFile(initialFile);
    setErrorMsg('');
  }, [label]);

  return (
    <form onSubmit={handleSubmit}>
      <QuestionPromptComponent />
      <Answers>
        <FormControl className={classes.form} error={!!errorMsg}>
          <div className={classes.fileUploadMinWidth}>
            <DndSingleFileUpload
              file={file}
              onChange={handleChange}
              url={url}
            />
          </div>
          <FormHelperText className={classes.error}>{errorMsg}</FormHelperText>
        </FormControl>
        <QuestionStepper
          handleSubmit={handleSubmit}
          disabled={!file.fileId && isRequired}
          hasAnswerChanged={!objectEquals(file, initialFile)}
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
}
