import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import OpenInNewIcon from 'mdi-material-ui/OpenInNew';

import { TrueLink } from 'components/link';
import { Table, TableCell, TableHead, TableRow } from 'components/table/index';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
    alignItems: 'center',
  },
  linkIcon: {
    margin: theme.spacing(0, 1),
  },
  tableRow: {
    backgroundColor: theme.palette.primary.white,
  },
}));

const useTableCellStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 2),
  },
  head: {
    backgroundColor: theme.palette.table.header,
  },
}));

export function QuestionTable({ questions }) {
  const classes = useStyles();
  const tableCellClasses = useTableCellStyles();

  return (
    <Table noTopMargin>
      <TableHead>
        <TableRow key="header" className={classes.tableRow}>
          <TableCell key="id" classes={tableCellClasses}>
            Question ID
          </TableCell>
          <TableCell key="fund" classes={tableCellClasses}>
            Fund
          </TableCell>
          <TableCell key="questionnaire" classes={tableCellClasses}>
            Questionnaire name
          </TableCell>
        </TableRow>
      </TableHead>
      {questions.map((q) => (
        <TableRow
          key={`${q.id}-${q.questionnaire_name}`}
          className={classes.tableRow}
        >
          <TableCell key="id" classes={tableCellClasses}>
            {q.id}
          </TableCell>
          <TableCell key="fund_name" classes={tableCellClasses}>
            {q.fund_name}
          </TableCell>
          <TableCell key="questionnaire" classes={tableCellClasses}>
            {q.questionnaire_url ? (
              <TrueLink href={q.questionnaire_url}>
                <div key="link" className={classes.link}>
                  {q.questionnaire_name}
                  <OpenInNewIcon className={classes.linkIcon} />
                </div>
              </TrueLink>
            ) : (
              q.questionnaire_name
            )}
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
}
