import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { Alert } from 'components/alert';
import { DialogContent } from 'components/dialog';

import { VIEW, EDIT, DELETE, ADD } from '../constants';
import { ViewEditContent } from './view_edit';
import { CreateContent } from './create';
import { DeleteContent } from './delete';

const useStyles = makeStyles((theme) => ({
  omitPadding: {
    // The list element used by view/edit has its own padding
    padding: theme.spacing(0),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
}));

export function Content({
  teamMembers,
  mode,
  setMode,
  fundName,
  memberToDelete,
  setMemberToDelete,
  formDataForCreate,
  setFormDataForCreate,
  formErrorsForCreate,
  onAddMemberSubmit,
  onMemberLevelUpdate,
  errorMsg,
  isLoading,
}) {
  const classes = useStyles();
  const isViewEdit = [VIEW, EDIT].includes(mode);

  return (
    <DialogContent
      className={clsx({
        [classes.omitPadding]: isViewEdit,
      })}
    >
      {errorMsg && Object.keys(formErrorsForCreate).length === 0 ? (
        <Alert severity="error" className={classes.alert}>
          {errorMsg}
        </Alert>
      ) : null}
      {isViewEdit ? (
        <ViewEditContent
          teamMembers={teamMembers}
          editMode={mode === EDIT}
          setMode={setMode}
          setMemberToDelete={setMemberToDelete}
          onMemberLevelUpdate={onMemberLevelUpdate}
          isLoading={isLoading}
        />
      ) : null}
      {mode === DELETE ? (
        <DeleteContent fundName={fundName} memberToDelete={memberToDelete} />
      ) : null}
      {mode === ADD ? (
        <CreateContent
          formData={formDataForCreate}
          setFormData={setFormDataForCreate}
          formErrors={formErrorsForCreate}
          onSubmit={onAddMemberSubmit}
        />
      ) : null}
    </DialogContent>
  );
}
