import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from 'components/alert';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EmailIcon from '@material-ui/icons/EmailOutlined';

import {
  Table,
  TableActions,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
} from 'components/table/index';
import { Button } from 'components/button';
import { useMe } from 'services/providers/me';
import { useConfirm } from '@passthrough/uikit';
import { getSingleErrorFromResponse } from 'services/utils';
import * as api from 'services/api';
import { useToast } from 'services/toast';

import { ACCESS_LEVELS } from '../constants';
import { ProfileCollaboratorTableHead } from '../profile_collaborator_table';
import { PermissionPopover } from '../permission_popover';
import { DialogStep } from '../dialog_step';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(5),
  },
  iconButton: {
    padding: theme.spacing(1),
  },
  error: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  tableDiv: {
    width: '100%',
    overflow: 'auto',
  },
  hidden: {
    visibility: 'hidden',
  },
  actions: {
    display: 'flex',
  },
}));

export function StepUserList({
  lpClosingId,
  profileId,
  isClaimedProfile,
  isProfileOwner,
  collaborators,
  showUserForm,
  onUpdateAccessLevel,
  onDelete,
  onClose,
  onRemind,
}) {
  const classes = useStyles();
  const [me] = useMe();
  const [error, setError] = React.useState('');
  const { toast } = useToast();
  const confirm = useConfirm();

  const canDelete = (c) => {
    if (isProfileOwner) {
      return c.userId !== me.id;
    }
    return (
      c.userId !== me.id &&
      c.accessLevel === ACCESS_LEVELS.ONLY_THIS_INVESTMENT.apiValue
    );
  };

  const canRemind = (c) => c.userId !== me.id;

  const apiAccessUpdate = ({ collaboratorId, accessLevel }) => {
    api
      .updateLpClosingCollaboratorAccessLevel({
        lpClosingId,
        profileId,
        accessLevel,
        userId: collaboratorId,
      })
      .then(() => {
        onUpdateAccessLevel();
        toast('Updated access level');
      })
      .catch(() => {
        setError('Failed to update access level.');
      });
  };

  const updateAccessLevel = ({ collaboratorId, accessLevel }) => {
    const collaborator = collaborators.find((c) => c.userId === collaboratorId);
    const previousAccessLevel = collaborator.accessLevel;

    const removedProfileAccess =
      previousAccessLevel === ACCESS_LEVELS.OWNER.apiValue &&
      accessLevel === ACCESS_LEVELS.ONLY_THIS_INVESTMENT.apiValue;
    if (removedProfileAccess) {
      confirm({
        description: `Remove ${collaborator.name} as an owner? Users removed
        will be notified via email.`,
        destructive: true,
      })
        .then(() => {
          apiAccessUpdate({ collaboratorId, accessLevel });
        })
        .catch(() => {});
    } else {
      apiAccessUpdate({ collaboratorId, accessLevel });
    }
  };

  const deleteUser = ({ userId, name, email, accessLevel }) => {
    let deleteText = `You are about to remove access from ${name || email}.`;
    if (accessLevel === ACCESS_LEVELS.OWNER.apiValue) {
      deleteText = `Remove ${name || email} as an owner? Users removed
        will be notified via email.`;
    }

    confirm({ description: deleteText, destructive: true })
      .then(() => {
        api
          .deleteLpClosingCollaborator({ lpClosingId, userId })
          .then(() => {
            onDelete();
            toast(`Removed access from ${name || email}`);
          })
          .catch((e) => {
            setError(getSingleErrorFromResponse(e.response));
          });
      })
      .catch(() => {});
  };

  const showAccessLevel = profileId && isClaimedProfile;

  return (
    <DialogStep title="Manage access" onClose={onClose}>
      <div className={classes.root}>
        <TableContainer>
          <TableActions>
            <Button
              variant="contained"
              onClick={showUserForm}
              startIcon={<AddIcon />}
            >
              Add signatory or collaborator
            </Button>
          </TableActions>
          {error ? (
            <Alert severity="error" className={classes.error}>
              {error}
            </Alert>
          ) : null}
          <div className={classes.tableDiv}>
            <Table>
              <ProfileCollaboratorTableHead
                hideAccessLevel={!showAccessLevel}
              />
              <TableBody>
                {collaborators.map((collaborator) => (
                  <TableRow key={collaborator.userId}>
                    <TableCell scope="row">{collaborator.name}</TableCell>
                    <TableCell>{collaborator.email}</TableCell>
                    {showAccessLevel ? (
                      <TableCell>
                        <PermissionPopover
                          collaboratorId={collaborator.userId}
                          accessLevel={collaborator.accessLevel}
                          setAccessLevel={updateAccessLevel}
                          me={me}
                          readOnly={!isProfileOwner}
                        />
                      </TableCell>
                    ) : null}
                    <TableCell align="right">
                      <div className={classes.actions}>
                        <div
                          className={
                            canRemind(collaborator) ? null : classes.hidden
                          }
                        >
                          <Tooltip
                            title={<Typography>Send reminder</Typography>}
                          >
                            <IconButton
                              onClick={
                                canRemind(collaborator)
                                  ? () => onRemind(collaborator)
                                  : null
                              }
                              className={classes.iconButton}
                            >
                              <EmailIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <div
                          className={
                            canDelete(collaborator) ? null : classes.hidden
                          }
                        >
                          <Tooltip title={<Typography>Delete</Typography>}>
                            <IconButton
                              onClick={
                                canDelete(collaborator)
                                  ? () => deleteUser(collaborator)
                                  : null
                              }
                              className={classes.iconButton}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </TableContainer>
      </div>
    </DialogStep>
  );
}
