import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import Paper from '@material-ui/core/Paper';
import { KeyValuePair } from 'components/key_value';
import { Typography } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  messageContainer: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.neutral.fill,
  },
}));

export function WillBeNotifiedDisplay({ notify, message }) {
  const classes = useStyles();
  return (
    <>
      <KeyValuePair icon={<EmailIcon />}>
        The investor <b>will {notify ? '' : 'not'} be notified</b> that they no
        longer need to provide additional information.
      </KeyValuePair>
      {notify && message ? (
        <KeyValuePair>
          Message to the investor:
          <Paper elevation={0} className={classes.messageContainer}>
            <Typography multiline variant="body" size="small">
              {message}
            </Typography>
          </Paper>
        </KeyValuePair>
      ) : null}
    </>
  );
}
