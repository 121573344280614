import React from 'react';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import SelectionDragIcon from 'mdi-material-ui/SelectionDrag';

import { Button } from 'components/button';
import { Paper, makeStyles } from '@material-ui/core';
import { Icons } from '@passthrough/uikit';
import { BoxInput } from './box_input';
import { boxTypes } from '../constants';

const useStyles = makeStyles((theme) => ({
  linkForm: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(2, 1),
    backgroundColor: theme.palette.background.default,
  },
  row: {
    display: 'flex',
    flexShrink: 0,
    gap: theme.spacing(1),
  },
}));

export function LinkInput({
  id,
  text,
  page,
  url,
  setPage,
  onChange,
  onDelete,
  highlightBox,
  setHighlightBoxId,
  replaceHellosignBox,
  deleteHellosignBox,
  setDragSelected,
  questions,
  handleClickItem,
  hellosignBoxes,
  handleAddHellosignBox,
  selectDocument,
}) {
  const classes = useStyles();
  const [showUrlInput, setShowUrlInput] = React.useState(Boolean(url));

  return (
    <Paper variant="outlined" className={classes.linkForm}>
      <div className={classes.row}>
        <IconButton
          onClick={() => {
            if (hellosignBoxes.length === 0) setPage(parseInt(page, 10));
            else setPage(parseInt(hellosignBoxes[0].page, 10));
          }}
        >
          <FindInPageIcon />
        </IconButton>
        <TextField
          value={text}
          onChange={(e) => {
            onChange({ id, page, text: e.target.value, url });
          }}
          variant="outlined"
          fullWidth
          required
          label="Link text"
        />
        {hellosignBoxes.length === 0 && !showUrlInput ? (
          <TextField
            value={page}
            onChange={(e) => {
              onChange({ id, page: e.target.value, text, url: null });
            }}
            variant="outlined"
            fullWidth
            required
            type="number"
            label="Page"
          />
        ) : null}
        {hellosignBoxes.length === 0 && showUrlInput ? (
          <TextField
            value={url}
            onChange={(e) => {
              onChange({ id, url: e.target.value, text, page: null });
            }}
            variant="outlined"
            fullWidth
            required
            type="url"
            label="URL"
          />
        ) : null}
        <IconButton aria-label="delete" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </div>
      {hellosignBoxes.length === 0 ? (
        <div>
          <Button
            variant="text"
            startIcon={<SelectionDragIcon />}
            onClick={() => {
              onChange({ id, page: null, text });
              handleAddHellosignBox({ type: boxTypes.CONTEXT, linkId: id });
            }}
          >
            Context
          </Button>
        </div>
      ) : null}
      <div>
        {showUrlInput ? (
          <Button
            variant="text"
            startIcon={<Icons.FindInPageOutlined />}
            onClick={() => {
              onChange({ id, url: null, text, page: null });
              setShowUrlInput(false);
            }}
          >
            Link to page
          </Button>
        ) : null}
      </div>
      <div>
        {hellosignBoxes.map((box) => (
          <BoxInput
            box={box}
            onChange={replaceHellosignBox}
            onDelete={deleteHellosignBox}
            onSelectDrag={setDragSelected}
            setPage={setPage}
            highlight={
              highlightBox.id === box.id ? highlightBox.timestamp : null
            }
            setHighlightBoxId={setHighlightBoxId}
            questions={questions}
            handleClickItem={handleClickItem}
            selectDocument={selectDocument}
            contextOnly
          />
        ))}
      </div>
    </Paper>
  );
}
