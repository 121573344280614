import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    height: 'inherit',
  },
  icon: {
    height: 'inherit',
  },
}));

export function FunnelDivider({ className }) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)}>
      <svg
        className={classes.icon}
        width="18"
        height="114"
        viewBox="0 0 18 114"
        fill="none"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M 0 0 L 17 57 L 0 114 H 1 L 18 57 L 1 0 H 1 Z"
          fill="#CCD0D7"
        />
      </svg>
    </div>
  );
}
