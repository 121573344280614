import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

export function SearchInput({ label, setThrottledSearchQuery, clearSearch }) {
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const timeout = setTimeout(() => {
      setThrottledSearchQuery(searchQuery);
    }, 200);
    return () => clearTimeout(timeout);
  }, [searchQuery]);

  useEffect(() => {
    if (clearSearch) {
      setSearchQuery('');
    }
  }, [clearSearch]);

  const SearchAdornment = () => {
    if (searchQuery === '') {
      return <SearchIcon color="action" />;
    }
    return (
      <IconButton
        size="small"
        aria-label="Clear search"
        onClick={() => {
          setSearchQuery('');
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  };

  return (
    <TextField
      variant="outlined"
      label={label}
      aria-label="Search"
      data-test="search-input"
      size="small"
      fullWidth
      value={searchQuery}
      onChange={(e) => {
        setSearchQuery(e.target.value);
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchAdornment />
          </InputAdornment>
        ),
      }}
    />
  );
}
