import React, { useState } from 'react';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Typography, Alert, Button } from '@passthrough/uikit';

import { AcceptedCommitment } from 'components/accepted_commitment';
import * as api from 'services/api';
import { useSignContext } from 'services/providers/sign';
import { Table, TableCell, TableHead, TableRow } from 'components/table';
import { useCurrency } from 'services/providers/currency';
import { FundProvider } from 'services/providers/fund';
import { Spinner } from 'components/spinner';
import { getSingleErrorFromResponse } from 'services/utils';

const TABLE_ROW_HEIGHT = 40;

const useStyles = makeStyles((theme) => ({
  acceptedCommitmentCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
  },
}));

const HAPPY_PATH = 'happy_path';
const OUTDATED_TASK = 'outdated';
const INVALID_TASK = 'invalid';

function InvestorTable({ lpDocuments, count }) {
  const classes = useStyles();
  const currency = useCurrency();
  const { code } = currency;
  if (!lpDocuments)
    return <Spinner height={TABLE_ROW_HEIGHT * (count + 1) + 1} />;
  return (
    <Table noTopMargin usePaper={false}>
      <TableHead>
        <TableRow>
          <TableCell>Investor</TableCell>
          <TableCell className={classes.acceptedCommitmentCell}>
            Commitment ({code})
          </TableCell>
        </TableRow>
      </TableHead>
      {lpDocuments.map((lpDocument) => (
        <TableRow key={lpDocument.id}>
          <TableCell>
            <Typography>{lpDocument.lpName}</Typography>
          </TableCell>
          <TableCell className={classes.acceptedCommitmentCell}>
            <AcceptedCommitment
              commitment={lpDocument.commitment}
              acceptedCommitment={lpDocument.acceptedCommitment}
              currency={currency}
            />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
}

function HappyPathBody({ errorMsg, lpDocuments, count, documentName }) {
  return (
    <>
      {errorMsg !== null ? <Alert severity="error">{errorMsg}</Alert> : null}
      <Typography>
        You are about to countersign the following investors.
      </Typography>
      <Typography variant="card-heading">{documentName}</Typography>
      <InvestorTable lpDocuments={lpDocuments} count={count} />
    </>
  );
}

function InvalidBody() {
  return (
    <>
      <Alert severity="error">This task is no longer available.</Alert>
      <Typography>These is no action required.</Typography>
    </>
  );
}

function OutdatedBody() {
  return (
    <>
      <Alert severity="warning">
        Updates are available for the included documents.
      </Alert>
      <Typography>
        A new countersigning task will be ready in a few minutes.
      </Typography>
    </>
  );
}

export function BulkCountersignModal({
  open,
  onClose,
  fundId,
  activeBulkCountersignDoc,
  setAlreadySigned,
  setActiveBulkCountersignDoc,
  refetchTasks,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [lpDocuments, setLpDocuments] = useState(null);
  const [modalState, setModalState] = useState(HAPPY_PATH);

  const { openSigningDialog } = useSignContext();

  const envelopeId = activeBulkCountersignDoc?.id;
  const count = activeBulkCountersignDoc?.count;
  const documentName = activeBulkCountersignDoc?.documentName;

  function clearState() {
    setLoading(false);
    setErrorMsg(null);
    setActiveBulkCountersignDoc(null);
    setModalState(HAPPY_PATH);
  }

  function getLpDocuments() {
    setLpDocuments(null);
    api
      .bulkCountersignLpDocumentsForEnvelopeList({ fundId, envelopeId })
      .then((response) => setLpDocuments(response.data))
      .catch((error) => {
        if (error.response?.status === 404) {
          refetchTasks();
          setModalState(INVALID_TASK);
        } else {
          setErrorMsg(getSingleErrorFromResponse(error.response));
        }
      });
  }

  function getSignaturePage() {
    setLoading(true);
    api
      .bulkCountersignaturePage({ fundId, envelopeId })
      .then((response) => {
        if (response.data.stale) {
          refetchTasks();
          setModalState(OUTDATED_TASK);
        } else {
          openSigningDialog({
            url: response.data.iframe,
            onSign: () => setAlreadySigned((a) => a + [envelopeId]),
            onClose: () => {
              clearState();
              onClose();
            },
          });
        }
      })
      .catch(() => {
        refetchTasks();
        setModalState(INVALID_TASK);
      });
  }

  const unhappyFooter = (
    <>
      <Divider />
      <div className={classes.modalFooter}>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </div>
    </>
  );

  let modalProps;
  switch (modalState) {
    case HAPPY_PATH:
      modalProps = {
        showCancelButton: true,
        headerLabel: 'Bulk countersign',
        subheaderLabel: `${count} investor${count !== 1 ? 's' : ''}`,
        primaryButtonText: 'Start countersigning',
        primaryButtonLoading: loading,
        primaryButtonProps: {
          'data-test': 'start-countersigning',
        },
        onSubmit: getSignaturePage,
      };
      break;
    case OUTDATED_TASK:
      modalProps = {
        headerLabel: 'Outdated countersigning task',
        footer: unhappyFooter,
      };
      break;
    case INVALID_TASK:
      modalProps = {
        headerLabel: 'Invalid countersigning task',
        footer: unhappyFooter,
      };
      break;
    default:
      throw new Error(`Unknown modal state: ${modalState}`);
  }

  return (
    <FundProvider fundId={fundId}>
      <Modal
        open={open}
        onClose={onClose}
        onExited={clearState}
        onEntering={getLpDocuments}
        {...modalProps}
      >
        {modalState === HAPPY_PATH ? (
          <HappyPathBody
            errorMsg={errorMsg}
            lpDocuments={lpDocuments}
            count={count}
            documentName={documentName}
          />
        ) : null}
        {modalState === OUTDATED_TASK ? <OutdatedBody /> : null}
        {modalState === INVALID_TASK ? <InvalidBody /> : null}
      </Modal>
    </FundProvider>
  );
}
