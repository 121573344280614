import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Step } from './step';
import { SELECT_PROFILE_STEP, ROLE_STEP, ACCESS_STEP } from './constants';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
  },
  stepDivider: {
    height: '1px',
    width: '40px',
    backgroundColor: theme.palette.divider,
    [theme.breakpoints.down('sm')]: {
      width: 0,
    },
  },
}));

export function Progress({ step, setStep, hideRoleStep }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Step
        name="Setup"
        isActive={step === SELECT_PROFILE_STEP}
        isComplete={step > SELECT_PROFILE_STEP}
        gotoStep={() => setStep(SELECT_PROFILE_STEP)}
        number="1"
      />
      {hideRoleStep ? null : (
        <>
          <div className={classes.stepDivider} />
          <Step
            name="Your role"
            isActive={step === ROLE_STEP}
            isComplete={step > ROLE_STEP}
            gotoStep={() => setStep(ROLE_STEP)}
            number="2"
          />
        </>
      )}
      <div className={classes.stepDivider} />
      <Step
        name="Access"
        isActive={step === ACCESS_STEP}
        isComplete={step > ACCESS_STEP}
        gotoStep={() => setStep(ACCESS_STEP)}
        number={hideRoleStep ? '2' : '3'}
      />
    </div>
  );
}
