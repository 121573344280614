import React, { useEffect, useState } from 'react';

import * as api from 'services/api';
import { Spinner } from 'components/spinner';
import { BaseAllDoneDisplay } from './base_all_done_display';
import { JOINT_SIGNER } from './constants';
import { TodoTimeline } from './todo_timeline';

export function SecondSignerAllDoneDisplay({
  lpClosingId,
  fundName,
  completionText,
}) {
  const [loading, setLoading] = useState(true);
  const [signers, setSigners] = useState(null);

  useEffect(() => {
    setLoading(true);

    api
      .lpClosingGetSignersForSecondSignatureTimeline({ lpClosingId })
      .then((resp) => {
        setSigners(resp?.data?.signers || []);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <BaseAllDoneDisplay
      fundName={fundName}
      customCompletionText={completionText}
      TimelineComponent={
        loading ? (
          <Spinner />
        ) : (
          <TodoTimeline
            fundName={fundName}
            jointSigners={signers}
            step={JOINT_SIGNER}
            hasSignatures
          />
        )
      }
    />
  );
}
