import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const ReviewChange = ({
  id,
  title,
  children,
  expanded,
  setExpanded,
}) => {
  const classes = useStyles();
  const accordionExpanded = expanded[id] ? expanded[id] : false;

  return (
    <Accordion
      expanded={accordionExpanded}
      TransitionProps={{ mountOnEnter: true }}
      onChange={() =>
        setExpanded((e) => {
          const newExpanded = { ...e };
          newExpanded[id] = !e[id];
          return newExpanded;
        })
      }
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${id}-content`}
        id={id}
      >
        <Typography>
          <strong>{title}</strong>
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
