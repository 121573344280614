import React from 'react';

import { Typography, Link, UIKitSettingsProvider } from '@passthrough/uikit';
import { SettingsGroup, Setting } from 'components/settings_group';

export function GeneralSettings({
  logo,
  currencyCode,
  currencySymbol,
  onEdit,
}) {
  const logoValue = logo ? (
    <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
      <Typography>
        <Link variant="external" href={logo}>
          {logo.split('/').pop()}
        </Link>
      </Typography>
    </UIKitSettingsProvider>
  ) : (
    <Typography color="text.secondary">No logo</Typography>
  );

  return (
    <SettingsGroup internal header="General" onEdit={onEdit}>
      <Setting label="Fund logo">{logoValue}</Setting>
      <Setting label="Currency code">
        <Typography>{currencyCode}</Typography>
      </Setting>
      <Setting label="Currency symbol">
        <Typography>{currencySymbol}</Typography>
      </Setting>
    </SettingsGroup>
  );
}
