import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Chip,
  Icons,
  Menu,
  MenuItem,
  useConfirm,
  Typography,
  Link,
} from '@passthrough/uikit';
import clsx from 'clsx';
import { Card, CardContent, CardActions, CardMedia } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 300,
    width: '100%',
    height: 250,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    height: 50,
    width: 50,
    border: '1px #ccc solid',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: 0,
    borderRadius: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    gap: 1,
    borderTop: `1px solid ${theme.palette.divider}`,
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    height: '100%',
  },
  alignRight: {
    justifyContent: 'flex-end',
  },
  warning: {
    marginTop: theme.spacing(2),
    display: 'flex !important',
  },
  image: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  activeChip: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  darkLayer: {
    backgroundColor: 'white',
    filter: 'alpha(opacity=50)',
    opacity: '0.5',
    /* Safari, Opera */
    '-moz-opacity': '0.50',
    /* FireFox */
    zIndex: 20,
    height: '100%',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    position: 'absolute',
    top: '0px',
    left: '0px',
  },
  disabledMessage: {
    display: 'flex',
    verticalAlign: 'center',
    marginTop: theme.spacing(2),
  },
  disabledIcon: {
    marginRight: theme.spacing(1),
  },
  description: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
}));

export function TraySolutionCard({
  solution,
  variant,
  connect,
  remove,
  hideManage,
  extraActions,
  helpUrl,
}) {
  const classes = useStyles();
  const confirm = useConfirm();
  const [menuRef, setMenuRef] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const isAvailable = variant === 'available';
  const isActive = variant === 'active';
  const isUnavailable = variant === 'unavailable';
  const isNeedsAttention = variant === 'needs_attention';

  const onConnect = () => {
    setLoading(true);
    connect().then(() => {
      setLoading(false);
    });
  };

  const onRemove = () => {
    confirm({
      title: 'Delete integration?',
      description: (
        <Typography>
          The integration "{solution.name}" will be deleted from your account.
          This action cannot be undone.
        </Typography>
      ),
      destructive: true,
      confirmationText: 'Delete',
    })
      .then(() => {
        setLoading(true);
        remove().then(() => {
          setLoading(false);
        });
      })
      .catch(() => {});
  };

  return (
    <Card className={classes.root} elevation={2}>
      {isUnavailable ? <div className={classes.darkLayer} /> : null}
      <div>
        <div className={classes.image}>
          <CardMedia
            title={solution.name}
            image={solution.logoUrl}
            className={classes.logo}
          />
          {isActive ? (
            <Chip
              variant="success"
              label="Active"
              className={classes.activeChip}
            />
          ) : null}
          {isUnavailable ? (
            <Chip
              variant="neutral"
              label="Unavailable"
              className={classes.activeChip}
            />
          ) : null}
          {isNeedsAttention ? (
            <Chip
              variant="error"
              label="Attention required"
              icon={<Icons.InfoOutlined />}
              className={classes.activeChip}
            />
          ) : null}
        </div>
        <CardContent>
          <Typography gutterBottom variant="h4" component="h2">
            {solution.name}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.description}
          >
            {solution.description}
          </Typography>
          {solution.disabled ? (
            <div className={classes.disabledMessage}>
              <Icons.CompareArrows className={classes.disabledIcon} />
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                size="small"
              >
                {solution.disabled}
              </Typography>
            </div>
          ) : null}
        </CardContent>
      </div>
      {isActive || isNeedsAttention ? (
        <CardActions
          className={clsx(classes.actions, {
            [classes.alignRight]: hideManage,
          })}
        >
          {!hideManage ? (
            <Button variant="secondary" onClick={onConnect} loading={loading}>
              Manage
            </Button>
          ) : null}

          <Button
            variant="icon"
            onClick={(e) => setMenuRef(e.currentTarget)}
            aria-label="More options"
          >
            <Icons.MoreVert />
          </Button>
          <Menu
            anchorEl={menuRef}
            open={Boolean(menuRef)}
            onClose={() => setMenuRef(null)}
          >
            {extraActions}
            <MenuItem
              text="Delete integration"
              icon={<Icons.Delete fontSize="small" />}
              destructive
              onClick={onRemove}
            />
          </Menu>
        </CardActions>
      ) : null}

      {isAvailable ? (
        <CardActions className={classes.actions}>
          <Button variant="primary" onClick={onConnect} loading={loading}>
            Connect
          </Button>
          {helpUrl ? (
            <Link variant="external" href={helpUrl}>
              Learn more
            </Link>
          ) : null}
        </CardActions>
      ) : null}

      {isUnavailable ? (
        <CardActions className={classes.actions}>
          <Button variant="primary" disabled>
            Connect
          </Button>
        </CardActions>
      ) : null}
    </Card>
  );
}
