import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Popover from '@material-ui/core/Popover';
import { Button, Typography, Modal, Alert } from '@passthrough/uikit';
import { BooleanIcon } from 'components/boolean_value';
import {
  SCREENING_CATEGORIES,
  DEFAULT_SCREENING_CATEGORIES,
} from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: 0,
    zIndex: 1300, // default MUI high zIndex is 1300
  },
  menuItem: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    gap: theme.spacing(2),
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 2),
  },
  optionalLabel: {
    color: theme.palette.text.secondary,
  },
  footer: {
    marginTop: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 2),
  },
}));

const Category = ({ category, enabled, onClick, shouldShowDisabledIcon }) => {
  const classes = useStyles();

  return (
    <ListItem className={classes.menuItem} key={category}>
      <Typography variant="body">{category}</Typography>
      {enabled || shouldShowDisabledIcon ? (
        <BooleanIcon bool={enabled} />
      ) : (
        <Button variant="secondary" size="small" onClick={onClick}>
          Enable
        </Button>
      )}
    </ListItem>
  );
};

const EnableAdverseMediaModal = ({ open, onClose, isIndividual, onSubmit }) => (
  <Modal
    open={open}
    headerLabel="Enable adverse media screening"
    onClose={onClose}
    showCancelButton
    primaryButtonText="Enable"
    onSubmit={onSubmit}
  >
    <Alert severity="warning">
      You may receive a significant increase of updates for this
      {isIndividual ? ' individual' : ' entity'}.
    </Alert>
    <Typography>
      Owner details will be resubmitted to populate new alerts and potential
      matches. You will not lose your current progress on reviews for matches.
    </Typography>
    <Typography>This action cannot be undone.</Typography>
  </Modal>
);

export function ScreeningCategoriesMenu({
  open,
  onClose,
  anchorEl,
  adverseMediaEnabled,
  isIndividual,
  handleEnableAdverseMedia,
  shouldShowDisabledIcon,
}) {
  const [showModal, setShowModal] = React.useState(false);
  const classes = useStyles();
  const defaultCategories = Object.values(DEFAULT_SCREENING_CATEGORIES);
  return (
    <>
      <EnableAdverseMediaModal
        open={showModal}
        onClose={() => setShowModal(false)}
        isIndividual={isIndividual}
        onSubmit={() => {
          setShowModal(false);
          handleEnableAdverseMedia();
        }}
      />

      <Popover
        open={open}
        onClose={onClose}
        className={classes.root}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableEnforceFocus
        disableRestoreFocus
      >
        <List disablePadding>
          <ListItem className={classes.title}>
            <Typography variant="card-heading">Screening categories</Typography>
          </ListItem>

          <div className={classes.body}>
            {defaultCategories?.map((category) => (
              <Category category={category} enabled />
            ))}

            <ListItem className={classes.menuItem}>
              <Divider />
            </ListItem>
            <ListItem className={classes.menuItem}>
              <Typography variant="label" className={classes.optionalLabel}>
                Optional
              </Typography>
            </ListItem>

            <Category
              category={SCREENING_CATEGORIES.ADVERSE_MEDIA}
              enabled={adverseMediaEnabled}
              onClick={() => setShowModal(true)}
              shouldShowDisabledIcon={shouldShowDisabledIcon}
            />
          </div>

          <div className={classes.footer}>
            <Button variant="secondary" onClick={onClose} size="small">
              Close
            </Button>
          </div>
        </List>
      </Popover>
    </>
  );
}
