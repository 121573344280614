import React from 'react';
import { Button, useConfirm } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';

import { FILTERS } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2),
  },
  right: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));

export const Actions = ({ question, potentialMatch, onIgnore, onMatch }) => {
  const classes = useStyles();
  const confirm = useConfirm();

  const handleIgnore = () => {
    if (question.filters.includes(FILTERS.MAPPED)) {
      confirm({
        title: 'Discard mappings and ignore?',
        description:
          'You are attempting to ignore a question with mappings. In order to ignore, the mappings will be removed.',
        destructive: true,
        confirmationText: 'Discard and continue',
      })
        .then(() => {
          onIgnore();
        })
        .catch(() => {});
    } else {
      onIgnore();
    }
  };

  return (
    <div className={classes.root}>
      <div />
      <div className={classes.right}>
        <Button
          variant="secondary"
          size="large"
          destructive
          onClick={handleIgnore}
        >
          Ignore
        </Button>
        <Button
          variant="primary"
          size="large"
          onClick={onMatch}
          disabled={!potentialMatch || !potentialMatch?.compatible}
        >
          Match
        </Button>
      </div>
    </div>
  );
};
