import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  responsivePadding: {
    padding: theme.spacing(4, 6, 9),

    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
  },
  normal: {
    alignItems: 'normal',
  },
  center: {
    alignItems: 'center',
  },
  left: {
    alignItems: 'flex-start',
  },
}));

export const PageAlignment = {
  normal: 'normal',
  center: 'center',
  left: 'left',
};

const ResponsiveWrapper = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.responsivePadding}>{children}</div>;
};

/**
 * Default wrapper for page content.
 *
 * For standalone use or as a wrapper for content below a PageHeader.
 *
 */
export function PageContainer({
  className,
  maxWidth,
  children,
  align = PageAlignment.normal,
}) {
  const classes = useStyles();

  return (
    <ResponsiveWrapper>
      <Container
        component="main"
        maxWidth={maxWidth || maxWidth === false ? maxWidth : 'lg'}
        disableGutters
      >
        <div
          className={clsx(classes.paper, className, {
            [classes.normal]: align === PageAlignment.normal,
            [classes.center]: align === PageAlignment.center,
            [classes.left]: align === PageAlignment.left,
          })}
        >
          {children}
        </div>
      </Container>
    </ResponsiveWrapper>
  );
}
