import React from 'react';

import * as api from 'services/api';
import { GenericDataExportModal } from './generic_data_export_modal';

export function SubdocDataExportModal({
  open,
  handleClose,
  investors,
  onExport,
}) {
  return (
    <GenericDataExportModal
      open={open}
      handleClose={handleClose}
      headerLabel="Export data"
      investors={investors}
      onExport={onExport}
      generateExportEndpoint={api.exportLpClosings}
    />
  );
}
