import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  cloneElement,
} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { getSignerColor, throttle } from './utils';

import * as constants from './constants';
import { BoxType, getBoxInfo } from './box_type';
import { ResizeLayer } from './resize_layer';

const MIN_BOX_WIDTH = 30;
const MIN_BOX_HEIGHT = 15;
const DEFAULT_DATE_ICON_SIZE = 6;
const DEFAULT_SIGNATURE_ICON_SIZE = 14;
const DEFAULT_INITIALS_ICON_SIZE = 14;
const DEFAULT_ACCEPTED_COMMITMENT_ICON_SIZE = 10;
const DEFAULT_ICON_SIZE = 12;

const resizableTypes = [
  BoxType.TEXT,
  BoxType.ACCEPTED_COMMITMENT,
  BoxType.NAME,
  BoxType.TITLE,
];

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  dragCursor: {
    cursor: 'grabbing',
  },
  grabCursor: {
    cursor: 'grab',
  },
  label: {
    position: 'absolute',
    top: '-24px',
    fontSize: '14px',
    whiteSpace: 'nowrap',
  },
}));

// Inspired by code from: https://codesandbox.io/s/eloquent-carson-rqvut?file=/src/App.js
const useDraggable = ({
  clampCoordinates,
  getResizeAdjustments,
  box,
  boxWidth,
  boxHeight,
  scale,
  actions,
  draggingBox,
  setDraggingBox,
  draggingResizeCorner,
  resetState,
  docHeight,
  docWidth,
} = {}) => {
  // Store starting drag coordinates in a ref to avoid unnecessary
  // re-renders and hook dependencies. This is set in the mousedown
  // handler on the box div.
  const start = useRef({ x: null, y: null });

  const boxRef = useRef();
  const unsubscribe = useRef();

  // TODO: Is this legacy ref thing really needed? What's stopping us from
  // adding the mousedown listener to the box div with useEffect?
  const legacyRef = useCallback(
    // This function is passed to the box div as the ref prop.
    (boxDiv) => {
      boxRef.current = boxDiv;
      if (unsubscribe.current) {
        unsubscribe.current();
        unsubscribe.current = null;
      }
      if (!boxDiv) {
        return;
      }

      const handleMouseDown = (event) => {
        // We don't need to propagate to the page container div, since boxes
        // should never be placed or created when clicking on an existing box.
        event.stopPropagation();

        actions.setActiveBox(box);
        start.current = { x: event.clientX, y: event.clientY };

        if (event.target.closest('div.ResizeCorner')) {
          // A ResizeCorner handled the event.
          return;
        }

        // We're not resizing, so we're dragging the box.
        setDraggingBox(true);
      };

      boxDiv.addEventListener('mousedown', handleMouseDown);
      unsubscribe.current = () => {
        boxDiv.removeEventListener('mousedown', handleMouseDown);
      };
    },
    [box, actions],
  );

  useEffect(() => {
    // Implements dragging and resizing. This modifies the DOM directly
    // so we don't have to wait for React to see the box move. React takes
    // control again when you let go of the mouse button (see handleMouseUp).
    const handleMouseMove = throttle((event) => {
      // Bail out if we're not interacting with this box.
      const triggered = draggingBox || draggingResizeCorner;
      const boxDiv = boxRef.current;
      if (!triggered || !boxDiv || start.current.x === null) {
        return;
      }

      const dragX = event.clientX - start.current.x;
      const dragY = event.clientY - start.current.y;

      if (draggingBox) {
        const { x, y } = clampCoordinates({
          x: dragX,
          y: dragY,
        });

        boxDiv.style.transform = `translate(${x}px, ${y}px)`;
      }

      if (draggingResizeCorner) {
        const { xDiff, yDiff, widthDiff, heightDiff } = getResizeAdjustments(
          dragX,
          dragY,
        );

        boxDiv.style.transform = `translate(${xDiff}px, ${yDiff}px)`;
        boxDiv.style.width = `${boxWidth + widthDiff}px`;
        boxDiv.style.height = `${boxHeight + heightDiff}px`;
      }
    });

    // Responsible for undoing direct DOM manipulation and updating React state after
    // dragging or resizing the box.
    const handleMouseUp = (event) => {
      // NOTE: May encounter buggy behavior where 'draggingBox' does not have most up to
      // date state from the mouse down handler, so always reset draggingBox state instead
      // of only resetting these values if draggingBox is true
      // NOTE: I might've fixed the bug mentioned above by changing some useEffect
      // dependencies, but we'll keep this behavior since it doesn't break anything.
      resetState();
      // State changes in resetState will have only been queued, not executed yet
      const triggered = draggingBox || draggingResizeCorner;
      const boxDiv = boxRef.current;
      if (!triggered || !boxDiv || start.current.x === null) {
        return;
      }

      // Undo the translate of the box; React will render the box in the correct position
      // when one of the actions is dispatched. (You might think this would cause the box
      // to momentarily jump back to its original position, but in practice React finishes
      // updating before the browser repaints. Will it still work when we migrate to React
      // async mode?)
      boxDiv.style.transform = 'translate(0px, 0px)';
      boxDiv.style.width = `${boxWidth}px`;
      boxDiv.style.height = `${boxHeight}px`;

      const dragX = event.clientX - start.current.x;
      const dragY = event.clientY - start.current.y;

      if (draggingBox) {
        const { x, y } = clampCoordinates({
          x: dragX,
          y: dragY,
        });

        const xDiff = x / scale;
        const yDiff = y / scale;

        actions.translateBox({
          boxId: box.id,
          xDiff,
          yDiff,
          docHeight,
          docWidth,
        });
      }

      if (draggingResizeCorner) {
        const { xDiff, yDiff, widthDiff, heightDiff } = getResizeAdjustments(
          dragX,
          dragY,
        );

        actions.resizeBox({
          boxId: box.id,
          xDiff: xDiff / scale,
          yDiff: yDiff / scale,
          widthDiff: widthDiff / scale,
          heightDiff: heightDiff / scale,
        });
      }

      // Clean up starting coordinates
      start.current = { x: null, y: null };
    };

    // The contextmenu event is triggered by right-clicking. This handler
    // is here so we don't get stuck in a state where a box is always
    // following the cursor.
    const handleContextMenu = (e) => {
      handleMouseUp(e);
    };

    // NOTE: Every Box instance sets event listeners on document, so e.g.
    // there could be many mousemove listeners on document at the same time.
    // This is a bit ugly, but it works. There would probably have to be
    // hundreds of boxes on a page for this to cause performance issues.
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      // Cancels any requested animation frame
      handleMouseMove.cancel();
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, [
    box,
    draggingBox,
    draggingResizeCorner,
    clampCoordinates,
    getResizeAdjustments,
    docWidth,
    docHeight,
    scale,
    actions,
  ]);

  return legacyRef;
};

export function Box({ box, state, actions, docWidth, docHeight, scale }) {
  const classes = useStyles();
  const [draggingBox, setDraggingBox] = useState(false);
  const [resizeLeft, setResizeLeft] = useState(null);
  const [resizeTop, setResizeTop] = useState(null);

  const draggingResizeCorner = resizeLeft !== null && resizeTop !== null;
  const resetState = () => {
    setDraggingBox(false);
    setResizeLeft(null);
    setResizeTop(null);
  };

  const { activeBox } = state;

  const borderWidth = activeBox && activeBox.id === box.id ? 3 : 1;
  const signerColor = getSignerColor(box.signer);

  function getFrontendVal(val) {
    return val * scale;
  }

  // Enforce boundaries of the document
  const clampCoordinates = useCallback(
    ({ x, y }) => {
      const origX = getFrontendVal(box.x);
      const rightBound = getFrontendVal(docWidth - box.width - box.x);
      const origY = getFrontendVal(box.y);
      const bottomBound = getFrontendVal(docHeight - box.height - box.y);
      return {
        x: Math.min(Math.max(-origX, x), rightBound),
        y: Math.min(Math.max(-origY, y), bottomBound),
      };
    },
    [box, docWidth, docHeight, scale],
  );

  // Compute x, y, width and height offsets when resizing
  const getResizeAdjustments = useCallback(
    (x, y) => {
      const capX = resizeLeft
        ? Math.max(x, -getFrontendVal(box.x))
        : Math.min(x, getFrontendVal(docWidth - box.width - box.x));
      const xDiff = resizeLeft
        ? Math.min(capX, getFrontendVal(box.width - MIN_BOX_WIDTH))
        : 0;
      const wDiff = resizeLeft ? -1 * capX : capX;
      const widthDiff = Math.max(
        getFrontendVal(MIN_BOX_WIDTH - box.width),
        wDiff,
      );

      const capY = resizeTop
        ? Math.max(y, -getFrontendVal(box.y))
        : Math.min(y, getFrontendVal(docHeight - box.height - box.y));
      const yDiff = resizeTop
        ? Math.min(capY, getFrontendVal(box.height - MIN_BOX_HEIGHT))
        : 0;
      const hDiff = resizeTop ? -1 * capY : capY;
      const heightDiff = Math.max(
        getFrontendVal(MIN_BOX_HEIGHT - box.height),
        hDiff,
      );

      return {
        xDiff,
        yDiff,
        widthDiff,
        heightDiff,
      };
    },
    [box, docWidth, docHeight, scale, resizeLeft, resizeTop],
  );

  const magicDraggableRef = useDraggable({
    clampCoordinates,
    getResizeAdjustments,
    scale,
    box,
    boxWidth: getFrontendVal(box.width),
    boxHeight: getFrontendVal(box.height),
    actions,
    draggingBox,
    setDraggingBox,
    draggingResizeCorner,
    resetState,
    docHeight,
    docWidth,
  });

  function getIconSize() {
    switch (box.type) {
      case BoxType.DATE:
        return DEFAULT_DATE_ICON_SIZE * scale;
      case BoxType.SIGNATURE:
        return DEFAULT_SIGNATURE_ICON_SIZE * scale;
      case BoxType.INITIALS:
        return DEFAULT_INITIALS_ICON_SIZE * scale;
      case BoxType.ACCEPTED_COMMITMENT:
        return DEFAULT_ACCEPTED_COMMITMENT_ICON_SIZE * scale;
      default:
        return DEFAULT_ICON_SIZE * scale;
    }
  }

  const { type, icon } = getBoxInfo(box);

  function getIcon() {
    if (box.type === BoxType.CHECKBOX) {
      return null;
    }

    const iconSize = getIconSize();
    return cloneElement(icon, {
      className: classes.icon,
      style: { fontSize: iconSize, color: signerColor },
    });
  }

  function getLabel() {
    switch (box.investorType) {
      case constants.INVESTOR_TYPE_ALL:
        return 'All investors';
      case constants.INVESTOR_TYPE_INDIVIDUAL:
        return 'Individuals only';
      case constants.INVESTOR_TYPE_ENTITY:
        return 'Entities only';
      default:
        return null;
    }
  }

  const boxStyle = {
    top: `${getFrontendVal(box.y)}px`,
    left: `${getFrontendVal(box.x)}px`,
    border: `${borderWidth}px solid ${signerColor}`,
    width: `${getFrontendVal(box.width)}px`,
    height: `${getFrontendVal(box.height)}px`,
    backgroundColor: `${signerColor}25`,
  };
  const labelStyle = {
    color: signerColor,
  };

  const showResizeLayer =
    activeBox?.id === box.id && resizableTypes.includes(type);

  return (
    <div
      style={boxStyle}
      ref={magicDraggableRef}
      className={clsx(
        classes.root,
        // If draggingResizeCorner, the corner components set the cursor style.
        {
          [classes.dragCursor]: draggingBox,
          [classes.grabCursor]: !draggingBox,
        },
      )}
    >
      <Typography className={classes.label} style={labelStyle}>
        {getLabel()}
      </Typography>
      {showResizeLayer ? (
        <ResizeLayer
          setResizeLeft={setResizeLeft}
          setResizeTop={setResizeTop}
          color={signerColor}
        />
      ) : null}
      {getIcon()}
    </div>
  );
}
