import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import CheckIcon from '@material-ui/icons/Check';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

import { Button } from 'components/button';
import { getInitials, getLevelText } from '../utils';
import { ADD, DELETE } from '../constants';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.info.fill,
  },
  avatarInitials: {
    color: theme.palette.primary.black,
  },
  noEditLevelText: {
    color: theme.palette.text.secondary,
    fontSize: '0.9rem',
  },
  addButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 3),
    gap: theme.spacing(2),
    // keeps the modal from growing when manage access is clicked
    // keep the same as editList
    height: theme.spacing(5),
  },
  list: {
    overflowY: 'auto',
    maxHeight: '60vh',
    padding: theme.spacing(0, 1),
  },
  editList: {
    maxHeight: `calc(60vh - ${theme.spacing(5)}px)`,
  },
  listItemIcon: {
    minWidth: theme.spacing(5),
  },
  menuItem: {
    backgroundColor: `${theme.palette.background.paper} !important`,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
    },
  },
  select: {
    // Let the button handle the padding
    padding: `${theme.spacing(0)} !important`,
    '&:focus': {
      borderRadius: theme.spacing(1),
    },
  },
}));

function SelectIconComponent() {
  return null;
}

export function ViewEditContent({
  teamMembers,
  editMode,
  setMode,
  setMemberToDelete,
  onMemberLevelUpdate,
  isLoading,
}) {
  const [loadingId, setLoadingId] = useState(null);
  const classes = useStyles();

  function editSelect(member) {
    const loading = isLoading && loadingId === member.id;
    const renderValue = (value) => (
      <Button
        variant="text"
        color="default"
        endIcon={<ExpandMoreIcon color={loading ? 'default' : 'primary'} />}
        loading={loading}
      >
        {getLevelText(value)}
      </Button>
    );

    return (
      <Select
        value={member.level}
        classes={{
          select: classes.select,
        }}
        disableUnderline
        IconComponent={SelectIconComponent}
        renderValue={renderValue}
        onChange={(e) => {
          e.preventDefault();
          setLoadingId(member.id);
          onMemberLevelUpdate(member.id, e.target.value);
        }}
      >
        {['EDIT', 'VIEW'].map((level) => (
          <MenuItem
            key={level}
            value={level}
            className={classes.menuItem}
            ListItemClasses={{ selected: classes.menuItem }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              {member.level === level && <CheckIcon color="primary" />}
            </ListItemIcon>
            {getLevelText(level)}
          </MenuItem>
        ))}
      </Select>
    );
  }

  function avatar(member) {
    const initials = getInitials(member.name);

    if (initials) {
      return (
        <Avatar className={classes.avatar}>
          <Typography className={classes.avatarInitials}>{initials}</Typography>
        </Avatar>
      );
    }

    return (
      <Avatar className={classes.avatar}>
        <Icon>
          <PermIdentityIcon color="action" />
        </Icon>
      </Avatar>
    );
  }

  return (
    <>
      {editMode ? (
        <div className={classes.addButtonContainer}>
          <Typography variant="body2">
            {teamMembers.length} team member
            {teamMembers.length > 1 ? 's have' : ' has'} access
          </Typography>
          <Button
            onClick={() => setMode(ADD)}
            variant="outlined_thin"
            data-test="add-team-member-mode"
          >
            Add team member
          </Button>
        </div>
      ) : null}
      <List
        className={clsx(classes.list, {
          [classes.editList]: editMode,
        })}
      >
        {teamMembers.map((member) => (
          <ListItem key={member.id}>
            <ListItemAvatar>{avatar(member)}</ListItemAvatar>
            <ListItemText primary={member.name} secondary={member.email} />
            <ListItemSecondaryAction>
              {editMode ? (
                <>
                  {editSelect(member)}
                  <IconButton
                    onClick={() => {
                      setMemberToDelete(member);
                      setMode(DELETE);
                    }}
                  >
                    <Icon>
                      <DeleteIcon />
                    </Icon>
                  </IconButton>
                </>
              ) : (
                <Typography className={classes.noEditLevelText}>
                  {getLevelText(member.level)}
                </Typography>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </>
  );
}
