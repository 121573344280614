import React from 'react';
import { FileUpload } from '@passthrough/uikit';
import { ShowCaseBoxRow } from '../../show_case';

export const MultiFileUploadShowCase = () => {
  const [files, setFiles] = React.useState([]);

  return (
    <ShowCaseBoxRow>
      <FileUpload
        files={files}
        acceptedFileTypes={['application/pdf', 'image/jpeg']}
        onAccept={(uploadedFiles) => setFiles(uploadedFiles)}
        onDelete={(fileId) => {
          setFiles(files.filter((file) => file.fileId !== fileId));
        }}
        multiple
        helperText="Max file size is 30mb. Supported file types are .pdf and .jpg."
      />
    </ShowCaseBoxRow>
  );
};
