import slugify from 'slugify';

export const getIdFromName = (name) => slugify(name.toLowerCase(), '_');

const toSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

const toCamelCase = (str) =>
  str.replace(/_([a-z])/g, (matchObj) => matchObj[1].toUpperCase());

const isArray = (a) => Array.isArray(a);

const isObject = (obj) =>
  obj === Object(obj) && !isArray(obj) && typeof o !== 'function';

const convertKeys = (convert, obj) => {
  if (isObject(obj)) {
    const n = {};

    Object.keys(obj).forEach((k) => {
      n[convert(k)] = convertKeys(convert, obj[k]);
    });

    return n;
  }
  if (isArray(obj)) {
    return obj.map((i) => convertKeys(convert, i));
  }

  return obj;
};

export const keysToSnake = (obj) => convertKeys(toSnakeCase, obj);
export const keysToCamel = (obj) => convertKeys(toCamelCase, obj);
