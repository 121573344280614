import Tooltip from '@material-ui/core/Tooltip';
import { Link, Typography } from '@passthrough/uikit';
import * as React from 'react';
import { useFund } from 'services/providers/fund';
import * as urls from 'services/urls';

const MAX_NAME_LENGTH = 20;

function getTruncatedName(name) {
  return `${name.slice(0, MAX_NAME_LENGTH / 2)}...${name.slice(
    -MAX_NAME_LENGTH / 2 + 2,
  )}`;
}

const ConditionalLink = ({ children, id }) => {
  const [fund] = useFund();
  return id && fund ? (
    <Link href={urls.closingUrl({ fundId: fund.id, closingId: id })}>
      {children}
    </Link>
  ) : (
    children
  );
};

export const ClosingNamesList = ({ closings, truncate }) => {
  const joinedClosingNames = closings.reduce((acc, { name, id }, i) => {
    const nameElement =
      truncate > 1 && name.length > MAX_NAME_LENGTH ? (
        <Tooltip
          key={id}
          title={
            <Typography variant="label" color="inherit">
              {name}
            </Typography>
          }
        >
          <span>
            <ConditionalLink id={id}>{getTruncatedName(name)}</ConditionalLink>
          </span>
        </Tooltip>
      ) : (
        <ConditionalLink key={id} id={id}>
          {name}
        </ConditionalLink>
      );
    if (i === 0) {
      return [nameElement];
    }
    return [...acc, ', ', nameElement];
  }, []);
  return joinedClosingNames;
};
