import React, { useState, useEffect } from 'react';
import { Button, useConfirm, Icons } from '@passthrough/uikit';

import { keysToCamel } from './helpers';

export function useCamelState(initial) {
  const [state, setState] = useState(initial);
  const setterWrapper = (newState) => setState(keysToCamel(newState));
  return [state, setterWrapper];
}

export function useModalState({
  initialState,
  label,
  addObject,
  changeObject,
  deleteObject,
  requiredFields,
  onClose,
}) {
  const [state, setState] = useState({});
  const [isNew, setIsNew] = useState(false);
  const [open, setOpen] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const confirm = useConfirm();

  const headerLabel = isNew ? `Add ${label}` : `Edit ${label}`;

  // Control open based on whether initialState is null
  useEffect(() => {
    if (open && initialState === null) {
      setOpen(false);
    }
    if (!open && initialState !== null) {
      setShowErrors(false);
      setState(initialState);
      setIsNew(initialState.id === null);
      setOpen(true);
    }
  }, [initialState, open]);

  const onSubmit = () => {
    for (let i = 0; i < requiredFields.length; i += 1) {
      if (state[requiredFields[i]] === '') {
        setShowErrors(true);
        return;
      }
    }

    setShowErrors(false);
    if (isNew) {
      addObject(state);
    } else {
      changeObject(state);
    }
    onClose();
  };

  const deleteButton = isNew ? null : (
    <Button
      variant="text"
      destructive
      onClick={() => {
        confirm({
          title: `Delete ${label}`,
          description: `Are you sure you want to delete this ${label}?`,
          confirmationText: 'Delete',
          destructive: true,
        })
          .then(() => {
            deleteObject(state);
            onClose();
          })
          .catch(() => {});
      }}
      startIcon={<Icons.DeleteOutlined />}
    >
      Delete
    </Button>
  );

  return [
    state,
    setState,
    headerLabel,
    open,
    onSubmit,
    isNew,
    deleteButton,
    showErrors,
  ];
}
