import React from 'react';

import { InvestorTaggingModalRoot } from './investor_tagging_modal_root';
import { InvestorTaggingModalStateProvider } from './providers/state_provider';
import { InvestorTaggingMutationsProvider } from './providers/mutations_provider';

export function InvestorTaggingModal({
  open,
  selectedInvestors,
  investorTagGroups,
  refreshTaggingData,
  handleClose,
}) {
  return (
    <InvestorTaggingModalStateProvider>
      <InvestorTaggingMutationsProvider
        refreshTaggingData={refreshTaggingData}
        handleClose={handleClose}
      >
        <InvestorTaggingModalRoot
          open={open}
          selectedInvestors={selectedInvestors}
          investorTagGroups={investorTagGroups}
          handleClose={handleClose}
        />
      </InvestorTaggingMutationsProvider>
    </InvestorTaggingModalStateProvider>
  );
}
