import React from 'react';
import { getAnswerDisplayComponent } from 'components/answer_displays/get_answer_display';
import { FieldContainer, Field } from './fields';

export const BankV2Display = ({ answer }) => {
  const AddressAnswerComponent =
    getAnswerDisplayComponent('AnswerType.address');

  return (
    <FieldContainer>
      <Field label="Bank name" labelMinWidth={180}>
        {answer?.bank?.bankName || '-'}
      </Field>
      <Field label="Account number">{answer?.bank?.accountNumber || '-'}</Field>
      <Field label="Account name">{answer?.bank?.accountName || '-'}</Field>
      <Field label="ABA (routing number)">{answer?.bank?.aba || '-'}</Field>
      <Field label="SWIFT">{answer?.bank?.swift || '-'}</Field>
      <Field label="IBAN">{answer?.bank?.iban || '-'}</Field>
      <Field label="Transmission notes">
        {answer?.bank?.transmissionNotes || '-'}
      </Field>
      <Field label="For further credit to account number">
        {answer?.bank?.ffctAccountNumber || '-'}
      </Field>
      <Field label="For further credit to account name">
        {answer?.bank?.ffctAccountName || '-'}
      </Field>
      <Field label="Address">
        {answer?.googleAddress || answer?.manualAddress ? (
          <AddressAnswerComponent
            answer={answer.googleAddress || answer.manualAddress}
          />
        ) : (
          '-'
        )}
      </Field>
    </FieldContainer>
  );
};
