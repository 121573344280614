import React from 'react';

export function DateStartIcon({ className }) {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1953 14.1321H8.24316V24.2972H15.2192V26.3302H8.24316C7.13696 26.3302 6.25 25.4154 6.25 24.2972L6.25997 10.0661C6.25997 8.94789 7.13696 8.03303 8.24316 8.03303H9.23974V6H11.2329V8.03303H19.2055V6H21.1987V8.03303H22.1953C23.2915 8.03303 24.1885 8.94789 24.1885 10.0661V17.361H22.1953V14.1321ZM22.1953 10.0661H8.24316V12.0991H22.1953V10.0661ZM26.6094 17.7459L28.3635 19.5L20.8511 27L16.6176 22.7418L18.3594 21L20.8511 23.5041L26.6094 17.7459Z"
        fill="grey"
      />
    </svg>
  );
}
