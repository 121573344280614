import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

export const IsRequiredInput = ({
  isRequired,
  setIsRequired,
  blockNotRequired,
  hideIsRequired,
  answerType,
}) => (
  <ToggleButtonGroup
    value={isRequired}
    exclusive
    onChange={(e, v) => {
      if (v !== null) {
        setIsRequired(v);
      }
    }}
  >
    <ToggleButton value="required" aria-label="required">
      <Tooltip title={<Typography>Required</Typography>}>
        <LockIcon />
      </Tooltip>
    </ToggleButton>
    {blockNotRequired.includes(answerType) || hideIsRequired ? null : (
      <ToggleButton value="optional" aria-label="optional">
        <Tooltip title={<Typography>Optional</Typography>}>
          <LockOpenIcon />
        </Tooltip>
      </ToggleButton>
    )}
  </ToggleButtonGroup>
);
