import React from 'react';

import { ComplexKeepTagsPanel } from './complex_keep_tag_panel';
import * as constants from './constants';
import { KeepTagPanel } from './keep_tag_panel';

export const KeepTags = ({
  questionTags,
  questionId,
  questionType,
  allFields,
  origQuestion,
  newQuestion,
  keepTagsConfirmed,
  setKeepTagsConfirmed,
}) => {
  const origQuestionText = origQuestion && origQuestion.question;
  const newQuestionText = newQuestion && newQuestion.question;

  if (questionType === constants.COMPLEX) {
    return (
      <ComplexKeepTagsPanel
        key={`${questionId}`}
        fields={allFields}
        keepTagsConfirmed={keepTagsConfirmed}
        setKeepTagsConfirmed={setKeepTagsConfirmed}
        questionTags={questionTags}
        questionId={questionId}
        origQuestionText={origQuestionText}
        newQuestionText={newQuestionText}
      />
    );
  }

  const keepTags = keepTagsConfirmed[questionId]
    ? Object.keys(keepTagsConfirmed[questionId]).map((c) => {
        const tags = questionTags && questionTags[c];
        const hasQuestionTags = tags.length > 0;
        const origQuestionChoiceId =
          origQuestion.choices.length > 0
            ? origQuestion.choices.find((ch) => ch.text === c).id
            : null;
        const newQuestionChoice = origQuestionChoiceId
          ? newQuestion.choices.find((ch) => ch.id === origQuestionChoiceId)
          : null;
        const newQuestionChoiceText = newQuestionChoice
          ? newQuestionChoice.text
          : '';

        const confirmedVal = keepTagsConfirmed[questionId]
          ? keepTagsConfirmed[questionId][c]
          : constants.CONFIRMED_NO;

        function setConfirmed(confirmed) {
          setKeepTagsConfirmed((map) => {
            const newMap = { ...map };
            newMap[questionId][c] = confirmed;
            return newMap;
          });
        }

        function undoConfirmation() {
          setConfirmed(constants.NOT_CONFIRMED);
        }

        return hasQuestionTags ? (
          <KeepTagPanel
            key={`${questionId}-${c}`}
            confirmed={confirmedVal}
            setConfirmed={setConfirmed}
            undoConfirmation={undoConfirmation}
            questionType={questionType}
            questionTags={tags}
            origQuestionText={origQuestionText}
            origQuestionChoice={c}
            newQuestionText={newQuestionText}
            newQuestionChoice={newQuestionChoiceText}
          />
        ) : null;
      })
    : null;

  return keepTags;
};
