import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from 'components/table/index';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { useMe } from 'services/providers/me';
import { useConfirm } from '@passthrough/uikit';
import { TextChip, TextChipVariants } from 'components/text_chip';
import { getCollaboratorId } from './helpers';
import { PermissionPopover } from './permission_popover';

const useHeadStyles = makeStyles((theme) => ({
  nameHeader: {
    width: '30%',
    whiteSpace: 'nowrap',
  },
  emailHeader: {
    width: '30%',
  },
  accessLevelHeader: {
    width: '30%',
    paddingLeft: theme.spacing(2),
    whiteSpace: 'nowrap',
  },
  actionHeader: {
    width: '10%',
  },
}));

const useBodyStyles = makeStyles((theme) => ({
  iconButton: {
    padding: theme.spacing(1),
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  highlightRow: {
    backgroundColor: theme.palette.table.highlight,
  },
}));

function CollaboratorName({ name, isMe, className }) {
  return (
    <div className={className}>
      <Typography>{name}</Typography>
      {isMe ? <TextChip label="You" variant={TextChipVariants.INFO} /> : null}
    </div>
  );
}

export function ProfileTableBody({
  allCollaborators,
  setAccessLevel,
  onDelete,
  readOnly,
  highlightAddedCollaborators = false,
}) {
  const classes = useBodyStyles();

  const [me] = useMe();
  const confirm = useConfirm();
  const showDelete = (c) => c.userId !== me.id && !readOnly;

  const deleteClick = (collaborator) => {
    confirm({
      description: `Remove ${collaborator.name || collaborator.email}?`,
      dialogProps: {
        maxWidth: 'xs',
      },
      destructive: true,
    })
      .then(() => {
        onDelete(collaborator);
      })
      .catch(() => {});
  };

  return (
    <TableBody>
      {allCollaborators.map((collaborator) => {
        const collaboratorId = getCollaboratorId(collaborator);

        return (
          <TableRow
            key={collaboratorId}
            className={clsx({
              [classes.highlightRow]:
                collaborator.added && highlightAddedCollaborators,
            })}
          >
            <TableCell scope="row">
              <CollaboratorName
                className={classes.nameContainer}
                name={collaborator.name}
                isMe={collaborator.userId === me.id}
              />
            </TableCell>
            <TableCell>{collaborator.email}</TableCell>
            <TableCell>
              <PermissionPopover
                collaboratorId={collaboratorId}
                accessLevel={collaborator.accessLevel}
                setAccessLevel={setAccessLevel}
                readOnly={readOnly}
                me={me}
              />
            </TableCell>
            <TableCell align="right">
              {showDelete(collaborator) ? (
                <>
                  <Tooltip title={<Typography>Delete</Typography>}>
                    <IconButton
                      onClick={() => deleteClick(collaborator)}
                      className={classes.iconButton}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ) : null}
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}

export function ProfileCollaboratorTableHead({ hideAccessLevel }) {
  const classes = useHeadStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell scope="row" className={classes.nameHeader}>
          Name
        </TableCell>
        <TableCell scope="row" className={classes.nameHeader}>
          Email address
        </TableCell>
        {hideAccessLevel ? null : (
          <TableCell scope="row" className={classes.accessLevelHeader}>
            Access level
          </TableCell>
        )}
        <TableCell className={classes.actionHeader} align="right" />
      </TableRow>
    </TableHead>
  );
}
