import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { DndMultiFileUpload } from 'components/dnd_file_upload/multi_file/multi_file_upload';

import { PDFNameChecker } from 'components/document_upload/pdf_name_checker';
import { getEmptyFile } from 'components/file_upload';
import { genDataTestLabel } from '../utils';

const useStyles = makeStyles((theme) => ({
  helperText: {
    whiteSpace: 'pre-wrap',
  },
  label: {
    color: theme.palette.text.primary,
    textAlign: 'left',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    whiteSpace: 'normal',
    lineHeight: '1.5rem',
  },
  upload: {
    display: 'block',
    margin: theme.spacing(1, 0),
  },
}));

export function DiligenceMultiFileInput({
  files,
  fileErrors,
  fileUploadUrl,
  onChange,
  formLabel,
  helpText = '',
  investorName,
  isStaff = false,
  enableNameChecks = false,
  ...extraInputProps
}) {
  const classes = useStyles();
  const fileDataTestLabel = genDataTestLabel(formLabel);

  const [error, setError] = React.useState([]);
  const [filesToCheck, setFilesToCheck] = React.useState([getEmptyFile()]);

  const handleChange = (newFile) => {
    if (enableNameChecks && isStaff && newFile?.fileName) {
      setFilesToCheck(newFile);
    } else {
      onChange(newFile);
    }
    setError(null);
  };

  return (
    <FormControl
      className={classes.upload}
      fullWidth
      error={!!(fileErrors || error?.message)}
    >
      <FormLabel className={classes.label} component="legend">
        {formLabel}
      </FormLabel>

      <FormHelperText className={classes.helperText}>{helpText}</FormHelperText>

      <DndMultiFileUpload
        files={files}
        onChange={handleChange}
        url={fileUploadUrl}
        {...extraInputProps}
        data-test={`diligence_v2_${fileDataTestLabel}_file_input`}
      />
      {isStaff && enableNameChecks
        ? files.map((file, index) => (
            <PDFNameChecker
              investorName={investorName}
              investorLegalName={null}
              file={file}
              setFile={onChange}
              fileToCheck={filesToCheck[index]}
              setFileToCheck={setFilesToCheck[index]}
              setFileError={setError}
              isStaff={isStaff}
              alreadyCheckedPdf={!!file}
              entityType="node"
            />
          ))
        : null}
      {fileErrors ? <FormHelperText>{fileErrors}</FormHelperText> : null}
      {error?.message ? <FormHelperText>{error.message}</FormHelperText> : null}
    </FormControl>
  );
}
