import React, { useState } from 'react';

import { Typography, Modal, Alert } from '@passthrough/uikit';
import { PersonEntityFilter } from 'pages/onboarding_v3/common/person_entity_filter';
import { useToast } from 'services/toast';

export const BulkUpdateInvestorTypeModal = ({
  open,
  selectedIds,
  isSaving,
  onClose,
  onSave,
}) => {
  const [investorType, setInvestorType] = useState(null);
  const { toast } = useToast();

  return (
    <Modal
      open={open}
      onClose={onClose}
      primaryButtonText="Save"
      primaryButtonLoading={isSaving}
      primaryButtonDisabled={!investorType}
      headerLabel="Bulk update investor type"
      onExited={() => setInvestorType(null)}
      onSubmit={() => {
        onSave(investorType);
        onClose();
        toast(`Investor type changed on ${selectedIds.length} questions.`);
      }}
      showCancelButton
    >
      <Alert severity="warning">
        Bulk updating investor types will overwrite the selected type on each
        selected question
      </Alert>
      <Typography>Select the intended investor type</Typography>
      <PersonEntityFilter isAsked={investorType} setIsAsked={setInvestorType} />
    </Modal>
  );
};
