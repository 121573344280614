import React from 'react';
import {
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
} from 'components/table/index';
import { Alert, Typography } from '@passthrough/uikit';
import { useSupport } from 'components/support';
import { DisplayLink } from 'components/link';
import { ProfileInfoSelect } from './profile_info_select';

export function StepInfo({
  fundName,
  lpName,
  activeProfile,
  availableProfiles,
  selectedProfileId,
  setSelectedProfileId,
  disableProfileSwitching,
}) {
  const { showSupport } = useSupport();

  return (
    <>
      <Typography variant="body">
        You are currently using the profile named {activeProfile.displayName} to
        fill out the subscription document for the investor {lpName}.
      </Typography>
      <TableContainer>
        <Table noTopMargin>
          <TableBody>
            <TableRow key="fund">
              <TableCell scope="row">Fund name</TableCell>
              <TableCell align="right">{fundName}</TableCell>
            </TableRow>
            <TableRow key="lp">
              <TableCell scope="row">Invited investor name</TableCell>
              <TableCell align="right">{lpName}</TableCell>
            </TableRow>
            <TableRow key="profile">
              <TableCell scope="row">Profile name</TableCell>
              <TableCell align="right">{activeProfile.displayName}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {disableProfileSwitching ? (
        <Alert severity="info">
          Your investor profile has been used for a submission. If you wish to
          use a different profile, please{' '}
          <DisplayLink onClick={() => showSupport()} underline="always">
            contact support
          </DisplayLink>
          .
        </Alert>
      ) : (
        <ProfileInfoSelect
          activeProfile={activeProfile}
          availableProfiles={availableProfiles}
          selectedProfileId={selectedProfileId}
          setSelectedProfileId={setSelectedProfileId}
        />
      )}
    </>
  );
}
