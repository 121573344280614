import React, { useState, useMemo } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import TextSearch from 'mdi-material-ui/TextSearch';
import { PartyPopper } from 'mdi-material-ui';

import { TableContainer, TableCell } from 'components/table/index';
import { SmartTable } from 'components/table/smart';
import { EmptyState } from 'components/empty_v2';

import { InvestorCell } from './investor_cell';
import { IssueCell } from './issue_cell';
import { sortTasks } from './utils';

import {
  ISSUE_COLUMN,
  RISK_RATING_COLUMN,
  LEGAL_NAME_COLUMN,
  INVESTOR_COLUMN,
  CLOSING_COLUMN,
  FUND_COLUMN,
  CREATED_COLUMN,
  ISSUE_FILTERS,
} from './constants';
import { RiskRatingCell } from './risk_rating_cell';
import { CreatedCell } from './created_cell';

const useStyles = makeStyles((theme) => ({
  cell: {
    verticalAlign: 'top',
    padding: theme.spacing(2),
  },
  nameCell: {
    minWidth: '160px',
    maxWidth: '240px',
  },
  headCell: {
    padding: theme.spacing(1, 2),
    whiteSpace: 'nowrap',
    fontWeight: 400,
    fontSize: '.875rem',
  },
}));

export function TableRows({
  unsortedTasks,
  columns,
  clearFilters,
  issueFilter,
  throttledSearchQuery,
}) {
  const [order, setOrder] = useState([ISSUE_COLUMN, 'desc']);
  const classes = useStyles();

  const tasks = useMemo(
    () => sortTasks(order, unsortedTasks),
    [unsortedTasks, ...order],
  );

  function getKey(task) {
    return task.key;
  }

  const CustomEmptyState = () =>
    issueFilter.length === ISSUE_FILTERS.length &&
    throttledSearchQuery === '' ? (
      <EmptyState
        Icon={PartyPopper}
        title="No issues"
        text="Try refreshing for new issues."
      />
    ) : (
      <EmptyState
        Icon={TextSearch}
        title="No issues"
        text="Adjust or clear all filters to try again."
        ctaPermission
        ctaText="Clear all filters"
        ctaOnClick={clearFilters}
      />
    );

  return (
    <TableContainer>
      <SmartTable
        getKey={getKey}
        rows={tasks}
        columnIds={columns}
        sortable={[
          ISSUE_COLUMN,
          CREATED_COLUMN,
          LEGAL_NAME_COLUMN,
          INVESTOR_COLUMN,
          CLOSING_COLUMN,
          FUND_COLUMN,
        ]}
        selected={[]}
        order={order}
        onChangeOrder={setOrder}
        canEdit
        headerCellClassName={classes.headCell}
        headerRowClassName={classes.headerRow}
        CustomEmptyState={CustomEmptyState}
        renderHeadLabel={(columnId) => {
          switch (columnId) {
            case ISSUE_COLUMN:
              return 'Issue';
            case RISK_RATING_COLUMN:
              return 'Risk Rating';
            case LEGAL_NAME_COLUMN:
              return 'Legal Name';
            case INVESTOR_COLUMN:
              return 'Investor';
            case CLOSING_COLUMN:
              return 'Closing';
            case FUND_COLUMN:
              return 'Fund';
            case CREATED_COLUMN:
              return 'Created';
            default:
              return null;
          }
        }}
        renderBodyCell={(columnId, task) => {
          switch (columnId) {
            case ISSUE_COLUMN:
              return (
                <TableCell
                  className={clsx(classes.cell, classes.nameCell)}
                  key={columnId}
                  scope="row"
                >
                  <IssueCell issueType={task.issue} />
                </TableCell>
              );
            case RISK_RATING_COLUMN:
              return (
                <TableCell
                  className={clsx(classes.cell)}
                  key={columnId}
                  scope="row"
                >
                  <RiskRatingCell rating={task.riskRating} />
                </TableCell>
              );
            case LEGAL_NAME_COLUMN:
              return (
                <TableCell
                  className={clsx(classes.cell, classes.nameCell)}
                  key={columnId}
                  scope="row"
                >
                  {task.legalName}
                </TableCell>
              );
            case INVESTOR_COLUMN:
              return (
                <TableCell
                  className={clsx(classes.cell, classes.nameCell)}
                  key={columnId}
                  scope="row"
                >
                  <InvestorCell task={task} />
                </TableCell>
              );
            case CLOSING_COLUMN:
              return (
                <TableCell
                  className={clsx(classes.cell, classes.nameCell)}
                  key={columnId}
                  scope="row"
                >
                  {task.closingName}
                </TableCell>
              );
            case FUND_COLUMN:
              return (
                <TableCell
                  className={clsx(classes.cell, classes.nameCell)}
                  key={columnId}
                  scope="row"
                >
                  {task.fundName}
                </TableCell>
              );
            case CREATED_COLUMN:
              return (
                <TableCell
                  className={clsx(classes.cell, classes.nameCell)}
                  key={columnId}
                  scope="row"
                >
                  <CreatedCell task={task} />
                </TableCell>
              );
            default:
              return null;
          }
        }}
      />
    </TableContainer>
  );
}
