import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';

import { HeaderWithSeeMoreButton } from './header_with_see_more_button';
import { AlertCard } from './alert_card';
import { alertVariants, NUM_CARDS_THRESHOLD } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
  alertContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
  headerContainer: {
    color: theme.palette.primary.grey,
    marginBottom: theme.spacing(1),
  },
}));

function getHeaderTitle(variant) {
  switch (variant) {
    case alertVariants.PEP:
      return 'Political exposure';
    case alertVariants.SANCTIONS:
      return 'Sanctions';
    case alertVariants.WARNINGS:
      return 'Warnings';
    case alertVariants.FITNESS_PROBITY:
      return 'Fitness and probity';
    case alertVariants.MEDIA_ALERT: // Old media alerts
    case alertVariants.ADVERSE_MEDIA: // Media alerts V2
      return 'Adverse media';
    default:
      return '';
  }
}

function parseExtraData(fields) {
  if (!fields) {
    return {};
  }

  return fields.reduce((prevState, field) => {
    const updatedState = { ...prevState };
    updatedState[field.name] = field.value;
    return updatedState;
  }, {});
}

function getPepClassNum(alert) {
  const sourceTypes = alert.sourceTypes || [];
  if (sourceTypes.includes('pep-class-1')) {
    return 1;
  }
  if (sourceTypes.includes('pep-class-2')) {
    return 2;
  }
  if (sourceTypes.includes('pep-class-3')) {
    return 3;
  }
  if (sourceTypes.includes('pep-class-4')) {
    return 4;
  }
  return null;
}

function sortHits(hitA, hitB) {
  const dateA = new Date(hitA.updatedAt);
  const dateB = new Date(hitB.updatedAt);
  const diffInMins = Math.abs(dateB - dateA) / 60000;
  if (Math.abs(diffInMins) > 0) {
    return dateB - dateA;
  }
  if (!hitA.listingStart && !hitB.listingStart) {
    return 0;
  }
  if (!hitB.listingStart) {
    return -1;
  }
  if (!hitA.listingStart) {
    return 1;
  }
  return new Date(hitB.listingStart) - new Date(hitA.listingStart);
}

export function AlertsDisplay({ hits = [], variant, lastReviewedAt }) {
  const classes = useStyles();
  const [showAllHits, setShowAllHits] = useState(false);

  const numHits = hits?.length || 0;
  const numHitsExceedsThreshold = numHits > NUM_CARDS_THRESHOLD;

  hits.sort(sortHits);

  const initialHits = hits.slice(0, NUM_CARDS_THRESHOLD);
  const remainingHits = hits.slice(NUM_CARDS_THRESHOLD);

  return (
    <div className={classes.root}>
      <HeaderWithSeeMoreButton
        title={getHeaderTitle(variant)}
        numHits={numHits}
        shouldExposeButton={numHitsExceedsThreshold}
        showAll={showAllHits}
        onClick={() => {
          setShowAllHits((prev) => !prev);
        }}
      />

      <div className={classes.alertContainer}>
        {initialHits.map((hit) => (
          <AlertCard
            key={hit.id}
            publishDate={hit.publishDate}
            createdAt={hit.createdAt}
            updatedAt={hit.updatedAt}
            listingStartDate={hit.listingStart}
            listingEndDate={hit.listingEnd}
            title={hit.title}
            url={hit.url}
            snippet={hit.snippet}
            variant={variant}
            pepClassNum={getPepClassNum(hit)}
            extraData={parseExtraData(hit.fields)}
            lastReviewedAt={lastReviewedAt}
          />
        ))}
      </div>

      <Collapse in={showAllHits} mountOnEnter unmountOnExit>
        <div className={classes.alertContainer}>
          {remainingHits.map((hit) => (
            <AlertCard
              key={hit.id}
              publishDate={hit.publishDate}
              createdAt={hit.createdAt}
              updatedAt={hit.updatedAt}
              listingStartDate={hit.listingStart}
              listingEndDate={hit.listingEnd}
              title={hit.title}
              url={hit.url}
              snippet={hit.snippet}
              variant={variant}
              pepClassNum={getPepClassNum(hit)}
              extraData={parseExtraData(hit.fields)}
              lastReviewedAt={lastReviewedAt}
            />
          ))}
        </div>
      </Collapse>
    </div>
  );
}
