import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
    padding: 0,
    margin: 0,
  },
}));

export const FieldContainer = ({ children }) => {
  const classes = useStyles();
  return (
    <Table className={classes.container} size="small">
      {children}
    </Table>
  );
};
