import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import CardActionArea from '@material-ui/core/CardActionArea';
import FormHelperText from '@material-ui/core/FormHelperText';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import { Alert } from 'components/alert';
import { Chip } from 'components/chip';

const useStyles = makeStyles((theme) => ({
  profileLabel: {
    padding: theme.spacing(1, 2),
    width: '100%',
  },
  formControlLabel: {
    width: '100%',
  },
  chipRoot: {
    borderRadius: theme.spacing(0.5),
    height: theme.spacing(3),
    backgroundColor: theme.palette.primary.white,
    border: `1px solid ${theme.palette.primary.grey}`,
  },
  detailContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  chipLabel: {
    padding: theme.spacing(0, 1),
  },
  wrapper: {
    transition: '0.2s',
  },
  radius: {
    width: '100%',
    borderRadius: 8,
  },
  alertContainer: {
    margin: theme.spacing(0, 2, 2, 2),
  },
  alert: {
    color: theme.palette.primary.black,
    textAlign: 'left',
  },
  nameAndLastUsed: {
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
  },
}));

function Wrapper({ children, outlined, hideRadio, ...props }) {
  const wrapperChildren = outlined ? (
    <Paper variant="outlined">{children}</Paper>
  ) : (
    children
  );

  return hideRadio ? (
    <div {...props}>{wrapperChildren}</div>
  ) : (
    <CardActionArea {...props}>{wrapperChildren}</CardActionArea>
  );
}

export const ProfileSelectItem = ({
  profileName,
  lastUsed,
  collaborators = [],
  showNameWarning = false,
  id = null,
  hideRadio = false,
  outlined = false,
}) => {
  const classes = useStyles();

  const collaboratorChipText =
    collaborators?.length === 1
      ? '1 collaborator'
      : `${collaborators?.length} collaborators`;

  const detailContainer = (
    <div className={classes.detailContainer}>
      <div className={classes.nameAndLastUsed}>
        <Typography variant="body1" component="span">
          {profileName}
        </Typography>
        <FormHelperText>{lastUsed}</FormHelperText>
      </div>
      {collaborators?.length ? (
        <Tooltip
          enterTouchDelay={200}
          leaveTouchDelay={3000}
          title={
            <div className={classes.tooltip}>
              {collaborators.map((collaborator) => {
                let collaboratorText = collaborator.email;
                if (collaborator.name) {
                  collaboratorText = `${collaborator.name} (${collaborator.email})`;
                }

                return (
                  <Typography key={collaboratorText}>
                    {collaboratorText}
                  </Typography>
                );
              })}
            </div>
          }
        >
          <span>
            <Chip
              label={collaboratorChipText}
              classes={{
                root: classes.chipRoot,
                label: classes.chipLabel,
              }}
            />
          </span>
        </Tooltip>
      ) : null}
    </div>
  );

  return (
    <Wrapper
      hideRadio={hideRadio}
      outlined={outlined}
      className={clsx(classes.wrapper, {
        [classes.radius]: outlined,
      })}
    >
      {hideRadio ? (
        <div className={classes.profileLabel}>{detailContainer}</div>
      ) : (
        <FormControlLabel
          classes={{
            label: classes.formControlLabel,
          }}
          className={classes.profileLabel}
          key={profileName}
          value={id}
          control={<Radio />}
          label={detailContainer}
          data-test={profileName}
        />
      )}
      <Collapse in={showNameWarning}>
        <div className={classes.alertContainer}>
          <Alert severity="warning" className={classes.alert}>
            The profile name does not match the investor's name.
          </Alert>
        </div>
      </Collapse>
    </Wrapper>
  );
};
