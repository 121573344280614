import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Typography } from '@passthrough/uikit';

import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export function HeaderWithCount({ title, count, className }) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <Typography variant="card-heading" size="large" color="text.primary">
        {title}
      </Typography>

      <Typography variant="label" color="text.secondary">
        {count} total
      </Typography>
    </div>
  );
}
