export const SIGNED_STATUSES = ['SIGNED', 'PARTIALLY_SIGNED'];

export const FILE_ANSWER_TYPES = [
  'AnswerType.file_upload',
  'AnswerType.multi_file_upload',
];

export const CHANGE_EVENT_TYPES = {
  answer_submitted: 'answer_submitted',
  change_request_sent: 'change_request_sent',
  change_request_resolved: 'change_request_resolved',
};

export const commentModeLpClosingStatuses = [
  'UNVIEWED',
  'VIEWED',
  'IN_PROGRESS',
  'REQUESTED_CHANGES',
];

export const QUESTIONNAIRES_SUBMITTED_STATUSES = [
  ...SIGNED_STATUSES,
  'APPROVED',
  'SENT_TO_COUNTERSIGNER',
  'COUNTERSIGNED',
];

export const NoteIconVariant = {
  LP: 'lp',
  GP: 'gp',
  INTERNAL_NOTE: 'internalNote',
};
