import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, UIKitSettingsProvider, Link } from '@passthrough/uikit';
import Tooltip from '@material-ui/core/Tooltip';
import LockIconOutlined from '@material-ui/icons/LockOutlined';

import { TruncatedTextDisplay } from 'components/truncated_text_display';
import { TruncatedListItemsDisplay } from 'pages/review_v2/truncated_list_items';
import { DILIGENCE_EVENT_TYPES } from 'services/thread_utils/constants';

const useStyles = makeStyles((theme) => ({
  rootPaper: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  dataFlexBox: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  answerDisplay: {
    marginRight: theme.spacing(16),
    flex: 1,
  },
  internalNoteEvent: {
    backgroundColor: theme.palette.background.internalNote,
    padding: theme.spacing(2),
    overflowWrap: 'anywhere',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },
  extraFilesContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(1),
    borderLeft: `1px solid ${theme.palette.divider}`,
    paddingLeft: theme.spacing(2),
  },
  lockIcon: {
    color: theme.palette.text.secondary,
  },
}));

function DiligenceTextEventDisplay({ text, listItems, isNote, className }) {
  return (
    <Paper variant="outlined" className={className}>
      {listItems ? (
        <TruncatedListItemsDisplay listItems={listItems} />
      ) : (
        <TruncatedTextDisplay
          content={text}
          expandText={isNote ? 'See full note' : 'See more'}
        />
      )}
    </Paper>
  );
}

export function DiligenceEventBody({ event }) {
  const classes = useStyles();

  // answer submission events now handled in the event header
  if (event.type === DILIGENCE_EVENT_TYPES.ANSWER_SUBMITTED) {
    return null;
  }

  const matchNameEvents = [
    DILIGENCE_EVENT_TYPES.NEW_MATCH,
    DILIGENCE_EVENT_TYPES.UPDATED_MATCH,
  ];
  if (matchNameEvents.includes(event.type)) {
    return (
      <DiligenceTextEventDisplay
        listItems={event?.potentialMatchNames || []}
        className={classes.rootPaper}
      />
    );
  }

  if (
    event.type === DILIGENCE_EVENT_TYPES.GP_SENT_COMMENT ||
    event.type === DILIGENCE_EVENT_TYPES.LP_SENT_COMMENT
  ) {
    return (
      <DiligenceTextEventDisplay
        text={event?.noteToInvestor || ''}
        isNote
        className={classes.rootPaper}
      />
    );
  }

  if (event.type === DILIGENCE_EVENT_TYPES.INTERNAL_NOTE_ADDED) {
    return (
      <Paper elevation={0} className={classes.internalNoteEvent}>
        <TruncatedTextDisplay
          content={event.internalNote}
          textColor="default"
          expandText="See full note"
          contractText="Hide full note"
        />
        <Tooltip
          title={
            <Typography variant="label">Not visible to the investor</Typography>
          }
        >
          <LockIconOutlined className={classes.lockIcon} fontSize="small" />
        </Tooltip>
      </Paper>
    );
  }

  if (event.type === DILIGENCE_EVENT_TYPES.MODIFIED_SUPPLEMENTARY_FILES) {
    return (
      <div className={classes.extraFilesContainer}>
        {event?.supplementaryFiles?.length === 0 ? (
          <Typography variant="label" color="text.secondary">
            No documents
          </Typography>
        ) : (
          <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
            {event.supplementaryFiles.map((fileData) => (
              <Link
                key={fileData.key}
                variant="external"
                href={fileData.fileUrl}
              >
                {fileData.fileName}
              </Link>
            ))}
          </UIKitSettingsProvider>
        )}
      </div>
    );
  }

  return null;
}
