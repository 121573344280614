import React, { useState } from 'react';
import { Button } from 'components/button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  buttonMargin: {
    marginTop: theme.spacing(1),
  },
}));

const NUM_LIST_ITEMS_TO_DISPLAY = 4;

export const TruncatedListItemsDisplay = ({
  listItems,
  textColor = 'default',
  expandText = 'See full list',
  contractText = 'See less',
}) => {
  const [showAll, setShowAll] = useState(false);
  const classes = useStyles();

  let truncatedItems = listItems;
  const shouldTruncate = listItems.length > NUM_LIST_ITEMS_TO_DISPLAY;
  if (shouldTruncate) {
    truncatedItems = listItems.slice(0, NUM_LIST_ITEMS_TO_DISPLAY);
  }

  const listItemsToDisplay = showAll ? listItems : truncatedItems;
  const buttonText = showAll ? contractText : expandText;

  return (
    <>
      {listItemsToDisplay.map((listItem) => (
        <div>{listItem}</div>
      ))}

      {shouldTruncate ? (
        <Button
          variant="text"
          className={classes.buttonMargin}
          color={textColor}
          onClick={(e) => {
            e.stopPropagation();
            setShowAll((prevShowState) => !prevShowState);
          }}
        >
          {buttonText}
        </Button>
      ) : null}
    </>
  );
};
