import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { LeftSidebarClaimed } from './left_sidebar_claimed';
import { LeftSidebarUnclaimed } from './left_sidebar_unclaimed';

const useStyles = makeStyles((theme) => ({
  profileName: {
    textAlign: 'center',
    padding: theme.spacing(3),
  },
}));

export const LeftSidebar = ({ profile, refresh }) => {
  const classes = useStyles();

  return (
    <Paper variant="outlined" elevation={0}>
      <div className={classes.profileName}>
        <Typography variant="h6">{profile.displayName}</Typography>
      </div>
      <Divider />
      {profile.isClaimed ? (
        <LeftSidebarClaimed profile={profile} />
      ) : (
        <LeftSidebarUnclaimed profile={profile} refresh={refresh} />
      )}
    </Paper>
  );
};
