import React, { useEffect } from 'react';

import { Typography } from '@passthrough/uikit';
import Checkbox from '@material-ui/core/Checkbox';

import FormControlLabel from '@material-ui/core/FormControlLabel';

export function OptOut({
  nodeName,
  fundName,
  optOut,
  shouldDisable,
  handleChange,
  className,
}) {
  useEffect(() => {
    if (shouldDisable) {
      handleChange('optOut', false);
    }
  }, [shouldDisable]);

  return (
    <div className={className}>
      <FormControlLabel
        control={
          <Checkbox
            disabled={shouldDisable}
            checked={optOut}
            onChange={(e) => {
              const checkVal = e.target.checked;
              handleChange('optOut', checkVal);
            }}
            name="optOut"
            data-test="diligence_v2_opt_out"
          />
        }
        label={
          <Typography>
            I do not want to provide all of the requested documents for{' '}
            {nodeName}.
            <Typography size="small" color="text.secondary">
              {fundName || 'Fund admins'} may still require these documents
              later at their discretion.
            </Typography>
          </Typography>
        }
      />
    </div>
  );
}
