import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { EmptyState } from 'components/empty_v2';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import { DataTags } from './data_tags';

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: theme.spacing(4),
  },
  sectionTitle: {
    marginBottom: theme.spacing(1),
  },
}));

export const RightSidebar = ({ profile }) => {
  const classes = useStyles();
  const tags = profile.profileTags || [];
  const noData = tags.length === 0;

  if (noData) {
    return <EmptyState title="No profile data" />;
  }

  const investorID = tags.filter((tag) => tag.tag.startsWith('investorID/'));
  const taxAndRegulatoryStatus = tags.filter((tag) =>
    tag.tag.startsWith('taxAndRegulatoryStatus/'),
  );
  const bank = tags.filter((tag) => tag.tag.startsWith('bank/'));
  const contacts = tags.filter((tag) => tag.tag.startsWith('contacts/'));
  const authorization = tags.filter((tag) =>
    tag.tag.startsWith('authorization/'),
  );
  const otherTags = tags.filter(
    (tag) =>
      !investorID.includes(tag) &&
      !taxAndRegulatoryStatus.includes(tag) &&
      !bank.includes(tag) &&
      !contacts.includes(tag) &&
      !authorization.includes(tag),
  );

  return (
    <>
      {investorID.length ? (
        <div className={classes.section}>
          <Typography variant="subtitle2" className={classes.sectionTitle}>
            Investor identification
          </Typography>
          <Paper variant="outlined">
            <List>
              <DataTags tags={investorID} />
            </List>
          </Paper>
        </div>
      ) : null}
      {taxAndRegulatoryStatus.length ? (
        <div className={classes.section}>
          <Typography variant="subtitle2" className={classes.sectionTitle}>
            Tax / regulatory status
          </Typography>
          <Paper variant="outlined">
            <List>
              <DataTags tags={taxAndRegulatoryStatus} />
            </List>
          </Paper>
        </div>
      ) : null}
      {bank.length ? (
        <div className={classes.section}>
          <Typography variant="subtitle2" className={classes.sectionTitle}>
            Banking
          </Typography>
          <Paper variant="outlined">
            <List>
              <DataTags tags={bank} />
            </List>
          </Paper>
        </div>
      ) : null}
      {contacts.length ? (
        <div className={classes.section}>
          <Typography variant="subtitle2" className={classes.sectionTitle}>
            Contact information
          </Typography>
          <Paper variant="outlined">
            <List>
              <DataTags tags={contacts} />
            </List>
          </Paper>
        </div>
      ) : null}
      {authorization.length ? (
        <div className={classes.section}>
          <Typography variant="subtitle2" className={classes.sectionTitle}>
            Authorization
          </Typography>
          <Paper variant="outlined">
            <List>
              <DataTags tags={authorization} />
            </List>
          </Paper>
        </div>
      ) : null}
      {otherTags.length ? (
        <div className={classes.section}>
          <Typography variant="subtitle2" className={classes.sectionTitle}>
            Other
          </Typography>
          <Paper variant="outlined">
            <List>
              <DataTags tags={otherTags} />
            </List>
          </Paper>
        </div>
      ) : null}
    </>
  );
};
