import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';

import { Modal } from '@passthrough/uikit';

export function useEditTimeState(totalTimeInSeconds) {
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const secondsAsInt = parseInt(seconds, 10);
  const minutesAsInt = parseInt(minutes, 10);

  const newTotalSeconds = minutesAsInt * 60 + secondsAsInt;

  const secondsOutOfRange = secondsAsInt < 0 || secondsAsInt >= 60;
  const minutesOutOfRange = minutesAsInt < 0;

  const isInvalid =
    Number.isNaN(secondsAsInt) ||
    Number.isNaN(minutesAsInt) ||
    secondsOutOfRange ||
    minutesOutOfRange;

  const resetState = () => {
    setSeconds(totalTimeInSeconds % 60);
    setMinutes(Math.floor(totalTimeInSeconds / 60));
  };

  return {
    seconds,
    minutes,
    setSeconds,
    setMinutes,
    newTotalSeconds,
    secondsOutOfRange,
    minutesOutOfRange,
    isInvalid,
    resetState,
  };
}

export function EditTimeForm({
  seconds,
  minutes,
  setSeconds,
  setMinutes,
  secondsOutOfRange,
  minutesOutOfRange,
}) {
  return (
    <>
      <TextField
        label="Minutes"
        type="number"
        value={minutes}
        variant="outlined"
        size="small"
        onChange={(e) => setMinutes(e.target.value)}
        error={minutesOutOfRange}
        helperText={minutesOutOfRange ? 'Must be greater than 0' : ''}
      />
      <TextField
        label="Seconds"
        type="number"
        value={seconds}
        variant="outlined"
        size="small"
        onChange={(e) => setSeconds(e.target.value)}
        error={secondsOutOfRange}
        helperText={secondsOutOfRange ? 'Must be between 0 and 59' : ''}
      />
    </>
  );
}

export function EditTimeModal({
  open,
  onClose,
  onSave,
  isLoading,
  totalTimeInSeconds,
}) {
  const {
    seconds,
    minutes,
    setSeconds,
    setMinutes,
    newTotalSeconds,
    secondsOutOfRange,
    minutesOutOfRange,
    isInvalid,
    resetState,
  } = useEditTimeState(totalTimeInSeconds);

  const updateButtonDisabled =
    isInvalid || newTotalSeconds === totalTimeInSeconds;

  const onSubmit = () => {
    onSave(newTotalSeconds);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      showCancelButton
      size="xs"
      headerLabel="Edit time"
      primaryButtonText="Update"
      primaryButtonDisabled={updateButtonDisabled}
      primaryButtonLoading={isLoading}
      onEntering={resetState}
      onSubmit={onSubmit}
    >
      <EditTimeForm
        seconds={seconds}
        minutes={minutes}
        setSeconds={setSeconds}
        setMinutes={setMinutes}
        secondsOutOfRange={secondsOutOfRange}
        minutesOutOfRange={minutesOutOfRange}
      />
    </Modal>
  );
}
