import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { NameAndTypeInputs } from '../../components/name_and_type_inputs';
import { SubsectionDisplay } from '../subsection_display/index';
import { JurisdictionSpecificDetails } from './jurisdiction_specific_details/index';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
}));

export function DiligenceDetailInputs({
  reuseDataChecked,
  form,
  formErrors,
  jurisdiction,
  handleChange,
  changeType,
  isRootNode,
  preventLegalNameChange,
  toLegalName,
}) {
  const classes = useStyles();

  const { name, type } = form;
  const { name: nameError, type: typeError } = formErrors;

  const extraNameProps = isRootNode
    ? { preventLegalNameChange, toLegalName }
    : {};

  return (
    <div className={classes.root}>
      <NameAndTypeInputs
        reuseDataChecked={reuseDataChecked}
        jurisdiction={jurisdiction}
        name={name}
        nameError={nameError}
        type={type}
        typeError={typeError}
        handleChange={handleChange}
        changeType={changeType}
        {...extraNameProps}
      />

      {reuseDataChecked ? null : (
        <JurisdictionSpecificDetails
          isRootNode={isRootNode}
          form={form}
          formErrors={formErrors}
          jurisdiction={jurisdiction}
          handleChange={handleChange}
        />
      )}
    </div>
  );
}

export function DetailsSubsection({
  jurisdiction,
  isRootNode,
  form,
  formErrors,
  handleChange,
  changeType,
  expanded,
  onClick,
  statusIcon,
  showVerticalBar,
  reuseDataChecked,
  preventLegalNameChange,
  toLegalName,
}) {
  return (
    <SubsectionDisplay
      label="Details"
      expanded={expanded}
      onClick={onClick}
      statusIcon={statusIcon}
      showVerticalBar={showVerticalBar}
    >
      <DiligenceDetailInputs
        reuseDataChecked={reuseDataChecked}
        form={form}
        formErrors={formErrors}
        jurisdiction={jurisdiction}
        handleChange={handleChange}
        changeType={changeType}
        isRootNode={isRootNode}
        preventLegalNameChange={preventLegalNameChange}
        toLegalName={toLegalName}
      />
    </SubsectionDisplay>
  );
}
