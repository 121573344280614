import React, { useState } from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Button, Icons, Typography } from '@passthrough/uikit';
import { Tooltip } from '@material-ui/core';

const usePdfDropdown = () => {
  const [pdfDropdownAnchorEl, setAnchorEl] = useState(null);
  const openReviewMenu = (event) => setAnchorEl(event.currentTarget);
  const handleReviewMenuClose = () => setAnchorEl(null);

  return {
    pdfDropdownAnchorEl,
    openReviewMenu,
    handleReviewMenuClose,
  };
};

const TooltipTitle = (
  <Typography variant="label">
    This document was just signed and the updated PDF is still being generated.
    This usually takes about one minute.
  </Typography>
);

export function PDFReviewDropdownButton({
  pdfCanReviewDocs,
  setPdfReviewModalDocument,
}) {
  const { pdfDropdownAnchorEl, openReviewMenu, handleReviewMenuClose } =
    usePdfDropdown();

  if (pdfCanReviewDocs.length > 1) {
    return (
      <>
        <Button
          variant="secondary"
          onClick={openReviewMenu}
          endIcon={<Icons.KeyboardArrowDown />}
        >
          Review in document
        </Button>
        <PDFReviewMenu
          pdfCanReviewDocs={pdfCanReviewDocs}
          setPdfReviewModalDocument={setPdfReviewModalDocument}
          pdfDropdownAnchorEl={pdfDropdownAnchorEl}
          handleReviewMenuClose={handleReviewMenuClose}
        />
      </>
    );
  }
  const doc = pdfCanReviewDocs[0];
  const button = (
    <Button
      variant="secondary"
      onClick={() => {
        setPdfReviewModalDocument(doc);
      }}
      color="default"
      htmlProps={{ 'data-test': 'pdf-review-button' }}
      disabled={!doc.isPdfReady}
    >
      Review in document
    </Button>
  );
  if (doc.isPdfReady) return button;
  return (
    <Tooltip title={TooltipTitle}>
      <div>{button}</div>
    </Tooltip>
  );
}

export function PDFReviewDropdownTextButton({
  pdfReviewableDocs,
  lpDocument,
  setPdfReviewModalDocument,
}) {
  const { pdfDropdownAnchorEl, openReviewMenu, handleReviewMenuClose } =
    usePdfDropdown();

  return (
    <>
      <Button
        onClick={openReviewMenu}
        variant="text"
        endIcon={<Icons.KeyboardArrowDown />}
      >
        {lpDocument.name}
      </Button>
      <PDFReviewMenu
        pdfCanReviewDocs={pdfReviewableDocs}
        setPdfReviewModalDocument={setPdfReviewModalDocument}
        pdfDropdownAnchorEl={pdfDropdownAnchorEl}
        handleReviewMenuClose={handleReviewMenuClose}
      />
    </>
  );
}

function PDFReviewMenu({
  pdfCanReviewDocs,
  setPdfReviewModalDocument,
  pdfDropdownAnchorEl,
  handleReviewMenuClose,
}) {
  return (
    <Menu
      anchorEl={pdfDropdownAnchorEl}
      keepMounted
      open={Boolean(pdfDropdownAnchorEl)}
      onClose={handleReviewMenuClose}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {pdfCanReviewDocs.map((doc) => {
        const disabled = !doc.isPdfReady;
        const menuItem = (
          <MenuItem
            key={doc.id}
            disabled={disabled}
            onClick={() => {
              setPdfReviewModalDocument(doc);
              handleReviewMenuClose();
            }}
          >
            {doc.name}
          </MenuItem>
        );
        if (!disabled) return menuItem;
        return (
          <Tooltip key={doc.id} title={TooltipTitle}>
            <div>{menuItem}</div>
          </Tooltip>
        );
      })}
    </Menu>
  );
}
