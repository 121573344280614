/* eslint-disable react/no-array-index-key */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Answers } from '../../answers';
import { SingleChoiceList, SingleChoiceItem, Actions } from '../common';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '500px',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      alignItems: 'inherit',
    },
  },
  description: {
    textAlign: 'center',
    fontSize: '0.8rem',
    marginBottom: theme.spacing(2),
  },
}));

export const InputQuestionReconciliation = ({
  lpDoc,
  activeProfile,
  QuestionPromptComponent,
  answersToReconcile,
  navRef,
  DisplayComponent,
  question,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const answer =
    value === null || value < 0 ? null : answersToReconcile[value]?.answer;
  return (
    <div className={classes.root}>
      <QuestionPromptComponent short />
      <FormHelperText className={classes.description}>
        Select an option to prefill your answer. You may make edits in the next
        step.
      </FormHelperText>
      <Answers>
        <SingleChoiceList
          value={value}
          onChange={(e) => {
            setValue(Number(e.target.value));
          }}
        >
          {answersToReconcile.map((answerToReconcile, idx) => (
            <SingleChoiceItem
              key={idx}
              value={idx}
              source={answerToReconcile.source}
              lpDoc={lpDoc}
              activeProfile={activeProfile}
            >
              <DisplayComponent
                answer={answerToReconcile.answer}
                answerType={question.answerType}
              />
            </SingleChoiceItem>
          ))}
          <SingleChoiceItem value={-1} short>
            I will provide a new answer
          </SingleChoiceItem>
        </SingleChoiceList>
        <Actions
          navRef={navRef}
          answer={answer}
          isContinueDisabled={value === null && question.isRequired}
        />
      </Answers>
    </div>
  );
};
