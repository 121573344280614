import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { Button } from 'components/button';

const useStyles = makeStyles((theme) => ({
  text: {
    margin: theme.spacing(2, 0),
  },
  topMargin: {
    marginTop: theme.spacing(1),
  },
  buttonMargin: {
    marginBottom: theme.spacing(4),
  },
}));

export function BadForm({ text, setText }) {
  const classes = useStyles();
  return (
    <div>
      <Typography
        variant="subtitle2"
        align="left"
        className={classes.topMargin}
      >
        What can we do better?
      </Typography>
      <TextField
        id="text"
        autoFocus
        variant="outlined"
        label="Feedback"
        value={text}
        onChange={(e) => {
          setText(e.target.value);
        }}
        fullWidth
        multiline
        rows={4}
        className={classes.text}
      />
      <Button
        variant="contained"
        type="submit"
        fullWidth
        className={classes.buttonMargin}
      >
        Submit
      </Button>
    </div>
  );
}
