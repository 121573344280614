import React, { useState } from 'react';
import { Modal, useConfirm } from '@passthrough/uikit';
import TextField from '@material-ui/core/TextField';
import * as api from 'services/api';
import * as urls from 'services/urls';
import { useToast } from 'services/toast';
import { useOrganization } from 'services/providers/organization';

export function DomainDialog({ open, onClose, onChange }) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [emailDomain, setEmailDomain] = useState('');
  const organizationId = urls.useOrganizationId();
  const { toast } = useToast();
  const [, , refreshOrganization] = useOrganization();
  const confirm = useConfirm();

  function onCreate(event) {
    event.preventDefault();
    if (emailDomain.startsWith('@')) {
      setErrors({ emailDomain: "Domain should not contain '@'" });
      return;
    }
    setLoading(true);
    setErrors({});
    api
      .orgApprovedDomainCreate({
        organizationId,
        emailDomain: '@'.concat(emailDomain),
      })
      .then(() => {
        setLoading(false);
        setEmailDomain('');
        refreshOrganization((c) => c + 1);
        onChange();
        onClose();
        toast(`Added ${emailDomain}`);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status === 400) {
          setErrors(error.response.data);
        }
      });
  }

  function handleClose() {
    if (!emailDomain) {
      onClose();
      return;
    }
    confirm({
      title: 'Discard unsaved changes?',
      description: 'Your changes have not been saved.',
      destructive: true,
      confirmationText: 'Discard',
    })
      .then(() => {
        setEmailDomain('');
        onClose();
      })
      .catch(() => {});
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      headerLabel="Add domain"
      primaryButtonText="Submit"
      showCancelButton
      primaryButtonLoading={loading}
      onSubmit={onCreate}
    >
      <TextField
        fullWidth
        autoFocus
        label="Domain"
        variant="outlined"
        value={emailDomain}
        onChange={(e) => setEmailDomain(e.target.value)}
        error={!!errors.emailDomain}
        helperText={errors.emailDomain}
      />
    </Modal>
  );
}
