import React from 'react';

import { useToast } from 'services/toast';
import { StateModal } from './state_modal';
import { GroupModal } from './group_modal';
import { ActionModal } from './action_modal';

import { keysToSnake, keysToCamel } from '../helpers';

export function CustomApprovalModals({
  actions,
  states,
  groups,
  actionModalObj,
  setActionModalObj,
  stateModalObj,
  setStateModalObj,
  groupModalObj,
  setGroupModalObj,
  customApprovalJson,
  setCustomApprovalJson,
  setError,
}) {
  const { toast } = useToast();

  const json = keysToCamel(customApprovalJson);
  const updateJson = (newJson) => setCustomApprovalJson(keysToSnake(newJson));

  const changeObj = (editedObj, objType) => {
    const allObjects = json[objType];
    let modalObj = null;
    if (objType === 'groups') {
      modalObj = groupModalObj;
    } else if (objType === 'states') {
      modalObj = stateModalObj;
    } else {
      modalObj = actionModalObj;
    }

    const editedObjects = allObjects.map((o) => {
      if (o.id === modalObj.id) {
        return editedObj;
      }
      return o;
    });

    updateJson({
      ...json,
      [objType]: editedObjects,
    });
  };

  const findUniqueId = (existingIds, desiredId) => {
    const matchingIds = existingIds.filter((id) => id.startsWith(desiredId));
    const maxId = matchingIds.reduce((max, id) => {
      const components = id.split('_');
      const idNum = parseInt(components[components.length - 1], 10) || 0;
      if (Number.isNaN(idNum)) {
        return max;
      }
      return Math.max(max, idNum);
    }, 0);
    return `${desiredId}_${maxId + 1}`;
  };

  const addObj = (newObj, objType) => {
    let allObjects = null;
    if (objType === 'groups') {
      allObjects = groups;
    } else if (objType === 'states') {
      allObjects = states;
    } else {
      allObjects = actions;
    }

    const allIds = allObjects.map(({ id }) => id);

    if (allIds.includes(newObj.id)) {
      // eslint-disable-next-line no-param-reassign
      newObj.id = findUniqueId(allIds, newObj.id);
    }

    updateJson({
      ...json,
      [objType]: [...allObjects, newObj],
    });
  };

  const deleteState = (deletedState) => {
    // Ensure that the state is not referenced in any actions
    const actionsUsingState = actions.filter(
      (a) => a.from_state === deletedState.id || a.to_state === deletedState.id,
    );
    if (actionsUsingState.length > 0) {
      setError(
        `Cannot delete state ${
          deletedState.id
        }. It is referenced in one or more actions: ${actionsUsingState
          .map((a) => a.id)
          .join(', ')}.`,
      );
      return;
    }

    const newStates = states.filter((state) => state.id !== deletedState.id);
    const newGroups = groups.map((g) => ({
      ...g,
      notifications: g.notifications.filter(
        (notificationState) => notificationState !== deletedState.id,
      ),
    }));
    updateJson({
      ...json,
      groups: newGroups,
      states: newStates,
    });
    toast('Deleted state');
  };

  const deleteAction = (deletedAction) => {
    const newActions = actions.filter((s) => s.id !== deletedAction.id);
    const newGroups = groups.map((g) => ({
      ...g,
      actions: g.actions.filter((a) => a !== deletedAction.id),
    }));
    updateJson({
      ...json,
      groups: newGroups,
      actions: newActions,
    });
    toast('Deleted action');
  };

  const deleteGroup = (deletedGroup) => {
    const newGroups = groups.filter((g) => g.id !== deletedGroup.id);
    updateJson({
      ...json,
      groups: newGroups,
    });
    toast('Deleted group');
  };

  const stateIds = states?.map((s) => s.id) || [];
  const actionIds = actions?.map((a) => a.id) || [];

  return (
    <>
      <ActionModal
        action={actionModalObj}
        onClose={() => {
          setActionModalObj(null);
        }}
        changeAction={(editedAction) => {
          changeObj(editedAction, 'actions');
          toast('Updated action');
        }}
        addAction={(newAction) => {
          addObj(newAction, 'actions');
          toast('Added action');
        }}
        deleteAction={deleteAction}
        stateIds={stateIds}
      />
      <StateModal
        state={stateModalObj}
        onClose={() => {
          setStateModalObj(null);
        }}
        changeState={(editedState) => {
          changeObj(editedState, 'states');
          toast('Updated state');
        }}
        addState={(newState) => {
          addObj(newState, 'states');
          toast('Added state');
        }}
        deleteState={deleteState}
      />
      <GroupModal
        group={groupModalObj}
        onClose={() => {
          setGroupModalObj(null);
        }}
        changeGroup={(editedGroup) => {
          changeObj(editedGroup, 'groups');
          toast('Updated group');
        }}
        addGroup={(newGroup) => {
          addObj(newGroup, 'groups');
          toast('Added group');
        }}
        deleteGroup={deleteGroup}
        actionIds={actionIds}
        stateIds={stateIds}
      />
    </>
  );
}
