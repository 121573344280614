import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useFeatureFlags } from 'services/providers/feature_flag';

const ACTIVE_JURISDICTIONS = [
  {
    id: 'USA_V2',
    name: 'United States',
  },
  {
    id: 'CYM',
    name: 'Cayman Islands',
  },
  {
    id: 'LUX',
    name: 'Luxembourg',
  },
];

export const JURISDICTIONS = [
  {
    id: 'SEI',
    name: 'SEI',
  },
  // deprecated version, retained for backwards compatibility
  {
    id: 'USA',
    name: 'United States',
  },
  ...ACTIVE_JURISDICTIONS,
  {
    id: 'USA_V3',
    name: 'United States',
  },
];

export function getJurisdictionDisplayName(jurisdiction) {
  return JURISDICTIONS.find((j) => j.id === jurisdiction)?.name;
}

export function JurisdictionSelect({
  jurisdiction,
  jurisdictionError,
  onChange,
}) {
  const { USA_V3_JURISDICTION } = useFeatureFlags();

  let selectableJurisdictions = [...ACTIVE_JURISDICTIONS];
  if (USA_V3_JURISDICTION) {
    selectableJurisdictions = [
      ...ACTIVE_JURISDICTIONS.filter((j) => j.id !== 'USA_V2'),
      JURISDICTIONS.find((j) => j.id === 'USA_V3'),
    ];
  }
  return (
    <>
      <FormControl
        variant="outlined"
        fullWidth
        required
        error={!!jurisdictionError}
      >
        <InputLabel id="diligence_jurisdiction">Jurisdiction</InputLabel>

        <Select
          label="Jurisdiction"
          labelId="diligenceJurisdiction"
          data-test="diligence-jurisdiction-select"
          variant="outlined"
          helperText={jurisdictionError}
          value={jurisdiction}
          onChange={onChange}
        >
          {selectableJurisdictions.map(({ id, name }) => (
            <MenuItem key={id} value={id} data-test={id}>
              {name}
            </MenuItem>
          ))}
        </Select>

        {jurisdictionError ? (
          <FormHelperText>{jurisdictionError}</FormHelperText>
        ) : null}
      </FormControl>
    </>
  );
}
