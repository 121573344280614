import React, { useState } from 'react';
import { Dialog } from 'components/dialog/index';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { StepUserList } from './step_user_list';
import { StepUserAdd } from './step_user_add';
import { StepUserRemind } from './step_user_remind';

const LIST_STEP = 0;
const ADD_COLLABORATOR_STEP = 1;
const SEND_REMINDER_STEP = 2;

const DIALOG_SIZES = {
  [LIST_STEP]: 'md',
  [ADD_COLLABORATOR_STEP]: 'sm',
  [SEND_REMINDER_STEP]: 'sm',
};

export function ProfileUsersModal({
  open,
  lpClosingId,
  profileId,
  isClaimedProfile,
  profileDisplayName,
  isProfileOwner,
  collaborators,
  getCollaborators,
  handleClose,
}) {
  const [step, setStep] = useState(LIST_STEP);
  const [collaboratorToRemind, setCollaboratorToRemind] = useState(null);

  const theme = useTheme();
  const fullScreenMobile = useMediaQuery(theme.breakpoints.down('md'));

  const onClose = () => {
    handleClose();
    setTimeout(() => setStep(LIST_STEP), 200);
  };

  const onRemind = (collaborator) => {
    setCollaboratorToRemind(collaborator);
    setStep(SEND_REMINDER_STEP);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={DIALOG_SIZES[step]}
      aria-labelledby="form-dialog-title"
      fullWidth
      fullScreen={fullScreenMobile}
    >
      {step === LIST_STEP ? (
        <StepUserList
          lpClosingId={lpClosingId}
          profileId={profileId}
          isClaimedProfile={isClaimedProfile}
          isProfileOwner={isProfileOwner}
          collaborators={collaborators}
          showUserForm={() => setStep(ADD_COLLABORATOR_STEP)}
          onUpdateAccessLevel={getCollaborators}
          onDelete={getCollaborators}
          onRemind={onRemind}
          onClose={onClose}
        />
      ) : null}

      {step === ADD_COLLABORATOR_STEP ? (
        <StepUserAdd
          lpClosingId={lpClosingId}
          profileId={profileId}
          isProfileOwner={isProfileOwner}
          profileDisplayName={profileDisplayName}
          onBack={() => setStep(LIST_STEP)}
          onSubmit={getCollaborators}
          onClose={onClose}
        />
      ) : null}

      {step === SEND_REMINDER_STEP ? (
        <StepUserRemind
          lpClosingId={lpClosingId}
          onBack={() => setStep(LIST_STEP)}
          onClose={onClose}
          collaborator={collaboratorToRemind}
        />
      ) : null}
    </Dialog>
  );
}
