/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useContext } from 'react';
import * as api from 'services/api';

const FundsContext = React.createContext();

export function useFunds() {
  return useContext(FundsContext);
}

export function FundsProvider({ ...props }) {
  const [funds, setFunds] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [change, setChange] = useState(0);

  function getFunds() {
    api.funds().then((response) => {
      setFunds(response.data);
      setIsLoading(false);
    });
  }

  useEffect(getFunds, [change]);

  function refresh() {
    setChange((c) => c + 1);
  }

  return (
    <FundsContext.Provider value={[funds, isLoading, refresh]} {...props} />
  );
}
