import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { ANSWER_STATES } from 'services/constants';
import { Typography, Icons } from '@passthrough/uikit';

import { hasLPAnswerChanged } from 'services/utils';
import { getAnswerDisplayComponent } from 'components/answer_displays/get_answer_display';

const useStyles = makeStyles((theme) => ({
  answersContainer: {
    display: 'flex',
    rowGap: theme.spacing(1),
    flexDirection: 'column',
    width: '100%',
  },
  answerChangedIndicatorButton: {
    color: theme.palette.grey[500],
    marginTop: theme.spacing(0.25),
    marginRight: theme.spacing(2),
  },
  topMargin: {
    marginTop: theme.spacing(2),
  },
  root: {
    display: 'flex',
    overflowWrap: 'anywhere',
  },
  doesNotApply: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  doesNotApplyIcon: {
    height: '18px',
    width: '18px',
    color: theme.palette.text.secondary,
  },
}));

export function QuestionAnswer({
  question,
  priorAnswerTypeOverride,
  priorAnswer,
  enableAnswerFormatting,
  isReviewMode,
  className,
  noBold,
}) {
  const classes = useStyles();

  const PriorAnswerComponent = getAnswerDisplayComponent(
    priorAnswerTypeOverride || question.answerType,
  );
  const AnswerComponent = getAnswerDisplayComponent(question.answerType);

  const showChangedAnswers =
    hasLPAnswerChanged(priorAnswer, question) && isReviewMode;
  const shouldDrawAttention = showChangedAnswers && enableAnswerFormatting;

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.answersContainer}>
        {showChangedAnswers && priorAnswer ? (
          <Typography>
            <PriorAnswerComponent
              answer={priorAnswer}
              questionState={ANSWER_STATES.CHANGED_PRIOR_ANSWER}
            />
          </Typography>
        ) : null}

        {!(question.answer === null || question.answer === undefined) ? (
          <Typography fontWeight={noBold ? '400' : '500'}>
            <AnswerComponent
              answer={question.answer}
              questionState={
                shouldDrawAttention
                  ? ANSWER_STATES.CHANGED_CURRENT_ANSWER
                  : ANSWER_STATES.UNCHANGED
              }
            />
          </Typography>
        ) : null}
        {!question.isAsked ? (
          <div className={classes.doesNotApply}>
            <Icons.NotInterested className={classes.doesNotApplyIcon} />
            <Typography size="small" color="text.secondary">
              Does not apply to investor
            </Typography>
          </div>
        ) : null}
      </div>
    </div>
  );
}
