export const FILTER_ALL = 'ALL';
export const FILTER_IN_PROGRESS = 'IN_PROGRESS';
export const FILTER_FULLY_EXECUTED = 'FULLY_EXECUTED';

export const CLOSING_FILTERS = [
  { key: FILTER_ALL, display: 'All' },
  { key: FILTER_IN_PROGRESS, display: 'In progress' },
  { key: FILTER_FULLY_EXECUTED, display: 'Fully executed' },
];

export const NAME_COLUMN = 'name';
export const CLIENT_MATTER_NUMBER_COLUMN = 'clientMatterNumber';
export const NEXT_DATE_COLUMN = 'nextDate';
export const PROGRESS_COLUMN = 'progress';
export const MEMBERS_COLUMN = 'members';

export const DEFAULT_COLUMNS = [NAME_COLUMN, NEXT_DATE_COLUMN, PROGRESS_COLUMN];
