import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { Typography } from '@passthrough/uikit';

import { HeaderWithCount } from 'components/header_with_count_v2';
import { ReviewCard } from '../review_card/index';

const useStyles = makeStyles((theme) => ({
  bottomSpacing: {
    marginBottom: theme.spacing(2.5),
  },
}));

export function PendingCardsReview({
  pendingEntries,
  onShowReviewClick,
  areSearchesReady,
  isInProgress,
}) {
  const classes = useStyles();

  return (
    <>
      <HeaderWithCount title="Pending review" count={pendingEntries.length} />

      <div className={classes.bottomSpacing}>
        <Typography variant="body" size="small" color="text.secondary">
          Review all owners (individuals and entities) associated with this
          investor.
        </Typography>
      </div>

      <List disablePadding>
        {pendingEntries.map((question) => (
          <ListItem disableGutters key={question.label}>
            <ReviewCard
              question={question}
              onShowReviewClick={onShowReviewClick}
              areSearchesReady={areSearchesReady}
              isInProgress={isInProgress}
            />
          </ListItem>
        ))}
      </List>
    </>
  );
}
