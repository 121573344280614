import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  header: {
    margin: 0,
    padding: theme.spacing(2, 3),
    backgroundColor: theme.palette.table.header,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  headerText: {
    fontWeight: '500',
  },
  root: {
    marginBottom: theme.spacing(2),
  },
}));

export function PaperHeader({ headerText, className, children }) {
  const classes = useStyles();

  return (
    <Paper variant="outlined" className={clsx(classes.root, className)}>
      <div className={classes.header}>
        <Typography className={classes.headerText}>{headerText}</Typography>
      </div>

      {children}
    </Paper>
  );
}
