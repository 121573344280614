import React from 'react';
import { UIKitSettingsProvider, MenuItem, Icons } from '@passthrough/uikit';
import * as api from 'services/api';
import { useToast } from 'services/toast';
import { TraySolutionCard } from './tray_solution_card';
import { IntegrationCardDialog } from './integration_card_dialog';

export const IntegrationCard = ({ card, organizationId }) => {
  const [open, setOpen] = React.useState(false);
  const [variant, setVariant] = React.useState(
    card.configured ? 'active' : 'available',
  );
  const { toast, errorToast } = useToast();
  return (
    <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
      <IntegrationCardDialog
        open={open}
        onClose={() => setOpen(false)}
        card={card}
        organizationId={organizationId}
        onConfirm={() => setVariant('active')}
        variant={variant === 'active' ? 'regenerate' : 'connect'}
      />
      <TraySolutionCard
        hideManage
        solution={{
          name: card.name,
          logoUrl: card.logoUrl,
          description: card.description,
        }}
        variant={variant}
        helpUrl={card.helpUrl}
        connectLabel=""
        connect={() =>
          new Promise((resolve) => {
            setOpen(true);
            resolve();
          })
        }
        remove={() =>
          api
            .deactivateIntegrationCard({ organizationId, cardId: card.id })
            .then(() => {
              setVariant('available');
              toast('Integration deleted.');
            })
            .catch(() => {
              errorToast('Failed to delete integration.');
            })
        }
        extraActions={
          <MenuItem
            text="Regenerate API key"
            icon={<Icons.Refresh fontSize="small" />}
            onClick={() => setOpen(true)}
          />
        }
      />
    </UIKitSettingsProvider>
  );
};
