import React from 'react';
import { FieldContainer, Field } from './fields';

export const NameWithTitleDisplay = ({ answer }) => (
  <FieldContainer>
    <Field label="First name" labelMinWidth={90}>
      {answer?.firstName || '-'}
    </Field>
    <Field label="Last name">{answer?.lastName || '-'}</Field>
    <Field label="Title">{answer?.title || '-'}</Field>
  </FieldContainer>
);
