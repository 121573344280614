import React, { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, useConfirm } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import FormHelperText from '@material-ui/core/FormHelperText';

import { TypeDisplay } from 'components/type_display';
import { TypeInput } from 'components/lp_doc/diligence/components/name_and_type_inputs';
import { DataValueDisplay } from 'components/data_value_display';

import RepeatIcon from '@material-ui/icons/Repeat';
import {
  questionHasAdditionalDataThatWillBeLost,
  genDataTestLabel,
  getNonAncestorNodes,
} from '../utils';
import { NameAutocomplete } from './name_autocomplete';
import { getQuestionsAsNodes } from '../tree_nav_v2/node';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
  interactiveContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
  ownerTypeLabel: {
    color: theme.palette.text.primary,
    textAlign: 'left',
    whiteSpace: 'pre-wrap',
  },
  helpText: {
    marginTop: theme.spacing(0),
    whiteSpace: 'pre-wrap',
  },
  addBtn: {
    display: 'flex',
    justifyContent: 'left',
  },
  childInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.spacing(1),
    alignItems: 'center',
    position: 'relative',
  },
  halfFlex: {
    flex: '50%',
  },
  viewOnlyData: {
    backgroundColor: theme.palette.neutral.fill,
    padding: theme.spacing(1, 2),
    display: 'flex',
    borderRadius: theme.spacing(1),
    flexDirection: 'row',
    columnGap: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
  },
  nameAndTypeContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.spacing(1),
    alignItems: 'center',
    width: '100%',
    maxWidth: '100%',
  },
  repeatIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.neutral.icon,
    position: 'absolute',
    left: -theme.spacing(4),
  },
}));

function ChildInput({
  id,
  name,
  type,
  onChange,
  onChangeType,
  onDelete,
  jurisdiction,
  dataTestBaseLabel,
  disableEdit = false,
  nodeChoices,
  idToReuse,
}) {
  const classes = useStyles();
  const containerRef = useRef();
  return (
    <div className={classes.childInputContainer} ref={containerRef}>
      {idToReuse ? <RepeatIcon className={classes.repeatIcon} /> : null}

      {disableEdit ? (
        <div className={classes.viewOnlyData}>
          <span className={classes.halfFlex}>
            <DataValueDisplay label="Full legal name" value={name || ''} />
          </span>

          <span className={classes.halfFlex}>
            <DataValueDisplay
              label="Type"
              value={<TypeDisplay type={type} />}
            />
          </span>
        </div>
      ) : (
        <>
          <NameAutocomplete
            id={id}
            type={type || ''}
            nodeChoices={nodeChoices}
            onChange={onChange}
            containerRef={containerRef}
            dataTestBaseLabel={dataTestBaseLabel}
          />

          <TypeInput
            type={type}
            jurisdiction={jurisdiction}
            onChange={(e) => {
              onChangeType({ id, name, type: e.target.value });
            }}
            dataTestLabelOverride={`${dataTestBaseLabel}_select_type`}
          />
        </>
      )}

      <Button variant="icon" aria-label="Delete" onClick={onDelete}>
        <DeleteIcon />
      </Button>
    </div>
  );
}

export function NameList({
  names,
  namesError,
  diligenceQuestions,
  ownerType,
  onChange,
  formLabel,
  label,
  helpText,
  jurisdiction,
  namesToDisableEdit = [],
  confirmDeletions = true,
  deletedNames,
  setDeletedNames,
}) {
  const classes = useStyles();
  const confirm = useConfirm();
  const dataTestOwnerLabel = genDataTestLabel(ownerType);
  const lowercasedOwnerType = ownerType ? ownerType.toLowerCase() : '';
  const buttonLabel = `Add ${lowercasedOwnerType}`;
  const nameIdsToDisableEdit = namesToDisableEdit.map((name) => name.id);

  const structuredNodesRoot = getQuestionsAsNodes(
    diligenceQuestions[0],
    diligenceQuestions,
    jurisdiction,
  );

  const nonAncestorNodes = getNonAncestorNodes(
    structuredNodesRoot,
    diligenceQuestions,
    jurisdiction,
    label,
  ).map((node) => ({
    id: node.question?.label,
    name: node.question?.answer?.name,
    type: node.question?.answer?.type,
    idToReuse: node.question?.answer?.idToReuse || null,
  }));

  const nodeChoices = nonAncestorNodes
    .filter((node) => node.idToReuse === null)
    .filter((node) => !deletedNames?.includes(node.id));

  function addName() {
    // NOTE - this is the code responsible for generating diligence subquestion ids
    onChange([...names, { name: '', id: uuidv4(), type: '' }]);
  }

  function deleteName(id) {
    const hasAdditionalDataThatWillBeLost =
      questionHasAdditionalDataThatWillBeLost(diligenceQuestions, id);

    const childIds =
      nonAncestorNodes
        .find((node) => node.question?.label === id)
        ?.getAllChildren()
        .map((node) => node.question?.label) || [];

    if (hasAdditionalDataThatWillBeLost && confirmDeletions) {
      confirm({
        title: `Delete this ${ownerType.toLowerCase()}?`,
        description: `The information and documents for this ${ownerType.toLowerCase()}, as well as their respective owners, will also be deleted.`,
        destructive: true,
        confirmationText: 'Delete',
      })
        .then(() => {
          const newNames = names.filter((name) => name.id !== id);
          onChange(newNames);
        })
        .catch(() => {});
    } else {
      const newNames = names.filter((name) => name.id !== id);
      onChange(newNames);
    }
    setDeletedNames([...deletedNames, id, ...childIds]);
  }

  function replaceName(id, e) {
    // changing a node's name will never remove its other data points
    const newNames = names.map((name) => (name.id === id ? e : name));
    onChange(newNames);
  }

  function replaceType(id, e) {
    const hasAdditionalDataThatWillBeLost =
      questionHasAdditionalDataThatWillBeLost(diligenceQuestions, id);

    if (hasAdditionalDataThatWillBeLost) {
      confirm({
        title: `Change type?`,
        description: `Changing ${e.name}'s type will discard all of their existing information and documents, as well as all data on their respective owners.`,
        destructive: true,
        confirmationText: 'Change type',
      })
        .then(() => {
          const newOwnerData = names.map((name) => (name.id === id ? e : name));
          onChange(newOwnerData);
        })
        .catch(() => {});
    } else {
      const newOwnerData = names.map((name) => (name.id === id ? e : name));
      onChange(newOwnerData);
    }
  }

  function getType(nodeId) {
    const reusingNode = nonAncestorNodes.find((node) => node.id === nodeId);
    if (reusingNode?.idToReuse) {
      return getType(reusingNode.idToReuse);
    }
    return reusingNode?.type || '';
  }

  return (
    <div className={classes.root}>
      <FormLabel
        className={classes.ownerTypeLabel}
        component="legend"
        error={!!namesError}
      >
        {formLabel}
      </FormLabel>

      {helpText ? (
        <FormHelperText className={classes.helpText}>{helpText}</FormHelperText>
      ) : null}

      <div className={classes.interactiveContentContainer}>
        {names.map((name, i) => (
          <React.Fragment key={name.id}>
            <ChildInput
              id={name.id}
              disableEdit={nameIdsToDisableEdit.includes(name.id)}
              name={name.name}
              nodeChoices={nodeChoices}
              idToReuse={
                nonAncestorNodes.find((node) => node.id === name.id)?.idToReuse
              }
              type={name.type || getType(name.idToReuse)}
              onChange={(e) => {
                replaceName(name.id, e);
              }}
              onDelete={() => {
                deleteName(name.id);
              }}
              onChangeType={(e) => {
                replaceType(name.id, e);
              }}
              jurisdiction={jurisdiction}
              dataTestBaseLabel={`diligence_v2_${dataTestOwnerLabel}_${i}`}
            />

            {namesError?.[i]?.name ? (
              <FormHelperText error>{namesError[i].name}</FormHelperText>
            ) : null}

            {namesError?.[i]?.type ? (
              <FormHelperText error>{namesError[i].type}</FormHelperText>
            ) : null}
          </React.Fragment>
        ))}

        <div className={classes.addBtn}>
          <Button
            startIcon={<AddIcon />}
            onClick={addName}
            variant="secondary"
            data-test={`diligence_v2_add_${dataTestOwnerLabel}`}
          >
            {buttonLabel}
          </Button>
        </div>
      </div>
    </div>
  );
}
