import React from 'react';
import NumberFormat from 'react-number-format';

export function NonNegativeCurrencyInput({ inputRef, ...props }) {
  return (
    <NumberFormat
      thousandsGroupStyle="thousand"
      decimalSeparator="."
      getInputRef={inputRef}
      displayType="input"
      type="text"
      thousandSeparator
      allowLeadingZeros={false}
      allowNegative={false}
      decimalScale={2}
      {...props}
    />
  );
}
