import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'components/button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'components/dialog/index';

const useStyles = makeStyles(() => ({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  hidden: { visibility: 'hidden' },
  blurForPrivacy: {
    backdropFilter: 'blur(8px)',
  },
}));

export const SessionTimeoutDialog = ({ open, handleClose, userId }) => {
  const classes = useStyles();
  const history = useHistory();

  const location = useLocation();
  const urlToReturnTo = location.pathname;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={classes.blurForPrivacy}
    >
      <div className={classes.titleContainer}>
        <IconButton className={classes.hidden}>
          <CloseIcon />
        </IconButton>
        <DialogTitle>Your session has timed out</DialogTitle>
        <IconButton onClick={handleClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        <Typography component="p">
          For your security, your session has timed out due to inactivity. You
          may close this message to copy any text you have entered.
        </Typography>
        <br />
        <Typography component="p">
          To continue, please sign in again.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          fullWidth
          onClick={() =>
            history.push(`/signin/${userId}/?next=${urlToReturnTo}`)
          }
        >
          Sign in
        </Button>
      </DialogActions>
    </Dialog>
  );
};
