import React from 'react';

import { getAnswerDisplayComponent } from 'components/answer_displays/get_answer_display';

export const SimpleDisplay = ({ answer, answerType }) => {
  const AnswerComponent = getAnswerDisplayComponent(answerType);

  return (
    <span>
      <AnswerComponent answer={answer} />
    </span>
  );
};
