import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import * as constants from './constants';
import { boxCanToggleRequiredStatus } from './utils';
import { Box } from './box';
import { getHeightFromBoxType, getWidthFromBoxType } from './box_type';

export function EditTool({
  state,
  actions,
  scale,
  selectedSigner,
  selectedBoxType,
  setSelectedBoxType,
  page,
  docWidth,
  docHeight,
  pageContainerRef,
}) {
  const { boxes, activeBox, boxToPlace } = state;

  const getCoordinatesForNewBox = (mouseDownEvent, { boxWidth, boxHeight }) => {
    const pageContainerDiv = pageContainerRef.current;
    const rect = pageContainerDiv.getBoundingClientRect();

    const origCoordinates = {
      x:
        (mouseDownEvent.clientX - rect.left + pageContainerDiv.scrollLeft) /
        scale,
      y:
        (mouseDownEvent.clientY - rect.top + pageContainerDiv.scrollTop) /
        scale,
    };

    if (origCoordinates.x < 0 || origCoordinates.y < 0) {
      return { x: null, y: null };
    }

    const rightBound = docWidth - boxWidth;
    const bottomBound = docHeight - boxHeight;

    return {
      x: Math.min(Math.max(0, origCoordinates.x), rightBound),
      y: Math.min(Math.max(0, origCoordinates.y), bottomBound),
    };
  };

  // Handle click events on the page container to place boxes on the page.
  useEffect(() => {
    const pageContainerDiv = pageContainerRef.current;
    if (!pageContainerDiv) {
      return undefined;
    }

    const handleMouseDown = (event) => {
      // Is there a box that needs to be placed?
      if (boxToPlace) {
        const { x, y } = getCoordinatesForNewBox(event, {
          boxWidth: boxToPlace.width,
          boxHeight: boxToPlace.height,
        });

        const newBox = {
          ...boxToPlace,
          x,
          y,
          page,
        };

        if (x && y) {
          actions.placeBox(newBox);
          return;
        }
      }

      // If a box is selected, deselect it. Don't create a new box if deselecting
      // the active box.
      if (activeBox) {
        actions.setActiveBox(null);
        return;
      }

      // Otherwise, try to create a new box
      if (selectedSigner !== null && selectedBoxType !== null) {
        const defaultIsRequired = boxCanToggleRequiredStatus(selectedBoxType);

        const boxWidth = getWidthFromBoxType(selectedBoxType);
        const boxHeight = getHeightFromBoxType(selectedBoxType);
        const { x, y } = getCoordinatesForNewBox(event, {
          boxWidth,
          boxHeight,
        });

        if (x && y) {
          const id = uuidv4();
          const newBox = {
            id,
            x,
            y,
            page,
            width: boxWidth,
            height: boxHeight,
            type: selectedBoxType,
            signer: selectedSigner,
            required: defaultIsRequired,
            investorType: constants.INVESTOR_TYPE_ALL,
            placeholder: '',
          };
          actions.addBox(newBox);
          setSelectedBoxType(null);
        }
      }
    };

    pageContainerDiv.addEventListener('mousedown', handleMouseDown);
    return () => {
      pageContainerDiv.removeEventListener('mousedown', handleMouseDown);
    };
  }, [
    activeBox,
    boxToPlace,
    scale,
    selectedSigner,
    selectedBoxType,
    page,
    docWidth,
    docHeight,
    actions,
    pageContainerRef.current,
  ]);

  const boxesOnPage = boxes.filter((b) => b.page === page);
  return (
    <>
      {boxesOnPage.map((b) => (
        // The dragging/resizing logic is implemented by the Box component
        <Box
          key={b.id}
          box={b}
          state={state}
          actions={actions}
          scale={scale}
          docWidth={docWidth}
          docHeight={docHeight}
        />
      ))}
    </>
  );
}
