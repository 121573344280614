import React from 'react';
import { getAnswerDisplayComponent } from 'components/answer_displays/get_answer_display';
import { FieldContainer, Field } from './fields';

export const ContactDisplay = ({ answer }) => {
  const AddressAnswerComponent =
    getAnswerDisplayComponent('AnswerType.address');

  return (
    <FieldContainer>
      <Field label="First name" labelMinWidth={90}>
        {answer?.firstName || '-'}
      </Field>
      <Field label="Last name">{answer?.lastName || '-'}</Field>
      <Field label="Email">{answer?.email || '-'}</Field>
      <Field label="Phone">{answer?.phone || '-'}</Field>
      <Field label="Fax">{answer?.fax || '-'}</Field>
      <Field label="Address">
        {answer?.googleAddress || answer?.manualAddress ? (
          <AddressAnswerComponent
            answer={answer.googleAddress || answer.manualAddress}
          />
        ) : (
          '-'
        )}
      </Field>
    </FieldContainer>
  );
};
