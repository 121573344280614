import * as React from 'react';
import { Modal, Typography } from '@passthrough/uikit';

export function DiscardAlert({ open, onClose, onDiscard }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      headerLabel="Discard unsaved changes?"
      size="xs"
      primaryButtonText="Discard"
      destructive
      onSubmit={onDiscard}
      showCancelButton
    >
      <Typography variant="body">Your changes have not been saved.</Typography>
    </Modal>
  );
}
