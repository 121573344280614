import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AccountOutlineIcon from 'mdi-material-ui/AccountOutline';
import MdiDomainIcon from 'mdi-material-ui/Domain';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
}));

export function TypeDisplay({ type, icon }) {
  const classes = useStyles();
  const TypeIcon = type === 'Individual' ? AccountOutlineIcon : MdiDomainIcon;

  if (!type) {
    return null;
  }

  return (
    <span className={classes.root}>
      {icon || <TypeIcon fontSize="inherit" />}
      <span>{type}</span>
    </span>
  );
}
