import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { formatProfileLastUsed } from 'services/utils';
import { TextChip, TextChipVariants } from 'components/text_chip';
import { Button } from 'components/button';
import { Table } from 'components/table/index';
import { Spinner } from 'components/spinner';
import { ProfileSelectItem } from 'components/profile_select_item';
import { Logo } from 'components/logo';
import {
  SELECT_PROFILE_STEP,
  ROLE_STEP,
  ACCESS_STEP,
  LOADING_STEP,
  IS_INVESTOR_CHOICE,
  NOT_INVESTOR_CHOICE,
} from './constants';
import { ProfilePage } from './profile_page';
import { ReviewSection } from './review_section';
import { normalizeName } from '../helpers';
import {
  ProfileCollaboratorTableHead,
  ProfileTableBody,
} from '../profile_collaborator_table';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(4, 10),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4, 2),
    },
  },
  startFromScratchTitle: {
    fontWeight: 400,
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(24),
  },
  displayName: {
    fontWeight: 500,
  },
  textPaper: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    width: '100%',
    gap: theme.spacing(1),
  },
  confirmButton: {
    marginTop: theme.spacing(2),
  },
  tableDiv: {
    width: '100%',
    overflowX: 'auto',
  },
  mobilePaper: {
    display: 'table',
    width: '100%',
    borderRadius: 0,
  },
}));

export function ReviewStep({
  fundName,
  fundLogo,
  lpName,
  setNavStep,
  profileData,
  isInvestorChoice,
  setupCollaborators,
  onSubmit,
  hideRoleStep,
  isLoading,
  isSubmitting,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const shouldSkipArtificialLoading =
    isInvestorChoice === NOT_INVESTOR_CHOICE || profileData.isNew;

  const onContinueClick = () => {
    if (shouldSkipArtificialLoading) {
      onSubmit();
    } else {
      setNavStep(LOADING_STEP);
    }
  };

  const showNameWarning =
    normalizeName(profileData.displayName) !== normalizeName(lpName);

  const reviewContents = (
    <>
      <ReviewSection
        headerText="Profile"
        onEditClick={() => setNavStep(SELECT_PROFILE_STEP)}
      >
        {profileData.isNew ? (
          <Paper variant="outlined" className={classes.textPaper}>
            <Typography>{lpName}</Typography>
            <TextChip label="New profile" variant={TextChipVariants.APPROVED} />
          </Paper>
        ) : (
          <ProfileSelectItem
            profileName={profileData.displayName}
            lastUsed={formatProfileLastUsed(
              profileData.lastUsedDatetime,
              profileData.lastUsedFundName,
            )}
            collaborators={profileData.collaborators}
            showNameWarning={showNameWarning}
            hideRadio
            outlined
          />
        )}
      </ReviewSection>

      {hideRoleStep ? null : (
        <ReviewSection
          headerText="Your role"
          onEditClick={() => setNavStep(ROLE_STEP)}
        >
          <Paper variant="outlined" className={classes.textPaper}>
            {isInvestorChoice === IS_INVESTOR_CHOICE ? (
              <>
                <Typography align="left">
                  The investor, or an authorized representative of the investor
                </Typography>
                <Tooltip
                  title={
                    <Typography>
                      You will have full access to manage this profile.
                    </Typography>
                  }
                >
                  <InfoOutlinedIcon fontSize="small" />
                </Tooltip>
              </>
            ) : (
              <Typography align="left">
                No, I am neither the investor, nor an authorized representative
                of the investor
              </Typography>
            )}
          </Paper>
        </ReviewSection>
      )}

      <ReviewSection
        headerText="Access"
        onEditClick={() => setNavStep(ACCESS_STEP)}
        readOnly={isInvestorChoice === NOT_INVESTOR_CHOICE}
      >
        <div className={classes.tableDiv}>
          <Table noTopMargin>
            {isMobile ? (
              <Paper className={classes.mobilePaper}>
                <ProfileCollaboratorTableHead />
                <ProfileTableBody
                  allCollaborators={setupCollaborators}
                  setAccessLevel={null}
                  onDelete={null}
                  readOnly
                />
              </Paper>
            ) : (
              <>
                <ProfileCollaboratorTableHead />
                <ProfileTableBody
                  allCollaborators={setupCollaborators}
                  setAccessLevel={null}
                  onDelete={null}
                  readOnly
                />
              </>
            )}
          </Table>
        </div>
      </ReviewSection>
    </>
  );

  return (
    <ProfilePage>
      <div className={classes.content}>
        {fundLogo ? <Logo src={fundLogo} /> : null}

        <Typography align="center" gutterBottom>
          {fundName}
        </Typography>

        <Typography
          className={classes.startFromScratchTitle}
          align="center"
          gutterBottom
        >
          Review your selections for{' '}
          <span className={classes.displayName}>{lpName}</span>
        </Typography>

        {isLoading ? <Spinner fullScreen /> : reviewContents}

        <Button
          variant="contained"
          onClick={onContinueClick}
          className={classes.confirmButton}
          loading={isSubmitting}
          data-test="confirm_and_start_questionnaire"
        >
          Confirm and start questionnaire
        </Button>
      </div>
    </ProfilePage>
  );
}
