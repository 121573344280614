import { format, parseISO } from 'date-fns';
import { reducerActionTypes, noteFlags } from './constants';

function initializeData(onboardingLogs) {
  const notes = {};
  for (let i = 0; i < onboardingLogs.length; i += 1) {
    const currLog = onboardingLogs[i];
    const { questionNotes } = currLog;
    for (let j = 0; j < questionNotes.length; j += 1) {
      const currNote = questionNotes[j];
      const timeStr = format(parseISO(currNote.createdAt), 'd LLL yyyy h:mm a');
      const allData = {
        note_id: currNote.id,
        note: currNote.note,
        flag: currNote.flag,
        severity: currNote.severity,
        resolved: currNote.resolved,
        log_id: currLog.id,
        userName: currNote.userName,
        userId: currNote.userId,
        state: currLog.state,
        timeStr,
        response_to: currNote.responseTo,
      };
      if (notes[questionNotes[j].qid]) {
        notes[questionNotes[j].qid].push(allData);
      } else {
        notes[questionNotes[j].qid] = [allData];
      }
    }
  }
  return notes;
}

export function noteDataReducer(state, action) {
  switch (action.type) {
    case reducerActionTypes.INITIALIZE_DATA: {
      return initializeData(action.onboardingLogs, action.user);
    }
    case reducerActionTypes.UPDATE_NOTE: {
      const { qid, note, questionNoteId, user, currState, createResolvedNote } =
        action;
      const currArray = state[qid];
      const timeStr = format(parseISO(note.updatedAt), 'd LLL yyyy h:mm a');
      for (let i = 0; i < currArray.length; i += 1) {
        if (currArray[i].note_id === questionNoteId) {
          const oldData = currArray[i];
          currArray[i] = {
            ...oldData,
            note: note.note,
            flag: note.flag,
            severity: note.severity,
            resolved: note.resolved,
          };
        }
      }
      if (createResolvedNote) {
        const newText = note.resolved
          ? 'resolved the note'
          : 'unresolved the note';
        const newFlag = note.resolved
          ? noteFlags.RESOLVE_NOTE
          : noteFlags.UNRESOLVE_NOTE;
        const newNote = {
          userName: `${user.firstName} ${user.lastName}`,
          note: newText,
          flag: newFlag,
          severity: null,
          resolved: true,
          state: currState,
          timeStr,
          response_to: questionNoteId,
        };
        currArray.unshift(newNote);
      }

      return {
        ...state,
        [qid]: currArray,
      };
    }
    case reducerActionTypes.CREATE_NOTE: {
      const { qid, note, currState, user, logId, createdAt, originalId } =
        action;
      const timeStr = format(parseISO(createdAt), 'd LLL yyyy h:mm a');

      const allData = {
        ...note,
        state: currState,
        log_id: logId,
        userName: `${user.firstName} ${user.lastName}`,
        userId: user.id,
        timeStr,
        response_to: originalId,
      };

      let currArray = [allData];
      if (state[qid]) {
        currArray = state[qid];
        currArray.unshift(allData);
      }

      if (originalId) {
        const originalArray = state[qid];
        for (let i = 0; i < originalArray.length; i += 1) {
          if (originalArray[i].note_id === originalId) {
            originalArray[i].resolved = true;
          }
        }

        return {
          ...state,
          [qid]: currArray,
          [originalId]: originalArray,
        };
      }

      return {
        ...state,
        [qid]: currArray,
      };
    }
    case reducerActionTypes.DELETE_NOTE: {
      const { qid, questionNoteId } = action;
      const currArray = state[qid];

      const newArray = currArray.filter(
        (note) => note.note_id !== questionNoteId,
      );

      return {
        ...state,
        [qid]: newArray,
      };
    }
    case reducerActionTypes.MOVE_NOTES: {
      const { fromQuestionId, toQuestionId } = action;

      const { [fromQuestionId]: fromQuestionNotes, ...rest } = state;
      if (!fromQuestionNotes) {
        return rest;
      }

      return {
        ...rest,
        [toQuestionId]: fromQuestionNotes.map((note) => ({
          ...note,
          qid: toQuestionId,
        })),
      };
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}
