import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { Alert } from 'components/alert';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  error: {
    marginBottom: theme.spacing(1),
  },
}));

export function TemplateQuestionnaireTypeahead({
  options,
  errorMessage,
  setSelectedTemplateQuestionnaireId,
}) {
  const classes = useStyles();

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
  });

  const getQuestionnaireOptionLabel = (questionnaire) =>
    `${questionnaire.organizationName} - ${questionnaire.fundName}, ${questionnaire.name}`;

  return (
    <div className={classes.root}>
      {errorMessage ? (
        <Alert severity="error" className={classes.error}>
          {errorMessage}
        </Alert>
      ) : null}
      <Autocomplete
        id="preexisting-questionnaire-selection"
        options={options}
        filterOptions={filterOptions}
        onChange={(event, newVal) => {
          setSelectedTemplateQuestionnaireId(newVal?.id);
        }}
        getOptionLabel={getQuestionnaireOptionLabel}
        renderInput={(params) => (
          <TextField
            variant="outlined"
            {...params}
            label="Questionnaire template"
          />
        )}
      />
    </div>
  );
}
