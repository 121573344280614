import React from 'react';
import { InputQuestionReconciliation } from './pages/input_question_reconciliation';
import { ChoiceQuestionReconciliation } from './pages/choice_question_reconciliation';
import { MultiSelectQuestionReconciliation } from './pages/multi_select_question_reconciliation';
import { BooleanQuestionReconciliation } from './pages/boolean_question_reconciliation';
import { FileQuestionReconciliation } from './pages/file_question_reconciliation';
import { MultiFileQuestionReconciliation } from './pages/multi_file_question_reconciliation';
import { AddressDisplay } from './answer_displays/address';
import { ContactDisplay } from './answer_displays/contact';
import { ContactNameDisplay } from './answer_displays/contact_name';
import { CrsTaxDetailsV2Display } from './answer_displays/crs_tax_details_v2';
import { NameWithTitleDisplay } from './answer_displays/name_with_title';
import { NameTitleEmailDisplay } from './answer_displays/name_title_email';
import { ContactNameAndEmailDisplay } from './answer_displays/contact_name_and_email';
import { BankDisplay } from './answer_displays/bank';
import { BankWithAddressDisplay } from './answer_displays/bank_with_address';
import { BankV2Display } from './answer_displays/bank_v2';
import { SimpleDisplay } from './answer_displays/simple';

const INPUT_TYPE = {
  'AnswerType.address': AddressDisplay,
  'AnswerType.bank': BankDisplay,
  'AnswerType.bank_with_address': BankWithAddressDisplay,
  'AnswerType.bank_v2': BankV2Display,
  'AnswerType.name': ContactNameDisplay,
  'AnswerType.name_with_title': NameWithTitleDisplay,
  'AnswerType.name_required_title': NameWithTitleDisplay,
  'AnswerType.name_title_email': NameTitleEmailDisplay,
  'AnswerType.contact_name_and_email': ContactNameAndEmailDisplay,
  'AnswerType.contact': ContactDisplay,
  'AnswerType.contact_with_required_address': ContactDisplay,
  'AnswerType.crs_tax_details_v2': CrsTaxDetailsV2Display,
  'AnswerType.text': SimpleDisplay,
  'AnswerType.text_long': SimpleDisplay,
  'AnswerType.tax_id': SimpleDisplay,
  'AnswerType.tax_id_with_escape': SimpleDisplay,
  'AnswerType.ssn': SimpleDisplay,
  'AnswerType.ssn_with_escape': SimpleDisplay,
  'AnswerType.giin': SimpleDisplay,
  'AnswerType.giin_with_escape': SimpleDisplay,
  'AnswerType.aba': SimpleDisplay,
  'AnswerType.email': SimpleDisplay,
  'AnswerType.tel': SimpleDisplay,
  'AnswerType.dollars': SimpleDisplay,
  'AnswerType.integer': SimpleDisplay,
  'AnswerType.country': SimpleDisplay,
  'AnswerType.state': SimpleDisplay,
  'AnswerType.state_with_escape': SimpleDisplay,
  'AnswerType.date': SimpleDisplay,
  'AnswerType.date_year': SimpleDisplay,
  'AnswerType.date_without_year': SimpleDisplay,
  'AnswerType.percentage': SimpleDisplay,
};

export const getReconciliationComponent = ({ question }) => {
  const DisplayComponent = INPUT_TYPE[question.answerType];

  if (DisplayComponent) {
    return (props) => (
      <InputQuestionReconciliation
        DisplayComponent={DisplayComponent}
        {...props}
      />
    );
  }

  if (question.answerType === 'AnswerType.choice') {
    return ChoiceQuestionReconciliation;
  }

  if (question.answerType === 'AnswerType.multi_select') {
    return MultiSelectQuestionReconciliation;
  }

  if (question.answerType === 'AnswerType.yes_or_no') {
    return (props) => (
      <BooleanQuestionReconciliation
        trueLabel="Yes"
        falseLabel="No"
        {...props}
      />
    );
  }

  if (question.answerType === 'AnswerType.true_or_false') {
    return (props) => (
      <BooleanQuestionReconciliation
        trueLabel="True"
        falseLabel="False"
        {...props}
      />
    );
  }

  if (question.answerType === 'AnswerType.file_upload') {
    return FileQuestionReconciliation;
  }

  if (question.answerType === 'AnswerType.multi_file_upload') {
    return MultiFileQuestionReconciliation;
  }

  return null;
};
