import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { Button } from '@passthrough/uikit';

import { SetUpProfileModal } from './set_up_profile_modal';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },
  button: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  text: {
    color: theme.palette.text.secondary,
  },
}));

export const LeftSidebarUnclaimed = ({ profile, refresh }) => {
  const classes = useStyles();
  const [open, setModal] = React.useState(false);
  const closeModal = () => {
    setModal(false);
  };

  return (
    <div className={classes.root}>
      <Typography variant="body2" className={classes.text}>
        Set up this profile to start using this profile for future investments.
      </Typography>

      <div className={classes.button}>
        <Button variant="primary" onClick={() => setModal(true)}>
          Set up profile
        </Button>
      </div>

      <SetUpProfileModal
        profile={profile}
        open={open}
        onClose={closeModal}
        refresh={refresh}
      />
    </div>
  );
};
