import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  actionArea: {
    borderRadius: 8,
    transition: '0.2s',
    '&:hover': {
      transform: 'scale(1.1)',
      zIndex: 1,
    },
  },
  paper: {
    padding: theme.spacing(2),
  },
  selected: {
    borderColor: theme.palette.primary.main,
    borderWidth: '2px',
  },
}));

export function Face({ text, Icon, selected, onClick, color }) {
  const classes = useStyles();
  return (
    <CardActionArea className={classes.actionArea} onClick={onClick}>
      <Paper
        variant="outlined"
        className={clsx(classes.paper, { [classes.selected]: selected })}
      >
        <Icon style={{ color }} />
        <Typography>{text}</Typography>
      </Paper>
    </CardActionArea>
  );
}
