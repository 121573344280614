/* eslint-disable react/jsx-props-no-spreading */
import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export function DatePicker({ date, setDate, ...props }) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        {...props}
        autoOk
        variant="inline"
        inputVariant="outlined"
        format="MM/dd/yyyy"
        id="date-picker-inline"
        value={date}
        onChange={(d) => {
          setDate(d);
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
