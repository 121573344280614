import React from 'react';

export function DateEndIcon({ className }) {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4453 14.1321H8.49316V24.2972H14.5V26.3302H8.49316C7.38696 26.3302 6.5 25.4154 6.5 24.2972L6.50997 10.0661C6.50997 8.94789 7.38696 8.03303 8.49316 8.03303H9.48974V6H11.4829V8.03303H19.4555V6H21.4487V8.03303H22.4453C23.5415 8.03303 24.4385 8.94789 24.4385 10.0661V16.5H22.4453V14.1321ZM22.4453 10.0661H8.49316V12.0991H22.4453V10.0661ZM21.5758 21.0923L25.1111 17.5569L26.7973 19.2432L23.262 22.7785L26.7973 26.3138L25.1111 28L21.5758 24.4647L18.0405 28L16.3542 26.3138L19.8896 22.7785L16.3423 19.2432L18.0285 17.5569L21.5758 21.0923Z"
        fill="grey"
      />
    </svg>
  );
}
