import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  depList: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  depDivider: {
    marginTop: theme.spacing(2),
  },
}));

export const DependenciesList = ({ dependencies, handleClickItem }) => {
  const classes = useStyles();

  if (!dependencies.length) return null;

  return (
    <div className={classes.root}>
      <Typography gutterBottom variant="body1">
        Dependencies
      </Typography>
      <div className={classes.depList}>
        {dependencies.map((dependency) => (
          <Chip
            key={dependency.id}
            label={dependency.id}
            component="button"
            onClick={() => handleClickItem(dependency)}
            clickable
            variant="outlined"
          />
        ))}
      </div>
      <Divider className={classes.depDivider} variant="middle" />
    </div>
  );
};
