import React from 'react';
import { Link } from '@passthrough/uikit';
import * as urls from 'services/urls';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from 'components/table';
import { ShowCaseBox, ComponentShowCase, ShowCaseBoxRow } from '../show_case';

export const LinkShowCase = () => (
  <ComponentShowCase title="Link">
    <ShowCaseBox>
      <ShowCaseBoxRow>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Style\Target</TableCell>
                <TableCell>Same tab (a.k.a. internal)</TableCell>
                <TableCell>New tab/window (a.k.a. external)</TableCell>
                <TableCell>New tab/window (a.k.a. external) no icon</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Standalone</TableCell>
                <TableCell>
                  <Link href={urls.UIKIT_URL} variant="internal">
                    Internal standalone
                  </Link>
                </TableCell>
                <TableCell>
                  <Link href={urls.UIKIT_URL} variant="external">
                    External standalone
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    href={urls.UIKIT_URL}
                    variant="external"
                    externalIcon={false}
                  >
                    External standalone no icon
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Inline</TableCell>
                <TableCell>
                  Inline{' '}
                  <Link href={urls.UIKIT_URL} inline variant="internal">
                    internal
                  </Link>
                </TableCell>
                <TableCell>
                  Inline{' '}
                  <Link href={urls.UIKIT_URL} inline variant="external">
                    external
                  </Link>
                </TableCell>
                <TableCell>
                  Inline{' '}
                  <Link
                    href={urls.UIKIT_URL}
                    inline
                    variant="external"
                    externalIcon={false}
                  >
                    external no icon
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Navigation</TableCell>
                <TableCell>
                  <Link href={urls.UIKIT_URL} variant="navigation">
                    Navigation Link
                  </Link>
                </TableCell>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </ShowCaseBoxRow>
    </ShowCaseBox>
  </ComponentShowCase>
);
