import React from 'react';
import Timeline from '@material-ui/lab/Timeline';
import { PaperHeader } from 'components/paper_header';
import { TimelineTask } from './timeline_task';
import { TimelineJointSigner } from './timeline_joint_signer';
import { FIRST_SUBMISSION } from './constants';

export function TodoTimeline({ step, jointSigners, fundName, hasSignatures }) {
  const signed = jointSigners?.filter((signer) => signer.signed) || [];
  const unsigned = jointSigners?.filter((signer) => !signer.signed) || [];

  return (
    <PaperHeader headerText="What happens next?">
      <Timeline
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {step === FIRST_SUBMISSION ? (
          <TimelineTask done>Submitted</TimelineTask>
        ) : null}

        {signed.map((signer) => (
          <TimelineJointSigner name={signer.name} email={signer.email} done />
        ))}

        {unsigned.map((signer, index) => (
          <TimelineJointSigner
            name={signer.name}
            email={signer.email}
            sent={index === 0}
          />
        ))}

        <TimelineTask isLast={!hasSignatures}>
          Approved by {fundName}
        </TimelineTask>

        {hasSignatures ? (
          <TimelineTask isLast>Signed documents sent by email</TimelineTask>
        ) : null}
      </Timeline>
    </PaperHeader>
  );
}
