import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Typography } from '@passthrough/uikit';
import { BooleanValue } from 'components/boolean_value';
import { EXPOSED_SIDE_LETTER_TYPES } from 'components/document_upload/constants';
import { SettingsGroup, Setting } from 'components/settings_group';

import { NotificationLearnMoreLink } from './notification_learn_more_link';

const useStyles = makeStyles((theme) => ({
  col: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));

function SubdocCompleteEmailEnabled({ subdocCompleteEmail }) {
  return (
    <Setting
      label="Completion email"
      description={
        <Typography variant="body" size="small" color="text.secondary">
          Send investors an email when they complete their questionnaire.
        </Typography>
      }
    >
      <BooleanValue
        label={subdocCompleteEmail ? 'Enabled' : 'Disabled'}
        bool={subdocCompleteEmail}
      />
    </Setting>
  );
}

function FullyExecutedEmailEnabled({ fullyExecutedEmail }) {
  return (
    <Setting
      label="Fully executed email"
      description={
        <Typography variant="body" size="small" color="text.secondary">
          Send investor an email when their documents are fully executed.
        </Typography>
      }
    >
      <BooleanValue
        label={fullyExecutedEmail ? 'Enabled' : 'Disabled'}
        bool={fullyExecutedEmail}
      />
    </Setting>
  );
}

function DocumentsTypesToInclude({ documentTypesToInclude }) {
  const classes = useStyles();
  return (
    <Setting
      label="Documents to include"
      description={
        <Typography variant="body" size="small" color="text.secondary">
          Include documents for investors to download in emails they receive.
        </Typography>
      }
    >
      <div className={classes.col}>
        {EXPOSED_SIDE_LETTER_TYPES.map(({ key, display }) => (
          <BooleanValue
            key={key}
            label={display}
            bool={documentTypesToInclude.includes(key)}
          />
        ))}
      </div>
    </Setting>
  );
}

export function InvestorNotificationSettings({
  subdocCompleteEmail,
  fullyExecutedEmail,
  documentTypesToInclude,
  onEdit,
}) {
  return (
    <SettingsGroup
      header="Investor notifications"
      subheader={<NotificationLearnMoreLink text="investor notifications" />}
      onEdit={onEdit}
    >
      <SubdocCompleteEmailEnabled subdocCompleteEmail={subdocCompleteEmail} />
      <FullyExecutedEmailEnabled fullyExecutedEmail={fullyExecutedEmail} />
      <DocumentsTypesToInclude
        documentTypesToInclude={documentTypesToInclude}
      />
    </SettingsGroup>
  );
}
