import React, { useCallback, useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';

import { DataValueDisplay } from 'components/data_value_display';
import * as api from 'services/api';
import { QuestionTable } from './question_table';
import { debounce } from '../utils';

const useStyles = makeStyles((theme) => ({
  accordionDetails: {
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  errorMessage: {
    marginBottom: theme.spacing(3),
  },
  summary: {
    flexDirection: 'row-reverse',
    padding: theme.spacing(0),
  },
  summaryContent: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0),
    justifyContent: 'space-between',
    width: '-webkit-fill-available',
  },
  text: {
    marginLeft: theme.spacing(2),
    alignSelf: 'center',
  },
  updateButton: {
    margin: theme.spacing(1, 0),
  },
  updateGid: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
}));

export function GidPanel({
  inputGid,
  allGids,
  index,
  questionInfo,
  handleDelete,
  handleEdit,
}) {
  const classes = useStyles();
  const addMode = inputGid === null;
  const [gid, setGid] = useState(inputGid);
  const [error, setError] = useState(null);

  function updateGid(newGid) {
    if (allGids.includes(newGid)) {
      setError('Duplicate GID. Will only be saved once.');
    } else {
      api
        .getQuestionGid({ gid: newGid })
        .then((response) => {
          handleEdit(index, newGid, response.data.gidsInfo);
          setError(null);
        })
        .catch((err) => {
          if (err.response.status === 404) {
            setError(
              'Could not find GID. Please make sure the input is valid.',
            );
          }
        });
    }
  }

  const optimizedGidUpdate = useCallback(debounce(updateGid), []);

  // Since text and type are consistent over all questions here, we can
  // get it from the first one.
  const questionText =
    !addMode && questionInfo?.length ? questionInfo[0].text : '';
  const questionType =
    !addMode && questionInfo?.length ? questionInfo[0].type : '';
  const fieldType =
    !addMode && questionInfo?.length ? questionInfo[0].field_type : '';
  const questionFieldOrChoice =
    !addMode && questionInfo?.length ? questionInfo[0].field_or_choice : '';

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id={`panel${index}`}
        className={classes.summary}
      >
        <div key="summaryContent" className={classes.summaryContent}>
          <Typography className={classes.text}>
            {!addMode ? questionText : 'Add new GID'}
          </Typography>
          <IconButton onClick={() => handleDelete(index)}>
            <DeleteIcon />
          </IconButton>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <div key="updateGid" className={classes.updateGid}>
          <TextField
            required
            id="gid"
            variant="outlined"
            label="Question gid"
            type="text"
            value={gid}
            onChange={(e) => {
              setGid(e.target.value);
              optimizedGidUpdate(e.target.value);
            }}
            fullWidth
          />
        </div>

        {error ? (
          <FormHelperText className={classes.errorMessage} error>
            {error}
          </FormHelperText>
        ) : null}

        <DataValueDisplay label="Field type" value={fieldType} />
        <DataValueDisplay label="Question type" value={questionType} />
        <DataValueDisplay label="Question text" value={questionText} />
        <DataValueDisplay
          label="Question field/choice"
          value={questionFieldOrChoice}
        />
        {!addMode && questionInfo?.length ? (
          <QuestionTable questions={questionInfo} />
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
}
