import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';

import { Button } from 'components/button';
import { GidPanel } from './gid_panel';

const useStyles = makeStyles((theme) => ({
  add: {
    marginTop: theme.spacing(2),
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
  root: {
    paddingBottom: theme.spacing(2),
  },
}));

export function GidEditTool({
  gids,
  gidsInfo,
  handleAdd,
  handleDelete,
  handleEdit,
}) {
  const classes = useStyles();

  const gidPanels =
    gids != null
      ? gids.map((gid, index) => {
          const questionInfo = gid !== null ? gidsInfo[gid] : null;
          return (
            <GidPanel
              key={gid}
              inputGid={gid}
              allGids={gids}
              index={index}
              questionInfo={questionInfo}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
            />
          );
        })
      : null;

  return (
    <div key="questionsList" className={classes.root}>
      <Typography variant="h5" className={classes.header}>
        Questions
      </Typography>
      {gidPanels}
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        className={classes.add}
        onClick={handleAdd}
      >
        Add
      </Button>
    </div>
  );
}
