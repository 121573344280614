// cspell:words Settlors
import * as constants from '../../constants';

export const SEI_REQUIREMENTS = {
  [constants.GROUP_NATURAL_PERSONS]: {
    [constants.TYPE_INDIVIDUAL]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(constants.TYPE_VALID_ID),
          helpText:
            "Provide a valid driver's license, passport or national identity card",
        },
        {
          ...constants.genDocumentKeyAndLabel(constants.TYPE_PROOF_OF_ADDRESS),
          helpText:
            'Provide a utility bill, bank statement or similar dated within the last 12 months. A utility bill dated in the last 3 months is preferable, and may be required for non-US funds. This data is strictly used for address matching.',
        },
      ],
      owners: [],
    },
    [constants.TYPE_JOINT_ACCOUNT_OR_TENANCY]: {
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_HOLDERS),
          helpText:
            'Add the names of the joint account holders or joint tenants.',
          minNum: 2,
        },
      ],
    },
    [constants.TYPE_IRA]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(
            constants.TYPE_PARTICIPANT_STATEMENT_REPORT_OR_QUARTERLY_ACCT,
          ),
          helpText:
            'Provide a participant statement report or quarterly account statement from for the IRA',
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_BENEFICIARIES),
          helpText:
            'Add the names of any non-minor beneficiaries of the IRA, including the IRA account holder',
        },
      ],
    },
  },
  [constants.GROUP_COMPANY]: {
    [constants.TYPE_LLC]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(constants.TYPE_ASL),
          helpText: 'Upload the list of authorized signatories for the entity.',
        },
        {
          ...constants.genDocumentKeyAndLabel(
            constants.TYPE_FORMATION_DOCUMENT,
          ),
          helpText: 'Upload the formation documents for this entity.',
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(
            constants.TYPE_AUTHORIZED_SIGNATORIES,
          ),
          helpText:
            'Add the names of any authorized signatories for the entity.',
        },
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_BENEFICIAL_OWNERS),
          singularLabel: 'Beneficial Owner of 25% or more',
          pluralLabel: 'Beneficial Owners of 25% or more',
          helpText:
            'Add the names of any beneficial owners of 25% or more of the entity.',
        },
      ],
    },
    [constants.TYPE_PRIVATE_CORPORATION]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(constants.TYPE_ASL),
          helpText: 'Upload the list of authorized signatories for the entity.',
        },
        {
          ...constants.genDocumentKeyAndLabel(
            constants.TYPE_FORMATION_DOCUMENT,
          ),
          helpText: 'Upload the formation documents for this entity.',
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(
            constants.TYPE_AUTHORIZED_SIGNATORIES,
          ),
          helpText:
            'Add the names of any authorized signatories for the entity.',
        },
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_BENEFICIAL_OWNERS),
          singularLabel: 'Beneficial Owner of 25% or more',
          pluralLabel: 'Beneficial Owners of 25% or more',
          helpText:
            'Add the names of any beneficial owners of 25% or more of the entity.',
        },
      ],
    },

    [constants.TYPE_LIMITED_PARTNERSHIP]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(
            constants.TYPE_FORMATION_DOCUMENT,
          ),
          helpText: 'Upload the formation documents for this entity.',
        },
        {
          ...constants.genDocumentKeyAndLabel(constants.TYPE_ASL),
          helpText: 'Upload the list of authorized signatories for the entity.',
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_BENEFICIAL_OWNERS),
          singularLabel: 'Beneficial Owner of 25% or more',
          pluralLabel: 'Beneficial Owners of 25% or more',
          helpText:
            'Add the names of any beneficial owners of 25% or more of the entity.',
        },
        {
          ...constants.genOwnerKeyAndLabels(
            constants.TYPE_AUTHORIZED_SIGNATORIES,
          ),
          helpText:
            'Add the names of any authorized signatories for the entity.',
        },
      ],
    },
    [constants.TYPE_REGULATED_FINANCIAL_INSTITUTION]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(constants.TYPE_ASL),
          helpText: 'Upload the list of authorized signatories for the entity.',
        },
        {
          ...constants.genDocumentKeyAndLabel(
            constants.TYPE_REGULATORY_AGENCY_FORM,
          ),
          helpText:
            'Upload evidence that the financial institution is regulated, including the name of the regulatory agency and the registration number, or a non-us financial institution certification form.',
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(
            constants.TYPE_AUTHORIZED_SIGNATORIES,
          ),
          helpText:
            'Add the names of any authorized signatories for the entity.',
        },
      ],
    },

    [constants.TYPE_PUBLIC_COMPANY]: {
      documents: [],
      owners: [],
    },

    [constants.TYPE_PENSION_PLAN]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(constants.TYPE_ASL),
          helpText: 'Upload the list of authorized signatories for the entity.',
        },
        {
          ...constants.genDocumentKeyAndLabel(
            constants.TYPE_PENSION_PLAN_DOCUMENT,
          ),
          helpText: 'Upload the document formally describing the pension plan.',
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(
            constants.TYPE_AUTHORIZED_SIGNATORIES,
          ),
          helpText:
            'Add the names of any authorized signatories for the entity.',
        },
      ],
    },
  },
  [constants.GROUP_OTHER]: {
    [constants.TYPE_TRUST]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(
            constants.TYPE_GOVERNING_TRUST_DOC,
          ),
          helpText:
            'Upload a copy of the governing trust document, such as a trust agreement, that includes the names of the trustees and any beneficiaries.',
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_TRUSTEES),
          helpText: 'Add the names of the trustees for the trust.',
        },
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_GRANTORS),
          helpText: 'Add the names of grantors / settlors for the trust.',
          singularLabel: 'Grantor / Settlor',
          pluralLabel: 'Grantors / Settlors',
        },
      ],
    },
    [constants.TYPE_NON_PROFIT_ENDOWMENT_OR_FOUNDATION]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(
            constants.TYPE_IRS_DETERMINATION_LETTER,
          ),
          helpText:
            'Upload a copy of the IRS determination letter for 501(c)(3) or of the non profit’s memorandum and articles of incorporation.',
        },
        {
          ...constants.genDocumentKeyAndLabel(constants.TYPE_ASL),
          helpText: 'Upload the list of authorized signatories for the entity.',
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(
            constants.TYPE_AUTHORIZED_SIGNATORIES,
          ),
          helpText:
            'Add the names of any authorized signatories for the entity.',
        },
      ],
    },
    [constants.TYPE_NOMINEE]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(constants.TYPE_ASL),
          helpText: 'Upload the list of authorized signatories for the entity.',
        },
        {
          key: constants.TYPE_AML_LETTER,
          helpText:
            'Upload a copy of an approved eligible introducer letter or AML representation letter for the nominee.',
          label:
            'Approved eligible introducer letter or AML representation letter',
        },
        {
          ...constants.genDocumentKeyAndLabel(
            constants.TYPE_BENEFICIAL_OWNERS_LIST,
          ),
          helpText:
            'Upload a schedule that shows the name and ownership percentage of any beneficial owners of 25% or more.',
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(
            constants.TYPE_AUTHORIZED_SIGNATORIES,
          ),
          helpText:
            'Add the names of any authorized signatories for the entity.',
        },
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_BENEFICIAL_OWNERS),
          helpText:
            'Add the names of any beneficial owners of 25% or more of the entity.',
          singularLabel: 'Beneficial Owner of 25% or more',
          pluralLabel: 'Beneficial Owners of 25% or more',
        },
      ],
    },
    [constants.TYPE_GOV_ENTITY]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(constants.TYPE_ASL),
          helpText: 'Upload the list of authorized signatories for the entity.',
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(
            constants.TYPE_AUTHORIZED_SIGNATORIES,
          ),
          helpText:
            'Add the names of any authorized signatories for the entity.',
        },
      ],
    },
  },
};
