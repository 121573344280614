import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { Button, Typography } from '@passthrough/uikit';
import { NoteBoxLabel } from './note_box_label';
import { NoteIconVariant } from './constants';

const useStyles = makeStyles((theme) => ({
  errorText: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  bottomRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
  noteExplanation: {
    display: 'flex',
    gap: theme.spacing(1),
    flexDirection: 'row',
    color: theme.palette.text.secondary,
  },
  input: {
    backgroundColor: theme.palette.background.internalNote,
  },
}));

export function InternalNoteForm({
  internalNote,
  setInternalNote,
  isLoading,
  onSave,
  showCancel,
  onCancel,
  errorText = null,
}) {
  const classes = useStyles();

  return (
    <>
      <NoteBoxLabel variant={NoteIconVariant.INTERNAL_NOTE}>
        Internal note
      </NoteBoxLabel>

      <TextField
        InputProps={{
          className: classes.input,
        }}
        data-test="diligence_internal_note"
        value={internalNote}
        onChange={(e) => {
          setInternalNote(e.target.value);
        }}
        variant="outlined"
        multiline
        minRows={4}
        fullWidth
        autoFocus
      />

      <div className={classes.bottomRow}>
        <div className={classes.noteExplanation}>
          <LockOutlinedIcon fontSize="small" />
          <Typography variant="body" size="small">
            Not visible to the investor
          </Typography>
        </div>

        <div className={classes.actions}>
          {showCancel ? (
            <Button variant="text" onClick={onCancel}>
              Cancel
            </Button>
          ) : null}

          <Button
            aria-label="Internal note"
            data-test="diligence_internal_note_save"
            data-testid="diligence_internal_note_save"
            variant="primary"
            disabled={!internalNote}
            loading={isLoading}
            onClick={onSave}
          >
            Add internal note
          </Button>
        </div>
      </div>

      {errorText ? (
        <FormHelperText error className={classes.errorText}>
          {errorText}
        </FormHelperText>
      ) : null}
    </>
  );
}
