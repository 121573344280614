/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Button } from 'components/button';

const useStyles = makeStyles((theme) => ({
  icon: {
    transition: '0.3s',
    opacity: '0',
    paddingRight: theme.spacing(1),
  },
  iconHover: {
    transform: 'translate(5px)',
    opacity: '1',
  },
}));

export function HiddenArrowButton({ ...props }) {
  const classes = useStyles();
  const [hover, setHover] = useState(false);

  return (
    <Button
      onMouseOver={() => setHover(true)}
      onFocus={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onBlur={() => setHover(false)}
      endIcon={
        <ArrowForwardIcon
          className={clsx(classes.icon, { [classes.iconHover]: hover })}
        />
      }
      {...props}
    />
  );
}
