import React, { useEffect } from 'react';
import { Spinner } from 'components/spinner';
import * as api from 'services/api';

export function DocsRedirect() {
  useEffect(() => {
    api.getDocsCookie().then(() => {
      // redirect to the docs page
      window.location.href = window.DOCS_REDIRECTED_URL;
    });
  }, []);

  return <Spinner fullScreen />;
}
