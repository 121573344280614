import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';

import { ExpansionHeader } from './expansion_header';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'transparent',
    border: 'none',
  },
  upperMargin: {
    marginTop: theme.spacing(2),
  },
  verticalConnector: {
    borderLeft: `1px solid ${theme.palette.primary.grey}`,
  },
  expansionContentContainer: {
    marginLeft: '19px',
    padding: theme.spacing(2, 2, 1, 3),
  },
}));

export function SubsectionDisplay({
  label,
  expanded,
  onClick,
  children,
  showVerticalBar,
  statusIcon,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ExpansionHeader
        label={label}
        expanded={expanded}
        onClick={onClick}
        statusIcon={statusIcon}
      />

      <div
        className={clsx(classes.expansionContentContainer, {
          [classes.verticalConnector]: showVerticalBar,
        })}
      >
        <Collapse in={expanded} mountOnEnter unmountOnExit>
          {children}
        </Collapse>
      </div>
    </div>
  );
}
