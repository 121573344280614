import { GoogleIcon } from './social_button/google_icon';
import { MicrosoftIcon } from './social_button/microsoft_icon';

export const PROVIDERS = {
  google: {
    icon: GoogleIcon,
    label: 'Sign in with Google',
  },
  microsoft: {
    icon: MicrosoftIcon,
    label: 'Sign in with Microsoft',
  },
};
