import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import CardActionArea from '@material-ui/core/CardActionArea';
import * as api from 'services/api';
import * as urls from 'services/urls';
import { Button } from 'components/button';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4, 0),
  },
  paper: {
    padding: theme.spacing(4),
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    fontSize: '1.5em',
    marginTop: theme.spacing(1),
  },
  choicesContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    margin: theme.spacing(4),
  },
  choice: {
    margin: theme.spacing(1),
  },
  profileLabel: {
    padding: theme.spacing(1),
    width: '100%',
  },
}));

export function ManageProfilesPage() {
  const classes = useStyles();
  const history = useHistory();
  const [profiles, setProfiles] = useState([]);

  const getUrl = (profile) => urls.manageProfile({ profileId: profile.id });

  const selectProfile = (profile) => {
    history.push(getUrl(profile));
  };

  useEffect(() => {
    api.getManageProfiles().then((response) => {
      if (response.data.length === 1) {
        // replace is better as a redirect than push- makes the back button work
        history.replace(getUrl(response.data[0]));
      } else {
        setProfiles(response.data);
      }
    });
  }, []);

  const hideManageProfiles = profiles.length <= 1;

  if (hideManageProfiles) {
    return null;
  }

  return (
    <Container component="main" maxWidth="sm" className={classes.container}>
      <Paper className={classes.paper} elevation={0}>
        <div className={classes.top}>
          <Typography variant="h2" className={classes.title}>
            Manage investor profiles
          </Typography>
          <div className={classes.choicesContainer}>
            {profiles.map((profile) => (
              <CardActionArea key={profile.id} className={classes.choice}>
                <Paper variant="outlined">
                  <Button
                    className={classes.profileLabel}
                    endIcon={<KeyboardArrowRightIcon />}
                    color="default"
                    onClick={() => {
                      selectProfile(profile);
                    }}
                  >
                    {profile.displayName}
                  </Button>
                </Paper>
              </CardActionArea>
            ))}
          </div>
        </div>
      </Paper>
    </Container>
  );
}
