import React, { useState, useEffect } from 'react';
import { Modal, Alert } from '@passthrough/uikit';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import { FileUpload, getEmptyFile } from 'components/file_upload';
import { TemplateQuestionnaireTypeahead } from 'components/add_sub_doc/template_questionnaire_typeahead';

import * as api from 'services/api';
import { useToast } from 'services/toast';
import { SimilarQuestionnaires } from 'components/similar_questionnaires';

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(3),
  },
  label: {
    marginBottom: theme.spacing(1),
  },
  error: {
    marginBottom: theme.spacing(1),
  },
}));

function AddSubDocDialogContent({
  open,
  handleClose,
  fundName,
  onSuccess,
  onExited,
}) {
  const classes = useStyles();
  const { successToast } = useToast();
  const { fundId } = useParams();

  const [file, setFile] = useState(getEmptyFile());
  const [name, setName] = useState(`${fundName} Subscription Document`);
  const [loading, setLoading] = useState(false);

  const [nameErrorMsg, setNameErrorMsg] = useState(null);
  const [templateQuestionnaireIdErrorMsg, setTemplateQuestionnaireIdErrorMsg] =
    useState(null);
  const [fileIdErrorMsg, setFileIdErrorMsg] = useState(null);

  const [templateQuestionnaires, setTemplateQuestionnaires] = useState([]);
  const [selectedTemplateQuestionnaireId, setSelectedTemplateQuestionnaireId] =
    useState(null);

  useEffect(() => {
    api.listQuestionnaires().then((response) => {
      setTemplateQuestionnaires(response.data);
    });
  }, []);

  const [questionnaireId, setQuestionnaireId] = useState(null);
  const [similarQuestionnaires, setSimilarQuestionnaires] = useState([]);

  function onSubmit(e) {
    e.preventDefault();
    setSimilarQuestionnaires([]);
    setLoading(true);
    api
      .subscriptionDocumentCreate({
        fundId,
        fileId: file.fileId,
        name,
        templateQuestionnaireId: selectedTemplateQuestionnaireId,
      })
      .then(({ data }) => {
        if (data.similarQuestionnaires?.length > 0) {
          setQuestionnaireId(data.questionnaireId);
          setSimilarQuestionnaires(data.similarQuestionnaires);
          return;
        }
        setFile(getEmptyFile());
        setNameErrorMsg('');
        setTemplateQuestionnaireIdErrorMsg('');
        setFileIdErrorMsg('');
        onSuccess(() => successToast('Added subscription document.'));
        handleClose();
      })
      .catch((error) => {
        if (error.response?.data) {
          setNameErrorMsg(error.response.data.name);
          setTemplateQuestionnaireIdErrorMsg(
            error.response.data.templateQuestionnaireId,
          );
          setFileIdErrorMsg(error.response.data.fileId);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const alertError = nameErrorMsg || fileIdErrorMsg;

  return (
    <Modal
      open={open}
      onClose={() => {
        if (similarQuestionnaires.length) {
          onSuccess(() => successToast('Added subscription document.'));
        }
        handleClose();
      }}
      onSubmit={onSubmit}
      headerLabel={
        similarQuestionnaires.length
          ? 'Found similar questionnaires'
          : 'Add subscription document'
      }
      primaryButtonText="Create"
      footer={similarQuestionnaires.length ? null : undefined}
      primaryButtonLoading={loading}
      primaryButtonDisabled={file.fileId === null || !name}
      size={similarQuestionnaires.length ? 'md' : 'sm'}
      onExited={onExited}
    >
      {similarQuestionnaires.length ? (
        <SimilarQuestionnaires
          similarQuestionnaires={similarQuestionnaires}
          fundId={fundId}
          questionnaireId={questionnaireId}
          onCopy={() => {
            successToast('Added subscription document.');
            onSuccess();
            handleClose();
          }}
        />
      ) : (
        <>
          {alertError ? <Alert severity="error">{alertError}</Alert> : null}
          <TextField
            className={classes.field}
            required
            id="name"
            variant="outlined"
            label="Name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />

          <div className={classes.field}>
            <FormLabel className={classes.label} component="legend">
              Upload the PDF of the subscription document *
            </FormLabel>
            <FileUpload file={file} onChange={setFile} />
          </div>
          <TemplateQuestionnaireTypeahead
            options={templateQuestionnaires}
            setSelectedTemplateQuestionnaireId={
              setSelectedTemplateQuestionnaireId
            }
            errorMessage={templateQuestionnaireIdErrorMsg}
          />
        </>
      )}
    </Modal>
  );
}

export function AddSubDocDialog({
  open,
  handleClose,
  allSubDocTypes,
  fundName,
  onSuccess,
}) {
  const [counter, setCounter] = useState(0);
  return (
    <AddSubDocDialogContent
      key={counter}
      open={open}
      handleClose={handleClose}
      allSubDocTypes={allSubDocTypes}
      fundName={fundName}
      onSuccess={onSuccess}
      onExited={() => setCounter(counter + 1)}
    />
  );
}
