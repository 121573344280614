import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  portionCount: {
    flex: 1,
    alignSelf: 'center',
    color: theme.palette.primary.grey,
  },
}));

export function NavKeys({
  currOwnerIndex,
  numOwners,
  onLeftNavClick,
  onRightNavClick,
  className,
}) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)}>
      <IconButton
        aria-label="review_nav_left_click"
        onClick={() => onLeftNavClick()}
        color="primary"
        disabled={currOwnerIndex === 0}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>

      <Typography className={classes.portionCount}>
        {
          // NOTE: currOwnerIndex should be 1-indexed when displayed
        }
        {currOwnerIndex + 1} of {numOwners}
      </Typography>

      <IconButton
        aria-label="review_nav_right_click"
        onClick={() => onRightNavClick()}
        color="primary"
        disabled={currOwnerIndex === numOwners - 1}
        data-test="diligence_review_nav_right"
      >
        <KeyboardArrowRightIcon />
      </IconButton>
    </div>
  );
}
