import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Typography from '@material-ui/core/Typography';
import { Button } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '35px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  input: {
    height: '35px',
    flexDirection: 'row',
    margin: '6px',
  },
  pageInputParent: {
    display: 'flex',
    alignItems: 'center',
  },
  leftAlignRoot: {
    justifyContent: 'left',
  },
  leftAlignButtons: {
    margin: theme.spacing(0, 1),
  },
}));

export function PDFReviewStepper({
  page,
  setPage,
  numPages,
  useLeftAlign = false,
  hideButtonLabels = false,
  loading = false,
}) {
  const classes = useStyles();
  const showNext = page < numPages;
  const showPrev = page > 1;

  // Local state to manage invalid numbers temporarily while inputting correct
  // page. For instance going from page 3 -> 1 requires deleting the 3, which
  // is an invalid number.
  const [pageInput, setPageInput] = useState(page.toString());

  useEffect(() => {
    if (loading) {
      return;
    }

    const pageInputNum = Number(pageInput);
    if (page === pageInputNum) {
      return;
    }
    if (pageInputNum >= numPages) {
      // If input is too big then we set it to max page num
      setPage(numPages);
      setPageInput(numPages.toString());
    } else if (pageInputNum > 0) {
      // we only want to set the number if it's bigger than 0
      setPage(pageInputNum);
    }
  }, [pageInput, loading]);

  useEffect(() => {
    if (page.toString() !== pageInput) {
      setPageInput(page.toString());
    }
  }, [page]);

  return (
    <div
      className={clsx(classes.root, { [classes.leftAlignRoot]: useLeftAlign })}
    >
      {hideButtonLabels ? (
        <Button
          variant="icon"
          size="medium"
          aria-label="Previous page"
          disabled={!showPrev}
          onClick={() => setPageInput((Number(pageInput) - 1).toString())}
        >
          <KeyboardArrowLeftIcon />
        </Button>
      ) : (
        <Button
          color="default"
          variant="secondary"
          disabled={!showPrev}
          onClick={() => setPageInput((Number(pageInput) - 1).toString())}
          startIcon={<KeyboardArrowLeftIcon />}
          htmlProps={{ 'data-test': 'pdf_review_previous' }}
        >
          Previous
        </Button>
      )}

      <span
        className={clsx(classes.pageInputParent, {
          [classes.leftAlignButtons]: useLeftAlign,
        })}
      >
        <Typography variant="body2">Page</Typography>

        <TextField
          type="number"
          value={pageInput}
          onChange={(e) => setPageInput(e.target.value.toString())}
          // Avoid the backspace key from deleting a box on the document fields modal
          onKeyUp={(e) => e.stopPropagation()}
          id="page"
          className={classes.input}
          InputProps={{
            inputProps: { min: 0, max: numPages },
          }}
          variant="outlined"
        />
        <Typography noWrap>of {numPages}</Typography>
      </span>

      {hideButtonLabels ? (
        <Button
          variant="icon"
          aria-label="Next page"
          size="medium"
          disabled={!showNext}
          onClick={() => setPageInput((Number(pageInput) + 1).toString())}
        >
          <KeyboardArrowRightIcon />
        </Button>
      ) : (
        <Button
          color="default"
          variant="secondary"
          disabled={!showNext}
          onClick={() => setPageInput((Number(pageInput) + 1).toString())}
          endIcon={<KeyboardArrowRightIcon />}
          htmlProps={{ 'data-test': 'pdf_review_next' }}
        >
          Next
        </Button>
      )}
    </div>
  );
}
