/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Alert as UikitAlert } from '@passthrough/uikit';

export function Alert({ severity, children, className, ...other }) {
  const severityToUikit = {
    error: 'error',
    info: 'info',
    success: 'success',
    warning: 'warning',
  }[severity];

  return (
    <div className={className}>
      <UikitAlert severity={severityToUikit || 'info'} {...other}>
        {children}
      </UikitAlert>
    </div>
  );
}
