import React, { useContext, createContext } from 'react';

const CurrencyContext = createContext();

export function useCurrency() {
  const currency = useContext(CurrencyContext);
  return currency;
}

export function CurrencyProvider({ children, currency }) {
  return (
    <CurrencyContext.Provider value={currency}>
      {children}
    </CurrencyContext.Provider>
  );
}
