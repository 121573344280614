import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LastPageIcon from '@material-ui/icons/LastPage';

import { Button } from 'components/button';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2, 3),
  },
  fileDataContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rightSpacing: {
    marginRight: theme.spacing(2),
  },
  minWidth: {
    minWidth: '85px',
  },
}));

export function Header({
  documentData,
  setShowDocumentViewer,
  className = '',
}) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <Button
        variant="text"
        onClick={() => {
          setShowDocumentViewer(false);
        }}
        endIcon={<LastPageIcon />}
        className={classes.rightSpacing}
      >
        Close
      </Button>

      <div className={classes.fileDataContainer}>
        <Typography className={classes.rightSpacing}>
          {documentData?.type}
        </Typography>

        <Typography variant="caption">{documentData?.fileName}</Typography>
      </div>

      <div className={classes.minWidth} />
    </div>
  );
}
