import React from 'react';

import { Address } from 'components/address_v2';
import { DiligenceV2SSN } from '../ssn';

export function SoleProprietorshipDetails({
  ssn,
  ssnError,
  useSsn,
  hqAddress,
  hqAddressError,
  handleChange,
}) {
  return (
    <>
      <DiligenceV2SSN
        ssn={ssn}
        ssnError={ssnError}
        useSsn={useSsn}
        handleChange={handleChange}
        data-test="diligence_v2_ssn"
      />

      <Address
        address={hqAddress}
        onChange={(addr) => {
          handleChange('hqAddress', addr);
        }}
        errors={hqAddressError}
        label="Headquarters address"
      />
    </>
  );
}
