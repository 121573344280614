import React, { useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { Popover } from '@material-ui/core';
import { Button } from '@passthrough/uikit';
import FilterListIcon from '@material-ui/icons/FilterList';
import { SearchInput } from 'components/fund_table/search_input';
import {
  ISSUE_COLUMN,
  RISK_RATING_COLUMN,
  LEGAL_NAME_COLUMN,
  INVESTOR_COLUMN,
  CLOSING_COLUMN,
  FUND_COLUMN,
  CREATED_COLUMN,
  ISSUE_FILTERS,
  TRUE_ALERT_KEY,
  UNSURE_ALERT_KEY,
  REVIEW_KEY,
  COMMENT_KEY,
  POTENTIAL_MATCH_KEY,
  WAIVED_KEY,
} from './constants';
import { TableRows } from './table_rows';
import { IssueFilterEntry } from './issue_filter_entry';
import { filterByQuery } from './utils';

const tableColumns = [
  ISSUE_COLUMN,
  RISK_RATING_COLUMN,
  INVESTOR_COLUMN,
  LEGAL_NAME_COLUMN,
  CLOSING_COLUMN,
  FUND_COLUMN,
  CREATED_COLUMN,
];

const useStyles = makeStyles((theme) => ({
  filtersContainer: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(2),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filterAndSearchContainer: {
    display: 'flex',
    columnGap: theme.spacing(1),
  },
  btnLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing(1),
  },
  filterBtnAndChipContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(1),
  },
  modifyFiltersButtons: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.spacing(1),
    alignItems: 'center',
  },
  popoverRoot: {
    marginTop: theme.spacing(2),
  },
  paddingRoot: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
  allIssues: {
    padding: theme.spacing(2, 2, 2, 2),
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.spacing(4),
    overflowY: 'auto',
    maxHeight: '450px',
  },
  issueFiltersContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
    width: '300px',
  },
  searchInput: {
    width: '250px',
  },
}));

export function filterTasksByIssue(filterArray, tasks) {
  return tasks.filter((task) => filterArray.includes(task.issue));
}

export function KYCIssueTable({
  setCurrTab,
  issueFilter,
  setIssueFilter,
  tasks,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const [throttledSearchQuery, setThrottledSearchQuery] = useState('');
  const [clearSearch, setClearSearch] = useState(0);

  const updateFilterAndTab = (updatedFilter) => {
    setIssueFilter(updatedFilter);
    setCurrTab('all');
  };

  const clearFilters = () => {
    updateFilterAndTab([
      TRUE_ALERT_KEY,
      UNSURE_ALERT_KEY,
      REVIEW_KEY,
      COMMENT_KEY,
      POTENTIAL_MATCH_KEY,
      WAIVED_KEY,
    ]);
    setClearSearch((prev) => prev + 1);
  };

  const filteredTasks = useMemo(() => {
    let updatedFilteredTasks = [...tasks];
    updatedFilteredTasks = filterTasksByIssue(
      issueFilter,
      updatedFilteredTasks,
    );
    if (throttledSearchQuery !== '') {
      updatedFilteredTasks = filterByQuery(
        throttledSearchQuery,
        updatedFilteredTasks,
      );
    }
    return updatedFilteredTasks;
  }, [issueFilter, throttledSearchQuery, tasks]);

  return (
    <>
      <div className={classes.filtersContainer}>
        <div className={classes.filterAndSearchContainer}>
          <div className={classes.filterBtnAndChipContainer}>
            <div className={classes.modifyFiltersButtons}>
              <Button
                variant="secondary"
                startIcon={<FilterListIcon />}
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}
              >
                <div className={classes.btnLabel}>Filter</div>
              </Button>
            </div>
          </div>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => {
              setAnchorEl(null);
            }}
            className={classes.popoverRoot}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <div className={classes.paddingRoot}>
              <div className={classes.allIssues}>
                <div className={classes.issueFiltersContainer}>
                  <Typography fontWeight={500}>Issue</Typography>
                  <div>
                    {ISSUE_FILTERS.map((issue) => (
                      <IssueFilterEntry
                        key={issue.key}
                        issue={issue}
                        issueFilter={issueFilter}
                        setIssueFilter={updateFilterAndTab}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Popover>
          <div className={classes.searchInput}>
            <SearchInput
              label="Investor, fund, or closing"
              setThrottledSearchQuery={setThrottledSearchQuery}
              clearSearch={clearSearch}
            />
          </div>
        </div>
        <Typography className={classes.fundCount}>
          {`${filteredTasks.length} total`}
        </Typography>
      </div>
      <TableRows
        unsortedTasks={filteredTasks}
        issueFilter={issueFilter}
        columns={tableColumns}
        clearFilters={clearFilters}
        throttledSearchQuery={throttledSearchQuery}
      />
    </>
  );
}
