import React from 'react';
import List from '@material-ui/core/List';

import { useWhiteLabelConfig } from 'services/providers/theme';
import { ExperienceSelectorItem } from './experience_selector_item';
import { getExperiences } from './utils';

export const ExperienceSelector = ({
  experience,
  setExperience,
  loading,
  exclude,
}) => {
  const config = useWhiteLabelConfig();
  const experiences = getExperiences(config.productName);

  return (
    <List>
      {experiences
        .filter((exp) => exp.value !== exclude)
        .map((exp) => (
          <ExperienceSelectorItem
            title={exp.title}
            subtitle={exp.subtitle}
            value={exp.value}
            onClick={(val) => setExperience(val)}
            selected={experience}
            disabled={loading}
          />
        ))}
    </List>
  );
};
