import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Chip, Icons } from '@passthrough/uikit';
import { UpdatedChip } from 'components/text_chip';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    columnGap: theme.spacing(1),
    rowGap: theme.spacing(1),
    flexWrap: 'wrap',
  },
  bottomSpacing: {
    marginBottom: theme.spacing(1),
  },
}));

export function DiligenceChipsDisplay({
  hasDraftComment,
  hasNewAlerts,
  numCommentEvents,
  isThreadResolved,
  numUnevaluatedMatches,
  isApproved,
  updated,
}) {
  const classes = useStyles();

  const hasUnevaluatedMatches = numUnevaluatedMatches > 0;
  const willDisplayDiligenceChip =
    hasDraftComment ||
    hasUnevaluatedMatches ||
    isApproved ||
    (numCommentEvents > 0 && !isThreadResolved) ||
    hasNewAlerts;

  return (
    <div
      className={clsx(classes.root, {
        [classes.bottomSpacing]: willDisplayDiligenceChip,
      })}
    >
      {hasNewAlerts ? (
        <Chip variant="warning" size="small" label="New alerts" />
      ) : null}
      {hasUnevaluatedMatches ? (
        <Chip
          variant="warning"
          size="small"
          label={`${numUnevaluatedMatches} potential match${
            numUnevaluatedMatches > 1 ? 'es' : ''
          }`}
        />
      ) : null}
      {numCommentEvents > 0 && !isThreadResolved && !hasDraftComment ? (
        <Chip
          variant="warning"
          size="small"
          label="Unresolved"
          icon={<Icons.ChatOutlined />}
        />
      ) : null}
      {updated ? <UpdatedChip /> : null}
      {hasDraftComment ? (
        <Chip
          variant="info"
          size="small"
          label="Unsent"
          icon={<Icons.ChatOutlined />}
        />
      ) : null}
      {isApproved ? (
        <Chip variant="success" size="small" label="Reviewed" />
      ) : null}
    </div>
  );
}
