import React from 'react';

import { Typography } from '@passthrough/uikit';

import { DiligenceTreeNav } from 'components/lp_doc/diligence/tree_nav_v2/index';

function addApprovalStateToReusedNodes(diligenceQuestions) {
  for (let i = 0; i < diligenceQuestions.length; i += 1) {
    const question = diligenceQuestions[i];
    const answers = question?.answer;

    if (answers?.idToReuse) {
      const originalQuestion = diligenceQuestions.find(
        (q) => q.label === answers.idToReuse,
      );
      if (originalQuestion) {
        question.isApproved = originalQuestion.isApproved;
      }
    }
  }

  return diligenceQuestions;
}

export function ReviewDiligenceTree({
  diligenceQuestions,
  jurisdiction,
  className,
}) {
  const questionsWithUpdatedApprovals =
    addApprovalStateToReusedNodes(diligenceQuestions);

  return (
    <div className={className}>
      <Typography variant="label" color="text.secondary">
        All owners
      </Typography>

      <DiligenceTreeNav
        diligenceQuestions={questionsWithUpdatedApprovals}
        jurisdiction={jurisdiction}
        isGPSide
        hideBackButton
      />
    </div>
  );
}
