import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';

export function NotifyCheckbox({ notify, setNotify }) {
  return (
    <FormControl>
      <FormControlLabel
        control={
          <Checkbox
            checked={notify}
            onChange={(e) => {
              setNotify(e.target.checked);
            }}
            name="notify_undo"
          />
        }
        label="Notify the investor"
      />
      <FormHelperText>
        If checked, the investor will be notified that they no longer need to
        provide additional information.
      </FormHelperText>
    </FormControl>
  );
}
