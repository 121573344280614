import React, { useState, useEffect } from 'react';
import { makeStyles, alpha } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { PauseDialog } from 'components/fund_external_access/pause_dialog';
import { InternalChip } from '@passthrough/uikit';
import { useFund } from 'services/providers/fund';
import * as api from 'services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}));

const useErrorSwitchStyles = makeStyles((theme) => ({
  switchBase: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: alpha(
        theme.palette.error.main,
        theme.palette.action.hoverOpacity,
      ),
    },
  },
  thumb: {
    backgroundColor: theme.palette.error.main,
  },
  track: {
    backgroundColor: theme.palette.error.main,
  },
}));

export function ExternalAccess() {
  const classes = useStyles();
  const errorSwitchClasses = useErrorSwitchStyles();
  const [fund, fundIsLoading] = useFund();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [externalAccessPaused, setExternalAccessPaused] = useState(false);

  function handleButtonClick() {
    setIsLoading(true);
    api
      .fundExternalAccess({
        id: fund.id,
        externalAccessPaused: !externalAccessPaused,
      })
      .then(() => {
        setDialogOpen(false);
        setIsLoading(false);
        // Wait before updating the paused state to prevent the dialog content
        // from updating while it's still visible.
        setTimeout(() => {
          setExternalAccessPaused(!externalAccessPaused);
        }, 200);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (!fundIsLoading) {
      setExternalAccessPaused(fund.externalAccessPaused);
    }
  }, [fundIsLoading]);

  if (fundIsLoading) {
    return null;
  }

  const switchClasses = externalAccessPaused ? errorSwitchClasses : undefined;

  return (
    <div className={classes.root}>
      <InternalChip />
      <FormControlLabel
        control={
          <Switch
            checked={!externalAccessPaused}
            onClick={() => setDialogOpen(true)}
            classes={switchClasses}
          />
        }
        label="External access"
      />
      <PauseDialog
        open={dialogOpen}
        isLoading={isLoading}
        handleClose={() => setDialogOpen(false)}
        handleClick={handleButtonClick}
        externalAccessPaused={externalAccessPaused}
      />
    </div>
  );
}
