import React from 'react';
import { Typography } from '@passthrough/uikit';

export function genTooltipText(noBoxes, areBoxesInvalid, signerName) {
  // 0 length string doesn't display a tooltip
  let tooltipText = '';

  if (noBoxes) {
    tooltipText = (
      <Typography variant="label">
        You must create at least one field
      </Typography>
    );
  }

  if (areBoxesInvalid) {
    tooltipText = (
      <Typography variant="label">
        You must create a field for {signerName}
      </Typography>
    );
  }

  return tooltipText;
}
