import React from 'react';
import 'react-diff-view/style/index.css';
import * as JSDiff from 'diff';
import { parseDiff, Diff, Hunk, Decoration } from 'react-diff-view';

const getDiffString = (oldObj, newObj) => {
  const result = JSDiff.diffJson(oldObj, newObj);

  const diffString = result.reduce((prev, next) => {
    if (next.added) {
      return `${prev}\n+${next.value.replaceAll('\n ', '\n+')}`;
    }
    if (next.removed) {
      return `${prev}\n-${next.value.replaceAll('\n ', '\n-')}`;
    }
    return prev + next.value;
  }, '');

  return String.raw`
--- draftData
+++ liveData
@@ -0,0 +0,0 @@
${diffString}`;
};

export const ReviewJSONDiff = ({ oldObj, newObj }) => {
  const data = getDiffString(oldObj, newObj);
  const files = parseDiff(data);
  const renderFile = ({
    oldPath,
    newPath,
    oldRevision,
    newRevision,
    type,
    hunks,
  }) => (
    <div key={`${oldRevision}-${newRevision}`} className="file-diff">
      <header className="diff-header">
        {oldPath === newPath ? oldPath : `${oldPath} -> ${newPath}`}
      </header>
      <Diff viewType="split" diffType={type} hunks={hunks}>
        {(h) =>
          h.map((hunk) => [
            <Decoration key={`deco-${hunk.content}`}>
              <div className="hunk-header">{hunk.content}</div>
            </Decoration>,
            <Hunk key={hunk.content} hunk={hunk} />,
          ])
        }
      </Diff>
    </div>
  );
  return <div>{files.map(renderFile)}</div>;
};
