/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import * as api from 'services/api';
import { useSession } from 'services/providers/session';

const TasksContext = React.createContext();

const areTasksEqual = (oldTasks, newTasks) => {
  if (!oldTasks && !newTasks) return true;
  if (!oldTasks || !newTasks) return false;
  if (oldTasks.length !== newTasks.length) return false;
  return oldTasks.every(
    (oldTask, index) => oldTask.key === newTasks[index].key,
  );
};

export function useTasks() {
  return useContext(TasksContext);
}

export function TasksProvider({ pollTasks, setPollTasks, ...props }) {
  const [tasks, setTasks] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [change, setChange] = useState(0);
  const session = useSession();

  function getTasks() {
    if (!pollTasks.current) {
      return;
    }
    api
      .tasks()
      .then((response) => {
        const newTasks = response.data;
        if (!areTasksEqual(tasks, newTasks)) {
          setTasks(newTasks);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status === 403) {
          session.showTimeoutDialog();
          setPollTasks(false);
        } else {
          throw error;
        }
      });
  }

  useEffect(getTasks, [change]);

  const refresh = useCallback(() => {
    setChange((c) => c + 1);
  }, []);

  const contextValue = useMemo(
    () => [tasks, isLoading, refresh],
    [tasks, isLoading, refresh],
  );

  return <TasksContext.Provider value={contextValue} {...props} />;
}
