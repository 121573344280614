import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Chip, Typography } from '@passthrough/uikit';
import SearchIcon from '@material-ui/icons/Search';
import { getIsAppleDevice } from 'services/utils';

const useStyles = makeStyles((theme) => ({
  chip: {
    marginLeft: theme.spacing(8),
  },
  searchBar: {
    marginLeft: theme.spacing(2),
  },
}));

export function NavSearchButton({ useMobileUI, setSearchModalOpen }) {
  const classes = useStyles();

  const openSearchModal = () => {
    setSearchModalOpen(true);
  };

  if (useMobileUI) {
    return (
      <Button variant="icon" onClick={openSearchModal} aria-label="Search">
        <SearchIcon />
      </Button>
    );
  }

  const isAppleDevice = getIsAppleDevice();

  return (
    <Button
      tabIndex={0}
      variant="secondary"
      startIcon={<SearchIcon color="action" />}
      aria-label="Search"
      data-test="search-input"
      className={classes.searchBar}
      onClick={openSearchModal}
    >
      <Typography color="text.secondary" size="small">
        Search
      </Typography>
      <Chip
        className={classes.chip}
        variant="neutral"
        label={isAppleDevice ? '⌘K' : 'Ctrl+K'}
        size="small"
      />
    </Button>
  );
}
