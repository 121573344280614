import React from 'react';
import { UserDisplay, Typography } from '@passthrough/uikit';
import {
  ShowCaseBox,
  ComponentShowCase,
  ShowCaseBoxRow,
  ShowCaseBoxColumn,
} from '../show_case';

export const UserDisplayShowCase = () => (
  <ComponentShowCase title="UserDisplay">
    <ShowCaseBox>
      <Typography align="center">
        <strong>Default</strong>
      </Typography>
      <ShowCaseBoxRow mt={4} gap={60}>
        <ShowCaseBoxColumn>
          <UserDisplay
            variant="default"
            name="John Doe"
            email="john@passthrough.com"
          />
        </ShowCaseBoxColumn>
        <ShowCaseBoxColumn>
          <UserDisplay variant="default" email="john@passthrough.com" />
        </ShowCaseBoxColumn>
      </ShowCaseBoxRow>
      <Typography align="center" mt={5}>
        <strong>Inline</strong>
      </Typography>
      <ShowCaseBoxRow mt={4} gap={80}>
        <ShowCaseBoxColumn>
          <UserDisplay
            variant="inline"
            name="John Doe"
            email="john@passthrough.com"
          />
        </ShowCaseBoxColumn>
        <ShowCaseBoxColumn>
          <UserDisplay variant="inline" email="john@passthrough.com" />
        </ShowCaseBoxColumn>
      </ShowCaseBoxRow>
    </ShowCaseBox>
  </ComponentShowCase>
);
