import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import isEqual from 'lodash/isEqual';

import * as api from 'services/api';
import { useFields } from 'components/document_fields/hooks';
import { useConfirm } from '@passthrough/uikit';

import { transformBoxesForPlacement } from './utils';
import { Editor } from './editor';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.default,
  },
}));

const DISCARD_CHANGES_CONFIRMATION = {
  title: 'Discard unsaved data?',
  description:
    'Your changes have not been saved. This action cannot be undone.',
  confirmationText: 'Discard',
  destructive: true,
};

export function PrepareSignaturePagesModal({
  open,
  handleClose,
  investor,
  onChange,
  hasFirstCounterSigner,
  hasSecondCounterSigner,
  hasThirdCounterSigner,
}) {
  const classes = useStyles();
  const [fileUrl, setFileUrl] = useState(null);
  const [documentFieldState, documentFieldActions] = useFields();
  const [initialBoxes, setInitialBoxes] = useState([]);
  const { fundId, closingId } = useParams();

  const { boxes } = documentFieldState;
  const boxesHaveChanged = !isEqual(boxes, initialBoxes);

  const subdoc = investor?.docs?.find((doc) => doc.isSubdoc);

  function fetchInitialBoxes() {
    if (!subdoc || !open) return;
    api
      .getBoxesForSignaturePlacement({
        fundId,
        closingId,
        lpDocumentId: subdoc.id,
      })
      .then((response) => {
        const boxesToSet = transformBoxesForPlacement(response.data.boxes);
        documentFieldActions.setBoxes(boxesToSet);
        setInitialBoxes(boxesToSet);
        setFileUrl(response.data.fileUrl);
      });
  }

  useEffect(fetchInitialBoxes, [open, fundId, closingId, subdoc?.id]);

  useEffect(() => {
    if (open && boxesHaveChanged) {
      const confirmForBrowser = (e) => {
        e.preventDefault();
        e.returnValue = '';
      };
      window.onbeforeunload = confirmForBrowser;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [open, boxesHaveChanged]);

  const confirm = useConfirm();

  const closeWithConfirmation = () => {
    if (boxesHaveChanged) {
      // TODO: Same range error issue as in send_to_countersigners_modal_v2/index.jsx
      confirm(DISCARD_CHANGES_CONFIRMATION)
        .then(handleClose)
        .catch(() => {});
    } else {
      handleClose();
    }
  };

  return (
    <Dialog
      fullScreen
      classes={{ paper: classes.paper }}
      open={open}
      onClose={closeWithConfirmation}
      TransitionProps={{
        onExited: () => {
          documentFieldActions.setBoxes([]);
        },
      }}
    >
      <Editor
        handleClose={closeWithConfirmation}
        handleCloseWithoutConfirmation={handleClose}
        investor={investor}
        hasFirstCounterSigner={hasFirstCounterSigner}
        hasSecondCounterSigner={hasSecondCounterSigner}
        hasThirdCounterSigner={hasThirdCounterSigner}
        documentFieldState={documentFieldState}
        documentFieldActions={documentFieldActions}
        onChange={onChange}
        fileUrl={fileUrl}
      />
    </Dialog>
  );
}
