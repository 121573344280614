import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';

const useDelClasses = makeStyles((theme) => ({
  del: {
    display: 'inline-block',
    textDecoration: 'line-through',
    textDecorationThickness: '2px',
    textDecorationColor: theme.palette.error.main,
    padding: '0 4px',
    color: theme.palette.text.secondary,
    '&:hover': {
      textDecoration: 'line-through',
      textDecorationThickness: '2px',
      textDecorationColor: theme.palette.error.main,
      color: theme.palette.text.secondary,
    },
  },
  thin: {
    textDecorationThickness: '1px',
    '&:hover': {
      textDecorationThickness: '1px',
    },
  },
}));

const useInsClasses = makeStyles((theme) => ({
  ins: {
    fontWeight: '500',
    display: 'inline-block',
    backgroundColor: theme.palette.primary.light,
    whiteSpace: 'pre-wrap',
    padding: '0 4px',
    borderRadius: '4px',
    textDecoration: 'none',
  },
}));

/**
 * A component that represents deleted or struck-out text or data.
 * https://mdn.io/del
 */
function Del({ children, thin, className, ...props }) {
  const classes = useDelClasses();
  return (
    <del
      {...props}
      className={clsx(className, classes.del, { [classes.thin]: thin })}
      role="deletion"
    >
      {children}
    </del>
  );
}

/**
 * A component that represents new text or data added to the document.
 * https://mdn.io/ins
 */
function Ins({ children, className, ...props }) {
  const classes = useInsClasses();

  return (
    <ins className={clsx(className, classes.ins)} {...props} role="insertion">
      {children}
    </ins>
  );
}

export function DiffStyle({ children, isDeleted, isNew, thin, ...props }) {
  if (isDeleted) {
    return (
      <Del thin={thin} {...props}>
        {children}
      </Del>
    );
  }
  if (isNew) {
    return <Ins {...props}>{children}</Ins>;
  }
  return <span {...props}>{children}</span>;
}
