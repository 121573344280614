import React from 'react';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  leftContent: {
    marginLeft: theme.spacing(2),
  },
  newText: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    margin: theme.spacing(3, 0, 2, 0),
  },
  originalText: {
    backgroundColor: theme.palette.warning.background,
    color: theme.palette.warning.text,
    margin: theme.spacing(3, 0, 2, 2),
  },
  questionDiff: {
    backgroundColor: theme.palette.primary.white,
    display: 'flex',
    justifyContent: 'space-between',
  },
  questionDiffCol: {
    display: 'flex',
    flexDirection: 'column',
    flex: '50%',
  },
  text: {
    width: 'fit-content',
    padding: theme.spacing(0, 1),
  },
}));

export const TagDiffPanel = ({ leftContent, rightContent }) => {
  const classes = useStyles();

  return (
    <Paper
      elevation={0}
      key="questionDiff"
      variant="outlined"
      className={classes.questionDiff}
    >
      <div key="left" className={classes.questionDiffCol}>
        <Paper
          elevation={0}
          className={clsx(classes.text, classes.originalText)}
        >
          Original
        </Paper>

        <div key="leftContent" className={classes.leftContent}>
          {leftContent}
        </div>
      </div>
      <Divider orientation="vertical" variant="middle" flexItem />
      <div key="right" className={classes.questionDiffCol}>
        <Paper elevation={0} className={clsx(classes.text, classes.newText)}>
          New
        </Paper>

        <div key="rightContent">{rightContent}</div>
      </div>
    </Paper>
  );
};
