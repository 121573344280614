import {
  APPROVED_STATUS,
  canPrepareSignaturePagesStatuses,
} from 'components/status/constants';
import { IMPLICIT_TAG_GROUPS } from './constants';

export function buildClosingStatusTag({ label, order, color }) {
  return {
    id: order,
    groupName: IMPLICIT_TAG_GROUPS.CLOSING_STATUS,
    name: label,
    color,
  };
}

export function buildRiskRatingTag(riskRating) {
  return {
    id: riskRating,
    groupName: IMPLICIT_TAG_GROUPS.RISK_RATING,
    name: riskRating,
  };
}

function getUsedCustomTagsPerGroup(investorTags) {
  return investorTags.reduce((acc, tag) => {
    const currGroup = tag.groupName;

    if (!acc[currGroup]) {
      acc[currGroup] = new Set([tag.name]);
    } else {
      acc[currGroup].add(tag.name);
    }

    return acc;
  }, {});
}

function getSelectedFiltersPerGroup(filters) {
  return filters.reduce((acc, filter) => {
    const currGroup = filter.groupName;
    const filterVal = filter.name;

    if (!acc[currGroup]) {
      acc[currGroup] = new Set([filterVal]);
    } else {
      acc[currGroup].add(filterVal);
    }

    return acc;
  }, {});
}

export function getFilteredInvestors(investors, filters) {
  if (filters.length === 0) {
    return investors;
  }

  const selectedTagsPerGroup = getSelectedFiltersPerGroup(filters);

  const selectedCustomTagGroupNames = Object.keys(selectedTagsPerGroup);
  const selectedImplicitTagsGroupNames =
    Object.getOwnPropertySymbols(selectedTagsPerGroup);
  const selectedTagGroupNames = [
    ...selectedCustomTagGroupNames,
    ...selectedImplicitTagsGroupNames,
  ];

  return investors.filter((i) => {
    const usedCustomTagsPerGroup = getUsedCustomTagsPerGroup(i.tags);

    for (let j = 0; j < selectedTagGroupNames.length; j += 1) {
      const selectedGroup = selectedTagGroupNames[j];
      const selectedTags = selectedTagsPerGroup[selectedGroup];

      if (selectedGroup === IMPLICIT_TAG_GROUPS.CLOSING_STATUS) {
        const { label: investorStatus } = i.statusInfo;

        if (!selectedTags.has(investorStatus)) {
          return false;
        }

        /* eslint-disable-next-line no-continue */
        continue;
      }

      if (selectedGroup === IMPLICIT_TAG_GROUPS.RISK_RATING) {
        const investorRiskRating = i.riskRating;

        if (!selectedTags.has(investorRiskRating)) {
          return false;
        }

        /* eslint-disable-next-line no-continue */
        continue;
      }

      if (!usedCustomTagsPerGroup[selectedGroup]) {
        return false;
      }

      const usedTags = usedCustomTagsPerGroup[selectedGroup];
      const tagIntersection = new Set(
        [...selectedTags].filter((x) => usedTags.has(x)),
      );
      if (tagIntersection.size === 0) {
        return false;
      }
    }

    return true;
  });
}

export const canRemoveDocsFromInvestor = (investor) =>
  investor?.sideletters.length + investor?.offerings.length > 0;

export function canPrepareSignaturePages(investor) {
  const subdoc = investor?.docs.find((doc) => doc.isSubdoc);
  return (
    canPrepareSignaturePagesStatuses.includes(investor?.status) &&
    subdoc &&
    subdoc.isOffline &&
    !subdoc.hasOnlineCountersigners
  );
}

export function mustPrepareSignaturePages(investor) {
  return investor?.status === APPROVED_STATUS && investor?.pendingPreparation;
}
