import React from 'react';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  labelIcon: {
    margin: theme.spacing(0, 1),
  },
  tag: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.grey,
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 1, 1, 0),
    paddingRight: theme.spacing(1),
  },
}));

export const QuestionTags = ({ questionTags }) => {
  const classes = useStyles();

  const tags = questionTags
    ? questionTags.map((t) => (
        <Tooltip title={t.platonic_question_text}>
          <Paper key={t.tag} elevation={0} className={classes.tag}>
            <LabelOutlinedIcon className={classes.labelIcon} /> {t.tag}
          </Paper>
        </Tooltip>
      ))
    : null;

  return tags;
};
