import { makeStyles } from '@material-ui/core';
import { Typography } from '@passthrough/uikit';
import { GPSelectionInput } from 'components/gp_selection_input';
import { Spinner } from 'components/spinner';
import React from 'react';
import { useFund } from 'services/providers/fund';

import { countersignerIsMember } from './helpers';

const COUNTERSIGNER_SELECTION_HEIGHT = 77;
const COUNTERSIGNER_SELECT_GAP = 16;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

function SelectCountersignersLoading({ countersigners }) {
  const classes = useStyles();

  const { countersigner, countersigner2, countersigner3 } = countersigners;
  const numberOfCountersigners = [
    countersigner,
    countersigner2,
    countersigner3,
  ].filter((c) => Boolean(c)).length;

  const height =
    COUNTERSIGNER_SELECTION_HEIGHT * numberOfCountersigners +
    COUNTERSIGNER_SELECT_GAP * (numberOfCountersigners - 1);
  return (
    <div className={classes.container}>
      <Typography variant="card-heading">Countersigners</Typography>
      <Spinner height={height} />
    </div>
  );
}

export function SelectCountersigners({
  closingSubDoc,
  members,
  countersigners,
  setCountersigners,
  countersignerErrors,
  setCountersignerErrors,
}) {
  const classes = useStyles();
  const [fund] = useFund();

  if (!closingSubDoc || !members)
    return <SelectCountersignersLoading countersigners={countersigners} />;

  const { countersigner, countersigner2, countersigner3 } = countersigners;
  const countersignerId = countersignerIsMember(countersigner, members)
    ? countersigner
    : null;
  const countersigner2Id = countersignerIsMember(countersigner2, members)
    ? countersigner2
    : null;
  const countersigner3Id = countersignerIsMember(countersigner3, members)
    ? countersigner3
    : null;
  const updateCountersigner1 = (value) => {
    setCountersignerErrors({
      ...countersignerErrors,
      countersigner: null,
    });
    setCountersigners({ ...countersigners, countersigner: value });
  };
  const updateCountersigner2 = (value) => {
    setCountersignerErrors({
      ...countersignerErrors,
      countersigner2: null,
    });
    setCountersigners({ ...countersigners, countersigner2: value });
  };
  const updateCountersigner3 = (value) => {
    setCountersignerErrors({
      ...countersignerErrors,
      countersigner3: null,
    });
    setCountersigners({ ...countersigners, countersigner3: value });
  };
  const { numberOfCountersigners } = closingSubDoc;
  const errorMsg = 'This field is required';

  return (
    <div className={classes.container}>
      <Typography variant="card-heading">Countersigners</Typography>
      {numberOfCountersigners > 0 ? (
        <GPSelectionInput
          label={closingSubDoc.countersigner1DisplayName}
          errorMsg={countersignerErrors?.countersigner ? errorMsg : null}
          value={countersignerId}
          setValue={updateCountersigner1}
          roleLabelNum={1}
          members={members}
          fundId={fund.id}
        />
      ) : null}
      {numberOfCountersigners > 1 ? (
        <GPSelectionInput
          label={closingSubDoc.countersigner2DisplayName}
          errorMsg={countersignerErrors?.countersigner2 ? errorMsg : null}
          value={countersigner2Id}
          setValue={updateCountersigner2}
          roleLabelNum={2}
          members={members}
          fundId={fund.id}
        />
      ) : null}
      {numberOfCountersigners > 2 ? (
        <GPSelectionInput
          label={closingSubDoc.countersigner3DisplayName}
          errorMsg={countersignerErrors?.countersigner3 ? errorMsg : null}
          value={countersigner3Id}
          setValue={updateCountersigner3}
          roleLabelNum={3}
          members={members}
          fundId={fund.id}
        />
      ) : null}
    </div>
  );
}
