import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { EmptyState } from 'components/empty_v2';
import { Button } from 'components/button';
import { PageContainer } from 'components/page_container';
import { Spinner } from 'components/spinner';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';

import { getAnswerDisplayComponent } from 'components/answer_displays/get_answer_display';
import * as urls from 'services/urls';
import * as api from 'services/api';

import { DataItem } from './data_item';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
  },
  content: {
    marginTop: theme.spacing(4),
  },
  paper: {
    width: '100%',
  },
  subtitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  jurisdictionAnswers: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
  diligenceNodeDisplay: {
    padding: theme.spacing(1),
  },
}));

function DiligenceJurisdictionDisplay({ jurisdiction, jurisdictionNodes }) {
  const classes = useStyles();
  const DiligenceAnswerComponent = getAnswerDisplayComponent(
    'DiligenceAnswerType.name_known',
  );

  return (
    <DataItem
      answerType={jurisdiction}
      answer={
        <List className={classes.jurisdictionAnswers}>
          {Object.keys(jurisdictionNodes)
            .filter((nodeId) => !jurisdictionNodes[nodeId]?.idToReuse)
            .map((nodeId) => (
              <Paper
                variant="outlined"
                key={nodeId}
                className={classes.diligenceNodeDisplay}
              >
                <DiligenceAnswerComponent
                  jurisdiction={jurisdiction}
                  answer={jurisdictionNodes[nodeId]}
                />
              </Paper>
            ))}
        </List>
      }
    />
  );
}

export const SavedDataPage = () => {
  const profileId = urls.useProfileId();
  const classes = useStyles();
  const history = useHistory();
  const [profile, setProfile] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const shouldShowDiligenceData =
    profile?.profilePastDiligenceAnswers &&
    Object.keys(profile.profilePastDiligenceAnswers).length > 0;

  React.useEffect(() => {
    api.profile({ id: profileId }).then((response) => {
      setProfile(response.data);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <Spinner fullScreen />;
  }

  return (
    <PageContainer maxWidth="lg" className={classes.root}>
      <Typography variant="h5">
        {`Saved data for ${profile.displayName}`}
      </Typography>
      <div className={classes.subtitleContainer}>
        <Typography>
          View all the answers and documents you provided to Passthrough
        </Typography>
        <div className={classes.viewSavedData}>
          <Button
            onClick={() => history.push(urls.manageProfile({ profileId }))}
          >
            Back to profile
          </Button>
        </div>
      </div>
      <Grid container className={classes.content}>
        <div className={classes.paper}>
          <Paper variant="outlined" className={classes.paper}>
            <List className={classes.list}>
              {profile.profilePastAnswers?.length ? (
                profile.profilePastAnswers.map((answer, currIndex) => {
                  const AnswerComponent = getAnswerDisplayComponent(
                    answer.answerType,
                  );

                  return (
                    <React.Fragment key={answer.questionText}>
                      {currIndex !== 0 ? <Divider /> : null}
                      <DataItem
                        answerType={answer.questionText}
                        answer={<AnswerComponent answer={answer.answer} />}
                      />
                    </React.Fragment>
                  );
                })
              ) : (
                <EmptyState title="No profile data" />
              )}

              {shouldShowDiligenceData
                ? Object.keys(profile.profilePastDiligenceAnswers).map(
                    (jurisdiction) => (
                      <>
                        <Divider />

                        <DiligenceJurisdictionDisplay
                          key={jurisdiction}
                          jurisdiction={jurisdiction}
                          jurisdictionNodes={
                            profile.profilePastDiligenceAnswers[jurisdiction]
                          }
                        />
                      </>
                    ),
                  )
                : null}
            </List>
          </Paper>
        </div>
      </Grid>
    </PageContainer>
  );
};
