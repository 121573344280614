import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from '@passthrough/uikit';
import { NoteDisplay } from './note_display';
import { REVIEW_STATE, noteFlags } from '../constants';
import { AddNoteForm } from './add_note_form';

const useStyles = makeStyles((theme) => ({
  threadDisplay: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  addNoteContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  oldNotes: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  responseNote: {
    marginLeft: theme.spacing(5),
  },
  errorText: {
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toggleAddNote: {
    marginTop: theme.spacing(2),
    float: 'right',
  },
  addNoteButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addNoteSelects: {
    display: 'flex',
  },
}));

export function ThreadDisplay({
  mostRecentNote,
  oldNotes,
  showAllNotes,
  createQuestionNote,
  updateQuestionNote,
  deleteQuestionNote,
  questionID,
  currentLog,
  reviewedQuestionIds,
  setReviewedQuestionIds,
  selectedIds,
}) {
  const [draftNoteText, setDraftNoteText] = useState('');
  const [flag, setFlag] = useState('');
  const [severity, setSeverity] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [showAddNote, setShowAddNote] = useState(true);
  const classes = useStyles();
  // default to "completed" because only time where currentLog shouldn't exist is
  // for already existing completed questionnaires
  const currentState = currentLog ? currentLog.state : 'completed';

  function cancelNote() {
    setIsAddingNote(false);
    setDraftNoteText('');
    setFlag('');
    setSeverity('');
  }

  function submitNote() {
    setIsLoading(true);
    setHasError(false);

    const sendFlag = flag === '' ? null : flag;
    const sendSeverity =
      severity === '' || flag !== noteFlags.NEEDS_REVIEW ? null : severity;

    const newNote = {
      note: draftNoteText,
      flag: sendFlag,
      severity: sendSeverity,
    };
    createQuestionNote(selectedIds, newNote)
      .then(() => {
        setIsLoading(false);
        setFlag('');
        setDraftNoteText('');
        setIsAddingNote(false);
        setSeverity('');
        if (
          currentState === REVIEW_STATE &&
          (sendFlag === noteFlags.NEEDS_REVIEW ||
            sendFlag === noteFlags.DISCUSS_WITH_CLIENT)
        ) {
          const currReviewedQuestions = [...reviewedQuestionIds];
          for (let i = 0; i < selectedIds.length; i += 1) {
            if (!currReviewedQuestions.includes(selectedIds[i])) {
              currReviewedQuestions.push(selectedIds[i]);
            }
          }
          setReviewedQuestionIds(currReviewedQuestions);
        }
      })
      .catch(() => {
        setIsLoading(false);
        setHasError(true);
      });
  }

  return (
    <Paper variant="outlined" className={classes.threadDisplay}>
      <Collapse in={showAllNotes} mountOnEnter unmountOnExit>
        <div className={classes.oldNotes}>
          {oldNotes.map((note) => (
            <NoteDisplay
              key={note.note_id}
              note={note}
              className={
                note.response_to ? classes.responseNote : classes.oldNote
              }
              currentLog={currentLog}
              createQuestionNote={createQuestionNote}
              updateQuestionNote={updateQuestionNote}
              deleteQuestionNote={deleteQuestionNote}
              questionID={questionID}
              showAllNotes={showAllNotes}
              setShowAddNote={setShowAddNote}
              reviewedQuestionIds={reviewedQuestionIds}
              setReviewedQuestionIds={setReviewedQuestionIds}
            />
          ))}
        </div>
      </Collapse>
      {mostRecentNote ? (
        <NoteDisplay
          className={
            showAllNotes && mostRecentNote.response_to
              ? classes.responseNote
              : ''
          }
          note={mostRecentNote}
          currentLog={currentLog}
          createQuestionNote={createQuestionNote}
          updateQuestionNote={updateQuestionNote}
          deleteQuestionNote={deleteQuestionNote}
          questionID={questionID}
          showAllNotes={showAllNotes}
          setShowAddNote={setShowAddNote}
          reviewedQuestionIds={reviewedQuestionIds}
          setReviewedQuestionIds={setReviewedQuestionIds}
        />
      ) : (
        <div>No notes yet.</div>
      )}
      {showAddNote ? (
        <Collapse in={showAllNotes} mountOnEnter unmountOnExit>
          {isAddingNote ? (
            <div className={classes.addNoteContainer}>
              <div className={classes.addNoteSelects}>
                <FormControl
                  variant="outlined"
                  className={classes.leftInput}
                  fullWidth
                >
                  <InputLabel id="flag">Flag</InputLabel>
                  <Select
                    label="Flag"
                    labelId="flag"
                    value={flag}
                    onChange={(e) => setFlag(e.target.value)}
                    classes={{ root: classes.select }}
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value={noteFlags.DISCUSS_WITH_CLIENT}>
                      Discuss with client
                    </MenuItem>
                    {currentState === REVIEW_STATE ? (
                      <MenuItem value={noteFlags.NEEDS_REVIEW}>
                        Needs review
                      </MenuItem>
                    ) : null}
                  </Select>
                </FormControl>
                {flag === noteFlags.NEEDS_REVIEW ? (
                  <FormControl
                    variant="outlined"
                    className={classes.rightInput}
                    fullWidth
                  >
                    <InputLabel id="severity">Severity</InputLabel>
                    <Select
                      label="Severity"
                      labelId="severity"
                      value={severity}
                      onChange={(e) => setSeverity(e.target.value)}
                    >
                      <MenuItem value="">None</MenuItem>
                      <MenuItem value="minor">Minor</MenuItem>
                      <MenuItem value="major">Major</MenuItem>
                    </Select>
                  </FormControl>
                ) : null}
              </div>
              <div className={classes.addNoteContainer}>
                <AddNoteForm
                  draftNoteText={draftNoteText}
                  setDraftNoteText={setDraftNoteText}
                  cancel={cancelNote}
                  disabled={!draftNoteText}
                  isLoading={isLoading}
                  submitNote={submitNote}
                  buttonLabel="Add note"
                  hasError={hasError}
                />
              </div>
            </div>
          ) : (
            <Button
              variant="primary"
              className={classes.toggleAddNote}
              onClick={() => {
                setIsAddingNote((prev) => !prev);
              }}
            >
              Add note
            </Button>
          )}
        </Collapse>
      ) : null}
    </Paper>
  );
}
