/* eslint-disable react/jsx-props-no-spreading, react/no-children-prop */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { useConfirm } from '@passthrough/uikit';

import { Button } from 'components/button';
import { useQuestionnaire } from 'services/providers/questionnaire';
import { useReconciliation } from './reconciliation/provider';
import { SAVING } from './saved';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      alignItems: 'inherit',
    },
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  confirmButton: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cancellationButton: {
    marginBottom: theme.spacing(2),
  },
}));

export function QuestionStepper({
  disabled,
  handleSubmit,
  saving,
  navRef,
  setShowNavWarning,
  hasAnswerChanged,
  hasInput,
  ReplacementNavigation,
  children,
  onClick,
}) {
  const classes = useStyles();
  const confirm = useConfirm();
  const nav = navRef.current;
  const { steps } = nav;
  const question = steps[nav.outerStep].questions[nav.innerStep];
  const fundName = nav?.lpDoc?.fundName;
  const {
    needsReconciliation,
    showReconciliationPage,
    resetReconciliationAnswer,
    setShowReconciliationPage,
  } = useReconciliation();
  const { useSupport } = useQuestionnaire();
  const { hasDraftComment } = useSupport();

  useEffect(() => {
    setShowNavWarning(hasAnswerChanged);
    return () => {
      setShowNavWarning(false);
    };
  }, [hasAnswerChanged]);

  function ContinueButton({ ...props }) {
    return (
      <Button
        id="continue"
        size="xl"
        fullWidth
        type="submit"
        variant="contained"
        loading={saving === SAVING}
        children={children || 'Continue'}
        {...props}
      />
    );
  }

  const shouldGoBackToReconciliation =
    needsReconciliation && !showReconciliationPage;

  const goBack = () => {
    // If the question has answers to reconcile we should show
    // the options again when the user clicks on "Back"
    if (shouldGoBackToReconciliation) {
      resetReconciliationAnswer();
      setShowReconciliationPage(true);
    } else {
      nav.decrement();
    }
  };

  const onBackClick = () => {
    if (hasAnswerChanged) {
      confirm({
        title: 'Discard unsaved changes?',
        description: 'Your changes have not been saved.',
        destructive: true,
        confirmationText: 'Discard',
      })
        .then(() => {
          goBack();
        })
        .catch(() => {});
    } else if (hasDraftComment) {
      confirm({
        title: 'Discard unsent comment?',
        description: 'Your comment has not been sent.',
        destructive: true,
        confirmationText: 'Discard',
      })
        .then(() => {
          goBack();
        })
        .catch(() => {});
    } else {
      goBack();
    }
  };

  const navNextRequired = () => {
    resetReconciliationAnswer();
    navRef.current.toNextRequired();
  };

  const shouldShowConfirm =
    question.needsConfirmation && !hasAnswerChanged && !question.isCompleted;

  const onNextClick = (e) => {
    e.preventDefault();

    if (shouldShowConfirm) {
      confirm({
        title: 'Continue without changes?',
        description: `${fundName} added a new comment to the thread.
            Do you want to continue without changing your answer?`,
        confirmationText: 'Continue',
        confirmationButtonProps: {
          'data-test': 'confirm-submission-of-unchanged-answer',
          variant: 'contained',
          className: classes.confirmButton,
        },
        cancellationButtonProps: {
          className: classes.cancellationButton,
        },
        destructive: true,
      })
        .then(() => {
          handleSubmit(e).then((returnVal) => {
            if (!(returnVal instanceof Error)) {
              navNextRequired();
            }
          });
        })
        .catch(() => {});
    } else if (hasDraftComment) {
      confirm({
        title: 'Discard unsent comment?',
        description: 'Your comment has not been sent.',
        destructive: true,
        confirmationText: 'Discard',
      })
        .then(() => {
          goBack();
        })
        .catch(() => {});
    } else {
      handleSubmit(e).then((returnVal) => {
        if (!(returnVal instanceof Error)) {
          navNextRequired();
        }
      });
    }
  };

  const disabledOptionalQuestion = hasInput ? disabled : false;
  const disableButton = question.isRequired
    ? disabled
    : disabledOptionalQuestion;

  const showPrevButton =
    nav.innerStep > 0 || nav.outerStep > 0 || shouldGoBackToReconciliation;

  return (
    <div className={classes.root}>
      <div key="back">
        <Button
          name="back"
          style={{ visibility: showPrevButton ? '' : 'hidden' }}
          variant="text"
          color="default"
          size="xl"
          fullWidth
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={onBackClick}
        >
          Back
        </Button>
      </div>
      <div className={classes.rightSide}>
        {ReplacementNavigation ? (
          <ReplacementNavigation
            onNextClick={onNextClick}
            navNext={navNextRequired}
            disableButton={disableButton}
            ContinueButton={ContinueButton}
            saving={saving}
          />
        ) : (
          <ContinueButton
            disabled={disableButton}
            onClick={onClick || onNextClick}
          />
        )}
      </div>
    </div>
  );
}
