import * as constants from '../../constants';

export const USA_V2_REQUIREMENTS = {
  [constants.GROUP_NATURAL_PERSONS]: {
    [constants.TYPE_INDIVIDUAL]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(constants.TYPE_VALID_ID),
          helpText:
            "Provide a valid driver's license, passport or national identity card",
        },
        {
          ...constants.genDocumentKeyAndLabel(constants.TYPE_PROOF_OF_ADDRESS),
          helpText:
            'Provide a utility bill, bank statement or similar dated within the last 12 months. A utility bill dated in the last 3 months is preferable, and may be required for non-US funds. This data is strictly used for address matching.',
        },
      ],
      owners: [],
    },
    [constants.TYPE_IRA]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(
            constants.TYPE_PARTICIPANT_STATEMENT_REPORT_OR_QUARTERLY_ACCT,
          ),
          helpText:
            'Provide a participant statement report or quarterly account statement from for the IRA',
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_BENEFICIARIES),
          helpText:
            'Add the names of any non-minor beneficiaries of the IRA, including the IRA account holder',
        },
      ],
    },
    [constants.TYPE_SOLE_PROPRIETORSHIP]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(
            constants.TYPE_COPY_OF_BUSINESS_LICENSES,
          ),
          helpText:
            'Provide copies of the sole proprietorship’s business licenses',
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_SOLE_PROPRIETOR),
          helpText:
            'Add the name of the sole proprietor of the sole proprietorship',
        },
      ],
    },
    [constants.TYPE_JOINT_ACCOUNT_OR_TENANCY]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(
            constants.TYPE_LIST_ACCOUNT_OWNERS,
          ),
          helpText:
            'Provide a statement dated in the last 3 months showing all account holders of the joint account',
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_HOLDERS),
          helpText:
            'Add the names of the joint account holders or joint tenants',
          minNum: 2,
        },
      ],
    },
  },
  [constants.GROUP_TRUST]: {
    [constants.TYPE_SPOUSAL_TRUST]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(
            constants.TYPE_TRUST_CERTIFICATE_OR_AGREEMENT,
          ),
          helpText:
            'Provide either a copy of the trust’s Trust Certificate or Trust Agreement',
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_TRUSTEES),
          helpText:
            'Add the names of all trustees of the spousal trust, as established in your trust agreement or trust certificate',
        },
        {
          ...constants.genOwnerKeyAndLabels(
            constants.TYPE_NON_MINOR_BENEFICIARIES,
          ),
          helpText:
            'Add the names of any beneficiaries of the trust that are not minors',
        },
      ],
    },
    [constants.TYPE_OTHER_TRUST]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(constants.TYPE_TRUST_AGREEMENT),
          helpText: 'Provide a copy of the trust’s Trust Agreement',
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_TRUSTEES),
          helpText:
            'Add the names of all trustees of the trust, as established in your trust agreement',
        },
        {
          ...constants.genOwnerKeyAndLabels(
            constants.TYPE_NON_MINOR_BENEFICIARIES,
          ),
          helpText:
            'Add the names of any beneficiaries of the trust that are not minors',
        },
      ],
    },
  },
  [constants.GROUP_COMPANY]: {
    [constants.TYPE_LLC]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(
            constants.TYPE_AOO_OR_OPERATING_AGREEMENT,
          ),
          helpText:
            'Provide a copy of the LLC’s Articles of Organization or Operating Agreement',
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_MANAGING_MEMBERS),
          helpText:
            'Add the names of all managing members of the LLC, as established in your operating agreement or other governing document',
        },
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_BENEFICIAL_OWNERS),
          helpText:
            'Add the names of any beneficial owners of 25% or more of the Limited Liability Company',
        },
      ],
    },
    [constants.TYPE_OTHER_PRIVATE_CORPORATION]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(
            constants.TYPE_COI_AOA_OR_COPY_OF_BUSINESS_LICENSES,
          ),
          helpText:
            'Provide a copy of the corporation’s Certificate of Incorporation, Articles of Association, or any business licenses',
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(
            constants.TYPE_DIRECTORS_SIGNATORIES_CONTROLLING_PERSONS,
          ),
          helpText:
            'Add the names of any directors, signatories, or persons having substantial control over any relevant matters of the private corporation',
        },
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_BENEFICIAL_OWNERS),
          helpText:
            'Add the names of any beneficial owners of 25% or more of the private corporation',
        },
      ],
    },
    [constants.TYPE_SUBSIDIARY_OR_AFFILIATE]: {
      documents: [],
      owners: [
        {
          key: constants.TYPE_ULTIMATE_BENEFICIAL_OWNERS,
          singularLabel: 'Ultimate beneficial owner company',
          pluralLabel: 'Ultimate beneficial owner companies',
          helpText:
            'Add the name of the ultimate beneficial owner company that is listed on a U.S. stock exchange (NASDAQ, NYSE, or AMEX)',
        },
      ],
    },
    [constants.TYPE_LISTED_COMPANY]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(constants.TYPE_SEC_FILING),
          helpText:
            'Provide the latest 10-K, 10-Q, or 8-K for the listed company',
        },
      ],
      owners: [],
    },
    [constants.TYPE_PARTNERSHIP]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(
            constants.TYPE_PARTNERSHIP_AGREEMENT_OR_COPY_OF_BUSINESS_LICENSES,
          ),
          helpText:
            'Provide a copy of a Partnership Agreement or copies of any business licenses you have for the partnership',
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_BENEFICIAL_OWNERS),
          helpText:
            'Add the names of any beneficial owners of 25% or more, including limited partners, of the partnership',
        },
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_PARTNERS),
          helpText: 'Add the names of all general partners of the partnership',
        },
        {
          ...constants.genOwnerKeyAndLabels(
            constants.TYPE_DIRECTORS_SIGNATORIES_CONTROLLING_PERSONS,
          ),
          helpText:
            'Add the names of any directors, signatories, or persons having substantial control over any relevant matters of the partnership',
        },
      ],
    },
  },
  [constants.GROUP_OTHER]: {
    [constants.TYPE_PENSION_PLAN]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(
            constants.TYPE_CERTIFICATE_OF_INCUMBENCY,
          ),
          helpText: 'Provide a Certificate of Incumbency for the pension plan',
        },
        {
          ...constants.genDocumentKeyAndLabel(
            constants.TYPE_STATEMENT_OF_FINANCIAL_CRIMES_COMPLIANCE,
          ),
          helpText:
            'Provide a signed statement by the individual overseeing compliance for the pension plan indicating compliance with rules and regulations related to financial crimes',
        },
        {
          ...constants.genDocumentKeyAndLabel(
            constants.TYPE_PENSION_PLAN_DOCUMENT,
          ),
          helpText: '',
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_PRINCIPALS),
          helpText: 'Add the names of any principals of the pension plan',
        },
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_DIRECTORS),
          helpText: 'Add the names of any directors of the pension plan',
        },
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_TRUSTEES),
          helpText:
            'Add the names of any trustees of the pension plan, if applicable',
        },
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_THIRD_PARTY_ADMIN),
          helpText:
            'Add the name of the third party administrator of the pension plan, if one exists',
        },
      ],
    },
    [constants.TYPE_NON_PROFIT]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(
            constants.TYPE_IRS_LETTER_COF_OR_AOA,
          ),
          helpText:
            'Provide either a copy of the non-profit’s IRS Letter Granting Tax-Exempt Status, Certificate of Formation, or Articles of Association',
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(
            constants.TYPE_DIRECTORS_SIGNATORIES_CONTROLLING_PERSONS,
          ),
          helpText:
            'Add the names of any directors, signatories, or persons having substantial control over any relevant matters of the non-profit organization',
        },
      ],
    },
  },
};
