import React from 'react';
import { Alert } from 'components/alert';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  alert: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

function missingLogo(classes, fundStatus) {
  if (!fundStatus?.healthChecks?.missingLogo) {
    return null;
  }

  return (
    <Alert severity="error" className={classes.alert}>
      {fundStatus.name} is missing a logo.
    </Alert>
  );
}

export const FundStatusAlerts = ({ fundStatus }) => {
  const classes = useStyles();

  return <>{missingLogo(classes, fundStatus)}</>;
};
