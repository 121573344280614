import React from 'react';
import { Typography } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';

import LabelIcon from '@material-ui/icons/LabelOutlined';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  groupValue: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: '4px',
  },
  nameDisplay: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing(1),
  },
  wrappedText: {
    overflowWrap: 'anywhere',
  },
}));

export function GenericTagRow({
  value,
  Icon = <LabelIcon />,
  isChecked,
  indeterminate = false,
  onChange,
}) {
  const classes = useStyles();

  return (
    <div className={classes.groupValue}>
      <div className={classes.nameDisplay}>
        {Icon}

        <Typography className={classes.wrappedText}>{value}</Typography>
      </div>

      <Checkbox
        checked={isChecked}
        indeterminate={indeterminate}
        // the checkbox onChange callback does not reliably execute
        // so rely on the onClick handler instead
        onClick={onChange}
      />
    </div>
  );
}

export function GenericInvestorTagGroup({
  tagGroupName,
  tags,
  getDisplayLabel = (tag) => tag.name,
  getIcon = () => <LabelIcon />,
  locallySelectedFilters,
  setLocallySelected,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {tagGroupName ? (
        <Typography
          variant="label"
          color="text.secondary"
          className={classes.wrappedText}
        >
          {tagGroupName}
        </Typography>
      ) : null}

      {tags.map((tag) => {
        const isChecked = locallySelectedFilters.some((f) => f.id === tag.id);

        return (
          <GenericTagRow
            key={tag.id}
            value={getDisplayLabel(tag)}
            Icon={getIcon(tag)}
            isChecked={isChecked}
            onChange={(e) => {
              setLocallySelected(e, tag, isChecked);
            }}
          />
        );
      })}
    </div>
  );
}
