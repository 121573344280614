import React from 'react';
import { Typography } from '@passthrough/uikit';

export function DataPoint({ label, value }) {
  return (
    <div>
      <div>
        <Typography variant="label" size="medium" color="text.secondary">
          {label}
        </Typography>
      </div>

      <Typography>{String(value)}</Typography>
    </div>
  );
}
