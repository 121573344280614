import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { COUNTRIES } from 'services/countries';
import { VisuallyHidden } from './utils';

const COUNTRY_BY_LABEL = new Map(COUNTRIES.map((c) => [c.label, c]));
const COUNTRY_BY_CODE = new Map(COUNTRIES.map((c) => [c.code, c]));

export function getCountry(code) {
  return COUNTRY_BY_CODE.get(code) || COUNTRY_BY_LABEL.get(code);
}

export function CountryInput({
  frontendLabel,
  value,
  handleChange,
  errorMsg,
  autoFocus = false,
  enableAutofill = false,
  hideLabels = false,
  ...params
}) {
  const foundCountry = getCountry(value);

  // hack to support country values that are not in the list
  const [enableFreeSolo, setEnableFreeSolo] = React.useState(
    Boolean(value && !foundCountry),
  );

  function getOptionLabel(option) {
    let country;

    if (typeof option === 'string') {
      country = COUNTRIES.find((c) => c.code === option);
      if (!country) {
        country = { label: option, code: option };
      }
    } else {
      country = option;
    }

    if (country) {
      return country.label;
    }

    return '';
  }

  return (
    <>
      {enableAutofill && (
        <VisuallyHidden
          component="input"
          value={foundCountry?.code || value || ''}
          onChange={(e) => {
            const country = getCountry(e.target.value);
            if (country) {
              setEnableFreeSolo(false);
            }
            handleChange(e, country);
          }}
          autoComplete="country"
        />
      )}
      <Autocomplete
        {...params}
        options={COUNTRIES}
        autoHighlight
        selectOnFocus
        freeSolo={enableFreeSolo}
        value={foundCountry?.code || value || null}
        onChange={handleChange}
        onInputChange={() => {
          if (enableFreeSolo) {
            setEnableFreeSolo(false);
          }
        }}
        getOptionLabel={getOptionLabel}
        getOptionSelected={(option, val) =>
          option.code === val || option.label === val
        }
        renderOption={(option) => (
          <>
            {option.label} {hideLabels ? null : `(${option.code})`}
          </>
        )}
        renderInput={(otherParams) => (
          <TextField
            {...otherParams}
            label={frontendLabel || 'Country'}
            variant="outlined"
            autoFocus={autoFocus}
            error={!!errorMsg}
            helperText={errorMsg}
            inputProps={{
              ...otherParams.inputProps,
              autoComplete: 'chrome-off',
              // avoiding id to thwart chrome autofill
              'data-test': 'country',
            }}
          />
        )}
      />
    </>
  );
}
