import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

export const ItemSection = ({ item }) => (
  <ListItem key={item.id}>
    <ListItemText>
      <strong>{item.sectionName}</strong>
    </ListItemText>
  </ListItem>
);
