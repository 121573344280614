import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, InternalChip, Typography } from '@passthrough/uikit';

import { Spinner } from 'components/spinner';
import { useMe } from 'services/providers/me';
import { useFund, useFundEdit } from 'services/providers/fund';
import { RiskRatingSelectionBanner } from 'components/risk_rating/risk_rating_selection_banner';
import { ReviewDiligenceTree } from './review_diligence_tree/index';
import { DiligenceCardsReview } from './diligence_cards_review/index';

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: theme.spacing(3),
  },
  riskRating: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  riskRatingText: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  flexBox: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  smallFlex: {
    flex: 1,
    marginRight: theme.spacing(2),
  },
  bigFlex: {
    flex: 3,
  },
}));

export function DiligenceSubmissionOverview({
  userIsADiligenceApprover,
  diligenceApproverName,
  showSpinner,
  riskChoice,
  changeRiskChoice,
  pendingUpdate,
  shouldDisplayTree,
  jurisdiction,
  annotatedNodes,
  originalDiligenceQuestions,
  onShowReviewClick,
  areSearchesReady,
  isInProgress,
}) {
  const classes = useStyles();
  const [fund] = useFund();
  const [fundEdit] = useFundEdit();
  const [me] = useMe();

  const isSelfServiceOrStaff = !fund?.managedDiligenceEnabled || me.isStaff;
  // allow risk rating for self service or staff
  // user managed service with edit access
  const canSeeRiskRating =
    userIsADiligenceApprover && isSelfServiceOrStaff && fundEdit;

  return (
    <>
      {!userIsADiligenceApprover ? (
        <div className={classes.alert}>
          <Alert severity="info">
            {diligenceApproverName
              ? `${diligenceApproverName} is the assigned diligence reviewer. You
                do not have permission to review owners.`
              : `You do not have permission to review owners. Assign a diligence reviewer to review owners.`}
          </Alert>
        </div>
      ) : null}

      {showSpinner ? (
        <Spinner height={200} />
      ) : (
        <div>
          {canSeeRiskRating ? (
            <div className={classes.riskRating}>
              <Typography
                variant="card-heading"
                size="large"
                className={classes.riskRatingText}
              >
                Risk rating
                {fund?.managedDiligenceEnabled ? <InternalChip /> : null}
              </Typography>

              <RiskRatingSelectionBanner
                riskChoice={riskChoice}
                changeRiskChoice={changeRiskChoice}
                pendingUpdate={pendingUpdate}
              />
            </div>
          ) : null}
          <div className={classes.flexBox}>
            {shouldDisplayTree ? (
              <ReviewDiligenceTree
                jurisdiction={jurisdiction}
                className={classes.smallFlex}
                diligenceQuestions={annotatedNodes}
              />
            ) : null}

            <DiligenceCardsReview
              diligenceQuestions={originalDiligenceQuestions}
              onShowReviewClick={onShowReviewClick}
              className={classes.bigFlex}
              areSearchesReady={areSearchesReady}
              isInProgress={isInProgress}
            />
          </div>
        </div>
      )}
    </>
  );
}
