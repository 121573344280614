import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { Typography, Alert } from '@passthrough/uikit';
import { useSmsOtpTimer } from 'components/resend_verification_button';
import * as api from 'services/api';
import { getSingleErrorFromResponse } from 'services/utils';
import { SignInPageContainer } from 'components/sign_in_page_container';

import * as constants from './constants';
import { InitialStep } from './initial_step';
import { PasswordStep } from './password_step';
import { SupportArticleLink } from './support_article_link';
import { SelectMethodStep } from './select_method_step';
import { TOTPSetupStep } from './totp_setup_step';
import { SmsSetupStep } from './sms_setup_step';
import { FinalStep } from './final_step';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    maxWidth: '460px',
    textAlign: 'center',
    gap: theme.spacing(4),
  },
  headerContainer: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
  },
  miscAlertContainer: {
    width: '100%',
  },
}));

const INITIAL_STEP = 1;
const PASSWORD_STEP = 2;
const SELECT_METHOD_STEP = 3;
const TOTP_SETUP_STEP = 4;
const SMS_SETUP_STEP = 5;
const FINAL_STEP = 10;

export function MFASetupFlow({ me }) {
  const [step, setStep] = useState(INITIAL_STEP);
  const [loading, setLoading] = useState(false);
  const [method, setMethod] = useState(null);
  const [methodError, setMethodError] = useState(null);
  const [miscError, setMiscError] = useState(null);

  // Password
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [passwordError, setPasswordError] = useState(null);
  const [retypePasswordError, setRetypePasswordError] = useState(null);
  const [passwordScore, setPasswordScore] = useState(null);
  const [passwordWarning, setPasswordWarning] = useState(null);
  const [passwordSuggestions, setPasswordSuggestions] = useState([]);

  // TOTP
  const [totpDeviceId, setTotpDeviceId] = useState(null);
  const [totpSecret, setTotpSecret] = useState(null);
  const [qrcodePngBase64, setQrcodePngBase64] = useState(null);
  const [totpCode, setTotpCode] = useState('');
  const [totpCodeError, setTotpCodeError] = useState('');

  // SMS
  const [smsDeviceId, setSmsDeviceId] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [smsOtpCode, setSmsOtpCode] = useState('');
  const [smsOtpCodeError, setSmsOtpCodeError] = useState('');
  const [resendDisabled, setResendDisabled] = useState(false);
  const [smsSetupLoading, setSmsSetupLoading] = useState(false);
  const { timeoutText, allowSend, onSend } = useSmsOtpTimer({
    onEnd: () => setResendDisabled(false),
  });

  const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
  const phoneIsValid = parsedPhoneNumber?.isValid();

  const resetFormState = () => {
    setTotpDeviceId(null);
    setTotpSecret(null);
    setQrcodePngBase64(null);
    setTotpCode('');
    setTotpCodeError('');
    setSmsDeviceId(null);
    setPhoneNumber('');
    setPhoneNumberError('');
    setSmsOtpCode('');
    setSmsOtpCodeError('');
  };

  const backToMethodStep = () => {
    resetFormState();
    setStep(SELECT_METHOD_STEP);
  };

  const backToPasswordStep = () => {
    setMethod(null);
    setMethodError(null);
    setStep(PASSWORD_STEP);
  };

  const classes = useStyles();

  // Clear method error when a method is selected
  useEffect(() => {
    if (method) {
      setMethodError(null);
    }
  }, [method]);

  // Clear error alert when changing steps
  useEffect(() => {
    setMiscError(null);
  }, [step]);

  // Clear SMS device ID when the phone number changes
  useEffect(() => {
    setSmsDeviceId(null);
  }, [phoneNumber]);

  function checkPassword() {
    return api.checkPassword({
      firstName: me.firstName,
      lastName: me.lastName,
      password,
    });
  }

  useEffect(() => {
    if (!password) {
      setPasswordScore(null);
      setPasswordError('');
      return;
    }

    checkPassword()
      .then((response) => {
        const { score, feedback } = response.data;
        setPasswordScore(score);
        setPasswordWarning(feedback.warning);
        setPasswordSuggestions(feedback.suggestions);
      })
      .catch((error) => {
        if (error?.response?.data?.password) {
          setPasswordError(error.response.data.password);
        }
      });
  }, [password, me]);

  function createTotpDevice() {
    setLoading(true);
    api
      .createTotpDevice()
      .then((response) => {
        setTotpDeviceId(response.data.deviceId);
        setTotpSecret(response.data.secret);
        setQrcodePngBase64(response.data.qrcodePngBase64);
        setStep(TOTP_SETUP_STEP);
      })
      .catch((error) => {
        setMiscError(getSingleErrorFromResponse(error.response));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function createSmsDevice() {
    if (!phoneIsValid) {
      setPhoneNumberError('Enter a valid phone number.');
      return;
    }

    setLoading(true);
    setResendDisabled(true);
    setPhoneNumberError('');
    api
      .createSmsDevice({ phoneNumber })
      .then((response) => {
        onSend();
        setSmsDeviceId(response.data.deviceId);
        setSmsOtpCode('');
        setSmsOtpCodeError('');
      })
      .catch((error) => {
        setPhoneNumberError(getSingleErrorFromResponse(error.response));
        setResendDisabled(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleMethodSubmit() {
    switch (method) {
      case constants.TOTP_METHOD:
        createTotpDevice();
        break;
      case constants.SMS_METHOD:
        setStep(SMS_SETUP_STEP);
        break;
      default:
        // No method selected
        setMethodError('Select a method to continue.');
    }
  }

  function setupTotpDevice() {
    setLoading(true);
    api
      .setupTotpDevice({
        deviceId: totpDeviceId,
        totpCode,
        password,
        retypePassword,
      })
      .then(() => {
        setStep(FINAL_STEP);
      })
      .catch((error) => {
        if (error?.response?.data?.totpCode) {
          setTotpCodeError(error.response.data.totpCode);
        } else {
          setMiscError(getSingleErrorFromResponse(error.response));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function setupSmsDevice() {
    setSmsSetupLoading(true);
    setSmsOtpCodeError('');
    api
      .setupSmsDevice({
        deviceId: smsDeviceId,
        smsOtpCode,
        password,
        retypePassword,
      })
      .then(() => {
        setStep(FINAL_STEP);
      })
      .catch((error) => {
        if (error?.response?.data.smsOtpCode) {
          setSmsOtpCodeError(error.response.data.smsOtpCode);
        } else {
          setSmsOtpCodeError(getSingleErrorFromResponse(error.response));
        }
      })
      .finally(() => {
        setSmsSetupLoading(false);
      });
  }

  function handlePasswordStepContinue() {
    setLoading(true);
    checkPassword()
      .then((response) => {
        const { score } = response.data;
        const passingScore = score >= 3;
        const passwordsMatch = password === retypePassword;

        if (passingScore && passwordsMatch) {
          setStep(SELECT_METHOD_STEP);
          return;
        }

        if (!passingScore) {
          setPasswordError('Password is not strong enough.');
        }
        if (!passwordsMatch) {
          setRetypePasswordError('Passwords do not match.');
        }
      })
      .catch((error) => {
        if (error?.response?.data?.password) {
          setPasswordError(error.response.data.password);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <SignInPageContainer>
      <div className={classes.container}>
        <div className={classes.miscAlertContainer}>
          {miscError ? <Alert severity="error">{miscError}</Alert> : null}
        </div>
        {step !== FINAL_STEP ? (
          <div className={classes.headerContainer}>
            <Typography variant="section-heading">
              Enable two-factor authentication (2FA)
            </Typography>
            {step !== INITIAL_STEP && step !== FINAL_STEP ? (
              <SupportArticleLink />
            ) : null}
          </div>
        ) : null}
        <div>
          {step === INITIAL_STEP ? (
            <InitialStep
              fundNameRequiringMfa={me.fundNameRequiringMfa}
              onContinue={() => setStep(PASSWORD_STEP)}
            />
          ) : null}
          {step === PASSWORD_STEP ? (
            <PasswordStep
              loading={loading}
              password={password}
              passwordError={passwordError}
              setPassword={setPassword}
              retypePassword={retypePassword}
              retypePasswordError={retypePasswordError}
              setRetypePassword={setRetypePassword}
              passwordScore={passwordScore}
              passwordWarning={passwordWarning}
              passwordSuggestions={passwordSuggestions}
              onContinue={handlePasswordStepContinue}
            />
          ) : null}
          {step === SELECT_METHOD_STEP ? (
            <SelectMethodStep
              loading={loading}
              method={method}
              setMethod={setMethod}
              methodError={methodError}
              onContinue={handleMethodSubmit}
              onBack={backToPasswordStep}
            />
          ) : null}
          {step === TOTP_SETUP_STEP ? (
            <TOTPSetupStep
              loading={loading}
              totpSecret={totpSecret}
              qrcodePngBase64={qrcodePngBase64}
              totpCode={totpCode}
              setTotpCode={setTotpCode}
              totpCodeError={totpCodeError}
              onSubmit={setupTotpDevice}
              onBack={backToMethodStep}
            />
          ) : null}
          {step === SMS_SETUP_STEP ? (
            <SmsSetupStep
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              phoneNumberError={phoneNumberError}
              resendDisabled={resendDisabled}
              timeoutText={timeoutText}
              allowSend={allowSend}
              smsOtpCode={smsOtpCode}
              setSmsOtpCode={setSmsOtpCode}
              smsOtpCodeError={smsOtpCodeError}
              smsDeviceId={smsDeviceId}
              smsSetupLoading={smsSetupLoading}
              onCreateDevice={createSmsDevice}
              onSubmit={setupSmsDevice}
              onBack={backToMethodStep}
              createLoading={loading}
            />
          ) : null}
          {step === FINAL_STEP ? <FinalStep method={method} /> : null}
        </div>
      </div>
    </SignInPageContainer>
  );
}
