import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { TextWithContextLinks } from './text_with_context_links';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    minHeight: '250px',
    whiteSpace: 'pre-wrap',
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  short: {
    minHeight: '100px',
  },
}));

export function Question({
  question,
  links,
  short,
  prefixText,
  children,
  pdfBoxes = [],
}) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.short]: short,
      })}
    >
      <TextWithContextLinks
        prefixText={prefixText}
        text={question}
        allLinksForQuestion={links}
        allPdfBoxesForQuestion={pdfBoxes}
      />
      {children}
    </div>
  );
}
