import React, { useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Alert,
  Typography,
  Link,
  Icons,
  UIKitSettingsProvider,
} from '@passthrough/uikit';

import { NavBar } from 'components/nav_bar';
import { PageContainer } from 'components/page_container';

import { DeliveryOptionStep } from './delivery_option_step';
import { DataEntryStep } from './data_entry_step';
import { ReviewStep } from './review_step';

import {
  DELIVERY_OPTION_BULK_PACKET,
  DELIVERY_OPTION_INDIVIDUAL_PACKETS,
  STEP_DATA_ENTRY,
  STEP_REVIEW,
  STEP_DELIVERY_OPTION,
} from './constants';
import {
  getCountersignerDataFromInvestors,
  collectLpDocumentsFromSubdocs,
  countersignerIsMember,
} from './helpers';

const useStyles = makeStyles((theme) => ({
  alert: {
    whiteSpace: 'pre-line',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    margin: 'auto',
    width: 'fit-content',
  },
}));

const SUPPORT_URL =
  'https://support.passthrough.com/bulk-countersigning-B1pQy2qc6';

function investorIsPartiallyCountersigned(investor) {
  return investor.docs.some((doc) => doc.partiallyCountersigned);
}

function SectionHeading() {
  return (
    <div>
      <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
        <Typography variant="section-heading">Send to countersigner</Typography>
        <Typography variant="body">
          Send investors for countersigning to fully execute subscription
          documents.{' '}
          <Link href={SUPPORT_URL} inline variant="external">
            Learn more
          </Link>
        </Typography>
      </UIKitSettingsProvider>
    </div>
  );
}

function getInitialCountersigners(countersignerData) {
  if (!countersignerData) return {};
  const initialCountersigners = {};
  const { first, second, third } = countersignerData.signers;
  if (first.length === 1) {
    initialCountersigners.countersigner = first[0].id;
  }
  if (second.length === 1) {
    initialCountersigners.countersigner2 = second[0].id;
  }
  if (third.length === 1) {
    initialCountersigners.countersigner3 = third[0].id;
  }
  return initialCountersigners;
}

function isInvestorSubdocFullyExecuted(investor) {
  return investor.docs.some((doc) => doc.isSubdoc && doc.fullyExecuted);
}

export function ModalContent({
  onCloseWithoutConfirmation,
  onClose,
  investors,
  setDataEntryUpdated,
  setDataEntryDirty,
  setWasSentToCountersigners,
  step,
  setStep,
  disableFullyExecutedEmail,
  closingSubDoc,
  members,
}) {
  const [deliveryOption, setDeliveryOption] = useState('');
  const [errorMsgs, setErrorMsgs] = useState(null);
  const [deliveryOptionError, setDeliveryOptionError] = useState('');

  const partiallyCountersignedInvestors = useMemo(
    () => investors.filter(investorIsPartiallyCountersigned),
    [investors],
  );
  const allowChangeCountersigners = !partiallyCountersignedInvestors.length;
  const canBulkCountersignInvestors = useMemo(
    () =>
      investors.filter((investor) => !isInvestorSubdocFullyExecuted(investor)),
    [investors],
  );
  const investorsWithFullyExecutedSubdoc = useMemo(
    () => investors.filter(isInvestorSubdocFullyExecuted),
    [investors],
  );

  const atLeastOneDocumentHasVariables = useMemo(() => {
    const lpDocuments = collectLpDocumentsFromSubdocs(
      canBulkCountersignInvestors,
    );
    return lpDocuments.some((doc) => doc.hasVariables);
  }, [canBulkCountersignInvestors]);

  const countersignerData = useMemo(
    () => getCountersignerDataFromInvestors(investors),
    [investors],
  );

  const [countersigners, setCountersigners] = useState(
    getInitialCountersigners(countersignerData),
  );
  const [countersignerErrors, setCountersignerErrors] = useState({});

  const showDeliveryOption = canBulkCountersignInvestors.length >= 1;
  const selectedDeliveryOption = showDeliveryOption
    ? deliveryOption
    : DELIVERY_OPTION_INDIVIDUAL_PACKETS;

  const multiplePackets =
    selectedDeliveryOption === DELIVERY_OPTION_INDIVIDUAL_PACKETS &&
    investors.length > 1;

  const handleDeliveryOptionContinue = () => {
    if (allowChangeCountersigners) {
      if (!closingSubDoc || !members) return;
      const { numberOfCountersigners } = closingSubDoc;
      const { countersigner, countersigner2, countersigner3 } = countersigners;
      const errors = {};
      if (
        numberOfCountersigners > 0 &&
        (!countersigner || !countersignerIsMember(countersigner, members))
      ) {
        errors.countersigner = true;
      }
      if (
        numberOfCountersigners > 1 &&
        (!countersigner2 || !countersignerIsMember(countersigner2, members))
      ) {
        errors.countersigner2 = true;
      }
      if (
        numberOfCountersigners > 2 &&
        (!countersigner3 || !countersignerIsMember(countersigner3, members))
      ) {
        errors.countersigner3 = true;
      }
      setCountersignerErrors(errors);
      if (Object.keys(errors).length > 0) {
        return;
      }
    }
    switch (selectedDeliveryOption) {
      case DELIVERY_OPTION_BULK_PACKET:
        setStep(STEP_DATA_ENTRY);
        break;
      case DELIVERY_OPTION_INDIVIDUAL_PACKETS:
        setStep(STEP_REVIEW);
        break;
      default:
        setDeliveryOptionError(
          'You must select a delivery option to continue.',
        );
    }
  };

  const classes = useStyles();

  const handleDataEntryContinue = () => setStep(STEP_REVIEW);
  const containerMaxWidthByStep = {
    [STEP_DELIVERY_OPTION]: 'sm',
    [STEP_DATA_ENTRY]: false,
    [STEP_REVIEW]: 'sm',
  };
  const maxWidth = containerMaxWidthByStep[step];

  return (
    <div>
      <NavBar
        leftIcon={<Icons.Close />}
        leftText="Close"
        handleBack={onClose}
      />

      <PageContainer className={classes.container} maxWidth={maxWidth}>
        {errorMsgs
          ? errorMsgs.map((errorMsg) => (
              <Alert key={errorMsg} className={classes.alert} severity="error">
                {errorMsg}
              </Alert>
            ))
          : null}
        {/* DATA_ENTRY renders SectionHeading so it can be hidden while loading */}
        {step === STEP_DATA_ENTRY ? null : <SectionHeading />}
        {step === STEP_DELIVERY_OPTION ? (
          <DeliveryOptionStep
            investorsWithFullyExecutedSubdoc={investorsWithFullyExecutedSubdoc}
            partiallyCountersignedInvestors={partiallyCountersignedInvestors}
            allowChangeCountersigners={allowChangeCountersigners}
            showDeliveryOption={showDeliveryOption}
            deliveryOption={selectedDeliveryOption}
            setDeliveryOption={(option) => {
              setDeliveryOptionError('');
              setDeliveryOption(option);
            }}
            deliveryOptionError={deliveryOptionError}
            onContinue={handleDeliveryOptionContinue}
            countersignerData={countersignerData}
            disableFullyExecutedEmail={disableFullyExecutedEmail}
            atLeastOneDocumentHasVariables={atLeastOneDocumentHasVariables}
            closingSubDoc={closingSubDoc}
            members={members}
            countersigners={countersigners}
            setCountersigners={setCountersigners}
            countersignerErrors={countersignerErrors}
            setCountersignerErrors={setCountersignerErrors}
            multiplePackets={multiplePackets}
          />
        ) : null}
        {step === STEP_DATA_ENTRY ? (
          <DataEntryStep
            investors={canBulkCountersignInvestors}
            onContinue={handleDataEntryContinue}
            onCloseWithoutConfirmation={onCloseWithoutConfirmation}
            setDataEntryUpdated={setDataEntryUpdated}
            setDataEntryDirty={setDataEntryDirty}
            closingSubDoc={closingSubDoc}
            onBack={() => setStep(STEP_DELIVERY_OPTION)}
            sectionHeading={<SectionHeading />}
            setErrorMsgs={setErrorMsgs}
          />
        ) : null}
        {step === STEP_REVIEW ? (
          <ReviewStep
            closingSubDoc={closingSubDoc}
            allowChangeCountersigners={allowChangeCountersigners}
            countersignerData={countersignerData}
            showDeliveryOption={showDeliveryOption}
            deliveryOption={selectedDeliveryOption}
            onClose={onClose}
            setWasSentToCountersigners={setWasSentToCountersigners}
            setErrorMsgs={setErrorMsgs}
            canBulkCountersignInvestors={canBulkCountersignInvestors}
            investorsWithFullyExecutedSubdoc={investorsWithFullyExecutedSubdoc}
            allInvestors={investors}
            disableFullyExecutedEmail={disableFullyExecutedEmail}
            atLeastOneDocumentHasVariables={atLeastOneDocumentHasVariables}
            multiplePackets={multiplePackets}
            countersigners={countersigners}
            members={members || []}
            onBack={() => {
              if (deliveryOption === DELIVERY_OPTION_BULK_PACKET) {
                setErrorMsgs(null);
                setStep(STEP_DATA_ENTRY);
              } else {
                setErrorMsgs(null);
                setStep(STEP_DELIVERY_OPTION);
              }
            }}
          />
        ) : null}
      </PageContainer>
    </div>
  );
}
