import * as React from 'react';
import { Document, Page } from 'react-pdf';

import { PDFTimeline } from 'components/pdf_timeline';
import { Spinner } from 'components/spinner';
import { PDF_WIDTH, PDF_HEIGHT } from 'services/pdf_dimensions';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { OverlayCanvas } from './overlay_canvas';
import { useDocumentsDrawerState, useDocumentsDrawerActions } from './utils';

const useStyles = makeStyles((theme) => ({
  timeline: {
    width: '100%',
  },
  timelineRail: {
    width: '100% !important',
  },
  pdfViewer: {
    position: 'relative',
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.neutral.fill,
    display: 'flex',
    overflow: 'hidden',
    flex: 1,
    justifyContent: 'center',
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
  },
  pdfDocument: {
    overflow: 'auto',
    overscrollBehavior: 'contain',
  },
  drawerContent: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
    flexDirection: 'column',
    padding: theme.spacing(1, 3, 0),
  },
  updateAlert: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
}));

export function DrawerContent({ nav, fileUrl, currentQuestions }) {
  const classes = useStyles();
  const state = useDocumentsDrawerState();
  const actions = useDocumentsDrawerActions();
  const containerRef = React.useRef(null);
  const documentRef = React.useRef(null);
  const [baseScale, setBaseScale] = React.useState(1);

  const currentQuestion = nav.getCurrentQuestion();
  const currentSection = nav.getCurrentSection();
  const questionsToHighlight = currentQuestions?.filter((q) => {
    if (q.documentId !== state.selectedDocId) {
      return false;
    }
    if (q.label === currentQuestion?.label) {
      return true;
    }
    return q.answer !== null;
  });

  const pdfBoxes =
    questionsToHighlight?.map((q) => q?.pdfBoxes ?? []).flat() ?? [];

  React.useLayoutEffect(() => {
    // observe the PDF container width so in mobile the 100% zoom fits the width of the screen
    if (!containerRef.current) {
      return null;
    }

    const observer = new ResizeObserver((entries) => {
      requestAnimationFrame(() => {
        const currentWidth = entries[0].contentRect.width;
        if (currentWidth !== PDF_WIDTH) {
          setBaseScale(currentWidth / PDF_WIDTH);
        }
      });
    });
    observer.observe(containerRef.current);
    return () => observer.disconnect();
  }, [containerRef.current]);

  React.useEffect(() => {
    if (!currentQuestion) {
      return;
    }
    if (state.linkId) {
      return;
    }
    const relevantBoxes = currentQuestion.pdfBoxes?.filter(
      (b) => b.highlightType !== 'context',
    );
    if (!relevantBoxes?.length) {
      return;
    }
    const sameDoc = state.selectedDocId === currentSection.document?.id;
    const pages = relevantBoxes.map((b) => b.page);
    if (sameDoc && pages.includes(state.page)) {
      return;
    }

    const earliestPage = Math.min(...pages);
    if (state.page !== earliestPage || !sameDoc) {
      actions.setPage({
        page: earliestPage,
        docId: currentSection.document?.id,
      });
    }
  }, [currentSection.document?.id, currentQuestion?.label]);

  const scale = state.zoom * baseScale;

  const showTimeline = questionsToHighlight.length && state.numPages;

  return (
    <div className={classes.drawerContent}>
      <div className={classes.timeline}>
        {state.loading || showTimeline ? (
          <PDFTimeline
            page={state.page}
            setPage={(page) => actions.setPage({ page })}
            numPages={state.numPages}
            questions={questionsToHighlight}
            changeEventsPerQuestion={nav.commentsPerQuestion}
            activeQuestion={currentQuestion}
            priorAnswers={{}}
            nav={nav}
            lpVersion
            className={classes.timelineRail}
            loading={state.loading}
          />
        ) : null}
      </div>
      <div
        className={classes.pdfViewer}
        ref={containerRef}
        style={{
          maxHeight: Math.ceil(baseScale * PDF_HEIGHT),
        }}
      >
        <Document
          inputRef={documentRef}
          className={clsx(classes.pdfDocument, {
            [classes.loading]: state.loading,
          })}
          file={fileUrl}
          onLoadSuccess={(pdf) => actions.setPdf({ pdf })}
          loading={() => <Spinner width={baseScale * PDF_WIDTH} height="50%" />}
        >
          <Page
            key={`page-${state.page}`}
            pageNumber={state.page}
            scale={scale}
            loading={() => null}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          >
            {!state.loading ? (
              <OverlayCanvas
                nav={nav}
                questions={questionsToHighlight}
                currentQuestion={currentQuestion}
                pdfBoxes={pdfBoxes}
                scale={scale}
                containerRef={documentRef}
              />
            ) : null}
          </Page>
        </Document>
      </div>
    </div>
  );
}
