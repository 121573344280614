export const reducerActionTypes = {
  INITIALIZE_DATA: 'INITIALIZE_DATA',
  UPDATE_MATCH_STATUS: 'UPDATE_MATCH_STATUS',
  MARK_AS_REVIEWED: 'MARK_AS_REVIEWED',
  APPROVE_NODE: 'APPROVE_NODE',
  UNAPPROVE_NODE: 'UNAPPROVE_NODE',
  ADD_NOTE_TO_INVESTOR: 'ADD_NOTE_TO_INVESTOR',
  DELETE_NOTE_TO_INVESTOR: 'DELETE_NOTE_TO_INVESTOR',
  ADD_INTERNAL_NOTE: 'ADD_INTERNAL_NOTE',
  SET_SELECTED_MATCH_ID: 'SET_SELECTED_MATCH_ID',
  AUTO_SELECT_NEXT_ID: 'AUTO_SELECT_NEXT_ID',
  DEQUEUE_ID: 'DEQUEUE_ID',
  ENQUEUE_ID: 'ENQUEUE_ID',
  REFRESH_ACTIVITY_EVENTS: 'REFRESH_ACTIVITY_EVENTS',
  RESOLVE_THREAD: 'RESOLVE_THREAD',
  REOPEN_THREAD: 'REOPEN_THREAD',
  UPDATE_NUM_UNRESOLVED_THREADS: 'UPDATE_NUM_UNRESOLVED_THREADS',
  CLEAR_NUM_UNRESOLVED_THREADS: 'CLEAR_NUM_UNRESOLVED_THREADS',
  REFRESH_SUPPLEMENTARY_FILES: 'REFRESH_SUPPLEMENTARY_FILES',
  EDIT_NODE_DETAILS: 'EDIT_NODE_DETAILS',
};

export const matchStatuses = {
  TRUE_MATCH: 'TRUE_MATCH',
  NOT_TRUE_MATCH: 'NOT_TRUE_MATCH',
  NOT_SURE: 'NOT_SURE',
  NO_CHOICE: 'NO_CHOICE',
};

export const DATA_KEYS_USED_IN_SEARCHES = ['name', 'type', 'dob'];

export const CAN_REVIEW_STATUSES = [
  'REQUESTED_CHANGES',
  'PARTIALLY_SIGNED',
  'SIGNED',
  'APPROVED',
  'SENT_TO_COUNTERSIGNER',
  'COUNTERSIGNED',
];

export const GP_CAN_EDIT_STATUSES = ['SIGNED'];

export const POST_AUTOMATED_SEARCH_STATUSES = [
  'SIGNED',
  'APPROVED',
  'SENT_TO_COUNTERSIGNER',
  'COUNTERSIGNED',
];

export const CAN_ADD_DRAFT_NOTE_STATUSES = [
  'DRAFT',
  'UNVIEWED',
  'VIEWED',
  'IN_PROGRESS',
  'REQUESTED_CHANGES',
  'PARTIALLY_SIGNED',
  'SIGNED',
];

export const addressAnswerKeys = ['residentialAddress', 'hqAddress'];
