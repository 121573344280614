import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import { Typography as PassTypography } from '@passthrough/uikit';
import * as api from 'services/api';
import { TraySolutionCard } from './tray_solution_card';
import { TrayDialog } from './tray_dialog';
import { IntegrationCard } from './integration_card';

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: theme.spacing(6),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  content: {
    display: 'flex',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

export function TraySection({ org }) {
  const organizationId = org.id;
  const classes = useStyles();
  const [solutions, setSolutions] = React.useState([]);
  const [cards, setCards] = React.useState([]);
  const [wizard, setWizard] = React.useState(null);
  const open = Boolean(wizard);

  const fetchSolutions = () => {
    api.getTraySolutions({ organizationId }).then((response) => {
      setSolutions(response.data);
    });
    api.getIntegrationCards({ organizationId }).then((response) => {
      setCards(response.data);
    });
  };

  const getVariant = (solution) => {
    if (!solution.enabled && !solution.created && solution.disabled) {
      return 'unavailable';
    }

    if (solution.enabled) {
      return 'active';
    }

    if (solution.created) {
      return 'needs_attention';
    }

    return 'available';
  };

  React.useEffect(() => {
    if (org.trayEnabled) {
      fetchSolutions();
    }
  }, []);

  return (
    <div className={classes.marginTop}>
      <div className={classes.header}>
        <Typography variant="h6">Integrations</Typography>
        <PassTypography variant="label" color="text.secondary">
          Connect your Passthrough account to external systems.
        </PassTypography>
      </div>
      <TrayDialog
        key={wizard?.solution?.id}
        open={open}
        wizardUrl={wizard?.url}
        onValidate={(data) =>
          api.validateTray({
            organizationId,
            instanceId: data.solutionInstanceId,
            data,
          })
        }
        onFinish={() => {
          api
            .enableTray({ organizationId, solutionId: wizard.solution.id })
            .then(() => {
              fetchSolutions();
              setWizard(null);
            });
        }}
        onClose={() => {
          fetchSolutions();
          setWizard(null);
        }}
      />
      <Grid container spacing={3} justifyContent="flex-start">
        {solutions.map((solution) => (
          <Grid item key={solution.id} xs={12} sm={6} lg={4}>
            <TraySolutionCard
              key={solution.id}
              solution={solution}
              variant={getVariant(solution)}
              connect={() =>
                api
                  .connectTray({ organizationId, solutionId: solution.id })
                  .then((response) => {
                    setWizard({ url: response.data.url, solution });
                  })
              }
              remove={() =>
                api
                  .removeTray({ organizationId, solutionId: solution.id })
                  .then(() => {
                    fetchSolutions();
                  })
              }
            />
          </Grid>
        ))}
        {cards.map((card) => (
          <Grid item key={card.name} xs={12} sm={6} lg={4}>
            <IntegrationCard
              key={card.name}
              card={card}
              organizationId={organizationId}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
