import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Button } from 'components/button';
import * as api from 'services/api';

const useStyles = makeStyles((theme) => ({
  paper: {
    minHeight: '500px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(10),
  },
  text: {
    marginBottom: theme.spacing(10),
    width: '100%',
    whiteSpace: 'pre-line',
  },
  button: {
    maxWidth: '400px',
    marginBottom: theme.spacing(10),
  },
  intro: {
    fontWeight: '600',
    width: '100%',
    marginBottom: theme.spacing(3),
  },
}));

export function Terms({ text, onAccept, fundName, documentDisplayType }) {
  const { lpDocumentId } = useParams();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  function accept() {
    setLoading(true);
    api.lpDocAccept({ lpDocumentId }).then(() => {
      onAccept();
      setLoading(false);
    });
  }

  return (
    <div className={classes.paper}>
      <Typography className={classes.intro} align="left">
        {fundName} asks that you agree to the following terms before viewing the{' '}
        {documentDisplayType}:
      </Typography>
      <Typography className={classes.text} align="left">
        {text}
      </Typography>
      <Button
        onClick={accept}
        variant="contained"
        fullWidth
        loading={loading}
        className={classes.button}
      >
        Agree
      </Button>
    </div>
  );
}
