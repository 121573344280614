import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ReviewInstructionStep } from './review_instruction_step';

const useStyles = makeStyles((theme) => ({
  instructionBottomSpacing: {
    marginBottom: theme.spacing(2),
  },
}));

export function AllInstructions({ className }) {
  const classes = useStyles();

  return (
    <div className={className}>
      <ReviewInstructionStep
        stepNum={1}
        header="Review details and documents"
        className={classes.instructionBottomSpacing}
      />
      <ReviewInstructionStep
        stepNum={2}
        header="Evaluate potential matches"
        body="Identify all true matches and assess alerts from various sanctions lists."
        className={classes.instructionBottomSpacing}
      />
      <ReviewInstructionStep
        stepNum={3}
        header="Approve"
        body="All beneficial owners must be individually reviewed."
      />
    </div>
  );
}
