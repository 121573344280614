import React from 'react';

import { Address } from 'components/address_v2';
import { DiligenceTIN } from '../tin';

export function TinEinHqAddressDetails({
  tinEin,
  tinEinError,
  hqAddress,
  hqAddressError,
  handleChange,
}) {
  return (
    <>
      <DiligenceTIN
        label="TIN/EIN"
        tin={tinEin}
        tinError={tinEinError}
        onChange={(e) => {
          handleChange('tinEin', e.target.value);
        }}
      />

      <Address
        address={hqAddress}
        onChange={(addr) => {
          handleChange('hqAddress', addr);
        }}
        errors={hqAddressError}
        label="Headquarters address"
      />
    </>
  );
}
