const addressFields = [
  'line1_v2',
  'line2_v2',
  'city',
  'state_province_or_region',
  'postal_code',
  'country',
  'full_address_v2',
];

export const FIELDS_BY_ANSWER_TYPE = {
  contact_name_and_email: ['first_name', 'last_name', 'full_name', 'email'],
  name: ['first_name', 'last_name', 'full_name'],
  name_with_title: ['first_name', 'last_name', 'full_name', 'title'],
  name_required_title: ['first_name', 'last_name', 'full_name', 'title'],
  name_title_email: ['first_name', 'last_name', 'full_name', 'title', 'email'],
  yes_or_no: ['yes', 'no'],
  true_or_false: ['true', 'false'],
  address: [
    ...addressFields,
    // Deprecated fields
    'line1',
    'line2',
    'full_address',
  ],
  bank: ['bank_name', 'account_name', 'account_number', 'aba', 'swift', 'ffct'],
  bank_with_address: [
    'bank_name',
    'account_name',
    'account_number',
    'aba',
    'swift',
    'ffct',
    ...addressFields,
    // Deprecated
    'full_address',
  ],
  bank_v2: [
    'bank_name',
    'account_name',
    'account_number',
    'aba',
    'swift',
    'iban',
    'transmission_notes',
    'ffct_account_name',
    'ffct_account_number',
    ...addressFields,
    // Deprecated
    'full_address',
  ],
  contact: [
    'first_name',
    'last_name',
    'full_name',
    'email',
    'phone',
    'fax',
    ...addressFields,
    // Deprecated - same as full_address from address/bank_with_address/bank_v2
    'address',
  ],
  contact_with_required_address: [
    'first_name',
    'last_name',
    'full_name',
    'email',
    'phone',
    'fax',
    ...addressFields,
    // Deprecated - same as full_address from address/bank_with_address/bank_v2
    'address',
  ],
  contact_with_nationality: [
    'first_name',
    'last_name',
    'full_name',
    'date_of_birth',
    'place_of_birth',
    'nationality',
    ...addressFields,
    // Deprecated - same as full_address from address/bank_with_address/bank_v2
    'address',
  ],
  crs_tax_details: ['country', 'tax_type', 'tax_number', 'not_applicable'],
  crs_tax_details_v2: [
    'country',
    'tax_type',
    'tax_number',
    'reason_not_applicable',
    'not_applicable',
  ],
};

export const DEPRECATED_FIELDS_BY_ANSWER_TYPE = {
  address: ['line1', 'line2', 'full_address'],
  bank_with_address: ['full_address'],
  bank_v2: ['full_address'],
  contact: ['address'],
  contact_with_nationality: ['address'],
  contact_with_required_address: ['address'],
};

export const checkboxEnabledByDefaultByAnswerType = {
  crs_tax_details: ['not_applicable'],
  crs_tax_details_v2: ['not_applicable'],
};

export const ANSWER_TYPES_WITHOUT_PDF_LOCATIONS = [
  ...Object.keys(FIELDS_BY_ANSWER_TYPE),
  'choice',
  'multi_select',
];

export const SIGNERS = [
  // If you add a new signer with an underscore, you must also change
  // the behavior of updateBoxType
  { value: 'investor1', label: 'Investor 1' },
  { value: 'investor2', label: 'Investor 2' },
  { value: 'investor3', label: 'Investor 3' },
  { value: 'investor4', label: 'Investor 4' },
  { value: 'investor5', label: 'Investor 5' },
  { value: 'investor6', label: 'Investor 6' },
  { value: 'countersigner1', label: 'Countersigner 1' },
  { value: 'countersigner2', label: 'Countersigner 2' },
  { value: 'countersigner3', label: 'Countersigner 3' },
];

export const SIGNATURE_BOX_TYPES = SIGNERS.map(
  (signer) => `${signer.value}_signature`,
);
export const SIGNATURE_CHECKBOX_TYPES = SIGNERS.map(
  (signer) => `${signer.value}_checkbox`,
);
export const NAMED_SIGNATURE_BOX_TYPES = SIGNERS.flatMap((signer) => [
  `${signer.value}_text`,
  `${signer.value}_checkbox`,
]);

export const MAX_BOX_NAME_LENGTH = 40;

export const boxTypes = {
  TEXTBOX: 'output_text',
  CUSTOMTEXTBOX: 'custom_text',
  CHECKBOX: 'output_checkbox',
  INITIALS: 'output_initials',
  SIGNATURE: 'investor1_signature',
  ALT_DATE_TEXTBOX: 'alt_date_textbox',
  CONTEXT: 'context',
};

export const checkboxEnabledByDefault = [
  'choice',
  'multi_select',
  'yes_or_no',
  'true_or_false',
  'affirmation',
];

export const frontendLabelRequired = [
  'text',
  'text_long',
  'integer',
  'email',
  'tel',
  'dollars',
  'aba',
  'date',
  'date_year',
  'date_without_year',
];

export const choicesRequired = ['choice', 'multi_select'];

export const blockNotRequired = [
  'yes_or_no',
  'true_or_false',
  'affirmation',
  'choice',
];

export const fileTypeRequired = ['file_upload', 'multi_file_upload'];

export const TAB_TYPES = {
  edit: 0,
  preview: 1,
  custom_code: 2,
};

const logicalCountryOperators = ['eq', 'neq'];

const NUMBER_OPERATORS = [
  'number_eq',
  'number_neq',
  'number_gte',
  'number_gt',
  'number_lte',
  'number_lt',
];

export const ALLOWED_OPERATIONS = {
  choice: ['eq', 'neq', 'nneq'],
  multi_select: ['contains', 'ncontains', 'nncontains', 'ocontains'],
  yes_or_no: ['eq'],
  true_or_false: ['eq'],
  country: logicalCountryOperators,
  contact_with_nationality: logicalCountryOperators,
  address: logicalCountryOperators,
  bank_with_address: logicalCountryOperators,
  bank_v2: logicalCountryOperators,
  contact: logicalCountryOperators,
  contact_with_required_address: logicalCountryOperators,
  state: ['eq', 'neq'],
  integer: [
    'integer_eq',
    'integer_neq',
    'integer_gte',
    'integer_gt',
    'integer_lte',
    'integer_lt',
    ...NUMBER_OPERATORS,
  ],
  dollars: NUMBER_OPERATORS,
  percentage: NUMBER_OPERATORS,
};

export const DEFAULT_OPERATORS = {
  choice: 'eq',
  multi_select: 'contains',
  yes_or_no: 'eq',
  true_or_false: 'eq',
  country: 'eq',
  contact_with_nationality: 'eq',
  address: 'eq',
  bank_with_address: 'eq',
  bank_v2: 'eq',
  contact: 'eq',
  contact_with_required_address: 'eq',
  state: 'eq',
  integer: 'number_eq',
  dollars: 'number_eq',
  percentage: 'number_eq',
};

export const LOGIC_ANSWER_TYPES = [
  'choice',
  'yes_or_no',
  'true_or_false',
  'multi_select',
  'country',
  'contact_with_nationality',
  'state',
  'integer',
  'dollars',
  'percentage',
  'address',
  'bank_with_address',
  'bank_v2',
  'contact',
  'contact_with_required_address',
];

export const YES_NO_LOGIC_TYPES = ['yes_or_no', 'true_or_false'];

export const COUNTRY_LOGIC_TYPES = [
  'address',
  'bank_with_address',
  'bank_v2',
  'contact',
  'contact_with_required_address',
  'country',
  'contact_with_nationality',
];

export const STATE_LOGIC_TYPES = ['state'];

export const NUMBER_LOGIC_TYPES = ['integer', 'dollars', 'percentage'];

export const ANSWER_MEANINGS = [
  { label: 'Investor Type', value: 'investor_type' },
  { label: 'Capital commitment', value: 'commitment' },
  { label: 'First signer name', value: 'first_signer_name' },
  { label: 'First signer email', value: 'first_signer_email' },
  { label: 'Second signer name', value: 'second_signer_name' },
  { label: 'Second signer email', value: 'second_signer_email' },
  { label: 'Third signer name', value: 'third_signer_name' },
  { label: 'Third signer email', value: 'third_signer_email' },
  { label: 'Fourth signer name', value: 'fourth_signer_name' },
  { label: 'Fourth signer email', value: 'fourth_signer_email' },
  { label: 'Fifth signer name', value: 'fifth_signer_name' },
  { label: 'Fifth signer email', value: 'fifth_signer_email' },
  { label: 'Sixth signer name', value: 'sixth_signer_name' },
  { label: 'Sixth signer email', value: 'sixth_signer_email' },
  { label: 'Supplemental materials', value: 'supplemental_materials' },
  { label: 'W9', value: 'w9_v4' },
  { label: 'W8', value: 'w8' },
  { label: 'CRS Individual', value: 'crs_individual' },
  { label: 'CRS Entity', value: 'crs_entity' },
  { label: 'Initials', value: 'initials' },
  { label: 'Controlling Persons Form', value: 'controlling_persons' },
  { label: 'UK Addendum', value: 'uk_addendum' },
  { label: 'Force offline signing', value: 'force_offline_signing' },
  {
    label: 'Alternativ compliance docs',
    value: 'alternativ_compliance_docs',
  },
  {
    label: 'V2 alternativ compliance docs',
    value: 'alternativ_compliance_docs_v2',
  },
  { label: 'Alternativ w9', value: 'alternativ_w9' },
  { label: 'Alternativ (Fidelity)', value: 'alternativ_fidelity' },
  { label: 'Alternativ (Schwab)', value: 'alternativ_schwab' },
  {
    label: '1st signer SMS verification',
    value: 'first_signer_phone_verification',
  },
  {
    label: '2nd signer SMS verification',
    value: 'second_signer_phone_verification',
  },
  {
    label: '3rd signer SMS verification',
    value: 'third_signer_phone_verification',
  },
  {
    label: '4th signer SMS verification',
    value: 'fourth_signer_phone_verification',
  },
  {
    label: '5th signer SMS verification',
    value: 'fifth_signer_phone_verification',
  },
  {
    label: '6th signer SMS verification',
    value: 'sixth_signer_phone_verification',
  },
  { label: 'W9 [Deprecated]', value: 'w9_v3' },
];

export const NAME_ANSWER_MEANINGS = [
  'first_signer_name',
  'second_signer_name',
  'third_signer_name',
  'fourth_signer_name',
  'fifth_signer_name',
  'sixth_signer_name',
  'supplemental_materials',
];

export const PHONE_ANSWER_MEANINGS = [
  'first_signer_phone_verification',
  'second_signer_phone_verification',
  'third_signer_phone_verification',
  'fourth_signer_phone_verification',
  'fifth_signer_phone_verification',
  'sixth_signer_phone_verification',
];

export const EMAIL_ANSWER_MEANINGS = [
  'first_signer_email',
  'second_signer_email',
  'third_signer_email',
  'fourth_signer_email',
  'fifth_signer_email',
  'sixth_signer_email',
  'supplemental_materials',
];

export const NAME_TYPES = [
  'contact',
  'contact_with_required_address',
  'contact_name_and_email',
  'contact_with_nationality',
  'name',
  'name_with_title',
  'name_required_title',
  'name_title_email',
  'text',
  'text_long',
];

export const EMAIL_TYPES = [
  'contact',
  'contact_with_required_address',
  'contact_name_and_email',
  'email',
  'name_title_email',
];

export const PHONE_TYPES = ['contact', 'contact_with_required_address', 'tel'];

export const INITIAL_STATE = 'initial';

export const REVIEW_STATE = 'review';

export const REVISION_STATE = 'revision';

export const COMPLETED_STATE = 'completed';

export const sendHeader = {
  [REVIEW_STATE]: 'Send to reviewer',
  [REVISION_STATE]: 'Send to reviser',
  [COMPLETED_STATE]: 'Mark as completed',
};

export const senderRole = {
  [INITIAL_STATE]: 'onboarder',
  [REVIEW_STATE]: 'reviewer',
  [REVISION_STATE]: 'reviser',
};

export const sendText = {
  [REVIEW_STATE]:
    "Please confirm that you've finished and are ready to send this document to the reviewer.",
  [REVISION_STATE]:
    "Please confirm that you've finished and are ready to send this document to the reviser.",
  [COMPLETED_STATE]:
    'Please confirm that no more review stages are needed and that the document is completed.',
};

export const reducerActionTypes = {
  INITIALIZE_DATA: 'INITIALIZE_DATA',
  UPDATE_NOTE: 'UPDATE_NOTE',
  CREATE_NOTE: 'CREATE_NOTE',
  DELETE_NOTE: 'DELETE_NOTE',
  MOVE_NOTES: 'MOVE_NOTES',
};

export const noteFlags = {
  NO_CHANGE_NEEDED: 'no_change_needed',
  NEEDS_REVIEW: 'needs_review',
  DISCUSS_WITH_CLIENT: 'discuss_with_client',
  RESOLVE_NOTE: 'resolve_note',
  UNRESOLVE_NOTE: 'unresolve_note',
};

export const noteFlagLabels = {
  [noteFlags.NO_CHANGE_NEEDED]: 'No changes needed',
  [noteFlags.NEEDS_REVIEW]: 'Needs review',
  [noteFlags.DISCUSS_WITH_CLIENT]: 'Discuss with client',
};

export const stateDict = {
  [INITIAL_STATE]: 0,
  [REVISION_STATE]: 0,
  [REVIEW_STATE]: 1,
  [COMPLETED_STATE]: 2,
};

export const SUBDOC_DOC_NAME = 'Subscription document';
