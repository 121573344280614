import React from 'react';
import { Tabs, Tab, Icons } from '@passthrough/uikit';
import { ShowCaseBox, ComponentShowCase, ShowCaseBoxRow } from '../show_case';

export const TabsShowCase = () => {
  const [tab, setTab] = React.useState('tab1');
  return (
    <ComponentShowCase title="Tabs">
      <ShowCaseBox>
        <ShowCaseBoxRow>
          <Tabs value={tab} onChange={(_, value) => setTab(value)}>
            <Tab
              label="Tab 1"
              value="tab1"
              startIcon={<Icons.TableChartOutlined />}
            />
            <Tab label="Tab 2" value="tab2" />
            <Tab
              label="Tab 3"
              value="tab3"
              endIcon={<Icons.TableChartOutlined />}
            />
          </Tabs>
        </ShowCaseBoxRow>
      </ShowCaseBox>
    </ComponentShowCase>
  );
};
