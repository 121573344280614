import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import LabelIcon from '@material-ui/icons/Label';
import { Chip, Divider, Paper, Typography } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import LabelOffIcon from '@material-ui/icons/LabelOff';
import CategoryIcon from '@material-ui/icons/Category';
import { Button } from 'components/button';
import { Dropdown } from './dropdown';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'sticky',
    top: 0,
    zIndex: 999,
    marginBottom: theme.spacing(2),
  },
  filters: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(3),
  },
  filterButtons: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  appliedFilters: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  search: {
    marginRight: theme.spacing(2),
    minWidth: 400,
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const Filters = ({
  tagOptions,
  tagFilter,
  fundOptions,
  fundFilter,
  typeOptions,
  typeFilter,
  noTagFilter,
  search,
  onSearch,
  appliedSearch,
  toggleFundFilter,
  toggleTagFilter,
  toggleTypeFilter,
  toggleNoTagFilter,
  totalCount,
}) => {
  const classes = useStyles();
  const shortenedSearch =
    appliedSearch && appliedSearch.length > 100
      ? `${appliedSearch.substring(0, 100)}...`
      : appliedSearch;
  return (
    <Paper variant="outlined" className={classes.root}>
      <div className={classes.filters}>
        <div className={classes.filterButtons}>
          <Typography>Filter by:</Typography>
          <Dropdown
            options={fundOptions}
            getOptionLabel={(option) => option.name}
            onSelect={(fund) => toggleFundFilter(fund)}
            placeholder="Filter by Fund"
            startIcon={<BusinessIcon />}
            selectedCount={fundFilter.length}
          >
            Fund
          </Dropdown>

          <Dropdown
            options={typeOptions}
            getOptionLabel={(option) => option}
            onSelect={(tag) => toggleTypeFilter(tag)}
            placeholder="Filter by question type"
            startIcon={<CategoryIcon />}
            selectedCount={typeFilter.length}
          >
            Question type
          </Dropdown>

          <Dropdown
            options={tagOptions}
            getOptionLabel={(option) => option}
            onSelect={(tag) => toggleTagFilter(tag)}
            placeholder="Filter by Tag"
            startIcon={<LabelIcon />}
            selectedCount={tagFilter.length}
          >
            Tag
          </Dropdown>

          <Button
            size="small"
            onClick={toggleNoTagFilter}
            startIcon={<LabelOffIcon />}
            selected={noTagFilter}
          >
            Not tagged
          </Button>
        </div>
        <div className={classes.rightSide}>
          <TextField
            className={classes.search}
            id="search-tag-text"
            variant="outlined"
            label="Search question"
            type="text"
            value={search}
            size="small"
            onChange={(e) => onSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {totalCount !== undefined ? `${totalCount} results` : null}
        </div>
      </div>
      {fundFilter.length ||
      tagFilter.length ||
      typeFilter.length ||
      noTagFilter ||
      appliedSearch ? (
        <>
          <Divider />
          <div className={classes.appliedFilters}>
            <strong>Applied filters:</strong>
            {fundFilter.map((item) => (
              <Chip
                key={item.id}
                variant="outlined"
                color="primary"
                icon={<LabelIcon />}
                onDelete={() => toggleFundFilter(item)}
                label={`Fund: ${item.name}`}
              />
            ))}
            {typeFilter.map((item) => (
              <Chip
                key={item}
                variant="outlined"
                color="primary"
                icon={<LabelIcon />}
                onDelete={() => toggleTypeFilter(item)}
                label={`Type: ${item}`}
              />
            ))}
            {tagFilter.map((item) => (
              <Chip
                key={item}
                variant="outlined"
                color="primary"
                icon={<LabelIcon />}
                onDelete={() => toggleTagFilter(item)}
                label={`Tag: ${item}`}
              />
            ))}
            {noTagFilter ? (
              <Chip
                variant="outlined"
                color="primary"
                icon={<LabelIcon />}
                onDelete={() => toggleNoTagFilter()}
                label="Not tagged gids"
              />
            ) : null}
            {appliedSearch ? (
              <Chip
                variant="outlined"
                color="primary"
                icon={<SearchIcon />}
                onDelete={() => onSearch('')}
                label={shortenedSearch}
              />
            ) : null}
          </div>
        </>
      ) : null}
    </Paper>
  );
};
