import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { ClickableChip } from '@passthrough/uikit';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import Tooltip from '@material-ui/core/Tooltip';

import { InvestorTagChip } from 'components/investor_tagging_chip';

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: 'wrap',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    maxWidth: '300px',
  },
  centeredArrow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  customSmallIcon: {
    fontSize: '0.75rem',
  },
  flippedIcon: {
    transform: 'rotate(180deg)',
  },
}));

const MAX_INITIAL_NUM_TAGS = 5;

export function InvestorTagsCellContainer({ investor, filters, setFilters }) {
  const classes = useStyles();
  const [showAll, setShowAll] = useState(false);

  const hasExtraTags = investor.tags.length > MAX_INITIAL_NUM_TAGS;
  const tagsToAlwaysShow = investor.tags.slice(0, MAX_INITIAL_NUM_TAGS);
  const extraTags = investor.tags.slice(MAX_INITIAL_NUM_TAGS);

  return (
    <div className={classes.root}>
      {tagsToAlwaysShow.map((t) => {
        const isFilterSelected = filters?.some((f) => f.id === t.id);

        return (
          <InvestorTagChip
            key={t.id}
            labelStr={t.name}
            onClick={
              !isFilterSelected
                ? () => {
                    setFilters((prevFilters) => [...prevFilters, t]);
                  }
                : null
            }
          />
        );
      })}

      {showAll
        ? extraTags.map((et) => {
            const isFilterSelected = filters?.some((f) => f.id === et.id);

            return (
              <InvestorTagChip
                key={et.id}
                labelStr={et.name}
                onClick={
                  !isFilterSelected
                    ? () => {
                        setFilters((prevFilters) => [...prevFilters, et]);
                      }
                    : null
                }
              />
            );
          })
        : null}

      {hasExtraTags ? (
        <ClickableChip
          size="small"
          onClick={() => {
            setShowAll((sa) => !sa);
          }}
          label={
            <Tooltip title={showAll ? 'View less' : 'View more'}>
              <span
                className={clsx(classes.centeredArrow, {
                  [classes.flippedIcon]: showAll,
                })}
              >
                <ArrowForwardIcon className={classes.customSmallIcon} />
              </span>
            </Tooltip>
          }
        />
      ) : null}
    </div>
  );
}
