import React from 'react';
import Typography from '@material-ui/core/Typography';

export function DeleteContent({ fundName, memberToDelete }) {
  return (
    <Typography>
      {memberToDelete.name}
      {' ('}
      {memberToDelete.email}
      {') '}
      will lose their access to {fundName}.
    </Typography>
  );
}
