import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import { ClickableChip } from '@passthrough/uikit';

import { Card } from './card';
import { ItemQuestion } from './item_question';
import { ItemSection } from './item_section';
import { FILTERS, CARD_LABEL } from '../constants';
import * as utils from '../utils';

const useStyles = makeStyles((theme) => ({
  search: {
    display: 'flex',
    flexDirection: 'column',
  },
  filters: {
    display: 'flex',
    padding: theme.spacing(1, 0),
    gap: theme.spacing(1),
    flexWrap: 'wrap',
  },
  list: {
    maxHeight: 'calc(100vh - 400px)',
    overflow: 'auto',
  },
}));

export const ListView = ({
  questions,
  question,
  currentQuestions,
  onSelect,
  search,
  onSearch,
  filter,
  onFilter,
}) => {
  const classes = useStyles();
  const questionsOnly = questions.filter((item) => !item.isSection);
  const countByFilter = utils.countFilters(questionsOnly);
  const filters = Object.values(FILTERS);

  return (
    <div>
      <div className={classes.search}>
        <Card
          current={questionsOnly.filter(utils.applyCountFilter(filter)).length}
          total={questionsOnly.length}
          label={CARD_LABEL[filter]}
        />
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          fullWidth
          value={search}
          onChange={(e) => onSearch(e.target.value)}
        />

        <div className={classes.filters}>
          {filters.map((item) => (
            <ClickableChip
              key={item}
              label={`${item} (${countByFilter[item] || 0})`}
              filter
              size="small"
              disabled={!countByFilter[item]}
              selected={item === filter}
              onClick={() => onFilter(item)}
            />
          ))}
        </div>
      </div>
      <List className={classes.list}>
        {currentQuestions.map((item) => {
          if (item.sectionName) {
            return <ItemSection item={item} />;
          }
          return (
            <ItemQuestion
              key={item.id}
              item={item}
              onClick={onSelect}
              showTags={filter === FILTERS.ALL}
              active={item.id === question?.id}
            />
          );
        })}
      </List>
    </div>
  );
};
