import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import { KeyValuePair } from 'components/key_value';

export function InvestorStatusChangedDisplay({ numSignatureRequired }) {
  const statusText =
    numSignatureRequired === 0
      ? 'Investor Signed'
      : 'Sent — Waiting on additional signer';
  return (
    <KeyValuePair icon={<CheckIcon />}>
      The investor's status will revert to <b>{statusText}</b>.
    </KeyValuePair>
  );
}
