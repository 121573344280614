import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Typography } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Button } from 'components/button';
import { TruncatedTextDisplay } from 'components/truncated_text_display';
import { MatchDataDisplay } from './match_data_display';
import { AlertsDisplay } from './alerts_display';
import { alertVariants } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(3),
  },
  nameAndDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    rowGap: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  nameAndExpandBtnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  allAlertsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(4),
  },
  invertedIcon: {
    transform: 'rotate(180deg)',
  },
}));

export function PotentialMatchDataDisplay({
  name,
  potentialMatchDataFields,
  associates,
  aliases,
  sanctionHits,
  warningHits,
  fitnessProbityHits,
  adverseMediaHits,
  mediaAlerts,
  pepHits,
  lastReviewedAt,
}) {
  const classes = useStyles();
  const [showAllData, setShowAllData] = useState(false);

  const hasDataToShow =
    Object.keys(potentialMatchDataFields).length > 0 || associates.length > 0;
  const hasManyDataPoints = Object.keys(potentialMatchDataFields).length > 4;

  useEffect(() => {
    setShowAllData(false);
  }, [name]);

  return (
    <div className={classes.root}>
      <div className={classes.nameAndDataContainer}>
        <div className={classes.nameAndExpandBtnContainer}>
          <Typography variant="section-heading" fontWeight={700}>
            {name}
          </Typography>

          {hasManyDataPoints ? (
            <Button
              aria-label="show_more_less_match_data"
              variant="text"
              onClick={() => {
                setShowAllData((prevState) => !prevState);
              }}
              endIcon={
                <ExpandMoreIcon
                  className={clsx({ [classes.invertedIcon]: showAllData })}
                />
              }
            >
              {showAllData ? 'View less' : 'View all'}
            </Button>
          ) : null}
        </div>

        {aliases.length > 0 ? (
          <TruncatedTextDisplay
            content={aliases.join(', ')}
            expandText="See all"
            textColor="primary"
            fontWeight={500}
          />
        ) : null}

        {hasDataToShow ? (
          <MatchDataDisplay
            associates={associates}
            potentialMatchDataFields={potentialMatchDataFields}
            showAllData={showAllData}
          />
        ) : null}
      </div>

      <div className={classes.allAlertsContainer}>
        {(sanctionHits?.length || 0) > 0 ? (
          <AlertsDisplay
            hits={sanctionHits}
            variant={alertVariants.SANCTIONS}
            lastReviewedAt={lastReviewedAt}
          />
        ) : null}

        {(warningHits?.length || 0) > 0 ? (
          <AlertsDisplay
            hits={warningHits}
            variant={alertVariants.WARNINGS}
            lastReviewedAt={lastReviewedAt}
          />
        ) : null}

        {(fitnessProbityHits?.length || 0) > 0 ? (
          <AlertsDisplay
            hits={fitnessProbityHits}
            variant={alertVariants.FITNESS_PROBITY}
            lastReviewedAt={lastReviewedAt}
          />
        ) : null}

        {(pepHits?.length || 0) > 0 ? (
          <AlertsDisplay
            hits={pepHits}
            variant={alertVariants.PEP}
            lastReviewedAt={lastReviewedAt}
          />
        ) : null}

        {(adverseMediaHits?.length || 0) > 0 ? (
          <AlertsDisplay
            hits={adverseMediaHits}
            variant={alertVariants.ADVERSE_MEDIA}
            lastReviewedAt={lastReviewedAt}
          />
        ) : null}

        {(mediaAlerts?.length || 0) > 0 ? (
          <AlertsDisplay
            hits={mediaAlerts}
            variant={alertVariants.MEDIA_ALERT}
            lastReviewedAt={lastReviewedAt}
          />
        ) : null}
      </div>
    </div>
  );
}
