export function findTrigramMatches(searchString, targetString) {
  /*
  Returns a list of pairs of indices where targetString matches a trigram of searchString

  For example:
  findTrigramMatches('abcXef', 'abcdef') => [[0, 3], [4, 6]]
  findTrigramMatches('abcdef', 'abcdef') => [[0, 6]]
  */

  // Combine all trigrams into one regex with lookahead to match overlaps
  // Include indices for each match (d flag)
  const trigrams = getTrigrams(searchString);
  // Sort trigrams by length descending so longer trigrams are matched first
  // e.g. search `abx` should become regex `( ab|abx|bx | a)` so that the
  // target ` abc ` tested against the regex matches " ab" instead of " a"
  const sortedTrigrams = Array.from(trigrams).sort(
    (a, b) => b.length - a.length,
  );
  const trigramRegex = new RegExp(`(?=(${sortedTrigrams.join('|')}))`, 'gd');

  // Replace all non-word characters with spaces and pad with spaces at the
  // beginning and end of targetString so partial trigrams match the ends
  const modifiedTargetString = ` ${targetString
    .replace(/\W/g, ' ')
    .toLowerCase()} `;

  const allMatchIndices = [];
  Array.from(modifiedTargetString.matchAll(trigramRegex)).forEach((match) => {
    // Subtract 1 from each index to account for the extra space at the beginning
    const matchIndices = match.indices[1].map((i) => Math.max(i - 1, 0));
    if (allMatchIndices.length) {
      const lastIndices = allMatchIndices[allMatchIndices.length - 1];
      if (matchIndices[0] <= lastIndices[1]) {
        // eslint-disable-next-line prefer-destructuring
        lastIndices[1] = matchIndices[1];
        return;
      }
    }
    allMatchIndices.push(matchIndices);
  });
  // Possibly clamp the last matching index to account for the padded end matching a suffix trigram
  if (allMatchIndices.length > 0) {
    const lastMatchingIndex = allMatchIndices[allMatchIndices.length - 1];
    lastMatchingIndex[1] = Math.min(lastMatchingIndex[1], targetString.length);
  }
  return allMatchIndices;
}

function getTrigrams(text) {
  if (!text) {
    return new Set();
  }

  const words = text
    .toLowerCase()
    .split(/\W+/)
    .filter((x) => x.trim());
  const trigrams = new Set();

  words.forEach((word) => {
    // Prefix two spaces and suffix one space to match pg_trgm behavior
    // https://www.postgresql.org/docs/current/pgtrgm.html#id-1.11.7.44.5
    const paddedWord = `  ${word} `;
    for (let i = 0; i < paddedWord.length - 2; i += 1) {
      const trigram = paddedWord.slice(i, i + 3);
      const strippedTrigram = trigram.replace(/\W+/g, ' ').toLowerCase();
      trigrams.add(strippedTrigram);
    }
  });
  return trigrams;
}
