import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  accessGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  numAccessText: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.primary.grey,
  },
  peopleIcon: {
    color: theme.palette.primary.grey,
  },
}));

function getNumAccessText(numCollaborators) {
  if (numCollaborators === 1) {
    return 'Only you have access.';
  }
  return `${numCollaborators} people have access.`;
}

export function AccessDropdownButton({ numCollaborators, lpDoc }) {
  const classes = useStyles();

  return (
    <div className={classes.accessGroup}>
      <Typography className={classes.numAccessText}>
        {getNumAccessText(numCollaborators || lpDoc.numCollaborators)}
      </Typography>
    </div>
  );
}
