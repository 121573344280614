import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import CardActionArea from '@material-ui/core/CardActionArea';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import { TrueLink } from 'components/link';
import OpenInNewIcon from 'mdi-material-ui/OpenInNew';

import { getSourceDisplay } from './answer_source';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    margin: theme.spacing(1, 0),
    borderRadius: 8,
    [theme.breakpoints.up('sm')]: {
      transition: '0.2s',
      '&:hover': {
        transform: 'scale(1.1)',
        zIndex: 1,
      },
    },
  },
  item: {
    fontSize: '2rem !important',
    padding: theme.spacing(2),
    width: '100%',
  },
  link: {
    marginLeft: theme.spacing(2),
  },
}));

export const MultiChoiceFileItem = ({
  children,
  lpDoc,
  activeProfile,
  sources,
  checked,
  onChange,
  name,
  fileUrl,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CardActionArea className={classes.card}>
        <Paper variant="outlined">
          <FormControlLabel
            className={classes.item}
            control={
              <Checkbox checked={checked} onChange={onChange} name={name} />
            }
            label={
              <span>
                {children}
                {sources.length ? (
                  <FormHelperText>
                    Selected by{' '}
                    {sources
                      .map((source) =>
                        getSourceDisplay({
                          source,
                          lpDoc,
                          activeProfile,
                        }),
                      )
                      .join(', ')}
                  </FormHelperText>
                ) : null}
              </span>
            }
          />
        </Paper>
      </CardActionArea>
      <TrueLink href={fileUrl} className={classes.link}>
        <OpenInNewIcon />
      </TrueLink>
    </div>
  );
};
