import React from 'react';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Alert, Typography } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';

import { Preview } from '../../preview';
import { Item } from './item';
import { Actions } from './actions';
import { Header } from '../../header';

const useStyles = makeStyles((theme) => ({
  alert: {
    padding: theme.spacing(2),
  },
  preview: {
    height: '500px',
    overflow: 'scroll',
    padding: theme.spacing(2),
  },
}));

export const PotentialMatchCard = ({
  potentialMatches,
  question,
  potentialMatch,
  setPotentialMatch,
  toQuestionnaire,
  questionnaireId,
  fundId,
  onMatch,
  onIgnore,
}) => {
  const classes = useStyles();

  return (
    <Paper elevation={0} variant="outlined">
      <Grid container>
        <Grid xs={12}>
          <List disablePadding>
            <ListItem>
              <ListItemText>
                <Typography variant="card-heading" size="small">
                  {question.id}{' '}
                  <Typography variant="label" color="text.secondary">
                    {question.answerType}
                  </Typography>
                </Typography>
                <Typography variant="body">{question.question}</Typography>
              </ListItemText>
            </ListItem>
            <Header
              title="Potential matches"
              description={
                <span>
                  Select a question below <strong>ONLY IF</strong> it has the{' '}
                  <strong>SAME MEANING</strong> as the question above. If none
                  of the questions below have the exact same meaning, you should
                  ignore the question.
                </span>
              }
            />
            {potentialMatches.length > 0 ? (
              <>
                {potentialMatches.map((item) => (
                  <Item
                    key={item.id}
                    question={question}
                    potentialMatch={item}
                    setPotentialMatch={() => {
                      setPotentialMatch(item);
                    }}
                    active={item?.id === potentialMatch?.id}
                  />
                ))}
              </>
            ) : (
              <div className={classes.alert}>
                <Alert
                  severity="warning"
                  title="There are no potential matches for this question."
                >
                  Some questions may be similar but will not appear if they are
                  incompatible with the type of the question above or were
                  already mapped to a different question.
                </Alert>
              </div>
            )}
          </List>

          <Divider />
          <Actions
            question={question}
            potentialMatch={potentialMatch}
            onMatch={onMatch}
            onIgnore={onIgnore}
          />
        </Grid>
        {potentialMatch ? (
          <Grid container xs={12}>
            <Header title="Preview" description="" />
            <Grid xs={6}>
              <Preview
                item={question}
                fundId={fundId}
                questionnaireId={questionnaireId}
              />
            </Grid>
            <Grid xs={6}>
              <Preview
                item={potentialMatch.question}
                fundId={toQuestionnaire.fundId}
                questionnaireId={toQuestionnaire.id}
              />
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  );
};
