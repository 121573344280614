import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Typography from '@material-ui/core/Typography';

import { useToast } from 'services/toast';
import { Button } from 'components/button';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  commentActionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  helperText: {
    fontSize: '14px',
    color: theme.palette.primary.grey,
    margin: theme.spacing(1, 0, 2, 0),
  },
  errorText: {
    color: theme.palette.error.main,
  },
}));

export function SendLPComment({
  draftNote,
  setDraftNote,
  questionId,
  isDiligenceQuestion,
  fundName,
  sendLPComment,
  handleClose,
  handleGoBack,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const classes = useStyles();
  const { toast } = useToast();

  const submitLPComment = () => {
    setIsLoading(true);
    setHasError(false);

    sendLPComment({
      label: questionId,
      lpComment: draftNote,
      isDiligence: isDiligenceQuestion,
    })
      .then(() => {
        setDraftNote('');
        setIsLoading(false);
        handleClose();
        toast('Notification sent to the fund');
      })
      .catch(() => {
        setHasError(true);
        setIsLoading(false);
      });
  };

  return (
    <div className={classes.root}>
      <TextField
        multiline
        maxRows={12}
        minRows={4}
        label="Comment"
        value={draftNote}
        onChange={(e) => {
          setDraftNote(e.target.value);
        }}
        autoFocus
        variant="outlined"
      />

      <Typography className={classes.helperText}>
        {fundName} will be notified.
      </Typography>

      <div className={classes.commentActionButtons}>
        <Button
          variant="text"
          color="default"
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={handleGoBack}
        >
          Back
        </Button>

        <Button
          disabled={!draftNote}
          loading={isLoading}
          onClick={submitLPComment}
          variant="contained"
        >
          Comment
        </Button>
      </div>

      {hasError ? (
        <div className={classes.errorText}>
          There was an error submitting your comment.
        </div>
      ) : null}
    </div>
  );
}
