import React from 'react';

import { PotentialMatchCard } from './cards/potential_match';
import { DefaultCard } from './cards/default';
import { FILTERS } from '../constants';

export const MatchView = ({
  question,
  potentialMatch,
  setPotentialMatch,
  toQuestionnaire,
  questionnaireId,
  fundId,
  onMatch,
  onIgnore,
}) => {
  if (
    question.filters.includes(FILTERS.NEEDS_ATTENTION) ||
    question.filters.includes(FILTERS.MAPPED) ||
    question.filters.includes(FILTERS.IGNORED)
  ) {
    return (
      <PotentialMatchCard
        potentialMatches={question?.mapping?.potentialMatches || []}
        question={question}
        potentialMatch={potentialMatch}
        setPotentialMatch={setPotentialMatch}
        toQuestionnaire={toQuestionnaire}
        questionnaireId={questionnaireId}
        fundId={fundId}
        onMatch={onMatch}
        onIgnore={onIgnore}
      />
    );
  }

  return (
    <DefaultCard
      potentialMatches={question?.mapping?.potentialMatches || []}
      question={question}
      potentialMatch={potentialMatch}
      setPotentialMatch={setPotentialMatch}
      toQuestionnaire={toQuestionnaire}
      questionnaireId={questionnaireId}
      fundId={fundId}
      onMatch={onMatch}
      onIgnore={onIgnore}
    />
  );
};
