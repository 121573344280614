import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Alert } from 'components/alert';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import Tooltip from '@material-ui/core/Tooltip';

import {
  Typography as PassTypography,
  useConfirm,
  Button,
} from '@passthrough/uikit';
import * as urls from 'services/urls';
import * as api from 'services/api';
import { useToast } from 'services/toast';

import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from 'components/table/index';
import { EmptyState } from 'components/empty_v2';

import { CopyValueButton } from './copy_value_button';
import { IntegrationDialog } from './integration_dialog';

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: theme.spacing(3),
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    marginTop: theme.spacing(6),
  },
  verticalGap: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(1),
  },
  dpa: {
    marginTop: theme.spacing(1),
  },
}));

export function IntegrationTable() {
  const organizationId = urls.useOrganizationId();
  const classes = useStyles();
  const confirm = useConfirm();
  const { successToast, errorToast } = useToast();
  const [isLoading, setLoading] = useState(true);
  const [showAlert, setAlert] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState(null);
  const [editIntegration, setEditIntegration] = useState(null);
  const [integrations, setIntegrations] = useState([]);

  const openDialog = (integration = null) => {
    setEditIntegration(integration);
    setDialogAction(integration ? 'Update' : 'Add');
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setEditIntegration(null);
  };

  const fetchIntegrations = () => {
    setLoading(true);

    api
      .integrations({ organizationId })
      .then((response) => {
        setIntegrations(response.data);
      })
      .catch(() => {
        errorToast('Failed to retrieve applications.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteIntegration = (integration) =>
    confirm({
      description: `You are about to delete the application "${integration.name}".`,
      destructive: true,
    })
      .then(() => {
        setLoading(true);

        api
          .deleteIntegration({ organizationId, integrationId: integration.id })
          .then(() => {
            fetchIntegrations();
            successToast(`Application "${integration.name}" deleted.`);
          })
          .catch(() => {
            errorToast(`Failed to delete "${integration.name}".`);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {});

  const onAddIntegration = (integration) => {
    setAlert(true);
    setIntegrations((data) => [...data, { isNew: true, ...integration }]);
    closeDialog();
    successToast(`Application "${integration.name}" created.`);
  };

  const onUpdateIntegration = (integration) => {
    fetchIntegrations();
    closeDialog();
    successToast(`Application "${integration.name}" updated.`);
  };

  useEffect(() => {
    fetchIntegrations();
  }, []);

  return (
    <>
      <div className={classes.spaceBetween}>
        <div className={classes.verticalGap}>
          <Typography variant="h6">API keys</Typography>
          <PassTypography variant="label" color="text.secondary">
            Manage API keys and configure webhooks.
          </PassTypography>
        </div>
        <Button
          variant="primary"
          onClick={() => openDialog(null)}
          startIcon={<AddIcon />}
          disabled={isLoading}
        >
          Add API key
        </Button>
      </div>

      {showAlert ? (
        <Alert severity="info" className={classes.marginTop}>
          Make sure to copy your API key now. You won’t be able to see it again!
        </Alert>
      ) : null}

      <TableContainer>
        <Table
          belowRowsElement={
            integrations.length === 0 ? (
              <EmptyState title="No application" />
            ) : null
          }
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Webhook URL</TableCell>
              <TableCell>API key</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {integrations.map((integration) => (
              <TableRow key={integration.id}>
                <TableCell>{integration.name}</TableCell>
                <TableCell>{integration.callbackUrl || '-'}</TableCell>
                <TableCell>
                  {integration.isNew ? (
                    <CopyValueButton value={integration.key} />
                  ) : (
                    integration.key
                  )}
                </TableCell>
                <TableCell align="right">
                  <Tooltip title={<Typography>Edit</Typography>}>
                    <IconButton
                      onClick={() => openDialog(integration)}
                      disabled={isLoading}
                    >
                      <SettingsIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={<Typography>Delete</Typography>}>
                    <IconButton
                      onClick={() => deleteIntegration(integration)}
                      disabled={isLoading}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div className={classes.dpa}>
        <PassTypography variant="label" color="text.secondary">
          By placing your API key on third-party systems that you choose to
          integrate with Passthrough, you authorize Passthrough to share your
          customer data with the relevant integration partner.
        </PassTypography>
      </div>
      <IntegrationDialog
        organizationId={organizationId}
        open={isDialogOpen}
        action={dialogAction}
        onClose={closeDialog}
        onSubmit={editIntegration ? onUpdateIntegration : onAddIntegration}
        integration={editIntegration}
      />
    </>
  );
}
