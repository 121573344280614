import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Modal, Typography } from '@passthrough/uikit';

const useStyles = makeStyles(() => ({
  list: {
    display: 'list-item',
    listStyle: 'disc inside',
  },
}));

export function CannotDeleteDialog({ open, handleClose, statusesToDisplay }) {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      showCancelButton
      headerLabel="Select only 1 investor to delete"
      footer={null}
    >
      <Typography>
        For investors who have completed their questionnaire, you may only
        delete them one at a time.
      </Typography>

      <Typography>
        This includes investors with the following statuses:
      </Typography>

      <List dense disablePadding>
        {statusesToDisplay.map((status) => (
          <ListItem key={status} className={classes.list}>
            <Typography variant="label">{status}</Typography>
          </ListItem>
        ))}
      </List>
    </Modal>
  );
}
