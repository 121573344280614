// cspell:ignore firstname lastname
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useWhiteLabelConfig } from 'services/providers/theme';
import clsx from 'clsx';

import { Typography, Button } from '@passthrough/uikit';
import { ExploreStorage } from './utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  passthrough: {
    backgroundColor: theme.palette.demoMode[1],
    color: theme.palette.primary.black,
  },
  button: {
    marginLeft: theme.spacing(2),
  },
}));

const buildUrl = (form) => {
  let url = 'https://passthrough.com/demo-request';

  if (form?.name) {
    const [firstName, lastName] = form.name.split(' ', 2);
    const params = new URLSearchParams();
    params.append('firstname', firstName);
    params.append('lastname', lastName);
    params.append('email', form.email);
    params.append('customer_account', form.company);
    if (form.howDidYouHear) {
      params.append('how_did_you_hear_about_us', form.howDidYouHear);
    }
    url += `?${params.toString()}`;
  }
  return url;
};

export const Banner = () => {
  const classes = useStyles();
  const { productName } = useWhiteLabelConfig();
  const isPassthrough = !window.WHITE_LABEL_CONFIG;
  const form = ExploreStorage.getForm();

  return (
    <div
      className={clsx(classes.root, { [classes.passthrough]: isPassthrough })}
    >
      <div>
        <Typography variant="card-heading">Explore mode</Typography>
      </div>
      <div>
        You're using test data to explore {productName}.
        {isPassthrough ? (
          <span className={classes.button}>
            <Button
              variant="primary"
              size="small"
              href={buildUrl(form)}
              external
            >
              Get a demo
            </Button>
          </span>
        ) : null}
      </div>
      <div />
    </div>
  );
};
