import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@passthrough/uikit';

import { NoteIcon } from './note_icon';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing(1),
  },
}));

export function NoteBoxLabel({ variant, children }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <NoteIcon variant={variant} />
      <Typography variant="card-heading">{children}</Typography>
    </div>
  );
}
