import React from 'react';

import { GenericInvestorTagGroup } from './generic_investor_tag_group';

export function LpCustomTagGroup({
  tagGroupName,
  tags,
  locallySelectedFilters,
  setLocallySelected,
}) {
  return (
    <GenericInvestorTagGroup
      tagGroupName={tagGroupName}
      tags={tags}
      locallySelectedFilters={locallySelectedFilters}
      setLocallySelected={setLocallySelected}
    />
  );
}
