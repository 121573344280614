export const getCollaboratorId = (c) =>
  c.userId ? c.userId : `${c.name}-${c.email}`;

export const sortCollaborators = (collaborators) => {
  const sortedCollaborators = [...collaborators];

  sortedCollaborators.sort((a, b) => {
    if (a.accessLevel === 0) {
      return a;
    }
    if (b.accessLevel === 0) {
      return b;
    }
    return a;
  });
  return sortedCollaborators;
};

export const normalizeName = (name) =>
  name
    .toLowerCase()
    .replace(/[^\p{L}\d\s]/gu, ' ')
    .replace(/\s+/g, ' ')
    .trim();

// ChatGPT prompt: js levenshtein distance 2 strings
function getEditDistance(str1, str2) {
  const m = str1.length;
  const n = str2.length;

  // Create a matrix to store the distances
  const dp = new Array(m + 1);
  for (let i = 0; i <= m; i += 1) {
    dp[i] = new Array(n + 1);
    dp[i][0] = i;
  }
  for (let j = 0; j <= n; j += 1) {
    dp[0][j] = j;
  }

  // Calculate the minimum edit distance
  for (let i = 1; i <= m; i += 1) {
    for (let j = 1; j <= n; j += 1) {
      if (str1[i - 1] === str2[j - 1]) {
        dp[i][j] = dp[i - 1][j - 1];
      } else {
        dp[i][j] = Math.min(
          dp[i - 1][j] + 1, // Deletion
          dp[i][j - 1] + 1, // Insertion
          dp[i - 1][j - 1] + 1, // Substitution
        );
      }
    }
  }

  return dp[m][n];
}

export const sortAvailableProfiles = (profiles, lpName) => {
  const sortedProfiles = [...profiles];
  const editDistanceMap = {};

  sortedProfiles.forEach((profile) => {
    const name = normalizeName(profile.displayName);
    const normalizedLpName = normalizeName(lpName);
    const editDistance = getEditDistance(name, normalizedLpName);
    editDistanceMap[profile.id] = editDistance;
  });

  return sortedProfiles.sort((a, b) => {
    if (editDistanceMap[a.id] < editDistanceMap[b.id]) {
      return -1;
    }
    if (editDistanceMap[a.id] > editDistanceMap[b.id]) {
      return 1;
    }

    // Alphabetical order
    if (a.displayName < b.displayName) {
      return -1;
    }
    if (a.displayName > b.displayName) {
      return 1;
    }
    return 0;
  });
};
