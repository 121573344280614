import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import { Typography, Icons, Button } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'relative',
    backgroundColor: theme.palette.appbar.background,
    padding: theme.spacing(1),
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}));

function WithTooltip({ children, title }) {
  if (!title) return children;
  return (
    <Tooltip title={<Typography variant="label">{title}</Typography>}>
      <div>{children}</div>
    </Tooltip>
  );
}

export function TopBar({
  handleClose,
  totalBoxes,
  placedBoxes,
  handleSubmit,
  submitLoading,
}) {
  const classes = useStyles();
  const canSubmit = totalBoxes === placedBoxes && totalBoxes > 0;
  const tooltipText = canSubmit
    ? ''
    : 'All fields must be added to the document';

  return (
    <AppBar className={classes.container}>
      <div className={classes.leftSide}>
        <IconButton
          name="back"
          variant="text"
          color="default"
          size="medium"
          data-test="close-modal"
          onClick={handleClose}
        >
          <Icons.Close />
        </IconButton>
        <Typography variant="card-heading">Close</Typography>
      </div>

      <div className={classes.rightSide}>
        {canSubmit ? (
          <CheckCircleOutline color="primary" fontSize="small" />
        ) : null}
        <Typography variant="label">
          {placedBoxes}/{totalBoxes} fields added
        </Typography>
        <WithTooltip title={tooltipText}>
          <Button
            data-test="save-document"
            variant="primary"
            disabled={!canSubmit}
            loading={submitLoading}
            onClick={handleSubmit}
          >
            Save document
          </Button>
        </WithTooltip>
      </div>
    </AppBar>
  );
}
