import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { HelpDisplay } from 'components/help_display';
import { useQuestionnaire } from 'services/providers/questionnaire';
import { Question } from './question';
import { getReconciliationComponent } from './reconciliation/components';
import { useReconciliation } from './reconciliation/provider';

import { CommentThreadDisplay } from './comment_thread/index';

const useStyles = makeStyles(() => ({
  paper: {
    minHeight: '500px',
  },
  restrictedThreadDisplay: {
    maxWidth: '600px',
    margin: 'auto',
  },
  compact: {
    minHeight: 'auto',
  },
}));

export function QuestionCard({
  activeProfile,
  lpDoc,
  lpDiligence,
  question,
  comments,
  sendLPComment,
  onAnswer,
  saving,
  setSaving,
  updateLpDoc,
  QuestionStepperComponent,
  DiligenceQuestionStepperComponent,
  nav,
  navRef,
  enableLpRequestedReview,
  setHasLpRequestedReview,
  missingDiligenceDataErrors,
  resetDiligenceQuestionValidation,
  updateSingleDiligenceQuestionValidation,
}) {
  const classes = useStyles();
  const CurrentQuestion = question.component;
  const ReconciliationComponent = getReconciliationComponent({ question });
  const { useSupport, compactQuestionEnabled, scrollToTop } =
    useQuestionnaire();
  const { setMenuComponent, setMenuComponentProps } = useSupport();
  const { showReconciliationPage, reconciliationAnswer } = useReconciliation();

  const showCommentThread = comments.length > 0;

  useEffect(() => {
    setMenuComponent(() => HelpDisplay);
    setMenuComponentProps((prevProps) => ({
      ...prevProps,
      fundName: lpDoc.fundName,
      sendLPComment,
    }));

    return () => {
      setMenuComponent(null);
      setMenuComponentProps({});
    };
  }, []);

  useEffect(() => {
    setMenuComponentProps((prevProps) => ({
      ...prevProps,
      questionId: question.label,
      isDiligenceQuestion: question.isDiligenceQuestion,
    }));

    // scroll to top to ensure that users always see full question text even when
    // using a compressed browser tab
    scrollToTop();
  }, [question.label]);

  const questionPrefixText = !question.isRequired ? '(Optional) ' : null;
  const QuestionPromptComponent = ({
    short,
    addPrefixText = true,
    children,
  }) => (
    <Question
      links={question.links || []}
      prefixText={addPrefixText ? questionPrefixText : null}
      short={short || compactQuestionEnabled}
      question={question.question}
      pdfBoxes={question.pdfBoxes || []}
    >
      {children}
    </Question>
  );

  return (
    <>
      {showCommentThread ? (
        <CommentThreadDisplay
          comments={comments}
          sendLPComment={sendLPComment}
          questionId={question.label}
          isDiligenceQuestion={question.isDiligenceQuestion}
          className={classes.restrictedThreadDisplay}
        />
      ) : null}

      {showReconciliationPage && ReconciliationComponent ? (
        <ReconciliationComponent
          navRef={navRef}
          question={question}
          answersToReconcile={question.answersToReconcile}
          lpDoc={lpDoc}
          activeProfile={activeProfile}
          updateLpDoc={updateLpDoc}
          setSaving={setSaving}
          QuestionStepper={QuestionStepperComponent}
          QuestionPromptComponent={QuestionPromptComponent}
        />
      ) : (
        <div
          className={clsx(classes.paper, {
            [classes.compact]: compactQuestionEnabled,
          })}
        >
          <CurrentQuestion
            lpDoc={lpDoc}
            lpDiligence={lpDiligence}
            onAnswer={onAnswer}
            updateLpDoc={updateLpDoc}
            saving={saving}
            setSaving={setSaving}
            question={question.question}
            answer={reconciliationAnswer || question.answer}
            choices={question.choices?.map((c) => ({
              id: c.id,
              text: c.text,
              exclusive: c.exclusive,
            }))}
            allowSelectAll={question.allowSelectAll}
            links={question.links || []}
            frontendLabel={question.frontendLabel}
            label={question.label}
            isRequired={question.isRequired}
            notInteroperable={question.notInteroperable}
            pdfBoxes={question.pdfBoxes || []}
            QuestionStepper={QuestionStepperComponent}
            DiligenceQuestionStepper={DiligenceQuestionStepperComponent}
            nav={nav}
            requireLpRequestReview={lpDoc.requireLpRequestReview}
            enableLpRequestedReview={enableLpRequestedReview}
            setHasLpRequestedReview={setHasLpRequestedReview}
            QuestionPromptComponent={QuestionPromptComponent}
            compact={compactQuestionEnabled}
            missingDiligenceDataErrors={missingDiligenceDataErrors}
            resetDiligenceQuestionValidation={resetDiligenceQuestionValidation}
            updateSingleDiligenceQuestionValidation={
              updateSingleDiligenceQuestionValidation
            }
          />
        </div>
      )}
    </>
  );
}
