import React from 'react';
import { Typography } from '@passthrough/uikit';
import { KeyValuePair } from 'components/key_value';
import Divider from '@material-ui/core/Divider';

export function CountersignerNamesDisplay({
  numberOfCountersigners,
  members,
  Icon,
  countersignerId,
  countersigner2Id,
  countersigner3Id,
}) {
  return numberOfCountersigners > 0 ? (
    <>
      <KeyValuePair
        icon={Icon}
        label={
          <Typography size="small" color="text.secondary">
            {numberOfCountersigners > 1 ? 'Countersigners' : 'Countersigner'}
          </Typography>
        }
      >
        <Typography>
          {members.find((m) => m.id === countersignerId).name}
          {numberOfCountersigners > 1 ? ', ' : ''}
          {members.find((m) => m.id === countersigner2Id)?.name}
          {numberOfCountersigners > 2 ? ', ' : ''}
          {members.find((m) => m.id === countersigner3Id)?.name}
        </Typography>
      </KeyValuePair>
      <Divider />
    </>
  ) : null;
}
