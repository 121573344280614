import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { Button } from 'components/button';

import { useMe } from 'services/providers/me';
import { useConfirm, UserDisplay } from '@passthrough/uikit';
import { getSingleErrorFromResponse } from 'services/utils';
import { useToast } from 'services/toast';
import * as api from 'services/api';

import { AddNewCollaboratorModal } from './add_new_collaborator_modal';

const useStyles = makeStyles((theme) => ({
  ownersTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  contactSection: {
    padding: theme.spacing(2),
  },
  contactItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
}));

export const LeftSidebarClaimed = ({ profile }) => {
  const classes = useStyles();
  const [users, setUsers] = React.useState([]);
  const [openAddModal, setOpenAddModal] = React.useState(false);
  const confirm = useConfirm();
  const { toast, errorToast } = useToast();
  const [me] = useMe();

  const fetchProfileUsers = () => {
    api.profileUsers({ profileId: profile.id }).then((response) => {
      setUsers(response.data);
    });
  };

  const onDelete = (collaborator) => {
    confirm({
      description: `Remove ${collaborator.name}? Removed users
      will be notified about the removal via email.`,
      destructive: true,
    })
      .then(() => {
        api
          .profileUserDelete({
            profileId: profile.id,
            profileUserId: collaborator.id,
          })
          .then(() => {
            toast('Removed user.');
            fetchProfileUsers();
          })
          .catch((error) => {
            if (error.response?.status === 400) {
              errorToast(getSingleErrorFromResponse(error.response));
            }
          });
      })
      .catch(() => {});
  };

  React.useEffect(() => {
    fetchProfileUsers();
  }, [profile.id]);

  return (
    <>
      <div className={classes.ownersTitleContainer}>
        <div>
          <Typography variant="subtitle2">Collaborators</Typography>
        </div>
        <div>
          <Button
            variant="text"
            size="small"
            onClick={() => setOpenAddModal(true)}
            startIcon={<AddIcon />}
          >
            Add collaborator
          </Button>
        </div>
      </div>
      <div className={classes.contactSection}>
        {users.map((user) => (
          <div className={classes.contactItem}>
            <UserDisplay
              variant="default"
              name={user.name}
              email={user.email}
            />
            {me.id !== user.userId ? (
              <Tooltip title={<Typography>Delete</Typography>}>
                <IconButton onClick={() => onDelete(user)} size="small">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            ) : null}
          </div>
        ))}
      </div>
      <AddNewCollaboratorModal
        open={openAddModal}
        handleClose={() => {
          setOpenAddModal(false);
        }}
        fetchProfileUsers={fetchProfileUsers}
        profileId={profile.id}
      />
    </>
  );
};
