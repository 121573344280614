import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AddCommentOutlinedIcon from '@material-ui/icons/AddCommentOutlined';
import FormHelperText from '@material-ui/core/FormHelperText';

import * as api from 'services/api';
import { Button } from 'components/button';
import { NoteBox } from './note_box';
import { reducerActionTypes } from '../constants';

const useStyles = makeStyles((theme) => ({
  addNoteInstructions: {
    color: theme.palette.primary.grey,
    marginBottom: theme.spacing(3),
  },
  maxWidth: {
    width: '100%',
  },
  errorText: {
    textAlign: 'center',
  },
}));

export function AddNotesDisplay({
  fundName,
  questionId,
  isApproved,
  note,
  isReviewMode,
  dispatch,
}) {
  const classes = useStyles();
  const { fundId, lpClosingId } = useParams();
  const [showNoteInput, setShowNoteInput] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  function handleSave(draftNote) {
    setErrorMsg('');
    setIsLoading(true);

    const currentNote = note;
    // optimistic update
    dispatch({
      type: reducerActionTypes.ADD_NOTE_TO_INVESTOR,
      newNoteToInvestor: draftNote,
    });

    api
      .diligenceNodeRequestChange({
        fundId,
        lpClosingId,
        questionId,
        note: draftNote,
      })
      .then(() => {
        setShowNoteInput(false);
        setIsLoading(false);
      })
      .catch(() => {
        setErrorMsg('There was an error saving the note to investor.');
        setIsLoading(false);
        dispatch({
          type: reducerActionTypes.ADD_NOTE_TO_INVESTOR,
          newNoteToInvestor: currentNote,
        });
      });
  }

  function handleDelete() {
    setErrorMsg('');
    setIsLoading(true);
    dispatch({
      type: reducerActionTypes.DELETE_NOTE_TO_INVESTOR,
    });

    api
      .diligenceNodeDeleteRequestChange({
        fundId,
        lpClosingId,
        questionId,
      })
      .then(() => {
        setShowNoteInput(false);
        setIsLoading(false);
      })
      .catch(() => {
        dispatch({
          type: reducerActionTypes.ADD_NOTE_TO_INVESTOR,
          newNoteToInvestor: note,
        });
        setErrorMsg('There was an error deleting the note to investor.');
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (isApproved) {
      setShowNoteInput(false);
    }
  }, [isApproved]);

  useEffect(() => {
    setErrorMsg('');
  }, [questionId]);

  return (
    <>
      {!showNoteInput && !note ? (
        <Button
          aria-label="diligence_add_note_to_investor"
          data-test="diligence_add_note_to_investor"
          variant="outlined"
          disabled={isApproved || !isReviewMode || isLoading}
          className={classes.maxWidth}
          startIcon={<AddCommentOutlinedIcon />}
          onClick={() => setShowNoteInput(true)}
        >
          Comment for investor
        </Button>
      ) : (
        <>
          <NoteBox
            fundName={fundName}
            isReviewMode={isReviewMode}
            note={note}
            canAddNote
            isLoading={isLoading}
            onDelete={handleDelete}
            onSave={handleSave}
            hideSavedNoteIcon
            showSavedNoteLabel
            showDivider={false}
          />

          {errorMsg ? (
            <FormHelperText error className={classes.errorText}>
              {errorMsg}
            </FormHelperText>
          ) : null}
        </>
      )}
    </>
  );
}
