import React from 'react';

import { Modal, Typography } from '@passthrough/uikit';
import { ProfileSelectItem } from 'components/profile_select_item';

export function NameWarningDialog({
  open,
  onClose,
  lpName,
  onSelectProfile,
  profileId,
  profileName,
  lastUsed,
  collaborators,
}) {
  const onSubmit = () => {
    onSelectProfile(profileId);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      headerLabel={`Use this profile for ${lpName}?`}
      showCancelButton
      onSubmit={onSubmit}
      primaryButtonText="Use this profile"
      primaryButtonProps={{
        variant: 'secondary',
        'data-test': 'name_warning_confirm_continue',
      }}
    >
      <Typography>
        You are applying sensitive data from a previous investment.
      </Typography>
      <ProfileSelectItem
        profileName={profileName}
        lastUsed={lastUsed}
        collaborators={collaborators}
        showNameWarning
        hideRadio
        outlined
      />
    </Modal>
  );
}
