import React, { useEffect, useState, useRef } from 'react';
import { Document, Page } from 'react-pdf';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Modal } from '@passthrough/uikit';

import { Spinner } from 'components/spinner';
import { PDF_HEIGHT, PDF_WIDTH } from 'services/pdf_dimensions';
import { COLUMN_NAME_OVERRIDE } from './constants';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    minHeight: `${PDF_HEIGHT}px`,
    minWidth: `${PDF_WIDTH}px`,
    margin: 'auto',
  },
  pdfDocument: {
    position: 'absolute',
  },
  pdfPage: {
    outlineStyle: 'solid',
    outlineWidth: '2px',
    outlineColor: theme.palette.neutral.border,
  },
  canvas: {
    // For whatever reason having a mixBlendMode on a canvas
    // inside a flexbox with overflowY auto makes the canvas go crazy.
    // So don't use mixBlendMode: 'multiply' here with the UiKit modal.
    // TODO: How does canvas with mixBlendMode in display flex work
    position: 'absolute',
  },
}));

export function BulkCountersignPdfReviewModal({
  open,
  handleClose,
  closingSubDoc,
  page,
  apiId,
}) {
  const classes = useStyles();
  const [pdfIsLoaded, setPdfIsLoaded] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const theme = useTheme();
  const drawCanvasRef = useRef(null);

  function initDrawingCanvas() {
    if (!pdfIsLoaded) {
      return () => {};
    }
    const drawCanvas = drawCanvasRef.current;
    const ctx = drawCanvas.getContext('2d');
    const gpFields = closingSubDoc?.allGpFields || [];
    const selectedBox = gpFields.find((box) => box.label === apiId);

    function drawBox(box, fillColor, lineColor, lineWidth) {
      ctx.fillStyle = `${fillColor}`;
      ctx.fillRect(box.x, box.y, box.width, box.height);
      ctx.strokeStyle = lineColor;
      ctx.lineWidth = lineWidth;
      if (lineWidth > 0) {
        ctx.strokeRect(box.x, box.y, box.width, box.height);
      }
    }

    function drawBoxText(box) {
      ctx.fillStyle = 'black';
      let text;
      if (box.type === 'accepted_commitment') {
        text = 'Accepted commitment';
      } else {
        text = COLUMN_NAME_OVERRIDE[box.name] || box.name;
      }
      ctx.fillText(text, box.x + box.width / 2, box.y + box.height / 2);
    }

    ctx.font = '11px Helvetica';
    ctx.textBaseline = 'middle';
    ctx.textAlign = 'center';
    const color = theme.palette.info;
    drawBox(selectedBox, color.fill, color.main, 1);
    drawBoxText(selectedBox);

    function cleanup() {
      ctx.clearRect(0, 0, drawCanvas.width, drawCanvas.height);
    }
    return cleanup;
  }

  useEffect(initDrawingCanvas, [pdfIsLoaded, page, closingSubDoc]);

  const onClose = () => {
    setPdfIsLoaded(false);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      footer={null}
      headerLabel={closingSubDoc?.name}
      subheaderLabel={`Page ${page} of ${numPages}`}
      size="md"
      disablePortal // https://stackoverflow.com/a/54133377
    >
      <div className={classes.container}>
        <Document
          className={classes.pdfDocument}
          file={closingSubDoc?.url}
          onLoadSuccess={(pdf) => {
            setPdfIsLoaded(true);
            setNumPages(pdf.numPages);
          }}
          loading={<Spinner height={PDF_HEIGHT} width={PDF_WIDTH} />}
        >
          <Page
            className={classes.pdfPage}
            pageNumber={page}
            loading={<Spinner height={PDF_HEIGHT} width={PDF_WIDTH} />}
            height={PDF_HEIGHT}
            width={PDF_WIDTH}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </Document>
        {pdfIsLoaded && (
          <canvas
            ref={drawCanvasRef}
            height={`${PDF_HEIGHT}px`}
            width={`${PDF_WIDTH}px`}
            className={classes.canvas}
          />
        )}
      </div>
    </Modal>
  );
}
