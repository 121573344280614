import { getLPClosingStatusInfo } from 'components/status';
import {
  dateCompare,
  strCompare,
  numCompare,
} from 'services/shared_sort_comparisons';

import {
  CRITICAL_RISK,
  HIGH_RISK,
  LOW_RISK,
  MODERATE_RISK,
  NO_RATING,
} from 'components/risk_rating/constants';

// By caching statusInfo, we avoid calling getLPClosingStatusInfo
// n * log(n) times when sorting the list. Assumes investors were
// serialized by LPClosingStatusSerializer on the backend.
export function annotateInvestorsWithStatusInfo(investors) {
  return investors.map((investor) => {
    const annotatedInvestor = { ...investor };
    annotatedInvestor.statusInfo = getLPClosingStatusInfo(investor);
    return annotatedInvestor;
  });
}

export function statusCompare(a, b, dir) {
  const { order: aOrder, event: aEvent = '' } = a.statusInfo;
  const { order: bOrder, event: bEvent = '' } = b.statusInfo;

  if (aOrder === bOrder) {
    if (aEvent === bEvent) {
      // Ties are broken by event time then by name.
      return 0;
    }
    // We don't want any of the extra logic in strCompare so compare
    // directly. Currently this just sorts null < Sent < Sent reminder.
    if (dir === 'asc') {
      return aEvent < bEvent ? 1 : -1;
    }
    return aEvent < bEvent ? -1 : 1;
  }

  if (dir === 'asc') {
    return bOrder - aOrder;
  }
  return aOrder - bOrder;
}

export function getRiskRatingOrder(riskRating) {
  switch (riskRating) {
    case CRITICAL_RISK:
      return 1;
    case HIGH_RISK:
      return 2;
    case MODERATE_RISK:
      return 3;
    case NO_RATING:
      return 4;
    case LOW_RISK:
      return 5;
    default:
      return 6;
  }
}

function riskRatingCompare(a, b, dir) {
  const aOrder = getRiskRatingOrder(a.riskRating);
  const bOrder = getRiskRatingOrder(b.riskRating);
  if (dir === 'asc') {
    return bOrder - aOrder;
  }
  return aOrder - bOrder;
}

const getDateToCompare = (inv) => inv.statusInfo.timeAgo;
const getLPName = (inv) => inv.lpName;
const getLegalName = (inv) => inv.legalName;
const getCommitment = (inv) => Number(inv.commitment);

// Assumes the investors list was annotated by annotateInvestorsWithStatusInfo.
export function sortInvestors(order, investors) {
  const [columnId, direction] = order;
  const sortedInvestors = [...investors];

  sortedInvestors.sort((a, b) => {
    // Secondary sorting dimensions
    const byNameDesc = strCompare(a, b, getLPName, 'desc');
    const byStatusDesc = statusCompare(a, b, 'desc');

    switch (columnId) {
      case 'investor':
        /* eslint-disable-next-line no-case-declarations */
        const byName = strCompare(a, b, getLPName, direction);
        return byName || byStatusDesc;
      case 'legalName': {
        const byLegalName = strCompare(a, b, getLegalName, direction);

        return byLegalName || byStatusDesc;
      }
      case 'status':
        /* eslint-disable-next-line no-case-declarations */
        const byStatus = statusCompare(a, b, direction);
        /* eslint-disable-next-line no-case-declarations */
        const byDate = dateCompare(a, b, getDateToCompare, direction);
        return byStatus || byDate || byNameDesc;
      case 'commitment':
        /* eslint-disable-next-line no-case-declarations */
        const byCommitment = numCompare(a, b, getCommitment, direction);
        return byCommitment || byNameDesc;
      case 'riskRating': {
        const byRiskRating = riskRatingCompare(a, b, direction);

        return byRiskRating || byNameDesc;
      }
      default:
        throw Error('unknown column');
    }
  });

  return sortedInvestors;
}
