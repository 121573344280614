import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/PersonOutlineOutlined';
import { TrigramMatchingText } from './TrigramMatchingText';
import { findTrigramMatches } from './trigram_utils';

const useStyles = makeStyles((theme) => ({
  collaboratorResult: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    borderLeft: `1px solid ${theme.palette.divider}`,
    paddingLeft: theme.spacing(1),
  },
  personIcon: {
    color: theme.palette.info.icon,
    fontSize: '1.2rem',
    alignSelf: 'center',
  },
}));

export function CollaboratorResult({ collaborator, searchString }) {
  const classes = useStyles();

  const nameMatches = findTrigramMatches(searchString, collaborator.name);
  const emailMatches = findTrigramMatches(searchString, collaborator.email);

  const hideName = nameMatches.length === 0;
  const hideEmail = emailMatches.length === 0;

  if (hideName && hideEmail) {
    return null;
  }

  return (
    <div className={classes.collaboratorResult}>
      <PersonIcon className={classes.personIcon} />
      {collaborator.name ? (
        <TrigramMatchingText
          searchString={searchString}
          targetString={collaborator.name}
          size="small"
        />
      ) : null}
      <TrigramMatchingText
        searchString={searchString}
        targetString={collaborator.email}
        size="small"
      />
    </div>
  );
}
