import React, { cloneElement } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import Done from '@material-ui/icons/Done';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import { Typography, Button } from '@passthrough/uikit';

import { BoxType, getBoxInfo } from 'components/document_fields/box_type';
import * as constants from 'components/document_fields/constants';

import { groupBoxesBySigner } from './utils';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(3, 2),
    width: `${constants.PANEL_WIDTH}px`,
    minWidth: `${constants.PANEL_WIDTH}px`,
    height: '100%',
    border: 'none',
    borderBottom: 'none',
    borderLeft: 'none',
    borderTop: 'none',
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  textButton: {
    justifyContent: 'left !important',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  left: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  padding: {
    padding: theme.spacing(0.5, 0),
    width: '100%',
  },
  icon: {
    color: theme.palette.neutral.icon,
  },
  circle: {
    minWidth: '6px',
    width: '6px',
    height: '6px',
    borderRadius: '6px',
  },
  disableRightGutter: {
    paddingRight: 0,
  },
  selectedButton: {
    backgroundColor: `${theme.palette.action.hover} !important`,
  },
}));

const MAX_LENGTH = 27;

function truncate(str) {
  return str.length > MAX_LENGTH
    ? `${str.substring(0, MAX_LENGTH - 3)}...`
    : str;
}

function getBoxLabel(box) {
  const { type, text } = getBoxInfo(box);

  const useName = type === BoxType.TEXT || type === BoxType.CHECKBOX;
  return useName ? box.name || text : text;
}

// better name?
function LeftAlignedTextWithRightComponent({ left, right, text }) {
  const classes = useStyles();

  return (
    <div className={classes.row}>
      <div className={classes.left}>
        {left}
        <Typography variant="body" size="small">
          {text}
        </Typography>
      </div>
      {right}
    </div>
  );
}

function PlacedBox({ box, documentFieldActions }) {
  const classes = useStyles();
  const label = getBoxLabel(box);

  const findInPage = () => documentFieldActions.setActiveBox(box);

  const findIcon = (
    <Button
      onClick={findInPage}
      variant="icon"
      data-test="show-in-document"
      aria-label="Show in document"
    >
      <FindInPageOutlinedIcon className={classes.icon} />
    </Button>
  );

  return (
    <LeftAlignedTextWithRightComponent
      left={<Done className={classes.icon} />}
      text={truncate(label)}
      right={findIcon}
    />
  );
}

function NeedPlacementBox({ box, signer, onClick, isSelected }) {
  const classes = useStyles();
  const { icon } = getBoxInfo(box);

  const label = getBoxLabel(box);

  return (
    <Button
      data-test="need-placement-box"
      className={clsx({ [classes.selectedButton]: isSelected })}
      fullWidth
      onClick={onClick}
      startIcon={cloneElement(icon, { style: { color: signer.color } })}
      variant="text"
      classes={{ text: classes.textButton }}
      ellipsis
    >
      {label}
    </Button>
  );
}

function Signer({ signer, numBoxes }) {
  const classes = useStyles();

  return (
    <LeftAlignedTextWithRightComponent
      left={
        <div
          className={classes.circle}
          style={{ backgroundColor: signer.color }}
        />
      }
      text={signer.name}
      right={
        <Typography variant="body" size="small" color="text.secondary">
          {numBoxes} total
        </Typography>
      }
    />
  );
}

function SignerBoxes({
  signer,
  boxesForSigner,
  documentFieldActions,
  documentFieldState,
}) {
  const classes = useStyles();
  const { boxToPlace } = documentFieldState;

  return (
    <div>
      <Signer signer={signer} numBoxes={boxesForSigner.length} />
      <List>
        {boxesForSigner.map((box) => (
          <ListItem key={box.id} className={classes.disableRightGutter}>
            {box.page ? (
              <PlacedBox
                box={box}
                documentFieldActions={documentFieldActions}
              />
            ) : (
              <NeedPlacementBox
                box={box}
                signer={signer}
                isSelected={boxToPlace?.id === box.id}
                onClick={() => documentFieldActions.setBoxToPlace(box)}
              />
            )}
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export function SideBar({ signers, documentFieldActions, documentFieldState }) {
  const classes = useStyles();
  const { boxes } = documentFieldState;
  const groupedBoxes = groupBoxesBySigner(signers, boxes);

  return (
    <Paper className={classes.container} square variant="outlined">
      <Typography variant="section-heading">Prepare signature pages</Typography>
      <Typography variant="body" size="small">
        Add all fields to the countersignature page(s) in the offline signed
        document
      </Typography>
      <Divider />
      {groupedBoxes.map(({ signer, boxesForSigner }) => (
        <SignerBoxes
          key={signer.pdfBoxSigner}
          signer={signer}
          boxesForSigner={boxesForSigner}
          documentFieldActions={documentFieldActions}
          documentFieldState={documentFieldState}
        />
      ))}
    </Paper>
  );
}
