import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';

import { Button, useConfirm, Icons } from '@passthrough/uikit';
import * as urls from 'services/urls';
import * as api from 'services/api';
import { useToast } from 'services/toast';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'flex-start',
  },
}));

export function DeleteButton({
  hidden,
  closingName,
  canDeleteClosing,
  hasExternalSignupLink,
}) {
  const [isDeleting, setIsDeleting] = useState(false);

  const { toast } = useToast();
  const history = useHistory();
  const { fundId, closingId } = useParams();
  const confirm = useConfirm();

  const classes = useStyles();

  if (hidden) {
    return null;
  }

  function handleDeleteClosing() {
    confirm({
      title: 'Delete closing?',
      confirmationText: 'Delete',
      destructive: true,
      description: `${
        hasExternalSignupLink
          ? 'By deleting this closing, investors who use the signup link will encounter an error. '
          : ''
      }This action cannot be undone.`,
    })
      .then(onDelete)
      .catch(() => {});
  }

  function onDelete() {
    setIsDeleting(true);

    api
      .deleteClosing({ fundId, closingId })
      .then(() => {
        history.replace(urls.closingsUrl({ fundId }));
        toast(`Deleted ${closingName}`);
      })
      .finally(() => {
        setIsDeleting(false);
      });
  }

  const deleteButtonTooltip = canDeleteClosing
    ? undefined
    : 'Closings with investors cannot be deleted';

  return (
    <div className={classes.container}>
      <Button
        variant="text"
        destructive
        onClick={handleDeleteClosing}
        loading={isDeleting}
        startIcon={<Icons.DeleteOutline />}
        tooltip={deleteButtonTooltip}
        disabled={!canDeleteClosing}
      >
        Delete closing
      </Button>
    </div>
  );
}
