import React, { useContext, useReducer } from 'react';

import {
  TAGGING_MODAL_STAGES,
  INVESTOR_TAGGING_REDUCER_ACTIONS,
} from '../constants';

const InvestorTaggingModalStateContext = React.createContext({});

function genInitialTaggingModalState() {
  return {
    modalStage: TAGGING_MODAL_STAGES.APPLY_TAGS,
    tagsToApply: [],
    tagsToRetain: [],
    selectedTag: null,
    selectedTagGroup: null,
    newTagName: '',
    newTagGroupName: '',
    // currently only used to determine which modal the create
    // tag stage should return to
    prevTagModal: null,
  };
}

function investorTaggingModalStateReducer(state, action) {
  switch (action.type) {
    case INVESTOR_TAGGING_REDUCER_ACTIONS.HANDLE_OPEN: {
      return {
        ...state,
        tagsToApply: action.tagsUsedByAllInvestors,
        tagsToRetain: action.tagsUsedBySomeInvestors,
      };
    }
    case INVESTOR_TAGGING_REDUCER_ACTIONS.HANDLE_CLOSE: {
      return {
        ...state,
        modalStage: TAGGING_MODAL_STAGES.APPLY_TAGS,
        newTagName: '',
        newTagGroupName: '',
        selectedTag: null,
        selectedTagGroup: null,
        prevTagModal: null,
      };
    }
    case INVESTOR_TAGGING_REDUCER_ACTIONS.MOVE_BACKWARDS:
      return {
        ...state,
        newTagName: '',
        newTagGroupName: '',
        selectedTag: null,
        selectedTagGroup: null,
        modalStage: action.modalStage,
        prevTagModal: null,
      };
    case INVESTOR_TAGGING_REDUCER_ACTIONS.MANAGE_TAGS: {
      return {
        ...state,
        modalStage: TAGGING_MODAL_STAGES.MANAGE_TAGS,
      };
    }
    case INVESTOR_TAGGING_REDUCER_ACTIONS.UPDATE_CHECKED_TAGS: {
      let newTagsToApply = [...state.tagsToApply];
      let newTagsToRetain = [...state.tagsToRetain];

      if (!action.selected) {
        newTagsToApply = newTagsToApply.filter(
          (t) => t.id !== action.selectedTag.id,
        );
        newTagsToRetain = newTagsToRetain.filter(
          (t) => t.id !== action.selectedTag.id,
        );
      } else {
        newTagsToApply.push(action.selectedTag);
      }

      return {
        ...state,
        tagsToApply: newTagsToApply,
        tagsToRetain: newTagsToRetain,
      };
    }
    case INVESTOR_TAGGING_REDUCER_ACTIONS.EDIT_TAG_NAME:
      return {
        ...state,
        newTagName: action.newTagName,
      };
    case INVESTOR_TAGGING_REDUCER_ACTIONS.EDIT_TAG_GROUP_NAME:
      return {
        ...state,
        newTagGroupName: action.newTagGroupName,
      };
    case INVESTOR_TAGGING_REDUCER_ACTIONS.CREATE_TAG:
      return {
        ...state,
        newTagName: '',
        newTagGroupName: '',
        prevTagModal: action.currModalStage,
        modalStage: TAGGING_MODAL_STAGES.CREATE_TAG,
      };

    case INVESTOR_TAGGING_REDUCER_ACTIONS.DELETE_TAG:
      return {
        ...state,
        tagsToApply: state.tagsToApply.filter((t) => t.id !== action.tag.id),
        tagsToRetain: state.tagsToRetain.filter((t) => t.id !== action.tag.id),
      };
    case INVESTOR_TAGGING_REDUCER_ACTIONS.EDIT_TAG_GROUP:
      return {
        ...state,
        selectedTagGroup: action.tagGroup,
        newTagGroupName: action.tagGroup.name,
        modalStage: TAGGING_MODAL_STAGES.EDIT_TAG_GROUP,
      };
    case INVESTOR_TAGGING_REDUCER_ACTIONS.EDIT_TAG:
      return {
        ...state,
        selectedTag: action.tag,
        newTagName: action.tag.name,
        newTagGroupName: action.tag.groupName,
        modalStage: TAGGING_MODAL_STAGES.EDIT_TAG,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

export function InvestorTaggingModalStateProvider({ children }) {
  const [investorTaggingModalState, dispatch] = useReducer(
    investorTaggingModalStateReducer,
    genInitialTaggingModalState(),
  );

  return (
    <InvestorTaggingModalStateContext.Provider
      value={{
        ...investorTaggingModalState,
        dispatch,
      }}
    >
      {children}
    </InvestorTaggingModalStateContext.Provider>
  );
}

export function useInvestorTaggingModalState() {
  return useContext(InvestorTaggingModalStateContext);
}
