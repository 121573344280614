import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { Button } from 'components/button';
import { usePdf } from 'components/lp_doc/pdf_provider';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(0, 1),
    alignSelf: 'start',
  },
  endIconButtonDropdown: {
    marginLeft: '0px',
  },
}));

export function Download({ docs }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const onOpenPdf = usePdf();

  const handleMenuClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectDoc = (doc) => {
    onOpenPdf(doc, 1);
    handleClose();
  };

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        variant="text"
        color="default"
        className={classes.button}
        classes={{ endIcon: classes.endIconButtonDropdown }}
        onClick={handleMenuClick}
        endIcon={<ArrowDropDownIcon />}
      >
        View documents
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {docs.map((doc) => (
          <MenuItem key={doc.id} onClick={() => selectDoc(doc)}>
            {doc.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
