import React from 'react';
import {
  PageHeader,
  Breadcrumbs,
  HeaderSkeleton,
  Button,
} from '@passthrough/uikit';
import * as urls from 'services/urls';
import { ShowCaseBox, ComponentShowCase, ShowCaseBoxRow } from '../show_case';

export const PageHeaderShowCase = () => (
  <ComponentShowCase title="PageHeader">
    <ShowCaseBox>
      <ShowCaseBoxRow>
        <PageHeader heading="Heading" />
      </ShowCaseBoxRow>
      <ShowCaseBoxRow>
        <PageHeader heading="Heading" description="With a description" />
      </ShowCaseBoxRow>
      <ShowCaseBoxRow>
        <PageHeader
          heading="Heading"
          description="With a description"
          crumbs={
            <Breadcrumbs
              crumbs={[
                {
                  name: 'Parent 1',
                  to: urls.UIKIT_URL,
                },
                {
                  name: 'Parent 2',
                  to: urls.UIKIT_URL,
                },
              ]}
            />
          }
        />
      </ShowCaseBoxRow>
      <ShowCaseBoxRow>
        <PageHeader
          heading="Heading"
          description="With an action button"
          actionButtons={<Button variant="primary">Button</Button>}
        />
      </ShowCaseBoxRow>
      <ShowCaseBoxRow>
        <PageHeader
          heading=""
          skeletons={[
            HeaderSkeleton.heading,
            HeaderSkeleton.description,
            HeaderSkeleton.crumbs,
            HeaderSkeleton.metadata,
          ]}
        />
      </ShowCaseBoxRow>
    </ShowCaseBox>
  </ComponentShowCase>
);
