import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  circle: {
    minWidth: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  item: {
    minWidth: '200px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  select: {
    minWidth: '200px',
  },
  inputLabel: {
    height: '1.2em',
  },
}));

function backgroundColorStyle(color) {
  return {
    backgroundColor: color,
  };
}

export function SignerDropdown({
  relevantSigners,
  activeSigner,
  setActiveSigner,
  inputLabelText,
}) {
  const classes = useStyles();

  const signerItems = relevantSigners.map((s) => {
    const displayName =
      s.name && s.name !== '' ? s.name : `${s.role} ${s.number}`;
    return (
      <MenuItem key={`${s.role} ${s.number}`} value={s.pdfBoxSigner}>
        <div className={classes.item}>
          <div
            style={backgroundColorStyle(s.color)}
            className={classes.circle}
          />
          {displayName}
        </div>
      </MenuItem>
    );
  });

  return (
    <FormControl variant="outlined">
      <InputLabel
        id="signer-select-label"
        shrink={activeSigner != null}
        className={classes.inputLabel}
      >
        {inputLabelText}
      </InputLabel>
      <Select
        label={inputLabelText}
        id="signer-select"
        labelId="signer-select-label"
        value={activeSigner}
        onChange={(e) => {
          setActiveSigner(e.target.value);
        }}
        variant="outlined"
        classes={{ root: classes.select }}
      >
        {signerItems}
      </Select>
    </FormControl>
  );
}
