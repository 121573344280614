import React from 'react';
import { Button, Typography, Icons } from '@passthrough/uikit';
import Tooltip from '@material-ui/core/Tooltip';

export const CopyValueButton = ({ value }) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClick = () => {
    handleOpen();
    navigator.clipboard.writeText(value);
    setTimeout(() => {
      handleClose();
    }, 1000);
  };
  return (
    <Tooltip
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      placement="right"
      disableFocusListener
      disableTouchListener
      disableHoverListener
      title={<Typography>Copied!</Typography>}
    >
      <span>
        <Button
          variant="secondary"
          endIcon={<Icons.ContentCopy />}
          onClick={handleClick}
          htmlProps={{ sx: { 'white-space': 'normal' } }}
        >
          {value}
        </Button>
      </span>
    </Tooltip>
  );
};
