import React from 'react';
import { Typography } from '@passthrough/uikit';

import { StatusDot } from 'components/status';
import { GenericInvestorTagGroup } from './generic_investor_tag_group';

const getDisplayLabel = (status) => status.name;
const getIcon = (status) => <StatusDot color={status.color} />;

export function LpClosingStatusGroup({
  statuses,
  locallySelectedFilters,
  setLocallySelected,
}) {
  return (
    <>
      <Typography fontWeight={500}>Status</Typography>

      <GenericInvestorTagGroup
        tags={statuses}
        getDisplayLabel={getDisplayLabel}
        getIcon={getIcon}
        locallySelectedFilters={locallySelectedFilters}
        setLocallySelected={setLocallySelected}
      />
    </>
  );
}
