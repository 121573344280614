import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import * as api from 'services/api';
import { ModalEdit } from './modal_edit';
import { ModalView } from './modal_view';

export function ManageFilesModal({ showModal, onUpload, onClose, documents }) {
  const { fundId, questionnaireId } = useParams();
  const [files, setFiles] = useState(documents);
  const [file, setFile] = useState(null);

  const handleClose = () => {
    onUpload();
    onClose();
    setFile(null);
  };

  const handleAdd = () => {
    setFile({
      id: null,
      name: '',
      file: null,
      order: files.length + 1,
      fundId,
      questionnaireId,
    });
  };

  const handleEdit = (f) => setFile(f);

  const handleBack = () => setFile(null);

  const handleDelete = (f) =>
    api
      .deleteQuestionnaireDocument({
        fundId,
        questionnaireId,
        documentId: f.id,
      })
      .then(() => {
        onUpload();
      });

  const handleUpdateOrder = (newFiles) => {
    setFiles(newFiles);

    Promise.all(
      newFiles
        .filter((f) => f.id && f.order > 0)
        .map((f) =>
          api.updateOrCreateQuestionnaireDocument({
            fundId,
            questionnaireId,
            documentId: f.id,
            data: {
              name: f.name,
              order: f.order,
            },
          }),
        ),
    ).then(() => {
      onUpload();
    });
  };

  const handleCreateOrUpdate = (f) => {
    if (f.name === null) {
      return api
        .uploadNewDraftFile({
          fundId,
          questionnaireId,
          fileId: f.fileId,
        })
        .then((response) => {
          onUpload();
          return response;
        });
    }
    return api
      .updateOrCreateQuestionnaireDocument({
        fundId,
        questionnaireId,
        documentId: file.id,
        data: {
          name: f.name,
          fileId: f.fileId,
          order: file.order,
        },
      })
      .then((response) => {
        if (!file.id) {
          setFiles([...files, response.data]);
        }
        onUpload();
        return response;
      });
  };

  React.useEffect(() => {
    setFiles(documents);
  }, [documents]);

  return file ? (
    <ModalEdit
      open={showModal}
      file={file}
      onClose={handleClose}
      onBack={handleBack}
      onSubmit={handleCreateOrUpdate}
    />
  ) : (
    <ModalView
      open={showModal}
      files={files}
      onEdit={handleEdit}
      onDelete={handleDelete}
      onUpdateOrder={handleUpdateOrder}
      onAdd={handleAdd}
      onClose={handleClose}
    />
  );
}
