import React from 'react';
import { Modal, Typography } from '@passthrough/uikit';

export function NewQuestionnaireDialog({
  open,
  onClose,
  onSubmit,
  isSubmitting,
  organizationName,
  usingPrefilledAnswers = false,
}) {
  const description = usingPrefilledAnswers
    ? `You will start with answers provided by ${organizationName} on your behalf.
        You can make any changes to them in the questionnaire.`
    : 'You will start with a blank questionnaire for this investment.';
  return (
    <Modal
      open={open}
      onClose={onClose}
      headerLabel="Ready to start?"
      showCancelButton
      onSubmit={onSubmit}
      primaryButtonText="Start questionnaire"
      primaryButtonLoading={isSubmitting}
    >
      <Typography>{description}</Typography>
    </Modal>
  );
}
