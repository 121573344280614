import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

import { Button, Typography, Icons } from '@passthrough/uikit';
import { keysToCamel } from './helpers';

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sectionDisplay: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
  },
  cardDisplay: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    alignItems: 'center',
    overflowX: 'auto',
  },
}));

export function Section({
  sectionHeader,
  addButtonText,
  onAdd,
  items,
  onEdit,
  itemHeaderAttr,
  itemSubheaderGetter = null,
}) {
  const classes = useStyles();

  return (
    <Paper className={classes.sectionDisplay} elevation={2}>
      <div className={classes.sectionHeader}>
        <Typography variant="card-heading" size="medium">
          {sectionHeader}
        </Typography>
        <Button startIcon={<Icons.Add />} variant="secondary" onClick={onAdd}>
          {addButtonText}
        </Button>
      </div>

      {items?.map((item) => (
        <Card variant="outlined" className={classes.cardDisplay} key={item.id}>
          <div>
            <Typography variant="card-heading" size="large">
              {item[itemHeaderAttr]}
            </Typography>
            {itemSubheaderGetter?.(keysToCamel(item))}
          </div>
          <Button
            variant="icon"
            aria-label="Edit"
            onClick={() => {
              onEdit(item);
            }}
          >
            <Icons.EditOutlined />
          </Button>
        </Card>
      ))}
    </Paper>
  );
}
