import React, { useState } from 'react';

import { Alert, Modal } from '@passthrough/uikit';
import { useToast } from 'services/toast';
import { QueryBuilder } from '../../query_builder';

export const BulkLogicModal = ({
  open,
  selectedIds,
  questions,
  isSaving,
  onClose,
  onSave,
}) => {
  const [bulkLogic, setBulkLogic] = useState([]);
  const { toast } = useToast();

  let hasQuestions = false;
  let hasSigsOrText = false;

  for (let i = 0; i < questions.length; i += 1) {
    if (selectedIds.includes(questions[i].id)) {
      if (
        !questions[i].isSignature &&
        !questions[i].isText &&
        !questions[i].isSection
      ) {
        hasQuestions = true;
      }
      if (questions[i].isSignature || questions[i].isText) {
        hasSigsOrText = true;
      }
    }
  }

  const showAlert = hasSigsOrText && hasQuestions;
  const showThisAndFutureQuestions = hasSigsOrText && !hasQuestions;

  return (
    <Modal
      open={open}
      onClose={onClose}
      primaryButtonText="Save"
      primaryButtonLoading={isSaving}
      primaryButtonDisabled={bulkLogic.length === 0}
      headerLabel="Bulk add conditions"
      onExited={() => setBulkLogic([])}
      onSubmit={() => {
        onSave(bulkLogic);
        onClose();
        toast(`Conditions changed on ${selectedIds.length} questions.`);
      }}
      showCancelButton
    >
      <Alert severity="warning">
        Bulk logic will overwrite any preexisting conditions on the selected
        questions.
      </Alert>
      {showAlert ? (
        <Alert severity="warning">
          To bulk apply conditions to signatures or text that depend on
          questions that come later, unselect all questions.
        </Alert>
      ) : null}
      <QueryBuilder
        questionIds={selectedIds}
        value={bulkLogic}
        onChange={setBulkLogic}
        questions={questions}
        hideAddCondition={false}
        showThisAndFutureQuestions={showThisAndFutureQuestions}
      />
    </Modal>
  );
};
