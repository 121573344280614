import React from 'react';

import List from '@material-ui/core/List';
import {
  SubDocTask,
  CountersignTask,
  SecondSignerTask,
  CustomApproveTask,
  KYCOnlyTask,
  SplitSubDocTask,
} from './task';

const TASKS = {
  kyc: KYCOnlyTask,
  subdoc: SubDocTask,
  countersign: CountersignTask,
  second_signer: SecondSignerTask,
  custom_approval: CustomApproveTask,
  split_kyc: SplitSubDocTask,
};

export const TaskList = ({ tasks }) => (
  <List disablePadding>
    {tasks.map((task) => {
      const TaskComponent = TASKS[task.type];
      const key = `${task.type}/${task.key}`;

      if (!TaskComponent) {
        return null;
      }

      return <TaskComponent key={key} task={task} />;
    })}
  </List>
);
