import React from 'react';
import { Modal, Typography } from '@passthrough/uikit';

export function CannotPrepareModal({ open, handleClose }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      showCancelButton
      headerLabel="Select only 1 investor to prepare signature pages"
      footer={null}
    >
      <Typography>
        You must prepare signature pages for one investor at a time to review
        each uploaded document.
      </Typography>
    </Modal>
  );
}
