import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from 'components/button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'components/dialog/index';
import * as urls from 'services/urls';

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  spacerButton: {
    visibility: 'hidden',
  },
  homeButton: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

export function FundErrorDialog({ open, handleClose }) {
  const history = useHistory();
  const classes = useStyles();

  function handleRedirect() {
    handleClose();
    history.replace(urls.DASH_URL);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="error-dialog-title"
      aria-describedby="error-dialog-description"
    >
      <div className={classes.titleContainer}>
        <IconButton className={classes.spacerButton}>
          <CloseIcon />
        </IconButton>
        <DialogTitle id="error-dialog-title">
          An unexpected error occurred
        </DialogTitle>
        <IconButton onClick={handleClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        <Typography id="error-dialog-description">
          Our support team has been notified. They will reach out to you
          directly.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          fullWidth
          onClick={handleRedirect}
          className={classes.homeButton}
        >
          Continue to Home
        </Button>
      </DialogActions>
    </Dialog>
  );
}
