import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { Button } from 'components/button';

const useStyles = makeStyles((theme) => ({
  buttonSpacing: {
    marginTop: theme.spacing(4),
  },
  label: {
    padding: 0,
    width: '100%',
  },
}));

export function GoodForm({ onSubmit, setProvideRecs }) {
  const classes = useStyles();
  const [recommend, setRecommend] = React.useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setProvideRecs(recommend);
    onSubmit(e, recommend);
  }

  return (
    <>
      <FormControlLabel
        className={classes.label}
        label={
          <strong>
            I have a fund manager I would like to connect you with.
          </strong>
        }
        control={
          <Checkbox
            checked={recommend}
            onChange={(e) => {
              setRecommend(e.target.checked);
            }}
            name="recommend"
          />
        }
      />
      <Typography align="left">
        If checked, someone from our team will be in touch.
      </Typography>

      <Button
        variant="contained"
        type="submit"
        fullWidth
        onClick={handleSubmit}
        className={classes.buttonSpacing}
      >
        Submit
      </Button>
    </>
  );
}
