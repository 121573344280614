import React, { useEffect, useState } from 'react';

import * as api from 'services/api';
import { Spinner } from 'components/spinner';
import { LpReviewRequestedTodoTimeline } from './lp_review_requested_todo_timeline';
import { BaseAllDoneDisplay } from './base_all_done_display';
import { TodoTimeline } from './todo_timeline';
import { REVIEW_REQUESTED, FIRST_SUBMISSION } from './constants';

export function SubdocQuestionnaireAllDoneDisplay({
  lpClosingId,
  fundName,
  completionText,
  hasLpRequestedReview,
  isOffline,
  enableReviewForm,
  submitExperienceReview,
}) {
  const [loading, setLoading] = useState(false);
  const [signers, setSigners] = useState(null);

  const step = hasLpRequestedReview ? REVIEW_REQUESTED : FIRST_SUBMISSION;

  useEffect(() => {
    if (hasLpRequestedReview) {
      // we don't show signers in the review timeline so there
      // is no need to fetch the signer data here
      return;
    }

    setLoading(true);

    api
      .lpClosingGetSignersForTimeline({ lpClosingId })
      .then((resp) => {
        setSigners(resp?.data?.signers || []);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function getTimelineComponent() {
    if (hasLpRequestedReview) {
      return <LpReviewRequestedTodoTimeline fundName={fundName} />;
    }

    if (loading) {
      return <Spinner />;
    }

    return (
      <TodoTimeline
        step={step}
        jointSigners={isOffline ? [] : signers}
        fundName={fundName}
        hasSignatures={signers?.length > 0}
      />
    );
  }

  return (
    <BaseAllDoneDisplay
      fundName={fundName}
      customCompletionText={completionText}
      step={step}
      enableReviewForm={enableReviewForm}
      submitExperienceReview={submitExperienceReview}
      TimelineComponent={getTimelineComponent()}
    />
  );
}
