import React, { useState } from 'react';
import { Modal } from '@passthrough/uikit';
import { StepInfo } from './step_info';
import { StepSwitchProfile } from './step_switch_profile';

export function ProfileInfoModal({
  open,
  handleClose,
  availableProfiles,
  activeProfile,
  setActiveProfile,
  setSetupProfileId,
  fundName,
  lpName,
  setRestarting,
  disableProfileSwitching,
}) {
  const [step, setStep] = React.useState(0);
  const [selectedProfileId, setSelectedProfileId] = useState(activeProfile?.id);
  const selectedProfile =
    availableProfiles.find((profile) => profile.id === selectedProfileId) ||
    'new';

  const onClose = () => {
    handleClose();
    setTimeout(() => setStep(0), 200);
  };
  const [existingAnswersChoice, setExistingAnswersChoice] = useState('');

  const changedProfiles = selectedProfileId !== activeProfile?.id;
  const currentName = activeProfile.displayName;
  const isNewInvestor = selectedProfile === 'new';
  const updatedName = isNewInvestor
    ? 'New profile'
    : selectedProfile.displayName;

  const goBack = () => {
    setStep(0);
  };

  const onSubmit = () => {
    if (selectedProfile === 'new') {
      setSetupProfileId('new');
    } else {
      setSetupProfileId(selectedProfile.id);
    }
    setActiveProfile(null);
    setRestarting(existingAnswersChoice === 'restart');
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      maxWidth="sm"
      headerLabel="Profile information"
      footer={disableProfileSwitching ? null : undefined}
      primaryButtonText="Continue"
      primaryButtonDisabled={
        step === 0 ? !changedProfiles : existingAnswersChoice === ''
      }
      onSubmit={() => (step === 0 ? setStep(1) : onSubmit())}
      showCancelButton={step === 1}
      onBack={step === 1 ? goBack : null}
      subheaderLabel={
        step === 1 ? `Change from ${currentName} to ${updatedName}` : null
      }
      onExited={() => {
        setSelectedProfileId(activeProfile?.id);
        setExistingAnswersChoice('');
      }}
    >
      {step === 0 ? (
        <StepInfo
          fundName={fundName}
          lpName={lpName}
          activeProfile={activeProfile}
          availableProfiles={availableProfiles}
          selectedProfileId={selectedProfileId}
          setSelectedProfileId={setSelectedProfileId}
          setStep={setStep}
          onClose={onClose}
          disableProfileSwitching={disableProfileSwitching}
        />
      ) : null}
      {step === 1 ? (
        <StepSwitchProfile
          selectedProfile={selectedProfile}
          isNewInvestor={isNewInvestor}
          existingAnswersChoice={existingAnswersChoice}
          setExistingAnswersChoice={setExistingAnswersChoice}
        />
      ) : null}
    </Modal>
  );
}
