import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { useQuestionnaire } from 'services/providers/questionnaire';
import { Button } from '@passthrough/uikit';
import { ThreadDisplay } from './thread_display';
import { ThreadHeader } from './thread_header';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  invertedIcon: {
    transform: 'rotate(180deg)',
  },
}));

function getResponses(notes) {
  const responses = {};
  for (let i = 0; i < notes.length; i += 1) {
    const note = notes[i];
    if (note.response_to) {
      if (!responses[note.response_to]) {
        responses[note.response_to] = [];
      }
      responses[note.response_to].push(note);
    }
  }
  return responses;
}

function getOrderedNotes(mainNotes, responses) {
  let newNotes = [];
  for (let i = 0; i < mainNotes.length; i += 1) {
    const currNote = mainNotes[i];
    if (responses[currNote.note_id]) {
      newNotes = newNotes.concat(responses[currNote.note_id]);
    }
    newNotes.push(currNote);
  }
  return newNotes;
}

export function NoteHistoryDisplay({
  notes,
  className,
  createQuestionNote,
  updateQuestionNote,
  deleteQuestionNote,
  questionID,
  currentLog,
  handleReview,
  reviewedQuestionIds,
  setReviewedQuestionIds,
  selectedIds,
}) {
  const classes = useStyles();
  const [showAllNotes, setShowAllNotes] = useState(false);
  const { useSupport } = useQuestionnaire();
  const { setMenuComponentProps } = useSupport();

  const mainNotes = notes.filter((note) => !note.response_to);
  const responses = getResponses(notes);
  const newNotes = getOrderedNotes(mainNotes, responses);

  const mostRecentNote = newNotes?.length ? newNotes[0] : null;
  const oldNotes = newNotes.slice(1).reverse();

  useEffect(() => {
    setMenuComponentProps((prevProps) => ({
      ...prevProps,
      openExistingThread: () => {
        setShowAllNotes(true);
      },
    }));

    return () => {
      setMenuComponentProps((prevProps) => ({
        ...prevProps,
        openExistingThread: null,
      }));
    };
  }, []);

  return (
    <div className={className}>
      <ThreadHeader
        ButtonComponent={
          <Button
            onClick={() => {
              setShowAllNotes((prev) => !prev);
            }}
            variant="text"
            endIcon={
              <KeyboardArrowDownIcon
                className={clsx({ [classes.invertedIcon]: showAllNotes })}
              />
            }
          >
            Show history and add note
          </Button>
        }
        showAllNotes={showAllNotes}
      />

      <ThreadDisplay
        mostRecentNote={mostRecentNote}
        oldNotes={oldNotes}
        showAllNotes={showAllNotes}
        createQuestionNote={createQuestionNote}
        updateQuestionNote={updateQuestionNote}
        deleteQuestionNote={deleteQuestionNote}
        questionID={questionID}
        currentLog={currentLog}
        handleReview={handleReview}
        reviewedQuestionIds={reviewedQuestionIds}
        setReviewedQuestionIds={setReviewedQuestionIds}
        selectedIds={selectedIds}
      />
    </div>
  );
}
