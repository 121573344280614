import React, { useState, useEffect } from 'react';

import * as api from 'services/api';
import * as urls from 'services/urls';
import { FundTable } from 'components/fund_table/index';
import {
  NAME_COLUMN,
  CLIENT_MATTER_NUMBER_COLUMN,
  NEXT_DATE_COLUMN,
  PROGRESS_COLUMN,
  MEMBERS_COLUMN,
} from 'components/fund_table/constants';

import { ClientMatterDialog } from './client_matter_dialog';
import { MemberAccessDialog } from './member_access_dialog/index';

const tableColumns = [
  NAME_COLUMN,
  CLIENT_MATTER_NUMBER_COLUMN,
  NEXT_DATE_COLUMN,
  PROGRESS_COLUMN,
  MEMBERS_COLUMN,
];

export function DashboardFundTable() {
  const [funds, setFunds] = useState(null);

  const [clientMatterDialogOpen, setClientMatterDialogOpen] = useState(false);
  const [clientMatterDialogFund, setClientMatterDialogFund] = useState({});
  const [accessDialogOpen, setAccessDialogOpen] = useState(false);
  const [accessDialogTeamMembers, setAccessDialogTeamMembers] = useState([]);
  const [accessDialogFund, setAccessDialogFund] = useState({});
  const [commitments, setCommitments] = useState([]);

  const organizationId = urls.useOrganizationId();

  const reloadFunds = () =>
    api.dashboardGetFunds({ organizationId }).then((response) => {
      setFunds(response.data);
    });

  function getCommitments() {
    const batchedFundIds = [];
    const chunkSize = 10;
    if (!funds) return;
    for (let i = 0; i < funds.length; i += chunkSize) {
      batchedFundIds.push(funds.slice(i, i + chunkSize).map((fund) => fund.id));
    }
    for (let i = 0; i < batchedFundIds.length; i += 1) {
      api
        .dashboardGetCommitment({
          organizationId,
          batchedFundIds: batchedFundIds[i],
        })
        .then((response) => {
          setCommitments((prevCommitments) => [
            ...prevCommitments,
            ...response.data,
          ]);
        });
    }
  }

  useEffect(() => {
    reloadFunds();
  }, [organizationId]);

  useEffect(() => {
    getCommitments();
  }, [funds]);

  useEffect(() => {
    const fundId = accessDialogFund.id;
    // The dialog team members state may now be stale, so update it from
    // the newly fetched funds list.
    if (fundId) {
      const fund = funds.find((f) => f.id === fundId);
      const newTeamMembers = fund?.teamMembers || [];
      setAccessDialogTeamMembers(newTeamMembers);
      // Last member with edit access was removed/changed to view only, so this
      // fund is removed from the list.
      if (newTeamMembers.length === 0) {
        setAccessDialogOpen(false);
      }
    }
  }, [funds, accessDialogFund?.id]);

  const openClientMatterDialog = (fund) => {
    setClientMatterDialogFund(fund);
    setClientMatterDialogOpen(true);
  };

  const openMemberAccessDialog = (fund) => {
    setAccessDialogTeamMembers(fund.teamMembers);
    setAccessDialogFund(fund);
    setAccessDialogOpen(true);
  };

  return (
    <>
      <FundTable
        funds={funds}
        commitments={commitments}
        columns={tableColumns}
        openClientMatterDialog={openClientMatterDialog}
        openMemberAccessDialog={openMemberAccessDialog}
        searchLabel="Fund or team member"
        enableAssignedToMeFilter
      />
      <ClientMatterDialog
        open={clientMatterDialogOpen}
        organizationId={organizationId}
        fundId={clientMatterDialogFund.id}
        fundName={clientMatterDialogFund.name}
        clientMatterNumber={clientMatterDialogFund.clientMatterNumber}
        onClose={() => setClientMatterDialogOpen(false)}
        reloadFunds={reloadFunds}
      />
      <MemberAccessDialog
        open={accessDialogOpen}
        onClose={() => setAccessDialogOpen(false)}
        teamMembers={accessDialogTeamMembers}
        fundId={accessDialogFund.id}
        fundName={accessDialogFund.name}
        fundAccessRestricted={
          accessDialogFund.accessRestricted || !accessDialogFund.canEdit
        }
        reloadFunds={reloadFunds}
      />
    </>
  );
}
