import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';

import { Button } from 'components/button';

const useStyles = makeStyles((theme) => ({
  button: {
    color: 'inherit',
    padding: 0,
    cursor: 'inherit',
    '& > span': {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  clickable: {
    cursor: 'pointer',
  },
  avatarBase: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.greyHover,
    color: theme.palette.primary.grey,
    fontSize: '.875rem',
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  complete: {
    backgroundColor: theme.palette.primary.light,
  },
  label: {
    whiteSpace: 'nowrap',
  },
  inactiveLabel: {
    color: theme.palette.primary.grey,
  },
}));

function NumberBubble({ isActive, number }) {
  const classes = useStyles();

  return (
    <Avatar
      className={clsx(classes.avatarBase, {
        [classes.active]: isActive,
      })}
    >
      {number}
    </Avatar>
  );
}

function CompleteBubble() {
  const classes = useStyles();

  return (
    <Avatar className={clsx(classes.avatarBase, classes.complete)}>
      <CheckIcon color="primary" fontSize="small" />
    </Avatar>
  );
}

export function Step({ name, number, isActive, isComplete, gotoStep }) {
  const classes = useStyles();

  return (
    <Button
      variant="text"
      className={clsx(classes.button, {
        [classes.clickable]: isComplete,
      })}
      onClick={isComplete ? gotoStep : undefined}
      disableRipple
    >
      {isComplete ? (
        <CompleteBubble />
      ) : (
        <NumberBubble isActive={isActive} number={number} />
      )}
      <Typography
        className={clsx(classes.label, {
          [classes.inactiveLabel]: !isActive && !isComplete,
        })}
      >
        {name}
      </Typography>
    </Button>
  );
}
