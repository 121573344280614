import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import {
  Table,
  TableActions,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from 'components/table/index';
import { Button } from 'components/button';

export function OrgAdminsList({ admins, setOpenAddAdminModal, onDelete }) {
  return (
    <TableContainer>
      <TableActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            setOpenAddAdminModal(true);
          }}
          startIcon={<AddIcon />}
        >
          Add admin
        </Button>
      </TableActions>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {admins.map((admin) => (
            <TableRow key={admin.id}>
              <TableCell>{admin.name}</TableCell>
              <TableCell>{admin.email}</TableCell>
              <TableCell align="right">
                <Tooltip title={<Typography>Delete</Typography>}>
                  <IconButton onClick={() => onDelete(admin.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
