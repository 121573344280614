import React from 'react';
import { Modal, Alert } from '@passthrough/uikit';

export function SubscriptionDocumentArchivedDialog({ open, handleClose }) {
  return (
    <Modal
      size="sm"
      headerLabel="Add investors"
      open={open}
      showCancelButton
      onClose={handleClose}
      footer={null}
    >
      <Alert severity="error">
        Subscription document for this closing is archived.
      </Alert>
      To add investors, create a new closing using an active subscription
      document. Or, restore the archived subscription document for this closing.
    </Modal>
  );
}
