import React from 'react';
import Chip from '@material-ui/core/Chip';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  greenChip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
  },
  chipLeftSpacing: {
    marginLeft: theme.spacing(1),
  },
}));

export function TabLabelWithNumChip({
  label,
  Icon,
  count,
  isSelected,
  className,
}) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      {Icon}
      {label}
      {count > 0 ? (
        <Chip
          label={count}
          size="small"
          className={clsx(classes.chipLeftSpacing, {
            [classes.greenChip]: isSelected,
          })}
        />
      ) : null}
    </div>
  );
}
