import React from 'react';
import { Button } from '@passthrough/uikit';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import { SUBDOC_DOC_NAME } from '../../constants';

export const FileItem = ({ file, onEdit, onDelete, dragHandleProps }) => {
  const isSubdoc = file.name === null;
  const canEditAndDelete = !isSubdoc;
  const displayName = isSubdoc ? SUBDOC_DOC_NAME : file.name;
  return (
    <ListItem>
      <ListItemIcon {...dragHandleProps}>
        <DragIndicatorIcon />
      </ListItemIcon>
      <ListItemText>{displayName}</ListItemText>
      <ListItemSecondaryAction>
        <Button variant="icon" onClick={() => onEdit(file)}>
          <EditIcon />
        </Button>
        {canEditAndDelete ? (
          <Button variant="icon" onClick={() => onDelete(file)} destructive>
            <DeleteIcon />
          </Button>
        ) : null}
      </ListItemSecondaryAction>
    </ListItem>
  );
};
