import React from 'react';
import { Button, Icons } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';
import * as urls from 'services/urls';
import { QuestionPreview } from '../../../question_preview';

const useStyles = makeStyles((theme) => ({
  alert: {
    padding: theme.spacing(2),
  },
  preview: {
    height: '500px',
    overflow: 'auto',
    padding: theme.spacing(2),
  },
}));

export const Preview = ({ questionnaireId, fundId, item }) => {
  const classes = useStyles();
  return (
    <div className={classes.preview}>
      <Button
        variant="text"
        href={urls.onboardingV2Url({
          fundId,
          questionnaireId,
        })}
        external
        endIcon={<Icons.OpenInNew />}
      >
        Open questionnaire
      </Button>
      <QuestionPreview item={item} noPaper />
    </div>
  );
};
