import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Modal,
  Typography,
  SpotIcon,
  Icons,
  Link,
  UIKitSettingsProvider,
} from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
}));

function Item({ icon, title, children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SpotIcon>{icon}</SpotIcon>
      <div>
        <Typography variant="card-heading">{title}</Typography>
        <Typography variant="body" size="small" color="text.secondary">
          {children}
        </Typography>
      </div>
    </div>
  );
}

const SECURITY = 'https://security.passthrough.com';

export function MoreInformationModal({ open, onClose }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      headerLabel="Answers from previous investments"
      footer={null}
    >
      <Typography>
        When participating in any Passthrough-powered investment, your answers
        (address, bank information, contacts, etc.) are stored as part of an
        investor profile. Each profile corresponds to a legal entity you have
        used and you can pre-fill with a profile to save time with future
        investments.
      </Typography>
      <Item
        icon={<Icons.LockOutlined fontSize="small" />}
        title="Profile ownership"
      >
        Profile data is only accessible to and managed by the investor and their
        authorized representatives.
      </Item>
      <Item
        icon={<Icons.PeopleAltOutlined fontSize="small" />}
        title="Access control"
      >
        Investors can invite collaborators to complete their questionnaire
        without granting access to the full profile.
      </Item>
      <UIKitSettingsProvider redirectAttribute="href">
        <Item
          icon={<Icons.GppGoodOutlined fontSize="small" />}
          title="Data security"
        >
          Your investor data is stored securely in Passthrough.{' '}
          <Link variant="external" href={SECURITY} inline>
            Learn how Passthrough protects your data
          </Link>
        </Item>
      </UIKitSettingsProvider>
    </Modal>
  );
}
