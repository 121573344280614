import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

import { formatDate, dateIsBeforeToday } from './utils';

const useStyles = makeStyles((theme) => ({
  missingDate: {
    color: theme.palette.text.secondary,
  },
}));

export function ClosingDateCell({ fund, skeleton }) {
  const classes = useStyles();
  const closingDate = fund.nextClosingDate;

  if (skeleton) {
    return <Skeleton width={100} height={20} />;
  }

  if (!closingDate) {
    return (
      <Typography variant="body2" className={classes.missingDate}>
        No closing date
      </Typography>
    );
  }

  if (dateIsBeforeToday(closingDate)) {
    return (
      <Typography variant="body2" className={classes.missingDate}>
        No upcoming closing
      </Typography>
    );
  }

  return <Typography>{formatDate(closingDate)}</Typography>;
}
