import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { FeatureFlagContext } from 'services/providers/feature_flag';
import * as api from 'services/api';
import { useFund } from './fund';
import { useMe } from './me';

export function LPAggregateFeatureFlagProvider({ children }) {
  // used to aggregate both individual and fund flags on the lp side
  // where we can only use an lpclosing id to determine which fund the
  // user is working within
  const [fundFlags, setFundFlags] = useState(null);
  const [me] = useMe();
  const { lpDocumentId: lpClosingId } = useParams();

  useEffect(() => {
    api.fundFlagsByLpClosing({ lpClosingId }).then((response) => {
      setFundFlags(response.data);
    });
  }, [lpClosingId]);

  const aggregatedFlags = { ...me.featureFlags, ...(fundFlags?.flags || {}) };

  return (
    <FeatureFlagContext.Provider value={aggregatedFlags}>
      {children}
    </FeatureFlagContext.Provider>
  );
}

export function GPAggregateFeatureFlagProvider({ children }) {
  // used to aggregate both individual and fund flags on the GP side
  // where we can directly plug into the fund provider
  const [fund] = useFund();
  const [me] = useMe();
  const aggregatedFlags = { ...me.featureFlags, ...(fund?.flags || {}) };

  return (
    <FeatureFlagContext.Provider value={aggregatedFlags}>
      {children}
    </FeatureFlagContext.Provider>
  );
}
