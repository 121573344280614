import React from 'react';

const ReconciliationContext = React.createContext();

export const ReconciliationProvider = ({ question, children }) => {
  const needsReconciliation = Boolean(question?.answersToReconcile?.length);
  const [showReconciliationPage, setShowReconciliationPage] =
    React.useState(needsReconciliation);
  const [answer, setAnswer] = React.useState(null);

  React.useEffect(() => {
    setShowReconciliationPage(needsReconciliation);
    setAnswer(null);
  }, [question?.label]);

  return (
    <ReconciliationContext.Provider
      value={{
        needsReconciliation,
        showReconciliationPage,
        setShowReconciliationPage,
        reconciliationAnswer: answer,
        setReconciliationAnswer: setAnswer,
        resetReconciliationAnswer: () => setAnswer(null),
      }}
    >
      {children}
    </ReconciliationContext.Provider>
  );
};

export const useReconciliation = () => {
  const context = React.useContext(ReconciliationContext);

  if (context !== undefined) {
    return context;
  }

  return {
    ReconciliationComponent: null,
    needsReconciliation: false,
    showReconciliationPage: false,
    setShowReconciliationPage: () => {},
    reconciliationAnswer: null,
    setReconciliationAnswer: () => {},
    resetReconciliationAnswer: () => {},
  };
};
