import { useEffect } from 'react';
import { useConfirm } from '@passthrough/uikit';

export function useDiscardConfirmation({ onConfirm, needConfirmation }) {
  const confirm = useConfirm();

  useEffect(() => {
    if (needConfirmation) {
      window.onbeforeunload = () => true;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [needConfirmation]);

  return () => {
    if (!needConfirmation) {
      onConfirm();
      return;
    }
    confirm({
      title: 'Discard unsaved data?',
      description:
        'Your changes have not been saved. This action cannot be undone.',
      confirmationText: 'Discard',
      size: 'xs',
      destructive: true,
    })
      .then(onConfirm)
      .catch(() => {});
  };
}
