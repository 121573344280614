import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { ComplexPanelContent } from './complex_panel_content';
import { ConfirmationBanner } from './confirmation_banner';
import * as constants from './constants';
import { TagDiffPanel } from './diff_panel';
import { UndoBanner } from './undo_banner';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
}));

export const ComplexKeepTagsPanel = ({
  fields,
  questionTags,
  questionId,
  origQuestionText,
  newQuestionText,
  keepTagsConfirmed,
  setKeepTagsConfirmed,
}) => {
  const classes = useStyles();
  const [alertText, setAlertText] = useState(false);
  const tags = fields
    .map((f) => (questionTags[f] ? questionTags[f] : []))
    .flat();

  const leftContent = (
    <ComplexPanelContent
      questionTags={questionTags}
      questionText={origQuestionText}
      fields={fields}
      left
    />
  );
  const rightContent = (
    <ComplexPanelContent
      questionTags={questionTags}
      questionText={newQuestionText}
      fields={fields}
    />
  );

  function setConfirmed(confirmed) {
    // Set all fields for the question id with the same confirmation.
    setKeepTagsConfirmed((map) => {
      const newMap = { ...map };
      Object.keys(keepTagsConfirmed[questionId]).forEach((f) => {
        newMap[questionId][f] = confirmed;
      });
      return newMap;
    });
  }

  function undoConfirmation() {
    setConfirmed(constants.NOT_CONFIRMED);
  }

  // Should be equivalent to check the value of any key, but we check them all.
  const notConfirmed = Object.keys(keepTagsConfirmed[questionId]).some(
    (f) => keepTagsConfirmed[questionId][f] === constants.NOT_CONFIRMED,
  );

  return notConfirmed ? (
    <div key="questionKeepTags" className={classes.root}>
      <ConfirmationBanner
        key={`${questionId}-banner`}
        setConfirmed={setConfirmed}
        setAlertText={setAlertText}
        questionTags={tags}
      />
      <TagDiffPanel
        key={`${questionId}-diff`}
        leftContent={leftContent}
        rightContent={rightContent}
      />
    </div>
  ) : (
    <UndoBanner undoConfirmation={undoConfirmation} alertText={alertText} />
  );
};
