import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import MuiButton from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  signedText: {
    color: `${theme.palette.success.main} !important`,
    fontWeight: 400,
    whiteSpace: 'nowrap',
  },
}));

export function SignedText({ text }) {
  const classes = useStyles();

  // Using mui button because our button forces variant="contained"
  // when disabled
  return (
    <MuiButton
      className={classes.signedText}
      disabled
      startIcon={<CheckIcon />}
    >
      {text}
    </MuiButton>
  );
}
