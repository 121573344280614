import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import {
  Typography as PassTypography,
  Button,
  Icons,
} from '@passthrough/uikit';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from 'components/table/index';
import { EmptyState } from 'components/empty_v2';
import { DomainDialog } from './domain_dialog';

const useStyles = makeStyles((theme) => ({
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
  },
  gap: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.spacing(1),
    justifyContent: 'flex-end',
  },
  verticalGap: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(1),
  },
}));

export function DomainTable({
  setManageDomain,
  onChange,
  onDelete,
  domains,
  verifiedDomains,
  loading,
}) {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  function tableRow(domain) {
    const verifiedDomain = verifiedDomains.find(
      (d) => d.emailDomain === domain.domain,
    );
    return (
      <TableRow key={domain.domain}>
        <TableCell>{domain.domain}</TableCell>
        <TableCell>{domain.orgAdminUsers}</TableCell>
        <TableCell>{domain.fundAdminUsers}</TableCell>
        <TableCell align="right" className={classes.gap}>
          {verifiedDomain?.verificationComplete ? null : (
            <Button
              variant="icon"
              aria-label="Delete"
              onClick={() => {
                onDelete(domain.domain);
              }}
              loading={loading}
            >
              <Icons.DeleteOutline />
            </Button>
          )}
          <Button
            aria-label="Manage"
            variant="secondary"
            onClick={() => setManageDomain(domain.domain)}
            loading={loading}
          >
            Manage
          </Button>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      <TableContainer>
        <div className={classes.spaceBetween}>
          <div className={classes.verticalGap}>
            <Typography variant="h6">Domains</Typography>
            <PassTypography variant="label" color="text.secondary">
              Users with email addresses from these domains can be invited to
              access your organization and funds. Only add domains controlled by
              organizations you trust.
            </PassTypography>
          </div>
          <Button
            variant="primary"
            onClick={() => setDialogOpen(true)}
            startIcon={<Icons.Add />}
            loading={loading}
          >
            Add domain
          </Button>
        </div>
        <Table
          belowRowsElement={
            domains.length === 0 ? <EmptyState title="No domains" /> : null
          }
        >
          <TableHead>
            <TableRow>
              <TableCell>Domain</TableCell>
              <TableCell>Organization admin</TableCell>
              <TableCell>Fund admin</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>{domains.map((domain) => tableRow(domain))}</TableBody>
        </Table>
      </TableContainer>
      <DomainDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onChange={onChange}
      />
    </>
  );
}
