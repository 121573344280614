import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography, Button } from '@passthrough/uikit';

import { OptOutAlert } from 'pages/review_v2/diligence_v2/components/opt_out_alert';
import { IdAndStatusDisplay } from './id_and_status_display';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: '4px',
    overflow: 'hidden',
    border: `1px solid ${theme.palette.neutral.border}`,
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
  nonAlertContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: theme.spacing(2, 3),
  },
}));

export function ReviewCard({
  question,
  onShowReviewClick,
  areSearchesReady,
  isInProgress,
}) {
  const classes = useStyles();

  const updated = !isInProgress && question?.updatedSinceLastSubmission;
  const isApproved = question?.isApproved;
  const hasNode = question?.hasDiligenceNode;
  const hasDraftComment = Boolean(question?.draftComment);
  const hasNewAlerts = question?.hasNewAlerts;

  const answers = question?.answer;
  const missingData = answers?.optOut;
  const name = answers?.name;
  const reviewDisabled = !areSearchesReady || !hasNode;
  const tooltipText = hasNode
    ? 'Potential matches are being generated. Refresh your browser in about one minute.'
    : 'The investor has not finalized this data yet.';

  return (
    <Paper className={classes.root} elevation="2">
      <div className={classes.nonAlertContainer}>
        <IdAndStatusDisplay
          name={name}
          answer={answers}
          hasDraftComment={hasDraftComment}
          hasNewAlerts={hasNewAlerts}
          numUnevaluatedMatches={question.numUnevaluatedMatches}
          numCommentEvents={question.numCommentEvents}
          isThreadResolved={question.isThreadResolved}
          isApproved={isApproved}
          className={classes.idDisplay}
          updated={updated}
        />

        <Tooltip
          title={
            reviewDisabled ? (
              <Typography variant="label">{tooltipText}</Typography>
            ) : (
              ''
            )
          }
          arrow
        >
          <span>
            <Button
              variant={isApproved ? 'secondary' : 'primary'}
              onClick={() => {
                onShowReviewClick(question.label);
              }}
              disabled={reviewDisabled}
              htmlProps={{ 'data-test': 'diligence_v2_review_owner' }}
            >
              {isApproved ? 'View' : 'Review'}
            </Button>
          </span>
        </Tooltip>
      </div>

      {missingData ? <OptOutAlert sticky /> : null}
    </Paper>
  );
}
