import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Alert,
  Button,
  Chip,
  Typography,
  Modal,
  Link,
  UIKitSettingsProvider,
  useConfirm,
} from '@passthrough/uikit';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { Spinner } from 'components/spinner';
import * as urls from 'services/urls';
import * as api from 'services/api';
import { useToast } from 'services/toast';
import { useOrganization } from 'services/providers/organization';
import { CopyValueButton } from './copy_value_button';

const useStyles = makeStyles((theme) => ({
  verifyButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  verifyHeading: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}));

export function ManageDomainDialog({
  domain,
  onClose,
  approvedDomain,
  trustedDomain,
  verifiedDomain,
  onChange,
}) {
  const classes = useStyles();
  const organizationId = urls.useOrganizationId();
  const [organization, , refreshOrganization] = useOrganization();
  const { toast } = useToast();
  const [approved, setApproved] = React.useState(false);
  const [trusted, setTrusted] = React.useState(false);
  const [verified, setVerified] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [verifyLoading, setVerifyLoading] = React.useState(false);
  const [verifyErrorMsg, setVerifyErrorMsg] = React.useState('');
  const [approvedDomainErrorMsg, setApprovedDomainErrorMsg] =
    React.useState('');
  const [trustedDomainErrorMsg, setTrustedDomainErrorMsg] = React.useState('');
  const confirm = useConfirm();

  useEffect(() => {
    setApproved(approvedDomain);
    setTrusted(!!trustedDomain);
    setVerified(!!verifiedDomain);
  }, [domain]);

  function changeApprovedDomains() {
    setApprovedDomainErrorMsg('');
    return new Promise((resolve, reject) => {
      if (approved && !approvedDomain) {
        api
          .orgApprovedDomainCreate({
            organizationId,
            emailDomain: '@'.concat(domain),
          })
          .then(() => {
            refreshOrganization((c) => c + 1);
            resolve();
          })
          .catch((err) => {
            setApprovedDomainErrorMsg(err.response.emailDomain);
            reject(err);
          });
      } else if (!approved && approvedDomain) {
        api
          .orgApprovedDomainDelete({
            organizationId,
            emailDomain: '@'.concat(domain),
          })
          .then(() => {
            refreshOrganization((c) => c + 1);
            resolve();
          })
          .catch(reject);
      } else {
        resolve();
      }
    });
  }

  function changeTrustedDomains() {
    setTrustedDomainErrorMsg('');
    return new Promise((resolve, reject) => {
      if (trusted && !trustedDomain) {
        api
          .orgTrustedDomainCreate({ organizationId, domain })
          .then(resolve)
          .catch((err) => {
            setTrustedDomainErrorMsg(err.response.domain);
            reject(err);
          });
      } else if (!trusted && trustedDomain) {
        api
          .orgTrustedDomainDelete({
            organizationId,
            domainId: trustedDomain.id,
          })
          .then(resolve)
          .catch(reject);
      } else {
        resolve();
      }
    });
  }

  const destructive = domain && !(approved || trusted || verified);

  function handleClose() {
    if (approved !== approvedDomain || trusted !== !!trustedDomain) {
      confirm({
        title: 'Discard unsaved changes?',
        description: 'Your changes have not been saved.',
        confirmationText: 'Discard',
        destructive: true,
      }).then(() => {
        onClose();
      });
    } else {
      onClose();
    }
  }

  function handleSave() {
    setLoading(true);
    Promise.allSettled([changeApprovedDomains(), changeTrustedDomains()]).then(
      (results) => {
        onChange();
        setLoading(false);
        onClose();
        if (destructive) {
          toast(`Deleted ${domain}`);
        } else if (!results.find((result) => result.status === 'rejected')) {
          toast(`Updated settings for ${domain}`);
        }
      },
    );
  }

  function handleCreateVerify() {
    setLoading(true);
    setVerifyErrorMsg('');
    api
      .orgVerifiedDomainCreate({ organizationId, emailDomain: domain })
      .then(() => {
        onChange();
      })
      .catch((err) => {
        setVerifyErrorMsg(err.response.emailDomain);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleVerify() {
    setVerifyLoading(true);
    Promise.allSettled([
      api.orgVerifiedDomainVerify({
        organizationId,
        domainId: verifiedDomain.id,
      }),
      new Promise((resolve) => setTimeout(resolve, 800)),
    ])
      .then((values) => {
        const rejected = values.find((value) => value.status === 'rejected');

        if (rejected) {
          setVerifyErrorMsg(rejected.reason.response.data.detail);
        } else if (values.every((value) => value.status === 'fulfilled')) {
          onChange();
        }
      })
      .finally(() => {
        setVerifyLoading(false);
      });
  }
  const verifyDomainInstructionsPrefix = verifiedDomain?.verificationComplete
    ? 'Your domain has been verified.'
    : "Create a TXT record with the verification value in your domain's DNS settings.";

  return (
    <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
      <Modal
        open={!!domain}
        onClose={handleClose}
        headerLabel="Manage Domain"
        subheaderLabel={domain}
        destructive={destructive}
        primaryButtonText={destructive ? 'Delete domain' : 'Save'}
        showCancelButton
        onSubmit={handleSave}
        primaryButtonDisabled={
          loading ||
          (approved === approvedDomain && trusted === !!trustedDomain)
        }
      >
        {destructive ? (
          <>
            <Alert severity="warning">
              The domain {domain} will be deleted.
            </Alert>
            {trustedDomain ? (
              <Alert severity="warning" sx={{ whiteSpace: 'pre-line' }}>
                {'The following functions will be terminated:\n' +
                  '\u2022 Webhooks and SDK'}
              </Alert>
            ) : null}
          </>
        ) : null}
        {approvedDomainErrorMsg ? (
          <Alert severity="error">
            <Typography variant="label">{approvedDomainErrorMsg}</Typography>
          </Alert>
        ) : null}
        <Typography variant="card-heading">Admin users</Typography>
        <FormControlLabel
          label={<Typography>Allow admin users with this domain</Typography>}
          control={
            <Checkbox
              checked={approved}
              onChange={() => {
                setApproved(!approved);
              }}
              disabled={loading}
            />
          }
        />
        <Typography variant="label">
          Users with email addresses from this domain can be invited to manage
          funds within this organization.
        </Typography>
        {organization?.publicApiEnabled ? (
          <>
            {trustedDomainErrorMsg ? (
              <Alert severity="error">
                <Typography variant="label">{trustedDomainErrorMsg}</Typography>
              </Alert>
            ) : null}
            <Typography variant="card-heading">Webhooks and SDK</Typography>
            <FormControlLabel
              label={
                <Typography>
                  Allow sending webhooks and embedding the SDK
                </Typography>
              }
              control={
                <Checkbox
                  checked={trusted}
                  onChange={() => {
                    setTrusted(!trusted);
                  }}
                  disabled={loading}
                />
              }
            />
            <Typography variant="label">
              This will allow webhook requests to be sent to URLs of this
              domain. Passthrough support will need to approve this domain
              before it is added to the whitelist.
            </Typography>
            {trusted && !trustedDomain ? (
              <Alert severity="info">
                Passthrough support will need to approve this domain before it
                is added to the whitelist.
              </Alert>
            ) : null}
            {trusted && trustedDomain && !trustedDomain.approvedOn ? (
              <Alert severity="info" title="Pending">
                Waiting for Passthrough support to approve this domain.
              </Alert>
            ) : null}
          </>
        ) : null}
        <div className={classes.verifyHeading}>
          <Typography variant="card-heading">Verification for SSO</Typography>
          {verifiedDomain?.verificationComplete ? (
            <Chip label="Complete" variant="success" size="small" />
          ) : null}
        </div>
        <Typography variant="label">
          {verifyDomainInstructionsPrefix} To complete the setup, read about{' '}
          <Link
            href="https://support.passthrough.com/en_us/setting-up-saml-sso-H1XYXmI12"
            inline
            variant="external"
          >
            setting up SAML SSO
          </Link>
        </Typography>
        {!verifiedDomain ? (
          <div className={classes.verifyButton}>
            <Button
              variant="secondary"
              onClick={handleCreateVerify}
              disabled={loading}
            >
              Generate verification value
            </Button>
          </div>
        ) : null}
        {verifyErrorMsg ? (
          <Alert severity="error">
            <Typography variant="label">{verifyErrorMsg}</Typography>
          </Alert>
        ) : null}
        {verifiedDomain && !verifiedDomain.verificationComplete ? (
          <>
            <CopyValueButton value={verifiedDomain.verificationValue} />
            <Alert
              severity="info"
              title="Pending"
              action={
                <>
                  {verifyLoading ? (
                    <Spinner size={64} />
                  ) : (
                    <Button variant="text" onClick={handleVerify}>
                      Verify
                    </Button>
                  )}
                </>
              }
            >
              DNS settings are not confirmed yet. DNS changes may take up to 3
              days to propagate.
            </Alert>
          </>
        ) : null}
      </Modal>
    </UIKitSettingsProvider>
  );
}
