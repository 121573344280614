import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ABAInput } from 'components/aba';
import {
  getDefaultEmptyAddress,
  Address,
  objectEqualsEmptyAddress,
} from 'components/address_v2';
import { DisplayLink } from 'components/link';
import { objectEquals } from 'services/utils';
import { Answers } from '../answers';
import { UNSAVED } from '../saved';

const useStyles = makeStyles((theme) => ({
  input: {
    margin: theme.spacing(1, 0),
  },
  helperText: {
    marginBottom: theme.spacing(1),
    marginTop: '-4px',
  },
  padding: {
    margin: theme.spacing(1, 0),
  },
}));

function parseInitialAddress(answer, defaultEmptyAddress) {
  const initialAddress = {
    ...defaultEmptyAddress,
    ...((answer && answer.googleAddress) || {}),
    ...((answer && answer.manualAddress) || {}),
  };

  return initialAddress;
}

export function BankWithAddressQuestion({
  updateLpDoc,
  setSaving,
  answer,
  label,
  QuestionStepper,
  QuestionPromptComponent,
}) {
  const classes = useStyles();

  const initialBankName = (answer && answer.bank.bankName) || '';
  const initialAba = (answer && answer.bank.aba) || '';
  const initialSwift = (answer && answer.bank.swift) || '';
  const initialAccountName = (answer && answer.bank.accountName) || '';
  const initialAccountNumber = (answer && answer.bank.accountNumber) || '';
  const initialFfct = (answer && answer.bank.ffct) || '';
  const { emptyAddress } = getDefaultEmptyAddress();
  const initialAddress = parseInitialAddress(answer, emptyAddress);

  const [bankName, setBankName] = useState(initialBankName);
  const [aba, setAba] = useState(initialAba);
  const [swift, setSwift] = useState(initialSwift);
  const [accountName, setAccountName] = useState(initialAccountName);
  const [accountNumber, setAccountNumber] = useState(initialAccountNumber);
  const [ffct, setFfct] = useState(initialFfct);
  const [address, setAddress] = useState(initialAddress);

  const [bankNameError, setBankNameError] = useState('');
  const [abaError, setAbaError] = useState('');
  const [swiftError, setSwiftError] = useState('');
  const [accountNameError, setAccountNameError] = useState('');
  const [accountNumberError, setAccountNumberError] = useState('');
  const [ffctError, setFfctError] = useState('');
  const [addressErrors, setAddressErrors] = useState({});

  const [showSwift, setShowSwift] = useState(!!swift);

  const hasInput =
    Boolean(bankName) ||
    Boolean(aba) ||
    Boolean(swift) ||
    Boolean(accountName) ||
    Boolean(accountNumber) ||
    Boolean(ffct) ||
    !objectEqualsEmptyAddress(address);

  function handleSubmit(e) {
    e.preventDefault();

    setBankNameError('');
    setAbaError('');
    setSwiftError('');
    setAccountNameError('');
    setAccountNumberError('');
    setFfctError('');
    setAddressErrors({});

    const props = {
      bank: {
        bankName,
        accountName,
        accountNumber,
        ffct,
      },
    };

    if (showSwift) {
      props.bank.swift = swift;
    } else {
      props.bank.aba = aba;
    }

    const { street, city, state, country, postalCode } = address.line1;

    props.manualAddress = {
      line1: {
        street,
        city,
        state,
        country,
        postalCode,
      },
      line2: address.line2,
    };

    return updateLpDoc({ label, answer: props, skipQuestion: !hasInput }).catch(
      (error) => {
        setSaving(UNSAVED);
        if (error.response?.status === 400) {
          const bankErrors = error.response.data.answer.bank;
          setBankNameError(bankErrors?.bankName);
          setAbaError(bankErrors?.aba);
          setSwiftError(bankErrors?.swift);
          setAccountNameError(bankErrors?.accountName);
          setAccountNumberError(bankErrors?.accountNumber);
          setFfctError(bankErrors?.ffct);
          if (error.response.data.answer.googleAddress) {
            setAddressErrors(error.response.data.answer.googleAddress);
          } else if (error.response.data.answer.manualAddress) {
            setAddressErrors(error.response.data.answer.manualAddress);
          }
        }
        return error;
      },
    );
  }

  function handleChange(e, field) {
    setSaving(UNSAVED);

    if (field === 'bankName') {
      setBankName(e.target.value);
    } else if (field === 'aba') {
      setAba(e.target.value);
    } else if (field === 'swift') {
      setSwift(e.target.value);
    } else if (field === 'accountName') {
      setAccountName(e.target.value);
    } else if (field === 'accountNumber') {
      setAccountNumber(e.target.value);
    } else if (field === 'ffct') {
      setFfct(e.target.value);
    } else if (field === 'address') {
      setAddress(e);
    }
  }

  // Reset the input when we change questions.
  useEffect(() => {
    setBankName(initialBankName);
    setAba(initialAba);
    setSwift(initialSwift);
    setAccountName(initialAccountName);
    setAccountNumber(initialAccountNumber);
    setFfct(initialFfct);
    setAddress(initialAddress);

    setBankNameError('');
    setAbaError('');
    setSwiftError('');
    setAccountNameError('');
    setAccountNumberError('');
    setFfctError('');
    setAddressErrors({});

    setShowSwift(!!swift);
  }, [label]);

  return (
    <form onSubmit={handleSubmit}>
      <QuestionPromptComponent short />
      <Answers tall>
        <TextField
          className={classes.input}
          id="bankName"
          autoFocus
          label="Bank name"
          variant="outlined"
          value={bankName}
          error={!!bankNameError}
          helperText={bankNameError}
          onChange={(e) => {
            handleChange(e, 'bankName');
          }}
          fullWidth
        />

        <div className={classes.padding}>
          <Address
            address={address}
            onChange={(value) => {
              handleChange(value, 'address');
            }}
            errors={addressErrors}
            label="Bank address"
          />
        </div>

        <TextField
          className={classes.input}
          id="accountName"
          label="Account name"
          variant="outlined"
          value={accountName}
          error={!!accountNameError}
          helperText={accountNameError}
          onChange={(e) => {
            handleChange(e, 'accountName');
          }}
          fullWidth
        />

        <FormHelperText className={classes.helperText}>
          The name under which the account is held. This typically matches the
          legal name of the investor.
        </FormHelperText>

        <TextField
          className={classes.input}
          id="accountNumber"
          label="Account number"
          variant="outlined"
          value={accountNumber}
          error={!!accountNumberError}
          helperText={accountNumberError}
          onChange={(e) => {
            handleChange(e, 'accountNumber');
          }}
          fullWidth
        />

        {showSwift ? (
          <>
            <TextField
              className={classes.input}
              id="swift"
              label="SWIFT code"
              variant="outlined"
              value={swift}
              error={!!swiftError}
              helperText={swiftError}
              onChange={(e) => {
                handleChange(e, 'swift');
              }}
              fullWidth
            />
            <FormHelperText className={classes.helperText}>
              <DisplayLink
                onClick={() => {
                  setShowSwift(false);
                }}
              >
                I have an ABA (routing number) ›
              </DisplayLink>
            </FormHelperText>
          </>
        ) : (
          <>
            <TextField
              className={classes.input}
              id="aba"
              label="ABA (routing number)"
              variant="outlined"
              value={aba}
              error={!!abaError}
              helperText={abaError}
              onChange={(e) => {
                handleChange(e, 'aba');
              }}
              InputProps={{
                inputComponent: ABAInput,
              }}
              fullWidth
            />
            <FormHelperText className={classes.helperText}>
              <DisplayLink
                onClick={() => {
                  setShowSwift(true);
                }}
              >
                I have a SWIFT code ›
              </DisplayLink>
            </FormHelperText>
          </>
        )}

        <TextField
          className={classes.input}
          id="ffct"
          label="For further credit to"
          variant="outlined"
          value={ffct}
          error={!!ffctError}
          helperText={ffctError}
          onChange={(e) => {
            handleChange(e, 'ffct');
          }}
          fullWidth
          multiline
          rows={4}
        />
        <FormHelperText className={classes.helperText}>
          "For further credit to" is not required for most bank accounts. It may
          be required if you are using a brokerage or transferring through an
          intermediary bank account
        </FormHelperText>
        <QuestionStepper
          hasAnswerChanged={
            bankName !== initialBankName ||
            aba !== initialAba ||
            swift !== initialSwift ||
            accountName !== initialAccountName ||
            accountNumber !== initialAccountNumber ||
            ffct !== initialFfct ||
            !objectEquals(address, initialAddress)
          }
          handleSubmit={handleSubmit}
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
}
