import React from 'react';
import { Alert } from 'components/alert';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  alert: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export const SubdocStatusAlerts = ({ subdocStatuses }) => {
  const classes = useStyles();

  function customLogicAlertOrNull(statusData) {
    const numQuestionsWithMissingCustomLogic =
      statusData.healthChecks.missingCustomLogic.length;
    if (numQuestionsWithMissingCustomLogic > 0) {
      return (
        <Alert severity="error" className={classes.alert}>
          {statusData.healthChecks.missingCustomLogic.join(', ')}{' '}
          {numQuestionsWithMissingCustomLogic > 1 ? 'require' : 'requires'}{' '}
          custom logic but no custom logic was found in{' '}
          <strong>{statusData.questionnaireName}</strong>
        </Alert>
      );
    }

    return null;
  }

  function missingLogicAnnotationsAlertOrNull(statusData) {
    const numQuestionsMissingLogicAnnotations =
      statusData.healthChecks.missingLogicAnnotations.length;

    const usePlural = numQuestionsMissingLogicAnnotations > 1;

    if (numQuestionsMissingLogicAnnotations > 0) {
      return (
        <Alert severity="error" className={classes.alert}>
          {statusData.healthChecks.missingLogicAnnotations.join(', ')}{' '}
          {usePlural ? 'have' : 'has'} custom logic but{' '}
          {usePlural ? 'have' : 'has'} not been marked as requiring it in{' '}
          <strong>{statusData.questionnaireName}</strong>
        </Alert>
      );
    }

    return null;
  }

  function interactiveFieldsAlertOrNull(statusData) {
    if (statusData.healthChecks.interactiveFieldsExist) {
      return (
        <Alert severity="error" className={classes.alert}>
          Interactive fields exist on{' '}
          <strong>{statusData.questionnaireName}</strong>
          {'. '}
          These fields can override the data Passthrough fills in. Flatten the
          PDF to prevent overwritten data.
        </Alert>
      );
    }

    return null;
  }

  return subdocStatuses.map((statusData) => (
    <React.Fragment key={statusData.questionnaireName}>
      {customLogicAlertOrNull(statusData)}
      {missingLogicAnnotationsAlertOrNull(statusData)}
      {interactiveFieldsAlertOrNull(statusData)}
    </React.Fragment>
  ));
};
