import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { Button } from 'components/button';
import { Dialog } from 'components/dialog/index';
import { NavBar } from 'components/nav_bar';
import * as api from 'services/api';
import { GidEditTool } from './gid_edit';

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    marginBottom: theme.spacing(2),
  },
  header: {
    margin: theme.spacing(3, 0, 2, 0),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  save: {
    marginBottom: theme.spacing(2),
  },
}));

const useDialogStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.default,
  },
}));

function getNonNullGids(questionGids) {
  return questionGids.filter((gid) => gid != null);
}

export function QuestionTagModal({ open, setOpen, tagId = null }) {
  const classes = useStyles();
  const dialogClasses = useDialogStyles();

  const [tag, setTag] = useState({
    tag: '',
    display: '',
    description: '',
    platonicQuestionText: '',
    questionGids: [],
    gidsInfo: {},
  });
  const [tagError, setTagError] = useState(null);
  const [gidsError, setGidsError] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  // If tagId is not specified, then we are creating a new tag.
  const addMode = !tagId;

  function resetErrors() {
    setTagError(null);
    setGidsError(null);
  }

  function createQuestionTag() {
    setIsSubmitting(true);

    api
      .addQuestionTag({
        tag: tag.tag,
        tagDisplay: tag.tagDisplay,
        tagDescription: tag.tagDescription,
        platonicQuestionText: tag.platonicQuestionText,
        questionGids: getNonNullGids(tag.questionGids),
      })
      .then(() => {
        setIsSubmitting(false);
        resetErrors();
        setTag({
          tag: '',
          tagDisplay: '',
          tagDescription: '',
          platonicQuestionText: '',
          questionGids: [],
          gidsInfo: {},
        });
        setOpen(false);
      })
      .catch((err) => {
        setIsSubmitting(false);
        setTagError(err.response.data.tag);
        setGidsError(err.response.data.questionGids);
      });
  }

  function getQuestionTag() {
    api
      .getQuestionTag({
        tagId,
      })
      .then((response) => {
        setTag(response.data);
        resetErrors();
      })
      .catch((err) => {
        setGidsError(err.response.data.questionGids);
      });
  }

  function editQuestionTag() {
    setIsSubmitting(true);

    api
      .editQuestionTag({
        tagId,
        tag: tag.tag,
        tagDisplay: tag.tagDisplay,
        tagDescription: tag.tagDescription,
        platonicQuestionText: tag.platonicQuestionText,
        questionGids: getNonNullGids(tag.questionGids),
      })
      .then(() => {
        setIsSubmitting(false);
        resetErrors();
        setTag({
          tag: null,
          tagDisplay: null,
          tagDescription: null,
          platonicQuestionText: null,
          questionGids: [],
          gidsInfo: {},
        });
        setOpen(false);
      })
      .catch((err) => {
        setIsSubmitting(false);
        setGidsError(err.response.data.questionGids);
      });
  }

  function addGid() {
    setTag({
      ...tag,
      questionGids: [...tag.questionGids, null],
    });
  }

  function deleteGid(index) {
    const newGids = [...tag.questionGids];
    newGids.splice(index, 1);
    setTag({
      ...tag,
      questionGids: newGids,
    });
  }

  function updateGid(oldIndex, newGid, newGidInfo) {
    const newGids = [...tag.questionGids];
    newGids.splice(oldIndex, 1, newGid);
    const newGidsInfo = {
      ...tag.gidsInfo,
      [newGid]: newGidInfo,
    };
    setTag({
      ...tag,
      questionGids: newGids,
      gidsInfo: newGidsInfo,
    });
  }

  useEffect(() => {
    if (!addMode) {
      getQuestionTag();
    } else {
      setTag({
        tag: '',
        tagDisplay: '',
        tagDescription: '',
        platonicQuestionText: '',
        questionGids: [],
        gidsInfo: {},
      });
    }
  }, [open]);

  const disableSubmissionButton =
    tag.tag === '' || tag.tagDisplay === '' || tag.platonicQuestionText === '';

  const header = addMode ? 'Add a tag' : 'Edit tag';

  return (
    <Dialog open={open} fullScreen classes={dialogClasses} disableEscapeKeyDown>
      <NavBar
        leftIcon={<CloseIcon />}
        leftText="Close"
        handleBack={() => setOpen(false)}
      />

      <Container component="main" maxWidth="md" className={classes.container}>
        <form autoComplete="off">
          <Typography variant="h5" className={classes.header}>
            {header}
          </Typography>
          <TextField
            className={classes.input}
            required
            id="tag"
            variant="outlined"
            label="Tag"
            type="text"
            value={tag.tag}
            onChange={(e) =>
              setTag({
                ...tag,
                tag: e.target.value,
              })
            }
            fullWidth
            disabled={!addMode}
          />
          {tagError ? (
            <FormHelperText className={classes.errorMessage} error>
              {tagError}
            </FormHelperText>
          ) : null}
          <TextField
            className={classes.input}
            required
            id="tagDisplay"
            variant="outlined"
            label="Tag display"
            type="text"
            value={tag.tagDisplay}
            onChange={(e) =>
              setTag({
                ...tag,
                tagDisplay: e.target.value,
              })
            }
            fullWidth
          />
          <TextField
            className={classes.input}
            id="tagDescription"
            variant="outlined"
            multiline
            rows="4"
            label="Description"
            type="text"
            value={tag.tagDescription}
            onChange={(e) =>
              setTag({
                ...tag,
                tagDescription: e.target.value,
              })
            }
            fullWidth
          />
          <TextField
            className={classes.input}
            required
            id="platonicQuestionText"
            variant="outlined"
            multiline
            rows="4"
            label="Platonic question text"
            type="text"
            value={tag.platonicQuestionText}
            onChange={(e) =>
              setTag({
                ...tag,
                platonicQuestionText: e.target.value,
              })
            }
            fullWidth
          />
          <GidEditTool
            gids={tag.questionGids}
            gidsInfo={tag.gidsInfo}
            handleAdd={addGid}
            handleDelete={deleteGid}
            handleEdit={updateGid}
          />
          {gidsError ? (
            <FormHelperText className={classes.errorMessage} error>
              {gidsError}
            </FormHelperText>
          ) : null}
          <Button
            onClick={addMode ? createQuestionTag : editQuestionTag}
            variant="contained"
            color="primary"
            fullWidth
            disabled={disableSubmissionButton}
            loading={isSubmitting}
            className={classes.save}
          >
            Save
          </Button>
        </form>
      </Container>
    </Dialog>
  );
}
