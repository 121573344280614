import React from 'react';
import { useParams } from 'react-router-dom';
import * as api from 'services/api';
import { useFundEdit } from 'services/providers/fund';
import { Icons, Menu, MenuItem } from '@passthrough/uikit';

function AnswerLocationDownload(fundId, subscriptionDoc) {
  const link = document.createElement('a');
  link.href = api.subscriptionDocumentTestFill({
    fundId,
    subDocId: subscriptionDoc.id,
  });
  link.download = `${subscriptionDoc.name}_token.pdf`;
  link.dispatchEvent(new MouseEvent('click'));
}

function LogicDownload(fundId, subscriptionDoc) {
  const link = document.createElement('a');
  link.href = api.getSubscriptionDocumentLogic({
    fundId,
    subDocId: subscriptionDoc.id,
  });
  link.download = `${subscriptionDoc.name}_logic.xlsx`;
  link.dispatchEvent(new MouseEvent('click'));
}

export function SubdocActionsMenu({
  subscriptionDoc,
  anchorEl,
  menuOpen,
  handleClose,
  onArchive,
  onUnarchive,
}) {
  const { fundId } = useParams();
  const [fundEdit] = useFundEdit();

  let toggleArchiveMenuItem = null;
  if (fundEdit) {
    toggleArchiveMenuItem = !subscriptionDoc.isArchived ? (
      <MenuItem
        onClick={() => onArchive(subscriptionDoc)}
        text="Archive document"
        icon={<Icons.ArchiveOutlined fontSize="small" color="primary" />}
      />
    ) : (
      <MenuItem
        onClick={() => onUnarchive(subscriptionDoc)}
        text="Restore document"
        icon={<Icons.RestoreOutlined fontSize="small" color="primary" />}
      />
    );
  }

  return (
    <Menu
      id="menu-subdoc-actions"
      anchorEl={anchorEl}
      open={menuOpen}
      onClose={handleClose}
    >
      <MenuItem
        icon={<Icons.FileDownloadOutlined fontSize="small" color="primary" />}
        text="Excel logic export"
        onClick={() => LogicDownload(fundId, subscriptionDoc)}
      />
      <MenuItem
        icon={<Icons.FileDownloadOutlined fontSize="small" color="primary" />}
        onClick={() => AnswerLocationDownload(fundId, subscriptionDoc)}
        text="Answer location export"
      />
      {toggleArchiveMenuItem}
    </Menu>
  );
}
