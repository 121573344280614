import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
  },
  iframe: {
    flexGrow: 1,
    border: `1px solid ${theme.palette.divider}`,
  },
}));

export function DocumentDisplay({ documentData }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <iframe
        src={documentData.url}
        className={classes.iframe}
        title="diligence-document-display"
        sandbox
        scrolling="yes"
      />
    </div>
  );
}
