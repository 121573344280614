import {
  TYPE_INDIVIDUAL,
  TYPE_PARTNERSHIP,
  TYPE_BENEFICIARIES,
  TYPE_GPS,
  TYPE_DIRECTORS,
  TYPE_BENEFICIAL_OWNERS,
  TYPE_TRUSTEES,
  TYPE_LPS,
  TYPE_VALID_ID,
  TYPE_PROOF_OF_ADDRESS,
  docTypesToLabels,
  TYPE_REGULATED_FINANCIAL_INSTITUTION,
  TYPE_ASL,
  TYPE_PRIVATE_SCHOOL,
  TYPE_AUDITED_ANNUAL_REPORT,
  TYPE_GOV_BODY,
  TYPE_AOA,
  TYPE_TRUST,
  TYPE_TRUST_AGREEMENT,
  TYPE_PARTNERSHIP_AGREEMENT,
  TYPE_FOUNDATION,
  TYPE_DONOR_LIST,
  TYPE_LISTED_COMPANY,
  TYPE_PRIVATE_COMPANY,
  TYPE_INVESTMENT_FUND,
  TYPE_PROSPECTUS,
  TYPE_INVESTMENT_MANAGEMENT_AGREEMENT,
  TYPE_PROOF_OF_EXISTENCE,
  TYPE_INITIATOR,
  TYPE_COUNTERPARTIES,
  TYPE_PENSION_FUND,
  TYPE_PROOF_OF_EMPLOYER_EXISTENCE,
  TYPE_CLUBS,
  TYPE_NON_PROFIT,
  GROUP_COMPANY,
  GROUP_ORGANIZATION,
  GROUP_NONE,
  TYPE_SETTLORS,
  TYPE_DIRECTORS_LIST,
  TYPE_REGISTER_OF_SHAREHOLDERS,
  TYPE_STRUCTURE_CHART,
  TYPE_MANAGEMENT_COMPANY,
  TYPE_MEMBERS_LIST,
} from '../../constants';

export const LUXEMBOURG_REQUIREMENTS = {
  [GROUP_NONE]: {
    [TYPE_INDIVIDUAL]: {
      documents: [
        {
          key: TYPE_VALID_ID,
          label: 'Certified passport',
          helpText:
            'Provide true copy of a valid passport for the individual certified within the last 3 months (by a notary or equivalent).',
        },
        {
          key: TYPE_PROOF_OF_ADDRESS,
          label: docTypesToLabels[TYPE_PROOF_OF_ADDRESS],
          helpText:
            'Provide a utility bill or similar dated within the last 3 months. A utility bill dated in the last 3 months is preferable.',
        },
      ],
      owners: [],
    },
    [TYPE_TRUST]: {
      documents: [
        {
          key: TYPE_TRUST_AGREEMENT,
          label: 'Trust agreement',
          helpText:
            'Provide a copy of the initial trust agreement for the trust and all of its amendments.',
        },
        {
          key: TYPE_ASL,
          label: docTypesToLabels[TYPE_ASL],
          helpText:
            'Provide a list of the authorized signatories for the trust dated within the last 12 months.',
        },
        {
          key: TYPE_DIRECTORS_LIST,
          label: docTypesToLabels[TYPE_DIRECTORS_LIST],
        },
      ],
      owners: [
        {
          key: TYPE_TRUSTEES,
          singularLabel: 'Trustee or controller',
          pluralLabel: 'Trustees or controllers',
          helpText:
            'Add the names of all trustees or controllers of the trust.',
        },
        {
          key: TYPE_BENEFICIARIES,
          singularLabel: 'Beneficiary',
          pluralLabel: 'Beneficiaries',
          helpText: 'Add the names of all beneficiaries of the trust.',
        },
        {
          key: TYPE_SETTLORS,
          singularLabel: 'Settlor',
          pluralLabel: 'Settlors',
          helpText: 'Add the names of all settlors of the trust.',
        },
        {
          key: TYPE_DIRECTORS,
          singularLabel: 'Director',
          pluralLabel: 'Directors',
          helpText: 'Add the names of any directors of the trust.',
        },
      ],
    },
  },
  [GROUP_COMPANY]: {
    [TYPE_PRIVATE_COMPANY]: {
      documents: [
        {
          key: TYPE_PROOF_OF_EXISTENCE,
          label: 'Proof of existence not older than 6 months',
          helpText:
            'Provide a regulator website extract dated within the last 6 months if the privately held company is regulated. Provide a certificate of incorporation, a certificate of good standing, or a trade register extract, dated within the last 6 months, if the privately held company is not regulated.',
        },
        {
          key: TYPE_AUDITED_ANNUAL_REPORT,
          label: docTypesToLabels[TYPE_AUDITED_ANNUAL_REPORT],
          helpText:
            'Provide the latest audited annual financial reports for the privately held company. If no audited version is available, provide the latest financial reports.',
        },
        {
          key: TYPE_ASL,
          label: docTypesToLabels[TYPE_ASL],
          helpText:
            'Provide a list of the authorized signatories for the privately held company dated within the last 12 months.',
        },
        {
          key: TYPE_DIRECTORS_LIST,
          label: docTypesToLabels[TYPE_DIRECTORS_LIST],
        },
      ],
      owners: [
        {
          key: TYPE_DIRECTORS,
          singularLabel: 'Director or controller',
          pluralLabel: 'Directors or controllers',
          helpText:
            'Add the names of any directors or persons having substantial control over any relevant matters of the privately held company.',
        },
        {
          key: TYPE_BENEFICIAL_OWNERS,
          singularLabel: 'Beneficial owner of 25% or more',
          pluralLabel: 'Beneficial owners of 25% or more',
          helpText:
            'Add the names of any beneficial owners of 25% or more of the privately held company.',
        },
      ],
    },
    [TYPE_PARTNERSHIP]: {
      documents: [
        {
          key: TYPE_PARTNERSHIP_AGREEMENT,
          label: docTypesToLabels[TYPE_PARTNERSHIP_AGREEMENT],
          helpText:
            'Provide a copy of the partnership agreement for the partnership.',
        },
        {
          key: TYPE_ASL,
          label: docTypesToLabels[TYPE_ASL],
          helpText:
            'Provide a list of the authorized signatories for the partnership dated within the last 12 months.',
        },
        {
          key: TYPE_REGISTER_OF_SHAREHOLDERS,
          label: docTypesToLabels[TYPE_REGISTER_OF_SHAREHOLDERS],
          helpText:
            'Provide the register of all shareholders of the partnership.',
        },
        {
          key: TYPE_STRUCTURE_CHART,
          label: docTypesToLabels[TYPE_STRUCTURE_CHART],
          helpText:
            'Provide a structure chart for the partnership illustrating the ownership of the partnership.',
        },
        {
          key: TYPE_DIRECTORS_LIST,
          label: docTypesToLabels[TYPE_DIRECTORS_LIST],
        },
      ],
      owners: [
        {
          key: TYPE_GPS,
          singularLabel: 'General partner',
          pluralLabel: 'General partners',
          helpText: 'Add the names of all general partners of the partnership.',
        },
        {
          key: TYPE_DIRECTORS,
          singularLabel: 'Director or controller',
          pluralLabel: 'Directors or controllers',
          helpText:
            'Add the names of any directors or persons having substantial control over any relevant matters of the partnership.',
        },
        {
          key: TYPE_LPS,
          singularLabel: 'Limited partner of 25% or more',
          pluralLabel: 'Limited partners of 25% or more',
          helpText:
            'Add the names of all limited partners owning 25% or more of the partnership.',
        },
      ],
    },
    [TYPE_INVESTMENT_FUND]: {
      documents: [
        {
          key: TYPE_PROOF_OF_EXISTENCE,
          label: 'Proof of existence not older than 6 months',
          helpText:
            'Provide a regulator website extract dated within the last 6 months if the investment fund is regulated. Provide a certificate of incorporation, a certificate of good standing, or a trade register extract, dated within the last 6 months, if the investment fund is not regulated.',
        },
        {
          key: TYPE_PROSPECTUS,
          label: docTypesToLabels[TYPE_PROSPECTUS],
          helpText:
            'Provide the prospectus or any other constitutive documents according to the legal form of the investment fund.',
        },
        {
          key: TYPE_INVESTMENT_MANAGEMENT_AGREEMENT,
          label: docTypesToLabels[TYPE_INVESTMENT_MANAGEMENT_AGREEMENT],
          helpText:
            'Provide the investment management agreement or equivalent document for the investment fund.',
        },
        {
          key: TYPE_ASL,
          label: docTypesToLabels[TYPE_ASL],
          helpText:
            'Provide a list of the authorized signatories for the investment fund dated within the last 12 months.',
        },
        {
          key: TYPE_DIRECTORS_LIST,
          label: docTypesToLabels[TYPE_DIRECTORS_LIST],
        },
        {
          key: TYPE_REGISTER_OF_SHAREHOLDERS,
          label: 'Shareholder register or AML Letter',
          helpText:
            'Provide a shareholder register not older than 1 year or an AML Letter from a regulated entity.',
        },
      ],
      owners: [
        {
          key: TYPE_DIRECTORS,
          singularLabel: 'Director or controller',
          pluralLabel: 'Directors or controllers',
          helpText:
            'Add the names of any directors or persons having substantial control over any relevant matters of the investment fund.',
        },
        {
          key: TYPE_INITIATOR,
          singularLabel: 'Initiator or promoter',
          pluralLabel: 'Initiator or promoter',
          helpText:
            'Add the names of all initiators or promoters of the investment fund.',
        },
        {
          key: TYPE_COUNTERPARTIES,
          singularLabel: 'Counterparty',
          pluralLabel: 'Counterparties',
          helpText:
            'Add the names of all counterparties of the investment fund.',
        },
        {
          key: TYPE_MANAGEMENT_COMPANY,
          singularLabel: 'Management company',
          pluralLabel: 'Management company',
          helpText:
            'Add the name of the management company of the investment fund.',
        },
      ],
    },
    [TYPE_LISTED_COMPANY]: {
      disableSourceOfWealth: true,
      documents: [
        {
          key: TYPE_ASL,
          label: docTypesToLabels[TYPE_ASL],
          helpText:
            'Provide a list of the authorized signatories for the listed company dated within the last 12 months.',
        },
        {
          key: TYPE_DIRECTORS_LIST,
          label: docTypesToLabels[TYPE_DIRECTORS_LIST],
        },
      ],
      owners: [
        {
          key: TYPE_DIRECTORS,
          singularLabel: 'Director or controller',
          pluralLabel: 'Directors or controllers',
          helpText:
            'Add the names of any directors or persons having substantial control over any relevant matters of the listed company.',
        },
        {
          key: TYPE_BENEFICIAL_OWNERS,
          singularLabel: 'Beneficial owner of 25% or more',
          pluralLabel: 'Beneficial owners of 25% or more',
          helpText:
            'Add the names of any shareholder owning 25% or more of the listed company.',
        },
      ],
    },
    [TYPE_REGULATED_FINANCIAL_INSTITUTION]: {
      disableSourceOfWealth: true,
      documents: [
        {
          key: TYPE_ASL,
          label: docTypesToLabels[TYPE_ASL],
          helpText:
            'Provide a list of the authorized signatories for the regulated financial institution dated within the last 12 months.',
        },
        {
          key: TYPE_DIRECTORS_LIST,
          label: docTypesToLabels[TYPE_DIRECTORS_LIST],
        },
      ],
      owners: [
        {
          key: TYPE_DIRECTORS,
          singularLabel: 'Director or controller',
          pluralLabel: 'Directors or controllers',
          helpText:
            'Add the names of any directors or persons having substantial control over any relevant matters of the regulated financial institution.',
        },
      ],
    },
    [TYPE_PRIVATE_SCHOOL]: {
      documents: [
        {
          key: TYPE_PROOF_OF_EXISTENCE,
          label: 'Proof of existence not older than 6 months',
          helpText:
            'Provide a regulator website extract dated within the last 6 months if the private school or university is regulated. Provide a certificate of incorporation, a certificate of good standing, or a trade register extract, dated within the last 6 months, if the private school or university is not regulated.',
        },
        {
          key: TYPE_AUDITED_ANNUAL_REPORT,
          label: docTypesToLabels[TYPE_AUDITED_ANNUAL_REPORT],
          helpText:
            'Provide the latest audited annual financial reports for the private school or university. If no audited version is available, provide the latest financial reports.',
        },
        {
          key: TYPE_ASL,
          label: docTypesToLabels[TYPE_ASL],
          helpText:
            'Provide a list of the authorized signatories for the private school or university dated within the last 12 months.',
        },
        {
          key: TYPE_DIRECTORS_LIST,
          label: docTypesToLabels[TYPE_DIRECTORS_LIST],
        },
      ],
      owners: [
        {
          key: TYPE_DIRECTORS,
          singularLabel: 'Director or controller',
          pluralLabel: 'Directors or controllers',
          helpText:
            'Add the names of any directors or persons having substantial control over any relevant matters of the private school or university.',
        },
        {
          key: TYPE_BENEFICIAL_OWNERS,
          singularLabel: 'Beneficial owner of 25% or more',
          pluralLabel: 'Beneficial owners of 25% or more',
          helpText:
            'Add the names of any beneficial owners of 25% or more of the private school or university.',
        },
      ],
    },
  },
  [GROUP_ORGANIZATION]: {
    [TYPE_PENSION_FUND]: {
      documents: [
        {
          key: TYPE_PROOF_OF_EXISTENCE,
          label: 'Proof of existence not older than 6 months',
          helpText:
            'Provide a regulator website extract dated within the last 6 months if the pension fund is regulated. Provide a certificate of incorporation, a certificate of good standing, or a trade register extract, dated within the last 6 months, if the pension fund is not regulated.',
        },
        {
          key: TYPE_PROSPECTUS,
          label: docTypesToLabels[TYPE_PROSPECTUS],
          helpText:
            'Provide the prospectus or any other constitutive documents according to the legal form of the pension fund.',
        },
        {
          key: TYPE_PROOF_OF_EMPLOYER_EXISTENCE,
          label: docTypesToLabels[TYPE_PROOF_OF_EMPLOYER_EXISTENCE],
          helpText:
            'Provide proof of the existence of the employer the pension fund is for, such as a certificate of incorporation, regulator website extract, or similar.',
        },
        {
          key: TYPE_ASL,
          label: docTypesToLabels[TYPE_ASL],
          helpText:
            'Provide a list of the authorized signatories for the pension fund dated within the last 12 months.',
        },
        {
          key: TYPE_DIRECTORS_LIST,
          label: docTypesToLabels[TYPE_DIRECTORS_LIST],
        },
      ],
      owners: [
        {
          key: TYPE_DIRECTORS,
          singularLabel: 'Director or controller',
          pluralLabel: 'Directors or controllers',
          helpText:
            'Add the names of any directors or persons having substantial control over any relevant matters of the pension fund.',
        },
      ],
    },
    [TYPE_NON_PROFIT]: {
      documents: [
        {
          key: TYPE_PROOF_OF_EXISTENCE,
          label: 'Proof of existence not older than 6 months',
          helpText:
            'Provide a regulator website extract dated within the last 6 months if the non-profit organization is regulated. Provide a certificate of incorporation, a certificate of good standing, or a trade register extract, dated within the last 6 months, if the non-profit organization is not regulated.',
        },
        {
          key: TYPE_ASL,
          label: docTypesToLabels[TYPE_ASL],
          helpText:
            'Provide a list of the authorized signatories for the non-profit organization dated within the last 12 months.',
        },
        {
          key: TYPE_AUDITED_ANNUAL_REPORT,
          label: docTypesToLabels[TYPE_AUDITED_ANNUAL_REPORT],
          helpText:
            'Provide the latest audited annual financial reports for the non-profit organization. If no audited version is available, provide the latest financial reports.',
        },
        {
          key: TYPE_DONOR_LIST,
          label: docTypesToLabels[TYPE_DONOR_LIST],
          helpText:
            'Provide a list of all donors of the non-profit organization, including their respective country(ies) of citizenship.',
        },
        {
          key: TYPE_DIRECTORS_LIST,
          label: docTypesToLabels[TYPE_DIRECTORS_LIST],
        },
      ],
      owners: [
        {
          key: TYPE_DIRECTORS,
          singularLabel: 'Director or controller',
          pluralLabel: 'Directors or controllers',
          helpText:
            'Add the names of any directors or persons having substantial control over any relevant matters of the non-profit organization.',
        },
      ],
    },
    [TYPE_GOV_BODY]: {
      disableSourceOfWealth: true,
      documents: [
        {
          key: TYPE_AOA,
          label: 'Articles of association',
          helpText:
            'Provide the articles of association or similar constitutive documents for the government body.',
        },
        {
          key: TYPE_AUDITED_ANNUAL_REPORT,
          label: 'Latest audited annual report',
          helpText:
            'Provide the latest audited annual financial reports for the government body. If no audited version is available, provide the latest financial reports.',
        },
        {
          key: TYPE_ASL,
          label: docTypesToLabels[TYPE_ASL],
          helpText:
            'Provide a list of the authorized signatories for the government body dated within the last 12 months.',
        },
        {
          key: TYPE_PROOF_OF_EXISTENCE,
          label:
            'Regulator website extract or copy of law establishing the government body',
          helpText:
            'Provide a regulator website extract for the government body, or a copy the law establishing the government body.',
        },
        {
          key: TYPE_DIRECTORS_LIST,
          label: docTypesToLabels[TYPE_DIRECTORS_LIST],
        },
      ],
      owners: [
        {
          key: TYPE_DIRECTORS,
          singularLabel: 'Director or controller',
          pluralLabel: 'Directors or controllers',
          helpText:
            'Add the names of any directors or persons having substantial control over any relevant matters of the government body.',
        },
      ],
    },
    [TYPE_FOUNDATION]: {
      disableSourceOfWealth: true,
      documents: [
        {
          key: TYPE_PROOF_OF_EXISTENCE,
          label: 'Proof of existence not older than 6 months',
          helpText:
            'Provide a regulator website extract dated within the last 6 months if the foundation is regulated. Provide a certificate of incorporation, a certificate of good standing, or a trade register extract, dated within the last 6 months, if the foundation is not regulated.',
        },
        {
          key: TYPE_AOA,
          label: 'Articles of association',
          helpText:
            'Provide the articles of association or similar constitutive documents for the foundation.',
        },
        {
          key: TYPE_ASL,
          label: docTypesToLabels[TYPE_ASL],
          helpText:
            'Provide a list of the authorized signatories for the foundation dated within the last 12 months.',
        },
        {
          key: TYPE_DONOR_LIST,
          label: 'List of donor names and countries of residence',
          helpText:
            'Provide a list of all donors of the foundation, including their respective country(ies) of citizenship.',
        },
        {
          key: TYPE_DIRECTORS_LIST,
          label: docTypesToLabels[TYPE_DIRECTORS_LIST],
        },
      ],
      owners: [
        {
          key: TYPE_DIRECTORS,
          singularLabel: 'Director or controller',
          pluralLabel: 'Directors or controllers',
          helpText:
            'Add the names of any directors or persons having substantial control over any relevant matters of the foundation.',
        },
        {
          key: TYPE_BENEFICIAL_OWNERS,
          singularLabel: 'Beneficial owner of 25% or more',
          pluralLabel: 'Beneficial owners of 25% or more',
          helpText:
            'Add the names of any beneficial owners of 25% or more of the foundation.',
        },
        {
          key: TYPE_BENEFICIARIES,
          singularLabel: 'Beneficiary or founder',
          pluralLabel: 'Beneficiaries or founders',
          helpText:
            'Add the names of all beneficiaries or founders of the foundation.',
        },
      ],
    },
    [TYPE_CLUBS]: {
      disableSourceOfWealth: true,
      documents: [
        {
          key: TYPE_PROOF_OF_EXISTENCE,
          label: 'Proof of existence not older than 6 months',
          helpText:
            'Provide a regulator website extract dated within the last 6 months if the club or society is regulated. Provide a certificate of incorporation, a certificate of good standing, or a trade register extract, dated within the last 6 months, if the club or society is not regulated.',
        },
        {
          key: TYPE_AUDITED_ANNUAL_REPORT,
          label: docTypesToLabels[TYPE_AUDITED_ANNUAL_REPORT],
          helpText:
            'Provide the latest audited annual financial reports for the club or society. If no audited version is available, provide the latest financial reports.',
        },
        {
          key: TYPE_MEMBERS_LIST,
          label:
            'List of members or copy of letter confirming there are no members of 25% or more',
          helpText:
            'Provide a list of all members of the club or society, or a letter confirming that no members of the club or society own 25% or more.',
        },
        {
          key: TYPE_DIRECTORS_LIST,
          label: docTypesToLabels[TYPE_DIRECTORS_LIST],
        },
      ],
      owners: [
        {
          key: TYPE_DIRECTORS,
          singularLabel: 'Director or controller',
          pluralLabel: 'Directors or controllers',
          helpText:
            'Add the names of any directors or persons having substantial control over any relevant matters of the club or society.',
        },
        {
          key: TYPE_BENEFICIAL_OWNERS,
          singularLabel: 'Beneficial owner of 25% or more',
          pluralLabel: 'Beneficial owners of 25% or more',
          helpText:
            'Add the names of any beneficial owners of 25% or more of the club or society.',
        },
      ],
    },
  },
};
