import React from 'react';
import { ANSWER_STATES } from 'services/constants';
import { makeStyles } from '@material-ui/core/styles';
import { FileLink } from './file_link';

const useStyles = makeStyles(() => ({
  wordBreak: {
    wordBreak: 'break-all',
  },
}));

export function SingleFileDisplay({
  answer,
  questionState = ANSWER_STATES.UNCHANGED,
}) {
  const classes = useStyles();

  if (!answer) {
    return null;
  }

  return (
    <div className={classes.wordBreak}>
      <FileLink file={answer} fileState={questionState} />
    </div>
  );
}
