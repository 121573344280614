import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Icons } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

export function BooleanIcon({ bool }) {
  return bool ? (
    <Icons.CheckCircleOutline color="success" fontSize="small" />
  ) : (
    <Icons.CancelOutlined color="error" fontSize="small" />
  );
}

export function BooleanValue({ label, bool }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <BooleanIcon bool={bool} />
      <Typography>{label}</Typography>
    </div>
  );
}
