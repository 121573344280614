import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import AccountIcon from 'mdi-material-ui/AccountOutline';
import DomainIcon from 'mdi-material-ui/Domain';
import StickerTextIcon from 'mdi-material-ui/StickerTextOutline';

import { NoteIconVariant } from './constants';

const useStyles = makeStyles((theme) => ({
  gpBackground: {
    backgroundColor: theme.palette.eventBackground.gp,
  },
  lpBackground: {
    backgroundColor: theme.palette.eventBackground.lp,
  },
  internalNoteBackground: {
    backgroundColor: theme.palette.eventBackground.internalNote,
  },
  avatar: {
    width: '32px',
    height: '32px',
  },
  icon: {
    color: theme.palette.neutral.main,
  },
}));

function Icon({ variant, ...props }) {
  switch (variant) {
    case NoteIconVariant.GP:
      return <DomainIcon {...props} />;

    case NoteIconVariant.LP:
      return <AccountIcon {...props} />;

    case NoteIconVariant.INTERNAL_NOTE:
      return <StickerTextIcon {...props} />;

    default:
      return null;
  }
}

export function NoteIcon({ variant }) {
  const classes = useStyles();

  return (
    <Avatar
      className={clsx(classes.avatar, {
        [classes.gpBackground]: variant === NoteIconVariant.GP,
        [classes.lpBackground]: variant === NoteIconVariant.LP,
        [classes.internalNoteBackground]:
          variant === NoteIconVariant.INTERNAL_NOTE,
      })}
    >
      <Icon variant={variant} className={classes.icon} fontSize="small" />
    </Avatar>
  );
}
