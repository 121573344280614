import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { TaxIDInput } from 'components/taxid';
import { Answers } from '../answers';
import { UNSAVED } from '../saved';

export function TaxIDQuestion({
  updateLpDoc,
  setSaving,
  answer,
  label,
  QuestionStepper,
  frontendLabel,
  QuestionPromptComponent,
}) {
  const initialTaxId = answer || '';
  const [taxId, setTaxId] = useState(initialTaxId);
  const [errorMsg, setErrorMsg] = useState('');

  const isValid = !!taxId.match(/\d\d-\d\d\d\d\d\d\d/);
  const hasInput = Boolean(taxId);

  function handleSubmit(e) {
    e.preventDefault();

    setErrorMsg('');

    return updateLpDoc({ label, answer: taxId, skipQuestion: !hasInput }).catch(
      (error) => {
        setSaving(UNSAVED);
        if (error.response?.status === 400) {
          setErrorMsg(error.response.data.answer);
        }
        return error;
      },
    );
  }

  function handleChange(e) {
    setSaving(UNSAVED);
    setTaxId(e.target.value);
  }

  // Reset the input when we change questions.
  useEffect(() => {
    setTaxId(initialTaxId);
    setErrorMsg('');
  }, [label]);

  return (
    <form onSubmit={handleSubmit}>
      <QuestionPromptComponent />
      <Answers>
        <TextField
          id="taxId"
          autoFocus
          label={frontendLabel || 'Tax ID'}
          variant="outlined"
          value={taxId}
          error={!!errorMsg}
          helperText={errorMsg}
          onChange={handleChange}
          InputProps={{
            inputComponent: TaxIDInput,
          }}
          fullWidth
        />
        <QuestionStepper
          disabled={!isValid}
          handleSubmit={handleSubmit}
          hasAnswerChanged={taxId !== initialTaxId}
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
}
