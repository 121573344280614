import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import SelectionDragIcon from 'mdi-material-ui/SelectionDrag';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { makeStyles } from '@material-ui/core/styles';
import { useFeatureFlags } from 'services/providers/feature_flag';
import { InputDiv } from '../common/input_div';
import { Ripple } from '../common/ripple';
import { QueryBuilder } from '../query_builder';

import { boxTypes, SUBDOC_DOC_NAME } from '../constants';

const useStyles = makeStyles((theme) => ({
  iconPadding: {
    padding: theme.spacing(0.5),
  },
  location: {
    marginBottom: theme.spacing(1),
    fontWeight: 600,
  },
}));

export function BoxInput({
  box,
  onChange,
  onDelete,
  onSelectDrag,
  setPage,
  highlight,
  setHighlightBoxId,
  questions,
  handleClickItem,
  questionId,
  isCustomText,
  isDateBoxInput,
  contextOnly = false,
  selectDocument,
}) {
  const classes = useStyles();
  const { MULTIPLE_DOCUMENTS } = useFeatureFlags();

  const deps = box.deps || [];

  const createNewConditions = () => {
    if (deps.length === 0) {
      onChange({
        ...box,
        deps: [{ type: 'group', op: 'OR', children: [] }],
      });
    }
  };

  const textboxLabel = isDateBoxInput ? '(MM/DD/YYYY)' : '(text)';

  const onClick = () => {
    if (MULTIPLE_DOCUMENTS) {
      selectDocument(box.documentName);
    }
    setPage(box.page);
    setHighlightBoxId(box.id);
  };

  // custom text and context boxes have fixed type
  const enableBoxTypeChange = !isCustomText && !contextOnly;

  return (
    <Ripple ripple={highlight}>
      <div style={{ marginTop: 15 }}>
        <Typography className={classes.location}>
          Output box
          {MULTIPLE_DOCUMENTS
            ? ` (${box.documentName || SUBDOC_DOC_NAME})`
            : ''}
        </Typography>
        <InputDiv>
          <IconButton
            aria-label="find"
            className={classes.iconPadding}
            onClick={onClick}
          >
            <FindInPageIcon />
          </IconButton>
          <TextField
            value={box.page}
            onChange={(e) => {
              onChange({ ...box, page: Number(e.target.value) });
            }}
            variant="outlined"
            required
            type="number"
            label="page"
          />
          <TextField
            value={box.x}
            onChange={(e) => {
              onChange({ ...box, x: Number(e.target.value) });
            }}
            variant="outlined"
            required
            type="number"
            label="x"
          />
          <TextField
            value={box.y}
            onChange={(e) => {
              onChange({ ...box, y: Number(e.target.value) });
            }}
            variant="outlined"
            required
            type="number"
            label="y"
          />
          <TextField
            value={box.h}
            onChange={(e) => {
              onChange({ ...box, h: Number(e.target.value) });
            }}
            variant="outlined"
            required
            type="number"
            label="height"
          />
          <TextField
            value={box.w}
            onChange={(e) => {
              onChange({ ...box, w: Number(e.target.value) });
            }}
            variant="outlined"
            required
            type="number"
            label="width"
          />
          {enableBoxTypeChange ? (
            <FormControl
              variant="outlined"
              style={{ minWidth: '200px' }}
              required
            >
              <InputLabel id="answer-type">Answer type</InputLabel>
              <Select
                label="Answer type"
                labelId="answer-type"
                value={box.type || ''}
                onChange={(e) => {
                  onChange({ ...box, type: e.target.value });
                }}
              >
                <MenuItem value={boxTypes.TEXTBOX} dr>
                  Output {textboxLabel}
                </MenuItem>
                <MenuItem value={boxTypes.CHECKBOX}>Output (checkbox)</MenuItem>
                <MenuItem value={boxTypes.INITIALS}>Output (initials)</MenuItem>
                {isDateBoxInput ? (
                  <MenuItem value={boxTypes.ALT_DATE_TEXTBOX}>
                    Output (DD/MM/YYYY)
                  </MenuItem>
                ) : null}
              </Select>
            </FormControl>
          ) : null}
          <IconButton
            aria-label="select"
            className={classes.iconPadding}
            onClick={() => onSelectDrag(box.id)}
          >
            <SelectionDragIcon />
          </IconButton>
          {!contextOnly && !isCustomText ? (
            <IconButton
              aria-label="condition"
              className={classes.iconPadding}
              onClick={createNewConditions}
              disabled={deps.length > 0}
            >
              <AccountTreeIcon />
            </IconButton>
          ) : null}
          <IconButton
            aria-label="delete"
            className={classes.iconPadding}
            onClick={() => onDelete(box.id)}
          >
            <DeleteIcon />
          </IconButton>
        </InputDiv>
        <InputDiv>
          <QueryBuilder
            questionIds={[questionId]}
            value={deps}
            onChange={(d) => {
              onChange({ ...box, deps: d });
            }}
            questions={questions}
            handleClickItem={handleClickItem}
            hideAddCondition
            showThisAndFutureQuestions
          />
        </InputDiv>
      </div>
    </Ripple>
  );
}
