import React from 'react';
import { Alert, Button } from '@passthrough/uikit';

export function WarningAlert({ children, onDismiss }) {
  return (
    <Alert
      severity="warning"
      action={
        <Button onClick={onDismiss} variant="text">
          I understand
        </Button>
      }
    >
      {children}
    </Alert>
  );
}
