import React from 'react';

import { Icons } from '@passthrough/uikit';
import { EmptyState } from 'components/empty_v2';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from 'components/table';

export function BccRecipientTable({ recipientList }) {
  return (
    <Table
      noTopMargin
      belowRowsElement={
        recipientList.length === 0 ? (
          <EmptyState
            title="No recipient yet"
            Icon={Icons.EmailOutlined}
            iconVariant="neutral"
          />
        ) : null
      }
    >
      <TableHead>
        <TableRow>
          <TableCell>Email</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {recipientList.map((email) => (
          <TableRow key={email}>
            <TableCell>{email}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
