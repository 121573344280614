import React from 'react';
import { InternalChip } from '@passthrough/uikit';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import * as urls from 'services/urls';

import { Alert } from 'components/alert';
import { DisplayLink } from 'components/link';
import { useSupport } from 'components/support';
import { Button, LinkButton } from 'components/button';
import { DialogActions, DialogContent } from 'components/dialog/index';
import { DndSingleFileUpload } from 'components/dnd_file_upload/single_file/single_file_upload';
import { NavBar } from 'components/nav_bar';
import { RadioCardChoice, RadioCardContainer } from 'components/radio_card';
import { PDFNameChecker } from './pdf_name_checker';
import { OfferingDocSideLetterTypeSelector } from './offering_doc_side_letter_type_selector';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(5, 0),
  },
  errorMessage: {
    textAlign: 'center',
  },
  header: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  input: {
    margin: theme.spacing(2, 0),
  },
  form: {
    position: 'relative',
  },
  upload: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  bottomMargin: {
    marginBottom: theme.spacing(2),
  },
  topMargin: {
    marginTop: theme.spacing(2),
  },
  maxWidthBtn: {
    width: '100%',
  },
}));

const useDialogStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column',
    padding: theme.spacing(0),
  },
}));

export function UploadDocument({
  handleClose,
  onSubmit,
  file,
  setFile,
  fileError,
  setFileError,
  handleFileUpload,
  name,
  setName,
  enableEditDocument,
  setEnableEditDocument,
  isFundDocumentUpload,
  pushToExisting,
  setPushToExisting,
  getPushToExistingInvestorsError,
  docType,
  setDocType,
  docTypesToDisplay,
  isSubmitting,
  hasSigners,
  uploadCreationDocFailed,
  investors,
  investorName,
  investorLegalName,
  fileToCheck,
  setFileToCheck,
  isStaff,
}) {
  const classes = useStyles();
  const dialogClasses = useDialogStyles();
  const { showSupport } = useSupport();
  const fundId = urls.useFundId();
  const url = `/api/fund/${fundId}/file/upload/`;

  const header = isFundDocumentUpload
    ? 'Add fund document'
    : 'Add investor document';
  const numExistingInvestors = investors?.length || 0;
  const pushToExistingText = `Add to ${numExistingInvestors} existing investors`;
  const submitButtonText = enableEditDocument ? 'Next' : 'Add';
  const disableSubmissionButton =
    !file.fileId ||
    name == null ||
    name.trim() === '' ||
    docType === '' ||
    (hasSigners && enableEditDocument == null);

  return (
    <div key="uploadDocument">
      <NavBar
        leftIcon={<CloseIcon />}
        leftText="Close"
        handleBack={handleClose}
      />

      <Container component="main" maxWidth="sm" className={classes.container}>
        <form onSubmit={onSubmit} autoComplete="off">
          <Typography variant="h5" className={classes.header}>
            {header}
          </Typography>

          <DialogContent classes={dialogClasses}>
            <div key="info" className={classes.bottomMargin}>
              <Alert
                severity="info"
                action={
                  <LinkButton
                    color="inherit"
                    variant="outlined"
                    url="https://support.passthrough.com/en_us/how-to-add-documents-BJMMTkkhq"
                  >
                    Learn more
                  </LinkButton>
                }
              >
                Upload a file and optionally require signatures.
              </Alert>
            </div>

            <TextField
              className={classes.input}
              required
              id="name"
              variant="outlined"
              label="Document name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />

            <OfferingDocSideLetterTypeSelector
              type={docType}
              setType={setDocType}
              docTypesToDisplay={docTypesToDisplay}
            />

            <div key="upload">
              <DndSingleFileUpload
                file={file}
                onChange={handleFileUpload}
                url={url}
                disabled={!!fileToCheck?.fileId}
                acceptedFileTypes="application/pdf"
                data-test="add_document_file_input"
              />
              <PDFNameChecker
                investorName={investorName}
                investorLegalName={investorLegalName}
                file={file}
                setFile={setFile}
                fileToCheck={fileToCheck}
                setFileToCheck={setFileToCheck}
                fileError={fileError}
                setFileError={setFileError}
                isStaff={isStaff}
              />
              {fileError ? (
                <FormHelperText error>
                  {fileError.isStaff ? <InternalChip /> : null}{' '}
                  {fileError.message}
                </FormHelperText>
              ) : null}
            </div>

            {hasSigners ? (
              <div key="signature" className={classes.input}>
                <RadioCardContainer
                  selectedChoice={String(enableEditDocument)}
                  setSelectedChoice={(val) => {
                    setEnableEditDocument(val === 'true');
                  }}
                >
                  <RadioCardChoice
                    choiceBackground="white"
                    choiceKey="true"
                    choiceValue="true"
                    choiceTitle="Signature required"
                    choiceSubtitle="Signatories will be asked to sign the document. You'll be able to choose how they are notified."
                    dataTest="signature_required"
                  />
                  <RadioCardChoice
                    choiceBackground="white"
                    choiceKey="false"
                    choiceValue="false"
                    choiceTitle="No signature required"
                    choiceSubtitle="The document will be visible to signatories but they will not be notified or have any action required."
                    dataTest="no_signature_required"
                  />
                </RadioCardContainer>
              </div>
            ) : null}

            {isFundDocumentUpload ? (
              <div key="existing" className={classes.input}>
                <FormControl>
                  <FormControlLabel
                    className={classes.label}
                    control={
                      <Checkbox
                        checked={pushToExisting}
                        onChange={(e) => {
                          setPushToExisting(e.target.checked);
                        }}
                        name="push-to-existing"
                      />
                    }
                    label={pushToExistingText}
                  />
                </FormControl>
                {pushToExisting ? (
                  <Alert severity="warning">
                    This document will be attached to all existing and new
                    investors.
                  </Alert>
                ) : null}
              </div>
            ) : null}
          </DialogContent>

          {uploadCreationDocFailed ? (
            <FormHelperText className={classes.errorMessage} error>
              Document upload failed, please{' '}
              <DisplayLink onClick={showSupport}>contact support.</DisplayLink>
            </FormHelperText>
          ) : null}

          {getPushToExistingInvestorsError ? (
            <FormHelperText className={classes.errorMessage} error>
              Error retrieving existing investors:{' '}
              {getPushToExistingInvestorsError}.
            </FormHelperText>
          ) : null}

          <DialogActions classes={dialogClasses}>
            <span className={classes.maxWidthBtn}>
              <Button
                type="submit"
                onClick={onSubmit}
                variant="contained"
                color="primary"
                fullWidth
                disabled={disableSubmissionButton}
                loading={isSubmitting}
                className={classes.topMargin}
                data-test="add_document_submit_button"
              >
                {submitButtonText}
              </Button>
            </span>
          </DialogActions>
        </form>
      </Container>
    </div>
  );
}
