import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { QuestionTags } from './tag';

const useStyles = makeStyles((theme) => ({
  header: {
    color: theme.palette.primary.grey,
  },
  item: {
    marginBottom: theme.spacing(2),
  },
  tags: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
}));

export const ChoicePanelContent = ({
  questionText,
  choice,
  questionTags,
  left = false,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Typography key="questionHeader" className={classes.header}>
        Question
      </Typography>
      <Typography key="questionText" className={classes.item}>
        <strong>{questionText}</strong>
      </Typography>

      <Typography key="choiceHeader" className={classes.header}>
        Choice
      </Typography>
      <Typography key="choiceText" className={classes.item}>
        <strong>{choice}</strong>
      </Typography>

      {left ? (
        <div key="tags" className={classes.tags}>
          <QuestionTags questionTags={questionTags} />
        </div>
      ) : null}
    </div>
  );
};
