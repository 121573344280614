import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { useConfirm, Alert, Typography } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4, 1),
    userSelect: 'none',
  },
}));

export function ReuseInvestorData({
  name,
  idToReuse,
  parentName,
  nodeType,
  ownerType,
  shouldWarn,
  reuseDataChecked,
  handleChange,
}) {
  const confirm = useConfirm();
  const classes = useStyles();

  const updateReuseState = (newCheckedState) => {
    if (newCheckedState) {
      handleChange('idToReuse', idToReuse);
    } else {
      handleChange('idToReuse', null);
    }
  };

  const onReuseDataClick = (newCheckedState) => {
    if (shouldWarn && newCheckedState) {
      confirm({
        title: 'Discard existing information?',
        description:
          'Any information that you have entered or saved will be discarded and replaced.',
        destructive: true,
        confirmationText: 'Discard and continue',
      })
        .then(() => {
          updateReuseState(newCheckedState);
        })
        .catch(() => {});
    } else {
      updateReuseState(newCheckedState);
    }
  };

  return (
    <div className={classes.root}>
      <Alert severity="info" skipTypography>
        <Typography>
          There is another{' '}
          <b>
            {name}
            {nodeType ? ` (${nodeType})` : ''}, {ownerType}
          </b>{' '}
          of <b>{parentName}</b>. Are they the same?
        </Typography>

        <FormControlLabel
          control={
            <Checkbox
              checked={reuseDataChecked}
              onChange={(e) => {
                onReuseDataClick(e.target.checked);
              }}
              name="reuseData"
              data-test="diligence_v2_reuse_data"
            />
          }
          label="Yes, use the same information here"
        />
      </Alert>
    </div>
  );
}
