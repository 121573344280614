import React from 'react';
import { Menu, MenuItem, Icons } from '@passthrough/uikit';

export function OfferingDocActionsMenu({
  document,
  anchorEl,
  menuOpen,
  handleClose,
  onDelete,
  onArchive,
  onUnarchive,
  onEdit,
}) {
  return (
    <Menu
      id="menu-offering-doc-actions"
      anchorEl={anchorEl}
      open={menuOpen}
      onClose={handleClose}
    >
      {!document.isArchived ? (
        <MenuItem
          onClick={() => onArchive(document)}
          text="Archive document"
          icon={<Icons.ArchiveOutlined fontSize="small" color="primary" />}
        />
      ) : (
        <MenuItem
          onClick={() => onUnarchive(document)}
          icon={<Icons.RestoreOutlined fontSize="small" color="primary" />}
          text="Restore document"
        />
      )}

      {onEdit ? (
        <MenuItem
          onClick={() => onEdit(document)}
          icon={<Icons.EditOutlined fontSize="small" color="primary" />}
          text="Rename"
        />
      ) : null}

      <MenuItem
        onClick={() => onDelete(document)}
        destructive
        icon={<Icons.DeleteOutline fontSize="small" />}
        text="Delete"
      />
    </Menu>
  );
}
