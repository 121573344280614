import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ANSWER_STATES } from 'services/constants';
import { FileLink } from './file_link';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '4px',
    wordBreak: 'break-all',
  },
}));

export function MultifileDisplay({
  answer,
  questionState = ANSWER_STATES.UNCHANGED,
}) {
  const classes = useStyles();

  if (!answer || answer.length === 0) {
    return null;
  }

  return (
    <div className={classes.root}>
      {answer.map((file) => (
        <FileLink key={file.fileId} file={file} fileState={questionState} />
      ))}
    </div>
  );
}
