import React from 'react';
import { Alert } from 'components/alert';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  alert: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export const FundClosingStatusAlerts = ({ fundClosingStatuses }) => {
  const classes = useStyles();

  return fundClosingStatuses.map((statusData) => (
    <React.Fragment key={statusData.closingName}>
      {statusData.missingQesRequestReview ? (
        <Alert severity="error" className={classes.alert}>
          <strong>{statusData.closingName}</strong> has Docusign QES enabled,
          but LP request review is disabled, which breaks the intended QES flow.
          Contact @eng-oncall
        </Alert>
      ) : null}

      {statusData.brokenForceOfflineSigning ? (
        <Alert severity="error" className={classes.alert}>
          <strong>{statusData.closingName}</strong> uses a questionnaire that
          can force offline signing, but has disabled offline signing at the
          fund closing level.
        </Alert>
      ) : null}
    </React.Fragment>
  ));
};
