import React from 'react';
import { Typography, Link, UIKitSettingsProvider } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import MessageIcon from '@material-ui/icons/MessageOutlined';

import { KeyValuePair, KeyValuePairs } from 'components/key_value';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from 'components/table/index';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
  docsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    rowGap: theme.spacing(1),
  },
  docLink: {
    margin: theme.spacing(0.5, 0),
  },
  messageContainer: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.neutral.fill,
  },
}));

export const ReviewStep = ({ message, investors }) => {
  const classes = useStyles();

  const plural = investors.length > 1 ? 's' : '';
  return (
    <div className={classes.root}>
      <KeyValuePairs>
        <Typography>
          The {`investor${plural}`} will receive all executed documents in this
          email.
        </Typography>

        <KeyValuePair
          icon={<MessageIcon />}
          label={
            <Typography
              variant={message ? 'label' : 'body'}
              size="small"
              color={message ? 'text.secondary' : ''}
            >
              {message
                ? 'This message will appear in the email to investors.'
                : 'No message'}
            </Typography>
          }
        >
          {message ? (
            <Paper elevation={0} className={classes.messageContainer}>
              <Typography multiline size="small">
                {message}
              </Typography>
            </Paper>
          ) : null}
        </KeyValuePair>
      </KeyValuePairs>

      <Table noTopMargin>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="label" size="small">
                Investor
              </Typography>
            </TableCell>

            <TableCell>
              <Typography variant="label" size="small">
                Documents
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {investors.map((investor) => (
            <TableRow key={investor.id}>
              <TableCell scope="row">
                <Typography>{investor.lpName}</Typography>
              </TableCell>

              <TableCell>
                <UIKitSettingsProvider
                  linkComponent="a"
                  redirectAttribute="href"
                >
                  <div className={classes.docsContainer}>
                    {investor.docs
                      .filter(
                        (doc) =>
                          !investor.documentTypesToExclude.includes(
                            doc.doctype,
                          ),
                      )
                      .map((doc) => (
                        <Link variant="external" key={doc.id} href={doc.url}>
                          {doc.name}
                        </Link>
                      ))}
                  </div>
                </UIKitSettingsProvider>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
