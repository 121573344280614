import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Fade from '@material-ui/core/Fade';

import { Typography } from '@passthrough/uikit';
import { PaperHeader } from 'components/paper_header';
import { FIRST_SUBMISSION, JOINT_SIGNER, REVIEW_REQUESTED } from './constants';
import { ConfettiPlayer } from './confetti_player';
import { ExperienceReviewForm } from '../experience_review/experience_review_form';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  messagePadding: {
    padding: theme.spacing(2, 3),
  },
  bottomSpacing: {
    paddingBottom: theme.spacing(6),
  },
  spacing: {
    paddingTop: theme.spacing(8),
  },
  experienceForm: {
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(9),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

export function BaseAllDoneDisplay({
  fundName,
  customCompletionText,
  submitExperienceReview,
  enableReviewForm,
  TimelineComponent,
  step = FIRST_SUBMISSION,
}) {
  const classes = useStyles();
  const showConfetti = [FIRST_SUBMISSION, JOINT_SIGNER].includes(step);
  const [showTimeline, setShowTimeline] = useState(!showConfetti);

  const timeout = step === REVIEW_REQUESTED ? 100 : 300;

  return (
    <div className={classes.root}>
      {showConfetti ? (
        <ConfettiPlayer onAnimationComplete={() => setShowTimeline(true)} />
      ) : null}

      <Fade in={showTimeline} timeout={timeout}>
        <div className={classes.container}>
          <Container
            maxWidth="sm"
            className={clsx(classes.bottomSpacing, {
              [classes.spacing]: step === REVIEW_REQUESTED,
            })}
          >
            {customCompletionText ? (
              <PaperHeader headerText={`Message from ${fundName}:`}>
                <div className={classes.messagePadding}>
                  <Typography multiline align="left">
                    {customCompletionText}
                  </Typography>
                </div>
              </PaperHeader>
            ) : null}

            {TimelineComponent}
          </Container>

          {enableReviewForm ? (
            <div className={classes.experienceForm}>
              <ExperienceReviewForm
                submitExperienceReview={submitExperienceReview}
              />
            </div>
          ) : null}
        </div>
      </Fade>
    </div>
  );
}
