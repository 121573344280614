import React, { useEffect } from 'react';
import {
  getDetailsIcon,
  getDocumentsIcon,
  getOwnersIcon,
  getFirstIncompleteSubsection,
  goToSubsection,
  getOwnerFields,
  getDocumentFields,
  findOriginalQuestionDataPerCleanedName,
  preorderDiligenceAnswerTreeTraversal,
  questionHasAdditionalDataThatWillBeLost,
} from '../utils';

import { DetailsSubsection } from './details_subsection/index';
import { DocumentsSubsection } from './documents_subsection/index';
import { OwnersSubsection } from './owners_subsection/index';

import { ReuseInvestorData } from './reuse_investor_data';
import { getQuestionsAsNodes } from '../tree_nav_v2/node';

export function SubsectionQuestionDisplay({
  lpClosingId,
  fundName,
  form,
  formErrors,
  jurisdiction,
  hideOptOut,
  label,
  fullAnswerData,
  handleChange,
  isNested,
  diligenceQuestions,
  detailsExpanded,
  documentsExpanded,
  ownersExpanded,
  goToDetails,
  goToDocuments,
  goToOwners,
  clickDetails,
  clickDocuments,
  clickOwners,
  changeType,
  QuestionPromptComponent,
  preventLegalNameChange,
  toLegalName,
}) {
  const docFields = getDocumentFields(form.type, jurisdiction, !isNested);
  const ownerFields = getOwnerFields(form.type, jurisdiction);

  const hasDocs = docFields.length > 0;
  const hasOwners = ownerFields.length > 0;

  const cleanedName = form?.name?.toLowerCase();

  const structuredNodesRoot = getQuestionsAsNodes(
    diligenceQuestions[0],
    diligenceQuestions,
    jurisdiction,
  );

  const preorderDiligenceIds = preorderDiligenceAnswerTreeTraversal(
    diligenceQuestions,
    jurisdiction,
  );
  const originalQuestionDataPerCleanedName =
    findOriginalQuestionDataPerCleanedName(
      preorderDiligenceIds,
      diligenceQuestions,
      label,
      cleanedName,
    );

  const cleanedLabelNode = structuredNodesRoot?.findNodeByLabel(
    originalQuestionDataPerCleanedName[cleanedName]?.label,
  );

  function findOriginalNode(idToReuse) {
    const node = structuredNodesRoot?.findNodeByLabel(idToReuse);
    if (node?.question?.answer?.idToReuse) {
      return findOriginalNode(node.idToReuse);
    }
    return node;
  }

  const originalNode = findOriginalNode(form?.idToReuse) || cleanedLabelNode;

  const originalNodeDescendants = originalNode?.getAllChildren() || [];
  const currNodeIsDescendantOfOriginalNode = originalNodeDescendants.some(
    (descendantNode) => descendantNode?.question?.label === label,
  );

  const shouldExposeReuseDataButton =
    Boolean(form?.idToReuse) ||
    Boolean(
      originalNode &&
        originalQuestionDataPerCleanedName[cleanedName]?.label &&
        originalQuestionDataPerCleanedName[cleanedName].label !== label &&
        !currNodeIsDescendantOfOriginalNode,
    );

  const goToFirstIncompleteSubsection = () => {
    const subsection = getFirstIncompleteSubsection(
      fullAnswerData,
      jurisdiction,
      !isNested,
    );
    goToSubsection(subsection, goToDetails, goToDocuments, goToOwners);
  };
  useEffect(goToFirstIncompleteSubsection, [fullAnswerData.type]);

  const selfHasAdditionalDataThatWillBeLost =
    questionHasAdditionalDataThatWillBeLost(diligenceQuestions, label);

  const [deletedNames, setDeletedNames] = React.useState([]);

  return (
    <>
      <QuestionPromptComponent short />

      {shouldExposeReuseDataButton ? (
        <ReuseInvestorData
          // original question may have differing capitalization from
          // the current question's name value
          name={originalNode?.question?.answer?.name}
          idToReuse={originalNode?.question?.label}
          parentName={originalNode.parentName}
          nodeType={originalNode.question?.answer?.type}
          ownerType={originalNode.childLabel}
          reuseDataChecked={form.idToReuse}
          handleChange={handleChange}
          shouldWarn={selfHasAdditionalDataThatWillBeLost}
        />
      ) : null}

      <DetailsSubsection
        isRootNode={!isNested}
        form={form}
        formErrors={formErrors}
        jurisdiction={jurisdiction}
        handleChange={handleChange}
        changeType={changeType}
        expanded={detailsExpanded}
        statusIcon={getDetailsIcon(
          fullAnswerData,
          jurisdiction,
          !isNested,
          formErrors,
        )}
        onClick={clickDetails}
        showVerticalBar={(hasDocs || hasOwners) && !form.idToReuse}
        reuseDataChecked={form.idToReuse}
        preventLegalNameChange={preventLegalNameChange}
        toLegalName={toLegalName}
      />

      <OwnersSubsection
        form={form}
        formErrors={formErrors}
        label={label}
        ownerFields={ownerFields}
        handleChange={handleChange}
        expanded={ownersExpanded}
        onClick={clickOwners}
        jurisdiction={jurisdiction}
        statusIcon={getOwnersIcon(
          fullAnswerData,
          form.type,
          jurisdiction,
          formErrors,
        )}
        diligenceQuestions={diligenceQuestions}
        reuseDataChecked={form.idToReuse}
        showVerticalBar={hasDocs}
        deletedNames={deletedNames}
        setDeletedNames={setDeletedNames}
      />

      <DocumentsSubsection
        lpClosingId={lpClosingId}
        fundName={fundName}
        form={form}
        hideOptOut={hideOptOut}
        formErrors={formErrors}
        docFields={docFields}
        handleChange={handleChange}
        expanded={documentsExpanded}
        onClick={clickDocuments}
        statusIcon={getDocumentsIcon(
          fullAnswerData,
          form.type,
          jurisdiction,
          !isNested,
          formErrors,
        )}
        jurisdiction={jurisdiction}
        reuseDataChecked={form.idToReuse}
      />
    </>
  );
}
