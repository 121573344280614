import React from 'react';
import { Typography } from '@passthrough/uikit';
import AddIcon from '@material-ui/icons/Add';

import { EmptyState } from 'components/empty_v2';
import { useInvestorTaggingModalState } from './providers/state_provider';
import { INVESTOR_TAGGING_REDUCER_ACTIONS } from './constants';
import { GenericTagRow } from '../lp_filters_popover/generic_investor_tag_group';

export function TagInvestorModalContents({ investorTagGroups }) {
  const { modalStage, tagsToApply, tagsToRetain, dispatch } =
    useInvestorTaggingModalState();
  const hasNoTags = !investorTagGroups || investorTagGroups?.length === 0;

  const checkedTags = [...tagsToApply, ...tagsToRetain];

  return (
    <>
      {hasNoTags ? (
        <EmptyState
          title="No tags created"
          text="Tags are shared within the fund. Anyone with access to the fund can manage the same tags."
          ctaPermission
          ctaText="Create tag"
          ctaOnClick={() => {
            dispatch({
              type: INVESTOR_TAGGING_REDUCER_ACTIONS.CREATE_TAG,
              currModalStage: modalStage,
            });
          }}
          ctaIcon={<AddIcon />}
        />
      ) : null}

      {investorTagGroups.map((tagGroup) => (
        <div key={tagGroup.id}>
          <Typography variant="label">{tagGroup.name}</Typography>

          {tagGroup.tags.map((tag) => (
            <GenericTagRow
              key={tag.id}
              value={tag.name}
              isChecked={checkedTags?.some(
                (selectedTag) => selectedTag.id === tag.id,
              )}
              indeterminate={tagsToRetain?.some((t) => t.id === tag.id)}
              onChange={(e) => {
                dispatch({
                  type: INVESTOR_TAGGING_REDUCER_ACTIONS.UPDATE_CHECKED_TAGS,
                  selected: e.target.checked,
                  selectedTag: tag,
                });
              }}
            />
          ))}
        </div>
      ))}
    </>
  );
}
