export const FILTERS = {
  NEEDS_ATTENTION: 'Needs attention',
  ALL: 'All questions',
  TAGGED: 'Tagged',
  MAPPED: 'Mapped',
  SAME_TEXT: 'Same text',
  IGNORED: 'Ignored',
  NOT_INTEROPERABLE: 'Not interoperable',
  NO_MATCHES: 'No matches',
};

export const CARD_LABEL = {
  [FILTERS.ALL]: 'are reusable',
  [FILTERS.NEEDS_ATTENTION]: 'needs attention',
  [FILTERS.TAGGED]: 'are tagged',
  [FILTERS.MAPPED]: 'are mapped',
  [FILTERS.SAME_TEXT]: 'are exactly the same',
  [FILTERS.IGNORED]: 'were ignored',
  [FILTERS.NOT_INTEROPERABLE]: 'are not interoperable',
  [FILTERS.NO_MATCHES]: 'have no matches',
};

export const NOT_REUSABLE = [FILTERS.IGNORED, FILTERS.NOT_INTEROPERABLE];
