import React from 'react';

import { GenericAnswerDisplay } from './generic_display';
import { formatAddress } from './utils';

export function AddressDisplay({ answer, flatten, questionState }) {
  return (
    <GenericAnswerDisplay
      answer={answer}
      flatten={flatten}
      questionState={questionState}
      transformAnswer={(a) => formatAddress(a)}
    />
  );
}
