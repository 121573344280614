import React from 'react';
import { makeStyles } from '@material-ui/core';

import { ANSWER_STATES } from 'services/constants';
import { DiffStyle } from 'components/Diff';
import { MAX_NUM_CHARS } from './constants';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export function NameTitleEmailDisplay({ answer, flatten, questionState }) {
  const classes = useStyles();
  if (!answer) {
    return null;
  }

  if (flatten) {
    const formattedAnswer = `${answer.firstName} ${answer.lastName}, ${answer.title}, ${answer.email}`;

    const numCharsUsed =
      answer.firstName.length +
      answer.lastName.length +
      answer.title.length +
      answer.email.length;
    return numCharsUsed <= MAX_NUM_CHARS
      ? formattedAnswer
      : `${formattedAnswer.substring(0, MAX_NUM_CHARS)}...`;
  }

  return (
    <span className={classes.root}>
      <DiffStyle
        isNew={questionState === ANSWER_STATES.CHANGED_CURRENT_ANSWER}
        isDeleted={questionState === ANSWER_STATES.CHANGED_PRIOR_ANSWER}
      >
        {answer.firstName} {answer.lastName}
      </DiffStyle>
      <DiffStyle
        isNew={questionState === ANSWER_STATES.CHANGED_CURRENT_ANSWER}
        isDeleted={questionState === ANSWER_STATES.CHANGED_PRIOR_ANSWER}
      >
        {answer.title}
      </DiffStyle>
      <DiffStyle
        isNew={questionState === ANSWER_STATES.CHANGED_CURRENT_ANSWER}
        isDeleted={questionState === ANSWER_STATES.CHANGED_PRIOR_ANSWER}
      >
        {answer.email}
      </DiffStyle>
    </span>
  );
}
