import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import { DialogContent, DialogTitle } from 'components/dialog/index';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogContent: {
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 5),
    position: 'relative',
  },
}));

export const DialogStep = ({ title, children, onClose, onBack }) => {
  const classes = useStyles();
  return (
    <>
      <DialogTitle disableTypography id="form-dialog-title">
        <div className={classes.dialogTitle}>
          <div style={{ visibility: onBack ? '' : 'hidden' }}>
            <IconButton aria-label="back" onClick={onBack}>
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div>
            <Typography variant="h6">{title}</Typography>
          </div>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>
    </>
  );
};
