import React from 'react';

import TextField from '@material-ui/core/TextField';
import { Address } from 'components/address_v2';

export function SeiListedCompanyDetails({
  tinEin,
  tinEinError,
  hqAddress,
  hqAddressError,
  ticker,
  tickerError,
  handleChange,
}) {
  return (
    <>
      <TextField
        data-test="tin/ein-input"
        label="TIN/EIN"
        variant="outlined"
        value={tinEin}
        error={!!tinEinError}
        helperText={tinEinError}
        onChange={(e) => {
          handleChange('tinEin', e.target.value);
        }}
        fullWidth
      />

      <TextField
        label="Ticker"
        variant="outlined"
        value={ticker}
        error={!!tickerError}
        helperText={tinEinError}
        onChange={(e) => {
          handleChange('ticker', e.target.value);
        }}
        fullWidth
      />

      <Address
        address={hqAddress}
        onChange={(addr) => {
          handleChange('hqAddress', addr);
        }}
        errors={hqAddressError}
        label="Headquarters address"
      />
    </>
  );
}
