import React from 'react';
import MUIPaper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
  },
}));

export const Paper = ({ children }) => {
  const classes = useStyles();
  return (
    <MUIPaper variant="outlined" className={classes.paper}>
      {children}
    </MUIPaper>
  );
};
