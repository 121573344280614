import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import PDFObject from 'pdfobject';

import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import { Button } from 'components/button';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';
import { Typography } from '@material-ui/core';

import { MobilePdfDisplay } from 'components/mobile_pdf_display';
import { Dialog, DialogActions, DialogContent } from 'components/dialog/index';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    overflowY: 'auto',
    display: 'flex',
    padding: theme.spacing(2, 3),
  },
  dialogContentMobile: {
    padding: theme.spacing(2),
  },
  nameAndActions: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(1),
  },
  dialogActionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
  },
  dialogActionsContainerMobile: {
    alignItems: 'start',
    padding: theme.spacing(2),
  },
  pdfRoot: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  iframe: {
    flexGrow: 1,
    width: '100%',
  },
}));

const usePdfViewerStyles = makeStyles(() => ({
  paper: {
    height: '100%',
    width: '100%',
  },
}));

function PDFDialogContent({
  downloadable,
  fillable,
  fileUrl,
  filledFileUrl,
  fileName,
  docDisplayName,
  onClose,
  initialPage,
  onMobileBrowser,
}) {
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [showFilled, setShowFilled] = useState(true);
  const [currentFileUrl, setFileUrl] = useState(
    showFilled ? filledFileUrl : fileUrl,
  );

  const classes = useStyles();

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    const { current: descriptionElement } = descriptionElementRef;
    if (descriptionElement !== null) {
      descriptionElement.focus();
    }
  }, []);

  useEffect(() => {
    const newUrl = showFilled ? filledFileUrl : fileUrl;
    setFileUrl(`${newUrl}#page=${initialPage}`);
  }, [showFilled, initialPage]);

  function handleDownload() {
    const link = document.createElement('a');
    link.href = `${currentFileUrl.split('#')[0]}?download=true`;
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click'));
    setDownloadLoading(true);
    setTimeout(() => {
      setDownloadLoading(false);
    }, 3000);
  }

  return (
    <>
      <DialogActions
        className={clsx(classes.dialogActionsContainer, {
          [classes.dialogActionsContainerMobile]: onMobileBrowser,
        })}
      >
        <div className={classes.nameAndActions}>
          {onMobileBrowser ? (
            <Typography variant="body2">{docDisplayName}</Typography>
          ) : null}

          <div>
            {fillable ? (
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={showFilled}
                    onChange={() => {
                      setShowFilled((v) => !v);
                    }}
                  />
                }
                label="Fill with answers"
              />
            ) : null}

            {downloadable ? (
              <Button
                onClick={handleDownload}
                startIcon={<GetAppIcon />}
                loading={downloadLoading}
              >
                Download
              </Button>
            ) : (
              <span />
            )}
          </div>
        </div>

        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogActions>

      <DialogContent
        dividers
        className={clsx(classes.dialogContent, {
          [classes.dialogContentMobile]: onMobileBrowser,
        })}
      >
        {onMobileBrowser || !downloadable ? (
          <MobilePdfDisplay
            file={currentFileUrl}
            initialPage={initialPage}
            initialZoom={onMobileBrowser ? 0.7 : 1}
          />
        ) : (
          <div className={classes.pdfRoot}>
            <iframe
              src={currentFileUrl}
              className={classes.iframe}
              title="lp-document-display"
              sandbox
            />
          </div>
        )}
      </DialogContent>
    </>
  );
}

export function PdfViewer({
  open,
  handleClose,
  downloadable,
  fillable,
  fileUrl,
  filledFileUrl,
  fileName,
  docDisplayName,
  initialPage,
}) {
  const pdfViewerClasses = usePdfViewerStyles();

  // some mobile browsers like safari return that pdf viewer is enabled
  // although the feature does not work, rely on this comprehensive check
  const onMobileBrowser = !PDFObject.supportsPDFs;

  return (
    <Dialog
      open={open}
      fullScreen={onMobileBrowser}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      maxWidth="lg"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      classes={{ paper: pdfViewerClasses.paper }}
    >
      {open && (
        <PDFDialogContent
          downloadable={downloadable}
          fillable={fillable}
          fileUrl={fileUrl}
          filledFileUrl={filledFileUrl}
          fileName={fileName}
          docDisplayName={docDisplayName}
          onClose={handleClose}
          initialPage={initialPage}
          onMobileBrowser={onMobileBrowser}
        />
      )}
    </Dialog>
  );
}
