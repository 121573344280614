import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  wavesPosition: {
    bottom: '0',
    left: '0',
    position: 'fixed',
    width: '100%',
    zIndex: '0',
  },
}));

export function BgWaves() {
  const classes = useStyles();
  return (
    <svg
      className={classes.wavesPosition}
      viewBox="0 0 1440 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMin"
    >
      <path
        d="M578.344 180.396C873.478 180.396 934.013 125.614 1151.59 125.614C1369.17 125.614 1440 183.885 1440 183.885L1440 298.334L9.59892e-05 298.333L0.00012207 3.81092e-06C69.809 60.1319 283.211 180.396 578.344 180.396Z"
        fill="#e6f9ff"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        opacity="0.1"
        d="M1034.58 147.5C1274.77 147.5 1404.94 194.426 1440 217.889L1440 298.334L0 298.333L6.00708e-06 229.62C304.064 229.621 734.343 147.5 1034.58 147.5Z"
        fill="#8060FF"
      />
    </svg>
  );
}
