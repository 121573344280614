import React, { useMemo } from 'react';
import { Modal, Button } from '@passthrough/uikit';
import SettingsIcon from '@material-ui/icons/Settings';

import { useInvestorTaggingModalState } from './providers/state_provider';
import { useInvestorTaggingMutations } from './providers/mutations_provider';
import {
  INVESTOR_TAGGING_REDUCER_ACTIONS,
  TAGGING_MODAL_STAGES,
} from './constants';

import { TagInvestorModalContents } from './tag_investors_contents';
import { ManageTagsContents } from './manage_tag_contents';
import { CreateEditTagModalContents } from './create_tag_contents';
import { EditTagGroupModalContents } from './edit_tag_group_contents';
import {
  getModalHeader,
  getModalSubheader,
  getPrimaryBtnText,
  countTagUsagesAcrossInvestors,
  aggregateInvestorTags,
  areSetsEqual,
} from './utils';

export function InvestorTaggingModalRoot({
  open,
  selectedInvestors,
  investorTagGroups,
  handleClose,
}) {
  const {
    modalStage,
    tagsToApply,
    tagsToRetain,
    selectedTag,
    selectedTagGroup,
    newTagName,
    newTagGroupName,
    prevTagModal,
    dispatch,
  } = useInvestorTaggingModalState();

  const {
    loading,
    resetLoadingState,
    handleApplyTags,
    handleCreateTag,
    handleEditTagGroup,
    handleEditTag,
  } = useInvestorTaggingMutations();
  const [tagsUsedByAllInvestors, tagsUsedBySomeInvestors] = useMemo(() => {
    const tagUsageCounts = countTagUsagesAcrossInvestors(selectedInvestors);
    const allTags = aggregateInvestorTags(investorTagGroups ?? []);

    const tagsUsedByAll = allTags.filter(
      (t) => tagUsageCounts[t.id] === selectedInvestors.length,
    );
    const tagsUsedBySome = allTags.filter(
      (t) => tagUsageCounts[t.id] < selectedInvestors.length,
    );

    return [tagsUsedByAll, tagsUsedBySome];
  }, [selectedInvestors, investorTagGroups]);

  const fundHasTags = investorTagGroups?.length > 0;
  const showManageTagsBtn =
    fundHasTags && modalStage === TAGGING_MODAL_STAGES.APPLY_TAGS;

  const hideFooter =
    modalStage === TAGGING_MODAL_STAGES.MANAGE_TAGS ||
    (!fundHasTags && modalStage === TAGGING_MODAL_STAGES.APPLY_TAGS);

  function haveCheckedTagsChanged() {
    const tagsToApplyIdSet = new Set(tagsToApply.map((t) => t.id));
    const tagsToRetainIdSet = new Set(tagsToRetain.map((t) => t.id));

    const tagIdsUsedByAllSet = new Set(tagsUsedByAllInvestors.map((t) => t.id));
    const tagIdsUsedBySomeSet = new Set(
      tagsUsedBySomeInvestors.map((t) => t.id),
    );

    return (
      areSetsEqual(tagsToApplyIdSet, tagIdsUsedByAllSet) &&
      areSetsEqual(tagsToRetainIdSet, tagIdsUsedBySomeSet)
    );
  }

  function getPrimaryBtnDisabledStatus() {
    const tagNameIsInvalid = !newTagGroupName || newTagGroupName?.trim() === '';
    const tagDataIsInvalid =
      !newTagName || newTagName?.trim() === '' || tagNameIsInvalid;

    const tagGroupIsUnchanged = selectedTagGroup?.name === newTagGroupName;
    const tagDataIsUnchanged =
      selectedTag?.name === newTagName &&
      selectedTag?.groupName === newTagGroupName;

    switch (modalStage) {
      case TAGGING_MODAL_STAGES.APPLY_TAGS:
        return haveCheckedTagsChanged();
      case TAGGING_MODAL_STAGES.CREATE_TAG:
        return tagDataIsInvalid;
      case TAGGING_MODAL_STAGES.EDIT_TAG:
        return tagDataIsInvalid || tagDataIsUnchanged;
      case TAGGING_MODAL_STAGES.EDIT_TAG_GROUP:
        return tagNameIsInvalid || tagGroupIsUnchanged;
      default:
        return true;
    }
  }

  function getBackBtnFunc() {
    switch (modalStage) {
      case TAGGING_MODAL_STAGES.APPLY_TAGS:
        return null;
      case TAGGING_MODAL_STAGES.MANAGE_TAGS:
        return () => {
          dispatch({
            type: INVESTOR_TAGGING_REDUCER_ACTIONS.MOVE_BACKWARDS,
            modalStage: TAGGING_MODAL_STAGES.APPLY_TAGS,
          });
        };
      case TAGGING_MODAL_STAGES.CREATE_TAG:
        return () => {
          resetLoadingState();
          dispatch({
            type: INVESTOR_TAGGING_REDUCER_ACTIONS.MOVE_BACKWARDS,
            modalStage: prevTagModal,
          });
        };
      case TAGGING_MODAL_STAGES.EDIT_TAG:
      case TAGGING_MODAL_STAGES.EDIT_TAG_GROUP:
        return () => {
          resetLoadingState();
          dispatch({
            type: INVESTOR_TAGGING_REDUCER_ACTIONS.MOVE_BACKWARDS,
            modalStage: TAGGING_MODAL_STAGES.MANAGE_TAGS,
          });
        };
      default:
        return '';
    }
  }

  function getSubmitAction() {
    switch (modalStage) {
      case TAGGING_MODAL_STAGES.APPLY_TAGS:
        return () => handleApplyTags(selectedInvestors);
      case TAGGING_MODAL_STAGES.CREATE_TAG:
        return handleCreateTag;
      case TAGGING_MODAL_STAGES.EDIT_TAG_GROUP:
        return handleEditTagGroup;
      case TAGGING_MODAL_STAGES.EDIT_TAG:
        return handleEditTag;
      default:
        return null;
    }
  }

  return (
    <Modal
      open={open}
      size="xs"
      onClose={handleClose}
      onBack={getBackBtnFunc()}
      headerLabel={getModalHeader(modalStage)}
      subheaderLabel={getModalSubheader(
        modalStage,
        selectedInvestors?.length ?? 0,
      )}
      primaryButtonText={getPrimaryBtnText(modalStage)}
      onSubmit={getSubmitAction()}
      primaryButtonLoading={loading}
      primaryButtonDisabled={getPrimaryBtnDisabledStatus()}
      // relies on undefined equality check hack to hide footer
      footer={hideFooter ? null : undefined}
      onEntering={() => {
        dispatch({
          type: INVESTOR_TAGGING_REDUCER_ACTIONS.HANDLE_OPEN,
          tagsUsedByAllInvestors,
          tagsUsedBySomeInvestors,
        });
      }}
      onExited={() => {
        dispatch({
          type: INVESTOR_TAGGING_REDUCER_ACTIONS.HANDLE_CLOSE,
        });
      }}
      tertiaryButton={
        showManageTagsBtn ? (
          <Button
            variant="text"
            startIcon={<SettingsIcon />}
            onClick={() => {
              dispatch({
                type: INVESTOR_TAGGING_REDUCER_ACTIONS.MANAGE_TAGS,
              });
            }}
          >
            Manage tags
          </Button>
        ) : null
      }
    >
      {modalStage === TAGGING_MODAL_STAGES.APPLY_TAGS ? (
        <TagInvestorModalContents investorTagGroups={investorTagGroups} />
      ) : null}

      {modalStage === TAGGING_MODAL_STAGES.MANAGE_TAGS ? (
        <ManageTagsContents investorTagGroups={investorTagGroups} />
      ) : null}

      {modalStage === TAGGING_MODAL_STAGES.CREATE_TAG ||
      modalStage === TAGGING_MODAL_STAGES.EDIT_TAG ? (
        <CreateEditTagModalContents investorTagGroups={investorTagGroups} />
      ) : null}

      {modalStage === TAGGING_MODAL_STAGES.EDIT_TAG_GROUP ? (
        <EditTagGroupModalContents />
      ) : null}
    </Modal>
  );
}
