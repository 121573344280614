import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { Alert } from 'components/alert';
import { Button } from 'components/button';

const useStyles = makeStyles((theme) => ({
  alert: {
    alignItems: 'center',
  },
  alertText: {
    margin: theme.spacing(1, 0),
  },
  root: {
    marginBottom: theme.spacing(2),
  },
}));

export const UndoBanner = ({ undoConfirmation, alertText }) => {
  const classes = useStyles();

  return (
    <Alert
      severity="success"
      className={clsx(classes.alert, classes.root)}
      action={
        <Button variant="text" onClick={undoConfirmation}>
          Undo
        </Button>
      }
    >
      <div key="text" className={classes.alertText}>
        {alertText}
      </div>
    </Alert>
  );
};
