import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import { Modal } from '@passthrough/uikit';
import { getSingleErrorFromResponse } from 'services/utils';
import { useToast } from 'services/toast';
import * as api from 'services/api';

export const OfferingDocEditDialog = ({
  fundId,
  document,
  onClose,
  onUpdate,
}) => {
  const open = Boolean(document);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [name, setName] = React.useState(document?.name);
  const { toast } = useToast();
  const changed = name !== document?.name;
  const handleSubmit = () => {
    setLoading(true);
    api
      .offeringDocUpdate({ fundId, offeringDocId: document.id, name })
      .then(() => {
        onUpdate();
        onClose();
        setLoading(false);
        toast(`Renamed ${name}`);
      })
      .catch((e) => {
        setError(getSingleErrorFromResponse(e.response));
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (open) {
      setName(document?.name || '');
    }
  }, [document?.id]);

  return (
    <Modal
      headerLabel="Rename document"
      open={open}
      onClose={onClose}
      size="xs"
      primaryButtonText="Save"
      primaryButtonLoading={loading}
      primaryButtonDisabled={!changed || !name}
      onSubmit={handleSubmit}
      showCancelButton
    >
      <FormControl error={!!error} component="fieldset" fullWidth>
        <TextField
          id="name"
          variant="outlined"
          label="Document name"
          error={!!error}
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
        />
        {error ? <FormHelperText>{error}</FormHelperText> : null}
      </FormControl>
    </Modal>
  );
};
