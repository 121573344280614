import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'components/button';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { useReconciliation } from '../provider';
import { UNSAVED } from '../../saved';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      alignItems: 'inherit',
    },
  },
}));

export const ActionsSubmit = ({
  navRef,
  onContinue,
  isContinueDisabled,
  answer,
  updateLpDoc,
  label,
  setSaving,
  setErrorMsg,
}) => {
  const classes = useStyles();
  const nav = navRef.current;
  const showPrevButton = nav.innerStep > 0 || nav.outerStep > 0;
  const { setShowReconciliationPage, resetReconciliationAnswer } =
    useReconciliation();

  const handleBack = () => {
    setShowReconciliationPage(false);
    resetReconciliationAnswer();
    nav.decrement();
  };

  const handleContinue = () => {
    updateLpDoc({
      label,
      answer,
      skipQuestion: false,
    })
      .catch((error) => {
        setSaving(UNSAVED);
        if (error.response?.status === 400) {
          setErrorMsg(error.response.data.answer);
        }
      })
      .then(() => {
        const nextQuestion = navRef.current.getNextRequiredQuestion();
        setShowReconciliationPage(
          Boolean(nextQuestion?.answersToReconcile?.length),
        );
        resetReconciliationAnswer();
        navRef.current.toNextRequired();
      });
  };

  return (
    <div className={classes.root}>
      <div key="back">
        <Button
          name="back"
          style={{ visibility: showPrevButton ? '' : 'hidden' }}
          variant="text"
          color="default"
          size="xl"
          fullWidth
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={handleBack}
        >
          Back
        </Button>
      </div>
      <div className={classes.rightSide}>
        <Button
          id="continue"
          size="xl"
          fullWidth
          type="submit"
          variant="contained"
          disabled={isContinueDisabled}
          onClick={onContinue || handleContinue}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
