import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'components/button';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { useReconciliation } from '../provider';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      alignItems: 'inherit',
    },
  },
}));

export const Actions = ({
  navRef,
  onContinue,
  isContinueDisabled,
  answer,
  showSkip,
}) => {
  const classes = useStyles();
  const nav = navRef.current;
  const showPrevButton = nav.innerStep > 0 || nav.outerStep > 0;
  const {
    setShowReconciliationPage,
    resetReconciliationAnswer,
    setReconciliationAnswer,
  } = useReconciliation();

  const handleBack = () => {
    setShowReconciliationPage(false);
    resetReconciliationAnswer();
    nav.decrement();
  };

  const handleContinue = () => {
    if (onContinue) {
      onContinue().then(() => {
        setShowReconciliationPage(false);
      });
    } else {
      setReconciliationAnswer(answer);
      setShowReconciliationPage(false);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const isAnswerEmpty = answer === null || answer?.length === 0;

  return (
    <div className={classes.root}>
      <div key="back">
        <Button
          name="back"
          style={{ visibility: showPrevButton ? '' : 'hidden' }}
          variant="text"
          color="default"
          size="xl"
          fullWidth
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={handleBack}
        >
          Back
        </Button>
      </div>
      <div className={classes.rightSide}>
        <Button
          id="continue"
          size="xl"
          fullWidth
          type="submit"
          variant="contained"
          disabled={isContinueDisabled}
          onClick={onContinue || handleContinue}
        >
          {showSkip && isAnswerEmpty ? 'Skip' : 'Continue'}
        </Button>
      </div>
    </div>
  );
};
