import React from 'react';

import { ANSWER_STATES } from 'services/constants';
import { getCountry } from 'services/countries';
import { DiffStyle } from 'components/Diff';
import { formatAddress } from './utils';
import { MAX_NUM_CHARS } from './constants';

const ORDERED_NON_NAME_KEYS = [
  'name',
  'phone',
  'email',
  'dateOfBirth',
  'placeOfBirth',
  'nationality',
  'fax',
  'manualAddress',
  'googleAddress',
];

function transformContactAnswers(contact) {
  const answers = {};

  for (let i = 0; i < ORDERED_NON_NAME_KEYS.length; i += 1) {
    const key = ORDERED_NON_NAME_KEYS[i];
    const rawAnswer = contact[key];

    if (key.endsWith('Address') && rawAnswer) {
      answers[key] = formatAddress(rawAnswer);
    } else if (key === 'nationality' && rawAnswer) {
      answers[key] = getCountry(rawAnswer);
    } else if (key === 'name') {
      // first and last name are always required
      answers[key] = `${contact.firstName} ${contact.lastName}`;
    } else {
      answers[key] = rawAnswer;
    }
  }

  return answers;
}

export function ContactDisplay({ answer, questionState, flatten }) {
  if (!answer) {
    return null;
  }

  const transformedAnswers = transformContactAnswers(answer);

  if (flatten) {
    const subAnswersToDisplay = Object.values(transformedAnswers).filter(
      (a) => a,
    );

    const numChars = subAnswersToDisplay.reduce(
      (prevCount, subAnswer) => prevCount + `${subAnswer}`.length,
      0,
    );

    const joinedAnswers = subAnswersToDisplay.join(', ');
    const truncatedAnswer = joinedAnswers.substring(0, MAX_NUM_CHARS);
    return numChars <= MAX_NUM_CHARS ? joinedAnswers : `${truncatedAnswer}...`;
  }

  return (
    <div>
      {ORDERED_NON_NAME_KEYS.map((field) => {
        const currAnswer = transformedAnswers[field];
        if (!currAnswer) {
          return null;
        }

        return (
          <div key={field}>
            <DiffStyle
              isNew={questionState === ANSWER_STATES.CHANGED_CURRENT_ANSWER}
              isDeleted={questionState === ANSWER_STATES.CHANGED_PRIOR_ANSWER}
            >
              {currAnswer}
            </DiffStyle>
          </div>
        );
      })}
    </div>
  );
}
