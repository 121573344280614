// cspell:words handleflip
import React, { useState } from 'react';
import { Typography, Button } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import PencilIcon from 'mdi-material-ui/PencilOutline';

import { SensitiveData } from 'components/answer_displays/sensitive_data';
import { SSNInput } from 'components/ssn';
import { DisplayLink } from 'components/link';

const useStyles = makeStyles((theme) => ({
  hiddenTaxContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  whiteSensitiveData: {
    backgroundColor: theme.palette.common.white,
  },
}));

function DiligenceSSNInput({
  ssn,
  ssnError,
  onChange,
  onFlipType,
  disableFlip,
}) {
  return (
    <>
      <TextField
        id="ssn"
        label="Social Security Number (SSN)"
        variant="outlined"
        value={ssn}
        error={!!ssnError}
        helperText={ssnError}
        onChange={(e) => {
          onChange('ssn', e.target.value);
        }}
        InputProps={{
          inputComponent: SSNInput,
        }}
        fullWidth
      />
      {disableFlip ? null : (
        <FormHelperText>
          <DisplayLink
            onClick={() => {
              onFlipType(false);
            }}
          >
            I don't have an SSN ›
          </DisplayLink>
        </FormHelperText>
      )}
    </>
  );
}

function DiligenceTINInput({
  tin,
  tinError,
  onChange,
  onFlipType,
  disableFlip,
  taxIDLabel = 'Other tax ID',
}) {
  return (
    <>
      <TextField
        id="ssn"
        label={taxIDLabel}
        variant="outlined"
        value={tin}
        error={!!tinError}
        helperText={tinError}
        onChange={(e) => {
          onChange('ssn', e.target.value);
        }}
        fullWidth
      />
      {disableFlip ? null : (
        <FormHelperText>
          <DisplayLink
            onClick={() => {
              onFlipType(true);
            }}
          >
            I have an SSN ›
          </DisplayLink>
        </FormHelperText>
      )}
    </>
  );
}

export function DiligenceV2SSN({
  ssn,
  ssnError,
  useSsn,
  handleChange,
  className,
  taxIDLabel = 'Other tax ID',
  disableFlip,
  ...props
}) {
  const classes = useStyles();
  const [shouldHide, setShouldHide] = useState(ssn);

  function handleflipTaxIdType(useVal) {
    handleChange('useSsn', useVal);
    // Ensure that we clear outdated ssn values so that users can submit
    // no ssn value if they so desire
    // must reset to empty string so that the text fields properly clear
    handleChange('ssn', '');
  }

  const TaxIdInputComponent = useSsn ? (
    <DiligenceSSNInput
      ssn={ssn}
      ssnError={ssnError}
      onChange={handleChange}
      onFlipType={handleflipTaxIdType}
      disableFlip={disableFlip}
    />
  ) : (
    <DiligenceTINInput
      tin={ssn}
      tinError={ssnError}
      onChange={handleChange}
      onFlipType={handleflipTaxIdType}
      disableFlip={disableFlip}
      taxIDLabel={taxIDLabel}
    />
  );

  return (
    <div className={className} {...props}>
      {shouldHide ? (
        <div>
          <Typography variant="label" size="medium" color="text.secondary">
            {useSsn ? 'Social Security Number (SSN)' : taxIDLabel}
          </Typography>

          <div className={classes.hiddenTaxContainer}>
            <SensitiveData
              answer={ssn}
              className={classes.whiteSensitiveData}
            />

            <Button
              variant="icon"
              aria-label="Edit"
              onClick={() => {
                setShouldHide(false);
              }}
            >
              <PencilIcon />
            </Button>
          </div>
        </div>
      ) : (
        TaxIdInputComponent
      )}
    </div>
  );
}
