import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AccountOutlineIcon from 'mdi-material-ui/AccountOutline';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import UndoIcon from '@material-ui/icons/Undo';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import { Button, Chip, Typography } from '@passthrough/uikit';
import { useMe } from 'services/providers/me';

import {
  REVIEW_STATE,
  REVISION_STATE,
  noteFlags,
  noteFlagLabels,
  COMPLETED_STATE,
} from '../constants';
import { AddNoteForm } from './add_note_form';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    columnGap: theme.spacing(2),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  threadDisplay: {
    padding: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  warningIcon: {
    color: theme.palette.warning.icon,
  },
  errorIcon: {
    color: theme.palette.error.icon,
  },
  greyIcon: {
    color: theme.palette.primary.grey,
  },
  timeVal: {
    color: theme.palette.primary.grey,
  },
  wrappedNote: {
    overflowWrap: 'anywhere',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.neutral.fill,
  },
  buttons: {
    float: 'right',
  },
  subheaderChips: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  subheader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addNoteContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  submitNoteButton: {
    alignSelf: 'end',
  },
  errorText: {
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  editButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addNoteSelects: {
    display: 'flex',
  },
  nameState: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));

export function NoteDisplay({
  questionID,
  note,
  className,
  currentLog,
  createQuestionNote,
  updateQuestionNote,
  deleteQuestionNote,
  showAllNotes,
  setShowAddNote,
  reviewedQuestionIds,
  setReviewedQuestionIds,
}) {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [isResponding, setIsResponding] = useState(false);
  const [draftNoteText, setDraftNoteText] = useState('');
  const [flag, setFlag] = useState('');
  const [severity, setSeverity] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [me] = useMe();

  const currentState = currentLog.state;

  function getIcon() {
    if (note.resolved) {
      return <AccountOutlineIcon className={classes.greyIcon} />;
    }
    if (note.flag === noteFlags.DISCUSS_WITH_CLIENT) {
      return <LiveHelpOutlinedIcon className={classes.warningIcon} />;
    }
    if (note.flag === noteFlags.NEEDS_REVIEW) {
      return <ErrorOutlineIcon className={classes.errorIcon} />;
    }
    return <AccountOutlineIcon className={classes.greyIcon} />;
  }

  function startEdit() {
    setIsEditing(true);
    setShowAddNote(false);
    setDraftNoteText(note.note);
    const updateFlag = note.flag === null ? '' : note.flag;
    setFlag(updateFlag);
    const updateSeverity = note.severity === null ? '' : note.severity;
    setSeverity(updateSeverity);
  }

  function cancelEdit() {
    setIsEditing(false);
    setDraftNoteText('');
    setFlag('');
    setSeverity('');
    setShowAddNote(true);
  }

  function updateNote() {
    setIsLoading(true);
    setHasError(false);

    const sendFlag = flag === '' ? null : flag;
    const sendSeverity =
      severity === '' || flag !== noteFlags.NEEDS_REVIEW ? null : severity;

    const newNote = {
      note: draftNoteText,
      flag: sendFlag,
      severity: sendSeverity,
    };
    updateQuestionNote(questionID, note.note_id, newNote)
      .then(() => {
        setIsEditing(false);
        setShowAddNote(true);
        if (
          currentState === REVIEW_STATE &&
          (sendFlag === noteFlags.NEEDS_REVIEW ||
            sendFlag === noteFlags.DISCUSS_WITH_CLIENT)
        ) {
          if (!reviewedQuestionIds.includes(questionID)) {
            const newQuestions = [...reviewedQuestionIds];
            newQuestions.push(questionID);
            setReviewedQuestionIds(newQuestions);
          }
        }
      })
      .catch(() => {
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function updateResolved(resolved) {
    setIsResponding(false);
    const newNote = {
      ...note,
      resolved,
    };
    updateQuestionNote(questionID, note.note_id, newNote);
  }

  function cancelResponse() {
    setIsResponding(false);
    setDraftNoteText('');
  }

  function submitResponse() {
    setIsLoading(true);
    setHasError(false);

    const newNote = {
      note: draftNoteText,
      flag: noteFlags.NO_CHANGE_NEEDED,
    };

    createQuestionNote([questionID], newNote, note.note_id)
      .then(() => {
        setIsLoading(false);
        setDraftNoteText('');
        setIsResponding(false);
      })
      .catch(() => {
        setIsLoading(false);
        setHasError(true);
      });
  }

  function updateDiscussWithClient() {
    setIsLoading(true);
    setHasError(false);
    const newNote = {
      note: note.note,
      flag: noteFlags.DISCUSS_WITH_CLIENT,
      severity: null,
    };
    updateQuestionNote(questionID, note.note_id, newNote);
  }

  function getFlagChip() {
    if (note.flag === noteFlags.DISCUSS_WITH_CLIENT) {
      return (
        <Chip
          label={noteFlagLabels[note.flag]}
          variant="warning"
          size="small"
        />
      );
    }
    if (note.flag === noteFlags.NEEDS_REVIEW) {
      return (
        <Chip label={noteFlagLabels[note.flag]} variant="error" size="small" />
      );
    }
    if (note.flag === noteFlags.NO_CHANGE_NEEDED) {
      return (
        <Chip label={noteFlagLabels[note.flag]} variant="info" size="small" />
      );
    }
    return null;
  }

  return isEditing && showAllNotes ? (
    <div className={classes.addNoteContainer}>
      <div className={classes.addNoteSelects}>
        <FormControl variant="outlined" className={classes.leftInput} fullWidth>
          <InputLabel id="flag">Flag</InputLabel>
          <Select
            label="Flag"
            labelId="flag"
            value={flag}
            onChange={(e) => setFlag(e.target.value)}
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value={noteFlags.DISCUSS_WITH_CLIENT}>
              Discuss with client
            </MenuItem>
            {currentState === REVIEW_STATE ? (
              <MenuItem value={noteFlags.NEEDS_REVIEW}>Needs review</MenuItem>
            ) : null}
          </Select>
        </FormControl>
        {flag === noteFlags.NEEDS_REVIEW ? (
          <FormControl
            variant="outlined"
            className={classes.rightInput}
            fullWidth
          >
            <InputLabel id="severity">Severity</InputLabel>
            <Select
              label="Severity"
              labelId="severity"
              value={severity}
              onChange={(e) => setSeverity(e.target.value)}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="minor">Minor</MenuItem>
              <MenuItem value="major">Major</MenuItem>
            </Select>
          </FormControl>
        ) : null}
      </div>
      <AddNoteForm
        draftNoteText={draftNoteText}
        setDraftNoteText={setDraftNoteText}
        cancel={cancelEdit}
        disabled={
          !draftNoteText ||
          (note.note === draftNoteText &&
            (note.flag === flag || (note.flag === null && flag === '')) &&
            (note.severity === severity ||
              (note.severity === null && severity === '')))
        }
        isLoading={isLoading}
        submitNote={updateNote}
        buttonLabel="Update note"
        hasError={hasError}
      />
    </div>
  ) : (
    <>
      <div className={clsx(classes.root, className)}>
        {getIcon()}

        <div className={classes.content}>
          <div className={classes.header}>
            <div className={classes.nameState}>
              <Typography fontWeight={600}>{note.userName}</Typography>
              <Typography color="text.secondary">{note.state}</Typography>
            </div>

            <Typography color="text.secondary" size="small">
              {note.timeStr}
            </Typography>
          </div>

          {getFlagChip() ? (
            <div className={classes.subheader}>
              <div className={classes.subheaderChips}>
                {getFlagChip()}
                {note.severity ? (
                  <Chip
                    label={
                      note.severity.charAt(0).toUpperCase() +
                      note.severity.slice(1)
                    }
                    variant="error"
                    size="small"
                  />
                ) : null}
              </div>
              <div className={classes.buttons}>
                {(!note.resolved && currentState === REVISION_STATE) ||
                (!note.resolved &&
                  note.flag === noteFlags.DISCUSS_WITH_CLIENT &&
                  currentState === COMPLETED_STATE) ? (
                  <>
                    {note.flag === noteFlags.NEEDS_REVIEW ? (
                      <Button
                        variant="text"
                        color="primary"
                        onClick={() => updateDiscussWithClient()}
                      >
                        Discuss with Client
                      </Button>
                    ) : null}
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => setIsResponding(true)}
                    >
                      No changes
                    </Button>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => updateResolved(true)}
                    >
                      Resolve
                    </Button>
                  </>
                ) : null}
                {(note.resolved &&
                  currentState === REVISION_STATE &&
                  (note.flag === noteFlags.NEEDS_REVIEW ||
                    note.flag === noteFlags.DISCUSS_WITH_CLIENT)) ||
                (note.resolved &&
                  note.flag === noteFlags.DISCUSS_WITH_CLIENT &&
                  currentState === COMPLETED_STATE) ? (
                  <Button
                    variant="text"
                    onClick={() => updateResolved(false)}
                    startIcon={<UndoIcon />}
                    size="small"
                  >
                    Undo resolution
                  </Button>
                ) : null}
              </div>
            </div>
          ) : null}

          <div className={classes.wrappedNote}>
            <Typography>{note.note}</Typography>
          </div>

          {note.log_id === currentLog.id &&
          me.id === note.userId &&
          showAllNotes &&
          note.flag !== noteFlags.RESOLVE_NOTE &&
          note.flag !== noteFlags.UNRESOLVE_NOTE ? (
            <div className={classes.buttons}>
              <Button variant="text" onClick={() => startEdit()} size="small">
                Edit
              </Button>
              <Button
                variant="text"
                onClick={() => deleteQuestionNote(questionID, note.note_id)}
                size="small"
              >
                Delete
              </Button>
            </div>
          ) : null}
        </div>
      </div>
      {isResponding ? (
        <AddNoteForm
          draftNoteText={draftNoteText}
          setDraftNoteText={setDraftNoteText}
          cancel={cancelResponse}
          disabled={!draftNoteText}
          isLoading={isLoading}
          submitNote={submitResponse}
          buttonLabel="No changes needed"
          hasError={hasError}
        />
      ) : null}
      {hasError ? (
        <div className={classes.errorText}>
          There was an error adding your note.
        </div>
      ) : null}
    </>
  );
}
