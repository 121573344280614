/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import { Answers } from '../answers';
import { UNSAVED } from '../saved';

function formatValueWithMax(val, max) {
  // We prevent users from putting in values over the passed in max,
  // prepend a 0 if the value is too high to be a tens value, and also
  // prevent a 0 from being set for both the month and the date
  let maxedValue = val;
  if (val.length === 1 && val[0] > max[0]) {
    maxedValue = `0${val}`;
  }

  if (maxedValue.length === 2) {
    if (Number(maxedValue) === 0) {
      maxedValue = '01';
    } else if (maxedValue > max) {
      maxedValue = max;
    }
  }

  return maxedValue;
}

const MONTH_DAY_LIMIT = {
  '01': '31',
  '02': '29',
  '03': '31',
  '04': '30',
  '05': '31',
  '06': '30',
  '07': '31',
  '08': '31',
  '09': '30',
  10: '31',
  11: '30',
  12: '31',
};

function dateWithoutYearFormat(val) {
  const month = formatValueWithMax(val.substring(0, 2), '12');
  const day = formatValueWithMax(val.substring(2, 4), MONTH_DAY_LIMIT[month]);

  return month + (day.length ? `/${day}` : '');
}

function MonthDateInput({ name, inputRef, onChange, ...other }) {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange(values.formattedValue);
      }}
      allowNegative={false}
      format={dateWithoutYearFormat}
      placeholder="MM/DD"
    />
  );
}

export function DateWithoutYearQuestion({
  updateLpDoc,
  setSaving,
  answer,
  label,
  frontendLabel,
  QuestionStepper,
  QuestionPromptComponent,
}) {
  const initialDateWithoutYear = answer || '';
  const [dateWithoutYear, setDate] = useState(initialDateWithoutYear);
  const [errorMsg, setErrorMsg] = useState('');
  const [isValidDate, setIsValidDate] = useState(false);
  const hasInput = Boolean(dateWithoutYear);

  useEffect(() => {
    setIsValidDate(dateWithoutYear.includes('/'));
  }, [dateWithoutYear]);

  function handleSubmit(e) {
    e.preventDefault();
    setErrorMsg('');

    return updateLpDoc({
      label,
      answer: dateWithoutYear,
      skipQuestion: !hasInput,
    }).catch((error) => {
      setSaving(UNSAVED);
      if (error.response?.status === 400) {
        setErrorMsg(error.response.data.answer);
      }
      return error;
    });
  }

  function handleChange(value) {
    setSaving(UNSAVED);
    setDate(value);
  }

  // Reset the input when we change questions.
  useEffect(() => {
    setDate(initialDateWithoutYear);
    setErrorMsg('');
  }, [label]);

  return (
    <form onSubmit={handleSubmit}>
      <QuestionPromptComponent />
      <Answers>
        <TextField
          id="dateWithoutYear"
          autoFocus
          label={frontendLabel}
          variant="outlined"
          value={dateWithoutYear}
          error={!!errorMsg}
          helperText={errorMsg}
          onChange={handleChange}
          InputProps={{
            inputComponent: MonthDateInput,
          }}
          fullWidth
        />
        <QuestionStepper
          handleSubmit={handleSubmit}
          disabled={!isValidDate}
          hasAnswerChanged={dateWithoutYear !== initialDateWithoutYear}
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
}
