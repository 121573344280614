import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { DialogTitle } from 'components/dialog';
import { VIEW, EDIT, DELETE, ADD } from './constants';

const useStyles = makeStyles(() => ({
  title: {
    textAlign: 'left',
  },
}));

export function Title({ mode, fundName, memberToDelete }) {
  const classes = useStyles();

  function titleContent() {
    switch (mode) {
      case VIEW:
        return <>Team members assigned to {fundName}</>;
      case EDIT:
        return <>Manage access to {fundName}</>;
      case DELETE:
        return <>Remove {memberToDelete.name || 'user'}?</>;
      case ADD:
        return <>Add team member to {fundName}</>;
      default:
        return null;
    }
  }

  return (
    <DialogTitle className={classes.title} id="member-access-dialog-title">
      {titleContent()}
    </DialogTitle>
  );
}
