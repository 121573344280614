import React, { useCallback, useState, useEffect } from 'react';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { useQuestionnaire } from 'services/providers/questionnaire';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import FormHelperText from '@material-ui/core/FormHelperText';
import { getEmptyFile } from 'components/file_upload';
import { Spinner } from 'components/spinner';

import { FileUploadArea } from '../file_upload_area';
import { SingleFileUploadDisplay } from './single_file_upload_display';
import { FileUploadAreaBackground } from '../file_upload_area_background';
import {
  windowHandlerInitialization,
  constructErrorDisplay,
  validateUploadedFile,
} from '../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1, 0),
    minHeight: '275px',
    border: '2px dashed rgba(0, 0, 0, 0.12)',
  },
}));

export const DndSingleFileUpload = ({
  file,
  onChange,
  url,
  acceptedFileTypes = '',
  uploadImmediately = true,
  ...extraInputProps
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [validationErrors, setValidationErrors] = useState('');
  const [uploadError, setUploadError] = useState('');
  const classes = useStyles();
  const {
    useSupport,
    uploadSingleFile,
    maxFileSize = null,
  } = useQuestionnaire();
  const { showSupport } = useSupport();

  const { fileName } = file;
  const hasFile = Boolean(fileName);

  const defaultValidationErrorStr = 'Invalid file.';
  const uploadErrorStr =
    'There was an error uploading your file, please resubmit it.';

  useEffect(windowHandlerInitialization, []);

  const clearFile = () => {
    onChange(getEmptyFile());
  };

  function onDropRejected(rejectedFiles) {
    if (rejectedFiles.length > 0) {
      setValidationErrors(constructErrorDisplay(rejectedFiles, showSupport));
      return;
    }
    setValidationErrors(defaultValidationErrorStr);
  }

  function uploadFile(fileToUpload) {
    if (fileToUpload === null || fileToUpload === undefined) {
      // even if dropped file(s) are rejected, this method will
      // still be called but with an undefined input
      return;
    }
    setIsUploading(true);
    setValidationErrors('');
    setUploadError('');

    uploadSingleFile(fileToUpload, url)
      .then((response) => {
        const remoteFile = {
          fileId: response.data.id,
          fileUrl: response.data.file,
          fileName: response.data.name,
        };
        onChange(remoteFile);
        setIsUploading(false);
      })
      .catch((error) => {
        setIsUploading(false);
        if (error.response && error.response.data.file) {
          const errorMsg = error.response.data.file;
          setUploadError(errorMsg);
          return;
        }
        setIsUploading(false);
        setUploadError(uploadErrorStr);
      });
  }

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (fileRejections.length === 0) {
        // The parent component will be responsible for sending the file
        // to the backend.
        if (uploadImmediately) {
          uploadFile(acceptedFiles[0]);
        } else {
          onChange(acceptedFiles[0]);
        }
      }
    },
    [file],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    validator: validateUploadedFile(acceptedFileTypes, maxFileSize),
    noClick: false,
    noKeyboard: true,
    multiple: false,
    disabled: hasFile,
    noDragEventsBubbling: true,
    accept: acceptedFileTypes,
  });

  if (isUploading) {
    return (
      <Paper variant="outlined" className={clsx(classes.root)}>
        <Spinner size={250} />
      </Paper>
    );
  }

  return (
    <>
      <FileUploadAreaBackground
        isDragging={isDragActive}
        hasFile={hasFile}
        dndRootProps={{ ...getRootProps() }}
      >
        {hasFile ? (
          <SingleFileUploadDisplay file={file} onDelete={clearFile} />
        ) : (
          <FileUploadArea
            inputProps={{ ...getInputProps(), ...extraInputProps }}
          />
        )}
      </FileUploadAreaBackground>
      {validationErrors ? (
        <FormHelperText error>{validationErrors}</FormHelperText>
      ) : null}
      {uploadError ? (
        <FormHelperText error>{uploadError}</FormHelperText>
      ) : null}
    </>
  );
};
