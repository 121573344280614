import React, { useEffect, createContext, useContext } from 'react';

import { useMe } from './providers/me';
import * as urls from './urls';

const PassthroughSpecificRedirectContext = createContext();

export function usePassthroughSpecificRedirect() {
  return useContext(PassthroughSpecificRedirectContext);
}

export function PassthroughSpecificRedirectProvider({ children }) {
  const [me] = useMe();
  let redirectTo = null;

  // The backend sets PASSTHROUGH_SPECIFIC_REDIRECT_URL if we're in
  // a white-labeled domain that doesn't contain passthrough.com
  // in the URL. For those domains, we want to redirect to
  // app.passthrough.com when the user views a page that is specific
  // to Passthrough, such as account management or profile pages.
  const redirectUrlBase = window.PASSTHROUGH_SPECIFIC_REDIRECT_URL;
  if (redirectUrlBase) {
    // The user will have to sign in again
    const signIn = urls.signinUrl({ userId: me.id });
    // Strip the leading slash from the signin URL
    redirectTo = redirectUrlBase + signIn.slice(1);
  }

  return (
    <PassthroughSpecificRedirectContext.Provider value={redirectTo}>
      {children}
    </PassthroughSpecificRedirectContext.Provider>
  );
}

export function PassthroughSpecificRedirect({ children }) {
  const redirectTo = usePassthroughSpecificRedirect();

  function redirectIfNecessary() {
    if (redirectTo) {
      window.location.replace(redirectTo);
    }
  }
  useEffect(redirectIfNecessary, []);

  return children;
}
