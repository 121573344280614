import * as constants from '../../constants';

export const USA_V3_REQUIREMENTS = {
  [constants.GROUP_NONE]: {
    [constants.TYPE_INDIVIDUAL]: {
      documents: [
        {
          key: constants.TYPE_VALID_ID,
          label: 'Valid ID or passport',
          helpText: `1. For US citizens, upload a government-issued photo ID (eg. driver’s license or passport).
2. For non-US citizens, upload a valid passport.
3. For individuals with citizenship of multiple countries, upload a passport for each citizenship the individual possesses.

This document will be used to confirm the existence of the individual, and that the information provided for the individual matches.`,
        },
        {
          key: constants.TYPE_PROOF_OF_ADDRESS,
          label: 'Proof of residential address',
          helpText: `1. Upload a bank statement, utility bill, or similar dated within the last 3 months showing the name of the individual and the residential address provided.
2. Government issued ID cannot be used.

This document confirms that the address provided for the individual is current.`,
        },
      ],
      owners: [],
    },
    [constants.TYPE_JOINT_ACCOUNT_OR_TENANCY]: {
      documents: [],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_HOLDERS),
          helpText:
            'Add the names of the joint account holders or joint tenants',
          minNum: 2,
        },
      ],
    },
    [constants.TYPE_IRA]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(
            constants.TYPE_IRA_ACCT_STATEMENT,
          ),
          helpText: `Upload the most recent account statement, including the names of all account beneficiaries.

This document will be used to confirm that information has been provided for all beneficiaries.`,
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_BENEFICIARIES),
          helpText: '',
        },
      ],
    },
  },
  [constants.GROUP_COMPANY]: {
    [constants.TYPE_PARTNERSHIP]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(
            constants.TYPE_PARTNERSHIP_AGREEMENT,
          ),
          helpText: `The partnership agreement must show the names of all general partners, any limited partners that own 25% or more of the partnership, and must include all amendments.

This document will be used to confirm that the partnership was legally formed and that information has been provided for all limited partners of 25% or more and general partners.`,
        },
      ],
      owners: [
        {
          key: constants.TYPE_LPS,
          singularLabel: 'Limited partner of 25% or more',
          pluralLabel: 'Limited partners of 25% or more',
          helpText:
            'Add all limited partners that own 25% or more of the limited partner interest in the partnership.',
        },
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_GPS),
          helpText:
            'Add all general partners identified in the partnership agreement.',
        },
      ],
    },
    [constants.TYPE_PRIVATE_CORPORATION]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(
            constants.TYPE_OPERATING_AGREEMENT,
          ),
          helpText: `Upload a document identifying all the managers and any controlling persons of the private corporation.

This document will be used to confirm that the private corporation was legally formed.`,
        },
        {
          key: constants.TYPE_OWNERSHIP_SCHEDULE,
          label: 'Schedule of ownership',
          helpText: `Upload a schedule identifying all owners of 25% or more of the private corporation.
A structure chart is acceptable.

This document will be used to confirm that information has been provided for all managers, controlling persons, or beneficial owners of 25% or more.`,
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(
            constants.TYPE_MANAGERS_CONTROLLING_PERSONS,
          ),
          helpText:
            'Add all individuals or entities authorized to make investment decisions on behalf of the private corporation.',
        },
        {
          key: constants.TYPE_BENEFICIAL_OWNERS,
          singularLabel: 'Beneficial owner of 25% or more',
          pluralLabel: 'Beneficial owners of 25% or more',
          helpText: `1. Add all direct beneficial owners of 25% or more.
2. Do not add owners that hold a 25% or more interest through other intermediate entities. You will add them as direct owners of those entities in the next steps.`,
        },
      ],
    },
    [constants.TYPE_FOREIGN_ENTITY]: {
      documents: [
        {
          key: constants.TYPE_AOA,
          label: 'Articles of Association or similar',

          helpText: `This document will be used to confirm that the foreign entity has been legally formed.`,
        },
        {
          key: constants.TYPE_OWNERSHIP_SCHEDULE,
          label: 'Schedule of ownership',
          helpText: `Upload a schedule of all beneficial owners of 25% or more of the foreign company. A structure chart is acceptable.

This document will be used to confirm that information has been provided for all managers, controlling persons, and beneficial owners of 25% or more.`,
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(
            constants.TYPE_MANAGERS_CONTROLLING_PERSONS,
          ),
          helpText:
            'Add all individuals or entities authorized to make investment decisions on behalf of the foreign company.',
        },
        {
          key: constants.TYPE_BENEFICIAL_OWNERS,
          singularLabel: 'Beneficial owner of 25% or more',
          pluralLabel: 'Beneficial owners of 25% or more',
          helpText: `1. Add all direct beneficial owners of 25% or more.
2. Do not add owners that hold a 25% or more interest through other intermediate entities. You will add them later.`,
        },
      ],
    },
    [constants.TYPE_LISTED_COMPANY]: {
      documents: [
        {
          key: constants.TYPE_SEC_FILING,
          label: 'Latest 10-K or 10-Q',
          helpText: `Upload the latest SEC filings for the listed company.

This document will be used to confirm that the entity is a listed company.`,
        },
      ],
      owners: [],
    },
    [constants.TYPE_SUBSIDIARY_OR_AFFILIATE]: {
      documents: [
        {
          key: constants.TYPE_FORMATION_DOCUMENT,
          label: 'Formation documents', // plural label
          helpText: `Upload the articles of association or operating agreement for the entity.

This document will be used to confirm that the subsidiary was legally formed.`,
        },
        {
          ...constants.genDocumentKeyAndLabel(constants.TYPE_STRUCTURE_CHART),
          helpText: `Upload a structure chart for the entity indicating how it is associated with the listed company.

This document confirms the relationship of the subsidiary to the ultimate beneficial owner listed company.`,
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(
            constants.TYPE_LISTED_COMPANY_OWNER,
          ),
          helpText:
            'Add the listed company that ultimately owns the subsidiary.',
        },
      ],
    },
    [constants.TYPE_LLC]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(
            constants.TYPE_OPERATING_AGREEMENT,
          ),
          helpText: `Upload documents identifying all managers and members with their ownership percentage.

This document will be used to confirm that information has been provided for all managers or members of 25% or more.`,
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_MANAGERS),
          helpText:
            'If the LLC is member-managed, do not add them as managers.',
        },
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_MEMBERS),
          helpText:
            'Typically listed in an exhibit attached to the end of the LLC’s operating agreement.',
        },
      ],
    },
  },
  [constants.GROUP_OTHER]: {
    [constants.TYPE_TRUST]: {
      documents: [
        {
          ...constants.genDocumentKeyAndLabel(constants.TYPE_TRUST_AGREEMENT),
          helpText: `1. Upload the most current trust agreement including amendments. It must identify all the trustees, grantors or settlors, and primary beneficiaries.
2. A redacted version of the agreement is acceptable.

This document will be used to confirm that the trust was legally formed and that information has been provided for all trustees, grantors or settlors, and primary beneficiaries.`,
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_TRUSTEES),
          helpText: 'Add all trustees identified in the trust agreement.',
        },
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_GRANTORS_SETTLORS),
          helpText:
            'Add all grantors and settlors identified in the trust agreement. If they are already added as a trustee, do not add them here.',
        },
        {
          key: constants.TYPE_BENEFICIARIES,
          singularLabel: 'Primary beneficiary',
          pluralLabel: 'Primary beneficiaries',
          helpText:
            'Add all primary beneficiaries involved in investment decisions.  Beneficiaries named to receive assets due to circumstance (e.g., will, death, etc.) are not required.',
        },
      ],
    },
    [constants.TYPE_PENSION_PLAN]: {
      documents: [
        {
          key: constants.TYPE_AML_LETTER,
          label: 'AML letter or similar',
          helpText: `Upload a document stating how the pension plan complies with AML rules and requirements. It must be signed by the chief compliance officer or similar.

This document will be used to verify that there is an adequate AML program in place at the pension fund.`,
        },
        {
          ...constants.genDocumentKeyAndLabel(
            constants.TYPE_PENSION_PLAN_DOCUMENT,
          ),
          helpText: `Upload a document stating the pension plan’s investment policy. It must outline how decisions are made and any investment committee involvement.

This document will be used to confirm that information has been provided for all Chief Investment Officers or investment committee members.`,
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(
            constants.TYPE_CIO_COMMITTEE_MEMBERS,
          ),
          helpText:
            'Add all individuals responsible for making investment related decisions on behalf of the pension plan.',
        },
      ],
    },
    [constants.TYPE_NON_PROFIT]: {
      documents: [
        {
          key: constants.TYPE_AOA,
          label: 'Articles of Association',
          helpText: `Upload a document that establishes the creation of the non-profit organization. It must indicate how it qualifies under section 501(a) of the Internal Revenue Service (IRS) Code.

This document will be used to confirm that the non-profit was legally formed and that information has been provided for all principal officers.`,
        },
        {
          ...constants.genDocumentKeyAndLabel(constants.TYPE_FORM_990),
          helpText: `Upload the most recently filed Form 990 with the IRS that shows the principal officer for the non-profit organization.

This document confirms that the charity is real and is reporting to the IRS.`,
        },
      ],
      owners: [
        {
          ...constants.genOwnerKeyAndLabels(constants.TYPE_PRINCIPAL_OFFICERS),
          helpText:
            'Add all individuals responsible for making investment decisions on behalf of the non-profit organization. They are typically the CEO, president, or the Chief Investment Officer.',
        },
      ],
    },
  },
};
