import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getEmptyFile } from 'components/file_upload';
import { useToast } from 'services/toast';
import * as api from 'services/api';
import {
  annotateInvestorsWithStatusInfo,
  sortInvestors,
} from 'services/investor_utils';
import { useMe } from 'services/providers/me';
import { useFields } from 'components/document_fields/hooks';

import { Confirmation } from './confirmation';
import { EditDocument } from './edit/index';
import { UploadDocument } from './upload_document';

const STEP_UPLOAD = 0;
const STEP_EDIT = 1;
const STEP_CONFIRMATION = 2;
const STEP_DONE = 3;

const ORDER = ['status', 'desc'];

export function UploadPageContent({
  handleClose,
  onChange,
  isFundDocumentUpload,
  dataModelType,
  lpClosings = [],
  docTypesToDisplay,
  hasFirstSigner,
  hasSecondSigner,
  hasThirdSigner,
  hasFourthSigner,
  hasFifthSigner,
  hasSixthSigner,
  hasFirstCounterSigner,
  hasSecondCounterSigner,
  hasThirdCounterSigner,
  allowInvestorTypeInEditMode,
}) {
  const [step, setStep] = useState(STEP_UPLOAD);

  const { fundId } = useParams();

  const [file, setFile] = useState(getEmptyFile());
  const [fileToCheck, setFileToCheck] = useState(getEmptyFile());
  const [name, setName] = useState(null);
  const [type, setType] = useState('');
  const [enableEditDocument, setEnableEditDocument] = useState(null);
  const [pushToExisting, setPushToExisting] = useState(false);
  const [uploadCreationDocFailed, setUploadCreationDocFailed] = useState(false);
  const [sendImmediately, setSendImmediately] = useState(null);
  const [requestChangesMessage, setRequestChangesMessage] = useState('');
  const [requestChangesMessageError, setRequestChangesMessageError] =
    useState(null);
  const [getPushToExistingInvestorsError, setGetPushToExistingInvestorsError] =
    useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [investors, setInvestors] = useState(null);
  const { successToast } = useToast();
  const [me] = useMe();
  const [fileError, setFileError] = useState(null);

  const [documentFieldState, documentFieldActions] = useFields();

  function unsavedProgressAlert() {
    window.onbeforeunload = () => true;

    return () => {
      window.onbeforeunload = null;
    };
  }

  useEffect(unsavedProgressAlert, []);

  useEffect(() => {
    // Get all LPClosings under this fund for offering document flows.
    if (isFundDocumentUpload) {
      api
        .FundLPClosings({ fundId })
        .then((response) => {
          const annotatedInvestors = annotateInvestorsWithStatusInfo(
            response.data,
          );
          setInvestors(sortInvestors(ORDER, annotatedInvestors));
        })
        .catch((error) => {
          if (error.response?.status === 400) {
            setGetPushToExistingInvestorsError(error.response.data.detail);
          }
        });
    } else {
      setInvestors(sortInvestors(ORDER, lpClosings));
    }
  }, []);

  const lpClosingIds = lpClosings.map((l) => l.id);
  const hasSigners =
    hasFirstSigner ||
    hasSecondSigner ||
    hasThirdSigner ||
    hasFourthSigner ||
    hasFifthSigner ||
    hasSixthSigner ||
    hasFirstCounterSigner ||
    hasSecondCounterSigner ||
    hasThirdCounterSigner;
  // If there is a shared name for an investors/countersigner across all closings,
  // display those that instead of the generic role label.

  if (step < STEP_UPLOAD || step > STEP_CONFIRMATION) {
    return null;
  }

  function onSubmit(e) {
    e.preventDefault();

    const params = {
      fundId,
      name,
      fileId: file.fileId,
      lpClosingIds,
      sendImmediately: Boolean(sendImmediately),
      pdfBoxes: enableEditDocument ? documentFieldState.boxes : null,
      message: requestChangesMessage,
      dataModelType,
      type,
      pushToExisting,
      signatureRequired: enableEditDocument || false,
    };

    setIsSubmitting(true);

    api
      .createUploadedDocument(params)
      .then(() => {
        setRequestChangesMessageError(null);
        setName('');
        setPushToExisting(false);
        setUploadCreationDocFailed(false);
        setFile(getEmptyFile());
        successToast(`Added document: ${name}`);
        onChange();
        setStep(STEP_DONE);
        setIsSubmitting(false);
        handleClose();
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setRequestChangesMessageError(error.response.data.message);
          setUploadCreationDocFailed(true);
          setIsSubmitting(false);
        }
      });
  }

  const investorName =
    !isFundDocumentUpload && lpClosings.length === 1
      ? lpClosings[0].lpName
      : null;
  const investorLegalName =
    !isFundDocumentUpload && lpClosings.length === 1
      ? lpClosings[0].legalName
      : null;

  const handleFileUpload = (f) => {
    if (me.isStaff && f?.fileName && investorName) {
      setFileToCheck(f);
      setFileError(null);
    } else {
      setFile(f);
      setFileError(null);
    }
  };

  if (step === STEP_UPLOAD) {
    return (
      <UploadDocument
        handleClose={handleClose}
        onSubmit={
          enableEditDocument
            ? () => {
                setStep(STEP_EDIT);
              }
            : onSubmit
        }
        file={file}
        setFile={setFile}
        fileError={fileError}
        setFileError={setFileError}
        handleFileUpload={handleFileUpload}
        name={name}
        setName={setName}
        enableEditDocument={enableEditDocument}
        setEnableEditDocument={setEnableEditDocument}
        isFundDocumentUpload={isFundDocumentUpload}
        getPushToExistingInvestorsError={getPushToExistingInvestorsError}
        pushToExisting={pushToExisting}
        docType={type}
        setDocType={setType}
        docTypesToDisplay={docTypesToDisplay}
        setPushToExisting={setPushToExisting}
        isSubmitting={isSubmitting}
        hasSigners={hasSigners}
        uploadCreationDocFailed={uploadCreationDocFailed}
        investors={investors}
        investorName={investorName}
        investorLegalName={investorLegalName}
        fileToCheck={fileToCheck}
        setFileToCheck={setFileToCheck}
        isStaff={me.isStaff}
      />
    );
  }
  if (step === STEP_EDIT) {
    return (
      <EditDocument
        onBack={() => setStep(STEP_UPLOAD)}
        moveStep={() => setStep(STEP_CONFIRMATION)}
        file={file}
        name={name}
        hasFirstSigner={hasFirstSigner}
        hasSecondSigner={hasSecondSigner}
        hasThirdSigner={hasThirdSigner}
        hasFourthSigner={hasFourthSigner}
        hasFifthSigner={hasFifthSigner}
        hasSixthSigner={hasSixthSigner}
        hasFirstCounterSigner={hasFirstCounterSigner}
        hasSecondCounterSigner={hasSecondCounterSigner}
        hasThirdCounterSigner={hasThirdCounterSigner}
        documentFieldState={documentFieldState}
        documentFieldActions={documentFieldActions}
        lpClosings={lpClosings}
        allowInvestorType={allowInvestorTypeInEditMode}
      />
    );
  }
  if (step === STEP_CONFIRMATION) {
    return (
      <Confirmation
        setStep={setStep}
        onBack={() => {
          setStep(STEP_EDIT);
        }}
        onSubmit={onSubmit}
        docName={name}
        docType={type}
        boxes={documentFieldState.boxes}
        investors={investors}
        pushToExisting={pushToExisting}
        isFundDocumentUpload={isFundDocumentUpload}
        sendImmediately={sendImmediately}
        setSendImmediately={setSendImmediately}
        uploadCreationDocFailed={uploadCreationDocFailed}
        requestChangesMessage={requestChangesMessage}
        setRequestChangesMessage={setRequestChangesMessage}
        requestChangesMessageError={requestChangesMessageError}
        isSubmitting={isSubmitting}
      />
    );
  }

  return null;
}
