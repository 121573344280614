import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Chip from '@material-ui/core/Chip';
import { Icons } from '@passthrough/uikit';
import { Button } from 'components/button';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.appbar.background,
  },
  toolBar: {
    justifyContent: 'space-between',
  },
  toolBarLeftSide: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}));

export const MappingAppBar = ({
  questionnaireName,
  toQuestionnaire,
  setToQuestionnaire,
  isLoading,
  onClose,
}) => {
  const classes = useStyles();
  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <div className={classes.toolBarLeftSide}>
          <Chip
            label={<strong>{questionnaireName}</strong>}
            variant="outlined"
          />
          {toQuestionnaire ? (
            <>
              <Icons.ArrowForward />
              <Chip
                label={<strong>{toQuestionnaire.name}</strong>}
                variant="outlined"
                onDelete={() => setToQuestionnaire(null)}
              />
            </>
          ) : null}
        </div>
        <div>
          <Button
            variant="text"
            size="large"
            color="default"
            onClick={onClose}
            loading={isLoading}
          >
            Close
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
};
