import React from 'react';
import * as api from './api';

export const useRegionRedirect = () => {
  const [url, setUrl] = React.useState(null);
  const [isLoading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    api
      .getUserRegionRedirect()
      .then((response) => {
        setUrl(response.data.redirectTo);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { url, isLoading };
};
