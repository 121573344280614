export function getChildrenSubTree(startingNodeLabel, structuredNodesRoot) {
  const subtreeRoot = structuredNodesRoot?.findNodeByLabel(startingNodeLabel);
  if (!subtreeRoot) {
    return [];
  }

  const childrenNodes = subtreeRoot.getAllChildren();
  return childrenNodes.map((childNode) => ({
    id: childNode.question.label,
    name: childNode.question.answer.name,
    type: childNode.childLabel,
    parentName: childNode.parentName,
  }));
}
