import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';

import { formatCurrency } from 'services/utils';
import { DEFAULT_CURRENCY } from 'services/constants';
import Skeleton from '@material-ui/lab/Skeleton';

import { ProgressBar } from './progress_bar';
import { useProgressBarInfo } from './utils';

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 'max-content',
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      flex: '0 0 auto',
      marginLeft: 'auto',
      width: (props) => `calc(${props.count} * ${theme.spacing(20)}px)`,
    },
  },
  secondaryText: {
    whiteSpace: 'nowrap',
    fontSize: '0.8rem',
    color: theme.palette.text.secondary,
  },
  investorCount: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  greyedOut: {
    color: theme.palette.text.secondary,
  },
  gridContainer: {
    justifyContent: 'flex-end',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
    marginTop: 0,
    '& > $gridItem': {
      paddingTop: theme.spacing(1),
    },
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      width: '120px',
    },
  },
  commitmentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
}));

export const PENDING = 'pending';
export const FULLY_EXECUTED = 'fully_executed';
export const DILIGENCE_REVIEWED = 'reviewed';
export const PENDING_COMMITMENT = 'pending_commitment';
const TARGET = 'target';

export function ProgressInfo({
  pending,
  fullyExecuted,
  target,
  pendingInvestorCount,
  fullyExecutedInvestorCount,
  reviewedCount,
  isLoading,
  show = [PENDING_COMMITMENT, FULLY_EXECUTED, TARGET],
  hideProgressBar = false,
  currency = DEFAULT_CURRENCY,
}) {
  const classes = useStyles({ count: show.length });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [colors, percentages, tooltips] = useProgressBarInfo({
    pending,
    fullyExecuted,
    target,
    currency,
  });

  const xs = isMobile ? undefined : 12 / show.length;

  function InvestorCount({ count }) {
    return (
      <div className={classes.investorCount}>
        <Typography
          className={clsx({
            [classes.greyedOut]: count === 0,
          })}
        >
          {count}
        </Typography>
        <Typography className={classes.secondaryText}>
          investor
          {count !== 1 ? 's' : ''}
        </Typography>
      </div>
    );
  }

  function NoTarget() {
    return <Typography className={classes.greyedOut}>No target</Typography>;
  }

  function Commitment({ commitment }) {
    if (!commitment && Number(commitment) !== 0) {
      return null;
    }

    return (
      <div className={classes.commitmentContainer}>
        <Typography
          className={clsx({
            [classes.greyedOut]: Number(commitment) === 0,
          })}
        >
          {formatCurrency(commitment, currency)}
        </Typography>
        <Typography className={classes.secondaryText}>
          {currency.code}
        </Typography>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={classes.container}>
        <Skeleton height="100%" width="100%" />

        <Grid container>
          <Grid item xs={4} className={classes.gridItem}>
            <Skeleton width="50%" height={75} />
          </Grid>

          <Grid item xs={4} className={classes.gridItem}>
            <Skeleton width="50%" height={75} />
          </Grid>

          <Grid item xs={4} className={classes.gridItem}>
            <Skeleton width="50%" height={75} />
          </Grid>
        </Grid>
      </div>
    );
  }

  const content = show.map((item) => {
    switch (item) {
      case PENDING:
        return (
          <Grid key={item} item xs={xs} className={classes.gridItem}>
            <Typography className={classes.secondaryText}>Pending</Typography>
            <InvestorCount count={pendingInvestorCount} />
          </Grid>
        );
      case FULLY_EXECUTED:
        return (
          <Grid key={item} item xs={xs} className={classes.gridItem}>
            <Typography className={classes.secondaryText}>
              Fully executed
            </Typography>
            <Commitment commitment={fullyExecuted} />
            <InvestorCount count={fullyExecutedInvestorCount} />
          </Grid>
        );
      case DILIGENCE_REVIEWED:
        return (
          <Grid key={item} item xs={xs} className={classes.gridItem}>
            <Typography className={classes.secondaryText}>Reviewed</Typography>
            <InvestorCount count={reviewedCount} />
          </Grid>
        );
      case PENDING_COMMITMENT:
        return (
          <Grid key={item} item xs={xs} className={classes.gridItem}>
            <Typography className={classes.secondaryText}>Pending</Typography>
            <Commitment commitment={pending} />
            <InvestorCount count={pendingInvestorCount} />
          </Grid>
        );
      case TARGET:
        return (
          <Grid key={item} item xs={xs} className={classes.gridItem}>
            <Typography className={classes.secondaryText}>Target</Typography>
            {Number(target) ? <Commitment commitment={target} /> : <NoTarget />}
          </Grid>
        );
      default:
        return null;
    }
  });

  return (
    <div className={classes.container} data-test="progress-bar">
      {!hideProgressBar ? (
        <ProgressBar
          colors={colors}
          percentages={percentages}
          tooltips={tooltips}
        />
      ) : null}
      <Grid container className={classes.gridContainer} spacing={4}>
        {content}
      </Grid>
    </div>
  );
}
