/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useContext } from 'react';
import { CurrencyProvider } from 'services/providers/currency';
import { DEFAULT_CURRENCY } from 'services/constants';
import * as api from 'services/api';
import { redirectIfDifferentDomain } from 'services/utils';

const FundContext = React.createContext();
const MembersContext = React.createContext();

export function useFund() {
  return useContext(FundContext);
}

export function useMembers() {
  return useContext(MembersContext);
}

// eslint-disable-next-line import/no-unused-modules
export function usePermission() {
  const [fund, isLoading] = useContext(FundContext);
  return [fund ? fund.level : null, isLoading];
}

export function useFundEdit() {
  const context = useContext(FundContext);
  if (context === undefined) {
    return [false];
  }

  const [fund, isLoading] = context;
  return [fund ? fund.level === 'EDIT' : null, isLoading];
}

export function useFundReview() {
  const context = useContext(FundContext);
  if (context === undefined) {
    return [false];
  }

  const [fund, isLoading] = context;
  return [
    fund ? fund.level === 'EDIT' || fund.level === 'REVIEW' : null,
    isLoading,
  ];
}

export function FundProvider({ fundId, children }) {
  const [fund, setFund] = useState(null);
  const [isLoadingFund, setIsLoadingFund] = useState(true);
  const [fundRefreshCount, refreshFund] = useState(0);
  const [members, setMembers] = useState([]);
  const [isLoadingMembers, setIsLoadingMembers] = useState(true);
  const [membersRefreshCount, setMembersRefreshCount] = useState(0);

  function getFund() {
    api.fund({ id: fundId }).then((response) => {
      const { baseUrl } = response.data;

      if (redirectIfDifferentDomain(baseUrl)) {
        return;
      }
      setFund(response.data);
      setIsLoadingFund(false);
    });
  }

  function getMembers() {
    api.members({ fundId }).then((response) => {
      setMembers(response.data);
      setIsLoadingMembers(false);
    });
  }

  useEffect(getFund, [fundId, fundRefreshCount]);
  useEffect(getMembers, [fundId, membersRefreshCount]);

  const refreshMembers = () => setMembersRefreshCount((c) => c + 1);

  return (
    <FundContext.Provider value={[fund, isLoadingFund, refreshFund]}>
      <MembersContext.Provider
        value={[members, isLoadingMembers, refreshMembers]}
      >
        <CurrencyProvider currency={fund?.currency || DEFAULT_CURRENCY}>
          {children}
        </CurrencyProvider>
      </MembersContext.Provider>
    </FundContext.Provider>
  );
}
