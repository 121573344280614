export const missingDiligenceDataReducerActions = {
  INITIALIZE_DATA: 'INITIALIZE_DATA',
  UPDATE_QUESTION_ERRORS: 'UPDATE_QUESTION_ERRORS',
  CLEAR_QUESTION_ERRORS: 'CLEAR_QUESTION_ERRORS',
};

export function missingDiligenceDataReducerReducer(state, action) {
  switch (action.type) {
    case missingDiligenceDataReducerActions.INITIALIZE_DATA: {
      return action.payload.errors;
    }
    case missingDiligenceDataReducerActions.UPDATE_QUESTION_ERRORS: {
      const { label, errors } = action.payload;

      return {
        ...state,
        [label]: errors,
      };
    }
    case missingDiligenceDataReducerActions.CLEAR_QUESTION_ERRORS: {
      const { labels } = action.payload;

      const newState = { ...state };
      for (let i = 0; i < labels.length; i += 1) {
        const label = labels[i];

        newState[label] = null;
      }

      return newState;
    }
    default:
      throw new Error('reducer received an action type that is not supported.');
  }
}
