import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from 'components/table';
import { Button } from 'components/button';

const useStyles = makeStyles((theme) => ({
  fullAccessTableContainer: {
    marginTop: theme.spacing(3),
  },
  fullAccessTableText: {
    marginBottom: theme.spacing(1),
  },
}));

export const ReviewInvestments = ({ investments }) => {
  const classes = useStyles();
  const [showAll, setShowAll] = React.useState(false);
  const plural = investments.length > 1;
  return (
    <div className={classes.fullAccessTableContainer}>
      <Typography className={classes.fullAccessTableText}>
        They will have access to {plural ? 'these' : 'this'} additional
        investment{plural ? 's' : ''}:
      </Typography>
      <Table usePaper={false}>
        <TableHead>
          <TableRow>
            <TableCell>Investor</TableCell>
            <TableCell>Fund</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {investments
            .slice(0, showAll ? investments.length : 3)
            .map((investment) => (
              <TableRow key={investment.id}>
                <TableCell>{investment.investorName}</TableCell>
                <TableCell>{investment.fundName}</TableCell>
              </TableRow>
            ))}
          {!showAll && investments.length > 3 ? (
            <TableRow>
              <TableCell colSpan={2} align="center" component="td">
                <Button
                  variant="text"
                  fullWidth
                  onClick={() => setShowAll(true)}
                >
                  View all funds
                </Button>
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    </div>
  );
};
