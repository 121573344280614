import React, { useEffect, useState } from 'react';
import { Alert } from 'components/alert';
import { Spinner } from 'components/spinner';
import { PageContainer } from 'components/page_container';
import { useConfirm } from '@passthrough/uikit';
import * as urls from 'services/urls';
import * as api from 'services/api';
import { useToast } from 'services/toast';
import { NewAdminModal } from './new_admin_modal';
import { OrgAdminsList } from './org_admin_list';

export function OrganizationAccessPage() {
  const organizationId = urls.useOrganizationId();

  const [openAddAdminModal, setOpenAddAdminModal] = useState(false);
  const [change, setChange] = useState(0);
  const [admins, setAdmins] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const { toast } = useToast();
  const confirm = useConfirm();

  function getAdmins() {
    api.orgAdmins({ organizationId }).then((response) => {
      setAdmins(response.data);
    });
  }

  const onDelete = (adminId) => {
    const admin = admins.find((a) => a.id === adminId);

    const performDelete = () => {
      api
        .deleteOrgAdmin({ organizationId, adminId })
        .then(() => {
          setChange((c) => c + 1);
          toast(`Removed access from ${admin.name}.`);
        })
        .catch((error) => {
          if (error.response?.status === 400) {
            setErrorMsg(error.response.data);
          }
        });
    };

    confirm({
      description: `You are about to revoke access to ${admin.name}.`,
    })
      .then(performDelete)
      .catch(() => {});
  };

  useEffect(getAdmins, [organizationId, change]);
  if (admins === null) {
    return <Spinner fullScreen />;
  }

  function onChange() {
    setChange((c) => c + 1);
  }

  return (
    <PageContainer>
      {errorMsg ? <Alert severity="error">{errorMsg}</Alert> : null}
      <OrgAdminsList
        admins={admins}
        setOpenAddAdminModal={setOpenAddAdminModal}
        onDelete={onDelete}
      />
      <NewAdminModal
        organizationId={organizationId}
        open={openAddAdminModal}
        handleClose={() => {
          setOpenAddAdminModal(false);
        }}
        onChange={onChange}
      />
    </PageContainer>
  );
}
