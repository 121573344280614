import React from 'react';
import { Button } from '@passthrough/uikit';
import PersonAddIcon from '@material-ui/icons/PersonAddOutlined';

export function AddCollaboratorsBtn({ handleClick }) {
  return (
    <Button
      onClick={() => {
        handleClick();
      }}
      startIcon={<PersonAddIcon />}
      variant="secondary"
    >
      Share
    </Button>
  );
}
