import * as React from 'react';
import { Button, Icons } from '@passthrough/uikit';
import { useDocumentsDrawerActions } from './utils';

export function ViewDocumentsButton({ count }) {
  const actions = useDocumentsDrawerActions();

  if (!count) {
    return null;
  }
  return (
    <Button
      variant="secondary"
      startIcon={<Icons.DescriptionOutlined />}
      onClick={() => actions.toggleDrawer()}
    >
      View document{count > 1 ? 's' : ''} ({count})
    </Button>
  );
}
