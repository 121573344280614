import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';

import { Typography, Icons } from '@passthrough/uikit';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from 'components/table';
import { StatusChip, LPClosingStatusChip } from 'components/status';
import {
  SENT_TO_COUNTERSIGNER_STATUS,
  STATUS_INFO,
} from 'components/status/constants';
import { useWhiteLabelConfig } from 'services/providers/theme';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  tableContainer: {
    padding: theme.spacing(2),
    maxHeight: '600px',
    overflowY: 'auto',
  },
  accordion: {
    borderRadius: '4px',
  },
  accordionContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

export function InvestorStatus({ investors, disableFullyExecutedEmail }) {
  const classes = useStyles();
  const { productName } = useWhiteLabelConfig();

  const { label: sentToCountersignerLabel, color: sentToCountersignerColor } =
    STATUS_INFO[SENT_TO_COUNTERSIGNER_STATUS];

  return (
    <div className={classes.container}>
      <Typography variant="card-heading">Investor status</Typography>
      {!disableFullyExecutedEmail ? (
        <Typography variant="body">
          Once countersigned, investors will automatically receive fully
          executed subscription agreements from {productName}.
        </Typography>
      ) : null}
      <Accordion
        variant="elevation"
        elevation={2}
        className={classes.accordion}
      >
        <AccordionSummary
          classes={{
            content: classes.accordionContent,
          }}
          expandIcon={<Icons.ExpandMore />}
        >
          <StatusChip
            label={sentToCountersignerLabel}
            color={sentToCountersignerColor}
          />
          <Typography variant="body">
            {investors.length}
            {investors.length === 1 ? ' investor' : ' investors'}
          </Typography>
        </AccordionSummary>
        <div className={classes.tableContainer}>
          <Table noTopMargin>
            <TableHead>
              <TableRow>
                <TableCell>Investor</TableCell>
                <TableCell>Current status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {investors.map((investor) => (
                <TableRow key={investor.id}>
                  <TableCell>{investor.lpName}</TableCell>
                  <TableCell>
                    <LPClosingStatusChip lpClosing={investor} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Accordion>
    </div>
  );
}
