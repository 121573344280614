import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  input: {
    padding: theme.spacing(1, 0),
    width: `calc(100% - ${theme.spacing(1)}px)`,
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export const InputDiv = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.input}>{children}</div>;
};
