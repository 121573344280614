import React from 'react';
import { ProfileSwitchRadioForm } from './profile_switch_radio_form';

export function StepSwitchProfile({
  selectedProfile,
  isNewInvestor,
  existingAnswersChoice,
  setExistingAnswersChoice,
}) {
  return (
    <ProfileSwitchRadioForm
      profileDisplayName={isNewInvestor ? null : selectedProfile.displayName}
      existingAnswersChoice={existingAnswersChoice}
      setExistingAnswersChoice={setExistingAnswersChoice}
    />
  );
}
