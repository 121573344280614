import * as React from 'react';
import { Button, Typography, Modal } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    gap: theme.spacing(2),
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export function Notice() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  return (
    <div className={classes.root}>
      <Typography align="center">
        If you are investing through an entity, identify the investing entity
        first. If you are investing as an individual, identify yourself. A
        complete submission should include every beneficial owner and controller
        listed in the documents that you provide. Completing all requirements
        will prevent any additional requests after submission.
      </Typography>

      <Button variant="text" onClick={() => setOpen(true)}>
        Why am I providing this information?
      </Button>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        headerLabel="Why am I providing this information?"
        footer={null}
      >
        <Typography>
          Know Your Customer (KYC) is a framework based on government
          regulations to enhance a financial institution’s knowledge of
          counterparties with whom they engage in financial transactions. The
          documents requested for each investor type allow a financial
          institution to verify the investor’s beneficial ownership structure.
        </Typography>
      </Modal>
    </div>
  );
}
