// cspell:ignore authy
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
  Typography,
  Button,
  Link,
  UIKitSettingsProvider,
  Modal,
  Icons,
} from '@passthrough/uikit';
import { useToast } from 'services/toast';
import { OTPInput } from 'components/otp_input';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

function SecretModal({ open, onClose, totpSecret }) {
  const { toast } = useToast();
  const copyToClipboard = () => {
    navigator.clipboard.writeText(totpSecret);
    toast('Setup key copied');
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      headerLabel="Your two-factor setup key"
      footer={null}
    >
      <Typography>Enter this key into your authentication app:</Typography>
      <div>
        <Button
          onClick={copyToClipboard}
          endIcon={<Icons.FileCopyOutlined color="primary" />}
          variant="secondary"
          ellipsis
        >
          {totpSecret}
        </Button>
      </div>
    </Modal>
  );
}

export function TOTPSetupStep({
  totpCode,
  setTotpCode,
  totpCodeError,
  totpSecret,
  onSubmit,
  qrcodePngBase64,
  onBack,
  loading,
}) {
  const [secretModalOpen, setSecretModalOpen] = useState(false);
  const classes = useStyles();

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit();
  }

  return (
    <>
      <form className={classes.form} onSubmit={handleSubmit} noValidate>
        <div>
          <Typography variant="card-heading">
            Set up authentication app
          </Typography>
          <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
            <Typography>
              You may use authenticator apps like{' '}
              <Link
                variant="external"
                inline
                href="https://support.google.com/accounts/answer/1066447"
              >
                Google Authenticator
              </Link>{' '}
              or{' '}
              <Link
                variant="external"
                inline
                href="https://www.microsoft.com/en-us/security/mobile-authenticator-app"
              >
                Microsoft Authenticator
              </Link>{' '}
              or{' '}
              <Link variant="external" inline href="https://authy.com/">
                Twilio Authy
              </Link>{' '}
              to scan this QR code and set up your account. You will use
              authentication codes as the second factor.
            </Typography>
          </UIKitSettingsProvider>
        </div>
        <div className={classes.imageContainer}>
          <img
            src={`data:image/png;base64,${qrcodePngBase64}`}
            height="200px"
            width="200px"
            alt="QR code"
          />
          <Button variant="text" onClick={() => setSecretModalOpen(true)}>
            Enter setup code manually instead
          </Button>
        </div>
        <TextField
          InputProps={{
            inputComponent: OTPInput,
          }}
          id="totp-token"
          label="Authentication code"
          variant="outlined"
          value={totpCode}
          onChange={(e) => setTotpCode(e.target.value)}
          fullWidth
          error={!!totpCodeError}
          helperText={totpCodeError}
          autoComplete="one-time-code"
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{ inputMode: 'numeric' }}
          autoFocus
        />
        <div className={classes.buttons}>
          <Button
            loading={loading}
            disabled={!totpCode}
            fullWidth
            variant="primary"
            onClick={handleSubmit}
            type="submit"
          >
            Continue
          </Button>
          <Button
            disabled={loading}
            fullWidth
            variant="secondary"
            onClick={onBack}
          >
            Back
          </Button>
        </div>
      </form>
      <SecretModal
        open={secretModalOpen}
        onClose={() => setSecretModalOpen(false)}
        totpSecret={totpSecret}
      />
    </>
  );
}
