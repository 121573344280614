import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { Modal, Alert } from '@passthrough/uikit';
import { useToast } from 'services/toast';
import * as api from 'services/api';
import { getSingleErrorFromResponse } from 'services/utils';
import { useDiscardConfirmation } from 'services/discard';

import { EditStage } from './edit_stage';
import { SubmitStage } from './submit_stage/index';
import { EDIT_STAGE, SUBMIT_STAGE } from './constants';

export function UndoRequestedChangesDialog({
  open,
  lpName,
  numSignatureRequired,
  handleClose,
  onChange,
}) {
  const [message, setMessage] = useState('');
  const [stage, setStage] = useState(EDIT_STAGE);
  const [notify, setNotify] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [submissionError, setSubmissionError] = useState('');
  const { fundId, closingId, lpClosingId } = useParams();
  const { toast } = useToast();

  const hasChanges = !notify || message || stage === SUBMIT_STAGE;

  const closeWithConfirmation = useDiscardConfirmation({
    onConfirm: handleClose,
    needConfirmation: hasChanges,
  });

  function undoChangeRequest() {
    setIsLoading(true);
    setSubmissionError('');

    const props = {
      fundId,
      closingId,
      lpClosingId,
      message,
      notify,
    };

    api
      .undoLpClosingChanges(props)
      .then(() => {
        setIsLoading(false);
        toast(`Dismissed change request for ${lpName}`);
        onChange();
        handleClose();
      })
      .catch((error) => {
        setIsLoading(false);
        setSubmissionError(getSingleErrorFromResponse(error.response));
      });
  }

  const primaryButtonText = stage === EDIT_STAGE ? 'Next' : 'Confirm';
  const primaryButtonEndIcon =
    stage === EDIT_STAGE ? <ArrowForwardIcon /> : null;
  const onSubmit =
    stage === EDIT_STAGE ? () => setStage(SUBMIT_STAGE) : undoChangeRequest;
  const onBack = stage === SUBMIT_STAGE ? () => setStage(EDIT_STAGE) : null;

  return (
    <Modal
      open={open}
      onClose={closeWithConfirmation}
      headerLabel="Undo change request"
      showCancelButton
      primaryButtonText={primaryButtonText}
      primaryButtonEndIcon={primaryButtonEndIcon}
      primaryButtonLoading={isLoading}
      onSubmit={onSubmit}
      onBack={onBack}
      onEntering={() => {
        setStage(EDIT_STAGE);
        setMessage('');
        setNotify(true);
        setSubmissionError('');
        setIsLoading(false);
      }}
    >
      {submissionError ? (
        <Alert severity="error">{submissionError}</Alert>
      ) : null}
      {stage === EDIT_STAGE ? (
        <EditStage
          message={message}
          setMessage={setMessage}
          notify={notify}
          setNotify={setNotify}
        />
      ) : (
        <SubmitStage
          message={message}
          notify={notify}
          numSignatureRequired={numSignatureRequired}
        />
      )}
    </Modal>
  );
}
