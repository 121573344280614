import React from 'react';
import clsx from 'clsx';
import { Button, Typography } from '@passthrough/uikit';
import Datasheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import { makeStyles } from '@material-ui/core/styles';

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from 'components/table/index';

const useStyles = makeStyles((theme) => ({
  cell: {
    userSelect: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.table.divider}`,
    cursor: 'cell',
    '&:nth-child(2)': {
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
  },
  headerCell: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.table.divider}`,
    backgroundColor: theme.palette.background.default,
    width: (length) => `calc(100% / ${length})`,
    '&:nth-child(2)': {
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
  },
  selectedCell: {
    backgroundColor: theme.palette.primary.light,
    outline: `1px solid ${theme.palette.primary.main}`,
    outlineOffset: '-1px',
  },
  editingCell: {
    // Prevent cell from expanding when editing
    maxWidth: '0px',
    // Prevent text from shifting down 1px when editing
    paddingTop: '2px',
  },
  indexCell: {
    textAlign: 'center',
    verticalAlign: 'middle',
    width: '0px',
    userSelect: 'none',
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  readOnlyCell: {
    cursor: 'default',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.default,
  },
  placeholderCell: {
    color: theme.palette.text.disabled,
  },
  actionCellFlexbox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  labelFlexbox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
}));

const CommonContext = React.createContext();

const SheetRenderer = ({ children }) => {
  const { columns, noTopMargin, footer, stickyTable } =
    React.useContext(CommonContext);

  const classes = useStyles(columns?.length || 1);

  return (
    <Table stickyHeader={stickyTable} noTopMargin={noTopMargin}>
      <TableHead className="data-header">
        <TableRow disableSidePadding>
          <TableCell readOnly className={classes.indexCell} />

          {columns.map((column) => (
            <TableCell
              className={classes.headerCell}
              style={column.cellStyle}
              key={column.label}
            >
              {column.cellAction ? (
                <div className={classes.actionCellFlexbox}>
                  <div className={classes.labelFlexbox}>
                    <Typography variant="label" size="medium" fontWeight={500}>
                      {column.label}
                    </Typography>
                    {column.subLabel ? (
                      <Typography
                        variant="label"
                        size="small"
                        color="text.secondary"
                      >
                        {column.subLabel}
                      </Typography>
                    ) : null}
                  </div>
                  <Button
                    size="small"
                    variant="icon"
                    {...column.cellAction.button}
                  >
                    {column.cellAction.icon}
                  </Button>
                </div>
              ) : (
                <div className={classes.labelFlexbox}>
                  <Typography variant="label" size="medium" fontWeight={500}>
                    {column.label}
                  </Typography>
                  {column.subLabel ? (
                    <Typography
                      variant="label"
                      size="small"
                      color="text.secondary"
                    >
                      {column.subLabel}
                    </Typography>
                  ) : null}
                </div>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody className="data-body">{children}</TableBody>

      {footer}
    </Table>
  );
};

const RowRenderer = (props) => {
  const { row: rowIndex, children } = props;
  const rowNumber = rowIndex + 1;

  const classes = useStyles();
  return (
    <TableRow disableSidePadding>
      <TableCell
        id={`row-number-${rowNumber}`}
        className={classes.indexCell}
        readOnly
      >
        {rowNumber}
      </TableCell>

      {children}
    </TableRow>
  );
};

const CellRenderer = (props) => {
  const classes = useStyles();
  const {
    cell,
    row,
    col,
    attributesRenderer,
    selected,
    editing,
    updated,
    style,
    className,
    children,
    ...rest
  } = props;

  const { columns } = React.useContext(CommonContext);

  // hey, how about some custom attributes on our cell?
  const attributes = cell.attributes || {};
  // ignore default style handed to us by the component and roll our own
  attributes.style = { width: columns[col].width };
  if (col === 0) {
    attributes.title = cell.label;
  }

  return (
    <TableCell
      {...rest}
      {...attributes}
      data-test="cell"
      aria-disabled={cell.bulkCountersignReadOnlyStyle}
      className={clsx(classes.cell, {
        [classes.selectedCell]: selected,
        [classes.readOnlyCell]: cell.bulkCountersignReadOnlyStyle,
        [classes.placeholderCell]: cell.value === '',
        [classes.editingCell]: editing,
      })}
    >
      {children}
    </TableCell>
  );
};

const renderers = {
  valueRenderer: (cell) => (cell.value === '' ? cell.placeholder : cell.value),
  dataRenderer: (cell) => (cell.value === '' ? '' : cell.value),
  sheetRenderer: SheetRenderer,
  rowRenderer: RowRenderer,
  cellRenderer: CellRenderer,
};

export function Sheet({
  grid,
  setGrid,
  newRow,
  columns,
  dataEditor,
  noTopMargin,
  valueViewer,
  footer,
  fixedRowSize = false,
  stickyTable = false,
  getCellValue = ({ value }) => ({ value }),
  onCellsChangedCallback = () => {},
}) {
  return (
    <CommonContext.Provider
      value={{ columns, noTopMargin, footer, stickyTable }}
    >
      <Datasheet
        data={grid}
        {...renderers}
        onContextMenu={(e, cell) => (cell.readOnly ? e.preventDefault() : null)}
        onCellsChanged={(changes, additions) => {
          const newGrid = grid.map((row) => [...row]);
          changes.forEach(({ row, col, value }) => {
            const { value: newValue, error: newError = '' } = getCellValue({
              col,
              value,
            });
            newGrid[row][col] = {
              ...newGrid[row][col],
              value: newValue,
              error: newError,
            };
          });
          if (additions && !fixedRowSize) {
            additions.forEach(({ row, col, value }) => {
              if (col < columns.length) {
                if (row >= newGrid.length) {
                  newGrid.push(newRow());
                  newGrid[row][col] = {
                    ...newGrid[row][col],
                    value,
                    error: '',
                  };
                } else {
                  newGrid[row][col] = {
                    ...newGrid[row][col],
                    value,
                    error: '',
                  };
                }
              }
            });
          }
          if (
            !fixedRowSize &&
            newGrid[newGrid.length - 1].some((cell) => cell.value !== '')
          ) {
            newGrid.push(newRow());
          }
          setGrid(newGrid);
          onCellsChangedCallback();
        }}
        valueViewer={valueViewer}
        dataEditor={dataEditor}
      />
    </CommonContext.Provider>
  );
}
