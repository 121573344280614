import * as React from 'react';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    whiteSpace: 'normal',
  },
}));

export function YesNo({ label, name, value, handleChange, error }) {
  const classes = useStyles();
  return (
    <FormControl component="fieldset" error={Boolean(error)}>
      <FormLabel
        component="legend"
        focused={false}
        classes={{ root: classes.label }}
      >
        {label}
      </FormLabel>
      <RadioGroup
        aria-label={label}
        name={name}
        value={value}
        onChange={(ev) => handleChange(name, ev.target.value)}
        className={classes.root}
      >
        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="No" control={<Radio />} label="No" />
      </RadioGroup>
      {error ? <FormHelperText>{error}</FormHelperText> : null}
    </FormControl>
  );
}
