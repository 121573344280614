import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import { Button, Typography, Icons } from '@passthrough/uikit';

import { KeyValuePair } from 'components/key_value';
import * as api from 'services/api';

import { getErrorListFromResponse } from 'services/utils';
import { useToast } from 'services/toast';

import { EXPOSED_SIDE_LETTER_TYPES } from 'components/document_upload/constants';
import { CountersignerList } from './countersigner_list';
import { InvestorStatus } from './investor_status';
import { DeliveryOptionDisplay } from './delivery_option_display';
import {
  DELIVERY_OPTION_BULK_PACKET,
  DELIVERY_OPTION_INDIVIDUAL_PACKETS,
  SUBSCRIPTION_DOCUMENT,
} from './constants';
import { collectLpDocumentIdsFromSubdocs } from './helpers';
import { InvestorsWithFullyExecutedSubDoc } from './investors_with_fully_executed_subdoc';

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  paddedPaper: {
    padding: theme.spacing(2),
  },
  paddedPaperContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

function DeliveryOption({
  deliveryOption,
  atLeastOneDocumentHasVariables,
  multiplePackets,
}) {
  const classes = useStyles();

  return (
    <div className={classes.paddedPaperContainer}>
      <Typography variant="card-heading">Delivery option</Typography>
      <Paper className={classes.paddedPaper} variant="outlined">
        <DeliveryOptionDisplay
          deliveryOption={deliveryOption}
          atLeastOneDocumentHasVariables={atLeastOneDocumentHasVariables}
          multiplePackets={multiplePackets}
        />
      </Paper>
    </div>
  );
}

function getDocIndex(docType) {
  if (docType === SUBSCRIPTION_DOCUMENT) return 0;
  const index = EXPOSED_SIDE_LETTER_TYPES.findIndex(
    (sortedDocType) => sortedDocType.key === docType,
  );
  if (index === -1) {
    return EXPOSED_SIDE_LETTER_TYPES.length + 1;
  }
  return index + 1;
}

function Document({ allInvestors, closingSubDoc }) {
  const classes = useStyles();
  const includedDocumentTypes = allInvestors.reduce((docTypes, investor) => {
    investor.docs.forEach((doc) => {
      if (!doc.fullyExecuted) {
        docTypes.add(doc.type);
      }
    });
    return docTypes;
  }, new Set());
  const sortedDocTypes = Array.from(includedDocumentTypes).sort(
    (a, b) => getDocIndex(a) - getDocIndex(b),
  );
  const displayDocTypes = sortedDocTypes.map((docType) => {
    if (docType === SUBSCRIPTION_DOCUMENT) {
      return closingSubDoc?.name;
    }
    const docTypeObj = EXPOSED_SIDE_LETTER_TYPES.find(
      (sortedDocType) => sortedDocType.key === docType,
    );
    return docTypeObj?.display || docType;
  });

  return (
    <div className={classes.paddedPaperContainer}>
      <Typography variant="card-heading">Document</Typography>
      <Paper variant="outlined">
        {displayDocTypes.map((docType) => (
          <div className={classes.flexBox}>
            <KeyValuePair icon={<DescriptionOutlinedIcon />}>
              {docType}
            </KeyValuePair>
          </div>
        ))}
      </Paper>
    </div>
  );
}

function SendToCountersigners({
  canBulkCountersignInvestors,
  individualPacketInvestors,
  allInvestors,
  countersignerData,
  countersigners,
  deliveryOption,
  onClose,
  setWasSentToCountersigners,
  setErrorMsgs,
  onBack,
  allowChangeCountersigners,
}) {
  const classes = useStyles();
  const { fundId, closingId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  const plural = allInvestors.length > 1 ? 's' : '';
  const successMessage = `Sent ${allInvestors.length} investor${plural} for countersignature.`;

  function handleSendBulkCountersigning() {
    setIsLoading(true);
    setErrorMsgs(null);

    const promises = [
      api.sendForBulkCountersign({
        fundId,
        closingId,
        countersignerId: countersigners.countersigner,
        countersigner2Id: countersigners.countersigner2,
        countersigner3Id: countersigners.countersigner3,
        lpDocumentIds: collectLpDocumentIdsFromSubdocs(
          canBulkCountersignInvestors,
        ),
      }),
    ];
    if (individualPacketInvestors.length > 0) {
      promises.push(
        api.sendToCountersignerClosingDocs({
          fundId,
          closingId,
          lpClosingIds: individualPacketInvestors.map((i) => i.id),
          countersignerId: countersigners.countersigner,
          countersigner2Id: countersigners.countersigner2,
          countersigner3Id: countersigners.countersigner3,
        }),
      );
    }

    Promise.all(promises)
      .then(() => {
        setWasSentToCountersigners(true);
        setIsLoading(false);
        onClose();
        toast(successMessage);
      })
      .catch((e) => {
        setErrorMsgs(getErrorListFromResponse(e.response));
        setIsLoading(false);
      });
  }

  function handleSendIndividualCountersigning() {
    setIsLoading(true);
    setErrorMsgs('');

    const changingCountersignerParams = {};
    if (allowChangeCountersigners) {
      changingCountersignerParams.countersignerId =
        countersigners.countersigner;
      changingCountersignerParams.countersigner2Id =
        countersigners.countersigner2;
      changingCountersignerParams.countersigner3Id =
        countersigners.countersigner3;
    }

    const lpClosingIds = allInvestors.map((i) => i.id);
    api
      .sendToCountersignerClosingDocs({
        fundId,
        closingId,
        lpClosingIds,
        countersignerId: changingCountersignerParams.countersignerId,
        countersigner2Id: changingCountersignerParams.countersigner2Id,
        countersigner3Id: changingCountersignerParams.countersigner3Id,
      })
      .then(() => {
        setWasSentToCountersigners(true);
        setIsLoading(false);
        onClose();
        toast(successMessage);
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setErrorMsgs(getErrorListFromResponse(error.response));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  let onSubmit;
  if (deliveryOption === DELIVERY_OPTION_BULK_PACKET) {
    onSubmit = handleSendBulkCountersigning;
  } else if (deliveryOption === DELIVERY_OPTION_INDIVIDUAL_PACKETS) {
    onSubmit = handleSendIndividualCountersigning;
  } else {
    throw new Error(`Invalid delivery option: ${deliveryOption}`);
  }

  return (
    <div className={classes.actions}>
      <Button
        variant="text"
        size="large"
        onClick={onBack}
        startIcon={<Icons.KeyboardArrowLeft />}
      >
        Back
      </Button>
      <Button
        loading={isLoading}
        onClick={onSubmit}
        variant="primary"
        size="large"
        data-test="send-to-countersigner"
      >
        Send to countersigner{countersignerData.numSigners > 1 ? 's' : ''}
      </Button>
    </div>
  );
}

export function ReviewStep({
  closingSubDoc,
  countersignerData,
  deliveryOption,
  onClose,
  setWasSentToCountersigners,
  setErrorMsgs,
  disableFullyExecutedEmail,
  canBulkCountersignInvestors,
  investorsWithFullyExecutedSubdoc,
  allInvestors,
  onBack,
  atLeastOneDocumentHasVariables,
  showDeliveryOption,
  multiplePackets,
  countersigners,
  members,
  allowChangeCountersigners,
}) {
  return (
    <>
      <Document allInvestors={allInvestors} closingSubDoc={closingSubDoc} />
      {showDeliveryOption ? (
        <DeliveryOption
          deliveryOption={deliveryOption}
          atLeastOneDocumentHasVariables={atLeastOneDocumentHasVariables}
          multiplePackets={multiplePackets}
        />
      ) : null}
      <CountersignerList
        showPacketCounts
        allowChangeCountersigners={allowChangeCountersigners}
        countersignerData={countersignerData}
        deliveryOption={deliveryOption}
        countersigners={countersigners}
        closingSubDoc={closingSubDoc}
        bulkEnvelopeInvestorCount={canBulkCountersignInvestors.length}
        individualPacketCount={investorsWithFullyExecutedSubdoc.length}
        members={members}
      />
      <InvestorStatus
        investors={allInvestors}
        disableFullyExecutedEmail={disableFullyExecutedEmail}
      />
      {investorsWithFullyExecutedSubdoc.length > 0 ? (
        <InvestorsWithFullyExecutedSubDoc
          investors={investorsWithFullyExecutedSubdoc}
          closingSubDocName={closingSubDoc.name}
        />
      ) : null}
      <SendToCountersigners
        canBulkCountersignInvestors={canBulkCountersignInvestors}
        individualPacketInvestors={investorsWithFullyExecutedSubdoc}
        allInvestors={allInvestors}
        countersignerData={countersignerData}
        countersigners={countersigners}
        deliveryOption={deliveryOption}
        onClose={onClose}
        setWasSentToCountersigners={setWasSentToCountersigners}
        setErrorMsgs={setErrorMsgs}
        onBack={onBack}
        allowChangeCountersigners={allowChangeCountersigners}
      />
    </>
  );
}
