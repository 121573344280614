import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
  },
  fieldset: {
    width: '100%',
  },
}));

export const MultiChoiceList = ({ children, error }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FormControl
        component="fieldset"
        error={!!error}
        className={classes.fieldset}
      >
        <FormGroup>{children}</FormGroup>
        {error ? <FormHelperText error>{error}</FormHelperText> : null}
      </FormControl>
    </div>
  );
};
