import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1px',
  },
}));

export const PoweredBy = () => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.root}>
      <img
        src={window.static?.powered_by_svg}
        color={theme.palette.text.secondary}
        alt="logo"
      />
    </div>
  );
};
