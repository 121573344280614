import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { AddSubDocDialog } from 'components/add_sub_doc/add_sub_doc_dialog';
import { Link } from 'components/link';
import { PageContainer } from 'components/page_container';
import { Spinner } from 'components/spinner';
import { Alert } from 'components/alert';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableActions,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from 'components/table/index';

import { Button, Typography, Icons, Chip } from '@passthrough/uikit';

import * as urls from 'services/urls';
import * as api from 'services/api';
import { getSingleErrorFromResponse } from 'services/utils';

import { AnswersDialog } from 'pages/fund_closing_overview/answers_dialog';
import { SubdocStatusAlerts } from './subdoc_health_status_alerts';
import { FundStatusAlerts } from './fund_status_alerts';
import { FundClosingStatusAlerts } from './fund_closing_alerts';
import { GoLiveDialog } from './go_live_dialog';
import { ManagedDiligenceModal } from './managed_diligence_modal';

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },
  alert: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  fundStatus: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  onboarding: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

const MODALS = {
  GO_LIVE: 'GO_LIVE',
  MANAGED_DILIGENCE: 'MANAGED_DILIGENCE',
};

function SubdocTable({
  subDocs,
  closings,
  fundName,
  subDocTypes,
  fundId,
  handleSuccess,
}) {
  const styles = useStyles();
  const [addSubDocDialogOpen, setAddSubDocDialogOpen] = useState(false);
  const [answersDialogOpen, setAnswersDialogOpen] = useState(false);

  function renderSubdocType(doc) {
    return (
      <Link
        to={urls.onboardingV2Url({
          fundId,
          questionnaireId: doc.questionnaire.id,
        })}
      >
        {doc.questionnaire.name}
      </Link>
    );
  }

  return (
    <>
      <TableContainer>
        <div className={styles.onboarding}>
          <Typography variant="card-heading" size="large">
            Onboarding
          </Typography>
          <TableActions>
            <div className={styles.actions}>
              <Button
                variant="secondary"
                onClick={() => setAnswersDialogOpen(true)}
                startIcon={<Icons.FileDownload color="neutral" />}
              >
                Export answers
              </Button>
              <Button
                variant="primary"
                startIcon={<Icons.Add />}
                onClick={() => setAddSubDocDialogOpen(true)}
              >
                Add subscription document and onboarding
              </Button>
            </div>
          </TableActions>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Subscription document</TableCell>
              <TableCell>Onboarding</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subDocs.map((doc) => (
              <TableRow key={doc.id}>
                <TableCell scope="row">{doc.name}</TableCell>
                <TableCell>{renderSubdocType(doc)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddSubDocDialog
        open={addSubDocDialogOpen}
        handleClose={() => setAddSubDocDialogOpen(false)}
        allSubDocTypes={subDocTypes}
        fundName={fundName}
        onSuccess={() => {
          handleSuccess();
        }}
      />
      <AnswersDialog
        open={answersDialogOpen}
        handleClose={() => setAnswersDialogOpen(false)}
        closings={closings}
      />
    </>
  );
}

export function FundStaffToolsPage() {
  const { fundId } = useParams();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [healthData, setHealthData] = useState({
    subdocStatuses: [],
    fundStatus: {},
    fundClosingStatuses: [],
  });

  const [fundData, setFundData] = useState({
    subDocs: [],
    subDocTypes: [],
    fundName: '',
    fundClosings: [],
    fundIsLive: false,
    managedDiligenceEnabled: false,
    hasCustomApprovalGroups: false,
  });

  const investorCount = fundData.fundClosings.reduce(
    (acc, closing) => acc + closing.numTotal,
    0,
  );

  const [modal, setModal] = useState('');

  function initializeData(callback) {
    setIsLoading(true);

    Promise.all([
      api.getSubdocHealth({ fundId }),
      api.getFundStaffToolsData({ fundId }),
    ])
      .then(([subdocHealthResponse, fundStaffToolsDataResponse]) => {
        setHealthData(subdocHealthResponse.data);
        setFundData(fundStaffToolsDataResponse.data);
        callback?.();
      })
      .catch((e) => {
        setError(getSingleErrorFromResponse(e.response));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(initializeData, []);

  if (isLoading) {
    return <Spinner fullScreen />;
  }

  return (
    <PageContainer maxWidth="lg">
      {error ? (
        <Alert severity="error" className={classes.alert}>
          {error}
        </Alert>
      ) : null}

      <FundStatusAlerts fundStatus={healthData.fundStatus} />

      <FundClosingStatusAlerts
        fundClosingStatuses={healthData.fundClosingStatuses}
      />

      <SubdocStatusAlerts subdocStatuses={healthData.subdocStatuses} />

      <div className={classes.fundStatus}>
        <Typography variant="card-heading" size="large">
          Fund status
        </Typography>
        <div className={classes.actions}>
          {fundData.managedDiligenceEnabled ? (
            <Chip
              label="Managed diligence enabled"
              variant="neutral"
              icon={<Icons.FingerprintOutlined color="success" />}
            />
          ) : (
            <Button
              variant="secondary"
              onClick={() => setModal(MODALS.MANAGED_DILIGENCE)}
              startIcon={<Icons.FingerprintOutlined />}
              disabled={fundData.hasCustomApprovalGroups}
              tooltip={
                fundData.hasCustomApprovalGroups
                  ? 'Fund has custom approval groups'
                  : null
              }
            >
              Enable managed diligence
            </Button>
          )}
          {!fundData.fundIsLive ? (
            <Button
              variant="primary"
              startIcon={<Icons.CheckCircleOutline />}
              onClick={() => setModal(MODALS.GO_LIVE)}
            >
              Go live
            </Button>
          ) : (
            <Chip
              label="Live"
              variant="success"
              icon={<Icons.CheckCircleOutline color="success" />}
            />
          )}
        </div>
      </div>

      <SubdocTable
        subDocs={fundData.subDocs}
        closings={fundData.fundClosings}
        fundName={fundData.fundName}
        subDocTypes={fundData.subDocTypes}
        fundId={fundId}
        handleSuccess={initializeData}
      />
      <GoLiveDialog
        open={modal === MODALS.GO_LIVE}
        onClose={() => setModal('')}
        fundId={fundId}
        setFundIsLive={(fundIsLive) =>
          setFundData((data) => ({ ...data, fundIsLive }))
        }
      />
      <ManagedDiligenceModal
        open={modal === MODALS.MANAGED_DILIGENCE}
        fundId={fundId}
        onClose={() => setModal('')}
        setManagedDiligenceEnabled={(managedDiligenceEnabled) =>
          setFundData((data) => ({ ...data, managedDiligenceEnabled }))
        }
        investorCount={investorCount}
      />
    </PageContainer>
  );
}
