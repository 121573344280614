import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PersonIcon from '@material-ui/icons/PersonOutlineOutlined';
import PublicIcon from '@material-ui/icons/Public';
import BusinessIcon from '@material-ui/icons/Business';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Typography } from '@passthrough/uikit';

export const PersonEntityFilter = ({ isAsked, setIsAsked }) => (
  <ToggleButtonGroup
    value={isAsked}
    exclusive
    onChange={(e, v) => {
      if (v !== null) {
        setIsAsked(v);
      }
    }}
  >
    <ToggleButton value="always" aria-label="always ask">
      <Tooltip title={<Typography variant="label">Always ask</Typography>}>
        <PublicIcon />
      </Tooltip>
    </ToggleButton>
    <ToggleButton value="natural_person" aria-label="natural person">
      <Tooltip
        title={<Typography variant="label">Only natural people</Typography>}
      >
        <PersonIcon />
      </Tooltip>
    </ToggleButton>
    <ToggleButton value="entity" aria-label="entity">
      <Tooltip title={<Typography variant="label">Only entities</Typography>}>
        <BusinessIcon />
      </Tooltip>
    </ToggleButton>
  </ToggleButtonGroup>
);
