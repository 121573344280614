import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Answers } from '../answers';
import { UNSAVED } from '../saved';

const useStyles = makeStyles((theme) => ({
  answer: {
    margin: theme.spacing(1, 0),
  },
}));

export function NameQuestion({
  updateLpDoc,
  setSaving,
  label,
  answer,
  QuestionStepper,
  QuestionPromptComponent,
}) {
  const { firstName: initialFirstName, lastName: initialLastName } = answer || {
    firstName: '',
    lastName: '',
  };
  const [firstName, setFirstName] = useState(initialFirstName);
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState(initialLastName);
  const [lastNameError, setLastNameError] = useState('');
  const classes = useStyles();
  const hasInput = Boolean(firstName) || Boolean(lastName);

  function handleSubmit(e) {
    e.preventDefault();

    setFirstNameError('');
    setLastNameError('');

    return updateLpDoc({
      label,
      answer: { firstName, lastName },
      skipQuestion: !hasInput,
    }).catch((error) => {
      setSaving(UNSAVED);
      if (error.response?.status === 400) {
        setFirstNameError(error.response.data.answer.firstName);
        setLastNameError(error.response.data.answer.lastName);
      }
      return error;
    });
  }

  function handleFirstNameChange(e) {
    setSaving(UNSAVED);
    setFirstName(e.target.value);
  }

  function handleLastNameChange(e) {
    setSaving(UNSAVED);
    setLastName(e.target.value);
  }

  // Reset the name when changing questions
  useEffect(() => {
    setFirstName(initialFirstName);
    setLastName(initialLastName);
    setFirstNameError('');
    setLastNameError('');
  }, [label]);

  return (
    <form onSubmit={handleSubmit}>
      <QuestionPromptComponent />
      <Answers>
        <TextField
          id="firstName"
          autoFocus
          className={classes.answer}
          label="First name"
          variant="outlined"
          value={firstName}
          error={!!firstNameError}
          helperText={firstNameError}
          onChange={handleFirstNameChange}
          fullWidth
        />
        <TextField
          id="lastName"
          className={classes.answer}
          label="Last name"
          variant="outlined"
          value={lastName}
          error={!!lastNameError}
          helperText={lastNameError}
          onChange={handleLastNameChange}
          fullWidth
        />
        <QuestionStepper
          handleSubmit={handleSubmit}
          hasAnswerChanged={
            firstName !== initialFirstName || lastName !== initialLastName
          }
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
}
