import React from 'react';
import { FieldContainer, Field } from './fields';

export const AddressDisplay = ({ answer }) => (
  <FieldContainer>
    <Field label="Country" labelMinWidth={120}>
      {answer?.line1?.country || '-'}
    </Field>
    <Field label="State">{answer?.line1?.state || '-'}</Field>
    <Field label="City">{answer?.line1?.city || '-'}</Field>
    <Field label="Street">{answer?.line1?.street || '-'}</Field>
    <Field label="Postal code">{answer?.line1?.postal_code || '-'}</Field>
    <Field label="Unit">{answer?.line2 || '-'}</Field>
  </FieldContainer>
);
