import React from 'react';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import { Button } from 'components/button';

import { Paper } from './common/paper';
import { InputDiv } from './common/input_div';

export const EditSection = ({
  sectionId,
  sectionName,
  setSectionName,
  handleSave,
  hasUnsavedChanges,
  isValid,
  isSaving,
}) => (
  <Container maxWidth="sm">
    <Paper>
      <InputDiv>
        <TextField
          value={sectionId}
          disabled
          variant="outlined"
          label="Section ID"
        />
        <TextField
          value={sectionName}
          onChange={(e) => {
            setSectionName(e.target.value);
          }}
          variant="outlined"
          label="Section name"
        />
      </InputDiv>
      <Button
        variant="contained"
        color="primary"
        disabled={!isValid() || !hasUnsavedChanges}
        onClick={handleSave}
        fullWidth
        loading={isSaving}
      >
        Save
      </Button>
    </Paper>
  </Container>
);
