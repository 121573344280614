import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InternalChip, Modal } from '@passthrough/uikit';
import { TextField } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { STATIC_NO_MATCH_EXPLANATIONS } from './constants';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    rowGap: theme.spacing(1),
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

export function NoMatchExplanationModal({ open, onClose, onSubmit }) {
  const classes = useStyles();
  const [selectedReasons, setSelectedReasons] = useState([]);

  const [useCustomReason, setUseCustomReason] = useState(false);
  const [customReasonText, setCustomReasonText] = useState('');

  function updateStaticSelectedReasons(reason, selected) {
    if (selected) {
      setSelectedReasons([...selectedReasons, reason]);
    } else {
      setSelectedReasons(selectedReasons.filter((r) => r !== reason));
    }
  }

  function resetData() {
    setUseCustomReason(false);
    setCustomReasonText('');
    setSelectedReasons([]);
  }

  function handleConfirmExplanations() {
    const explanations = [...selectedReasons];

    if (useCustomReason && customReasonText) {
      explanations.push(customReasonText);
    }

    onSubmit(explanations);
    onClose();
  }

  return (
    <Modal
      open={open}
      size="md"
      headerLabel={
        <div className={classes.header}>
          Why is this hit not a true match?
          <InternalChip />
        </div>
      }
      primaryButtonText="Submit explanation"
      onClose={onClose}
      onExited={resetData}
      showCancelButton
      onSubmit={handleConfirmExplanations}
    >
      <div className={classes.container}>
        {STATIC_NO_MATCH_EXPLANATIONS.map((r) => (
          <FormControlLabel
            key={r}
            control={
              <Checkbox
                checked={selectedReasons.includes(r)}
                onChange={(e) => {
                  updateStaticSelectedReasons(r, e.target.checked);
                }}
              />
            }
            label={r}
          />
        ))}

        <FormControlLabel
          control={
            <Checkbox
              checked={useCustomReason}
              onChange={(e) => {
                setUseCustomReason(e.target.checked);
              }}
            />
          }
          label="Other"
        />

        {useCustomReason ? (
          <TextField
            value={customReasonText}
            onChange={(e) => {
              setCustomReasonText(e.target.value);
            }}
            helperText="Explain why this hit is not a true match"
          />
        ) : null}
      </div>
    </Modal>
  );
}
