import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { Button } from 'components/button';
import * as api from 'services/api';

export function PDFReviewDownloadButton({ lpDocument }) {
  const { fundId, closingId } = useParams();

  const [downloadLoading, setDownloadLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const fileParams = { fundId, closingId, lpDocId: lpDocument.id };

  const fileUrl = api.filledPdfServeURLforGP(fileParams);
  const signedUrl = api.lpDocFinalPdfUri(fileParams);

  function handleDownload() {
    const link = document.createElement('a');
    link.href = `${fileUrl}?download=true`;
    link.download = `${lpDocument.name}.pdf`;
    link.dispatchEvent(new MouseEvent('click'));
    setDownloadLoading(true);
    setTimeout(() => {
      setDownloadLoading(false);
    }, 3000);
  }

  function handleOpenSigned() {
    window.open(signedUrl, '_blank');
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="text"
        size="small"
        onClick={lpDocument.isOffline ? handleMenu : handleDownload}
        loading={downloadLoading}
        endIcon={lpDocument.isOffline ? <KeyboardArrowDownIcon /> : null}
      >
        Download
      </Button>
      <Menu
        id="download-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={menuOpen}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleOpenSigned();
            handleClose();
          }}
        >
          Download signed document that investor uploaded
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDownload();
            handleClose();
          }}
        >
          Download unsigned, filled document (as displayed here)
        </MenuItem>
      </Menu>
    </>
  );
}
