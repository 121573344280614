import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { TableRow, TableCell } from 'components/table/index';
import { Button, Typography, Icons } from '@passthrough/uikit';
import { SubdocActionsMenu } from './subdoc_actions_menu';

const useStyles = makeStyles((theme) => ({
  rowName: {
    display: 'flex',
    alignItems: 'center',
  },
  archiveIcon: {
    marginRight: theme.spacing(1),
  },
}));

export function SubdocListElement({ subscriptionDoc, onArchive, onUnarchive }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const canInteractWithSubdoc =
    subscriptionDoc.subdocType ||
    (subscriptionDoc.questionnaire &&
      subscriptionDoc.questionnaire.isPublished);

  return (
    <TableRow key={subscriptionDoc.id}>
      <TableCell scope="row">
        <div className={classes.rowName}>
          {subscriptionDoc.isArchived ? (
            <Tooltip
              title={
                <Typography variant="label">
                  This subscription document is archived. It can't be used for
                  new closings.
                </Typography>
              }
            >
              <Icons.ArchiveOutlined
                color="action"
                className={classes.archiveIcon}
              />
            </Tooltip>
          ) : null}
          {subscriptionDoc.name}
        </div>
      </TableCell>
      <TableCell>Subscription document</TableCell>
      <TableCell>{subscriptionDoc.createdDate}</TableCell>
      <TableCell>Passthrough</TableCell>
      <TableCell actions>
        <Button
          variant="icon"
          size="large"
          onClick={() => {
            window.open(subscriptionDoc.url, '_blank');
          }}
          aria-label="View document"
        >
          <OpenInNewIcon />
        </Button>
        {canInteractWithSubdoc ? (
          <>
            <Button
              variant="icon"
              size="large"
              onClick={handleMenu}
              color="inherit"
              aria-label="More options"
            >
              <MoreVertIcon />
            </Button>
            <SubdocActionsMenu
              subscriptionDoc={subscriptionDoc}
              anchorEl={anchorEl}
              menuOpen={menuOpen}
              handleClose={handleClose}
              onArchive={onArchive}
              onUnarchive={onUnarchive}
            />
          </>
        ) : null}
      </TableCell>
    </TableRow>
  );
}
