import React, { useState } from 'react';
import HumanGreetingIcon from 'mdi-material-ui/HumanGreeting';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {
  Alert,
  Typography,
  Button,
  Link,
  UIKitSettingsProvider,
} from '@passthrough/uikit';
import Container from '@material-ui/core/Container';
import Grow from '@material-ui/core/Grow';
import Fade from '@material-ui/core/Fade';
import { Spinner } from 'components/spinner';
import * as api from 'services/api';
import * as urls from 'services/urls';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { SignInPageContainer } from 'components/sign_in_page_container';

const useStyles = makeStyles((theme) => ({
  stepContainer: {
    maxWidth: '460px',
  },
  contentHeader: {
    display: 'flex',
    marginBottom: theme.spacing(4),
    justifyContent: 'center',
  },
  alert: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    marginTop: theme.spacing(6),
  },
  input: {
    backgroundColor: 'white',
  },
  welcome: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: theme.spacing(10),
    textAlign: 'center',
  },
  terms: {
    paddingTop: theme.spacing(2),
  },
  paddingTop: {
    paddingTop: theme.spacing(2),
  },
}));

export function GoodwinEmbark({ me, onSignUp }) {
  const classes = useStyles();

  const [complete, setComplete] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({
    firstName: me.firstName || '',
    lastName: me.lastName || '',
    hasAcceptedPlatformTerms: false,
    hasAcceptedPrivacyAndTerms: false,
  });
  const isSubmitDisabled =
    !data.firstName ||
    !data.lastName ||
    !data.hasAcceptedPrivacyAndTerms ||
    !data.hasAcceptedPlatformTerms;

  function handleCheckedChange(field) {
    return (e) => {
      setData({
        ...data,
        [field]: e.target.checked,
      });
    };
  }

  function handleValueChange(field) {
    return (e) => {
      setData({
        ...data,
        [field]: e.target.value,
      });
    };
  }

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitting(true);
    setErrors({});

    api
      .signup({
        firstName: data.firstName,
        lastName: data.lastName,
        hasAcceptedPrivacyAndTerms: data.hasAcceptedPrivacyAndTerms,
        hasAcceptedPlatformTerms: data.hasAcceptedPlatformTerms,
      })
      .then(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        setComplete(true);
        setTimeout(() => {
          onSignUp();
        }, 2000);
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setErrors(error.response.data);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  return (
    <>
      <Grow in={complete}>
        <Container component="main" maxWidth="md" className={classes.welcome}>
          <HumanGreetingIcon style={{ fontSize: '80px' }} />
          <Typography variant="section-heading">
            Welcome, {data.firstName}!
          </Typography>
          <Spinner size={100} />
        </Container>
      </Grow>

      <Fade in={!complete}>
        <div>
          <SignInPageContainer>
            <div className={classes.stepContainer}>
              <div className={classes.contentHeader}>
                <Typography variant="section-heading">
                  What is your name?
                </Typography>
              </div>
              <div className={classes.alert}>
                <Alert severity="info">
                  Do not enter someone else's name even if you intend to sign on
                  their behalf.
                </Alert>
              </div>
              <form
                className={classes.form}
                noValidate
                onSubmit={(e) => handleSubmit(e)}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.input}
                      size="medium"
                      autoComplete="fname"
                      name="firstName"
                      variant="outlined"
                      fullWidth
                      id="firstName"
                      label="First name"
                      autoFocus={!data.firstName}
                      error={!!errors.firstName}
                      helperText={errors.firstName}
                      value={data.firstName}
                      onChange={handleValueChange('firstName')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.input}
                      variant="outlined"
                      fullWidth
                      id="lastName"
                      label="Last name"
                      name="lastName"
                      autoComplete="lname"
                      error={!!errors.lastName}
                      helperText={errors.lastName}
                      value={data.lastName}
                      onChange={handleValueChange('lastName')}
                    />
                  </Grid>
                </Grid>
                <UIKitSettingsProvider
                  linkComponent="a"
                  redirectAttribute="href"
                >
                  <FormControl
                    className={classes.terms}
                    error={!!errors.hasAcceptedPrivacyAndTerms}
                  >
                    <FormControlLabel
                      label={
                        <span>
                          I have read, understood and I agree to Passthrough's{' '}
                          <Link
                            href={urls.PRIVACY_POLICY_URL}
                            inline
                            variant="external"
                            externalIcon={false}
                          >
                            Privacy Policy
                          </Link>{' '}
                          and{' '}
                          <Link
                            href={urls.TERMS_URL}
                            inline
                            variant="external"
                            externalIcon={false}
                          >
                            Terms & Conditions
                          </Link>
                          .
                        </span>
                      }
                      control={
                        <Checkbox
                          checked={data.hasAcceptedPrivacyAndTerms}
                          onChange={handleCheckedChange(
                            'hasAcceptedPrivacyAndTerms',
                          )}
                          name="hasAcceptedPrivacyAndTerms"
                        />
                      }
                    />
                    {errors.hasAcceptedPrivacyAndTerms ? (
                      <FormHelperText>
                        {errors.hasAcceptedPrivacyAndTerms}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                  <FormControl
                    className={classes.terms}
                    error={!!errors.hasAcceptedPlatformTerms}
                  >
                    <FormControlLabel
                      label={
                        <span>
                          l have read, understood and I agree to Goodwin's{' '}
                          <Link
                            href="/goodwin-embark-disclaimer/"
                            inline
                            variant="external"
                            externalIcon={false}
                          >
                            disclaimer notice
                          </Link>
                          .
                        </span>
                      }
                      control={
                        <Checkbox
                          checked={data.hasAcceptedPlatformTerms}
                          onChange={handleCheckedChange(
                            'hasAcceptedPlatformTerms',
                          )}
                          name="hasAcceptedPlatformTerms"
                        />
                      }
                    />
                    {errors.hasAcceptedPlatformTerms ? (
                      <FormHelperText>
                        {errors.hasAcceptedPlatformTerms}
                      </FormHelperText>
                    ) : null}
                  </FormControl>

                  <div className={classes.submit}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="primary"
                      disabled={isSubmitDisabled}
                      loading={complete || submitting}
                      htmlProps={{
                        style: {
                          maxWidth: '100%',
                        },
                      }}
                    >
                      Continue
                    </Button>
                  </div>
                </UIKitSettingsProvider>
              </form>
            </div>
          </SignInPageContainer>
        </div>
      </Fade>
    </>
  );
}
