import axios from 'axios';

export const MAX_SMALL_FILE_UPLOAD_SIZE = 32 * 1024 * 1024; // 32MB
export const MAX_LARGE_FILE_UPLOAD_SIZE = 200 * 1024 * 1024; // 200MB

export function uploadSmallFile(file, uploadUrl) {
  const formData = new FormData();
  formData.append('file', file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

  return axios.put(uploadUrl, formData, config);
}

const uploadLargeFile = (file, uploadUrl) => {
  const formData = new FormData();
  formData.append('file', file);

  function generateSignedUrl() {
    return axios.post(uploadUrl, { name: file.name });
  }

  function uploadToGoogle({ data }) {
    return axios
      .put(data.url, formData, {
        withCredentials: false,
        headers: {
          'X-Goog-Content-Length-Range': `0,${MAX_LARGE_FILE_UPLOAD_SIZE}`,
          'content-type': 'multipart/form-data',
          'x-csrftoken': null,
        },
      })
      .then(() => axios.get(uploadUrl, { params: { file_id: data.file_id } }));
  }

  return generateSignedUrl().then(uploadToGoogle);
};

export const uploadFile = (file, url) => {
  if (file.size > MAX_SMALL_FILE_UPLOAD_SIZE) {
    return uploadLargeFile(file, `${url}large/`);
  }
  return uploadSmallFile(file, url);
};
