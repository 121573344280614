import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import { formatApprovedDomainEmailError } from 'services/approved_domains';

const useStyles = makeStyles((theme) => ({
  input: {
    margin: theme.spacing(1, 0),
  },
  helperText: {
    marginBottom: theme.spacing(2),
  },
  radio: {
    color: theme.palette.primary.black,
  },
  radioGroup: {
    marginTop: theme.spacing(2),
  },
  radioLabel: {
    margin: theme.spacing(1),
  },
  radioGroupLabel: {
    // It's !important because otherwise the label turns green
    // when a radio button is selected.
    color: `${theme.palette.primary.black} !important`,
    marginBottom: theme.spacing(1),
  },
}));

export function CreateContent({ formData, formErrors, setFormData, onSubmit }) {
  const classes = useStyles();

  return (
    <form id="add-member-form" onSubmit={onSubmit}>
      <Typography className={classes.helperText}>
        Team member will be notified by email.
      </Typography>
      <TextField
        autoFocus
        className={classes.input}
        id="name"
        variant="outlined"
        label="Name"
        type="text"
        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        value={formData.name}
        error={!!formErrors.name}
        helperText={formErrors.name}
        fullWidth
      />
      <TextField
        className={classes.input}
        id="email"
        variant="outlined"
        label="Email"
        type="email"
        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        value={formData.email}
        error={!!formErrors.email}
        helperText={formatApprovedDomainEmailError(formErrors)}
        fullWidth
      />
      <FormControl
        error={!!formErrors.level}
        component="fieldset"
        fullWidth
        className={classes.radioGroup}
      >
        <FormLabel component="legend" className={classes.radioGroupLabel}>
          Access level
        </FormLabel>
        <RadioGroup
          aria-label="access"
          name="access"
          value={formData.level}
          onChange={(e) => setFormData({ ...formData, level: e.target.value })}
        >
          <FormControlLabel
            value="EDIT"
            control={<Radio className={classes.radio} />}
            data-test="edit-radio"
            label={
              <div className={classes.radioLabel}>
                <Typography variant="body1">Editor</Typography>
                <Typography variant="body2">
                  Full permissions to the fund and managing access.
                </Typography>
              </div>
            }
          />
          <FormControlLabel
            value="VIEW"
            control={<Radio className={classes.radio} />}
            label={
              <div className={classes.radioLabel}>
                <Typography variant="body1">Viewer</Typography>
                <Typography variant="body2">
                  Ability to view all fund data.
                </Typography>
              </div>
            }
          />
        </RadioGroup>
        {formErrors.level ? (
          <FormHelperText>{formErrors.level}</FormHelperText>
        ) : null}
      </FormControl>
    </form>
  );
}
