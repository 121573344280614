import React from 'react';
import { Answers } from '../answers';

export function AffirmationQuestion({
  updateLpDoc,
  answer,
  label,
  QuestionStepper,
  QuestionPromptComponent,
  compact,
}) {
  const confirmed = answer;

  function handleSubmit(e) {
    e.preventDefault();
    return updateLpDoc({ label, answer: true });
  }

  function AffirmNavigation({ onNextClick, disableButton, ContinueButton }) {
    return (
      <>
        <ContinueButton disabled={disableButton} onClick={onNextClick}>
          {confirmed === true ? 'Confirm' : 'Continue'}
        </ContinueButton>
      </>
    );
  }

  return (
    <>
      <QuestionPromptComponent />
      <Answers compact={compact}>
        <QuestionStepper
          disabled={false}
          handleSubmit={handleSubmit}
          hasAnswerChanged={false}
          hasInput={false}
          ReplacementNavigation={AffirmNavigation}
        />
      </Answers>
    </>
  );
}
