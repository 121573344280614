import React from 'react';
import { FieldContainer, Field } from './fields';

export const BankDisplay = ({ answer }) => (
  <FieldContainer>
    <Field label="Bank name" labelMinWidth={180}>
      {answer?.bankName || '-'}
    </Field>
    <Field label="Account name">{answer?.accountName || '-'}</Field>
    <Field label="Account number">{answer?.accountNumber || '-'}</Field>
    <Field label="ABA (routing number)">{answer?.aba || '-'}</Field>
    <Field label="Swift">{answer?.swift || '-'}</Field>
    <Field label="For further credit">{answer?.ffct || '-'}</Field>
  </FieldContainer>
);
