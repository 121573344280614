import React from 'react';

import { Typography } from '@passthrough/uikit';
import { SettingsGroup, Setting } from 'components/settings_group';
import { JURISDICTIONS } from 'components/jurisdiction_select';

import { Member } from './member';

function Jurisdiction({ diligenceJurisdiction }) {
  return (
    <Setting label="Jurisdiction">
      <Typography>
        {JURISDICTIONS.find((j) => j.id === diligenceJurisdiction).name}
      </Typography>
    </Setting>
  );
}

export function DiligenceSettings({
  members,
  diligenceEnabled,
  diligenceJurisdiction,
  defaultDiligenceApproverId,
  onEdit,
  readOnly,
}) {
  if (!diligenceEnabled) {
    return null;
  }

  return (
    <SettingsGroup header="Diligence" onEdit={onEdit} readOnly={readOnly}>
      <Jurisdiction diligenceJurisdiction={diligenceJurisdiction} />
      <Member
        optional={false}
        members={members}
        memberId={defaultDiligenceApproverId}
        display="diligence reviewer"
      />
    </SettingsGroup>
  );
}
