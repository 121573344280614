import {
  TYPE_IRA,
  TYPE_INDIVIDUAL,
  TYPE_PRIVATE_TRUST,
  TYPE_PENSION_SCHEME,
  TYPE_PRIVATE_COMPANY,
  TYPE_LISTED_COMPANY,
  TYPE_LIMITED_PARTNERSHIP,
  TYPE_JOINT_ACCOUNT,
  TYPE_LLC,
  TYPE_CHARITY_OR_FOUNDATION,
  TYPE_SCHOOL,
  TYPE_S_CORP,
  TYPE_BENEFICIARIES,
  TYPE_HOLDERS,
  TYPE_PARTNERS,
  TYPE_GPS,
  TYPE_DIRECTORS,
  TYPE_BENEFICIAL_OWNERS,
  TYPE_ULTIMATE_BENEFICIAL_OWNERS,
  TYPE_MANAGING_MEMBERS,
  TYPE_MEMBERS,
  TYPE_TRUSTEES,
  TYPE_GRANTORS,
  TYPE_SIGNATORIES,
  TYPE_RECIPIENTS,
  TYPE_OFFICIALS,
  ownerTypesToLabels,
  TYPE_VALID_ID,
  TYPE_PROOF_OF_ADDRESS,
  TYPE_LPA,
  TYPE_COP,
  TYPE_OWNERSHIP_SCHEDULE,
  TYPE_SOB,
  TYPE_AML_LETTER,
  TYPE_COI,
  TYPE_AOA,
  TYPE_REGISTER_OF_DIRECTORS,
  TYPE_REGISTER_OF_SHAREHOLDERS,
  TYPE_EVIDENCE_OF_LISTING,
  TYPE_LETTER_FROM_EXTERNAL_AUDITOR,
  TYPE_ORG_CHART,
  TYPE_DOM,
  TYPE_COF,
  TYPE_OPERATING_AGREEMENT,
  TYPE_TRUST_DEED,
  TYPE_CERTIFICATE_OF_TRUST,
  TYPE_SCHEDULE_OF_TRUSTEES,
  TYPE_FORMATION_DOCUMENT,
  TYPE_COPOC,
  TYPE_COAS,
  TYPE_AUDITED_FINANCIALS,
  TYPE_DONORS_OVER_1OK,
  TYPE_CONFIRMATION_OF_OWNERSHIP,
  TYPE_DETAILS_OF_MAIN_OFFICIALS,
  TYPE_SCHEDULE_OF_MAJOR_DONORS,
  TYPE_PARTNERSHIP,
  docTypesToLabels,
  TYPE_PARTNERSHIP_AGREEMENT,
  TYPE_COR,
  TYPE_STRUCTURE_CHART,
  TYPE_ASL,
  GROUP_NONE,
} from '../../constants';

export const USA_REQUIREMENTS = {
  [GROUP_NONE]: {
    [TYPE_INDIVIDUAL]: {
      documents: [
        {
          key: TYPE_VALID_ID,
          label: docTypesToLabels[TYPE_VALID_ID],
          helpText:
            'Upload your valid proof of identification; for foreign (non-US) investors, upload a photo of your valid passport.',
        },
        {
          key: TYPE_PROOF_OF_ADDRESS,
          label: docTypesToLabels[TYPE_PROOF_OF_ADDRESS],
          helpText:
            'Provide a utility bill, bank statement or similar dated within the last 12 months. A utility bill dated in the last 3 months is preferable, and may be required for non-US funds. This data is strictly used for address matching.',
        },
      ],
      owners: [],
    },
    [TYPE_LIMITED_PARTNERSHIP]: {
      documents: [
        {
          key: TYPE_LPA,
          label: docTypesToLabels[TYPE_LPA],
          helpText:
            'Upload a signed copy of the limited partnership’s limited partnership agreement.',
        },
        {
          key: TYPE_COP,
          label: docTypesToLabels[TYPE_COP],
          helpText:
            'Upload the limited partnership’s certificate of partnership.',
        },
        {
          key: TYPE_OWNERSHIP_SCHEDULE,
          label: docTypesToLabels[TYPE_OWNERSHIP_SCHEDULE],
          helpText:
            'Upload a full schedule indicating the ownership of the limited partnership’s partners.',
        },
      ],
      owners: [
        {
          key: TYPE_PARTNERS,
          singularLabel: ownerTypesToLabels[TYPE_PARTNERS].single,
          pluralLabel: ownerTypesToLabels[TYPE_PARTNERS].plural,
          helpText:
            'Add the names of all partners of 25% or more of the limited partnership.',
        },
        {
          key: TYPE_GPS,
          singularLabel: ownerTypesToLabels[TYPE_GPS].single,
          pluralLabel: ownerTypesToLabels[TYPE_GPS].plural,
          helpText:
            'Add the names of all general partners of 25% or more of the limited partnership.',
        },
      ],
    },
    [TYPE_PARTNERSHIP]: {
      documents: [
        {
          key: TYPE_COR,
          label: docTypesToLabels[TYPE_COR],
          helpText:
            'Upload the certificate of registration or equivalent for the partnership.',
        },
        {
          key: TYPE_PARTNERSHIP_AGREEMENT,
          label: docTypesToLabels[TYPE_PARTNERSHIP_AGREEMENT],
          helpText: 'Upload the partnership agreement.',
        },
        {
          key: TYPE_STRUCTURE_CHART,
          label: docTypesToLabels[TYPE_STRUCTURE_CHART],
          optional: true,
          helpText: 'Upload the partnership’s structure chart.',
        },
        {
          key: TYPE_ASL,
          label: docTypesToLabels[TYPE_ASL],
          helpText: 'Upload the partnership’s authorized signatory list.',
        },
      ],
      owners: [
        {
          key: TYPE_BENEFICIAL_OWNERS,
          singularLabel: ownerTypesToLabels[TYPE_BENEFICIAL_OWNERS].single,
          pluralLabel: ownerTypesToLabels[TYPE_BENEFICIAL_OWNERS].plural,
          helpText:
            'Add the names of all beneficial owners of 25% or more of the partnership.',
        },
        {
          key: TYPE_PARTNERS,
          singularLabel: ownerTypesToLabels[TYPE_PARTNERS].single,
          pluralLabel: ownerTypesToLabels[TYPE_PARTNERS].plural,
          helpText: 'Add the names of all partners of the partnership.',
        },
        {
          key: TYPE_SIGNATORIES,
          singularLabel: ownerTypesToLabels[TYPE_SIGNATORIES].single,
          pluralLabel: ownerTypesToLabels[TYPE_SIGNATORIES].plural,
          helpText: 'Add the names of all signatories of the partnership.',
        },
      ],
    },
    [TYPE_JOINT_ACCOUNT]: {
      documents: [
        {
          key: TYPE_SOB,
          label: docTypesToLabels[TYPE_SOB],
          helpText: 'Upload a schedule of the joint account’s beneficiaries.',
        },
      ],
      owners: [
        {
          key: TYPE_HOLDERS,
          singularLabel: ownerTypesToLabels[TYPE_HOLDERS].single,
          pluralLabel: ownerTypesToLabels[TYPE_HOLDERS].plural,
          helpText:
            'Add the names of all joint account holders of the joint account.',
          minNum: 2,
        },
        {
          key: TYPE_BENEFICIARIES,
          singularLabel: ownerTypesToLabels[TYPE_BENEFICIARIES].single,
          pluralLabel: ownerTypesToLabels[TYPE_BENEFICIARIES].plural,
          helpText: 'Add the names of all beneficiaries of the joint account.',
        },
      ],
    },
    [TYPE_IRA]: {
      documents: [
        {
          key: TYPE_AML_LETTER,
          label: docTypesToLabels[TYPE_AML_LETTER],
          helpText:
            'Upload a letter attesting that there is not substantive money laundering activity associated with the IRA.',
        },
      ],
      owners: [],
    },
    [TYPE_PRIVATE_COMPANY]: {
      documents: [
        {
          key: TYPE_COI,
          label: docTypesToLabels[TYPE_COI],
          helpText: 'Upload the company’s certificate of incorporation.',
        },
        {
          key: TYPE_AOA,
          label: docTypesToLabels[TYPE_AOA],
          helpText:
            'Upload the company’s memorandum and articles of association.',
        },
        {
          key: TYPE_REGISTER_OF_DIRECTORS,
          label: docTypesToLabels[TYPE_REGISTER_OF_DIRECTORS],
          helpText: 'Upload the company’s register of directors.',
        },
        {
          key: TYPE_REGISTER_OF_SHAREHOLDERS,
          label: docTypesToLabels[TYPE_REGISTER_OF_SHAREHOLDERS],
          helpText: 'Upload the company’s register of shareholders.',
        },
      ],
      owners: [
        {
          key: TYPE_DIRECTORS,
          singularLabel: ownerTypesToLabels[TYPE_DIRECTORS].single,
          pluralLabel: ownerTypesToLabels[TYPE_DIRECTORS].plural,
          helpText:
            'Add the names of all directors and signatories of the private company.',
        },
        {
          key: TYPE_BENEFICIAL_OWNERS,
          singularLabel: ownerTypesToLabels[TYPE_BENEFICIAL_OWNERS].single,
          pluralLabel: ownerTypesToLabels[TYPE_BENEFICIAL_OWNERS].plural,
          helpText:
            'Add the names of all beneficial owners of 25% or more of the private company.',
        },
      ],
    },
    [TYPE_LISTED_COMPANY]: {
      documents: [
        {
          key: TYPE_EVIDENCE_OF_LISTING,
          label: docTypesToLabels[TYPE_EVIDENCE_OF_LISTING],
          helpText:
            'Upload evidence of the company’s listing on a recognized stock exchange.',
        },
        {
          key: TYPE_LETTER_FROM_EXTERNAL_AUDITOR,
          label: docTypesToLabels[TYPE_LETTER_FROM_EXTERNAL_AUDITOR],
          helpText:
            'Upload a letter of good standing from an external auditor.',
        },
        {
          key: TYPE_ORG_CHART,
          label: docTypesToLabels[TYPE_ORG_CHART],
          helpText:
            'Upload a copy of the listed company’s organizational chart.',
        },
      ],
      owners: [
        {
          key: TYPE_ULTIMATE_BENEFICIAL_OWNERS,
          singularLabel:
            ownerTypesToLabels[TYPE_ULTIMATE_BENEFICIAL_OWNERS].single,
          pluralLabel:
            ownerTypesToLabels[TYPE_ULTIMATE_BENEFICIAL_OWNERS].plural,
          helpText:
            'Add the names of all of the ultimate beneficial owners of the listed company.',
        },
      ],
    },
    [TYPE_LLC]: {
      documents: [
        {
          key: TYPE_COF,
          label: docTypesToLabels[TYPE_COF],
          helpText:
            'Upload a copy of the limited liability company’s certificate of formation.',
        },
        {
          key: TYPE_OPERATING_AGREEMENT,
          label: docTypesToLabels[TYPE_OPERATING_AGREEMENT],
          helpText:
            'Upload a copy of the limited liability company’s operating agreement.',
        },
        {
          key: TYPE_DOM,
          label: docTypesToLabels[TYPE_DOM],
          helpText:
            'Upload a schedule indicating the ownership of all managers of the LLC.',
        },
        {
          key: TYPE_ORG_CHART,
          label: docTypesToLabels[TYPE_ORG_CHART],
          helpText:
            'Upload a copy of the limited liability company’s organizational chart.',
        },
      ],
      owners: [
        {
          key: TYPE_MANAGING_MEMBERS,
          singularLabel: ownerTypesToLabels[TYPE_MANAGING_MEMBERS].single,
          pluralLabel: ownerTypesToLabels[TYPE_MANAGING_MEMBERS].plural,
          helpText:
            'Add the names of all of the managing members of 25% or more of the limited liability company.',
        },
        {
          key: TYPE_MEMBERS,
          singularLabel: ownerTypesToLabels[TYPE_MEMBERS].single,
          pluralLabel: ownerTypesToLabels[TYPE_MEMBERS].plural,
          helpText:
            'Add the names of all of the members of 25% or more of the limited liability company.',
        },
      ],
    },
    [TYPE_PRIVATE_TRUST]: {
      documents: [
        {
          key: TYPE_TRUST_DEED,
          label: docTypesToLabels[TYPE_TRUST_DEED],
          helpText: 'Upload a copy of the private trust’s deed.',
        },
        {
          key: TYPE_CERTIFICATE_OF_TRUST,
          label: docTypesToLabels[TYPE_CERTIFICATE_OF_TRUST],
          helpText: 'Upload a copy of the trust’s certificate of trust.',
        },
        {
          key: TYPE_SCHEDULE_OF_TRUSTEES,
          label: docTypesToLabels[TYPE_SCHEDULE_OF_TRUSTEES],
          helpText: 'Upload a schedule of all of the trust’s trustees.',
        },
      ],
      owners: [
        {
          key: TYPE_TRUSTEES,
          singularLabel: ownerTypesToLabels[TYPE_TRUSTEES].single,
          pluralLabel: ownerTypesToLabels[TYPE_TRUSTEES].plural,
          helpText:
            'Add the names of all of the trustees of the private trust.',
        },
        {
          key: TYPE_GRANTORS,
          singularLabel: ownerTypesToLabels[TYPE_GRANTORS].single,
          pluralLabel: ownerTypesToLabels[TYPE_GRANTORS].plural,
          helpText: 'Add the names of all grantors of the private trust.',
        },
        {
          key: TYPE_BENEFICIARIES,
          singularLabel: ownerTypesToLabels[TYPE_BENEFICIARIES].single,
          pluralLabel: ownerTypesToLabels[TYPE_BENEFICIARIES].plural,
          helpText: 'Add the names of all beneficiaries of the private trust.',
        },
      ],
    },
    [TYPE_PENSION_SCHEME]: {
      documents: [
        {
          key: TYPE_FORMATION_DOCUMENT,
          label: docTypesToLabels[TYPE_FORMATION_DOCUMENT],
          helpText: 'Upload a copy of the pension scheme’s formation document.',
        },
        {
          key: TYPE_AML_LETTER,
          label: docTypesToLabels[TYPE_AML_LETTER],
          helpText:
            'Upload a letter attesting that there is no substantive money laundering activity associated with the pension scheme.',
        },
      ],
      owners: [
        {
          key: TYPE_TRUSTEES,
          singularLabel: ownerTypesToLabels[TYPE_TRUSTEES].single,
          pluralLabel: ownerTypesToLabels[TYPE_TRUSTEES].plural,
          helpText: 'Add the names of all trustees of the pension scheme.',
        },
        {
          key: TYPE_SIGNATORIES,
          singularLabel: ownerTypesToLabels[TYPE_SIGNATORIES].single,
          pluralLabel: ownerTypesToLabels[TYPE_SIGNATORIES].plural,
          helpText: 'Add the names of all signatories of the pension scheme.',
        },
      ],
    },
    [TYPE_CHARITY_OR_FOUNDATION]: {
      documents: [
        {
          key: TYPE_FORMATION_DOCUMENT,
          label: docTypesToLabels[TYPE_FORMATION_DOCUMENT],
          helpText:
            'Upload a copy of the charity or foundation’s formation document.',
        },
        {
          key: TYPE_COPOC,
          label: docTypesToLabels[TYPE_COPOC],
          helpText:
            'Upload a confirmation of the charity or foundation’s nature or purpose.',
        },
        {
          key: TYPE_COAS,
          label: docTypesToLabels[TYPE_COAS],
          helpText:
            'Upload a confirmation of the charity or foundation’s approved status.',
        },
        {
          key: TYPE_AUDITED_FINANCIALS,
          label: docTypesToLabels[TYPE_AUDITED_FINANCIALS],
          helpText:
            'Upload a copy of the charity or foundation’s audited financial statements.',
        },
        {
          key: TYPE_LETTER_FROM_EXTERNAL_AUDITOR,
          label: docTypesToLabels[TYPE_LETTER_FROM_EXTERNAL_AUDITOR],
          helpText:
            'Upload a letter of good standing from an external auditor.',
        },
        {
          key: TYPE_AML_LETTER,
          label: docTypesToLabels[TYPE_AML_LETTER],
          helpText:
            'Upload a letter attesting there is not substantive money laundering activity associated with the charity or foundation.',
        },
        {
          key: TYPE_DONORS_OVER_1OK,
          label: docTypesToLabels[TYPE_DONORS_OVER_1OK],
          helpText:
            'Upload a schedule of the charity’s major donors of more than $10,000.',
        },
      ],
      owners: [
        {
          key: TYPE_TRUSTEES,
          singularLabel: ownerTypesToLabels[TYPE_TRUSTEES].single,
          pluralLabel: ownerTypesToLabels[TYPE_TRUSTEES].plural,
          helpText:
            'Add the names of all trustees of the charity or foundation.',
        },
        {
          key: TYPE_DIRECTORS,
          singularLabel: ownerTypesToLabels[TYPE_DIRECTORS].single,
          pluralLabel: ownerTypesToLabels[TYPE_DIRECTORS].plural,
          helpText:
            'Add the names of all directors of the charity or foundation.',
        },
        {
          key: TYPE_BENEFICIARIES,
          singularLabel: ownerTypesToLabels[TYPE_BENEFICIARIES].single,
          pluralLabel: ownerTypesToLabels[TYPE_BENEFICIARIES].plural,
          helpText:
            'Add the names of all beneficiaries of the charity or foundation.',
        },

        {
          key: TYPE_RECIPIENTS,
          singularLabel: ownerTypesToLabels[TYPE_RECIPIENTS].single,
          pluralLabel: ownerTypesToLabels[TYPE_RECIPIENTS].plural,
          helpText:
            'Add the names of all primary recipients of funds of the charity or foundation.',
        },
      ],
    },
    [TYPE_SCHOOL]: {
      documents: [
        {
          key: TYPE_FORMATION_DOCUMENT,
          label: docTypesToLabels[TYPE_FORMATION_DOCUMENT],
          helpText:
            'Upload a copy of the school, college, or university’s constitution or formation document.',
        },
        {
          key: TYPE_CONFIRMATION_OF_OWNERSHIP,
          label: docTypesToLabels[TYPE_CONFIRMATION_OF_OWNERSHIP],
          helpText:
            'Upload a document confirming the school, college, or university’s ownership.',
        },
        {
          key: TYPE_DETAILS_OF_MAIN_OFFICIALS,
          label: docTypesToLabels[TYPE_DETAILS_OF_MAIN_OFFICIALS],
          helpText:
            'Upload a schedule of all of the main officials of the school, college, or university, including any person responsible for making investments on behalf of the institution.',
        },
        {
          key: TYPE_SCHEDULE_OF_MAJOR_DONORS,
          label: docTypesToLabels[TYPE_SCHEDULE_OF_MAJOR_DONORS],
          helpText:
            'Upload a schedule of the school, college, or university’s major donors.',
        },
        {
          key: TYPE_ORG_CHART,
          label: docTypesToLabels[TYPE_ORG_CHART],
          helpText:
            'Upload a copy of the school, college, or university’s organizational chart.',
        },
      ],
      owners: [
        {
          key: TYPE_BENEFICIARIES,
          singularLabel: ownerTypesToLabels[TYPE_BENEFICIARIES].single,
          pluralLabel: ownerTypesToLabels[TYPE_BENEFICIARIES].plural,
          helpText:
            'Add the names of all beneficiaries of the school, college, or university.',
        },
        {
          key: TYPE_OFFICIALS,
          singularLabel: ownerTypesToLabels[TYPE_OFFICIALS].single,
          pluralLabel: ownerTypesToLabels[TYPE_OFFICIALS].plural,
          helpText:
            'Add the names of all officials of the school, college, or university.',
        },
      ],
    },
    [TYPE_S_CORP]: {
      documents: [
        {
          key: TYPE_COI,
          label: docTypesToLabels[TYPE_COI],
          helpText: 'Upload the S-corp’s certificate of incorporation.',
        },
        {
          key: TYPE_AOA,
          label: docTypesToLabels[TYPE_AOA],
          helpText:
            'Upload the S-corp’s memorandum and articles of association.',
        },
        {
          key: TYPE_REGISTER_OF_DIRECTORS,
          label: docTypesToLabels[TYPE_REGISTER_OF_DIRECTORS],
          helpText: 'Upload the S-corp’s register of directors.',
        },
        {
          key: TYPE_REGISTER_OF_SHAREHOLDERS,
          label: docTypesToLabels[TYPE_REGISTER_OF_SHAREHOLDERS],
          helpText: 'Upload the S-corp’s register of shareholders.',
        },
      ],
      owners: [
        {
          key: TYPE_DIRECTORS,
          singularLabel: ownerTypesToLabels[TYPE_DIRECTORS].single,
          pluralLabel: ownerTypesToLabels[TYPE_DIRECTORS].plural,
          helpText: 'Add the names of all directors of the S-corp.',
        },
        {
          key: TYPE_BENEFICIAL_OWNERS,
          singularLabel: ownerTypesToLabels[TYPE_BENEFICIAL_OWNERS].single,
          pluralLabel: ownerTypesToLabels[TYPE_BENEFICIAL_OWNERS].plural,
          helpText:
            'Add the names of all beneficial owners of 25% or more of the S-corp.',
        },
      ],
    },
  },
};
