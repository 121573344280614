import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Document, Page } from 'react-pdf';

import { Spinner } from 'components/spinner';
import { Zoom } from 'components/zoom';
import { PDFReviewStepper } from 'components/pdf_review_stepper';
import { Typography } from '@passthrough/uikit';

import * as constants from './constants';
import { EditTool } from './tool';
import { FieldPanel } from './field_panel';
import { throttle } from './utils';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: '1',
  },
  documentBackground: {
    backgroundColor: theme.palette.divider,
    border: `1px solid ${theme.palette.divider}`,
    borderBottom: 'none',
  },
  reactPdfPage: {
    // This allows you to scroll the page past the end, showing the
    // dark gray document background under the page. Maybe this is
    // to make it easier to place boxes at the bottom of the page.
    marginBottom: theme.spacing(16),
    backgroundColor: 'inherit !important',
  },
  reactPdfDocument: {
    position: 'absolute',
    marginTop: theme.spacing(2),
  },
  pageContainer: {
    overflow: 'scroll',
    position: 'relative',
    minWidth: `${constants.PAGE_CONTAINER_MIN_WIDTH}px`,
    minHeight: `${constants.PAGE_CONTAINER_MIN_HEIGHT}px`,
    userSelect: 'none',
  },
  docToolBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  displayNameContainer: {
    flex: '1',
    overflow: 'hidden',
  },
  ellipsisText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  zoomContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: '1',
  },
  documentPadding: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    padding: `${constants.DOCUMENT_PADDING}px`, // === theme.spacing(3)
    paddingBottom: 0,
  },
  pointerCursor: {
    cursor: 'pointer',
  },
}));

export function DocumentFields({
  file,
  state,
  actions,
  mode,
  signers,
  displayName = null,
  // The below props are used by the document upload modal.
  // TODO: Further decouple DocumentFields from document upload by
  // moving these values into state/actions.
  selectedSigner = null,
  selectedBoxType = null,
  setSelectedBoxType = () => null,
  allowInvestorType = false,
}) {
  const classes = useStyles();

  const [pdf, setPdf] = useState(null);
  const [zoom, setZoom] = useState(1.0); // Start with 100%

  // The page container div is created after the file finishes loading. The
  // EditTool component sets a mousedown listener on this div for placing boxes.
  const pageContainerRef = useRef(null);

  // Computed width and height in pixels of the page container div, should be
  // exactly enough to use the rest of the available space on the screen.
  const [pageContainerWidth, setPageContainerWidth] = useState(0);
  const [pageContainerHeight, setPageContainerHeight] = useState(0);

  // Width and height in pixels of the actual pdf page
  const [docWidth, setDocWidth] = useState(0);
  const [docHeight, setDocHeight] = useState(0);

  const baseScale = docWidth > 0 ? pageContainerWidth / docWidth : 1.0;

  const [pdfLoading, setPdfLoading] = useState(true);
  useEffect(() => setPdfLoading(true), [file.fileUrl]);

  function updateDimensions() {
    const pageContainerDiv = pageContainerRef.current;

    // Calculate the desired dimensions of pageContainerDiv.
    const spaceBetweenPanels = window.innerWidth - constants.PANEL_WIDTH * 2;
    const computedWidth = spaceBetweenPanels - constants.DOCUMENT_PADDING * 2;
    const finalWidth = Math.max(
      computedWidth,
      constants.PAGE_CONTAINER_MIN_WIDTH,
    );
    setPageContainerWidth(finalWidth);

    const computedHeight = pageContainerDiv
      ? // Total window height - height of content above pageContainerDiv
        window.innerHeight - pageContainerDiv.getBoundingClientRect().top
      : // Guess for the spinner height while loading the pdf
        window.innerHeight - 150;
    const finalHeight = Math.max(
      computedHeight,
      constants.PAGE_CONTAINER_MIN_HEIGHT,
    );
    setPageContainerHeight(finalHeight);
  }
  useEffect(updateDimensions, [pageContainerRef.current]);

  // Calculate the page container dimensions again if the window is resized.
  useEffect(() => {
    const throttledUpdateDimensions = throttle(updateDimensions);

    window.addEventListener('resize', throttledUpdateDimensions);
    return () => {
      // Cancels any requested animation frame
      throttledUpdateDimensions.cancel();
      window.removeEventListener('resize', throttledUpdateDimensions);
    };
  }, []);

  const { activeBox, boxToPlace, currentPage } = state;

  // Get the current page's width and height from the pdf service worker
  useEffect(() => {
    if (pdf) {
      pdf.getPage(currentPage).then((p) => {
        const pdfWidth = p.view[2];
        const pdfHeight = p.view[3];
        setDocWidth(pdfWidth);
        setDocHeight(pdfHeight);
      });
    }
  }, [pdf, currentPage]);

  // Registration of all key handlers: arrow keys move the active box,
  // the delete key deletes the active box.
  useEffect(() => {
    const processKeyDown = (event) => {
      if (activeBox) {
        switch (event.key) {
          case 'ArrowUp':
            actions.translateBox({
              boxId: activeBox.id,
              xDiff: 0,
              yDiff: -constants.ARROW_KEY_STEP_SIZE,
              docHeight,
              docWidth,
            });
            break;
          case 'ArrowRight':
            actions.translateBox({
              boxId: activeBox.id,
              xDiff: constants.ARROW_KEY_STEP_SIZE,
              yDiff: 0,
              docHeight,
              docWidth,
            });
            break;
          case 'ArrowDown':
            actions.translateBox({
              boxId: activeBox.id,
              xDiff: 0,
              yDiff: constants.ARROW_KEY_STEP_SIZE,
              docHeight,
              docWidth,
            });
            break;
          case 'ArrowLeft':
            actions.translateBox({
              boxId: activeBox.id,
              xDiff: -constants.ARROW_KEY_STEP_SIZE,
              yDiff: 0,
              docHeight,
              docWidth,
            });
            break;
          default:
            break;
        }
      }
    };

    const processKeyUp = (event) => {
      const deleteAction =
        mode === constants.BOX_CREATION_MODE
          ? actions.deleteBox
          : actions.removePlacedBox;
      // The mac delete key is actually the backspace key
      const isDeleteKey =
        event.keyCode === constants.BACKSPACE_KEY_CODE ||
        event.keyCode === constants.DELETE_KEY_CODE;

      if (activeBox && isDeleteKey) {
        deleteAction(activeBox.id);
      }
    };
    window.addEventListener('keyup', processKeyUp);
    window.addEventListener('keydown', processKeyDown);

    return () => {
      window.removeEventListener('keyup', processKeyUp);
      window.removeEventListener('keydown', processKeyDown);
    };
  }, [activeBox, actions, mode]);

  if (!constants.VALID_MODES.includes(mode)) {
    throw new Error(`Invalid mode for DocumentFields: ${mode}`);
  }

  // We have to explicitly set dimensions for certain elements because they
  // have a tendency to take up way too much space.
  // TODO: Figure out how to fix the css so we don't have to calculate
  // pageContainerWidth and pageContainerHeight.
  const toolBarStyle = {
    width: `${pageContainerWidth}px`,
  };
  const pageContainerStyle = {
    width: `${pageContainerWidth}px`,
    height: `${pageContainerHeight}px`,
  };

  // cursor=pointer on page if we're placing a box
  const showPointer = (selectedBoxType || boxToPlace) && !activeBox;

  return (
    <div className={classes.container}>
      <div className={classes.documentPadding}>
        <div className={classes.docToolBar} style={toolBarStyle}>
          {displayName ? (
            <div className={classes.displayNameContainer}>
              <Typography variant="body" className={classes.ellipsisText}>
                {displayName}
              </Typography>
            </div>
          ) : null}
          <PDFReviewStepper
            page={currentPage}
            setPage={actions.updatePage}
            numPages={pdf ? pdf.numPages : 1}
            useLeftAlign
            hideButtonLabels
          />
          <div className={classes.zoomContainer}>
            <Zoom zoom={zoom} setZoom={setZoom} />
          </div>
        </div>

        {/* This wrapper div is needed to keep Document, which is set
        to position: absolute, from covering docToolBar. */}
        <div>
          <Document
            className={clsx(classes.reactPdfDocument, {
              [classes.documentBackground]: !pdfLoading,
            })}
            file={file.fileUrl}
            onLoadSuccess={(p) => {
              setPdf(p);
              setPdfLoading(false);
            }}
            loading={
              <Spinner
                height={pageContainerHeight}
                width={pageContainerWidth}
              />
            }
          >
            <div
              ref={pageContainerRef}
              style={pageContainerStyle}
              className={clsx(classes.pageContainer, {
                [classes.pointerCursor]: showPointer,
              })}
            >
              <Page
                pageNumber={currentPage}
                loading={
                  <Spinner
                    height={pageContainerHeight}
                    width={pageContainerWidth}
                  />
                }
                scale={zoom * baseScale}
                className={classes.reactPdfPage}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
              <EditTool
                state={state}
                actions={actions}
                scale={zoom * baseScale}
                page={currentPage}
                selectedSigner={selectedSigner}
                selectedBoxType={selectedBoxType}
                setSelectedBoxType={setSelectedBoxType}
                docWidth={docWidth}
                docHeight={docHeight}
                pageContainerRef={pageContainerRef}
              />
            </div>
          </Document>
        </div>
      </div>

      <FieldPanel
        relevantSigners={signers}
        actions={actions}
        activeBox={activeBox}
        allowInvestorType={allowInvestorType}
        allowEditing={mode === constants.BOX_CREATION_MODE}
        boxToPlace={boxToPlace}
        selectedBoxType={selectedBoxType}
      />
    </div>
  );
}
