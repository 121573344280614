import React from 'react';
import CalendarToday from '@material-ui/icons/CalendarToday';
import CheckBox from '@material-ui/icons/CheckBox';
import TextRotationNoneIcon from '@material-ui/icons/TextRotationNone';
import SignatureFreehand from 'mdi-material-ui/SignatureFreehand';
import AlphabeticalVariant from 'mdi-material-ui/AlphabeticalVariant';
import PermIdentity from '@material-ui/icons/PermIdentity';
import CashMultiple from 'mdi-material-ui/CashMultiple';
import WorkOutline from '@material-ui/icons/WorkOutline';

export const BoxType = {
  TEXT: 'text',
  DATE: 'date_signed',
  CHECKBOX: 'checkbox',
  SIGNATURE: 'signature',
  NAME: 'fullname',
  TITLE: 'title',
  ACCEPTED_COMMITMENT: 'accepted_commitment',
  INITIALS: 'initials',
};

const fullBoxTypeList = [
  { type: BoxType.TEXT, icon: <TextRotationNoneIcon />, text: 'Text field' },
  { type: BoxType.DATE, icon: <CalendarToday />, text: 'Date' },
  { type: BoxType.CHECKBOX, icon: <CheckBox />, text: 'Checkbox' },
  { type: BoxType.SIGNATURE, icon: <SignatureFreehand />, text: 'Signature' },
  { type: BoxType.NAME, icon: <PermIdentity />, text: 'Name' },
  { type: BoxType.TITLE, icon: <WorkOutline />, text: 'Title' },
  {
    type: BoxType.ACCEPTED_COMMITMENT,
    icon: <CashMultiple />,
    text: 'Accepted commitment',
  },
  { type: BoxType.INITIALS, icon: <AlphabeticalVariant />, text: 'Initials' },
];

// cspell: words uncreatable
const UNCREATABLE_BOX_TYPES = [
  BoxType.NAME,
  BoxType.TITLE,
  BoxType.ACCEPTED_COMMITMENT,
];

function isCreatable(boxType) {
  return !UNCREATABLE_BOX_TYPES.includes(boxType.type);
}

export const boxTypeList = fullBoxTypeList.filter(isCreatable);

const boxTypeMap = fullBoxTypeList.reduce((acc, info) => {
  const { type } = info;
  acc[type] = info;
  return acc;
}, {});

const TYPE_OVERRIDE = {
  fullname: 'fullname',
  title: 'title',
};

export function getBoxInfo(box) {
  const derivedType = TYPE_OVERRIDE[box.name] || box.type;
  return boxTypeMap[derivedType];
}

const DEFAULT_CHECKBOX_SIZE = 15;
const DEFAULT_DATE_WIDTH = 57;
const DEFAULT_DATE_HEIGHT = 14;
const DEFAULT_SIGNATURE_WIDTH = 100;
const DEFAULT_SIGNATURE_HEIGHT = 36;
const DEFAULT_BOX_WIDTH = 100;
const DEFAULT_BOX_HEIGHT = 25;

export function getWidthFromBoxType(boxType, initialWidth = null) {
  switch (boxType) {
    case BoxType.CHECKBOX:
      return DEFAULT_CHECKBOX_SIZE;
    case BoxType.DATE:
      return DEFAULT_DATE_WIDTH;
    case BoxType.SIGNATURE:
      return DEFAULT_SIGNATURE_WIDTH;
    case BoxType.INITIALS:
      return DEFAULT_SIGNATURE_HEIGHT;
    default:
      return initialWidth || DEFAULT_BOX_WIDTH;
  }
}

export function getHeightFromBoxType(boxType, initialHeight = null) {
  switch (boxType) {
    case BoxType.CHECKBOX:
      return DEFAULT_CHECKBOX_SIZE;
    case BoxType.DATE:
      return DEFAULT_DATE_HEIGHT;
    case BoxType.SIGNATURE:
      return DEFAULT_SIGNATURE_HEIGHT;
    case BoxType.INITIALS:
      return DEFAULT_SIGNATURE_HEIGHT;
    default:
      return initialHeight || DEFAULT_BOX_HEIGHT;
  }
}
