export const COLUMN_MAPPING = {
  Investor: 'lpName',
  'Legal name': 'legalName',
  'Original commitment': 'commitment',
};
export const SUBSCRIPTION_DOCUMENT = 'Subscription document';
export const DELIVERY_OPTION_BULK_PACKET = 'bulk_packet';
export const DELIVERY_OPTION_INDIVIDUAL_PACKETS = 'individual_packets';

export const STEP_DATA_ENTRY = 'data_entry';
export const STEP_REVIEW = 'review';
export const STEP_DELIVERY_OPTION = 'delivery_option';

export const DATA_ENTRY_DISCARD_CHANGES_CONFIRMATION = {
  title: 'Discard unsaved data?',
  description:
    'Your changes have not been saved. This action cannot be undone.',
  confirmationText: 'Discard',
  destructive: true,
};

/*
There's nothing special about the "fullname" and "title" gp fields from the
questionnaire data being displayed here, i.e. there's no way to differentiate
a "fullname" field from a field with type "Other" and the name "fullname".
*/
export const COLUMN_NAME_OVERRIDE = {
  fullname: 'Full name',
  title: 'Title',
};
