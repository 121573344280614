import React from 'react';
import TextField from '@material-ui/core/TextField';

import { Modal } from '@passthrough/uikit';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useModalState } from '../hooks';
import { getIdFromName } from '../helpers';

export function ActionModal({
  action,
  onClose,
  changeAction,
  addAction,
  stateIds,
  deleteAction,
}) {
  const [
    editedAction,
    setEditedAction,
    headerLabel,
    open,
    onSubmit,
    isNew,
    deleteButton,
    showErrors,
  ] = useModalState({
    label: 'action',
    initialState: action,
    addObject: addAction,
    changeObject: changeAction,
    deleteObject: deleteAction,
    requiredFields: ['customActionText', 'fromState', 'toState'],
    onClose,
  });

  const filteredToStates = stateIds.filter((s) => s !== editedAction.fromState);
  const filteredFromStates = stateIds.filter((s) => s !== editedAction.toState);

  const customActionTextError = showErrors && !editedAction.customActionText;
  const fromStateError = showErrors && !editedAction.fromState;
  const toStateError = showErrors && !editedAction.toState;

  return (
    <Modal
      open={open}
      onClose={onClose}
      headerLabel={headerLabel}
      showCancelButton
      primaryButtonText="Save changes"
      onSubmit={onSubmit}
      tertiaryButton={deleteButton}
    >
      <TextField
        required
        id="custom-action-text"
        variant="outlined"
        label="Custom action text"
        type="text"
        value={editedAction.customActionText}
        onChange={(e) => {
          let newId = editedAction.id;
          if (isNew) {
            newId = getIdFromName(e.target.value);
          }

          setEditedAction({
            ...editedAction,
            customActionText: e.target.value,
            id: newId,
          });
        }}
        fullWidth
        error={customActionTextError}
        helperText={customActionTextError ? 'This field is required' : ''}
      />
      <TextField
        required
        id="ID"
        variant="outlined"
        label={isNew ? '' : 'ID'}
        type="text"
        value={editedAction.id || ''}
        disabled
        fullWidth
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={editedAction.isTask}
            onChange={(e) => {
              setEditedAction({ ...editedAction, isTask: e.target.checked });
            }}
            name="is_task"
          />
        }
        label="User has a task when this action is available"
      />
      <Autocomplete
        options={filteredFromStates}
        value={editedAction.fromState}
        onChange={(e, v) => {
          setEditedAction({ ...editedAction, fromState: v });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="From state"
            variant="outlined"
            fullWidth
            required
            inputProps={{
              ...params.inputProps,
              autoComplete: 'chrome-off',
            }}
            error={fromStateError}
            helperText={fromStateError ? 'This field is required' : ''}
          />
        )}
      />
      <Autocomplete
        options={filteredToStates}
        value={editedAction.toState}
        onChange={(e, v) => {
          setEditedAction({ ...editedAction, toState: v });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="To state"
            variant="outlined"
            fullWidth
            required
            inputProps={{
              ...params.inputProps,
              autoComplete: 'chrome-off',
            }}
            error={toStateError}
            helperText={toStateError ? 'This field is required' : ''}
          />
        )}
      />
    </Modal>
  );
}
