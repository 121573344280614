// @ts-check
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@passthrough/uikit';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderRadius: '4px',
    backgroundColor: theme.palette.neutral.fill,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      rowGap: theme.spacing(2),
    },
  },
  dense: {
    padding: theme.spacing(1, 2),
  },
  action: {
    alignSelf: 'self-start',
    minWidth: 'fit-content',
  },
  message: {
    whiteSpace: 'pre-wrap',
  },
}));

/**
 * @typedef {object} Props
 * @property {string} [header]
 * @property {string} message
 * @property {React.ReactNode} action
 * @property {boolean} [dense]
 *
 * @param {Props} props
 * @returns {React.ReactElement}
 */
export function Callout({ header, message, action, dense = false }) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, { [classes.dense]: dense })}>
      <div>
        {header ? (
          <Typography variant="card-heading" size="medium">
            {header}
          </Typography>
        ) : null}

        {message ? (
          <Typography variant="body" size="small">
            <span className={classes.message}>{message}</span>
          </Typography>
        ) : null}
      </div>

      {action ? <div className={classes.action}>{action}</div> : null}
    </div>
  );
}
