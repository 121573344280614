import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';

import { Typography, Modal } from '@passthrough/uikit';
import { useToast } from 'services/toast';
import { isPageInRange, isValidPageRange } from 'services/utils';
import { boxTypes } from 'pages/onboarding_v3/constants';

export const BulkPageShiftModal = ({
  open,
  isSaving,
  selectedQuestions,
  onClose,
  onSave,
}) => {
  const [pageNumberShift, setPageNumberShift] = useState(0);
  const [pageRange, setPageRange] = useState('');
  const { toast } = useToast();

  const questionIdsThatCannotShift = selectedQuestions.reduce((priorIds, q) => {
    const questionContainsInvalidBoxShift = (q.hellosignBoxes || []).some(
      (box) =>
        isPageInRange(box.page, pageRange) && box.page + pageNumberShift <= 0,
    );
    const contextLinkIds = (q.hellosignBoxes || [])
      .filter((b) => b.type === boxTypes.CONTEXT)
      .map((b) => b.linkId);
    const questionContainsInvalidLinkShift = (q.links || []).some(
      (link) =>
        !contextLinkIds.includes(link.id) &&
        isPageInRange(link.page, pageRange) &&
        Number(link.page) + pageNumberShift <= 0,
    );

    if (questionContainsInvalidBoxShift || questionContainsInvalidLinkShift) {
      priorIds.push(q.id);
    }

    return priorIds;
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      primaryButtonText="Save"
      primaryButtonLoading={isSaving}
      primaryButtonDisabled={
        !pageNumberShift ||
        questionIdsThatCannotShift.length > 0 ||
        (pageRange && !isValidPageRange(pageRange))
      }
      headerLabel="Bulk page shift"
      onExited={() => {
        setPageNumberShift(0);
        setPageRange('');
      }}
      onSubmit={() => {
        const movedItems = onSave(pageNumberShift, pageRange);
        onClose();
        toast(`${movedItems} output boxes and links moved.`);
      }}
      showCancelButton
    >
      <Typography>
        Each output box and link of the selected questions will shift its page
        number by the specified amount.
      </Typography>

      <TextField
        value={pageNumberShift}
        onChange={(e) => {
          setPageNumberShift(Number(e.target.value));
        }}
        variant="outlined"
        required
        type="number"
        label="Page shift"
      />

      <TextField
        value={pageRange}
        onChange={(e) => setPageRange(e.target.value)}
        placeholder="1-5,8,11-13"
        variant="outlined"
        type="text"
        label="Pages to shift (optional)"
        helperText="Leave blank to shift all pages"
      />

      {pageRange && !isValidPageRange(pageRange) ? (
        <Typography>"{pageRange}" is not a valid page range.</Typography>
      ) : null}

      {questionIdsThatCannotShift.length > 0 ? (
        <Typography>
          The following questions contain output boxes or links that cannot be
          decremented by the specified amount:{' '}
          {questionIdsThatCannotShift.join(', ')}
        </Typography>
      ) : null}
    </Modal>
  );
};
