export const ACCESS_LEVELS = {
  OWNER: {
    value: 'OWNER',
    label: 'Full access',
    helpText:
      "Full permissions to add and remove people from the profile, view and edit profile data, and manage all this profile's investments.",
    apiValue: 0,
  },
  ONLY_THIS_INVESTMENT: {
    value: 'ONLY_THIS_INVESTMENT',
    label: 'Only this investment',
    helpText:
      'Limited permissions to add and remove people, view and edit information for only this investment.',
    apiValue: null,
  },
};

export const ACCESS_LEVEL_MAPPING = {
  // Map frontend keys to backend values
  [ACCESS_LEVELS.ONLY_THIS_INVESTMENT.value]:
    ACCESS_LEVELS.ONLY_THIS_INVESTMENT.apiValue,
  [ACCESS_LEVELS.OWNER.value]: ACCESS_LEVELS.OWNER.apiValue,
  [ACCESS_LEVELS.OWNER.apiValue]: ACCESS_LEVELS.OWNER.apiValue,
};
