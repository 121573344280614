import React from 'react';

import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';

export function PensionScheme({
  schemeAdminDetails,
  schemeAdminDetailsError,
  amlEntityDetails,
  amlEntityDetailsError,
  handleChange,
}) {
  return (
    <>
      <div>
        <TextField
          id="pension-scheme-admin-details"
          autoFocus
          label="Scheme admin details"
          multiline
          minRows={4}
          variant="outlined"
          value={schemeAdminDetails}
          error={!!schemeAdminDetailsError}
          helperText={schemeAdminDetailsError}
          onChange={(e) => {
            handleChange('schemeAdminDetails', e.target.value);
          }}
          fullWidth
        />

        <FormHelperText>
          Provide the <strong>name and address</strong> of the{' '}
          <strong>scheme administrator.</strong>
        </FormHelperText>
      </div>

      <div>
        <TextField
          id="pension-aml-entity-details"
          autoFocus
          label="AML entity details"
          multiline
          minRows={4}
          variant="outlined"
          value={amlEntityDetails}
          error={!!amlEntityDetailsError}
          helperText={amlEntityDetailsError}
          onChange={(e) => {
            handleChange('amlEntityDetails', e.target.value);
          }}
          fullWidth
        />

        <FormHelperText>
          Provide the <strong>name</strong> of the <strong>entity</strong>{' '}
          carrying out AML on the scheme investors.
        </FormHelperText>
      </div>
    </>
  );
}
