import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Typography, Button, useConfirm } from '@passthrough/uikit';

import { PaperHeader } from 'components/paper_header';
import Divider from '@material-ui/core/Divider';
import AddCommentOutlinedIcon from '@material-ui/icons/AddCommentOutlined';
import StickerTextOutline from 'mdi-material-ui/StickerTextOutline';

import { DraftNoteDisplay } from './draft_note_display';
import { InternalNoteForm } from './internal_note_form';
import { NoteBoxLabel } from './note_box_label';
import { NoteIconVariant } from './constants';

const useStyles = makeStyles((theme) => ({
  body: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(3),
    padding: theme.spacing(0, 3, 3, 3),
  },
  footer: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(2, 3),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    columnGap: theme.spacing(2),
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  },
  changeContent: {
    padding: theme.spacing(3),
  },
  actions: {
    justifyContent: 'flex-end',
    display: 'flex',
    columnGap: theme.spacing(2),
    width: '100%',
  },
}));

function ReadOnlyNoteDisplay({ existingDraftNote }) {
  const classes = useStyles();

  return (
    <PaperHeader headerText="Note to investor">
      <div className={classes.changeContent}>
        <Typography variant="body" size="small">
          {existingDraftNote}
        </Typography>
      </div>
    </PaperHeader>
  );
}

function DraftCommentForm({
  draftNote,
  setDraftNote,
  saveButtonDisabled,
  onCancel,
  onSave,
}) {
  const classes = useStyles();

  return (
    <>
      <NoteBoxLabel variant={NoteIconVariant.GP}>
        Comment for investor
      </NoteBoxLabel>

      <TextField
        inputProps={{
          'aria-label': 'Note to investor',
          'data-test': 'note_to_investor',
        }}
        value={draftNote}
        onChange={(e) => {
          setDraftNote(e.target.value);
          e.stopPropagation();
        }}
        variant="outlined"
        multiline
        minRows={4}
        fullWidth
        autoFocus
      />

      <div className={classes.actions}>
        <Button variant="text" onClick={onCancel}>
          Cancel
        </Button>

        <Button
          aria-label="Save note to investor"
          variant="primary"
          onClick={onSave}
          disabled={saveButtonDisabled}
          data-test="save_note"
        >
          Save
        </Button>
      </div>
    </>
  );
}

function Footer({
  showCommentForInvestorButton,
  showInternalNoteButton,
  onCreateDraftNote,
  onCreateInternalNote,
}) {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      {showInternalNoteButton ? (
        <Button
          aria-label="Add internal note"
          variant="secondary"
          startIcon={<StickerTextOutline />}
          onClick={onCreateInternalNote}
        >
          Internal note
        </Button>
      ) : null}

      {showCommentForInvestorButton ? (
        <Button
          aria-label="Add comment for investor"
          data-test="comment_for_investor"
          variant="secondary"
          startIcon={<AddCommentOutlinedIcon />}
          onClick={onCreateDraftNote}
        >
          Comment for investor
        </Button>
      ) : null}
    </div>
  );
}

export function NoteBox({
  fundName,
  hasFundReviewPermission,
  existingDraftNote,
  canAddDraftNote,
  onDeleteDraftNote,
  onSaveDraftNote,
  onSaveInternalNote,
}) {
  const classes = useStyles();
  const [draftNote, setDraftNote] = useState(existingDraftNote || '');
  const [internalNote, setInternalNote] = useState('');
  const [editingDraftNote, setEditingDraftNote] = useState(false);
  const [editingInternalNote, setEditingInternalNote] = useState(false);
  const confirm = useConfirm();

  const isEditing = editingDraftNote || editingInternalNote;
  const draftNoteChanged = draftNote.trim() !== existingDraftNote;
  const shouldWarnIfLeaving =
    isEditing && (draftNoteChanged || Boolean(internalNote));
  const showDivider = isEditing || (existingDraftNote && canAddDraftNote);

  const showCommentForInvestorButton =
    canAddDraftNote && !existingDraftNote && !editingDraftNote;
  const showInternalNoteButton = !editingInternalNote;
  const showBody = existingDraftNote || isEditing;
  const showFooter = hasFundReviewPermission && !isEditing;

  const clearDraftNoteState = () => {
    setDraftNote(existingDraftNote || '');
    setEditingDraftNote(false);
  };

  const clearInternalNoteState = () => {
    setInternalNote('');
    setEditingInternalNote(false);
  };

  const handleCancelFactory =
    (clearState, needConfirmation, warningMessage) => () => {
      if (needConfirmation) {
        confirm({
          title: 'Discard unsaved changes?',
          description: warningMessage,
          confirmationText: 'Discard',
          destructive: true,
        })
          .then(() => {
            clearState();
          })
          .catch(() => {});
      } else {
        clearState();
      }
    };

  const handleCancelDraftNote = handleCancelFactory(
    clearDraftNoteState,
    draftNoteChanged,
    'Your comment has not been saved.',
  );

  const handleCancelInternalNote = handleCancelFactory(
    clearInternalNoteState,
    Boolean(internalNote),
    'Your note has not been saved.',
  );

  const handleSaveDraftNote = () => {
    onSaveDraftNote(draftNote);
    clearDraftNoteState();
  };

  const handleSaveInternalNote = () => {
    onSaveInternalNote(internalNote);
    clearInternalNoteState();
  };

  useEffect(() => {
    if (shouldWarnIfLeaving) {
      window.onbeforeunload = () => true;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [draftNoteChanged, Boolean(internalNote), isEditing]);

  useEffect(() => {
    setDraftNote(existingDraftNote || '');
  }, [existingDraftNote]);

  return (
    <>
      {showBody ? (
        <div className={classes.body}>
          {showDivider ? <Divider /> : null}

          {!canAddDraftNote && existingDraftNote ? (
            <ReadOnlyNoteDisplay existingDraftNote={existingDraftNote} />
          ) : null}

          {canAddDraftNote && existingDraftNote && !editingDraftNote ? (
            <DraftNoteDisplay
              fundName={fundName}
              note={existingDraftNote}
              handleEdit={() => {
                setEditingDraftNote(true);
              }}
              handleDelete={() => {
                setDraftNote('');
                onDeleteDraftNote();
              }}
            />
          ) : null}

          {editingDraftNote ? (
            <>
              <Prompt
                when={draftNoteChanged}
                message="Your comment has not been saved."
              />
              <DraftCommentForm
                draftNote={draftNote}
                setDraftNote={setDraftNote}
                saveButtonDisabled={!draftNoteChanged || !draftNote}
                onCancel={handleCancelDraftNote}
                onSave={handleSaveDraftNote}
              />
            </>
          ) : null}

          {editingInternalNote ? (
            <>
              <Prompt
                when={Boolean(internalNote)}
                message="Your note has not been saved."
              />
              <InternalNoteForm
                showCancel
                isLoading={false}
                onCancel={handleCancelInternalNote}
                internalNote={internalNote}
                setInternalNote={setInternalNote}
                onSave={handleSaveInternalNote}
              />
            </>
          ) : null}
        </div>
      ) : null}

      {showFooter ? (
        <Footer
          showCommentForInvestorButton={showCommentForInvestorButton}
          showInternalNoteButton={showInternalNoteButton}
          onCreateDraftNote={() => setEditingDraftNote(true)}
          onCreateInternalNote={() => setEditingInternalNote(true)}
        />
      ) : null}
    </>
  );
}
