import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { Alert } from 'components/alert';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { Button } from 'components/button';
import { Spinner } from 'components/spinner';
import { useFundEdit } from 'services/providers/fund';
import { useConfirm, Typography, UserDisplay } from '@passthrough/uikit';
import * as api from 'services/api';
import { useToast } from 'services/toast';
import { AddPersonModal } from './add_person';

const useStyles = makeStyles((theme) => ({
  peopleActions: {
    margin: theme.spacing(2, 2, 0, 2),
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  peopleList: {
    padding: 0,
  },
}));

export function People({ investor, onChange }) {
  const classes = useStyles();
  const confirm = useConfirm();
  const [fundEdit] = useFundEdit();
  const { toast } = useToast();
  const [addPersonModalOpen, setAddPersonModalOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  function handleChange() {
    setErrorMsg('');
    onChange();
  }

  function removeUser(user) {
    function performDelete() {
      api
        .investorDeleteUser({
          fundId: investor.fundId,
          lpId: investor.id,
          lpUserId: user.id,
        })
        .then(() => {
          setErrorMsg('');
          toast(`Removed access from ${user.email}`);
          onChange();
        })
        .catch((error) => {
          if (error.response?.status === 400) {
            setErrorMsg(error.response.data[0]);
          }
        });
    }

    confirm({
      description:
        `You are about to remove access from ${user.email}. ` +
        'Use caution when removing people since they may have been added by the investor.',
      destructive: true,
    })
      .then(performDelete)
      .catch(() => {});
  }

  if (fundEdit === null) {
    return <Spinner fullScreen />;
  }

  const someClosingInActiveStatus = investor.closings.some(
    (closing) => closing.isActiveStatus,
  );
  return (
    <>
      <Typography variant="section-heading">People</Typography>
      {errorMsg ? <Alert severity="error">{errorMsg}</Alert> : null}
      {fundEdit ? (
        <div className={classes.peopleActions}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setAddPersonModalOpen(true);
            }}
            startIcon={<AddIcon />}
          >
            Add person
          </Button>
        </div>
      ) : null}

      <List className={classes.peopleList}>
        {investor.users.map((user) => (
          <ListItem key={user.email}>
            <ListItemText
              primary={
                <UserDisplay
                  variant="default"
                  name={user.name}
                  email={user.email}
                />
              }
            />
            {fundEdit && user.accessLevel === null ? (
              <Tooltip
                title={
                  <Typography variant="label">Remove this user</Typography>
                }
              >
                <IconButton
                  aria-label="remove"
                  onClick={() => {
                    removeUser(user);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            ) : null}
            {fundEdit && user.accessLevel !== null ? (
              <Tooltip
                title={
                  <Typography variant="label">
                    Cannot remove this person because they are the owner of this
                    profile.
                  </Typography>
                }
              >
                <span>
                  <IconButton aria-label="remove" onClick={() => {}} disabled>
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            ) : null}
          </ListItem>
        ))}
      </List>
      <AddPersonModal
        open={addPersonModalOpen}
        onClose={() => {
          setAddPersonModalOpen(false);
        }}
        onChange={handleChange}
        fundId={investor.fundId}
        lpId={investor.id}
        someClosingInActiveStatus={someClosingInActiveStatus}
      />
    </>
  );
}
