import React from 'react';
import { Link, UIKitSettingsProvider } from '@passthrough/uikit';

const APPROVED_DOMAIN_ERROR_CODE = 'domain_not_approved';
const APPROVED_DOMAIN_HELP_PAGE =
  'https://support.passthrough.com/en_us/organization-administrators-rk1U1E7ea';

function ApprovedDomainError({ domain }) {
  return (
    <>
      {domain} is not an approved domain for this organization. Contact your
      organization administrators who can{' '}
      <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
        <Link inline variant="external" href={APPROVED_DOMAIN_HELP_PAGE}>
          manage approved domains
        </Link>
      </UIKitSettingsProvider>
      .
    </>
  );
}

export function formatApprovedDomainEmailError(errorObj) {
  if (typeof errorObj === 'string') {
    return errorObj;
  }
  if (Array.isArray(errorObj)) {
    return formatApprovedDomainEmailError(errorObj[0]);
  }

  if (errorObj?.errorCode === APPROVED_DOMAIN_ERROR_CODE) {
    return <ApprovedDomainError domain={errorObj.domain} />;
  }

  return errorObj?.email;
}
