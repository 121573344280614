import {
  TYPE_IRA,
  TYPE_INDIVIDUAL,
  TYPE_PRIVATE_TRUST,
  TYPE_PENSION_SCHEME,
  TYPE_PRIVATE_COMPANY,
  TYPE_LISTED_COMPANY,
  TYPE_LIMITED_PARTNERSHIP,
  TYPE_JOINT_ACCOUNT,
  TYPE_LLC,
  TYPE_CHARITY_OR_FOUNDATION,
  TYPE_SCHOOL,
  TYPE_S_CORP,
  TYPE_PARTNERSHIP,
  TYPE_BENEFICIARIES,
  TYPE_HOLDERS,
  TYPE_GPS,
  TYPE_DIRECTORS,
  TYPE_BENEFICIAL_OWNERS,
  TYPE_MANAGING_MEMBERS,
  TYPE_MEMBERS,
  TYPE_TRUSTEES,
  TYPE_GRANTORS,
  TYPE_SIGNATORIES,
  TYPE_RECIPIENTS,
  TYPE_OFFICIALS,
  TYPE_LPS,
  ownerTypesToLabels,
  TYPE_VALID_ID,
  TYPE_PROOF_OF_ADDRESS,
  TYPE_EVIDENCE_OF_LISTING,
  TYPE_LETTER_FROM_EXTERNAL_AUDITOR,
  TYPE_COPOC,
  TYPE_COAS,
  TYPE_CONFIRMATION_OF_OWNERSHIP,
  TYPE_DETAILS_OF_MAIN_OFFICIALS,
  TYPE_CERT_COR,
  TYPE_CERT_AFS,
  TYPE_CERT_LPA,
  TYPE_CERT_STRUCTURE_CHART,
  TYPE_CERT_SHAREHOLDER_REGISTRY,
  TYPE_CERT_ASL,
  TYPE_CERT_COI,
  TYPE_CERT_AOA,
  TYPE_CERT_AOI,
  TYPE_CERT_COF,
  TYPE_CERT_TRUST_DEED,
  TYPE_CERT_TRUST_AGREEMENT,
  TYPE_CERT_PARTNERSHIP_AGREEMENT,
  TYPE_CERT_DIRECTOR_REGISTER,
  TYPE_CERT_AML_LETTER,
  TYPE_CERT_REGISTER_OF_DIRECTORS,
  TYPE_CERT_REGISTER_OF_SHAREHOLDERS,
  TYPE_CERT_ORG_CHART,
  TYPE_CERT_OPERATING_AGREEMENT,
  TYPE_CERT_DOM,
  TYPE_CERT_SCHEDULE_OF_TRUSTEES,
  TYPE_CERT_FORMATION_DOCUMENT,
  TYPE_CERT_SCHEDULE_OF_MAJOR_DONORS,
  TYPE_CERT_SOB,
  TYPE_CERT_CERTIFICATE_OF_TRUST,
  TYPE_CERT_DONORS_OVER_1OK,
  docTypesToLabels,
  GROUP_NONE,
} from '../../constants';

export const CAYMAN_REQUIREMENTS = {
  [GROUP_NONE]: {
    [TYPE_INDIVIDUAL]: {
      documents: [
        {
          key: TYPE_VALID_ID,
          label: docTypesToLabels[TYPE_VALID_ID],
          helpText:
            'Upload a photo of your valid passport or birth certificate.',
        },
        {
          key: TYPE_PROOF_OF_ADDRESS,
          label: docTypesToLabels[TYPE_PROOF_OF_ADDRESS],
          helpText:
            'Upload your proof of residence, such as a utility bill, dated in the last three months.',
        },
      ],
      owners: [],
    },
    [TYPE_LIMITED_PARTNERSHIP]: {
      documents: [
        {
          key: TYPE_CERT_COR,
          label: docTypesToLabels[TYPE_CERT_COR],
          helpText:
            'Upload a certified copy of a certificate of registration or equivalent.',
        },
        {
          key: TYPE_CERT_LPA,
          label: docTypesToLabels[TYPE_CERT_LPA],
          helpText:
            'Upload a certified signed copy of the limited partnership’s limited partnership agreement.',
        },
        {
          key: TYPE_CERT_STRUCTURE_CHART,
          label: docTypesToLabels[TYPE_CERT_STRUCTURE_CHART],
          helpText:
            'Upload a certified structure chart (only required for multi-level structured entities).',
          optional: true,
        },
        {
          key: TYPE_CERT_SHAREHOLDER_REGISTRY,
          label: docTypesToLabels[TYPE_CERT_SHAREHOLDER_REGISTRY],
          helpText:
            'Upload a copy of a certified shareholder registry listing all limited partners and the percent holding in the Partnership.',
        },
        {
          key: TYPE_CERT_ASL,
          label: docTypesToLabels[TYPE_CERT_ASL],
          helpText:
            'Upload a certified copy of the authorized signatory list (including all individuals authorized to act on the limited partnership’s behalf in relation to its investment), together with specimen signatures.',
        },
        {
          key: TYPE_CERT_DIRECTOR_REGISTER,
          label: docTypesToLabels[TYPE_CERT_DIRECTOR_REGISTER],
          helpText:
            'Upload a certified copy of the register of directors of the limited partnership.',
        },
      ],
      owners: [
        {
          key: TYPE_LPS,
          singularLabel: ownerTypesToLabels[TYPE_LPS].single,
          pluralLabel: ownerTypesToLabels[TYPE_LPS].plural,
          helpText:
            'Add the names of all limited partners of 10% or more of the limited partnership',
        },
        {
          key: TYPE_GPS,
          singularLabel: ownerTypesToLabels[TYPE_GPS].single,
          pluralLabel: ownerTypesToLabels[TYPE_GPS].plural,
          helpText:
            'Add the names of all general partners of the limited partnership',
        },
        {
          key: TYPE_SIGNATORIES,
          singularLabel: ownerTypesToLabels[TYPE_SIGNATORIES].single,
          pluralLabel: ownerTypesToLabels[TYPE_SIGNATORIES].plural,
          helpText:
            'Add at least two signatories for the limited partnership, including the signatory that will execute the subscription documents.',
        },
      ],
    },
    [TYPE_PARTNERSHIP]: {
      documents: [
        {
          key: TYPE_CERT_COR,
          label: docTypesToLabels[TYPE_CERT_COR],
          helpText:
            'Upload a certified copy of a certificate of registration or equivalent.',
        },
        {
          key: TYPE_CERT_PARTNERSHIP_AGREEMENT,
          label: docTypesToLabels[TYPE_CERT_PARTNERSHIP_AGREEMENT],
          helpText:
            'Upload a certified signed copy of the partnership’s partnership agreement.',
        },
        {
          key: TYPE_CERT_AOI,
          label: docTypesToLabels[TYPE_CERT_AOI],
          helpText:
            'Upload a certified copy of any applicable resolutions, powers of attorney, or authorization letters authorizing the partnership’s investment.',
          optional: true,
        },
        {
          key: TYPE_CERT_ASL,
          label: docTypesToLabels[TYPE_CERT_ASL],
          helpText:
            'Upload a certified copy of the authorized signatory list (including all individuals authorized to act on the partnership’s behalf in relation to its investment), together with specimen signatures.',
        },
        {
          key: TYPE_CERT_STRUCTURE_CHART,
          label: docTypesToLabels[TYPE_CERT_STRUCTURE_CHART],
          helpText:
            'Upload a certified structure chart (only required for multi-level structured entities).',
          optional: true,
        },
        {
          key: TYPE_CERT_AFS,
          label: docTypesToLabels[TYPE_CERT_AFS],
          helpText:
            'Upload a certified copy of the latest audited financial statements for the partnership, where available.',
          optional: true,
        },
      ],
      owners: [
        {
          key: TYPE_BENEFICIAL_OWNERS,
          singularLabel: 'Beneficial owner of 10% or more',
          pluralLabel: 'Beneficial owners of 10% or more',
          helpText:
            'Add the names of all beneficial owners of 10% or more of the partnership.',
        },
        {
          key: TYPE_GPS,
          singularLabel: ownerTypesToLabels[TYPE_GPS].single,
          pluralLabel: ownerTypesToLabels[TYPE_GPS].plural,
          helpText:
            'Add the names of all general partners persons of the partnership',
        },
        {
          key: TYPE_SIGNATORIES,
          singularLabel: ownerTypesToLabels[TYPE_SIGNATORIES].single,
          pluralLabel: ownerTypesToLabels[TYPE_SIGNATORIES].plural,
          helpText: 'Add the names of all signatories of the partnership.',
        },
      ],
    },
    [TYPE_JOINT_ACCOUNT]: {
      documents: [
        {
          key: TYPE_CERT_SOB,
          label: docTypesToLabels[TYPE_CERT_SOB],
          helpText:
            'Upload a certified schedule of the joint account’s beneficiaries.',
        },
      ],
      owners: [
        {
          key: TYPE_HOLDERS,
          singularLabel: ownerTypesToLabels[TYPE_HOLDERS].single,
          pluralLabel: ownerTypesToLabels[TYPE_HOLDERS].plural,
          helpText:
            'Add the names of all joint account holders of the joint account.',
          minNum: 2,
        },
        {
          key: TYPE_BENEFICIARIES,
          singularLabel: ownerTypesToLabels[TYPE_BENEFICIARIES].single,
          pluralLabel: ownerTypesToLabels[TYPE_BENEFICIARIES].plural,
          helpText: 'Add the names of all beneficiaries of the joint account.',
        },
      ],
    },
    [TYPE_IRA]: {
      documents: [
        {
          key: TYPE_CERT_AML_LETTER,
          label: docTypesToLabels[TYPE_CERT_AML_LETTER],
          helpText:
            'Upload a certified letter attesting that there is not substantive money laundering activity associated with the IRA.',
        },
      ],
      owners: [],
    },
    [TYPE_PRIVATE_COMPANY]: {
      documents: [
        {
          key: TYPE_CERT_COI,
          label: docTypesToLabels[TYPE_CERT_COI],
          helpText:
            'Upload a certified copy of the company’s certificate of incorporation.',
        },
        {
          key: TYPE_CERT_AOA,
          label: docTypesToLabels[TYPE_CERT_AOA],
          helpText:
            'Upload a certified copy of the company’s memorandum and articles of association.',
        },
        {
          key: TYPE_CERT_ASL,
          label: docTypesToLabels[TYPE_CERT_ASL],
          helpText:
            'Upload a certified copy of the authorized signatory list (including all individuals authorized to act on the company’s behalf in relation to its investment), together with specimen signatures.',
        },
        {
          key: TYPE_CERT_REGISTER_OF_DIRECTORS,
          label: docTypesToLabels[TYPE_CERT_REGISTER_OF_DIRECTORS],
          helpText: 'Upload a certified register of the company’s directors.',
        },
        {
          key: TYPE_CERT_REGISTER_OF_SHAREHOLDERS,
          label: docTypesToLabels[TYPE_CERT_REGISTER_OF_SHAREHOLDERS],
          helpText:
            'Upload a certified register of the company’s shareholders.',
        },
        {
          key: TYPE_CERT_AOI,
          label: docTypesToLabels[TYPE_CERT_AOI],
          helpText:
            'Upload a certified copy of any applicable resolutions, powers of attorney, or authorization letters authorizing the company’s investment.',
          optional: true,
        },
      ],
      owners: [
        {
          key: TYPE_DIRECTORS,
          singularLabel: 'Director',
          pluralLabel: 'Director',
          helpText:
            'Add the names of at least two directors for the private company.',
        },
        {
          key: TYPE_SIGNATORIES,
          singularLabel: ownerTypesToLabels[TYPE_SIGNATORIES].single,
          pluralLabel: ownerTypesToLabels[TYPE_SIGNATORIES].plural,
          helpText: 'Add the names of all signatories of the private company.',
        },
        {
          key: TYPE_BENEFICIAL_OWNERS,
          singularLabel: 'Beneficial owner of 10% or more',
          pluralLabel: 'Beneficial owners of 10% or more',
          helpText:
            'Add the names of all beneficial owners of 10% or more of the private company.',
        },
      ],
    },
    [TYPE_LISTED_COMPANY]: {
      documents: [
        {
          key: TYPE_EVIDENCE_OF_LISTING,
          label: docTypesToLabels[TYPE_EVIDENCE_OF_LISTING],
          helpText:
            'Upload evidence of the company’s listing on a recognized stock exchange.',
        },
        {
          key: TYPE_LETTER_FROM_EXTERNAL_AUDITOR,
          label: docTypesToLabels[TYPE_LETTER_FROM_EXTERNAL_AUDITOR],
          helpText:
            'Upload a letter of good standing from an external auditor.',
        },
        {
          key: TYPE_CERT_ORG_CHART,
          label: docTypesToLabels[TYPE_CERT_ORG_CHART],
          helpText:
            'Upload a certified copy of the listed company’s organizational chart.',
        },
        {
          key: TYPE_CERT_AOI,
          label: docTypesToLabels[TYPE_CERT_AOI],
          helpText:
            'Upload a certified copy of any applicable resolutions, powers of attorney, or authorization letters authorizing the company’s investment.',
          optional: true,
        },
        {
          key: TYPE_CERT_ASL,
          label: docTypesToLabels[TYPE_CERT_ASL],
          helpText:
            'Upload a certified copy of the authorized signatory list (including all individuals authorized to act on the company’s behalf in relation to its investment), together with specimen signatures.',
        },
      ],
      owners: [
        {
          key: TYPE_BENEFICIAL_OWNERS,
          singularLabel: 'Beneficial owner of 10% or more',
          pluralLabel: 'Beneficial owners of 10% or more',
          helpText:
            'Add the names of all beneficial owners of 10% or more of the listed company.',
        },
        {
          key: TYPE_SIGNATORIES,
          singularLabel: ownerTypesToLabels[TYPE_SIGNATORIES].single,
          pluralLabel: ownerTypesToLabels[TYPE_SIGNATORIES].plural,
          helpText: 'Add the names of all signatories of the listed company.',
        },
      ],
    },
    [TYPE_LLC]: {
      documents: [
        {
          key: TYPE_CERT_COF,
          label: docTypesToLabels[TYPE_CERT_COF],
          helpText:
            'Upload a certified copy of the limited liability company’s certificate of formation.',
        },
        {
          key: TYPE_CERT_OPERATING_AGREEMENT,
          label: docTypesToLabels[TYPE_CERT_OPERATING_AGREEMENT],
          helpText:
            'Upload a certified copy of the limited liability company’s operating agreement.',
        },
        {
          key: TYPE_CERT_DOM,
          label: docTypesToLabels[TYPE_CERT_DOM],
          helpText:
            'Upload a certified schedule indicating the ownership of all managers of the LLC.',
        },
        {
          key: TYPE_CERT_ORG_CHART,
          label: docTypesToLabels[TYPE_CERT_ORG_CHART],
          helpText:
            'Upload a certified copy of the limited liability company’s organizational chart.',
        },
        {
          key: TYPE_CERT_AOI,
          label: docTypesToLabels[TYPE_CERT_AOI],
          helpText:
            'Upload a certified copy of any applicable resolutions, powers of attorney, or authorization letters authorizing the company’s investment.',
          optional: true,
        },
      ],
      owners: [
        {
          key: TYPE_MANAGING_MEMBERS,
          singularLabel: 'Managing Member of 10% or more',
          pluralLabel: 'Managing Members of 10% or more',
          helpText:
            'Add the names of all of the managing members of 10% or more of the limited liability company.',
        },
        {
          key: TYPE_MEMBERS,
          singularLabel: 'Member of 10% or more',
          pluralLabel: 'Members of 10% or more',
          helpText:
            'Add the names of all of the members of 10% or more of the limited liability company.',
        },
      ],
    },
    [TYPE_PRIVATE_TRUST]: {
      documents: [
        {
          key: TYPE_CERT_TRUST_DEED,
          label: docTypesToLabels[TYPE_CERT_TRUST_DEED],
          helpText:
            'Upload a certified copy of the trust deed with any amendments.',
          optional: true,
        },
        {
          key: TYPE_CERT_TRUST_AGREEMENT,
          label: docTypesToLabels[TYPE_CERT_TRUST_AGREEMENT],
          helpText: 'Upload a certified copy of the trust agreement.',
        },
        {
          key: TYPE_CERT_CERTIFICATE_OF_TRUST,
          label: docTypesToLabels[TYPE_CERT_CERTIFICATE_OF_TRUST],
          helpText: 'Upload a certified copy of the certificate of trust.',
        },
        {
          key: TYPE_CERT_SCHEDULE_OF_TRUSTEES,
          label: docTypesToLabels[TYPE_CERT_SCHEDULE_OF_TRUSTEES],
          helpText:
            'Upload a certified schedule of all of the trust’s trustees.',
        },
        {
          key: TYPE_CERT_STRUCTURE_CHART,
          label: docTypesToLabels[TYPE_CERT_STRUCTURE_CHART],
          helpText:
            'Upload a certified structure chart (only required for multi-level structured entities).',
          optional: true,
        },
        {
          key: TYPE_CERT_ASL,
          label: docTypesToLabels[TYPE_CERT_ASL],
          helpText:
            'Upload a certified copy of the authorized signatory list (including all individuals authorized to act on the trust’s behalf in relation to its investment), together with specimen signatures.',
        },
        {
          key: TYPE_CERT_AOI,
          label: docTypesToLabels[TYPE_CERT_AOI],
          helpText:
            'Upload a certified copy of any applicable resolutions, powers of attorney, or authorization letters authorizing the trust’s investment.',
          optional: true,
        },
        {
          key: TYPE_CERT_AFS,
          label: docTypesToLabels[TYPE_CERT_AFS],
          helpText:
            'Upload a certified copy of the latest audited financial statements for the trust, where available.',
          optional: true,
        },
      ],
      owners: [
        {
          key: TYPE_TRUSTEES,
          singularLabel: ownerTypesToLabels[TYPE_TRUSTEES].single,
          pluralLabel: ownerTypesToLabels[TYPE_TRUSTEES].plural,
          helpText:
            'Add the names of all of the trustees of the private trust.',
        },
        {
          key: TYPE_GRANTORS,
          singularLabel: ownerTypesToLabels[TYPE_GRANTORS].single,
          pluralLabel: ownerTypesToLabels[TYPE_GRANTORS].plural,
          helpText: 'Add the names of all grantors of the private trust.',
        },
        {
          key: TYPE_BENEFICIARIES,
          singularLabel: ownerTypesToLabels[TYPE_BENEFICIARIES].single,
          pluralLabel: ownerTypesToLabels[TYPE_BENEFICIARIES].plural,
          helpText: 'Add the names of all beneficiaries of the private trust.',
        },
      ],
    },
    [TYPE_PENSION_SCHEME]: {
      documents: [
        {
          key: TYPE_CERT_FORMATION_DOCUMENT,
          label: docTypesToLabels[TYPE_CERT_FORMATION_DOCUMENT],
          helpText:
            'Upload a certified copy of the pension scheme’s formation document.',
        },
        {
          key: TYPE_CERT_AML_LETTER,
          label: docTypesToLabels[TYPE_CERT_AML_LETTER],
          helpText:
            'Upload a certified letter attesting that there is no substantive money laundering activity associated with the pension scheme.',
        },
      ],
      owners: [
        {
          key: TYPE_TRUSTEES,
          singularLabel: ownerTypesToLabels[TYPE_TRUSTEES].single,
          pluralLabel: ownerTypesToLabels[TYPE_TRUSTEES].plural,
          helpText: 'Add the names of all trustees of the pension scheme.',
        },
        {
          key: TYPE_SIGNATORIES,
          singularLabel: ownerTypesToLabels[TYPE_SIGNATORIES].single,
          pluralLabel: ownerTypesToLabels[TYPE_SIGNATORIES].plural,
          helpText: 'Add the names of all signatories of the pension scheme.',
        },
      ],
    },
    [TYPE_CHARITY_OR_FOUNDATION]: {
      documents: [
        {
          key: TYPE_CERT_FORMATION_DOCUMENT,
          label: docTypesToLabels[TYPE_CERT_FORMATION_DOCUMENT],
          helpText:
            'Upload a certified copy of the charity or foundation’s formation document.',
        },
        {
          key: TYPE_COPOC,
          label: docTypesToLabels[TYPE_COPOC],
          helpText:
            'Upload a confirmation of the charity or foundation’s nature or purpose.',
        },
        {
          key: TYPE_COAS,
          label: docTypesToLabels[TYPE_COAS],
          helpText:
            'Upload a confirmation of the charity or foundation’s approved status.',
        },
        {
          key: TYPE_CERT_AFS,
          label: docTypesToLabels[TYPE_CERT_AFS],
          helpText:
            'Upload a certified copy of the charity or foundation’s audited financial statements.',
        },
        {
          key: TYPE_LETTER_FROM_EXTERNAL_AUDITOR,
          label: docTypesToLabels[TYPE_LETTER_FROM_EXTERNAL_AUDITOR],
          helpText:
            'Upload a letter of good standing from an external auditor.',
        },
        {
          key: TYPE_CERT_AML_LETTER,
          label: docTypesToLabels[TYPE_CERT_AML_LETTER],
          helpText:
            'Upload a certified letter attesting there is not substantive money laundering activity associated with the charity or foundation.',
        },
        {
          key: TYPE_CERT_DONORS_OVER_1OK,
          label: docTypesToLabels[TYPE_CERT_DONORS_OVER_1OK],
          helpText:
            'Upload a certified schedule of the charity’s major donors of more than $10,000.',
        },
      ],
      owners: [
        {
          key: TYPE_TRUSTEES,
          singularLabel: ownerTypesToLabels[TYPE_TRUSTEES].single,
          pluralLabel: ownerTypesToLabels[TYPE_TRUSTEES].plural,
          helpText:
            'Add the names of all trustees of the charity or foundation.',
        },
        {
          key: TYPE_DIRECTORS,
          singularLabel: 'Director',
          pluralLabel: 'Directors',
          helpText:
            'Add the names of all directors of the charity or foundation.',
        },
        {
          key: TYPE_BENEFICIARIES,
          singularLabel: ownerTypesToLabels[TYPE_BENEFICIARIES].single,
          pluralLabel: ownerTypesToLabels[TYPE_BENEFICIARIES].plural,
          helpText:
            'Add the names of all beneficiaries of the charity or foundation.',
        },

        {
          key: TYPE_RECIPIENTS,
          singularLabel: ownerTypesToLabels[TYPE_RECIPIENTS].single,
          pluralLabel: ownerTypesToLabels[TYPE_RECIPIENTS].plural,
          helpText:
            'Add the names of all primary recipients of funds of the charity or foundation.',
        },
      ],
    },
    [TYPE_SCHOOL]: {
      documents: [
        {
          key: TYPE_CERT_FORMATION_DOCUMENT,
          label: docTypesToLabels[TYPE_CERT_FORMATION_DOCUMENT],
          helpText:
            'Upload a certified copy of the school, college, or university’s constitution or formation document.',
        },
        {
          key: TYPE_CONFIRMATION_OF_OWNERSHIP,
          label: docTypesToLabels[TYPE_CONFIRMATION_OF_OWNERSHIP],
          helpText:
            'Upload a document confirming the school, college, or university’s ownership.',
        },
        {
          key: TYPE_DETAILS_OF_MAIN_OFFICIALS,
          label: docTypesToLabels[TYPE_DETAILS_OF_MAIN_OFFICIALS],
          helpText:
            'Upload a schedule of all of the main officials of the school, college, or university, including any person responsible for making investments on behalf of the institution.',
        },
        {
          key: TYPE_CERT_SCHEDULE_OF_MAJOR_DONORS,
          label: docTypesToLabels[TYPE_CERT_SCHEDULE_OF_MAJOR_DONORS],
          helpText:
            'Upload a certified schedule of the school, college, or university’s major donors.',
        },
        {
          key: TYPE_CERT_ORG_CHART,
          label: docTypesToLabels[TYPE_CERT_ORG_CHART],
          helpText:
            'Upload a certified copy of the school, college, or university’s organizational chart.',
        },
      ],
      owners: [
        {
          key: TYPE_BENEFICIARIES,
          singularLabel: ownerTypesToLabels[TYPE_BENEFICIARIES].single,
          pluralLabel: ownerTypesToLabels[TYPE_BENEFICIARIES].plural,
          helpText:
            'Add the names of all beneficiaries of the school, college, or university.',
        },
        {
          key: TYPE_OFFICIALS,
          singularLabel: ownerTypesToLabels[TYPE_OFFICIALS].single,
          pluralLabel: ownerTypesToLabels[TYPE_OFFICIALS].plural,
          helpText:
            'Add the names of all officials of the school, college, or university.',
        },
      ],
    },
    [TYPE_S_CORP]: {
      documents: [
        {
          key: TYPE_CERT_COI,
          label: docTypesToLabels[TYPE_CERT_COI],
          helpText:
            'Upload a certified copy of the S-corp’s certificate of incorporation.',
        },
        {
          key: TYPE_CERT_AOA,
          label: docTypesToLabels[TYPE_CERT_AOA],
          helpText:
            'Upload a certified copy of the S-corp’s memorandum and articles of association.',
        },
        {
          key: TYPE_CERT_REGISTER_OF_DIRECTORS,
          label: docTypesToLabels[TYPE_CERT_REGISTER_OF_DIRECTORS],
          helpText: 'Upload a certified register of the S-corp’s directors.',
        },
        {
          key: TYPE_CERT_REGISTER_OF_SHAREHOLDERS,
          label: docTypesToLabels[TYPE_CERT_REGISTER_OF_SHAREHOLDERS],
          helpText: 'Upload a certified register of the S-corp’s shareholders.',
        },
        {
          key: TYPE_CERT_AOI,
          label: docTypesToLabels[TYPE_CERT_AOI],
          helpText:
            'Upload a certified copy of any applicable resolutions, powers of attorney, or authorization letters authorizing the S-corp’s investment.',
          optional: true,
        },
      ],
      owners: [
        {
          key: TYPE_DIRECTORS,
          singularLabel: 'Director',
          pluralLabel: 'Directors',
          helpText: 'Add the names of at least two directors of the S-corp',
        },
        {
          key: TYPE_BENEFICIAL_OWNERS,
          singularLabel: 'Beneficial owner of 10% or more',
          pluralLabel: 'Beneficial owners of 10% or more',
          helpText:
            'Add the names of all beneficial owners of 10% or more of the S-corp.',
        },
      ],
    },
  },
};
