import * as constants from './constants';

// Right now we only support simple questions
export function getFieldsOrChoices(questionType, questionTags) {
  if (questionType === constants.SIMPLE) {
    return [''];
  }
  if (questionType === constants.COMPLEX || constants.CHOICE) {
    return Object.keys(questionTags);
  }
  return [];
}

function idsEqual(origIds, newIds) {
  return (
    origIds.length === newIds.length &&
    origIds.every((id) => newIds.indexOf(id) > -1)
  );
}

export function choicesDiffer(origQuestion, newQuestion) {
  const origChoiceIds = origQuestion?.choices.map((c) => c.id);
  const newChoiceIds = newQuestion?.choices.map((c) => c.id);
  return !idsEqual(origChoiceIds.sort(), newChoiceIds.sort());
}

export function questionValidForKeepTagValid(
  origQuestionType,
  origQuestion,
  newQuestion,
  questionTags,
) {
  const isUpdate = origQuestion && newQuestion;
  if (!isUpdate) {
    return false;
  }

  if (!questionTags) {
    return false;
  }

  const answerTypeDiffers = origQuestion.answerType !== newQuestion.answerType;
  const isInteroperable =
    !origQuestion.notInteroperable && !newQuestion.notInteroperable;

  if (answerTypeDiffers || !isInteroperable) {
    return false;
  }

  const questionTextDiffers = origQuestion.question !== newQuestion.question;

  if (
    origQuestionType === constants.SIMPLE ||
    origQuestionType === constants.COMPLEX
  ) {
    return questionTextDiffers;
  }
  if (origQuestionType === constants.CHOICE) {
    return questionTextDiffers || choicesDiffer(origQuestion, newQuestion);
  }
  return false;
}
