import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LockIcon from '@material-ui/icons/LockOutlined';
import { Alert } from 'components/alert';

import { KeyValuePair } from 'components/key_value';
import { StrengthIndicator } from 'components/strength_indicator';

const useStyles = makeStyles((theme) => ({
  passwords: {
    marginTop: theme.spacing(0),
  },
  lockIcon: {
    marginTop: '16px',
  },
  alert: {
    marginBottom: theme.spacing(1),
  },
}));

export function ChangePassword({
  oldPassword,
  setOldPassword,
  oldPasswordError,
  password,
  passwordError,
  passwordScore,
  setPassword,
  passwordWarning,
  passwordSuggestions,
  retypePassword,
  retypePasswordError,
  setRetypePassword,
}) {
  const classes = useStyles();

  return (
    <KeyValuePair iconClassName={classes.lockIcon} input icon={<LockIcon />}>
      <Alert severity="info" className={classes.alert}>
        Changing passwords will sign you out on all devices, including this one.
      </Alert>
      <Grid container spacing={2} className={classes.passwords}>
        <Grid item xs={12}>
          <TextField
            id="password"
            type="password"
            size="medium"
            label="Old password"
            variant="outlined"
            value={oldPassword}
            error={!!oldPasswordError}
            helperText={oldPasswordError}
            onChange={(e) => {
              setOldPassword(e.target.value);
            }}
            fullWidth
            autoComplete="current-password"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="new-password"
            type="password"
            size="medium"
            label="New password"
            variant="outlined"
            value={password}
            error={!!passwordError || (!!password && passwordScore <= 2)}
            helperText={passwordError}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            fullWidth
            autoComplete="new-password"
          />
          <StrengthIndicator
            score={passwordScore}
            warning={passwordWarning}
            suggestions={passwordSuggestions}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="password-again"
            type="password"
            size="medium"
            label="New password again"
            variant="outlined"
            value={retypePassword}
            error={!!retypePasswordError}
            helperText={retypePasswordError}
            onChange={(e) => {
              setRetypePassword(e.target.value);
            }}
            fullWidth
            autoComplete="new-password"
          />
        </Grid>
      </Grid>
    </KeyValuePair>
  );
}
