import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { EXPOSED_SIDE_LETTER_TYPES } from 'components/document_upload/constants';
import { InputDiv } from './input_div';
import { fileTypeRequired } from '../constants';

export const FileTypeInput = ({ answerType, fileType, setFileType }) => {
  if (!fileTypeRequired.includes(answerType)) return null;

  return (
    <InputDiv>
      <FormControl variant="outlined" required fullWidth>
        <InputLabel id="file-type">File type for zip export</InputLabel>
        <Select
          label="File type for zip export"
          labelId="file-type"
          value={fileType}
          required
          onChange={(e) => setFileType(e.target.value)}
        >
          {EXPOSED_SIDE_LETTER_TYPES.map((sideLetterType) => (
            <MenuItem value={sideLetterType.key}>
              {sideLetterType.display}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </InputDiv>
  );
};
