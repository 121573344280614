import React, { cloneElement } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import { Button, Typography, SpotIcon, Icons } from '@passthrough/uikit';

import * as constants from './constants';
import { boxCanToggleRequiredStatus } from './utils';
import { BoxTypeSelect } from './box_type_select';
import { SignerDropdown } from './signer_select';
import { InvestorTypeSelect } from './investor_type_select';
import {
  BoxType,
  getHeightFromBoxType,
  getWidthFromBoxType,
  getBoxInfo,
} from './box_type';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(3, 2),
    width: `${constants.PANEL_WIDTH}px`,
    minWidth: `${constants.PANEL_WIDTH}px`,
    height: '100%',
    border: 'none',
    borderBottom: 'none',
    borderRight: 'none',
    borderTop: 'none',
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  fieldWithLabel: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  iconText: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  circle: {
    minWidth: '8px',
    width: '8px',
    height: '8px',
    borderRadius: '8px',
  },
  centerVertically: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  emptyStateTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

function EmptyState() {
  const classes = useStyles();

  return (
    <div className={classes.centerVertically}>
      <SpotIcon size="medium" variant="neutral">
        <Icons.AdsClick />
      </SpotIcon>
      <div className={classes.emptyStateTextContainer}>
        <Typography variant="body" size="large" fontWeight={500}>
          No field selected
        </Typography>
        <Typography variant="body" color="text.secondary">
          Select a field to place in the document
        </Typography>
      </div>
    </div>
  );
}

function EditFields({
  relevantSigners,
  activeBox,
  actions,
  allowInvestorType,
}) {
  const classes = useStyles();

  const canSetTooltipText = activeBox.type === BoxType.TEXT;
  const canToggleBoxIsRequired = boxCanToggleRequiredStatus(activeBox.type);

  function setBoxType(type) {
    actions.updateBox({
      boxId: activeBox.id,
      newBox: {
        type,
        required: boxCanToggleRequiredStatus(type),
        width: getWidthFromBoxType(type),
        height: getHeightFromBoxType(type),
      },
    });
  }

  function setSigner(signer) {
    actions.updateBox({
      boxId: activeBox.id,
      newBox: {
        signer,
      },
    });
  }

  function setPlaceholder(placeholder) {
    actions.updateBox({
      boxId: activeBox.id,
      newBox: {
        placeholder,
      },
    });
  }

  function setRequired(required) {
    actions.updateBox({
      boxId: activeBox.id,
      newBox: {
        required,
      },
    });
  }

  function setInvestorType(investorType) {
    actions.updateBox({
      boxId: activeBox.id,
      newBox: {
        investorType,
      },
    });
  }

  function deleteBox() {
    actions.deleteBox(activeBox.id);
  }

  return (
    <>
      <div className={classes.spaceBetween}>
        <SignerDropdown
          fullWidth
          relevantSigners={relevantSigners}
          activeSigner={activeBox.signer}
          setActiveSigner={setSigner}
          inputLabelText="Assigned to"
        />
        <Button
          aria-label="Delete box"
          variant="icon"
          size="large"
          onClick={deleteBox}
        >
          <Icons.DeleteOutline />
        </Button>
      </div>

      <Divider />

      <BoxTypeSelect boxType={activeBox.type} setBoxType={setBoxType} />

      {allowInvestorType ? (
        <InvestorTypeSelect
          investorType={activeBox.investorType}
          setInvestorType={setInvestorType}
        />
      ) : null}

      {canSetTooltipText ? (
        /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
        <div
          onKeyDown={(e) => e.stopPropagation()}
          onKeyUp={(e) => e.stopPropagation()}
        >
          <TextField
            fullWidth
            id="tooltipText"
            variant="outlined"
            label="Tooltip text"
            type="text"
            value={activeBox.placeholder}
            onChange={(e) => setPlaceholder(e.target.value)}
          />
        </div>
      ) : null}

      {canToggleBoxIsRequired ? (
        <FormControlLabel
          control={<Switch checked={activeBox.required} />}
          label="Required field"
          onChange={() => setRequired(!activeBox.required)}
        />
      ) : null}
    </>
  );
}

function ReadOnlyFieldWithLabel({ label, children }) {
  const classes = useStyles();

  return (
    <div className={classes.fieldWithLabel}>
      <Typography variant="label" size="small" color="text.secondary">
        {label}
      </Typography>
      {children}
    </div>
  );
}

function Circle({ color }) {
  const classes = useStyles();

  return <div className={classes.circle} style={{ backgroundColor: color }} />;
}

function ReadOnlyFields({ activeBox, actions, signer }) {
  const classes = useStyles();
  const { text, icon } = getBoxInfo(activeBox);

  const investorType = constants.INVESTOR_TYPE_CHOICES.find(
    (t) => t.value === activeBox.investorType,
  );
  const boxIcon = cloneElement(icon, { style: { color: signer.color } });

  function removeBox() {
    actions.removePlacedBox(activeBox.id);
  }

  return (
    <>
      <div className={classes.spaceBetween}>
        <ReadOnlyFieldWithLabel label="Assigned to">
          <div className={classes.iconText}>
            <Circle color={signer.color} />
            <Typography variant="body">{signer.name}</Typography>
          </div>
        </ReadOnlyFieldWithLabel>
        <Button
          aria-label="Remove box"
          variant="icon"
          size="large"
          onClick={removeBox}
        >
          <Icons.DeleteOutline />
        </Button>
      </div>

      <Divider />

      <ReadOnlyFieldWithLabel label="Type">
        <div className={classes.iconText}>
          {boxIcon}
          <Typography variant="body">{text}</Typography>
        </div>
      </ReadOnlyFieldWithLabel>

      <ReadOnlyFieldWithLabel label="Investor type">
        <div className={classes.iconText}>
          {investorType.icon}
          <Typography variant="body">{investorType.label}</Typography>
        </div>
      </ReadOnlyFieldWithLabel>
    </>
  );
}

export function FieldPanel({
  relevantSigners,
  activeBox,
  actions,
  allowInvestorType,
  allowEditing,
  boxToPlace,
  selectedBoxType,
}) {
  const classes = useStyles();

  const signer = relevantSigners.find(
    (s) => s.pdfBoxSigner === activeBox?.signer,
  );

  const isPlacing =
    boxToPlace !== null || (selectedBoxType !== null && activeBox === null);

  return (
    <Paper square variant="outlined" className={classes.container}>
      {isPlacing ? (
        <div className={classes.centerVertically}>
          Place the selected field in the document
        </div>
      ) : (
        <>
          {activeBox === null ? <EmptyState /> : null}
          {activeBox && allowEditing ? (
            <EditFields
              relevantSigners={relevantSigners}
              activeBox={activeBox}
              actions={actions}
              allowInvestorType={allowInvestorType}
            />
          ) : null}
          {activeBox && !allowEditing ? (
            <ReadOnlyFields
              activeBox={activeBox}
              actions={actions}
              signer={signer}
            />
          ) : null}
        </>
      )}
    </Paper>
  );
}
