import { FILTERS } from './constants';

export function truncate(text) {
  if (!text) {
    return '';
  }
  if (text.length > 500) {
    return `${text.substring(0, 500)}...`;
  }
  return text;
}

function needsAttention(item, mappings) {
  const mapping = (mappings || {})[item.id];
  return (
    !mapping?.tags?.length &&
    !mapping?.mappedTo &&
    !mapping?.notInteroperable &&
    !mapping?.ignored &&
    !mapping?.equalTo &&
    mapping.potentialMatches.some((match) => match.compatible)
  );
}

function noMatches(item, mappings) {
  const mapping = (mappings || {})[item.id];
  return (
    !mapping?.tags?.length &&
    !mapping?.mappedTo &&
    !mapping?.notInteroperable &&
    !mapping?.ignored &&
    !mapping?.equalTo &&
    mapping.potentialMatches.every((match) => !match.compatible)
  );
}

export const transformItems = (mappings) => (item) => {
  const mapping = (mappings || {})[item.id];
  const filters = [];

  if (item.isSection) {
    return { ...item, filters, mapping };
  }

  if (mapping?.tags?.length) {
    filters.push(FILTERS.TAGGED);
  }

  if (mapping?.mappedTo) {
    filters.push(FILTERS.MAPPED);
  }

  if (mapping?.ignored) {
    filters.push(FILTERS.IGNORED);
  }

  if (mapping?.notInteroperable) {
    filters.push(FILTERS.NOT_INTEROPERABLE);
  }

  if (mapping?.equalTo) {
    filters.push(FILTERS.SAME_TEXT);
  }

  if (noMatches(item, mappings)) {
    filters.push(FILTERS.NO_MATCHES);
  }

  if (noMatches(item, mappings)) {
    filters.push(FILTERS.NO_MATCHES);
  }

  if (needsAttention(item, mappings)) {
    filters.push(FILTERS.NEEDS_ATTENTION);
  }

  return { ...item, filters, mapping };
};

export const countFilters = (questions) => {
  const counts = {};
  questions.forEach((item) => {
    item.filters.forEach((filter) => {
      counts[filter] = (counts[filter] || 0) + 1;
    });
  });
  counts[FILTERS.ALL] = questions.length;
  return counts;
};

export const applyListFilter = (filter) => (item) => {
  if (item?.isSection) {
    return true;
  }

  if (filter === FILTERS.ALL) {
    return true;
  }

  return item.filters.includes(filter);
};

export const applyCountFilter = (filter) => (item) => {
  if (filter === FILTERS.ALL) {
    if (item.filters.includes(FILTERS.TAGGED)) {
      return true;
    }

    if (item.filters.includes(FILTERS.SAME_TEXT)) {
      return true;
    }

    if (item.filters.includes(FILTERS.MAPPED)) {
      return true;
    }

    return false;
  }
  return item.filters.includes(filter);
};

export const applySearch = (search) => (item) => {
  if (!search) {
    return true;
  }

  if (item.isSection) {
    return true;
  }

  const question = `${item.id} ${item.question}`.toLowerCase();
  return question.includes(search.toLowerCase());
};

export const applyRemoveEmptySections = (item, idx, array) => {
  if (!item.isSection) {
    return true;
  }

  if (idx === array.length - 1) {
    return false;
  }

  return !array[idx + 1].isSection;
};
