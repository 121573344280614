import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Button } from 'components/button';
import { Dialog } from 'components/dialog/index';
import { InternalChip } from '@passthrough/uikit';

const useDialogStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.default,
  },
}));

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.appbar.background,
  },
  toolBar: {
    justifyContent: 'space-between',
  },
  toolBarLeftSide: {
    flexDirection: 'column',
  },
  closeButton: {
    marginRight: theme.spacing(2),
  },
  iframe: {
    width: '100%',
    flexGrow: 1,
    border: `1px solid ${theme.palette.divider}`,
  },
  pdfViewer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    alignItems: 'center',
  },
  titleBody: {
    fontWeight: 500,
  },
  titleBold: {
    fontWeight: 600,
  },
  chip: {
    marginRight: theme.spacing(1),
  },
}));

export function StaffReviewFile({ lpName, file, setFile, skipStaffReview }) {
  const dialogClasses = useDialogStyles();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (file?.fileId && !skipStaffReview) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [file?.fileId, skipStaffReview]);

  return (
    <Dialog open={open} fullScreen classes={dialogClasses}>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <div className={classes.toolBarLeftSide}>
            <InternalChip className={classes.chip} />
            <Typography className={classes.titleBody} component="span">
              Does "
            </Typography>
            <Typography className={classes.titleBold} component="span">
              {file?.fileName}
            </Typography>
            <Typography className={classes.titleBody} component="span">
              " belong to{' '}
            </Typography>
            <Typography className={classes.titleBold} component="span">
              {lpName}
            </Typography>
            <Typography className={classes.titleBody} component="span">
              ?
            </Typography>
          </div>
          <div>
            <Button
              variant="text"
              size="large"
              color="default"
              onClick={() => setFile({})}
              className={classes.closeButton}
            >
              No, cancel
            </Button>
            <Button size="large" onClick={() => setOpen(false)}>
              Yes, upload
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.pdfViewer}>
        <iframe
          src={file?.fileUrl}
          className={classes.iframe}
          title="Review file"
        />
      </div>
    </Dialog>
  );
}
