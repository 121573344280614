import React, { useEffect } from 'react';

import { ReviewChange } from './review_change';
import { getId } from '../publish_modal/utils';
import { ReviewJSONDiff } from '../publish_modal/review_json_diff';

export const ReviewChanges = ({ diff }) => {
  const [expanded, setExpanded] = React.useState({});

  useEffect(() => {
    const expandedMap = {};
    diff.created.forEach((q) => {
      const id = getId(q);
      expandedMap[id] = false;
    });
    diff.deleted.forEach((q) => {
      const id = getId(q);
      expandedMap[id] = false;
    });
    diff.updated.forEach((q) => {
      const id = getId(q[1]);
      expandedMap[id] = false;
    });
    setExpanded(expandedMap);
  }, []);

  return (
    <>
      {diff.created.map((q) => {
        const id = getId(q);
        return (
          <ReviewChange
            id={id}
            key={id}
            title={`${id} CREATED`}
            expanded={expanded}
            setExpanded={setExpanded}
          >
            <ReviewJSONDiff newObj={q} oldObj={{}} />
          </ReviewChange>
        );
      })}

      {diff.updated.map((q) => {
        const id = getId(q[1]);

        return (
          <ReviewChange
            key={id}
            id={id}
            title={`${id} UPDATED`}
            expanded={expanded}
            setExpanded={setExpanded}
          >
            <ReviewJSONDiff newObj={q[1]} oldObj={q[0]} />
          </ReviewChange>
        );
      })}

      {diff.deleted.map((q) => {
        const id = getId(q);
        return (
          <ReviewChange
            id={id}
            key={id}
            title={`${id} DELETED`}
            expanded={expanded}
            setExpanded={setExpanded}
          >
            <ReviewJSONDiff newObj={{}} oldObj={q} />
          </ReviewChange>
        );
      })}
    </>
  );
};
