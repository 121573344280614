import { useHistory } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal } from '@passthrough/uikit';

// https://stackoverflow.com/a/71105897
export function MultiOptionPrompt({ when, title, onSubmit, message }) {
  const history = useHistory();
  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const unblockRef = useRef();

  const onClose = () => setShowPrompt(false);

  const primaryContinue = () => {
    if (unblockRef) {
      unblockRef.current();
    }
    if (onSubmit) {
      setIsLoading(true);
      onSubmit();
    }
    history.block(() => {});
    history.push(currentPath);
  };
  const onContinueNoSave = () => {
    if (unblockRef) {
      unblockRef.current();
    }
    history.block(() => {});
    history.push(currentPath);
  };

  useEffect(() => {
    unblockRef.current = history.block((location) => {
      if (when) {
        setCurrentPath(location.pathname);
        setShowPrompt(true);
        return false;
      }
      return true;
    });
    return () => {
      if (unblockRef.current) {
        unblockRef.current();
      }
    };
  }, [when]);

  useEffect(() => {
    const confirmForBrowser = (e) => {
      if (when) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.onbeforeunload = confirmForBrowser;
    return () => {
      window.onbeforeunload = null;
    };
  }, [when]);

  return (
    <Modal
      headerLabel={title}
      open={showPrompt}
      onClose={onClose}
      onSubmit={primaryContinue}
      destructive={!onSubmit}
      showCancelButton
      primaryButtonText={onSubmit ? 'Save' : 'Continue without saving'}
      primaryButtonLoading={isLoading}
      tertiaryButton={
        onSubmit ? (
          <Button onClick={onContinueNoSave} variant="secondary">
            Continue without saving
          </Button>
        ) : null
      }
    >
      {message}
    </Modal>
  );
}
