import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { PendingCardsReview } from './pending_cards_review';
import { ApprovedCardsReview } from './approved_cards_review';

const useStyles = makeStyles((theme) => ({
  upperSpacing: {
    marginTop: theme.spacing(3),
  },
}));

export function DiligenceCardsReview({
  diligenceQuestions,
  onShowReviewClick,
  className,
  areSearchesReady,
  isInProgress,
}) {
  const classes = useStyles();
  const filteredDiligenceQuestions = diligenceQuestions.filter(
    (question) => !question.answer?.idToReuse,
  );

  const pendingQuestions = filteredDiligenceQuestions.filter(
    (question) => !question.isApproved,
  );
  const approvedQuestions = filteredDiligenceQuestions.filter(
    (question) => question.isApproved,
  );

  const shouldShowApprovedCardsReview = approvedQuestions.length > 0;
  const shouldShowPendingCardsReview = pendingQuestions.length > 0;

  return (
    <div className={className}>
      {shouldShowPendingCardsReview ? (
        <PendingCardsReview
          pendingEntries={pendingQuestions}
          onShowReviewClick={onShowReviewClick}
          areSearchesReady={areSearchesReady}
          isInProgress={isInProgress}
        />
      ) : null}

      {shouldShowApprovedCardsReview ? (
        <ApprovedCardsReview
          approvedEntries={approvedQuestions}
          onShowReviewClick={onShowReviewClick}
          className={clsx({
            [classes.upperSpacing]: shouldShowPendingCardsReview,
          })}
          areSearchesReady={areSearchesReady}
          isInProgress={isInProgress}
        />
      ) : null}
    </div>
  );
}
