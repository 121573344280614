import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  Modal,
  UIKitSettingsProvider,
  Typography,
  useConfirm,
} from '@passthrough/uikit';

import * as api from 'services/api';
import { useToast } from 'services/toast';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  makeStyles,
} from '@material-ui/core';
import { Button } from 'components/button';

const useStyles = makeStyles((theme) => ({
  formGroup: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  checkboxLabel: {
    margin: theme.spacing(1),
  },
}));

export function ClosingsExport({ open, handleClose, closings }) {
  const classes = useStyles();
  const { fundId } = useParams();
  const { successToast } = useToast();
  const confirm = useConfirm();
  const [exportLoading, setExportLoading] = useState(false);
  const [exportUrl, setExportUrl] = useState(null);
  const [selectedClosingIds, setSelectedClosingIds] = useState([]);

  useEffect(() => {
    // Reset selectedClosingIds when opening modal
    if (open) {
      setSelectedClosingIds([]);
    }
  }, [open]);

  function handleChange(e, value) {
    if (e.target.checked) {
      setSelectedClosingIds([...selectedClosingIds, value]);
    } else {
      setSelectedClosingIds(selectedClosingIds.filter((s) => s !== value));
    }
  }

  function isSelectionDisabled(closing) {
    return closing.numTotal === 0;
  }

  function isSelectionEnabled(closing) {
    return closing.numTotal > 0;
  }

  const allSelectableClosings = closings.filter(isSelectionEnabled);

  const shouldSelectAll =
    selectedClosingIds.length !== allSelectableClosings.length;
  function handleSelectAll() {
    if (shouldSelectAll) {
      setSelectedClosingIds(allSelectableClosings.map((c) => c.id));
    } else {
      setSelectedClosingIds([]);
    }
  }

  function pollForResults(exportId) {
    api.getFundClosingsExport({ fundId, exportId }).then((response) => {
      if (response.data.url) {
        setExportLoading(false);
        setExportUrl(response.data.url);
        successToast('Generated export');
      } else {
        setTimeout(() => {
          pollForResults(exportId);
        }, 500);
      }
    });
  }

  function onSubmit(e) {
    e.preventDefault();

    setExportLoading(true);

    api
      .exportFundClosings({
        fundId,
        closingIds: selectedClosingIds,
      })
      .then((response) => {
        const exportId = response.data;
        setTimeout(() => {
          pollForResults(exportId);
        }, 500);
      });
  }

  function onCloseWithoutDownloadingExport() {
    confirm({
      title: 'Leave without report?',
      description: 'You have not downloaded your report.',
      confirmationText: 'Leave',
      size: 'xs',
      destructive: true,
    })
      .then(() => {
        handleClose();
      })
      .catch(() => {});
  }

  return (
    <UIKitSettingsProvider redirectAttribute="href">
      <Modal
        open={open}
        headerLabel="Export closings"
        onClose={() => {
          if (exportUrl || exportLoading) {
            onCloseWithoutDownloadingExport();
          } else {
            handleClose();
          }
        }}
        onExited={() => {
          setExportUrl(null);
          setExportLoading(false);
          setSelectedClosingIds([]);
        }}
        onSubmit={onSubmit}
        primaryButtonText={exportUrl ? 'Download report' : 'Generate export'}
        primaryButtonLoading={exportLoading}
        primaryButtonDisabled={selectedClosingIds.length === 0}
        primaryButtonEndIcon={exportUrl ? <GetAppIcon /> : null}
        primaryButtonProps={{
          href: exportUrl,
          // if the primary button is provided an href and renders as
          // an anchor tag, onSubmit wont fire when clicking it so we
          // need to pass another manual handler
          onClick: () => {
            if (exportUrl) {
              handleClose();
            }
          },
        }}
      >
        <Typography>Generating an export may take a few moments.</Typography>
        <FormControl component="fieldset" variant="outlined">
          <FormGroup className={classes.formGroup}>
            <FormControlLabel
              control={
                <Button
                  variant="text"
                  onClick={handleSelectAll}
                  color="primary"
                >
                  {shouldSelectAll ? 'Select all' : 'Clear'}
                </Button>
              }
            />
            {closings.map((closing) => (
              <FormControlLabel
                key={closing.id}
                control={
                  <Checkbox
                    checked={selectedClosingIds.includes(closing.id)}
                    onChange={(e) => handleChange(e, closing.id)}
                  />
                }
                label={
                  <div className={classes.checkboxLabel}>
                    <Typography variant="body1">{closing.name}</Typography>
                    <Typography
                      variant="label"
                      color={
                        isSelectionDisabled(closing) ? null : 'text.secondary'
                      }
                    >
                      {closing.numTotal} investor
                      {closing.numTotal === 1 ? '' : 's'}
                    </Typography>
                  </div>
                }
                disabled={isSelectionDisabled(closing)}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Modal>
    </UIKitSettingsProvider>
  );
}
