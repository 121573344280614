import React from 'react';
import { Typography, Button } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';
import { isOwnerType, getOwnerFields } from 'components/lp_doc/diligence/utils';
import Paper from '@material-ui/core/Paper';
import PencilIcon from 'mdi-material-ui/PencilOutline';

import { SingleChildTypeDisplay } from './single_child_type_display';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paperRoot: {
    padding: theme.spacing(0, 2),
  },
  childDisplay: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
}));

function getChildrenAnswerData(answerData) {
  if (!answerData) {
    return {};
  }

  return Object.keys(answerData)
    .filter((key) => isOwnerType(key, answerData[key]))
    .reduce((res, key) => {
      res[key] = answerData[key];

      return res;
    }, {});
}

export function ChildrenDisplay({
  jurisdiction,
  answerData,
  hideEditButtons,
  lastSubmittedAnswer,
  changedType,
  handleOpenOwnersEditModal,
}) {
  const classes = useStyles();

  const allOwnerConfigData = getOwnerFields(answerData?.type, jurisdiction);
  const hasChildrenData = allOwnerConfigData.length > 0;

  const childrenData = getChildrenAnswerData(answerData);
  const lastChildrenData = !changedType
    ? getChildrenAnswerData(lastSubmittedAnswer || {})
    : null;

  if (!hasChildrenData) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <Typography variant="card-heading">Owners</Typography>

        {hideEditButtons ? null : (
          <Button
            size="small"
            aria-label="Edit owners"
            tooltip="Edit owners"
            variant="icon"
            onClick={handleOpenOwnersEditModal}
          >
            <PencilIcon />
          </Button>
        )}
      </div>

      <Paper variant="outlined" className={classes.paperRoot}>
        {allOwnerConfigData.map((ownerTypeConfig) => (
          <SingleChildTypeDisplay
            key={ownerTypeConfig.key}
            typeLabel={ownerTypeConfig.pluralLabel}
            currChildren={childrenData[ownerTypeConfig.key]}
            lastChildren={lastChildrenData?.[ownerTypeConfig.key]}
            className={classes.childDisplay}
          />
        ))}
      </Paper>
    </div>
  );
}
