import React from 'react';
import { Container } from '@material-ui/core';
import { TypographyShowCase } from './show_cases/typography';
import { ButtonShowCase } from './show_cases/button';
import { LinkShowCase } from './show_cases/link';
import { ChipShowCase } from './show_cases/chip';
import { AlertShowCase } from './show_cases/alert';
import { TabsShowCase } from './show_cases/tabs';
import { CrumbsShowCase } from './show_cases/crumbs';
import { PageHeaderShowCase } from './show_cases/page_header';
import { MenuShowCase } from './show_cases/menu';
import { ModalShowCase } from './show_cases/modal';
import { SpotIconShowCase } from './show_cases/spot_icon';
import { ConfirmShowCase } from './show_cases/confirm';
import { ThemeShowcase } from './show_cases/theme';
import { UserDisplayShowCase } from './show_cases/user_display';
import { FileUploadShowCase } from './show_cases/file_upload/file_upload';

export const StaffUikit = () => (
  <Container>
    <TypographyShowCase />
    <ButtonShowCase />
    <LinkShowCase />
    <ChipShowCase />
    <AlertShowCase />
    <TabsShowCase />
    <CrumbsShowCase />
    <PageHeaderShowCase />
    <MenuShowCase />
    <ModalShowCase />
    <SpotIconShowCase />
    <ConfirmShowCase />
    <UserDisplayShowCase />
    <FileUploadShowCase />
    <ThemeShowcase />
  </Container>
);
