import React from 'react';

import { Address } from 'components/address_v2';
import { DiligenceTIN } from '../tin';

export function TinHqAddressDetails({
  tin,
  tinError,
  hqAddress,
  hqAddressError,
  handleChange,
  tinLabel = 'TIN',
  addressLabel = 'Headquarters Address',
  addressOnly = false,
}) {
  return (
    <>
      {!addressOnly ? (
        <DiligenceTIN
          label={tinLabel}
          tin={tin}
          tinError={tinError}
          onChange={(e) => {
            handleChange('tin', e.target.value);
          }}
        />
      ) : null}

      <Address
        address={hqAddress}
        onChange={(addr) => {
          handleChange('hqAddress', addr);
        }}
        errors={hqAddressError}
        label={addressLabel}
      />
    </>
  );
}
