import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MatButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

export const SAVING = 'SAVING';
export const UNSAVED = 'UNSAVED';
export const SAVED = 'SAVED';

const LABELS = {
  [SAVING]: 'Saving',
  [UNSAVED]: 'Unsaved',
  [SAVED]: 'Saved',
};

const ICONS = {
  [SAVING]: <CircularProgress size={20} />,
  [UNSAVED]: null,
  [SAVED]: null,
};

const TOOLTIPS = {
  [SAVING]: '',
  [UNSAVED]: '',
  [SAVED]: (
    <Typography>
      Your changes are saved. You can always close this window and come back to
      finish later.
    </Typography>
  ),
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: `${theme.palette.primary.black} !important`,
    width: '85px',
  },
  span: {
    alignSelf: 'center',
    width: '85px',
  },
}));

export function SaveIndicator({ saving }) {
  const classes = useStyles();
  const label = LABELS[saving];
  const icon = ICONS[saving];
  const tooltip = TOOLTIPS[saving];

  return (
    <Tooltip title={tooltip}>
      <span className={classes.span}>
        <MatButton
          variant="text"
          disabled
          endIcon={icon}
          style={{ visibility: saving === UNSAVED ? 'hidden' : '' }}
          className={classes.root}
        >
          {label}
        </MatButton>
      </span>
    </Tooltip>
  );
}
