import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useToast } from 'services/toast';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import * as api from 'services/api';
import { formatApprovedDomainEmailError } from 'services/approved_domains';

import { Alert, Typography, Modal } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  radioLabel: {
    margin: theme.spacing(1),
  },
  emailPreferenceCheckboxes: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
}));

function ExpiresAlert() {
  return <Alert severity="info">Access will expire after 24 hours.</Alert>;
}

export function NewMemberDialog({
  fundId,
  diligenceEnabled,
  open,
  onClose,
  onChange,
  groupChoices = [],
}) {
  const classes = useStyles();
  const { successToast } = useToast();

  const [errors, setErrors] = useState({});
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [level, setLevel] = useState('');
  const [groupId, setGroupId] = useState('');
  const [enableTransactionalEmails, setEnableTransactionalEmails] =
    useState(true);
  const [enableDiligenceDigest, setEnableDiligenceDigest] = useState(false);
  const [loading, setLoading] = useState(false);

  function resetLocalData() {
    setName('');
    setEmail('');
    setLevel('');
    setGroupId('');
    setEnableTransactionalEmails(true);
    setEnableDiligenceDigest(false);
    setErrors({});
  }

  function handleSubmit() {
    setLoading(true);
    api
      .createMember({
        name,
        email,
        level,
        fundId,
        groupId,
        transactionEmailsEnabled: enableTransactionalEmails,
        digestEmailsEnabled: enableDiligenceDigest,
      })
      .then(() => {
        onChange();
        onClose();
        successToast(`Added ${name} as a collaborator.`);
        setErrors({});
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setErrors(error.response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const showExpiresAlert =
    email.includes('@passthrough.com') &&
    !window.UNLIMITED_PASSTHROUGH_ACCESS_ENABLED;
  const hasCustomApproval = groupChoices.length > 0;
  const changed = Boolean(name && email && level);

  return (
    <Modal
      headerLabel="New collaborator"
      open={open}
      onClose={onClose}
      onExited={resetLocalData}
      size="xs"
      primaryButtonText="Add"
      primaryButtonLoading={loading}
      primaryButtonDisabled={!changed}
      onSubmit={handleSubmit}
      showCancelButton
    >
      <Typography variant="label">
        This will notify the new collaborator by email.
      </Typography>
      {errors.detail ? <Alert severity="error">{errors.detail}</Alert> : null}
      <TextField
        autoFocus
        required
        name="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        variant="outlined"
        label="Name"
        type="text"
        error={!!errors.name}
        helperText={errors.name}
        fullWidth
      />
      <TextField
        required
        name="email"
        value={email}
        onChange={(ev) => setEmail(ev.target.value)}
        variant="outlined"
        label="Email"
        type="email"
        error={!!errors.email}
        helperText={formatApprovedDomainEmailError(errors)}
        fullWidth
      />
      {hasCustomApproval ? (
        <FormControl variant="outlined" fullWidth error={!!errors.group}>
          <InputLabel id="group-label">Group</InputLabel>
          <Select
            label="Group"
            labelId="group-label"
            name="groupId"
            value={groupId}
            onChange={(ev) => setGroupId(ev.target.value)}
          >
            <MenuItem key="none" value="" aria-label="None">
              None
            </MenuItem>
            {groupChoices.map((groupChoice) => (
              <MenuItem key={groupChoice.id} value={groupChoice.id}>
                {groupChoice.name}
              </MenuItem>
            ))}
          </Select>
          {errors.group ? (
            <FormHelperText>{errors.group}</FormHelperText>
          ) : null}
        </FormControl>
      ) : null}
      {showExpiresAlert ? <ExpiresAlert /> : null}
      <FormControl
        required
        error={!!errors.level}
        component="fieldset"
        fullWidth
      >
        <FormLabel component="legend">Access level</FormLabel>
        <RadioGroup
          aria-label="access"
          name="level"
          value={level}
          onChange={(ev) => setLevel(ev.target.value)}
        >
          <FormControlLabel
            value="EDIT"
            control={<Radio />}
            label={
              <div className={classes.radioLabel}>
                <Typography variant="body">Edit</Typography>
                <Typography variant="label">
                  Full permissions to the fund and managing access.
                </Typography>
              </div>
            }
          />
          {hasCustomApproval && (
            <FormControlLabel
              value="REVIEW"
              control={<Radio />}
              label={
                <div className={classes.radioLabel}>
                  <Typography variant="body">Review</Typography>
                  <Typography variant="label">
                    Ability to view all fund data and take assigned actions.
                  </Typography>
                </div>
              }
            />
          )}
          <FormControlLabel
            value="VIEW"
            control={<Radio />}
            label={
              <div className={classes.radioLabel}>
                <Typography variant="body">View only</Typography>
                <Typography variant="label">
                  Ability to view all fund data.
                </Typography>
              </div>
            }
          />
        </RadioGroup>
        {errors.level ? <FormHelperText>{errors.level}</FormHelperText> : null}
      </FormControl>

      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend">Email preferences</FormLabel>

        <div className={classes.emailPreferenceCheckboxes}>
          <FormControlLabel
            control={
              <Checkbox
                checked={enableTransactionalEmails}
                onChange={(e) => {
                  setEnableTransactionalEmails(e.target.checked);
                }}
              />
            }
            label={
              <div>
                <Typography variant="body">Investor activity</Typography>
                <Typography variant="label">
                  Updates on investor status and comments
                </Typography>
              </div>
            }
          />

          {diligenceEnabled ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={enableDiligenceDigest}
                  onChange={(e) => {
                    setEnableDiligenceDigest(e.target.checked);
                  }}
                />
              }
              label={
                <div>
                  <Typography variant="body">Daily diligence report</Typography>
                  <Typography variant="label">
                    A daily report about investor risk rating updates
                  </Typography>
                </div>
              }
            />
          ) : null}
        </div>
      </FormControl>
    </Modal>
  );
}
