import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import {
  Modal,
  Typography,
  UIKitSettingsProvider,
  useConfirm,
} from '@passthrough/uikit';

import { Spinner } from 'components/spinner';
import { useToast } from 'services/toast';
import * as api from 'services/api';
import { DisplayLink } from 'components/link';
import { EXPOSED_SIDE_LETTER_TYPES } from 'components/document_upload/constants';

const EXPOSED_DOC_TYPES = [
  { key: 'Subscription document', display: 'Subscription document' },
  ...EXPOSED_SIDE_LETTER_TYPES,
];

const useStyles = makeStyles((theme) => ({
  helperText: {
    color: theme.palette.text.secondary,
  },
  dialogContent: {
    overflowY: 'auto',
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  formGroup: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  helperCheckboxLabel: {
    margin: theme.spacing(1),
  },
}));

const useFormControlLabelStyles = makeStyles(() => ({
  disabled: {
    cursor: 'not-allowed !important',
  },
}));

export function ZipDialog({ open, handleClose, investors, onDownload }) {
  const classes = useStyles();
  const formControlLabelClasses = useFormControlLabelStyles();

  const confirm = useConfirm();
  const { fundId, closingId } = useParams();
  const { successToast } = useToast();
  const [exportLoading, setExportLoading] = useState(false);
  const [docCountsLoading, setDocCountsLoading] = useState(false);
  const [exportUrl, setExportUrl] = useState(null);
  const [docTypeCounts, setDocTypeCounts] = useState({});
  const [selectedDocTypes, setSelectedDocTypes] = useState([]);
  const [shouldGroupInvestors, setShouldGroupInvestors] = useState(true);
  const pollTimeoutId = useRef(null);

  const lpClosingIds = investors.map((i) => i.id);

  function pollForResults(zipId) {
    api.getZip({ fundId, closingId, zipId }).then((response) => {
      if (response.data.url) {
        setExportLoading(false);
        pollTimeoutId.current = null;
        setExportUrl(response.data.url);
        successToast('Generated zip archive');
      } else {
        const timeoutId = setTimeout(() => {
          pollForResults(zipId);
        }, 5000);

        pollTimeoutId.current = timeoutId;
      }
    });
  }

  function fetchDocTypeCounts() {
    setDocCountsLoading(true);
    api
      .getZipDocTypeCount({
        fundId,
        closingId,
        lpClosingIds,
      })
      .then((response) => {
        const newDocTypeCounts = {};
        response.data.forEach((docTypeCount) => {
          newDocTypeCounts[docTypeCount.doctype] = docTypeCount.count;
        });
        setDocTypeCounts(newDocTypeCounts);
      })
      .finally(() => {
        setDocCountsLoading(false);
      });
  }

  useEffect(() => {
    if (open) {
      fetchDocTypeCounts();
    }
  }, [open, lpClosingIds.length]);

  function getCount(docType) {
    if (!Object.prototype.hasOwnProperty.call(docTypeCounts, docType.key)) {
      return 0;
    }
    return docTypeCounts[docType.key];
  }

  function docTypeIsSelectable(docType) {
    return getCount(docType) > 0;
  }

  const selectableDocTypes = EXPOSED_DOC_TYPES.filter(docTypeIsSelectable);

  function handleSelectAll() {
    setSelectedDocTypes(selectableDocTypes.map((docType) => docType.key));
  }

  function handleClear() {
    setSelectedDocTypes([]);
  }

  function handleSelection(docType) {
    setSelectedDocTypes((oldSelectedDocTypes) => {
      if (oldSelectedDocTypes.includes(docType.key)) {
        return oldSelectedDocTypes.filter((key) => key !== docType.key);
      }
      return [...oldSelectedDocTypes, docType.key];
    });
  }

  function onSubmit(e) {
    e.preventDefault();

    setExportLoading(true);

    api
      .zipLpClosings({
        fundId,
        closingId,
        lpClosingIds,
        allowedDocTypes: selectedDocTypes,
        isFlattened: !shouldGroupInvestors,
      })
      .then((response) => {
        const zipId = response.data;

        const timeoutId = setTimeout(() => {
          pollForResults(zipId);
        }, 5000);
        pollTimeoutId.current = timeoutId;
      });
  }

  function onCloseWithoutDownload() {
    confirm({
      title: 'Leave without report?',
      description: 'You have not downloaded your report',
      size: 'xs',
      confirmLabel: 'Leave',
    })
      .then(() => {
        handleClose();
        if (onDownload) {
          onDownload();
        }
      })
      .catch(() => {});
  }

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    const { current: descriptionElement } = descriptionElementRef;
    if (descriptionElement !== null) {
      descriptionElement.focus();
    }
  }, []);

  useEffect(() => {
    setExportUrl(null);
    setSelectedDocTypes([]);
    setShouldGroupInvestors(true);
  }, [investors.length]);

  function documentTypeSelectionForm() {
    if (docCountsLoading) {
      return (
        <div className={classes.spinner}>
          <Spinner height={300} width={300} />
        </div>
      );
    }

    return (
      <div>
        <Typography fontWeight={500}>
          Select investor documents to download
        </Typography>

        {selectedDocTypes.length < selectableDocTypes.length ? (
          <DisplayLink onClick={handleSelectAll}>
            <Typography fontWeight={500}>Select all</Typography>
          </DisplayLink>
        ) : (
          <DisplayLink onClick={handleClear}>
            <Typography fontWeight={500}>Clear</Typography>
          </DisplayLink>
        )}

        <FormGroup className={classes.formGroup}>
          {EXPOSED_DOC_TYPES.map((docType) => {
            const itemIsSelected = selectedDocTypes.includes(docType.key);
            const itemIsSelectable = docTypeIsSelectable(docType);

            return (
              <FormControlLabel
                key={docType.key}
                label={`${docType.display} (${getCount(docType)})`}
                classes={formControlLabelClasses}
                control={
                  <Checkbox
                    checked={itemIsSelected}
                    onChange={() => handleSelection(docType)}
                  />
                }
                disabled={!itemIsSelectable}
              />
            );
          })}
        </FormGroup>

        <Typography fontWeight={500}>Export options</Typography>
        <FormGroup className={classes.formGroup}>
          <FormControlLabel
            key="flatten"
            label={
              <div className={classes.helperCheckboxLabel}>
                <Typography variant="body1">Group by investor</Typography>
                <Typography variant="body2" className={classes.helperText}>
                  Outputs one folder per investor
                </Typography>
              </div>
            }
            control={
              <Checkbox
                checked={shouldGroupInvestors}
                onChange={() => {
                  setShouldGroupInvestors(!shouldGroupInvestors);
                }}
              />
            }
          />
        </FormGroup>
      </div>
    );
  }

  return (
    <UIKitSettingsProvider redirectAttribute="href">
      <Modal
        open={open}
        onClose={() => {
          if (exportUrl || exportLoading) {
            onCloseWithoutDownload();
            return;
          }

          handleClose();
        }}
        onExited={() => {
          if (pollTimeoutId.current) {
            setExportLoading(false);
            clearTimeout(pollTimeoutId.current);
            pollTimeoutId.current = null;
          }
        }}
        showCancelButton
        headerLabel="Download documents"
        subheaderLabel={`${investors.length} investor${
          investors.length > 1 ? 's' : ''
        } selected`}
        primaryButtonLoading={exportLoading}
        primaryButtonDisabled={selectedDocTypes.length === 0}
        primaryButtonText={exportUrl ? 'Download documents' : 'Generate zip'}
        primaryButtonEndIcon={exportUrl ? <GetAppIcon /> : null}
        onSubmit={onSubmit}
        primaryButtonProps={{
          href: exportUrl,
          onClick: () => {
            if (exportUrl) {
              handleClose();
              if (onDownload) {
                onDownload();
              }
            }
          },
        }}
      >
        <Typography>
          Generating a zip archive of all documents can take several minutes.
        </Typography>

        <Collapse in={!exportUrl && !exportLoading}>
          <div>{documentTypeSelectionForm()}</div>
        </Collapse>
      </Modal>
    </UIKitSettingsProvider>
  );
}
