import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { resetSupport } from 'components/support';
import * as api from 'services/api';
import * as urls from 'services/urls';

export function useLogout() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const redirect = () => {
    history.push(urls.signinUrl({}));
  };

  const logout = () => {
    setLoading(true);
    api
      .logout()
      .then(() => {
        resetSupport();
        redirect();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  return [logout, loading];
}
