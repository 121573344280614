import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { UIKitSettingsProvider, Link } from '@passthrough/uikit';

import {
  DataValueDisplay,
  DataValueDisplayVariants,
} from 'components/data_value_display';
import { TypeDisplay } from 'components/type_display';
import { NotProvidedChip } from 'components/text_chip';
import {
  jurisdictions,
  ownerTypes,
} from 'components/lp_doc/diligence/constants';
import {
  genDiligenceAnswerLabel,
  filterDisplayableDiligenceAnswerKeys,
} from 'services/utils';
import {
  getDocumentConfigsForKeys,
  getOwnerFields,
} from 'components/lp_doc/diligence/utils';
import { getCountry } from 'services/countries';
import { SensitiveData } from './sensitive_data';
import { MAX_NUM_CHARS, addressAnswerKeys } from './constants';
import { formatAddress } from './utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
  nonFileDataContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2, 8),
  },
}));

function isFileValue(val) {
  return val && Object.hasOwn(val, 'fileId');
}

function isMultiFileValue(val) {
  return val && Array.isArray(val) && val.every((file) => isFileValue(file));
}

function getFlattenedSubAnswersToDisplay(
  answers,
  answerKeysToUse,
  ownerConfigData,
) {
  const processedAnswers = answerKeysToUse.map((key) => {
    const rawAnswer = answers[key];
    const ownerConfig = ownerConfigData.find((config) => config.key === key);
    if (ownerConfig) {
      const names = answers[key]?.map((ownerData) => ownerData?.name);
      if (names.length === 0) {
        return null;
      }

      return `${ownerConfig.pluralLabel} (${names.join(', ')})`;
    }
    if (isFileValue(rawAnswer)) {
      return rawAnswer.fileName;
    }
    if (isMultiFileValue(rawAnswer)) {
      return rawAnswer.map((file) => file.fileName).join(', ');
    }

    if (key === 'ssn' && rawAnswer) {
      if (answers?.useSsn) {
        // always hide ssn when flattening
        return '***-**-****';
      }

      return '*'.repeat(rawAnswer.length);
    }
    if (addressAnswerKeys.includes(key) && rawAnswer) {
      return formatAddress(rawAnswer);
    }

    if ((key === 'tin' || key === 'tinEin') && rawAnswer) {
      return '*'.repeat(rawAnswer.length);
    }

    if (key === 'countriesOfCitizenship' && rawAnswer) {
      return rawAnswer.map((countryCode) => getCountry(countryCode)).join(', ');
    }

    return rawAnswer;
  });

  return processedAnswers.filter((a) => a);
}

export function DiligenceAnswerDisplay({ answer, flatten, jurisdiction }) {
  const classes = useStyles();

  const relevantAnswerKeys = filterDisplayableDiligenceAnswerKeys(answer);
  const ownerConfigData = getOwnerFields(answer.type, jurisdiction);

  const nonEmptyFileKeys = relevantAnswerKeys.filter(
    (key) => isFileValue(answer[key]) || isMultiFileValue(answer[key]),
  );

  const fileConfigDataToShow = getDocumentConfigsForKeys(
    answer.type,
    nonEmptyFileKeys,
    jurisdiction,
  );

  const detailKeys = relevantAnswerKeys.filter(
    (key) => !ownerTypes.includes(key) && !nonEmptyFileKeys.includes(key),
  );

  if (flatten) {
    const flattenedAnswersToShow = getFlattenedSubAnswersToDisplay(
      answer,
      relevantAnswerKeys,
      ownerConfigData,
    );

    const numChars = flattenedAnswersToShow.reduce(
      (prevCount, subAnswer) => prevCount + `${subAnswer}`.length,
      0,
    );
    const joinedAnswers = flattenedAnswersToShow.join(', ');
    const truncatedAnswer = joinedAnswers.substring(0, MAX_NUM_CHARS);

    return numChars >= MAX_NUM_CHARS ? `${truncatedAnswer}...` : joinedAnswers;
  }

  return (
    <div className={classes.root}>
      {detailKeys.map((key) => {
        let val = answer[key];
        let finalKey = key;

        if (key === 'type' && Boolean(val)) {
          val = <TypeDisplay type={val} />;
        }

        if (addressAnswerKeys.includes(key) && Boolean(val)) {
          val = formatAddress(val);
        }

        if (key === 'ssn' && Boolean(val)) {
          val = <SensitiveData answer={val} />;

          if (!answer?.useSsn) {
            finalKey = 'taxId';
          }
          if (jurisdiction === jurisdictions.LUXEMBOURG) {
            finalKey = 'tinOrPassport';
          }
        }

        if ((key === 'tin' || key === 'tinEin') && Boolean(val)) {
          val = <SensitiveData answer={val} />;

          if (jurisdiction === jurisdictions.LUXEMBOURG) {
            finalKey = 'tinOnly';
          }
        }

        if (key === 'countriesOfCitizenship' && Boolean(val)) {
          val = val.map((countryCode) => getCountry(countryCode)).join(', ');
        }

        return (
          <DataValueDisplay
            key={finalKey}
            label={genDiligenceAnswerLabel(finalKey)}
            value={val}
            variant={DataValueDisplayVariants.HORIZONTAL}
          />
        );
      })}

      {fileConfigDataToShow.length > 0 ? (
        <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
          {fileConfigDataToShow.map((fileConfig) => {
            const fileData = answer[fileConfig.key];
            const files = Array.isArray(fileData) ? fileData : [fileData];
            return files.map((file, index) => (
              <DataValueDisplay
                key={fileConfig.key}
                label={index > 0 ? '' : fileConfig.label}
                value={
                  <Link variant="external" href={file.fileUrl}>
                    {file.fileName}
                  </Link>
                }
                variant={DataValueDisplayVariants.HORIZONTAL}
              />
            ));
          })}
        </UIKitSettingsProvider>
      ) : null}

      {ownerConfigData.map((ownerConfig) => (
        <DataValueDisplay
          key={ownerConfig.key}
          label={ownerConfig.pluralLabel}
          value={
            answer[ownerConfig.key]?.length > 0 ? (
              <>
                {answer[ownerConfig.key]?.map((ownerData) => (
                  <div key={ownerData.name}>{ownerData.name}</div>
                ))}
              </>
            ) : (
              <NotProvidedChip />
            )
          }
          variant={DataValueDisplayVariants.HORIZONTAL}
        />
      ))}
    </div>
  );
}
