export const EXPOSED_OFFERING_DOC_TYPES = [
  { key: 'Side letter', display: 'Side letter' },
  { key: 'PPM', display: 'Private placement memorandum' },
  { key: 'Summary of terms', display: 'Summary of terms' },
  { key: 'Governing document', display: 'Governing document' },
  { key: 'Blank form', display: 'Blank form' },
  { key: 'Other offering document', display: 'Other offering document' },
];

export const EXPOSED_SIDE_LETTER_TYPES = [
  { key: 'KYC/AML document', display: 'KYC/AML document' },
  { key: 'W-8/W-9', display: 'W-8/W-9' },
  ...EXPOSED_OFFERING_DOC_TYPES,
  { key: 'Other', display: 'Other' },
];

export const SIDE_LETTER = 'side_letter';
export const OFFERING_DOCUMENT = 'offering_document';
