import React, { useState, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@passthrough/uikit';

import { objectEquals } from 'services/utils';
import { useMe } from 'services/providers/me';
import * as api from 'services/api';
import { DiligenceReviewTopBar } from './diligence_review_top_bar';
import { DiligenceReviewArea } from './diligence_review_area';
import { reducerActionTypes, DATA_KEYS_USED_IN_SEARCHES } from './constants';
import { nodeDataReducer, getNodeDataDefaultState } from './node_reducer_utils';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.white,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100vh',
  },
  noGrow: {
    flex: '0 1 auto',
  },
}));

function isSearchDataDifferent(
  searchedData,
  currAnswerData,
  docCollectionMode,
) {
  if (docCollectionMode) {
    return false;
  }
  if (!searchedData || !currAnswerData) {
    return false;
  }

  const allDataIsIdentical = DATA_KEYS_USED_IN_SEARCHES.every((key) => {
    const searchedDataUsesKey = Object.hasOwn(searchedData, key);
    const currAnswerUsesKey = Object.hasOwn(currAnswerData, key);

    if (searchedDataUsesKey) {
      return currAnswerUsesKey && searchedData[key] === currAnswerData[key];
    }

    return !searchedDataUsesKey && !currAnswerUsesKey;
  });

  return !allDataIsIdentical;
}

function simplifyRawAnswer(rawAnswerData) {
  // although both the diligence node and lp diligence will store the same
  // file ID, the LpDiligence will add additional file metadata as it
  // serializes its answer for the frontend, but the diligence node will
  // not as it does not expose a mechanism for serializing arbitrary file
  // data in its answer
  return Object.keys(rawAnswerData).reduce((acc, key) => {
    const val = rawAnswerData[key];

    if (val instanceof Object && 'fileId' in val) {
      return { ...acc, [key]: val.fileId };
    }

    return { ...acc, [key]: val };
  }, {});
}

export function DiligenceReviewModal({
  fundName,
  docCollectionMode,
  managedDiligenceEnabled,
  jurisdiction,
  selectedQuestionId,
  allQuestions,
  rawAnswerData,
  answerData,
  numCommentEvents,
  draftComment,
  reusedNodeIds,
  numUnresolvedThreads,
  hasUnresolvedInternalNote,
  hasSearch,
  initialNodeApprovedState,
  isDiligenceApprover,
  diligenceApproverName,
  currOwnerIndex,
  numApprovedOwners,
  numOwners,
  closingStatus,
  isInProgress,
  onLeftNavClick,
  onRightNavClick,
  closeModal,
  onGpAnswerSubmission,
  refreshDiligenceData,
  lastSubmittedAnswerData,
  updated,
}) {
  const classes = useStyles();
  const [me] = useMe();
  const { fundId, lpClosingId } = useParams();

  const [localNumApproved, setLocalNumApproved] = useState(numApprovedOwners);
  const [hideInstructionsUserSetting, setHideInstructionsUserSetting] =
    useState(Boolean(me.hideDiligenceInstructions || docCollectionMode));
  const [shouldNotDisplayInstructions, setShouldNotDisplayInstructions] =
    useState(Boolean(me.hideDiligenceInstructions || docCollectionMode));

  const [isLoading, setIsLoading] = useState(false);
  const [nodeData, dispatch] = useReducer(
    nodeDataReducer,
    getNodeDataDefaultState(
      selectedQuestionId,
      initialNodeApprovedState,
      draftComment,
      numUnresolvedThreads,
      hasUnresolvedInternalNote,
    ),
  );

  const simplifiedRawAnswerData = simplifyRawAnswer(rawAnswerData);
  const hasInProgressAnswerChange =
    isInProgress &&
    !isLoading &&
    nodeData.priorAnswer &&
    Object.keys(nodeData.priorAnswer).length > 0 &&
    !objectEquals(nodeData.priorAnswer, simplifiedRawAnswerData);

  // This state should only be reachable if the data on the underlying node
  // or lpdiligence was altered outside of an in-app workflow
  const dataIsOutOfSync =
    !isInProgress &&
    !isLoading &&
    hasSearch &&
    nodeData.questionId === selectedQuestionId &&
    isSearchDataDifferent(
      nodeData.searchedData,
      simplifiedRawAnswerData,
      docCollectionMode,
    );

  function handleClose() {
    closeModal();

    if (nodeData.isChanged) {
      refreshDiligenceData();
    }
  }

  useEffect(() => {
    if (!selectedQuestionId) {
      return;
    }

    api
      .getDiligenceNodeMatchData({
        fundId,
        lpClosingId,
        diligenceQuestionId: selectedQuestionId,
      })
      .then((response) => {
        dispatch({
          type: reducerActionTypes.INITIALIZE_DATA,
          matchData: response.data.potentialMatches,
          supplementaryFiles: response.data.supplementaryFiles,
          priorAnswer: response.data.priorAnswerData,
          questionId: selectedQuestionId,
          nodeApproval: initialNodeApprovedState,
          adverseMediaEnabled: response.data.adverseMediaEnabled,
          draftComment,
          hasUnresolvedInternalNote,
        });
      })
      .catch((e) => {
        if (e.response?.status === 404) {
          dispatch({
            type: reducerActionTypes.INITIALIZE_DATA,
            matchData: null,
            searchedData: null,
            questionId: selectedQuestionId,
            nodeApproval: initialNodeApprovedState,
            draftComment,
            hasUnresolvedInternalNote,
            adverseMediaEnabled: false,
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedQuestionId]);

  return (
    <div className={classes.root}>
      <DiligenceReviewTopBar
        shouldNotDisplayInstructions={shouldNotDisplayInstructions}
        setShouldNotDisplayInstructions={setShouldNotDisplayInstructions}
        docCollectionMode={docCollectionMode}
        numApprovedOwners={localNumApproved}
        numOwners={numOwners}
        handleClose={handleClose}
        className={classes.noGrow}
      />

      {isInProgress ? (
        <Alert
          severity="warning"
          variant="sticky"
          title="This data is not final"
        >
          This investor has not yet completed their questionnaire and the owners
          may still change.
        </Alert>
      ) : null}

      {!isDiligenceApprover ? (
        <Alert severity="info" variant="sticky">
          {diligenceApproverName ? (
            <>
              <strong>{diligenceApproverName}</strong> is the assigned diligence
              reviewer. You do not have permission to review owners.
            </>
          ) : (
            `You do not have permission to review owners. Assign a diligence reviewer to review owners.`
          )}
        </Alert>
      ) : null}

      {dataIsOutOfSync ? (
        <Alert
          title="An unexpected error has occurred"
          variant="sticky"
          severity="error"
        >
          The potential matches listed may not match the provided owner data.
          Please reach out to support to resolve this issue.
        </Alert>
      ) : null}

      <DiligenceReviewArea
        fundName={fundName}
        allQuestions={allQuestions}
        nodeData={nodeData}
        dataIsOutOfSync={dataIsOutOfSync}
        hasInProgressAnswerChange={hasInProgressAnswerChange}
        dispatch={dispatch}
        isLoading={isLoading}
        managedDiligenceEnabled={managedDiligenceEnabled}
        docCollectionMode={docCollectionMode}
        jurisdiction={jurisdiction}
        numCommentEvents={numCommentEvents}
        currOwnerIndex={currOwnerIndex}
        numOwners={numOwners}
        hasSearch={hasSearch}
        answerData={answerData}
        reusedNodeIds={reusedNodeIds}
        isDiligenceApprover={isDiligenceApprover}
        closingStatus={closingStatus}
        shouldNotDisplayInstructions={shouldNotDisplayInstructions}
        setShouldNotDisplayInstructions={setShouldNotDisplayInstructions}
        hideInstructionsUserSetting={hideInstructionsUserSetting}
        setHideInstructionsUserSetting={setHideInstructionsUserSetting}
        onLeftNavClick={onLeftNavClick}
        onRightNavClick={onRightNavClick}
        setLocalNumApproved={setLocalNumApproved}
        onGpAnswerSubmission={onGpAnswerSubmission}
        selectedQuestionId={selectedQuestionId}
        lastSubmittedAnswer={lastSubmittedAnswerData}
        updated={updated}
        refreshDiligenceData={refreshDiligenceData}
      />
    </div>
  );
}
