import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PersonOutlined from '@material-ui/icons/PersonOutlineOutlined';
import EmailIcon from '@material-ui/icons/EmailOutlined';

import { KeyValuePair, KeyValuePairs } from 'components/key_value';
import { Button } from 'components/button';
import { useMe } from 'services/providers/me';
import * as api from 'services/api';
import { useToast } from 'services/toast';
import { PageContainer } from 'components/page_container';
import { AddPassword } from './add_password';
import { ChangePassword } from './change_password';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4, 0),
  },
  paper: {
    padding: theme.spacing(4),
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    flexDirection: 'column',
  },
  title: {
    fontSize: '1.5em',
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  keyIcon: {
    marginTop: '10px',
  },
}));

export function UserSettingsPage() {
  const classes = useStyles();
  const [me, onChangeMe] = useMe();
  const [saving, setSaving] = useState(false);
  const [firstName, setFirstName] = useState(me.firstName || '');
  const [lastName, setLastName] = useState(me.lastName || '');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [enablePassword, setEnablePassword] = useState(me.hasPassword);
  const [oldPassword, setOldPassword] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordScore, setPasswordScore] = useState(null);
  const [passwordWarning, setPasswordWarning] = useState(null);
  const [passwordSuggestions, setPasswordSuggestions] = useState([]);
  const [retypePassword, setRetypePassword] = useState('');
  const [retypePasswordError, setRetypePasswordError] = useState('');
  const { successToast } = useToast();

  const passwordChanged =
    (enablePassword === true && me.hasPassword === false) || oldPassword;
  const disabled =
    firstName === me.firstName && lastName === me.lastName && !passwordChanged;

  function onSubmit(e) {
    e.preventDefault();

    setSaving(true);

    const props = { firstName, lastName };

    if (enablePassword) {
      props.password = password;
      props.retypePassword = retypePassword;
    }

    if (me.hasPassword) {
      props.oldPassword = oldPassword;
    }

    api
      .saveUserSettings(props)
      .then(() => {
        setSaving(false);
        successToast('Settings updated.');
        setFirstNameError('');
        setLastNameError('');
        setPasswordError('');
        setOldPasswordError('');
        setRetypePasswordError('');
        onChangeMe();
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setSaving(false);
          setFirstNameError(error.response.data.firstName);
          setLastNameError(error.response.data.lastName);
          setPasswordError(error.response.data.password);
          setOldPasswordError(error.response.data.oldPassword);
          setRetypePasswordError(error.response.data.retypePassword);
        }
      });
  }

  function checkPassword() {
    if (!password) {
      setPasswordScore(null);
      setPasswordError('');
      return;
    }

    api.checkPassword({ firstName, lastName, password }).then((response) => {
      const { score, feedback } = response.data;
      setPasswordScore(score);
      setPasswordWarning(feedback.warning);
      setPasswordSuggestions(feedback.suggestions);
    });
  }

  useEffect(checkPassword, [password]);
  useEffect(() => {
    setFirstName(me.firstName);
    setLastName(me.lastName);
  }, [me]);

  return (
    <PageContainer maxWidth="sm">
      <Paper className={classes.paper}>
        <form onSubmit={onSubmit} autoComplete="off">
          <div className={classes.top}>
            <PersonOutlined fontSize="large" />
            <Typography variant="h2" className={classes.title}>
              Account settings
            </Typography>
          </div>
          <KeyValuePairs>
            <KeyValuePair icon={<EmailIcon />}>
              <Typography>{me.email}</Typography>
            </KeyValuePair>
            <KeyValuePair icon={<PersonOutlined />}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="firstName"
                    type="text"
                    fullWidth
                    label="First name"
                    autoComplete="fname"
                    variant="outlined"
                    value={firstName}
                    error={!!firstNameError}
                    helperText={firstNameError}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="lastName"
                    type="text"
                    fullWidth
                    label="Last name"
                    autoComplete="lname"
                    variant="outlined"
                    value={lastName}
                    error={!!lastNameError}
                    helperText={lastNameError}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </KeyValuePair>
            {me.hasPassword ? (
              <ChangePassword
                oldPassword={oldPassword}
                setOldPassword={setOldPassword}
                oldPasswordError={oldPasswordError}
                password={password}
                passwordError={passwordError}
                passwordScore={passwordScore}
                setPassword={setPassword}
                passwordWarning={passwordWarning}
                passwordSuggestions={passwordSuggestions}
                retypePassword={retypePassword}
                retypePasswordError={retypePasswordError}
                setRetypePassword={setRetypePassword}
              />
            ) : (
              <AddPassword
                enablePassword={enablePassword}
                setEnablePassword={setEnablePassword}
                password={password}
                passwordError={passwordError}
                passwordScore={passwordScore}
                setPassword={setPassword}
                passwordWarning={passwordWarning}
                passwordSuggestions={passwordSuggestions}
                retypePassword={retypePassword}
                retypePasswordError={retypePasswordError}
                setRetypePassword={setRetypePassword}
                socialProvider={me.socialProvider}
              />
            )}
          </KeyValuePairs>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            className={classes.submit}
            disabled={disabled}
            loading={saving}
          >
            Save
          </Button>
        </form>
      </Paper>
    </PageContainer>
  );
}
