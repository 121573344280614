/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { IMaskInput } from 'react-imask';

// a valid GIIN with never use the letter O
const GIIN_DEFINITIONS = {
  '-': /(?!o)[a-z0-9]/gim,
  A: /(?!o)[a-z]/gim,
};

export function GIINInput({ name, inputRef, onChange, ...other }) {
  return (
    <IMaskInput
      {...other}
      mask={'------\\.-----\\.AA\\.000'}
      placeholderChar="_"
      lazy={false}
      inputRef={inputRef}
      definitions={GIIN_DEFINITIONS}
      onAccept={(value) => {
        onChange({
          target: {
            name,
            value,
          },
        });
      }}
    />
  );
}
