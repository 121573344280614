import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useToast } from 'services/toast';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';

import * as api from 'services/api';
import { useFundReview } from 'services/providers/fund';
import { Spinner } from 'components/spinner';
import { DiligenceActivityTimeline } from 'pages/review_v2/activity_timeline_v2/diligence_activity_timeline';
import { InternalNoteForm } from 'pages/review_v2/internal_note_form';
import {
  isThreadResolved,
  countNumCommentEvents,
  countNumInternalNoteEvents,
} from 'services/thread_utils';
import { reducerActionTypes } from '../constants';
import { NodeTab } from './node_tab';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    maxWidth: '1440px',
    minWidth: '1080px',
  },
  displayRoot: {
    padding: theme.spacing(3, 4),
    display: 'flex',
    flex: 3,
  },
  nodeTabs: {
    marginTop: theme.spacing(2),
  },
  nodeTabsContainer: {
    borderRight: `1px solid ${theme.palette.divider}`,
    flex: 1,
  },
  flexContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(3),
  },
}));

function getEventsByQId({ events, reusedNodeEvents }) {
  const eventsByQid = {};

  if (events.length > 0) {
    const originalQuestionId = events[0].questionId;
    eventsByQid[originalQuestionId] = events;
  }

  for (let i = 0; i < reusedNodeEvents.length; i += 1) {
    const currEvent = reusedNodeEvents[i];
    const { questionId } = currEvent;

    if (!eventsByQid[questionId]) {
      eventsByQid[questionId] = [currEvent];
    } else {
      eventsByQid[questionId].push(currEvent);
    }
  }

  return eventsByQid;
}

export function CommentsAndActivityDisplay({
  fundName,
  jurisdiction,
  nodeName,
  structuredNodesRoot,
  refreshActivityEvents,
  originalQuestionId,
  reusedNodeIds,
  dispatch,
  className,
  draftComment,
}) {
  const { toast } = useToast();
  const classes = useStyles();
  const [fundReview] = useFundReview();
  const { fundId, lpClosingId } = useParams();
  const [internalNote, setInternalNote] = useState('');
  const [areEventsLoading, setAreEventsLoading] = useState(false);
  const [isNoteSaveLoading, setIsNoteSaveLoading] = useState(false);
  const [noteSaveErrorText, setNoteSaveErrorText] = useState('');
  const [selectedQuestionId, setSelectedQuestionId] =
    useState(originalQuestionId);
  const [activityEvents, setActivityEvents] = useState([]);

  const canAddInternalNote = fundReview;
  const reusedNodesToShowAsTabs =
    reusedNodeIds?.filter(
      (reusedId) => activityEvents?.[reusedId]?.length > 0,
    ) || [];
  const threadHasInternalNoteEvent =
    countNumInternalNoteEvents(activityEvents?.[selectedQuestionId]) > 0;

  function getRelationshipText(nodeId) {
    const node = structuredNodesRoot.findNodeByLabel(nodeId);
    if (!node) {
      return '';
    }

    return node.formatChildParentRelationship();
  }

  function handleSave() {
    setIsNoteSaveLoading(true);
    setNoteSaveErrorText('');

    api
      .saveDiligenceInternalNote({
        fundId,
        lpClosingId,
        questionId: selectedQuestionId,
        note: internalNote,
      })
      .then(() => {
        dispatch({
          type: reducerActionTypes.ADD_INTERNAL_NOTE,
        });

        setInternalNote('');
        toast('Internal note saved.');
        setIsNoteSaveLoading(false);
      })
      .catch(() => {
        setIsNoteSaveLoading(false);
        setNoteSaveErrorText('There was an error saving your internal note.');
      });
  }

  useEffect(() => {
    setSelectedQuestionId(originalQuestionId);
  }, [originalQuestionId]);

  useEffect(() => {
    setAreEventsLoading(true);
    dispatch({
      type: reducerActionTypes.CLEAR_NUM_UNRESOLVED_THREADS,
    });

    api
      .getDiligenceNodeEvents({
        fundId,
        lpClosingId,
        diligenceQuestionId: originalQuestionId,
      })
      .then((resp) => {
        const structuredEvents = getEventsByQId(resp.data);
        setActivityEvents(structuredEvents);

        const threads = Object.values(structuredEvents);
        const numUnresolvedCommentThreads = threads.filter(
          (thread) => !isThreadResolved(thread),
        ).length;

        dispatch({
          type: reducerActionTypes.UPDATE_NUM_UNRESOLVED_THREADS,
          numUnresolvedCommentThreads,
        });
      })
      .catch((e) => {
        if (e.response?.status === 404) {
          setActivityEvents([]);
        }
      })
      .finally(() => {
        setAreEventsLoading(false);
      });
  }, [refreshActivityEvents]);

  if (areEventsLoading) {
    return (
      <div data-testid="activity_display_spinner" className={className}>
        <Spinner size={150} />
      </div>
    );
  }

  return (
    <div className={clsx(className, classes.root)}>
      {reusedNodesToShowAsTabs.length > 0 ? (
        <div className={classes.nodeTabsContainer}>
          <Tabs
            value={selectedQuestionId}
            orientation="vertical"
            onChange={(e, v) => {
              setSelectedQuestionId(v);
            }}
            className={classes.nodeTabs}
          >
            {[originalQuestionId, ...reusedNodesToShowAsTabs].map((id) => (
              <NodeTab
                key={id}
                value={id}
                name={nodeName}
                numComments={countNumCommentEvents(activityEvents[id])}
                relationship={getRelationshipText(id)}
              />
            ))}
          </Tabs>
        </div>
      ) : null}

      <div data-testid="activity_display" className={classes.displayRoot}>
        <div className={classes.flexContainer}>
          <DiligenceActivityTimeline
            canInteractWithThread
            fundName={fundName}
            jurisdiction={jurisdiction}
            questionId={selectedQuestionId}
            events={activityEvents[selectedQuestionId]}
            draftComment={draftComment}
            onResolveThread={() => {
              dispatch({ type: reducerActionTypes.RESOLVE_THREAD });
            }}
            onReopenThread={() => {
              dispatch({
                type: reducerActionTypes.REOPEN_THREAD,
                threadHasInternalNoteEvent,
              });
            }}
          />

          {canAddInternalNote ? (
            <InternalNoteForm
              internalNote={internalNote}
              setInternalNote={setInternalNote}
              isLoading={isNoteSaveLoading}
              errorText={noteSaveErrorText}
              onSave={handleSave}
              showCancel={false}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
