import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { PDFReviewStepper } from 'components/pdf_review_stepper';
import { Zoom } from 'components/zoom';
import { useDocumentsDrawerState, useDocumentsDrawerActions } from './utils';

const useStyles = makeStyles((theme) => ({
  pageControls: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(2, 3),
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
}));

export function DrawerControls() {
  const classes = useStyles();
  const state = useDocumentsDrawerState();
  const actions = useDocumentsDrawerActions();

  return (
    <div className={classes.pageControls}>
      <Zoom
        zoom={state.zoom}
        setZoom={(newZoom) =>
          actions.setZoom({
            zoom: typeof newZoom === 'number' ? newZoom : newZoom?.(state.zoom),
          })
        }
        loading={state.loading}
      />
      {state.numPages && state.numPages > 1 ? (
        <PDFReviewStepper
          page={state.page}
          setPage={(page) => actions.setPage({ page })}
          numPages={state.numPages}
          useLeftAlign
          hideButtonLabels
          loading={state.loading}
        />
      ) : null}
    </div>
  );
}
