import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { EmptyState as BaseEmptyState } from 'components/empty_v2';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as api from 'services/api';

const useStyles = makeStyles(() => ({
  select: {
    width: '300px',
  },
}));

export const EmptyState = ({
  setToQuestionnaire,
  organizationId,
  questionnaireId,
}) => {
  const classes = useStyles();
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    api.listQuestionnaires({ organization_id: organizationId }).then((res) => {
      setOptions(res.data.filter((q) => q.id !== questionnaireId));
    });
  }, [organizationId]);

  return (
    <BaseEmptyState title="Select a questionnaire to start">
      <Autocomplete
        id="preexisting-questionnaire-selection"
        options={options}
        onChange={(event, newVal) => {
          setToQuestionnaire(newVal);
        }}
        getOptionLabel={(option) => option.name}
        renderOption={(option) => <>{option.name}</>}
        className={classes.select}
        renderInput={(params) => (
          <TextField
            variant="outlined"
            {...params}
            label="Questionnaire to compare"
            size="small"
          />
        )}
      />
    </BaseEmptyState>
  );
};
