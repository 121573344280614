import React from 'react';
import { FieldContainer, Field } from './fields';

export const CrsTaxDetailsV2Display = ({ answer }) => (
  <FieldContainer>
    <Field label="Country">{answer?.country || '-'}</Field>
    <Field label="Tax type">{answer?.taxType || '-'}</Field>
    <Field label="Tax number">{answer?.taxNumber || '-'}</Field>
    <Field label="Reason not applicable">
      {answer?.reasonNotApplicable || '-'}
    </Field>
    <Field label="notApplicable">{answer?.notApplicable || '-'}</Field>
  </FieldContainer>
);
