import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { Typography, Modal, Button, Icons, Alert } from '@passthrough/uikit';
import * as api from 'services/api';
import { useToast } from 'services/toast';
import { getSingleErrorFromResponse } from 'services/utils';
import { useFundEdit } from 'services/providers/fund';
import { useDiscardConfirmation } from 'services/discard';

import { NoteDisplay } from './note_display';

const useStyles = makeStyles((theme) => ({
  noteExplanation: {
    display: 'flex',
    gap: theme.spacing(1),
    flexDirection: 'row',
    color: theme.palette.text.secondary,
  },
  input: {
    backgroundColor: theme.palette.background.internalNote,
  },
  list: {
    marginTop: theme.spacing(1),
  },
  listItem: {
    display: 'list-item',
    listStyleType: 'disc',
    marginLeft: '16px',
  },
}));

const VIEW_MODE = 'VIEW_MODE';
const EDIT_MODE = 'EDIT_MODE';

function getInitialNote(investors) {
  const notes = investors
    ?.map((investor) => investor.mostRecentNote)
    ?.filter(Boolean);
  if (!notes || notes.length === 0) {
    return '';
  }

  if (notes.length === 1) {
    return notes[0];
  }

  const uniqueNotes = new Set([...notes]);
  if (uniqueNotes.size === 1) {
    return uniqueNotes.values().next().value;
  }
  return '';
}

function NoteForm({ note, setNote }) {
  const classes = useStyles();
  return (
    <>
      <TextField
        InputProps={{
          className: classes.input,
        }}
        autoFocus
        variant="outlined"
        value={note}
        onChange={(e) => setNote(e.target.value)}
        fullWidth
        multiline
        minRows={4}
      />
      <div className={classes.noteExplanation}>
        <Icons.LockOutlined fontSize="small" />
        <Typography variant="body" size="small">
          Not visible to the investor
        </Typography>
      </div>
    </>
  );
}

function ExistingNoteWarning({ investors }) {
  const classes = useStyles();
  const investorsWithNotes =
    investors?.filter((investor) => investor.mostRecentNote) || [];

  // Show the warning if:
  // - more than one investor is selected
  // - at least one investor has a note
  if ((investors && investors.length <= 1) || investorsWithNotes.length === 0) {
    return null;
  }

  return (
    <Alert severity="warning" skipTypography>
      <Typography variant="body" size="small">
        Saving this note will replace already saved notes for the following
        investors:
      </Typography>
      <List disablePadding className={classes.list}>
        {investorsWithNotes.map((investor) => (
          <ListItem key={investor.lpName} dense className={classes.listItem}>
            <Typography variant="body" size="small">
              {investor.lpName}
            </Typography>
          </ListItem>
        ))}
      </List>
    </Alert>
  );
}

export function EditNoteModal({
  open,
  handleClose,
  investors,
  onChange,
  onExited = () => {},
  showNumInvestors = false,
  startInViewMode = false,
}) {
  const [mode, setMode] = useState(VIEW_MODE);
  const [initialNote, setInitialNote] = useState('');
  const [note, setNote] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [fundEdit] = useFundEdit();
  const { fundId, closingId } = useParams();
  const { toast } = useToast();
  const closeWithConfirmation = useDiscardConfirmation({
    onConfirm: handleClose,
    needConfirmation: open && note !== initialNote,
  });

  const resetToEdit = () => {
    const initial = getInitialNote(investors);
    setMode(EDIT_MODE);
    setNote(initial);
    setInitialNote(initial);
    setCreatedAt('');
  };

  const resetToView = (investor) => {
    setMode(VIEW_MODE);
    setInitialNote(investor.mostRecentNote);
    setNote(investor.mostRecentNote);
    setCreatedAt(investor.mostRecentNoteCreatedAt);
  };

  const resetState = () => {
    setSubmitting(false);

    if (startInViewMode) {
      // Only reached when clicking on the note icon in the list, so there's
      // exactly one investor
      resetToView(investors[0]);
      return;
    }

    resetToEdit();
  };

  const headerLabel =
    investors?.length === 1
      ? `Note for ${investors[0].lpName}`
      : 'Add or edit note';
  const subheaderLabel = showNumInvestors
    ? `${investors?.length} investor${investors?.length === 1 ? '' : 's'}`
    : undefined;

  // Hide primary button in view mode
  const primaryButtonProps =
    mode === VIEW_MODE ? { style: { display: 'none' } } : undefined;

  const tertiaryButton =
    mode === VIEW_MODE && fundEdit ? (
      <Button
        variant="text"
        onClick={() => setMode(EDIT_MODE)}
        startIcon={<Icons.EditOutlined />}
      >
        Edit
      </Button>
    ) : undefined;

  const submitNote = () => {
    const lpClosingIds = investors.map((investor) => investor.id);

    setSubmitting(true);
    api
      .saveOverallInvestorNote({ fundId, closingId, lpClosingIds, note })
      .then(() => {
        onChange();
        toast('Note saved');
        handleClose();
      })
      .catch((error) => {
        setErrorMsg(getSingleErrorFromResponse(error));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Modal
      open={open}
      onClose={closeWithConfirmation}
      onEntering={resetState}
      headerLabel={headerLabel}
      subheaderLabel={subheaderLabel}
      showCancelButton
      tertiaryButton={tertiaryButton}
      primaryButtonText="Save"
      primaryButtonProps={primaryButtonProps}
      primaryButtonDisabled={note === initialNote}
      primaryButtonLoading={submitting}
      onSubmit={submitNote}
      onExited={onExited}
    >
      {errorMsg ? <Alert severity="error">{errorMsg}</Alert> : null}
      <ExistingNoteWarning investors={investors} />
      {mode === VIEW_MODE ? (
        <NoteDisplay
          note={note}
          createdAt={createdAt}
          fullWidth
          truncate={false}
        />
      ) : (
        <NoteForm note={note} setNote={setNote} />
      )}
    </Modal>
  );
}
