import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { RadioCardChoice, RadioCardContainer } from 'components/radio_card';
import { TextChip, TextChipVariants } from 'components/text_chip';
import { ProfileCard } from './profile_card';
import {
  SELECT_PROFILE_STEP,
  ROLE_STEP,
  ACCESS_STEP,
  IS_INVESTOR_CHOICE,
  NOT_INVESTOR_CHOICE,
} from './constants';

const useStyles = makeStyles((theme) => ({
  startFromScratchTitle: {
    fontWeight: 400,
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(24),
  },
  displayName: {
    fontWeight: 500,
  },
  radioContainer: {
    margin: theme.spacing(2),
  },
  tooltipRadioCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  infoIcon: {
    margin: theme.spacing(1),
  },
  radioCardChoice: {
    minWidth: '300px',
  },
  newProfileChip: {
    margin: theme.spacing(1),
  },
}));

export function RoleStep({
  lpName,
  setNavStep,
  profileData,
  setIsInvestorChoice,
  setNonInvestorWarningDialogOpen,
}) {
  const classes = useStyles();
  const [choice, setChoice] = useState(null);

  const onBackClick = () => {
    setNavStep(SELECT_PROFILE_STEP);
  };

  const onContinueClick = () => {
    setIsInvestorChoice(choice);
    if (choice === IS_INVESTOR_CHOICE) {
      setNavStep(ACCESS_STEP);
    } else {
      setNonInvestorWarningDialogOpen(true);
    }
  };

  return (
    <ProfileCard
      maxWidth="md"
      lpName={lpName}
      step={ROLE_STEP}
      setStep={setNavStep}
      onBackClick={onBackClick}
      onContinueDisabled={!choice}
      onContinueClick={onContinueClick}
    >
      {profileData.isNew ? (
        <TextChip
          label="New profile"
          variant={TextChipVariants.APPROVED}
          className={classes.newProfileChip}
        />
      ) : null}

      <Typography
        className={classes.startFromScratchTitle}
        align="center"
        gutterBottom
      >
        Are you{' '}
        <span className={classes.displayName}>{profileData.displayName}</span>,
        or their authorized representative?
      </Typography>

      <div key="isInvestorContainer" className={classes.radioContainer}>
        <RadioCardContainer
          selectedChoice={choice}
          setSelectedChoice={setChoice}
        >
          <div className={classes.tooltipRadioCard}>
            <RadioCardChoice
              choiceBackground="white"
              choiceKey={IS_INVESTOR_CHOICE}
              choiceValue={IS_INVESTOR_CHOICE}
              choiceTitle="Yes"
              className={classes.radioCardChoice}
              dataTest="is_investor_choice"
            />
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              title={
                <Typography>
                  You will have full access to manage this profile.
                </Typography>
              }
            >
              <InfoOutlinedIcon fontSize="small" className={classes.infoIcon} />
            </Tooltip>
          </div>
          <div className={classes.tooltipRadioCard}>
            <RadioCardChoice
              choiceBackground="white"
              choiceKey={NOT_INVESTOR_CHOICE}
              choiceValue={NOT_INVESTOR_CHOICE}
              choiceTitle="No, I am neither"
              className={classes.radioCardChoice}
            />
            <InfoOutlinedIcon
              fontSize="small"
              style={{ visibility: 'hidden' }} // Hidden for spacing
              className={classes.infoIcon}
            />
          </div>
        </RadioCardContainer>
      </div>
    </ProfileCard>
  );
}
