import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Alert, Modal } from '@passthrough/uikit';
import * as React from 'react';
import * as api from 'services/api';
import { useFund } from 'services/providers/fund';

export function ManagedDiligenceModal({
  fundId,
  open,
  onClose,
  setManagedDiligenceEnabled,
  investorCount,
}) {
  const [, , refreshFund] = useFund();
  const [diligenceReviewerEmail, setDiligenceReviewerEmail] =
    React.useState(null);
  const [possibleDiligenceReviewers, setPossibleDiligenceReviewers] =
    React.useState([]);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    api.listDiligenceReviewers().then((response) => {
      setPossibleDiligenceReviewers(response.data);
    });
  }, []);

  function getDiligenceReviewerLabel(option) {
    let reviewer = option;

    if (typeof option === 'string') {
      reviewer = possibleDiligenceReviewers.find((c) => c.email === option);
    }

    return `${reviewer?.name} - ${reviewer?.email}`;
  }
  return (
    <Modal
      open={open}
      onClose={onClose}
      headerLabel="Enable managed diligence"
      primaryButtonText="Enable managed diligence"
      onSubmit={(event) => {
        event.preventDefault();
        api
          .enableManagedDiligence({ fundId, diligenceReviewerEmail })
          .then(() => {
            setManagedDiligenceEnabled(true);
            refreshFund();
            onClose();
          })
          .catch((exc) => {
            setError(exc.response.data);
          });
      }}
    >
      {error?.nonFieldErrors ? (
        <Alert severity="error">{error.nonFieldErrors}</Alert>
      ) : null}
      {investorCount > 0 ? (
        <Alert severity="warning">
          Custom approval groups and statuses will be applied to existing
          investors.
        </Alert>
      ) : null}
      <Autocomplete
        options={possibleDiligenceReviewers}
        value={diligenceReviewerEmail}
        onChange={(e, v) => {
          setDiligenceReviewerEmail(v?.email || null);
        }}
        getOptionLabel={getDiligenceReviewerLabel}
        getOptionSelected={(option, value) => option.email === value}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Diligence reviewer"
            variant="outlined"
            fullWidth
            required
            error={Boolean(error?.diligenceReviewerEmail)}
            helperText={error?.diligenceReviewerEmail}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'chrome-off',
            }}
          />
        )}
      />
    </Modal>
  );
}
