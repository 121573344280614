import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { QuestionTags } from './tag';

const useStyles = makeStyles((theme) => ({
  item: {
    marginBottom: theme.spacing(2),
  },
  tags: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
}));

export const SimplePanelContent = ({
  questionText,
  questionTags,
  left = false,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.item}>
        <strong>{questionText}</strong>
      </Typography>

      {left ? (
        <div key="tags" className={classes.tags}>
          <QuestionTags questionTags={questionTags} />
        </div>
      ) : null}
    </div>
  );
};
