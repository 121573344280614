import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, Typography, Button, useConfirm } from '@passthrough/uikit';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import LabelIcon from '@material-ui/icons/LabelOutlined';

import {
  INVESTOR_TAGGING_REDUCER_ACTIONS,
  TAGGING_MODAL_STAGES,
} from './constants';
import { useInvestorTaggingMutations } from './providers/mutations_provider';
import { useInvestorTaggingModalState } from './providers/state_provider';
import { isLastTagInGroup } from './utils';

const useStyles = makeStyles((theme) => ({
  tagGroupNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'start',
  },
  tagValueRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tagValue: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.spacing(1),
    alignItems: 'center',
  },
  tagValueActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    columnGap: theme.spacing(0),
  },
  deleteConfirmDescriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
}));

export function ManageTagsContents({ investorTagGroups }) {
  const classes = useStyles();
  const { dispatch } = useInvestorTaggingModalState();
  const { handleDeleteTag } = useInvestorTaggingMutations();
  const confirm = useConfirm();

  function confirmDeleteTag(tag) {
    const isLastTag = isLastTagInGroup(investorTagGroups, tag);

    confirm({
      size: 'xs',
      title: 'Delete tag',
      description: (
        <div className={classes.deleteConfirmDescriptionContainer}>
          {isLastTag ? (
            <Alert severity="warning">
              The tag group "{tag?.groupName}" will also be deleted.
            </Alert>
          ) : null}

          {tag.numUsages > 0 ? (
            <Alert severity="warning">
              {tag.numUsages} investor
              {tag.numUsages > 1 ? 's' : ''} {tag.numUsages > 1 ? 'are' : 'is'}{' '}
              using this tag.
            </Alert>
          ) : null}

          <Typography>This action cannot be undone.</Typography>
        </div>
      ),
      destructive: true,
      confirmationText: 'Delete',
    }).then(() => {
      handleDeleteTag(tag);
    });
  }

  return (
    <>
      <Typography>
        Tags are shared across the fund. Anyone with access to the fund can
        manage these tags.
      </Typography>

      <Button
        variant="secondary"
        startIcon={<AddIcon />}
        onClick={() => {
          dispatch({
            type: INVESTOR_TAGGING_REDUCER_ACTIONS.CREATE_TAG,
            currModalStage: TAGGING_MODAL_STAGES.MANAGE_TAGS,
          });
        }}
      >
        Create tag
      </Button>

      {investorTagGroups.map((tagGroup) => (
        <div key={tagGroup.id}>
          <div className={classes.tagGroupNameContainer}>
            <Typography variant="label" color="text.secondary">
              {tagGroup.name}
            </Typography>

            <Button
              variant="icon"
              onClick={() => {
                dispatch({
                  type: INVESTOR_TAGGING_REDUCER_ACTIONS.EDIT_TAG_GROUP,
                  tagGroup,
                });
              }}
              aria-label="Edit tag group"
            >
              <EditIcon />
            </Button>
          </div>

          {tagGroup.tags.map((tag) => (
            <div key={tag.id} className={classes.tagValueRow}>
              <div className={classes.tagValue}>
                <LabelIcon />

                <Typography>{tag.name}</Typography>
              </div>

              <div className={classes.tagValueActions}>
                <Button
                  variant="icon"
                  onClick={() => {
                    dispatch({
                      type: INVESTOR_TAGGING_REDUCER_ACTIONS.EDIT_TAG,
                      tag,
                    });
                  }}
                  aria-label="Edit tag"
                >
                  <EditIcon />
                </Button>

                <Button
                  variant="icon"
                  onClick={() => {
                    confirmDeleteTag(tag);
                  }}
                  aria-label="Delete tag"
                >
                  <DeleteIcon />
                </Button>
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  );
}
