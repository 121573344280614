import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import BlockIcon from '@material-ui/icons/Block';
import HelpIcon from '@material-ui/icons/Help';
import DoneIcon from '@material-ui/icons/Done';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Button } from 'components/button';
import { TrueLink } from 'components/link';
import { useToast } from 'services/toast';
import {
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
} from 'components/table/index';
import * as api from 'services/api';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  icon: {
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(5, 0),
  },
  btn: {
    margin: theme.spacing(3, 0),
  },
  helpIcon: {
    fontSize: theme.typography.pxToRem(12),
    marginRight: theme.spacing(0.5),
  },
  textCenter: {
    textAlign: 'center',
  },
}));

const CopyButton = ({ question }) => {
  const { toast } = useToast();
  const [menuEl, setMenuEl] = React.useState(null);
  const handleCopy = (gid) => {
    navigator.clipboard.writeText(gid);
    setMenuEl(null);
    toast(`Copied ${gid}`);
  };

  if (!question?.gids?.length) {
    return null;
  }

  const onClick =
    question.gids.length > 1
      ? (e) => setMenuEl(e.currentTarget)
      : () => handleCopy(question.gids[0][0]);

  return (
    <span>
      <Tooltip arrow title="Copy GID" disableFocusListener>
        <IconButton onClick={onClick}>
          <FileCopyIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={menuEl}
        open={Boolean(menuEl)}
        onClose={() => setMenuEl(null)}
        keepMounted
        style={{ marginTop: 40 }}
      >
        {question.gids.map((gid) => (
          <MenuItem onClick={() => handleCopy(gid[0])}>{gid[1]}</MenuItem>
        ))}
      </Menu>
    </span>
  );
};

export const StaffCompareSubdocPage = () => {
  const classes = useStyles();
  const [subdocBase, setSubdocBase] = React.useState(null);
  const [subdocTarget, setSubdocTarget] = React.useState(null);
  const [result, setResult] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [subdocs, setSubdocs] = React.useState([]);

  const compare = () => {
    setLoading(true);

    api
      .compareSubdocs({ baseId: subdocBase.id, targetId: subdocTarget.id })
      .then((res) => {
        setResult(res.data);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    api.getSubdocsToCompare().then((res) => {
      setSubdocs(res.data);
    });
  }, []);

  return (
    <Paper className={classes.paper} elevation={0} variant="outlined">
      <Autocomplete
        options={subdocs}
        autoHighlight
        selectOnFocus
        value={subdocBase}
        onChange={(e, value) => setSubdocBase(value)}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Base subscription document"
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'chrome-off',
            }}
          />
        )}
      />
      <div className={classes.icon}>
        <CompareArrowsIcon />
      </div>
      <Autocomplete
        options={subdocs}
        autoHighlight
        selectOnFocus
        value={subdocTarget}
        onChange={(e, value) => setSubdocTarget(value)}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Target subscription document"
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'chrome-off',
            }}
          />
        )}
      />

      <Button
        variant="contained"
        className={classes.btn}
        fullWidth
        onClick={compare}
        loading={loading}
      >
        Compare
      </Button>
      {result ? (
        <>
          <Divider className={classes.divider} />

          <Table>
            <TableRow>
              <TableCell>Base subdoc</TableCell>
              <TableCell>
                <TrueLink href={result.baseSubdoc.url}>
                  {result.baseSubdoc.name}
                </TrueLink>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Target subdoc</TableCell>
              <TableCell>
                <TrueLink href={result.targetSubdoc.url}>
                  {result.targetSubdoc.name}
                </TrueLink>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Percentage of tagged questions in the target subdoc
              </TableCell>
              <TableCell>{`${result.percentages.tagged}%`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Percentage of reused questions for investors coming from base
                subdoc to target
              </TableCell>
              <TableCell>{`${result.percentages.reused}%`}</TableCell>
            </TableRow>
          </Table>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>QID</TableCell>
                <TableCell>Question</TableCell>
                <TableCell className={classes.textCenter}>
                  <Tooltip
                    arrow
                    title="Indicates if the question is tagged and an investor could potentially have seen this question in another subdoc."
                    placement="top"
                  >
                    <span>
                      <HelpIcon className={classes.helpIcon} />
                      Tagged?
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.textCenter}>
                  <Tooltip
                    arrow
                    title="Indicates if the question was asked in the base subdoc either via tagging or because they have the same text."
                    placement="top"
                  >
                    <span>
                      <HelpIcon className={classes.helpIcon} />
                      Reused?
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {result.questions.map((question) => (
                <TableRow key={question.label}>
                  <TableCell>
                    <CopyButton question={question} />
                  </TableCell>
                  <TableCell>
                    <span>{question.label}</span>
                  </TableCell>
                  <TableCell>{question.question}</TableCell>
                  <TableCell className={classes.textCenter}>
                    {question.tagged ? (
                      <Tooltip title={question.tags.join(',')}>
                        <DoneIcon color="primary" />
                      </Tooltip>
                    ) : (
                      <BlockIcon color="error" />
                    )}
                  </TableCell>
                  <TableCell className={classes.textCenter}>
                    {question.reused ? (
                      <DoneIcon color="primary" />
                    ) : (
                      <BlockIcon color="error" />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      ) : null}
    </Paper>
  );
};
