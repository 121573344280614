/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { CountryInput } from 'components/country';
import { Answers } from '../answers';
import { UNSAVED } from '../saved';

export function CountryQuestion({
  updateLpDoc,
  setSaving,
  answer,
  label,
  QuestionStepper,
  QuestionPromptComponent,
}) {
  const initialCountryCode = answer || '';
  const [countryCode, setCountryCode] = useState(initialCountryCode);
  const [errorMsg, setErrorMsg] = useState('');
  const hasInput = Boolean(countryCode);

  function handleSubmit(e) {
    e.preventDefault();

    return updateLpDoc({
      label,
      answer: countryCode,
      skipQuestion: !hasInput,
    }).catch((error) => {
      setSaving(UNSAVED);
      if (error.response?.status === 400) {
        setErrorMsg(error.response.data.answer);
      }
      return error;
    });
  }

  function handleChange(e, value) {
    e.preventDefault();
    e.stopPropagation();

    setSaving(UNSAVED);

    if (value === null) {
      setCountryCode('');
      return;
    }
    setCountryCode(value.code);
  }

  // Reset the countryCode when changing questions
  useEffect(() => {
    setCountryCode(initialCountryCode);
    setErrorMsg('');
  }, [label]);

  return (
    <form onSubmit={handleSubmit}>
      <QuestionPromptComponent />
      <Answers>
        <CountryInput
          frontendLabel="Country"
          value={countryCode}
          handleChange={handleChange}
          errorMsg={errorMsg}
          autoFocus
        />
        <QuestionStepper
          disabled={!countryCode}
          handleSubmit={handleSubmit}
          hasAnswerChanged={countryCode !== initialCountryCode}
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
}
