import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from 'components/alert';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { PageContainer } from 'components/page_container';
import { Spinner } from 'components/spinner';
import * as urls from 'services/urls';
import { DocumentSigningItem } from 'components/document_signing_item_v2/index';
import { Signed } from 'components/signed';
import { useFund } from 'services/providers/fund';
import { useClosing } from 'services/providers/closing';
import { HeaderSkeleton, PageHeader, Breadcrumbs } from '@passthrough/uikit';
import { BulkCountersignModal } from './bulk_countersign_modal';

const SUBSCRIPTION_DOCUMENT = 'Subscription document';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'block',
  },
  top: {
    marginTop: theme.spacing(4),
  },
  list: {
    padding: 0,
  },
  documentSectionContainer: {
    marginBottom: theme.spacing(4),
  },
}));

const getDocsByType = (docs) => {
  const docsByType = {};
  docs.forEach((doc) => {
    const docType =
      doc.type === SUBSCRIPTION_DOCUMENT ? doc.documentName : doc.type;
    if (!docsByType[docType]) {
      docsByType[docType] = [];
    }
    docsByType[docType].push(doc);
  });
  return docsByType;
};

const getDocumentSigningItemProps = ({
  doc,
  loadingId,
  setLoadingId,
  alreadySigned,
  setAlreadySigned,
  setErrorMsg,
  setActiveBulkCountersignDoc,
  setBulkCountersignModalOpen,
}) => {
  const isAlreadySigned = alreadySigned.includes(doc.id);
  if (doc.isEnvelope) {
    const plural = doc.count > 1 ? 's' : '';
    return {
      name: `${doc.count} investor${plural}`,
      mustCountersign: true,
      isPdfReady: doc.isPdfReady && doc.documentUrl,
      signers: [],
      lpDocId: doc.id,
      handleSign: () => {
        setAlreadySigned((a) => a + [doc.id]);
        setActiveBulkCountersignDoc(null);
      },
      alreadySigned: isAlreadySigned,
      setErrorMsg,
      countersignOnClick: () => {
        setActiveBulkCountersignDoc(doc);
        setBulkCountersignModalOpen(true);
      },
      downloadOnClick: () => {
        window.open(doc.documentUrl, '_blank');
      },
      forceIcon: isAlreadySigned ? 'check' : 'signature',
    };
  }
  return {
    name: doc.lpName,
    mustCountersign: true,
    isPdfReady: doc.isPdfReady && !isAlreadySigned,
    signers: doc.signers,
    lpDocId: doc.id,
    lpClosingId: doc.lpClosingId,
    isLoading: doc.id === loadingId,
    setLoading: (l) => {
      setLoadingId(l ? doc.id : null);
    },
    handleSign: () => {
      setAlreadySigned((a) => a + [doc.id]);
    },
    disabled: !!loadingId,
    alreadySigned: isAlreadySigned,
    isOffline: doc.isOffline,
    hasOnlineCountersigners: doc.hasOnlineCountersigners || isAlreadySigned,
    setErrorMsg,
  };
};

const DocumentSection = ({
  lpDocs,
  loadingId,
  setLoadingId,
  alreadySigned,
  setAlreadySigned,
  setErrorMsg,
  setActiveBulkCountersignDoc,
  setBulkCountersignModalOpen,
}) => {
  const classes = useStyles();
  return (
    <Paper variant="outlined" className={classes.paper}>
      <List className={classes.list}>
        {lpDocs.map((doc, index) => (
          <React.Fragment key={doc.id}>
            {index !== 0 ? <Divider /> : null}

            <DocumentSigningItem
              {...getDocumentSigningItemProps({
                doc,
                loadingId,
                setLoadingId,
                alreadySigned,
                setAlreadySigned,
                setErrorMsg,
                setActiveBulkCountersignDoc,
                setBulkCountersignModalOpen,
              })}
            />
          </React.Fragment>
        ))}
      </List>
    </Paper>
  );
};

export function CountersignPage() {
  const { fundId } = useParams();
  const [errorMsg, setErrorMsg] = useState('');
  const [loadingId, setLoadingId] = useState(null);
  const [alreadySigned, setAlreadySigned] = useState([]);
  const [fund] = useFund();
  const [closingData, refresh] = useClosing();
  useEffect(refresh, []);

  const { closing, countersignTasks } = closingData;

  const [bulkCountersignModalOpen, setBulkCountersignModalOpen] =
    useState(false);
  const [activeBulkCountersignDoc, setActiveBulkCountersignDoc] =
    useState(null);

  const classes = useStyles();

  function crumbs() {
    return (
      <Breadcrumbs
        crumbs={[
          {
            name: fund?.organizationName,
            to: fund?.isOrganizationAdmin
              ? urls.organizationUrl({ organizationId: fund?.organizationId })
              : null,
            skeleton: !fund,
          },
          {
            name: fund?.name,
            to: urls.fundUrl({ fundId: fund?.id }),
            skeleton: !fund,
          },
          {
            name: closing?.name,
            to: urls.closingUrl({ fundId: fund?.id, closingId: closing?.id }),
            skeleton: !closing,
          },
          {
            name: 'Countersign',
            skeleton: !closing,
          },
        ]}
      />
    );
  }

  function getContent() {
    if (closing === null || countersignTasks === null) {
      return <Spinner fullScreen />;
    }

    const { lpDocs, envelopes } = countersignTasks;
    const annotatedEnvelopes = envelopes.map((envelope) => ({
      ...envelope,
      type: SUBSCRIPTION_DOCUMENT,
      isEnvelope: true,
    }));
    const allDocs = [...annotatedEnvelopes, ...lpDocs];

    const docsByType = getDocsByType(allDocs);

    const docsToSign = allDocs.filter((doc) => !alreadySigned.includes(doc.id));

    if (docsToSign.length === 0) {
      return <Signed />;
    }

    return (
      <>
        <div className={classes.top} />
        {errorMsg ? <Alert severity="error">{errorMsg}</Alert> : null}
        {Object.entries(docsByType).map(([docType, docs]) => (
          <div key={docType} className={classes.documentSectionContainer}>
            <Typography variant="h6" gutterBottom>
              {docType}
            </Typography>
            <DocumentSection
              lpDocs={docs}
              loadingId={loadingId}
              setLoadingId={setLoadingId}
              alreadySigned={alreadySigned}
              setAlreadySigned={setAlreadySigned}
              setErrorMsg={setErrorMsg}
              setActiveBulkCountersignDoc={setActiveBulkCountersignDoc}
              setBulkCountersignModalOpen={setBulkCountersignModalOpen}
            />
          </div>
        ))}
      </>
    );
  }

  return (
    <>
      <PageHeader
        heading={fund?.name}
        crumbs={crumbs()}
        skeletons={[HeaderSkeleton.crumbs, HeaderSkeleton.heading]}
      />
      <PageContainer maxWidth="md" className={classes.container}>
        {getContent()}
      </PageContainer>
      <BulkCountersignModal
        open={bulkCountersignModalOpen}
        onClose={() => setBulkCountersignModalOpen(false)}
        fundId={fundId}
        activeBulkCountersignDoc={activeBulkCountersignDoc}
        setAlreadySigned={setAlreadySigned}
        setActiveBulkCountersignDoc={setActiveBulkCountersignDoc}
        refetchTasks={refresh}
      />
    </>
  );
}
