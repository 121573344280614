import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { getFilterChipFunction } from './chips';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    flexWrap: 'wrap',
  },
}));

export function FilterChips({ filters, hasSigning, handleDelete }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {filters.map((filter) => {
        const FilterChip = getFilterChipFunction(filter);

        return (
          <FilterChip
            key={filter.id}
            filter={filter}
            hasSigning={hasSigning}
            onDelete={() => {
              handleDelete(filter);
            }}
          />
        );
      })}
    </div>
  );
}
