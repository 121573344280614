export const QUESTIONNAIRE_EVENT_TYPES = {
  THREAD_RESOLVED: 'change_request_resolved',
  THREAD_REOPENED: 'thread_reopened',
  GP_SENT_COMMENT: 'change_request_sent',
  LP_SENT_COMMENT: 'lp_sent_comment',
  ANSWER_SUBMITTED: 'answer_submitted',
  ANSWER_PREFILLED: 'answer_prefilled',
  INTERNAL_NOTE_ADDED: 'internal_note',
};

export const DILIGENCE_EVENT_TYPES = {
  ANSWER_SUBMITTED: 'answer_submitted',
  GP_ANSWER_SUBMITTED: 'gp_answer_submitted',
  GP_SENT_COMMENT: 'change_request_sent',
  MODIFIED_SUPPLEMENTARY_FILES: 'modified_supplementary_files',
  NEW_MATCH: 'new_match',
  UPDATED_MATCH: 'updated_match',
  CHANGE_REQUEST_RESOLVED: 'change_request_resolved',
  MARKED_NOT_TRUE_MATCH: 'marked_not_true_match',
  MARKED_NOT_SURE: 'marked_not_sure',
  MARKED_TRUE_MATCH: 'marked_true_match',
  MARKED_NO_CHOICE: 'marked_no_choice',
  INTERNAL_NOTE_ADDED: 'internal_note',
  APPROVED: 'approved',
  UNAPPROVED: 'unapproved',
  ANSWER_CHANGE_UNAPPROVED: 'answer_change_unapproved',
  LP_SENT_COMMENT: 'lp_sent_comment',
  THREAD_REOPENED: 'thread_reopened',
  MANUAL_SEARCH: 'manual_search',
  TYPE_GP_CREATED_OWNER: 'gp_created_owner',
  MARK_AS_REVIEWED: 'mark_as_reviewed',
  ENABLED_ADVERSE_MEDIA: 'enabled_adverse_media',
};

export const lpIconEvents = [
  QUESTIONNAIRE_EVENT_TYPES.LP_SENT_COMMENT,
  DILIGENCE_EVENT_TYPES.LP_SENT_COMMENT,
];

export const gpIconEvents = [
  QUESTIONNAIRE_EVENT_TYPES.GP_SENT_COMMENT,
  DILIGENCE_EVENT_TYPES.GP_SENT_COMMENT,
];

export const internalNoteIconEvents = [
  QUESTIONNAIRE_EVENT_TYPES.INTERNAL_NOTE_ADDED,
  DILIGENCE_EVENT_TYPES.INTERNAL_NOTE_ADDED,
];

export const internalNoteEvents = internalNoteIconEvents;

export const useFundNameEvents = [
  QUESTIONNAIRE_EVENT_TYPES.GP_SENT_COMMENT,
  QUESTIONNAIRE_EVENT_TYPES.ANSWER_PREFILLED,
  DILIGENCE_EVENT_TYPES.GP_SENT_COMMENT,
];

export const commentEvents = [
  QUESTIONNAIRE_EVENT_TYPES.LP_SENT_COMMENT,
  DILIGENCE_EVENT_TYPES.LP_SENT_COMMENT,
  QUESTIONNAIRE_EVENT_TYPES.GP_SENT_COMMENT,
  DILIGENCE_EVENT_TYPES.GP_SENT_COMMENT,
  ...internalNoteEvents,
];

export const openedThreadEvents = [
  ...commentEvents,
  QUESTIONNAIRE_EVENT_TYPES.THREAD_REOPENED,
  DILIGENCE_EVENT_TYPES.THREAD_REOPENED,
];

export const threadEvents = [
  ...openedThreadEvents,
  DILIGENCE_EVENT_TYPES.THREAD_RESOLVED,
  DILIGENCE_EVENT_TYPES.INTERNAL_NOTE_ADDED,
  ...internalNoteEvents,
];

export const automatedDiligenceEvents = [
  DILIGENCE_EVENT_TYPES.ANSWER_CHANGE_UNAPPROVED,
  DILIGENCE_EVENT_TYPES.NEW_MATCH,
  DILIGENCE_EVENT_TYPES.UPDATED_MATCH,
];
