import React from 'react';
import { Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import { Spinner } from 'components/spinner';
import { useToast } from 'services/toast';

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: theme.spacing(5),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  container: {
    border: 'none',
    backgroundColor: theme.palette.background.paper,
    height: '80vh',
    minWidth: '500px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },
}));

export const TrayDialog = ({
  open,
  onClose,
  onFinish,
  onValidate,
  wizardUrl,
}) => {
  const classes = useStyles();
  const [ready, setReady] = React.useState(false);
  const { errorToast } = useToast();
  const ref = React.useRef();

  const postValidationMessage = (data) => {
    ref.current.contentWindow.postMessage(
      {
        type: 'tray.configPopup.client.validation',
        data,
      },
      '*',
    );
  };

  const validateUserPermissions = (data) => {
    postValidationMessage({ inProgress: true });
    onValidate(data)
      .then((response) => {
        postValidationMessage({
          inProgress: false,
          errors: response.data.errors,
        });
      })
      .catch(() => {
        errorToast(
          'There was an error validating your permissions. Please try again later.',
        );
      });
  };

  React.useEffect(() => {
    const onmessage = (e) => {
      if (e.origin === 'https://embedded.tray.io' && e.isTrusted) {
        switch (e.data.type) {
          case 'tray.configPopup.cancel':
            onClose();
            break;
          case 'tray.configPopup.error':
            Sentry.captureMessage(e.data.err);
            onClose();
            break;
          case 'tray.configPopup.finish':
            onFinish();
            break;
          case 'tray.configPopup.validate': {
            if (ref.current) {
              validateUserPermissions(e.data.data);
            }
            break;
          }
          case 'tray.configPopup.ready': {
            setReady(true);
            break;
          }
          default:
        }
      }
    };
    window.addEventListener('message', onmessage);
    return () => {
      window.removeEventListener('message', onmessage);
    };
  }, [ref]);

  return (
    <Dialog open={open} onClose={onClose}>
      <div className={classes.container}>
        <iframe
          ref={ref}
          title="Tray"
          src={wizardUrl}
          className={classes.container}
          style={{ display: ready ? 'block' : 'none' }}
        />
        {ready ? null : <Spinner fullScreen />}
      </div>
    </Dialog>
  );
};
