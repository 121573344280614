import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  boldText: {
    fontWeight: 600,
  },
}));

export function ThreadHeader({ ButtonComponent, showAllNotes }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.boldText}>
        {showAllNotes ? 'All notes' : 'Most recent note'}
      </Typography>

      {ButtonComponent}
    </div>
  );
}
