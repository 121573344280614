import React from 'react';

import { Typography, UserDisplay } from '@passthrough/uikit';
import { Setting } from 'components/settings_group';

export function Member({ optional, members, memberId, display }) {
  const member = members.find((m) => m.id === memberId);
  const label = `Default ${display}${optional ? ' (optional)' : ''}`;

  return (
    <Setting label={label}>
      {member ? (
        <UserDisplay name={member.name} email={member.email} />
      ) : (
        <Typography color="text.secondary">None</Typography>
      )}
    </Setting>
  );
}
