import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { Chip } from 'components/chip';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  headerText: {
    fontWeight: 500,
    fontSize: '1rem',
  },
  instructionStepNumChip: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.success.background,
    marginRight: theme.spacing(2),
    fontWeight: 500,
    fontSize: '1rem',
  },
}));

export function ReviewInstructionStep({
  stepNum,
  header,
  body = '',
  className,
}) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <div>
        <Chip
          label={stepNum}
          className={classes.instructionStepNumChip}
          size="small"
        />
      </div>

      <div>
        <Typography className={classes.headerText}>{header}</Typography>

        {body ? <Typography variant="body2">{body}</Typography> : null}
      </div>
    </div>
  );
}
