import React from 'react';
import { useQuestionnaire } from 'services/providers/questionnaire';
import { Answers } from '../answers';

export function BlockerQuestion({
  label,
  updateLpDoc,
  QuestionStepper,
  QuestionPromptComponent,
}) {
  const { useSupport } = useQuestionnaire();
  const { showSupport } = useSupport();

  function handleSubmit(e) {
    e.preventDefault();
    return updateLpDoc({ label, answer: null });
  }

  return (
    <form onSubmit={handleSubmit}>
      <QuestionPromptComponent addPrefixText={false} />
      <Answers short>
        <QuestionStepper
          disabled={false}
          hasAnswerChanged={false}
          hasInput={false}
          onClick={showSupport}
        >
          Contact Support
        </QuestionStepper>
      </Answers>
    </form>
  );
}
