import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import {
  StatusSummary,
  INVESTOR_CLOSING_STATUS_COLORS,
} from './status_summary';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    overflowY: 'hidden',
    overflowX: 'auto',
  },
}));

export function FunnelSummary({ closing, currency }) {
  const classes = useStyles();
  if (closing === null) {
    return null;
  }

  const {
    numDraft,
    numNotStarted,
    numInProgress,
    numSigned,
    numApproved,
    numSentToCountersigner,
    numFullyExecuted,
    commitmentUnsent,
    commitmentInProgress,
    commitmentSigned,
    commitmentApproved,
    commitmentFullyExecuted,
    commitmentSentToCountersigner,
    hasCommitmentQuestion,
  } = closing;

  return (
    <Paper variant="outlined" className={classes.root}>
      <StatusSummary
        showCommitmentValues={hasCommitmentQuestion}
        investorCount={numDraft}
        commitmentAmount={commitmentUnsent}
        statusLabel="Unsent"
        color={INVESTOR_CLOSING_STATUS_COLORS.UNSENT}
        currency={currency}
      />

      <StatusSummary
        showCommitmentValues={hasCommitmentQuestion}
        investorCount={numNotStarted + numInProgress}
        commitmentAmount={commitmentInProgress}
        statusLabel="Sent to investor"
        color={INVESTOR_CLOSING_STATUS_COLORS.SENT}
        currency={currency}
      />

      <StatusSummary
        showCommitmentValues={hasCommitmentQuestion}
        investorCount={numSigned}
        commitmentAmount={commitmentSigned}
        statusLabel={
          closing.hasSigning ? 'Investor signed' : 'Investor completed'
        }
        color={INVESTOR_CLOSING_STATUS_COLORS.SIGNED}
        currency={currency}
      />

      <StatusSummary
        showCommitmentValues={hasCommitmentQuestion}
        investorCount={numApproved}
        commitmentAmount={commitmentApproved}
        statusLabel="Approved"
        color={INVESTOR_CLOSING_STATUS_COLORS.APPROVED}
        currency={currency}
      />

      {closing.hasCountersigning ? (
        <StatusSummary
          showCommitmentValues={hasCommitmentQuestion}
          investorCount={numSentToCountersigner}
          commitmentAmount={commitmentSentToCountersigner}
          statusLabel="Sent to countersigner"
          color={INVESTOR_CLOSING_STATUS_COLORS.SENT}
          currency={currency}
        />
      ) : null}

      {closing.hasFullyExecuted ? (
        <StatusSummary
          showCommitmentValues={hasCommitmentQuestion}
          investorCount={numFullyExecuted}
          commitmentAmount={commitmentFullyExecuted}
          statusLabel="Fully executed"
          color={INVESTOR_CLOSING_STATUS_COLORS.EXECUTED}
          currency={currency}
        />
      ) : null}
    </Paper>
  );
}
