import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MaterialBreadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'components/link';

const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: 'flex-start',
  },
  current: {
    fontWeight: 600,
    color: theme.palette.primary.black,
  },
  previous: {
    color: theme.palette.primary.grey,
  },
}));

/** Usage:
 *   <Breadcrumbs crumbs={[
 *     {to: '/dashboard', name: 'funds'},
 *     {name: 'Fund I'}]}
 *   />
 */
export function Breadcrumbs({ crumbs }) {
  const classes = useStyles();

  return (
    <MaterialBreadcrumbs
      className={classes.root}
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
    >
      {crumbs.map((crumb, index) =>
        crumb.to ? (
          <Link
            className={clsx({
              [classes.current]: index === crumbs.length - 1,
              [classes.previous]: index !== crumbs.length - 1,
            })}
            key={crumb.name}
            to={crumb.to}
          >
            {crumb.name}
          </Link>
        ) : (
          <Typography
            key={crumb.name}
            className={clsx({
              [classes.current]: index === crumbs.length - 1,
              [classes.previous]: index !== crumbs.length - 1,
            })}
          >
            {crumb.name}
          </Typography>
        ),
      )}
    </MaterialBreadcrumbs>
  );
}
