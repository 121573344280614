import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import CardActionArea from '@material-ui/core/CardActionArea';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';

import { getSourceDisplay } from './answer_source';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    borderRadius: 8,
    [theme.breakpoints.up('sm')]: {
      transition: '0.2s',
      '&:hover': {
        transform: 'scale(1.1)',
        zIndex: 1,
      },
    },
  },
  shortItem: {
    fontSize: '2rem !important',
    padding: theme.spacing(2),
    width: '100%',
  },
  longItem: {
    fontSize: '2rem !important',
    padding: theme.spacing(2),
    width: '100%',
    alignItems: 'flex-start',
  },
}));

export const MultiChoiceItem = ({
  children,
  lpDoc,
  activeProfile,
  sources,
  short,
  checked,
  onChange,
  name,
}) => {
  const classes = useStyles();
  return (
    <CardActionArea className={classes.root}>
      <Paper variant="outlined">
        <FormControlLabel
          className={short ? classes.shortItem : classes.longItem}
          control={
            <Checkbox checked={checked} onChange={onChange} name={name} />
          }
          label={
            <span>
              {children}
              {sources.length ? (
                <FormHelperText>
                  Selected by{' '}
                  {sources
                    .map((source) =>
                      getSourceDisplay({
                        source,
                        lpDoc,
                        activeProfile,
                      }),
                    )
                    .join(', ')}
                </FormHelperText>
              ) : null}
            </span>
          }
        />
      </Paper>
    </CardActionArea>
  );
};
