import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1px 4px',
    fontSize: '0.75rem',
  },
  highRisk: {
    color: theme.palette.pepClasses.highRisk,
    borderColor: theme.palette.pepClasses.highRisk,
  },
  mediumRisk: {
    color: theme.palette.pepClasses.mediumRisk,
    borderColor: theme.palette.pepClasses.mediumRisk,
  },
  lowRisk: {
    color: theme.palette.pepClasses.lowRisk,
    borderColor: theme.palette.pepClasses.lowRisk,
  },
  minimalRisk: {
    color: theme.palette.pepClasses.minimalRisk,
    borderColor: theme.palette.pepClasses.minimalRisk,
  },
}));

function getPepClassText(pepClassNum) {
  switch (pepClassNum) {
    case 1:
      return 'High';
    case 2:
      return 'Medium';
    case 3:
      return 'Low';
    case 4:
      return 'Minimal';
    default:
      return '';
  }
}

export function PepClassDisplay({ pepClassNum, className }) {
  const classes = useStyles();

  const pepPaperClass = clsx(classes.root, className, {
    [classes.highRisk]: pepClassNum === 1,
    [classes.mediumRisk]: pepClassNum === 2,
    [classes.lowRisk]: pepClassNum === 3,
    [classes.minimalRisk]: pepClassNum === 4,
  });

  return (
    <Paper variant="outlined" className={pepPaperClass}>
      <Typography variant="body2">
        {getPepClassText(pepClassNum)} risk
      </Typography>
    </Paper>
  );
}
