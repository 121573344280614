import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'components/button';
import * as api from 'services/api';
import { useToast } from 'services/toast';

import { Alert } from '@passthrough/uikit';
import { reducerActionTypes } from '../constants';
import { MarkReviewModal } from './mark_review_modal';

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.warning.fill,
    borderColor: theme.palette.warning.border,
    gap: theme.spacing(2),
  },
  warningIcon: {
    color: theme.palette.warning.main,
  },
  markButton: {
    marginLeft: 'auto',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.warning.text,
  },
}));

export function UpdatesAvailable({ questionId, selectedId, dispatch }) {
  const [showMarkReviewModal, setShowMarkReviewModal] = useState(false);
  const classes = useStyles();
  const { fundId, lpClosingId } = useParams();
  const { toast } = useToast();

  const markReviewed = () => {
    api
      .markMatchAsReviewed({
        fundId,
        lpClosingId,
        questionId,
        matchId: selectedId,
      })
      .then(() => {
        dispatch({
          type: reducerActionTypes.MARK_AS_REVIEWED,
          matchId: selectedId,
        });

        toast('Marked match as reviewed');
      })
      .catch(() => {});
    setShowMarkReviewModal(false);
  };

  return (
    <>
      <MarkReviewModal
        open={showMarkReviewModal}
        onClose={() => setShowMarkReviewModal(false)}
        onSubmit={markReviewed}
      />
      <Alert
        variant="inpage"
        severity="warning"
        action={
          <Button
            variant="text"
            className={classes.markButton}
            onClick={() => setShowMarkReviewModal(true)}
          >
            Mark as reviewed
          </Button>
        }
      >
        Updates available for this match
      </Alert>
    </>
  );
}
