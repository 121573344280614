import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Typography, Button } from '@passthrough/uikit';
import { TrueLink } from 'components/link';
import * as urls from 'services/urls';

const useStyles = makeStyles((theme) => ({
  contentHeader: {
    display: 'flex',
    marginBottom: theme.spacing(4),
    justifyContent: 'center',
  },
  next: {
    marginTop: theme.spacing(3),
  },
  disclaimer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    textAlign: 'left',
  },
}));

export function Step2({ config, error, loading, onSubmit }) {
  const classes = useStyles();
  const [data, setData] = React.useState({});
  return (
    <div>
      <div className={classes.contentHeader}>
        <Typography variant="section-heading">
          Tell us about yourself
        </Typography>
      </div>

      <TextField
        InputProps={{
          className: classes.input,
        }}
        variant="outlined"
        margin="normal"
        fullWidth
        id="name"
        label="Full name"
        name="name"
        autoComplete="name"
        autoFocus
        error={!!error.name}
        helperText={error.name}
        value={data.name}
        onChange={(e) => setData((d) => ({ ...d, name: e.target.value }))}
        disabled={loading}
      />

      <TextField
        InputProps={{
          className: classes.input,
        }}
        variant="outlined"
        margin="normal"
        fullWidth
        type="email"
        id="email"
        label="Email"
        name="email"
        autoComplete="email"
        error={!!error.email}
        helperText={error.email}
        value={data.email}
        onChange={(e) => setData((d) => ({ ...d, email: e.target.value }))}
        disabled={loading}
      />

      <TextField
        InputProps={{
          className: classes.input,
        }}
        variant="outlined"
        margin="normal"
        fullWidth
        id="company"
        label="Company name"
        name="company"
        autoComplete="company"
        error={!!error.company}
        helperText={error.company}
        value={data.company}
        onChange={(e) => setData((d) => ({ ...d, company: e.target.value }))}
        disabled={loading}
      />

      <TextField
        InputProps={{
          className: classes.input,
        }}
        variant="outlined"
        margin="normal"
        fullWidth
        id="howDidYouHear"
        label="How did you hear about us? (Optional)"
        name="howDidYouHear"
        error={!!error.howDidYouHear}
        helperText={error.howDidYouHear}
        value={data.howDidYouHear}
        onChange={(e) =>
          setData((d) => ({ ...d, howDidYouHear: e.target.value }))
        }
        disabled={loading}
      />

      <div className={classes.disclaimer}>
        <Typography variant="label" color="text.secondary">
          By clicking "Continue", you agree to {config.productName}'s{' '}
          <TrueLink
            underline="always"
            component="a"
            href={urls.PRIVACY_POLICY_URL}
          >
            privacy policy
          </TrueLink>{' '}
          and{' '}
          <TrueLink underline="always" component="a" href={urls.TERMS_URL}>
            terms of use
          </TrueLink>
          . You also agree to receive marketing communications.
        </Typography>
      </div>

      <div className={classes.next}>
        <Button
          variant="primary"
          fullWidth
          size="large"
          onClick={() => onSubmit(data)}
          loading={loading}
          htmlProps={{
            style: {
              maxWidth: '100%',
            },
          }}
        >
          Start exploring
        </Button>
      </div>
    </div>
  );
}
