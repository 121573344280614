import * as React from 'react';
import EditIcon from '@material-ui/icons/EditOutlined';
import { Button } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core';
import { NoteDisplay } from './note_display';

const useStyles = makeStyles((theme) => ({
  noteContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    flexWrap: 'wrap',
    alignItems: 'center',
  },
}));

export function EditableNote({ lpClosing, onEdit, readOnly = false }) {
  const classes = useStyles();
  return (
    <div className={classes.noteContainer}>
      <NoteDisplay
        note={lpClosing?.mostRecentNote}
        createdAt={lpClosing?.mostRecentNoteCreatedAt}
      />
      {!readOnly ? (
        <Button variant="icon" onClick={onEdit} aria-label="Edit note">
          <EditIcon />
        </Button>
      ) : null}
    </div>
  );
}
