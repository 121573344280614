import React from 'react';
import TextField from '@material-ui/core/TextField';
import Collapse from '@material-ui/core/Collapse';
import MessageIcon from '@material-ui/icons/MessageOutlined';

import { Alert } from '@passthrough/uikit';
import { KeyValuePair } from 'components/key_value';

import { NotifyCheckbox } from './notify_checkbox';

export function EditStage({ message, setMessage, notify, setNotify }) {
  return (
    <>
      <Alert severity="info">
        The investor will no longer be able to change their answers.
      </Alert>

      <NotifyCheckbox notify={notify} setNotify={setNotify} />

      <Collapse in={notify} mountOnEnter unmountOnExit>
        <KeyValuePair input icon={<MessageIcon />} disableGutters dense>
          <TextField
            fullWidth
            id="undo-message-to-investor"
            label="Message to investor (optional)"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            variant="outlined"
            multiline
            minRows={4}
          />
        </KeyValuePair>
      </Collapse>
    </>
  );
}
