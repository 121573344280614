import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import PencilIcon from 'mdi-material-ui/Pencil';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import IconButton from '@material-ui/core/IconButton';
import { ThreadCountChip } from 'components/thread_count_chip';

import { isLongAnswer } from 'services/utils';
import { getAnswerDisplayComponent } from 'components/answer_displays/get_answer_display';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderRadius: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  highlightRequestedChange: {
    backgroundColor: theme.palette.error.background,
  },
  summary: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      rowGap: theme.spacing(2),
      alignItems: 'inherit',
    },
  },
  questionAndAnswer: {
    display: 'flex',
    flexGrow: 1,
    rowGap: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  questionContainer: {
    flexBasis: '60%',
    alignSelf: 'center',
    [theme.breakpoints.down('md')]: {
      alignSelf: 'flex-start',
    },
  },
  dynamicDataContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexBasis: '40%',
    alignSelf: 'center',
    [theme.breakpoints.down('md')]: {
      alignSelf: 'flex-start',
    },
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  answerContainer: {
    flexBasis: '85%',
    overflowWrap: 'anywhere',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2),
    },
  },
  longAnswerContainer: {
    flexBasis: '80%',
    overflowWrap: 'anywhere',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2),
    },
  },
  threadChipContainer: {
    flexBasis: '15%',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
      margin: theme.spacing(1, 0, 0, 0),
    },
  },
  question: {
    color: theme.palette.text.secondary,
  },
  questionUnanswered: {
    color: theme.palette.text.primary,
    fontWeight: '700',
  },
  answer: {
    color: theme.palette.text.primary,
    fontWeight: '700',
  },
  outlined: {
    borderRight: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
    '&:first-child': {
      borderTopLeftRadius: '6px',
      borderTopRightRadius: '6px',
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    '&:last-child': {
      borderBottomLeftRadius: '6px',
      borderBottomRightRadius: '6px',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
}));

const INFO_ANSWER_TYPE = 'AnswerType.info';
const FILE_ANSWER_TYPES = [
  'AnswerType.file_upload',
  'AnswerType.multi_file_upload',
];

export function OverviewQuestionRowV2({
  question,
  navigateToQuestion,
  outlined,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const isLong = isLongAnswer(question);
  const isInfoQuestion = question.answerType === INFO_ANSWER_TYPE;
  const isFileQuestion = FILE_ANSWER_TYPES.includes(question.answerType);
  const AnswerComponent = getAnswerDisplayComponent(question.answerType);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const ThreadCountChipComponent = (
    <div className={classes.threadChipContainer}>
      <ThreadCountChip
        threadCount={question.numComments}
        threadIsActionable={question.needsConfirmation}
      />
    </div>
  );

  return (
    <Paper
      elevation={0}
      key={question.label}
      className={clsx(classes.root, {
        [classes.highlightRequestedChange]: !question.isCompleted,
        [classes.outlined]: outlined,
      })}
    >
      <div className={classes.summary}>
        <div className={classes.questionAndAnswer}>
          {question.numComments > 0 && isMobile
            ? ThreadCountChipComponent
            : null}

          <div className={classes.questionContainer}>
            <Typography
              className={clsx(classes.question, {
                [classes.questionUnanswered]: !question.isCompleted,
              })}
            >
              {question.question}
            </Typography>
          </div>

          <div className={classes.dynamicDataContainer}>
            {!isInfoQuestion ? (
              <div
                className={clsx({
                  [classes.answerContainer]: !isLong,
                  [classes.longAnswerContainer]: isLong,
                })}
              >
                <Typography
                  component="span"
                  data-test={`${question.label}-answer`}
                  className={clsx({ [classes.answer]: !isFileQuestion })}
                >
                  <AnswerComponent answer={question?.answer} />
                </Typography>
              </div>
            ) : null}

            {question.numComments > 0 && !isMobile
              ? ThreadCountChipComponent
              : null}
          </div>
        </div>
        <div className={classes.actionContainer}>
          <IconButton
            aria-label={isInfoQuestion ? 'edit' : 'view'}
            data-test={!question.isCompleted ? 'incomplete' : 'complete'}
            onClick={() => {
              navigateToQuestion();
            }}
          >
            {isInfoQuestion ? <ArrowForwardIcon /> : <PencilIcon />}
          </IconButton>
        </div>
      </div>
    </Paper>
  );
}
