import React, { useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import { Button, Icons } from '@passthrough/uikit';
import { formatProfileLastUsed } from 'services/utils';
import { ProfileSelectItem } from 'components/profile_select_item';
import { ProfileCard } from './profile_card';
import { SELECT_PROFILE_STEP } from './constants';
import { normalizeName } from '../helpers';

const useStyles = makeStyles((theme) => ({
  radioContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    marginBottom: theme.spacing(2),
  },
  variableWidth: {
    width: '80%',
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  startFromScratchTitle: {
    fontWeight: 400,
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(24),
  },
  lpName: {
    fontWeight: 500,
  },
  headerSection: {
    marginBottom: theme.spacing(2),
  },
  list: {
    maxHeight: 'calc(80vh - 450px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    transition: 'min-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    transitionDuration: '300ms',
  },
  helperTextContainer: {
    padding: theme.spacing(2, 0),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  helperText: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
  },
}));

// Used to compute minHeight of the list so that the dynamic calculation
// of maxHeight doesn't cause the list to be too small.
const LIST_ITEM_HEIGHT = 64;

export function SelectProfileStep({
  profilesList,
  setupProfileId,
  lpName,
  onSelectProfile,
  setWarningDialogOpen,
  setNameWarningProfile,
  setNavStep,
  onStartFromScratch,
  isSubmitting,
  restarting,
  openInfoModal,
}) {
  const classes = useStyles();
  const [choice, setChoice] = useState(setupProfileId || 'new');

  const selectedProfile = profilesList.find((p) => p.id === choice);

  const showNameWarningForChoice =
    choice !== 'new' &&
    normalizeName(selectedProfile.displayName) !== normalizeName(lpName);

  let minHeightItems = profilesList.length;
  if (showNameWarningForChoice) {
    // If the name warning is visible, and there are less than 4 profiles,
    // add 64px to minHeight to prevent the warning from being hidden on small
    // small screens.
    minHeightItems += 1;
  }
  const listMinHeight = LIST_ITEM_HEIGHT * Math.min(minHeightItems, 4);

  const onSubmit = () => {
    onSelectProfile(choice);
  };

  const onContinueClick = () => {
    if (choice === 'new') {
      onStartFromScratch();
      return;
    }

    if (showNameWarningForChoice) {
      setNameWarningProfile(selectedProfile);
      setWarningDialogOpen(true);
      return;
    }

    onSubmit();
  };

  const blankProfileText = restarting
    ? 'Start with a blank questionnaire'
    : 'Start new profile';

  return (
    <ProfileCard
      maxWidth="md"
      lpName={lpName}
      onContinueClick={onContinueClick}
      onContinueDisabled={isSubmitting}
      step={SELECT_PROFILE_STEP}
      setStep={setNavStep}
      hideNewInvestmentText
    >
      <div className={classes.headerSection}>
        <Typography
          className={classes.startFromScratchTitle}
          align="center"
          gutterBottom
        >
          <span className={classes.lpName}>{lpName}</span> has been invited to
          invest.
        </Typography>
        <FormControl component="fieldset" className={classes.variableWidth}>
          <RadioGroup
            aria-label="choose-profile"
            name="Choose profile"
            value={choice}
            onChange={(e) => {
              setChoice(e.target.value);
            }}
          >
            <ProfileSelectItem
              id="new"
              profileName={blankProfileText}
              outlined
            />
          </RadioGroup>
        </FormControl>
      </div>

      <Typography variant="subtitle2" align="center">
        Or, apply answers from a previous investment
      </Typography>

      <div className={classes.radioContainer}>
        <FormControl component="fieldset" className={classes.variableWidth}>
          <div className={classes.helperTextContainer}>
            <Typography className={classes.helperText}>
              You have previously participated in at least one
              Passthrough-powered investment. The submitted investor data has
              been saved and can be used for future investments.
            </Typography>
            <Button
              variant="icon"
              aria-label="Learn more"
              onClick={openInfoModal}
            >
              <Icons.InfoOutlined />
            </Button>
          </div>

          <RadioGroup
            aria-label="choose-profile"
            name="Choose profile"
            value={choice}
            onChange={(e) => {
              setChoice(e.target.value);
            }}
          >
            <Paper
              variant="outlined"
              className={classes.list}
              style={{ minHeight: listMinHeight }}
            >
              {profilesList.map((profile, index) => (
                <Fragment key={profile.id}>
                  <ProfileSelectItem
                    id={profile.id}
                    profileName={profile.displayName}
                    lastUsed={formatProfileLastUsed(
                      profile.lastUsedDatetime,
                      profile.lastUsedFundName,
                    )}
                    showNameWarning={
                      choice === profile.id && showNameWarningForChoice
                    }
                    collaborators={profile.collaborators}
                  />
                  {index !== profilesList.length - 1 ? <Divider /> : null}
                </Fragment>
              ))}
            </Paper>
          </RadioGroup>
        </FormControl>
      </div>
    </ProfileCard>
  );
}
