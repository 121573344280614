import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export function InteroperableInput({ notInteroperable, setNotInteroperable }) {
  return (
    <FormControl>
      <FormControlLabel
        control={
          <Checkbox
            checked={notInteroperable}
            onChange={(e) => {
              setNotInteroperable(e.target.checked);
            }}
            name="interoperable"
          />
        }
        label="Not interoperable"
      />
    </FormControl>
  );
}
