import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { NoMatchesIcon } from './no_matches_icon';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noMatchesLabel: {
    marginBottom: theme.spacing(1),
  },
  noMatchesIcon: {
    marginBottom: theme.spacing(4),
  },
}));

export function NoMatchesDisplay() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <NoMatchesIcon className={classes.noMatchesIcon} />

      <Typography variant="h5" className={classes.noMatchesLabel}>
        No matches found
      </Typography>

      <Typography variant="body2">
        Review details and documents for this owner
      </Typography>
    </div>
  );
}
