import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import LockIconOutlined from '@material-ui/icons/LockOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { Typography } from '@passthrough/uikit';
import { TruncatedTextDisplay } from 'components/truncated_text_display';

const useStyles = makeStyles((theme) => ({
  note: {
    backgroundColor: theme.palette.background.internalNote,
    padding: theme.spacing(2),
    overflowWrap: 'anywhere',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  lockIcon: {
    color: theme.palette.text.secondary,
  },
  maxWidth: {
    maxWidth: '600px',
  },
}));

export function NoteDisplay({
  note,
  createdAt,
  fullWidth = false,
  truncate = true,
}) {
  const classes = useStyles();

  if (!note) {
    return (
      <Typography variant="label" color="text.secondary">
        No note
      </Typography>
    );
  }

  const dateOb = parseISO(createdAt);
  const updateTime = format(dateOb, 'h:mm a');
  const updateDate = format(dateOb, 'd MMM yyyy');

  return (
    <Paper
      elevation={0}
      className={clsx(classes.note, { [classes.maxWidth]: !fullWidth })}
    >
      <div className={classes.col}>
        {truncate ? (
          <TruncatedTextDisplay
            content={note}
            expandText="See full note"
            contractText="Hide full note"
          />
        ) : (
          <Typography multiline variant="body" size="small">
            {note}
          </Typography>
        )}
        <Typography variant="label" color="text.secondary">
          Updated at {updateTime} on {updateDate}
        </Typography>
      </div>
      <Tooltip
        title={
          <Typography variant="label">Not visible to the investor</Typography>
        }
      >
        <LockIconOutlined className={classes.lockIcon} fontSize="small" />
      </Tooltip>
    </Paper>
  );
}
