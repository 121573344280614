import * as React from 'react';
import { useDrawerReducer } from './utils';
import { DocumentsDrawerContext } from './context';
import { DocumentsDrawer } from './drawer';

export function DocumentsDrawerProvider({ children, lpDoc, nav }) {
  const reducer = useDrawerReducer(lpDoc);
  return (
    <DocumentsDrawerContext.Provider value={reducer}>
      {children}
      <DocumentsDrawer lpDoc={lpDoc} nav={nav} />
    </DocumentsDrawerContext.Provider>
  );
}
