import React from 'react';
import PersonIcon from '@material-ui/icons/PersonOutlineOutlined';
import PublicIcon from '@material-ui/icons/Public';
import BusinessIcon from '@material-ui/icons/Business';

import { signerPalette } from 'services/theme';

// Used by the document upload modal; can create unlimited new boxes.
export const BOX_CREATION_MODE = 'boxCreation';
// Used by the prepare signature pages modal; only allows you to place a
// predetermined set of boxes; limited editing (can only resize and
// move boxes).
export const BOX_PLACEMENT_MODE = 'boxPlacement';
export const VALID_MODES = [BOX_CREATION_MODE, BOX_PLACEMENT_MODE];

// Action names; see document_fields/hooks/useFields() for reducer implementation
export const SET_BOXES_ACTION = 'setBoxes';
export const ADD_ACTION = 'add';
export const DELETE_ACTION = 'delete';
export const UPDATE_ACTION = 'update';
export const TRANSLATE_ACTION = 'translate';
export const RESIZE_ACTION = 'resize';
export const SET_ACTIVE_BOX_ACTION = 'setActiveBox';
export const SET_BOX_TO_PLACE_ACTION = 'setBoxToPlace';
export const PLACE_BOX_ACTION = 'placeBox';
export const REMOVE_PLACED_BOX = 'removePlacedBox';
export const UPDATE_PAGE_ACTION = 'updatePage';

// Allow moving the active box on the page using arrow keys
export const ARROW_KEY_STEP_SIZE = 5;

// Signer roles
export const FIRST_SIGNER = 'Investor';
export const SECOND_SIGNER = 'Investor2';
export const THIRD_SIGNER = 'Investor4';
export const FOURTH_SIGNER = 'Investor5';
export const FIFTH_SIGNER = 'Investor6';
export const SIXTH_SIGNER = 'Investor7';

export const FIRST_COUNTERSIGNER = 'Countersigner';
export const SECOND_COUNTERSIGNER = 'Countersigner2';
export const THIRD_COUNTERSIGNER = 'Countersigner3';

// Values used by BOX_CREATION_MODE === 'boxCreation' for editing boxes
export const INVESTOR_TYPE_ALL = 0;
export const INVESTOR_TYPE_INDIVIDUAL = 1;
export const INVESTOR_TYPE_ENTITY = 2;

export const INVESTOR_TYPE_CHOICES = [
  { value: INVESTOR_TYPE_ALL, label: 'All', icon: <PublicIcon /> },
  {
    value: INVESTOR_TYPE_INDIVIDUAL,
    label: 'Individual',
    icon: <PersonIcon />,
  },
  { value: INVESTOR_TYPE_ENTITY, label: 'Entity', icon: <BusinessIcon /> },
];

const investorRole = 'Investor';
const countersignerRole = 'Countersigner';

// Initial list of signers; signer names are set in document_fields/hooks/useSigners()
export const signers = [
  {
    role: investorRole,
    number: 1,
    color: signerPalette.investor1,
    pdfBoxSigner: FIRST_SIGNER,
    name: '',
  },
  {
    role: investorRole,
    number: 2,
    color: signerPalette.investor2,
    pdfBoxSigner: SECOND_SIGNER,
    name: '',
  },
  {
    role: investorRole,
    number: 3,
    color: signerPalette.investor3,
    pdfBoxSigner: THIRD_SIGNER,
    name: '',
  },
  {
    role: investorRole,
    number: 4,
    color: signerPalette.investor4,
    pdfBoxSigner: FOURTH_SIGNER,
    name: '',
  },
  {
    role: investorRole,
    number: 5,
    color: signerPalette.investor5,
    pdfBoxSigner: FIFTH_SIGNER,
    name: '',
  },
  {
    role: investorRole,
    number: 6,
    color: signerPalette.investor6,
    pdfBoxSigner: SIXTH_SIGNER,
    name: '',
  },
  {
    role: countersignerRole,
    number: 1,
    color: signerPalette.countersigner1,
    pdfBoxSigner: FIRST_COUNTERSIGNER,
    name: '',
  },
  {
    role: countersignerRole,
    number: 2,
    color: signerPalette.countersigner2,
    pdfBoxSigner: SECOND_COUNTERSIGNER,
    name: '',
  },
  {
    role: countersignerRole,
    number: 3,
    color: signerPalette.countersigner3,
    pdfBoxSigner: THIRD_COUNTERSIGNER,
    name: '',
  },
];

export const DELETE_KEY_CODE = 46;
export const BACKSPACE_KEY_CODE = 8;

// These are used in DocumentFields to calculate the dimensions of the
// page container. PANEL_WIDTH should be used to set the width/minWidth
// of the left and right panels. DOCUMENT_PADDING is used to set the
// padding around the page container.
export const PANEL_WIDTH = 312;
export const DOCUMENT_PADDING = 24;

// Just big enough to keep the doc tool bar from overflowing: 444px
// TODO: Somehow make DocumentFields mobile friendly.
export const PAGE_CONTAINER_MIN_WIDTH = 444;
// Show at least 200px
export const PAGE_CONTAINER_MIN_HEIGHT = 200;
