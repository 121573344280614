import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import BlockIcon from '@material-ui/icons/Block';

import { componentForQuestion } from 'components/lp_doc/component_for_question';
import { Question } from 'components/lp_doc/question';
import { QuestionStepper } from 'components/lp_doc/question_stepper';
import { Navigator } from 'components/lp_doc/navigator';
import { timezoneNow } from 'services/utils';
import { CommentThreadDisplay } from 'components/lp_doc/comment_thread';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  basePaper: {
    padding: theme.spacing(4),
    minHeight: '500px',
    border: 0,
    borderRadius: 0,
    alignItems: 'center',
  },
  hoverQuestionPaper: {
    position: 'absolute',
    justifyContent: 'center',
    cursor: 'not-allowed',
    backgroundColor: theme.palette.primary.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  changeHeader: {
    margin: 0,
    padding: theme.spacing(1, 3),
    backgroundColor: '#f3f3f3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  headerText: {
    fontWeight: '500',
  },
  hoverIcon: {
    color: theme.palette.warning.main,
    marginBottom: theme.spacing(2),
    fontSize: '4rem',
  },
  hiddenQuestionPaper: {
    padding: theme.spacing(4),
    opacity: 0.1,
    pointerEvents: 'none',
  },
  outerContainer: {
    position: 'relative',
  },
}));

function MockNavRef() {
  const nav = new Navigator({
    lpDoc: {
      sections: [],
      docs: [],
    },
    navState: { innerStep: 0, outerStep: 0 },
    setNavState: () => {},
    setSelectedQuestionId: () => {},
  });
  return { current: nav };
}

function QuestionStepperComponent({ ...props }) {
  return (
    <QuestionStepper
      navRef={MockNavRef()}
      saving={false}
      setShowNavWarning={() => {}}
      hasInput={false}
      {...props}
    />
  );
}

function mockCommentEvent(commentText, label, fundName) {
  return {
    id: '1',
    comment: commentText,
    commentTime: timezoneNow(),
    isLpComment: false,
    questionId: label,
    fundName,
    user: { email: '', name: '' },
  };
}

export function PDFQuestionPreview({ question, fundName, commentEvents }) {
  const classes = useStyles();
  const [isHover, setIsHover] = useState(false);

  const { answer, answerType, isRequired, label, frontendLabel, choices } =
    question;
  const Component = componentForQuestion({ answerType });
  const questionPrefixText = !isRequired ? '(Optional) ' : null;
  const QuestionPromptComponent = ({
    short,
    addPrefixText = true,
    children,
  }) => (
    <Question
      links={[]}
      prefixText={addPrefixText ? questionPrefixText : null}
      short={short}
      question={question.question}
    >
      {children}
    </Question>
  );

  return (
    <Paper className={classes.root} variant="outlined">
      <div className={classes.changeHeader}>
        <Typography className={classes.headerText}>Preview</Typography>
      </div>
      <div
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        className={classes.outerContainer}
      >
        {isHover && (
          <Paper
            className={clsx({
              [classes.basePaper]: true,
              [classes.hoverQuestionPaper]: true,
            })}
            variant="outlined"
          >
            <BlockIcon className={classes.hoverIcon} />
            <Typography variant="h4">Uneditable preview</Typography>
            <Typography align="center">
              This is a preview of what investors will see.
            </Typography>
          </Paper>
        )}
        <Paper
          className={clsx({
            [classes.basePaper]: true,
            [classes.hiddenQuestionPaper]: isHover,
          })}
          variant="outlined"
          tabIndex={-1}
        >
          {question.draftComment ? (
            <CommentThreadDisplay
              comments={[
                ...commentEvents,
                mockCommentEvent(question.draftComment, label, fundName),
              ]}
            />
          ) : null}

          <Component
            choices={choices?.map((c) => ({
              text: c.text,
              exclusive: c.exclusive,
            }))}
            question={question.question}
            links={[]}
            frontendLabel={frontendLabel}
            label={label}
            QuestionStepper={QuestionStepperComponent}
            setSaving={() => {}}
            updateLpDoc={() => {}}
            onAnswer={() => {}}
            lpDoc={{}}
            answer={answer}
            QuestionPromptComponent={QuestionPromptComponent}
          />
        </Paper>
      </div>
    </Paper>
  );
}
