import * as React from 'react';
import { v4 as uuid4 } from 'uuid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { useQuestionnaire } from 'services/providers/questionnaire';
import { useAutoFilled } from './utils';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

function useGetGeoLocation() {
  const active = React.useRef(false);
  const location = React.useRef(null);
  const api = useQuestionnaire();

  return React.useCallback(() => {
    if (!window.ENABLE_GEOLOCATION) {
      location.current = [];
    } else if (!active.current && location.current === null) {
      const cachedLocation = localStorage.getItem('pt-geolocation');
      if (cachedLocation) {
        location.current = JSON.parse(cachedLocation);
        return location.current;
      }
      active.current = true;
      api
        .getGeolocation()
        .then((response) => {
          const { location: loc } = response.data;
          location.current = [loc.lat, loc.lng];
          localStorage.setItem(
            'pt-geolocation',
            JSON.stringify(location.current),
          );
        })
        .catch(() => {
          location.current = [];
        })
        .finally(() => {
          active.current = false;
        });
    }
    return location.current;
  }, []);
}

/**
 * Google Maps API uses session tokens to group multiple autocomplete requests
 * followed by a place details request in a single SKU billing event.
 *
 * This hook returns a session token and a function to reset it.
 */
function useSessionToken() {
  const [sessionToken, setSessionToken] = React.useState(null);

  function resetSession() {
    setSessionToken(uuid4());
  }

  return [sessionToken, resetSession];
}

export function AddressLine1Input({
  address,
  onChange,
  errorMsg,
  label,
  selectedCountry,
}) {
  const api = useQuestionnaire();
  const classes = useStyles();
  const [sessionToken, resetSession] = useSessionToken();
  const getLocation = useGetGeoLocation();
  const [options, setOptions] = React.useState([]);
  const [autoFilled, autoFilledInputProps, autoFillLabelProps] =
    useAutoFilled();

  function handleTextChange(event) {
    if (sessionToken === null) {
      // only start a session when the user starts typing
      resetSession();
    }
    onChange(event.target.value);
  }

  function parsePlace(place, value) {
    return {
      ...place,
      line1: {
        ...place.line1,
        street: value.mainText,
        country: place?.line1?.country || selectedCountry,
      },
    };
  }

  function handleAddressChange(event, value, reason) {
    if (reason === 'clear') {
      onChange(null);
      return;
    }
    if (reason === 'blur' && typeof value === 'string') {
      onChange(value);
      return;
    }
    if (value === null) {
      return;
    }
    if (reason === 'select-option') {
      const { placeId } = value;
      api.getAddressDetails({ placeId, sessionToken }).then((response) => {
        onChange(parsePlace(response.data, value));
      });
      // don't wait for the api call to finish
      // to reset the session so that the next
      // autocomplete request can start a new session
      resetSession();
    }
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        api
          .getAddressAutocomplete(request)
          .then((response) => {
            callback(response.data);
          })
          .catch(() => {
            callback([]);
          });
      }, 300),
    [],
  );

  React.useEffect(() => {
    if (!sessionToken) {
      // only start fetching once a session is started
      return undefined;
    }
    let active = true;

    if (autoFilled || address.street === '') {
      // do not fetch option if auto filled by the browser
      setOptions([]);
      return undefined;
    }

    const location = getLocation();

    if (location === null) {
      // do not fetch option while geolocation is being fetched
      return undefined;
    }

    fetch(
      {
        input: address.street,
        country: selectedCountry,
        location,
        sessionToken,
      },
      (results) => {
        if (active) {
          setOptions(results || []);
        }
      },
    );

    return () => {
      active = false;
    };
  }, [
    address.street,
    fetch,
    autoFilled,
    sessionToken,
    selectedCountry,
    getLocation,
  ]);

  const value = typeof address === 'string' ? address : address.street;

  return (
    <Autocomplete
      key="street"
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : address.street || ''
      }
      value={value || null}
      filterOptions={(x) => x}
      options={options}
      onChange={handleAddressChange}
      autoHighlight
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={handleTextChange}
          label={label}
          variant="outlined"
          fullWidth
          error={!!errorMsg}
          helperText={errorMsg}
          InputLabelProps={{ ...autoFillLabelProps }}
          inputProps={{
            ...params.inputProps,
            autoComplete: address.street ? 'chrome-off' : 'address-line1',
            'data-test': 'address-line-1',
            ...autoFilledInputProps,
          }}
        />
      )}
      renderOption={(option) => {
        const parts = parse(option.mainText, option.parts);
        return (
          <Grid
            container
            alignItems="center"
            key={option.placeId}
            data-test="address-option"
          >
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, idx) => (
                <span
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${part.text}-${idx}`}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}

              <Typography color="text.secondary">
                {option.secondaryText}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
