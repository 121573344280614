import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog } from 'components/dialog/index';
import * as api from 'services/api';
import Grid from '@material-ui/core/Grid';
import { useToast } from 'services/toast';
import { getSingleErrorFromResponse } from 'services/utils';

import { MappingAppBar } from './app_bar';
import { EmptyState } from './empty_state';
import { ListView } from './list_view';
import { MatchView } from './match_view';
import * as utils from './utils';
import { FILTERS } from './constants';

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: theme.spacing(2),
  },
}));

const useDialogStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.default,
  },
}));

export const MappingModal = ({
  open,
  onClose,
  questionnaireName,
  fundId,
  questionnaireId,
  organizationId,
}) => {
  const classes = useStyles();
  const dialogClasses = useDialogStyles();
  const { errorToast } = useToast();
  const [questionLoading, setQuestionLoading] = React.useState(false);
  const [toQuestionnaire, setToQuestionnaire] = React.useState(null);
  const [questions, setQuestions] = React.useState([]);
  const [question, setQuestion] = React.useState(null);
  const [potentialMatch, setPotentialMatch] = React.useState(null);
  const [filter, setFilter] = React.useState(FILTERS.NEEDS_ATTENTION);
  const [search, setSearch] = React.useState('');

  const currentQuestions = questions
    .filter((item) => !questionLoading[item.id])
    .filter(utils.applyListFilter(filter))
    .filter(utils.applySearch(search))
    .filter(utils.applyRemoveEmptySections);

  const updateState = (data) => {
    const result = data.questionnaire
      .filter((q) => !q.isSignature && !q.isText)
      .map(utils.transformItems(data.mapping));

    setQuestions(result);
  };

  const selectQuestion = (item) => {
    if (item) {
      setQuestion(item);

      const mappedTo = item.mapping?.mappedTo || [];
      const matches = item.mapping?.potentialMatches || [];
      const mapped = matches.find((m) => mappedTo.includes(m.id));

      if (mapped) {
        setPotentialMatch(mapped);
      } else {
        setPotentialMatch(null);
      }
    } else {
      setQuestion(null);
      setPotentialMatch(null);
    }
  };

  const handleSelectQuestionnaire = (q) => {
    setToQuestionnaire(q);
    if (q) {
      api
        .findQuestionnaireMapping({
          fundId,
          questionnaireId,
          toQuestionnaireId: q.id,
        })
        .then((res) => updateState(res.data));
    }
  };

  const findNextQuestion = () => {
    const questionsOnly = currentQuestions.filter((item) => !item.isSection);
    const idx = questionsOnly.findIndex((q) => q.id === question.id);
    const nextIdx = idx + 1;

    if (nextIdx > 0 && nextIdx < questionsOnly.length) {
      return questionsOnly[nextIdx];
    }

    return null;
  };

  const onMatch = () => {
    const qId = question.id;
    setQuestionLoading((prev) => ({ ...prev, [qId]: true }));
    selectQuestion(findNextQuestion());
    api
      .matchOrIgnoreQuestion({
        fundId,
        questionnaireId,
        toQuestionnaireId: toQuestionnaire.id,
        questionId: qId,
        mappedTo: potentialMatch.id,
      })
      .then((res) => {
        updateState(res.data);
      })
      .catch((e) => {
        const msg = getSingleErrorFromResponse(e.response);
        errorToast(`${qId}: ${msg}`);
      })
      .finally(() => {
        setQuestionLoading((prev) => ({ ...prev, [qId]: false }));
      });
  };

  const onIgnore = () => {
    const qId = question.id;
    setQuestionLoading((prev) => ({ ...prev, [qId]: true }));
    selectQuestion(findNextQuestion());
    api
      .matchOrIgnoreQuestion({
        fundId,
        questionnaireId,
        toQuestionnaireId: toQuestionnaire.id,
        questionId: question.id,
        ignored: true,
        mappedTo: null,
      })
      .then((res) => {
        updateState(res.data);
      })
      .catch((e) => {
        const msg = getSingleErrorFromResponse(e.response);
        errorToast(`${qId}: ${msg}`);
      })
      .finally(() => {
        setQuestionLoading((prev) => ({ ...prev, [qId]: false }));
      });
  };

  return (
    <Dialog
      open={open}
      fullScreen
      onClose={onClose}
      classes={dialogClasses}
      TransitionProps={{
        onExited: () => {
          setToQuestionnaire(null);
          updateState({ questionnaire: [], mappings: {} });
          selectQuestion(null);
        },
      }}
    >
      <MappingAppBar
        questionnaireName={questionnaireName}
        toQuestionnaire={toQuestionnaire}
        setToQuestionnaire={setToQuestionnaire}
        onClose={onClose}
      />

      {toQuestionnaire && questions?.length ? (
        <Grid container>
          <Grid xs={5} className={classes.grid}>
            <ListView
              question={question}
              questions={questions}
              currentQuestions={currentQuestions}
              search={search}
              onSearch={setSearch}
              filter={filter}
              onFilter={setFilter}
              onSelect={(q) => {
                selectQuestion(q);
              }}
            />
          </Grid>
          {question ? (
            <Grid xs={7} className={classes.grid}>
              <MatchView
                question={question}
                potentialMatch={potentialMatch}
                setPotentialMatch={setPotentialMatch}
                toQuestionnaire={toQuestionnaire}
                questionnaireId={questionnaireId}
                fundId={fundId}
                onMatch={onMatch}
                onIgnore={onIgnore}
              />
            </Grid>
          ) : null}
        </Grid>
      ) : (
        <EmptyState
          setToQuestionnaire={handleSelectQuestionnaire}
          organizationId={organizationId}
          questionnaireId={questionnaireId}
        />
      )}
    </Dialog>
  );
};
