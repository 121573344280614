import React from 'react';
import {
  Table,
  TableActions,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from 'components/table/index';
import { useFundEdit } from 'services/providers/fund';
import { Button, Icons } from '@passthrough/uikit';
import { SubdocListElement } from './subdoc_list_element';
import { OfferingDocListElement } from './offering_doc_list_element';

export function DocumentList({
  documents,
  subscriptionDocs,
  openNewDialog,
  onOfferingDocDelete,
  onOfferingDocArchive,
  onOfferingDocUnarchive,
  onOfferingDocEdit,
  onSubscriptionDocArchive,
  onSubscriptionDocUnarchive,
}) {
  const [fundEdit] = useFundEdit();

  const makeSubdocListElement = (subscriptionDoc) => (
    <SubdocListElement
      key={subscriptionDoc.id}
      subscriptionDoc={subscriptionDoc}
      onArchive={onSubscriptionDocArchive}
      onUnarchive={onSubscriptionDocUnarchive}
    />
  );
  const makeOfferingDocListElement = (document) => (
    <OfferingDocListElement
      key={document.id}
      document={document}
      onDelete={onOfferingDocDelete}
      onArchive={onOfferingDocArchive}
      onUnarchive={onOfferingDocUnarchive}
      onEdit={onOfferingDocEdit}
    />
  );

  return (
    <TableContainer>
      {fundEdit ? (
        <TableActions>
          <Button
            variant="primary"
            onClick={openNewDialog}
            startIcon={<Icons.Add />}
          >
            Upload document
          </Button>
        </TableActions>
      ) : null}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Upload date</TableCell>
            <TableCell>Uploaded by</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {/* Archived documents go at the bottom */}
          {subscriptionDocs
            .filter((doc) => !doc.isArchived)
            .map(makeSubdocListElement)}
          {documents
            .filter((doc) => !doc.isArchived)
            .map(makeOfferingDocListElement)}
          {subscriptionDocs
            .filter((doc) => doc.isArchived)
            .map(makeSubdocListElement)}
          {documents
            .filter((doc) => doc.isArchived)
            .map(makeOfferingDocListElement)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
