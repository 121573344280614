import React, { useState, useEffect, useRef } from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Document, Page } from 'react-pdf';

import { hasLPAnswerChanged } from 'services/utils';
import { Spinner } from 'components/spinner';
import { PDFReviewStepper } from 'components/pdf_review_stepper';
import { doesThreadExist, isThreadResolved } from 'services/thread_utils';
import { PDF_HEIGHT, PDF_WIDTH } from 'services/pdf_dimensions';
import { PDFTimeline } from 'components/pdf_timeline';

const useStyles = makeStyles(() => ({
  topDialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogActions: {
    display: 'block',
  },
  stepper: {
    backgroundColor: 'inherit',
  },
  pdfDocument: {
    position: 'absolute',
  },
  canvas: {
    position: 'absolute',
    mixBlendMode: 'multiply',
  },
}));

export function PDFReviewViewer({
  fileUrl,
  page,
  setPage,
  setNumPages,
  questions,
  pdfBoxes,
  activeQuestion,
  setActiveQuestion,
  changeEventsPerQuestion,
  priorAnswers,
}) {
  const [pdf, setPdf] = useState(null);
  const classes = useStyles();
  const theme = useTheme();
  const drawCanvasRef = useRef(null);

  useEffect(() => {
    if (pdf) {
      setNumPages(pdf.numPages);
    }
  }, [pdf]);

  function initDrawingCanvas() {
    if (!pdf) {
      // When the PDF hasn't loaded yet, don't render the canvas to avoid drawing
      // floating boxes
      return () => {};
    }
    const drawCanvas = drawCanvasRef.current;
    const ctx = drawCanvas.getContext('2d');
    const onPageBoxes = pdfBoxes.filter((box) => box.page === page);

    function getMousePos(e) {
      const bound = drawCanvas.getBoundingClientRect();
      return {
        x: Math.round(
          ((e.clientX - bound.left) / (bound.right - bound.left)) *
            drawCanvas.width,
        ),
        y: Math.round(
          ((e.clientY - bound.top) / (bound.bottom - bound.top)) *
            drawCanvas.height,
        ),
      };
    }

    function findBoxForMousePos(e) {
      const mouse = getMousePos(e);
      return onPageBoxes.find((box) => {
        const path = new Path2D();
        path.rect(box.x, box.y, box.width, box.height);
        return ctx.isPointInPath(path, mouse.x, mouse.y);
      });
    }

    function mousemove(e) {
      const box = findBoxForMousePos(e);

      if (box) {
        drawCanvas.style.cursor = 'pointer';
      } else {
        drawCanvas.style.cursor = 'default';
      }
    }

    function getQuestionFromBox(box) {
      return questions.find((q) =>
        (q.pdfBoxes || []).map((h) => h.apiId).includes(box.apiId),
      );
    }

    function click(e) {
      const box = findBoxForMousePos(e);

      if (box) {
        const question = getQuestionFromBox(box);
        if (question) {
          if (!activeQuestion || question.label !== activeQuestion.label) {
            // If we're not changing questions, no need to call this
            setActiveQuestion(question);
          }
        }
      }
    }

    function drawBox(box, fillColor, lineColor, lineWidth) {
      ctx.fillStyle = `${fillColor}`;
      ctx.fillRect(box.x, box.y, box.width, box.height);
      ctx.strokeStyle = lineColor;
      ctx.lineWidth = lineWidth;
      if (lineWidth > 0) {
        ctx.strokeRect(box.x, box.y, box.width, box.height);
      }
    }

    function getThemeColorForBox(question) {
      if (question.draftComment) {
        return theme.palette.error;
      }
      // if unresolved comment thread or updated answer
      const changedEvents = changeEventsPerQuestion[question.label];
      const priorAnswer = priorAnswers[question.label];
      const hasUnresolvedThread =
        doesThreadExist(changedEvents) && !isThreadResolved(changedEvents);
      const hasChangedAnswer =
        priorAnswer && hasLPAnswerChanged(priorAnswer, question);
      if (hasUnresolvedThread || hasChangedAnswer) {
        return theme.palette.warning;
      }
      return theme.palette.info;
    }

    function drawBoxes() {
      onPageBoxes.forEach((b) => {
        const question = getQuestionFromBox(b);
        const color = getThemeColorForBox(question);
        drawBox(
          b,
          color.fill,
          color.main,
          activeQuestion && activeQuestion.label === question.label ? 2 : 0,
        );
      });
    }

    drawBoxes();

    drawCanvas.addEventListener('click', click, true);
    drawCanvas.addEventListener('mousemove', mousemove, true);

    function cleanup() {
      ctx.clearRect(0, 0, drawCanvas.width, drawCanvas.height);
      drawCanvas.removeEventListener('click', click, true);
      drawCanvas.removeEventListener('mousemove', mousemove, true);
    }

    return cleanup;
  }

  useEffect(initDrawingCanvas, [
    pdf,
    page,
    questions,
    activeQuestion,
    pdfBoxes.map((b) => b.apiId + b.x + b.y + b.height + b.width).join('/'),
  ]);

  return (
    <>
      <div className={classes.dialogActions}>
        {Boolean(pdf && pdf.numPages) && (
          <>
            <PDFReviewStepper
              page={page}
              setPage={setPage}
              numPages={pdf.numPages}
            />
            <PDFTimeline
              page={page}
              setPage={setPage}
              numPages={pdf.numPages}
              questions={questions}
              activeQuestion={activeQuestion}
              changeEventsPerQuestion={changeEventsPerQuestion}
              priorAnswers={priorAnswers}
            />
          </>
        )}
      </div>

      <div>
        <Document
          className={classes.pdfDocument}
          file={fileUrl}
          onLoadSuccess={(p) => setPdf(p)}
          loading={<Spinner height={PDF_HEIGHT} width={PDF_WIDTH} />}
        >
          <Page
            pageNumber={page}
            loading={<Spinner height={PDF_HEIGHT} width={PDF_WIDTH} />}
            height={PDF_HEIGHT}
            width={PDF_WIDTH}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </Document>
        {pdf && (
          <canvas
            ref={drawCanvasRef}
            height={`${PDF_HEIGHT}px`}
            width={`${PDF_WIDTH}px`}
            className={classes.canvas}
          />
        )}
      </div>
    </>
  );
}
