import React from 'react';

import * as urls from 'services/urls';

import { Link, UIKitSettingsProvider } from '@passthrough/uikit';

export function InvestorCell({ task }) {
  const url = urls.diligenceAccessUrl({
    fundId: task.fundId,
    closingId: task.closingId,
    lpClosingId: task.lpClosingId,
  });

  return (
    <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
      <Link href={url}>{task.lpName}</Link>
    </UIKitSettingsProvider>
  );
}
