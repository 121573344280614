import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { formatCurrency } from 'services/utils';

const useStyles = makeStyles((theme) => ({
  commitmentWithAcceptedAmount: {
    backgroundColor: theme.palette.primary.fill,
    fontWeight: '500',
    padding: '0 4px',
    borderRadius: '4px',
  },
}));

export function AcceptedCommitment({
  commitment,
  acceptedCommitment,
  currency,
}) {
  /**
   * Commitment is what the LP offered, acceptedCommitment is what the GP accepted.
   * If there is only commitment, show the commitment amount.
   * If there is both commitment and accepted commitment, show accepted commitment
   *  in bold text and include a tooltip that shows the original commitment amount.
   */
  const classes = useStyles();

  if (commitment || acceptedCommitment) {
    if (acceptedCommitment !== null && acceptedCommitment !== commitment) {
      const title = (
        <>
          <Typography>
            Accepted {formatCurrency(acceptedCommitment, currency)}
          </Typography>
          <Typography>
            Original {formatCurrency(commitment, currency)}
          </Typography>
        </>
      );
      return (
        <Tooltip title={title}>
          <Typography
            className={classes.commitmentWithAcceptedAmount}
            display="inline"
          >
            {formatCurrency(acceptedCommitment, currency)}
          </Typography>
        </Tooltip>
      );
    }
    return <Typography>{formatCurrency(commitment, currency)}</Typography>;
  }
}
