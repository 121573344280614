import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';

import * as api from 'services/api';
import { Modal, Typography } from '@passthrough/uikit';
import { ACCESS_LEVEL_MAPPING, ACCESS_LEVELS } from './constants';

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    marginTop: theme.spacing(2),
  },
  radioLabel: {
    margin: theme.spacing(1),
  },
}));

export function ProfileCollaboratorModal({
  open,
  handleClose,
  addNewCollaborator,
}) {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [accessLevel, setAccessLevel] = useState(null);
  const [loading, setLoading] = useState(false);

  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [accessLevelError, setAccessLevelError] = useState('');

  function resetState() {
    setName('');
    setEmail('');
    setAccessLevel(null);

    setNameError('');
    setEmailError('');
    setAccessLevelError('');
  }

  function onSubmit(e) {
    e.preventDefault();

    // This mapping is necessary because null/0 does not play well with radio buttons
    const remappedAccessLevel = ACCESS_LEVEL_MAPPING[accessLevel];

    setLoading(true);
    api
      .validateCollaboratorEmail({
        name,
        email,
        accessLevel: remappedAccessLevel,
      })
      .then(() => {
        addNewCollaborator({
          name: name.trim(),
          email: email.toLowerCase().trim(),
          accessLevel: remappedAccessLevel,
        });
        handleClose();
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setNameError(error.response.data.name);
          setEmailError(error.response.data.email);
          setAccessLevelError(error.response.data.accessLevel);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      headerLabel="New collaborator"
      showCancelButton
      onSubmit={onSubmit}
      primaryButtonText="Add"
      primaryButtonLoading={loading}
      primaryButtonDisabled={!name || !email || !accessLevel}
      primaryButtonProps={{
        'data-test': 'submit-add-collaborator',
      }}
      onExited={resetState}
      size="xs"
    >
      <Typography>This will notify the new collaborator by email.</Typography>
      <TextField
        autoFocus
        required
        id="name"
        name="name"
        variant="outlined"
        label="Name"
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        error={!!nameError}
        helperText={nameError}
        fullWidth
      />
      <TextField
        required
        id="email"
        name="email"
        variant="outlined"
        label="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={!!emailError}
        helperText={emailError}
        fullWidth
        FormHelperTextProps={{ variant: 'standard' }}
      />
      <FormControl
        required
        error={!!accessLevelError}
        component="fieldset"
        fullWidth
        className={classes.radioGroup}
      >
        <FormLabel component="legend">Access level</FormLabel>
        <RadioGroup
          aria-label="access"
          name="access"
          value={accessLevel}
          onChange={(e) => {
            setAccessLevel(e.target.value);
          }}
        >
          <FormControlLabel
            value={ACCESS_LEVELS.OWNER.value}
            control={<Radio />}
            label={
              <div className={classes.radioLabel}>
                <Typography variant="body">
                  {ACCESS_LEVELS.OWNER.label}
                </Typography>
                <Typography variant="label">
                  {ACCESS_LEVELS.OWNER.helpText}
                </Typography>
              </div>
            }
            data-test="owner-choice"
          />
          <FormControlLabel
            value={ACCESS_LEVELS.ONLY_THIS_INVESTMENT.value}
            control={<Radio />}
            label={
              <div className={classes.radioLabel}>
                <Typography variant="body">
                  {ACCESS_LEVELS.ONLY_THIS_INVESTMENT.label}
                </Typography>
                <Typography variant="label">
                  {ACCESS_LEVELS.ONLY_THIS_INVESTMENT.helpText}
                </Typography>
              </div>
            }
            data-test="only-this-investment-choice"
          />
        </RadioGroup>
        {accessLevelError ? (
          <FormHelperText>{accessLevelError}</FormHelperText>
        ) : null}
      </FormControl>
    </Modal>
  );
}
