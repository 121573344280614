import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Link } from 'components/link';
import * as urls from 'services/urls';
import { UserDisplay } from '@passthrough/uikit';

import {
  ACCESS_LEVEL_EDIT,
  ACCESS_LEVEL_REVIEW,
  ACCESS_LEVEL_VIEW,
} from './constants';

const useStyles = makeStyles(() => ({
  italicized: {
    fontStyle: 'italic',
  },
  formHelperText: {
    marginLeft: 0,
  },
}));

export function GPSelectionInput({
  className,
  label,
  errorMsg,
  value,
  setValue,
  roleLabelNum,
  members,
  fundId,
  fundPermissionLevel = ACCESS_LEVEL_VIEW,
  includeBlankOption = false,
  required = false,
}) {
  const classes = useStyles();

  let filteredMembers = members;
  if (fundPermissionLevel === ACCESS_LEVEL_EDIT) {
    filteredMembers = members.filter(
      (member) => member.level === ACCESS_LEVEL_EDIT,
    );
  }
  if (fundPermissionLevel === ACCESS_LEVEL_REVIEW) {
    filteredMembers = members.filter(
      (member) =>
        member.level === ACCESS_LEVEL_REVIEW ||
        member.level === ACCESS_LEVEL_EDIT,
    );
  }

  const membersList = filteredMembers.map((member) => (
    <MenuItem key={member.id} value={member.id}>
      <UserDisplay name={member.name} email={member.email} />
    </MenuItem>
  ));

  if (includeBlankOption) {
    const emptyOption = (
      <MenuItem key="-1" value={null}>
        <div className={classes.italicized}>None</div>
      </MenuItem>
    );
    membersList.push(emptyOption);
  }

  return (
    <FormControl
      variant="outlined"
      fullWidth
      className={className}
      error={!!errorMsg}
      required={required}
    >
      <InputLabel id="gp-role-label">{label}</InputLabel>
      <Select
        label={label}
        labelId={`gp-role-label-${roleLabelNum}`}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      >
        {membersList}
      </Select>
      {errorMsg ? <FormHelperText>{errorMsg}</FormHelperText> : null}
      <FormHelperText className={classes.formHelperText}>
        Don't see the right person here?{' '}
        <Link to={urls.accessUrl({ fundId })}>Add a collaborator ›</Link>
      </FormHelperText>
    </FormControl>
  );
}
