import React from 'react';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Chip, Icons } from '@passthrough/uikit';
import { InternalNoteChip } from 'components/internal_note_chip';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'end',
    [theme.breakpoints.down('md')]: {
      alignSelf: 'start',
    },
  },
  chipContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    flexWrap: 'wrap',
    justifyContent: 'end',
    // prevent chips from being selected for copying
    userSelect: 'none',
  },
}));

export function QuestionPanelChips({
  showUpdatedChip,
  showDraftCommentChip,
  showThreadResolvedChip,
  showThreadCountChip,
  showInternalNoteChip,
  numThreadEvents,
  hasNoChips,
  className,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isMobile && hasNoChips) {
    // if on desktop, need to display empty space to keep the question text
    // aligned across questions with and without chips.
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.chipContainer}>
        {showDraftCommentChip ? (
          <Chip
            label="Unsent"
            variant="info"
            icon={<Icons.ChatOutlined />}
            size="small"
          />
        ) : null}

        {showThreadCountChip ? (
          <Chip
            label={numThreadEvents}
            variant="neutral"
            icon={<Icons.ChatOutlined />}
            size="small"
          />
        ) : null}

        {showThreadResolvedChip ? (
          <Chip
            label="Resolved"
            variant="neutral"
            icon={<Icons.ChatOutlined />}
            size="small"
          />
        ) : null}

        {showUpdatedChip ? (
          <Chip
            label="Updated"
            variant="info"
            icon={<Icons.FlagOutlined />}
            size="small"
          />
        ) : null}

        {showInternalNoteChip ? <InternalNoteChip /> : null}
      </div>
    </div>
  );
}
