import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';

const useSubsectionBodyStyles = makeStyles(() => ({
  topPadding: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '4px',
  },
  addressHelperText: {
    marginTop: '-4px',
    marginBottom: '4px',
  },
}));

export function SubsectionBody({ helperText, children }) {
  const classes = useSubsectionBodyStyles();

  return (
    <div className={classes.topPadding}>
      <FormHelperText className={classes.addressHelperText}>
        {helperText}
      </FormHelperText>
      {children}
    </div>
  );
}
