import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Button, Link, UIKitSettingsProvider } from '@passthrough/uikit';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import * as api from 'services/api';
import * as urls from 'services/urls';

import { CreateFundModal } from './create_fund_dialog_v2';
import { UploadLogoDialog } from './upload_logo_dialog';

/**
 * Note: this used to be a page in itself, but now it only shows up
 * as the contents of a modal.
 */
export function StaffTools({ closeModal }) {
  const [createFundDialogOpen, setCreateFundDialogOpen] = useState(false);
  const [uploadLogoDialogOpen, setUploadLogoDialogOpen] = useState(false);

  function EmailReportDownload() {
    const link = document.createElement('a');
    link.href = api.getEmailReport();
    link.download = 'email_domain_report.xlsx';
    link.dispatchEvent(new MouseEvent('click'));
  }

  return (
    <>
      <List component="nav" aria-label="main mailbox folders">
        <ListItem>
          <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
            <Link href="/admin/support-center/">Support command center</Link>
          </UIKitSettingsProvider>
        </ListItem>
        <ListItem>
          <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
            <Link href="/admin/">Django admin</Link>
          </UIKitSettingsProvider>
        </ListItem>
        <ListItem>
          <Link href={urls.QUESTION_TAGS_URL} onClick={closeModal}>
            Tagging center
          </Link>
        </ListItem>
        <ListItem>
          <Link href={urls.SELECT_STAFF_ORG} onClick={closeModal}>
            Select staff organization
          </Link>
        </ListItem>
        <ListItem>
          <Link href={urls.DILIGENCE_HUB_URL} onClick={closeModal}>
            Diligence hub
          </Link>
        </ListItem>
        <ListItem>
          <Link href={urls.UIKIT_URL} onClick={closeModal}>
            Uikit
          </Link>
        </ListItem>
        <ListItem>
          <Button
            variant="secondary"
            startIcon={<GetAppIcon />}
            onClick={() => EmailReportDownload()}
          >
            Generate email domains report
          </Button>
        </ListItem>
        <ListItem>
          <Button
            variant="secondary"
            startIcon={<AddIcon />}
            onClick={() => setCreateFundDialogOpen(true)}
          >
            Create fund
          </Button>
        </ListItem>
        <ListItem>
          <Button
            variant="secondary"
            startIcon={<AddIcon />}
            onClick={() => setUploadLogoDialogOpen(true)}
          >
            Upload logo
          </Button>
        </ListItem>
      </List>
      <CreateFundModal
        open={createFundDialogOpen}
        handleClose={() => setCreateFundDialogOpen(false)}
      />
      <UploadLogoDialog
        open={uploadLogoDialogOpen}
        handleClose={() => setUploadLogoDialogOpen(false)}
      />
    </>
  );
}
