import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import { TrueLink } from 'components/link';
import * as urls from 'services/urls';

const useStyles = makeStyles((theme) => ({
  link: {
    margin: theme.spacing(0, 2),
  },
  links: {
    margin: theme.spacing(3, 0),
    textAlign: 'center',
    position: 'relative',
    zIndex: '1',
  },
  copyright: {
    textAlign: 'center',
    position: 'relative',
    zIndex: '1',
  },
}));

export function Copyright() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.copyright}>
        <Typography variant="caption" className={classes.link}>
          Copyright © {new Date().getFullYear()} Passthrough, Inc.
        </Typography>
      </div>
      <div className={classes.links}>
        <TrueLink
          component="a"
          href={urls.PRIVACY_POLICY_URL}
          className={classes.link}
        >
          Privacy
        </TrueLink>
        <TrueLink component="a" href={urls.TERMS_URL} className={classes.link}>
          Terms
        </TrueLink>
        <TrueLink
          component="a"
          href={urls.COOKIE_POLICY_URL}
          className={classes.link}
        >
          Cookie policy
        </TrueLink>
      </div>
    </>
  );
}
