import * as React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import * as api from 'services/api';
import { useDocumentsDrawerActions, useDocumentsDrawerState } from './utils';
import { DrawerContent } from './contents';
import { DrawerControls } from './controls';
import { DrawerHeader } from './header';
import { DRAWER_WIDTH } from './constants';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: '33%',
    height: '100vh',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      top: 0,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: DRAWER_WIDTH,
    },
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
    paddingBottom: theme.spacing(10),
  },
}));

export function DocumentsDrawer({ lpDoc, nav }) {
  const state = useDocumentsDrawerState();
  const actions = useDocumentsDrawerActions();
  const classes = useStyles();

  React.useEffect(() => {
    if (
      state.selectedDocId &&
      !lpDoc.docs.find((doc) => doc.id === state.selectedDocId)
    ) {
      actions.selectDocument({ docId: lpDoc.docs[0].id });
    }
  }, [lpDoc.docs, state.selectedDocId]);

  React.useEffect(() => {
    if (state.open) {
      api.lpClosingGetBoxes({ lpClosingId: lpDoc.id }).then((response) => {
        actions.setCurrentQuestions({
          currentQuestions: response.data.questions,
        });
      });
    }
  }, [state.open]);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const currentDocument = lpDoc.docs.find(
    (doc) => doc.id === state.selectedDocId,
  );

  if (!currentDocument) {
    return null;
  }

  const templateFileUrl = currentDocument
    ? api.templatePdfServeURL({
        lpDocId: currentDocument?.id,
      })
    : '';
  const fileUrl = currentDocument
    ? api.filledPdfServeURL({
        lpDocId: currentDocument?.id,
      })
    : '';

  return (
    <Drawer
      open={state.open}
      onClose={() => actions.closeDrawer()}
      variant="temporary"
      anchor={isMobile ? 'bottom' : 'right'}
      classes={{ paper: classes.drawerPaper }}
    >
      <DrawerHeader
        lpDoc={lpDoc}
        fileUrl={fileUrl}
        templateFileUrl={templateFileUrl}
        fillable={currentDocument?.fillable}
        currentDocName={currentDocument?.name}
      />

      <div className={classes.main}>
        <DrawerControls fillable={currentDocument?.fillable} />
        <DrawerContent
          nav={nav}
          fileUrl={fileUrl}
          currentQuestions={state.currentQuestions}
        />
      </div>
    </Drawer>
  );
}
