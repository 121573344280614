import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Button } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  submitNoteButton: {
    alignSelf: 'end',
  },
  errorText: {
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  editButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export function AddNoteForm({
  draftNoteText,
  setDraftNoteText,
  cancel,
  isLoading,
  disabled,
  submitNote,
  buttonLabel,
  hasError,
}) {
  const classes = useStyles();

  return (
    <>
      <TextField
        multiline
        autoFocus
        minRows={4}
        maxRows={12}
        value={draftNoteText}
        onChange={(e) => {
          setDraftNoteText(e.target.value);
        }}
        variant="outlined"
        fullWidth
        label={buttonLabel}
      />
      <div className={classes.editButtons}>
        <Button
          variant="text"
          className={classes.cancelNoteButton}
          onClick={() => {
            cancel();
          }}
        >
          Cancel
        </Button>

        <Button
          variant="primary"
          loading={isLoading}
          disabled={disabled}
          className={classes.submitNoteButton}
          onClick={() => {
            submitNote();
          }}
        >
          {buttonLabel}
        </Button>
      </div>
      {hasError ? (
        <div className={classes.errorText}>An error occurred.</div>
      ) : null}
    </>
  );
}
