import * as React from 'react';

export function useAutoFilled() {
  const [autoFilled, setAutoFilled] = React.useState(false);

  const animationStartHandler = React.useCallback((e) => {
    const filled = !!e.target?.matches('*:-webkit-autofill');
    if (e.animationName === 'mui-auto-fill') {
      setAutoFilled(filled);
    }

    if (e.animationName === 'mui-auto-fill-cancel') {
      setAutoFilled(false);
    }
  }, []);

  return [
    autoFilled,
    { onAnimationStart: animationStartHandler },
    autoFilled ? { shrink: true } : {},
  ];
}
const hiddenStyles = {
  display: 'inline-block',
  position: 'absolute',
  overflow: 'hidden',
  clip: 'rect(0 0 0 0)',
  height: 1,
  width: 1,
  margin: -1,
  padding: 0,
  border: 0,
};

export function VisuallyHidden({ component: Component = 'span', ...props }) {
  return (
    <Component style={hiddenStyles} {...props} tabIndex={-1} aria-hidden />
  );
}
