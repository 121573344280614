import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

import { BgWaves } from 'components/bg_waves';
import { FormattedContainer } from 'components/formatted_container';
import { TrueLink } from 'components/link';
import { Button } from 'components/button';
import * as api from 'services/api';
import * as urls from 'services/urls';
import { Copyright } from 'pages/lp_doc/copyright';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: '540px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(20),
    marginBottom: theme.spacing(32),
    padding: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'auto',
    position: 'relative',
    zIndex: '1',
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      padding: theme.spacing(3, 4),
    },
  },
  inviteHeader: {
    textAlign: 'center',
    marginBottom: theme.spacing(7),
  },
  continueButton: {
    marginTop: theme.spacing(5),
  },
}));

export function AcceptPassthroughTerms({ onAcceptance }) {
  const [privacyAndTerms, setPrivacyAndTerms] = useState(false);
  const [privacyAndTermsError, setPrivacyAndTermsError] = useState('');
  const [complete, setComplete] = useState(false);

  const classes = useStyles();

  function handleSubmit(e) {
    e.preventDefault();

    api
      .acceptTerms({ hasAcceptedPrivacyAndTerms: privacyAndTerms })
      .then(() => {
        setComplete(true);
        onAcceptance();
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setPrivacyAndTermsError(
            error.response.data.hasAcceptedPrivacyAndTerms,
          );
        }
      });
  }

  return (
    <>
      <BgWaves />
      <FormattedContainer>
        <Paper elevation={0} className={classes.paper} variant="outlined">
          <div>
            <Typography
              component="h1"
              variant="h4"
              className={classes.inviteHeader}
            >
              Privacy and Terms
            </Typography>
            <form noValidate onSubmit={(e) => handleSubmit(e)}>
              <FormControl error={Boolean(privacyAndTermsError)}>
                <FormControlLabel
                  label={
                    <span>
                      I have read, understood and I agree to Passthrough's{' '}
                      <TrueLink
                        underline="always"
                        component="a"
                        href={urls.PRIVACY_POLICY_URL}
                      >
                        Privacy Policy
                      </TrueLink>{' '}
                      and{' '}
                      <TrueLink
                        underline="always"
                        component="a"
                        href={urls.TERMS_URL}
                      >
                        Terms & Conditions
                      </TrueLink>
                      .
                    </span>
                  }
                  control={
                    <Checkbox
                      checked={privacyAndTerms}
                      onChange={(e) => {
                        setPrivacyAndTerms(e.target.checked);
                      }}
                      name="privacy-and-terms"
                    />
                  }
                />
                {privacyAndTermsError ? (
                  <FormHelperText>{privacyAndTermsError}</FormHelperText>
                ) : null}
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                disabled={!privacyAndTerms}
                className={classes.continueButton}
                loading={complete}
              >
                Continue
              </Button>
            </form>
          </div>
        </Paper>
        <Copyright />
      </FormattedContainer>
    </>
  );
}
