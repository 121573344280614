import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
} from 'components/table/index';
import { AdapterLink } from 'components/link';

const useStyles = makeStyles((theme) => ({
  selectAllCheckbox: {
    // needs to be visible on the dark background
    // color: 'inherit !important',
  },
  hideCursor: {
    cursor: 'default',
  },
  unsortableHeaderLabel: {
    fontSize: 'inherit',
  },
  icon: {
    color: `${theme.palette.primary.main} !important`,
  },
}));

const SpeedyTableRow = React.memo(
  TableRow,
  (oldProps, newProps) => oldProps.selected === newProps.selected,
);

export function SmartTable({
  title,
  noTopMargin,
  rows,
  getKey,
  columnIds,
  sortable,
  renderHeadLabel,
  renderBodyCell,
  canEdit,
  checkboxes,
  highlight,
  selected,
  onChangeSelected,
  order,
  onChangeOrder,
  hover,
  rightAlign,
  speedy,
  enableRowClick,
  headerCellClassName,
  headerRowClassName,
  genClickUrl = () => {},
  CustomEmptyState,
  elevation,
  getSmartTableRowClass = () => '',
  rowProps,
}) {
  const classes = useStyles();
  const showCheckboxes = canEdit && checkboxes;
  const isSelected = (row) => selected.includes(getKey(row));
  const [orderBy, orderDirection] = order || [columnIds[0], 'desc'];
  const matHover = hover === undefined ? true : hover;
  const matHighlight = highlight === undefined ? true : highlight;
  const sortableColumns = sortable || [];
  const rightAlignColumns = rightAlign || [];

  function handleClick(row) {
    const key = getKey(row);
    onChangeSelected((oldSelected) => {
      if (oldSelected.includes(key)) {
        return oldSelected.filter((k) => k !== key);
      }
      return [...oldSelected, key];
    });
  }

  function handleSelectAll() {
    if (selected.length > 0) {
      onChangeSelected([]);
    } else {
      onChangeSelected(rows.map(getKey));
    }
  }

  const SmartTableRow = speedy ? SpeedyTableRow : TableRow;
  const showEmptyState = CustomEmptyState && rows.length === 0;

  return (
    <Table
      title={title}
      noTopMargin={noTopMargin}
      elevation={elevation}
      belowRowsElement={showEmptyState ? <CustomEmptyState /> : null}
    >
      <TableHead>
        <TableRow disableSidePadding className={headerRowClassName}>
          {showCheckboxes ? (
            <TableCell padding="checkbox">
              <Checkbox
                className={classes.selectAllCheckbox}
                checked={selected.length === rows.length && rows.length > 0}
                indeterminate={
                  selected.length > 0 && selected.length < rows.length
                }
                onChange={handleSelectAll}
                data-test="select-all-checkbox"
              />
            </TableCell>
          ) : null}
          {columnIds.map((columnId) => (
            <TableCell
              className={headerCellClassName}
              key={columnId}
              align={rightAlignColumns.includes(columnId) ? 'right' : 'left'}
            >
              {sortableColumns.includes(columnId) ? (
                <TableSortLabel
                  classes={{
                    icon: classes.icon,
                  }}
                  active={orderBy === columnId}
                  direction={orderDirection}
                  onClick={() => {
                    const direction =
                      orderBy === columnId && orderDirection === 'desc'
                        ? 'asc'
                        : 'desc';
                    onChangeOrder([columnId, direction]);
                  }}
                >
                  {renderHeadLabel(columnId)}
                </TableSortLabel>
              ) : (
                <span className={classes.unsortableHeaderLabel}>
                  {renderHeadLabel(columnId)}
                </span>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {rows.map((row) => (
          <SmartTableRow
            key={getKey(row)}
            disableSidePadding
            component={enableRowClick ? AdapterLink : 'div'}
            hover={matHover}
            role={enableRowClick ? 'checkbox' : ''}
            style={{ cursor: enableRowClick ? 'pointer' : '' }}
            selected={matHighlight && isSelected(row)}
            aria-checked={isSelected(row)}
            to={genClickUrl(row.id)}
            className={getSmartTableRowClass(row)}
            {...rowProps}
          >
            {showCheckboxes ? (
              <TableCell
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                padding="checkbox"
                className={classes.hideCursor}
              >
                <Checkbox
                  checked={isSelected(row)}
                  onClick={showCheckboxes ? () => handleClick(row) : null}
                />
              </TableCell>
            ) : null}
            {columnIds.map((columnId) => renderBodyCell(columnId, row))}
          </SmartTableRow>
        ))}
      </TableBody>
    </Table>
  );
}
