import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import FormHelperText from '@material-ui/core/FormHelperText';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from 'components/dialog/index';
import { Button } from 'components/button';
import * as api from 'services/api';
import { useToast } from 'services/toast';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: 'flex',
    alignSelf: 'start',
    textAlign: 'left',
  },
  actions: {
    padding: theme.spacing(2),
    gap: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
}));

export function ClientMatterDialog({
  open,
  clientMatterNumber,
  organizationId,
  fundId,
  fundName,
  onClose,
  reloadFunds,
}) {
  const classes = useStyles();
  const { toast } = useToast();
  const [formNumber, setFormNumber] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setFormNumber(clientMatterNumber);
    }
  }, [clientMatterNumber, open]);

  const onSubmit = () => {
    setIsLoading(true);
    api
      .updateClientMatterNumber({
        organizationId,
        clientMatterNumber: formNumber,
        fundId,
      })
      .then(() => {
        reloadFunds().then(() => {
          setIsLoading(false);
          onClose();
          toast('Updated client matter number');
        });
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle className={classes.dialogTitle}>
        Edit client matter number for {fundName}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          id="client-matter-number"
          variant="outlined"
          label="Client matter number"
          type="text"
          value={formNumber}
          onChange={(e) => setFormNumber(e.target.value)}
          fullWidth
        />
        <FormHelperText>Optional</FormHelperText>
      </DialogContent>
      <Divider className={classes.divider} />
      <DialogActions className={classes.actions}>
        <Button onClick={onClose} variant="outlined_thin">
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={onSubmit}
          variant="contained"
          color="primary"
          data-test="save-client-matter-number"
          loading={isLoading}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
