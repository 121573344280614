import React, { useContext } from 'react';

export const FeatureFlagContext = React.createContext({});

export function useFeatureFlags() {
  /**
   * const flags = useFeatureFlags();
   *
   * if (flags.YOUR_FLAG) {
   *   DO_NEW_STUFF;
   * } else {
   *   DO_REGULAR_STUFF;
   * }
   * */
  return useContext(FeatureFlagContext);
}
