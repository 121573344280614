import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

import { INVESTOR_TYPE_CHOICES } from './constants';

const useStyles = makeStyles((theme) => ({
  boxTypeIcon: {
    paddingRight: theme.spacing(2),
  },
  boxTypeItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  select: {
    minWidth: '200px',
    minHeight: '28px',
    padding: '14.5px',
  },
}));

export function InvestorTypeSelect({ investorType, setInvestorType }) {
  const classes = useStyles();

  return (
    <FormControl variant="outlined">
      <InputLabel id="investor-type-select-label">Investor type</InputLabel>
      <Select
        label="Investor type"
        id="investor-type-select"
        labelId="investor-type-select"
        value={investorType}
        onChange={(e) => setInvestorType(e.target.value)}
        variant="outlined"
        classes={{ root: classes.select }}
      >
        {INVESTOR_TYPE_CHOICES.map((choice) => (
          <MenuItem key={choice.value} value={choice.value}>
            <div className={classes.boxTypeItem}>
              <div className={classes.boxTypeIcon}>{choice.icon}</div>
              {choice.label}
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
