/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from 'components/alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import { filterCommentEvents } from 'services/thread_utils';
import { useWhiteLabelConfig } from 'services/providers/theme';
import { LinkButton } from 'components/button';
import { Dialog } from 'components/dialog/index';
import * as api from 'services/api';
import { QuestionPanel } from 'pages/review_v2/question_panel';
import { PDF_HEIGHT, PDF_WIDTH } from 'services/pdf_dimensions';
import { PDFReviewDropdownTextButton } from './pdf_review_dropdown';
import { PDFQuestionPreview } from './pdf_question_preview';
import { PDFReviewViewer } from './pdf_review_viewer';
import { PDFReviewDownloadButton } from './pdf_review_download';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.appbar.background,
  },
  toolBar: {
    justifyContent: 'space-between',
  },
  toolBarBottom: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
    fontWeight: 500,
  },
  root: {
    padding: theme.spacing(4, 4, 0, 4),
  },
  rootWarning: {
    marginBottom: theme.spacing(4),
  },
  tools: {
    display: 'flex',
    justifyItems: 'center',
  },
  toolBarLeftSide: {
    flexDirection: 'column',
  },
  rightSide: {
    flexGrow: 1,
    paddingLeft: theme.spacing(4),
    // Arbitrarily defined to make sure content on right is still visible
    // even when window width is low
    minWidth: '450px',
  },
  pdfContainer: {
    minHeight: `${PDF_HEIGHT}px`,
    width: `${PDF_WIDTH}px`,
  },
  paper: {
    display: 'flex',
    flex: '1 1 100%',
    padding: theme.spacing(1),
    alignItems: 'center',
    // Should match height of navigation component on top + HEIGHT in px
    // to maintain equal height between canvas and right side content
    minHeight: '846px',
  },
  emptyStateText: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  invisibleDiv: {
    width: 0,
    height: 0,
    overflow: 'hidden',
  },
}));

const useDialogStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.default,
  },
}));

export function PDFReviewDialog({
  open,
  fundName,
  handleClose,
  document: doc,
  lpName,
  pdfReviewableDocs,
  questions,
  setPdfReviewModalDocument,
  enableAnswerFormatting,
  priorAnswers,
  changeEventsPerQuestion,
  onChange,
  isInProgress,
}) {
  const classes = useStyles();
  const dialogClasses = useDialogStyles();
  const { fundId, closingId } = useParams();
  const [page, setPage] = useState(null);

  // Data we get from our API call- only contains PDF box location data
  const [questionsWithOnlyBoxes, setQuestionsWithOnlyBoxes] = useState([]);

  // Questions that have merged data from the review page and our PDF box location
  const [questionsWithBoxes, setQuestionsWithBoxes] = useState([]);
  const [pdfBoxes, setPdfBoxes] = useState();
  const [numPages, setNumPages] = useState();
  const [activeQuestion, setActiveQuestion] = useState(null);
  const focusRef = useRef(null);
  const { productName } = useWhiteLabelConfig();

  // Combined with the invisibleDiv, this works to prevent auto focusing
  // to the previewed form inputs
  useEffect(() => {
    if (focusRef.current) {
      focusRef.current.focus();
    }
  }, [focusRef, activeQuestion]);

  const getUpdatedBoxData = (apiQuestionData) => {
    let allPdfBoxes = [];
    // Attach boxes to existing questions
    const updatedBoxes = questions.map((q) => {
      const boxData = apiQuestionData.find(
        (questionWithBoxData) => questionWithBoxData.label === q.label,
      );
      if (boxData && boxData.pdfBoxes) {
        allPdfBoxes = [...allPdfBoxes, ...boxData.pdfBoxes];
        return { ...q, pdfBoxes: boxData.pdfBoxes };
      }
      return { ...q, pdfBoxes: [] };
    });

    return {
      updatedBoxes,
      allPdfBoxes,
    };
  };

  useEffect(() => {
    if (pdfBoxes?.length && !page) {
      const earliestBoxInPdf = pdfBoxes.reduce(
        (prev, current) => Math.min(prev, current.page),
        Infinity,
      );
      setPage(earliestBoxInPdf);
    } else if (!page) {
      setPage(1);
    }
  }, [page, pdfBoxes]);

  useEffect(() => {
    if (doc) {
      api
        .getReviewPDFData({ fundId, closingId, lpDocId: doc.id })
        .then((response) => {
          setQuestionsWithOnlyBoxes(response.data.questions);
        });
    }
  }, [doc, fundId, closingId]);

  useEffect(() => {
    if (questions && questionsWithOnlyBoxes.length > 0) {
      const { updatedBoxes, allPdfBoxes } = getUpdatedBoxData(
        questionsWithOnlyBoxes,
      );
      setQuestionsWithBoxes(updatedBoxes);
      setPdfBoxes(allPdfBoxes);
    }
  }, [questions, questionsWithOnlyBoxes]);

  useEffect(() => {
    // When we create a draft comment, questions from this component's props
    // get updated. The activeQuestion we have set already needs to contain
    // the updated draftComment attribute so we add that back in
    // by finding the updated question
    if (activeQuestion) {
      setActiveQuestion(
        questionsWithBoxes.find((q) => q.label === activeQuestion.label),
      );
    }
  }, [questionsWithBoxes]);

  useEffect(() => {
    // Reset the activeQuestion, page, and pdf boxes on document change
    setActiveQuestion(null);
    setPage(null);
    setPdfBoxes(null);
  }, [doc]);

  const leftRightKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowLeft':
        if (page > 1) {
          setPage(page - 1);
        }
        return;
      case 'ArrowRight':
        if (page < numPages) {
          setPage(page + 1);
        }
        break;

      default:
    }
  };

  function dialogContent() {
    if (!doc || !questions || !pdfBoxes || !page) return null;

    const fileParams = { fundId, closingId, lpDocId: doc.id };
    const fileUrl = api.filledPdfServeURLforGP(fileParams);

    return (
      /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
      <div onKeyDown={leftRightKeyDown} tabIndex="0">
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <div className={classes.toolBarLeftSide}>
              <Typography className={classes.title}>{lpName}</Typography>
              <div className={classes.toolBarBottom}>
                <PDFReviewDropdownTextButton
                  pdfReviewableDocs={pdfReviewableDocs}
                  setPdfReviewModalDocument={setPdfReviewModalDocument}
                  lpDocument={doc}
                />
                <PDFReviewDownloadButton lpDocument={doc} />
              </div>
            </div>
            <LinkButton
              color="inherit"
              variant="text"
              onClick={handleClose}
              endIcon={<CloseIcon />}
              data-test="close-review-pdf"
            >
              Close and review
            </LinkButton>
          </Toolbar>
        </AppBar>

        <div className={classes.root}>
          <div className={classes.rootWarning}>
            {isInProgress ? (
              <Alert severity="warning">
                <AlertTitle>This data is not final</AlertTitle>
                The investor has not yet completed their questionnaire and their
                answers may still change.
              </Alert>
            ) : null}

            {doc.isOffline ? (
              <Alert severity="warning">
                <AlertTitle>Signed offline</AlertTitle>
                This document was signed offline and then uploaded to{' '}
                {productName}. The answers here don't necessarily match the
                contents of the uploaded file.
              </Alert>
            ) : null}
          </div>
          <div className={classes.tools}>
            <div className={classes.pdfContainer}>
              <PDFReviewViewer
                fileUrl={fileUrl}
                page={page}
                setPage={setPage}
                setNumPages={setNumPages}
                questions={questionsWithBoxes}
                pdfBoxes={pdfBoxes}
                activeQuestion={activeQuestion}
                setActiveQuestion={setActiveQuestion}
                changeEventsPerQuestion={changeEventsPerQuestion}
                priorAnswers={priorAnswers}
              />
            </div>
            <div className={classes.rightSide}>
              <div className={classes.invisibleDiv}>
                <input ref={focusRef} />
              </div>
              {activeQuestion ? (
                <div>
                  <QuestionPanel
                    key={activeQuestion.label}
                    fundName={fundName}
                    priorAnswer={priorAnswers[activeQuestion.label]}
                    enableAnswerFormatting={enableAnswerFormatting}
                    question={activeQuestion}
                    changeEvents={changeEventsPerQuestion[activeQuestion.label]}
                    onChange={onChange}
                    showQuestionAndAnswer={false}
                    // Passing these props as always true because the PDFReviewDialog can
                    // only be open when these are true in the review page component
                    isReviewMode
                    hasFundReviewPermission
                    alwaysExpand
                  />

                  <PDFQuestionPreview
                    question={activeQuestion}
                    fundName={fundName}
                    commentEvents={filterCommentEvents(
                      changeEventsPerQuestion?.[activeQuestion.label],
                    )}
                  />
                </div>
              ) : (
                <Paper className={classes.paper}>
                  <Typography variant="h6" className={classes.emptyStateText}>
                    To get started, click an answer in the document
                  </Typography>
                </Paper>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
      classes={dialogClasses}
    >
      {dialogContent()}
    </Dialog>
  );
}
