import React, { useEffect, useState } from 'react';

import { OrganizationDashboardPage } from 'pages/organization_dashboard/index';
import * as urls from 'services/urls';
import * as api from 'services/api';
import { useMe } from 'services/providers/me';
import { PageContainer } from 'components/page_container';
import { FundTable } from 'components/fund_table/index';

function MainFundTable() {
  const organizationId = urls.useOrganizationId();
  const [commitments, setCommitments] = useState([]);
  const [funds, setFunds] = useState(null);

  function getFunds() {
    api.organizationFunds({ organizationId }).then((response) => {
      setFunds(response.data);
    });
  }

  function getCommitments() {
    const batchedFundIds = [];
    const chunkSize = 10;
    if (!funds) return;
    for (let i = 0; i < funds.length; i += chunkSize) {
      batchedFundIds.push(funds.slice(i, i + chunkSize).map((fund) => fund.id));
    }
    for (let i = 0; i < batchedFundIds.length; i += 1) {
      api
        .organizationFundListCommitment({
          organizationId,
          batchedFundIds: batchedFundIds[i],
        })
        .then((response) => {
          setCommitments((prevCommitments) => [
            ...prevCommitments,
            ...response.data,
          ]);
        });
    }
  }

  useEffect(getFunds, [organizationId]);
  useEffect(getCommitments, [funds]);

  return (
    <FundTable
      funds={funds}
      commitments={commitments}
      searchLabel="Fund name"
      enableAssignedToMeFilter
    />
  );
}

export function OrganizationFundListPage() {
  const organizationId = urls.useOrganizationId();
  const [me] = useMe();
  const { dashboardOrgs } = me;

  if (dashboardOrgs.some((org) => org.id === organizationId)) {
    return <OrganizationDashboardPage />;
  }

  return (
    <PageContainer>
      <MainFundTable />
    </PageContainer>
  );
}
