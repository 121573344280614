import React, { useEffect, useState } from 'react';
import { Alert, Modal, Typography } from '@passthrough/uikit';
import * as api from 'services/api';
import Skeleton from '@material-ui/lab/Skeleton';
import { getSingleErrorFromResponse } from 'services/utils';
import { formatDistanceToNowStrict } from 'date-fns';

export const SupportPINModal = ({ open, onClose }) => {
  const [supportPINError, setSupportPINError] = useState(null);
  const [supportPIN, setSupportPIN] = useState(null);
  const [expiresAt, setExpiresAt] = useState(null);
  const [validForText, setValidForText] = useState(null);
  const [change, setChange] = useState(0);
  useEffect(() => {
    if (!open) return;
    api
      .getSupportPIN()
      .then((response) => {
        setSupportPIN(response.data.supportPin);
        setExpiresAt(new Date(response.data.expiresAt));
      })
      .catch((error) => {
        const errorMsg = getSingleErrorFromResponse(error.response);
        setSupportPINError(errorMsg);
      });
  }, [open]);
  const onExited = () => {
    setSupportPIN(null);
    setSupportPINError(null);
    setExpiresAt(null);
    setValidForText(null);
  };

  const getValidFor = () => {
    if (!open || !expiresAt) return;
    const now = new Date();
    const differenceInMs = expiresAt - now;
    const getNewText = () => {
      if (differenceInMs < 0) return 'This PIN is expired.';
      return `This PIN is valid for ${formatDistanceToNowStrict(expiresAt, {
        roundingMethod: 'floor',
      })}.`;
    };
    setValidForText(getNewText());
  };

  const refreshValidFor = () => {
    const id = setInterval(() => {
      setChange((c) => c + 1);
    }, 1000);
    return () => clearInterval(id);
  };

  useEffect(refreshValidFor, []);
  useEffect(getValidFor, [expiresAt, change]);

  return (
    <Modal
      headerLabel="Support PIN"
      open={open}
      onClose={onClose}
      size="xs"
      footer={null}
      onExited={onExited}
    >
      {supportPINError && <Alert severity="error">{supportPINError}</Alert>}
      <Typography>
        In order to assist you, Passthrough support staff may ask for this PIN
        to verify your identity and to gain limited access to your account.
      </Typography>
      {supportPIN ? (
        <>
          <Typography
            size="extra-large"
            fontWeight={700}
            tabIndex={0}
            data-test="user-support-pin"
          >
            {supportPIN}
          </Typography>
          <Typography size="small" data-test="support-pin-valid-for">
            {validForText}
          </Typography>
        </>
      ) : (
        <>
          <Skeleton width={70} height={33} />
          <Skeleton width={200} height={21} />
        </>
      )}
    </Modal>
  );
};
