import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';

import { Button } from 'components/button';
import { Table, TableCell, TableHead, TableRow } from 'components/table/index';
import { useConfirm } from '@passthrough/uikit';
import * as api from 'services/api';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    backgroundColor: theme.palette.primary.white,
  },
}));

const useTableCellStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 2),
  },
  head: {
    backgroundColor: theme.palette.table.header,
  },
}));

export function QuestionTagsTable({
  tags,
  updateTable,
  setDeleteError,
  editButtonOnClick,
}) {
  const classes = useStyles();
  const confirm = useConfirm();
  const tableCellClasses = useTableCellStyles();

  const sortedTags = tags.sort((a, b) =>
    a.tagDisplay.localeCompare(b.tagDisplay),
  );

  function handleDelete(tagDisplay, tagId) {
    confirm({
      description: `You are about to delete tag: ${tagDisplay}.`,
      destructive: true,
    })
      .then(() => {
        api
          .deleteQuestionTag({ tagId })
          .then(() => {
            updateTable('');
            setDeleteError(null);
          })
          .catch((error) => {
            setDeleteError(error.response);
          });
      })
      .catch(() => {});
  }

  return (
    <Table noTopMargin>
      <TableHead>
        <TableRow key="header" className={classes.tableRow}>
          <TableCell key="tag" classes={tableCellClasses}>
            Tag
          </TableCell>
          <TableCell key="description" classes={tableCellClasses}>
            Description
          </TableCell>
          <TableCell key="platonicQuestionText" classes={tableCellClasses}>
            Platonic question text
          </TableCell>
          <TableCell key="edit" classes={tableCellClasses} />
          <TableCell key="delete" classes={tableCellClasses} />
        </TableRow>
      </TableHead>
      {sortedTags.map((tag) => (
        <TableRow key={tag.tagDisplay} className={classes.tableRow}>
          <TableCell key="tag" scope="row" classes={tableCellClasses}>
            {tag.tagDisplay}
          </TableCell>
          <TableCell key="description" classes={tableCellClasses}>
            {tag.tagDescription}
          </TableCell>
          <TableCell key="platonicQuestionText" classes={tableCellClasses}>
            {tag.platonicQuestionText}
          </TableCell>
          <TableCell key="edit" classes={tableCellClasses}>
            <Button variant="text" onClick={() => editButtonOnClick(tag.id)}>
              Edit
            </Button>
          </TableCell>
          <TableCell key="delete" classes={tableCellClasses}>
            <IconButton onClick={() => handleDelete(tag.tagDisplay, tag.id)}>
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
}
