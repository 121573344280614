import React, { useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, Modal, Typography, Icons } from '@passthrough/uikit';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import * as api from 'services/api';
import { useFund } from 'services/providers/fund';
import { Sheet } from 'components/sheet';
import { useToast } from 'services/toast';

const EDIT_STEP = 0;
const REVIEW_STEP = 1;

const useStyles = makeStyles((theme) => ({
  table: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));

function newRow() {
  return [
    {
      value: '',
      error: '',
    },
  ];
}

function newOrgAdminRow() {
  return [
    {
      value: '',
      error: '',
    },
    {
      value: '',
      error: '',
    },
  ];
}

function defaultGrid(fund) {
  return (
    fund?.organizationApprovedDomains.map((domain) => [
      {
        value: domain,
        error: '',
      },
    ]) || []
  );
}

function DataEditor(textFieldId) {
  return ({ cell, value, onChange, onKeyDown }) => {
    const textFieldProps = {
      id: textFieldId,
      autoFocus: true,
      variant: 'standard',
      type: 'text',
      value,
      onKeyDown,
      onChange: (e) => onChange(e.target.value),
      error: !!cell.error,
      helperText: cell.error,
      fullWidth: true,
      multiline: true,
    };

    return <TextField {...textFieldProps} />;
  };
}

function ValueViewer({ cell, value }) {
  return (
    <FormControl required error={!!cell.error} fullWidth>
      <Typography variant="body">{value}</Typography>
      <FormHelperText>{cell.error}</FormHelperText>
    </FormControl>
  );
}

function EditStepContent({ grid, setGrid, orgAdminGrid, setOrgAdminGrid }) {
  return (
    <>
      <Typography size="small">Add approved domains</Typography>
      <Sheet
        noTopMargin
        grid={grid}
        setGrid={setGrid}
        newRow={newRow}
        columns={[{ label: 'Domain' }]}
        dataEditor={DataEditor('add-domain-field')}
        valueViewer={ValueViewer}
      />
      <Typography size="small">Add organization admins</Typography>
      <Sheet
        noTopMargin
        grid={orgAdminGrid}
        setGrid={setOrgAdminGrid}
        newRow={newOrgAdminRow}
        columns={[{ label: 'Name' }, { label: 'Email' }]}
        dataEditor={DataEditor('add-org-admin-field')}
        valueViewer={ValueViewer}
      />
    </>
  );
}

function ReviewStepContent({
  errorMsg,
  approvedDomainErrorMsg,
  orgAdminErrorMsg,
  grid,
  orgAdminGrid,
}) {
  const classes = useStyles();

  return (
    <>
      {errorMsg ? (
        <Alert severity="error">
          <Typography variant="label">{errorMsg}</Typography>
        </Alert>
      ) : null}
      <Typography>
        This action cannot be undone. Passthrough staff should not be added as
        organization admin once the fund is live.
      </Typography>
      <div className={classes.table}>
        {approvedDomainErrorMsg ? (
          <Alert severity="error">
            <Typography variant="label">{approvedDomainErrorMsg}</Typography>
          </Alert>
        ) : null}
        <Typography size="small">
          The following domains will be added:
        </Typography>
        <Sheet
          noTopMargin
          grid={grid
            .filter((row) => row[0].value !== '')
            .map((row) => {
              const changedRow = cloneDeep(row);
              changedRow[0].readOnly = true;
              return changedRow;
            })}
          columns={[{ label: 'Domain' }]}
          valueViewer={ValueViewer}
          newRow={() => {}}
          setGrid={() => {}}
        />
      </div>
      <div className={classes.table}>
        {orgAdminErrorMsg ? (
          <Alert severity="error">
            <Typography variant="label">{orgAdminErrorMsg}</Typography>
          </Alert>
        ) : null}
        <Typography size="small">
          The following organization admins will be added:
        </Typography>
        <Sheet
          noTopMargin
          grid={orgAdminGrid
            .filter((row) => row[0].value !== '' && row[1].value !== '')
            .map((row) => {
              const changedRow = cloneDeep(row);
              changedRow[0].readOnly = true;
              changedRow[1].readOnly = true;
              return changedRow;
            })}
          columns={[{ label: 'Name' }, { label: 'Email' }]}
          valueViewer={ValueViewer}
          newRow={() => {}}
          setGrid={() => {}}
        />
      </div>
    </>
  );
}

export function GoLiveDialog({ open, onClose, fundId, setFundIsLive }) {
  const [fund, , refreshFund] = useFund();
  const [grid, setGrid] = useState([...defaultGrid(fund), newRow()]);
  const [orgAdminGrid, setOrgAdminGrid] = useState([newOrgAdminRow()]);
  const [errorMsg, setErrorMsg] = useState('');
  const [approvedDomainErrorMsg, setApprovedDomainErrorMsg] = useState('');
  const [orgAdminErrorMsg, setOrgAdminErrorMsg] = useState('');
  const [step, setStep] = useState(EDIT_STEP);
  const { successToast } = useToast();

  function handleGoLive() {
    setErrorMsg('');
    setApprovedDomainErrorMsg('');
    setOrgAdminErrorMsg('');
    api
      .fundGoLive({
        fundId,
        approvedDomains:
          grid.map((row) => row[0].value).filter((value) => value !== '') || [],
        organizationAdmins:
          orgAdminGrid
            .map((row) => ({ name: row[0].value, email: row[1].value }))
            .filter((values) => values.name !== '' && values.email !== '') ||
          [],
      })
      .then(() => {
        onClose();
        refreshFund((c) => c + 1);
        setFundIsLive(true);
        successToast(`${fund.name} is now live`);
      })
      .catch((e) => {
        setErrorMsg(e.response.data.message);
        setApprovedDomainErrorMsg(
          e.response.data.approvedDomains?.message || '',
        );
        setOrgAdminErrorMsg(e.response.data.orgAdmins?.message || '');
        if (e.response.data.approvedDomains) {
          const newGrid = cloneDeep(grid);
          Object.entries(e.response.data.approvedDomains).forEach((entry) => {
            const [i, domain] = entry;
            const error = domain[0];
            newGrid[i][0].error = error;
          });
          setGrid(newGrid);
        }
        if (Array.isArray(e.response.data.orgAdmins)) {
          const newOrgAdminGrid = cloneDeep(orgAdminGrid);
          e.response.data.orgAdmins.forEach((orgAdmin, i) => {
            newOrgAdminGrid[i][0].error = orgAdmin.name ? orgAdmin.name[0] : '';
            newOrgAdminGrid[i][1].error = orgAdmin.email
              ? orgAdmin.email[0]
              : '';
          });
          setOrgAdminGrid(newOrgAdminGrid);
        }
      });
  }

  useEffect(() => {
    setGrid([...defaultGrid(fund), newRow()]);
  }, [fund]);

  return (
    <>
      {fund?.organizationHasAdmin ? (
        <Modal
          open={open}
          onClose={onClose}
          headerLabel="Go live"
          subheaderLabel={fund?.name}
          primaryButtonText="Go live"
          onSubmit={handleGoLive}
        >
          <Typography>
            This will allow investors to be added to the fund.
          </Typography>
        </Modal>
      ) : (
        <Modal
          open={open}
          onClose={onClose}
          headerLabel="Go live"
          subheaderLabel={fund?.name}
          primaryButtonText={step === EDIT_STEP ? 'Next' : 'Go live'}
          primaryButtonEndIcon={
            step === EDIT_STEP ? <Icons.ArrowForward /> : null
          }
          onSubmit={() => {
            if (step === EDIT_STEP) {
              setErrorMsg('');
              setStep(REVIEW_STEP);
            } else {
              handleGoLive();
            }
          }}
          onBack={step === REVIEW_STEP ? () => setStep(EDIT_STEP) : null}
        >
          {step === EDIT_STEP ? (
            <EditStepContent
              grid={grid}
              setGrid={setGrid}
              orgAdminGrid={orgAdminGrid}
              setOrgAdminGrid={setOrgAdminGrid}
            />
          ) : (
            <ReviewStepContent
              errorMsg={errorMsg}
              approvedDomainErrorMsg={approvedDomainErrorMsg}
              orgAdminErrorMsg={orgAdminErrorMsg}
              grid={grid}
              orgAdminGrid={orgAdminGrid}
            />
          )}
        </Modal>
      )}
    </>
  );
}
