/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useQuestionnaire } from 'services/providers/questionnaire';
import { useConfirm } from '@passthrough/uikit';
import { Answers } from '../answers';
import { SAVING } from '../saved';

const useStyles = makeStyles((theme) => ({
  yesButton: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  noButton: {
    marginTop: theme.spacing(2),
  },
}));

function BooleanQuestion({
  updateLpDoc,
  answer,
  label,
  QuestionStepper,
  trueLabel,
  falseLabel,
  QuestionPromptComponent,
}) {
  const [selected, setSelected] = useState(answer);
  const hasInput = selected !== undefined && selected !== null;

  function handleSubmit(bool) {
    return updateLpDoc({ label, answer: bool, skipQuestion: hasInput });
  }

  const booleanClasses = useStyles();

  useEffect(() => {
    setSelected(answer);
  }, [label]);

  function BooleanNavigation({ navNext, ContinueButton, saving }) {
    const [buttonClicked, setButtonClicked] = useState();
    const { useSupport } = useQuestionnaire();
    const { hasDraftComment } = useSupport();
    const confirm = useConfirm();

    const onBooleanSelect = (bool) => {
      setButtonClicked(bool);
      handleSubmit(bool).then(() => navNext());
      setSelected(bool);
    };

    const handleBooleanSelect = (bool) => {
      if (hasDraftComment) {
        confirm({
          title: 'Discard unsent comment?',
          description: 'Your comment has not been sent.',
          destructive: true,
          confirmationText: 'Discard',
        })
          .then(() => {
            onBooleanSelect(bool);
          })
          .catch(() => {});
      } else {
        onBooleanSelect(bool);
      }
    };

    return (
      <>
        <ContinueButton
          id="yes"
          size="xl"
          variant={selected === true ? 'contained' : 'outlined'}
          fullWidth
          selected={selected === true}
          className={booleanClasses.yesButton}
          loading={saving === SAVING && buttonClicked === true}
          onClick={() => handleBooleanSelect(true)}
        >
          {trueLabel}
        </ContinueButton>
        <ContinueButton
          id="no"
          size="xl"
          variant={selected === false ? 'contained' : 'outlined'}
          fullWidth
          selected={selected === false}
          loading={saving === SAVING && buttonClicked === false}
          className={booleanClasses.noButton}
          onClick={() => handleBooleanSelect(false)}
        >
          {falseLabel}
        </ContinueButton>
      </>
    );
  }

  return (
    <>
      <QuestionPromptComponent />
      <Answers>
        <QuestionStepper
          handleSubmit={handleSubmit}
          disabled={false}
          ReplacementNavigation={BooleanNavigation}
          hasAnswerChanged={false}
          hasInput={hasInput}
        />
      </Answers>
    </>
  );
}

export function YesOrNoQuestion(props) {
  return <BooleanQuestion {...props} trueLabel="Yes" falseLabel="No" />;
}

export function TrueOrFalseQuestion(props) {
  return <BooleanQuestion {...props} trueLabel="True" falseLabel="False" />;
}
