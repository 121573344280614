import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography, useConfirm } from '@passthrough/uikit';
import Grow from '@material-ui/core/Grow';

import { isLongAnswer, hasLPAnswerChanged } from 'services/utils';
import * as api from 'services/api';

import { ActivityCard } from 'components/activity_card_v2';
import { useToast } from 'services/toast';
import {
  countNumCommentEvents,
  countNumInternalNoteEvents,
  doesThreadExist,
  isThreadResolved,
} from 'services/thread_utils';
import { QuestionnaireActivityTimeline } from './activity_timeline_v2/questionnaire_activity_timeline';
import { QuestionPanelChips } from './question_panel_chips';
import { QuestionAnswer } from './question_answer';
import { NoteBox } from './note_box';

const useStyles = makeStyles((theme) => ({
  summaryContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2, 1),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  summary: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexBasis: '80%',
    alignItems: 'center',
    gap: theme.spacing(1, 2),
    [theme.breakpoints.down('md')]: {
      alignItems: 'unset',
      flexDirection: 'column',
    },
  },
  longSummary: {
    flexBasis: '80%',
    alignItems: 'unset',
    flexDirection: 'column',
  },
  chipsFlex: {
    flex: '1 1 20%',
  },
  questionTextFlex: {
    flex: '1 1 70%',
  },
  answerFlex: {
    flex: '1 1 30%',
  },
  accordionSummary: {
    '& .Mui-expanded': {
      marginBottom: '0px',
    },
    padding: theme.spacing(0, 3),
    // Allow users to highlight the question and answer text for copying
    userSelect: 'auto',
  },
  suppressPointer: {
    cursor: 'default !important',
  },
  hideChipSpace: {
    minHeight: '0px !important',
    height: '0px !important',
  },
  accordionAccent: {
    borderLeft: `4px solid ${theme.palette.text.secondary}`,
    '&.MuiAccordion-root.Mui-expanded': {
      borderLeft: `4px solid ${theme.palette.text.secondary} !important`,
    },
  },
}));

function isLongAnswerDisplay(question, priorAnswer) {
  let isLong = isLongAnswer(question);

  if (priorAnswer) {
    isLong =
      isLong ||
      isLongAnswer({ answerType: question.answerType, answer: priorAnswer });
  }

  return isLong;
}

export function QuestionPanel({
  fundName,
  question,
  onChange,
  isReviewMode,
  hasFundReviewPermission,
  changeEvents,
  priorAnswer,
  enableAnswerFormatting,
  alwaysExpand,
  showQuestionAndAnswer,
  showFullQuestionnaire,
  lpClosing,
}) {
  const classes = useStyles();
  const confirm = useConfirm();
  const { fundId, closingId, lpClosingId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const note = question.draftComment;
  const [expanded, setExpanded] = useState(Boolean(note || alwaysExpand));
  const [errorMsg, setErrorMsg] = useState('');

  const { toast } = useToast();

  const { label } = question;
  const threadIsResolved =
    doesThreadExist(changeEvents) && isThreadResolved(changeEvents);
  const hasUnresolvedThread =
    doesThreadExist(changeEvents) && !isThreadResolved(changeEvents);
  const showChangedAnswers =
    hasLPAnswerChanged(priorAnswer, question) && isReviewMode;
  const showUpdatedChip = showChangedAnswers && enableAnswerFormatting;
  const numThreadEvents = countNumCommentEvents(changeEvents);
  const hasInternalNotes = Boolean(countNumInternalNoteEvents(changeEvents));
  const showThreadSizeCount = numThreadEvents > 0 && !threadIsResolved;
  const isLong = isLongAnswerDisplay(question, priorAnswer);

  const showDraftCommentChip = question.draftComment;
  const showThreadResolvedChip = !question.draftComment && threadIsResolved;
  const showThreadCountChip = !question.draftComment && showThreadSizeCount;
  const showInternalNoteChip = hasInternalNotes && !threadIsResolved;

  const hasNoChips =
    !showUpdatedChip &&
    !showDraftCommentChip &&
    !showThreadResolvedChip &&
    !showThreadCountChip &&
    !showInternalNoteChip;

  function setExpandedState(expandedState) {
    if (alwaysExpand) {
      return;
    }
    setExpanded(expandedState);
  }

  function resolveThread() {
    setIsLoading(true);
    setErrorMsg('');

    const props = {
      fundId,
      closingId,
      lpClosingId,
      label: question.label,
    };
    api
      .resolveThreadLpClosing(props)
      .then(onChange)
      .catch(() => {
        setIsLoading(false);
        setErrorMsg('Error resolving thread');
      });
  }

  function handleResolveThread() {
    if (note) {
      confirm({
        title: 'Resolve this thread and delete the saved comment draft?',
        description:
          'The saved comment draft will be deleted. Do you want to resolve this thread?',
        confirmationText: 'Yes, resolve and delete',
        destructive: true,
      })
        .then(resolveThread)
        .catch(() => {});
    } else {
      resolveThread();
    }
  }

  function handleReopenThread() {
    setIsLoading(true);
    setErrorMsg('');

    const props = {
      fundId,
      closingId,
      lpClosingId,
      label: question.label,
    };

    api
      .reopenThreadLpClosing(props)
      .then(onChange)
      .catch(() => {
        setIsLoading(false);
        setErrorMsg('Error reopening thread');
      });
  }

  function handleSaveDraftNote(draftNote) {
    setIsLoading(true);
    setErrorMsg('');

    const props = {
      fundId,
      closingId,
      lpClosingId,
      label,
      note: draftNote,
    };
    api
      .saveNoteLpClosing(props)
      .then(onChange)
      .catch(() => {
        setIsLoading(false);
        setErrorMsg('Error saving note to investor');
      });
  }

  function handleSaveInternalNote(internalNote) {
    setIsLoading(true);
    setErrorMsg('');

    const props = {
      fundId,
      closingId,
      lpClosingId,
      label: question.label,
      internalNote,
    };

    api
      .saveQuestionnaireInternalNote(props)
      .then(() => {
        toast('Internal note added');
        onChange();
      })
      .catch(() => {
        setIsLoading(false);
        setErrorMsg('Error saving internal note');
      });
  }

  function handleDeleteDraftNote() {
    setIsLoading(true);
    const props = {
      fundId,
      closingId,
      lpClosingId,
      label,
    };
    api
      .deleteNoteLpClosing(props)
      .then(onChange)
      .catch(() => {
        setIsLoading(false);
        setErrorMsg('Error deleting note');
      });
  }

  useEffect(() => {
    // We're not done loading until our new text is reflected in our props.
    setIsLoading(false);

    // Refresh the expanded state when the events or draft comment update
    setExpandedState(Boolean(note));
  }, [note, changeEvents?.length]);

  useEffect(() => {
    // change events are gathered within an effect, so cannot rely on them
    // being available on initial render and must also use an effect here
    if (!isThreadResolved(changeEvents)) {
      setExpanded(true);
    }
  }, [changeEvents]);

  return (
    <Grow
      appear={false}
      in={
        question.isAsked ||
        showFullQuestionnaire ||
        (!lpClosing.questionnaireApproved && hasUnresolvedThread)
      }
      timeout={500}
      unmountOnExit
    >
      <Accordion
        key={question.label}
        expanded={expanded}
        onChange={(e, exp) => {
          setExpandedState(exp);
        }}
        elevation={2}
        className={clsx({ [classes.accordionAccent]: !question.isAsked })}
      >
        <AccordionSummary
          expandIcon={alwaysExpand ? null : <ExpandMoreIcon />}
          aria-controls={`${question.label}-content`}
          id={`${question.label}-header`}
          data-test={`question-${question.label}`}
          className={clsx(classes.accordionSummary, {
            [classes.suppressPointer]: alwaysExpand,
            [classes.hideChipSpace]: hasNoChips && alwaysExpand,
          })}
        >
          <div className={classes.summaryContainer}>
            {showQuestionAndAnswer ? (
              <div
                className={clsx(classes.summary, {
                  [classes.longSummary]: isLong,
                })}
              >
                <Typography
                  variant="body"
                  size="small"
                  color="text.secondary"
                  className={classes.questionTextFlex}
                >
                  {question.question}
                </Typography>

                <QuestionAnswer
                  question={question}
                  priorAnswer={priorAnswer}
                  enableAnswerFormatting={enableAnswerFormatting}
                  isReviewMode={isReviewMode}
                  className={classes.answerFlex}
                />
              </div>
            ) : null}

            <QuestionPanelChips
              showDraftCommentChip={showDraftCommentChip}
              showThreadCountChip={showThreadCountChip}
              showThreadResolvedChip={showThreadResolvedChip}
              showInternalNoteChip={showInternalNoteChip}
              showUpdatedChip={showUpdatedChip}
              numThreadEvents={numThreadEvents}
              hasNoChips={hasNoChips}
              className={classes.chipsFlex}
            />
          </div>
        </AccordionSummary>

        <ActivityCard
          showDivider={!alwaysExpand || !hasNoChips}
          question={question}
          showFullQuestionnaire={showFullQuestionnaire}
          hasUnresolvedThread={hasUnresolvedThread}
          isLoading={isLoading}
          errorMsg={errorMsg}
          TimelineComponent={
            changeEvents?.length > 0 ? (
              <QuestionnaireActivityTimeline
                fundName={fundName}
                question={question}
                events={changeEvents}
                canInteract={hasFundReviewPermission}
                isLoading={isLoading}
                handleResolveThread={handleResolveThread}
                handleReopenThread={handleReopenThread}
              />
            ) : null
          }
          NoteBoxComponent={
            <NoteBox
              fundName={fundName}
              hasFundReviewPermission={hasFundReviewPermission}
              existingDraftNote={question.draftComment}
              canAddDraftNote={question.isAsked && isReviewMode}
              onDeleteDraftNote={handleDeleteDraftNote}
              onSaveDraftNote={handleSaveDraftNote}
              onSaveInternalNote={handleSaveInternalNote}
            />
          }
        />
      </Accordion>
    </Grow>
  );
}
