import React, { useState, useEffect } from 'react';
import {
  Modal,
  Alert,
  Typography,
  Icons,
  Button,
  useConfirm,
} from '@passthrough/uikit';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import NumberFormat from 'react-number-format';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import cloneDeep from 'lodash/cloneDeep';

import { CurrencyTextField } from 'components/currency_text_field';
import { TableFooter, TableRow, TableCell } from 'components/table/index';
import { Sheet } from 'components/sheet';
import { useToast } from 'services/toast';
import * as api from 'services/api';
import { useCurrency } from 'services/providers/currency';
import { Callout } from 'components/Callout';
import { getJurisdictionDisplayName } from 'components/jurisdiction_select';

function newPlaceholderRow() {
  return [
    { value: '', error: '' },
    {
      value: '',
      error: '',
      placeholder: 'e.g. contact@gmail.com, contact2@gmail.com...',
    },
    { value: '', error: '' },
  ];
}

function newRow() {
  return [
    { value: '', error: '' },
    { value: '', error: '' },
    { value: '', error: '' },
  ];
}

function getInitialGrid() {
  return [newPlaceholderRow(), newRow(), newRow()];
}

const useStyles = makeStyles((theme) => ({
  emailHeader: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(1),
  },
  customFooter: {
    backgroundColor: theme.palette.background.default,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  addCommitmentLabel: {
    color: theme.palette.primary.black,
    padding: '2px 0px',
  },
  tooltipIcon: {
    fontSize: '1.125rem',
  },
  jurisdictionHelpText: {
    display: 'inline-flex',
    alignItems: 'center',
    columnGap: '1px',
  },
}));

const NAME_INDEX = 0;
const EMAIL_INDEX = 1;
const COMMITMENT_INDEX = 2;

function Footer({ checked, onChange }) {
  const classes = useStyles();

  return (
    <TableFooter className={classes.customFooter}>
      <TableRow component="tr">
        <TableCell colSpan={checked ? 4 : 3} align="right" component="td">
          <FormControlLabel
            className={classes.addCommitmentLabel}
            control={
              <Switch
                color="primary"
                checked={checked}
                onChange={(ev, value) => onChange(value)}
              />
            }
            label={
              <Typography variant="label" size="medium">
                Add commitment
              </Typography>
            }
          />
        </TableCell>
      </TableRow>
    </TableFooter>
  );
}

function DataEditor({ cell, value, onChange, onKeyDown, col }) {
  const textFieldProps = {
    id: 'add-investors-field',
    autoFocus: true,
    variant: 'standard',
    type: 'text',
    value,
    onKeyDown,
    onChange: (e) => onChange(e.target.value),
    error: !!cell.error,
    helperText: cell.error,
    fullWidth: true,
    multiline: true,
  };

  const { symbol } = useCurrency();

  switch (col) {
    case COMMITMENT_INDEX:
      return <CurrencyTextField currencySymbol={symbol} {...textFieldProps} />;

    default:
      return <TextField {...textFieldProps} />;
  }
}

const useValueStyles = makeStyles(() => ({
  currency: {
    textAlign: 'right',
    '&::before': {
      content: (props) => `"${props.currencyDisplay}"`,
      float: 'left',
    },
  },
}));

function ValueViewer({ cell, value, col }) {
  const { symbol } = useCurrency();
  const currencyDisplay = symbol;

  const classes = useValueStyles({ currencyDisplay });

  const showCurrency = col === COMMITMENT_INDEX && !!value;

  return (
    <FormControl required error={!!cell.error} fullWidth>
      {showCurrency ? (
        <div className={classes.currency}>
          <Typography variant="body">
            <NumberFormat
              thousandsGroupStyle="thousand"
              decimalSeparator="."
              displayType="text"
              type="text"
              thousandSeparator
              allowLeadingZeros={false}
              allowNegative={false}
              decimalScale={2}
              value={value}
            />
          </Typography>
        </div>
      ) : (
        <Typography variant="body">{value}</Typography>
      )}
      <FormHelperText>{cell.error}</FormHelperText>
    </FormControl>
  );
}

export function NewInvestorClosingDialog({
  open,
  externalSignupLink,
  enableCommitmentPrefill,
  handleClose,
  onChange,
  diligenceClosingJurisdiction,
}) {
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { fundId, closingId } = useParams();
  const { toast } = useToast();
  const [grid, setGrid] = useState(getInitialGrid());
  const [errorMsg, setErrorMsg] = useState(null);
  const [showCommitment, setShowCommitment] = useState(false);
  const { code } = useCurrency();
  const confirm = useConfirm();
  const jurisdiction = getJurisdictionDisplayName(diligenceClosingJurisdiction);

  const columns = [
    { label: 'Name' },
    {
      label: (
        <span className={classes.emailHeader}>
          Emails
          <Tooltip
            placement="top"
            title={
              <Typography variant="label" size="medium">
                Add multiple collaborators by using comma-separated emails.
              </Typography>
            }
          >
            <InfoOutlinedIcon className={classes.tooltipIcon} />
          </Tooltip>
        </span>
      ),
    },
    { label: `Commitment (${code})` },
  ];

  const gridForSheet = showCommitment
    ? grid
    : grid.map((row) => row.slice(0, 2));
  const columnsForSheet = showCommitment ? columns : columns.slice(0, 2);

  const investors = grid
    .map((row) => {
      const investor = {
        name: row[NAME_INDEX].value,
        emails: row[EMAIL_INDEX].value.split(',').map((s) => s.trim()),
      };
      // only send commitment data to the server if the
      // showCommitment switch is checked, even if there's
      // commitment data in grid
      if (showCommitment && !!row[COMMITMENT_INDEX].value) {
        investor.commitment = parseFloat(
          // the regex removes anything that isn't a digit or a '.'
          row[COMMITMENT_INDEX].value.replace(/[^\d.]/g, ''),
        );
      }
      return investor;
    })
    .filter((i) => i.name || i.emails[0] || i.commitment);

  function discardChanges() {
    handleClose();
  }

  function handleSetGrid(newGrid) {
    if (!showCommitment) {
      // save any existing commitment values in case the user
      // misclicked the commitment switch after adding values
      const gridToSet = [];
      for (let i = 0; i < newGrid.length; i += 1) {
        gridToSet.push(
          newGrid[i].length === 2
            ? newGrid[i].concat([grid[i][COMMITMENT_INDEX]])
            : [...newGrid[i]],
        );
      }

      setGrid(gridToSet);
      return;
    }
    setGrid(newGrid);
  }

  function scrollToFirstError(newGrid) {
    // Find the row with the first error
    const errorIndex = newGrid.findIndex((row) =>
      row.some((cell) => !!cell.error),
    );

    if (errorIndex >= 0) {
      const element = document.getElementById(`row-number-${errorIndex + 1}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }

  function handleInlineErrors(investorErrors) {
    // find the right index into grid and change it
    const newGrid = cloneDeep(grid);

    // The index not including empty rows.
    // This is what the server knows about.
    let index = 0;

    for (let i = 0; i < newGrid.length; i += 1) {
      const [name, emails, commitment] = newGrid[i];
      if (name.value || emails.value || (showCommitment && commitment.value)) {
        name.error = investorErrors[index].name;
        commitment.error = investorErrors[index].commitment;
        if (investorErrors[index].emails !== undefined) {
          // Just show the first email error
          const [firstError] = Object.values(investorErrors[index].emails);
          emails.error = firstError;
        } else if (investorErrors[index].nonFieldErrors !== undefined) {
          const [firstError] = investorErrors[index].nonFieldErrors;
          emails.error = firstError;
        }
        index += 1;
      }
    }

    setGrid(newGrid);
    scrollToFirstError(newGrid);
  }

  function pollForResults(taskId) {
    api
      .getCreateLPClosingsTaskResult({ fundId, closingId, taskId })
      .then((response) => {
        if (!response.data.isCompleted) {
          setTimeout(() => {
            pollForResults(taskId);
          }, 1000);
        } else if (response.data.error) {
          const { error } = response.data;
          setIsSubmitting(false);

          if (typeof error === 'string') {
            setErrorMsg(error);
          } else if (error.investors) {
            handleInlineErrors(error.investors);
          } else {
            setErrorMsg('An error occurred while adding investors.');
          }
        } else {
          setIsSubmitting(false);
          onChange();
          handleClose();
          const plural = investors.length > 1 ? 's' : '';
          toast(`Added ${investors.length} investor${plural}.`);
        }
      })
      .catch(() => {
        setIsSubmitting(false);
        setErrorMsg('An error occurred while adding investors.');
      });
  }

  function handleSubmit() {
    setIsSubmitting(true);

    setErrorMsg(null);
    // also clear all preexisting cell errors prior to submitting
    const noErrorsGrid = cloneDeep(grid);
    for (let i = 0; i < noErrorsGrid.length; i += 1) {
      const [nameTuple, emailTuple, commitmentTuple] = noErrorsGrid[i];
      nameTuple.error = '';
      emailTuple.error = '';
      commitmentTuple.error = '';
    }
    setGrid(noErrorsGrid);

    api
      .createLpClosings({ fundId, closingId, investors })
      .then((response) => {
        if (response?.data.taskId) {
          setTimeout(() => {
            pollForResults(response?.data.taskId);
          }, 500);
        } else {
          setIsSubmitting(false);
          onChange();
          handleClose();

          const plural = investors.length > 1 ? 's' : '';
          toast(`Added ${investors.length} investor${plural}.`);
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        if (error.response?.status === 400) {
          if (Array.isArray(error.response.data)) {
            setErrorMsg(error.response.data[0]);
            return;
          }

          if (error.response.data.detail) {
            setErrorMsg(error.response.data.detail);
            return;
          }

          handleInlineErrors(error.response.data.investors);
        }
      });
  }

  function handleDiscardConfirmation() {
    confirm({
      title: 'Discard unsaved changes?',
      description: 'Your changes have not been saved.',
      destructive: true,
      confirmationText: 'Discard',
    })
      .then(() => {
        discardChanges();
      })
      .catch(() => {});
  }

  // Clear validation errors if showCommitment changes
  useEffect(() => {
    if (grid.some((row) => row.some((cell) => !!cell.error))) {
      const newGrid = grid.map((row) =>
        row.map((cell) => ({ value: cell.value, error: '' })),
      );

      setGrid(newGrid);
    }
  }, [showCommitment]);

  useEffect(() => {
    const cleanup = () => {
      if (!open) {
        setShowCommitment(false);
        setErrorMsg(null);
        setGrid(getInitialGrid());
      }
    };
    return cleanup;
  }, [open]);

  return (
    <Modal
      size="md"
      headerLabel="Add investors"
      open={open}
      primaryButtonText="Add"
      primaryButtonDisabled={investors.length === 0}
      primaryButtonLoading={isSubmitting}
      showCancelButton={!isSubmitting}
      onClose={() => {
        if (!isSubmitting) {
          if (investors.length > 0) {
            handleDiscardConfirmation();
          } else {
            discardChanges();
          }
        }
      }}
      onSubmit={handleSubmit}
      primaryButtonProps={{
        'data-test': 'add',
      }}
    >
      {externalSignupLink ? (
        <Callout
          header="Investor signup link"
          message="Anyone with this link can create a new investor in this closing."
          action={
            <Button
              variant="secondary"
              startIcon={<Icons.ContentCopy color="primary" />}
              onClick={() => {
                navigator.clipboard.writeText(externalSignupLink);
                toast('Link copied');
              }}
            >
              Copy link
            </Button>
          }
        />
      ) : null}

      <Typography variant="body" size="small" color="text.primary">
        Investor name will appear in the invitation to investors. They must be
        unique and can be edited later.
      </Typography>

      <Alert severity="info" skipTypography>
        <Typography variant="body" size="small" color="text.primary">
          You can copy and paste from Excel or Google Sheets.
        </Typography>
      </Alert>

      {errorMsg ? <Alert severity="error">{errorMsg}</Alert> : null}

      <Sheet
        noTopMargin
        grid={gridForSheet}
        setGrid={handleSetGrid}
        newRow={newRow}
        columns={columnsForSheet}
        dataEditor={DataEditor}
        valueViewer={ValueViewer}
        footer={
          enableCommitmentPrefill ? (
            <Footer
              checked={showCommitment}
              onChange={(selected) => setShowCommitment(selected)}
            />
          ) : null
        }
      />

      {jurisdiction ? (
        <Typography variant="body" size="small" color="text.primary">
          When investors join this closing, they will also receive{' '}
          <span className={classes.jurisdictionHelpText}>
            diligence
            <Tooltip
              title={
                <Typography variant="label" size="medium">
                  Investors identify their beneficial ownership structure and
                  are screened to mitigate any potential risks.
                </Typography>
              }
            >
              <Icons.InfoOutlined
                fontSize="inherit"
                sx={{ color: 'text.secondary' }}
              />
            </Tooltip>
          </span>{' '}
          questions for the {jurisdiction} jurisdiction.
        </Typography>
      ) : null}
      <Typography variant="body" size="small" color="text.primary">
        New investors are not notified until you send the invitations.
      </Typography>
    </Modal>
  );
}
