export const STATIC_NO_MATCH_EXPLANATIONS = [
  'Legal name',
  'Date of birth / age',
  'Address',
  'Photograph',
  'Race',
  'Height',
  'Country',
  'Biological sex',
  'Date of incorporation',
  'Entity Type',
  'Country of incorporation',
  'Registration number',
  'Gender',
  'TIN',
  'Eye color',
  'Country of residence',
  'Country of domicile',
  'Country of headquarters',
];
