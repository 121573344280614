import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';

import { buildTypography } from '@passthrough/uikit';
import { buildTheme, buildOverrides } from './theme';

const defaultFontFamily = '"Centra No1", "Helvetica", "Arial", sans-serif';

const uikitPriorityList = [
  'primary.main',
  'primary.light',
  'primary.border',
  'primary.dark',
  'primary.fill',
  'secondary.main',
  'secondary.light',
  'secondary.border',
  'secondary.dark',
  'secondary.fill',
  'link',
];

export const uikitPaletteMapping = {
  priority: uikitPriorityList,
  'primary.main': ['primary.main', 'text.link', 'secondary.main'],
  'primary.light': ['primary.light', 'secondary.light'],
  'primary.border': ['primary.border', 'secondary.border'],
  'primary.dark': ['primary.dark', 'secondary.dark'],
  'primary.fill': ['primary.fill', 'secondary.fill'],
  'secondary.main': ['secondary.main'],
  'secondary.light': ['secondary.light'],
  'secondary.border': ['secondary.border'],
  'secondary.dark': ['secondary.dark'],
  'secondary.fill': ['secondary.fill'],
  link: ['text.link'],
};

const v4PriorityList = [
  'primary.main',
  'primary.dark',
  'primary.light',
  'primary.border',
  'primary.fill',
  'primary.contrastText',
  'secondary.main',
  'secondary.dark',
  'secondary.light',
  'secondary.border',
  'secondary.fill',
  'secondary.contrastText',
  'link',
  'darkModeLink',
  'investorClosingStatus.unsent',
  'investorClosingStatus.notStarted',
  'investorClosingStatus.sent',
  'investorClosingStatus.signed',
  'investorClosingStatus.approved',
  'investorClosingStatus.executed',
];

export const v4PaletteMapping = {
  priority: v4PriorityList,
  'primary.main': ['primary.main', 'text.link', 'secondary.main'],
  'primary.dark': ['primary.dark', 'secondary.dark'],
  'primary.light': ['primary.light', 'secondary.light'],
  'primary.border': ['primary.border', 'secondary.border'],
  'primary.fill': ['primary.fill', 'secondary.fill'],
  'primary.contrastText': ['primary.contrastText', 'secondary.contrastText'],
  'secondary.main': ['secondary.main'],
  'secondary.dark': ['secondary.dark'],
  'secondary.light': ['secondary.light'],
  'secondary.border': ['secondary.border'],
  'secondary.fill': ['secondary.fill'],
  'secondary.contrastText': ['secondary.contrastText'],
  link: ['text.link', 'drawer.link'],
  darkModeLink: ['drawer.link'],
  'investorClosingStatus.unsent': ['investorClosingStatus.unsent'],
  'investorClosingStatus.notStarted': [
    'investorClosingStatus.notStarted',
    'investorClosingStatus.sent',
    'status.waiting',
    'progressBar.pending',
  ],
  'investorClosingStatus.sent': [
    'investorClosingStatus.sent',
    'status.waiting',
    'progressBar.pending',
  ],
  'investorClosingStatus.signed': [
    'investorClosingStatus.signed',
    'investorClosingStatus.approved',
    'status.signed',
  ],
  'investorClosingStatus.approved': [
    'investorClosingStatus.approved',
    'status.signed',
  ],
  'investorClosingStatus.executed': [
    'investorClosingStatus.executed',
    'investorClosingStatus.closedEmailSent',
    'progressBar.fullyExecuted',
  ],
};

export function applyMapping(customColors, basePalette, mapping) {
  const newPalette = cloneDeep(basePalette);

  const priorityList = mapping.priority;
  priorityList.forEach((sourcePath) => {
    const color = get(customColors, sourcePath);
    const targetPaths = mapping[sourcePath];

    if (color) {
      targetPaths.forEach((path) => {
        set(newPalette, path, color);
      });
    }
  });

  return newPalette;
}

function getV4Theme({
  baseV4Theme,
  customColors,
  fontFamily,
  headingFontFamily,
  hasCustomFonts,
}) {
  if (!customColors && !hasCustomFonts) {
    return baseV4Theme;
  }

  const newPalette = applyMapping(
    customColors,
    baseV4Theme.palette,
    v4PaletteMapping,
  );

  return buildTheme({
    palette: newPalette,
    typography: buildTypography(fontFamily, headingFontFamily),
    themeName: 'Custom Outer Space Lochmara Oryx V2',
    overrides: buildOverrides(newPalette),
  });
}

function getUikitPalette(baseUikitPalette, customColors) {
  if (!customColors) {
    return baseUikitPalette;
  }

  const newPalette = applyMapping(
    customColors,
    baseUikitPalette,
    uikitPaletteMapping,
  );

  return newPalette;
}

export function applyCustomTheme(themeConfig, baseUikitPalette, baseV4Theme) {
  const fontFamily = themeConfig.font?.main || defaultFontFamily;
  const headingFontFamily =
    themeConfig.font?.heading || themeConfig.font?.main || defaultFontFamily;
  const hasCustomFonts =
    !!themeConfig.font?.main || !!themeConfig.font?.heading;

  const palette = getUikitPalette(baseUikitPalette, themeConfig.colors);
  const v4Theme = getV4Theme({
    baseV4Theme,
    customColors: themeConfig.colors,
    fontFamily,
    headingFontFamily,
    hasCustomFonts,
  });

  return {
    fontFamily,
    headingFontFamily,
    palette,
    v4Theme,
  };
}
