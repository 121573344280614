import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Button, Icons, InternalChip } from '@passthrough/uikit';
import {
  Table,
  TableActions,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from 'components/table/index';
import { useFundEdit } from 'services/providers/fund';
import { useMe } from 'services/providers/me';

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },
  internalChip: {
    marginRight: theme.spacing(1),
    marginLeft: -theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
}));

const labels = {
  VIEW: 'View only',
  REVIEW: 'Review',
  EDIT: 'Edit',
};

const getGroupName = (groups, groupId) =>
  groups.find((group) => group.id === groupId)?.name;

export function MemberList({
  members,
  openNewDialog,
  openExpireFundPermissionDialog,
  onUpdate,
  onDelete,
  groups,
}) {
  const classes = useStyles();
  const [me] = useMe();
  const [fundEdit] = useFundEdit();
  const hasCustomApproval = groups.length > 0;

  return (
    <TableContainer>
      {fundEdit ? (
        <TableActions>
          <div className={classes.actions}>
            {me.isStaff && members.length > 0 ? (
              <Button
                onClick={openExpireFundPermissionDialog}
                variant="secondary"
              >
                <InternalChip className={classes.internalChip} />
                Expire fund access
              </Button>
            ) : null}
            <Button
              variant="primary"
              onClick={openNewDialog}
              startIcon={<Icons.Add />}
            >
              Add collaborator
            </Button>
          </div>
        </TableActions>
      ) : null}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            {hasCustomApproval ? <TableCell>Group</TableCell> : null}
            <TableCell>Access level</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {members.map((member) => (
            <TableRow key={member.id}>
              <TableCell scope="row">{member.name}</TableCell>
              <TableCell>{member.email}</TableCell>
              {hasCustomApproval ? (
                <TableCell>
                  {getGroupName(groups, member.customGroupId)}
                </TableCell>
              ) : null}
              <TableCell>{labels[member.level]}</TableCell>
              <TableCell actions>
                {fundEdit ? (
                  <>
                    <Button
                      variant="icon"
                      size="large"
                      onClick={() => onDelete(member.id)}
                      aria-label="Delete"
                    >
                      <Icons.DeleteOutline />
                    </Button>
                    <Button
                      variant="icon"
                      size="large"
                      onClick={() => onUpdate(member.id)}
                      aria-label="Edit settings"
                    >
                      <Icons.SettingsOutlined />
                    </Button>
                  </>
                ) : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
