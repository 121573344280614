import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AccountOutlineIcon from 'mdi-material-ui/AccountOutline';
import MdiDomainIcon from 'mdi-material-ui/Domain';
import { Typography } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    columnGap: theme.spacing(2),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  threadDisplay: {
    padding: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
  },
  greyIcon: {
    color: theme.palette.primary.grey,
  },
  wrappedComment: {
    overflowWrap: 'anywhere',
  },
}));

export function CommentDisplay({
  timeStr,
  comment,
  userName,
  fundName,
  isLPComment = true,
  className,
}) {
  const classes = useStyles();

  const IconClass = isLPComment ? AccountOutlineIcon : MdiDomainIcon;

  return (
    <div className={clsx(classes.root, className)}>
      <IconClass className={classes.greyIcon} />

      <div className={classes.content}>
        <div className={classes.header} data-test="timestamp_header">
          <Typography variant="card-heading">
            {isLPComment ? userName : fundName}
          </Typography>

          <Typography color="text.secondary">{timeStr}</Typography>
        </div>

        <Typography multiline className={classes.wrappedComment}>
          {comment}
        </Typography>
      </div>
    </div>
  );
}
