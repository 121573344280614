import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

export const QuestionDisplay = ({ item }) => (
  <List>
    <ListItem>
      <ListItemText
        secondary={item.questionText}
        primary={<strong>Question</strong>}
      />
    </ListItem>
    <ListItem>
      <ListItemText
        secondary={item.questionType}
        primary={<strong>Question Type</strong>}
      />
    </ListItem>
    {item.questionFieldOrChoice ? (
      <ListItem>
        <ListItemText
          secondary={item.questionFieldOrChoice}
          primary={<strong>Question Field</strong>}
        />
      </ListItem>
    ) : null}
  </List>
);
