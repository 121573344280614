import * as React from 'react';

export const DiligenceContext = React.createContext(null);

export function DiligenceProvider({ diligence, children }) {
  return (
    <DiligenceContext.Provider value={diligence}>
      {children}
    </DiligenceContext.Provider>
  );
}
