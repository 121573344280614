/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  paperDefault: {
    backgroundColor: theme.palette.background.default,
  },
  paperWhite: {
    backgroundColor: theme.palette.background.white,
  },
  actionArea: {
    margin: theme.spacing(1, 0),
    borderRadius: 8,
    transition: '0.2s',
    backgroundColor: theme.palette.primary.grey,
    '&:hover': {
      zIndex: 1,
    },
  },
  formControl: {
    flexGrow: 1,
  },
  formControlFullWidth: {
    display: 'block',
  },
  formControlLabel: {
    fontSize: '2rem !important',
    padding: theme.spacing(2),
    width: '100%',
  },
  formControlRadioOffset: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  alert: {
    border: null,
  },
  iconRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  shiftIcon: {
    position: 'relative',
    right: theme.spacing(1),
  },
}));

export const RadioCardChoice = ({
  choiceKey,
  choiceValue,
  choiceTitle,
  choiceSubtitle,
  dataTest,
  choiceBackground = 'default',
  className,
  alertText,
  alertSeverity,
  setSelectedChoice,
  cardContent,
  icon,
}) => {
  /**
   * If alertText and alertSeverity are passed in, setSelectedChoice is required.
   * This should be the same setSelectedChoice passed to RadioCardContainer.
   */
  const classes = useStyles();

  const formControlLabel = cardContent || (
    <>
      <Typography>{choiceTitle}</Typography>
      {choiceSubtitle ? (
        <Typography variant="body1">{choiceSubtitle}</Typography>
      ) : null}
    </>
  );

  return (
    <CardActionArea
      className={clsx(classes.actionArea, className)}
      key={choiceKey}
    >
      <Paper
        variant="outlined"
        className={clsx({
          [classes.paperWhite]: choiceBackground === 'white',
          [classes.paperDefault]: choiceBackground === 'default',
        })}
      >
        <div className={classes.iconRow}>
          {/*
          https://github.com/mui/material-ui/issues/34039
          FormControlLabel has margin-left -11px, margin-right 16px
          which makes it possible to click past the left edge and still select the option,
          or at the very right edge and have it not select the option.
          Might be changed in Mui v7.
          */}
          <FormControlLabel
            className={clsx(classes.formControlLabel, {
              [classes.formControlRadioOffset]: Boolean(choiceSubtitle),
            })}
            key={choiceKey}
            value={choiceValue}
            control={<Radio />}
            data-test={dataTest}
            label={formControlLabel}
          />
          {icon ? <div className={classes.shiftIcon}>{icon}</div> : null}
        </div>
        {alertText ? (
          <div className={classes.alert}>
            <Alert
              variant="sticky"
              onClick={() => setSelectedChoice(choiceValue)}
              severity={alertSeverity}
            >
              {alertText}
            </Alert>
          </div>
        ) : null}
      </Paper>
    </CardActionArea>
  );
};

export const RadioCardContainer = ({
  selectedChoice,
  setSelectedChoice,
  flexGrowContainer = true,
  fullWidth = false,
  children,
  ...props
}) => {
  const classes = useStyles();

  return (
    <FormControl
      component="fieldset"
      className={clsx({
        [classes.formControl]: flexGrowContainer,
        [classes.formControlFullWidth]: fullWidth,
      })}
    >
      <RadioGroup
        aria-label="choose"
        name="Choose"
        value={selectedChoice}
        onChange={(e) => {
          setSelectedChoice(e.target.value);
        }}
        {...props}
      >
        {children}
      </RadioGroup>
    </FormControl>
  );
};
