import React, { useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Modal } from '@passthrough/uikit';
import { useDiscardConfirmation } from 'services/discard';
import { objectEquals } from 'services/utils';

export function FundCollaboratorNotificationSettingsModal({
  open,
  onClose,
  initialState,
  errors,
  setErrors,
  updateSettings,
  loading,
}) {
  const [form, setForm] = useState(initialState);
  const closeWithConfirmation = useDiscardConfirmation({
    onConfirm: onClose,
    needConfirmation: open && !objectEquals(form, initialState),
  });

  return (
    <Modal
      open={open}
      onClose={closeWithConfirmation}
      headerLabel="Edit admin notifications"
      primaryButtonText="Save"
      primaryButtonLoading={loading}
      showCancelButton
      onEntering={() => {
        setForm(initialState);
        setErrors({});
      }}
      onSubmit={() => updateSettings(form)}
    >
      <FormControl
        error={!!errors.fullyExecutedAdminEmailEnabled}
        component="fieldset"
        fullWidth
      >
        <FormLabel component="legend">Fully executed email</FormLabel>
        <RadioGroup
          aria-label="Fully executed email"
          name="fully-executed-email"
          value={form.fullyExecutedAdminEmailEnabled}
          onChange={() =>
            setForm({
              ...form,
              fullyExecutedAdminEmailEnabled:
                !form.fullyExecutedAdminEmailEnabled,
            })
          }
        >
          <FormControlLabel value control={<Radio />} label="Enabled" />
          <FormControlLabel
            value={false}
            control={<Radio />}
            label="Disabled"
          />
        </RadioGroup>
        {errors.fullyExecutedAdminEmailEnabled ? (
          <FormHelperText>
            {errors.fullyExecutedAdminEmailEnabled}
          </FormHelperText>
        ) : null}
      </FormControl>
    </Modal>
  );
}
