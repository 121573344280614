import React, { useState, useMemo, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';

import * as constants from 'components/document_fields/constants';
import { useSigners } from 'components/document_fields/hooks';
import { DocumentFields } from 'components/document_fields';
import * as api from 'services/api';
import { useToast } from 'services/toast';
import { getSingleErrorFromResponse } from 'services/utils';
import * as urls from 'services/urls';
import { Alert, Link } from '@passthrough/uikit';

import { transformBoxesToSubmit } from './utils';
import { TopBar } from './top_bar';
import { SideBar } from './side_bar';

const useStyles = makeStyles(() => ({
  body: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
}));

export function Editor({
  handleClose,
  handleCloseWithoutConfirmation,
  investor,
  hasFirstCounterSigner,
  hasSecondCounterSigner,
  hasThirdCounterSigner,
  documentFieldActions,
  documentFieldState,
  onChange,
  fileUrl,
}) {
  const classes = useStyles();
  const { fundId, closingId } = useParams();
  const { toast } = useToast();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [showPageDimensionsWarning, setShowPageDimensionsWarning] =
    useState(false);

  const memoizedInvestorList = useMemo(() => [investor], [investor]);
  const signers = useSigners({
    investors: memoizedInvestorList,
    hasFirstCounterSigner,
    hasSecondCounterSigner,
    hasThirdCounterSigner,
  });

  const subdoc = investor?.docs?.find((doc) => doc.isSubdoc);

  const { boxes } = documentFieldState;
  const totalBoxes = boxes.length;
  const placedBoxes = boxes.filter((box) => box.page).length;

  function pageDimensionCheck() {
    setShowPageDimensionsWarning(false);
    if (!subdoc) {
      return;
    }
    api
      .filePageDimensionsCheck({
        fundId,
        fileId: subdoc.fileId,
      })
      .then((response) => {
        if (response.data.hasLargeDimensions) {
          setShowPageDimensionsWarning(true);
        }
      });
  }

  useEffect(pageDimensionCheck, [fundId, closingId, subdoc?.fileId]);

  function handleSubmit() {
    const boxesToSubmit = transformBoxesToSubmit(boxes);
    setSubmitLoading(true);
    setSubmitError(null);

    api
      .placeSignatureBoxes({
        fundId,
        closingId,
        lpDocumentId: subdoc.id,
        boxes: boxesToSubmit,
      })
      .then(() => {
        toast('Document saved');
        onChange();
        setSubmitLoading(false);
        handleCloseWithoutConfirmation();
      })
      .catch((error) => {
        let errorStr = getSingleErrorFromResponse(error.response);
        if (typeof errorStr === 'object') {
          errorStr = getSingleErrorFromResponse(errorStr);
        }
        setSubmitError(errorStr);
        setSubmitLoading(false);
      });
  }

  return (
    <>
      <TopBar
        handleClose={handleClose}
        totalBoxes={totalBoxes}
        placedBoxes={placedBoxes}
        handleSubmit={handleSubmit}
        submitLoading={submitLoading}
      />

      {showPageDimensionsWarning ? (
        <Alert
          title="Signature and text may not be readable"
          variant="sticky"
          severity="warning"
          action={
            <Link
              // Can't use the handleClose with the confirmation because
              // the link might be navigating from the investor list to the
              // review page, which causes the modal to be un-rendered anyway.
              onClick={handleCloseWithoutConfirmation}
              href={urls.reviewQuestionnaireUrl({
                fundId,
                closingId,
                lpClosingId: investor?.id || '',
                tab: 'documents',
              })}
            >
              Go to documents
            </Link>
          }
        >
          The uploaded pages exceed the recommended size (612x792px) for fields
          to appear properly. You may resize the pages and upload the document
          again.
        </Alert>
      ) : null}

      {submitError ? (
        <Alert variant="sticky" severity="error">
          {submitError}
        </Alert>
      ) : null}

      <div className={classes.body}>
        <SideBar
          investor={investor}
          signers={signers}
          documentFieldState={documentFieldState}
          documentFieldActions={documentFieldActions}
        />
        <DocumentFields
          file={{ fileUrl }}
          state={documentFieldState}
          actions={documentFieldActions}
          mode={constants.BOX_PLACEMENT_MODE}
          signers={signers}
          displayName={investor?.lpName}
        />
      </div>
    </>
  );
}
