/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import * as Sentry from '@sentry/react';
import Typography from '@material-ui/core/Typography';
import { useToast } from 'services/toast';
import { DisplayLink } from 'components/link';
import { useSupport } from 'components/support';

class ErrorBoundaryInternal extends Sentry.ErrorBoundary {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    super.componentDidCatch(error, errorInfo);
    const { errorToast } = this.props;
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });
    // You can also log error messages to an error reporting service here
    errorToast('An unexpected error occurred');
  }

  render() {
    const { errorInfo, error } = this.state;
    const { children, showSupport } = this.props;
    if (errorInfo) {
      const errorMsg = (error && error.toString()) || '';
      const stackMsg = errorInfo.componentStack || '';
      const trace = btoa(`${errorMsg}\n${stackMsg}`);
      // Error path
      return (
        <div style={{ padding: '26px' }}>
          <Typography variant="h5" gutterBottom>
            An unexpected error occurred
          </Typography>
          <Typography gutterBottom>
            You can try{' '}
            <DisplayLink
              onClick={() => {
                window.location.reload();
              }}
            >
              refreshing the page
            </DisplayLink>{' '}
            or{' '}
            <DisplayLink onClick={showSupport}>contacting support</DisplayLink>.
          </Typography>
          <details
            style={{
              whiteSpace: 'pre-wrap',
              overflowWrap: 'anywhere',
              color: '#4e4e4e',
            }}
          >
            {trace}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return children;
  }
}

export function ErrorBoundary(props) {
  const { errorToast } = useToast();
  const showSupport = useSupport();

  return (
    <ErrorBoundaryInternal
      errorToast={errorToast}
      showSupport={showSupport}
      {...props}
    />
  );
}
