import React, { useState, useEffect } from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Answers } from '../answers';
import { UNSAVED } from '../saved';

export function TelQuestion({
  updateLpDoc,
  setSaving,
  answer,
  label,
  frontendLabel,
  QuestionStepper,
  QuestionPromptComponent,
}) {
  const initialNumber = answer || '';
  const [number, setNumber] = useState(initialNumber);
  const [errorMsg, setErrorMsg] = useState('');

  const phoneNumber = parsePhoneNumberFromString(number);
  const hasInput = Boolean(phoneNumber) && phoneNumber.nationalNumber;

  function handleSubmit(e) {
    e.preventDefault();

    const submitNumber = phoneNumber ? number : '';

    return updateLpDoc({
      label,
      answer: submitNumber,
      skipQuestion: !hasInput,
    }).catch((error) => {
      setSaving(UNSAVED);
      if (error.response?.status === 400) {
        setErrorMsg(error.response.data.answer);
      }
      return error;
    });
  }

  function handleChange(value) {
    setSaving(UNSAVED);
    setNumber(value);
  }

  // Reset the number when changing questions
  useEffect(() => {
    setNumber(initialNumber);
    setErrorMsg('');
  }, [label]);

  return (
    <form onSubmit={handleSubmit}>
      <QuestionPromptComponent />
      <Answers>
        <MuiPhoneNumber
          id="tel"
          type="tel"
          autoFocus
          disableAreaCodes
          defaultCountry="us"
          label={frontendLabel}
          variant="outlined"
          value={number}
          error={!!errorMsg}
          helperText={errorMsg}
          onChange={handleChange}
          fullWidth
          // material-ui-phone-number is throwing an exception when the component
          // is quickly mounted/unmounted and the property autoFocus is true.
          //
          // Exception: Cannot read properties of null (reading 'focus')
          //
          // Passing the prop onFocus seems to resolve the issue.
          onFocus={() => {}}
        />
        <QuestionStepper
          handleSubmit={handleSubmit}
          disabled={!phoneNumber || !phoneNumber.isPossible()}
          hasAnswerChanged={number !== initialNumber}
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
}
