import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import { Typography } from '@passthrough/uikit';
import { TruncatedTextDisplay } from 'components/truncated_text_display';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.neutral.fill,
    padding: theme.spacing(1, 2),
  },
}));

export function Message({ missingMessageText = 'No message', message }) {
  const classes = useStyles();

  if (!message) {
    return <Typography color="text.secondary">{missingMessageText}</Typography>;
  }

  return (
    <Paper elevation={0} className={classes.container}>
      <TruncatedTextDisplay
        content={message}
        expandText="See full message"
        contractText="Hide full message"
        size="medium"
      />
    </Paper>
  );
}
