/* eslint-disable no-restricted-syntax */
import { componentForQuestion } from './component_for_question';
import { SignQuestion } from './sign';
import { isNodeComplete } from './diligence/tree_nav_v2/tree_utils';
import { TYPE_INDIVIDUAL } from './diligence/constants';
import { getOwnerFields } from './diligence/utils';

const DILIGENCE_SECTION_TYPE = 'DILIGENCE';
const QUESTIONNAIRE_SECTION_TYPE = 'QUESTIONNAIRE';
const SUBDOC_CONFIRMATION_TYPE = 'SUBDOC_CONFIRM';
const DILIGENCE_CONFIRM_TYPE = 'DILIGENCE_CONFIRM';
const SECTION_OVERVIEW_INDEX = -1;
const FIRST_QUESTION_INDEX = 0;

function isQuestionnaireDiligenceOnly(questionnaireSections) {
  return questionnaireSections[0].type === DILIGENCE_SECTION_TYPE;
}

function filteredSteps({
  lpDoc,
  commentsPerQuestion,
  getComponentForQuestion,
  signComponent,
  lpDiligence,
  confirmType = SUBDOC_CONFIRMATION_TYPE,
  blockDiligence = false,
}) {
  if (!lpDoc) {
    return [];
  }

  const askedQuestions = new Map();

  const sections = lpDoc.isCompleted
    ? []
    : lpDoc.sections
        // remove unasked questions and map to React components
        .map((section) => ({
          ...section,
          lpClosingId: lpDoc.id,
          questions: section.questions
            .filter((q) => q.isAsked)
            .map((q) => {
              let numComments = 0;
              askedQuestions.set(q.label, true);

              const questionThread = commentsPerQuestion[q.label] || [];
              numComments = questionThread.length;

              const needsConfirmation = q.requiresAttention && numComments > 0;

              return {
                ...q,
                component: getComponentForQuestion(q),
                numComments,
                needsConfirmation,
                sectionId: section.id,
                documentId: section.document?.id,
              };
            }),
        }))
        // remove empty sections
        .filter((section) => section.questions.length)
        .map((section) => {
          const sectionHasComments = section.questions.some(
            (q) => q.numComments > 0,
          );
          const sectionHasActionableQuestion = section.questions.some(
            (q) => q.needsConfirmation,
          );

          return {
            ...section,
            sectionHasComments,
            sectionHasActionableQuestion,
          };
        })
        // mark sections as complete, or started
        .map((section) => ({
          ...section,
          isCompleted: section.questions.every((q) => q.isCompleted),
        }))
        .map((section) => {
          let isConfirmedVal = section.isConfirmed && section.isCompleted;
          let blocked = false;
          if (section.type === DILIGENCE_SECTION_TYPE) {
            isConfirmedVal = section.isCompleted;
            blocked = blockDiligence;
          }

          return {
            ...section,
            blocked,
            isStarted: section.questions.some((q) => q.isCompleted),
            isConfirmed: isConfirmedVal,
          };
        });

  const hasSignatures = lpDoc.docs.some((d) => d.requireSignature);

  if (!lpDoc.isCompleted) {
    sections.push({
      id: `${confirmType}-section`,
      type: confirmType,
      label: hasSignatures ? 'Confirm & sign' : 'Confirm & submit',
      lpClosingId: lpDoc.id,
      blocked: !sections.every((step) => step.isConfirmed),
      questions: [
        {
          isCompleted: false,
          isConfirmed: false,
          isRequired: true,
          isAsked: true,
          component: signComponent,
          lpClosingId: lpDoc.id,
        },
      ],
    });
  }
  if (lpDiligence) {
    sections.push(
      ...filteredSteps({
        lpDoc: lpDiligence,
        commentsPerQuestion,
        getComponentForQuestion,
        signComponent,
        confirmType: DILIGENCE_CONFIRM_TYPE,
        blockDiligence: !lpDoc.isCompleted && !lpDoc.legalName,
      }),
    );
  }

  return sections;
}

function shouldForceOfflineSigning(steps) {
  return steps.some((step) =>
    step.questions.some((q) => q.forcesOfflineSigning),
  );
}

export class Navigator {
  constructor({
    lpDoc,
    lpDiligence = null,
    navState,
    setNavState,
    setSelectedQuestionId,
    commentsPerQuestion = {},
    getComponentForQuestion = componentForQuestion,
    signComponent = SignQuestion,
  }) {
    const { innerStep, outerStep, showDiligenceNav, showAnimation } = navState;

    this.lpDoc = lpDoc;
    this.lpDiligence = lpDiligence;
    this.innerStep = innerStep;
    this.outerStep = outerStep;
    this.setNavState = setNavState;
    this.commentsPerQuestion = commentsPerQuestion;
    this.steps = filteredSteps({
      lpDoc,
      commentsPerQuestion,
      getComponentForQuestion,
      signComponent,
      lpDiligence,
    });

    if (this.outerStep >= this.steps?.length || 0) {
      this.outerStep = 0;
    }
    if (this.innerStep >= this.steps[this.outerStep]?.questions.length || 0) {
      this.innerStep = SECTION_OVERVIEW_INDEX;
    }
    this.forceOfflineSigning = shouldForceOfflineSigning(this.steps);
    this.showDiligenceNav = showDiligenceNav;
    this.showAnimation = showAnimation;
    this.setSelectedQuestionId = setSelectedQuestionId;
    this.getComponentForQuestion = getComponentForQuestion;
    this.signComponent = signComponent;
  }

  save() {
    const diligenceIndex = this.getDiligenceSectionIndex();
    const subdocConfirmationIndex = this.getSubdocConfirmationIndex();
    const diligenceConfirmationIndex = this.getDiligenceConfirmationIndex();

    if (
      this.innerStep === SECTION_OVERVIEW_INDEX &&
      this.outerStep === diligenceIndex
    ) {
      this.innerStep = FIRST_QUESTION_INDEX;

      if (this.getShouldShowTreeNav(this.outerStep)) {
        this.setSelectedQuestionId('QD1');
        this.showAnimation = true;
        this.showDiligenceNav = true;
      }
    }

    // We don't show this for the signature section
    const onConfirmation =
      this.outerStep === subdocConfirmationIndex ||
      this.outerStep === diligenceConfirmationIndex;
    if (this.innerStep === SECTION_OVERVIEW_INDEX && onConfirmation) {
      this.innerStep = FIRST_QUESTION_INDEX;
    }
    this.setNavState({
      innerStep: this.innerStep,
      outerStep: this.outerStep,
      showDiligenceNav: this.showDiligenceNav,
      showAnimation: this.showAnimation,
    });
  }

  getDiligenceSectionIndex() {
    return this.steps.findIndex(
      (section) => section.type === DILIGENCE_SECTION_TYPE,
    );
  }

  getSubdocConfirmationIndex() {
    const index = this.steps.findIndex(
      (section) => section.type === SUBDOC_CONFIRMATION_TYPE,
    );
    return index === -1 ? null : index;
  }

  getDiligenceConfirmationIndex() {
    const index = this.steps.findIndex(
      (section) => section.type === DILIGENCE_CONFIRM_TYPE,
    );
    return index === -1 ? null : index;
  }

  toFirstQuestion() {
    this.toQuestion(FIRST_QUESTION_INDEX);
  }

  hasLegalNameQuestion() {
    return this.lpDoc.legalNameQuestionIds?.length > 0;
  }

  legalNameQuestionIsAskedOnce() {
    return (
      this.lpDoc.legalNameQuestionIds?.filter((id) =>
        this.lpDoc.sections.some((section) =>
          section.questions.some((q) => q.label === id && q.isAsked),
        ),
      ).length === 1
    );
  }

  legalNameLocation() {
    // legal name question label is on this.lpDoc.legalNameQuestionId
    // need to find the section and question index for that label
    const { legalNameQuestionIds } = this.lpDoc;

    if (!legalNameQuestionIds || !legalNameQuestionIds.length) {
      return [null, null];
    }

    // section.questions only contains asked questions
    const sectionIndex = this.steps.findIndex((section) =>
      section.questions.find((q) => legalNameQuestionIds.includes(q.label)),
    );

    if (sectionIndex === -1) {
      return [null, null];
    }

    const questionId = this.steps[sectionIndex].questions.find((q) =>
      legalNameQuestionIds.includes(q.label),
    ).label;
    return [sectionIndex, questionId];
  }

  toLegalName() {
    const [sectionIndex, questionId] = this.legalNameLocation();
    if (sectionIndex === null) {
      return;
    }
    this.toQuestionAndSection(this.steps[sectionIndex].id, questionId);
  }

  getNextSectionIndices() {
    const { outerStep } = this;

    const nextRequired = this.getNextRequiredIndices();

    let nextInnerStep = Object.prototype.hasOwnProperty.call(
      nextRequired,
      'questionIndex',
    )
      ? nextRequired.questionIndex
      : SECTION_OVERVIEW_INDEX;
    const nextOuterStep = nextRequired?.sectionIndex || outerStep + 1;
    const confirmationIndex = this.getSubdocConfirmationIndex();
    const diligenceConfirmationIndex =
      this.getDiligenceConfirmationIndex() || confirmationIndex;

    if (
      nextOuterStep === confirmationIndex ||
      nextOuterStep === diligenceConfirmationIndex
    ) {
      // if on signing section, go to first question of that section
      // now as opposed to waiting for save() to do that
      nextInnerStep = FIRST_QUESTION_INDEX;
    }

    if (nextOuterStep > diligenceConfirmationIndex) {
      return null;
    }

    return { nextInnerStep, nextOuterStep };
  }

  toNextSection(newLpDoc) {
    const lpDoc = newLpDoc || this.lpDoc;

    // The next questions can change in response to the new answer, so we
    // have to calculate using the newLpDoc.
    const newSteps = filteredSteps({
      lpDoc,
      commentsPerQuestion: this.commentsPerQuestion,
      getComponentForQuestion: this.getComponentForQuestion,
      signComponent: this.signComponent,
      lpDiligence: this.lpDiligence,
    });
    this.forceOfflineSigning = shouldForceOfflineSigning(newSteps);

    const nextIndices = this.getNextSectionIndices();

    if (nextIndices) {
      this.innerStep = nextIndices.nextInnerStep;
      this.outerStep = nextIndices.nextOuterStep;
      this.save();
    }
  }

  decrement() {
    const onSectionOverview = this.innerStep === SECTION_OVERVIEW_INDEX;

    if (onSectionOverview) {
      // Currently on section overview, going back means going to previous
      // section overview
      this.outerStep -= 1;
    } else {
      this.innerStep -= 1;
    }

    this.save();
  }

  diligenceDecrement() {
    const wouldDisplaySectionOverviewNext =
      this.innerStep - 1 === SECTION_OVERVIEW_INDEX;
    if (wouldDisplaySectionOverviewNext) {
      // we no longer show the diligence section overview,
      // so skip over it and show the prior section overview
      this.outerStep -= 1;
      this.innerStep = SECTION_OVERVIEW_INDEX;
      this.toRegularNav();
    } else {
      this.innerStep -= 1;

      const priorQuestion =
        this.steps[this.outerStep].questions[this.innerStep];
      this.setSelectedQuestionId(priorQuestion?.label);
    }

    this.save();
  }

  toRegularNav() {
    this.showDiligenceNav = false;
    this.showAnimation = true;
    this.save();
  }

  toDiligence(index) {
    /*
    Clicking on the diligence section will take you to the diligence nav if applicable
    Otherwise go to the first diligence question
    */
    this.showDiligenceNav = this.getShouldShowTreeNav(index);
    if (this.showDiligenceNav) {
      // When we don't have the tree nav, we want to show the animation
      // Otherwise don't show the animation because you're not going anywhere
      this.showAnimation = true;
    } else {
      this.innerStep = FIRST_QUESTION_INDEX;
      this.outerStep = index;
    }
    this.save();
  }

  toSection(index) {
    this.innerStep = SECTION_OVERVIEW_INDEX;
    this.outerStep = index;
    this.setSelectedQuestionId(null);
    this.save();
  }

  toQuestion(index) {
    const diligenceSectionIndex = this.getDiligenceSectionIndex();
    const hasDiligenceSection =
      diligenceSectionIndex !== null && diligenceSectionIndex !== undefined;
    if (this.showDiligenceNav && hasDiligenceSection) {
      const question = this.steps[diligenceSectionIndex].questions[index];
      this.outerStep = diligenceSectionIndex;
      this.setSelectedQuestionId(question?.label);
    } else {
      this.setSelectedQuestionId(null);
    }
    this.innerStep = index;
    this.save();
  }

  hasNextRequired() {
    for (const [, section] of this.steps.entries()) {
      for (const [, question] of section.questions.entries()) {
        if (!question.isCompleted) {
          return true;
        }
      }
      if (!section.isConfirmed) {
        return true;
      }
    }
    return false;
  }

  // Goes to first unanswered question in the section, if any. Otherwise moves forward.
  // When you're on the last section and have incomplete
  // questions, it will then bring you to the first incomplete question.
  getNextRequiredIndices() {
    const origOuterIndex = this.outerStep;
    const origInnerIndex = this.innerStep;

    let sectionIndex = origOuterIndex;
    const docConfirmationIndex = this.getSubdocConfirmationIndex();
    const diligenceConfirmationIndex =
      this.getDiligenceConfirmationIndex() || docConfirmationIndex;

    const onSignPage =
      origOuterIndex === docConfirmationIndex ||
      origOuterIndex === diligenceConfirmationIndex;
    if (onSignPage) {
      // If we're on the last section and we're going to next required,
      // it means there's unanswered questions
      sectionIndex = FIRST_QUESTION_INDEX;
    }

    do {
      const section = this.steps[sectionIndex];
      let questionIndex =
        sectionIndex === origOuterIndex
          ? Math.max(origInnerIndex, FIRST_QUESTION_INDEX)
          : FIRST_QUESTION_INDEX;
      for (; questionIndex < section.questions.length; questionIndex += 1) {
        const question = section.questions[questionIndex];
        if (!question.isCompleted) {
          return { questionIndex, sectionIndex };
        }
      }

      if (!section.isConfirmed) {
        return { questionIndex: SECTION_OVERVIEW_INDEX, sectionIndex };
      }

      if (
        sectionIndex === docConfirmationIndex ||
        sectionIndex === diligenceConfirmationIndex
      ) {
        sectionIndex = FIRST_QUESTION_INDEX;
      } else {
        sectionIndex += 1;
      }
    } while (sectionIndex !== origOuterIndex);
    return {};
  }

  getJurisdiction() {
    if (this.lpDiligence) {
      return this.lpDiligence.diligenceJurisdiction;
    }
    return this.lpDoc.diligenceJurisdiction;
  }

  getShouldShowTreeNav(sectionIndex) {
    // sectionIndex should be the section you are going to be on
    const onDiligenceSection =
      this.steps[sectionIndex].type === DILIGENCE_SECTION_TYPE;
    if (onDiligenceSection) {
      const diligenceSection = this.steps[sectionIndex];
      const rootQuestion = diligenceSection.questions[0];

      const diligenceJurisdiction = this.getJurisdiction();
      const ownerFields = getOwnerFields(
        rootQuestion.answer?.type,
        diligenceJurisdiction,
      );
      const showTreeNav = Boolean(
        rootQuestion.answer &&
          rootQuestion.answer.type !== TYPE_INDIVIDUAL &&
          // TODO refactor to reuse isOwnersSectionInProgress
          ownerFields.some(
            (ownerData) => rootQuestion.answer[ownerData.key]?.length > 0,
          ),
      );
      return showTreeNav;
    }
    return false;
  }

  getNextRequiredQuestion() {
    const { questionIndex, sectionIndex } = this.getNextRequiredIndices();
    return this.steps[sectionIndex].questions[questionIndex];
  }

  toNextRequired() {
    const onDiligenceSection =
      this.steps[this.outerStep].type === DILIGENCE_SECTION_TYPE;
    const diligenceOnly = isQuestionnaireDiligenceOnly(this.steps);

    if (onDiligenceSection && !diligenceOnly) {
      // Diligence only questionnaires will skip past the diligence section if we call
      // this function which is designed to force you forward.
      this.toDiligenceNextRequired();
    } else {
      const { questionIndex, sectionIndex } = this.getNextRequiredIndices();
      const nextQuestion = this.steps[sectionIndex].questions[questionIndex];
      // When the next question is the same as the current, it means we haven't completed
      // the current question. But we should still move past it for consistency.
      const nextQuestionIsSame =
        this.steps[this.outerStep].questions[this.innerStep] === nextQuestion;

      if (diligenceOnly && nextQuestionIsSame) {
        this.toDiligenceNextRequired();
        return;
      }

      this.showDiligenceNav = this.getShouldShowTreeNav(sectionIndex);
      this.setSelectedQuestionId(nextQuestion?.label);
      this.innerStep = questionIndex;
      this.outerStep = sectionIndex;
      this.save();
    }
  }

  toDiligenceNextRequired(startQuestionIndex = null) {
    // By default this only moves you forward from your current position in the diligence
    // section
    // Use startQuestionIndex if you want the next required starting from a specific question
    // index
    const diligenceSection = this.steps.find(
      (section) => section.type === DILIGENCE_SECTION_TYPE,
    );
    const diligenceSectionIndex = this.getDiligenceSectionIndex();

    for (const [index, question] of diligenceSection.questions.entries()) {
      const isPastCurrentStep = index > (startQuestionIndex || this.innerStep);
      const isRootDiligenceQuestion = question.label === 'QD1';
      const diligenceJurisdiction = this.getJurisdiction();
      const diligenceQuestionCompleted =
        isNodeComplete(
          question.answer,
          diligenceJurisdiction,
          isRootDiligenceQuestion,
        ) && question.isCompleted;

      if (!diligenceQuestionCompleted && isPastCurrentStep) {
        this.innerStep = index;
        this.showDiligenceNav = this.getShouldShowTreeNav(
          diligenceSectionIndex,
        );
        if (this.showDiligenceNav) {
          this.showAnimation = true;
        }
        this.setSelectedQuestionId(question.label);
        this.save();
        return;
      }
    }

    // This sectionIndex will be the diligence section if it's still incomplete-
    // but we always want to move forward so we advance the outerStep by 1
    const { sectionIndex } = this.getNextRequiredIndices();
    if (diligenceSectionIndex === sectionIndex) {
      this.outerStep = sectionIndex + 1;
    } else {
      this.outerStep = sectionIndex;
    }

    this.innerStep = SECTION_OVERVIEW_INDEX;
    this.showDiligenceNav = false;
    this.save();
  }

  showOverview() {
    const onConfirmation =
      this.outerStep === this.getSubdocConfirmationIndex() ||
      this.outerStep === this.getDiligenceConfirmationIndex();
    if (onConfirmation) {
      return false;
    }
    return this.innerStep === SECTION_OVERVIEW_INDEX;
  }

  getCurrentSection() {
    if (!this.lpDoc) {
      return null;
    }
    return this.steps[this.outerStep];
  }

  getNextSectionToWorkOn() {
    let potentialNextSectionIndex = this.outerStep + 1;
    let nextSection;

    while (potentialNextSectionIndex < this.steps.length) {
      nextSection = this.steps[potentialNextSectionIndex];

      if (!nextSection.isCompleted || !nextSection.isConfirmed) {
        return nextSection.label;
      }

      potentialNextSectionIndex += 1;
    }

    return null;
  }

  getCurrentQuestion() {
    if (!this.lpDoc) {
      return null;
    }

    return this.steps[this.outerStep]?.questions[this.innerStep];
  }

  toQuestionAndSection(sectionId, questionLabel) {
    const section = this.steps?.find((s) => sectionId === s.id);
    const question = section?.questions.find((q) => questionLabel === q.label);
    this.outerStep = this.steps?.indexOf(section);
    this.innerStep = section?.questions.indexOf(question);
    this.setSelectedQuestionId(null);
    this.save();
  }

  goToInitialQuestion() {
    if (!this.lpDoc.isCompleted) {
      const { sectionIndex: nextRequiredSectionIndex } =
        this.getNextRequiredIndices();

      const nextRequiredSection = this.steps[nextRequiredSectionIndex];
      const questionsRequireAttention = nextRequiredSection.questions.some(
        (q) => q.requiresAttention,
      );
      const diligenceSectionIsNext =
        nextRequiredSection.type === DILIGENCE_SECTION_TYPE;

      const goToSectionOverview =
        nextRequiredSection.isStarted &&
        !nextRequiredSection.isConfirmed &&
        !questionsRequireAttention &&
        !diligenceSectionIsNext;

      if (goToSectionOverview) {
        // If there are some answers already, show them an overview of what they were
        // working on. Or if it's the first time and the document is prefilled, let them
        // see what is prefilled.
        this.toSection(nextRequiredSectionIndex);
        return;
      }

      if (isQuestionnaireDiligenceOnly(this.steps)) {
        // always start on the first uncompleted diligence question
        this.toDiligenceNextRequired(SECTION_OVERVIEW_INDEX);
        return;
      }

      this.toNextRequired();
    }
  }

  readyToSign() {
    const currentSection = this.getCurrentSection();

    const hasDiligenceOnlyConfirmation = this.steps.find(
      (s) => s.type === DILIGENCE_CONFIRM_TYPE,
    );
    if (hasDiligenceOnlyConfirmation) {
      // has separate step for diligence confirmation
      if (currentSection.type === DILIGENCE_CONFIRM_TYPE) {
        // diligence confirmation is ready to complete if diligence is complete
        return this.steps[this.getDiligenceSectionIndex()].isConfirmed;
      }
      // subdoc confirmation is ready to complete if all sections of
      // the subdoc are complete
      return this.steps
        .filter((s) => s.type === QUESTIONNAIRE_SECTION_TYPE)
        .every((s) => s.isConfirmed);
    }

    // single step for subdoc and diligence confirmation
    // complete if all sections are complete
    return this.steps
      .filter((s) =>
        [QUESTIONNAIRE_SECTION_TYPE, DILIGENCE_SECTION_TYPE].includes(s.type),
      )
      .every((s) => s.isConfirmed);
  }
}
