import * as React from 'react';

import { DiffStyle } from 'components/Diff';
import { ANSWER_STATES } from 'services/constants';

import { TypeDisplay } from 'components/type_display';

export function DiligenceInvestorType({ answer, questionState }) {
  return (
    <DiffStyle
      isNew={questionState === ANSWER_STATES.CHANGED_CURRENT_ANSWER}
      isDeleted={questionState === ANSWER_STATES.CHANGED_PRIOR_ANSWER}
    >
      <TypeDisplay type={answer} />
    </DiffStyle>
  );
}
