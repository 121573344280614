export function getInitials(name) {
  const names = name.split(' ');
  if (names.length === 1 && names[0].length > 0) {
    return names[0][0].toUpperCase();
  }
  if (names.length > 1) {
    return `${names[0][0]}${names[1][0]}`.toUpperCase();
  }
  return '';
}

export function getLevelText(level) {
  switch (level) {
    case 'EDIT':
      return 'Editor';
    case 'REVIEW':
      return 'Reviewer';
    case 'VIEW':
      return 'Viewer';
    default:
      return '';
  }
}
