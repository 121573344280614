import React, { useState, useEffect } from 'react';

import {
  PageHeader,
  Breadcrumbs,
  UIKitSettingsProvider,
  Typography,
  Link,
} from '@passthrough/uikit';
import { useParams } from 'react-router-dom';
import { PageContainer } from 'components/page_container';
import { Spinner } from 'components/spinner';
import * as urls from 'services/urls';
import * as api from 'services/api';
import { useFund, useMembers } from 'services/providers/fund';
import { useClosing } from 'services/providers/closing';
import { useToast } from 'services/toast';
import { getSingleErrorFromResponse } from 'services/utils';

import { PageBody } from './body';

function Crumbs({ fund, fundId, closing, closingId }) {
  return (
    <Breadcrumbs
      crumbs={[
        {
          name: fund?.organizationName,
          to: fund?.isOrganizationAdmin
            ? urls.organizationUrl({
                organizationId: fund.organizationId,
              })
            : null,
          skeleton: !fund?.organizationName,
        },
        {
          name: fund?.name,
          to: fund ? urls.closingsUrl({ fundId: fund.id }) : null,
          skeleton: !fund?.name,
        },
        {
          name: closing?.name,
          to: closing ? urls.closingUrl({ fundId, closingId }) : null,
          skeleton: !closing?.name,
        },
        { name: 'Closing settings', skeleton: !closing?.name },
      ]}
    />
  );
}

function HelpArticleLink() {
  return (
    <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
      <Typography variant="body" size="small">
        Configure settings and manage the investor experience for this closing.{' '}
        <Link
          variant="external"
          inline
          href="https://support.passthrough.com/en_us/closing-settings-page-notifications-rJHQf0jR"
        >
          Learn more
        </Link>
      </Typography>
    </UIKitSettingsProvider>
  );
}

export function ClosingSettingsPage() {
  const [initialClosingData] = useClosing();
  const { closing: initialClosing } = initialClosingData;
  // Store closing locally so we can refetch after updating
  const [closing, setClosing] = useState(initialClosing);
  const [errors, setErrors] = useState({});
  const [errorMsg, setErrorMsg] = useState(null);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [modalName, setModalName] = useState(null);
  const [fund] = useFund();
  const [members] = useMembers();
  const { toast } = useToast();
  const { fundId, closingId } = useParams();

  function getClosing() {
    return api.closing({ fundId, closingId }).then((res) => {
      setClosing(res.data);
    });
  }

  useEffect(() => {
    getClosing();
  }, [fundId, closingId]);

  function updateClosingSettings(form) {
    setIsUpdateLoading(true);
    setErrors({});
    setErrorMsg(null);

    api
      .updateClosingSettings({ fundId, closingId, form })
      .then(() => {
        getClosing()
          .then(() => {
            setModalName(null);
            setIsUpdateLoading(false);
            toast('Changes saved');
          })
          .catch(() => window.location.reload());
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          setErrors(error.response.data);
        } else if (error?.response) {
          setErrorMsg(getSingleErrorFromResponse(error.response));
        }
        setIsUpdateLoading(false);
      });
  }

  return (
    <>
      <PageHeader
        heading="Closing settings"
        crumbs={
          <Crumbs
            fund={fund}
            fundId={fundId}
            closing={closing}
            closingId={closingId}
          />
        }
        metadata={<HelpArticleLink />}
      />

      {closing === null ? (
        <Spinner fullScreen />
      ) : (
        <PageContainer maxWidth="md">
          <PageBody
            closing={closing}
            members={members}
            fundId={fundId}
            fundName={fund?.name}
            errors={errors}
            setErrors={setErrors}
            errorMsg={errorMsg}
            updateSettings={updateClosingSettings}
            isUpdateLoading={isUpdateLoading}
            modalName={modalName}
            setModalName={setModalName}
          />
        </PageContainer>
      )}
    </>
  );
}
