import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Typography, Icons, Alert } from '@passthrough/uikit';

import {
  DELIVERY_OPTION_BULK_PACKET,
  DELIVERY_OPTION_INDIVIDUAL_PACKETS,
} from './constants';

const useStyles = makeStyles(() => ({
  icon: {
    marginRight: '16px',
    minWidth: '0px',
  },
  listItem: {
    padding: 0,
  },
  secondaryText: {
    fontSize: '12px',
  },
  disableBottomPadding: {
    paddingBottom: 0,
  },
}));

function BulletPoint({ icon, primary, secondary = undefined }) {
  const classes = useStyles();

  return (
    <ListItem className={classes.listItem} dense disableGutters>
      <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
      <ListItemText
        classes={{ secondary: classes.secondaryText }}
        primary={primary}
        secondary={secondary}
      />
    </ListItem>
  );
}

export function DeliveryOptionDisplay({
  deliveryOption,
  atLeastOneDocumentHasVariables,
  multiplePackets,
}) {
  const classes = useStyles();

  const checkIcon = <Icons.Check color="success" fontSize="small" />;
  const xIcon = <Icons.Close color="error" fontSize="small" />;

  const showDocumentVariableWarning =
    atLeastOneDocumentHasVariables &&
    deliveryOption === DELIVERY_OPTION_INDIVIDUAL_PACKETS;

  let title;
  let bulkCountersigning;
  let documentVariables;
  let cryptographicSeal;
  let auditTrail;
  let dataTest;

  switch (deliveryOption) {
    case DELIVERY_OPTION_BULK_PACKET:
      title = 'Combined signature packet';
      bulkCountersigning = true;
      documentVariables = true;
      cryptographicSeal = false;
      auditTrail = false;
      dataTest = 'bulk-packet';
      break;
    case DELIVERY_OPTION_INDIVIDUAL_PACKETS:
      title = 'Individual signature packet';
      bulkCountersigning = false;
      documentVariables = false;
      cryptographicSeal = true;
      auditTrail = true;
      dataTest = 'individual-packets';
      break;
    default:
      return null;
  }

  if (multiplePackets) {
    title += 's';
  }

  const bulkCountersigningText = bulkCountersigning
    ? 'Bulk countersigning'
    : 'No bulk countersigning';

  const documentVariablesText = documentVariables
    ? 'Bulk editing for document variables'
    : 'No bulk editing for document variables';

  const documentVariablesSecondaryText = documentVariables
    ? undefined
    : 'Countersigner must complete all required fields during signing.';

  const cryptographicSealText = cryptographicSeal
    ? 'Cryptographic seal for all signatures'
    : 'No cryptographic seal for signatures';

  const auditTrailText = auditTrail
    ? 'Complete audit trail in signature packets'
    : 'Audit trail for countersignature not included in signature packets';

  return (
    <>
      <Typography data-test={dataTest} variant="body">
        {title}
      </Typography>
      <List
        className={clsx({
          [classes.disableBottomPadding]: !showDocumentVariableWarning,
        })}
      >
        <BulletPoint
          icon={bulkCountersigning ? checkIcon : xIcon}
          primary={bulkCountersigningText}
        />
        <BulletPoint
          icon={documentVariables ? checkIcon : xIcon}
          primary={documentVariablesText}
          secondary={documentVariablesSecondaryText}
        />
        <BulletPoint
          icon={cryptographicSeal ? checkIcon : xIcon}
          primary={cryptographicSealText}
        />
        <BulletPoint
          icon={auditTrail ? checkIcon : xIcon}
          primary={auditTrailText}
        />
      </List>
      {showDocumentVariableWarning ? (
        <Alert severity="warning">
          There are saved document variables for at least one of the selected
          investors. They will be discarded.
        </Alert>
      ) : null}
    </>
  );
}
