import React, { useEffect, useState } from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';

import * as api from 'services/api';
import { QuestionTagModal } from './add_or_edit';
import { QuestionTagsTable } from './table';
import { QuestionTagsToolBar } from './tool_bar';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(5, 0),
  },
  errorMessage: {
    paddingBottom: theme.spacing(3),
  },
  tableSection: {
    paddingTop: theme.spacing(3),
  },
  toolBar: {
    paddingTop: theme.spacing(3),
  },
}));

export const QuestionTags = () => {
  const classes = useStyles();

  const [selectedTagId, setSelectedTagId] = useState(null);
  const [tags, setTags] = useState([]);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [deleteError, setDeleteError] = useState(null);

  const updateTable = (delayedSearchText) => {
    api.questionTags({ search: delayedSearchText }).then((response) => {
      setTags(response.data);
    });
  };

  // Populate the table initially with all tags
  useEffect(() => {
    updateTable('');
  }, [tagModalOpen]);

  function addButtonOnClick() {
    setTagModalOpen(true);
    setSelectedTagId(null);
  }

  function editButtonOnClick(tagId) {
    setTagModalOpen(true);
    setSelectedTagId(tagId);
  }
  return (
    <div>
      <div>
        <QuestionTagsToolBar
          updateTable={updateTable}
          addButtonOnClick={addButtonOnClick}
        />

        <div key="table" className={classes.tableSection}>
          {deleteError ? (
            <FormHelperText className={classes.errorMessage} error>
              {deleteError}
            </FormHelperText>
          ) : null}
          <QuestionTagsTable
            tags={tags}
            updateTable={updateTable}
            setDeleteError={setDeleteError}
            editButtonOnClick={editButtonOnClick}
          />
        </div>
      </div>
      <QuestionTagModal
        open={tagModalOpen}
        setOpen={setTagModalOpen}
        tagId={selectedTagId}
      />
    </div>
  );
};
