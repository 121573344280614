/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Alert } from 'components/alert';
import {
  boxTypes,
  checkboxEnabledByDefault,
  checkboxEnabledByDefaultByAnswerType,
} from '../constants';

// used to display warnings about the usage of helloSignBox types for questions where their
// usage does not make much sense
export const HellosignBoxUsageWarnings = ({
  classes,
  answerType,
  field,
  boxes,
}) => {
  function usingBoxType(boxTypeName) {
    return boxes.find((currBox) => currBox.type === boxTypeName);
  }

  const shouldUseCheckbox =
    checkboxEnabledByDefault.includes(answerType) ||
    checkboxEnabledByDefaultByAnswerType[answerType]?.includes(field);

  function shouldDisplayCheckboxWarning() {
    return !shouldUseCheckbox && usingBoxType(boxTypes.CHECKBOX);
  }

  function shouldDisplayTextboxWarning() {
    return shouldUseCheckbox && usingBoxType(boxTypes.TEXTBOX);
  }

  function generateUsageWarningOrNull(featureName, displayCondition) {
    if (!displayCondition) return null;
    if (!field) {
      return (
        <Alert severity="warning">
          Are you sure that you want to add a {featureName} to a question of
          type {answerType}?
        </Alert>
      );
    }
    return (
      <Alert severity="warning">
        Are you sure that you want to add a {featureName} to the field {field}?
      </Alert>
    );
  }

  const checkboxWarning = generateUsageWarningOrNull(
    'checkbox',
    shouldDisplayCheckboxWarning(),
  );
  const textboxWarning = generateUsageWarningOrNull(
    'textbox',
    shouldDisplayTextboxWarning(),
  );

  return (
    <div className={classes.warning}>
      {checkboxWarning}
      {textboxWarning}
    </div>
  );
};
