import React, { useMemo, useState } from 'react';
import { Button, Typography } from '@passthrough/uikit';
import { buildDarkTheme, theme as defaultTheme } from 'services/theme';
import { Box, Tooltip, Grid, makeStyles, useTheme } from '@material-ui/core';
import {
  ShowCaseBox,
  ComponentShowCase,
  ShowCaseBoxRow,
  ShowCaseBoxColumn,
} from '../show_case';

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
}));

const PASSTHROUGH_THEME = 1;
const WHITELABEL_THEME = 2;
const PASSTHROUGH_DARK_THEME = 3;

const getTextColor = (color, theme) => {
  if (color.includes('rgba')) {
    const alpha = color.split(',')[3].split(')')[0];
    if (parseFloat(alpha) < 0.5) {
      return '#000';
    }
  }
  return theme.palette.getContrastText(color);
};

const colorBox = (color, text, theme) => (
  <Tooltip title={<Typography>{color}</Typography>}>
    <Box
      sx={{
        margin: theme.spacing(1),
        width: 200,
        height: 50,
        color: getTextColor(color, theme),
        backgroundColor: color,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: '#000',
        borderStyle: 'solid',
        borderWidth: '1px',
      }}
    >
      <Typography variant="body">{text}</Typography>
    </Box>
  </Tooltip>
);

const paletteRow = (key, theme) => {
  const colors = theme.palette[key];
  if (typeof colors === 'object') {
    return (
      <div key={key}>
        <ShowCaseBoxRow>
          <Typography variant="section-heading">{key}</Typography>
        </ShowCaseBoxRow>
        <ShowCaseBoxRow>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={2}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {Object.entries(colors)
              .sort((a, b) => a[0].localeCompare(b[0]))
              .filter((color) => CSS.supports('color', color[1]))
              .map((color) => (
                <ShowCaseBoxColumn>
                  {colorBox(color[1], color[0], theme)}
                </ShowCaseBoxColumn>
              ))}
          </Grid>
        </ShowCaseBoxRow>
      </div>
    );
  }
  if (CSS.supports('color', colors)) {
    return (
      <div key={key}>
        <ShowCaseBoxRow>
          <Typography variant="section-heading">{key}</Typography>
        </ShowCaseBoxRow>
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          <ShowCaseBoxRow>{colorBox(colors, key, theme)}</ShowCaseBoxRow>
        </Grid>
      </div>
    );
  }
  return null;
};

const semanticColors = [
  'neutral',
  'info',
  'success',
  'emphasis',
  'warning',
  'error',
];

const ShowCaseForTheme = ({ theme }) => (
  <ShowCaseBox>
    {semanticColors.map((color) => paletteRow(color, theme))}
    {Object.keys(theme.palette)
      .filter((key) => !semanticColors.includes(key))
      .map((key) => paletteRow(key, theme))}
  </ShowCaseBox>
);

export const ThemeShowcase = () => {
  const classes = useStyles();
  const [whichTheme, setWhichTheme] = useState(WHITELABEL_THEME);
  const whitelabelTheme = useTheme();
  const darkTheme = useMemo(
    () => buildDarkTheme(whitelabelTheme),
    [whitelabelTheme],
  );

  return (
    <ComponentShowCase title="Theme">
      <div className={classes.flexContainer}>
        <Button
          variant={whichTheme === PASSTHROUGH_THEME ? 'primary' : 'secondary'}
          onClick={() => setWhichTheme(PASSTHROUGH_THEME)}
        >
          Passthrough Theme
        </Button>
        {whitelabelTheme !== defaultTheme ? (
          <Button
            variant={whichTheme === WHITELABEL_THEME ? 'primary' : 'secondary'}
            onClick={() => setWhichTheme(WHITELABEL_THEME)}
          >
            Whitelabel Theme
          </Button>
        ) : null}
        <Button
          variant={
            whichTheme === PASSTHROUGH_DARK_THEME ? 'primary' : 'secondary'
          }
          onClick={() => setWhichTheme(PASSTHROUGH_DARK_THEME)}
        >
          Passthrough Dark Theme (only bulk action drawer)
        </Button>
      </div>
      <div
        style={
          whichTheme === PASSTHROUGH_THEME ? undefined : { display: 'none' }
        }
      >
        <ShowCaseForTheme theme={defaultTheme} />
      </div>
      <div
        style={
          whichTheme === WHITELABEL_THEME ? undefined : { display: 'none' }
        }
      >
        <ShowCaseForTheme theme={whitelabelTheme} />
      </div>
      <div
        style={
          whichTheme === PASSTHROUGH_DARK_THEME
            ? undefined
            : { display: 'none' }
        }
      >
        <ShowCaseForTheme theme={darkTheme} />
      </div>
    </ComponentShowCase>
  );
};
