import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    rowGap: '4px',
    margin: theme.spacing(0, 0),
  },
  smallGreyText: {
    color: theme.palette.primary.grey,
    fontSize: '12px',
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'pre',
  },
}));

export function AssociatesDisplay({ associates }) {
  const classes = useStyles();

  const associateLabels = associates.map((associate, i) => {
    const nameAndAssociationKey = `${associate.name}, ${associate.association}`;
    const association = (
      <span
        className={classes.smallGreyText}
      >{` (${associate.association})`}</span>
    );
    const optionalComma = i !== associates.length - 1 ? ', ' : '';

    return (
      <span key={nameAndAssociationKey} className={classes.flexContainer}>
        {associate.name}
        {association}
        {optionalComma}
      </span>
    );
  });

  return <p className={classes.root}>{associateLabels}</p>;
}
