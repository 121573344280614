import React from 'react';
import { Chip, InternalChip, ClickableChip, Icons } from '@passthrough/uikit';
import { ShowCaseBox, ComponentShowCase, ShowCaseBoxRow } from '../show_case';

export const ChipShowCase = () => (
  <ComponentShowCase title="Chip">
    <ShowCaseBox>
      <ShowCaseBoxRow>
        <Chip variant="info" label="Info" />
        <Chip variant="error" label="Error" />
        <Chip variant="neutral" label="Neutral" />
        <Chip variant="success" label="Success" />
        <Chip variant="warning" label="Warning" />
        <Chip size="small" label="Small" />
        <Chip size="medium" label="Medium" />
        <Chip label="Text + Icon" icon={<Icons.Delete />} />
        <ClickableChip label="Clickable chip" />
        <InternalChip label="Internal chip" />
      </ShowCaseBoxRow>
    </ShowCaseBox>
  </ComponentShowCase>
);
