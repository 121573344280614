import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from 'components/alert';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { PageContainer } from 'components/page_container';
import { Spinner } from 'components/spinner';
import * as api from 'services/api';
import { DocumentSigningItem } from 'components/document_signing_item_v2/index';
import { Signed } from 'components/signed';
import { SecondSignerAllDoneDisplay } from 'components/lp_doc/all_done/second_signer_all_done_display';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'block',
  },
  top: {
    marginTop: theme.spacing(4),
  },
  list: {
    padding: 0,
  },
}));

export function SecondSignerPage() {
  const { lpClosingId } = useParams();
  const [documents, setDocuments] = useState(null);
  const [fundName, setFundName] = useState('');
  const [subdocCompleteText, setSubdocCompleteText] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [loadingId, setLoadingId] = useState(null);
  const [alreadySigned, setAlreadySigned] = useState([]);
  const classes = useStyles();

  const docsToSign =
    documents?.filter((doc) => !alreadySigned.includes(doc.id)) || [];

  const complete = alreadySigned.length > 0 && docsToSign.length === 0;

  function getSecondSignatures() {
    setLoading(true);

    api
      .secondSignatures({ lpClosingId })
      .then((response) => {
        setDocuments(response.data.documents);
        setFundName(response.data.fundName);
        setSubdocCompleteText(response.data.subdocCompleteText);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(getSecondSignatures, []);

  if (loading) {
    return <Spinner fullScreen />;
  }

  function getContent() {
    if (docsToSign.length === 0) {
      return <Signed />;
    }
    return (
      <>
        <div className={classes.top} />
        <Typography variant="h6" gutterBottom>
          Documents to sign
        </Typography>
        {errorMsg ? <Alert severity="error">{errorMsg}</Alert> : null}
        <Paper variant="outlined" className={classes.paper}>
          <List className={classes.list}>
            {docsToSign.map((doc, index) => (
              <React.Fragment key={doc.id}>
                {index !== 0 ? <Divider /> : null}

                <DocumentSigningItem
                  name={doc.name}
                  mustSecondSign
                  isPdfReady={doc.isPdfReady}
                  signers={doc.signers}
                  lpDocId={doc.id}
                  lpClosingId={doc.lpClosingId}
                  isLoading={doc.id === loadingId}
                  setLoading={(l) => {
                    setLoadingId(l ? doc.id : null);
                  }}
                  handleSign={() => {
                    setAlreadySigned((a) => [...a, doc.id]);
                  }}
                  alreadySigned={alreadySigned.includes(doc.id)}
                  disabled={!!loadingId}
                  setErrorMsg={setErrorMsg}
                />
              </React.Fragment>
            ))}
          </List>
        </Paper>
      </>
    );
  }

  return complete ? (
    <SecondSignerAllDoneDisplay
      lpClosingId={lpClosingId}
      fundName={fundName}
      customCompletionText={subdocCompleteText}
    />
  ) : (
    <PageContainer maxWidth="md" className={classes.container}>
      {getContent()}
    </PageContainer>
  );
}
