import React from 'react';
import clsx from 'clsx';
import { Typography, Button } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import { TruncatedTextDisplay } from 'components/truncated_text_display';
import { NoteIcon } from './note_icon';
import { NoteIconVariant } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    columnGap: theme.spacing(2),
  },
  contentContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    rowGap: theme.spacing(1),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  noteDisplay: {
    backgroundColor: theme.palette.background.alternative,
    padding: theme.spacing(2),
    overflowWrap: 'anywhere',
  },
  helpText: {
    color: theme.palette.primary.grey,
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

export function DraftNoteDisplay({
  fundName,
  note,
  handleEdit,
  handleDelete,
  className,
  isLoading,
}) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <NoteIcon variant={NoteIconVariant.GP} />

      <div className={classes.contentContainer}>
        <div className={classes.header}>
          <Typography variant="body" size="small">
            <strong>{fundName}</strong> saved a comment:
          </Typography>

          {isLoading ? null : (
            <div className={classes.btnContainer}>
              <Button
                variant="text"
                onClick={handleEdit}
                aria-label="Edit comment"
              >
                Edit
              </Button>
              <Button
                variant="text"
                onClick={handleDelete}
                aria-label="Delete comment"
                data-testid="delete_comment"
              >
                Delete
              </Button>
            </div>
          )}
        </div>

        <Paper elevation={0} className={classes.noteDisplay}>
          <TruncatedTextDisplay
            content={note}
            textColor="default"
            expandText="See full comment"
            contractText="Hide full comment"
          />
        </Paper>
      </div>
    </div>
  );
}
