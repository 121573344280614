import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 3),
    display: 'flex',
    zIndex: 2,
    backgroundColor: theme.palette.primary.white,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  diligenceLabelAndIcon: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  helpIcon: {
    color: theme.palette.primary.grey,
    marginLeft: theme.spacing(1),
  },
  portionApprovedLabel: {
    color: theme.palette.primary.grey,
    alignSelf: 'center',
    marginRight: theme.spacing(2),
  },
}));

export function DiligenceReviewTopBar({
  numApprovedOwners,
  numOwners,
  shouldNotDisplayInstructions,
  setShouldNotDisplayInstructions,
  handleClose,
  className,
  docCollectionMode,
}) {
  const classes = useStyles();

  const portionApprovedMessage = `${numApprovedOwners}/${numOwners} owner${
    numOwners > 1 ? 's' : ''
  } reviewed`;

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.diligenceLabelAndIcon}>
        <Typography variant="h6">Diligence</Typography>

        {docCollectionMode ? null : (
          <Tooltip
            arrow
            title={
              shouldNotDisplayInstructions ? (
                <Typography>Show instructions</Typography>
              ) : (
                ''
              )
            }
          >
            <IconButton
              className={classes.helpIcon}
              disabled={!shouldNotDisplayInstructions}
              onClick={() => {
                setShouldNotDisplayInstructions(false);
              }}
            >
              <HelpIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>

      <Typography className={classes.portionApprovedLabel} variant="body2">
        {portionApprovedMessage}
      </Typography>

      <IconButton
        data-test="close_diligence_review_modal"
        onClick={() => handleClose()}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
}
