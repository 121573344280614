import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import MessageIcon from '@material-ui/icons/Message';

import * as api from 'services/api';
import { useToast } from 'services/toast';
import { KeyValuePair, KeyValuePairs } from 'components/key_value';
import { Button } from 'components/button';
import { DialogStep } from '../dialog_step';

const useStyles = makeStyles((theme) => ({
  button: {
    marginBottom: theme.spacing(2),
  },
}));

export function StepUserRemind({ lpClosingId, onClose, onBack, collaborator }) {
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState('');
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { toast } = useToast();

  const collaboratorDisplay = collaborator.name
    ? `${collaborator.name} (${collaborator.email})`
    : collaborator.email;

  function onSubmit(event) {
    event.preventDefault();
    setLoading(true);
    setMessageError('');

    api
      .lpClosingCollaboratorSendReminder({
        lpClosingId,
        userId: collaborator.userId,
        message,
      })
      .then(() => {
        setLoading(false);
        toast(`Sent reminder to ${collaborator.name || collaborator.email}`);
        setMessage('');
        onBack();
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.message) {
          setMessageError(error.response.message);
        }
      });
  }

  return (
    <DialogStep title="Send reminder" onClose={onClose} onBack={onBack}>
      <form onSubmit={onSubmit}>
        <KeyValuePairs>
          <KeyValuePair icon={<EmailIcon />}>
            <Typography>{collaboratorDisplay} will be notified.</Typography>
          </KeyValuePair>

          <KeyValuePair input icon={<MessageIcon />}>
            <TextField
              autoFocus
              fullWidth
              variant="outlined"
              label="Include a message (optional)"
              multiline
              rows="4"
              helperText={
                messageError ||
                'This message will appear in the reminder email.'
              }
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              error={!!messageError}
            />
          </KeyValuePair>
        </KeyValuePairs>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          className={classes.button}
          loading={loading}
        >
          Send
        </Button>
      </form>
    </DialogStep>
  );
}
