import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline';

import { Typography } from '@passthrough/uikit';

import { Button } from 'components/button';

const useStyles = makeStyles((theme) => ({
  resolutionStatusDisplay: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '4px',
  },
  resolveCommentThreadDisplay: {
    backgroundColor: theme.palette.warning.fill,
  },
  reopenCommentThreadDisplay: {
    backgroundColor: theme.palette.success.fill,
  },
}));

export function ThreadResolutionButtons({
  isResolved,
  loading,
  handleReopenThread,
  handleResolveThread,
}) {
  const classes = useStyles();

  return !isResolved ? (
    <div
      className={clsx(
        classes.resolutionStatusDisplay,
        classes.resolveCommentThreadDisplay,
      )}
    >
      <Typography variant="body" size="small">
        Unresolved comment thread
      </Typography>

      <Button
        data-testid="resolve-thread-button"
        className={classes.resolveButton}
        variant="contained"
        loading={loading}
        startIcon={<CheckCircleIcon />}
        onClick={handleResolveThread}
      >
        Resolve
      </Button>
    </div>
  ) : (
    <div
      className={clsx(
        classes.resolutionStatusDisplay,
        classes.reopenCommentThreadDisplay,
      )}
    >
      <Typography variant="body" size="small">
        Resolved
      </Typography>
      <Button variant="text" loading={loading} onClick={handleReopenThread}>
        Reopen comment thread
      </Button>
    </div>
  );
}
