import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import * as api from 'services/api';
import { Spinner } from 'components/spinner';
import { EmptyState } from 'components/empty_v2';

import { Alert } from '@passthrough/uikit';
import { DataPoint } from './data_point';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2, 8),
    padding: theme.spacing(4),
  },
}));

export function AISummaryDisplay({ questionId, className }) {
  const { fundId, lpClosingId } = useParams();
  const classes = useStyles();

  const [summary, setSummary] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pollingTimer, setPollingTimer] = useState(null);

  useEffect(
    // This looks odd because we're using useEffect only to clean up the timer
    // so it doesn't do anything except return the cleanup function
    () => () => {
      if (pollingTimer) {
        clearTimeout(pollingTimer);
      }
    },
    [questionId, pollingTimer],
  );

  function generateSummary() {
    setIsLoading(true);
    setErrorMsg('');
    api
      .generateDiligenceNodeAISummary({ fundId, lpClosingId, questionId })
      .then((response) => {
        pollForSummary(response.data);
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          setSummary(null);
          setIsLoading(false);
          setErrorMsg(error.response.data.detail);
        }
      });
  }

  function schedulePoll(aiSummaryId) {
    setPollingTimer(
      setTimeout(() => {
        pollForSummary(aiSummaryId);
      }, 2000),
    );
  }

  function pollForSummary(aiSummaryId) {
    api
      .diligenceNodeAISummary({ fundId, lpClosingId, questionId, aiSummaryId })
      .then((response) => {
        switch (response?.data?.result) {
          case 'ERROR':
            setErrorMsg(response.data.error);
            setIsLoading(false);
            setSummary(null);
            break;
          case 'SUCCESS':
            setSummary(response.data.extract || {});
            setErrorMsg(response.data.error || '');
            setIsLoading(false);
            break;
          case 'PENDING':
          default:
            schedulePoll(aiSummaryId);
            break;
        }
      })
      .catch(() => {
        schedulePoll(aiSummaryId);
      });
  }

  if (isLoading) {
    return <Spinner fullScreen />;
  }

  return (
    <div className={clsx(className, classes.root)}>
      {errorMsg ? <Alert severity="error">{errorMsg}</Alert> : null}
      {summary ? (
        <>
          <Alert severity="info">
            This data was automatically extracted from the uploaded document.
          </Alert>
          {Object.entries(summary).map(([key, value]) => (
            <DataPoint key={key} label={key} value={value} />
          ))}
        </>
      ) : (
        <EmptyState
          title="AI summary"
          ctaOnClick={generateSummary}
          ctaText="Generate"
          ctaPermission
        />
      )}
    </div>
  );
}
