import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Typography, Link, UIKitSettingsProvider } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '87px',
    display: 'flex',
    padding: theme.spacing(1, 4),
    alignItems: 'center',
  },
  copyright: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    columnGap: theme.spacing(1.25),
    rowGap: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  copyrightLinks: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    gap: theme.spacing(1.25),
  },
}));

export function Copyright() {
  const classes = useStyles();

  return (
    <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
      <div className={classes.root}>
        <div className={classes.copyright}>
          <Typography variant="label" size="small">
            Copyright © {new Date().getFullYear()} Passthrough, Inc.
          </Typography>
          <div className={classes.copyrightLinks}>
            <Typography variant="label" size="small">
              <Link
                href="https://passthrough.com/privacy"
                variant="external"
                externalIcon={false}
              >
                Privacy
              </Link>
            </Typography>
            <Typography variant="label" size="small">
              <Link
                href="https://passthrough.com/terms"
                variant="external"
                externalIcon={false}
              >
                Terms
              </Link>
            </Typography>
            <Typography variant="label" size="small">
              <Link
                href="https://passthrough.com/cookie-policy"
                variant="external"
                externalIcon={false}
              >
                Cookie policy
              </Link>
            </Typography>
          </div>
        </div>
      </div>
    </UIKitSettingsProvider>
  );
}
