export const DEFAULT_SCREENING_CATEGORIES = {
  SANCTION: 'Sanctions',
  WARNING: 'Warnings',
  FITNESS_PROBITY: 'Fitness and probity',
  PEP: 'PEPs',
};

export const SCREENING_CATEGORIES = {
  ...DEFAULT_SCREENING_CATEGORIES,
  ADVERSE_MEDIA: 'Adverse media',
};
