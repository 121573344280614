import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Modal } from '@passthrough/uikit';
import { useToast } from 'services/toast';

const REUSE = 'reuse';
const GENERATE = 'generate';

export const PasteModal = ({ usedIds, onClose, onSave, open }) => {
  const [contentToPaste, setContentToPaste] = useState(null);
  const [idReuseOption, setIdReuseOption] = useState(GENERATE);
  const [idsThatCannotBeReused, setIdsThatCannotBeReused] = useState([]);
  const { errorToast } = useToast();

  useEffect(() => {
    if (open) {
      navigator.clipboard.readText().then((value) => {
        try {
          setContentToPaste(JSON.parse(value));
        } catch (e) {
          setContentToPaste(null);
          setIdReuseOption(null);
          errorToast('Cannot read question data');
        }
      });
    }
  }, [open]);

  useEffect(() => {
    if (open && contentToPaste) {
      try {
        const IdsToPaste = contentToPaste.reduce(
          (accIds, qData) => accIds.add(qData.id),
          new Set(),
        );

        const nonReusableIds = usedIds.filter((id) => IdsToPaste.has(id));
        setIdsThatCannotBeReused(nonReusableIds);

        if (nonReusableIds.length > 0) {
          setIdReuseOption(GENERATE);
        }
      } catch (e) {
        setContentToPaste(null);
        setIdReuseOption(null);
        errorToast('Cannot read question data');
      }
    }
  }, [open, usedIds, contentToPaste]);

  const cannotReuseIds = idsThatCannotBeReused.length > 0;

  return (
    <Modal
      open={open}
      onClose={onClose}
      headerLabel="Bulk paste"
      primaryButtonText="Paste"
      primaryButtonDisabled={!contentToPaste}
      onSubmit={() => onSave(contentToPaste, idReuseOption === REUSE)}
      showCancelButton
    >
      <Typography>
        Do you want to reuse the original questions ID(s)?
      </Typography>
      <RadioGroup
        name="reuse_id_boxes"
        value={idReuseOption}
        onChange={(e) => {
          setIdReuseOption(e.target.value);
        }}
      >
        <FormControlLabel
          value={REUSE}
          control={<Radio disabled={cannotReuseIds || !contentToPaste} />}
          label="Reuse original IDs"
        />
        <FormControlLabel
          value={GENERATE}
          control={<Radio disabled={!contentToPaste} />}
          label="Generate new IDs"
        />
      </RadioGroup>

      {cannotReuseIds ? (
        <Typography>
          The following question IDs are already in use:{' '}
          {idsThatCannotBeReused.join(', ')}
        </Typography>
      ) : null}
    </Modal>
  );
};
