import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { ANSWER_STATES } from 'services/constants';
import { DiffStyle } from 'components/Diff';
import { MAX_NUM_CHARS } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(1),
  },
}));

function formatOption(option) {
  return option.endsWith('.') ? option : `${option}.`;
}

function truncateMultiSelectAnswer(answer) {
  let runningCumulativeCharCount = 0;
  const truncatedAnswer = [];

  for (let i = 0; i < answer.length; i += 1) {
    const selectedOption = answer[i];
    const formattedOption = formatOption(selectedOption);

    let hitTruncateLimit = false;
    let content = formattedOption;

    if (runningCumulativeCharCount + formattedOption.length > MAX_NUM_CHARS) {
      hitTruncateLimit = true;
      const numRemainingChars = MAX_NUM_CHARS - runningCumulativeCharCount;

      content = `${formattedOption.substring(0, numRemainingChars)}...`;
    }

    truncatedAnswer.push(content);

    if (hitTruncateLimit) {
      break;
    } else {
      runningCumulativeCharCount += formattedOption.length;
    }
  }

  return truncatedAnswer;
}

export function MultiselectDisplay({ answer, questionState, flatten }) {
  const classes = useStyles();

  if (!answer) {
    return null;
  }

  if (flatten) {
    const truncatedAnswers = truncateMultiSelectAnswer(answer);
    return truncatedAnswers.join(' ');
  }

  return (
    <div className={classes.root}>
      {answer.map((a) => (
        <div key={a}>
          <DiffStyle
            isNew={questionState === ANSWER_STATES.CHANGED_CURRENT_ANSWER}
            isDeleted={questionState === ANSWER_STATES.CHANGED_PRIOR_ANSWER}
          >
            {a}
          </DiffStyle>
        </div>
      ))}
    </div>
  );
}
