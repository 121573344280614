import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.appbar.background,
    padding: theme.spacing(1, 1),
  },
  appBarContent: {
    display: 'flex',
    alignItems: 'center',
  },
  appBarText: {
    fontWeight: '500',
  },
}));

export function NavBar({ leftIcon, leftText, handleBack }) {
  const classes = useStyles();

  return (
    <AppBar className={classes.appBar}>
      <div key="back" className={classes.appBarContent}>
        <IconButton
          name="back"
          variant="text"
          color="default"
          onClick={handleBack}
        >
          {leftIcon}
        </IconButton>
        <Typography variant="body1" className={classes.appBarText}>
          {leftText}
        </Typography>
      </div>
    </AppBar>
  );
}
