import React from 'react';
import { Breadcrumbs } from '@passthrough/uikit';
import * as urls from 'services/urls';
import { ShowCaseBox, ComponentShowCase, ShowCaseBoxRow } from '../show_case';

export const CrumbsShowCase = () => (
  <ComponentShowCase title="Breadcrumb">
    <ShowCaseBox>
      <ShowCaseBoxRow>
        <Breadcrumbs
          crumbs={[
            {
              name: 'Parent',
              to: urls.UIKIT_URL,
            },
            {
              name: 'Child With a very long name very very very long',
              to: urls.UIKIT_URL,
            },
            {
              name: 'Child 2',
            },
          ]}
        />
      </ShowCaseBoxRow>
      <ShowCaseBoxRow>
        <Breadcrumbs
          crumbs={[
            {
              name: 'Parent',
              to: urls.UIKIT_URL,
            },
            {
              name: 'Child 1',
              to: urls.UIKIT_URL,
              skeleton: true,
            },
            {
              name: 'Child 2',
              skeleton: true,
            },
          ]}
        />
      </ShowCaseBoxRow>
    </ShowCaseBox>
  </ComponentShowCase>
);
