import React, { useState } from 'react';
import { Modal, Alert } from '@passthrough/uikit';
import { useParams } from 'react-router-dom';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { useToast } from 'services/toast';
import { getSingleErrorFromResponse } from 'services/utils';
import { useDiscardConfirmation } from 'services/discard';
import * as api from 'services/api';
import { EditStep } from './edit_step';
import { ReviewStep } from './review_step';

const EDIT_STEP = 0;
const REVIEW_STEP = 1;

export function SendClosingEmailDialog({
  open,
  onChange,
  handleClose,
  investors,
}) {
  const { fundId, closingId } = useParams();
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState(null);
  const [step, setStep] = useState(EDIT_STEP);
  const { toast } = useToast();

  const count = investors.length;
  const plural = count > 1 ? 's' : '';

  const closeWithConfirmation = useDiscardConfirmation({
    onConfirm: handleClose,
    needConfirmation: Boolean(message) || step === REVIEW_STEP,
  });

  function onSubmit() {
    setIsSending(true);
    api
      .resendClosingEmail({
        fundId,
        closingId,
        message,
        lpClosingIds: investors.map((i) => i.id),
      })
      .then(() => {
        toast(`Sent closing email${plural} to ${count} investor${plural}.`);
        onChange();
        handleClose();
      })
      .catch((e) => {
        setError(getSingleErrorFromResponse(e.response));
      })
      .finally(() => {
        setIsSending(false);
      });
  }

  return (
    <Modal
      open={open}
      onClose={closeWithConfirmation}
      onExited={() => {
        setStep(EDIT_STEP);
        setMessage('');
        setIsSending(false);
      }}
      onSubmit={() => (step === EDIT_STEP ? setStep(REVIEW_STEP) : onSubmit())}
      onBack={step === REVIEW_STEP ? () => setStep(EDIT_STEP) : null}
      cancelButtonText="Cancel"
      showCancelButton
      headerLabel="Send closing email"
      subheaderLabel={`${count} investor${plural} selected`}
      primaryButtonText={step === EDIT_STEP ? 'Next' : 'Send'}
      primaryButtonEndIcon={step === EDIT_STEP ? <ArrowForwardIcon /> : null}
      primaryButtonLoading={isSending}
    >
      {error ? <Alert severity="error">{error}</Alert> : null}

      {step === EDIT_STEP ? (
        <EditStep
          message={message}
          setMessage={setMessage}
          pluralGrammar={plural}
        />
      ) : null}

      {step === REVIEW_STEP ? (
        <ReviewStep message={message} investors={investors} />
      ) : null}
    </Modal>
  );
}
