import React, { useState, useEffect } from 'react';

import * as api from 'services/api';
import { useMe } from 'services/providers/me';
import { useConfirm } from '@passthrough/uikit';
import { useToast } from 'services/toast';
import { formatProfileLastUsed } from 'services/utils';
import { SelectProfileStep } from './select_profile_step';
import { AccessStep } from './access_step';
import { RoleStep } from './role_step';
import { ReviewStep } from './review_step';
import { ArtificialLoadingStep } from './artificial_loading_step';
import { NameWarningDialog } from './name_warning_dialog';
import { NonInvestorWarningDialog } from './non_investor_warning_dialog';
import { NewQuestionnaireDialog } from './new_questionnaire_dialog';
import { MoreInformationModal } from './more_information_modal';
import {
  getCollaboratorId,
  sortAvailableProfiles,
  sortCollaborators,
} from '../helpers';
import { ACCESS_LEVEL_MAPPING, ACCESS_LEVELS } from '../constants';
import {
  SELECT_PROFILE_STEP,
  ACCESS_STEP,
  ROLE_STEP,
  REVIEW_STEP,
  LOADING_STEP,
  IS_INVESTOR_CHOICE,
  NOT_INVESTOR_CHOICE,
} from './constants';

export function ProfileSelectFlow({
  setupProfileId,
  setSetupProfileId,
  organizationName,
  fundName,
  fundLogo,
  lpName,
  lpClosingId,
  availableProfiles,
  restarting,
  forcePrefill,
  setupCollaborators,
  setSetupCollaborators,
  ownedProfileIds,
  documentDisplayType,
  numLpDocAnswers,
  refresh,
}) {
  const [suggestedCollaborators, setSuggestedCollaborators] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [navStep, setNavStep] = useState(SELECT_PROFILE_STEP);
  const [isInvestorChoice, setIsInvestorChoice] = useState(IS_INVESTOR_CHOICE);
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const [nonInvestorWarningDialogOpen, setNonInvestorWarningDialogOpen] =
    useState(false);
  const [newQuestionnaireDialogOpen, setNewQuestionnaireDialogOpen] =
    useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [nameWarningProfile, setNameWarningProfile] = useState(null);
  const [hideRoleStep, setHideRoleStep] = useState(false);
  const confirm = useConfirm();
  const [me] = useMe();
  const { toast, errorToast } = useToast();

  let profileData = null;
  if (setupProfileId === 'new') {
    profileData = {
      displayName: lpName,
      id: 'new',
      isNew: true,
    };
  } else {
    profileData = availableProfiles.find((p) => p.id === setupProfileId);
  }

  const sortedProfiles = sortAvailableProfiles(availableProfiles, lpName);

  const resetState = () => {
    setIsInvestorChoice(IS_INVESTOR_CHOICE);
    setHideRoleStep(false);
  };

  // reset state when going back to step 1
  useEffect(() => {
    if (navStep === SELECT_PROFILE_STEP) {
      resetState();
    }
  }, [navStep]);

  function waitSetupAndRefresh(taskId) {
    setIsSubmitting(true);

    const onError = () => {
      errorToast(
        'An unexpected error occurred. Please refresh and try again. If the problem persists contact support at support@passthrough.com.',
      );
      setIsSubmitting(false);
    };

    api
      .getSetupTaskStatus({ lpClosingId, taskId })
      .then((response) => {
        switch (response.data.status) {
          case 'ERROR':
            onError();
            break;
          case 'SUCCESS':
            refresh();
            break;
          default:
            setTimeout(() => {
              waitSetupAndRefresh(taskId);
            }, 2000); // every 2 seconds
        }
      })
      .catch(onError);
  }

  function saveSetupProfile(collaborators, updatedSetupProfileId = null) {
    const currentSetupProfileId = updatedSetupProfileId || setupProfileId;

    const remappedCollaborators = collaborators.map((c) => ({
      ...c,
      accessLevel: ACCESS_LEVEL_MAPPING[c.accessLevel],
    }));

    setIsSubmitting(true);
    api
      .setupLpClosingProfile({
        lpClosingId,
        profileId:
          currentSetupProfileId === 'new' ? null : currentSetupProfileId,
        collaborators: remappedCollaborators,
        restarting,
        forcePrefill,
        shouldBecomeOwner: isInvestorChoice === IS_INVESTOR_CHOICE,
      })
      .then((response) => {
        waitSetupAndRefresh(response.data.taskId);
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setErrors(error.response.data.collaborators);
        }
        setIsSubmitting(false);
      });
  }

  const onSubmitProfile = () => {
    saveSetupProfile(setupCollaborators);
  };

  const onStartFromScratch = () => {
    setIsSubmitting(true);
    api
      .getLpClosingProfileSetupUsers({
        lpClosingId,
        setupProfileId: null,
        shouldBecomeOwner: false,
      })
      .then((response) => {
        // All lp users should have access to the investment
        // Also starting from scratch means we want to use a new profile
        saveSetupProfile(response.data.collaborators, 'new');
      })
      .catch(() => {
        setIsSubmitting(false);
      });
  };

  const configureSetupWorkflow = () => {
    if (setupProfileId) {
      setIsLoading(true);

      let mappedProfileId = setupProfileId;
      if (
        setupProfileId === 'new' ||
        isInvestorChoice === NOT_INVESTOR_CHOICE
      ) {
        // new profile that doesn't exist yet or we are not the investor,
        // so get us the users who currently have access to the closing
        mappedProfileId = null;
      }

      api
        .getLpClosingProfileSetupUsers({
          lpClosingId,
          setupProfileId: mappedProfileId,
          shouldBecomeOwner: isInvestorChoice === IS_INVESTOR_CHOICE,
        })
        .then((response) => {
          const sortedCollaborators = sortCollaborators(
            response.data.collaborators,
          );
          const collaboratorsWithOriginalAccess = sortedCollaborators.map(
            (c) => ({
              ...c,
              originalAccessLevel: c.accessLevel,
            }),
          );

          if (isInvestorChoice === NOT_INVESTOR_CHOICE) {
            // If you're not an investor you don't update access levels,
            // just give everyone who had access before access again
            setSuggestedCollaborators([]);
            setSetupCollaborators(collaboratorsWithOriginalAccess);
          } else {
            setSuggestedCollaborators(
              collaboratorsWithOriginalAccess.filter(
                (c) => c.accessLevel === null,
              ),
            );
            setSetupCollaborators(
              collaboratorsWithOriginalAccess.filter(
                (c) => c.accessLevel !== null || c.userId === me.id,
              ),
            );
          }
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };
  useEffect(configureSetupWorkflow, [
    lpClosingId,
    setupProfileId,
    isInvestorChoice,
  ]);

  const addNewCollaborator = (collaborator) => {
    const previousEmails = setupCollaborators.map((c) => c.email);

    if (previousEmails.includes(collaborator.email)) {
      setErrors('Collaborator already added');
      return;
    }

    setErrors(null);

    const addedCollaborator = {
      added: true,
      ...collaborator,
    };
    const newCollaborators = setupCollaborators.concat([addedCollaborator]);
    const newEmails = newCollaborators.map((c) => c.email);

    setSetupCollaborators(newCollaborators);
    setSuggestedCollaborators(
      suggestedCollaborators.filter((c) => !newEmails.includes(c.email)),
    );

    toast(`Added ${collaborator.name || collaborator.email}`);
  };

  const deleteCollaborator = (collaborator) => {
    setErrors(null);
    const idOrHash = getCollaboratorId(collaborator);
    const newCollaborators = setupCollaborators.filter(
      (c) => getCollaboratorId(c) !== idOrHash,
    );
    setSetupCollaborators(newCollaborators);
    setSuggestedCollaborators([...suggestedCollaborators, collaborator]);
    toast(`Removed ${collaborator.name || collaborator.email}`);
  };

  const updateSingleCollaboratorPermission = (collaboratorId, accessLevel) => {
    setSetupCollaborators(
      setupCollaborators.map((c) => {
        const id = getCollaboratorId(c);
        if (id === collaboratorId) {
          return { ...c, accessLevel };
        }
        return c;
      }),
    );
  };

  const setAccessLevel = ({ collaboratorId, accessLevel }) => {
    const collaborator = setupCollaborators.find(
      (c) => getCollaboratorId(c) === collaboratorId,
    );
    if (!collaborator) {
      return;
    }

    const { originalAccessLevel } = collaborator;
    const removedProfileAccess =
      originalAccessLevel === ACCESS_LEVELS.OWNER.apiValue &&
      accessLevel === ACCESS_LEVELS.ONLY_THIS_INVESTMENT.apiValue;

    if (removedProfileAccess) {
      confirm({
        description: `Remove ${collaborator.name} as an owner? Users removed
        will be notified via email.`,
        destructive: true,
      })
        .then(() => {
          updateSingleCollaboratorPermission(collaboratorId, accessLevel);
        })
        .catch(() => {});
    } else {
      updateSingleCollaboratorPermission(collaboratorId, accessLevel);
    }
  };

  const onSelectProfile = (profileId) => {
    setSetupProfileId(profileId);

    // If the user selects a profile that they already own, skip the role step
    if (ownedProfileIds.includes(profileId)) {
      setHideRoleStep(true);
      setNavStep(ACCESS_STEP);
    } else {
      setNavStep(ROLE_STEP);
    }
  };

  const cancelLoadingStep = () => setNavStep(REVIEW_STEP);

  function step() {
    switch (navStep) {
      case SELECT_PROFILE_STEP:
        return (
          <SelectProfileStep
            profilesList={sortedProfiles}
            lpName={lpName}
            setupProfileId={setupProfileId}
            setSetupProfileId={setSetupProfileId}
            setNavStep={setNavStep}
            onSelectProfile={onSelectProfile}
            setWarningDialogOpen={setWarningDialogOpen}
            setNameWarningProfile={setNameWarningProfile}
            onStartFromScratch={() => setNewQuestionnaireDialogOpen(true)}
            isSubmitting={isSubmitting}
            restarting={restarting}
            openInfoModal={() => setInfoModalOpen(true)}
          />
        );

      case ROLE_STEP:
        return (
          <RoleStep
            lpName={lpName}
            setNavStep={setNavStep}
            profileData={profileData}
            isInvestorChoice={isInvestorChoice}
            setIsInvestorChoice={setIsInvestorChoice}
            setNonInvestorWarningDialogOpen={setNonInvestorWarningDialogOpen}
          />
        );

      case ACCESS_STEP:
        return (
          <AccessStep
            fundName={fundName}
            lpName={lpName}
            isLoading={isLoading}
            errors={errors}
            addNewCollaborator={addNewCollaborator}
            deleteCollaborator={deleteCollaborator}
            setAccessLevel={setAccessLevel}
            setupCollaborators={setupCollaborators}
            availableProfiles={availableProfiles}
            suggestedCollaborators={suggestedCollaborators}
            setNavStep={setNavStep}
            hideRoleStep={hideRoleStep}
            profileData={profileData}
          />
        );

      case REVIEW_STEP:
        return (
          <ReviewStep
            fundName={fundName}
            fundLogo={fundLogo}
            lpName={lpName}
            setNavStep={setNavStep}
            profileData={profileData}
            isInvestorChoice={isInvestorChoice}
            setupCollaborators={setupCollaborators}
            onSubmit={onSubmitProfile}
            isLoading={isLoading}
            hideRoleStep={hideRoleStep}
            isSubmitting={isSubmitting}
          />
        );

      case LOADING_STEP:
        return (
          <ArtificialLoadingStep
            fundName={fundName}
            lpName={lpName}
            profileName={profileData?.displayName}
            onSubmit={onSubmitProfile}
            onCancel={cancelLoadingStep}
            documentDisplayType={documentDisplayType}
            isSubmitting={isSubmitting}
          />
        );

      default:
        throw new Error(`Invalid step: ${navStep}`);
    }
  }

  return (
    <>
      {step()}
      <NameWarningDialog
        open={warningDialogOpen}
        onClose={() => setWarningDialogOpen(false)}
        lpName={lpName}
        onSelectProfile={onSelectProfile}
        profileId={nameWarningProfile?.id}
        profileName={nameWarningProfile?.displayName}
        lastUsed={formatProfileLastUsed(
          nameWarningProfile?.lastUsedDatetime,
          nameWarningProfile?.lastUsedFundName,
        )}
        collaborators={nameWarningProfile?.collaborators}
      />
      <NonInvestorWarningDialog
        open={nonInvestorWarningDialogOpen}
        onClose={() => {
          setNonInvestorWarningDialogOpen(false);
        }}
        profileName={profileData?.displayName}
        lpName={lpName}
        onSubmit={() => {
          onStartFromScratch();
        }}
        isSubmitting={isSubmitting}
      />
      <NewQuestionnaireDialog
        open={newQuestionnaireDialogOpen}
        onClose={() => {
          setNewQuestionnaireDialogOpen(false);
        }}
        onSubmit={() => {
          onStartFromScratch();
        }}
        isSubmitting={isSubmitting}
        organizationName={organizationName}
        usingPrefilledAnswers={numLpDocAnswers > 0}
      />
      <MoreInformationModal
        open={infoModalOpen}
        onClose={() => setInfoModalOpen(false)}
      />
    </>
  );
}
