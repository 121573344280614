import { v4 as uuidv4 } from 'uuid';
import {
  getWidthFromBoxType,
  getHeightFromBoxType,
} from 'components/document_fields/box_type';

export function transformBoxesForPlacement(boxes) {
  return boxes.map((box) => ({
    ...box,
    id: uuidv4(),
    width: getWidthFromBoxType(box.type, box.width),
    height: getHeightFromBoxType(box.type, box.height),
    // TODO: Add investorType to GP fields
    investorType: 0,
  }));
}

export function transformBoxesToSubmit(boxes) {
  return boxes.map((box) => ({
    ...box,
    lpdocPage: box.page,
  }));
}

export function groupBoxesBySigner(signers, boxes) {
  return signers.map((signer) => {
    const boxesForSigner = boxes.filter(
      (box) => box.signer === signer.pdfBoxSigner,
    );
    return {
      signer,
      boxesForSigner,
    };
  });
}
