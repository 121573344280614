import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { Typography, Icons } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  accordion: {
    borderRadius: '4px',
  },
  accordionContent: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  investorListContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    gap: theme.spacing(2),
  },
  listItem: {
    display: 'list-item',
    listStyleType: 'disc',
    marginLeft: '40px',
  },
}));

export function InvestorsWithFullyExecutedSubDoc({
  investors,
  closingSubDocName,
}) {
  const classes = useStyles();

  return (
    <Accordion variant="elevation" elevation={2} className={classes.accordion}>
      <AccordionSummary
        expandIcon={<Icons.ExpandMore />}
        classes={{
          content: classes.accordionContent,
        }}
      >
        <Icons.ReportProblemOutlined color="warning" />
        <Typography variant="body">
          {investors.length} investor
          {investors.length > 1 ? 's' : ''} will use individual signature
          packets.
        </Typography>
      </AccordionSummary>
      <div className={classes.investorListContainer}>
        <Divider />
        <Typography variant="body">
          The combined signature packet option is only available for{' '}
          {closingSubDocName}. Documents for the following investors will be
          sent individually:
        </Typography>
        <List>
          {investors.map((investor) => (
            <ListItem key={investor.id} dense className={classes.listItem}>
              <Typography variant="body">{investor.lpName}</Typography>
            </ListItem>
          ))}
        </List>
      </div>
    </Accordion>
  );
}
