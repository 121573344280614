import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography } from '@passthrough/uikit';

import { formatCurrency } from 'services/utils';
import { FunnelDivider } from './funnel_divider';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '& + & $leftColor': {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    '& $funnelDivider': {
      display: 'block',
    },
    '&:last-child': {
      '& $lastSectionColor': {
        marginRight: '2px',
      },
      '& $squareEnd': {
        marginLeft: '-4px',
        minWidth: '4px',
        display: 'block',
      },
    },
    '&:first-child': {
      '& $funnelDivider': {
        display: 'none',
      },
      '& $firstSectionColor': {
        marginLeft: '-2px',
      },
      '& $firstSection': {
        alignSelf: 'end',
        [theme.breakpoints.down('md')]: {
          alignSelf: 'inherit',
        },
      },
    },
  },
  funnelDivider: {},
  contentRoot: {
    display: 'flex',
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  firstSection: {},
  contentContainer: {
    flex: 1,
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'end',
    textAlign: 'end',
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  horizontalColorContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: (props) => props.statusColor,
  },
  topColor: {
    zIndex: 1,
    flex: 1,
    transform: 'skew(15deg)',
    height: '4px',
  },
  firstSectionColor: {},
  lastSectionColor: {},
  squareEnd: {
    display: 'none',
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    textAlign: 'end',
    flex: 1,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      textAlign: 'start',
      alignItems: 'start',
      alignSelf: 'start',
    },
  },
  statusDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'end',
    textAlign: 'end',
    rowGap: theme.spacing(1),
    flex: 1,
  },
  leftColor: {
    borderLeft: (props) => `4px solid ${props.statusColor}`,
  },
  investorCount: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '4px',
  },
}));

export const INVESTOR_CLOSING_STATUS_COLORS = {
  UNSENT: 'unsent',
  SENT: 'sent',
  SIGNED: 'signed',
  APPROVED: 'approved',
  EXECUTED: 'executed',
};

export function StatusSummary({
  statusLabel,
  investorCount,
  commitmentAmount,
  color,
  currency,
  showCommitmentValues,
}) {
  const theme = useTheme();
  const useMobileUI = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles({
    useMobileUI,
    statusColor: getColor(investorCount),
  });
  const typographyTextColor =
    investorCount === 0 ? 'text.secondary' : 'text.primary';

  function getColor(numInvestors) {
    if (numInvestors === 0) {
      return theme.palette.investorClosingStatus.unsent;
    }

    switch (color) {
      case INVESTOR_CLOSING_STATUS_COLORS.UNSENT:
        return theme.palette.investorClosingStatus.unsent;
      case INVESTOR_CLOSING_STATUS_COLORS.SENT:
        return theme.palette.investorClosingStatus.sent;
      case INVESTOR_CLOSING_STATUS_COLORS.SIGNED:
        return theme.palette.investorClosingStatus.signed;
      case INVESTOR_CLOSING_STATUS_COLORS.APPROVED:
        return theme.palette.investorClosingStatus.approved;
      case INVESTOR_CLOSING_STATUS_COLORS.EXECUTED:
        return theme.palette.investorClosingStatus.executed;
      default:
        return null;
    }
  }

  return (
    <div className={classes.root}>
      {!useMobileUI ? (
        <div className={classes.horizontalColorContainer}>
          <div
            className={clsx(
              classes.topColor,
              classes.lastSectionColor,
              classes.firstSectionColor,
            )}
          />

          <div className={classes.squareEnd} />
        </div>
      ) : null}

      <div
        className={clsx(classes.contentRoot, classes.firstSection, {
          [classes.leftColor]: useMobileUI,
        })}
      >
        {!useMobileUI ? (
          <FunnelDivider className={classes.funnelDivider} />
        ) : null}

        <div className={classes.contentContainer}>
          <div className={classes.statusContainer}>
            <Typography variant="body" size="small" color={typographyTextColor}>
              {statusLabel}
            </Typography>
          </div>

          <div className={classes.statusDataContainer}>
            {showCommitmentValues ? (
              <div>
                <Typography
                  variant="body"
                  size="large"
                  color={typographyTextColor}
                >
                  {formatCurrency(commitmentAmount, currency)}
                </Typography>
                <Typography variant="body" size="small" color="textSecondary">
                  {currency.code}
                </Typography>
              </div>
            ) : null}

            <div className={classes.investorCount}>
              <Typography variant="body" color={typographyTextColor}>
                {investorCount}
              </Typography>

              <Typography
                variant="body"
                size="small"
                color={typographyTextColor}
              >
                investor{investorCount === 1 ? '' : 's'}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
