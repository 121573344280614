import React from 'react';

import {
  TYPE_PENSION_PLAN,
  TYPE_INDIVIDUAL,
  TYPE_PARTNERSHIP,
  TYPE_OTHER_PRIVATE_CORPORATION,
  TYPE_SUBSIDIARY_OR_AFFILIATE,
  TYPE_LISTED_COMPANY,
  TYPE_LLC,
  TYPE_OTHER_TRUST,
  TYPE_NON_PROFIT,
  TYPE_SOLE_PROPRIETORSHIP,
  TYPE_SPOUSAL_TRUST,
} from '../../../constants';
import { IndividualDetails } from '../../../components/details/individual';
import { TinHqAddressDetails } from '../../../components/details/tin_and_hq_address_details';
import { TinEinHqAddressDetails } from '../../../components/details/tin_or_ein_and_hq_address_details';
import { SpousalTrustDetails } from '../../../components/details/spousal_trust';
import { SoleProprietorshipDetails } from '../../../components/details/sole_proprietorship';
import { PensionPlanDetails } from '../../../components/details/pension_plan';

export function AdditionalUsaV2Details({ form, formErrors, handleChange }) {
  const shouldDisplayTinAndHq = [
    TYPE_OTHER_PRIVATE_CORPORATION,
    TYPE_OTHER_TRUST,
  ].includes(form.type);

  const shouldDisplayTinEinAndHq = [
    TYPE_PARTNERSHIP,
    TYPE_LISTED_COMPANY,
    TYPE_SUBSIDIARY_OR_AFFILIATE,
    TYPE_LLC,
    TYPE_NON_PROFIT,
  ].includes(form.type);

  if (shouldDisplayTinAndHq) {
    return (
      <TinHqAddressDetails
        // use type as key so that we reset the state of the tin component
        // when the type changes but we still render this comp
        key={form.type}
        tin={form.tin}
        tinError={formErrors.tin}
        hqAddress={form.hqAddress}
        hqAddressError={formErrors.hqAddress}
        handleChange={handleChange}
      />
    );
  }

  if (shouldDisplayTinEinAndHq) {
    return (
      <TinEinHqAddressDetails
        key={form.type}
        tinEin={form.tinEin}
        tinEinError={formErrors.tinEin}
        hqAddress={form.hqAddress}
        hqAddressError={formErrors.hqAddress}
        handleChange={handleChange}
      />
    );
  }

  if (form.type === TYPE_INDIVIDUAL) {
    return (
      <IndividualDetails
        dob={form.dob}
        dobError={formErrors.dob}
        ssn={form.ssn}
        ssnError={formErrors.ssn}
        useSsn={form.useSsn}
        handleChange={handleChange}
        residentialAddress={form.residentialAddress}
        residentialAddressError={formErrors.residentialAddress}
      />
    );
  }

  if (form.type === TYPE_SPOUSAL_TRUST) {
    return (
      <SpousalTrustDetails
        tin={form.tin}
        tinError={formErrors.tin}
        handleChange={handleChange}
      />
    );
  }

  if (form.type === TYPE_SOLE_PROPRIETORSHIP) {
    return (
      <SoleProprietorshipDetails
        ssn={form.ssn}
        ssnError={formErrors.ssn}
        useSsn={form.useSsn}
        handleChange={handleChange}
        hqAddress={form.hqAddress}
        hqAddressError={formErrors.hqAddress}
      />
    );
  }

  if (form.type === TYPE_PENSION_PLAN) {
    return (
      <PensionPlanDetails
        hqAddress={form.hqAddress}
        hqAddressError={formErrors.hqAddress}
        handleChange={handleChange}
      />
    );
  }

  return null;
}
