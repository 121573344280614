import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';

import * as api from 'services/api';
import { useConfirm } from '@passthrough/uikit';
import {
  groupDiligenceEventsByTzAwareDate,
  objectEquals,
} from 'services/utils';
import { EmptyState } from 'components/empty_v2';
import { DILIGENCE_EVENT_TYPES } from 'services/thread_utils/constants';
import { doesThreadExist, isThreadResolved } from 'services/thread_utils';
import { EventsPerDateDisplay } from './events_per_date_display';
import { ThreadResolutionButtons } from './thread_resolution_buttons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(3),
  },

  noEventsLabel: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  errorText: {
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
  },
  eventGroupContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(3),
  },
  maxWidth: {
    width: '100%',
  },
}));

function markUnchangedAnswerEvents(changeEvents) {
  let priorAnswer = null;

  for (let i = 0; i < changeEvents.length; i += 1) {
    const event = changeEvents[i];

    if (event.type === DILIGENCE_EVENT_TYPES.ANSWER_SUBMITTED) {
      if (priorAnswer) {
        event.isAnswerUnchanged = objectEquals(priorAnswer, event.answer);
      }

      priorAnswer = event.answer;
    }
  }

  return changeEvents;
}

export function DiligenceActivityTimeline({
  jurisdiction,
  events = [],
  canInteractWithThread,
  fundName,
  questionId,
  draftComment,
  onResolveThread,
  onReopenThread,
}) {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { fundId, lpClosingId } = useParams();
  const classes = useStyles();
  const confirm = useConfirm();

  const annotatedChangeEvents = markUnchangedAnswerEvents(events);
  const { orderedDates, eventsByDate } = groupDiligenceEventsByTzAwareDate(
    annotatedChangeEvents,
  );
  const threadExists = doesThreadExist(events);
  const isResolved = isThreadResolved(events);

  function resolveThread() {
    setLoading(true);
    setHasError(false);

    const props = {
      fundId,
      lpClosingId,
      questionId,
    };
    api
      .diligenceNodeResolveChangeRequest(props)
      .then(() => {
        setLoading(false);
        onResolveThread();
      })
      .catch(() => {
        setLoading(false);
        setHasError(true);
      });
  }

  function handleResolveThread() {
    if (draftComment) {
      confirm({
        title: 'Resolve this thread and delete the saved comment draft?',
        description:
          'The saved comment draft will be deleted. Do you want to resolve this thread?',
        confirmationText: 'Yes, resolve and delete',
        destructive: true,
      })
        .then(resolveThread)
        .catch(() => {});
    } else {
      resolveThread();
    }
  }

  function handleReopenThread() {
    setLoading(true);
    setHasError(false);

    const props = {
      fundId,
      lpClosingId,
      questionId,
    };

    api
      .diligenceNodeReopenThread(props)
      .then(() => {
        setLoading(false);
        onReopenThread();
      })
      .catch(() => {
        setLoading(false);
        setHasError(true);
      });
  }

  if (events.length === 0) {
    return <EmptyState title="No events" />;
  }

  return (
    <div className={classes.root}>
      {threadExists && canInteractWithThread ? (
        <ThreadResolutionButtons
          isResolved={isResolved}
          loading={loading}
          handleReopenThread={handleReopenThread}
          handleResolveThread={handleResolveThread}
        />
      ) : null}

      {hasError ? (
        <div className={classes.errorText}>
          There was an error updating the thread. Please refresh and try again.
        </div>
      ) : null}

      <div className={classes.eventGroupContainer}>
        {orderedDates.map((date) => (
          <EventsPerDateDisplay
            key={date}
            fundName={fundName}
            dateStr={date}
            events={eventsByDate[date]}
            jurisdiction={jurisdiction}
          />
        ))}
      </div>
    </div>
  );
}
