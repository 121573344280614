import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'components/button';
import { ANSWER_STATES } from 'services/constants';
import OpenInNewIcon from 'mdi-material-ui/OpenInNew';
import { UIKitSettingsProvider, Link } from '@passthrough/uikit';
import { DiffStyle } from 'components/Diff';

const useStyles = makeStyles((theme) => ({
  priorFileAnswer: {
    color: theme.palette.primary.black,
    fontWeight: '500',
    fontSize: '0.875rem',
    paddingRight: '5px',
  },
  newFileAnswer: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  redButton: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1.5),
  },
}));

function FileLinkComponent({ file, fileState }) {
  const classes = useStyles();

  function doNothing(e) {
    e.stopPropagation();
  }

  let styleClass = '';

  if (fileState === ANSWER_STATES.CHANGED_PRIOR_ANSWER) {
    styleClass = classes.priorFileAnswer;
  } else if (fileState === ANSWER_STATES.CHANGED_CURRENT_ANSWER) {
    styleClass = classes.newFileAnswer;
  }

  if (
    fileState === ANSWER_STATES.UNCHANGED ||
    fileState === ANSWER_STATES.CHANGED_CURRENT_ANSWER
  ) {
    return (
      /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
      <DiffStyle
        isNew={fileState === ANSWER_STATES.CHANGED_CURRENT_ANSWER}
        onClick={doNothing}
        onKeyDown={doNothing}
      >
        <Link variant="external" href={file.fileUrl} className={styleClass}>
          {file.fileName}
        </Link>
      </DiffStyle>
    );
  }
  if (fileState === ANSWER_STATES.CHANGED_PRIOR_ANSWER) {
    return (
      /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
      <span onClick={doNothing} onKeyDown={doNothing}>
        <Button
          variant="text"
          endIcon={<OpenInNewIcon className={classes.redButton} />}
          onClick={() => {
            window.open(file.fileUrl, '_blank');
          }}
          className={styleClass}
          color="default"
        >
          <DiffStyle isDeleted thin>
            {file.fileName}
          </DiffStyle>
        </Button>
      </span>
    );
  }

  return null;
}

export const FileLink = ({ file, fileState }) => (
  <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
    <FileLinkComponent file={file} fileState={fileState} />
  </UIKitSettingsProvider>
);
