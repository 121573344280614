import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { Answers } from '../answers';
import { UNSAVED } from '../saved';

export function IntegerQuestion({
  updateLpDoc,
  setSaving,
  answer,
  label,
  frontendLabel,
  QuestionStepper,
  QuestionPromptComponent,
}) {
  const initialNumber = answer === null ? '' : answer;
  const [number, setNumber] = useState(initialNumber);
  const [errorMsg, setErrorMsg] = useState('');
  const hasInput = Boolean(number);

  function handleSubmit(e) {
    e.preventDefault();

    setErrorMsg('');

    return updateLpDoc({
      label,
      answer: number,
      skipQuestion: !hasInput,
    }).catch((error) => {
      setSaving(UNSAVED);
      if (error.response?.status === 400) {
        setErrorMsg(error.response.data.answer);
      }
      return error;
    });
  }

  function handleChange(e) {
    setSaving(UNSAVED);
    setNumber(e.target.value);
  }

  // Reset the input when we change questions.
  useEffect(() => {
    setNumber(initialNumber);
    setErrorMsg('');
  }, [label]);

  return (
    <form onSubmit={handleSubmit}>
      <QuestionPromptComponent />
      <Answers>
        <TextField
          id="text"
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
          autoFocus
          label={frontendLabel}
          variant="outlined"
          value={number}
          error={!!errorMsg}
          helperText={errorMsg}
          onChange={handleChange}
          fullWidth
        />
        <QuestionStepper
          handleSubmit={handleSubmit}
          disabled={number === ''}
          hasAnswerChanged={number !== initialNumber}
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
}
