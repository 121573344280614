import * as React from 'react';
import { useSnackbar } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

export function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)} color="inherit">
      <Close />
    </IconButton>
  );
}

export function useToast() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function successToast(msg, props = {}) {
    return enqueueSnackbar(msg, { variant: 'success', ...props });
  }

  function errorToast(msg, props = {}) {
    return enqueueSnackbar(msg, { variant: 'error', ...props });
  }

  function infoToast(msg, props = {}) {
    return enqueueSnackbar(msg, { variant: 'info', ...props });
  }

  function toast(msg, props = {}) {
    return enqueueSnackbar(msg, { variant: 'default', ...props });
  }

  function warningToast(msg, props = {}) {
    return enqueueSnackbar(msg, { variant: 'warning', ...props });
  }

  function dismiss(key) {
    return closeSnackbar(key);
  }

  return {
    successToast,
    errorToast,
    infoToast,
    warningToast,
    toast,
    dismiss,
  };
}
