import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Spinner } from 'components/spinner';
import { EmptyState } from 'components/empty_v2';

import { PageContainer } from 'components/page_container';
import { useFundEdit, useFund, useMembers } from 'services/providers/fund';
import { useCurrency } from 'services/providers/currency';
import * as api from 'services/api';
import * as urls from 'services/urls';
import { useFeatureFlags } from 'services/providers/feature_flag';
import { useMe } from 'services/providers/me';
import { ClosingList } from './closing_list';
import { NewClosingDialog } from './modals/new_closing';
import { NewClosingDialog as NewClosingDialogV2 } from './modals/new_closing_v2';
import { NewJurisdictionDialog } from './modals/new_jurisdiction';
import { CLOSING_TYPES, NEW_CLOSING_MODALS } from './constants';

export function ClosingsPage({ closingType }) {
  const { fundId } = useParams();
  const [closings, setClosings] = useState(null);
  const [me] = useMe();
  const [fund, isLoading] = useFund();
  const [closingDialog, setClosingDialog] = useState('');
  const [fundEdit] = useFundEdit();
  const [members, isLoadingMembers, refreshMembers] = useMembers();
  const currency = useCurrency();
  const { DILIGENCE_ON_SEPARATE_CLOSING } = useFeatureFlags();

  function getClosings() {
    api
      .closings({
        fundId,
        closingType: DILIGENCE_ON_SEPARATE_CLOSING ? closingType : null,
      })
      .then((response) => {
        setClosings(response.data);
      });
  }

  useEffect(getClosings, [fundId, closingType]);
  useEffect(refreshMembers, []);

  if (closings === null || isLoading) {
    return <Spinner fullScreen />;
  }
  const LABELS = {
    closings: {
      emptyTitle: 'No fund closings',
      ctaText: 'Add fund closing',
      action: () => setClosingDialog(NEW_CLOSING_MODALS.NEW_CLOSING),
    },
    diligence: {
      emptyTitle: 'No jurisdictions',
      ctaText: 'Add jurisdiction',
      action: () => setClosingDialog(NEW_CLOSING_MODALS.NEW_JURISDICTION),
    },
  };

  const labels = LABELS[closingType] || LABELS.closings;

  const canAddClosing =
    closingType === CLOSING_TYPES.diligence ? fundEdit && me.isStaff : fundEdit;

  return (
    <PageContainer maxWidth="md" key={closingType}>
      {closingType === CLOSING_TYPES.diligence && !fund?.diligenceEnabled ? (
        <Redirect to={urls.closingsUrl({ fundId })} />
      ) : null}
      {closings.length === 0 ? (
        <EmptyState
          title={labels.emptyTitle}
          ctaText={labels.ctaText}
          ctaOnClick={labels.action}
          ctaPermission={canAddClosing}
        />
      ) : (
        <ClosingList
          closings={closings}
          openNewDialog={labels.action}
          currency={currency}
          closingType={closingType}
        />
      )}
      {closingType === CLOSING_TYPES.closings &&
      fund?.enableDiligenceOnSeparateClosing ? (
        <NewClosingDialogV2
          fundId={fundId}
          open={closingDialog === NEW_CLOSING_MODALS.NEW_CLOSING}
          onClose={() => setClosingDialog('')}
          members={members}
          isLoadingMembers={isLoadingMembers}
        />
      ) : (
        <NewClosingDialog
          managedDiligenceEnabled={fund?.managedDiligenceEnabled}
          fundId={fundId}
          open={closingDialog === NEW_CLOSING_MODALS.NEW_CLOSING}
          onClose={() => setClosingDialog('')}
          members={members}
          isLoadingMembers={isLoadingMembers}
        />
      )}
      {closingType === CLOSING_TYPES.diligence &&
      DILIGENCE_ON_SEPARATE_CLOSING ? (
        <NewJurisdictionDialog
          managedDiligenceEnabled={fund?.managedDiligenceEnabled}
          fundId={fundId}
          open={closingDialog === NEW_CLOSING_MODALS.NEW_JURISDICTION}
          onClose={() => setClosingDialog('')}
          members={members}
          isLoadingMembers={isLoadingMembers}
        />
      ) : null}
    </PageContainer>
  );
}
