import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Answers } from '../../answers';
import { SingleChoiceList, SingleChoiceItem, ActionsSubmit } from '../common';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '500px',
  },
}));

export const ChoiceQuestionReconciliation = ({
  lpDoc,
  activeProfile,
  answersToReconcile,
  question,
  setSaving,
  updateLpDoc,
  QuestionPromptComponent,
  navRef,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState('');
  const sourceByChoice = answersToReconcile.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.answer]: curr.source,
    }),
    {},
  );

  return (
    <div className={classes.root}>
      <QuestionPromptComponent short />
      <Answers>
        <SingleChoiceList
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          error={errorMsg}
        >
          {question.choices.map((choice) => (
            <SingleChoiceItem
              key={choice.text}
              value={choice.text}
              source={sourceByChoice[choice.text]}
              lpDoc={lpDoc}
              activeProfile={activeProfile}
            >
              {choice.text}
            </SingleChoiceItem>
          ))}
        </SingleChoiceList>
        <ActionsSubmit
          navRef={navRef}
          answer={value}
          isContinueDisabled={value === null}
          label={question.label}
          setSaving={setSaving}
          updateLpDoc={updateLpDoc}
          setErrorMsg={setErrorMsg}
        />
      </Answers>
    </div>
  );
};
