import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import GoodFaceIcon from '@material-ui/icons/SentimentVerySatisfied';
import PoorFaceIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import Container from '@material-ui/core/Container';
import clsx from 'clsx';
import { useWhiteLabelConfig } from 'services/providers/theme';
import { REVIEW_TYPES } from './constants';

import { Face } from './face';
import { GoodForm } from './good_form';
import { BadForm } from './bad_form';

const useStyles = makeStyles((theme) => ({
  buttonSpacing: {
    marginRight: theme.spacing(2),
  },
  faceBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    margin: theme.spacing(2, 0),
    textAlign: 'center',
  },
  bottomMargin: {
    marginBottom: theme.spacing(8),
  },
  closingText: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
    fontWeight: '600',
  },
}));

export function ExperienceReviewForm({ submitExperienceReview }) {
  const classes = useStyles();
  const [moodChoice, setMoodChoice] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [provideRecs, setProvideRecs] = useState(false);
  const [messageText, setMessageText] = useState('');
  const { productName } = useWhiteLabelConfig();

  function onSubmit(e, provideRecsButtonPressed = false) {
    e.preventDefault();
    submitExperienceReview(moodChoice, messageText, provideRecsButtonPressed);
    setIsSubmitted(true);
  }

  if (isSubmitted) {
    return (
      <Container maxWidth="sm" className={classes.closingText}>
        <div>
          <Typography variant="subtitle">
            {moodChoice === REVIEW_TYPES.GOOD && provideRecs
              ? 'Thank you! Someone from our team will reach out shortly.'
              : 'Thank you for your response.'}
          </Typography>
        </div>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <form onSubmit={onSubmit}>
        <Typography align="left" variant="subtitle2">
          How was your experience using {productName}?
        </Typography>
        <div
          className={clsx(classes.faceBox, {
            [classes.bottomMargin]: !moodChoice,
          })}
        >
          <Face
            text="Good"
            color="green"
            Icon={GoodFaceIcon}
            onClick={() => {
              setMoodChoice(REVIEW_TYPES.GOOD);
            }}
            selected={moodChoice === REVIEW_TYPES.GOOD}
          />
          <div className={classes.buttonSpacing} />
          <Face
            text="Bad"
            color="red"
            Icon={PoorFaceIcon}
            onClick={() => {
              setMoodChoice(REVIEW_TYPES.BAD);
            }}
            selected={moodChoice === REVIEW_TYPES.BAD}
          />
        </div>
        <Collapse
          in={moodChoice === REVIEW_TYPES.GOOD}
          mountOnEnter
          unmountOnExit
        >
          <GoodForm onSubmit={onSubmit} setProvideRecs={setProvideRecs} />
        </Collapse>
        <Collapse
          mountOnEnter
          unmountOnExit
          in={moodChoice === REVIEW_TYPES.BAD}
        >
          <BadForm
            text={messageText}
            setText={setMessageText}
            setProvideRecs={setProvideRecs}
          />
        </Collapse>
      </form>
    </Container>
  );
}
