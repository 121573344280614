import React from 'react';
import { Address } from 'components/address_v2';

export function PensionPlanDetails({
  hqAddress,
  hqAddressError,
  handleChange,
}) {
  return (
    <Address
      address={hqAddress}
      onChange={(addr) => {
        handleChange('hqAddress', addr);
      }}
      errors={hqAddressError}
      label="Headquarters address"
    />
  );
}
