import React, { useState, useContext, useEffect, useRef } from 'react';
import * as Sentry from '@sentry/react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import Fab from '@material-ui/core/Fab';

import { OfflineDisplay } from './help_display/offline_display';

const useStyles = makeStyles((theme) => ({
  fabV1: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    zIndex: '2',
  },
  fabV2: {
    position: 'fixed',
    bottom: '16px',
    right: '30px',
    zIndex: '3',
    padding: theme.spacing(1, 2),
    textTransform: 'none',
    backgroundColor: theme.palette.primary.white,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    boxShadow:
      '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
  },
  helpLabel: {
    marginLeft: theme.spacing(1),
  },
}));

export const SupportContext = React.createContext();

export function useSupport() {
  return useContext(SupportContext);
}

export function resetSupport() {
  if (window.Kustomer) {
    window.Kustomer.logout();
  }
}

const MAX_NUM_BOOT_SUPPORT_CALLBACKS = 10;
export function bootSupport({ email, jwtToken }) {
  if (window.Kustomer) {
    const callback = (numCallbackIteration) => {
      if (!window.isKustomerStarted) {
        if (numCallbackIteration === MAX_NUM_BOOT_SUPPORT_CALLBACKS) {
          Sentry.captureMessage('Kustomer failed to start.');
          return;
        }

        // Try every 2 seconds until Kustomer is loaded or
        // we hit the max number of callbacks
        setTimeout(() => callback(numCallbackIteration + 1), 2000);
        return;
      }

      window.Kustomer.login({ jwtToken }, (loginCallbackResponse, error) => {
        if (!error) {
          window.Kustomer.describeCustomer({
            attributes: {
              emails: [email],
            },
          });
        }
      });
    };
    callback(1);
  }
}

const DisplayStates = {
  CLOSED: 'CLOSED',
  QUESTION_MENU: 'QUESTION_MENU',
  OFFLINE_HELP: 'OFFLINE_HELP',
};

export function SupportProvider({ children }) {
  const anchor = useRef(null);
  const [displayState, setDisplayState] = useState(DisplayStates.CLOSED);
  const [MenuComponent, setMenuComponent] = useState(null);
  const [MenuComponentProps, setMenuComponentProps] = useState({});
  const [hasDraftComment, setHasDraftComment] = useState(false);

  const classes = useStyles();
  const haveMenu = Boolean(MenuComponent);

  useEffect(() => {
    if (!haveMenu) {
      setDisplayState(DisplayStates.CLOSED);
    }
  }, [MenuComponent]);

  function showSupportV2() {
    // NOTE: since the menuComponent can only be set when LPCommenting is live,
    // this method will continue to function the same as the v1 help button
    // if the lp commenting feature flag is disabled
    if (haveMenu) {
      setDisplayState(DisplayStates.QUESTION_MENU);
    } else if (window.Kustomer) {
      window.Kustomer.open();
      setDisplayState(DisplayStates.CLOSED);
    } else {
      setDisplayState(DisplayStates.OFFLINE_HELP);
    }
  }

  if (window.EXPLORE_MODE) {
    // Disable support button if in explore mode
    return (
      <SupportContext.Provider
        value={{
          showSupport: showSupportV2,
          setMenuComponent,
          setMenuComponentProps,
          hasDraftComment,
          setHasDraftComment,
        }}
      >
        {children}
      </SupportContext.Provider>
    );
  }

  return (
    <SupportContext.Provider
      value={{
        showSupport: showSupportV2,
        setMenuComponent,
        setMenuComponentProps,
        hasDraftComment,
        setHasDraftComment,
      }}
    >
      {children}

      <Fab
        ref={anchor}
        variant="extended"
        aria-label="contact support"
        className={classes.fabV2}
        onClick={showSupportV2}
      >
        <HelpOutlineOutlinedIcon />

        <Typography className={classes.helpLabel} color="textPrimary">
          Help
        </Typography>
      </Fab>

      {haveMenu ? (
        <MenuComponent
          {...MenuComponentProps}
          showMenu={displayState === DisplayStates.QUESTION_MENU}
          anchor={anchor}
          handleClose={() => {
            setDisplayState(DisplayStates.CLOSED);
          }}
        />
      ) : null}

      {displayState === DisplayStates.OFFLINE_HELP ? (
        <OfflineDisplay
          handleClose={() => {
            setDisplayState(DisplayStates.CLOSED);
          }}
        />
      ) : null}
    </SupportContext.Provider>
  );
}
