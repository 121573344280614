/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import MuiPhoneNumber from 'material-ui-phone-number';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Button } from 'components/button';
import { DisplayLink } from 'components/link';
import { objectEquals } from 'services/utils';
import {
  Address,
  getDefaultEmptyAddress,
  isValidAddress,
  objectEqualsEmptyAddress,
} from 'components/address_v2';
import { Answers } from '../answers';
import { UNSAVED } from '../saved';

const useStyles = makeStyles((theme) => ({
  input: {
    margin: theme.spacing(1, 0),
  },
  helperText: {
    marginBottom: theme.spacing(1),
    marginTop: '-4px',
  },
  removeHelper: {
    marginBottom: `-${theme.spacing(1)}px`,
  },
  removeText: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

function parseInitialAddress(answer, defaultEmptyAddress) {
  const initialAddress = {
    ...defaultEmptyAddress,
    ...((answer && answer.googleAddress) || {}),
    ...((answer && answer.manualAddress) || {}),
  };

  return initialAddress;
}

function InternalQuestion({
  updateLpDoc,
  setSaving,
  answer,
  label,
  QuestionStepper,
  addressRequired,
  isRequired,
  QuestionPromptComponent,
}) {
  const classes = useStyles();

  const initialFirstName = (answer && answer.firstName) || '';
  const initialLastName = (answer && answer.lastName) || '';
  const initialEmail = (answer && answer.email) || '';
  const initialPhone = (answer && answer.phone) || '';
  const initialFax = (answer && answer.fax) || '';
  const { emptyAddress } = getDefaultEmptyAddress();
  const initialAddress = parseInitialAddress(answer, emptyAddress);

  const [firstName, setFirstName] = useState(initialFirstName);
  const [lastName, setLastName] = useState(initialLastName);
  const [email, setEmail] = useState(initialEmail);
  const [phone, setPhone] = useState(initialPhone);
  const [fax, setFax] = useState(initialFax);
  const [address, setAddress] = useState(initialAddress);

  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [faxError, setFaxError] = useState('');
  const [addressErrors, setAddressErrors] = useState({});

  const [showFaxInput, setShowFaxInput] = useState(!!initialFax);
  const [showAddressInput, setShowAddressInput] = useState(
    addressRequired || initialAddress.line1.country !== '',
  );

  const phoneNumber = parsePhoneNumberFromString(phone);
  const faxNumber = parsePhoneNumberFromString(fax);

  const isValidPhone = phoneNumber && phoneNumber.isPossible();
  const isValidFax = faxNumber && faxNumber.isPossible();

  const hasInput =
    Boolean(firstName) ||
    Boolean(lastName) ||
    Boolean(email) ||
    (Boolean(phoneNumber) && phoneNumber.nationalNumber) ||
    Boolean(fax) ||
    !objectEqualsEmptyAddress(address);

  function handleSubmit(e) {
    e.preventDefault();

    setFirstNameError('');
    setLastNameError('');
    setEmailError('');
    setPhoneError('');
    setFaxError('');
    setAddressErrors({});

    if (isRequired && !isValidPhone) {
      // This is the one field that needs frontend validation
      // because we don't check phone number validity on the backend.
      setPhoneError('Enter a valid phone number');
      return;
      // TODO: Add backend validation for phone
      // Doing this following will do phone validation before submitting
    }

    if (showFaxInput && !isValidFax) {
      // This is the one field that needs frontend validation
      // because we don't check phone number validity on the backend.
      setFaxError('Enter a valid fax number');
      return;
      // TODO: Add backend validation for fax
      // Doing this following will do fax validation before submitting
    }

    const submitNumber = phoneNumber ? phone : '';
    const props = {
      firstName,
      lastName,
      email,
      phone: submitNumber,
    };

    if (showFaxInput) {
      props.fax = fax;
    }

    if (showAddressInput) {
      const { street, city, state, country, postalCode } = address.line1;
      props.manualAddress = {
        line1: {
          street,
          city,
          state,
          country,
          postalCode,
        },
        line2: address.line2,
      };
    }

    /* eslint-disable-next-line consistent-return */
    return updateLpDoc({ label, answer: props, skipQuestion: !hasInput }).catch(
      (error) => {
        setSaving(UNSAVED);
        if (error.response?.status === 400) {
          setFirstNameError(error.response.data.answer.firstName);
          setLastNameError(error.response.data.answer.lastName);
          setEmailError(error.response.data.answer.email);
          setPhoneError(error.response.data.answer.phone);
          setFaxError(error.response.data.answer.fax);
          if (error.response.data.answer.googleAddress) {
            setAddressErrors(error.response.data.answer.googleAddress);
          } else if (error.response.data.answer.manualAddress) {
            setAddressErrors(error.response.data.answer.manualAddress);
          }
        }
        return error;
      },
    );
  }

  function handleChange(value, field) {
    setSaving(UNSAVED);

    if (field === 'firstName') {
      setFirstName(value);
    } else if (field === 'lastName') {
      setLastName(value);
    } else if (field === 'email') {
      setEmail(value);
    } else if (field === 'phone') {
      setPhone(value);
    } else if (field === 'fax') {
      setFax(value);
    } else if (field === 'address') {
      setAddress(value);
    }
  }

  // Reset the input when we change questions.
  useEffect(() => {
    setFirstName(initialFirstName);
    setLastName(initialLastName);
    setEmail(initialEmail);
    setPhone(initialPhone);
    setFax(initialFax);
    setAddress(initialAddress);

    setFirstNameError('');
    setLastNameError('');
    setEmailError('');
    setPhoneError('');
    setFaxError('');
    setAddressErrors({});

    setShowFaxInput(!!initialFax);
    setShowAddressInput(addressRequired || initialAddress.line1.country !== '');
  }, [label]);

  return (
    <form onSubmit={handleSubmit} autoComplete="chrome-off">
      <QuestionPromptComponent short />
      <Answers tall>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <TextField
              className={classes.input}
              id="firstName"
              autoFocus
              label="First name"
              variant="outlined"
              value={firstName}
              error={!!firstNameError}
              helperText={firstNameError}
              fullWidth
              onChange={(e) => {
                handleChange(e.target.value, 'firstName');
              }}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              className={classes.input}
              id="lastName"
              label="Last name"
              variant="outlined"
              value={lastName}
              error={!!lastNameError}
              helperText={lastNameError}
              fullWidth
              onChange={(e) => {
                handleChange(e.target.value, 'lastName');
              }}
            />
          </Grid>
        </Grid>

        <TextField
          className={classes.input}
          id="email"
          type="email"
          label="Email"
          variant="outlined"
          value={email}
          error={!!emailError}
          helperText={emailError}
          onChange={(e) => {
            handleChange(e.target.value, 'email');
          }}
          fullWidth
        />

        <MuiPhoneNumber
          className={classes.input}
          id="phone"
          type="tel"
          disableAreaCodes
          defaultCountry="us"
          label="Phone number"
          variant="outlined"
          value={phone}
          error={!!phoneError}
          helperText={phoneError}
          onChange={(value) => {
            handleChange(value, 'phone');
          }}
          fullWidth
        />

        <Collapse in={showFaxInput} mountOnEnter>
          <FormHelperText className={classes.removeHelper}>
            <DisplayLink
              onClick={() => {
                setShowFaxInput(false);
              }}
            >
              <span className={classes.removeText}>
                Remove fax number <CloseIcon fontSize="small" />
              </span>
            </DisplayLink>
          </FormHelperText>
          <MuiPhoneNumber
            className={classes.input}
            id="fax"
            type="tel"
            disableAreaCodes
            defaultCountry="us"
            label="Fax number"
            variant="outlined"
            value={fax}
            error={!!faxError}
            helperText={faxError}
            onChange={(value) => {
              handleChange(value, 'fax');
            }}
            fullWidth
          />
        </Collapse>

        <Collapse in={showAddressInput} mountOnEnter>
          {addressRequired ? null : (
            <FormHelperText className={classes.removeHelper}>
              <DisplayLink
                onClick={() => {
                  setShowAddressInput(false);
                }}
              >
                <span className={classes.removeText}>
                  Remove address <CloseIcon fontSize="small" />
                </span>
              </DisplayLink>
            </FormHelperText>
          )}
          <div className={classes.input}>
            <Address
              address={address}
              onChange={(value) => {
                handleChange(value, 'address');
              }}
              errors={addressErrors}
            />
          </div>
        </Collapse>

        <Collapse in={!showFaxInput}>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              setShowFaxInput((b) => !b);
            }}
          >
            Add fax number
          </Button>
        </Collapse>

        <Collapse in={!showAddressInput}>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              setShowAddressInput((b) => !b);
            }}
          >
            Add address
          </Button>
        </Collapse>

        <QuestionStepper
          // TODO: According to our style guide this should not be disabled,
          // but we need backend validation for phone and fax.
          disabled={
            !(
              firstName &&
              lastName &&
              email &&
              phone &&
              isValidPhone &&
              (!showFaxInput || isValidFax) &&
              (!showAddressInput || isValidAddress(address))
            )
          }
          handleSubmit={handleSubmit}
          hasAnswerChanged={
            firstName !== initialFirstName ||
            lastName !== initialLastName ||
            email !== initialEmail ||
            phone !== initialPhone ||
            fax !== initialFax ||
            !objectEquals(address, initialAddress)
          }
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
}

export function ContactQuestion(props) {
  return <InternalQuestion addressRequired={false} {...props} />;
}

export function ContactWithRequiredAddressQuestion(props) {
  return <InternalQuestion addressRequired {...props} />;
}
