import React from 'react';
import { Button } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    columnGap: theme.spacing(2),
  },
}));

function genBtnText(numSelectedFilters) {
  if (numSelectedFilters === 0) {
    return 'Clear filters';
  }

  if (numSelectedFilters === 1) {
    return 'Apply filter';
  }

  return `Apply ${numSelectedFilters} filters`;
}

export function Footer({
  numSelectedFilters,
  disableApplyFiltersBtn,
  handleCancel,
  handleApply,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button variant="secondary" onClick={handleCancel}>
        Cancel
      </Button>

      <Button
        variant="primary"
        disabled={disableApplyFiltersBtn}
        onClick={handleApply}
      >
        {genBtnText(numSelectedFilters)}
      </Button>
    </div>
  );
}
