import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Typography, Alert } from '@passthrough/uikit';

const useStyles = makeStyles(() => ({
  alert: {
    whiteSpace: 'pre-line',
  },
  strong: {
    fontWeight: 600,
  },
}));

function SkipCustomApprovalsWarning({ investors }) {
  const investor = investors[0];
  const hasMultipleInvestors = investors.length > 1;
  const classes = useStyles();
  const investorName = investor.legalName || investor.lpName;
  const currentStatus = investor.customState?.statusText;

  if (hasMultipleInvestors || !currentStatus) {
    return (
      <Alert severity="warning">
        You are approving {hasMultipleInvestors ? 'investors' : 'an investor'}{' '}
        with steps remaining in the approval flow.
      </Alert>
    );
  }

  return (
    <Alert severity="warning">
      {investorName} is currently in the status of{' '}
      <strong className={classes.strong}>{currentStatus}</strong> pending review
      from at least one group.
    </Alert>
  );
}

export function ApproveModal({
  investors,
  open,
  handleClose,
  onApproveClick,
  approvalLoading,
  showSkippingApprovalsWarning,
  errorMsgs,
}) {
  const classes = useStyles();
  const hasMultipleInvestors = investors.length > 1;

  return (
    <Modal
      open={open}
      headerLabel={
        hasMultipleInvestors ? 'Approve investors?' : 'Approve investor?'
      }
      subheaderLabel={
        hasMultipleInvestors ? `${investors.length} investors selected` : ''
      }
      onClose={handleClose}
      showCancelButton
      primaryButtonText="Approve"
      primaryButtonLoading={approvalLoading}
      onSubmit={onApproveClick}
      primaryButtonProps={{
        'data-test': 'lp_closing_approval_confirmation',
      }}
    >
      {showSkippingApprovalsWarning ? (
        <SkipCustomApprovalsWarning investors={investors} />
      ) : null}

      {errorMsgs
        ? errorMsgs.map((errorMsg) => (
            <Alert key={errorMsg} className={classes.alert} severity="error">
              {errorMsg}
            </Alert>
          ))
        : null}

      <Typography>
        The investor
        {hasMultipleInvestors ? 's ' : ' '}
        will not be notified of this action.
      </Typography>
    </Modal>
  );
}
