import React, { useState, useEffect } from 'react';
import { Alert, Modal } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import FormHelperText from '@material-ui/core/FormHelperText';

import { FileUpload, getEmptyFile } from 'components/file_upload';
import * as api from 'services/api';
import { getSingleErrorFromResponse } from 'services/utils';
import { SimilarQuestionnaires } from 'components/similar_questionnaires';

const useStyles = makeStyles((theme) => ({
  errorText: {
    alignSelf: 'center',
    marginBottom: theme.spacing(1),
    margin: theme.spacing(1),
  },
}));

export function UploadDraftFileModal({ showModal, onUpload, onClose }) {
  const [newFile, setNewFile] = useState(() => getEmptyFile());
  const [isLoading, setIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [showLargeFileWarning, setShowLargeFileWarning] = useState(false);
  const [similarQuestionnaires, setSimilarQuestionnaires] = useState([]);
  const { fundId, questionnaireId } = useParams();
  const classes = useStyles();

  function handleSubmit() {
    setIsLoading(true);
    setUploadError(false);
    setShowLargeFileWarning(false);

    api
      .uploadNewDraftFile({
        fundId,
        questionnaireId,
        fileId: newFile.fileId,
      })
      .then((resp) => {
        setShowLargeFileWarning(resp?.data?.showFileSizeWarning);
        setIsLoading(false);
        setSimilarQuestionnaires(resp?.data?.similarQuestionnaires || []);
        onUpload();
      })
      .catch((error) => {
        setIsLoading(false);
        setUploadError(getSingleErrorFromResponse(error.response));
      });
  }

  function handleClose() {
    onClose();
    setSimilarQuestionnaires([]);
  }

  useEffect(() => {
    setUploadError(false);
  }, [newFile.fileId]);

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      size={similarQuestionnaires.length ? 'md' : 'xs'}
      headerLabel="Upload new draft file"
      footer={similarQuestionnaires.length ? null : undefined}
      primaryButtonText="Upload file"
      primaryButtonDisabled={!newFile?.fileId}
      onSubmit={handleSubmit}
      primaryButtonLoading={isLoading}
    >
      {similarQuestionnaires.length ? (
        <SimilarQuestionnaires
          similarQuestionnaires={similarQuestionnaires}
          fundId={fundId}
          questionnaireId={questionnaireId}
          onCopy={() => {
            onUpload();
          }}
        />
      ) : (
        <>
          Select a new file below
          <div>
            <FileUpload file={newFile} onChange={setNewFile} />
          </div>
        </>
      )}

      {showLargeFileWarning ? (
        <Alert severity="warning">This subdoc PDF is larger than 15MB.</Alert>
      ) : null}

      {uploadError ? (
        <FormHelperText error className={classes.errorText}>
          {uploadError}
        </FormHelperText>
      ) : null}
    </Modal>
  );
}
