import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    width: '100%',
  },
  innerNonFlex: {
    width: '100%',
  },
  timeoutContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '24px',
  },
}));

const SMS_TIMEOUT = 30;
const LOCAL_STORAGE_KEY = 'mfa:sms:lastSentAt';

function getRemainingTime(lastSentAt) {
  if (!lastSentAt) return 0;
  const diff = new Date() - new Date(lastSentAt);
  return Math.max(SMS_TIMEOUT - Math.floor(diff / 1000), 0);
}

export function useSmsOtpTimer({ onEnd }) {
  const storedLastSentAt = localStorage.getItem(LOCAL_STORAGE_KEY);
  const initialRemaining = getRemainingTime(storedLastSentAt);

  const [remaining, setRemaining] = useState(initialRemaining);

  const timeoutText = remaining > 0 ? `${remaining}s` : null;
  const allowSend = remaining <= 0;

  const onSend = () => {
    const now = new Date().toISOString();
    localStorage.setItem(LOCAL_STORAGE_KEY, now);
    setRemaining(SMS_TIMEOUT);
  };

  useEffect(() => {
    if (allowSend) return () => {};

    const interval = setInterval(() => {
      setRemaining((prevRemaining) => {
        if (prevRemaining <= 1) {
          localStorage.removeItem(LOCAL_STORAGE_KEY);
          onEnd();
          return 0;
        }
        return prevRemaining - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [allowSend]);

  return { timeoutText, allowSend, onSend };
}

export function ResendVerificationButton({
  allowSend,
  timeoutText,
  ...buttonProps
}) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.innerNonFlex}>
        <Button
          {...buttonProps}
          disabled={buttonProps.disabled || !allowSend}
        />
      </div>
      {allowSend ? null : (
        <div className={classes.timeoutContainer}>
          <Typography variant="body" size="small">
            {timeoutText}
          </Typography>
        </div>
      )}
    </div>
  );
}
