import { AddressQuestion } from './generics/address';
import { BlockerQuestion } from './generics/blocker';
import { NameQuestion } from './generics/name';
import { NameWithTitleQuestion } from './generics/name_with_title';
import { NameRequiredTitleQuestion } from './generics/name_required_title';
import { NameTitleEmailQuestion } from './generics/name_title_email';
import { InfoQuestion } from './generics/info';
import { SSNQuestion } from './generics/ssn';
import { SSNWithEscapeQuestion } from './generics/ssn_with_escape';
import { GIINQuestion } from './generics/giin';
import { GIINWithEscapeQuestion } from './generics/giin_with_escape';
import { TaxIDQuestion } from './generics/taxid';
import { TaxIDWithEscapeQuestion } from './generics/taxid_with_escape';
import { ABAQuestion } from './generics/aba';
import { TextQuestion, LongTextQuestion } from './generics/text';
import { FileUploadQuestion } from './generics/upload';
import { MultiFileUploadQuestion } from './generics/multi_upload';
import { YesOrNoQuestion, TrueOrFalseQuestion } from './generics/yes_or_no';
import { AffirmationQuestion } from './generics/affirmation';
import { EmailQuestion } from './generics/email';
import { TelQuestion } from './generics/tel';
import { DollarsQuestion } from './generics/dollars';
import { IntegerQuestion } from './generics/number';
import { CountryQuestion } from './generics/country';
import { StateQuestion } from './generics/state';
import { StateWithEscapeQuestion } from './generics/state_with_escape';
import { DateQuestion } from './generics/date';
import { DateYearQuestion } from './generics/date_year';
import { DateWithoutYearQuestion } from './generics/date_without_year';
import { SelectQuestion } from './generics/select';
import { MultiSelectQuestion } from './generics/multi_select';
import { PercentageQuestion } from './generics/percent';
import { BankQuestion } from './generics/bank';
import { BankWithAddressQuestion } from './generics/bank_with_address';
import { BankV2Question } from './generics/bank_v2';
import { W9V3Question } from './generics/w9_v3';
import { W9V4Question } from './generics/w9_v4';
import { CrsTaxDetailsQuestion } from './generics/crs_tax_details';
import { CrsTaxDetailsV2Question } from './generics/crs_tax_details_v2';
import {
  ContactQuestion,
  ContactWithRequiredAddressQuestion,
} from './generics/contact';
import { ContactWithNationalityQuestion } from './generics/contact_with_nationality';
import { ContactNameAndEmail } from './generics/contact_name_and_email';
import {
  RootDiligenceQuestion,
  ChildDiligenceQuestion,
} from './diligence/index';

export function componentForQuestion(q) {
  switch (q.answerType) {
    case 'AnswerType.yes_or_no':
      return YesOrNoQuestion;
    case 'AnswerType.true_or_false':
      return TrueOrFalseQuestion;
    case 'AnswerType.text':
      return TextQuestion;
    case 'AnswerType.text_long':
      return LongTextQuestion;
    case 'AnswerType.name':
      return NameQuestion;
    case 'AnswerType.name_with_title':
      return NameWithTitleQuestion;
    case 'AnswerType.name_required_title':
      return NameRequiredTitleQuestion;
    case 'AnswerType.name_title_email':
      return NameTitleEmailQuestion;
    case 'AnswerType.contact_with_nationality':
      return ContactWithNationalityQuestion;
    case 'AnswerType.tax_id':
      return TaxIDQuestion;
    case 'AnswerType.tax_id_with_escape':
      return TaxIDWithEscapeQuestion;
    case 'AnswerType.ssn':
      return SSNQuestion;
    case 'AnswerType.ssn_with_escape':
      return SSNWithEscapeQuestion;
    case 'AnswerType.giin':
      return GIINQuestion;
    case 'AnswerType.giin_with_escape':
      return GIINWithEscapeQuestion;
    case 'AnswerType.aba':
      return ABAQuestion;
    case 'AnswerType.address':
      return AddressQuestion;
    case 'AnswerType.email':
      return EmailQuestion;
    case 'AnswerType.tel':
      return TelQuestion;
    case 'AnswerType.dollars':
      return DollarsQuestion;
    case 'AnswerType.integer':
      return IntegerQuestion;
    case 'AnswerType.country':
      return CountryQuestion;
    case 'AnswerType.state':
      return StateQuestion;
    case 'AnswerType.state_with_escape':
      return StateWithEscapeQuestion;
    case 'AnswerType.date':
      return DateQuestion;
    case 'AnswerType.date_year':
      return DateYearQuestion;
    case 'AnswerType.date_without_year':
      return DateWithoutYearQuestion;
    case 'AnswerType.file_upload':
      return FileUploadQuestion;
    case 'AnswerType.multi_file_upload':
      return MultiFileUploadQuestion;
    case 'AnswerType.choice':
      return SelectQuestion;
    case 'AnswerType.multi_select':
      return MultiSelectQuestion;
    case 'AnswerType.info':
      return InfoQuestion;
    case 'AnswerType.affirmation':
      return AffirmationQuestion;
    case 'AnswerType.percentage':
      return PercentageQuestion;
    case 'AnswerType.bank':
      return BankQuestion;
    case 'AnswerType.bank_with_address':
      return BankWithAddressQuestion;
    case 'AnswerType.bank_v2':
      return BankV2Question;
    case 'AnswerType.contact':
      return ContactQuestion;
    case 'AnswerType.contact_with_required_address':
      return ContactWithRequiredAddressQuestion;
    case 'AnswerType.contact_name_and_email':
      return ContactNameAndEmail;
    case 'AnswerType.w9_v3':
      return W9V3Question;
    case 'AnswerType.w9_v4':
      return W9V4Question;
    case 'AnswerType.crs_tax_details':
      return CrsTaxDetailsQuestion;
    case 'AnswerType.crs_tax_details_v2':
      return CrsTaxDetailsV2Question;
    case 'AnswerType.blocker':
      return BlockerQuestion;
    case 'DiligenceAnswerType.unknown':
      return RootDiligenceQuestion;
    case 'DiligenceAnswerType.name_known':
      return ChildDiligenceQuestion;
    default:
      throw new Error(`unknown question ${q.answerType}`);
  }
}
