import React from 'react';
import { InputDiv } from '../common/input_div';
import { InteroperableInput } from '../common/interoperable_input';
import { CustomLogicCheckbox } from './has_custom_logic';

export const CheckboxNotesInput = ({
  hasCustomLogic,
  setHasCustomLogic,
  notInteroperable,
  setNotInteroperable,
  disableInteroperableNote,
}) => (
  <InputDiv>
    <div key="checkboxes">
      {!disableInteroperableNote ? (
        <InteroperableInput
          notInteroperable={notInteroperable}
          setNotInteroperable={setNotInteroperable}
        />
      ) : null}

      <CustomLogicCheckbox
        hasCustomLogic={hasCustomLogic}
        setHasCustomLogic={setHasCustomLogic}
      />
    </div>
  </InputDiv>
);
