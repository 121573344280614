import React from 'react';
import clsx from 'clsx';
import { Typography as PassTypography } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import StickerTextOutline from 'mdi-material-ui/StickerTextOutline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  tooltipArrow: {
    color: theme.palette.primary.main,
  },
  draftCommentIcon: {
    color: theme.palette.error.main,
  },
  unresolvedThreadIcon: {
    color: theme.palette.warning.main,
  },
  updatedAnswerIcon: {
    color: theme.palette.warning.main,
  },
  otherQuestionsIcon: {
    color: theme.palette.info.main,
  },
  pageText: {
    fontWeight: 500,
  },
  pageTextMargin: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  listItem: {
    padding: `0px ${theme.spacing(1)}px 0px ${theme.spacing(0.5)}px`,
  },
  iconRoot: {
    minWidth: '0px',
    paddingRight: '5px',
  },
}));

function pluralize(number) {
  return number > 1 ? 's' : '';
}

export function PDFTimelineTooltip({
  children,
  pageNum,
  numPages,
  numQuestionsOnPage,
  activeQuestionOnPage,
  activeDraftCommentOnPage,
  activeUnresolvedThreadOnPage,
  activeUpdatedAnswerOnPage,
  numDraftCommentsOnPage,
  numUnresolvedThreadsOnPage,
  numUpdatedAnswersOnPage,
  pageHasInternalNote,
  lpVersion = false,
}) {
  const classes = useStyles();
  const showList =
    Boolean(numDraftCommentsOnPage) ||
    Boolean(numUnresolvedThreadsOnPage) ||
    Boolean(numUpdatedAnswersOnPage);

  function ListItemWrapper({ id, icon, text }) {
    return (
      <ListItem key={id} className={classes.listItem}>
        <ListItemIcon className={classes.iconRoot}>{icon}</ListItemIcon>
        <ListItemText>{text}</ListItemText>
      </ListItem>
    );
  }

  function getSelectedIconClass() {
    if (activeDraftCommentOnPage) {
      return classes.draftCommentIcon;
    }
    if (activeUnresolvedThreadOnPage) {
      return classes.unresolvedThreadIcon;
    }
    if (activeUpdatedAnswerOnPage) {
      return classes.updatedAnswerIcon;
    }
    return classes.otherQuestionsIcon;
  }

  return (
    <Tooltip
      arrow
      classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
      title={
        <>
          <div
            className={clsx({
              [classes.pageTextMargin]: showList,
            })}
          >
            <Typography className={classes.pageText}>
              Page {pageNum} of {numPages}
            </Typography>
            {numQuestionsOnPage > 0 ? (
              <PassTypography variant="label" color="text.secondary">
                {numQuestionsOnPage} question{pluralize(numQuestionsOnPage)}
              </PassTypography>
            ) : null}
          </div>
          {showList && (
            <List>
              {activeQuestionOnPage && (
                <ListItemWrapper
                  id="selected"
                  icon={
                    <FiberManualRecordIcon
                      fontSize="small"
                      className={getSelectedIconClass()}
                    />
                  }
                  text={<Typography>Selected question</Typography>}
                />
              )}

              {numDraftCommentsOnPage > 0 && (
                <ListItemWrapper
                  id="notes"
                  icon={
                    <FiberManualRecordIcon
                      className={classes.draftCommentIcon}
                      fontSize="small"
                    />
                  }
                  text={
                    <Typography>
                      {numDraftCommentsOnPage} saved comment draft
                      {pluralize(numDraftCommentsOnPage)}
                    </Typography>
                  }
                />
              )}

              {!lpVersion && numUnresolvedThreadsOnPage > 0 ? (
                <ListItemWrapper
                  id="unresolvedThreads"
                  icon={
                    <FiberManualRecordIcon
                      className={classes.unresolvedThreadIcon}
                      fontSize="small"
                    />
                  }
                  text={
                    <Typography>
                      {numUnresolvedThreadsOnPage} unresolved comment thread
                      {pluralize(numUnresolvedThreadsOnPage)}
                    </Typography>
                  }
                />
              ) : null}

              {numUpdatedAnswersOnPage > 0 ? (
                <ListItemWrapper
                  id="updatedAnswers"
                  icon={
                    <FiberManualRecordIcon
                      className={classes.updatedAnswerIcon}
                      fontSize="small"
                    />
                  }
                  text={
                    <Typography>
                      {numUpdatedAnswersOnPage} updated answer
                      {pluralize(numUpdatedAnswersOnPage)}
                    </Typography>
                  }
                />
              ) : null}

              {pageHasInternalNote ? (
                <ListItemWrapper
                  id="internalNote"
                  icon={<StickerTextOutline fontSize="small" />}
                  text={<Typography>Internal notes</Typography>}
                />
              ) : null}
            </List>
          )}
        </>
      }
    >
      {children}
    </Tooltip>
  );
}
