import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';

import { useMe } from 'services/providers/me';
import { Typography, Button, Icons, InternalChip } from '@passthrough/uikit';
import { useFundEdit } from 'services/providers/fund';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    // Make sure the spacing is the same when the buttons are hidden
    minHeight: '36px',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    padding: theme.spacing(3),
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
  chipMargin: {
    marginLeft: theme.spacing(1),
  },
}));

function EditButton({ onEdit, internal, readOnly }) {
  const [me] = useMe();
  const [fundEdit] = useFundEdit();
  const classes = useStyles();

  if (!fundEdit || readOnly) {
    return null;
  }
  if (internal && !me.isStaff) {
    return (
      <Typography variant="label" size="small" color="text.secondary">
        Contact support to edit
      </Typography>
    );
  }

  return (
    <Button
      variant="secondary"
      startIcon={<Icons.EditOutlined />}
      onClick={onEdit}
    >
      Edit
      {internal ? <InternalChip className={classes.chipMargin} /> : null}
    </Button>
  );
}

export function Setting({ children, label, description = null }) {
  const classes = useStyles();
  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <div className={classes.col}>
          <Typography variant="card-heading">{label}</Typography>
          {description}
        </div>
      </Grid>
      <Grid item xs={6}>
        {children}
      </Grid>
    </Grid>
  );
}

export function SettingsGroup({
  header,
  subheader = null,
  internal = false,
  readOnly = false,
  onEdit,
  children,
}) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.headerRow}>
        <div className={classes.headerContainer}>
          <Typography variant="card-heading">{header}</Typography>
          {subheader}
        </div>
        <EditButton onEdit={onEdit} internal={internal} readOnly={readOnly} />
      </div>
      <Card variant="elevation" elevation={2} className={classes.card}>
        {children}
      </Card>
    </div>
  );
}
