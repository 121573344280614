import React from 'react';

import { Button } from '@passthrough/uikit';
import * as api from 'services/api';
import { useSignContext } from 'services/providers/sign';

export function CountersignButton({
  lpDocId,
  lpClosingId,
  isLoading,
  disabled,
  setLoading,
  setErrorMsg,
  handleSign,
  onClick,
}) {
  const { openSigningDialog } = useSignContext();

  function getSignaturePage() {
    setLoading(true);

    api
      .countersignaturePage({ lpClosingId, lpDocId })
      .then((response) =>
        openSigningDialog({
          url: response.data.iframe,
          onSign: () => handleSign(),
          onClose: () => setLoading(false),
        }),
      )
      .catch((error) => {
        if (error.response?.status === 409 || error.response?.status === 400) {
          setErrorMsg(error.response.data.detail);
        }
      });
  }

  return (
    <Button
      variant="primary"
      disabled={disabled}
      loading={isLoading}
      onClick={onClick || getSignaturePage}
      htmlProps={{ 'data-test': 'sign' }}
    >
      Sign
    </Button>
  );
}
