import React from 'react';
import Divider from '@material-ui/core/Divider';

import { getAnswerDisplayComponent } from 'components/answer_displays/get_answer_display';

import { DataItem } from './data_item';

export const DataTags = ({ tags }) => (
  <>
    {tags.map((tag, currIndex) => {
      const AnswerComponent = getAnswerDisplayComponent(tag.answerType);

      return (
        <>
          {currIndex !== 0 ? <Divider /> : null}
          <DataItem
            answerType={tag.tagDisplay}
            answer={<AnswerComponent answer={tag.answer} />}
          />
        </>
      );
    })}
  </>
);
