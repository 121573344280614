import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';

import { ThreadCountChip } from 'components/thread_count_chip';

const useCustomTabStyle = makeStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  selected: {
    '&$selected': {
      color: theme.palette.primary.main,
      fontWeight: '500',
    },
    '&:hover': {
      backgroundColor: theme.palette.success.background,
    },
    backgroundColor: theme.palette.success.background,
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 1, 1, 2),
    fontWeight: '400',
    maxWidth: 'none',
    opacity: 1,
  },
  tabLabel: {
    fontSize: '1rem',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    width: '100%',
  },
  relationshipText: {
    color: theme.palette.primary.grey,
    fontWeight: '300',
    fontSize: '0.8rem',
  },
  nameIconContainer: {
    display: 'flex',
    columnGap: theme.spacing(1),
  },
}));

export function NodeTab({ name, value, relationship, numComments, ...props }) {
  const classes = useStyles();
  const customTabStyles = useCustomTabStyle();

  return (
    <Tab
      value={value}
      disableRipple
      classes={customTabStyles}
      className={classes.root}
      label={
        <div className={classes.tabLabel}>
          <div className={classes.nameIconContainer}>
            {name}

            <ThreadCountChip threadCount={numComments} />
          </div>

          <div className={classes.relationshipText}>{relationship}</div>
        </div>
      }
      {...props}
    />
  );
}
