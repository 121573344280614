// deletion does not get its own stage because
// it simply requires a confirmation
export const TAGGING_MODAL_STAGES = {
  APPLY_TAGS: 'APPLY_TAGS',
  CREATE_TAG: 'CREATE_TAG',
  MANAGE_TAGS: 'MANAGE_TAGS',
  EDIT_TAG_GROUP: 'EDIT_TAG_GROUP',
  EDIT_TAG: 'EDIT_TAG',
};

export const INVESTOR_TAGGING_REDUCER_ACTIONS = {
  HANDLE_CLOSE: 'HANDLE_CLOSE',
  HANDLE_OPEN: 'HANDLE_OPEN',
  MOVE_BACKWARDS: 'MOVE_BACKWARDS',
  UPDATE_CHECKED_TAGS: 'UPDATE_CHECKED_TAGS',
  MANAGE_TAGS: 'MANAGE_TAGS',
  EDIT_TAG_NAME: 'EDIT_TAG_NAME',
  EDIT_TAG_GROUP_NAME: 'EDIT_TAG_GROUP_NAME',
  CREATE_TAG: 'CREATE_TAG',
  EDIT_TAG: 'EDIT_TAG',
  EDIT_TAG_GROUP: 'EDIT_TAG_GROUP',
  DELETE_TAG: 'DELETE_TAG',
};
