import * as React from 'react';
import { Alert, Link } from '@passthrough/uikit';
import * as riskConstants from './constants';

const ALERT_MESSAGE = {
  [riskConstants.CRITICAL_RISK]:
    'The recommended risk rating for this investor is critical.',
  [riskConstants.HIGH_RISK]:
    'The recommended risk rating for this investor is high.',
};

export function RiskRecommendationAlert({ diligence, goToSubmission }) {
  const message = ALERT_MESSAGE[diligence?.riskRecommendation];

  if (!message) {
    return null;
  }

  return (
    <Alert
      severity="error"
      action={
        goToSubmission ? (
          <Link tab="diligence" href={diligence.reviewUrl}>
            Go to submission
          </Link>
        ) : null
      }
    >
      {message}
    </Alert>
  );
}
