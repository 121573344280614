import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import { Typography } from '@passthrough/uikit';

import { DELIVERY_OPTION_INDIVIDUAL_PACKETS } from './constants';

const useStyles = makeStyles((theme) => ({
  profileIcon: {
    backgroundColor: theme.palette.info.fill,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  listItem: {
    padding: 0,
  },
}));

const getInitials = (fullName) => {
  const allNames = fullName.trim().split(' ');
  const initials = allNames.reduce((acc, curr, index) => {
    let newAcc = acc;
    if (index === 0 || index === allNames.length - 1) {
      newAcc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return newAcc;
  }, '');
  return initials;
};

function Countersigner({
  members,
  countersignerLabel,
  showPacketCounts,
  changeCountersignerPacketCount,
  deliveryOption,
}) {
  const classes = useStyles();

  function packetCount(member) {
    if (!showPacketCounts) {
      return null;
    }

    const numPackets =
      changeCountersignerPacketCount ||
      (deliveryOption === DELIVERY_OPTION_INDIVIDUAL_PACKETS
        ? member.individualPacketCount
        : member.bulkPacketCount);

    return (
      <Typography variant="body" color="text.primary">
        {numPackets} packet{numPackets > 1 ? 's' : ''}
      </Typography>
    );
  }

  return (
    <div className={classes.container}>
      {countersignerLabel ? (
        <Typography variant="body" size="small" color="text.secondary">
          {countersignerLabel}
        </Typography>
      ) : null}
      {members.map((member) => (
        <div className={classes.row}>
          <ListItem className={classes.listItem}>
            <ListItemAvatar>
              <Avatar className={classes.profileIcon}>
                <Typography variant="body" color="text.primary">
                  {getInitials(member.name)}
                </Typography>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={member.name} secondary={member.email} />
          </ListItem>
          {packetCount(member)}
        </div>
      ))}
    </div>
  );
}

function ChangingCountersignersList({
  countersigners,
  closingSubDoc,
  deliveryOption,
  bulkEnvelopeInvestorCount,
  individualPacketCount,
  members,
}) {
  const classes = useStyles();
  const { countersigner, countersigner2, countersigner3 } = countersigners;
  const { numberOfCountersigners } = closingSubDoc;
  let packetCount;
  if (deliveryOption === DELIVERY_OPTION_INDIVIDUAL_PACKETS) {
    packetCount = bulkEnvelopeInvestorCount + individualPacketCount;
  } else {
    packetCount = 1 + individualPacketCount;
  }
  const getMember = (memberId) =>
    members.find((member) => member.id === memberId);
  const firstCountersignerLabel =
    numberOfCountersigners > 1 ? 'Countersigner 1' : null;
  const headerLabel =
    numberOfCountersigners > 1 ? 'Countersigners' : 'Countersigner';
  return (
    <div className={classes.section}>
      <Typography variant="card-heading">{headerLabel}</Typography>
      <Paper variant="outlined">
        {numberOfCountersigners > 0 ? (
          <Countersigner
            members={[getMember(countersigner)]}
            countersignerLabel={firstCountersignerLabel}
            showPacketCounts
            changeCountersignerPacketCount={packetCount}
            deliveryOption={deliveryOption}
          />
        ) : null}
        {numberOfCountersigners > 1 ? (
          <>
            <Divider />
            <Countersigner
              members={[getMember(countersigner2)]}
              countersignerLabel="Countersigner 2"
              showPacketCounts
              changeCountersignerPacketCount={packetCount}
              deliveryOption={deliveryOption}
            />
          </>
        ) : null}
        {numberOfCountersigners > 2 ? (
          <>
            <Divider />
            <Countersigner
              members={[getMember(countersigner3)]}
              countersignerLabel="Countersigner 3"
              showPacketCounts
              changeCountersignerPacketCount={packetCount}
              deliveryOption={deliveryOption}
            />
          </>
        ) : null}
      </Paper>
    </div>
  );
}

export function CountersignerList({
  allowChangeCountersigners,
  countersignerData,
  deliveryOption,
  countersigners,
  closingSubDoc,
  bulkEnvelopeInvestorCount,
  individualPacketCount,
  members,
  showPacketCounts = false,
}) {
  const classes = useStyles();

  if (allowChangeCountersigners) {
    return (
      <ChangingCountersignersList
        countersigners={countersigners}
        closingSubDoc={closingSubDoc}
        deliveryOption={deliveryOption}
        bulkEnvelopeInvestorCount={bulkEnvelopeInvestorCount}
        individualPacketCount={individualPacketCount}
        members={members}
      />
    );
  }

  const { numSigners, signers } = countersignerData;
  const { first, second, third } = signers;

  const firstCountersignerLabel = numSigners > 1 ? 'Countersigner 1' : null;
  const headingLabel = numSigners > 1 ? 'Countersigners' : 'Countersigner';

  return (
    <div className={classes.section}>
      <Typography variant="card-heading">{headingLabel}</Typography>
      <Paper variant="outlined">
        <>
          <Countersigner
            members={first}
            countersignerLabel={firstCountersignerLabel}
            showPacketCounts={showPacketCounts}
            deliveryOption={deliveryOption}
          />
          {second.length > 0 ? (
            <>
              <Divider />
              <Countersigner
                members={second}
                countersignerLabel="Countersigner 2"
                showPacketCounts={showPacketCounts}
                deliveryOption={deliveryOption}
              />
            </>
          ) : null}
          {third.length > 0 ? (
            <>
              <Divider />
              <Countersigner
                members={third}
                countersignerLabel="Countersigner 3"
                showPacketCounts={showPacketCounts}
                deliveryOption={deliveryOption}
              />
            </>
          ) : null}
        </>
      </Paper>
    </div>
  );
}
