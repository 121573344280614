import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { ABAInput } from 'components/aba';
import { Answers } from '../answers';
import { UNSAVED } from '../saved';

export function ABAQuestion({
  updateLpDoc,
  setSaving,
  answer,
  label,
  frontendLabel,
  QuestionStepper,
  QuestionPromptComponent,
}) {
  const initialAba = answer || '';
  const [aba, setAba] = useState(initialAba);
  const [errorMsg, setErrorMsg] = useState('');

  const isValid = aba.match(/\d\d\d\d\d\d\d\d\d/);
  const hasInput = Boolean(aba);

  function handleSubmit(e) {
    e.preventDefault();

    setErrorMsg('');

    return updateLpDoc({ label, answer: aba, skipQuestion: !hasInput }).catch(
      (error) => {
        setSaving(UNSAVED);
        if (error.response?.status === 400) {
          setErrorMsg(error.response.data.answer);
        }
        return error;
      },
    );
  }

  function handleChange(e) {
    setSaving(UNSAVED);
    setAba(e.target.value);
  }

  // Reset the input when we change questions.
  useEffect(() => {
    setAba(initialAba);
    setErrorMsg('');
  }, [label]);

  return (
    <form onSubmit={handleSubmit}>
      <QuestionPromptComponent />
      <Answers>
        <TextField
          id="aba"
          autoFocus
          label={frontendLabel}
          variant="outlined"
          value={aba}
          error={!!errorMsg}
          helperText={errorMsg}
          onChange={handleChange}
          InputProps={{
            inputComponent: ABAInput,
          }}
          fullWidth
        />
        <QuestionStepper
          disabled={!isValid}
          handleSubmit={handleSubmit}
          hasAnswerChanged={aba !== initialAba}
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
}
