import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { DiligenceTIN } from '../tin';

export function PrivateTrustDetails({
  tin,
  tinError,
  trustType,
  trustTypeError,
  handleChange,
}) {
  return (
    <>
      <DiligenceTIN
        label="TIN"
        tin={tin}
        tinError={tinError}
        onChange={(e) => {
          handleChange('tin', e.target.value);
        }}
      />

      <FormControl variant="outlined" fullWidth error={!!trustTypeError}>
        <InputLabel id="subdoc">Trust type</InputLabel>
        <Select
          label="Trust type"
          labelId="trust-type-selection"
          variant="outlined"
          value={trustType}
          onChange={(e) => {
            handleChange('trustType', e.target.value);
          }}
        >
          <MenuItem key="revocable" value="Revocable">
            Revocable
          </MenuItem>
          <MenuItem key="irrevocable" value="Irrevocable">
            Irrevocable
          </MenuItem>
        </Select>
        {trustTypeError ? (
          <FormHelperText>{trustTypeError}</FormHelperText>
        ) : null}
      </FormControl>
    </>
  );
}
