import { withStyles } from '@material-ui/core/styles';
import MatDialog from '@material-ui/core/Dialog';
import MatDialogActions from '@material-ui/core/DialogActions';
import MatDialogContent from '@material-ui/core/DialogContent';
import MatDialogContentText from '@material-ui/core/DialogContentText';
import MatDialogTitle from '@material-ui/core/DialogTitle';

export const Dialog = MatDialog;
export const DialogActions = MatDialogActions;
export const DialogContent = withStyles({
  root: {
    overflowY: 'initial',
  },
})(MatDialogContent);
export const DialogContentText = MatDialogContentText;
export const DialogTitle = withStyles({
  root: {
    textAlign: 'center',
  },
})(MatDialogTitle);
