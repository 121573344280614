import React, { useState } from 'react';

import { getSingleErrorFromResponse } from 'services/utils';
import * as api from 'services/api';
import { MFAFlow } from './mfa';
import { TOTPForm } from './totp_form';

export function SignInTOTPStep({ email, errorComponent, onSwitch }) {
  const [password, setPassword] = useState('');
  const [totpCode, setTotpCode] = useState('');
  const [totpError, setTotpError] = useState('');
  const [loading, setLoading] = useState(false);

  function handleSubmit() {
    setTotpError('');
    setLoading(true);

    return api.loginWithTOTP({ email, password, totpCode }).catch((error) => {
      const { response } = error;

      if (response?.status === 400) {
        setTotpError(
          response?.data.totp || getSingleErrorFromResponse(response),
        );
      } else if (response?.status === 401) {
        setTotpError('Incorrect authentication code.');
      } else if (response?.status === 429) {
        setTotpError(
          'Too many sign in attempts. Please wait a few minutes before trying again.',
        );
      } else {
        setTotpError(getSingleErrorFromResponse(error.response));
      }

      setLoading(false);
      throw error;
    });
  }

  return (
    <MFAFlow
      email={email}
      errorComponent={errorComponent}
      onSwitch={onSwitch}
      password={password}
      setPassword={setPassword}
      methodComponent={
        <TOTPForm
          onSubmit={handleSubmit}
          totpCode={totpCode}
          setTotpCode={setTotpCode}
          totpError={totpError}
          setTotpError={setTotpError}
          loading={loading}
        />
      }
    />
  );
}
