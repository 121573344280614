import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
  labelCell: {
    border: 0,
    paddingLeft: theme.spacing(2),
    color: theme.palette.greyText,
  },
  answerCell: {
    border: 0,
  },
}));

export const Field = ({ label, labelMinWidth = 120, children }) => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell
        style={{ minWidth: labelMinWidth }}
        className={classes.labelCell}
      >
        {label}
      </TableCell>
      <TableCell className={classes.answerCell}>{children}</TableCell>
    </TableRow>
  );
};
