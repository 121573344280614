import React, { useEffect, useState } from 'react';
import {
  Card,
  InputAdornment,
  List,
  ListItem,
  makeStyles,
  TextField,
  Tooltip,
} from '@material-ui/core';
import format from 'date-fns/format';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import CashMultipleIcon from 'mdi-material-ui/CashMultiple';
import FolderMultipleOutlineIcon from 'mdi-material-ui/FolderMultipleOutline';
import SearchIcon from '@material-ui/icons/Search';

import * as api from 'services/api';
import { PageContainer } from 'components/page_container';
import { ClosingIcon } from 'components/closing_icon';
import { Breadcrumbs, PageHeader, Typography } from '@passthrough/uikit';
import { formatCurrency } from 'services/utils';
import { DEFAULT_CURRENCY } from 'services/constants';
import { LPInvestmentStatus } from 'components/status';
import { Spinner } from 'components/spinner';
import { EmptyState } from 'components/empty_v2';

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    width: '100%',
  },
  cardContentRoot: {
    padding: theme.spacing(2, 3),
  },
  cardContentIconFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  cardContentFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(2),
    flex: 'auto',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: theme.spacing(1),
      flex: 'initial',
      alignItems: 'flex-start',
    },
  },
  cardContentIcon: {
    flex: 'initial',
  },
  cardContentInfoBlock: {
    flex: '0 0 70%',
  },
  cardContentStatus: {
    flex: '0 0 30%',
    overflowWrap: 'anywhere',
    hyphens: 'auto',
  },
  searchBarFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  bottomClosingInfoRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    color: theme.palette.text.secondary,
    '& > span': {
      display: 'flex',
      flexDirection: 'row',
      gap: '4px',
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: theme.spacing(0),
    },
  },
  verticalFlex: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  searchResultsText: {
    textAlign: 'right',
  },
}));

const ClosingInfoBlock = ({ closing }) => {
  const classes = useStyles();
  const {
    commitment,
    currency,
    fundName,
    isDiligence,
    isSubdoc,
    lastSubmittedTime,
    legalName,
    lpName,
  } = closing;
  const nameRowText =
    legalName && legalName !== lpName ? `${lpName} (${legalName})` : lpName;
  const closingTypes = [];
  if (isSubdoc) {
    closingTypes.push('Questionnaire');
  }
  if (isDiligence) {
    closingTypes.push('Diligence');
  }
  const closingType = closingTypes.join(', ');
  return (
    <div className={classes.verticalFlex}>
      <Typography variant="card-heading">{fundName}</Typography>
      <Typography>{nameRowText}</Typography>
      <div className={classes.bottomClosingInfoRow}>
        {lastSubmittedTime ? (
          <Tooltip
            title={<Typography variant="label">Submission date</Typography>}
          >
            <span>
              <CalendarTodayOutlinedIcon fontSize="inherit" />
              <Typography variant="label" size="medium">
                {format(new Date(lastSubmittedTime), 'dd MMM yyyy')}
              </Typography>
            </span>
          </Tooltip>
        ) : null}
        <Tooltip title={<Typography variant="label">Task type</Typography>}>
          <span>
            <FolderMultipleOutlineIcon fontSize="inherit" />
            <Typography variant="label" size="medium">
              {closingType}
            </Typography>
          </span>
        </Tooltip>
        {commitment ? (
          <Tooltip title={<Typography variant="label">Commitment</Typography>}>
            <span>
              <CashMultipleIcon fontSize="inherit" />
              <Typography variant="label" size="medium">
                {currency.code || DEFAULT_CURRENCY.code}{' '}
                {formatCurrency(commitment, currency)}
              </Typography>
            </span>
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
};

const SearchBar = ({
  searchTerm,
  setSearchTerm,
  totalResults,
  filteredResults,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.searchBarFlex}>
      <TextField
        label="Fund or investor name"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Typography
        className={classes.searchResultsText}
        variant="body"
        color="textSecondary"
      >
        {filteredResults !== totalResults ? `${filteredResults} of ` : ''}
        {totalResults} total
      </Typography>
    </div>
  );
};

const InvestmentComponent = ({ investment }) => {
  const classes = useStyles();
  const closingIconProps = {
    isSubdoc: investment.isSubdoc,
    isDiligence: investment.isDiligence,
    isLinked: investment.hasLinkedDiligence || investment.hasLinkedSubdoc,
  };
  const { status, hasLpRequestedReview, signer } = investment;
  const task = { hasLpRequestedReview, signer };
  return (
    <ListItem disableGutters>
      <Card className={classes.root} elevation={2}>
        <div className={classes.cardContentRoot}>
          <div className={classes.cardContentIconFlex}>
            <div className={classes.cardContentIcon}>
              <ClosingIcon {...closingIconProps} />
            </div>
            <div className={classes.cardContentFlex}>
              <div className={classes.cardContentInfoBlock}>
                <ClosingInfoBlock closing={investment} />
              </div>
              <div className={classes.cardContentStatus}>
                <LPInvestmentStatus status={status} task={task} />
              </div>
            </div>
          </div>
        </div>
      </Card>
    </ListItem>
  );
};

function doesInvestmentMatch(investment, searchTerm) {
  const { fundName, lpName, legalName } = investment;
  const search = searchTerm.toLowerCase();
  return (
    (fundName || '').toLowerCase().includes(search) ||
    (lpName || '').toLowerCase().includes(search) ||
    (legalName || '').toLowerCase().includes(search)
  );
}

export const InvestmentsPage = () => {
  const [investments, setInvestments] = useState(null);
  const [filteredInvestments, setFilteredInvestments] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  function getInvestments() {
    api.investments().then((response) => {
      setInvestments(response.data);
      setFilteredInvestments(response.data);
    });
  }
  useEffect(getInvestments, []);

  function filterInvestments() {
    if (investments === null) {
      return;
    }
    if (searchTerm === '') {
      setFilteredInvestments(investments);
    } else {
      setFilteredInvestments(
        investments.filter((investment) =>
          doesInvestmentMatch(investment, searchTerm),
        ),
      );
    }
  }
  useEffect(filterInvestments, [searchTerm, investments]);

  const pageContainerContent =
    investments === null || filteredInvestments === null ? (
      <Spinner fullScreen />
    ) : (
      <>
        <SearchBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          totalResults={investments.length}
          filteredResults={filteredInvestments.length}
        />
        {filteredInvestments.length === 0 ? (
          <EmptyState
            title={`No results for "${searchTerm}"`}
            ctaPermission
            ctaOnClick={() => {
              setSearchTerm('');
            }}
            ctaText="Clear search"
          />
        ) : (
          <List>
            {filteredInvestments.map((investment) => (
              <InvestmentComponent
                key={investment.id}
                investment={investment}
              />
            ))}
          </List>
        )}
      </>
    );
  return (
    <>
      <PageHeader
        heading="Investments"
        crumbs={<Breadcrumbs crumbs={[{ name: 'Investments' }]} />}
      />
      <PageContainer maxWidth="md">{pageContainerContent}</PageContainer>
    </>
  );
};
