import React from 'react';
import clsx from 'clsx';
import { makeStyles, alpha } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1, 0),
    width: '100%',
    padding: theme.spacing(1, 2),
    minHeight: '275px',
    border: `2px dashed ${theme.palette.divider}`,
  },
  hasFile: {
    border: `2px solid ${theme.palette.divider}`,
    cursor: 'default',
  },
  dragging: {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
    border: `2px dashed ${theme.palette.primary.main}`,
    cursor: 'copy',
  },
}));

export const FileUploadAreaBackground = ({
  isDragging,
  hasFile,
  dndRootProps,
  children,
}) => {
  const classes = useStyles();

  return (
    <Paper
      variant="outlined"
      className={clsx(
        classes.root,
        { [classes.dragging]: isDragging },
        { [classes.hasFile]: hasFile },
      )}
      {...dndRootProps}
    >
      {children}
    </Paper>
  );
};
