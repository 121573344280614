import React, { useState } from 'react';
import * as api from 'services/api';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import { Spinner } from 'components/spinner';
import { useWhiteLabelConfig } from 'services/providers/theme';
import { ExternalLogo } from 'components/logo';
import { getSingleErrorFromResponse } from 'services/utils';
import { useToast } from 'services/toast';
import { ExploreStorage } from 'components/explore/utils';

import { Step1 } from './step_1';
import { Step2 } from './step_2';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    padding: theme.spacing(8),
  },
  stepContainer: {
    overflow: 'hidden',
    maxWidth: '500px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  step: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  hiddenStep: {
    height: 0,
  },
  logo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
}));

const STEP_1 = 1;
const STEP_2 = 2;

export function ExploreForm() {
  const classes = useStyles();
  const config = useWhiteLabelConfig();
  const { errorToast } = useToast();
  const [step, setStep] = useState(STEP_1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = React.useState({});
  const [experience, setExperience] = React.useState('');
  const [loggedIn, setLoggedIn] = React.useState(null);

  React.useEffect(() => {
    Promise.all([api.me(), new Promise((resolve) => setTimeout(resolve, 1000))])
      .then(() => {
        setLoggedIn(true);
      })
      .catch(() => {
        setLoggedIn(false);
      });
  }, []);

  const handleStep1 = (exp) => {
    if (loggedIn) {
      setLoading(true);

      api
        .switchExploreExperience(exp)
        .then((response) => {
          ExploreStorage.setExperience(exp);
          window.location.href = response.data.url;
        })
        .catch((err) => {
          const msg = getSingleErrorFromResponse(err.response);
          errorToast(msg);
          setLoading(false);
        });
    } else {
      setExperience(exp);
      setStep(STEP_2);
    }
  };

  const handleStep2 = (data) => {
    setLoading(true);

    api
      .exploreSignup({ ...data, experience })
      .then((response) => {
        ExploreStorage.setForm(data);
        ExploreStorage.setExperience(experience);
        window.location.href = response.data.url;
      })
      .catch((e) => {
        if (e.response.status === 400) {
          setError(e.response.data);
        } else {
          const err = getSingleErrorFromResponse(e.response);
          errorToast(err);
        }
        setLoading(false);
      });
  };

  if (loggedIn === null) {
    return (
      <Grid container className={classes.root}>
        <Spinner size={250} />
      </Grid>
    );
  }

  return (
    <Grid container className={classes.root}>
      <div className={classes.stepContainer}>
        <div className={classes.logo}>
          <ExternalLogo />
        </div>

        <Slide
          direction="right"
          in={step === STEP_1}
          appear={false}
          mountOnEnter
          unmountOnExit
        >
          <div
            className={clsx(classes.step, {
              [classes.hiddenStep]: step !== STEP_1,
            })}
          >
            <Step1 config={config} loading={loading} onSubmit={handleStep1} />
          </div>
        </Slide>
        <Slide direction="left" in={step === STEP_2} mountOnEnter unmountOnExit>
          <div
            className={clsx(classes.step, {
              [classes.hiddenStep]: step !== STEP_2,
            })}
          >
            <Step2
              config={config}
              loading={loading}
              onSubmit={handleStep2}
              error={error}
            />
          </div>
        </Slide>
      </div>
    </Grid>
  );
}
