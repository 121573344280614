import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'components/dialog/index';

export function OfflineDisplay({ handleClose }) {
  return (
    <Dialog open onClose={handleClose} maxWidth="xs">
      <DialogTitle>Contact Support</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Send us an email at{' '}
          <a href="mailto:support@passthrough.com">support@passthrough.com</a>,
          we will be happy to help!
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
