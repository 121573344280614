const QUESTIONNAIRE_CLOSING_TYPE = 'Questionnaire';
const DILIGENCE_CLOSING_TYPE = 'Diligence';

export function getClosingTypes(isDiligenceEnabled, hasSubdoc) {
  if (isDiligenceEnabled && hasSubdoc) {
    return [QUESTIONNAIRE_CLOSING_TYPE, DILIGENCE_CLOSING_TYPE];
  }
  if (isDiligenceEnabled) {
    return [DILIGENCE_CLOSING_TYPE];
  }

  return [QUESTIONNAIRE_CLOSING_TYPE];
}
