import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { GPSelectionInput } from 'components/gp_selection_input/index';
import { Modal } from '@passthrough/uikit';

import { useToast } from 'services/toast';
import * as api from 'services/api';
import { ACCESS_LEVEL_REVIEW } from 'components/gp_selection_input/constants';

export function ChangeDiligenceApproverDialog({
  open,
  defaultDiligenceApproverId,
  handleClose,
  investors,
  onChange,
  members,
}) {
  const { fundId, closingId } = useParams();
  const { toast } = useToast();
  const [selectedApproverId, setSelectedApproverId] = useState(
    defaultDiligenceApproverId,
  );
  const [loading, setLoading] = useState(false);

  function handleSubmit() {
    const lpClosingIds = investors.map((investor) => investor.id);
    setLoading(true);

    api
      .changeDiligenceApprover({
        diligenceApproverId: selectedApproverId,
        fundId,
        closingId,
        lpClosingIds,
      })
      .then(() => {
        handleClose();
        setSelectedApproverId(defaultDiligenceApproverId);
        toast('Diligence reviewer updated');
        setLoading(false);
        onChange();
      })
      .catch(() => {
        setLoading(false);
      });
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      headerLabel="Change diligence reviewer"
      subheaderLabel={`${investors.length} investor${
        investors.length > 1 ? 's' : ''
      } selected`}
      onExited={() => setSelectedApproverId(defaultDiligenceApproverId)}
      showCancelButton
      primaryButtonText="Save"
      primaryButtonLoading={loading}
    >
      <GPSelectionInput
        label="Diligence reviewer"
        value={selectedApproverId}
        setValue={setSelectedApproverId}
        members={members}
        fundId={fundId}
        fundPermissionLevel={ACCESS_LEVEL_REVIEW}
      />
    </Modal>
  );
}
