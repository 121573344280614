/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';

import * as api from 'services/api';
import { useCurrency } from 'services/providers/currency';
import { useSupport } from 'components/support';
import { useExplore } from 'components/explore/button';
import { uploadFile, MAX_LARGE_FILE_UPLOAD_SIZE } from 'services/file';

export const QuestionnaireContext = React.createContext();

const buildSingleFileUploadUrl = (lpDocId) =>
  `/api/lpclosing/${lpDocId}/file/upload/`;

export function useQuestionnaire() {
  return useContext(QuestionnaireContext);
}

export function QuestionnaireProvider({ values, ...props }) {
  return <QuestionnaireContext.Provider value={values} {...props} />;
}

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

export function WebAppQuestionnaireProvider({ ...props }) {
  const explore = useExplore();
  const values = {
    pLogoUrl: window.static.p_logo_png,
    confettiUrl: window.static.confetti_json,
    artificialLoadingSvgUrl: window.static.artificial_loading_svg,
    lpCanComment: true,
    useSupport,
    getPdf: (doc) =>
      Promise.resolve({
        data: {
          url: api.templatePdfServeURL({ lpDocId: doc.id }),
          filledUrl: api.filledPdfServeURL({ lpDocId: doc.id }),
        },
      }),
    maxFileSize: MAX_LARGE_FILE_UPLOAD_SIZE,
    uploadSingleFile: uploadFile,
    buildSingleFileUploadUrl,
    lpClosingComplete: (lpClosingId) => api.lpClosingComplete({ lpClosingId }),
    getLpDoc: (lpClosingId, lpDocId) => api.lpDoc({ lpDocId, lpClosingId }),
    signaturePage: (lpClosingId, lpDocId) =>
      api.signaturePage({ lpDocId, lpClosingId }),
    lpClosingRequestReview: (lpClosingId) =>
      api.lpClosingRequestReview({ lpClosingId }),
    lpClosingUploadSignedDoc: (lpDocId, lpClosingId, fileId) =>
      api.uploadOrDropSigned({
        lpDocId,
        lpClosingId,
        fileId,
      }),
    onQuestionnaireFinish: () => {
      explore.completeMilestone();
    },
    compactQuestionEnabled: false,
    useCurrency,
    getAddressAutocomplete: ({ input, country, location, sessionToken }) =>
      api.getAddressAutocomplete({ input, country, location, sessionToken }),
    getAddressDetails: ({ placeId, sessionToken }) =>
      api.getAddressDetails({ placeId, sessionToken }),
    getGeolocation: () => api.getGeolocation(),
    scrollToTop,
  };

  return <QuestionnaireProvider values={values} {...props} />;
}
