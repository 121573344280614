import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import { NonNegativeCurrencyInput } from './non_negative_currency_input';

const useStyles = makeStyles(() => ({
  rightAlign: {
    textAlign: 'right',
  },
}));

export function CurrencyTextField({
  value,
  currencySymbol,
  endAdornment = null,
  fixedDecimalScale = false,
  ...props
}) {
  const classes = useStyles();

  return (
    <TextField
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{currencySymbol}</InputAdornment>
        ),
        endAdornment,
        inputComponent: NonNegativeCurrencyInput,
        inputProps: { className: classes.rightAlign, fixedDecimalScale },
      }}
      {...props}
    />
  );
}
