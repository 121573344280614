import React, { useCallback, useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';

import { Button } from 'components/button';
import { debounce } from '../utils';
import { ExportDialog } from './export_dialogue';

const useStyles = makeStyles((theme) => ({
  addButton: {
    color: theme.palette.primary.white,
  },
  button: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  toolBar: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

export function QuestionTagsToolBar({ updateTable, addButtonOnClick }) {
  const classes = useStyles();

  const [tagSearchText, setTagSearchText] = useState('');
  const [exportDialogOpen, setExportDialogOpen] = useState(false);

  const optimizedTableUpdate = useCallback(debounce(updateTable), []);

  return (
    <div key="toolBar" className={classes.toolBar}>
      <TextField
        className={classes.input}
        id="search-tag-text"
        variant="outlined"
        label="Search tags"
        type="text"
        value={tagSearchText}
        onChange={(e) => {
          setTagSearchText(e.target.value);
          optimizedTableUpdate(e.target.value);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <div key="buttons">
        <Button
          variant="outlined"
          onClick={() => {
            setExportDialogOpen(true);
          }}
          className={classes.button}
        >
          Export Tags
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={addButtonOnClick}
        >
          Add
        </Button>
        <ExportDialog
          open={exportDialogOpen}
          handleClose={() => {
            setExportDialogOpen(false);
          }}
        />
      </div>
    </div>
  );
}
