import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  baseCornerStyle: {
    position: 'absolute',
    width: '10px',
    height: '10px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #000000',
  },
  topLeftStyle: {
    top: '-5px',
    left: '-5px',
    cursor: 'nwse-resize',
  },
  bottomLeftStyle: {
    bottom: '-5px',
    left: '-5px',
    cursor: 'nesw-resize',
  },
  bottomRightStyle: {
    bottom: '-5px',
    right: '-5px',
    cursor: 'nwse-resize',
  },
  topRightStyle: {
    top: '-5px',
    right: '-5px',
    cursor: 'nesw-resize',
  },
  dragCursor: {
    cursor: 'grabbing',
  },
  grabCursor: {
    cursor: 'grab',
  },
}));

export function ResizeCorner({
  left,
  setResizeLeft,
  top,
  setResizeTop,
  color,
}) {
  const classes = useStyles();
  const cornerRef = useRef(null);

  useEffect(() => {
    const cornerDiv = cornerRef.current;
    if (cornerDiv === null) {
      return undefined;
    }
    const handleMouseDown = () => {
      // Propagates to the mousedown handler on Box. This tells Box which
      // corner is being dragged.
      setResizeLeft(left);
      setResizeTop(top);
    };
    cornerDiv.addEventListener('mousedown', handleMouseDown);
    return () => {
      cornerDiv.removeEventListener('mousedown', handleMouseDown);
    };
  }, [cornerRef.current, left, top]);

  return (
    <div
      style={{ border: `1px solid ${color}` }}
      ref={cornerRef}
      className={clsx('ResizeCorner', classes.baseCornerStyle, {
        [classes.topLeftStyle]: top && left,
        [classes.bottomLeftStyle]: !top && left,
        [classes.bottomRightStyle]: !top && !left,
        [classes.topRightStyle]: top && !left,
      })}
    />
  );
}
