import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FileItem } from './file_item';

const useStyles = makeStyles((theme) => ({
  paperFile: {
    marginTop: theme.spacing(1),
  },
}));

export const FileList = ({ files, onDelete, onEdit, onUpdateOrder }) => {
  const classes = useStyles();
  const sortedFiles = files.sort((a, b) => (a.order > b.order ? 1 : -1));

  function handleUpdate(result) {
    if (result.destination) {
      if (result.destination.index === 0 || result.source.index === 0) {
        // Cannot drag subscription document
        return;
      }
      const source = sortedFiles.find((f) => f.order === result.source.index);

      if (!source?.id) {
        return;
      }
      const newFiles = sortedFiles.filter((f) => f !== source);
      newFiles.splice(result.destination.index, 0, source);
      const newOrder = newFiles.map((f, index) =>
        index > 0 ? { ...f, order: index } : f,
      );
      onUpdateOrder(newOrder);
    }
  }

  return (
    <List>
      <DragDropContext onDragEnd={handleUpdate}>
        <Droppable droppableId="droppable">
          {(outerProvided, outerSnapshot) => (
            <div
              {...outerProvided.droppableProps}
              ref={outerProvided.innerRef}
              style={{
                border: outerSnapshot.isDraggingOver
                  ? '1px dashed black'
                  : '1px dashed transparent',
              }}
            >
              {sortedFiles.map((file) => (
                <Draggable
                  key={file.order}
                  draggableId={file.order + 1}
                  index={file.order}
                >
                  {(provided) => (
                    <Paper
                      ref={provided.innerRef}
                      variant="outlined"
                      className={classes.paperFile}
                      {...provided.draggableProps}
                      style={{
                        ...provided.draggableProps.style,
                      }}
                    >
                      <FileItem
                        file={file}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        dragHandleProps={provided.dragHandleProps}
                      />
                    </Paper>
                  )}
                </Draggable>
              ))}
              {outerProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </List>
  );
};
