import React, { useState } from 'react';
import { Button } from 'components/button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { ProfileInfoModal } from './profile_info_modal';

const useStyles = makeStyles((theme) => ({
  button: {
    alignSelf: 'flex-end',
    padding: theme.spacing(0, 1),
  },
  name: {
    fontWeight: 500,
    padding: theme.spacing(0, 1),
  },
}));

export function ProfileInfoButton({
  availableProfiles,
  activeProfile,
  setActiveProfile,
  setSetupProfileId,
  fundName,
  lpName,
  hasProfileQuestions,
  setRestarting,
  disableProfileSwitching,
}) {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);

  const showLPName =
    !availableProfiles.length > 0 || !hasProfileQuestions || !activeProfile;
  if (showLPName) {
    return <Typography className={classes.name}>{lpName}</Typography>;
  }

  if (activeProfile && !availableProfiles.length) {
    return (
      <Typography className={classes.name}>
        {activeProfile.displayName}
      </Typography>
    );
  }

  // If the profile isn't claimed it means it's called "New profile"
  // which isn't helpful- so we show the LP name instead.
  const profileOrLpName = activeProfile.isClaimed
    ? activeProfile.displayName
    : lpName;

  return (
    <>
      <Button
        aria-controls="open-info-modal"
        aria-haspopup="true"
        variant="contained"
        className={classes.button}
        color="default"
        onClick={() => {
          setOpenModal(true);
        }}
        startIcon={<InfoOutlinedIcon />}
      >
        {profileOrLpName}
      </Button>
      <ProfileInfoModal
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
        }}
        activeProfile={activeProfile}
        availableProfiles={availableProfiles}
        setActiveProfile={setActiveProfile}
        setSetupProfileId={setSetupProfileId}
        fundName={fundName}
        lpName={lpName}
        hasProfileQuestions={hasProfileQuestions}
        setRestarting={setRestarting}
        disableProfileSwitching={disableProfileSwitching}
      />
    </>
  );
}
