import React from 'react';
import { ResizeCorner } from './resize_corner';

const corners = [
  { left: true, top: true },
  { left: true, top: false },
  { left: false, top: false },
  { left: false, top: true },
];

export function ResizeLayer({ setResizeLeft, setResizeTop, color }) {
  return (
    <>
      {corners.map((c) => (
        <ResizeCorner
          key={`${c.left}-${c.top}`}
          left={c.left}
          top={c.top}
          setResizeLeft={setResizeLeft}
          setResizeTop={setResizeTop}
          color={color}
        />
      ))}
    </>
  );
}
