import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Timeline from '@material-ui/lab/Timeline';

import { DateDisplay } from './date_display';
import { EventDisplay } from './event_display/index';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
  timelineContainer: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
  },
}));

export function EventsPerDateDisplay({
  question,
  fundName,
  dateStr,
  events,
  jurisdiction = null,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DateDisplay dateStr={dateStr} />

      <Timeline className={classes.timelineContainer}>
        {events.map((event, i) => (
          <EventDisplay
            key={event.id}
            fundName={fundName}
            question={question}
            jurisdiction={jurisdiction}
            event={event}
            showTimelineConnector={i !== events.length - 1}
          />
        ))}
      </Timeline>
    </div>
  );
}
