import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, Typography, useConfirm } from '@passthrough/uikit';

import { useOrganization } from 'services/providers/organization';
import { PageContainer } from 'components/page_container';
import { Spinner } from 'components/spinner';
import * as api from 'services/api';
import * as urls from 'services/urls';
import { useToast } from 'services/toast';
import { IntegrationTable } from './integration_table';
import { TraySection } from './tray_section';
import { DomainTable } from './domain_table';
import { ManageDomainDialog } from './manage_domain_dialog';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  verticalSpacer: {
    height: theme.spacing(5),
  },
  alignLeft: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  confirmDescription: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

export function SettingsPage() {
  const [org, , refreshOrganization] = useOrganization();
  const organizationId = urls.useOrganizationId();
  const classes = useStyles();
  const [manageDomain, setManageDomain] = React.useState(null);
  const [verifiedDomains, setVerifiedDomains] = React.useState([]);
  const [approvedDomains, setApprovedDomains] = React.useState([]);
  const [trustedDomains, setTrustedDomains] = React.useState([]);
  const [fundAdminCounts, setFundAdminCounts] = React.useState({});
  const [orgAdminCounts, setOrgAdminCounts] = React.useState({});
  const [domains, setDomains] = React.useState([]);
  const [change, setChange] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [domainTableLoading, setDomainTableLoading] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(null);
  const confirm = useConfirm();
  const { toast } = useToast();

  function loadVerifiedDomains() {
    return new Promise((resolve, reject) => {
      api
        .orgVerifiedDomainList({ organizationId })
        .then((response) => {
          setVerifiedDomains(response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  function loadTrustedDomains() {
    return new Promise((resolve, reject) => {
      api
        .orgTrustedDomainList({ organizationId })
        .then((response) => {
          setTrustedDomains(response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  function loadApprovedDomains() {
    return new Promise((resolve) => {
      setApprovedDomains(
        org?.approvedDomains.map((d) => d.split('@')[1]) || [],
      );
      resolve();
    });
  }

  function loadFundAdmins() {
    return new Promise((resolve, reject) => {
      api
        .orgFundMemberCount({ organizationId })
        .then((response) => {
          setFundAdminCounts(response.data.memberCount);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  function loadOrgAdmins() {
    return new Promise((resolve, reject) => {
      api
        .orgAdmins({ organizationId })
        .then((response) => {
          const orgAdminCount = response.data.reduce((counts, orgAdmin) => {
            const newCounts = counts;
            const domain = orgAdmin.email.split('@')[1];
            if (newCounts[domain]) {
              newCounts[domain] += 1;
            } else {
              newCounts[domain] = 1;
            }
            return newCounts;
          }, {});
          setOrgAdminCounts(orgAdminCount);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  useEffect(() => {
    setLoading(true);
    Promise.allSettled([
      loadVerifiedDomains(),
      loadTrustedDomains(),
      loadApprovedDomains(),
      loadFundAdmins(),
      loadOrgAdmins(),
    ]).finally(() => {
      setLoading(false);
    });
  }, [change, org]);

  function setFundAdminUsersString(domain) {
    if (approvedDomains.includes(domain)) {
      const numAdmins = fundAdminCounts[domain] || 0;
      return numAdmins.toString().concat(numAdmins !== 1 ? ' users' : ' user');
    }
    return 'No permission';
  }

  function setOrgAdminUsersString(domain) {
    if (approvedDomains.includes(domain)) {
      const numAdmins = orgAdminCounts[domain] || 0;
      return numAdmins.toString().concat(numAdmins !== 1 ? ' users' : ' user');
    }
    return 'No permission';
  }

  useEffect(() => {
    setDomains(
      Array.from(
        new Set([
          ...verifiedDomains.map((d) => d.emailDomain),
          ...approvedDomains,
          ...trustedDomains.map((d) => d.domain),
        ]),
      )
        .sort()
        .map((d) => ({
          domain: d,
          fundAdminUsers: setFundAdminUsersString(d),
          orgAdminUsers: setOrgAdminUsersString(d),
        })),
    );
  }, [
    verifiedDomains,
    approvedDomains,
    trustedDomains,
    fundAdminCounts,
    orgAdminCounts,
  ]);

  const onChange = () => setChange((c) => c + 1);

  function onClose() {
    setManageDomain(null);
  }

  function onDelete(domain) {
    confirm({
      title: `Delete ${domain}?`,
      description: (
        <div className={classes.confirmDescription}>
          {trustedDomains.find((d) => d.domain === domain) ? (
            <Alert severity="warning" sx={{ whiteSpace: 'pre-line' }}>
              {'The following functions will be terminated:\n' +
                '\u2022 Webhooks'}
            </Alert>
          ) : null}
          <Typography>
            All existing admin users with the email domain @{domain} will
            continue to have access.
          </Typography>
        </div>
      ),
      destructive: true,
    }).then(() => {
      setErrorMsg('');
      setDomainTableLoading(true);
      api
        .orgDomainDelete({ organizationId, domain })
        .then(() => {
          toast(`Deleted ${domain}`);
          onChange();
          refreshOrganization((c) => c + 1);
        })
        .catch((error) => {
          setErrorMsg(error.response.data.domain);
        })
        .finally(() => {
          setDomainTableLoading(false);
        });
    });
  }

  if (loading) {
    return <Spinner size={250} />;
  }

  return (
    <PageContainer>
      {errorMsg ? <Alert severity="error">{errorMsg}</Alert> : null}
      <DomainTable
        setManageDomain={setManageDomain}
        onChange={onChange}
        onDelete={onDelete}
        domains={domains}
        verifiedDomains={verifiedDomains}
        loading={domainTableLoading}
      />
      <ManageDomainDialog
        domain={manageDomain}
        onClose={onClose}
        onChange={onChange}
        approvedDomain={approvedDomains.includes(manageDomain)}
        trustedDomain={trustedDomains.find((d) => d.domain === manageDomain)}
        verifiedDomain={verifiedDomains.find(
          (d) => d.emailDomain === manageDomain,
        )}
      />
      {org?.trayEnabled ? <TraySection org={org} /> : null}
      {org?.publicApiEnabled ? <IntegrationTable /> : null}
    </PageContainer>
  );
}
