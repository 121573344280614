import React from 'react';
import { RadioCardChoice, RadioCardContainer } from 'components/radio_card';

export const ProfileSwitchRadioForm = ({
  profileDisplayName,
  existingAnswersChoice,
  setExistingAnswersChoice,
}) => {
  const restartDescription = profileDisplayName
    ? `Clear all my answers and use ${profileDisplayName}'s profile data.`
    : 'Clear all my answers and start from scratch.';
  return (
    <RadioCardContainer
      selectedChoice={existingAnswersChoice}
      setSelectedChoice={setExistingAnswersChoice}
      flexGrowContainer={false}
    >
      <RadioCardChoice
        choiceKey="restart"
        choiceValue="restart"
        choiceTitle="Start over"
        choiceSubtitle={restartDescription}
        choiceBackground="white"
      />
      <RadioCardChoice
        choiceKey="keep"
        choiceValue="keep"
        choiceTitle="Keep everything"
        choiceSubtitle="Keep all my answers and only change profile."
        choiceBackground="white"
      />
    </RadioCardContainer>
  );
};
