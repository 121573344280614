import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import IconButton from '@material-ui/core/IconButton';
import FileDownloadOutline from 'mdi-material-ui/FileDownloadOutline';
import { UIKitSettingsProvider, Link } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  img: {
    color: theme.palette.primary.grey,
    marginTop: theme.spacing(3),
    height: '120px',
    width: '100px',
  },
  fileName: {
    overflowWrap: 'anywhere',
  },
  deleteIcon: {
    margin: theme.spacing(1, 0),
  },
}));

export function SingleFileUploadDisplay({ file, onDelete }) {
  const classes = useStyles();
  const { fileName, fileUrl } = file;
  return (
    <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
      <FileDownloadOutline className={classes.img} />
      <div>
        {fileUrl ? (
          <Link variant="external" href={fileUrl} className={classes.fileName}>
            {fileName}
          </Link>
        ) : (
          fileName
        )}
        <IconButton
          aria-label="delete"
          onClick={onDelete}
          className={classes.deleteIcon}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </UIKitSettingsProvider>
  );
}
