import React from 'react';
import AccountCogIcon from 'mdi-material-ui/AccountCog';
import FolderOpenIcon from 'mdi-material-ui/FolderOpen';
import Cog from 'mdi-material-ui/Cog';
import { useOrganization } from 'services/providers/organization';
import * as urls from 'services/urls';
import {
  PageHeader,
  Breadcrumbs,
  Tabs,
  Tab,
  HeaderSkeleton,
} from '@passthrough/uikit';
import { useHistory } from 'react-router-dom';

const TABS = [
  {
    id: 'funds',
    icon: FolderOpenIcon,
    name: 'Funds',
    path: urls.ORGANIZATION_FUNDS_URL,
    toUrl: (props) => urls.organizationFundsUrl(props),
  },
  {
    id: 'access',
    icon: AccountCogIcon,
    name: 'Access',
    path: urls.ORGANIZATION_ACCESS_URL,
    toUrl: (props) => urls.organizationAccessUrl(props),
  },
];

TABS.push({
  id: 'settings',
  icon: Cog,
  name: 'Settings',
  path: urls.ORGANIZATION_SETTINGS_URL,
  toUrl: (props) => urls.organizationSettingsUrl(props),
});

function getMatchTabId(filteredTabs, history, organizationId) {
  const matchTab = filteredTabs.find(
    (t) => t.toUrl({ organizationId }) === history.location.pathname,
  );
  return matchTab ? matchTab.id : TABS[0].id;
}

export function OrganizationNav() {
  const [organization] = useOrganization();
  const organizationId = urls.useOrganizationId();

  const history = useHistory();
  const [currentTab, setCurrentTab] = React.useState(
    getMatchTabId(TABS, history, organizationId),
  );

  React.useEffect(() => {
    const matchTabId = getMatchTabId(TABS, history, organizationId);
    setCurrentTab(matchTabId);
  }, [history.location.pathname]);

  return (
    <PageHeader
      heading={organization?.name}
      tabs={
        <Tabs
          value={currentTab}
          onChange={(ev, nextTab) => {
            setCurrentTab(nextTab);
          }}
        >
          {TABS.map((tab) => (
            <Tab
              key={tab.id}
              label={tab.name}
              value={tab.id}
              href={tab.toUrl({ organizationId })}
            />
          ))}
        </Tabs>
      }
      crumbs={
        organization?.name && (
          <Breadcrumbs
            crumbs={[
              {
                name: organization.name,
              },
            ]}
          />
        )
      }
      skeletons={[HeaderSkeleton.crumbs, HeaderSkeleton.heading]}
    />
  );
}
