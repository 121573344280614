export const alertVariants = {
  PEP: 'pep',
  SANCTIONS: 'sanctions',
  WARNINGS: 'warnings',
  MEDIA_ALERT: 'media_alert',
  FITNESS_PROBITY: 'fitness_probity',
  ADVERSE_MEDIA: 'adverse_media',
};

export const NUM_CARDS_THRESHOLD = 3;
