import React, { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { useQuestionnaire } from 'services/providers/questionnaire';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';

import { Spinner } from 'components/spinner';
import { FileUploadArea } from '../file_upload_area';
import { MultiFileUploadDisplay } from './multi_file_upload_display';
import { FileUploadAreaBackground } from '../file_upload_area_background';
import {
  windowHandlerInitialization,
  constructErrorDisplay,
  validateUploadedFile,
} from '../utils';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const DndMultiFileUpload = ({ files, onChange, url }) => {
  const classes = useStyles();
  const [isUploading, setIsUploading] = useState(false);
  const [validationError, setValidationErrors] = useState('');
  const [uploadError, setUploadError] = useState('');
  const {
    useSupport,
    uploadSingleFile,
    maxFileSize = null,
  } = useQuestionnaire();
  const { showSupport } = useSupport();

  const hasFiles = files.length > 0;
  const defaultValidationError = 'Invalid files.';
  const uploadDefaultValidationError =
    'There was an error uploading your file, please resubmit it.';

  useEffect(windowHandlerInitialization, []);

  function onDropRejected(rejectedFiles) {
    if (rejectedFiles.length > 0) {
      setValidationErrors(constructErrorDisplay(rejectedFiles, showSupport));
      return;
    }
    setValidationErrors(defaultValidationError);
  }

  function onDelete(fileId) {
    onChange(files.filter((file) => file.fileId !== fileId));
  }

  function uploadFiles(acceptedFiles) {
    if (
      acceptedFiles === null ||
      acceptedFiles === undefined ||
      acceptedFiles.length === 0
    ) {
      return;
    }
    setIsUploading(true);
    setValidationErrors('');
    setUploadError('');

    const requests = [];

    acceptedFiles.forEach((file) => {
      const request = uploadSingleFile(file, url);
      requests.push(request);
    });

    axios
      .all(requests)
      .then(
        axios.spread((...responses) => {
          setIsUploading(false);
          onChange([
            ...files,
            ...responses.map((response) => ({
              fileId: response.data.id,
              fileUrl: response.data.file,
              fileName: response.data.name,
            })),
          ]);
        }),
      )
      .catch((error) => {
        setIsUploading(false);
        if (error.response && error.response.data.file) {
          const errorMsg = error.response.data.file;
          setUploadError(errorMsg);
          return;
        }
        setUploadError(uploadDefaultValidationError);
      });
  }

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        uploadFiles(acceptedFiles);
      }
    },
    [files],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    validator: validateUploadedFile(null, maxFileSize),
    noClick: false,
    noKeyboard: true,
    multiple: true,
    noDragEventsBubbling: true,
    disabled: isUploading,
    accept: '',
  });

  return (
    <div className={classes.root}>
      <FileUploadAreaBackground
        isDragging={isDragActive}
        hasFiles={hasFiles}
        dndRootProps={{ ...getRootProps() }}
      >
        {isUploading ? (
          <Spinner size={250} />
        ) : (
          <FileUploadArea inputProps={{ ...getInputProps() }} usePlural />
        )}
      </FileUploadAreaBackground>

      <MultiFileUploadDisplay files={files} onDelete={onDelete} />

      {validationError ? (
        <FormHelperText error>{validationError}</FormHelperText>
      ) : null}
      {uploadError ? (
        <FormHelperText error>{uploadError}</FormHelperText>
      ) : null}
    </div>
  );
};
