/* eslint-disable react/no-array-index-key */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useSliceStyles = makeStyles((theme) => ({
  vertical: {
    borderLeft: `1px solid ${theme.palette.text.primary}`,
    left: '18px',
    marginRight: '18px',
    paddingRight: theme.spacing(2.25),
    position: 'relative',
  },
  innerBar: {
    borderTop: `1px solid ${theme.palette.text.primary}`,
    bottom: '0px',
    left: '-1px',
    width: '100%',
    height: 'calc(100% - 23px)',
    position: 'absolute',
  },
  blank: {
    minWidth: '40px',
    left: '18px',
    paddingRight: theme.spacing(2.25),
    position: 'relative',
  },
  endHorizontal: {
    // Mask the bottom part of the line
    backgroundColor: theme.palette.background.default,
    borderTop: `1px solid ${theme.palette.text.primary}`,
    bottom: '0px',
    left: '-1px',
    width: '100%',
    height: 'calc(100% - 23px)',
    position: 'absolute',
  },
}));

export function TreeSpacingList({ treeSlices }) {
  const sliceClasses = useSliceStyles();

  return treeSlices.map((treeSlice, index) => {
    if (treeSlice === '┠') {
      return (
        <div key={index} className={sliceClasses.vertical}>
          <div className={sliceClasses.innerBar} />
        </div>
      );
    }
    if (treeSlice === '|') {
      return <div key={index} className={sliceClasses.vertical} />;
    }
    if (treeSlice === ' ') {
      return <div key={index} className={sliceClasses.blank} />;
    }
    if (treeSlice === '╰') {
      return (
        <div key={index} className={sliceClasses.vertical}>
          <div className={sliceClasses.endHorizontal} />
        </div>
      );
    }
    // Should be impossible
    return null;
  });
}
