import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

import { Modal, Alert } from '@passthrough/uikit';
import { BooleanValue } from 'components/boolean_value';
import { useDiscardConfirmation } from 'services/discard';
import { objectEquals } from 'services/utils';
import {
  REQUEST_REVIEW_VALUES,
  REQUEST_REVIEW_DISABLED,
  REQUEST_REVIEW_REQUIRED,
} from '../constants';

export function QuestionnaireSettingsModal({
  open,
  onClose,
  initialState,
  errors,
  setErrors,
  updateSettings,
  loading,
}) {
  const [form, setForm] = useState(initialState);
  const closeWithConfirmation = useDiscardConfirmation({
    onConfirm: onClose,
    needConfirmation: open && !objectEquals(form, initialState),
  });

  // Show alert if we're switching to or from required
  const showChangesAlert =
    form.lpRequestReview !== initialState.lpRequestReview &&
    (form.lpRequestReview === REQUEST_REVIEW_REQUIRED ||
      initialState.lpRequestReview === REQUEST_REVIEW_REQUIRED);

  return (
    <Modal
      open={open}
      onClose={closeWithConfirmation}
      headerLabel="Edit questionnaire settings"
      showCancelButton
      primaryButtonText="Save"
      primaryButtonLoading={loading}
      onEntering={() => {
        setForm(initialState);
        setErrors({});
      }}
      onSubmit={() => updateSettings(form)}
    >
      {showChangesAlert ? (
        <Alert severity="warning">
          Changes will only apply to new investors.
        </Alert>
      ) : null}
      <FormControl
        variant="outlined"
        fullWidth
        error={!!errors.lpRequestReview}
      >
        <InputLabel id="request-review">Request a review</InputLabel>
        <Select
          label="Request a review"
          labelId="request-review"
          value={form.lpRequestReview}
          onChange={(e) => {
            setForm({ ...form, lpRequestReview: e.target.value });
          }}
        >
          {REQUEST_REVIEW_VALUES.map(({ label, value }) => (
            <MenuItem key={label} value={value}>
              <BooleanValue
                label={label}
                bool={value !== REQUEST_REVIEW_DISABLED}
              />
            </MenuItem>
          ))}
        </Select>
        {errors.lpRequestReview ? (
          <FormHelperText>{errors.lpRequestReview}</FormHelperText>
        ) : null}
      </FormControl>
    </Modal>
  );
}
