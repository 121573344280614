import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'components/button';

const useStyles = makeStyles(() => ({
  maxWidth: {
    width: '100%',
  },
}));

function UndoApprovalButton({ className, isLoading, unapproveDiligenceNode }) {
  return (
    <div className={className}>
      <Button
        aria-label="node_unapprove"
        variant="text"
        loading={isLoading}
        onClick={() => unapproveDiligenceNode()}
      >
        Undo review
      </Button>
    </div>
  );
}

function ApprovalButton({
  className,
  isLoading,
  shouldDisableApproval,
  approveDiligenceNode,
}) {
  const classes = useStyles();

  return (
    <div className={className}>
      <Button
        aria-label="node_approve"
        className={classes.maxWidth}
        data-test="approve_owner"
        variant="contained"
        loading={isLoading}
        disabled={shouldDisableApproval}
        onClick={() => approveDiligenceNode()}
      >
        Mark as reviewed
      </Button>
    </div>
  );
}

export function ApprovalButtons({
  isApproved,
  isLoading,
  shouldDisableApproval,
  approveDiligenceNode,
  unapproveDiligenceNode,
  className,
}) {
  if (isApproved) {
    return (
      <UndoApprovalButton
        className={className}
        isLoading={isLoading}
        unapproveDiligenceNode={unapproveDiligenceNode}
      />
    );
  }

  return (
    <ApprovalButton
      className={className}
      isLoading={isLoading}
      shouldDisableApproval={shouldDisableApproval}
      approveDiligenceNode={approveDiligenceNode}
    />
  );
}
