import { useState, useReducer, useEffect, useMemo } from 'react';

import * as constants from './constants';
import { determineIfBoxesAreInvalid } from './utils';

// Reducer logic

function updateBox(array, boxId, newBox) {
  return array.map((box) => {
    if (box.id !== boxId) {
      return box;
    }

    return { ...box, ...newBox };
  });
}

function translate(array, boxId, xDiff, yDiff, docHeight, docWidth) {
  return array.map((box) => {
    if (box.id !== boxId) {
      return box;
    }

    return {
      ...box,
      x: Math.max(0, Math.min(docWidth - box.width, box.x + xDiff)),
      y: Math.max(0, Math.min(docHeight - box.height, box.y + yDiff)),
    };
  });
}

function resize(array, boxId, xDiff, yDiff, widthDiff, heightDiff) {
  return array.map((box) => {
    if (box.id !== boxId) {
      return box;
    }

    return {
      ...box,
      width: box.width + widthDiff,
      height: box.height + heightDiff,
      x: box.x + xDiff,
      y: box.y + yDiff,
    };
  });
}

function boxesReducer(boxes, action) {
  switch (action.type) {
    case constants.ADD_ACTION:
      return [...boxes, action.newBox];
    case constants.DELETE_ACTION:
      return boxes.filter((b) => b.id !== action.boxId);
    case constants.TRANSLATE_ACTION:
      return translate(
        boxes,
        action.boxId,
        action.xDiff,
        action.yDiff,
        action.docHeight,
        action.docWidth,
      );
    case constants.RESIZE_ACTION:
      return resize(
        boxes,
        action.boxId,
        action.xDiff,
        action.yDiff,
        action.widthDiff,
        action.heightDiff,
      );
    case constants.UPDATE_ACTION:
      return updateBox(boxes, action.boxId, action.newBox);
    case constants.SET_BOXES_ACTION:
      return action.boxes;
    case constants.PLACE_BOX_ACTION:
      return boxes.map((box) =>
        box.id === action.boxToPlace.id ? action.boxToPlace : box,
      );
    case constants.REMOVE_PLACED_BOX:
      return updateBox(boxes, action.boxId, {
        page: null,
        x: null,
        y: null,
      });
    default:
      return boxes;
  }
}

function activeBoxReducer(activeBox, action) {
  switch (action.type) {
    case constants.SET_ACTIVE_BOX_ACTION:
      return action.activeBox;
    case constants.ADD_ACTION:
      return action.newBox;
    case constants.DELETE_ACTION:
      return null;
    case constants.UPDATE_ACTION:
      if (activeBox?.id === action.boxId) {
        return { ...activeBox, ...action.newBox };
      }
      return activeBox;
    case constants.SET_BOX_TO_PLACE_ACTION:
      return null;
    case constants.PLACE_BOX_ACTION:
      return action.boxToPlace;
    case constants.REMOVE_PLACED_BOX:
      return null;
    case constants.SET_BOXES_ACTION:
      return null;
    case constants.UPDATE_PAGE_ACTION:
      return null;
    default:
      return activeBox;
  }
}

function boxToPlaceReducer(boxToPlace, action) {
  switch (action.type) {
    case constants.SET_BOX_TO_PLACE_ACTION:
      return action.boxToPlace;
    case constants.SET_ACTIVE_BOX_ACTION:
      return null;
    case constants.PLACE_BOX_ACTION:
      return null;
    case constants.REMOVE_PLACED_BOX:
      return null;
    case constants.SET_BOXES_ACTION:
      return null;
    case constants.UPDATE_PAGE_ACTION:
      return null;
    default:
      return boxToPlace;
  }
}

function currentPageReducer(currentPage, action) {
  switch (action.type) {
    case constants.SET_BOXES_ACTION:
      return 1;
    case constants.SET_ACTIVE_BOX_ACTION:
      return action.activeBox?.page || currentPage;
    case constants.UPDATE_PAGE_ACTION:
      return action.page;
    default:
      return currentPage;
  }
}

function documentFieldReducer(state, action) {
  const boxes = boxesReducer(state.boxes, action);
  const activeBox = activeBoxReducer(state.activeBox, action);
  const boxToPlace = boxToPlaceReducer(state.boxToPlace, action);
  const currentPage = currentPageReducer(state.currentPage, action);

  const areBoxesInvalid = determineIfBoxesAreInvalid(boxes);

  return { boxes, activeBox, boxToPlace, currentPage, areBoxesInvalid };
}

// Actions

const addBox = (dispatch) => (newBox) => {
  dispatch({ type: constants.ADD_ACTION, newBox });
};

const deleteBox = (dispatch) => (boxId) => {
  dispatch({ type: constants.DELETE_ACTION, boxId });
};

const translateBox =
  (dispatch) =>
  ({ boxId, xDiff, yDiff, docHeight, docWidth }) => {
    dispatch({
      type: constants.TRANSLATE_ACTION,
      boxId,
      xDiff,
      yDiff,
      docHeight,
      docWidth,
    });
  };

const resizeBox =
  (dispatch) =>
  ({ boxId, xDiff, yDiff, widthDiff, heightDiff }) => {
    dispatch({
      type: constants.RESIZE_ACTION,
      boxId,
      xDiff,
      yDiff,
      widthDiff,
      heightDiff,
    });
  };

const updateBoxAction =
  (dispatch) =>
  ({ boxId, newBox }) => {
    dispatch({ type: constants.UPDATE_ACTION, boxId, newBox });
  };

const setActiveBox = (dispatch) => (activeBox) => {
  dispatch({ type: constants.SET_ACTIVE_BOX_ACTION, activeBox });
};

const setBoxes = (dispatch) => (boxes) => {
  dispatch({ type: constants.SET_BOXES_ACTION, boxes });
};

const setBoxToPlace = (dispatch) => (boxToPlace) => {
  dispatch({ type: constants.SET_BOX_TO_PLACE_ACTION, boxToPlace });
};

const placeBox = (dispatch) => (boxToPlace) => {
  dispatch({ type: constants.PLACE_BOX_ACTION, boxToPlace });
};

const removePlacedBox = (dispatch) => (boxId) => {
  dispatch({ type: constants.REMOVE_PLACED_BOX, boxId });
};

const updatePage = (dispatch) => (page) => {
  dispatch({ type: constants.UPDATE_PAGE_ACTION, page });
};

// Hooks

export function useFields() {
  const [state, dispatch] = useReducer(documentFieldReducer, {
    boxes: [],
    currentPage: 1,
    activeBox: null,
    boxToPlace: null,
    areBoxesInvalid: false,
  });

  const actions = useMemo(
    () => ({
      addBox: addBox(dispatch),
      deleteBox: deleteBox(dispatch),
      translateBox: translateBox(dispatch),
      resizeBox: resizeBox(dispatch),
      updateBox: updateBoxAction(dispatch),
      setBoxes: setBoxes(dispatch),
      setActiveBox: setActiveBox(dispatch),
      setBoxToPlace: setBoxToPlace(dispatch),
      placeBox: placeBox(dispatch),
      removePlacedBox: removePlacedBox(dispatch),
      updatePage: updatePage(dispatch),
    }),
    [dispatch],
  );

  return [state, actions];
}

function getName(lpClosing, signer) {
  switch (signer) {
    case constants.FIRST_SIGNER:
      return lpClosing?.lpName;
    case constants.SECOND_SIGNER:
      return lpClosing?.secondSignerName ? lpClosing?.secondSignerName : '';
    case constants.THIRD_SIGNER:
      return lpClosing?.thirdSignerName ? lpClosing?.thirdSignerName : '';
    case constants.FOURTH_SIGNER:
      return lpClosing?.fourthSignerName || '';
    case constants.FIFTH_SIGNER:
      return lpClosing?.fifthSignerName || '';
    case constants.SIXTH_SIGNER:
      return lpClosing?.sixthSignerName || '';
    case constants.FIRST_COUNTERSIGNER:
      return lpClosing?.countersigners?.length > 0
        ? lpClosing?.countersigners[0].name
        : '';
    case constants.SECOND_COUNTERSIGNER:
      return lpClosing?.countersigners?.length > 1
        ? lpClosing?.countersigners[1].name
        : '';
    case constants.THIRD_COUNTERSIGNER:
      return lpClosing?.countersigners?.length > 2
        ? lpClosing?.countersigners[2].name
        : '';
    default:
      return '';
  }
}

function updateNameOverrides(lpClosings, signer, initialName, setNameOverride) {
  if (
    lpClosings.every((lpClosing) => getName(lpClosing, signer) === initialName)
  ) {
    setNameOverride(initialName);
  }
}

export function useSigners({
  investors,
  hasFirstSigner,
  hasSecondSigner,
  hasThirdSigner,
  hasFourthSigner,
  hasFifthSigner,
  hasSixthSigner,
  hasFirstCounterSigner,
  hasSecondCounterSigner,
  hasThirdCounterSigner,
}) {
  // Gets signer objects for each signer for a list of investors.
  // Only returns a definite name for each signer if all investors have
  // the same name for the given signer, e.g. for countersigners or all
  // signers for a single investor.

  const [signer1NameOverride, setSigner1NameOverride] = useState('');
  const [signer2NameOverride, setSigner2NameOverride] = useState('');
  const [signer3NameOverride, setSigner3NameOverride] = useState('');
  const [signer4NameOverride, setSigner4NameOverride] = useState('');
  const [signer5NameOverride, setSigner5NameOverride] = useState('');
  const [signer6NameOverride, setSigner6NameOverride] = useState('');

  const [counterSigner1NameOverride, setCounterSigner1NameOverride] =
    useState('');
  const [counterSigner2NameOverride, setCounterSigner2NameOverride] =
    useState('');
  const [counterSigner3NameOverride, setCounterSigner3NameOverride] =
    useState('');

  useEffect(() => {
    if (investors.length > 0) {
      const initialLPClosing = investors[0];
      const initialSigner1Name = getName(
        initialLPClosing,
        constants.FIRST_SIGNER,
      );
      const initialSigner2Name = getName(
        initialLPClosing,
        constants.SECOND_SIGNER,
      );
      const initialSigner3Name = getName(
        initialLPClosing,
        constants.THIRD_SIGNER,
      );
      const initialSigner4Name = getName(
        initialLPClosing,
        constants.FOURTH_SIGNER,
      );
      const initialSigner5Name = getName(
        initialLPClosing,
        constants.FIFTH_SIGNER,
      );
      const initialSigner6Name = getName(
        initialLPClosing,
        constants.SIXTH_SIGNER,
      );

      const initialCounterSigner1Name = getName(
        initialLPClosing,
        constants.FIRST_COUNTERSIGNER,
      );
      const initialCounterSigner2Name = getName(
        initialLPClosing,
        constants.SECOND_COUNTERSIGNER,
      );
      const initialCounterSigner3Name = getName(
        initialLPClosing,
        constants.THIRD_COUNTERSIGNER,
      );

      updateNameOverrides(
        investors,
        constants.FIRST_SIGNER,
        initialSigner1Name,
        setSigner1NameOverride,
      );
      updateNameOverrides(
        investors,
        constants.SECOND_SIGNER,
        initialSigner2Name,
        setSigner2NameOverride,
      );
      updateNameOverrides(
        investors,
        constants.THIRD_SIGNER,
        initialSigner3Name,
        setSigner3NameOverride,
      );
      updateNameOverrides(
        investors,
        constants.FOURTH_SIGNER,
        initialSigner4Name,
        setSigner4NameOverride,
      );
      updateNameOverrides(
        investors,
        constants.FIFTH_SIGNER,
        initialSigner5Name,
        setSigner5NameOverride,
      );
      updateNameOverrides(
        investors,
        constants.SIXTH_SIGNER,
        initialSigner6Name,
        setSigner6NameOverride,
      );

      updateNameOverrides(
        investors,
        constants.FIRST_COUNTERSIGNER,
        initialCounterSigner1Name,
        setCounterSigner1NameOverride,
      );
      updateNameOverrides(
        investors,
        constants.SECOND_COUNTERSIGNER,
        initialCounterSigner2Name,
        setCounterSigner2NameOverride,
      );
      updateNameOverrides(
        investors,
        constants.THIRD_COUNTERSIGNER,
        initialCounterSigner3Name,
        setCounterSigner3NameOverride,
      );
    }
  }, [investors]);

  const relevantSigners = constants.signers.filter((s) => {
    if (s.role === 'Investor') {
      switch (s.number) {
        case 1:
          return hasFirstSigner;
        case 2:
          return hasSecondSigner;
        case 3:
          return hasThirdSigner;
        case 4:
          return hasFourthSigner;
        case 5:
          return hasFifthSigner;
        case 6:
          return hasSixthSigner;
        default:
          return false;
      }
    } else if (s.role === 'Countersigner') {
      switch (s.number) {
        case 1:
          return hasFirstCounterSigner;
        case 2:
          return hasSecondCounterSigner;
        case 3:
          return hasThirdCounterSigner;
        default:
          return false;
      }
    }
    return false;
  });
  const signers = relevantSigners.map((signer) => {
    switch (signer.pdfBoxSigner) {
      case constants.FIRST_SIGNER:
        return { ...signer, name: signer1NameOverride };
      case constants.SECOND_SIGNER:
        return { ...signer, name: signer2NameOverride };
      case constants.THIRD_SIGNER:
        return { ...signer, name: signer3NameOverride };
      case constants.FOURTH_SIGNER:
        return { ...signer, name: signer4NameOverride };
      case constants.FIFTH_SIGNER:
        return { ...signer, name: signer5NameOverride };
      case constants.SIXTH_SIGNER:
        return { ...signer, name: signer6NameOverride };
      case constants.FIRST_COUNTERSIGNER:
        return { ...signer, name: counterSigner1NameOverride };
      case constants.SECOND_COUNTERSIGNER:
        return { ...signer, name: counterSigner2NameOverride };
      case constants.THIRD_COUNTERSIGNER:
        return { ...signer, name: counterSigner3NameOverride };
      default:
        return signer;
    }
  });

  return signers;
}
