import { isBefore } from 'date-fns';
import { matchStatuses } from './constants';

export function isMatchRead(matchData) {
  const { lastReviewedAt, alerts, matchStatus } = matchData;

  if (matchStatus === matchStatuses.NO_CHOICE) return true;
  if (!lastReviewedAt) return false;

  const isAlertRead = (alert) =>
    isBefore(new Date(alert.updatedAt), new Date(lastReviewedAt));

  return alerts.every(isAlertRead);
}
