import React from 'react';

import { formatCurrency } from 'services/utils';
import { useQuestionnaire } from 'services/providers/questionnaire';
import { GenericAnswerDisplay } from './generic_display';

export function DollarsDisplay({ answer, flatten, questionState }) {
  const { useCurrency } = useQuestionnaire();
  const currency = useCurrency();

  const transformAnswer = (a) =>
    `${currency.code} ${formatCurrency(a, currency)}`;

  return (
    <GenericAnswerDisplay
      answer={answer}
      flatten={flatten}
      questionState={questionState}
      transformAnswer={transformAnswer}
    />
  );
}
