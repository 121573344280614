import React, { useState } from 'react';
import clsx from 'clsx';
import { Document, Page } from 'react-pdf';
import { makeStyles } from '@material-ui/core/styles';
import { Spinner } from 'components/spinner';
import { MobileZoom } from 'components/mobile_zoom';
import { PDFReviewStepper } from 'components/pdf_review_stepper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      rowGap: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      rowGap: theme.spacing(1),
    },
  },
  doc: {
    width: '100%',
    height: `100%`,
    overflow: 'auto',
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.neutral.fill,
  },
  fixedPageDisplay: {
    width: '100%',
    height: `100%`,
    '& canvas': {
      margin: '0 auto',
    },
  },
}));

export function MobilePdfDisplay({
  file,
  initialPage,
  className,
  initialZoom = 0.7,
}) {
  const classes = useStyles();
  const [pdf, setPdf] = useState(null);
  const [page, setPage] = useState(0);
  const [zoom, setZoom] = useState(initialZoom);
  const [loading, setLoading] = useState(true);

  return (
    <div className={clsx(classes.root, className)}>
      <Document
        file={file}
        className={classes.doc}
        onLoadSuccess={(p) => {
          setPdf(p);
          setLoading(false);
          setPage(initialPage || 1);
        }}
        loading={<Spinner fullScreen />}
      >
        <div className={classes.fixedPageDisplay}>
          <Page
            scale={zoom}
            pageNumber={page}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </div>
      </Document>

      <PDFReviewStepper
        page={page}
        setPage={(newPage) => setPage(newPage)}
        numPages={pdf ? pdf.numPages : 0}
        useLeftAlign
        hideButtonLabels
      />

      <MobileZoom zoom={zoom} setZoom={setZoom} disabled={loading} />
    </div>
  );
}
