import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardActionArea from '@material-ui/core/CardActionArea';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Paper from '@material-ui/core/Paper';
import FormHelperText from '@material-ui/core/FormHelperText';
import { TrueLink } from 'components/link';
import OpenInNewIcon from 'mdi-material-ui/OpenInNew';

import { getSourceDisplay } from './answer_source';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    margin: theme.spacing(1, 0),
    borderRadius: 8,
    [theme.breakpoints.up('sm')]: {
      transition: '0.2s',
      '&:hover': {
        transform: 'scale(1.1)',
        zIndex: 1,
      },
    },
  },
  item: {
    fontSize: '2rem !important',
    padding: theme.spacing(2),
    width: '100%',
  },
  link: {
    marginLeft: theme.spacing(2),
  },
}));

export const SingleChoiceFileItem = ({
  children,
  value,
  lpDoc,
  activeProfile,
  source,
  fileUrl,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CardActionArea className={classes.card}>
        <Paper variant="outlined">
          <FormControlLabel
            className={classes.item}
            value={value}
            control={<Radio />}
            label={
              <span>
                {children}
                {source ? (
                  <FormHelperText>
                    Provided by{' '}
                    {getSourceDisplay({ source, lpDoc, activeProfile })}
                  </FormHelperText>
                ) : null}
              </span>
            }
          />
        </Paper>
      </CardActionArea>
      <TrueLink href={fileUrl} className={classes.link}>
        <OpenInNewIcon />
      </TrueLink>
    </div>
  );
};
