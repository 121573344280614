import React from 'react';
import { ANSWER_STATES } from 'services/constants';
import { DiffStyle } from 'components/Diff';
import { MAX_NUM_CHARS } from './constants';

export function GenericAnswerDisplay({
  answer,
  flatten,
  questionState,
  transformAnswer = (a) => a,
}) {
  if (answer === undefined || answer === null) {
    return null;
  }

  const transformedAnswer = transformAnswer(answer);
  if (transformedAnswer === null) {
    return null;
  }

  const strAnswer = `${transformedAnswer}`;

  if (flatten) {
    const truncatedAnswer = strAnswer.substring(0, MAX_NUM_CHARS);
    return truncatedAnswer !== strAnswer ? `${truncatedAnswer}...` : strAnswer;
  }

  return (
    <DiffStyle
      isNew={questionState === ANSWER_STATES.CHANGED_CURRENT_ANSWER}
      isDeleted={questionState === ANSWER_STATES.CHANGED_PRIOR_ANSWER}
    >
      {strAnswer}
    </DiffStyle>
  );
}
