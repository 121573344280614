import React from 'react';

import { getTypeDetailKeysToDisplay } from 'components/lp_doc/diligence/utils';
import {
  jurisdictions,
  TYPE_INDIVIDUAL,
  TYPE_PUBLIC_COMPANY,
} from '../../../constants';
import { IndividualDetails } from '../../../components/details/individual';
import { TinHqAddressDetails } from '../../../components/details/tin_and_hq_address_details';
import { TinEinHqAddressDetails } from '../../../components/details/tin_or_ein_and_hq_address_details';
import { SeiListedCompanyDetails } from './sei_public_company';
import { RootSourceOfWealthDetails } from '../../../subsection_question_display/details_subsection/jurisdiction_specific_details/root_source_of_wealth_data';

export function AdditionalSeiDetails({
  isRootNode,
  form,
  formErrors,
  handleChange,
}) {
  const detailKeys = getTypeDetailKeysToDisplay(
    form.type,
    jurisdictions.SEI,
    isRootNode,
  );
  const shouldDisplayTinAndHq =
    detailKeys.includes('tin') && detailKeys.includes('hqAddress');
  const shouldDisplayTinEinAndHq =
    detailKeys.includes('tinEin') && detailKeys.includes('hqAddress');

  return (
    <>
      {shouldDisplayTinAndHq ? (
        <TinHqAddressDetails
          tin={form.tin}
          tinError={formErrors.tin}
          hqAddress={form.hqAddress}
          hqAddressError={formErrors.hqAddress}
          handleChange={handleChange}
        />
      ) : null}

      {shouldDisplayTinEinAndHq ? (
        <TinEinHqAddressDetails
          tinEin={form.tinEin}
          tinEinError={formErrors.tinEin}
          hqAddress={form.hqAddress}
          hqAddressError={formErrors.hqAddress}
          handleChange={handleChange}
        />
      ) : null}

      {form.type === TYPE_INDIVIDUAL ? (
        <IndividualDetails
          dob={form.dob}
          dobError={formErrors.dob}
          exposeNationality
          nationality={form.nationality}
          nationalityError={formErrors.nationality}
          ssn={form.ssn}
          ssnError={formErrors.ssn}
          useSsn={form.useSsn}
          handleChange={handleChange}
          residentialAddress={form.residentialAddress}
          residentialAddressError={formErrors.residentialAddress}
        />
      ) : null}

      {form.type === TYPE_PUBLIC_COMPANY ? (
        <SeiListedCompanyDetails
          tinEin={form.tinEin}
          tinEinError={formErrors.tinEin}
          hqAddress={form.hqAddress}
          hqAddressError={formErrors.hqAddress}
          ticker={form.ticker}
          tickerError={formErrors.ticker}
          handleChange={handleChange}
        />
      ) : null}

      {isRootNode ? (
        <RootSourceOfWealthDetails
          sourceOfWealth={form.sourceOfWealth}
          sourceOfWealthError={formErrors.sourceOfWealth}
          sourceOfWealthDetails={form.sourceOfWealthDetails}
          sourceOfWealthDetailsError={formErrors.sourceOfWealthDetails}
          handleChange={handleChange}
        />
      ) : null}
    </>
  );
}
