import * as React from 'react';
import {
  Alert,
  Button,
  Icons,
  Link,
  UIKitSettingsProvider,
} from '@passthrough/uikit';
import formatDate from 'date-fns/format';

import * as api from 'services/api';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from 'components/table';

export function SimilarQuestionnaires({
  similarQuestionnaires,
  fundId,
  onCopy,
  questionnaireId,
}) {
  return (
    <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
      <Alert severity="info">
        The following subscription documents are similar to the one you are
        trying to create. You can copy the data from one of them to create a new
        subscription document.
      </Alert>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Questionnaire</TableCell>
              <TableCell>Similarity</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Last Update</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {similarQuestionnaires.map((questionnaire) => (
              <TableRow key={questionnaire.id}>
                <TableCell>
                  <Link href={questionnaire.onboardingUrl} variant="external">
                    {questionnaire.name}
                  </Link>
                </TableCell>
                <TableCell>
                  {Number(100 * questionnaire.similarity).toPrecision(4)} %
                </TableCell>
                <TableCell>
                  {formatDate(new Date(questionnaire.createdAt), 'MMM d, yyyy')}
                </TableCell>
                <TableCell>
                  {formatDate(new Date(questionnaire.updatedAt), 'MMM d, yyyy')}
                </TableCell>
                <TableCell>
                  <Button
                    variant="secondary"
                    startIcon={<Icons.FileCopyOutlined />}
                    size="small"
                    onClick={() => {
                      api
                        .copyQuestionnaire({
                          fundId,
                          from: questionnaire.id,
                          to: questionnaireId,
                        })
                        .then(() => {
                          onCopy();
                        });
                    }}
                  >
                    Copy
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </UIKitSettingsProvider>
  );
}
