import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from '@passthrough/uikit';
import TextField from '@material-ui/core/TextField';
import { FileUpload } from 'components/file_upload';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles((theme) => ({
  errorText: {
    alignSelf: 'center',
    marginBottom: theme.spacing(1),
    margin: theme.spacing(1),
  },
  paperFile: {
    marginTop: theme.spacing(1),
  },
  fileName: {
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  actions: {
    display: 'flex',
    gap: 0,
    padding: 0,
    justifyContent: 'space-between',
    // gap: theme.spacing(2),
  },
  error: {
    marginTop: theme.spacing(2),
    margin: theme.spacing(0, 2),
  },
}));

export function ModalEdit({ open, file, onClose, onBack, onSubmit }) {
  const classes = useStyles();
  const [form, setForm] = useState(file);
  const [loading, setLoading] = useState(false);
  const isSubdoc = file.name === null;
  const hasChanged = form.name !== file.name || form.fileId !== file.fileId;
  const disabled =
    !hasChanged ||
    (hasChanged && isSubdoc && !form.fileId) ||
    (hasChanged && !isSubdoc && (!form.name || !form.fileId));

  const handleSubmit = () => {
    setLoading(true);
    onSubmit(form)
      .then(() => {
        onBack();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNameChange = (e) => {
    setForm({
      ...form,
      name: e.target.value,
    });
  };

  const handleUpload = (f) => {
    setForm({
      ...form,
      ...f,
    });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      size="sm"
      headerLabel={isSubdoc ? 'Subscription document' : file.name || 'New File'}
      onBack={onBack}
      primaryButtonText="Save"
      primaryButtonLoading={loading}
      primaryButtonDisabled={disabled}
      showCancelButton
      onSubmit={handleSubmit}
    >
      {!isSubdoc ? (
        <TextField
          variant="outlined"
          size="medium"
          placeholder="Document name"
          value={form.name}
          onChange={handleNameChange}
          color="black"
        />
      ) : null}
      <FormControl error={false} component="fieldset" fullWidth>
        <FormLabel className={classes.fileName}>File</FormLabel>
        <div>
          <FileUpload file={form} onChange={handleUpload} />
        </div>
      </FormControl>
    </Modal>
  );
}
