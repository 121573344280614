import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { objectEquals } from 'services/utils';
import { formatApprovedDomainEmailError } from 'services/approved_domains';
import { useDiscardConfirmation } from 'services/discard';

import { Modal, Button, Icons } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  textButton: {
    alignSelf: 'flex-start',
    color: `${theme.palette.primary.main} !important`,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
}));

function ChildInput({ recipient, setRecipient, deleteRecipient, error }) {
  const classes = useStyles();
  return (
    <div className={classes.inputContainer}>
      <TextField
        variant="outlined"
        label="Email"
        value={recipient}
        onChange={(e) => setRecipient(e.target.value)}
        fullWidth
        // Should autofocus when clicking on add recipient
        autoFocus={recipient === ''}
        error={!!error}
        helperText={formatApprovedDomainEmailError(error)}
      />
      <Button variant="icon" aria-label="Delete" onClick={deleteRecipient}>
        <Icons.DeleteOutlined />
      </Button>
    </div>
  );
}

export function ComplianceSettingsModal({
  open,
  onClose,
  initialState,
  errors,
  setErrors,
  updateSettings,
  loading,
}) {
  const [form, setForm] = useState(initialState);
  const classes = useStyles();

  const { bccRecipients } = form;
  const bccRecipientsErrors = errors.bccRecipients || [];
  const closeWithConfirmation = useDiscardConfirmation({
    onConfirm: onClose,
    needConfirmation: open && !objectEquals(form, initialState),
  });

  const addNewRecipient = () => {
    setForm({
      ...form,
      bccRecipients: [...bccRecipients, ''],
    });
  };

  return (
    <Modal
      open={open}
      onClose={closeWithConfirmation}
      headerLabel="Edit compliance settings"
      showCancelButton
      primaryButtonText="Save"
      primaryButtonLoading={loading}
      onEntering={() => {
        setForm(initialState);
        setErrors({});
      }}
      onSubmit={() => updateSettings(form)}
    >
      {bccRecipients.map((email, index) => (
        <ChildInput
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          recipient={email}
          error={bccRecipientsErrors[index]}
          setRecipient={(newRecipient) => {
            const newRecipients = [...bccRecipients];
            newRecipients[index] = newRecipient;
            setErrors({});
            setForm({
              ...form,
              bccRecipients: newRecipients,
            });
          }}
          deleteRecipient={() => {
            const newRecipients = [...bccRecipients];
            newRecipients.splice(index, 1);
            setErrors({});
            setForm({
              ...form,
              bccRecipients: newRecipients,
            });
          }}
        />
      ))}
      <Button
        variant="text"
        startIcon={<Icons.Add />}
        classes={{ text: classes.textButton }}
        onClick={addNewRecipient}
      >
        Add recipient
      </Button>
    </Modal>
  );
}
