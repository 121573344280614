import React from 'react';

import { PageContainer } from 'components/page_container';
import { DashboardFundTable } from './dashboard_fund_table';

export function OrganizationDashboardPage() {
  return (
    <PageContainer>
      <DashboardFundTable />
    </PageContainer>
  );
}
