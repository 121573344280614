import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Chip } from '@passthrough/uikit';
import StickerTextOutline from 'mdi-material-ui/StickerTextOutline';

const useStyles = makeStyles((theme) => ({
  chip: {
    backgroundColor: `${theme.palette.chipColors.internalNote} !important`,
    borderColor: `${theme.palette.chipColors.internalNoteBorder} !important`,
  },
}));

export function InternalNoteChip() {
  const classes = useStyles();

  return (
    <Chip
      className={classes.chip}
      icon={<StickerTextOutline />}
      label="Internal"
      variant="neutral"
      size="small"
    />
  );
}
