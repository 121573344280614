import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { TimelineTask } from './timeline_task';

const useStyles = makeStyles((theme) => ({
  grayColor: {
    color: theme.palette.text.secondary,
  },
}));

export function TimelineJointSigner({ name, email, sent, done }) {
  const classes = useStyles();
  const actionLanguage = sent ? 'have been' : 'will be';
  return (
    <TimelineTask done={done}>
      <Typography>{name} signed</Typography>
      {done ? null : (
        <div className={classes.grayColor}>
          {`Instructions ${actionLanguage} sent to ${email}`}
        </div>
      )}
    </TimelineTask>
  );
}
