import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@passthrough/uikit';
import PencilIcon from 'mdi-material-ui/PencilOutline';
import TextField from '@material-ui/core/TextField';

import { SensitiveData } from 'components/answer_displays/sensitive_data';

const useStyles = makeStyles((theme) => ({
  hiddenTaxContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  whiteSensitiveData: {
    backgroundColor: theme.palette.common.white,
  },
}));

export function DiligenceTIN({ label, tin, tinError, onChange }) {
  const classes = useStyles();
  const [shouldHide, setShouldHide] = useState(tin);

  return shouldHide ? (
    <div>
      <Typography variant="label" size="medium" color="text.secondary">
        {label}
      </Typography>

      <div className={classes.hiddenTaxContainer}>
        <SensitiveData answer={tin} className={classes.whiteSensitiveData} />

        <Button
          variant="icon"
          aria-label="Edit"
          onClick={() => {
            setShouldHide(false);
          }}
        >
          <PencilIcon />
        </Button>
      </div>
    </div>
  ) : (
    <TextField
      data-test={`${label}-input`}
      label={label}
      variant="outlined"
      value={tin}
      error={!!tinError}
      helperText={tinError}
      onChange={(e) => {
        onChange(e);
      }}
      fullWidth
    />
  );
}
