import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Alert } from 'components/alert';
import AddIcon from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { Button } from 'components/button';
import { Table, TableActions, TableContainer } from 'components/table/index';
import {
  ProfileCollaboratorTableHead,
  ProfileTableBody,
} from '../profile_collaborator_table';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: theme.spacing(1),
  },
  error: {
    width: '100%',
    display: 'flex',
    marginTop: theme.spacing(1),
  },
  tableDiv: {
    width: '100%',
    overflowX: 'auto',
  },
  addContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(2),
    gap: theme.spacing(1),
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  add: {
    paddingRight: theme.spacing(1),
  },
  addButton: {
    fontWeight: 400,
  },
  mobilePaper: {
    display: 'table',
    width: '100%',
    borderRadius: 0,
  },
}));

export function ProfileUsersList({
  allCollaborators,
  setNewCollaboratorModal,
  setAccessLevel,
  suggestedCollaborators,
  addNewCollaborator,
  onDelete,
  errors,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <TableContainer>
      <TableActions>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => setNewCollaboratorModal(true)}
          startIcon={<AddIcon />}
          data-test="add-collaborator"
        >
          Add collaborator
        </Button>
      </TableActions>
      {errors ? (
        <Alert severity="error" className={classes.error}>
          {errors}
        </Alert>
      ) : null}
      <div className={classes.tableDiv}>
        <Table>
          {/* This Paper is used to ensure the scrolling white color of the table */}
          {isMobile ? (
            <Paper className={classes.mobilePaper}>
              <ProfileCollaboratorTableHead />
              <ProfileTableBody
                allCollaborators={allCollaborators}
                setAccessLevel={setAccessLevel}
                onDelete={onDelete}
                highlightAddedCollaborators
              />
            </Paper>
          ) : (
            <>
              <ProfileCollaboratorTableHead />
              <ProfileTableBody
                allCollaborators={allCollaborators}
                setAccessLevel={setAccessLevel}
                onDelete={onDelete}
                highlightAddedCollaborators
              />
            </>
          )}
        </Table>
      </div>
      {suggestedCollaborators.length > 0 ? (
        <div className={classes.addContainer}>
          <Typography className={classes.add}>Add</Typography>
          {suggestedCollaborators.map((user) => (
            <Button
              className={classes.addButton}
              color="default"
              variant="outlined"
              onClick={() => addNewCollaborator(user)}
              startIcon={<AddIcon />}
              data-test=""
            >
              {user.name ? (
                <>
                  {user.name} ({user.email.split('@').pop()})
                </>
              ) : (
                user.email
              )}
            </Button>
          ))}
        </div>
      ) : null}
    </TableContainer>
  );
}
