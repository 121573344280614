import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FileUploadOutline from 'mdi-material-ui/FileUploadOutline';
import { Button } from 'components/button';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(2, 0),
  },
  img: {
    color: theme.palette.primary.grey,
    marginTop: theme.spacing(3),
    fontSize: theme.typography.pxToRem(100),
  },
}));

export function FileUploadArea({ inputProps, usePlural }) {
  const classes = useStyles();

  return (
    <>
      <FileUploadOutline className={classes.img} />
      <input {...inputProps} />
      <Typography variant="subtitle1">
        {`Drag your file${usePlural ? '(s)' : ''} here or`}
      </Typography>
      <Button color="primary" variant="contained" className={classes.button}>
        Browse
      </Button>
    </>
  );
}
