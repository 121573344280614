import React, { useState, useMemo, useContext, createContext } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as V4ThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider as UikitProvider } from '@passthrough/uikit';

import { theme, uikitPalette } from 'services/theme';
import { applyCustomTheme } from 'services/white_label_utils';

const WhiteLabelConfigContext = createContext();

export function useWhiteLabelConfig() {
  const whiteLabelConfig = useContext(WhiteLabelConfigContext);
  return whiteLabelConfig;
}

const DEFAULT_PRODUCT_NAME = 'Passthrough';

export function ThemeProvider({ children }) {
  const [whiteLabelConfig] = useState(
    // The "right" way to do this is to read from window in an effect and
    // set the state there, but we really want to have the correct config
    // available during the initial render.
    () => {
      const baseConfig = window.WHITE_LABEL_CONFIG || {};
      const productName = baseConfig.productName || DEFAULT_PRODUCT_NAME;
      return {
        ...baseConfig,
        productName,
      };
    },
  );

  const {
    v4Theme: customTheme,
    palette,
    fontFamily,
    headingFontFamily,
  } = useMemo(
    () => applyCustomTheme(whiteLabelConfig, uikitPalette, theme),
    [whiteLabelConfig],
  );

  return (
    <WhiteLabelConfigContext.Provider value={whiteLabelConfig}>
      <V4ThemeProvider theme={customTheme}>
        <UikitProvider
          palette={palette}
          fontFamily={fontFamily}
          headingFontFamily={headingFontFamily}
        >
          <CssBaseline />
          {children}
        </UikitProvider>
      </V4ThemeProvider>
    </WhiteLabelConfigContext.Provider>
  );
}
