import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import LockIconOutlined from '@material-ui/icons/LockOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography } from '@passthrough/uikit';

import { getAnswerDisplayComponent } from 'components/answer_displays/get_answer_display';
import { QUESTIONNAIRE_EVENT_TYPES } from 'services/thread_utils/constants';
import { TruncatedTextDisplay } from 'components/truncated_text_display';
import { FILE_ANSWER_TYPES } from '../../constants';

const useStyles = makeStyles((theme) => ({
  historicalEvent: {
    backgroundColor: theme.palette.background.alternative,
    padding: theme.spacing(2),
    overflowWrap: 'anywhere',
  },
  internalNoteEvent: {
    backgroundColor: theme.palette.background.internalNote,
    padding: theme.spacing(2),
    overflowWrap: 'anywhere',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },
  verticalMargin: {
    marginTop: theme.spacing(2),
  },
  lockIcon: {
    color: theme.palette.text.secondary,
  },
}));

export function QuestionnaireEventBody({ question, event }) {
  const classes = useStyles();

  if (
    event.type === QUESTIONNAIRE_EVENT_TYPES.THREAD_RESOLVED ||
    event.type === QUESTIONNAIRE_EVENT_TYPES.THREAD_REOPENED
  ) {
    return null;
  }

  if (
    event.type === QUESTIONNAIRE_EVENT_TYPES.GP_SENT_COMMENT ||
    event.type === QUESTIONNAIRE_EVENT_TYPES.LP_SENT_COMMENT
  ) {
    return (
      <Paper elevation={0} className={classes.historicalEvent}>
        <TruncatedTextDisplay
          content={event.noteToInvestor}
          textColor="primary"
          expandText="See full comment"
          contractText="Hide full comment"
        />
      </Paper>
    );
  }

  if (event.type === QUESTIONNAIRE_EVENT_TYPES.INTERNAL_NOTE_ADDED) {
    return (
      <Paper elevation={0} className={classes.internalNoteEvent}>
        <TruncatedTextDisplay
          content={event.internalNote}
          textColor="default"
          expandText="See full note"
          contractText="Hide full note"
        />
        <Tooltip
          title={
            <Typography variant="label">Not visible to the investor</Typography>
          }
        >
          <LockIconOutlined className={classes.lockIcon} fontSize="small" />
        </Tooltip>
      </Paper>
    );
  }

  // answer submission events are handled within the event header comp
  // which allows for truncation and conditionally rendering full displays
  if (event.type === QUESTIONNAIRE_EVENT_TYPES.ANSWER_PREFILLED) {
    const AnswerComponent = getAnswerDisplayComponent(question.answerType);

    const removeBackground =
      FILE_ANSWER_TYPES.includes(question.answerType) &&
      event.type === QUESTIONNAIRE_EVENT_TYPES.ANSWER_SUBMITTED;

    return removeBackground ? (
      <div className={classes.verticalMargin}>
        <AnswerComponent answer={event.answer} />
      </div>
    ) : (
      <Paper elevation={0} className={clsx(classes.historicalEvent)}>
        <AnswerComponent answer={event.answer} />
      </Paper>
    );
  }

  return null;
}
