import React from 'react';
import { Typography } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  mainText: {
    marginBottom: theme.spacing(2),
  },
}));

export function SignInWaitStep({ email }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.mainText}>
        <Typography>
          We've sent an email to <b>{email}</b> with a sign in link. If the
          email does not show up within a minute, make sure to check your spam
          folder.
        </Typography>
      </div>
      <Typography>You may close this tab.</Typography>
    </>
  );
}
