import React from 'react';

import { ANSWER_STATES } from 'services/constants';
import { getCountry } from 'services/countries';
import { DiffStyle } from 'components/Diff';
import { MAX_NUM_CHARS } from './constants';

export function CrsTaxDetailsV2Display({ answer, questionState, flatten }) {
  if (!answer) {
    return null;
  }

  if (flatten) {
    const taxNum = answer.notApplicable
      ? answer.reasonNotApplicable
      : `${answer.taxType}, ${answer.taxNumber}`;
    const numChars = getCountry(answer.country).length + taxNum.length;

    const joinedAnswers = `${getCountry(answer.country)}, ${taxNum}`;
    const truncatedAnswer = joinedAnswers.substring(0, MAX_NUM_CHARS);

    return numChars >= MAX_NUM_CHARS ? `${truncatedAnswer}...` : joinedAnswers;
  }

  return (
    <div>
      <div>
        <DiffStyle
          isNew={questionState === ANSWER_STATES.CHANGED_CURRENT_ANSWER}
          isDeleted={questionState === ANSWER_STATES.CHANGED_PRIOR_ANSWER}
        >
          {getCountry(answer.country)}
        </DiffStyle>
      </div>
      {answer.notApplicable ? (
        <DiffStyle
          isNew={questionState === ANSWER_STATES.CHANGED_CURRENT_ANSWER}
          isDeleted={questionState === ANSWER_STATES.CHANGED_PRIOR_ANSWER}
        >
          {answer.reasonNotApplicable}
        </DiffStyle>
      ) : (
        <>
          <div>
            <DiffStyle
              isNew={questionState === ANSWER_STATES.CHANGED_CURRENT_ANSWER}
              isDeleted={questionState === ANSWER_STATES.CHANGED_PRIOR_ANSWER}
            >
              {answer.taxType}
            </DiffStyle>
          </div>
          <div>
            <DiffStyle
              isNew={questionState === ANSWER_STATES.CHANGED_CURRENT_ANSWER}
              isDeleted={questionState === ANSWER_STATES.CHANGED_PRIOR_ANSWER}
            >
              {answer.taxNumber}
            </DiffStyle>
          </div>
        </>
      )}
    </div>
  );
}
