import { getCountry } from 'services/countries';

function getCountryValueFromAddressFromCode(rawCountry) {
  return getCountry(rawCountry) || rawCountry;
}

export function formatAddress({ line1, line2 }) {
  const { street, city, state, postalCode, country } = line1;

  const addressParts = [
    street || '',
    line2 || '',
    city || '',
    `${state || ''} ${postalCode || ''}`,
    getCountryValueFromAddressFromCode(country) || '',
  ];
  return addressParts
    .map((s) => s.trim())
    .filter(Boolean)
    .join(', ');
}
