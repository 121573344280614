import React from 'react';

import { Typography } from '@passthrough/uikit';
import { BooleanValue } from 'components/boolean_value';
import { SettingsGroup, Setting } from 'components/settings_group';

import { NotificationLearnMoreLink } from './notification_learn_more_link';

export function FundCollaboratorNotificationSettings({
  fullyExecutedAdminEmailEnabled,
  onEdit,
}) {
  return (
    <SettingsGroup
      header="Fund collaborator notifications"
      subheader={
        <NotificationLearnMoreLink text="fund collaborator notifications" />
      }
      onEdit={onEdit}
    >
      <Setting
        label="Fully executed email"
        description={
          <Typography variant="body" size="small" color="text.secondary">
            Send fund collaborators an email when documents are fully executed.
          </Typography>
        }
      >
        <BooleanValue
          label={fullyExecutedAdminEmailEnabled ? 'Enabled' : 'Disabled'}
          bool={fullyExecutedAdminEmailEnabled}
        />
      </Setting>
    </SettingsGroup>
  );
}
