import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import cloneDeep from 'lodash/cloneDeep';

import { Typography, Chip, Icons } from '@passthrough/uikit';

import { inProgressStatuses } from 'components/status/constants';
import { Dialog } from 'components/dialog/index';
import { Spinner } from 'components/spinner';
import { EmptyState } from 'components/empty_v2';
import { useMe } from 'services/providers/me';
import { annotateDiligenceQuestions } from 'components/lp_doc/diligence/utils';
import * as api from 'services/api';
import { useToast } from 'services/toast';
import { PageContainer } from 'components/page_container';
import { DiligenceReviewModal } from './review_modal/index';
import { DiligenceActionsMenu } from './diligence_actions_menu';
import { DiligenceSubmissionOverview } from './diligence_submission_overview';
import { QUESTIONNAIRES_SUBMITTED_STATUSES } from '../constants';

const useStyles = makeStyles((theme) => ({
  topBar: {
    display: 'flex',
    padding: theme.spacing(2, 3, 2, 6),
    justifyContent: 'start',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    gap: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(3),
    },
    minHeight: '70px',
  },
  flexGrow: {
    flexGrow: 1,
  },
  searchesPendingDisplayContainer: {
    padding: theme.spacing(20),
    display: 'flex',
    rowGap: theme.spacing(2),
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export function DiligenceV2Tab({
  investorName,
  fundName,
  fundId,
  lpClosingId,
  diligenceId,
  docCollectionMode,
  managedDiligenceEnabled,
  jurisdiction,
  diligenceQuestions,
  diligenceApprovers,
  refreshDiligenceData,
  showSpinner,
  pendingUpdate,
  closingStatus,
  areSearchesReady,
  riskRecommendation,
  canSkipDiligence,
  canRequireDiligence,
  canWaiveDiligence,
  isPostApproval,
  isDiligenceOnly,
}) {
  const classes = useStyles();
  const [me] = useMe();
  const { toast } = useToast();

  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const [numUnsent, setNumUnsent] = useState(0);
  const [numUnresolved, setNumUnresolved] = useState(0);
  const [numUpdated, setNumUpdated] = useState(0);
  const [gpSubmittedAnswer, setGpSubmittedAnswer] = useState(false);
  const pollTimeoutRef = useRef(null);

  const isInProgress = inProgressStatuses.includes(closingStatus);

  const riskChoice = riskRecommendation || '';
  // explicitly check falseness of lpdiligence state so that we ignore situations
  // where this component renders prior to diligence data loading
  // also use frontend state so that we show the spinner immediately upon closing modal
  const isWaitingForSearches = areSearchesReady === false || gpSubmittedAnswer;

  // in order to support both answer diffs and dynamically injecting name data into
  // reused nodes, we need to retain an original copy of its answers
  const rawAnswerData =
    diligenceQuestions?.find((q) => q.label === selectedQuestionId)?.answer ||
    {};
  const diligenceQuestionDataToProcess = cloneDeep(diligenceQuestions);

  const annotatedNodes = annotateDiligenceQuestions(
    diligenceQuestionDataToProcess,
  );
  const originalDiligenceQuestions = annotatedNodes.filter(
    (question) => !question?.answer?.idToReuse,
  );

  const showReviewModal = selectedQuestionId != null;
  const currQuestionData =
    originalDiligenceQuestions.find(
      (question) => question.label === selectedQuestionId,
    ) || {};

  const userIsADiligenceApprover = diligenceApprovers.some(
    (approver) => approver.email === me.email,
  );
  // there actually can only be 1 diligence approver
  const diligenceApproverName = diligenceApprovers[0]?.name;
  const shouldDisplayTree = originalDiligenceQuestions.length > 1;

  const numApprovedOriginalOwners = originalDiligenceQuestions.filter(
    (question) => question.isApproved,
  ).length;

  const numOriginalOwners = originalDiligenceQuestions.length;

  const currOwnerIndex = originalDiligenceQuestions.findIndex(
    (question) => question.label === selectedQuestionId,
  );

  const canAlterDiligence =
    canSkipDiligence || canWaiveDiligence || canRequireDiligence;

  // the root diligence question is created as soon as the lpclosing is first sent
  const rootDiligenceQuestionId = 'QD1';
  const isEmptyState =
    originalDiligenceQuestions.length === 1 &&
    originalDiligenceQuestions[0].label === rootDiligenceQuestionId &&
    !originalDiligenceQuestions[0].answer;

  const onLeftNavClick = () => {
    const prevIndex = originalDiligenceQuestions.findLastIndex(
      (question, index) => question.hasDiligenceNode && index < currOwnerIndex,
    );
    const priorDiligenceQuestion = originalDiligenceQuestions[prevIndex];
    if (priorDiligenceQuestion) {
      setSelectedQuestionId(priorDiligenceQuestion.label);
      refreshDiligenceData();
    }
  };

  const onRightNavClick = () => {
    const nextIndex = originalDiligenceQuestions.findIndex(
      (question, index) => question.hasDiligenceNode && index > currOwnerIndex,
    );
    const nextDiligenceQuestion = originalDiligenceQuestions[nextIndex];
    if (nextDiligenceQuestion) {
      setSelectedQuestionId(nextDiligenceQuestion.label);
      refreshDiligenceData();
    }
  };

  const onShowReviewClick = (selectedId) => {
    setSelectedQuestionId(selectedId);
  };

  const changeRiskChoice = (updatedRiskChoice) => {
    let submissionChoice = updatedRiskChoice;
    if (updatedRiskChoice === '') {
      submissionChoice = null;
    }
    api
      .updateRiskRecommendation({
        fundId,
        lpClosingId,
        riskRecommendation: submissionChoice,
      })
      .then(() => {
        refreshDiligenceData();

        const toastMessage =
          submissionChoice === null
            ? 'Risk rating removed'
            : 'Risk rating updated';
        toast(toastMessage);
      });
  };

  useEffect(() => {
    setNumUnsent(
      annotatedNodes.reduce(
        (total, question) => total + (question.draftComment ? 1 : 0),
        0,
      ),
    );
    setNumUnresolved(
      annotatedNodes.reduce(
        (total, question) =>
          total +
          (question.numCommentEvents > 0 &&
          !question.isThreadResolved &&
          !question.draftComment
            ? 1
            : 0),
        0,
      ),
    );
    setNumUpdated(
      annotatedNodes.reduce(
        (total, question) =>
          total +
          (question.updatedSinceLastSubmission && !isInProgress ? 1 : 0),
        0,
      ),
    );
  }, [annotatedNodes]);

  function pollForReadySearches() {
    api.lpDiligenceAreSearchesReady({ fundId, lpClosingId }).then((resp) => {
      if (resp?.data?.areSearchesReady) {
        setGpSubmittedAnswer(false);
        refreshDiligenceData();
        toast('Ready for review');
      } else {
        pollTimeoutRef.current = setTimeout(pollForReadySearches, 2000);
      }
    });
  }

  useEffect(() => {
    if (isWaitingForSearches) {
      pollForReadySearches();
    }

    return () => {
      clearTimeout(pollTimeoutRef.current);
    };
  }, [areSearchesReady, gpSubmittedAnswer]);

  const topBar =
    isInProgress ||
    (numUnresolved && !isPostApproval) ||
    numUnsent ||
    numUpdated ||
    canAlterDiligence ? (
      <div className={classes.topBar}>
        {isInProgress ? (
          <Tooltip
            title={
              <Typography variant="label">
                This investor has not yet completed their questionnaire and
                their answers may still change.
              </Typography>
            }
          >
            <Chip
              label="Data not final"
              variant="warning"
              icon={<Icons.WarningAmber />}
            />
          </Tooltip>
        ) : null}
        {numUnresolved ? (
          <Tooltip
            title={
              <Typography variant="label">
                Resolve all comment threads to approve investors.
              </Typography>
            }
          >
            <Chip
              label={`Unresolved (${numUnresolved})`}
              variant="warning"
              icon={<Icons.ChatOutlined />}
            />
          </Tooltip>
        ) : null}
        {numUpdated ? (
          <Tooltip
            title={
              <Typography variant="label">
                The investor updated their submission since last review.
              </Typography>
            }
          >
            <Chip
              label={`Updated (${numUpdated})`}
              icon={<Icons.FlagOutlined />}
              variant="info"
            />
          </Tooltip>
        ) : null}
        {numUnsent ? (
          <Tooltip
            title={
              <Typography variant="label">
                The investor is not notified of comment drafts until they are
                sent.
              </Typography>
            }
          >
            <Chip
              label={`Unsent (${numUnsent})`}
              variant="info"
              icon={<Icons.ChatOutlined />}
            />
          </Tooltip>
        ) : null}
        {canAlterDiligence ? (
          <DiligenceActionsMenu
            isDiligenceOnly={isDiligenceOnly}
            investorName={investorName}
            canWaiveDiligence={canWaiveDiligence}
            canRequireDiligence={canRequireDiligence}
            refreshDiligenceData={refreshDiligenceData}
            fundId={fundId}
            lpClosingId={lpClosingId}
            diligenceId={diligenceId}
            onSkip={() => {
              refreshDiligenceData();
              toast(`Deleted diligence for ${investorName}`);
            }}
            onWaive={() => {
              refreshDiligenceData();
              toast(`Made diligence optional for ${investorName}`);
            }}
            onRequire={() => {
              refreshDiligenceData();
              toast(`Made diligence required for ${investorName}`);
            }}
          />
        ) : null}
      </div>
    ) : null;

  if (isEmptyState) {
    // A closing in a post submission status can be moved into a diligence closing
    // and will then default to this case
    const showComeBackLaterText =
      !QUESTIONNAIRES_SUBMITTED_STATUSES.includes(closingStatus);

    return (
      <>
        {topBar}
        <EmptyState
          title="No data provided"
          text={
            showComeBackLaterText
              ? 'Come back once the investor has submitted their questionnaire.'
              : ''
          }
        />
      </>
    );
  }

  // Decide if we're passing the last submitted answer to the child components.
  // Answer diffs are only available if the questionnaire is not
  // in progress _and_ the answer has been updated since the last submission.
  const updated = !isInProgress && currQuestionData.updatedSinceLastSubmission;
  const lastSubmittedAnswer = updated
    ? currQuestionData.lastSubmittedAnswer
    : null;

  return (
    <>
      {topBar}
      <PageContainer maxWidth="lg">
        {/* must disable enforce focus so that lower level GP name change modal can be focused */}
        <Dialog open={showReviewModal} fullScreen disableEnforceFocus>
          <DiligenceReviewModal
            fundName={fundName}
            managedDiligenceEnabled={managedDiligenceEnabled}
            docCollectionMode={docCollectionMode}
            jurisdiction={jurisdiction}
            selectedQuestionId={selectedQuestionId}
            allQuestions={annotatedNodes}
            rawAnswerData={rawAnswerData}
            answerData={currQuestionData.answer}
            lastSubmittedAnswerData={lastSubmittedAnswer}
            updated={updated}
            numCommentEvents={currQuestionData.numCommentEvents}
            draftComment={currQuestionData.draftComment}
            hasUnresolvedInternalNote={
              currQuestionData.hasUnresolvedInternalNote
            }
            initialNodeApprovedState={currQuestionData.isApproved}
            reusedNodeIds={currQuestionData.dependentLabels}
            numUnresolvedThreads={currQuestionData.numUnresolvedThreads}
            hasSearch={currQuestionData.hasSearch}
            isDiligenceApprover={userIsADiligenceApprover}
            diligenceApproverName={diligenceApproverName}
            currOwnerIndex={currOwnerIndex}
            numApprovedOwners={numApprovedOriginalOwners}
            numOwners={numOriginalOwners}
            closingStatus={closingStatus}
            isInProgress={isInProgress}
            onLeftNavClick={onLeftNavClick}
            onRightNavClick={onRightNavClick}
            closeModal={() => {
              setSelectedQuestionId(null);
            }}
            onGpAnswerSubmission={() => {
              setSelectedQuestionId(null);
              setGpSubmittedAnswer(true);
            }}
            refreshDiligenceData={refreshDiligenceData}
          />
        </Dialog>

        {isWaitingForSearches ? (
          <div className={classes.searchesPendingDisplayContainer}>
            <Spinner height={50} width={50} />

            <Typography variant="card-heading" size="medium">
              Screening investor...
            </Typography>
          </div>
        ) : (
          <DiligenceSubmissionOverview
            userIsADiligenceApprover={userIsADiligenceApprover}
            diligenceApproverName={diligenceApproverName}
            showSpinner={showSpinner}
            riskChoice={riskChoice}
            changeRiskChoice={changeRiskChoice}
            pendingUpdate={pendingUpdate}
            shouldDisplayTree={shouldDisplayTree}
            jurisdiction={jurisdiction}
            annotatedNodes={annotatedNodes}
            originalDiligenceQuestions={originalDiligenceQuestions}
            onShowReviewClick={onShowReviewClick}
            areSearchesReady={areSearchesReady}
            isInProgress={isInProgress}
          />
        )}
      </PageContainer>
    </>
  );
}
