import React from 'react';

import { ANSWER_STATES } from 'services/constants';
import { DiffStyle } from 'components/Diff';

import { formatAddress } from './utils';
import { MAX_NUM_CHARS } from './constants';

const addressKeys = ['manualAddress', 'googleAddress'];

const answerKeys = [
  'bankName',
  ...addressKeys,
  'aba',
  'swift',
  'iban',
  'accountNumber',
  'accountName',
  'transmissionNotes',
  'ffctAccountNumber',
  'ffctAccountName',
  'ffct',
];

function getBankAnswers(answer) {
  // bank questions that contain addresses have the bank
  // and address as top most keys
  const bank = answer?.bank ?? answer;

  return answerKeys.map((key) => {
    if (addressKeys.includes(key)) {
      const rawAnswer = answer[key];
      return [key, rawAnswer ? formatAddress(rawAnswer) : null];
    }

    return [key, bank[key]];
  });
}

export function BankDisplay({ answer, questionState, flatten }) {
  if (!answer) {
    return null;
  }

  const subAnswers = getBankAnswers(answer);

  if (flatten) {
    const nonNullAnswers = subAnswers.filter((a) => a[1]);
    const nonNullAnswerValues = nonNullAnswers.map((a) => a[1]);

    const numChars = nonNullAnswerValues.reduce(
      (prevCount, subAnswer) => prevCount + `${subAnswer}`.length,
      0,
    );

    const joinedAnswers = nonNullAnswerValues.join(', ');
    const truncatedAnswer = joinedAnswers.substring(0, MAX_NUM_CHARS);

    return numChars >= MAX_NUM_CHARS ? `${truncatedAnswer}...` : joinedAnswers;
  }

  return (
    <div>
      {subAnswers.map(([key, sa]) =>
        sa ? (
          <div key={key}>
            <DiffStyle
              isNew={questionState === ANSWER_STATES.CHANGED_CURRENT_ANSWER}
              isDeleted={questionState === ANSWER_STATES.CHANGED_PRIOR_ANSWER}
            >
              {sa}
            </DiffStyle>
          </div>
        ) : null,
      )}
    </div>
  );
}
