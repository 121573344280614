import React from 'react';

import { YesNo } from 'components/lp_doc/diligence/components/yesno';
import { getOwnerRequirementsForJurisdiction } from 'components/lp_doc/diligence/type_to_requirements_config';
import { RootSourceOfWealthDetails } from '../../../subsection_question_display/details_subsection/jurisdiction_specific_details/root_source_of_wealth_data';
import { IndividualDetails } from '../../../components/details/individual';
import { TinHqAddressDetails } from '../../../components/details/tin_and_hq_address_details';
import {
  TYPE_INDIVIDUAL,
  TYPE_REGULATED_FINANCIAL_INSTITUTION,
  TYPE_PRIVATE_SCHOOL,
  TYPE_GOV_BODY,
  TYPE_TRUST,
  TYPE_PARTNERSHIP,
  TYPE_FOUNDATION,
  TYPE_PRIVATE_COMPANY,
  TYPE_INVESTMENT_FUND,
  TYPE_PENSION_FUND,
  TYPE_CLUBS,
  TYPE_NON_PROFIT,
  TYPE_LISTED_COMPANY,
  jurisdictions,
} from '../../../constants';

const TIN_HQ_TYPES = [
  TYPE_REGULATED_FINANCIAL_INSTITUTION,
  TYPE_LISTED_COMPANY,
  TYPE_PRIVATE_COMPANY,
  TYPE_PRIVATE_SCHOOL,
  TYPE_GOV_BODY,
  TYPE_INVESTMENT_FUND,
  TYPE_PENSION_FUND,
  TYPE_TRUST,
  TYPE_PARTNERSHIP,
  TYPE_CLUBS,
  TYPE_NON_PROFIT,
  TYPE_FOUNDATION,
];

export function AdditionalLuxDetails({
  isRootNode,
  form,
  formErrors,
  handleChange,
}) {
  const config = getOwnerRequirementsForJurisdiction(
    jurisdictions.LUXEMBOURG,
  )?.[form.type];
  const shouldDisplayTinAndHq = TIN_HQ_TYPES.includes(form.type);

  const addressOnly = [TYPE_LISTED_COMPANY].includes(form.type);

  const isNestedIndividual = form.type === TYPE_INDIVIDUAL;
  const isInvestmentCompany = form.type === TYPE_INVESTMENT_FUND;

  const showSourceOfWealth = isRootNode && !config?.disableSourceOfWealth;

  return (
    <>
      {shouldDisplayTinAndHq ? (
        <TinHqAddressDetails
          // use type as key so that we reset the state of the tin component
          // when the type changes but we still render this comp
          key={form.type}
          tin={form?.tin}
          tinError={formErrors?.tin}
          hqAddress={form?.hqAddress}
          hqAddressError={formErrors?.hqAddress}
          handleChange={handleChange}
          addressLabel="Registered address"
          addressOnly={addressOnly}
        />
      ) : null}

      {isNestedIndividual ? (
        <IndividualDetails
          dob={form.dob}
          dobError={formErrors.dob}
          ssn={form.ssn}
          ssnError={formErrors.ssn}
          useSsn={false}
          handleChange={handleChange}
          residentialAddress={form.residentialAddress}
          residentialAddressError={formErrors.residentialAddress}
          taxIDLabel="TIN or Passport number"
          disableFlip
        />
      ) : null}

      {isInvestmentCompany ? (
        <>
          <YesNo
            label="Is the investment fund regulated in an equivalent jurisdiction to Luxembourg? "
            value={form.fundRegulationStatus}
            handleChange={handleChange}
            name="fundRegulationStatus"
            error={formErrors.fundRegulationStatus}
          />
          <YesNo
            label="Is the investment fund's manager regulated in an equivalent jurisdiction to Luxembourg?"
            value={form.fundManagerRegulationStatus}
            handleChange={handleChange}
            name="fundManagerRegulationStatus"
            error={formErrors.fundManagerRegulationStatus}
          />
        </>
      ) : null}

      {showSourceOfWealth ? (
        <RootSourceOfWealthDetails
          sourceOfWealth={form.sourceOfWealth}
          sourceOfWealthError={formErrors.sourceOfWealth}
          sourceOfWealthDetails={form.sourceOfWealthDetails}
          sourceOfWealthDetailsError={formErrors.sourceOfWealthDetails}
          handleChange={handleChange}
        />
      ) : null}
    </>
  );
}
