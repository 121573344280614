import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Menu from '@material-ui/core/Menu';
import { Button } from 'components/button';

const useStyles = makeStyles((theme) => ({
  textField: {
    border: 'none',
  },
  dropdownPaper: {
    padding: 0,
    margin: 0,
    border: 'none',
    background: 'inherit',
  },
  dropdownItem: {
    width: '400px',
  },
  selectedCount: {
    width: '15px',
    height: '15px',
    fontSize: `${theme.typography.pxToRem(12)} !important`,
    borderRadius: '50%',
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const Dropdown = ({
  children,
  placeholder,
  options,
  getOptionLabel,
  onSelect,
  startIcon,
  selectedCount,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        startIcon={
          selectedCount ? (
            <span className={classes.selectedCount}>{selectedCount}</span>
          ) : (
            startIcon
          )
        }
        variant={selectedCount ? 'contained' : 'outlined'}
        size="small"
        disabled={options.length === 0}
      >
        {children}
      </Button>
      {anchorEl ? (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          PaperProps={{
            elevation: 0,
            variant: 'outlined',
            className: classes.dropdownPaper,
          }}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          className={classes.dropdown}
          transformOrigin={{
            vertical: -45,
            horizontal: 0,
          }}
        >
          <Autocomplete
            size="small"
            clearOnEscape
            disableClearable
            transitionDuration={0}
            onChange={(_, newValue) => {
              if (options.includes(newValue)) {
                setAnchorEl(null);
                onSelect(newValue);
              }
            }}
            options={options}
            getOptionLabel={getOptionLabel}
            className={classes.textField}
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={placeholder}
                size="small"
                className={classes.dropdownItem}
                variant="outlined"
              />
            )}
          />
        </Menu>
      ) : null}
    </>
  );
};
