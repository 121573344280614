import React, { useState } from 'react';
import clsx from 'clsx';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Still using MUI Button because the uikit one doesn't play nice with the
// ButtonGroup component
import { Button } from 'components/button';

const useStyles = makeStyles((theme) => ({
  icon: {
    margin: theme.spacing(0),
  },
  zoomButton: {
    minWidth: '80px',
    paddingLeft: '10px',
    paddingRight: '5px',
  },
  zoomMenu: {
    zIndex: 2,
  },
  invertedIcon: {
    transform: 'rotate(180deg)',
  },
  group: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export function Zoom({ zoom, setZoom, disabled = false }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const presetZoomPercentages = [0.5, 0.8, 1.0, 1.2, 1.4, 1.6, 1.8, 2.0];
  const MIN_ZOOM_PERCENTAGE = 0.5;
  const MAX_ZOOM_PERCENTAGE = 2.0;
  const ZOOM_STEP = 0.1;

  const onZoomButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prevOpenState) => !prevOpenState);
  };

  const zoomOutDisabled = zoom <= MIN_ZOOM_PERCENTAGE || disabled;
  const zoomInDisabled = zoom >= MAX_ZOOM_PERCENTAGE || disabled;

  return (
    <ButtonGroup
      className={classes.group}
      variant="outlined"
      aria-label="zoom-control"
    >
      <Button
        color="default"
        variant="outlined"
        disabled={zoomOutDisabled}
        onClick={() => {
          setZoom((z) => Math.max(z - ZOOM_STEP, MIN_ZOOM_PERCENTAGE));
        }}
        startIcon={
          <RemoveIcon color={zoomOutDisabled ? 'neutral' : 'primary'} />
        }
        classes={{
          startIcon: classes.icon,
        }}
      />
      <Button
        className={classes.zoomButton}
        onClick={onZoomButtonClick}
        endIcon={
          <ExpandMoreIcon
            color="primary"
            className={clsx({ [classes.invertedIcon]: open })}
          />
        }
      >
        {Math.round(zoom * 100)}%
      </Button>
      <Popper
        open={open}
        anchorEl={anchorEl}
        disablePortal
        placement="bottom"
        className={classes.zoomMenu}
      >
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Paper>
            <MenuList id="split-button-menu">
              {presetZoomPercentages.map((zoomVal) => (
                <MenuItem
                  key={zoomVal}
                  disabled={disabled}
                  value={zoomVal}
                  onClick={() => {
                    setOpen(false);
                    setZoom(zoomVal);
                  }}
                >
                  {`${zoomVal * 100}%`}
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
      <Button
        color="default"
        variant="outlined"
        disabled={zoomInDisabled}
        onClick={() => {
          setZoom((z) => Math.min(z + ZOOM_STEP, MAX_ZOOM_PERCENTAGE));
        }}
        endIcon={<AddIcon color={zoomInDisabled ? 'neutral' : 'primary'} />}
        classes={{
          endIcon: classes.icon,
        }}
      />
    </ButtonGroup>
  );
}
