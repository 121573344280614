/* eslint-disable react/no-array-index-key */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Answers } from '../../answers';
import { MultiChoiceList, MultiChoiceFileItem, Actions } from '../common';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '500px',
  },
  description: {
    textAlign: 'center',
    fontSize: '0.8rem',
    marginBottom: theme.spacing(2),
  },
}));

export const MultiFileQuestionReconciliation = ({
  lpDoc,
  activeProfile,
  QuestionPromptComponent,
  answersToReconcile,
  navRef,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState([]);
  const answers = answersToReconcile.flatMap((answer) =>
    answer.answer.map((file) => ({
      file,
      source: answer.source,
    })),
  );
  const files = answers.reduce(
    (prev, cur) => ({
      ...prev,
      [cur.file.fileHash]: {
        file: cur.file,
        sources: [...(prev[cur.file.fileHash]?.sources || []), cur.source],
      },
    }),
    {},
  );
  const toggleChoice = (idx) => {
    if (value.includes(idx)) {
      setValue(value.filter((v) => v !== idx));
    } else {
      setValue([...value, idx]);
    }
  };
  const answer =
    value === null || !value?.length
      ? null
      : value.map((idx) => answers[idx].file);
  return (
    <div className={classes.root}>
      <QuestionPromptComponent short />
      <FormHelperText className={classes.description}>
        Select an option to prefill your answer. You may make edits in the next
        step.
      </FormHelperText>
      <Answers>
        <MultiChoiceList>
          {Object.values(files).map((answerToReconcile, idx) => (
            <MultiChoiceFileItem
              key={idx}
              sources={answerToReconcile.sources}
              lpDoc={lpDoc}
              activeProfile={activeProfile}
              name={idx}
              checked={value.includes(idx)}
              onChange={() => toggleChoice(idx)}
              fileUrl={answerToReconcile?.file?.fileUrl}
            >
              {answerToReconcile?.file?.fileName}
            </MultiChoiceFileItem>
          ))}
        </MultiChoiceList>
        <Actions navRef={navRef} answer={answer} showSkip />
      </Answers>
    </div>
  );
};
