import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    backgroundColor: theme.palette.waves.background,
    minHeight: '100%',
    justifyContent: 'center',
  },
  container: {
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export function FormattedContainer({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <div className={classes.container}>
        <CssBaseline />
        {children}
      </div>
    </div>
  );
}
