import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { useQuestionnaire } from 'services/providers/questionnaire';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 9997,
  },
  screen: {
    display: 'block',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    '@media only screen and (max-width: 767px),only screen and (max-width: 900px) and (min-aspect-ratio: 16/9)':
      {
        display: 'none',
      },
  },
  content: {
    boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.25)',
    position: 'absolute',
    top: '5vh',
    left: '50%',
    transform: 'translateX(-50%)',
    height: '90vh',
    minHeight: '400px',
    width: '80vw',
    minWidth: '512px',
    maxWidth: '1024px',
    '@media only screen and (max-width: 767px),only screen and (max-width: 900px) and (min-aspect-ratio: 16/9)':
      {
        overflow: 'hidden',
        boxShadow: 'none',
        height: '100%',
        minHeight: 0,
        width: '100%',
        minWidth: 0,
        transform: 'none',
        left: 0,
        top: 0,
      },
  },
  closeContainer: {
    position: 'absolute',
    top: 0,
    right: '-60px',
    height: '40px',
    width: '40px',
    '@media only screen and (max-width: 767px),only screen and (max-width: 900px) and (min-aspect-ratio: 16/9)':
      {
        display: 'none',
      },
  },
  closeButton: {
    '-webkit-appearance': 'none',
    height: '100%',
    width: '100%',
    border: 'none',
    backgroundColor: '#1A1A1A',
    transition: 'background-color 0.35s ease',
    willChange: 'background-color',
    boxShadow: '0px 3px 9px 0px rgba(0, 0, 0, 0.25)',
    cursor: 'pointer',
    padding: '20px',

    '&:hover': {
      backgroundColor: '#4E4E4E',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '1px',
      backgroundColor: 'white',
      height: '20px',
      top: '10px',
      // Bottom-left to top-right white line.
      transform: 'rotateZ(45deg)',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '1px',
      backgroundColor: 'white',
      height: '20px',
      top: '10px',
      // Top-left to bottom-right white line.
      transform: 'rotateZ(-45deg)',
    },
  },
  iframe: {
    minHeight: '400px',
    backgroundColor: '#F7F8F9',
    height: '100%',
    border: 'none',

    // Fix 100% width iFrame on iOS.
    // https://stackoverflow.com/a/20142280
    minWidth: '100%',
    width: '100px',
    '*width': '100%',
  },
}));

export const DocuSignDialog = ({
  url,
  docId,
  onClose,
  onSign,
  onIdCheckFailed,
  isDocusignQesEnabled,
}) => {
  const classes = useStyles();
  const { useSupport } = useQuestionnaire();
  const { showSupport } = useSupport();
  const history = useHistory();

  useEffect(() => {
    const handler = (event) => {
      if (event.data === 'signed') {
        onSign();
        onClose();
      }

      if (event.data === 'contactSupport') {
        onClose();
        showSupport();
      }

      if (event.data === 'close') {
        onClose();
      }

      if (event.data === 'idCheckFailed') {
        // only the sdk can send this message as if in the webapp
        // we directly process the underlying lpdoc
        onIdCheckFailed(docId);
        onClose();
        showSupport();
      }
    };

    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, []);

  useEffect(() => {
    const unlistenCallback = history.listen((location, action) => {
      if (action === 'POP') {
        onClose();
      }
    });

    return () => {
      unlistenCallback();
    };
  }, []);

  const allow = isDocusignQesEnabled ? 'camera; microphone' : undefined;
  return (
    <div className={classes.container}>
      <div className={classes.screen} />
      <div className={classes.content}>
        <div className={classes.closeContainer}>
          <button
            className={classes.closeButton}
            onClick={onClose}
            type="button"
            aria-label="close"
            title="Close signature request"
            data-test="docusign-close-btn"
          />
        </div>
        <iframe
          src={url}
          className={classes.iframe}
          title="docusign"
          name="docusign-dialog"
          sandbox="allow-scripts allow-same-origin allow-forms"
          allow={allow}
          scrolling="no"
        />
      </div>
    </div>
  );
};
