import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Timeline from '@material-ui/lab/Timeline';
import { PaperHeader } from 'components/paper_header';
import { TimelineTask } from './timeline_task';

const useStyles = makeStyles((theme) => ({
  grayColor: {
    color: theme.palette.text.secondary,
  },
}));

// When LP request changes, we have a separate timeline. Once those
// LP requested changes are approved, then they continue back to
// the standard timeline.
export function LpReviewRequestedTodoTimeline({ fundName }) {
  const classes = useStyles();

  return (
    <PaperHeader headerText="What happens next?">
      <Timeline
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <TimelineTask done>Review requested</TimelineTask>
        <TimelineTask>
          <Typography>Reviewed by {fundName}</Typography>
          <div className={classes.grayColor}>
            You will be notified when the review is complete.
          </div>
        </TimelineTask>
        <TimelineTask isLast>Update answers or sign and submit</TimelineTask>
      </Timeline>
    </PaperHeader>
  );
}
