import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  badge: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '20px',
  },
  badgeDot: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
}));

export function UnreadBadge({ className }) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.badge, className)}>
      <div className={classes.badgeDot} />
    </div>
  );
}
