import * as React from 'react';
import { Button, Icons, Menu, MenuItem, Typography } from '@passthrough/uikit';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Tooltip, makeStyles } from '@material-ui/core';
import { useDocumentsDrawerState, useDocumentsDrawerActions } from './utils';
import { DOCUMENTS_MENU, DOWNLOAD_MENU } from './constants';

const useStyles = makeStyles((theme) => ({
  topBar: {
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
    ...theme.mixins.toolbar,
    padding: theme.spacing(0, 3, 0, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  topBarName: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    overflow: 'hidden',
    flex: 1,
  },
  closeButton: {
    [theme.breakpoints.down('md')]: {
      transform: 'rotate(90deg)',
    },
  },
}));

function downloadFile(fileUrl) {
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.src = `${fileUrl}?download=true`;
  document.body.appendChild(iframe);
  setTimeout(() => {
    document.body.removeChild(iframe);
  }, 5000);
}

function SimpleDownloadButton({ templateFileUrl }) {
  return (
    <Button
      variant="text"
      onClick={() => downloadFile(templateFileUrl)}
      size="small"
      startIcon={<Icons.FileDownloadOutlined color="primary" />}
    >
      Download
    </Button>
  );
}

function DownloadMenu({ state, actions, fileUrl, templateFileUrl }) {
  return (
    <>
      <Button
        variant="text"
        startIcon={<Icons.FileDownloadOutlined color="primary" />}
        size="small"
        endIcon={<Icons.KeyboardArrowDownOutlined />}
        onClick={(ev) =>
          state.currentMenu === DOWNLOAD_MENU
            ? actions.closeMenu()
            : actions.openMenu({
                menu: DOWNLOAD_MENU,
                anchor: ev.currentTarget,
              })
        }
      >
        Download
      </Button>
      <Menu
        open={state.currentMenu === DOWNLOAD_MENU}
        onClose={() => actions.closeMenu()}
        anchorEl={state.menuAnchor}
        insideModal
        align="start"
      >
        <MenuItem
          icon={<Icons.FileDownloadOutlined color="primary" />}
          onClick={() => downloadFile(fileUrl)}
          text="Document with answers"
        />
        <MenuItem
          icon={<Icons.FileDownloadOutlined color="primary" />}
          onClick={() => downloadFile(templateFileUrl)}
          text="Blank document"
        />
      </Menu>
    </>
  );
}

function SelectDocument({ lpDoc, state, actions, currentDocName }) {
  return (
    <>
      <Tooltip title={<Typography variant="label">Change document</Typography>}>
        <Button
          id="lp-docs-menu"
          variant="secondary"
          endIcon={
            lpDoc.docs.length > 1 ? <Icons.KeyboardArrowDownOutlined /> : null
          }
          onClick={(ev) =>
            state.currentMenu === DOCUMENTS_MENU
              ? actions.closeMenu()
              : actions.openMenu({
                  menu: DOCUMENTS_MENU,
                  anchor: ev.currentTarget,
                })
          }
          size="medium"
          ellipsis
        >
          {currentDocName}
        </Button>
      </Tooltip>
      <Menu
        open={state.currentMenu === DOCUMENTS_MENU}
        onClose={() => actions.closeMenu()}
        anchorEl={state.menuAnchor}
        insideModal
        align="start"
      >
        {lpDoc.docs.map((doc) => (
          <MenuItem
            key={doc.id}
            onClick={() => {
              actions.selectDocument({
                docId: doc.id,
              });
            }}
            text={doc.name}
          />
        ))}
      </Menu>
    </>
  );
}

export function DrawerHeader({
  lpDoc,
  fileUrl,
  templateFileUrl,
  fillable,
  currentDocName,
}) {
  const classes = useStyles();
  const state = useDocumentsDrawerState();
  const actions = useDocumentsDrawerActions();

  return (
    <AppBar position="relative" elevation={0}>
      <Toolbar className={classes.topBar}>
        <div className={classes.topBarName}>
          <Button
            variant="icon"
            aria-label="Close"
            onClick={() => actions.closeDrawer()}
          >
            <Icons.LastPage className={classes.closeButton} />
          </Button>
          {lpDoc.docs.length > 1 ? (
            <SelectDocument
              lpDoc={lpDoc}
              state={state}
              actions={actions}
              currentDocName={currentDocName}
            />
          ) : (
            <Typography variant="card-heading">{currentDocName}</Typography>
          )}
        </div>
        {lpDoc.downloadable ? (
          <>
            {fillable ? (
              <DownloadMenu
                state={state}
                actions={actions}
                fileUrl={fileUrl}
                templateFileUrl={templateFileUrl}
              />
            ) : (
              <SimpleDownloadButton templateFileUrl={templateFileUrl} />
            )}
          </>
        ) : null}
      </Toolbar>
    </AppBar>
  );
}
