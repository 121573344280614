import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Header } from './header';
import { DocumentDisplay } from './document_display';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: theme.palette.primary.white,
  },
  headerBottomBorder: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

export function DocumentViewer({ documentData, setShowDocumentViewer }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header
        documentData={documentData}
        setShowDocumentViewer={setShowDocumentViewer}
        className={classes.headerBottomBorder}
      />

      <DocumentDisplay documentData={documentData} />
    </div>
  );
}
