import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Button } from 'components/button';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  input: {
    width: '150px',
  },
}));

export function Stepper({ page, setPage, numPages }) {
  const classes = useStyles();
  const showNext = page < numPages;
  const showPrev = page > 1;

  return (
    <div className={classes.root}>
      <Button
        color="primary"
        variant="contained"
        disabled={!showPrev}
        onClick={() => setPage(page - 1)}
        startIcon={<KeyboardArrowLeftIcon />}
      >
        Previous
      </Button>

      <TextField
        label="Page"
        type="number"
        value={page}
        onChange={(e) => setPage(Number(e.target.value))}
        id="page"
        className={classes.input}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {'/ '}
              {numPages}
            </InputAdornment>
          ),
          inputProps: { min: 1, max: numPages },
        }}
        variant="outlined"
        error={page < 1 || page > numPages}
        helperText={page < 1 || page > numPages ? 'Invalid page' : null}
      />

      <Button
        variant="contained"
        color="primary"
        disabled={!showNext}
        onClick={() => setPage(page + 1)}
        endIcon={<KeyboardArrowRightIcon />}
      >
        Next
      </Button>
    </div>
  );
}
