import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { useToast } from 'services/toast';
import * as api from 'services/api';
import {
  UIKitSettingsProvider,
  Typography,
  Link,
  Alert,
  Modal,
} from '@passthrough/uikit';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(1, 0),
  },
}));

const EMPTY_FORM = {
  name: '',
  callbackUrl: '',
  webhooksEnabled: false,
  eventTypes: [],
};

export function IntegrationDialog({
  organizationId,
  open,
  onClose,
  onSubmit,
  action,
  integration = null,
}) {
  const classes = useStyles();
  const { errorToast } = useToast();

  const [form, setForm] = useState(EMPTY_FORM);
  const [isLoading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [errors, setErrors] = useState({});
  const [eventTypeOptions, setEventTypeOptions] = useState([]);
  const webhookValid = form.callbackUrl && form.eventTypes.length > 0;
  const valid = form.name && (!form.webhooksEnabled || webhookValid);

  const updateForm = (data) => {
    setChanged(true);
    setForm((oldForm) => ({ ...oldForm, ...data }));
  };

  const addIntegration = () => {
    setLoading(true);

    api
      .createIntegration({
        organizationId,
        name: form.name,
        callbackUrl: form.callbackUrl === '' ? null : form.callbackUrl,
        eventTypes: form.eventTypes || [],
      })
      .then((response) => {
        onSubmit(response.data);
      })
      .catch((e) => {
        if (e.response.status === 400 && e?.response?.data) {
          setErrors(e.response.data);
        }
        errorToast(`Failed to create "${form.name}".`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateIntegration = () => {
    setLoading(true);

    api
      .updateIntegration({
        organizationId,
        integrationId: integration.id,
        name: form.name,
        callbackUrl: form.callbackUrl === '' ? null : form.callbackUrl,
        eventTypes: form.eventTypes || [],
      })
      .then((response) => {
        onSubmit(response.data);
      })
      .catch((e) => {
        if (e.response.status === 400 && e?.response?.data) {
          setErrors(e.response.data);
        }
        errorToast(`Failed to update "${form.name}".`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateEvent = (eventType, checked) => {
    const eventTypes = checked
      ? [...form.eventTypes, eventType]
      : form.eventTypes.filter((ev) => ev !== eventType);

    updateForm({ eventTypes });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setChanged(false);

    if (integration) {
      updateIntegration();
    } else {
      addIntegration();
    }
  };

  React.useEffect(() => {
    if (open) {
      if (integration) {
        setForm({
          name: integration.name,
          callbackUrl: integration.callbackUrl,
          webhooksEnabled: Boolean(integration.callbackUrl),
          eventTypes: integration.eventTypes || [],
        });
      } else {
        setForm(EMPTY_FORM);
      }
    }

    setErrors({});
    setChanged(false);
  }, [open]);

  React.useEffect(() => {
    api.getIntegrationEventTypes({ organizationId }).then((response) => {
      setEventTypeOptions(response.data);
    });
  }, []);

  return (
    <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
      <Modal
        size="xs"
        open={open}
        onClose={onClose}
        headerLabel={`${action} API key`}
        showCancelButton
        primaryButtonText={action}
        primaryButtonLoading={isLoading}
        primaryButtonDisabled={isLoading || !valid || !changed}
        onSubmit={handleSubmit}
      >
        <TextField
          autoFocus={!integration}
          id="name"
          variant="outlined"
          label="Name"
          type="text"
          value={form.name || ''}
          onChange={(e) => updateForm({ name: e.target.value })}
          fullWidth
          disabled={isLoading}
          error={!!errors.name && !changed}
          helperText={errors.name && !changed ? errors.name : null}
        />
        <FormControlLabel
          control={
            <Checkbox
              name="webhooksEnabled"
              checked={form.webhooksEnabled}
              onChange={(e) => {
                if (e.target.checked) {
                  updateForm({ webhooksEnabled: e.target.checked });
                } else {
                  updateForm({
                    webhooksEnabled: e.target.checked,
                    eventTypes: [],
                    callbackUrl: '',
                  });
                }
              }}
            />
          }
          label="Enable webhooks"
        />
        {form.webhooksEnabled ? (
          <>
            <Divider className={classes.divider} />

            <Typography variant="card-heading">Webhooks</Typography>
            <TextField
              id="callbackUrl"
              variant="outlined"
              label="Webhook URL"
              type="text"
              value={form.callbackUrl || ''}
              onChange={(e) => updateForm({ callbackUrl: e.target.value })}
              fullWidth
              disabled={isLoading}
              error={!!errors.callbackUrl && !changed}
              helperText={
                errors.callbackUrl && !changed
                  ? errors.callbackUrl[0]
                  : "Passthrough's webhook will send a POST request to this URL."
              }
            />

            <div>
              <Typography variant="card-heading">Events</Typography>
              <Typography size="small">
                Learn more about events in the{' '}
                <Link
                  href="https://dev.passthrough.com/reference/#tag/Events"
                  inline
                  variant="external"
                >
                  developer documentation
                </Link>
                .
              </Typography>

              {errors.eventTypes ? (
                <Alert severity="error">{errors.eventTypes[0]}</Alert>
              ) : null}

              <FormControl variant="outlined" fullWidth>
                {eventTypeOptions.map((eventType) => (
                  <FormControlLabel
                    key={eventType}
                    control={
                      <Checkbox
                        id={eventType}
                        name={eventType}
                        checked={form.eventTypes.includes(eventType)}
                        onChange={(e) => {
                          updateEvent(eventType, e.target.checked);
                        }}
                      />
                    }
                    label={eventType}
                  />
                ))}
              </FormControl>
            </div>
          </>
        ) : null}
      </Modal>
    </UIKitSettingsProvider>
  );
}
