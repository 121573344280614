import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '150px',
    textAlign: 'center',
    maxWidth: '600px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  tall: {
    minHeight: '350px',
  },
  compact: {
    minHeight: 'auto',
  },
}));

export function Answers({ children, tall, compact }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.tall]: tall,
        [classes.compact]: compact,
      })}
    >
      {children}
    </div>
  );
}
