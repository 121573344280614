import { TAGGING_MODAL_STAGES } from './constants';

export function isLastTagInGroup(tagGroups, tag) {
  const tagGroupName = tag?.groupName;
  const affectedTagGroup = tagGroups.find((tg) => tg.name === tagGroupName);

  if (!affectedTagGroup) {
    return false;
  }

  return (
    affectedTagGroup.tags.length === 1 && affectedTagGroup.tags[0].id === tag.id
  );
}

export function getModalHeader(modalState) {
  switch (modalState) {
    case TAGGING_MODAL_STAGES.APPLY_TAGS:
      return 'Tag investors';
    case TAGGING_MODAL_STAGES.MANAGE_TAGS:
      return 'Manage tags';
    case TAGGING_MODAL_STAGES.CREATE_TAG:
      return 'Create tag';
    case TAGGING_MODAL_STAGES.EDIT_TAG_GROUP:
      return 'Rename group';
    case TAGGING_MODAL_STAGES.EDIT_TAG:
      return 'Rename tag';
    default:
      return '';
  }
}

export function getModalSubheader(modalState, numSelectedInvestors) {
  switch (modalState) {
    case TAGGING_MODAL_STAGES.APPLY_TAGS: {
      const usePluralLanguage = numSelectedInvestors > 1;

      return `${numSelectedInvestors} investor${
        usePluralLanguage ? 's' : ''
      } selected`;
    }
    default:
      return '';
  }
}

export function getPrimaryBtnText(modalState) {
  switch (modalState) {
    case TAGGING_MODAL_STAGES.APPLY_TAGS:
      return 'Apply';
    case TAGGING_MODAL_STAGES.CREATE_TAG:
      return 'Create tag';
    case TAGGING_MODAL_STAGES.EDIT_TAG:
    case TAGGING_MODAL_STAGES.EDIT_TAG_GROUP:
      return 'Save';
    default:
      return '';
  }
}

export function countTagUsagesAcrossInvestors(investors) {
  return investors.reduce((acc, investor) => {
    investor.tags.forEach((tag) => {
      if (tag.id in acc) {
        acc[tag.id] += 1;
      } else {
        acc[tag.id] = 1;
      }
    });

    return acc;
  }, {});
}

export function aggregateInvestorTags(investorTagGroups) {
  return investorTagGroups.reduce((acc, tagGroup) => {
    tagGroup.tags.forEach((tag) => {
      acc.push(tag);
    });

    return acc;
  }, []);
}

export function areSetsEqual(a, b) {
  return a.size === b.size && [...a].every((value) => b.has(value));
}
