import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Toolbar from '@material-ui/core/Toolbar';
import { AccountNavButton } from 'components/AccountNavButton';
import { ViewDocumentsButton } from 'components/documents_drawer';
import { AddCollaboratorsBtn } from './add_collaborators_btn';
import { ProfileInfoButton } from '../profile/profile_info/profile_info_button';
import { AccessDropdownButton } from '../access_dropdown_button';

const useStyles = makeStyles((theme) => ({
  mobileToolbar: {
    backgroundColor: theme.palette.primary.white,
  },
  mobileMenu: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(1),
  },
  accountNavButton: {
    padding: theme.spacing(0, 1),
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  nameAndAccessContainer: {
    flexGrow: 1,
  },
  buttons: {
    display: 'flex',
    columnGap: theme.spacing(1),
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-end',
      rowGap: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
}));

export function MobileMenuBar({
  isOpen,
  lpName,
  activeProfile,
  availableProfiles,
  setActiveProfile,
  setSetupProfileId,
  lpDoc,
  setLpAccessDialogOpen,
  numCollaborators,
  hasProfileQuestions,
  setRestarting,
  disableProfileSwitching,
  fundName,
}) {
  const classes = useStyles();

  return (
    <Collapse in={isOpen} appear={false} unmountOnExit>
      <Toolbar className={classes.mobileToolbar} disableGutters>
        <div className={classes.mobileMenu}>
          <div className={classes.accountNavButton}>
            <AccountNavButton useMobileUI />
          </div>

          <div className={classes.nameAndAccessContainer}>
            <ProfileInfoButton
              activeProfile={activeProfile}
              availableProfiles={availableProfiles}
              setActiveProfile={setActiveProfile}
              setSetupProfileId={setSetupProfileId}
              fundName={fundName}
              lpName={lpName}
              hasProfileQuestions={hasProfileQuestions}
              setRestarting={setRestarting}
              disableProfileSwitching={disableProfileSwitching}
            />
            <AccessDropdownButton
              setLpAccessDialogOpen={setLpAccessDialogOpen}
              numCollaborators={numCollaborators}
              lpDoc={lpDoc}
            />
          </div>

          <div className={classes.buttons}>
            <AddCollaboratorsBtn
              handleClick={() => {
                setLpAccessDialogOpen(true);
              }}
              className={classes.shareButton}
            />
            <ViewDocumentsButton count={lpDoc.docs?.length} />
          </div>
        </div>
      </Toolbar>
    </Collapse>
  );
}
