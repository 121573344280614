import { structureAnnotatedComments } from 'services/utils';

export const commentReducerActions = {
  INITIALIZE_DATA: 'INITIALIZE_DATA',
  ADD_COMMENT: 'ADD_COMMENT',
  REINITIALIZE_COMMENTS_ON_ANSWER: 'REINITIALIZE_COMMENTS_ON_ANSWER',
};

export function commentsReducer(state = {}, action) {
  switch (action.type) {
    case commentReducerActions.INITIALIZE_DATA: {
      return {
        ...state,
        ...structureAnnotatedComments(action.payload.comments),
      };
    }
    case commentReducerActions.REINITIALIZE_COMMENTS_ON_ANSWER: {
      return {
        ...state,
        ...structureAnnotatedComments(action.payload.comments),
      };
    }
    case commentReducerActions.ADD_COMMENT: {
      const data = action.payload.commentData;
      const { questionId } = data;

      if (state[questionId]) {
        return {
          ...state,
          [questionId]: [...state[questionId], data],
        };
      }
      return { ...state, [questionId]: [data] };
    }
    default:
      throw new Error('reducer received an action type that is not supported.');
  }
}
