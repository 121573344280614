import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Button } from 'components/button';

const useReviewSectionStyles = makeStyles((theme) => ({
  reviewSection: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2, 0),
  },
  headerText: {
    color: theme.palette.primary.grey,
    fontWeight: 500,
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  hidden: {
    visibility: 'hidden',
  },
}));

export function ReviewSection({ children, headerText, onEditClick, readOnly }) {
  const classes = useReviewSectionStyles();

  return (
    <div className={classes.reviewSection}>
      <div className={classes.headerRow}>
        <Typography className={classes.headerText}>{headerText}</Typography>
        <Button
          onClick={onEditClick}
          variant="text"
          className={readOnly ? classes.hidden : undefined}
        >
          Edit
        </Button>
      </div>
      {children}
    </div>
  );
}
