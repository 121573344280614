import React from 'react';
import { Modal, Typography } from '@passthrough/uikit';

export function NonInvestorWarningDialog({
  open,
  onClose,
  profileName,
  lpName,
  onSubmit,
  isSubmitting,
}) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      headerLabel="Start with a blank questionnaire"
      showCancelButton
      onSubmit={onSubmit}
      primaryButtonText="Start questionnaire"
      primaryButtonLoading={isSubmitting}
    >
      <Typography>
        Only {profileName} and their authorized representative can apply answers
        from this profile. You may still start the questionnaire for {lpName}{' '}
        with a blank questionnaire.
      </Typography>
    </Modal>
  );
}
