import { useContext, useMemo, useReducer } from 'react';
import * as React from 'react';
import {
  DRAWER_CLOSE,
  DRAWER_SELECT,
  DRAWER_OPEN_LINK,
  DRAWER_OPEN_MENU,
  DRAWER_CLOSE_MENU,
  DRAWER_SET_PDF,
  DRAWER_SET_PAGE,
  DRAWER_SET_ZOOM,
  INITIAL_STATE,
  CURRENT_QUESTIONS,
  DRAWER_TOGGLE,
  drawerReducer,
} from './reducer';

import { DocumentsDrawerContext } from './context';

export function useDocumentsDrawerActions() {
  const [, dispatch] = useContext(DocumentsDrawerContext);

  const drawerActions = useMemo(
    () => ({
      closeDrawer: () => dispatch({ type: DRAWER_CLOSE }),
      selectDocument: ({ docId }) =>
        dispatch({ type: DRAWER_SELECT, payload: { docId } }),
      openLink: ({ docId, page, linkId }) =>
        dispatch({ type: DRAWER_OPEN_LINK, payload: { docId, page, linkId } }),
      openMenu: ({ menu, anchor }) =>
        dispatch({ type: DRAWER_OPEN_MENU, payload: { menu, anchor } }),
      closeMenu: () => dispatch({ type: DRAWER_CLOSE_MENU }),
      setPdf: ({ pdf }) => dispatch({ type: DRAWER_SET_PDF, payload: { pdf } }),
      setPage: ({ page, docId }) =>
        dispatch({ type: DRAWER_SET_PAGE, payload: { page, docId } }),
      setZoom: ({ zoom }) =>
        dispatch({ type: DRAWER_SET_ZOOM, payload: { zoom } }),
      toggleDrawer: () => dispatch({ type: DRAWER_TOGGLE }),
      setCurrentQuestions: ({ currentQuestions }) =>
        dispatch({ type: CURRENT_QUESTIONS, payload: { currentQuestions } }),
    }),
    [dispatch],
  );

  return drawerActions;
}

export function useDrawerReducer(lpDoc) {
  const reducer = useReducer(drawerReducer, INITIAL_STATE, (state) => ({
    ...state,
    selectedDocId: lpDoc.docs[0]?.id,
  }));

  return reducer;
}

export function useDocumentsDrawerState() {
  const [state] = React.useContext(DocumentsDrawerContext);
  return state;
}

export function hashPdfBoxes(boxes) {
  return boxes.map((b) => b.apiId + b.x + b.y + b.height + b.width).join('/');
}
