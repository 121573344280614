import React from 'react';
import { Typography } from '@passthrough/uikit';
import { Box, Paper } from '@material-ui/core';

export const ComponentShowCase = ({ title, children }) => (
  <Box sx={{ mt: 4 }}>
    <Typography variant="page-heading">{title}</Typography>
    {children}
  </Box>
);

export const ShowCaseBox = ({ children }) => (
  <Box sx={{ mt: 4 }}>
    <Paper variant="outlined">
      <Box sx={{ p: 4 }}>{children}</Box>
    </Paper>
  </Box>
);

export const ShowCaseBoxRow = ({ children, mt, gap = 10 }) => (
  <Box display="flex" gridGap={gap} sx={{ justifyContent: 'center', mt }}>
    {children}
  </Box>
);

export const ShowCaseBoxColumn = ({ children, mt }) => (
  <Box display="flex" flexDirection="column" gridGap={10} sx={{ mt }}>
    {children}
  </Box>
);
