import React from 'react';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MuiPhoneNumber from 'material-ui-phone-number';

import { Typography, Button } from '@passthrough/uikit';
import { useWhiteLabelConfig } from 'services/providers/theme';
import { ResendVerificationButton } from 'components/resend_verification_button';
import { OTPInput } from 'components/otp_input';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  collapseMargin: {
    marginTop: theme.spacing(2),
  },
}));

export function SmsSetupStep({
  phoneNumber,
  setPhoneNumber,
  phoneNumberError,
  resendDisabled,
  allowSend,
  timeoutText,
  smsDeviceId,
  smsOtpCode,
  setSmsOtpCode,
  smsOtpCodeError,
  smsSetupLoading,
  onCreateDevice,
  onSubmit,
  onBack,
  createLoading,
}) {
  const classes = useStyles();
  const { productName } = useWhiteLabelConfig();

  const showAuthenticationCode = !!smsDeviceId;

  function handleCreate(e) {
    e.preventDefault();
    onCreateDevice();
  }

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit();
  }

  return (
    <div className={classes.container}>
      <div>
        <Typography variant="card-heading">
          Set up text message authentication
        </Typography>
        <Typography>
          Get authentication codes by SMS on your mobile phone when signing into{' '}
          {productName}.
        </Typography>
      </div>
      <div className={classes.fieldContainer}>
        <form
          className={classes.fieldContainer}
          onSubmit={handleCreate}
          noValidate
        >
          <MuiPhoneNumber
            id="phone"
            type="tel"
            disableAreaCodes
            defaultCountry="us"
            label="Phone number"
            variant="outlined"
            value={phoneNumber}
            error={!!phoneNumberError}
            helperText={phoneNumberError}
            onChange={(value) => setPhoneNumber(value)}
            autoFocus
            fullWidth
            disabled={smsSetupLoading || createLoading}
          />
          <ResendVerificationButton
            loading={createLoading}
            fullWidth
            variant={showAuthenticationCode ? 'secondary' : 'primary'}
            onClick={handleCreate}
            type="submit"
            disabled={resendDisabled || smsSetupLoading || !phoneNumber}
            allowSend={allowSend}
            timeoutText={timeoutText}
          >
            {showAuthenticationCode
              ? 'Resend text message'
              : 'Send authentication code'}
          </ResendVerificationButton>
        </form>
        <Collapse
          className={classes.collapseMargin}
          in={showAuthenticationCode}
          unmountOnExit
          mountOnEnter
        >
          <form
            className={classes.fieldContainer}
            onSubmit={handleSubmit}
            noValidate
          >
            <TextField
              InputProps={{
                inputComponent: OTPInput,
              }}
              id="sms-otp-token"
              label="Authentication code"
              variant="outlined"
              value={smsOtpCode}
              onChange={(e) => setSmsOtpCode(e.target.value)}
              fullWidth
              error={!!smsOtpCodeError}
              helperText={smsOtpCodeError}
              autoFocus
              autoComplete="one-time-code"
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{ inputMode: 'numeric' }}
              disabled={smsSetupLoading}
            />
            <Button
              loading={smsSetupLoading}
              disabled={!smsOtpCode}
              fullWidth
              variant="primary"
              onClick={handleSubmit}
              type="submit"
            >
              Continue
            </Button>
          </form>
        </Collapse>
        <Button
          disabled={createLoading || smsSetupLoading}
          fullWidth
          variant="secondary"
          onClick={onBack}
        >
          Back
        </Button>
      </div>
    </div>
  );
}
