import React from 'react';
import { Typography } from '@passthrough/uikit';
import { ShowCaseBox, ComponentShowCase } from '../show_case';

export const TypographyShowCase = () => (
  <ComponentShowCase title="Typography">
    <ShowCaseBox>
      <Typography variant="page-heading">Page heading</Typography>
      <Typography variant="section-heading">Section heading</Typography>
      <Typography variant="card-heading">Card heading</Typography>
      <Typography variant="label">Label</Typography>
      <div>
        <Typography variant="label" size="small">
          Label / Small
        </Typography>
      </div>
      <Typography variant="label" size="medium">
        Label / Medium
      </Typography>
      <Typography variant="body">Body</Typography>
      <Typography variant="body" size="small">
        Body / Small
      </Typography>
      <Typography variant="body" size="medium">
        Body / Medium
      </Typography>
      <Typography variant="body" size="large">
        Body / Large
      </Typography>
    </ShowCaseBox>
  </ComponentShowCase>
);
