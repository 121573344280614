import React, { useState, useMemo } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import TextSearch from 'mdi-material-ui/TextSearch';

import { TableContainer, TableCell } from 'components/table/index';
import { SmartTable } from 'components/table/smart';
import { EmptyState } from 'components/empty_v2';

import { ProgressInfo } from 'components/progress';
import { NameCell } from './name_cell';
import { ClientMatterCell } from './client_matter_cell';
import { ClosingDateCell } from './closing_date_cell';
import { MembersCell } from './members_cell';
import { sortFunds } from './utils';
import {
  NAME_COLUMN,
  CLIENT_MATTER_NUMBER_COLUMN,
  NEXT_DATE_COLUMN,
  PROGRESS_COLUMN,
  MEMBERS_COLUMN,
} from './constants';

const useStyles = makeStyles((theme) => ({
  cell: {
    verticalAlign: 'top',
    padding: theme.spacing(2),
  },
  progressCell: {
    verticalAlign: 'top',
    padding: theme.spacing(2),
    width: '512px',
  },
  nameCell: {
    minWidth: '160px',
    maxWidth: '240px',
  },
  headCell: {
    padding: theme.spacing(1, 2),
    whiteSpace: 'nowrap',
    fontWeight: 400,
    fontSize: '.875rem',
  },
}));

// Include placeholder id for react key
const SKELETON_FUNDS = [{ id: '1' }, { id: '2' }, { id: '3' }, { id: '4' }];

export function TableRows({
  unsortedFunds,
  commitments,
  clearFilters,
  openClientMatterDialog,
  openMemberAccessDialog,
  columnIds,
  skeleton,
  alwaysLinkToFund,
}) {
  const classes = useStyles();
  const [order, setOrder] = useState([NEXT_DATE_COLUMN, 'desc']);

  function getKey(fund) {
    return fund.id;
  }

  const funds = useMemo(
    () => sortFunds(order, unsortedFunds),
    [unsortedFunds, ...order],
  );

  const fundsToRender = skeleton ? SKELETON_FUNDS : funds;

  const fundIdsToCommitments = useMemo(() => {
    const result = {};
    commitments.forEach((commitment) => {
      result[commitment.fundId] = commitment;
    });
    return result;
  }, [commitments]);

  const CustomEmptyState = () => (
    <EmptyState
      Icon={TextSearch}
      title="No matching funds"
      text="Adjust or clear all filters to try again."
      ctaPermission
      ctaText="Clear all filters"
      ctaOnClick={clearFilters}
    />
  );

  return (
    <TableContainer>
      <SmartTable
        getKey={getKey}
        rows={fundsToRender}
        columnIds={columnIds}
        sortable={[NAME_COLUMN, NEXT_DATE_COLUMN]}
        selected={[]}
        order={order}
        onChangeOrder={setOrder}
        canEdit
        headerCellClassName={classes.headCell}
        headerRowClassName={classes.headerRow}
        CustomEmptyState={CustomEmptyState}
        renderHeadLabel={(columnId) => {
          switch (columnId) {
            case NAME_COLUMN:
              return 'Fund';
            case CLIENT_MATTER_NUMBER_COLUMN:
              return 'Client matter number';
            case NEXT_DATE_COLUMN:
              return 'Next closing date';
            case PROGRESS_COLUMN:
              return 'Progress';
            case MEMBERS_COLUMN:
              return 'Team members';
            default:
              return null;
          }
        }}
        renderBodyCell={(columnId, fund) => {
          switch (columnId) {
            case NAME_COLUMN:
              return (
                <TableCell
                  className={clsx(classes.cell, classes.nameCell)}
                  key={columnId}
                  scope="row"
                >
                  <NameCell
                    fund={fund}
                    alwaysLinkToFund={alwaysLinkToFund}
                    skeleton={skeleton}
                  />
                </TableCell>
              );
            case CLIENT_MATTER_NUMBER_COLUMN:
              return (
                <TableCell
                  key={columnId}
                  className={clsx(classes.cell, classes.nameCell)}
                >
                  <ClientMatterCell
                    fund={fund}
                    skeleton={skeleton}
                    openClientMatterDialog={openClientMatterDialog}
                  />
                </TableCell>
              );
            case NEXT_DATE_COLUMN:
              return (
                <TableCell key={columnId} className={classes.cell}>
                  <ClosingDateCell fund={fund} skeleton={skeleton} />
                </TableCell>
              );
            case PROGRESS_COLUMN: {
              const commitmentAmounts = fundIdsToCommitments[fund.id];

              return (
                <TableCell key={columnId} className={classes.progressCell}>
                  <ProgressInfo
                    pending={commitmentAmounts?.pendingCommitment}
                    fullyExecuted={commitmentAmounts?.fullyExecutedCommitment}
                    target={fund.targetCommitment}
                    pendingInvestorCount={
                      commitmentAmounts?.pendingInvestorsCount
                    }
                    fullyExecutedInvestorCount={
                      commitmentAmounts?.fullyExecutedInvestorsCount
                    }
                    isLoading={commitmentAmounts === undefined}
                    currency={fund.currency}
                  />
                </TableCell>
              );
            }
            case MEMBERS_COLUMN:
              return (
                <TableCell key={columnId} className={classes.cell}>
                  <MembersCell
                    fund={fund}
                    skeleton={skeleton}
                    openMemberAccessDialog={openMemberAccessDialog}
                  />
                </TableCell>
              );
            default:
              return null;
          }
        }}
      />
    </TableContainer>
  );
}
