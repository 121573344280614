import React from 'react';

import { formatDistanceToNowStrict } from 'date-fns';
import { Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';

const useStyles = makeStyles((theme) => ({
  testTooltip: {
    padding: theme.spacing(1),
  },
}));

export function CreatedCell({ task }) {
  const then = new Date(task.submissionTime);
  const timeAgo = task.submissionTime
    ? formatDistanceToNowStrict(then, {
        addSuffix: true,
      })
    : '';
  const classes = useStyles();
  const dateString = format(then, 'h:mma d MMM yyyy');

  return (
    <Tooltip
      title={
        <div className={classes.testTooltip}>
          <Typography>{dateString}</Typography>
        </div>
      }
    >
      <div>
        <Typography>{timeAgo}</Typography>
      </div>
    </Tooltip>
  );
}
