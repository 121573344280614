import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles((theme) => ({
  input: {
    margin: theme.spacing(1, 0),
  },
  label: {
    marginRight: 0,
    flex: '1 0 50%',
  },
  formGroup: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    marginTop: theme.spacing(1),
  },
}));

const SOURCE_OF_WEALTH_OPTIONS = [
  'Salary or business revenues',
  'Interest on savings',
  'Investments',
  'Inheritance',
  'National insurance allowance',
  'Rent or income producing real estate',
  'Pension or provident payments',
  'Other',
];

export function RootSourceOfWealthDetails({
  sourceOfWealthDetails,
  sourceOfWealthDetailsError,
  sourceOfWealth = [],
  sourceOfWealthError,
  handleChange,
}) {
  const classes = useStyles();

  return (
    <FormControl component="fieldset" fullWidth error={!!sourceOfWealthError}>
      <FormLabel component="legend" focused={false}>
        Source of wealth
      </FormLabel>
      <FormGroup className={classes.formGroup}>
        {SOURCE_OF_WEALTH_OPTIONS.map((option) => (
          <FormControlLabel
            key={option}
            className={classes.label}
            control={
              <Checkbox
                checked={sourceOfWealth.includes(option)}
                onChange={(_, checked) => {
                  handleChange(
                    'sourceOfWealth',
                    checked
                      ? [...sourceOfWealth, option]
                      : sourceOfWealth.filter((o) => o !== option),
                  );
                }}
                name="sourceOfWealth"
              />
            }
            label={option}
          />
        ))}
        {sourceOfWealthError && (
          <FormHelperText error>{sourceOfWealthError}</FormHelperText>
        )}
        <TextField
          className={classes.input}
          fullWidth
          label={`Explanation for source of wealth ${
            !sourceOfWealth.includes('Other') ? '(optional)' : ''
          }`}
          name="sourceOfWealth"
          onChange={(ev) => {
            handleChange('sourceOfWealthDetails', ev.target.value);
          }}
          multiline
          minRows={4}
          value={sourceOfWealthDetails}
          variant="outlined"
          data-test="source-of-wealth-details"
        />
        {sourceOfWealthDetailsError && (
          <FormHelperText error>{sourceOfWealthDetailsError}</FormHelperText>
        )}
      </FormGroup>
    </FormControl>
  );
}
