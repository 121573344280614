import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { Button } from 'components/button';
import { Alert } from 'components/alert';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'components/dialog/index';

const useStyles = makeStyles((theme) => ({
  bottomSpacing: {
    marginBottom: theme.spacing(2),
  },
  modalTitle: {
    textAlign: 'left',
  },
  modalAlert: {
    color: theme.palette.warning.text,
    marginBottom: theme.spacing(2),
  },
  modalButtons: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1, 3),
  },
  reducedBoldText: {
    fontWeight: '500',
  },
}));

export function ApproveConfirmationModal({
  name,
  loading,
  showModal,
  setShowModal,
  internalNote,
  setInternalNote,
  approveDiligenceNode,
}) {
  const classes = useStyles();

  return (
    <Dialog
      open={showModal}
      maxWidth="md"
      onClose={() => {
        setShowModal(false);
      }}
    >
      <DialogTitle className={classes.modalTitle}>
        Add an internal note?
      </DialogTitle>

      <DialogContent>
        <Alert severity="warning" className={classes.modalAlert}>
          <strong className={classes.reducedBoldText}>{name}</strong> has at
          least one{' '}
          <strong className={classes.reducedBoldText}>true match</strong> or{' '}
          <strong className={classes.reducedBoldText}>unsure match</strong>.
        </Alert>

        <Typography className={classes.bottomSpacing}>
          You will receive new updates about this owner in your inbox.
        </Typography>

        <TextField
          value={internalNote}
          className={classes.bottomSpacing}
          onChange={(e) => {
            setInternalNote(e.target.value);
          }}
          variant="outlined"
          multiline
          rows={4}
          fullWidth
          label="Internal note (Optional)"
        />
      </DialogContent>

      <DialogActions className={classes.modalButtons}>
        <Button
          square
          loading={loading}
          variant="text"
          onClick={() => {
            setShowModal(false);
          }}
        >
          Cancel
        </Button>

        <Button
          square
          loading={loading}
          variant="contained"
          onClick={() => {
            approveDiligenceNode();
          }}
        >
          Mark as reviewed
        </Button>
      </DialogActions>
    </Dialog>
  );
}
