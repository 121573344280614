import React from 'react';
import { Alert, Button, Link } from '@passthrough/uikit';
import * as urls from 'services/urls';
import {
  ShowCaseBox,
  ComponentShowCase,
  ShowCaseBoxRow,
  ShowCaseBoxColumn,
} from '../show_case';

export const AlertShowCase = () => (
  <ComponentShowCase title="Alert">
    <ShowCaseBox>
      <ShowCaseBoxColumn>
        <Alert
          title="Title"
          severity="info"
          action={
            <Button href="#" variant="text">
              Button
            </Button>
          }
        >
          Info message for the user
        </Alert>
        <Alert title="Title" severity="warning">
          Warning message for the user
        </Alert>
        <Alert title="Title" severity="error">
          Error message for the user
        </Alert>
        <Alert title="Title" severity="success">
          Success message for the user
        </Alert>
        <Alert variant="sticky" severity="info">
          Info message for the user. Use <kbd>variant="sticky"</kbd> for full
          screen alerts.
        </Alert>
        <Alert variant="sticky" severity="warning">
          Warning message for the user. Use <kbd>variant="sticky"</kbd> for full
          screen alerts.
        </Alert>
        <Alert variant="sticky" severity="error">
          Error message for the user. Use <kbd>variant="sticky"</kbd> for full
          screen alerts.
        </Alert>
        <Alert variant="sticky" severity="success">
          Success message for the user. Use <kbd>variant="sticky"</kbd> for full
          screen alerts.
        </Alert>
        <Alert
          severity="info"
          action={
            <Button href="#" variant="text">
              Button
            </Button>
          }
        >
          Info message for the user
        </Alert>
        <Alert
          severity="warning"
          action={
            <Link href="." variant="external">
              Learn more
            </Link>
          }
        >
          Warning message for the user
        </Alert>
        <Alert
          severity="error"
          action={
            <Button href="." variant="primary" size="small">
              Button
            </Button>
          }
        >
          Error message for the user
        </Alert>
        <Alert
          severity="success"
          action={
            <Button href="." variant="secondary" size="small">
              Button
            </Button>
          }
        >
          Success message for the user
        </Alert>
      </ShowCaseBoxColumn>
      <ShowCaseBoxRow mt={2}>
        <Alert title="Title" severity="info">
          Info message for the user
        </Alert>
        <Alert title="Title" severity="warning">
          Warning message for the user
        </Alert>
        <Alert title="Title" severity="error">
          Error message for the user
        </Alert>
        <Alert title="Title" severity="success">
          Success message for the user
        </Alert>
      </ShowCaseBoxRow>
      <ShowCaseBoxRow mt={4}>
        <Alert
          title="Title"
          severity="warning"
          action={
            <Link href={urls.UIKIT_URL} variant="external">
              Learn more
            </Link>
          }
        >
          Info message for the user
        </Alert>
        <Alert severity="info">Alert without a title</Alert>
        <Alert
          severity="info"
          skipTypography
          title="Alert wrapped in typography using div"
        >
          <ul>
            <li>You can do stuff like this</li>
            <li>With a valid DOM</li>
          </ul>
        </Alert>
      </ShowCaseBoxRow>
    </ShowCaseBox>
  </ComponentShowCase>
);
