import React from 'react';

import { ANSWER_STATES } from 'services/constants';
import { DiffStyle } from 'components/Diff';
import { MAX_NUM_CHARS } from './constants';

export function NameWithTitleDisplay({ answer, questionState, flatten }) {
  if (!answer) {
    return null;
  }

  if (flatten) {
    const formattedAnswer = `${answer.firstName} ${answer.lastName}${
      answer?.title ? `, ${answer.title}` : ''
    }`;

    const numCharsUsed =
      answer.firstName.length +
        answer.lastName.length +
        answer?.title?.length || 0;
    return numCharsUsed <= MAX_NUM_CHARS
      ? formattedAnswer
      : `${formattedAnswer.substring(0, MAX_NUM_CHARS)}...`;
  }

  return (
    <>
      <div>
        <DiffStyle
          isNew={questionState === ANSWER_STATES.CHANGED_CURRENT_ANSWER}
          isDeleted={questionState === ANSWER_STATES.CHANGED_PRIOR_ANSWER}
        >
          {answer.firstName} {answer.lastName}
        </DiffStyle>
      </div>

      {answer.title ? (
        <div>
          <DiffStyle
            isNew={questionState === ANSWER_STATES.CHANGED_CURRENT_ANSWER}
            isDeleted={questionState === ANSWER_STATES.CHANGED_PRIOR_ANSWER}
          >
            {answer.title}
          </DiffStyle>
        </div>
      ) : null}
    </>
  );
}
