import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { Typography } from '@passthrough/uikit';

const useDataValueStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.grey,
  },
  horizontal: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    '& > *:first-child': {
      flex: 1,
    },
    '& > *:last-child': {
      flex: 2,
    },
  },
}));

export const DataValueDisplayVariants = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
};

export function DataValueDisplay({
  label,
  value,
  variant = DataValueDisplayVariants.VERTICAL,
}) {
  const classes = useDataValueStyles();
  const isHorizontal = variant === DataValueDisplayVariants.HORIZONTAL;

  if (!value) {
    return null;
  }

  return (
    <div className={clsx({ [classes.horizontal]: isHorizontal })}>
      <Typography variant="label" color="text.secondary">
        {label}
      </Typography>
      <Typography variant="body" color="text.primary">
        {Array.isArray(value) ? value.join(', ') : value}
      </Typography>
    </div>
  );
}
