export const REQUEST_REVIEW_DISABLED = 'DISABLED';
const REQUEST_REVIEW_OPTIONAL = 'OPTIONAL';
export const REQUEST_REVIEW_REQUIRED = 'REQUIRED';

export const REQUEST_REVIEW_VALUES = [
  { value: REQUEST_REVIEW_OPTIONAL, label: 'Optional' },
  {
    value: REQUEST_REVIEW_REQUIRED,
    label: 'Required',
  },
  { value: REQUEST_REVIEW_DISABLED, label: 'Disabled' },
];
