import { formatAddress } from 'components/answer_displays/utils';
import { MAX_NUM_CHARS } from 'components/answer_displays/constants';
import { getCountry } from 'services/countries';

const countryKeys = ['country', 'nationality'];

const addressKeys = ['manualAddress', 'googleAddress'];

function calculateNumCharsInAnswer(answer) {
  if (Array.isArray(answer)) {
    const cumulativeLength = answer.reduce(
      (prevLength, arrEle) => prevLength + calculateNumCharsInAnswer(arrEle),
      0,
    );

    return cumulativeLength;
  }

  if (typeof answer === 'object') {
    if (Object.hasOwn(answer, 'line1') && Object.hasOwn(answer, 'line2')) {
      // handles standard address question
      return formatAddress(answer)?.length || 0;
    }

    const cumulativeLength = Object.entries(answer).reduce(
      (prevLength, [key, val]) => {
        if (countryKeys.includes(key)) {
          return prevLength + getCountry(val)?.length || 0;
        }
        if (addressKeys.includes(key)) {
          return prevLength + formatAddress(val)?.length || 0;
        }

        return prevLength + calculateNumCharsInAnswer(val);
      },
      0,
    );

    return cumulativeLength;
  }

  return `${answer}`.length;
}

export function canTruncateDisplayComponent(questionType, answer) {
  if (!answer) {
    return false;
  }

  if (
    questionType === 'AnswerType.w9_v3' ||
    questionType === 'AnswerType.w9_v4' ||
    questionType === 'DiligenceAnswerType.unknown' ||
    questionType === 'DiligenceAnswerType.name_known'
  ) {
    return true;
  }

  if (
    questionType === 'AnswerType.file_upload' ||
    questionType === 'AnswerType.multi_file_upload'
  ) {
    return false;
  }

  return calculateNumCharsInAnswer(answer) > MAX_NUM_CHARS;
}
