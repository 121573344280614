import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

import { KeyValuePairs } from 'components/key_value';
import { WillBeNotifiedDisplay } from './will_be_notified_display';
import { InvestorStatusChangedDisplay } from './investor_status_change_display';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(1, 0),
  },
}));

export function SubmitStage({ notify, message, numSignatureRequired }) {
  const classes = useStyles();
  return (
    <KeyValuePairs>
      <WillBeNotifiedDisplay notify={notify} message={message} />
      <Divider className={classes.divider} />
      <InvestorStatusChangedDisplay
        numSignatureRequired={numSignatureRequired}
      />
    </KeyValuePairs>
  );
}
