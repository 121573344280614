import React from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import { Button } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';

import * as api from 'services/api';
import { EmptyState } from 'components/empty_v2';
import { QuestionCard } from './question_card';
import { Filters } from './filters';
import { debounce } from '../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(10),
  },
  loadMore: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
  },
}));

export const GIDs = () => {
  const classes = useStyles();
  const [gidResult, setGidResult] = React.useState([]);
  const [tags, setTags] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageLoading, setPageLoading] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState('');
  const [filters, setFilters] = React.useState({
    search: '',
    tags: [],
    funds: [],
    types: [],
    noTag: false,
  });

  const setGids = (res) => setGidResult(res.data);

  const loadNextPage = () => {
    setPageLoading(true);
    api.getGIDs(filters, gidResult.next).then((res) => {
      setGidResult({
        ...res.data,
        results: [...gidResult.results, ...res.data.results],
      });
      setPageLoading(false);
    });
  };

  const toggleNoTagFilter = () => {
    const newValue = !filters.noTag;

    if (newValue) {
      setFilters((f) => ({ ...f, tags: [], noTag: newValue }));
    } else {
      setFilters((f) => ({ ...f, noTag: newValue }));
    }
  };

  const toggleFundFilter = (fund) => {
    let newValue;

    if (filters.funds.includes(fund)) {
      newValue = filters.funds.filter((item) => item !== fund);
    } else {
      newValue = [...filters.funds, fund];
    }

    setFilters((f) => ({ ...f, funds: newValue }));
  };

  const toggleTagFilter = (tag) => {
    let newValue;

    if (filters.tags.includes(tag)) {
      newValue = filters.tags.filter((item) => item !== tag);
    } else {
      newValue = [...filters.tags, tag];
    }

    setFilters((f) => ({ ...f, tags: newValue, noTag: false }));
  };

  const toggleTypeFilter = (type) => {
    let newValue;

    if (filters.types.includes(type)) {
      newValue = filters.types.filter((item) => item !== type);
    } else {
      newValue = [...filters.types, type];
    }

    setFilters((f) => ({ ...f, types: newValue }));
  };

  const applySearch = React.useCallback(
    debounce((search) => {
      setFilters((f) => ({ ...f, search }));
    }),
    [],
  );

  const handleSearch = (value) => {
    setSearchInput(value);
    applySearch(value);
  };

  // Load initial data
  React.useEffect(() => {
    api.questionTags({ search: '' }).then((response) => {
      setTags(response.data.map((item) => item.tag));
    });
  }, []);

  // Load and re-fetch GIDs based on filters
  React.useEffect(() => {
    setLoading(true);
    Promise.all([
      api.getGIDs(filters).then(setGids),
      new Promise((resolve) => setTimeout(resolve, 200)),
    ]).finally(() => {
      setLoading(false);
    });
  }, [filters]);

  return (
    <div className={classes.root}>
      <Filters
        tagOptions={gidResult?.filters?.tags || []}
        tagFilter={filters.tags}
        toggleTagFilter={toggleTagFilter}
        fundOptions={gidResult?.filters?.funds || []}
        fundFilter={filters.funds}
        toggleFundFilter={toggleFundFilter}
        noTagFilter={filters.noTag}
        toggleNoTagFilter={toggleNoTagFilter}
        typeOptions={gidResult?.filters?.types || []}
        typeFilter={filters.types}
        toggleTypeFilter={toggleTypeFilter}
        totalCount={gidResult.count}
        search={searchInput}
        appliedSearch={filters.search}
        onSearch={handleSearch}
      />
      {!loading
        ? (gidResult.results || []).map((item) => (
            <Box key={item.questionGid} mb={2}>
              <QuestionCard
                key={item.questionGid}
                item={item}
                tagOptions={tags}
                onAddTag={(newTag) => api.addGidToTag(item.questionGid, newTag)}
                onRemoveTag={(tag) =>
                  api.removeGidFromTag(item.questionGid, tag)
                }
              />
            </Box>
          ))
        : null}

      {loading || pageLoading ? (
        <>
          <Box mb={2}>
            <Skeleton variant="rect" height={300} />
          </Box>
          <Box mb={2}>
            <Skeleton variant="rect" height={300} />
          </Box>
          <Box>
            <Skeleton variant="rect" height={300} />
          </Box>
        </>
      ) : null}
      {!gidResult?.results?.length ? (
        <Paper variant="outlined">
          <EmptyState title="No data" />
        </Paper>
      ) : null}

      <div className={classes.loadMore}>
        <Button
          variant="primary"
          disabled={loading || pageLoading || !gidResult.next}
          onClick={loadNextPage}
        >
          See more
        </Button>
      </div>
    </div>
  );
};
