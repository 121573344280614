import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { Button } from 'components/button';
import AddIcon from '@material-ui/icons/Add';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { InputDiv } from './input_div';
import { choicesRequired } from '../constants';
import { ChoiceInput } from '../inputs/choice_input';

const useStyles = makeStyles(() => ({
  dropOver: {
    border: '1px dashed black',
  },
  dropNotOver: {
    border: '1px dashed transparent',
  },
}));

export const DraggableChoices = ({
  choices,
  setChoices,
  handleAddChoice,
  replaceChoice,
  deleteChoice,
  copyChoice,
  setPage,
  hellosignBoxes,
  replaceHellosignBox,
  deleteHellosignBox,
  setDragSelected,
  handleAddHellosignBox,
  answerType,
  highlightBox,
  setHighlightBoxId,
  questions,
  handleClickItem,
  questionId,
  handleAddChoiceLink,
  links,
  replaceLink,
  deleteLink,
  selectDocument,
}) => {
  const classes = useStyles();

  function onDragEndChoices(result) {
    if (result.destination) {
      const newChoices = [...choices];

      const [c] = newChoices.splice(result.source.index, 1);
      newChoices.splice(result.destination.index, 0, c);

      setChoices(newChoices);
    }
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEndChoices}>
        <Droppable droppableId="choices">
          {(outerProvided, outerSnapshot) => (
            <div
              {...outerProvided.droppableProps}
              ref={outerProvided.innerRef}
              className={clsx({
                [classes.dropOver]: outerSnapshot.isDraggingOver,
                [classes.dropNotOver]: !outerSnapshot.isDraggingOver,
              })}
            >
              {choices.map((choice, index) => (
                <Draggable
                  key={choice.id}
                  draggableId={choice.id}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{ ...provided.draggableProps.style }}
                    >
                      <ChoiceInput
                        key={choice.id}
                        id={choice.id}
                        index={index}
                        text={choice.text}
                        exclusive={choice.exclusive}
                        onChange={replaceChoice}
                        onDelete={() => {
                          deleteChoice(choice.id);
                        }}
                        onCopy={() => {
                          copyChoice(choice.id);
                        }}
                        setPage={setPage}
                        hellosignBoxes={hellosignBoxes.filter((c) =>
                          Boolean(c.choice === choice.id),
                        )}
                        changeBox={replaceHellosignBox}
                        deleteBox={deleteHellosignBox}
                        dragBox={setDragSelected}
                        addBox={handleAddHellosignBox}
                        answerType={answerType}
                        highlightBox={highlightBox}
                        setHighlightBoxId={setHighlightBoxId}
                        questions={questions}
                        handleClickItem={handleClickItem}
                        questionId={questionId}
                        handleAddChoiceLink={handleAddChoiceLink}
                        links={links}
                        replaceLink={replaceLink}
                        deleteLink={deleteLink}
                        selectDocument={selectDocument}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Draggable>
              ))}
              {outerProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {choicesRequired.includes(answerType) ? (
        <InputDiv>
          <Button startIcon={<AddIcon />} onClick={handleAddChoice}>
            Add choice
          </Button>
        </InputDiv>
      ) : null}
    </>
  );
};
