import React from 'react';
import TextField from '@material-ui/core/TextField';

import { Modal } from '@passthrough/uikit';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { useModalState } from '../hooks';
import { getIdFromName } from '../helpers';

export function StateModal({
  state,
  onClose,
  changeState,
  addState,
  deleteState,
}) {
  const [
    editedState,
    setEditedState,
    headerLabel,
    open,
    onSubmit,
    isNew,
    deleteButton,
    showErrors,
  ] = useModalState({
    label: 'state',
    initialState: state,
    addObject: addState,
    changeObject: changeState,
    deleteObject: deleteState,
    requiredFields: ['customStatusText', 'sortOrder'],
    onClose,
  });

  const customStatusTextError = showErrors && !editedState.customStatusText;
  const sortOrderError =
    showErrors && !editedState.sortOrder && editedState.sortOrder !== 0;

  return (
    <Modal
      open={open}
      onClose={onClose}
      headerLabel={headerLabel}
      showCancelButton
      primaryButtonText="Save changes"
      onSubmit={onSubmit}
      tertiaryButton={deleteButton}
    >
      <TextField
        required
        id="custom-status-text"
        variant="outlined"
        label="Custom status text"
        type="text"
        value={editedState.customStatusText}
        onChange={(e) => {
          let newId = editedState.id;
          if (isNew) {
            newId = getIdFromName(e.target.value);
          }

          setEditedState({
            ...editedState,
            customStatusText: e.target.value,
            id: newId,
          });
        }}
        fullWidth
        error={customStatusTextError}
        helperText={customStatusTextError ? 'This field is required' : ''}
      />
      <TextField
        required
        id="ID"
        variant="outlined"
        label={isNew ? '' : 'ID'}
        type="text"
        value={editedState.id || ''}
        fullWidth
        disabled
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={editedState.isInitial}
            onChange={(e) => {
              setEditedState({ ...editedState, isInitial: e.target.checked });
            }}
            name="is_initial"
          />
        }
        label="Is initial status"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={editedState.isFinal}
            onChange={(e) => {
              setEditedState({ ...editedState, isFinal: e.target.checked });
            }}
            name="is_final"
          />
        }
        label="Is final status"
      />
      <TextField
        id="sort-order"
        type="number"
        InputProps={{ inputProps: { min: 0 } }}
        label="Sort order"
        variant="outlined"
        value={editedState.sortOrder}
        onChange={(e) => {
          setEditedState({
            ...editedState,
            sortOrder: Number(e.target.value),
          });
        }}
        fullWidth
        error={sortOrderError}
        helperText={sortOrderError ? 'This field is required' : ''}
      />
    </Modal>
  );
}
