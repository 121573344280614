import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import clsx from 'clsx';
import { truncate } from '../utils';
import { QuestionChips } from '../question_chips';

const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: theme.palette.primary.fill,
  },
  loading: {
    backgroundColor: theme.palette.grey[200],
  },
  linearProgress: {
    marginTop: theme.spacing(2),
  },
}));

export const ItemQuestion = ({ item, onClick, showTags, active, loading }) => {
  const classes = useStyles();
  const shouldClick = !loading && !active;
  return (
    <ListItem
      key={item.id}
      button={shouldClick}
      onClick={shouldClick ? () => onClick(item) : undefined}
      className={clsx({ [classes.active]: active, [classes.loading]: loading })}
    >
      <ListItemText>
        <span>{`${item.id} - ${truncate(item.question)}`}</span>
        {showTags ? (
          <div style={{ marginTop: '10px' }}>
            <QuestionChips tags={item.filters} />
          </div>
        ) : null}

        {loading ? (
          <div className={classes.linearProgress}>
            <LinearProgress />
          </div>
        ) : null}
      </ListItemText>
    </ListItem>
  );
};
