import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@passthrough/uikit';
import { useLogout } from 'services/logout';
import { SupportArticleLink } from './support_article_link';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));

export function InitialStep({ fundNameRequiringMfa, onContinue }) {
  const classes = useStyles();
  const [logout, loading] = useLogout();

  return (
    <div className={classes.root}>
      <div className={classes.textContainer}>
        {fundNameRequiringMfa ? (
          <Typography>
            {fundNameRequiringMfa} requires you to enable two-factor
            authentication for an extra layer of security.
          </Typography>
        ) : null}
        <Typography>
          Your account will require more than just a password when you sign in.
        </Typography>
      </div>
      <div className={classes.buttonContainer}>
        <Button fullWidth variant="primary" onClick={onContinue}>
          Get started
        </Button>
        <Button
          fullWidth
          variant="text"
          size="small"
          loading={loading}
          onClick={logout}
        >
          Sign out to use a different account
        </Button>
      </div>
      <SupportArticleLink />
    </div>
  );
}
