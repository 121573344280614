import React from 'react';
import { FieldContainer, Field } from './fields';

export const ContactNameAndEmailDisplay = ({ answer }) => (
  <FieldContainer>
    <Field label="First name" labelMinWidth={90}>
      {answer?.firstName || '-'}
    </Field>
    <Field label="Last name">{answer?.lastName || '-'}</Field>
    <Field label="Email">{answer?.email || '-'}</Field>
  </FieldContainer>
);
