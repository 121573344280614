import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { ANSWER_STATES } from 'services/constants';
import { Button } from 'components/button';
import { DiffStyle } from 'components/Diff';

function hideValues(str) {
  return str.replace(/[0-9A-Za-z]/g, '*');
}

const useStyles = makeStyles((theme) => ({
  root: {
    // min width to ensure that all asterisks display remains on one line
    minWidth: '84px',
    border: `1px solid ${theme.palette.neutral.border}`,
  },
}));

export function SensitiveData({ answer, questionState, className }) {
  const [showAnswer, setShowAnswer] = useState(false);
  const classes = useStyles();

  if (!answer) {
    return null;
  }

  const displayValue = showAnswer ? answer : hideValues(answer);
  const tooltipText = showAnswer ? 'Hide' : 'Show';

  return (
    <Button
      onClick={(e) => {
        e.stopPropagation();
        setShowAnswer((sa) => !sa);
      }}
      variant="text"
      color="default"
      className={clsx(classes.root, className)}
      disableRipple
    >
      <Tooltip
        title={<Typography>{tooltipText}</Typography>}
        arrow
        placement="top"
      >
        <DiffStyle
          isDeleted={questionState === ANSWER_STATES.CHANGED_PRIOR_ANSWER}
          thin
        >
          {displayValue}
        </DiffStyle>
      </Tooltip>
    </Button>
  );
}
