import React from 'react';
import { Alert, Typography, SpotIcon } from '@passthrough/uikit';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Collapse from '@material-ui/core/Collapse';
import FormHelperText from '@material-ui/core/FormHelperText';
import Paper from '@material-ui/core/Paper';
import BorderColorIcon from '@material-ui/icons/BorderColor';

import { DisplayLink } from 'components/link';
import { useSupport } from 'components/support';
import { Button, LinkButton } from 'components/button';
import { RadioCardChoice, RadioCardContainer } from 'components/radio_card';
import { Table, TableCell, TableRow } from 'components/table/index';
import { InvestorReviewTable } from './investor_review_table';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.appbar.background,
    padding: theme.spacing(1, 1),
  },
  appBarContent: {
    display: 'flex',
    alignItems: 'center',
  },
  boldText: {
    fontWeight: '500',
  },
  container: {
    padding: theme.spacing(5, 0),
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(4),
  },
  countersignOnlyDocDisplay: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
  collapsibleContent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
  radioBtnDisplay: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
  learnMore: {
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
  radioContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    margin: theme.spacing(0),
  },
  root: {
    backgroundColor: theme.palette.background.default,
  },
  tableRow: {
    backgroundColor: theme.palette.primary.white,
  },
  formControl: {
    width: '100%',
  },
  errorMessage: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
  },
}));

const useTableCellStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 2),
  },
  flexRoot: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
  head: {
    backgroundColor: theme.palette.table.header,
  },
  tableRow: {
    backgroundColor: theme.palette.primary.white,
  },
}));

function UsedSignerRoleSummaryTable({
  numUniqueSigners,
  numUniqueCountersigners,
}) {
  const tableCellClasses = useTableCellStyles();

  return (
    <div className={tableCellClasses.flexRoot}>
      <Typography variant="card-heading">Signatories</Typography>

      <Table noTopMargin>
        <TableRow className={tableCellClasses.tableRow}>
          <TableCell classes={tableCellClasses}>Investor signers</TableCell>
          <TableCell classes={tableCellClasses} align="right">
            {numUniqueSigners}
          </TableCell>
        </TableRow>
        <TableRow className={tableCellClasses.tableRow}>
          <TableCell classes={tableCellClasses}>Countersigners</TableCell>
          <TableCell classes={tableCellClasses} align="right">
            {numUniqueCountersigners}
          </TableCell>
        </TableRow>
      </Table>
    </div>
  );
}

function TopBar({ onBack }) {
  const classes = useStyles();

  return (
    <AppBar className={classes.appBar}>
      <div key="back" className={classes.appBarContent}>
        <IconButton
          name="back"
          variant="text"
          color="default"
          size="medium"
          onClick={onBack}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
        <Typography fontWeight={500}>Edit document</Typography>
      </div>
    </AppBar>
  );
}

function CountersignerOnlyConfirmationUI({ alreadyFullyExecutedInvestors }) {
  const classes = useStyles();

  return (
    <div className={classes.countersignOnlyDocDisplay}>
      <Typography variant="card-heading">Investors</Typography>

      <Alert severity="warning">
        The following investors have already been fully executed. To request
        signatures, undo the approval for each investor and send the document to
        countersigners.
      </Alert>

      <InvestorReviewTable
        investors={alreadyFullyExecutedInvestors}
        noTopMargin
      />
    </div>
  );
}

const useDocDisplayStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
  paperRoot: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.spacing(2),
  },
  nameAndType: {
    display: 'flex',
    flexDirection: 'column',
  },
  iconAlignment: {
    alignSelf: 'center',
  },
}));

function DocDisplay({ docName, docType }) {
  const classes = useDocDisplayStyles();

  return (
    <div className={classes.root}>
      <Typography variant="card-heading">Document</Typography>

      <Paper variant="outlined" className={classes.paperRoot}>
        <SpotIcon className={classes.iconAlignment} size="medium">
          <BorderColorIcon />
        </SpotIcon>

        <div className={classes.nameAndType}>
          <Typography>{docName}</Typography>

          <Typography size="small">{docType}</Typography>
        </div>
      </Paper>
    </div>
  );
}

function genSummarySubHeadingText(
  investors,
  isFundOfferingDoc,
  pushToExisting,
) {
  if (isFundOfferingDoc && !pushToExisting) {
    return 'Review and confirm all information to add document to new investors.';
  }

  const numInvestorsAddingTo = investors.length;
  return (
    `Review and confirm all information to add document to ` +
    `${numInvestorsAddingTo} investor${numInvestorsAddingTo > 1 ? 's' : ''}.`
  );
}

export function Confirmation({
  docName,
  docType,
  onBack,
  onSubmit,
  boxes,
  pushToExisting,
  isFundDocumentUpload,
  sendImmediately,
  setSendImmediately,
  investors,
  uploadCreationDocFailed,
  requestChangesMessage,
  setRequestChangesMessage,
  requestChangesMessageError,
  isSubmitting,
}) {
  const classes = useStyles();
  const { showSupport } = useSupport();

  const boxSignerRoles = boxes.map((b) => b.signer);
  const uniqueBoxSignerRoles = new Set(boxSignerRoles);

  const numUniqueSigners = [...uniqueBoxSignerRoles].reduce(
    (acc, currRole) => (currRole.includes('Investor') ? acc + 1 : acc),
    0,
  );
  const numUniqueCountersigners = [...uniqueBoxSignerRoles].reduce(
    (acc, currRole) => (currRole.includes('Countersigner') ? acc + 1 : acc),
    0,
  );

  const requestReviewStatuses = [
    'PARTIALLY_SIGNED',
    'SIGNED',
    'APPROVED',
    'SENT_TO_COUNTERSIGNER',
    'COUNTERSIGNED',
  ];
  const requestReviewInvestors = investors.filter((i) =>
    requestReviewStatuses.includes(i.status),
  );
  const unsentStatuses = ['DRAFT'];
  const unsentInvestors = investors.filter((i) =>
    unsentStatuses.includes(i.status),
  );
  const alreadyFullyExecutedInvestors = investors.filter(
    (i) => i.status === 'COUNTERSIGNED',
  );

  const isCountersignerOnlyDoc =
    numUniqueSigners === 0 && numUniqueCountersigners > 0;
  const showCountersignerOnlyConfirmationUI =
    isCountersignerOnlyDoc && alreadyFullyExecutedInvestors.length > 0;

  const enableRadioButtons =
    !isCountersignerOnlyDoc && (!isFundDocumentUpload || pushToExisting);

  const offeringDocNeedsSendOption =
    isFundDocumentUpload && pushToExisting && sendImmediately === null;
  const sideletterNeedsSendOption =
    !isFundDocumentUpload && sendImmediately === null;
  const submitDisabled =
    !isCountersignerOnlyDoc &&
    (offeringDocNeedsSendOption || sideletterNeedsSendOption);

  return (
    <div key="confirmation">
      <TopBar onBack={onBack} />

      <div key="root" className={classes.root}>
        <Container component="main" maxWidth="sm" className={classes.container}>
          <div>
            <Typography variant="section-heading">Summary</Typography>

            <Typography>
              {genSummarySubHeadingText(
                investors,
                isFundDocumentUpload,
                pushToExisting,
              )}
            </Typography>
          </div>

          <DocDisplay docName={docName} docType={docType} />

          <UsedSignerRoleSummaryTable
            numUniqueSigners={numUniqueSigners}
            numUniqueCountersigners={numUniqueCountersigners}
          />

          {showCountersignerOnlyConfirmationUI ? (
            <CountersignerOnlyConfirmationUI
              alreadyFullyExecutedInvestors={alreadyFullyExecutedInvestors}
            />
          ) : null}

          {enableRadioButtons ? (
            <div className={classes.radioBtnDisplay}>
              <Typography fontWeight={500}>
                Do you want to send the document and notify the signatories now?
              </Typography>

              <Alert
                severity="info"
                action={
                  <LinkButton
                    color="inherit"
                    variant="outlined"
                    className={classes.learnMore}
                    url="https://support.passthrough.com/en_us/how-to-add-documents-BJMMTkkhq"
                  >
                    Learn more
                  </LinkButton>
                }
              >
                The current status of the investors will affect their access to
                this document and how they are notified.
              </Alert>

              <RadioCardContainer
                selectedChoice={String(sendImmediately)}
                setSelectedChoice={(val) => {
                  setSendImmediately(val === 'true');
                }}
                className={classes.radioContainer}
              >
                <RadioCardChoice
                  choiceBackground="white"
                  choiceKey="true"
                  choiceValue="true"
                  choiceTitle="Send immediately"
                  choiceSubtitle="Add this document to the investors and notify them immediately by email."
                />
                <RadioCardChoice
                  choiceBackground="white"
                  choiceKey="false"
                  choiceValue="false"
                  choiceTitle="Send later"
                  choiceSubtitle="Add this document to the investors now but do not notify them."
                />
              </RadioCardContainer>

              <Collapse in={sendImmediately}>
                <div className={classes.collapsibleContent}>
                  {unsentInvestors.length > 0 ? (
                    <Alert severity="warning">
                      {unsentInvestors.length} selected investor
                      {unsentInvestors.length > 1 ? 's are' : ' is'} in
                      <strong> unsent </strong>
                      status and will not be notified immediately.
                    </Alert>
                  ) : null}

                  {requestReviewInvestors.length > 0 ? (
                    <>
                      <Alert severity="warning">
                        The following investors will be moved to{' '}
                        <strong>Sent for investor signature</strong> status, or{' '}
                        <strong>Requested changes</strong> status for investors
                        with unsent comment drafts. They can be approved once
                        the document is signed with no unresolved comments.
                      </Alert>

                      <InvestorReviewTable
                        investors={requestReviewInvestors}
                        noTopMargin
                      />
                    </>
                  ) : null}

                  <TextField
                    id="message-to-investor"
                    label="Include a message (optional)"
                    value={requestChangesMessage}
                    onChange={(e) => {
                      setRequestChangesMessage(e.target.value);
                    }}
                    variant="outlined"
                    multiline
                    minRows="4"
                    fullWidth
                    error={!!requestChangesMessageError}
                    helperText={
                      requestChangesMessageError ||
                      'This message will appear in the email to the investor.'
                    }
                  />
                </div>
              </Collapse>
            </div>
          ) : null}

          {uploadCreationDocFailed ? (
            <FormHelperText className={classes.errorMessage} error>
              Document upload failed, please{' '}
              <DisplayLink onClick={showSupport}>contact support.</DisplayLink>
            </FormHelperText>
          ) : null}

          <Button
            type="submit"
            onClick={onSubmit}
            variant="contained"
            color="primary"
            fullWidth
            disabled={submitDisabled}
            loading={isSubmitting}
          >
            Add document
          </Button>
        </Container>
      </div>
    </div>
  );
}
