import React from 'react';
import { Typography, UIKitSettingsProvider } from '@passthrough/uikit';
import { useQuestionnaire } from 'services/providers/questionnaire';
import { ShowCaseBox, ComponentShowCase } from '../../show_case';
import { MultiFileUploadShowCase } from './multi_file_upload';
import { SingleFileUploadShowCase } from './single_file_upload';

export const FileUploadShowCase = () => {
  const [nextFileId, setNextFileId] = React.useState(1);
  const { useSupport } = useQuestionnaire();
  const { showSupport } = useSupport();

  function uploadFiles(acceptedFiles) {
    const uploadedFiles = acceptedFiles.map((file, index) => ({
      fileId: nextFileId + index,
      fileUrl: file.url,
      fileName: file.name,
    }));

    setNextFileId(nextFileId + acceptedFiles.length);
    return Promise.resolve(uploadedFiles);
  }

  return (
    <UIKitSettingsProvider showSupport={showSupport} uploadFiles={uploadFiles}>
      <ComponentShowCase title="File Upload">
        <ShowCaseBox>
          <Typography align="center">
            <strong>Single</strong>
          </Typography>
          <SingleFileUploadShowCase />
          <Typography align="center" mt={5}>
            <strong>Multiple</strong>
          </Typography>
          <MultiFileUploadShowCase />
        </ShowCaseBox>
      </ComponentShowCase>
    </UIKitSettingsProvider>
  );
};
