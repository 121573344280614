import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, Typography } from '@passthrough/uikit';

import * as constants from 'components/document_fields/constants';
import { useSigners } from 'components/document_fields/hooks';
import { DocumentFields } from 'components/document_fields';

import { SideBar } from './sidebar';
import { genTooltipText } from './utils';

const useStyles = makeStyles((theme) => ({
  appBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'relative',
    backgroundColor: theme.palette.appbar.background,
    padding: theme.spacing(1),
  },
  appBarContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
}));

export function EditDocument({
  onBack,
  moveStep,
  file,
  name,
  hasFirstSigner,
  hasSecondSigner,
  hasThirdSigner,
  hasFourthSigner,
  hasFifthSigner,
  hasSixthSigner,
  hasFirstCounterSigner,
  hasSecondCounterSigner,
  hasThirdCounterSigner,
  documentFieldState,
  documentFieldActions,
  allowInvestorType,
  lpClosings,
}) {
  const classes = useStyles();

  const [selectedSigner, setSelectedSigner] = useState(constants.FIRST_SIGNER);
  const [selectedBoxType, setSelectedBoxType] = useState(null);

  const { boxes, areBoxesInvalid } = documentFieldState;
  const noBoxes = boxes.length === 0;

  const updateSelectedDocType = (newType) => {
    setSelectedBoxType(newType);
    documentFieldActions.setActiveBox(null);
  };

  const signers = useSigners({
    investors: lpClosings,
    hasFirstSigner,
    hasSecondSigner,
    hasThirdSigner,
    hasFourthSigner,
    hasFifthSigner,
    hasSixthSigner,
    hasFirstCounterSigner,
    hasSecondCounterSigner,
    hasThirdCounterSigner,
  });
  const initialSignerName = signers[0].name;

  return (
    <>
      <AppBar className={classes.appBar}>
        <div className={classes.appBarContent}>
          <IconButton
            name="back"
            variant="text"
            color="default"
            size="medium"
            onClick={onBack}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Typography variant="card-heading">{name}</Typography>
        </div>

        <div className={classes.appBarContent}>
          <Tooltip
            title={genTooltipText(noBoxes, areBoxesInvalid, initialSignerName)}
          >
            <div>
              <Button
                type="submit"
                onClick={moveStep}
                variant="primary"
                disabled={noBoxes || areBoxesInvalid}
              >
                Next
              </Button>
            </div>
          </Tooltip>
        </div>
      </AppBar>

      <div className={classes.body}>
        <SideBar
          relevantSigners={signers}
          selectedSigner={selectedSigner}
          setSelectedSigner={setSelectedSigner}
          selectedBoxType={selectedBoxType}
          updateSelectedDocType={updateSelectedDocType}
        />
        <DocumentFields
          file={file}
          state={documentFieldState}
          actions={documentFieldActions}
          mode={constants.BOX_CREATION_MODE}
          allowInvestorType={allowInvestorType}
          signers={signers}
          selectedSigner={selectedSigner}
          selectedBoxType={selectedBoxType}
          setSelectedBoxType={setSelectedBoxType}
        />
      </div>
    </>
  );
}
