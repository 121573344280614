import React from 'react';
import { ClickableChip, Icons } from '@passthrough/uikit';

export function EmailChip({ email, onClick }) {
  return (
    <ClickableChip
      defaultIcon={<Icons.PersonOutline />}
      label={email}
      onClick={onClick}
      onDelete={onClick}
      deleteIcon={<Icons.CancelOutlined />}
      variant="neutral"
    />
  );
}
