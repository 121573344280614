import React from 'react';
import { SpotIcon } from '@passthrough/uikit';
import { ShowCaseBox, ComponentShowCase, ShowCaseBoxRow } from '../show_case';

export const SpotIconShowCase = () => (
  <ComponentShowCase title="SpotIcon">
    <ShowCaseBox>
      <ShowCaseBoxRow>
        <SpotIcon variant="error" />
        <SpotIcon variant="info" />
        <SpotIcon variant="warning" />
        <SpotIcon variant="neutral" />
        <SpotIcon variant="success" />
        <SpotIcon size="small" />
        <SpotIcon size="medium" />
      </ShowCaseBoxRow>
    </ShowCaseBox>
  </ComponentShowCase>
);
