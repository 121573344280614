import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useWhiteLabelConfig } from 'services/providers/theme';
import { Typography, Chip } from '@passthrough/uikit';

import { getExperience } from './utils';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
}));

export const ExperienceItem = ({ experience, variant }) => {
  const classes = useStyles();
  const config = useWhiteLabelConfig();
  const data = getExperience(config.productName, experience);

  if (!data) {
    return null;
  }

  return (
    <Paper variant="outlined" elevation={0} className={classes.root}>
      <ListItem>
        {variant === 'done' ? (
          <ListItemIcon size="large">
            <CheckCircleIcon color="primary" size="large" />
          </ListItemIcon>
        ) : null}
        <ListItemText
          primary={
            <Typography>
              <strong>{data.title}</strong>{' '}
              <Chip variant="success" size="small" label="Current" />
            </Typography>
          }
          secondary={data.subtitle}
        />
      </ListItem>
    </Paper>
  );
};
