import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '25px',
    padding: theme.spacing(1),
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flex: 'auto',
    transition: '0.1s',
  },
  onHover: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.divider,
    },
  },
  mainLabel: {
    marginLeft: theme.spacing(1),
    userSelect: 'none',
    width: '100%',
  },
  inverted: {
    transform: 'rotate(180deg)',
  },
  slowTransition: {
    transition: '0.2s',
  },
}));

export function ExpansionHeader({ label, expanded, onClick, statusIcon }) {
  const classes = useStyles();
  const subsectionName = `${label}-subsection`;

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  return (
    <div
      id={subsectionName}
      onClick={onClick}
      className={clsx(classes.root, { [classes.onHover]: !expanded })}
      role="button"
      tabIndex="0"
    >
      {statusIcon}
      <Typography variant="subtitle1" className={classes.mainLabel}>
        {label}
      </Typography>
    </div>
  );
}
