// NOTE: until we upgrade cloud run, can only upload files

import { ErrorCode } from 'react-dropzone';

export const FILE_TOO_LARGE_VALIDATION_ERROR_CODE = 'file_too_large';
export const INVALID_FILE_TYPE_VALIDATION_ERROR_CODE = 'invalid_file_type';

export const KNOWN_ERROR_TYPES = [
  FILE_TOO_LARGE_VALIDATION_ERROR_CODE,
  INVALID_FILE_TYPE_VALIDATION_ERROR_CODE,
  // other errors from react-dropzone are handled by the above
  ErrorCode.TooManyFiles,
];
