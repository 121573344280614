import React from 'react';
import { Button, Icons } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    alignItems: 'center',
  },
  input: {
    width: '400px',
  },
}));

export const CompareBar = ({
  templateQuestionnaireName,
  questionnaireName,
  error,
  onChange,
  loading,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(templateQuestionnaireName);

  React.useEffect(() => {
    setValue(templateQuestionnaireName);
  }, [templateQuestionnaireName]);

  return (
    <Paper variant="outlined" elevation={0} className={classes.root}>
      <TextField
        value={value}
        onChange={(e) => setValue(e.target.value)}
        label="Template"
        variant="outlined"
        className={classes.input}
        size="small"
        disabled={loading}
        error={Boolean(error)}
      />

      <Button
        variant="primary"
        startIcon={<Icons.Save />}
        disabled={!value || value === templateQuestionnaireName}
        onClick={() => onChange(value)}
      >
        Edit template
      </Button>

      <Icons.ArrowRightAlt />

      <TextField
        label="Current"
        variant="outlined"
        size="small"
        className={classes.input}
        value={questionnaireName}
        disabled
      />

      {error ? <FormHelperText error>{error}</FormHelperText> : null}
    </Paper>
  );
};
