import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'components/dialog/index';
import { Button, LinkButton } from 'components/button';
import { Spinner } from 'components/spinner';
import * as api from 'services/api';
import { useToast } from 'services/toast';

const useStyles = makeStyles((theme) => ({
  helperText: {
    color: theme.palette.text.secondary,
  },
  dialogContent: {
    overflowY: 'auto',
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  download: {
    margin: theme.spacing(2, 0),
  },
}));

export function ExportDialog({ open, handleClose }) {
  const classes = useStyles();
  const { successToast } = useToast();
  const [exportLoading, setExportLoading] = useState(false);
  const [exportUrl, setExportUrl] = useState(null);

  function pollForResults(exportId) {
    api.getQuestionTagExport({ exportId }).then((response) => {
      if (response.data.url) {
        setExportLoading(false);
        setExportUrl(response.data.url);
        successToast('Generated excel export');
      } else {
        setTimeout(() => {
          pollForResults(exportId);
        }, 500);
      }
    });
  }

  function onSubmit(e) {
    e.preventDefault();
    setExportLoading(true);

    api.questionTagExport().then((response) => {
      const { exportId } = response.data;
      setTimeout(() => {
        pollForResults(exportId);
      }, 500);
    });
  }

  useEffect(() => {
    setExportUrl(null);
  }, [open]);

  function dialogContent() {
    return (
      <>
        <DialogTitle>Export Question Tags</DialogTitle>
        <DialogContent dividers className={classes.dialogContent}>
          <div>
            <Typography>
              Generating an export may take a few moments.
            </Typography>
            {exportLoading ? (
              <>
                <Typography>Your export is being generated.</Typography>
                <div className={classes.spinner}>
                  <Spinner height={300} width={300} />
                </div>
              </>
            ) : null}
            {exportUrl ? (
              <LinkButton
                variant="contained"
                color="primary"
                href={exportUrl}
                onClick={handleClose}
                endIcon={<GetAppIcon />}
                className={classes.download}
              >
                Download tag export
              </LinkButton>
            ) : null}
          </div>
        </DialogContent>
        <DialogActions>
          {exportUrl ? (
            <Button
              type="submit"
              variant="contained"
              color="default"
              fullWidth
              onClick={handleClose}
            >
              Close
            </Button>
          ) : (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              onClick={onSubmit}
              disabled={exportLoading}
            >
              Generate export
            </Button>
          )}
        </DialogActions>
      </>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      disableBackdropClick={exportLoading || exportUrl}
    >
      {dialogContent()}
    </Dialog>
  );
}
