import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    backgroundColor: 'transparent',
  },
  subtitle: {
    paddingTop: theme.spacing(1),
  },
}));

export const Card = ({ current, total, label }) => {
  const classes = useStyles();
  const percentage = Math.round((current / total) * 100);
  return (
    <Paper variant="outlined" elevation={0} className={classes.root}>
      <Typography variant="page-heading">{percentage}%</Typography>
      <LinearProgress variant="determinate" value={percentage} />
      <Typography variant="card-heading" className={classes.subtitle}>
        {current} of {total} questions {label}
      </Typography>
    </Paper>
  );
};
