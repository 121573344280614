import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { Typography, Modal } from '@passthrough/uikit';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useModalState } from '../hooks';
import { getIdFromName } from '../helpers';

const useStyles = makeStyles((theme) => ({
  groupCheckboxes: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 1),
  },
}));

export function GroupModal({
  group,
  onClose,
  changeGroup,
  addGroup,
  deleteGroup,
  actionIds,
  stateIds,
}) {
  const classes = useStyles();
  const [
    editedGroup,
    setEditedGroup,
    headerLabel,
    open,
    onSubmit,
    isNew,
    deleteButton,
    showErrors,
  ] = useModalState({
    label: 'group',
    initialState: group,
    addObject: addGroup,
    changeObject: changeGroup,
    deleteObject: deleteGroup,
    requiredFields: ['name'],
    onClose,
  });

  const nameError = showErrors && !editedGroup.name;

  return (
    <Modal
      open={open}
      onClose={onClose}
      headerLabel={headerLabel}
      showCancelButton
      tertiaryButton={deleteButton}
      primaryButtonText="Save changes"
      onSubmit={onSubmit}
    >
      <TextField
        required
        id="name"
        variant="outlined"
        label="Name"
        type="text"
        value={editedGroup.name}
        onChange={(e) => {
          let newId = editedGroup.id;
          if (isNew) {
            newId = getIdFromName(e.target.value);
          }

          setEditedGroup({
            ...editedGroup,
            name: e.target.value,
            id: newId,
          });
        }}
        fullWidth
        error={nameError}
        helperText={nameError ? 'This field is required' : ''}
      />
      <TextField
        required
        id="ID"
        variant="outlined"
        label={isNew ? '' : 'ID'}
        type="text"
        value={editedGroup.id || ''}
        fullWidth
        disabled
      />
      <Typography variant="card-heading" size="medium">
        Actions
      </Typography>
      <div className={classes.groupCheckboxes}>
        {actionIds.map((actionId) => (
          <FormControlLabel
            key={actionId}
            control={
              <Checkbox
                checked={editedGroup?.actions?.includes(actionId)}
                onChange={(e) => {
                  setEditedGroup({
                    ...editedGroup,
                    actions: e.target.checked
                      ? [...editedGroup.actions, actionId]
                      : editedGroup.actions.filter((a) => a !== actionId),
                  });
                }}
              />
            }
            label={actionId}
          />
        ))}
      </div>
      <Typography variant="card-heading" size="medium">
        Notifications
      </Typography>
      <div className={classes.groupCheckboxes}>
        {stateIds.map((stateId) => (
          <FormControlLabel
            key={stateId}
            control={
              <Checkbox
                checked={editedGroup?.notifications?.includes(stateId)}
                onChange={(e) => {
                  setEditedGroup({
                    ...editedGroup,
                    notifications: e.target.checked
                      ? [...editedGroup.notifications, stateId]
                      : editedGroup.notifications.filter((a) => a !== stateId),
                  });
                }}
              />
            }
            label={stateId}
          />
        ))}
      </div>
    </Modal>
  );
}
