import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Button } from 'components/button';
import { ACCESS_LEVEL_MAPPING, ACCESS_LEVELS } from './constants';

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    marginTop: theme.spacing(2),
  },
  radioLabel: {
    margin: theme.spacing(1),
  },
  popoverPaper: {
    padding: theme.spacing(2),
    maxWidth: '400px',
  },
  popoverHeader: {
    fontWeight: 500,
  },
  permissionDescriptionText: {
    paddingLeft: theme.spacing(5),
  },
  saveButton: {
    marginTop: theme.spacing(3),
  },
  selfAccessText: {
    fontSize: '0.875rem',
    fontWeight: 500,
    padding: theme.spacing(1),
  },
}));

export function PermissionPopover({
  collaboratorId,
  accessLevel,
  setAccessLevel,
  me,
  readOnly,
}) {
  const accessValue =
    accessLevel === ACCESS_LEVELS.OWNER.apiValue
      ? ACCESS_LEVELS.OWNER.value
      : ACCESS_LEVELS.ONLY_THIS_INVESTMENT.value;
  const accessDisplay =
    accessLevel === ACCESS_LEVELS.OWNER.apiValue
      ? ACCESS_LEVELS.OWNER.label
      : ACCESS_LEVELS.ONLY_THIS_INVESTMENT.label;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [updatedAccessValue, setUpdatedAccessValue] = useState(accessValue);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setUpdatedAccessValue(accessValue);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChange = (e) => {
    setUpdatedAccessValue(e.target.value);
  };

  const onSubmit = () => {
    setAccessLevel({
      collaboratorId,
      accessLevel: ACCESS_LEVEL_MAPPING[updatedAccessValue],
    });
    handleClose();
  };

  const open = Boolean(anchorEl);

  if (collaboratorId === me.id || readOnly) {
    return (
      <Typography className={classes.selfAccessText}>
        {accessDisplay}
      </Typography>
    );
  }

  return (
    <div>
      <Button
        variant="text"
        color="default"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        className={classes.tableTextButton}
      >
        {accessDisplay}
      </Button>
      <Popover
        id={open ? 'popover' : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{ paper: classes.popoverPaper }}
      >
        <Typography className={classes.popoverHeader}>
          What level of access should this collaborator have?
        </Typography>
        <FormControl
          required
          component="fieldset"
          fullWidth
          className={classes.radioGroup}
        >
          <RadioGroup
            aria-label="access"
            name="access"
            value={updatedAccessValue}
            onChange={onChange}
          >
            <FormControlLabel
              value={ACCESS_LEVELS.OWNER.value}
              control={<Radio />}
              label={
                <div className={classes.radioLabel}>
                  <Typography variant="body1">
                    {ACCESS_LEVELS.OWNER.label}
                  </Typography>
                </div>
              }
            />
            <Typography
              variant="body2"
              className={classes.permissionDescriptionText}
            >
              {ACCESS_LEVELS.OWNER.helpText}
            </Typography>
            <FormControlLabel
              value={ACCESS_LEVELS.ONLY_THIS_INVESTMENT.value}
              control={<Radio />}
              label={
                <div className={classes.radioLabel}>
                  <Typography variant="body1">
                    {ACCESS_LEVELS.ONLY_THIS_INVESTMENT.label}
                  </Typography>
                </div>
              }
            />
            <Typography
              variant="body2"
              className={classes.permissionDescriptionText}
            >
              {ACCESS_LEVELS.ONLY_THIS_INVESTMENT.helpText}
            </Typography>
          </RadioGroup>
        </FormControl>
        <Button
          type="submit"
          onClick={onSubmit}
          variant="contained"
          color="primary"
          fullWidth
          className={classes.saveButton}
        >
          Save changes
        </Button>
      </Popover>
    </div>
  );
}
