import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Collapse from '@material-ui/core/Collapse';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { Modal, Alert, Typography, Icons } from '@passthrough/uikit';
import { useDiscardConfirmation } from 'services/discard';
import { getSingleErrorFromResponse, objectEquals } from 'services/utils';
import { Spinner } from 'components/spinner';
import * as api from 'services/api';

import { INVESTOR_ACCESS_LABELS } from '../investor_access_label';
import * as constants from '../constants';

const useStyles = makeStyles((theme) => ({
  list: {
    marginTop: theme.spacing(1),
  },
  listItem: {
    display: 'list-item',
    listStyleType: 'disc',
    marginLeft: '16px',
  },
  slugDisplay: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
  },
  linkIcon: {
    marginTop: '2px',
  },
  collapseEntered: {
    minHeight: 'auto !important',
  },
  collapseHidden: {
    display: 'none',
  },
  collapseWrapperInner: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

function getMissingDefaults(form, errors) {
  const missingDefaults = [];
  if (form.investorAccess === constants.SIGNUP_LINK) {
    if (errors.defaultCountersignerId) {
      missingDefaults.push('Default countersigner');
    }
    if (errors.defaultCountersigner2Id) {
      missingDefaults.push('Default second countersigner');
    }
    if (errors.defaultCountersigner3Id) {
      missingDefaults.push('Default third countersigner');
    }
    if (errors.defaultDiligenceApproverId) {
      missingDefaults.push('Default diligence approver');
    }
  }
  return missingDefaults;
}

function SlugDisplay({ closingName, slug }) {
  const classes = useStyles();
  const slugToDisplay = `/${slug}`;

  return (
    <div className={classes.slugDisplay}>
      <Icons.Link
        color="primary"
        fontSize="small"
        className={classes.linkIcon}
      />
      <div>
        <Typography variant="body">{slugToDisplay}</Typography>
        <Typography variant="body" size="small" color="text.secondary">
          {closingName}
        </Typography>
      </div>
    </div>
  );
}

function getExternalSignUpSlugMenuItems(
  currentClosingName,
  externalSignupSlug,
  linksFromOtherClosings,
  linksFromOtherClosingsLoading,
) {
  const showLinksFromOtherClosings =
    linksFromOtherClosings.length > 0 || linksFromOtherClosingsLoading;
  const menuItems = [];

  if (externalSignupSlug !== constants.GENERATE_NEW_LINK) {
    menuItems.push(
      <MenuItem key={externalSignupSlug} value={externalSignupSlug} divider>
        <SlugDisplay
          closingName={currentClosingName}
          slug={externalSignupSlug}
        />
      </MenuItem>,
    );
  }

  menuItems.push(
    <MenuItem
      key={constants.GENERATE_NEW_LINK}
      value={constants.GENERATE_NEW_LINK}
      divider={showLinksFromOtherClosings}
    >
      <Typography>Generate a new sign-up link</Typography>
    </MenuItem>,
  );

  if (showLinksFromOtherClosings) {
    menuItems.push(
      <ListSubheader
        key="other_closings"
        disableSticky
        // see https://github.com/mui/material-ui/issues/18200
        onClickCapture={(e) => {
          e.stopPropagation();
        }}
      >
        Use an existing link from other closings
        {linksFromOtherClosingsLoading ? <Spinner height={150} /> : null}
      </ListSubheader>,
    );

    linksFromOtherClosings.forEach(({ closingName, slug }) =>
      menuItems.push(
        <MenuItem key={slug} value={slug}>
          <SlugDisplay closingName={closingName} slug={slug} />
        </MenuItem>,
      ),
    );
  }

  return menuItems;
}

function DisableSignUpLinkWarning() {
  return (
    <Alert severity="warning">
      Saving this setting will disable the current sign-up link. You will not be
      able to use this link again.
    </Alert>
  );
}

export function InvitationSettingsModal({
  open,
  onClose,
  defaultExternalSignupText,
  closingName,
  initialState,
  errors,
  setErrors,
  updateSettings,
  loading,
}) {
  const [form, setForm] = useState(initialState);
  // Initial state is used to decide whether to show alerts. Save it in
  // state to prevent the alert from popping up for a split second
  // after generating a new sign-up link.
  const [savedInitialState, setSavedInitialState] = useState(initialState);
  const [linksFromOtherClosings, setLinksFromOtherClosings] = useState([]);
  const [linksFromOtherClosingsLoading, setLinksFromOtherClosingsLoading] =
    useState(false);
  const [linksFromOtherClosingsError, setLinksFromOtherClosingsError] =
    useState('');
  const { fundId, closingId } = useParams();
  const classes = useStyles();

  const missingDefaults = getMissingDefaults(form, errors);
  const showMissingDefaultError = missingDefaults.length > 0;

  const closeWithConfirmation = useDiscardConfirmation({
    onConfirm: onClose,
    needConfirmation:
      // Don't confirm when showing the missing default error since they'll
      // have to close the modal no matter what.
      open && !objectEquals(form, initialState) && !showMissingDefaultError,
  });

  const showDisableLinkWarning =
    form.investorAccess === constants.INVITE_ONLY &&
    savedInitialState.investorAccess === constants.SIGNUP_LINK;
  const showChangeLinkWarning =
    form.investorAccess === constants.SIGNUP_LINK &&
    savedInitialState.externalSignupSlug !== constants.GENERATE_NEW_LINK &&
    savedInitialState.externalSignupSlug !== form.externalSignupSlug;

  const existingLink = linksFromOtherClosings.find(
    (link) => link.slug === form.externalSignupSlug,
  );

  const resetState = () => {
    setForm(initialState);
    setSavedInitialState(initialState);
    setErrors({});
    setLinksFromOtherClosings([]);
    setLinksFromOtherClosingsLoading(true);
    setLinksFromOtherClosingsError('');

    api
      .existingSignUpLinks({ fundId, closingId })
      .then((response) => {
        setLinksFromOtherClosings(response.data);
      })
      .catch((error) => {
        if (error?.response) {
          setLinksFromOtherClosingsError(
            getSingleErrorFromResponse(error.response),
          );
        }
      })
      .finally(() => {
        setLinksFromOtherClosingsLoading(false);
      });
  };

  const useDefaultExternalSignupTextChange = (e) => {
    if (e.target.value === 'true') {
      setForm({
        ...form,
        useDefaultExternalSignupText: true,
        externalSignupText:
          form.externalSignupText === defaultExternalSignupText
            ? initialState.externalSignupText
            : form.externalSignupText,
      });
    } else {
      setForm({
        ...form,
        useDefaultExternalSignupText: false,
        externalSignupText:
          form.externalSignupText || defaultExternalSignupText,
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={closeWithConfirmation}
      headerLabel="Edit invitation settings"
      showCancelButton
      primaryButtonText="Save"
      primaryButtonLoading={loading}
      onEntering={resetState}
      // Setting form to initialState after exiting prevents showing
      // the Collapse animation when entering.
      onExited={() => setForm(initialState)}
      onSubmit={() => updateSettings(form)}
    >
      {showMissingDefaultError ? (
        <Alert severity="error" skipTypography>
          <Typography variant="body" size="small">
            A sign-up link requires the following to be assigned:
          </Typography>
          <List disablePadding className={classes.list}>
            {missingDefaults.map((text) => (
              <ListItem dense key={text} className={classes.listItem}>
                <Typography variant="body" size="small">
                  {text}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Alert>
      ) : null}

      {linksFromOtherClosingsError ? (
        <Alert severity="error">
          There was an error fetching existing sign-up links:{' '}
          {linksFromOtherClosingsError}
        </Alert>
      ) : null}

      <TextField
        id="default_invitation_message"
        variant="outlined"
        label="Default invitation message (optional)"
        type="text"
        value={form.defaultInvitationText || ''}
        onChange={(e) =>
          setForm({ ...form, defaultInvitationText: e.target.value })
        }
        error={!!errors.defaultInvitationText}
        helperText={
          errors.defaultInvitationText ||
          'Add a message template when you send invitations to investors.'
        }
        fullWidth
        multiline
        minRows={4}
      />

      <FormControl variant="outlined" error={!!errors.investorAccess}>
        <InputLabel id="external_signup_enabled_label">
          Investor sign-up
        </InputLabel>
        <Select
          id="external_signup_enabled"
          label="Investor sign-up"
          labelId="external_signup_enabled_label"
          value={form.investorAccess}
          variant="outlined"
          onChange={(e) => setForm({ ...form, investorAccess: e.target.value })}
        >
          {INVESTOR_ACCESS_LABELS.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
        {errors.investorAccess ? (
          <FormHelperText>{errors.investorAccess}</FormHelperText>
        ) : null}
      </FormControl>

      {showDisableLinkWarning ? <DisableSignUpLinkWarning /> : null}

      <Collapse
        in={form.investorAccess === constants.SIGNUP_LINK}
        classes={{
          entered: classes.collapseEntered,
          hidden: classes.collapseHidden,
          wrapperInner: classes.collapseWrapperInner,
        }}
      >
        <FormControl
          fullWidth
          variant="outlined"
          error={!!errors.externalSignupSlug}
        >
          <InputLabel id="external_signup_slug_label">Sign-up link</InputLabel>
          <Select
            id="external_signup_slug"
            label="Sign-up link"
            labelId="external_signup_slug_label"
            value={form.externalSignupSlug}
            variant="outlined"
            onChange={(e) =>
              setForm({ ...form, externalSignupSlug: e.target.value })
            }
          >
            {getExternalSignUpSlugMenuItems(
              closingName,
              initialState.externalSignupSlug,
              linksFromOtherClosings,
              linksFromOtherClosingsLoading,
            )}
          </Select>
          {errors.externalSignupSlug ? (
            <FormHelperText>{errors.externalSignupSlug}</FormHelperText>
          ) : null}
        </FormControl>

        {existingLink ? (
          <Alert severity="warning">
            The selected link will allow investors to join the current closing
            instead of {existingLink.closingName}.
          </Alert>
        ) : null}

        {showChangeLinkWarning ? (
          // Same text as showDisableLinkWarning, but in a different position.
          // They're mutually exclusive.
          <DisableSignUpLinkWarning />
        ) : null}

        <FormControl
          id="use_default_external_signup_text"
          error={errors.useDefaultExternalSignupText}
          component="fieldset"
          fullWidth
        >
          <FormLabel component="legend">Sign-up message</FormLabel>
          <RadioGroup
            aria-label="Use default sign-up message"
            name="use_default_external_signup_text"
            value={form.useDefaultExternalSignupText}
            onChange={useDefaultExternalSignupTextChange}
          >
            <FormControlLabel
              value
              control={<Radio />}
              label={<Typography>Default</Typography>}
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label={<Typography>Custom</Typography>}
            />
          </RadioGroup>
          {errors.disableOfflineSigning ? (
            <FormHelperText>{errors.disableOfflineSigning}</FormHelperText>
          ) : null}
        </FormControl>

        <TextField
          id="custom_signup_message"
          variant="outlined"
          label="Sign-up message"
          type="text"
          value={
            form.useDefaultExternalSignupText
              ? defaultExternalSignupText
              : form.externalSignupText || ''
          }
          onChange={(e) =>
            setForm({ ...form, externalSignupText: e.target.value })
          }
          error={!!errors.externalSignupText}
          helperText={
            errors.externalSignupText ||
            'Message for investors who use the sign-up link'
          }
          disabled={form.useDefaultExternalSignupText}
          fullWidth
          multiline
          minRows={4}
        />
      </Collapse>
    </Modal>
  );
}
