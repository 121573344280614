import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { Button } from 'components/button';

const useStyles = makeStyles((theme) => ({
  requestChangesButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.dark,
      backgroundColor: theme.palette.error.background,
    },
  },
}));

export function UndoChangeRequestButton({
  disabled,
  setUndoRequestChangesDialogOpen,
}) {
  const classes = useStyles();

  const toolTipText = disabled ? (
    <Typography>
      The investor's documents require at least 1 additional signature. Please
      contact support to undo this change request.
    </Typography>
  ) : (
    ''
  );

  return (
    <Tooltip title={toolTipText}>
      {/* a div is needed to ensure that the tooltip appears for a disabled button */}
      <div>
        <Button
          className={classes.requestChangesButton}
          disabled={disabled}
          onClick={() => {
            setUndoRequestChangesDialogOpen(true);
          }}
          fullWidth
        >
          Undo change request
        </Button>
      </div>
    </Tooltip>
  );
}
