import {
  QUESTIONNAIRE_EVENT_TYPES,
  DILIGENCE_EVENT_TYPES,
  threadEvents,
  commentEvents,
  openedThreadEvents,
  internalNoteEvents,
} from './constants';

export const doesThreadExist = (events) =>
  events?.some((event) => threadEvents.includes(event.type));

export const filterCommentEvents = (events) =>
  events?.filter((event) => commentEvents.includes(event.type)) || [];

export const countNumCommentEvents = (events) =>
  filterCommentEvents(events).length;

export const filterInternalNoteEvents = (events) =>
  events?.filter((event) => internalNoteEvents.includes(event.type)) || [];

export const countNumInternalNoteEvents = (events) =>
  filterInternalNoteEvents(events).length;

export const isThreadResolved = (events) => {
  if (!events) {
    return true;
  }

  // assumes threads are asc sorted by time
  for (let i = events.length - 1; i >= 0; i -= 1) {
    const currEventType = events[i].type;
    if (
      currEventType === QUESTIONNAIRE_EVENT_TYPES.THREAD_RESOLVED ||
      currEventType === DILIGENCE_EVENT_TYPES.THREAD_RESOLVED
    ) {
      return true;
    }

    if (openedThreadEvents.includes(currEventType)) {
      return false;
    }
  }

  // if a thread was never opened through a sent comment,
  // it is implicitly resolved
  return true;
};

export function calculateNumUnresolved(sections, changeEventsPerQuestion) {
  return sections.reduce(
    (total, s) =>
      total +
      s.questions.reduce(
        (sectionTotal, q) =>
          sectionTotal +
          (!q.draftComment &&
          doesThreadExist(changeEventsPerQuestion[q.label]) &&
          !isThreadResolved(changeEventsPerQuestion[q.label])
            ? 1
            : 0),
        0,
      ),
    0,
  );
}

export function calculateNumUnsent(sections) {
  return sections.reduce(
    (total, s) =>
      total +
      s.questions.reduce(
        (sectionTotal, q) => sectionTotal + (q.draftComment ? 1 : 0),
        0,
      ),
    0,
  );
}
