/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/HelpOutline';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Dialog, DialogContent } from 'components/dialog/index';
import { SSNInput } from 'components/ssn';
import { TaxIDInput } from 'components/taxid';
import { Button } from 'components/button';
import { Answers } from '../answers';
import { UNSAVED } from '../saved';

const useStyles = makeStyles((theme) => ({
  input: {
    margin: theme.spacing(1, 0),
  },
  helperText: {
    marginBottom: theme.spacing(1),
    marginTop: '-4px',
  },
  radioContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },
  label: {
    textAlign: 'left',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  nameLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tooltipIcon: {
    marginLeft: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
  advanced: {
    marginTop: theme.spacing(1),
  },
  dialogTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  lineLeft: {
    flex: '0 0 50%',
    marginRight: theme.spacing(2),
  },
  lineHeader: {
    margin: theme.spacing(2, 0),
  },
  bold: {
    fontWeight: 500,
  },
}));

function Line({ left, right, header }) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.line, { [classes.lineHeader]: header })}>
      <Typography
        className={clsx(classes.lineLeft, { [classes.bold]: header })}
      >
        {left}
      </Typography>
      <Typography className={clsx({ [classes.bold]: header })}>
        {right}
      </Typography>
    </div>
  );
}

function NameHelpTooltip({ open, onClose }) {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <div className={classes.dialogTitle}>
        <div style={{ visibility: 'hidden' }}>
          <IconButton>
            <CloseIcon />
          </IconButton>
        </div>
        <Typography variant="h6" align="center">
          What individual/entity to use for W-9
        </Typography>
        <Tooltip title={<Typography>Close</Typography>}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </div>
      <DialogContent>
        <Line
          left="For this type of account:"
          right="Complete this form and give SSN for:"
          header
        />

        <Line left="Individual" right="The individual" />

        <Divider />

        <Line
          left="Two or more individuals (joint account) other than an account maintained by an FFI"
          right="The actual owner of the account or, if combined funds, the first individual on the account"
        />

        <Divider />

        <Line
          left="Two or more U.S. persons (joint account maintained by an FFI)"
          right="Each holder of the account"
        />

        <Divider />

        <Line
          left="Custodial account of a minor (Uniform Gift to Minors Act)"
          right="The minor"
        />

        <Divider />

        <Line
          left="The usual revocable savings trust (grantor is also trustee)"
          right="The grantor-trustee"
        />

        <Divider />

        <Line
          left="So-called trust account that is not a legal or valid trust under state law"
          right="The actual owner"
        />

        <Divider />

        <Line
          left="Sole proprietorship or disregarded entity owned by an individual"
          right="The owner"
        />

        <Divider />

        <Line
          left="Grantor trust filing under Optional Filing Method 1 (see Regulations section 1.671-4(b)(2)(i)(A))"
          right="The grantor"
        />

        <Line
          left="For this type of account:"
          right="Complete this form and give EIN for:"
          header
        />

        <Line left="Individual retirement account" right="The custodian" />

        <Divider />

        <Line
          left="Disregarded entity not owned by an individual"
          right="The owner"
        />

        <Divider />

        <Line
          left="A valid trust, estate, or pension trust"
          right="Legal entity"
        />

        <Divider />

        <Line
          left="Corporation or LLC electing corporate status on Form 8832 or Form 2553"
          right="The corporation"
        />

        <Divider />
        <Line
          left="Association, club, religious, charitable, educational, or other tax exempt organization"
          right="The organization"
        />

        <Divider />

        <Line left="Partnership or multi-member LLC" right="The partnership" />

        <Divider />

        <Line
          left="A broker or registered nominee"
          right="The broker or nominee"
        />

        <Divider />

        <Line
          left="Account with the Department of Agriculture in the name of a public
            entity (such as a state or local government, school district, or
            prison) that receives agricultural program payments"
          right="The public entity"
        />

        <Divider />

        <Line
          left="Grantor trust filing Form 1041 or under the Optional Filing
          Method 2, requiring Form 1099 (see Regulations section 1.671-4(b)(2)(i)(B))"
          right="The trust"
        />
      </DialogContent>
    </Dialog>
  );
}

function getTaxIdType(ssn, ein) {
  if (ssn) {
    return 'SSN';
  }
  if (ein) {
    return 'EIN';
  }
  return '';
}

const TEXT_LLC = 'Limited liability company';
const TEXT_TRUST_ESTATE = 'Trust/estate';
const TEXT_PARTNERSHIP = 'Partnership';

export function W9V4Question({
  updateLpDoc,
  setSaving,
  answer,
  label,
  QuestionStepper,
  QuestionPromptComponent,
}) {
  const classes = useStyles();

  const initialName = (answer && answer.name) || '';
  const initialBusinessName = (answer && answer.businessName) || '';
  const initialTaxClassification = (answer && answer.taxClassification) || '';
  const initialLlcTaxClassification =
    (answer && answer.llcTaxClassification) || '';
  const initialOtherTaxClassification =
    (answer && answer.otherTaxClassification) || '';
  const initialHasForeignPartner =
    (answer && answer.hasForeignPartner) || false;
  const initialAddress1 = (answer && answer.address1) || '';
  const initialAddress2 = (answer && answer.address2) || '';
  const initialSsn = (answer && answer.ssn) || '';
  const initialEin = (answer && answer.ein) || '';
  const initialAccountNumbers = (answer && answer.accountNumbers) || '';
  const initialExemptCode = (answer && answer.exemptCode) || '';
  const initialFatcaCode = (answer && answer.fatcaCode) || '';

  const [name, setName] = useState(initialName);
  const [businessName, setBusinessName] = useState(initialBusinessName);
  const [taxClassification, setTaxClassification] = useState(
    initialTaxClassification,
  );
  const [llcTaxClassification, setLlcTaxClassification] = useState(
    initialLlcTaxClassification,
  );
  const [otherTaxClassification, setOtherTaxClassification] = useState(
    initialOtherTaxClassification,
  );
  const [address1, setAddress1] = useState(initialAddress1);
  const [address2, setAddress2] = useState(initialAddress2);
  const [ssn, setSsn] = useState(initialSsn);
  const [ein, setEin] = useState(initialEin);
  const [accountNumbers, setAccountNumbers] = useState(initialAccountNumbers);
  const [exemptCode, setExemptCode] = useState(initialExemptCode);
  const [fatcaCode, setFatcaCode] = useState(initialFatcaCode);

  const [nameError, setNameError] = useState('');
  const [businessNameError, setBusinessNameError] = useState('');
  const [taxClassificationError, setTaxClassificationError] = useState('');
  const [llcTaxClassificationError, setLlcTaxClassificationError] =
    useState('');
  const [otherTaxClassificationError, setOtherTaxClassificationError] =
    useState('');
  const [address1Error, setAddress1Error] = useState('');
  const [address2Error, setAddress2Error] = useState('');
  const [ssnError, setSsnError] = useState('');
  const [einError, setEinError] = useState('');
  const [accountNumbersError, setAccountNumbersError] = useState('');
  const [exemptCodeError, setExemptCodeError] = useState('');
  const [fatcaCodeError, setFatcaCodeError] = useState('');
  const [taxIdTypeError, setTaxIdTypeError] = useState('');

  const [showNameHelp, setShowNameHelp] = useState(false);
  const [showLlc, setShowLlc] = useState(taxClassification === TEXT_LLC);
  const [showOther, setShowOther] = useState(taxClassification === 'Other');

  const [hasForeignPartner, setHasForeignPartner] = useState(
    initialHasForeignPartner,
  );
  const [showHasForeignPartner, setShowHasForeignPartner] = useState(
    taxClassification === TEXT_PARTNERSHIP ||
      taxClassification === TEXT_TRUST_ESTATE ||
      (taxClassification === TEXT_LLC &&
        llcTaxClassification === TEXT_PARTNERSHIP),
  );

  const [taxIdType, setTaxIdType] = useState(getTaxIdType(ssn, ein));
  const [showAdvanced, setShowAdvanced] = useState(
    !!(accountNumbers || exemptCode || fatcaCode),
  );
  const hasInput =
    Boolean(name) ||
    Boolean(businessName) ||
    Boolean(taxClassification) ||
    Boolean(llcTaxClassification) ||
    Boolean(otherTaxClassification) ||
    Boolean(address1) ||
    Boolean(address2) ||
    Boolean(ssn) ||
    Boolean(ein) ||
    Boolean(accountNumbers) ||
    Boolean(exemptCode) ||
    Boolean(hasForeignPartner) ||
    Boolean(fatcaCode);

  function handleSubmit(e) {
    e.preventDefault();

    setNameError('');
    setBusinessNameError('');
    setTaxClassificationError('');
    setLlcTaxClassificationError('');
    setOtherTaxClassificationError('');
    setAddress1Error('');
    setAddress2Error('');
    setSsnError('');
    setEinError('');
    setAccountNumbersError('');
    setExemptCodeError('');
    setFatcaCodeError('');
    setTaxIdTypeError('');

    const props = {
      name,
      businessName,
      taxClassification,
      address1,
      address2,
      accountNumbers,
      exemptCode,
      fatcaCode,
    };

    if (showLlc) {
      props.llcTaxClassification = llcTaxClassification;
    }

    if (showOther) {
      props.otherTaxClassification = otherTaxClassification;
    }

    if (showHasForeignPartner) {
      props.hasForeignPartner = hasForeignPartner;
    }

    if (taxIdType === 'SSN') {
      props.ssn = ssn;
    } else if (taxIdType === 'EIN') {
      props.ein = ein;
    }

    return updateLpDoc({ label, answer: props, skipQuestion: !hasInput }).catch(
      (error) => {
        setSaving(UNSAVED);
        if (error.response?.status === 400) {
          setNameError(error.response.data.answer.name);
          setBusinessNameError(error.response.data.answer.businessName);
          setTaxClassificationError(
            error.response.data.answer.taxClassification,
          );
          setLlcTaxClassificationError(
            error.response.data.answer.llcTaxClassification,
          );
          setOtherTaxClassificationError(
            error.response.data.answer.otherTaxClassification,
          );
          setAddress1Error(error.response.data.answer.address1);
          setAddress2Error(error.response.data.answer.address2);
          setSsnError(error.response.data.answer.ssn);
          setEinError(error.response.data.answer.ein);
          setAccountNumbersError(error.response.data.answer.accountNumbers);
          setExemptCodeError(error.response.data.answer.exemptCode);
          setFatcaCodeError(error.response.data.answer.fatcaCode);
          setTaxIdTypeError(error.response.data.answer.taxIdType);
        }
        return error;
      },
    );
  }

  function handleChange(e, field) {
    setSaving(UNSAVED);

    if (field === 'name') {
      setName(e.target.value);
    } else if (field === 'businessName') {
      setBusinessName(e.target.value);
    } else if (field === 'taxClassification') {
      setTaxClassification(e.target.value);
      setShowLlc(e.target.value === TEXT_LLC);
      setShowOther(e.target.value === 'Other');
      setShowHasForeignPartner(
        e.target.value === TEXT_PARTNERSHIP ||
          e.target.value === TEXT_TRUST_ESTATE ||
          (e.target.value === TEXT_LLC &&
            llcTaxClassification === TEXT_PARTNERSHIP),
      );
    } else if (field === 'llcTaxClassification') {
      setLlcTaxClassification(e.target.value);
      setShowHasForeignPartner(e.target.value === TEXT_PARTNERSHIP);
    } else if (field === 'otherTaxClassification') {
      setOtherTaxClassification(e.target.value);
    } else if (field === 'address1') {
      setAddress1(e.target.value);
    } else if (field === 'address2') {
      setAddress2(e.target.value);
    } else if (field === 'taxIdType') {
      setTaxIdType(e.target.value);
    } else if (field === 'ssn') {
      setSsn(e.target.value);
    } else if (field === 'ein') {
      setEin(e.target.value);
    } else if (field === 'accountNumbers') {
      setAccountNumbers(e.target.value);
    } else if (field === 'exemptCode') {
      setExemptCode(e.target.value);
    } else if (field === 'fatcaCode') {
      setFatcaCode(e.target.value);
    }
  }

  // Reset the input when we change questions.
  useEffect(() => {
    setName(initialName);
    setBusinessName(initialBusinessName);
    setTaxClassification(initialTaxClassification);
    setLlcTaxClassification(initialLlcTaxClassification);
    setOtherTaxClassification(initialOtherTaxClassification);
    setAddress1(initialAddress1);
    setAddress2(initialAddress2);
    setSsn(initialSsn);
    setEin(initialEin);
    setAccountNumbers(initialAccountNumbers);
    setExemptCode(initialExemptCode);
    setFatcaCode(initialFatcaCode);
    setHasForeignPartner(initialHasForeignPartner);
    setNameError('');
    setBusinessNameError('');
    setTaxClassificationError('');
    setLlcTaxClassificationError('');
    setOtherTaxClassificationError('');
    setAddress1Error('');
    setAddress2Error('');
    setSsnError('');
    setEinError('');
    setAccountNumbersError('');
    setExemptCodeError('');
    setFatcaCodeError('');
    setTaxIdTypeError('');

    setShowLlc(taxClassification === TEXT_LLC);
    setShowHasForeignPartner(
      taxClassification === TEXT_PARTNERSHIP ||
        taxClassification === TEXT_TRUST_ESTATE ||
        (taxClassification === TEXT_LLC &&
          llcTaxClassification === TEXT_PARTNERSHIP),
    );
    setShowOther(taxClassification === 'Other');
    setTaxIdType(getTaxIdType(initialSsn, initialEin));
    setShowAdvanced(
      !!(initialAccountNumbers || initialExemptCode || initialFatcaCode),
    );
  }, [label]);

  return (
    <form onSubmit={handleSubmit}>
      <QuestionPromptComponent short />
      <Answers tall>
        <div className={classes.nameLine}>
          <TextField
            autoFocus
            className={classes.input}
            id="name"
            label="Name"
            variant="outlined"
            value={name}
            error={!!nameError}
            helperText={nameError}
            onChange={(e) => {
              handleChange(e, 'name');
            }}
            fullWidth
          />

          <Tooltip title={<Typography>Learn more</Typography>}>
            <IconButton
              className={classes.tooltipIcon}
              onClick={() => {
                setShowNameHelp(true);
              }}
            >
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </div>

        <NameHelpTooltip
          open={showNameHelp}
          onClose={() => {
            setShowNameHelp(false);
          }}
        />

        <FormHelperText className={classes.helperText}>
          As shown on your income tax return. Name must correspond to the
          SSN/EIN used in this form. For sole proprietor or disregarded entity,
          enter the owner’s name.
        </FormHelperText>

        <TextField
          className={classes.input}
          id="businessName"
          label="Business name / disregarded entity name"
          variant="outlined"
          value={businessName}
          error={!!businessNameError}
          helperText={businessNameError}
          onChange={(e) => {
            handleChange(e, 'businessName');
          }}
          fullWidth
        />

        <FormHelperText className={classes.helperText}>
          If different from above
        </FormHelperText>

        <div className={classes.divider} />

        <RadioGroup
          aria-label="federal tax classification"
          name="federal tax classification"
          value={taxClassification}
          onChange={(e) => {
            handleChange(e, 'taxClassification');
          }}
        >
          <FormLabel
            error={!!taxClassificationError}
            className={classes.label}
            component="legend"
          >
            Federal tax classification
          </FormLabel>
          {taxClassificationError ? (
            <FormHelperText error className={classes.helperText}>
              {taxClassificationError}
            </FormHelperText>
          ) : null}
          <Grid container>
            <Grid item md={6} xs={12} className={classes.radioContainer}>
              {/*
              label is different from value to keep answers backward compatible
              with the old W9 while showing the correct text on the frontend
              */}
              <FormControlLabel
                value="Individual/sole proprietor or single-member LLC"
                control={<Radio />}
                label="Individual/sole proprietor"
              />
              <FormControlLabel
                value="C Corporation"
                control={<Radio />}
                label="C Corporation"
              />
              <FormControlLabel
                value="S Corporation"
                control={<Radio />}
                label="S Corporation"
              />
              <FormControlLabel
                value="Other"
                control={<Radio />}
                label="Other"
              />
            </Grid>
            <Grid item md={6} xs={12} className={classes.radioContainer}>
              <FormControlLabel
                value={TEXT_PARTNERSHIP}
                control={<Radio />}
                label={TEXT_PARTNERSHIP}
              />
              <FormControlLabel
                value={TEXT_TRUST_ESTATE}
                control={<Radio />}
                label={TEXT_TRUST_ESTATE}
              />
              <FormControlLabel
                value={TEXT_LLC}
                control={<Radio />}
                label={TEXT_LLC}
              />
            </Grid>
          </Grid>
        </RadioGroup>

        <Collapse in={showLlc} appear={false}>
          <RadioGroup
            aria-label="llc tax classification"
            name="llc tax classification"
            value={llcTaxClassification}
            onChange={(e) => {
              handleChange(e, 'llcTaxClassification');
            }}
          >
            <FormLabel
              error={!!llcTaxClassificationError}
              className={classes.label}
              component="legend"
            >
              LLC tax classification
            </FormLabel>
            {llcTaxClassificationError ? (
              <FormHelperText error className={classes.helperText}>
                {llcTaxClassificationError}
              </FormHelperText>
            ) : null}
            <Grid container>
              <Grid item md={4} xs={12} className={classes.radioContainer}>
                <FormControlLabel
                  value="C Corporation"
                  control={<Radio />}
                  label="C Corporation"
                />
              </Grid>
              <Grid item md={4} xs={12} className={classes.radioContainer}>
                <FormControlLabel
                  value="S Corporation"
                  control={<Radio />}
                  label="S Corporation"
                />
              </Grid>
              <Grid item md={4} xs={12} className={classes.radioContainer}>
                <FormControlLabel
                  value={TEXT_PARTNERSHIP}
                  control={<Radio />}
                  label={TEXT_PARTNERSHIP}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </Collapse>

        <Collapse in={showHasForeignPartner} appear={false}>
          <FormControlLabel
            className={classes.label}
            control={
              <Checkbox
                checked={hasForeignPartner}
                onChange={() => setHasForeignPartner((v) => !v)}
              />
            }
            label={
              <Typography>
                The investor has at least one <strong>foreign</strong> partner,
                owner, or beneficiary and this form is provided to a
                partnership, trust, or estate in which the investor has an
                ownership interest.
              </Typography>
            }
          />
        </Collapse>

        <Collapse in={showOther} appear={false}>
          <TextField
            className={classes.input}
            id="other"
            label="Other tax classification"
            variant="outlined"
            value={otherTaxClassification}
            error={!!otherTaxClassificationError}
            helperText={otherTaxClassificationError}
            onChange={(e) => {
              handleChange(e, 'otherTaxClassification');
            }}
            fullWidth
          />
        </Collapse>

        <div className={classes.divider} />

        <FormLabel className={classes.label} component="legend">
          Address
        </FormLabel>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <TextField
              className={classes.input}
              id="address1"
              label="Number, street, and apt or suite no."
              variant="outlined"
              value={address1}
              error={!!address1Error}
              helperText={address1Error}
              onChange={(e) => {
                handleChange(e, 'address1');
              }}
              fullWidth
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              className={classes.input}
              id="address2"
              label="City, state and zip code"
              variant="outlined"
              value={address2}
              error={!!address2Error}
              helperText={address2Error}
              onChange={(e) => {
                handleChange(e, 'address2');
              }}
              fullWidth
            />
          </Grid>
        </Grid>

        <div className={classes.divider} />

        <RadioGroup
          aria-label="tax id type"
          name="tax id type"
          value={taxIdType}
          onChange={(e) => {
            handleChange(e, 'taxIdType');
          }}
        >
          <Grid container>
            <Grid item md={6} xs={12} className={classes.radioContainer}>
              <FormControlLabel
                value="SSN"
                control={<Radio />}
                label="Social Security Number"
              />
            </Grid>
            <Grid item md={6} xs={12} className={classes.radioContainer}>
              <FormControlLabel
                value="EIN"
                control={<Radio />}
                label="Employer identification number"
              />
            </Grid>
          </Grid>
        </RadioGroup>

        {taxIdTypeError ? (
          <FormHelperText error className={classes.helperText}>
            {taxIdTypeError}
          </FormHelperText>
        ) : null}

        <Collapse in={taxIdType === 'SSN'} appear={false}>
          <TextField
            className={classes.input}
            id="ssn"
            label="Social security number"
            variant="outlined"
            value={ssn}
            error={!!ssnError}
            helperText={ssnError}
            onChange={(e) => {
              handleChange(e, 'ssn');
            }}
            InputProps={{
              inputComponent: SSNInput,
            }}
            fullWidth
          />
        </Collapse>

        <Collapse in={taxIdType === 'EIN'} appear={false}>
          <TextField
            className={classes.input}
            id="ein"
            label="Employer identification number"
            variant="outlined"
            value={ein}
            error={!!einError}
            helperText={einError}
            onChange={(e) => {
              handleChange(e, 'ein');
            }}
            InputProps={{
              inputComponent: TaxIDInput,
            }}
            fullWidth
          />
        </Collapse>

        <Button
          size="large"
          variant="text"
          color="primary"
          className={classes.advanced}
          onClick={() => {
            setShowAdvanced((a) => !a);
          }}
          endIcon={showAdvanced ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        >
          {showAdvanced ? 'Hide additional fields' : 'Show additional fields'}
        </Button>

        <Collapse in={showAdvanced} appear={false}>
          <TextField
            className={classes.input}
            id="accountNumbers"
            label="Account number(s) (optional)"
            variant="outlined"
            value={accountNumbers}
            error={!!accountNumbersError}
            helperText={accountNumbersError}
            onChange={(e) => {
              handleChange(e, 'accountNumbers');
            }}
            fullWidth
          />

          <TextField
            className={classes.input}
            id="exemptCode"
            label="Exempt payee code (if any)"
            variant="outlined"
            value={exemptCode}
            error={!!exemptCodeError}
            helperText={exemptCodeError}
            onChange={(e) => {
              handleChange(e, 'exemptCode');
            }}
            fullWidth
          />

          <TextField
            className={classes.input}
            id="fatcaCode"
            label="FATCA reporting code (if any)"
            variant="outlined"
            value={fatcaCode}
            error={!!fatcaCodeError}
            helperText={fatcaCodeError}
            onChange={(e) => {
              handleChange(e, 'fatcaCode');
            }}
            fullWidth
          />
        </Collapse>

        <QuestionStepper
          handleSubmit={handleSubmit}
          disabled={!name}
          hasAnswerChanged={
            name !== initialName ||
            businessName !== initialBusinessName ||
            taxClassification !== initialTaxClassification ||
            llcTaxClassification !== initialLlcTaxClassification ||
            otherTaxClassification !== initialOtherTaxClassification ||
            hasForeignPartner !== initialHasForeignPartner ||
            address1 !== initialAddress1 ||
            address2 !== initialAddress2 ||
            ssn !== initialSsn ||
            ein !== initialEin ||
            accountNumbers !== initialAccountNumbers ||
            exemptCode !== initialExemptCode ||
            fatcaCode !== initialFatcaCode
          }
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
}
