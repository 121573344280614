import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import AccountCogIcon from 'mdi-material-ui/AccountCogOutline';

import { Button } from 'components/button';
import { DialogActions } from 'components/dialog';
import { VIEW, EDIT, DELETE, ADD } from './constants';

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
}));

export function Actions({
  mode,
  setMode,
  onClose,
  disableAddButton,
  onAddMemberSubmit,
  onMemberDelete,
  fundAccessRestricted,
  isLoading,
}) {
  const classes = useStyles();

  function actionsContent() {
    switch (mode) {
      case VIEW:
        return (
          <>
            {fundAccessRestricted ? null : (
              <Button
                key="manage-access"
                variant="outlined_thin"
                onClick={() => setMode(EDIT)}
                startIcon={<AccountCogIcon color="primary" />}
                data-test="manage-access"
              >
                Manage access
              </Button>
            )}
            <Button key="close" variant="outlined_thin" onClick={onClose}>
              Close
            </Button>
          </>
        );
      case EDIT:
        return (
          <Button
            key="close"
            variant="outlined_thin"
            onClick={onClose}
            data-test="close-modal"
          >
            Close
          </Button>
        );
      case DELETE:
        return (
          <>
            <Button
              key="cancel"
              variant="outlined_thin"
              onClick={() => setMode(EDIT)}
            >
              Cancel
            </Button>
            <Button
              key="remove"
              variant="contained"
              color="primary"
              loading={isLoading}
              onClick={onMemberDelete}
            >
              Remove
            </Button>
          </>
        );
      case ADD:
        return (
          <>
            <Button
              key="cancel"
              variant="outlined_thin"
              onClick={() => setMode(EDIT)}
            >
              Cancel
            </Button>
            <Button
              key="add"
              type="submit"
              form="add-member-form"
              variant="contained"
              color="primary"
              disabled={disableAddButton}
              loading={isLoading}
              onClick={onAddMemberSubmit}
              data-test="add-member-save"
            >
              Add
            </Button>
          </>
        );
      default:
        return null;
    }
  }

  return (
    <DialogActions
      className={clsx(classes.actions, {
        [classes.spaceBetween]: mode === VIEW && !fundAccessRestricted,
      })}
    >
      {actionsContent()}
    </DialogActions>
  );
}
